
import zip from 'lodash.zip';
import { PropType, defineComponent } from 'vue';

import numbro from '@/initNumbro';
import ZoneBadge from '@/precision-farming/application-maps/components/resultTable/ZoneBadge.vue';
import type { PlantProtection, SummaryData } from '@/precision-farming/application-maps/spraying/store/types';
import { Zone } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Zone';
import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';
import FormFieldInputNumberFormattedDisplayValue from '@/shared/components/form/FormFieldInputNumberFormattedDisplayValue.vue';

export default defineComponent({
  name: 'ResultTable',
  components: {
    FormFieldInputNumberFormattedDisplayValue,
    ZoneBadge,
    MultiRootWrapper,
  },
  props: {
    zones: {
      type: Array as PropType<Zone[]>,
      required: true,
      default: () => [],
    },
    products: {
      type: Array as PropType<PlantProtection[]>,
      required: true,
      default: () => [],
    },
    sprayMix: {
      type: Number,
      required: true,
      default: 200,
    },
    summaryData: {
      type: Object as PropType<SummaryData>,
      required: false,
      default: () => ({
        reducedSprayMixPerZone: [],
        reducedVegetationPerZone: [],
      }),
    },
  },
  emits: ['update:summaryData'],
  model: {
    prop: 'summaryData',
    event: 'update:summaryData',
  },
  data(): {
    numbro: typeof numbro;
  } {
    return { numbro };
  },
  computed: {
    reducedSprayMixPerZone: {
      get(): number[] {
        return this.summaryData.reducedSprayMixPerZone;
      },
      set(values: number[]) {
        const newSummaryData: SummaryData = {
          reducedSprayMixPerZone: values,
          reducedVegetationPerZone: this.reducedVegetationPerZone,
        };
        this.$emit('update:summaryData', newSummaryData);
      },
    },
    reducedVegetationPerZone: {
      get(): number[] {
        return this.summaryData.reducedVegetationPerZone;
      },
      set(values: number[]) {
        const newSummaryData: SummaryData = {
          reducedSprayMixPerZone: this.reducedSprayMixPerZone,
          reducedVegetationPerZone: values,
        };
        this.$emit('update:summaryData', newSummaryData);
      },
    },
    vegetationMax(): number {
      return Math.max(...this.reducedVegetationPerZone);
    },
    areas(): number[] {
      return this.zones.map(({ size }) => size);
    },
    sprayMixMid(): number {
      const totalArea = this.areas.reduce((a, b) => a + b, 0);
      const totalSprayMixReduction = zip(this.reducedSprayMixPerZone, this.areas)
        .map(([a, b]) => [a ?? 1, b ?? 1]) // technically neither a nor b will ever be undefined, because both lists always have the same length, but it doesn't compile otherwise
        .map(([a, b]) => a * b)
        .reduce((a, b) => a + b, 0);
      return totalSprayMixReduction / totalArea;
    },
  },
  methods: {
    mid(productAmount: number): number {
      const totalArea = this.areas.reduce((a, b) => a + b, 0);
      const reductions = this.zones.map((_, i) => this.reduceProduct(productAmount, i));
      const reducedProductAmountPerArea = zip(this.areas, reductions)
        .map(([area, reduction]) => [area ?? 1, reduction ?? 1]) // technically neither a nor b will ever be undefined, because both lists always have the same length, but it doesn't compile otherwise
        .map(([area, reduction]) => area * reduction)
        .reduce((a, b) => a + b, 0);
      return reducedProductAmountPerArea / totalArea;
    },
    updateSprayMix(value: number, targetIndex: number): number[] {
      return this.reducedSprayMixPerZone.map((sprayMix, index) => (index === targetIndex ? value : sprayMix));
    },
    updateVegetation(productAmount: number, zoneIndex: number, manualValue: number): number[] {
      const manualReducedVegetation = (manualValue / productAmount) * this.vegetationMax;
      return this.reducedVegetationPerZone.map((vegetation, index) =>
        index === zoneIndex ? manualReducedVegetation : vegetation,
      );
    },
    formatter(value: number | null): string {
      return numbro(value).format();
    },
    reduceProduct(productAmount: number, zoneIndex: number): number {
      return (productAmount * this.reducedVegetationPerZone[zoneIndex]) / this.vegetationMax;
    },
  },
});
