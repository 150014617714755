
import { defineComponent } from 'vue';

import { RootState } from '@/store/types';

import Accordion from '../../components/sidebarZones/Accordion.vue';
import { VisualizationMode, ZoningMode } from '../../store/types';

export default defineComponent({
  name: 'AccordionContainer',
  components: { Accordion },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    selectedNumberOfZones: {
      get() {
        return (this.$store.state as RootState).precisionFarming.applicationMaps.fertilizationCereals.zoneMap
          .numberOfZones;
      },
      set(value: number) {
        this.$store.commit('precisionFarming/applicationMaps/fertilizationCereals/setNumberOfZones', value);
      },
    },
    selectedZoningMode: {
      get() {
        return (this.$store.state as RootState).precisionFarming.applicationMaps.fertilizationCereals.zoneMap
          .zoningMode;
      },
      set(zoningMode: ZoningMode) {
        this.$store.commit('precisionFarming/applicationMaps/fertilizationCereals/setZoningMode', zoningMode);
      },
    },
    borderZoneCorrection: {
      get() {
        return (this.$store.state as RootState).precisionFarming.applicationMaps.fertilizationCereals.zoneMap
          .borderZoneCorrection;
      },
      set(zoneCorrection: boolean) {
        this.$store.commit(
          'precisionFarming/applicationMaps/fertilizationCereals/setBorderZoneCorrection',
          zoneCorrection,
        );
      },
    },
  },
  watch: {
    collapsed(newCollapsedValue: boolean) {
      if (newCollapsedValue === false) {
        this.$store.commit(
          'precisionFarming/applicationMaps/fertilizationCereals/setVisualizationMode',
          VisualizationMode.ZONES,
        );
      }
    },
  },
});
