import { Module } from 'vuex';

import { RootState } from '@/store/types';

import actions from './actions';
import getters from './getters';
import initialState from './initialState';
import mutations from './mutations';
import { ApplicationMapsSprayingState } from './types';

// TODO #1428 update the store content and actions in upcoming story
const vuexModule: Module<ApplicationMapsSprayingState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};

export default vuexModule;
