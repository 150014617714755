import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faShareAlt } from '@fortawesome/pro-regular-svg-icons';
import Handsontable from 'handsontable';

import { getFontColor } from '../../../shared/modules/colorHelpers';

const baseUrl = process.env.VUE_APP_ORIGIN;

library.add(faShareAlt);

function SharedFieldInfoRenderer(instance, td, row, col, prop, value, cellProperties, ...args) {
  Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties, ...args]);
  if (!Array.isArray(value)) {
    return;
  }

  // eslint-disable-next-line no-param-reassign
  td.innerHTML = '';
  // eslint-disable-next-line no-param-reassign
  td.style.textOverflow = 'clip';
  // eslint-disable-next-line no-param-reassign
  td.style.position = 'relative';
  td.classList.add('py-2', 'px-3');
  value.forEach((companyInfo) => {
    const { name, sharingLogoUrl, sharingBackground, shareId } = companyInfo;
    const backgroundColor = !sharingLogoUrl && sharingBackground ? sharingBackground : 'white ';
    const div = document.createElement('div');
    const span = document.createElement('span');
    const cellId = `cell-${row}-${col}`;
    if (sharingLogoUrl) {
      const sharingLogo = document.createElement('img');
      sharingLogo.src = baseUrl + sharingLogoUrl;
      sharingLogo.style.maxHeight = '100%';
      sharingLogo.style.height = 'auto';
      sharingLogo.style.maxWidth = '100%';
      sharingLogo.style.width = 'auto';
      sharingLogo.style.paddingTop = '2px';
      sharingLogo.style.paddingBottom = '2px';
      sharingLogo.style.objectFit = 'contain';
      div.prepend(sharingLogo);
    } else {
      span.innerText = name;

      span.style.overflow = 'hidden';
      span.style.whiteSpace = 'nowrap';
      span.style.textOverflow = 'ellipsis';
      span.style.fontSize = '12px';
      span.style.fontWeight = '600';
      span.style.color = getFontColor(backgroundColor);
      const [statusIcon] = icon({ prefix: 'far', iconName: 'share-alt' }).node;
      statusIcon.classList.add('mr-2');
      span.prepend(statusIcon);
      div.appendChild(span);
    }
    div.title = name;
    div.classList.add('badge', 'badge-pill', 'badge-white', 'border', 'mr-3');
    div.style.minWidth = '130px';
    div.style.maxWidth = '130px';
    div.style.height = '25px';
    div.style.display = 'flex';
    div.style.alignItems = 'center';
    div.style.justifyContent = 'center';
    div.style.background = backgroundColor;
    div.addEventListener('click', () => cellProperties.onClick({ shareId }));
    div.style.cursor = 'pointer';

    let cell = document.getElementById(cellId);
    if (cell === null) {
      cell = document.createElement('div');
    }
    cell.style.width = '100%';
    cell.style.height = '25px';
    cell.style.display = 'flex';
    cell.style.flexDirection = 'row';
    cell.style.alignItems = 'center';
    cell.id = `cell-${row}-${col}`;
    cell.appendChild(div);
    td.appendChild(cell);
  });
}

Handsontable.cellTypes.registerCellType('sharedFieldInfo', {
  renderer: SharedFieldInfoRenderer,
});
