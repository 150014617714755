<template>
  <ModalWrapper
    ref="modalWrapper"
    size="md"
    body-class="modal-edit-status"
    :visible="visible"
    :title="$t('Erntejahr-Status')"
    @change="(value) => $emit('change', value)"
  >
    <template #default>
      <div v-if="success">
        <p>
          {{ $t('Der Status der gewählten Felder wurde aktualisiert.') }}
        </p>
        <p v-if="status === 'completed'">
          {{
            $t('Sie wurden im Erntejahr {current} abgeschlossen und werden in {next} aktiv gestellt.', {
              current,
              next,
            })
          }}
        </p>
        <p v-if="status === 'active'">
          <!-- eslint-disable max-len -->

          {{
            $t(
              'Sie sind nun im Erntejahr {current} aktiv und werden in den vorangehenden Erntejahren auf abgeschlossen gestellt.',
              { current },
            )
          }}
          <!-- eslint-enable max-len -->
        </p>
        <div v-if="status === 'planned'">
          <p>
            {{ $t('Sie wurden für {current} auf geplant gestellt.', { current }) }}
          </p>
          <p v-if="numberOfNewActiveFieldsInPrevious === 1">
            {{ $t('Im Erntejahr {previous} wurde ein Feld auf aktiv gestellt.', { previous }) }}
          </p>
          <p v-else-if="numberOfNewActiveFieldsInPrevious != null">
            {{
              $t('Im Erntejahr {previous} wurden {numberOfNewActiveFieldsInPrevious} Felder auf aktiv gestellt.', {
                previous,
                numberOfNewActiveFieldsInPrevious,
              })
            }}
          </p>
        </div>
      </div>
      <p v-else-if="error" class="modal-edit-status__error">
        {{ errorUserMessage }}
      </p>
      <div v-else>
        <FormFieldRadioGroup v-model="status" class="m-0" variant="no-label" stacked>
          <FormRadio variant="bordered" value="completed" :disabled="fetching">
            <template #default>
              <span v-if="initialStatus === 'completed'">{{ $t('Abgeschlossen') }}</span>
              <span v-else>
                {{ $t('Abschließen') }}
                <span v-if="allActiveAndHaveHarvestingMeasures" class="text-danger"> ({{ $t('empfohlen') }}) </span>
              </span>
              &nbsp;
              <FontAwesomeIcon class="modal-edit-status__icon modal-edit-status__icon--completed" icon="check-circle" />
            </template>
            <template #text v-if="initialStatus === 'completed'">
              <!-- eslint-disable max-len -->
              {{
                $t(
                  'Die Ernte für das Erntejahr {current} ist auf den ausgewählten Feldern bereits abgeschlossen. Sie sind zur Zeit in einem zukünftigen Erntejahr "aktiv".',
                  { current },
                )
              }}
              <!-- eslint-enable max-len -->
            </template>
            <template #text v-else-if="allActiveAndHaveHarvestingMeasures">
              <!-- eslint-disable max-len -->
              {{
                $t(
                  'Auf diesen Feldern wurden bereits Erntemaßnahmen für das Erntejahr {current} gebucht. Schließe das Erntejahr für die Felder jetzt ab, sie werden automatisch im nächsten Erntejahr {next} angelegt und "aktiv" gesetzt.',
                  { current, next },
                )
              }}
              <!-- eslint-enable max-len -->
            </template>
            <template #text v-else>
              <!-- eslint-disable max-len -->
              {{
                $t(
                  'Setze die ausgewählten Felder auf "abgeschlossen", sobald die Ernte für das Erntejahr {current} beendet wurde. Die Felder werden automatisch im nächsten Erntejahr {next} angelegt und "aktiv" gesetzt.',
                  { current, next },
                )
              }}
              <!-- eslint-enable max-len -->
            </template>
          </FormRadio>
          <FormRadio variant="bordered" value="active" :disabled="fetching">
            <template #default>
              {{ $t('Aktiv') }}
              <FontAwesomeIcon class="modal-edit-status__icon modal-edit-status__icon--active" icon="dot-circle" />
            </template>
            <template #text v-if="initialStatus === 'active'">
              {{ $t('Die Felder werden derzeit im angezeigten Erntejahr {current} bewirtschaftet.', { current }) }}
            </template>
            <template #text v-else>
              <!-- eslint-disable max-len -->
              {{
                $t(
                  'Setze die ausgewählten Felder "aktiv", wenn sie ab sofort im angezeigten Erntejahr {current} bewirtschaftet werden sollen. Vorangehende Erntejahre werden auf diesen Feldern abgeschlossen.',
                  { current },
                )
              }}
              <!-- eslint-enable max-len -->
            </template>
          </FormRadio>
          <FormRadio variant="bordered" value="planned" :disabled="fetching">
            <template #default>
              {{ $t('Geplant') }}
              <FontAwesomeIcon class="modal-edit-status__icon modal-edit-status__icon--planned" icon="clock" />
            </template>
            <template #text v-if="initialStatus === 'planned'">
              {{ $t('Das Erntejahr {current} für die ausgewählten Felder befindet sich in Planung.', { current }) }}
            </template>
            <template #text v-else>
              <!-- eslint-disable max-len -->
              {{
                $t(
                  'Setze den Status auf "geplant", wenn das Erntejahr {current} auf den ausgewählten Feldern noch nicht begonnen hat.',
                  { current },
                )
              }}
              <!-- eslint-enable max-len -->
            </template>
          </FormRadio>
        </FormFieldRadioGroup>
      </div>
    </template>
    <template #buttons>
      <BButton variant="primary" class="mr-3" :disabled="okDisabled" @click="save">
        <span>
          {{ okBtnLabel }}
          <FontAwesomeIcon v-if="fetching" icon="circle-notch" spin />
        </span>
      </BButton>
      <BButton v-if="!success && !error" variant="white" :disabled="fetching" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { mapGetters } from 'vuex';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';

library.add(faCircleNotch);

export default {
  name: 'ModalEditStatus',
  components: { FormFieldRadioGroup, FormRadio, ModalWrapper },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fieldIds: {
      type: Array,
      required: true,
    },
    allActiveAndHaveHarvestingMeasures: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: null,
      initialStatus: null,
      fetching: false,
      success: false,
      error: false,
      errorUserMessage: null,
      numberOfNewActiveFieldsInPrevious: null,
    };
  },
  computed: {
    ...mapGetters({
      fields: 'fields/data',
    }),
    okDisabled() {
      if (this.fetching) {
        return true;
      }
      if (this.success || this.error) {
        return false;
      }
      if (this.status === this.initialStatus) {
        return true;
      }
      return this.status == null;
    },
    okBtnLabel() {
      if (this.success || this.error) {
        return this.$t('Schließen');
      }
      const changingStatus = this.status !== this.initialStatus ? this.status : undefined;
      switch (changingStatus) {
        case 'completed':
          return this.$t('Abschließen');
        case 'active':
          return this.$t('Aktiv setzen');
        case 'planned':
          return this.$t('Geplant setzen');
        default:
          break;
      }
      return this.$t('Speichern');
    },
    previous() {
      return Number(this.$store.state.auth.currentProcessOrderName) - 1;
    },
    current() {
      return this.$store.state.auth.currentProcessOrderName;
    },
    next() {
      return Number(this.$store.state.auth.currentProcessOrderName) + 1;
    },
  },
  mounted() {
    let status = new Set();
    this.fieldIds.forEach((fieldId) => {
      status.add(this.fields[fieldId].status);
    });
    status = [...status];
    if (status.length === 1) {
      [this.initialStatus] = status;
    }
    switch (this.initialStatus) {
      case 'active':
        this.status = 'completed';
        break;
      case 'planned':
        this.status = 'active';
        break;
      case 'completed':
        this.status = 'completed';
        break;
      default:
        this.status = null;
        break;
    }
  },
  methods: {
    async save() {
      if (this.success || this.error) {
        this.hide();
        return;
      }
      this.fetching = true;
      // wait 100ms so that the BE request is not blocking the update of the UI (loading status)
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 100));
      const data = await this.$store.dispatch('fields/setStatus', {
        status: this.status,
        fieldIds: this.fieldIds,
      });
      if (data.status === 'error') {
        this.fetching = false;
        this.error = true;
        this.errorUserMessage = data.errorUserMessage.join('\n');
        return;
      }
      const changesInPreviousProcessOrder = data.data[String(this.previous)];
      if (changesInPreviousProcessOrder != null) {
        this.numberOfNewActiveFieldsInPrevious = Object.values(changesInPreviousProcessOrder).filter(
          (value) => value === 'active',
        ).length;
      }
      await this.$store.dispatch('auth/refreshUserCompanies');
      this.fetching = false;
      this.success = true;
    },
    hide() {
      if (this.success) {
        this.$emit('success');
      }
      this.$refs.modalWrapper.hide();
    },
  },
};
</script>

<style scoped>
.modal-edit-status__error {
  color: var(--danger_dark);
}

.modal-edit-status__icon {
  margin-bottom: 2px;
  margin-left: 5px;
  font-size: 12px;
}

.modal-edit-status__icon--completed {
  color: var(--gray_600);
}

.modal-edit-status__icon--active {
  color: var(--primary);
}

.modal-edit-status__icon--planned {
  color: var(--black);
}
</style>
