
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { PropType, defineComponent } from 'vue';
import VuejsDatepicker, { DayCellContent, DisabledDates, HighlightedDates } from 'vuejs-datepicker';

import {
  getNextAvailableDate,
  getPreviousAvailableDate,
  nextAvailableDateExists,
  previousAvailableDateExists,
} from '@/shared/modules/datepickerUtils';

import BaseFormField from './BaseFormField.vue';

library.add(faCalendarAlt, faChevronLeft, faChevronRight);

type Variant = 'horizontal' | 'vertical' | 'vertical-lg' | 'no-label';

export default defineComponent({
  name: 'FormFieldDatepicker',
  components: { BaseFormField, VuejsDatepicker },
  model: {
    prop: 'selectedDate',
    event: 'setSelectedDate',
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    variant: {
      type: String as PropType<Variant>,
      default: 'horizontal',
      validator: (value: string) => ['horizontal', 'vertical', 'vertical-lg', 'no-label'].includes(value),
    },
    calendarClass: {
      type: String,
      default: '',
    },
    selectedDate: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    disabledDates: {
      type: Object as PropType<DisabledDates>,
      default: undefined,
    },
    highlightedDates: {
      type: Object as PropType<HighlightedDates>,
      default: undefined,
    },
    dayCellContent: {
      type: Function as PropType<DayCellContent>,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'dark',
      validator: (value: string) => ['dark', 'light'].includes(value),
    },
    step: {
      type: Number,
      default: 1,
    },
    description: {
      type: String,
      default: '',
    },
  },
  emits: ['setSelectedDate'],
  computed: {
    language(): unknown | undefined {
      return this.$store?.getters?.datepickerTranslation || 'de';
    },
    selectedPreviousDateClickDisabled(): boolean {
      if (!this.selectedDate) return true;
      return !previousAvailableDateExists(this.selectedDate, this.disabledDates);
    },
    selectedNextDateClickDisabled(): boolean {
      if (!this.selectedDate) return true;
      return !nextAvailableDateExists(this.selectedDate, this.disabledDates);
    },
  },
  methods: {
    onCalendarIconClick() {
      // @ts-ignore
      this.$refs.datepicker?.showCalendar();
    },
    onSelectNextDateClick() {
      if (!this.selectedDate) return;
      const nextAvailableDate = getNextAvailableDate(this.selectedDate, this.disabledDates, this.step);
      if (nextAvailableDate) {
        this.$emit('setSelectedDate', nextAvailableDate);
      }
    },
    onSelectPreviousDateClick() {
      if (!this.selectedDate) return;
      const previousAvailableDate = getPreviousAvailableDate(this.selectedDate, this.disabledDates, this.step);
      if (previousAvailableDate) {
        this.$emit('setSelectedDate', previousAvailableDate);
      }
    },
  },
});
