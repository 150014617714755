import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import Handsontable from 'handsontable';

import RequiredRenderer from './RequiredRenderer';
import setCellTitleAttribute from './helpers/setCellTitleAttribute';

library.add(faExclamationCircle);

export default function DropdownRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: string | null,
  cellProperties: Handsontable.ColumnSettings,
  ...args: unknown[]
) {
  if (cellProperties.required && value == null) {
    // @ts-ignore
    RequiredRenderer.apply(this, [instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args]);
    return;
  }

  const displayValue = cellProperties.getRendererValue({ value, visualRow, instance });
  // @ts-ignore
  Handsontable.renderers.AutocompleteRenderer(instance, TD, visualRow, visualCol, prop, displayValue, cellProperties);

  if (cellProperties.readOnly) {
    TD.classList.add('read-only');
  }

  setCellTitleAttribute(TD, displayValue);

  if (!cellProperties.showNotification) {
    return;
  }

  // remember the span as it is used as target for Bootstrap popovers
  let span = TD.querySelector('span.hot-floating-cell-icon');
  if (span == null) {
    span = document.createElement('span');
  }
  span.className = 'hot-floating-cell-icon hot-floating-cell-icon--plain hot-floating-cell-icon--dropdown';
  span.innerHTML = '';
  const [statusIcon] = icon({ prefix: 'fal', iconName: 'exclamation-circle' }).node;
  span.append(statusIcon);

  // @ts-ignore
  span.onmouseover = () =>
    cellProperties.hoverNotification({
      visualRow,
      target: span,
    });

  TD.classList.add('hot-cell-with-floating-cell-icon');
  TD.classList.add('hot-cell-with-floating-cell-icon--dropdown');
  TD.append(span);
}
