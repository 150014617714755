<template>
  <Button
    class="button-onboarding"
    :class="{ 'button-onboarding--active': active }"
    variant="white"
    size="lg"
    block
    :pressed="false"
    @click="$emit('click')"
  >
    <slot name="default" />
  </Button>
</template>

<script>
import Button from '@/shared/components/buttons/Button.vue';

export default {
  name: 'ButtonOnboarding',
  components: { Button },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.button-onboarding {
  min-height: 62px;
  padding: var(--spacer_1) var(--spacer_2);
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 14px;
  line-height: 19px;
  white-space: normal;
  word-break: break-word;
  hyphens: auto;
}

@media (min-height: 600px) {
  .button-onboarding {
    min-height: 68px;
  }
}

@media (min-width: 768px) {
  .button-onboarding {
    font-size: 16px;
    line-height: 24px;
  }
}

.button-onboarding--active,
.button-onboarding--active:not(:disabled):not(.disabled):active,
.button-onboarding--active:not(:disabled):not(.disabled):active:focus,
.button-onboarding--active:not(:disabled):not(.disabled):focus,
.button-onboarding--active:not(:disabled):not(.disabled):hover {
  color: var(--primary_darker);
  border: 1px solid var(--primary_darker);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: var(--primary_light);
}
</style>
