<template lang="html">
  <ModalWrapper
    ref="modalWrapper"
    size="max"
    body-class="modal-insert-fields"
    :visible="visible"
    :title="$t('Felder hinzufügen')"
    :map-full="!showForm"
    @change="(value) => $emit('change', value)"
  >
    <template #default>
      <div class="modal-insert-fields__body">
        <FormFieldSet v-if="companies.length > 1 || useCustomers" :headline="$t('Betrieb')">
          <FormFieldSelect
            v-if="companies.length > 1"
            v-model="companyId"
            required
            :label="$t('Betrieb')"
            :options="companyOptions"
            :state="missingCompany ? false : null"
            @update="missingCompany = false"
          />
          <FormFieldDropdown
            v-if="useCustomers"
            required
            :label="$t('Kunde')"
            :value="customerValueForDropdown"
            :items="customersToDropdownItems"
            :items-loading="$store.state.customers.fetching"
            @update="(value) => (customerId = value.id)"
          />
        </FormFieldSet>
        <FormFieldSet
          v-for="field in newFields"
          collapsible
          :key="field.id"
          :headline="fieldHeadline(field)"
          :collapsed="currentField !== field.id"
          @update="(collapsed) => updateFormFieldCollapsed(field, collapsed)"
        >
          <FormFieldInput
            v-model="field['fieldGroup.mfa']"
            :label="$t('Feldstück Nr')"
            :placeholder="$t('Feldstück Nr')"
          />
          <FormFieldInput
            v-if="useFieldNumber"
            v-model="field.fieldNumber"
            :label="$t('Feld-Nummer')"
            :placeholder="$t('Feld-Nummer')"
          />
          <FormFieldInput
            v-model="field.name"
            required
            :label="$t('Bezeichnung')"
            :placeholder="$t('Pflichtfeld')"
            :state="nameInputState"
            @update="resetNameInput"
          />
          <FormFieldInput
            v-model="field.fieldSize"
            type="number"
            placeholder="0"
            step="0.01"
            :label="$t('Größe (in ha)')"
            @blur="field.fieldSize = fieldSizeDecimals(field.fieldSize)"
          />
          <FormFieldDropdown
            :label="$t('Kultur')"
            :value="cropValueForDropdown(field)"
            :items="cropsToDropdownItems"
            :items-loading="$store.state.products.crops.fetching"
            @update="(value) => (field.cropId = value.id)"
          />
          <BButton
            v-if="field.cropId != null && showCopyCropToAllFields"
            class="modal-insert-fields__button-copy-crop"
            variant="outline-primary"
            size="sm"
            @click="copyCropToAllFields(field)"
          >
            {{ $t('Kultur für alle Felder übernehmen') }}
          </BButton>
        </FormFieldSet>
      </div>
    </template>
    <template #bottom-content>
      <p class="text-danger m-0">
        <span v-if="missingCompany">{{ $t('Betrieb fehlt.') }}</span>
        <span v-if="uniqueNameViolation">{{ $t('Ein Feld mit diesem Namen existiert bereits.') }}</span>
        <span v-else-if="missingName != null">{{
          $t('Bezeichnung für Feld {number} fehlt.', { number: missingName + 1 })
        }}</span>
        <span v-else-if="invalidNewFields.length > 0">{{
          $t('Ein oder mehrere Felder haben eine ungültige Kontur')
        }}</span>
        <span v-else style="display: inline-block" />
      </p>
    </template>
    <template #map-container>
      <FieldsMap
        class="modal-insert-fields__map"
        ref="map"
        initial-center-include-disabled
        :companies="companies"
        :crops="crops"
        :disabledFields="fields"
        :activeFields="activeFields"
        :editableFields="editableFields"
        :insertEnabled="!showForm"
        :invalidNewFields="invalidNewFields"
        :selectionSource="'MAP'"
        @insert="insert"
        @addPolygon="addPolygon"
        @change:polygon="changePolygon"
        @change:marker="changeMarker"
        @change:fieldName="changeFieldName"
        @click:polygon="(fieldId) => (currentField = fieldId)"
        @click:marker="(fieldId) => (currentField = fieldId)"
        @action:save="fieldSave"
        @action:removePolygon="onRemovePolygon"
        @action:discard="fieldDiscard"
      />
    </template>
    <template #buttons>
      <BButton v-if="!showForm" variant="primary" class="mr-3" :disabled="newFields.length < 1" @click="mapOk">
        <span>{{ $t('Weiter') }}</span>
      </BButton>
      <BButton
        v-if="showForm"
        variant="primary"
        class="mr-3"
        :disabled="newFields.length < 1 || invalidNewFields.length > 0"
        @click="save"
      >
        <span>{{ $t('Speichern') }}</span>
      </BButton>
      <BButton v-if="showForm" variant="white" class="mr-3" @click="showForm = false">
        <span>{{ $t('Zurück') }}</span>
      </BButton>
      <BButton variant="white" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
    </template>
    <template #footer-content-right>
      <InfoContainer v-if="!showForm">
        {{ $t('Klicke in der Karte um ein neues Feld hinzuzufügen.') }}
      </InfoContainer>
    </template>
  </ModalWrapper>
</template>

<script>
import area from '@turf/area';
import { mapGetters } from 'vuex';

import cropsToDropdownItems from '@/products/crops/mixins/containers/cropsToDropdownItems';
import InfoContainer from '@/shared/components/InfoContainer.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import { createUuid } from '@/shared/modules/uuid';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import { companyId as columnCompany, customer as columnCustomer } from '../handsontable/columns/columns';
import fieldSizeDecimals from '../mixins/fieldSizeDecimals';
import isValidPolygon from '../utils/validatePolygon';
import FieldsMap from './FieldsMap.vue';

export default {
  name: 'ModalInsertFields',
  components: {
    FormFieldSelect,
    FormFieldDropdown,
    FormFieldInput,
    FormFieldSet,
    FieldsMap,
    ModalWrapper,
    InfoContainer,
  },
  mixins: [cropsToDropdownItems, fieldSizeDecimals],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: null,
      validator(columns) {
        if (columns == null) {
          return true;
        }
        if (!Array.isArray(columns)) {
          return false;
        }
        return columns.every(
          (column) => column != null && typeof column.key === 'string' && typeof column.visible === 'boolean',
        );
      },
    },
  },
  data() {
    return {
      showForm: false,
      newFields: [],
      invalidNewFields: [],
      companyId: null,
      customerId: 'own',
      currentField: null,
      missingCompany: false,
      missingName: null,
      uniqueNameViolation: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      crops: 'products/crops',
      cropsUsed: 'products/crops/used',
      fields: 'fields/dataWithoutRemoved',
      customers: 'customers/data',
    }),
    nameInputState() {
      if (this.missingName != null || this.uniqueNameViolation) {
        return false;
      }

      return null;
    },
    columnsByKey() {
      if (this.columns == null) {
        return {};
      }
      return this.columns.reduce(
        (columnsByKey, column) => ({
          ...columnsByKey,
          [column.key]: column,
        }),
        {},
      );
    },
    companies() {
      return this.$store.state.auth.currentCompanies;
    },
    companyOptions() {
      return [
        { value: null, text: this.$t('Betrieb wählen'), disabled: true },
        ...this.companies.map((company) => ({ value: company.id, text: company.name })),
      ];
    },
    fieldSizeRounded: {
      get() {
        if (typeof this.field.fieldSize !== 'number') {
          return null;
        }
        return Math.round(this.field.fieldSize * 100) / 100;
      },
      set(fieldSize) {
        this.field.fieldSize = fieldSize;
      },
    },
    activeFields() {
      return this.newFields
        .filter((field) => field.id !== this.currentField)
        .reduce(
          (activeFields, field) => ({
            ...activeFields,
            [field.id]: field,
          }),
          {},
        );
    },
    editableFields() {
      return this.newFields
        .filter((field) => field.id === this.currentField)
        .reduce(
          (activeFields, field) => ({
            ...activeFields,
            [field.id]: field,
          }),
          {},
        );
    },
    showCopyCropToAllFields() {
      if (this.newFields.length < 2) {
        return false;
      }
      const { cropId } = this.newFields[0];
      return this.newFields.some((field) => field.cropId !== cropId);
    },
    useCustomers() {
      if (this.customers == null) {
        return false;
      }
      return (
        this.columnsByKey[columnCustomer.key] != null &&
        this.columnsByKey[columnCustomer.key].visible &&
        Object.keys(this.customers).length > 0
      );
    },
    useFieldNumber() {
      return this.columnsByKey.fieldNumber != null && this.columnsByKey.fieldNumber.visible;
    },
    customerValueForDropdown() {
      if (this.customerId != null && this.customers[this.customerId] != null) {
        return {
          id: this.customerId,
          name: this.customers[this.customerId].name,
        };
      }
      if (this.customerId === 'own') {
        return { id: 'own', name: this.$t('Eigener Betrieb') };
      }
      return { id: null, name: '' };
    },
    customersToDropdownItems() {
      return [
        {
          id: 'crops-used',
          items: [{ id: 'own', name: this.$t('Eigener Betrieb') }],
          sort: false,
        },
        {
          name: this.$t('Kunden'),
          id: 'customers',
          items: Object.values(this.customers),
          sort: true,
        },
      ];
    },
    requireUniqueFieldName() {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_FIELD_NAMES_UNIQUE);
    },
  },
  created() {
    this.$store.dispatch('products/crops/subscribe');
  },
  methods: {
    save() {
      this.resetDirtyFormValues();
      if (this.companies.length > 1 && this.companyId == null) {
        this.missingCompany = true;
        return;
      }
      const missingName = Object.values(this.newFields).some((field, index) => {
        if (typeof field.name === 'string' && field.name.length > 0) {
          return false;
        }
        this.currentField = field.id;
        this.missingName = index;
        return true;
      });
      if (missingName) {
        return;
      }

      if (this.requireUniqueFieldName) {
        const isNameViolation = Object.values(this.newFields).some((field, index) => {
          if (this.$store.getters['fields/isUniqueFieldName'](field.name)) {
            this.missingName = index;
            this.currentField = field.id;
            this.uniqueNameViolation = true;
            return true;
          }
          return false;
        });
        if (isNameViolation) {
          return;
        }
      }

      let customerId = null;
      if (this.customerId != null && this.customers[this.customerId] != null) {
        ({ customerId } = this);
      }
      Object.values(this.newFields).forEach((field) => {
        const entry = {
          ...field,
          [columnCustomer.key]: customerId,
        };
        if (this.companies.length > 1) {
          entry[columnCompany.key] = this.companyId;
        }
        this.$store.dispatch('fields/insertAndSyncNewEntry', { entry });
      });
      this.hide();
    },
    hide() {
      this.$refs.modalWrapper.hide();
    },
    selectCrop(selected) {
      this.field.cropId = selected.id;
      this.field.crop.name = selected.name;
    },
    insert(field) {
      let fieldContour = null;
      let fieldSize = null;
      if (field.fieldContour != null) {
        fieldSize = Math.floor(area(field.fieldContour.geoJson)) / 10000;
        fieldContour = {
          ...field.fieldContour,
          id: createUuid(),
          area: fieldSize,
        };
      }
      const id = createUuid();
      this.newFields = [
        ...this.newFields,
        {
          ...field,
          'fieldGroup.mfa': null,
          name: null,
          fieldSize,
          cropId: null,
          id,
          fieldContour,
          placeholderName: this.$t('Feld {number}', { number: this.newFields.length + 1 }),
        },
      ];
      this.currentField = id;
    },
    addPolygon(field) {
      let fieldContour = null;
      let fieldSize = null;
      if (field.fieldContour != null) {
        fieldSize = Math.floor(area(field.fieldContour.geoJson)) / 10000;
        fieldContour = {
          ...field.fieldContour,
          id: createUuid(),
          area: fieldSize,
        };
      }
      this.newFields = this.newFields.map((currentField) => {
        if (currentField.id !== field.id) {
          return currentField;
        }
        return {
          ...currentField,
          fieldSize,
          fieldContour,
        };
      });
    },
    changePolygon({ fieldContour, fieldId }) {
      const fieldSize = Math.floor(area(fieldContour.geoJson)) / 10000;
      this.newFields = this.newFields.map((field) => {
        if (field.id !== fieldId) {
          return field;
        }

        const isValid = isValidPolygon(fieldContour.geoJson.coordinates);
        if (isValid) {
          if (this.invalidNewFields.some((f) => f.id === field.id)) {
            this.invalidNewFields = this.invalidNewFields.filter((f) => f.id !== field.id);
          }
        } else {
          this.invalidNewFields.push(field);
        }

        return {
          ...field,
          fieldSize,
          fieldContour: {
            ...field.fieldContour,
            geoJson: fieldContour.geoJson,
            area: fieldSize,
          },
        };
      });
    },
    changeMarker({ lat, lon, fieldId }) {
      this.newFields = this.newFields.map((field) => {
        if (field.id !== fieldId) {
          return field;
        }
        return {
          ...field,
          lat,
          lon,
        };
      });
    },
    changeFieldName({ fieldId, name }) {
      this.newFields = this.newFields.map((field) => {
        if (field.id !== fieldId) {
          return field;
        }
        return {
          ...field,
          name,
        };
      });
    },
    fieldSave(fieldId) {
      if (this.currentField === fieldId) {
        this.currentField = null;
      }
    },
    onRemovePolygon(fieldId) {
      this.newFields = this.newFields.map((field) => {
        if (field.id !== fieldId) {
          return field;
        }
        return {
          ...field,
          fieldContour: null,
        };
      });
    },
    fieldDiscard(fieldId) {
      if (this.currentField === fieldId) {
        this.currentField = null;
      }
      this.newFields = this.newFields
        .filter((field) => field.id !== fieldId)
        .map((field, index) => ({
          ...field,
          placeholderName: this.$t('Feld {number}', { number: index + 1 }),
        }));
    },
    fieldHeadline(field) {
      if (typeof field.name !== 'string' || field.name.length === 0) {
        return field.placeholderName;
      }
      return field.name;
    },
    updateFormFieldCollapsed(field, collapsed) {
      if (!collapsed) {
        this.currentField = field.id;
      } else if (collapsed && this.currentField === field.id) {
        this.currentField = null;
      }
    },
    cropValueForDropdown(field) {
      if (field.cropId == null || this.crops[field.cropId] == null) {
        return { id: null, name: '' };
      }
      return { id: field.cropId, name: this.crops[field.cropId].name };
    },
    copyCropToAllFields(field) {
      this.newFields = this.newFields.map((currentField) => ({
        ...currentField,
        cropId: field.cropId,
      }));
    },
    mapOk() {
      this.showForm = true;
      if (this.newFields.length > 0) {
        this.currentField = this.newFields[0].id;
      }
      setTimeout(() => {
        this.$refs.map.centerOnFields(this.$refs.map.editableFields);
      }, 750);
    },
    resetDirtyFormValues() {
      this.currentField = null;
      this.missingCompany = false;
      this.missingName = null;
      this.uniqueNameViolation = false;
      this.errorMessage = '';
    },
    resetNameInput() {
      this.missingName = null;
      this.uniqueNameViolation = false;
      this.errorMessage = '';
    },
  },
};
</script>

<style lang="css" scoped>
.modal-insert-fields__map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(229, 227, 223);
}

.modal-insert-fields__button-copy-crop {
  float: right;
  margin-top: calc(-1 * var(--spacer_2));
  margin-bottom: var(--spacer_3);
  padding: 0;
  border-color: transparent;
  text-decoration: underline;
}

.modal-insert-fields__button-copy-crop:not(:disabled):not(.disabled):active:focus,
.modal-insert-fields__button-copy-crop:active,
.modal-insert-fields__button-copy-crop:focus,
.modal-insert-fields__button-copy-crop:hover {
  box-shadow: none;
  border-color: transparent;
  background: transparent;
  text-decoration: none;
  color: var(--primary);
}

.modal-insert-fields__button-copy-crop:not(:disabled):not(.disabled):active:focus,
.modal-insert-fields__button-copy-crop:active {
  text-decoration: underline;
}
</style>
