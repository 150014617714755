<template>
  <Widget v-if="cropRotationVisible">
    <template #title>
      <h3 class="widget-crop-rotation__title">
        {{ $t('Fruchtfolge') }}
        <FontAwesomeIcon
          v-if="cropRotationEnabled && reloading"
          class="widget-crop-rotation__title-icon"
          icon="circle-notch"
          spin
        />
      </h3>
    </template>
    <template #default>
      <Table
        fixed
        headerless
        class="widget-crop-rotation__table"
        :class="{ 'widget-crop-rotation__table--blurred': !cropRotationEnabled }"
        :fields="tableFields"
        :items="tableItems"
        :busy="fetching"
      >
        <template #empty>
          {{ $t('Noch keine Fruchtfolge vorhanden.') }}
        </template>
        <template #cell(crops)="data">
          <Table fixed headerless borderless class="m-0" :fields="tableCropsFields" :items="data.item.crops">
            <template #empty>
              <span
                v-b-tooltip.hover.d500="{
                  title: `${data.item.fullFieldName} (${$t('Keine Kultur')})`,
                }"
              >
                {{ $t('Keine Kultur') }}
              </span>
              <FieldStatusIcon class="ml-2 float-right" :status="data.item.status" />
            </template>
            <template #cell(name)="{ item, value }">
              <span
                v-b-tooltip.hover.d500="{
                  title: `${item.fullFieldName} (${value})`,
                }"
              >
                {{ value }}
              </span>
              <FieldStatusIcon class="ml-2 float-right" :status="item.status" />
            </template>
          </Table>
        </template>
      </Table>
      <FeatureNotAvailable v-if="!cropRotationEnabled" :add-link-to-shop="canPurchaseLicenses" />
    </template>
  </Widget>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';
import debounce from 'lodash.debounce';
import numbro from 'numbro';

import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import Table from '@/shared/components/Table.vue';
import Widget from '@/shared/components/Widget.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import { fullFieldName } from '../handsontable/columns/columns';
import FieldStatusIcon from './FieldStatusIcon.vue';

library.add(faCircleNotch);

export default {
  name: 'WidgetCropRotation',
  components: {
    FeatureNotAvailable,
    FieldStatusIcon,
    Widget,
    Table,
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    selectedField: {
      type: Object,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    isSharedField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cropRotation: [],
      fetching: false,
      reloading: false,
      source: null,
    };
  },
  created() {
    this.source = axios.CancelToken.source();
    this.setCropRotation();
    this.reload = debounce(() => this.setCropRotation(true), 300);
  },
  deactivated() {
    this.source.cancel();
  },
  computed: {
    cropRotationVisible() {
      if (this.companyId == null) {
        return false;
      }
      return this.$store.getters.companyFeatureVisible(
        this.companyId,
        availableFeatures.FEATURE_FIELD_DETAIL_CROP_ROTATION,
      );
    },
    cropRotationEnabled() {
      if (this.companyId == null) {
        return false;
      }
      return this.$store.getters.companyFeatureEnabled(
        this.companyId,
        availableFeatures.FEATURE_FIELD_DETAIL_CROP_ROTATION,
      );
    },
    canPurchaseLicenses() {
      if (this.companyId == null) {
        return false;
      }
      return this.$store.getters.companyFeatureEnabled(this.companyId, availableFeatures.CAN_PURCHASE_LICENSE);
    },
    tableFields() {
      return [
        {
          key: 'year',
          width: '85px',
          tdClass: (value, key, item) => {
            const classes = [];
            if (item.current) {
              classes.push('widget-crop-rotation__td-current-year');
            }
            if (item.currentField) {
              classes.push('widget-crop-rotation__td-current-field');
            }
            return classes;
          },
        },
        {
          key: 'crops',
          tdClass: (value, key, item) => {
            const classes = ['p-0'];
            if (item.current) {
              classes.push('widget-crop-rotation__td-current-year');
            }
            return classes;
          },
        },
      ];
    },
    tableItems() {
      return this.cropRotation;
    },
    tableCropsFields() {
      const tableCropsFields = [
        {
          key: 'name',
          tdClass: (value, key, item) => {
            const classes = [];
            if (item.noCrop) {
              classes.push('font-italic');
            }
            if (item.currentField) {
              classes.push('widget-crop-rotation__td-current-field');
            }
            return classes;
          },
        },
      ];
      if (this.expanded && this.showFieldNames) {
        tableCropsFields.push(
          ...[
            {
              key: 'fieldSize',
              width: '40px',
              tdClass: (value, key, item) => {
                const classes = ['pl-0', 'pr-0', 'text-right'];
                if (item.currentField) {
                  classes.push('widget-crop-rotation__td-current-field');
                }
                return classes;
              },
            },
            {
              key: 'fieldSizeLabel',
              width: '30px',
              tdClass: (value, key, item) => {
                const classes = ['pl-1', 'pr-0'];
                if (item.currentField) {
                  classes.push('widget-crop-rotation__td-current-field');
                }
                return classes;
              },
            },
          ],
        );
      }
      return tableCropsFields;
    },
    fieldId() {
      if (this.selectedField == null) {
        return null;
      }
      return this.selectedField.id;
    },
    companyId() {
      if (!(this.$store.state.auth.currentCompanies && this.$store.state.auth.currentCompanies[0].id)) {
        return null;
      }
      return this.$store.state.auth.currentCompanies[0].id;
    },
    selectedFieldFullFieldName() {
      if (this.selectedField == null) {
        return null;
      }
      return fullFieldName.data(this.selectedField);
    },
    showFieldNames() {
      return this.cropRotation.some((rotation) => rotation.fullFieldName !== this.selectedFieldFullFieldName);
    },
  },
  methods: {
    async setCropRotation(reload = false) {
      if (this.fieldId == null || !this.cropRotationVisible) {
        this.reloading = false;
        this.fetching = false;
        return;
      }
      if (!this.cropRotationEnabled) {
        this.cropRotation = JSON.parse(
          '[{"year":"2022","fullFieldName":"HAUSACKER","status":"planned","fieldSize":"2,14","current":false,"currentField":false,"crops":[{"name":"Wintergerste","fullFieldName":"HAUSACKER","status":"planned","fieldSize":"2,14","current":false,"currentField":false,"fieldSizeLabel":"ha"}]},{"year":"2021","fullFieldName":"HAUSACKER","status":"active","fieldSize":"2,14","current":true,"currentField":true,"crops":[{"name":"Winterraps","fullFieldName":"HAUSACKER","status":"active","fieldSize":"2,14","current":true,"currentField":true,"fieldSizeLabel":"ha"}]},{"year":"2020","fullFieldName":"HAUSACKER","status":"completed","fieldSize":"2,14","current":false,"currentField":false,"crops":[{"name":"Wintertriticale","fullFieldName":"HAUSACKER","status":"completed","fieldSize":"2,14","current":false,"currentField":false,"fieldSizeLabel":"ha"}]},{"year":"2019","fullFieldName":"HAUSACKER","status":"completed","fieldSize":"2,14","current":false,"currentField":false,"crops":[{"name":"Silomais","fullFieldName":"HAUSACKER","status":"completed","fieldSize":"2,14","current":false,"currentField":false,"fieldSizeLabel":"ha"}]}]',
        );
        this.reloading = false;
        this.fetching = false;
        return;
      }
      if (reload) {
        this.reloading = true;
      } else {
        this.cropRotation = [];
        this.fetching = true;
      }

      const sharedFieldQueryParams = `?permissionMode=fieldSharing&fieldSharingCompanyId=${this.$store.state.auth.currentCompanies[0].id}`;
      const response = await axios(
        `/admin/rest/field/crop_rotation_for_field/${this.fieldId}${this.isSharedField ? sharedFieldQueryParams : ''}`,
        {
          cancelToken: this.source.token,
        },
      );
      const cropRotationByYear = response.data.data.reduce((accumulator, rotation) => {
        if (rotation == null) {
          return accumulator;
        }
        const year = rotation.processOrder.name;
        let fieldSize = 0;
        if (typeof rotation.fieldSize === 'number') {
          ({ fieldSize } = rotation);
        }
        if (accumulator[year] == null) {
          // eslint-disable-next-line no-param-reassign
          accumulator[year] = {
            year,
            fullFieldName: fullFieldName.data(rotation),
            status: rotation.status,
            fieldSize: numbro(fieldSize).format(),
            current: year === this.$store.state.auth.currentProcessOrderName,
            currentField: rotation.id === this.fieldId,
            crops: [],
          };
        }
        // eslint-disable-next-line no-param-reassign
        accumulator[year].currentField = accumulator[year].currentField || rotation.id === this.fieldId;
        if (rotation.crop == null || typeof rotation.crop.name !== 'string') {
          return accumulator;
        }
        let { name } = rotation.crop;
        if (rotation.variety != null && typeof rotation.variety.name === 'string') {
          name = `${name} (${rotation.variety.name})`;
        }
        accumulator[year].crops.push({
          name,
          fullFieldName: fullFieldName.data(rotation),
          status: rotation.status,
          fieldSize: numbro(fieldSize).format(),
          current: year === this.$store.state.auth.currentProcessOrderName,
          currentField: rotation.id === this.fieldId,
          fieldSizeLabel: this.$t('ha'),
        });
        return accumulator;
      }, {});
      this.cropRotation = Object.values(cropRotationByYear)
        .map((cropYear) => {
          if (cropYear.crops.length > 0) {
            return cropYear;
          }
          return {
            ...cropYear,
            crops: [
              {
                name: this.$t('Keine Kultur'),
                fullFieldName: cropYear.fullFieldName,
                status: cropYear.status,
                fieldSize: cropYear.fieldSize,
                current: cropYear.current,
                currentField: cropYear.currentField,
                noCrop: true,
                fieldSizeLabel: this.$t('ha'),
              },
            ],
          };
        })
        .sort((a, b) => b.year - a.year);
      this.fetching = false;
      this.reloading = false;
    },
  },
  watch: {
    fieldId() {
      this.setCropRotation();
    },
    selectedField() {
      this.reloading = true;
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-crop-rotation__title {
  margin-bottom: 0;
  font-size: 1.14rem;
  font-weight: 600;
}

.widget-crop-rotation__title-icon {
  margin-left: var(--spacer_2);
  color: var(--gray_600);
}

.widget-crop-rotation__table--blurred {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}

.table .widget-crop-rotation__td-current-year .table::v-deep td,
.table::v-deep .widget-crop-rotation__td-current-year {
  background-color: var(--gray_300);
}

.table .widget-crop-rotation__td-current-field,
.table::v-deep .widget-crop-rotation__td-current-field {
  font-weight: 600;
}
</style>
