import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';

import { ImportActivitiesStateWithoutModules } from './types';

export default function initialState(): ImportActivitiesStateWithoutModules {
  return {
    ...initialStateSubscribable(),
    tableDataMetadata: {},
    hot: null,
    userColumnSettings: {},
    activityImportMapDataRequestParameters: {
      source: 'CnhDownload',
      objectIds: undefined,
    },
  };
}
