import { Module } from 'vuex';

import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { RootState } from '@/store/types';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import type { DaturaHistoryState } from './types';

export function initialState(): DaturaHistoryState {
  return {
    ...initialStateSubscribable(),
    tableDataMetadata: {},
    hot: null,
    userColumnSettings: {},
  };
}

const vuexModule: Module<DaturaHistoryState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};

export default vuexModule;
