import { ColHeadersHook } from './types';

function colHeadersHooksFactory(hooks: ColHeadersHook[]) {
  return function colHeaderHookProcessor(index: number): string {
    let colHeader: string | undefined;
    hooks.some((hook) => {
      colHeader = hook(index);
      return colHeader !== undefined;
    });

    return colHeader ?? '';
  };
}

export default {
  colHeaders: { colHeadersHooksFactory },
};
