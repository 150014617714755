import Vue from 'vue';

import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';

import {
  crops,
  farmName,
  farmNumber,
  fieldGroupNumber,
  fieldName,
  fieldNumber,
  fieldSize,
  lastUpdate,
  partnerNumber,
  varieties,
} from './columns';

export default [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...partnerNumber,
    sortOrder: 'asc',
  },
  farmNumber,
  farmName,
  fieldGroupNumber,
  fieldNumber,
  fieldName,
  {
    ...fieldSize,
    header: {
      title: Vue.i18n.translate('Größe'),
      description: Vue.i18n.translate('ha'),
    },
  },
  { ...crops, width: 170 },
  { ...varieties, width: 170 },
  lastUpdate,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];
