
import { PropType, defineComponent } from 'vue';

import displayValue from './displayValue';
import { Zone } from './types';

export default defineComponent({
  name: 'ZoneBadge',
  props: {
    zone: {
      type: Object as PropType<Zone>,
      required: true,
    },
  },
  computed: {
    isCloudy(): boolean {
      if (typeof this.zone.name === 'string') {
        const isCloudy = this.zone.name.includes('cloud') || this.zone.name.includes('snow');
        return isCloudy;
      }
      return false;
    },
  },
  methods: {
    displayValue,
  },
});
