import Vue from 'vue';

import { crop, name } from '@/fields/handsontable/columns/columns';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';

import { amount, creationDate, processedArea, userComment } from './columns';

export const materialKey = 'materialId';
export const fieldSizeKey = 'fieldSize';
export const fieldIdKey = 'fieldId';

export const tableImportTotalYieldsFieldMapping = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...creationDate,
    lockedVisibility: true,
  },
  {
    ...name,
    key: 'combinedName',
    title: Vue.i18n.translate('FSNr - Schlag'),
    readOnly: true,
    lockedVisibility: true,
  },
  {
    ...processedArea,
    key: fieldSizeKey,
    className: 'border-right',
    required: false,
    lockedVisibility: true,
  },
  {
    ...crop,
    key: materialKey,
    className: 'border-right',
    lockedVisibility: true,
    width: 220,
  },
  {
    ...amount,
    header: {
      title: Vue.i18n.translate('Reine Kartoffel (t)'),
      description: Vue.i18n.translate('t'),
    },
    width: 200,
    lockedVisibility: true,
  },
  {
    ...userComment,
    lockedVisibility: true,
  },
];

export default tableImportTotalYieldsFieldMapping;
