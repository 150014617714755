
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import numbro from '@/initNumbro';
import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';
import FormSlider from '@/shared/components/form/FormSlider.vue';

export default defineComponent({
  name: 'SprayControlsContainer',
  components: { MultiRootWrapper, FormSlider },
  data(): { numbro: typeof numbro } {
    return { numbro };
  },
  computed: {
    reduction: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps.spraying.calculation.reduction;
      },
      set(reduction: number) {
        this.$store.state.precisionFarming.applicationMaps.spraying.calculation.reduction = reduction;
      },
    },
    sprayMix: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps.spraying.calculation.sprayMix;
      },
      set(sprayMix: number) {
        this.$store.state.precisionFarming.applicationMaps.spraying.calculation.sprayMix = sprayMix;
      },
    },
    ...mapGetters('precisionFarming/applicationMaps/spraying', ['isOverwritten']),
  },
  methods: {
    reductionFormatter(value: number): string {
      return numbro(value).format({
        mantissa: 0,
        output: 'percent',
        prefix: value ? '-' : '',
      });
    },
    reductionParser(value: string): number {
      const digits = /\d+/;
      const match = value.match(digits)?.shift();
      return Number(match) / 100;
    },
  },
});
