import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { DataEntry, SubscribableDataState } from '../types';
import actions from './actions';
import getters from './getters';
import initialState from './initialState';
import mutations from './mutations';
import { FetchAll, FetchByIds, RequiredFeatures } from './types';

export default function subscribableData<Entry extends DataEntry, Resolve>(
  fetchAll: FetchAll<Entry, Resolve>,
  fetchByIds: FetchByIds<Entry>,
  requireFeatures: RequiredFeatures = [],
): Module<SubscribableDataState<Entry>, RootState> {
  return {
    namespaced: true,
    state: initialState<Entry>(),
    mutations,
    actions: actions(fetchAll, fetchByIds, requireFeatures),
    getters,
  };
}
