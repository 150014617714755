var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BFormCheckbox',{staticClass:"form-checkbox",class:[
    `form-checkbox--${this.size}`,
    {
      'form-checkbox--in-group': _vm.isInGroup,
      'form-checkbox--slim': _vm.slim,
      'form-checkbox--switch': _vm.isSwitch,
      'form-checkbox--empty': _vm.$slots.default == null,
    },
  ],attrs:{"checked":_vm.isInGroup ? null : _vm.checked,"disabled":_vm.disabled,"value":_vm.value},on:{"change":_vm.change,"input":_vm.input}},[_c('span',{staticClass:"form-checkbox__icon-wrapper",class:[
      `form-checkbox__icon-wrapper--${_vm.size}`,
      { 'form-checkbox__icon-wrapper--switch': _vm.isSwitch },
      { 'form-checkbox__icon-wrapper--checked': _vm.checkedComputed },
      { 'form-checkbox__icon-wrapper--empty': _vm.$slots.default == null },
    ]},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.icon,"flip":_vm.isSwitch && !_vm.checkedComputed ? 'horizontal' : null}})],1),_c('span',{staticClass:"form-checkbox__title",class:`form-checkbox__title--${_vm.size}`},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }