<template>
  <TableBase
    ref="table"
    disable-read-only-bg
    read-only
    :id="id"
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @reload="loadData"
  >
  </TableBase>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import { ERROR_UPDATING } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import loadRestTableData from '@/shared/handsontable/mixins/containers/featureLoadRestTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import columnsTableFieldsRestore from '../handsontable/columns/tableFieldsRestore';

const dataToBase64 = (data) => (data != null ? btoa(JSON.stringify(data)) : '');

export default {
  name: 'TableFieldsRestoreContainer',
  components: {
    TableBase,
  },
  props: {
    filterString: {
      type: String,
      default: '',
    },
    selectedRows: {
      type: Array,
      default: null,
    },
    contractId: {
      type: String,
      required: true,
    },
  },
  mixins: [loadRestTableData, tableContainerBase],
  data() {
    return {
      columnDefinition: columnsTableFieldsRestore,
    };
  },
  computed: {
    id() {
      return ['hot-fields-restore', ...this.$store.state.auth.currentCompanies.map((company) => company.id)].join('-');
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const todayMinusNinetyDays = moment().startOf('day').subtract({ days: 90 });
      const filterCurrent = [
        'AND',
        ['contractCompany.contractId', '=', this.contractId],
        ['deleted', '>=', Math.round(todayMinusNinetyDays / 1000)],
      ];
      const resolve = ['contractCompany'];
      this.loadTableData(
        `/admin/rest/contractField?showDeleted=1&disablePagination=1&filter=${dataToBase64(
          filterCurrent,
        )}&resolve=${dataToBase64(resolve)}`,
      );
    },
    async restore() {
      const selectedRows = [...this.selectedRows];
      try {
        await Promise.all(
          [...new Array(Math.ceil(selectedRows.length / 50)).keys()].map((index) => {
            const start = index * 50;
            const end = (index + 1) * 50;
            const filterCurrent = ['id', 'IN', selectedRows.slice(start, end)];
            return axios.put(`/admin/rest/contractField/undelete/?filter=${dataToBase64(filterCurrent)}`);
          }),
        );
        await this.$store.dispatch('managedContracts/fields/reset');
        await this.$store.dispatch('managedContracts/fields/subscribe');
      } catch (error) {
        console.error(error);
        this.addErrorsAfterRestore(selectedRows);
        return false;
      }
      return true;
    },
    addErrorsAfterRestore(selectedRows) {
      this.tableErrors = [
        ...this.tableErrors,
        ...selectedRows.map((guid) => ({
          type: ERROR_UPDATING,
          guid,
          key: null,
          errorUserMessage: this.$t('Das Feld konnte nicht wieder hergestellt werden.'),
        })),
      ];
    },
  },
};
</script>
