
import { PropType, defineComponent } from 'vue';

import ActivitySummary from '@/activities/components/ActivitySummary.vue';
import { ActivityWithDisplayNames } from '@/activities/types';
import { ActivityType } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

export default defineComponent({
  name: 'ActivityStatusIndicator',
  components: {
    ActivitySummary,
  },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    activities: {
      type: Array as PropType<ActivityWithDisplayNames[]>,
      required: true,
    },
    activityTypes: {
      type: Object as PropType<Data<ActivityType>>,
      required: true,
    },
  },
  computed: {
    sectionHeader(): string {
      return `${this.activities.length} ${this.title}`;
    },
  },
});
