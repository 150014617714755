import { getCurrentInstance, onBeforeMount } from 'vue';

export default function useToast() {
  let app: { proxy: Vue } | null;

  onBeforeMount(() => {
    app = getCurrentInstance();
  });

  function toast(message: string, variant: string = 'danger') {
    if (app) {
      app.proxy.$bvToast.toast(message, { title: 'Warning', variant });
    }
  }

  return {
    toast,
  };
}
