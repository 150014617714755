import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';

import { FarmdokColumnSettings } from '@/shared/handsontable/rework/types';

import { ColHeadersHook } from '../../types';

library.add(faSquare, faCheckSquare);

export default function colHeadersSelectFactory(
  tableColumns: FarmdokColumnSettings[],
  allRowsSelected: () => boolean,
): ColHeadersHook {
  return function colHeaderSelect(index: number): string | undefined {
    const column = tableColumns[index];
    if (column == null || column.type === undefined || !['checkbox', 'farmdok.checkbox.optional'].includes(column.type))
      return undefined;

    const selected = allRowsSelected();
    const checkbox = createCheckboxElement(selected);

    return checkbox.outerHTML;
  };
}

function createCheckboxElement(selected: boolean) {
  const wrapper = document.createElement('div');
  let [checkbox] = icon({ prefix: 'far', iconName: 'square' }).node;
  if (selected) {
    // noinspection JSValidateTypes
    [checkbox] = icon({ prefix: 'far', iconName: 'check-square' }).node;
    wrapper.classList.add('text-black');
  } else {
    wrapper.classList.add('text-medium');
  }
  checkbox.classList.add('checkbox-icon');
  wrapper.classList.add('header-checkbox--select-rows');

  wrapper.appendChild(checkbox);

  return wrapper;
}
