import { ActivityProduct, ProductCategory, StoragePlace, StoragePlaceProductType } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import Vue from 'vue';
import { GetterTree } from 'vuex';

import isMulticompany from '@/auth/isMulticompany';
import { Company } from '@/auth/store/types';
import groupProductsByCategory from '@/products/store/getterUtils/groupProductsByCategory';
import { sortProducts } from '@/products/store/getterUtils/sortProducts';
import {
  ActivityType,
  Field,
  MineralFertilizer,
  ProcessOrder,
  Product,
  ProductCategoryType,
  ProductVisibility,
} from '@/shared/api/rest/models';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { DropdownItems } from '@/shared/handsontable/types';
import { Data } from '@/shared/mixins/store/types';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { RootState } from '@/store/types';

import Dropdown from '../entities/Dropdown';
import DropdownService from '../services/Dropdown';
import {
  filterCompatibleProducOfCategory,
  filterExpiredHerbizideProducts,
  filterFavoriteProducts,
  filterMulticompany,
  filterOtherProducts,
  filterProductVisibilities,
  filterSgdVersion,
  filterSiblingDuplicates,
  findFamilyOfProduct,
  isGlobalProduct,
} from './compatibleProductsFilter';
import { ProductsState } from './types';

export type Getters = {
  crops: Data<Product>;
  mineralFertilizers: Data<MineralFertilizer>;
  allProducts: Product[];
  findProductById: (id: string) => Product | undefined;
  getProductById: (id: string) => Product;
  findProductOfCompany: (productId: string, companyId: string) => Product | undefined;
  allProductsOfCategories: (categories: ProductCategoryType[]) => Product[];
  compatibleProducts: (
    activityType: ActivityType,
    companies: Company[],
    processOrders: ProcessOrder[],
    productVisibilities: ProductVisibility[],
    storagePlaces: StoragePlace[],
    activityTimeStart?: number[],
  ) => Product[];
  compatibleProductsOfCategories: (
    activityType: ActivityType,
    companies: Company[],
    processOrders: ProcessOrder[],
    productVisibilities: ProductVisibility[],
    productCategories: ProductCategoryType[],
    storagePlaces: StoragePlace[],
  ) => Product[];
  compatibleProductsToDropdownItems: (
    activityType: ActivityType,
    companies: Company[],
    processOrders: ProcessOrder[],
    productVisibilities: ProductVisibility[],
    productCategories: ProductCategory[],
    fields: Field[],
    storagePlaces: StoragePlace[],
    activityTimeStart?: number[],
  ) => DropdownItems[];
  favoriteProducts: (
    activityType: ActivityType,
    companies: Company[],
    processOrders: ProcessOrder[],
    productVisibilities: ProductVisibility[],
    fields: Field[],
    storagePlaces: StoragePlace[],
  ) => Product[];
  primaryProducts: (
    activityType: ActivityType,
    companies: Company[],
    processOrders: ProcessOrder[],
    productVisibilities: ProductVisibility[],
    storagePlaces: StoragePlace[],
  ) => Product[];
  primaryProductsToDropdownItems: (
    activityType: ActivityType,
    companies: Company[],
    processOrders: ProcessOrder[],
    productVisibilities: ProductVisibility[],
    productCategories: ProductCategory[],
    storagePlaces: StoragePlace[],
  ) => DropdownItemsPerCategory;
  dropdownItem: (productId: string, storagePlaceId?: string | null) => DropdownItem | null;
  storageProducts: (compatibleProducts: Product[], storagePlaces: StoragePlace[]) => Product[];
  compatibleHerbizideProducts: (compatibleProducts: Product[]) => Product[];
  isCompanySpecificProduct: (product: Product) => boolean;
  getProductOrSibling: (productId: string, companyId: string) => Product;
  getActivityProductWithProductOrSibling: (
    activityProduct: Product,
    productId: string,
    companyId: string,
  ) => ActivityProduct;
};

const moduleGetters: GetterTree<ProductsState, RootState> = {
  crops: (state) => state.crops.data, // TODO remove unnecessary getters
  mineralFertilizers: (state) => state.mineralFertilizers.data, // TODO remove unnecessary getters
  loading: (state, getters): boolean => {
    const loading =
      getters['companyFertilizers/loading'] ||
      getters['crops/loading'] ||
      getters['harvests/loading'] ||
      getters['herbizides/loading'] ||
      getters['mineralFertilizers/loading'] ||
      getters['miscellaneous/loading'] ||
      getters['otherProductsAndFertilizers/loading'] ||
      getters['secondaryFertilizers/loading'] ||
      getters['seeds/loading'];

    return loading;
  },

  allProducts: (state): Product[] => {
    const allProducts = [
      ...Object.values(state.companyFertilizers.data),
      ...Object.values(state.crops.data),
      ...Object.values(state.harvests.data),
      ...Object.values(state.herbizides.data),
      ...Object.values(state.mineralFertilizers.data),
      ...Object.values(state.miscellaneous.data),
      ...Object.values(state.otherProductsAndFertilizers.data),
      ...Object.values(state.secondaryFertilizers.data),
      ...Object.values(state.seeds.data),
    ] as Product[];

    return allProducts;
  },
  findProductById:
    (state: ProductsState) =>
    (id: string): Product | undefined => {
      if (state.companyFertilizers.data[id]) return state.companyFertilizers.data[id];
      if (state.crops.data[id]) return state.crops.data[id];
      if (state.harvests.data[id]) return state.harvests.data[id];
      if (state.herbizides.data[id]) return state.herbizides.data[id];
      if (state.mineralFertilizers.data[id]) return state.mineralFertilizers.data[id];
      if (state.miscellaneous.data[id]) return state.miscellaneous.data[id];
      if (state.otherProductsAndFertilizers.data[id]) return state.otherProductsAndFertilizers.data[id];
      if (state.secondaryFertilizers.data[id]) return state.secondaryFertilizers.data[id];
      if (state.seeds.data[id]) return state.seeds.data[id];
      return undefined;
    },
  getProductById:
    (state: ProductsState, getters: Getters) =>
    (id: string): Product => {
      const product = getters.findProductById(id);
      if (!product) throw new Error(`Product with id ${id} not found`);
      return product;
    },
  findProductOfCompany:
    (state: ProductsState, getters: Getters) =>
    (productId: string, companyId: string): Product | undefined => {
      const product = getters.findProductById(productId);
      if (!product || isGlobalProduct(product)) return undefined;
      if (product.companyId === companyId) return product;

      const family = findFamilyOfProduct(product, getters.allProducts);
      const productOfCompany = family.find((candidate) => candidate.companyId === companyId);

      return productOfCompany;
    },
  allProductsOfCategories:
    (state) =>
    (categories: ProductCategoryType[]): Product[] => {
      const productsByCategory: Product[][] = categories.map((category) => {
        switch (category) {
          case ProductCategoryType.MINERAL_FERTILIZER: {
            return Object.values(state.mineralFertilizers.data);
          }
          case ProductCategoryType.COMPANY_FERTILIZER: {
            return Object.values(state.companyFertilizers.data);
          }
          case ProductCategoryType.SECONDARY_FERTILIZER: {
            return Object.values(state.secondaryFertilizers.data);
          }
          case ProductCategoryType.HERBIZIDE: {
            return Object.values(state.herbizides.data);
          }
          case ProductCategoryType.CROP: {
            return Object.values(state.crops.data);
          }
          case ProductCategoryType.MISCELLANEOUS: {
            return Object.values(state.miscellaneous.data);
          }
          case ProductCategoryType.HARVEST: {
            return Object.values(state.harvests.data);
          }
          case ProductCategoryType.SEED: {
            return Object.values(state.seeds.data);
          }
          case ProductCategoryType.OTHER_AUXILARY_PRODUCTS_AND_FERTILIZERS: {
            return Object.values(state.otherProductsAndFertilizers.data);
          }
          default: {
            throw new Error(`Unknown product category: ${category}`);
          }
        }
      });

      const allProductsOfCategories = productsByCategory.flat();

      return allProductsOfCategories;
    },
  compatibleProducts:
    (state: ProductsState, getters: Getters, rootState: RootState) =>
    (
      activityType: ActivityType,
      companies: Company[],
      processOrders: ProcessOrder[],
      productVisibilities: ProductVisibility[],
      storagePlaces: StoragePlace[],
      activityTimeStarts?: number[],
    ): Product[] => {
      let compatibleProducts = getters
        .allProductsOfCategories(activityType.usableWorkingMeanCategories)
        .filter((product) => filterSgdVersion(product, processOrders))
        .filter((product) =>
          activityTimeStarts
            ? filterExpiredHerbizideProducts(product, activityTimeStarts, rootState.productCategories.data)
            : true,
        );

      if (isMulticompany(companies)) {
        compatibleProducts = compatibleProducts.filter((product) => filterMulticompany(product, companies));
      }
      if (!isMulticompany(companies) && storagePlaces.length > 0) {
        const storageProducts = getters.storageProducts(compatibleProducts, storagePlaces);
        if (storageProducts) {
          compatibleProducts = [...compatibleProducts, ...storageProducts];
        }
      }

      const compatibleHerbizideProducts = getters.compatibleHerbizideProducts(compatibleProducts);
      compatibleProducts = compatibleProducts.filter(
        (compatibleProduct) =>
          !compatibleHerbizideProducts.find(
            (compatibleHerbizideProduct) => compatibleHerbizideProduct.id === compatibleProduct.id,
          ),
      );
      compatibleProducts = [...compatibleProducts, ...compatibleHerbizideProducts];

      compatibleProducts = compatibleProducts.filter((product) => {
        const productFamily = findFamilyOfProduct(product, compatibleProducts);
        return filterProductVisibilities(product, productFamily, companies, productVisibilities);
      });

      return sortProducts(compatibleProducts);
    },

  compatibleProductsOfCategories:
    (state: ProductsState, getters: Getters) =>
    (
      activityType: ActivityType,
      companies: Company[],
      processOrders: ProcessOrder[],
      productVisibilities: ProductVisibility[],
      categories: ProductCategoryType[],
      storagePlaces: StoragePlace[],
    ): Product[] => {
      const compatibleProducts = getters.compatibleProducts(
        activityType,
        companies,
        processOrders,
        productVisibilities,
        storagePlaces,
      );
      const productsOfCategoryIds = getters.allProductsOfCategories(categories).map((product) => product.id);
      return compatibleProducts.filter((product) => filterCompatibleProducOfCategory(product, productsOfCategoryIds));
    },

  favoriteProducts:
    (state: ProductsState, getters: Getters) =>
    (
      activityType: ActivityType,
      companies: Company[],
      processOrders: ProcessOrder[],
      productVisibilities: ProductVisibility[],
      fields: Field[],
      storagePlaces: StoragePlace[],
    ): Product[] | null => {
      const activityCategoryType = activityType.type as ProductCategoryType;
      const activityTypeHasFavoriteProducts =
        activityCategoryType === ProductCategoryType.SEED || activityCategoryType === ProductCategoryType.HARVEST;

      if (!fields || fields.length === 0 || !activityTypeHasFavoriteProducts) {
        return null;
      }

      const compatibleProducts = getters.compatibleProductsOfCategories(
        activityType,
        companies,
        processOrders,
        productVisibilities,
        [activityCategoryType],
        storagePlaces,
      );
      const fieldCrops: string[] = fields.map((field) => field.cropId).filter(notNullOrUndefined);
      const favoriteProducts = compatibleProducts.filter((product) => filterFavoriteProducts(product, fieldCrops));
      return sortProducts(favoriteProducts);
    },

  primaryProducts:
    (state: ProductsState, getters: Getters) =>
    (
      activityType: ActivityType,
      companies: Company[],
      processOrders: ProcessOrder[],
      productVisibilities: ProductVisibility[],
      storagePlaces: StoragePlace[],
    ): Product[] | null => {
      const primaryCategoryType = activityType.type as ProductCategoryType;
      if (!primaryCategoryType) {
        return null;
      }
      const compatibleProductsInCategories = getters.compatibleProductsOfCategories(
        activityType,
        companies,
        processOrders,
        productVisibilities,
        [primaryCategoryType],
        storagePlaces,
      );
      return sortProducts(compatibleProductsInCategories);
    },

  primaryProductsToDropdownItems:
    (state: ProductsState, getters: Getters) =>
    (
      activityType: ActivityType,
      companies: Company[],
      processOrders: ProcessOrder[],
      productVisibilities: ProductVisibility[],
      productCategories: ProductCategory[],
      storagePlaces: StoragePlace[],
    ): DropdownItemsPerCategory | null => {
      let primaryProducts = getters.primaryProducts(
        activityType,
        companies,
        processOrders,
        productVisibilities,
        storagePlaces,
      );
      primaryProducts = filterSiblingDuplicates(primaryProducts);

      const primaryCategoryType = activityType.type;
      const primaryCategory = productCategories.find((category) => category.type === primaryCategoryType);
      if (primaryCategoryType && primaryProducts && primaryProducts.length > 0 && primaryCategory) {
        return { name: primaryCategory.name, id: primaryCategory.id, items: primaryProducts };
      }
      return null;
    },

  compatibleProductsToDropdownItems:
    (state: ProductsState, getters: Getters) =>
    (
      activityType: ActivityType,
      companies: Company[],
      processOrders: ProcessOrder[],
      productVisibilities: ProductVisibility[],
      productCategories: ProductCategory[],
      fields: Field[],
      storagePlaces: StoragePlace[],
      activityTimeStarts?: number[],
    ): DropdownItemsPerCategory[] => {
      const dropdownItems = [];
      const compatibleProducts = getters.compatibleProducts(
        activityType,
        companies,
        processOrders,
        productVisibilities,
        storagePlaces,
        activityTimeStarts,
      );
      const products = filterSiblingDuplicates(compatibleProducts);
      const favorites = getters.favoriteProducts(
        activityType,
        companies,
        processOrders,
        productVisibilities,
        fields,
        storagePlaces,
      );
      if (favorites && favorites.length > 0) {
        const favoritesWithoutDuplicates = filterSiblingDuplicates(favorites);
        dropdownItems.push({
          name: Vue.i18n.translate('Favoriten'),
          id: 'favorites',
          items: favoritesWithoutDuplicates,
        });
      }
      const primaryProducts = getters.primaryProductsToDropdownItems(
        activityType,
        companies,
        processOrders,
        productVisibilities,
        productCategories,
        storagePlaces,
      );
      if (primaryProducts) {
        dropdownItems.push(primaryProducts);
      }

      const otherProducts = products.filter((product) => filterOtherProducts(product, primaryProducts?.id));
      if (otherProducts.length > 0) {
        const productsByCategory = groupProductsByCategory(otherProducts, productCategories);
        dropdownItems.push(...productsByCategory);
      }
      return dropdownItems;
    },

  dropdownItem:
    (state: ProductsState, getters: Getters, rootState: RootState) =>
    (productId: string, storagePlaceId: string | null): DropdownItem | null => {
      const product = getters.findProductById(productId);
      if (!product) return null;
      const storagePlace = storagePlaceId ? rootState.storagePlaces.data[storagePlaceId] : undefined;

      const dropdown = DropdownService.getDropdownItem(product, storagePlace);
      return dropdown;
    },

  storageProducts:
    () =>
    (compatibleProducts: Product[], storagePlaces: StoragePlace[] | null): Product[] => {
      if (!storagePlaces || storagePlaces.length === 0 || compatibleProducts.length === 0) {
        return [];
      }
      const storageProducts: Product[] = [];
      storagePlaces.forEach((storagePlace) => {
        if (storagePlace.deleted || !storagePlace.products) {
          return;
        }
        storagePlace.products.forEach((productRef) => {
          if (productRef.type === StoragePlaceProductType.MutuallyDependent) {
            return;
          }

          const product = compatibleProducts.find((p) => p.id === productRef.productId);
          if (!product) {
            return;
          }
          const storageProduct: Product = {
            ...product,
            id: `${productRef.productId}_${storagePlace.id}`,
            name: `${product?.name} (${storagePlace.name})`,
          };
          storageProducts.push(storageProduct);
        });
      });

      return storageProducts;
    },

  compatibleHerbizideProducts:
    () =>
    (compatibleProducts: Product[]): Product[] => {
      if (compatibleProducts.length === 0) {
        return [];
      }

      const compatibleHerbizideProducts: Product[] = [];

      compatibleProducts.forEach((product) => {
        if (!product.pesticide) {
          return;
        }

        const { name } = new Dropdown(product).toData();

        const herbizideProduct: Product = {
          ...product,
          id: product.id,
          name,
        };
        compatibleHerbizideProducts.push(herbizideProduct);
      });

      return compatibleHerbizideProducts;
    },
  isCompanySpecificProduct:
    () =>
    (product: Product): boolean =>
      product.companyId !== null,
  getProductOrSibling: (state: ProductsState, getters: Getters) => (productId: string, companyId: string) => {
    const product = getters.findProductById(productId);

    if (!product) throw new Error(`Product with id ${productId} not found`);

    if (!getters.isCompanySpecificProduct(product)) return product;

    const productOfCompany = getters.findProductOfCompany(productId, companyId);
    if (!productOfCompany) throw new Error(`Product of company with id ${productId} not found`);
    return productOfCompany;
  },
  getActivityProductWithProductOrSibling:
    (state: ProductsState, getters: Getters) =>
    (activityProduct: ActivityProduct, productId: string, companyId: string): ActivityProduct => {
      const product = getters.getProductOrSibling(productId, companyId);
      const updatedActivityProduct = _cloneDeep(activityProduct);
      updatedActivityProduct.productId = product.id;

      return updatedActivityProduct;
    },
};

export default moduleGetters;
