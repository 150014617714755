import { onMounted, onUnmounted, ref } from 'vue';

export default function useBrowserZoom() {
  const browserZoom = ref(window.devicePixelRatio <= 1 ? Math.round(window.devicePixelRatio * 100) : 100);

  onMounted(() => {
    window.addEventListener('resize', setBrowserZoom);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', setBrowserZoom);
  });

  function setBrowserZoom() {
    browserZoom.value = window.devicePixelRatio <= 1 ? Math.round(window.devicePixelRatio * 100) : 100;
  }

  return { browserZoom };
}
