import Handsontable from 'handsontable';

import range from '@/shared/modules/range';

import { ColumnSettingsFlattenedSubtable } from '../../types';
import { getFirstColumnOfSubtable, getLastColumnOfSubtable } from '../../utils/getColumnsOfSubtable';

/**
 * checks if movedColumns contains a subtable column and moves all columns of the subtable together
 */
export default function moveAllColumnsOfSubtableFactory(hot: Handsontable) {
  function moveAllColumnsOfSubtable(
    movedColumns: number[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    finalIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropIndex: number | void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    movePossible: boolean,
  ): boolean {
    replaceMovesOfSubtableColumnsByFirstSubtableColumn(movedColumns, hot);
    removeDuplicateMoves(movedColumns);
    replaceMovesOfFirstSubtableColumnByMovesOfAllSubtableColumns(movedColumns, hot);
    // recalculateFinalIndex(movedColumns, finalIndex, dropIndex); // TODO this must be done to fix moving of subtable columns to the right but is currently not supported by handsontable as it is only allowed to mutate the first parameter of a hook

    return true;
  }

  return moveAllColumnsOfSubtable;
}

export function replaceMovesOfSubtableColumnsByFirstSubtableColumn(movedColumns: number[], hot: Handsontable): void {
  const columns = getColumns(hot);

  const copyOfMovedColumns = [...movedColumns];
  copyOfMovedColumns.forEach((visualColumn) => {
    const physicalColumn = hot.toPhysicalColumn(visualColumn);
    const column = columns[physicalColumn];

    if (column.isSubtableColumn && !column.isFirstSubtableColumn) {
      const firstColumnOfSubtable = getFirstColumnOfSubtable(columns, physicalColumn);
      const firstVisualColumnOfSubtable = hot.toVisualColumn(firstColumnOfSubtable);

      const originalMovedColumnIndex = movedColumns.indexOf(visualColumn);

      replaceMoveColumnEntry(movedColumns, originalMovedColumnIndex, firstVisualColumnOfSubtable);
    }
  });
}

export function removeDuplicateMoves(movedColumns: number[]): void {
  for (let i = movedColumns.length; i >= 0; i -= 1) {
    const movedColumnsBeforeCurrent = movedColumns.slice(0, i);
    if (movedColumnsBeforeCurrent.includes(movedColumns[i])) {
      movedColumns.splice(i, 1);
    }
  }
}

export function replaceMovesOfFirstSubtableColumnByMovesOfAllSubtableColumns(
  movedColumns: number[],
  hot: Handsontable,
): void {
  const columns = getColumns(hot);

  const copyOfMovedColumns = [...movedColumns];
  copyOfMovedColumns.forEach((visualColumn) => {
    const physicalColumn = hot.toPhysicalColumn(visualColumn);
    const column = columns[physicalColumn];

    if (column.isFirstSubtableColumn) {
      const lastColumnOfSubtable = getLastColumnOfSubtable(columns, physicalColumn);
      const physicalColumnsOfSubtable = range(physicalColumn, lastColumnOfSubtable);
      const visualColumnsOfSubtable = toVisualColumns(hot, physicalColumnsOfSubtable);

      const originalMovedColumnIndex = movedColumns.indexOf(visualColumn);

      replaceOriginalMovedColumnWithArray(movedColumns, originalMovedColumnIndex, visualColumnsOfSubtable);
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function recalculateFinalIndex(movedColumns: number[], finalIndex: number, dropIndex: number | void): void {
  if (dropIndex) {
    // eslint-disable-next-line no-param-reassign
    finalIndex = dropIndex - movedColumns.length;
  }
}

function replaceMoveColumnEntry(movedColumns: number[], originalMovedColumnIndex: number, newValue: number) {
  movedColumns.splice(originalMovedColumnIndex, 1, newValue);
}

function replaceOriginalMovedColumnWithArray(
  movedColumns: number[],
  originalMovedColumnIndex: number,
  visualColumnsOfSubtable: number[],
) {
  movedColumns.splice(originalMovedColumnIndex, 1, ...visualColumnsOfSubtable);
}

function getColumns(hot: Handsontable): ColumnSettingsFlattenedSubtable[] {
  const { columns } = hot.getSettings();
  if (!Array.isArray(columns)) throw new Error('columns must be of type ColumnSettings[]');

  return columns;
}

function toVisualColumns(hot: Handsontable, physicalColumns: number[]) {
  return physicalColumns.map((physicalColumn) => hot.toVisualColumn(physicalColumn));
}
