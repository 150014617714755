import { Field, FieldContour } from '@/shared/api/rest/models/field';

export enum PolygonStates {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

export type FieldInfo = {
  name: Field['name'];
  crop: Field['cropId'];
  size: Field['fieldSize'];
};

export type PolygonHoveredData = {
  color: Polygon['lineChartColor'];
  name: Field['name'];
  crop: Field['cropId'];
  size: Field['fieldSize'] | string;
};

export type Fields = Record<Field['id'], Field>;

export type Polygons = Record<Field['id'], Polygon>;

export type Polygon = google.maps.Polygon & {
  fieldInfo: FieldInfo;
  // eslint-disable-next-line camelcase
  field_key: Field['id'];
  paths: FieldContour['geoJson']['coordinates'];
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillColor: string;
  fillOpacity: number;
  state: PolygonStates;
  lat: number;
  lon: number;
  name: Field['name'];
  lineChartColor: string;
  crop: Field['cropId'];
  size: Field['fieldSize'];
};
