import { MoveActivityState as MoveActivityResult } from 'farmdok-rest-api';
import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { MoveActivityParameterWithResult, MoveActivityState } from './types';

export type Getters = {
  moveActivityParametersWithoutMode: MoveActivityParameterWithResult[] | null;
  moveActivityParametersWithConflict: MoveActivityParameterWithResult[] | null;
  moveActivityParametersWithSuccess: MoveActivityParameterWithResult[] | null;
};

const gettersModule: GetterTree<MoveActivityState, RootState> = {
  moveActivityParametersWithoutMode: (state: MoveActivityState): MoveActivityParameterWithResult[] | null => {
    if (state.moveActivityParametersWithResult) {
      return Object.values(state.moveActivityParametersWithResult).filter(
        (moveActivityParameter: any) => !moveActivityParameter.mode,
      );
    }
    return null;
  },
  moveActivityParametersWithConflict: (state: MoveActivityState): MoveActivityParameterWithResult[] | null => {
    if (state.moveActivityParametersWithResult) {
      return Object.values(state.moveActivityParametersWithResult).filter(
        (moveActivityParameter: any) => moveActivityParameter.result === MoveActivityResult.NoMatchFound,
      );
    }
    return null;
  },
  moveActivityParametersWithSuccess: (state: MoveActivityState): MoveActivityParameterWithResult[] | null => {
    if (state.moveActivityParametersWithResult) {
      return Object.values(state.moveActivityParametersWithResult).filter(
        (moveActivityParameter: MoveActivityParameterWithResult) =>
          moveActivityParameter.result === MoveActivityResult.CopyField ||
          moveActivityParameter.result === MoveActivityResult.ReplaceField,
      );
    }
    return null;
  },
};

export default gettersModule;
