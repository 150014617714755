
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import numbro from '@/initNumbro';
import { type Unit } from '@/shared/api/rest/models';
import FormFieldInputNumberFormattedDisplayValue from '@/shared/components/form/FormFieldInputNumberFormattedDisplayValue.vue';

export default defineComponent({
  name: 'ControlProductAmount',
  components: { FormFieldInputNumberFormattedDisplayValue },
  props: {
    amount: {
      type: Number,
      required: true,
      default: 0,
    },
    productId: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: FormFieldInputNumberFormattedDisplayValue.props.variant,
  },
  emits: ['update:amount'],
  model: {
    prop: 'amount',
    event: 'update:amount',
  },
  created(): void {
    this.$store.dispatch('units/subscribe');
  },
  computed: {
    ...mapState('units', {
      units: 'data',
    }),
    ...mapGetters('products', ['findProductById']),
    ...mapGetters('productCategories', ['isHerbicide']),
    mantissa(): number {
      const product = this.findProductById(this.productId);
      return this.isHerbicide(product?.categoryId) ? 3 : 2;
    },
    unit(): Unit | undefined {
      const product = this.findProductById(this.productId);
      return this.units[product?.unitId ?? ''];
    },
  },
  methods: {
    formatter(value: number | null): string {
      return numbro(value).format({ mantissa: this.unit?.ddiDecimals ?? this.mantissa });
    },
    numbro,
  },
});
