import Handsontable from 'handsontable';

export default function createInitialSortConfig(
  columns: Handsontable.ColumnSettings[],
  hot: Handsontable,
): Handsontable.columnSorting.Config | undefined {
  const columnWithInitialSortOrderIndex = columns.findIndex((column) => column.initialSortOrder);
  if (columnWithInitialSortOrderIndex === -1) return undefined;
  const columnWithInitialSortOrder = columns[columnWithInitialSortOrderIndex];

  if (typeof columnWithInitialSortOrder.data === 'function' || columnWithInitialSortOrder.data === undefined) {
    throw new Error('columnWithInitialSortOrder.data must be a string or number to enable initialSortOrder');
  }

  const physicalColumnIndex = hot.propToCol(columnWithInitialSortOrder.data);

  return {
    column: physicalColumnIndex,
    sortOrder: columnWithInitialSortOrder.initialSortOrder,
  };
}
