
import { PropType, defineComponent } from 'vue';

import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import { RadioOption } from '@/shared/components/form/formRadioTypes';

import { VisualizationMode } from '../../store/types';

export default defineComponent({
  name: 'VisualizationSelect',
  components: {
    FormFieldRadioGroup,
  },
  model: {
    prop: 'selectedVisualizationMode',
    event: 'update:selectedVisualizationMode',
  },
  props: {
    selectedVisualizationMode: {
      type: String as PropType<VisualizationMode>,
      required: true,
    },
  },
  data() {
    const options: RadioOption[] = [
      { text: this.$t('Satellitenbild') || 'Satellitenbild', value: VisualizationMode.SATELLITE_IMAGE },
      { text: this.$t('Vegetation') || 'Vegetation', value: VisualizationMode.VEGETATION },
      { text: this.$t('Zonen') || 'Zonen', value: VisualizationMode.ZONES },
    ];

    return {
      options,
    };
  },
});
