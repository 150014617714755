import Vue from 'vue';

import { REGION_GUIDS } from '@/shared/constants';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { tableSettingsColumn } from '@/shared/handsontable/mixins/featureTablesettingsColumn';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import {
  amaCode,
  cadastralCommunity,
  catchCropVariant,
  combinedNameExclCrop,
  comment,
  companyId,
  crop,
  customer,
  fieldGroupNumber,
  fieldNumber,
  gwArea,
  kindOfUse,
  mbi,
  nameWithStatus,
  ndvi,
  owner,
  plots,
  preCrop,
  rightOfUse,
  sharedFieldInfo,
  size,
  status,
  variety,
} from './columns';

export const tableFields = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...combinedNameExclCrop,
    header: {
      title: Vue.i18n.translate('Kunde - Feldstück - Feld'),
      description: Vue.i18n.translate('Kunde - Feldstück - Feld'),
    },
    width: 145,
    lockedPosition: true,
    sortOrder: 'asc',
  },
  {
    ...size,
    readOnly: true,
    width: 85,
  },
  {
    ...crop,
    lockedPosition: false,
    readOnly: true,
    width: 95,
  },
  {
    ...variety,
    readOnly: true,
    width: 95,
  },
  {
    ...fieldGroupNumber,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...fieldNumber,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...customer,
    hiddenPerDefault: true,
    readOnly: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length >= 0,
  },
  {
    ...nameWithStatus,
    hiddenPerDefault: true,
    readOnly: true,
    width: 145,
  },
  {
    ...sharedFieldInfo,
    requiredFeatures: (store, context) =>
      context.sharedFieldsByFieldId != null && Object.keys(context.sharedFieldsByFieldId).length > 0,
  },
  {
    ...preCrop,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...catchCropVariant,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...kindOfUse,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...comment,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...cadastralCommunity,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...plots,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...owner,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...rightOfUse,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...status,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...ndvi,
    hiddenPerDefault: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_FIELDS_NDVI_MEAN),
  },
  {
    ...mbi,
    hiddenPerDefault: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_FIELDS_NDVI_MEAN),
  },
  tableSettingsColumn,
];

export const tableFieldsAt = [
  ...tableFields,
  {
    ...amaCode,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...gwArea,
    hiddenPerDefault: true,
    readOnly: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_GROUNDWATER_PROTECTION),
  },
];

export default {
  international: tableFields,
  [REGION_GUIDS.AT]: tableFieldsAt,
  [REGION_GUIDS.DE]: tableFields,
};
