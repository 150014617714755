
import { PropType, defineComponent } from 'vue';

import ApplicationMapContainer from '@/precision-farming/application-maps/fertilizationCereals/containers/sidebarApplicationMap/ApplicationMapContainer.vue';
import ProductSelectionContainer from '@/precision-farming/application-maps/fertilizationCereals/containers/sidebarApplicationMap/ProductSelectionContainer.vue';
import RequiredAmountContainer from '@/precision-farming/application-maps/fertilizationCereals/containers/sidebarApplicationMap/RequiredAmountContainer.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';

import ScrollableContent from '../../../components/ScrollableContent.vue';

export default defineComponent({
  name: 'SidebarCreateApplicationMap',
  components: {
    ProductSelectionContainer,
    RequiredAmountContainer,
    ApplicationMapContainer,
    SidebarHeadline,
    ScrollableContent,
  },
  props: {
    workflowKey: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
