import { Activity, ActivityProduct } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import { ActionContext } from 'vuex';

import ActivityProductService from '@/activities/services/ActivityProductService';
import ActivityService from '@/activities/services/ActivityService';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../types';
import initServices from './utils/initServices';

type UpdateValues = {
  pestId: string;
  pesticideIndicationId: string | null;
};

export default function updatePestOnActivityProductInAllActivities(
  { state, commit, rootState, rootGetters }: ActionContext<CreateEditActivityState, RootState>,
  data: { activityProductId: string; updateValues: UpdateValues },
) {
  const { activityService, findService } = initServices(state, rootState, rootGetters);
  const referenceActivityProduct = _cloneDeep(findService.getActivityProduct(data.activityProductId));

  state.activities.forEach((activity) => {
    const clonedActivity = _cloneDeep(activity);
    const family = activityService.findActivityProductsOrSiblings(clonedActivity, referenceActivityProduct);

    if (family.length > 0) {
      updateProducts(family, data.updateValues, clonedActivity.id, commit);
    } else {
      addNewProduct(clonedActivity, data.updateValues, activityService, commit);
    }
  });
}

function updateProducts(
  product: ActivityProduct[],
  updateValues: UpdateValues,
  activityId: string,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  product.forEach((p) => {
    updateProduct(p, updateValues, activityId, commit);
  });
}

function updateProduct(
  product: ActivityProduct,
  updateValues: UpdateValues,
  activityId: string,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  const updatedProduct = updateActivityProductValues(product, updateValues);

  commit('setActivityProduct', { activityId, activityProduct: updatedProduct });
}

function addNewProduct(
  activity: Activity,
  updateValues: UpdateValues,
  activityService: ActivityService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  let activityProduct = ActivityProductService.createActivityProduct();
  activityProduct = updateActivityProductValues(activityProduct, updateValues);
  activityService.addActivityProduct(activity, activityProduct);

  commit('addActivityProduct', { activityId: activity.id, activityProduct: activity.products.slice(-1)[0] });
}

// TODO combine this with updateValues of updateProductOnActivityProductInAllActivities.ts
function updateActivityProductValues(
  activityProduct: ActivityProduct,
  { pestId, pesticideIndicationId }: UpdateValues,
): ActivityProduct {
  const updatedActivityProduct = _cloneDeep(activityProduct);
  updatedActivityProduct.pestId = pestId;
  updatedActivityProduct.pesticideIndicationId = pesticideIndicationId;

  return updatedActivityProduct;
}
