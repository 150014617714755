import { AxiosRequestConfig } from 'axios';
import { ActionContext } from 'vuex';

import { fetchActivityTypes } from '@/shared/api/rest/RestApi';
import { ActivityType, ApiResponse } from '@/shared/api/rest/models';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<ActivityType>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ActivityType[]>> {
  const companyIds = context.rootState.auth.currentCompanies.map((company) => company.id);

  const { data } = await fetchActivityTypes(companyIds, axiosOptions);

  return data;
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<ActivityType>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ActivityType[]>> {
  throw new Error('NOT IMPLEMENTED');
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
