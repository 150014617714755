import { AxiosRequestConfig } from 'axios';
import { PlantVarietiesApi, PlantVarietiesApiPlantVarietyListRequest, PlantVariety } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<PlantVariety>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<PlantVariety>> {
  const companyIds = context.rootGetters['auth/currentCompanyIds'] as string[];
  const regionIds = context.rootState.auth.currentCompanies.map((company) => company.regionId);

  const { plantVarietiesApi } = Vue.prototype.$api as Api;

  const apiResponsesCompany = await Promise.all(
    companyIds.map((companyId) => {
      const requestParameters: PlantVarietiesApiPlantVarietyListRequest = { companyId, deleted: false };
      return loadAllPages<PlantVarietiesApi, PlantVariety>(
        plantVarietiesApi,
        'plantVarietyList',
        requestParameters,
        axiosOptions,
      );
    }),
  );

  const apiResponsesRegion = await Promise.all(
    regionIds.map((regionId) => {
      const requestParameters: PlantVarietiesApiPlantVarietyListRequest = { regionId, deleted: false };
      return loadAllPages<PlantVarietiesApi, PlantVariety>(
        plantVarietiesApi,
        'plantVarietyList',
        requestParameters,
        axiosOptions,
      );
    }),
  );

  const responses = apiResponsesCompany.concat(apiResponsesRegion);

  const apiResponse = mergeApiResponses(responses);

  return apiResponse;
}

async function fetchByIds(
  ids: string[],
  context: ActionContext<SubscribableDataState<PlantVariety>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<PlantVariety>> {
  const { plantVarietiesApi } = Vue.prototype.$api as Api;
  const { data } = await plantVarietiesApi.plantVarietyObjects({ objectIds: ids.join(',') }, axiosOptions);

  return data as ApiResponse<PlantVariety>;
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
