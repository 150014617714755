<template>
  <div
    v-show="target != null || hovered"
    class="table-row-hover-buttons"
    :style="{
      top,
      height,
      marginRight,
    }"
  >
    <BButton
      v-if="!noMainButton"
      variant="outline-medium"
      class="table-row-hover-buttons__btn-edit bg-white text-black"
      @click="$emit('mainButton')"
    >
      <FontAwesomeIcon :icon="mainButtonIcon" />
      {{ mainButtonTitle != null ? mainButtonTitle : $t('Bearbeiten') }}
    </BButton>
    <BButton
      v-if="!readOnly"
      variant="outline-medium"
      class="table-row-hover-buttons__btn-context-menu ml-2 bg-white text-black"
      @click="$emit('contextmenu', $event)"
    >
      <FontAwesomeIcon :icon="['far', 'ellipsis-h']" />
    </BButton>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';

library.add(faEllipsisH);
library.add(faPencil);

export default {
  name: 'TableRowHoverButtons',
  props: {
    target: {
      type: HTMLElement,
      default: null,
    },
    noMainButton: {
      type: Boolean,
      default: false,
    },
    mainButtonIcon: {
      type: [String, Array],
      default: 'pencil',
    },
    mainButtonTitle: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      top: 0,
      height: 0,
      marginRight: 0,
    };
  },
  watch: {
    target() {
      this.updatePosition();
    },
  },
  mounted() {
    this.$el.addEventListener('mouseenter', () => {
      this.hovered = true;
    });
    this.$el.addEventListener('mouseleave', () => {
      this.hovered = false;
    });
  },
  methods: {
    updatePosition() {
      if (this.hovered) {
        return;
      }
      if (this.target == null) {
        this.top = 0;
        this.height = 0;
        return;
      }

      const wtHolder = this.target.closest('.wtHolder');

      const parentBoundingClientRect = wtHolder.getBoundingClientRect();
      const targetBoundingClientRect = this.target.getBoundingClientRect();
      const offsetTop = targetBoundingClientRect.top - parentBoundingClientRect.top;

      const { offsetHeight: holderHeight } = wtHolder;
      this.top = `${offsetTop + 1}px`;

      this.height = `${this.target.offsetHeight - 2}px`;

      const { offsetHeight: spreaderHeight } = this.target.closest('.wtSpreader');
      if (spreaderHeight > holderHeight) {
        this.marginRight = '16px';
      } else {
        this.marginRight = 0;
      }
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
  },
};
</script>

<style scoped>
.table-row-hover-buttons {
  position: absolute;
  right: 0;
  padding: 5px 10px 5px 55px;
  display: flex;
  z-index: 100;
  pointer-events: none;
  background: linear-gradient(
    to right,
    rgba(247, 247, 247, 0),
    rgba(247, 247, 247, 1) 50px,
    rgba(247, 247, 247, 1) 50%
  );
}

.table-row-hover-buttons__btn-edit {
  padding-left: 6px;
  font-size: 12px;
  line-height: 20px;
  pointer-events: auto;
}

.table-row-hover-buttons__btn-context-menu {
  padding-left: 6px;
  padding-right: 6px;
  pointer-events: auto;
}
</style>
