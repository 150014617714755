
import { defineComponent } from 'vue';

import { RootState } from '@/store/types';

import ThirdStep from '../components/ThirdStep.vue';

export default defineComponent({
  name: 'ThirdStepContainer',
  components: { ThirdStep },
  methods: {
    addActivityProduct() {
      this.$store.commit(`activities/createEditActivity/createActivityProduct`);
      if ((this.$store.state as RootState).activities.createEditActivity.currentActivityProductId !== null) {
        this.$store.commit(`activities/createEditActivity/setCurrentActivityProductId`, null);
      }
    },
  },
});
