import { MutationTree } from 'vuex';

import {
  ENTRY_DIRTY,
  ENTRY_ERROR_INSERTING,
  ENTRY_ERROR_UPDATING,
  ENTRY_INSERTING,
  ENTRY_NEW,
  ENTRY_SYNCED,
  ENTRY_UPDATING,
  ERROR_INSERTING,
  ERROR_UPDATING,
  GUID_KEY,
} from '@/shared/constants';

import { DataEntry, Error, SyncableDataState } from '../types';

const mutations: MutationTree<SyncableDataState<DataEntry>> = {
  syncAllStart(state) {
    state.syncing = true;
  },
  /**
   * Call this before sending (bulk) data to the BE.
   * Update the storeStatus for all given entries to either ENTRY_INSERTING or ENTRY_UPDATING.
   * Removes errors for all entries that get synced.
   *
   * @param state
   * @param entries
   */
  syncAllAddEntries(state, entries: DataEntry[]) {
    state.data = entries.reduce(
      (currentState, entry) => ({
        ...currentState,
        [entry.id]: {
          ...currentState[entry.id],
          storeStatus: state.data[entry.id].storeStatus === ENTRY_NEW ? ENTRY_INSERTING : ENTRY_UPDATING,
        },
      }),
      state.data,
    );
    const entryIds = entries.map(({ id }) => id);
    state.errors = state.errors.filter((error) => !entryIds.includes(error.guid));
  },
  /**
   * Call this after successful (bulk) data request to BE.
   * Updates all given entries in the store iff the storeStatus is still 'inserting' or 'updating'.
   * Additionally set state.syncing to false.
   *
   * @param state
   * @param entries
   */
  syncAllFinish(state, entries: DataEntry[]) {
    if (!state.syncing) {
      return;
    }
    state.syncing = false;
    if (!Array.isArray(entries)) {
      return;
    }
    state.data = entries.reduce((currentState, entry) => {
      const storeStatus = state.data[entry.id]?.storeStatus;
      if (storeStatus && ![ENTRY_INSERTING, ENTRY_UPDATING].includes(storeStatus)) {
        return currentState;
      }
      return {
        ...currentState,
        [entry.id]: {
          ...currentState[entry.id],
          ...entry,
          storeStatus: ENTRY_SYNCED,
        },
      };
    }, state.data);
  },
  /**
   * Add a callback that is called as soon as all entries are synced or an error occurred.
   *
   * @param state
   * @param callback
   */
  addSyncableObserver(state, callback: Function) {
    state.syncableObservers = [...state.syncableObservers, callback];
  },
  /**
   * Resolve and clear all current callbacks. Call this after all entries are synced or an error occurred.
   *
   * @param state
   */
  clearSyncableObservers(state) {
    state.syncableObservers.forEach((callback) => callback());
    state.syncableObservers = [];
  },
  /**
   * Add error into store.
   *
   * @param state
   * @param guid
   * @param key
   * @param errorUserMessage
   */
  addSyncError(state, error: Error) {
    let storeStatus = ENTRY_ERROR_UPDATING;
    let type = ERROR_UPDATING;
    if (
      state.data[error.guid].storeStatus === ENTRY_NEW ||
      state.data[error.guid].storeStatus === ENTRY_INSERTING ||
      state.data[error.guid].storeStatus === ENTRY_ERROR_INSERTING
    ) {
      storeStatus = ENTRY_ERROR_INSERTING;
      type = ERROR_INSERTING;
    }
    state.data[error.guid] = {
      ...state.data[error.guid],
      storeStatus,
    };
    state.errors = [
      ...state.errors,
      {
        ...error,
        type,
      },
    ];
  },
  /**
   * Update the value of a single field of a single entry.
   * Sets the storeStatus to 'dirty'.
   *
   * @param state
   * @param guid
   * @param key
   * @param value
   */
  updateEntryByKeyAndValue(state, { guid, key, value }) {
    if (state.data[guid] == null) {
      return;
    }
    const data = { ...state.data };
    data[guid] = {
      ...state.data[guid],
      [key]: value,
      storeStatus: ENTRY_DIRTY,
    };
    state.data = data;
    state.errors = state.errors.filter((error) => error[GUID_KEY] !== guid || error.key !== key);
  },
  /**
   * Update an entry in the store and setting it to 'dirty' (unless the silent flag is set, than storeStatus is not changed).
   *
   * @param state
   * @param entry
   * @param silent {boolean}
   */
  updateEntry(state, { entry, silent = false }) {
    const guid = entry[GUID_KEY];
    const data = { ...state.data };
    data[guid] = {
      ...state.data[guid],
      ...entry,
    };
    if (!silent) {
      data[guid].storeStatus = ENTRY_DIRTY;
    }
    state.data = data;
  },
  updateStoreStatusOfEntry(state, { guid, storeStatus }) {
    if (state.data[guid] == null) {
      return;
    }
    state.data[guid].storeStatus = storeStatus;
  },
  /**
   * Insert an entry in the store and setting it to 'new'.
   *
   * @param state
   * @param entry
   */
  insert(state, { entry }) {
    const guid = entry[GUID_KEY];
    state.data = {
      ...state.data,
      [guid]: {
        ...entry,
        storeStatus: ENTRY_NEW,
      },
    };
  },
};

export default mutations;
