<template>
  <div class="sidebar-content-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SidebarContentWrapper',
};
</script>

<style scoped>
.sidebar-content-wrapper {
  margin: 16px 0 32px;
  padding: 0 12px;
}
</style>
