<template>
  <BButton
    v-b-tooltip.hover.bottom.d500="tooltipOptions"
    class="button"
    :class="[
      `button--${variantComputed}`,
      `button--${size}`,
      {
        'button--has-next-sibling': hasNextSibling,
        'button--has-transparent-next-sibling': hasTransparentNextSibling,
      },
    ]"
    :size="size"
    :variant="variantComputed"
    :title="iconOnly || tooltipOptions ? title : ''"
    :block="block"
    :disabled="disabled || fetching"
    :data-namespace="componentNamespace"
    :style="buttonStyle"
    @click="onClick"
    data-testid="button"
  >
    <span
      v-if="iconComputed != null && leadingIcon"
      class="button__leading-icon"
      :class="{
        'button__leading-icon--icon-only': iconOnly,
        'button__leading-icon--with-notification': notificationComputed != null,
      }"
    >
      <FontAwesomeIcon class="mx-0" :icon="iconComputed" :spin="fetching" />
    </span>
    <span
      v-if="!iconOnly"
      class="button__label"
      :class="{ 'button__label--with-notification': iconComputed == null && notificationComputed != null }"
    >
      <slot name="default">
        {{ title }}
      </slot>
    </span>
    <span
      v-if="iconComputed != null && !leadingIcon"
      class="button__trailing-icon"
      :class="{
        'button__trailing-icon--icon-only': iconOnly,
        'button__trailing-icon--with-notification': notificationComputed != null,
      }"
    >
      <FontAwesomeIcon class="mx-0" :icon="iconComputed" :spin="fetching" />
    </span>
  </BButton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import componentNamespace from '../../mixins/componentNamespace';

library.add(faCircleNotch);

/**
 * FARMDOK Button<br>
 * If multiple buttons are placed next to each other they will dynamically set the margin-right.
 * So always place them into the same container if they should align next to each other.<br>
 * For examples take a look at [Figma]{@link https://www.figma.com/file/p4TEqNSpxiFc9gquNdOsCp/2.-Components---FDK}.<br>
 *
 * @category Shared
 * @subcategory Atoms
 * @component
 */
export default {
  name: 'Button',
  mixins: [componentNamespace],
  props: {
    /**
     * Change font size and padding/margin. Allowed values: 'sm', 'md' (default), 'lg'
     */
    size: {
      type: String,
      default: 'md',
      validator: (size) => ['sm', 'md', 'lg'].includes(size),
    },
    /**
     * Color scheme of the button.<br>
     * Allowed colors: 'primary', 'danger', 'black', 'medium', 'white', 'lightest'<br>
     * Use 'medium' only in combination with the outline variant.<br>
     * Use 'white' and 'lightest' only on a background of the same color (pseudo-transparent button).
     */
    color: {
      type: String,
      default: 'primary',
      validator: (color) => ['primary', 'danger', 'black', 'medium', 'white', 'lightest'].includes(color),
    },
    /**
     * Change the button look to an outline variant (border with color, rest of the button transparent).
     */
    outline: {
      type: Boolean,
      default: false,
    },
    /**
     * Shorthand for color+outline. Overwrites color and variant.
     */
    variant: {
      type: String,
      default: null,
    },
    /**
     * Fontawesome icon
     */
    icon: {
      type: [String, Array],
      default: null,
    },
    /**
     * If true icon rendered in front, otherwise at the back.
     */
    leadingIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to true the default slot will not be rendered. Use only in combination of a set icon.
     */
    iconOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Title will be rendered inside the button if the default slot is empty.<br>
     * Additionally, if iconOnly is set to true, the title will be shown using bootstrap tooltip directive.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Set display block.
     */
    block: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable the button.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables the button and renders a spinning circle instead of the icon.
     */
    fetching: {
      type: Boolean,
      default: false,
    },
    /**
     * Will be propagated to v-b-tooltip, see https://bootstrap-vue.org/docs/directives/tooltip#directive-syntax-and-usage
     */
    tooltipOptions: {
      type: Object,
      default: null,
    },
    /**
     * Places a dot notification indicator next to the icon (if there is one) or on top right of the button's text.
     * If set to true, the dot will be colored with our 'danger' color, if string containing a theme color, this color will be used.
     * Allowed values: true, false, 'primary', 'danger', 'black', 'medium', 'white', 'lightest'
     */
    notification: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      hasNextSibling: false,
      hasTransparentNextSibling: false,
    };
  },
  computed: {
    variantComputed() {
      let variantComputed = this.variant;
      if (this.variant == null) {
        if (this.outline) {
          variantComputed = `outline-${this.color}`;
        } else {
          variantComputed = this.color;
        }
      }
      if (
        !['primary', 'danger', 'black', 'white', 'lightest', 'white-black'].includes(variantComputed) &&
        !['outline-primary', 'outline-danger', 'outline-black', 'outline-medium'].includes(variantComputed)
      ) {
        console.error(
          '[Vue warn]: Invalid prop: custom validator check failed for prop "variantComputed".',
          variantComputed,
        );
      }
      return variantComputed;
    },
    iconComputed() {
      if (this.fetching) {
        return 'circle-notch';
      }
      return this.icon;
    },
    notificationComputed() {
      if (typeof this.notification === 'string') {
        return this.notification;
      }
      if (this.notification === true) {
        return 'danger';
      }
      return null;
    },
    buttonStyle() {
      if (this.notificationComputed != null) {
        return { '--notification-color': `var(--${this.notificationComputed})` };
      }
      return null;
    },
  },
  mounted() {
    if (this.$el.nextElementSibling == null) {
      return;
    }
    this.hasNextSibling = true;
    if (
      [...this.$el.nextElementSibling.classList].some((className) =>
        ['button--lightest', 'button--white'].includes(className),
      )
    ) {
      this.hasTransparentNextSibling = true;
    }
  },
  methods: {
    onClick(event) {
      /**
       *
       * @event root#button--click
       * @type {String}
       */
      this.$root.$emit('button--click', this.componentNamespace);
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped>
.button {
  margin: var(--spacer_2) 0;
}

.button--lg {
  margin: var(--spacer_3) 0;
}

.button--lightest,
.button--white {
  color: var(--black);
}

.button--outline-medium {
  color: var(--gray_600);
  border-color: var(--gray_600);
}

.button--has-next-sibling {
  margin-right: var(--spacer_3);
}

.button--lightest.button--has-transparent-next-sibling,
.button--white.button--has-transparent-next-sibling {
  margin-right: var(--spacer_2);
}

.button__label--with-notification {
  position: relative;
}

.button__label--with-notification::after {
  content: '';
  position: absolute;
  top: 0;
  right: -7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--notification-color);
}

.button__leading-icon {
  margin-left: 0;
  margin-right: 0.4em;
}

.button__trailing-icon {
  margin-left: 0.4em;
  margin-right: 0;
}

.button__leading-icon.button__leading-icon--icon-only,
.button__trailing-icon.button__trailing-icon--icon-only {
  margin-left: 0.4em;
  margin-right: 0.4em;
}

.button__leading-icon--with-notification,
.button__trailing-icon--with-notification {
  position: relative;
}

.button__leading-icon--with-notification::after,
.button__trailing-icon--with-notification::after {
  content: '';
  position: absolute;
  top: 0;
  right: -3px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--notification-color);
}
</style>
