<template>
  <ModalWrapper ref="modal" noCancelButton :okTitle="$t('Schließen')" size="lg" :title="$t('Berater-Tipp')">
    <!-- eslint-disable max-len -->

    <p>
      {{
        $t(
          'Mit diesem Schieberegler kannst du kalkulierbare Verluste berücksichtigen und die Saatstärke pauschal korrigieren (1-15%). Pflanzenverluste können beispielsweise auftreten durch:',
        )
      }}
    </p>
    <ul>
      <li>
        {{ $t('mechanische Pflegemaßnahmen') }}
      </li>
      <li>
        {{ $t('Saatzeitpunkt außerhalb des Optimums') }}
      </li>
      <li>
        {{ $t('Saatbett-Bedingungen (Scholligkeit, Wasserverfügbarkeit, …)') }}
      </li>
      <li>
        {{ $t('tierische Schäden (Bsp.: Wild, Vögel, Insekten, …)') }}
      </li>
    </ul>
    <p>
      {{ $t('Für Fragen steht Ihr regionaler Berater gerne zur Verfügung (Beraterzuordnung nach Region).') }}
    </p>
    <ButtonLink href="https://www.saatbau.com/beratung/" target="_blank">
      {{ $t('Meinen persönlichen Berater kontaktieren...') }}
    </ButtonLink>
    <!-- eslint-enable max-len -->
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';

export default {
  name: 'ModalLossCompensationInfo',
  components: { ModalWrapper, ButtonLink },
  methods: {
    async show() {
      return this.$refs.modal.show();
    },
  },
};
</script>
