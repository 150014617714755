import { ActivityRuleViolation } from 'farmdok-rest-api';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import initialState from './initialState';
import { ActivityRuleViolationsState } from './types';

const mutations: MutationTree<ActivityRuleViolationsState> = {
  setData(state, activityRuleViolations: ActivityRuleViolation[]) {
    Vue.set(state, 'data', activityRuleViolations);
  },
  removeData(state, activityRuleViolationIds: string[]) {
    const newData = state.data.filter(
      (activityRuleViolation) => !activityRuleViolationIds.includes(activityRuleViolation.id),
    );
    Vue.set(state, 'data', newData);
  },
  setFetching(state, fetching: boolean) {
    state.fetching = fetching;
  },
  reset(state) {
    const newState = initialState();
    Object.entries(newState).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },
};

export default mutations;
