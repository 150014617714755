import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import Vue from 'vue';

library.add(faEllipsisV);

export const tableSettingsColumn = {
  header: {
    description: Vue.i18n.translate('Einstellungen'),
  },
  data: () => null,
  key: '__dotmenu',
  readOnly: true,
  type: 'text',
  width: 45,
  fixedEnd: true,
  hiddenInPageSettings: true,
  lockedVisibility: true,
  lockedPosition: true,
  noHeaderContextMenu: true,
};

export default {
  created() {
    this.tableHooks.colHeaders.push(this.tableSettingsColHeader);
  },

  mounted() {
    this.$el.addEventListener('click', (e) => {
      if (
        e.target != null &&
        e.target.className != null &&
        typeof e.target.className.indexOf === 'function' &&
        e.target.className.indexOf('header-tablesettings') > -1
      ) {
        this.openTableSettingsModal();
      }
      return true;
    });
  },

  methods: {
    tableSettingsColHeader(column) {
      if (this.hot == null || column.key !== tableSettingsColumn.key) {
        return this.selectableRowsColHeaders(column);
      }
      const wrapper = document.createElement('div');

      const [ellipsis] = icon({ prefix: 'far', iconName: 'ellipsis-v' }).node;

      wrapper.classList.add('header-tablesettings');
      ellipsis.style.pointerEvents = 'none';

      wrapper.appendChild(ellipsis);
      return wrapper.outerHTML;
    },

    openTableSettingsModal() {
      this.$emit('showTableSettings');
    },
  },
};
