import Vue from 'vue';
import { MutationTree } from 'vuex';

import { ENTRY_DIRTY, ENTRY_SYNCED, ENTRY_UPDATING, ERROR_FETCHING } from '@/shared/constants';

import { DataEntry, SubscribableDataState } from '../types';
import initialState from './initialState';

const mutations: MutationTree<SubscribableDataState<DataEntry>> = {
  startFetching(state, source) {
    Vue.set(state, 'fetching', true);
    Vue.set(state, 'axiosSources', [...state.axiosSources, source]);
  },
  addObserver(state, callback) {
    Vue.set(state, 'observers', [...state.observers, callback]);
  },
  clearObservers(state) {
    Vue.set(state, 'observers', []);
  },
  addOne(state, entry: DataEntry) {
    Vue.set(state.data, entry.id, entry);
  },
  loadAll(state, { data }: { data: DataEntry[] }) {
    Vue.set(
      state,
      'data',
      data.reduce((dataCurrent, entry) => {
        const storeStatus = state.data[entry.id]?.storeStatus;
        if (storeStatus && [ENTRY_DIRTY, ENTRY_UPDATING].includes(storeStatus)) {
          return {
            ...dataCurrent,
            [entry.id]: state.data[entry.id],
          };
        }
        return {
          ...dataCurrent,
          [entry.id]: {
            ...entry,
            storeStatus: ENTRY_SYNCED,
          },
        };
      }, {}),
    );
    Vue.set(state, 'loaded', new Date());
  },
  updateEntries(state, { data }: { data: DataEntry[] }) {
    Vue.set(
      state,
      'data',
      data.reduce((dataCurrent, entry) => {
        const storeStatus = state.data[entry.id]?.storeStatus;
        if (storeStatus && [ENTRY_DIRTY, ENTRY_UPDATING].includes(storeStatus)) {
          return {
            ...dataCurrent,
            [entry.id]: state.data[entry.id],
          };
        }
        return {
          ...dataCurrent,
          [entry.id]: {
            ...entry,
            storeStatus: ENTRY_SYNCED,
          },
        };
      }, state.data),
    );
  },
  finishFetching(state, source) {
    Vue.set(state, 'fetching', false);
    Vue.set(
      state,
      'axiosSources',
      state.axiosSources.filter((currentSource) => currentSource !== source),
    );
  },
  fetchingError(state, source) {
    Vue.set(state, 'fetching', false);
    Vue.set(
      state,
      'axiosSources',
      state.axiosSources.filter((currentSource) => currentSource !== source),
    );
    Vue.set(state, 'errors', [...state.errors, { type: ERROR_FETCHING }]);
  },
  resetLoaded(state) {
    Vue.set(state, 'loaded', null);
  },
  reset(state) {
    const newState = initialState<DataEntry>();

    Object.entries(newState).forEach(([key, value]) => {
      if (key === 'observers') {
        return;
      }
      Vue.set(state, key, value);
    });
  },
};

export default mutations;
