export default {
  methods: {
    /**
     * @param {string} fieldSize
     * @return {string}
     */
    fieldSizeDecimals(fieldSize) {
      if (Number.isNaN(Number(fieldSize))) {
        return '0.00';
      }
      const parts = String(fieldSize).split('.');
      if (parts[1] == null || parts[1].length === 0) {
        return `${parts[0]}.00`;
      }
      if (parts[1].length === 1) {
        return `${parts[0]}.${parts[1]}0`;
      }
      return String(fieldSize);
    },
  },
};
