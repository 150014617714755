import Vue from 'vue';

import { companyId as companyIdShared } from '@/shared/handsontable/columns';

import {
  PropertyArchived,
  PropertyComment,
  PropertyCompanyId,
  PropertyCustomerId,
  PropertyName,
  PropertyNumber,
  PropertySize,
} from '../../store';
import '../renderers/FieldGroupArchivedRenderer';

export const companyId = {
  ...companyIdShared,
  key: PropertyCompanyId,
};

export const number = {
  key: PropertyNumber,
  header: {
    title: Vue.i18n.translate('FSNr'),
    description: Vue.i18n.translate('Feldstück-Nummer'),
  },
  type: 'text',
  width: 70,
};

export const customer = {
  key: PropertyCustomerId,
  header: {
    title: Vue.i18n.translate('Kunde'),
  },
  type: 'dropdown',
  width: 170,
};

export const name = {
  key: PropertyName,
  header: {
    title: Vue.i18n.translate('Bezeichnung'),
    description: Vue.i18n.translate('Feldstück'),
  },
  type: 'text',
  className: 'border-right text-bold',
  width: 270,
};

export const size = {
  key: PropertySize,
  header: {
    title: Vue.i18n.translate('Größe'),
    description: Vue.i18n.translate('ha'),
  },
  type: 'numeric',
  width: 100,
};

export const archived = {
  key: PropertyArchived,
  header: {
    title: Vue.i18n.translate('Archiviert'),
  },
  type: 'fieldGroupArchived',
  onClick({ target, visualRow }) {
    const id = this.visualRowToGuid(visualRow);
    this.$emit(`click:${PropertyArchived}`, { target, id });
  },
  getStatusReadOnly() {
    return this.$parent.processOrdersReadOnly || this.$parent.fieldsReadOnly;
  },
  width: 130,
};

export const comment = {
  key: PropertyComment,
  header: {
    title: Vue.i18n.translate('Beschreibung'),
  },
  type: 'text',
  width: 350,
};
