import { TerrazoFieldTimestampEntry, TerrazoGrainQuality, TerrazoHumidityAreaType } from 'farmdok-rest-api';

import {
  FertilizationCalculation,
  FertilizationZoneDosage,
} from '@/precision-farming/application-maps/fertilization/store/types';
import { Heatmaps } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { UnixTimestamp } from '@/shared/modules/unixTimestampHelpers';

import { BaseWorkflowStoreModuleState } from '../../store/baseWorkflowStore/types';
import { Zone } from '../../store/baseWorkflowStore/types/Zone';
import { WorkflowKeyForRoutes } from '../../types';

export enum ZoningMode {
  EQUAL_ZONE_SIZE = 'EQUAL_ZONE_SIZE',
  EQUAL_INDEX_DISTANCE = 'EQUAL_INDEX_DISTANCE',
  JENKS_NATURAL_BREAKS = 'JENKS_NATURAL_BREAKS',
}

export enum VisualizationMode {
  SATELLITE_IMAGE = 'SATELLITE_IMAGE',
  VEGETATION = 'VEGETATION',
  ZONES = 'ZONES',
}

export type FertilizationCerealsNutrients = {
  n: number | null;
  p: number | null;
  k: number | null;
};

export enum ApplicationType {
  MANUAL = 'manual',
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
}

export type Application = {
  applicationType: ApplicationType | null;
  applicationTypeFirst: {
    targetNRate: number | null;
  };
  applicationTypeThird: {
    targetYield: number | null;
    humidityAreaType: TerrazoHumidityAreaType | null;
    targetGrainQuality: TerrazoGrainQuality | null;
    averageFertilizerRate1N: number | null;
    averageFertilizerRate2N: number | null;
  };
  validationError: boolean;
};

export type FertilizationCerealsZone = Zone & {
  minNdvi: number | undefined;
  maxNdvi: number | undefined;
  avgNdvi: number | undefined;
};

type ApiData<Type> = {
  loading: boolean;
  error: null | string;
  data: Type | null;
};
export type FieldTimestamps = ApiData<TerrazoFieldTimestampEntry[]>;
export type Zones = ApiData<FertilizationCerealsZone[]>;

export type ZoneMap = {
  date: UnixTimestamp | null;
  numberOfZones: number;
  zoningMode: ZoningMode;
  borderZoneCorrection: boolean;
  visualizationMode: VisualizationMode;
};

export type ApplicationMapsFertilizationCerealsState = BaseWorkflowStoreModuleState & {
  fieldTimestamps: FieldTimestamps;
  zoneMap: ZoneMap;
  calculation: FertilizationCalculation;
  application: Application;
  zones: Zones;
};

export type TaskDataBody = {
  version: string;
  data: TaskData;
};

export type TaskData = {
  companyId: string;
  processOrderId: string;
  activityId: string;
  timeStart: UnixTimestamp;
  state: string;
  fields: { fieldId: string; processedArea: number }[];
  workingMeans: { workingMeanId: string; amount: number | null; unitId: string | null }[];
  applicationMap: ApplicationMap;
  userComment: string | null;
};

export type ApplicationMap = {
  additionalData: AdditionalApplicationMapData;
  geoJson: Heatmaps;
  companyId: string;
  workflowKey: WorkflowKeyForRoutes;
};

export type AdditionalApplicationMapData = {
  fields: { id: string; geoJsonId: string | null }[];
  zoneGenerationMode: 'satellite' | 'upload';
  calculation: FertilizationCalculation & {
    averageDosage: number;
  };
  zoneDosage: FertilizationZoneDosage[];
  application: Application;
  zoneMap: ZoneMap;
};
