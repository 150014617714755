import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { Company } from '@/auth/store/types';

import { BASE_PATH } from '../common';
import { ApiRequestOptions, ApiResponse, Filter, Product, ProductCategoryType } from '../models';
import { apiOptionsToSearchParams, dataToBase64, urlSearchParamsToUnencodedString } from '../requestUtils';

// eslint-disable-next-line import/prefer-default-export
export async function fetchProducts<ProductType = Product, Resolve = undefined, ResolveInSeparateList = undefined>(
  companies: Company[],
  categoryType: ProductCategoryType,
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
): Promise<AxiosResponse<ApiResponse<ProductType[], Resolve, ResolveInSeparateList>>> {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  const filter = getFilter(companies, categoryType);
  searchParams.append('filter', dataToBase64(filter));

  const url = `${BASE_PATH}/material?${urlSearchParamsToUnencodedString(searchParams)}`;

  return axios.get<ApiResponse<ProductType[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}

function getFilter(companies: Company[], categoryType: ProductCategoryType) {
  const companyIds = companies.map((company) => company.id);
  const regionIds = companies.map((company) => company.regionId);

  const filter: Filter = [
    'AND',
    ['systemOnly', '=', false],
    ['category.type', '=', categoryType],
    ['regionId', 'IN', regionIds],
    ['OR', ['companyId', 'IS', null], ['companyId', 'IN', companyIds]],
  ];

  return filter;
}
