
import Vue, { type PropType, defineComponent } from 'vue';

import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import { type DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

const t = Vue.i18n.translate;

export default defineComponent({
  name: 'ControlNumZones',
  components: { FormFieldDropdown },
  props: {
    quantisationCode: {
      type: Object as PropType<DropdownItem<{ id: string; name: string }>>,
      required: false,
      default: () => ({ id: 'medium', name: t('Mittel') }),
    },
  },
  emits: ['update:quantisationCode'],
  model: {
    prop: 'quantisationCode',
    event: 'update:quantisationCode',
  },
  data(): {
    quantisationCodes: DropdownItem<{ id: string; name: string }>[];
    dropdownItems: DropdownItemsPerCategory[];
  } {
    const items = [
      { id: 'high', name: t('Hoch')! },
      { id: 'medium', name: t('Mittel')! },
      { id: 'low', name: t('Gering')! },
    ];
    return {
      quantisationCodes: items,
      dropdownItems: [
        {
          items,
        },
      ],
    };
  },
});
