import { ActionContext, ActionTree } from 'vuex';

import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import setActivityTypeOrSibling from '@/activities/utils/activity/setActivityTypeOrSibling';
import { syncName } from '@/activityTypes/store/compatibleActivityTypesFilter';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { ImportActivitiesState, ImportActivityEquipmentCombined, ImportActivityProductCombined } from './types';

type ActionPayloads = {
  addImportActivityProduct: string;
  addImportActivityEquipment: string;
  removeImportActivityProduct: { importActivityId: string; importActivityProductId: string };
  removeImportActivityEquipment: { importActivityId: string; importActivityEquipmentId: string };
  updateActivityType: { importActivityId: string; importActivityTypeId: string };
};

const actions: ActionTree<ImportActivitiesState, RootState> = {
  ...subscribableStore.actions,

  addImportActivityProduct({ state, commit }, importActivityId: ActionPayloads['addImportActivityProduct']) {
    const importActivity = state.data[importActivityId];
    if (!importActivity) return;

    const newImportActivityProduct = ActivityProductService.createActivityProduct();

    const updatedImportActivityProducts = [...importActivity.products, newImportActivityProduct];
    commit('updateEntryByKeyAndValue', {
      guid: importActivity.id,
      key: 'products',
      value: updatedImportActivityProducts,
    });
  },
  addImportActivityEquipment({ state, commit }, importActivityId: ActionPayloads['addImportActivityEquipment']) {
    const importActivity = state.data[importActivityId];
    if (!importActivity) return;

    const newImportActivityEquipment = ActivityEquipmentService.createActivityEquipment();

    const updatedImportActivityEquipment = [...importActivity.equipment, newImportActivityEquipment];
    commit('updateEntryByKeyAndValue', {
      guid: importActivity.id,
      key: 'equipment',
      value: updatedImportActivityEquipment,
    });
  },

  removeImportActivityProduct(
    { state, commit },
    { importActivityId, importActivityProductId }: ActionPayloads['removeImportActivityProduct'],
  ) {
    const importActivity = state.data[importActivityId];
    if (!importActivity) return;

    const updatedActivityProducts = importActivity.products.filter(
      (product: ImportActivityProductCombined) => product.id !== importActivityProductId,
    );
    commit('updateEntryByKeyAndValue', { guid: importActivity.id, key: 'products', value: updatedActivityProducts });
  },
  removeImportActivityEquipment(
    { state, commit },
    { importActivityId, importActivityEquipmentId }: ActionPayloads['removeImportActivityEquipment'],
  ) {
    const importActivity = state.data[importActivityId];
    if (!importActivity) return;

    const updatedActivityEquipment = importActivity.equipment.filter(
      (equipment: ImportActivityEquipmentCombined) => equipment.id !== importActivityEquipmentId,
    );
    commit('updateEntryByKeyAndValue', { guid: importActivity.id, key: 'equipment', value: updatedActivityEquipment });
  },

  updateActivityType(
    { getters, rootGetters, commit }: ActionContext<ImportActivitiesState, RootState>,
    { importActivityId, importActivityTypeId }: ActionPayloads['updateActivityType'],
  ) {
    const activity = getters.getById(importActivityId);
    const activityType = rootGetters['activityTypes/getById'](importActivityTypeId);
    const updatedActivity = setActivityTypeOrSibling(
      activity,
      syncName(activityType),
      rootGetters['auth/getCompanyByProcessOrderId'],
      rootGetters['activityTypes/getActivityTypeOfCompanyBySyncName'],
      rootGetters['activityTypes/getBySyncName'],
    );

    commit('updateEntryByKeyAndValue', {
      guid: importActivityId,
      key: 'activityTypeId',
      value: updatedActivity.activityTypeId,
    });
  },
};

export default actions;
