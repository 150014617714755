<template lang="html">
  <div class="index-graph px-3">
    <div v-show="loading" class="loading">
      <FontAwesomeIcon class="text-primary" icon="circle-notch" spin />
    </div>
    <div class="date-picker">
      <FormFieldDatepicker
        @setSelectedDate="(date) => (selectedTimestamp = date)"
        v-model="defaultDate"
        variant="horizontal"
        :label="$t('Datum des Vegetationsindex')"
        :loading="overlay.loading"
        :disabledDates="disabledDates"
        :step="5"
      />
    </div>
    <div>
      <div class="w-100 border-bottom border-medium index-picker">
        <Tabs :tabs="indexTypes.slice(0, 3)" :size="variant === 'widget' ? 'lg' : 'md'" :fill="true" />
        <BDropdown class="m-0 p-0" size="md" variant="outline" :text="$t('Mehr')">
          <BDropdownItem
            v-for="indexType in indexTypes.slice(3)"
            :key="indexType.label"
            @click="
              () => {
                rotateBy = indexType.rotateBy;
                indexType.onClick();
              }
            "
            >{{ indexType.label }}
          </BDropdownItem>
        </BDropdown>
      </div>
      <div v-if="!overlay.folded" class="main">
        <LineChart
          v-show="chartData"
          :chartData="chartData"
          :selectedValueX="loading || dismissVerticalIndicator ? null : selectedTimestamp"
          :minX="minX.unix()"
          :maxX="maxX.unix()"
          :minY="0"
          :axisData="axisData"
          :missingDataMessage="$t('Keine Satellitendaten für den ausgewählten Zeitraum verfügbar.')"
          :nDecimals="2"
          @prev="previousDate"
          @restore="resetDate"
          @next="nextDate"
          @mouseover="dismissVerticalIndicator = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faCircleNotch, faPlay } from '@fortawesome/pro-solid-svg-icons';
import zip from 'lodash.zip';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import { identifier } from '@/precision-farming/monitoring/sen4/store/common';
import Tabs from '@/shared/components/Tabs.vue';
import LineChart from '@/shared/components/charts/LineChart.vue';
import FormFieldDatepicker from '@/shared/components/form/FormFieldDatepicker.vue';
import dateSwitcher from '@/shared/mixins/charts/dateSwitcher';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

library.add(faPlay, faCalendarAlt, faCircleNotch);

export default {
  name: 'IndexGraph',
  mixins: [dateSwitcher],
  components: {
    Tabs,
    LineChart,
    FormFieldDatepicker,
  },
  props: {
    hoveredPolygon: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'compact',
      validator: (val) => ['compact', 'widget'].includes(val),
    },
  },
  mounted() {
    // query initial heatmap
    this.selectedAggregate = 'Daily';
    this.selectedTimestamp = this.defaultDate;
  },
  data() {
    return {
      selectedValueX: null,
      disabledDates: {
        customPredictor: (date) => {
          const selectedDate = moment(date);
          const nowIn16Days = moment().add(16, 'days');
          return selectedDate.isAfter(nowIn16Days);
        },
      },
      pageIndex: 0,
      dismissVerticalIndicator: false,
      rotateBy: 0,
    };
  },
  methods: {
    rotateArrayBy(arr, times) {
      const newArr = [...arr];
      for (let i = 0; i < times; i += 1) {
        newArr.push(newArr.shift());
      }
      return newArr;
    },
    toSen4IndexType(indexType) {
      switch (indexType) {
        case 'DNN_NDVI':
          return 'DnnNdvi';
        case 'DNN_LAI':
          return 'DnnLai';
        case 'DNN_NDWI':
          return 'DnnNdwi';
        case 'DNN_CIRE':
          return 'DnnCire';
        case 'MBI':
          return 'Mbi';
        case undefined:
          return undefined;
        default:
          throw new Error(`Unsupported index type: ${indexType}`);
      }
    },
    toGraphDataPoints(lineData, lineType) {
      const relevanceFn = this.toRelevanceFunction(lineType);
      const fieldName = this.polygons[lineData.developerPayload].name;
      const dataPoints = zip(lineData.data?.time, lineData.data?.value)
        .map(([x, y]) => [this.toMoment(x), y ?? 0])
        .filter(relevanceFn)
        .map(([x, y]) => ({
          x: x.unix(),
          y,
          caption: this.caption(x),
          placeholder: y === 0,
        }));

      return {
        ...lineType,
        dataPoints,
        name: this.selectedFields.length > 1 ? fieldName : null,
        greyedOut: this.hoveredPolygon !== lineData.developerPayload && this.hoveredPolygon !== null,
      };
    },
    toGraphArea(lineData, lineType) {
      const dataPoints = zip(lineData.data?.time, lineData.data?.min, lineData.data?.max)
        .map(([x, yMin, yMax]) => [this.toMoment(x), yMin ?? 0, yMax ?? 0])
        .map(([x, yMin, yMax]) => ({
          x: x.unix(),
          yMin,
          yMax,
          caption: this.caption(x),
          placeholder: yMin === 0 || yMax === 0,
        }));
      return { ...lineType, dataPoints };
    },
    toRelevanceFunction(lineType) {
      if (lineType.isMean) {
        return ([time]) => time.isSameOrBefore(moment(), 'day');
      }
      if (lineType.isForecast) {
        return ([time]) => time.isAfter(moment(), 'day');
      }
      if (lineType.isMeanOverall) {
        return () => !['MBI'].includes(this.selectedIndexType);
      }

      throw new Error('Unsupported lineType', lineType);
    },
  },
  computed: {
    ...mapGetters('precisionFarming/monitoring', [
      'overlay',
      'overlayFolded',
      'polygons',
      'multiPolyTimestamps',
      'vegetationData',
      'selectedHeatmapDbId',
      'selectedFields',
    ]),
    ...mapState('precisionFarming/monitoring/sen4', {
      loading: 'fetching',
      sen4Actual(state) {
        return this.requestParams
          .map((params) => ({ ...params, statistics: 'No' }))
          .map(identifier)
          .map((requestId) => state.data[requestId])
          .filter(notNullOrUndefined);
      },
      sen4Mean(state) {
        return this.requestParams
          .map((params) => ({ ...params, statistics: 'MeanY5' }))
          .map(identifier)
          .map((requestId) => state.data[requestId])
          .filter(notNullOrUndefined);
      },
    }),
    requestParams() {
      return this.selectedFields.map((fieldId) => ({
        fieldId,
        startDate: this.minX.format('YYYY-MM-DD'),
        endDate: this.maxX.format('YYYY-MM-DD'),
        indexType: this.toSen4IndexType(this.selectedIndexType),
        statistics: 'No',
        developerPayload: fieldId,
      }));
    },
    selectedIndexType: {
      get() {
        return this.$store.state.precisionFarming.monitoring.selectedIndexType;
      },
      set(selectedIndexType) {
        this.$store.dispatch('precisionFarming/monitoring/setSelectedDnnIndexType', selectedIndexType);
        this.$store.dispatch('precisionFarming/monitoring/loadDnnHeatmaps');
      },
    },
    selectedTimestamp: {
      get() {
        return this.$store.state.precisionFarming.monitoring.selectedTimestamp;
      },
      set(selectedDate) {
        const timestamp = moment(selectedDate).startOf('day').unix();
        this.$store.dispatch('precisionFarming/monitoring/setSelectedTimestamp', timestamp);
        if (!this.loading) {
          this.$store.dispatch('precisionFarming/monitoring/loadDnnHeatmaps');
        }
        this.dismissVerticalIndicator = false;
      },
    },
    chartData() {
      const minMaxAreas = this.sen4Actual
        .filter(() => this.selectedFields.length === 1)
        .map((lineData) => this.toGraphArea(lineData, { isMinMax: true }));
      const actualMeanLines = this.sen4Actual.map((lineData) => this.toGraphDataPoints(lineData, { isMean: true }));
      const forecastLines = this.sen4Actual.map((lineData) => this.toGraphDataPoints(lineData, { isForecast: true }));
      const overallMeanLines = this.sen4Mean
        .filter(() => this.selectedFields.length === 1)
        .map((lineData) => this.toGraphDataPoints(lineData, { isMeanOverall: true }));
      return [...minMaxAreas, ...overallMeanLines, ...actualMeanLines, ...forecastLines]; // order matters
    },
    minX() {
      return moment(this.navigationDate).startOf('year');
    },
    maxX() {
      return this.minX.clone().endOf('year');
    },
    axisData() {
      const axisData = [];
      for (const iDate = this.minX.clone(); iDate.isSameOrBefore(this.maxX); iDate.add(1, 'days')) {
        const isHighlight = this.isHighlight(iDate);
        if (isHighlight) {
          axisData.push({
            caption: this.axisLabel(iDate),
            x: iDate.unix(),
            highlight: isHighlight,
          });
        }
      }
      return axisData;
    },
    indexTypes() {
      const indexTypes = [
        {
          type: 'button',
          onClick: () => {
            this.selectedIndexType = 'DNN_NDVI';
          },
          label: this.$t('NDVI - Biomasse'),
          /* eslint-disable  vue/no-computed-properties-in-data */
          active: this.selectedIndexType === 'DNN_NDVI',
          rotateBy: 0,
        },
        {
          type: 'button',
          onClick: () => {
            this.selectedIndexType = 'DNN_LAI';
          },
          label: this.$t('LAI - Blattflächenindex'),
          active: this.selectedIndexType === 'DNN_LAI',
          rotateBy: 1,
        },
        {
          type: 'button',
          onClick: () => {
            this.selectedIndexType = 'DNN_NDWI';
          },
          label: this.$t('NDWI - Wassergehalt'),
          active: this.selectedIndexType === 'DNN_NDWI',
          rotateBy: 2,
        },
        {
          type: 'button',
          onClick: () => {
            this.selectedIndexType = 'DNN_CIRE';
          },
          label: this.$t('CIre - Chlorophyll'),
          active: this.selectedIndexType === 'DNN_CIRE',
          rotateBy: 3,
        },
        {
          type: 'button',
          onClick: () => {
            this.selectedIndexType = 'MBI';
          },
          label: this.$t('MBI - Mehrjährige Biomasse'),
          active: this.selectedIndexType === 'MBI',
          rotateBy: 4,
        },
      ];
      return this.rotateArrayBy(indexTypes, this.rotateBy);
    },
  },
  watch: {
    requestParams(params) {
      params
        .map((param) => ({
          ...param,
          statistics: 'No',
        }))
        .forEach((payload) => this.$store.dispatch('precisionFarming/monitoring/sen4/vegetationIndexGraph', payload));
      params
        .map((param) => ({
          ...param,
          statistics: 'MeanY5',
        }))
        .forEach((payload) => this.$store.dispatch('precisionFarming/monitoring/sen4/vegetationIndexGraph', payload));
    },
    selectedFields() {
      this.$store.dispatch('precisionFarming/monitoring/loadDnnHeatmaps');
    },
  },
  filters: {
    timestampToDate(timestamp) {
      return moment.unix(timestamp).format('DD.MM');
    },
    timestampToYear(timestamp) {
      return moment.unix(timestamp).format('YYYY');
    },
  },
};
</script>

<style lang="css" scoped>
.index-graph {
  width: 100%;
  position: relative;
}

.date-picker {
  margin-bottom: 15px;
}

.date-picker__prev svg:first-child {
  margin-left: 0;
}

.index-picker {
  display: flex;
  justify-content: space-between;
}

.index-picker > * {
  flex-shrink: 0;
}

h5 {
  font-size: 14px;
  font-weight: 600;
}

.main {
  position: relative;
  min-height: 100px;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20pt;
}
</style>
