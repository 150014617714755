<template lang="html">
  <div class="topbar">
    <div>
      <BLink v-if="$route.meta.back != null" class="topbar__back-link text-black" :to="$route.meta.back.to">
        <FontAwesomeIcon class="mr-2" icon="long-arrow-left" />{{ $route.meta.back.label }}
      </BLink>
      <h1 class="position-relative">
        {{ title }}
        <DebugInstanceBadge />
      </h1>
      <p v-if="typeof $route.meta.subheader === 'string'" class="topbar__subheader m-0">{{ $route.meta.subheader }}</p>
    </div>
    <MetaNav class="topbar__meta-nav" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import {
  faBell,
  faEnvelope,
  faInfoCircle,
  faLongArrowLeft,
  faQuestionCircle,
  faSignOutAlt,
  faUser,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons';

import DebugInstanceBadge from '@/shared/components/DebugInstanceBadge.vue';

import MetaNav from './MetaNav.vue';

library.add(
  faBell,
  faUser,
  faSignOutAlt,
  faQuestionCircle,
  faUserCircle,
  faLongArrowLeft,
  faShoppingCart,
  faEnvelope,
  faInfoCircle,
);

export default {
  name: 'TheTopbar',
  components: { MetaNav, DebugInstanceBadge },
  computed: {
    title() {
      if (typeof this.$route.meta.label === 'string') {
        return this.$route.meta.label;
      }
      if (typeof this.$route.meta.label === 'function') {
        return this.$route.meta.label(this.$route);
      }
      return 'FARMDOK';
    },
  },
};
</script>

<style lang="css" scoped>
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 15px;
  min-height: 50px;
}

.topbar__back-link {
  display: block;
  margin-top: -7px;
  margin-bottom: 8px;
  font-size: 12px;
}

.topbar__subheader {
  font-size: 12px;
}

@media (max-width: 767px) {
  .topbar__meta-nav {
    display: none;
  }
}

@media print {
  .topbar {
    display: none;
  }
}
</style>
