var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart-tooltip",class:{
    'chart-tooltip--move-left-90': _vm.overflowPercent > 0.3,
    'chart-tooltip--move-left-80': _vm.overflowPercent > 0.2 && _vm.overflowPercent <= 0.3,
    'chart-tooltip--move-left-70': _vm.overflowPercent > 0.1 && _vm.overflowPercent <= 0.2,
    'chart-tooltip--move-left-60': _vm.overflowPercent > 0 && _vm.overflowPercent <= 0.1,
  },style:({
    top: `${_vm.top}px`,
    left: `${_vm.left}px`,
  })},[(_vm.showColors)?_c('div',{staticClass:"chart-tooltip__colors"},_vm._l((_vm.colors),function(color,index){return _c('span',{key:`color-${index}`},[_c('span',{style:({ background: color })})])}),0):_vm._e(),_c('div',{staticClass:"chart-tooltip__descriptions"},_vm._l((_vm.descriptions),function(description,index){return _c('span',{key:`description-${index}`},[_vm._v(" "+_vm._s(description)+" ")])}),0),_c('div',{staticClass:"chart-tooltip__values"},_vm._l((_vm.values),function(value,index){return _c('span',{key:`value-${index}`},[_vm._v(" "+_vm._s(value)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }