<template>
  <h3 class="form-headline" @click="(event) => $emit('click', event)">
    <slot />
  </h3>
</template>

<script>
export default {
  name: 'FormHeadline',
};
</script>

<style scoped>
.form-headline {
  margin-top: var(--spacer_2);
  margin-bottom: var(--spacer_2);
  padding: 7px 17px;
  border-radius: 2px;
  background: var(--dark);
  color: var(--white);
  font-size: 12px;
  font-weight: 600;
}
</style>
