import { Module } from 'vuex';

import { initialState as initialStateRemovable } from '@/shared/mixins/store/removableData';
import { initialState as initialStateRestorable } from '@/shared/mixins/store/restorableData';
import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import initialStateSyncable from '@/shared/mixins/store/syncableData/initialState';
import { RootState } from '@/store/types';

import actions from './actions';
import { amaCodes, catchCropVariants, gwAreas, kindOfUseTypes } from './common';
import getters from './getters';
import mutations from './mutations';
import { FieldsState } from './types';

export function initialState(): FieldsState {
  return {
    ...initialStateSubscribable(),
    ...initialStateSyncable(),
    ...initialStateRemovable(),
    ...initialStateRestorable(),
    kindOfUseTypes,
    catchCropVariants,
    amaCodes,
    gwAreas,
    loadingNdvi: false,
    loadingMbi: false,
  };
}

const fieldsModule: Module<FieldsState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};

export default fieldsModule;
