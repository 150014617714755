import { Activity, ActivityProduct } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import { ActionContext } from 'vuex';

import ActivityProductService from '@/activities/services/ActivityProductService';
import ActivityService from '@/activities/services/ActivityService';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../types';
import initServices from './utils/initServices';

type UpdateValues = {
  referenceProductId: string;
  storagePlaceId: string | null;
};

export default function updateProductOnActivityProductInAllActivities(
  { state, commit, rootState, rootGetters }: ActionContext<CreateEditActivityState, RootState>,
  data: { activityProductId: string; updateValues: UpdateValues },
): void {
  const { activityProductService, companyService, activityService, findService } = initServices(
    state,
    rootState,
    rootGetters,
  );
  const referenceActivityProduct = _cloneDeep(findService.getActivityProduct(data.activityProductId));

  state.activities.forEach((activity) => {
    const clonedActivity = _cloneDeep(activity);
    const family = activityService.findActivityProductsOrSiblings(clonedActivity, referenceActivityProduct);
    const companyId = companyService.getCompanyByProcessOrder(activity.processOrderId).id;

    if (family.length > 0) {
      updateProducts(family, data.updateValues, companyId, clonedActivity.id, activityProductService, commit);
    } else {
      addNewProduct(clonedActivity, data.updateValues, companyId, activityService, activityProductService, commit);
    }
  });
}

function updateProducts(
  products: ActivityProduct[],
  updateValues: UpdateValues,
  companyId: string,
  activityId: string,
  activityProductService: ActivityProductService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  products.forEach((p) => {
    updateProduct(p, updateValues, companyId, activityId, activityProductService, commit);
  });
}

function updateProduct(
  activityProduct: ActivityProduct,
  updateValues: UpdateValues,
  companyId: string,
  activityId: string,
  activityProductService: ActivityProductService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  const updatedProduct = updateActivityProductValues(activityProduct, updateValues, companyId, activityProductService);
  commit('setActivityProduct', { activityId, activityProduct: updatedProduct });
}

function addNewProduct(
  activity: Activity,
  updateValues: UpdateValues,
  companyId: string,
  activityService: ActivityService,
  activityProductService: ActivityProductService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  const product = ActivityProductService.createActivityProduct();
  const activityProduct = updateActivityProductValues(product, updateValues, companyId, activityProductService);
  activityService.addActivityProduct(activity, activityProduct);

  commit('addActivityProduct', { activityId: activity.id, activityProduct: activity.products.slice(-1)[0] });
}

function updateActivityProductValues(
  activityProduct: ActivityProduct,
  { referenceProductId, storagePlaceId }: UpdateValues,
  companyId: string,
  activityProductService: ActivityProductService,
): ActivityProduct {
  let updatedProduct = activityProductService.setProductOrSibling(activityProduct, referenceProductId, companyId);
  const unitId = activityProductService.getProduct(updatedProduct)?.unitId ?? null;
  updatedProduct = ActivityProductService.setUnitId(updatedProduct, unitId);
  updatedProduct = ActivityProductService.setStoragePlaceId(updatedProduct, storagePlaceId);
  return updatedProduct;
}
