export enum WorkflowKey {
  SEEDING = 'seeding',
  FERTILIZATION = 'fertilization',
  FERTILIZATION_CEREALS = 'fertilizationCereals',
  SPRAYING = 'spraying',
}
export enum WorkflowKeyForRoutes {
  SEEDING = 'seeding',
  FERTILIZATION = 'fertilization',
  FERTILIZATION_CEREALS = 'fertilization-cereals',
  SPRAYING = 'spraying',
}
