import Handsontable from 'handsontable';
import Vue from 'vue';

const { fastInnerHTML } = Handsontable.dom;

export default function footerRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  Handsontable.renderers.getRenderer('base')(instance, TD, row, col, prop, value, cellProperties, ...args);
  if (typeof value === 'number' || typeof value === 'string') {
    fastInnerHTML(TD, value);
  }
  TD.classList.add('cell-footer', 'bg-lightest', 'border-top', 'border-right-0', 'text-bold');
  Vue.set(TD.style, 'height', '30px');
}
