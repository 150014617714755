
import { Activity } from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import FirstStep from '@/activities/modals/createEditActivity/components/FirstStep.vue';
import { syncName } from '@/activityTypes/store/compatibleActivityTypesFilter';
import { Getters as ActivityTypeGetters } from '@/activityTypes/store/getters';
import { ActivityType } from '@/shared/api/rest/models';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { parseSecondsToTime, parseTimeToSeconds } from '@/shared/modules/parseTimeToSeconds';
import { fromUnixTimestamp, toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { fileNameToImageUrl } from '@/shared/modules/urlHelpers';
import { RootState } from '@/store/types';

import { IconType } from '../components/IconSelectSection.vue';

export default defineComponent({
  name: 'FirstStepContainer',
  components: {
    FirstStep,
  },
  created() {
    this.$store.dispatch('activityTypes/subscribe');
  },
  computed: {
    ...mapState('auth', ['currentCompanies']),
    ...mapGetters({
      regionCodes: 'auth/currentCompaniesRegionCodes',
    }),
    activityTypesSorted(): ActivityType[] {
      const activityTypes = (
        this.$store.getters[
          'activityTypes/compatibleActivityTypesWithoutSyncNameDuplicates'
        ] as ActivityTypeGetters['compatibleActivityTypesWithoutSyncNameDuplicates']
      )(this.currentCompanies);

      return activityTypes.sort((a, b) => a.sortKey - b.sortKey);
    },
    selectedActivityTypeIds(): string[] {
      const activityTypeIds = (this.$store.state as RootState).activities.createEditActivity.activities
        .map((activity: Activity) => (activity.activityTypeId ? activity.activityTypeId : null))
        .filter(notNullOrUndefined);
      return activityTypeIds;
    },
    availableIconTypes(): IconType[] {
      return this.activityTypesSorted.map(this.toIconType);
    },
    selectedIconTypes(): IconType[] {
      return this.selectedActivityTypeIds.map((activityTypeId: string) =>
        this.toIconType(this.$store.getters['activityTypes/getById'](activityTypeId)),
      );
    },
    timeStarts(): Date[] | undefined {
      const dates = (this.$store.state as RootState).activities.createEditActivity.activities?.map(
        (activity: Activity) => (activity.timeStart ? new Date(fromUnixTimestamp(activity.timeStart)) : new Date()),
      );
      return dates;
    },
    workingTime(): string | null {
      const workingTime = this.$store.getters[`activities/createEditActivity/sumWorkingTime`];
      if (workingTime) {
        return parseSecondsToTime(workingTime);
      }

      return null;
    },
    drivingTime(): string | null {
      const drivingTime = this.$store.getters[`activities/createEditActivity/sumDrivingTime`];
      if (drivingTime) {
        return parseSecondsToTime(drivingTime);
      }

      return null;
    },
    setupTime(): string | null {
      const setupTime = this.$store.getters[`activities/createEditActivity/sumSetupTime`];
      if (setupTime) {
        return parseSecondsToTime(setupTime);
      }

      return null;
    },
    pauseTime(): string | null {
      const pauseTime = this.$store.getters[`activities/createEditActivity/sumPauseTime`];
      if (pauseTime) {
        return parseSecondsToTime(pauseTime);
      }

      return null;
    },
    userComments(): Array<string | null> | undefined {
      const userComments = (this.$store.state as RootState).activities.createEditActivity.activities?.map(
        (activity: Activity) => (activity.userComment ? activity.userComment : null),
      );
      return userComments;
    },
  },
  methods: {
    toIconType(activityType: ActivityType): IconType {
      return {
        id: syncName(activityType),
        displayName: activityType.displayName,
        iconPath: fileNameToImageUrl(`${activityType.appIconName}.svg`, 'icons/activity_type'),
      };
    },
    setIconType(iconType: IconType): void {
      this.$store.dispatch(`activities/createEditActivity/updateActivityType`, iconType.id);
    },
    setTimeStart(date: Date): void {
      this.$store.commit(`activities/createEditActivity/setTimeStart`, toUnixTimestamp(date.getTime()));
    },
    setWorkingTime(time: string): void {
      this.$store.commit(`activities/createEditActivity/distributeWorkingTimeToActivities`, parseTimeToSeconds(time));
    },
    setDrivingTime(time: string): void {
      this.$store.commit(`activities/createEditActivity/distributeDrivingTimeToActivities`, parseTimeToSeconds(time));
    },
    setSetupTime(time: string): void {
      this.$store.commit(`activities/createEditActivity/distributeSetupTimeToActivities`, parseTimeToSeconds(time));
    },
    setPauseTime(time: string): void {
      this.$store.commit(`activities/createEditActivity/distributePauseTimeToActivities`, parseTimeToSeconds(time));
    },
    setUserComment(userComment: string): void {
      this.$store.commit(`activities/createEditActivity/setUserComment`, userComment);
    },
  },
});
