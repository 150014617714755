import axios, { AxiosRequestConfig } from 'axios';

import { BASE_PATH } from '../common';
import { ActivityTypeRough, ApiRequestOptions, ApiResponse } from '../models';
import { apiOptionsToSearchParams } from '../requestUtils';

// eslint-disable-next-line import/prefer-default-export
export async function fetchActivityTypesRough<Resolve = undefined, ResolveInSeparateList = undefined>(
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
) {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  const url = `${BASE_PATH}/activityTypesRough?${searchParams.toString()}`;

  return axios.get<ApiResponse<ActivityTypeRough[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}
