<template>
  <BTooltip
    v-if="cellError"
    :target="cellError.target"
    placement="rightbottom"
    custom-class="handsontable-error-tooltip"
  >
    <p>{{ $t('Fehler') }}</p>
    <p>{{ cellError.errorUserMessage }}</p>
    <FontAwesomeIcon icon="exclamation-square" />
  </BTooltip>
</template>

<script>
export default {
  name: 'TooltipCellError',
  props: {
    cellError: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped></style>
