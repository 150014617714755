import merge from 'lodash.merge';
import traverse from 'traverse';

export default function objectsMatchingProperties(arrayOfObjects) {
  const result = merge({}, ...arrayOfObjects);
  const matchingInfo = {};
  const resultTraverse = traverse(result);
  const matchingInfoTraverse = traverse(matchingInfo);

  arrayOfObjects.forEach((object) => {
    const objectTraverse = traverse(object);
    resultTraverse.forEach(function callback() {
      if (!this.isLeaf) {
        return;
      }

      // already detected non-matchers must not be considered again
      if (matchingInfoTraverse.get(this.path) === false) {
        return;
      }

      // non matching values return null in the result
      // and false in the matchingInfo
      if (this.node !== objectTraverse.get(this.path)) {
        this.update(null);
        matchingInfoTraverse.set(this.path, false);
        return;
      }
      // matching values remain untouched in the result
      // and return true in the matchingInfo
      matchingInfoTraverse.set(this.path, true);
    });
  });
  return {
    result,
    matchingInfo,
  };
}
