import { Unit } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

import { findUnitByName, isRelativeUnit } from './findUnit';

export const calculateTotal = (
  amountRelative: number,
  unitRelative: Unit,
  processedAreaInHa: number,
  units: Data<Unit>,
): number | null => {
  if (!isRelativeUnit(unitRelative) || !unitRelative.byUnitId) throw new Error('Unit is not relative');

  const haUnit = findUnitByName('ha', units);
  if (!haUnit) throw new Error('Ha unit not found');

  if (unitRelative.byUnitId === haUnit.id) {
    return amountRelative * processedAreaInHa;
  }

  const targetTotalUnit = units[unitRelative.byUnitId];
  const processedAreaInTargetUnit = calculateToTargetUnit(processedAreaInHa, haUnit, targetTotalUnit);
  if (!processedAreaInTargetUnit) return null;

  return amountRelative * processedAreaInTargetUnit;
};

export const calculateRelative = (
  amountTotal: number,
  unitRelative: Unit,
  processedAreaInHa: number,
  units: Data<Unit>,
): number | null => {
  if (amountTotal === 0) return 0;
  if (!isRelativeUnit(unitRelative)) throw new Error('Unit is not relative');

  const haUnit = findUnitByName('ha', units);
  if (!haUnit) throw new Error('Ha unit not found');

  if (unitRelative.byUnitId === haUnit.id) {
    return amountTotal / processedAreaInHa;
  }

  const amountPerHa = amountTotal / processedAreaInHa;
  const amountPerTargetUnit = calculateToTargetUnit(amountPerHa, haUnit, unitRelative);
  if (!amountPerTargetUnit) return null;

  return amountPerTargetUnit;
};

export function calculateToTargetUnit(value: number, currentUnit: Unit, targetUnit: Unit): number | null {
  if (!isCompatibleWith(currentUnit, targetUnit)) {
    return null;
  }

  const valueSiUnit = toSi(value, currentUnit);
  if (!valueSiUnit) return null;

  const valueTargetUnit = fromSi(valueSiUnit, targetUnit);
  if (!valueTargetUnit) return null;

  return valueTargetUnit;
}

export function isSiUnit(unit: Unit): boolean {
  return !unit.siUnitId;
}

export function toSi(value: number, unit: Unit): number | null {
  if (isSiUnit(unit)) {
    return value;
  }
  if (!unit.siFactor) return null;

  return value * unit.siFactor;
}

export function fromSi(value: number, unit: Unit): number | null {
  if (isSiUnit(unit)) {
    return value;
  }
  if (!unit.siFactor) return null;

  return value / unit.siFactor;
}

export function isCompatibleWith(currentUnit: Unit, targetUnit: Unit): boolean {
  return (
    ((currentUnit.siUnitId === targetUnit.siUnitId && currentUnit.siUnitId !== null && targetUnit.siUnitId !== null) ||
      currentUnit.id === targetUnit.siUnitId ||
      currentUnit.siUnitId === targetUnit.id) &&
    getUnitKind(currentUnit) === getUnitKind(targetUnit)
  );
}

export function getUnitKind(unit: Unit): UnitKind {
  if (unit.isPerLoad) {
    return UnitKind.PER_LOAD;
  }
  if (unit.byUnitId) {
    return UnitKind.DIVISOR;
  }
  return UnitKind.TOTAL;
}

export enum UnitKind {
  TOTAL,
  DIVISOR,
  PER_LOAD,
}
