import weightedAverage from '@/shared/modules/weightedAverage';

import { clampInRange, roundToPrecision } from '../common';
import { Calculation, SeedingZone } from '../types';

export default function calcAreaWeightedVarietyMeanRoundedAndClamped(zones: SeedingZone[], calculation: Calculation) {
  const mean = calcAreaWeightedVarietyMean(zones);
  const meanRounded = roundToPrecision(mean);
  const meanRoundedClamped = clampInRange(meanRounded, calculation.seedrateMin, calculation.seedrateMax);

  return meanRoundedClamped;
}

export function calcAreaWeightedVarietyMean(zones: SeedingZone[]) {
  const scores = zones.map((zone) => zone.varietyValue || 0);
  const weights = zones.map((zone) => zone.size);
  return weightedAverage(scores, weights);
}
