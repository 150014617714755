var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linechart"},[(_vm.chartDataValuesY.length === 0)?_c('p',{staticClass:"linechart__no-data"},[_vm._v(" "+_vm._s(_vm.missingDataMessageComputed)+" ")]):_vm._e(),_c('svg',{staticClass:"linechart__data",attrs:{"width":_vm.width,"height":_vm.height}},[_c('g',{style:({ transform: `translate(${_vm.margin.left - 7}px, ${_vm.margin.top}px)` })},_vm._l((_vm.captionsY),function(caption,index){return _c('text',{key:`caption-${index}`,attrs:{"x":"0","y":caption.y + 3,"text-anchor":"end","font-size":"12"}},[_vm._v(" "+_vm._s(_vm._f("numbro")(caption.value,{ mantissa: 1 }))+" ")])}),0),_c('g',{style:({ transform: `translate(${_vm.margin.left - 7}px, ${_vm.margin.top}px)` })},[_vm._l((_vm.lines),function(line,index){return _c('path',{key:`line-${index}`,class:{
          'linechart__horizontal-helper': line.isHorizontalHelper,
          'linechart__horizontal-helper--zero': line.isZero,
          linechart__line: !line.isHorizontalHelper,
          'linechart__line--mean': line.isMean,
          'linechart__line--forecast': line.isForecast,
          'linechart__line--mean-overall': line.isMeanOverall,
          'linechart__line--min-max': line.isMinMax,
          'linechart__line--mean--transparent': line.greyedOut,
        },attrs:{"d":line.data}})}),(_vm.selectedValueXComputed != null && _vm.scaleX != null)?_c('line',{staticClass:"linechart__vertical-helper",attrs:{"x1":_vm.scaleX(_vm.selectedValueXComputed),"y1":0,"x2":_vm.scaleX(_vm.selectedValueXComputed),"y2":_vm.chartHeight}}):_vm._e(),_vm._l((_vm.circles),function(circle,index){return _c('circle',{key:`circle-${index}`,staticClass:"linechart__circle",class:{
          'linechart__circle--mean': circle.isMean,
          'linechart__circle--forecast': circle.isForecast,
          'linechart__circle--mean-overall': circle.isMeanOverall,
          'linechart__circle--min-max': circle.isMinMax,
        },attrs:{"cx":circle.cx,"cy":circle.cy,"r":circle.r}})})],2)]),(_vm.tooltip)?_c('ChartTooltip',{attrs:{"chart-width":_vm.width,"top":_vm.tooltip.top,"left":_vm.tooltip.left,"entries":_vm.tooltip.entries}}):_vm._e(),_c('svg',{staticClass:"linechart__hover",attrs:{"width":_vm.width,"height":_vm.height}},[_c('g',{style:({ transform: `translate(${_vm.margin.left}px, ${_vm.margin.top}px)` })},_vm._l((_vm.tooltipBars),function(tooltipBar,index){return _c('g',{key:`tooltipBar-${index}`,style:({ transform: `translate(${tooltipBar.left}px, 0px)` })},[_c('rect',{attrs:{"width":tooltipBar.width,"height":_vm.chartHeight,"data-value-x":tooltipBar.valueX},on:{"mouseover":_vm.mouseover,"mouseout":_vm.mouseout}})])}),0)]),(_vm.width != null)?_c('ChartAxisBottom',_vm._g({attrs:{"chartWidth":_vm.width,"navigationWidth":_vm.margin.left,"data":_vm.axisDataComputed}},this.$listeners)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }