import Handsontable from 'handsontable';

import { compareValuesByFilterType } from '@/shared/modules/dataFilters';
import getDisplayValue, { getColumnKey } from '@/shared/modules/getDisplayValue';

import { TableDataBase } from '../../types';
import { FilterByColumnKey, FilterByDate } from './types';

export function filterTable(
  hot: Handsontable,
  filterString: string,
  filtersByColumnKey: Record<string, FilterByColumnKey>,
  filtersByDate: FilterByDate | null,
  tableData: any[],
  tableColumns: Handsontable.ColumnSettings[],
) {
  let rowsToTrim: any[] = [];

  if (filterString.length > 0 || filtersByColumnKey !== null) {
    rowsToTrim = filterByAllFields(filterString, tableData, tableColumns, filtersByColumnKey);
  }

  const dateRowsToTrim = filterByDates(filtersByDate, tableData);

  rowsToTrim = [...dateRowsToTrim, ...rowsToTrim];
  const physicalIndices = rowsToTrim.map((rowToTrim) => rowToTrim.index);
  const plugin = getTrimRowsPlugin(hot);
  resetFilter(plugin);
  plugin.trimRows(physicalIndices);
}

export function filterTableByPhysicalIndices(hot: Handsontable, physicalRowIndices: number[]) {
  const plugin = getTrimRowsPlugin(hot);
  plugin.trimRows(physicalRowIndices);
}

export function resetFilter(hotOrPlugin: Handsontable | Handsontable.plugins.TrimRows) {
  const plugin = hotOrPlugin instanceof Handsontable.plugins.TrimRows ? hotOrPlugin : getTrimRowsPlugin(hotOrPlugin);
  plugin.untrimAll();
}

export function getFilteredRows(hot: Handsontable) {
  const plugin = getTrimRowsPlugin(hot);
  return plugin.getTrimmedRows();
}

function getTrimRowsPlugin(hot: Handsontable) {
  const trimRowsPlugin = hot.getPlugin('trimRows');
  if (!trimRowsPlugin) throw new Error('TrimRows plugin not found');

  return trimRowsPlugin;
}

/**
 * Filters the table by the given needle and the given filtersByColumnKey.
 * If used with subtables, the filter will also include all rows with the same id, so if a subrow is found,
 * the corresponding parent and all other subrows will be included too.
 * @param needle
 * @param data
 * @param columns
 * @param filtersByColumnKey
 * @returns
 */
export function filterByAllFields(
  needle: string = '',
  data: any[] = [],
  columns: Handsontable.ColumnSettings[] = [],
  filtersByColumnKey: Record<string, FilterByColumnKey> | null = null,
) {
  if (needle.length === 0 && (filtersByColumnKey == null || Object.keys(filtersByColumnKey).length === 0)) {
    return [];
  }
  const matchedRows: any[] = [];
  data.forEach((entry) => {
    let match = false;
    // check if the entry contains the needle in ANY column
    if (needle) {
      match = columns.some((column) => {
        const displayValue = getDisplayValue(entry, column);
        if (displayValue.length === 0) {
          return false;
        }
        if (Array.isArray(displayValue)) {
          return displayValue.some((element) => element.toLowerCase().includes(needle.toLowerCase()));
        }
        return displayValue.toLowerCase().includes(needle.toLowerCase());
      });
      if (match) {
        matchedRows.push(entry);
        return;
      }
    }

    // check if the entry matches all column specific filters too
    if (filtersByColumnKey == null || Object.keys(filtersByColumnKey).length === 0) {
      return;
    }
    match = columns.every((column) => {
      const columnKey = getColumnKey(column);
      if (filtersByColumnKey[columnKey] == null) {
        return true;
      }
      const displayValue = getDisplayValue(entry, column);
      if (Array.isArray(filtersByColumnKey[columnKey].selectedValues)) {
        if (Array.isArray(displayValue)) {
          return displayValue.some((value) => filtersByColumnKey[columnKey].selectedValues.includes(value));
        }
        return filtersByColumnKey[columnKey].selectedValues.includes(displayValue);
      }
      if (typeof filtersByColumnKey[columnKey].value !== 'string' || filtersByColumnKey[columnKey].value.length === 0) {
        return true;
      }
      return compareValuesByFilterType(
        displayValue,
        filtersByColumnKey[columnKey].value,
        filtersByColumnKey[columnKey].type,
      );
    });
    if (match) {
      matchedRows.push(entry);
    }
  });

  const matchedIds = new Set(matchedRows.flatMap((row) => (row.id === undefined ? [] : [row.id])));

  // search for all rows with the same id as the matched rows
  const dependentRows = matchedRows.concat(data.filter((object) => matchedIds.has(object.id)));

  // invert the matched rows to get the not matched rows
  const notMatchedRows = data
    .map((entry, index) => ({ entry, index }))
    .filter((item) => {
      if (item.entry.id === undefined) {
        return !dependentRows.includes(item.entry);
      }
      return !dependentRows.some((row) => row.id === item.entry.id);
    });

  return notMatchedRows;
}

export function filterByDates(filter: FilterByDate | null, tableData: any[]) {
  let rowsToTrim: Array<{ entry: TableDataBase; index: number }> = [];

  if (filter && filter.columnKey) {
    rowsToTrim = tableData
      .map((entry, index) => ({ entry, index }))
      .filter((row) => {
        const timestamp = row.entry[filter.columnKey!];
        const date = new Date(timestamp * 1000);

        if (!filter.dateFrom && !filter.dateTo) {
          return false;
        }

        if (filter.dateFrom && !filter.dateTo) {
          return date.setHours(0, 0, 0) < filter.dateFrom.setHours(0, 0, 0);
        }

        if (filter.dateTo && !filter.dateFrom) {
          return date.setHours(0, 0, 0) > filter.dateTo.setHours(0, 0, 0);
        }

        if (filter.dateFrom && filter.dateTo) {
          return (
            date.setHours(0, 0, 0) < filter.dateFrom.setHours(0, 0, 0) ||
            date.setHours(0, 0, 0) > filter.dateTo.setHours(0, 0, 0)
          );
        }
        return true;
      });
    return rowsToTrim;
  }
  return [];
}
