<template>
  <TableBase
    id="hot-contracting-import-fields-yields"
    ref="table"
    disable-read-only-bg
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :table-settings="tableSettings"
    :table-data="tableData"
    :loading="loading"
    @tableMounted="tableMounted"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:filterString="(value) => $emit('update:filterString', value)"
  >
    <template #footer>
      <TableFooter
        :total-rows="Object.keys(tableData).length"
        :visible-rows="Object.keys(tableData).length"
        :selected-rows="selectedRows.length"
      />
    </template>
  </TableBase>
</template>

<script>
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import {
  tableImportFieldsYieldsPotatoes as columnsTableImportFieldsYieldsPotatoes,
  tableImportFieldsYieldsSugarBeets as columnsTableImportFieldsYieldsSugarBeets,
} from '../handsontable/columns/tableImportFieldYields';

export default {
  name: 'TableImportFieldYieldsContainer',
  components: { TableFooter, TableBase },
  mixins: [tableContainerBase],
  props: {
    filterString: {
      type: String,
      default: '',
    },
    fieldsYields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columnDefinition: this.fieldsYields.some((entry) => entry.kulturart === 'R')
        ? columnsTableImportFieldsYieldsSugarBeets
        : columnsTableImportFieldsYieldsPotatoes,
      loading: true,
    };
  },
  computed: {
    tableData() {
      return this.fieldsYields.reduce((tableData, field, index) => {
        const id = String(index + 1);
        return {
          ...tableData,
          [id]: {
            ...field,
            id,
          },
        };
      }, {});
    },
    selectedTableData() {
      if (this.selectedRows.length === 0) {
        return null;
      }
      return this.selectedRows.map((id) => this.tableData[id]);
    },
  },
  watch: {
    selectedTableData(newVal) {
      this.$emit('update:selectedTableData', newVal);
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>
