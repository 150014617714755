<template>
  <ThePageSkeleton
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <template #left="{ contentbarLarge }">
          <ButtonDropdown
            v-if="editMode"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonUpdateSelection"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="selectedRows = []"
          />
          <Button
            color="lightest"
            icon="cloud-download"
            leading-icon
            component-namespace-name="ButtonExportManagedContractPartners"
            :title="editMode ? $t('Auswahl exportieren') : $t('Exportieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="exportData"
          />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar v-show="!editMode" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <DropdownPageOptions
            v-show="!editMode"
            class="ml-4"
            @export="exportData"
            @settings="showSettings"
            :hideRestore="true"
          />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TablePartnersContainer
        ref="table"
        :contractId="contractId"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
        @click:status="onClickStatus"
      />
      <ModalPageSettings ref="modalPageSettings" :columns="columnsForPageSettings" />
      <ModalTableExport
        ref="modalTableExport"
        :title="selectedRows.length > 0 ? $t('Selektierte Felder exportieren') : null"
        :filtered="selectedRows.length === 0 && $refs.table != null && !$refs.table.allVisible"
        :availableTypes="[
          {
            value: 'xls',
            label: $t('XLS'),
            text: $t('Als Excel-Datei exportieren'),
          },
        ]"
      />
      <PopoverPartnerStatus
        v-if="partnerStatusTarget !== null"
        :company="partnerStatusCompany"
        :target="partnerStatusTarget"
        @close="partnerStatusTarget = null"
        @reloadAdditionalData="reloadAdditionalData"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import { mapGetters } from 'vuex';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import DropdownPageOptions from '@/shared/components/DropdownPageOptions.vue';
import ModalPageSettings from '@/shared/components/ModalPageSettings.vue';
import ModalTableExport from '@/shared/components/ModalTableExport.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import PopoverPartnerStatus from './components/PopoverPartnerStatus.vue';
import TablePartnersContainer from './containers/TablePartnersContainer.vue';

export default {
  name: 'PageManagedContractPartners',
  components: {
    ThePageSkeleton,
    TheContentbar,
    DropdownPageOptions,
    TablePartnersContainer,
    SearchBar,
    ButtonDropdown,
    Button,
    ModalPageSettings,
    ModalTableExport,
    PopoverPartnerStatus,
  },
  mixins: [buttonUpdateSelection('table')],

  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      columnsForPageSettings: null,
      partnerStatusTarget: null,
      partnerStatusCompany: null,
    };
  },

  computed: {
    ...mapGetters({
      companies: 'managedContracts/companies/data',
    }),
    editMode() {
      return this.selectedRows.length > 0;
    },
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_MANAGED_CONTRACTS);
    },

    contractId() {
      return this.$route.params.contractId;
    },
  },

  methods: {
    async exportData() {
      const { exportFiltered, okay } = await this.$refs.modalTableExport.show();
      if (!okay) {
        return;
      }

      let partnerIds = [];
      if (this.editMode) {
        partnerIds = this.selectedRows;
      } else if (exportFiltered) {
        partnerIds = this.visibleRows;
      } else {
        partnerIds = Object.values(this.$refs.table.tableData).map((partner) => partner.id);
      }
      this.$refs.table.$refs.table.export(partnerIds);
    },

    async showSettings() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      const settings = await this.$refs.modalPageSettings.show();
      if (settings != null && Array.isArray(settings.columns)) {
        this.$refs.table.$refs.table.updateColumnsFromPageSettings(settings.columns);
      }
    },

    async onClickStatus(payload) {
      if (payload == null) {
        this.partnerStatusTarget = null;
        this.partnerStatusCompany = null;
      }
      const { target, guid } = payload;

      this.partnerStatusTarget = null;
      this.partnerStatusCompany = null;
      if (this.partnerStatusTarget === target || !this.companies[guid]) {
        return;
      }

      await this.$nextTick();
      this.partnerStatusTarget = target;
      this.partnerStatusCompany = this.companies[guid];
    },

    reloadAdditionalData() {
      this.$refs.table.loadAdditionalData();
    },
  },
};
</script>
