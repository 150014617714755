import merge from 'lodash.merge';
import Vue from 'vue';

import { REST_BASE_MODEL_KEYS } from '@/shared/constants';

/**
 * Removes all keys defined in REST_BASE_MODEL_KEYS.
 * Additional keys can be given in second argument. Those will be removed too.
 *
 * @param {object} data
 * @param {array|null} additionalKeys
 * @returns {object}
 */
export const requestDataRemoveBaseModelProperties = (data: any, additionalKeys: any) => {
  if (data == null) {
    return {};
  }
  const requestData = { ...data };
  REST_BASE_MODEL_KEYS.forEach((key) => {
    delete requestData[key];
  });
  if (Array.isArray(additionalKeys)) {
    additionalKeys.forEach((key) => {
      delete requestData[key];
    });
  }
  return requestData;
};

export const normalizeValue = (
  value: any,
  definition: { type: string | number; default: string | number; values: any; nullable: boolean },
) => {
  if (definition == null) {
    return value;
  }
  if (definition.type === 'string') {
    return String(value);
  }
  if (definition.type === 'number') {
    if (typeof value === 'number') {
      return value;
    }
    let finalValue: number | null = Number(value);
    if (Number.isNaN(finalValue)) {
      return value;
    }
    if (value == null || value === '') {
      finalValue = null;
    }
    if (finalValue == null) {
      if (typeof definition.default === 'number') {
        finalValue = definition.default;
      } else if (!definition.nullable) {
        finalValue = 0;
      }
    }
    return finalValue;
  }
  if (definition.type === 'enum' && Array.isArray(definition.values)) {
    let foundValue;
    definition.values.some((currentValue) => {
      if (currentValue === value) {
        foundValue = value;
        return true;
      }
      // WORKAROUND: Handsontable editor is converting all values to strings
      if (String(currentValue) === value) {
        foundValue = currentValue;
        return true;
      }
      return false;
    });
    if (foundValue != null) {
      return foundValue;
    }
    if (definition.nullable) {
      return null;
    }
    return definition.default;
  }
  return value;
};

/**
 * Clones the model and data into a new object, therefore setting default values for missing keys in the data.
 * Afterwards removes all keys that are not available in the model.
 *
 * @param data
 * @param model
 * @param modelDefinition
 */
export const requestDataCreateFromEntryAndModel = (
  data: any,
  model: any,
  modelDefinition: Record<string, any> = {},
) => {
  const requestData = merge({}, model, data);
  Object.keys(requestData).forEach((key) => {
    if (!Object.prototype.hasOwnProperty.call(model, key)) {
      delete requestData[key];
    }
    if (Object.prototype.hasOwnProperty.call(modelDefinition, key)) {
      requestData[key] = normalizeValue(requestData[key], modelDefinition[key]);
    }
  });
  return requestData;
};

/**
 * Call this with the response of a request to https://git.farmdok.com/farmdok/AgriDat/-/wikis/REST/Request-standard.
 * The response could be the error object from a a caught axios error, or the json response.
 * This will extract the response from a success and an error hat always looks like:
 * https://git.farmdok.com/farmdok/AgriDat/-/wikis/REST/Response-standard.
 *
 * @param response
 * @param defaultErrorOverride
 * @return {{errorUserMessage: string[], errorCode: string, status: string}}
 */
export const getRestResponseData = (response: any, defaultErrorOverride = {}) => {
  const defaultError = {
    status: 'error',
    errorCode: 'generalError',
    errorUserMessage: [Vue.i18n.translate('Es ist ein unbekannter Fehler aufgetreten.')],
    ...defaultErrorOverride,
  };
  let responseData;
  if (response != null && typeof response.status === 'string') {
    responseData = response;
  } else if (response != null && response.data != null && typeof response.data.status === 'string') {
    responseData = response.data;
  } else if (
    response != null &&
    response.response != null &&
    response.response.data != null &&
    typeof response.response.data.status === 'string'
  ) {
    responseData = response.response.data;
  }
  if (responseData == null) {
    return defaultError;
  }
  if (responseData.status === 'success' || responseData.status === 'downloadSuccess') {
    return responseData;
  }
  if (responseData.status === 'partialSuccess') {
    return {
      errors: {},
      errorUserMessage: [
        Vue.i18n.translate(
          'Es wurden mehrere Operationen durchgeführt. Bei mindestens einer ist ein Fehler aufgetreten.',
        ),
      ],
      ...responseData,
    };
  }
  return {
    ...defaultError,
    ...responseData,
  };
};

/**
 * used to identify request errors in mergeRestResponseData
 */
let requestErrorNumber = 0;

/**
 * Helper function to merge multiple REST responses into one.
 * If all provided response objects are of type 'success', it will return a combined 'success' object.
 * If all provided response objects are of type 'error', it will return a combined 'partialSuccess' object.
 * If all provided response objects are of type 'partialSuccess', it will return a combined 'partialSuccess' object.
 * If the provided response objects are of different types, it will return a response object of type 'partialSuccess'.
 * The returned object will always look like:
 * https://git.farmdok.com/farmdok/AgriDat/-/wikis/REST/Response-standard.
 *
 * @param responses
 * @returns {{errorUserMessage: string[], errorCode: string, status: string}|null}
 */
export const mergeRestResponseData = (...responses: any) => {
  if (responses.length === 0) {
    console.error('mergeRestResponseData called without parameters');
    return null;
  }
  const mergedResponse = responses.reduce((merged: any, current: any) => {
    const result: { version: any; status: any; errorUserMessage?: string[]; data?: any; errors?: any } = {
      version: merged.version != null ? merged.version : current.version,
      status: merged.status != null ? merged.status : current.status,
    };
    if (result.version !== current.version) {
      console.error('mergeRestResponseData called with version mismatch');
      return null;
    }
    if (result.status !== 'success' || current.status !== 'success') {
      result.errorUserMessage = [
        Vue.i18n.translate(
          'Es wurden mehrere Operationen durchgeführt. Bei mindestens einer ist ein Fehler aufgetreten.',
        ) || '',
      ];
      result.status = 'partialSuccess';
    }
    result.data = [];
    if (merged.data != null) {
      result.data = [...(Array.isArray(merged.data) ? merged.data : [merged.data])];
    }
    if (current.data != null) {
      result.data = [...result.data, ...(Array.isArray(current.data) ? current.data : [current.data])];
    }
    // result.resolved = {
    // TODO: iterate over Object.keys(previous.resolved) and Object.keys(current.resolved) and merge each sub array
    // };
    if (result.status === 'success') {
      return result;
    }
    result.errors = {};
    if (merged.status === 'error') {
      requestErrorNumber += 1;
      result.errors[`request-${requestErrorNumber}`] = {
        errorCode: merged.errorCode,
        errorAttributeUserMessages: merged.errorAttributeUserMessages,
        errorUserMessage: merged.errorUserMessage,
      };
    } else if (merged.status === 'partialSuccess') {
      result.errors = {
        ...merged.errors,
      };
    }
    if (current.status === 'error') {
      requestErrorNumber += 1;
      result.errors[`request-${requestErrorNumber}`] = {
        errorCode: current.errorCode,
        errorAttributeUserMessages: current.errorAttributeUserMessages,
        errorUserMessage: current.errorUserMessage,
      };
    } else if (current.status === 'partialSuccess') {
      result.errors = {
        ...result.errors,
        ...current.errors,
      };
    }
    return result;
  }, {});
  return mergedResponse;
};

/**
 * Helper function to determine if a given model has all required fields set. (e.g. for a measure activityId and fieldId are not allowed to be null)
 *
 * @param entry
 * @param modelDefinition
 * @return {this is string[]}
 */
export const modelRequiredFieldsAreSet = (entry: Record<string, any> = {}, modelDefinition: Record<string, any> = []) =>
  Object.keys(modelDefinition).every((key: any) => {
    if (!modelDefinition[key].required) {
      return true;
    }
    if (typeof entry[key] === 'undefined') {
      return false;
    }
    if (entry[key] === null) {
      return !!modelDefinition[key].nullable;
    }
    return true;
  });
