import { Customer, Field, Product } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

export default function getFieldDisplayName(field: Field, customers: Data<Customer>, crops: Data<Product>): string {
  const customerName = getCustomerName(customers, field.fieldGroup.customerId);
  const cropName = getCropName(crops, field.cropId);
  const fieldName = getFieldName(field);
  return `${customerName}${fieldName}${cropName}`;
}

function getCustomerName(customers: Data<Customer>, customerId: string | undefined): string {
  if (!customerId) {
    return '';
  }
  const customer = customers[customerId];
  if (!customer) {
    return '';
  }
  if (customer.shortname) {
    return `${customer.shortname} - `;
  }
  return `${customer.name} - `;
}

function getCropName(crops: Data<Product>, cropId: string | undefined | null): string {
  if (!cropId) {
    return '';
  }
  const crop = crops[cropId];
  if (!crop) {
    return '';
  }
  return ` (${crop.name})`;
}

function getFieldName(field: Field): string {
  if (field.fieldGroup.mfa) {
    return `${field.fieldGroup.mfa} - ${field.name}`;
  }
  return field.name;
}
