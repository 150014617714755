<template>
  <BPopover
    ref="popover"
    show
    placement="bottom"
    custom-class="popover-partner-status shadow"
    :id="id"
    :target="target"
  >
    <h3 class="popover-partner-status__title">
      {{ $t('Partner-Status') }}
    </h3>
    <div class="popover-partner-status__body">
      <p v-if="error" class="popover-partner-status__error">
        {{ errorUserMessage }}
      </p>
      <FormFieldRadioGroup
        v-if="!error"
        v-model="status"
        class="m-0"
        variant="no-label"
        stacked
        @change="(value) => save(value)"
      >
        <FormRadio variant="bordered" value="received" :disabled="fetching">
          <template #default>
            <span>{{ $t('Erhalten') }}</span>
            &nbsp;
            <FontAwesomeIcon
              class="popover-partner-status__icon popover-partner-status__icon--received"
              icon="dot-circle"
            />
          </template>
          <template #text>
            <!-- eslint-disable max-len -->
            {{
              $t(
                'Dein Partner hat Felder für diesen Vertrag bereitgestellt. Dein Partner kann die Felder jederzeit aktualisieren.',
              )
            }}
            <!-- eslint-enable max-len -->
          </template>
        </FormRadio>
        <FormRadio variant="bordered" value="contracted" :disabled="fetching">
          <template #default>
            <span>{{ $t('Kontrahiert') }}</span>
            &nbsp;
            <FontAwesomeIcon
              class="popover-partner-status__icon popover-partner-status__icon--contracted"
              icon="dot-circle"
            />
          </template>
          <template #text>
            <!-- eslint-disable max-len -->
            {{ $t('Der Vertragsabschluss ist erfolgt. Dein Partner kann keine Aktualisierungen senden.') }}
            <!-- eslint-enable max-len -->
          </template>
        </FormRadio>
        <FormRadio variant="bordered" value="canceled" :disabled="fetching">
          <template #default>
            <span>{{ $t('Storniert') }}</span>
            &nbsp;
            <FontAwesomeIcon
              class="popover-partner-status__icon popover-partner-status__icon--canceled"
              icon="dot-circle"
            />
          </template>
          <template #text>
            <!-- eslint-disable max-len -->
            {{ $t('Der Vertrag wurde storniert. Die bereitgestellten Felder werden nicht mehr berücksichtigt.') }}
            <!-- eslint-enable max-len -->
          </template>
        </FormRadio>
      </FormFieldRadioGroup>
    </div>
  </BPopover>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDotCircle } from '@fortawesome/pro-solid-svg-icons';
import vClickOutside from 'v-click-outside';

import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';

library.add(faDotCircle);

const { bind, unbind } = vClickOutside.directive;

let counter = 0;

export default {
  name: 'PopoverPartnerStatus',
  components: { FormFieldRadioGroup, FormRadio },
  props: {
    target: {
      type: HTMLElement,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    activeAndHasHarvestingMeasure: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    counter += 1;
    return {
      id: `popover-partner-status--nr-${counter}`,
      status: this.company.status,
      popover: null,
      error: false,
      fetching: false,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.popover = document.querySelector(`#${this.id}`);
    if (this.popover) {
      bind(this.popover, { value: this.onClickOutside });
    }
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    if (this.popover) {
      unbind(this.popover);
    }
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (e.key === 'Escape') {
        this.$emit('close');
      }
    },
    onClickOutside() {
      this.$emit('close');
    },
    async save(status) {
      if (this.error) {
        this.$emit('close');
        return;
      }
      this.fetching = true;

      await this.$store.dispatch('managedContracts/companies/updateAndSyncEntry', {
        status,
        companyId: this.company.id,
      });
      this.$emit('reloadAdditionalData');
      this.$emit('close');

      this.fetching = false;
    },
  },
};
</script>

<style>
.popover.popover-partner-status {
  min-width: 390px;
}

.popover-partner-status.bs-popover-bottom > .arrow::before,
.popover-partner-status.bs-popover-bottom > .arrow::after {
  border-bottom-color: var(--black);
}

.popover.popover-partner-status .popover-body {
  padding: 0;
}
</style>

<style scoped>
.popover-partner-status__title {
  padding: var(--spacer_2) var(--spacer_3);
  background: var(--black);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--white);
}

.popover-partner-status__body {
  padding: var(--spacer_2) var(--spacer_3);
}

.popover-partner-status__error {
  color: var(--danger_dark);
}

.popover-partner-status__icon {
  font-size: 12px;
}

.popover-partner-status__icon--received {
  color: var(--warning_medium);
}

.popover-partner-status__icon--contracted {
  color: var(--primary);
}

.popover-partner-status__icon--canceled {
  color: var(--gray-400);
}
</style>
