import Handsontable from 'handsontable';

import { selectColumn } from '@/activities/handsontable/columns';
import { CellCoords } from '@/shared/handsontable/types';

export default function multiSelectClickHandlerFactory(hot: Handsontable | null) {
  return function selectClickHandler(event: MouseEvent, { row, col }: CellCoords) {
    if (!hot) {
      return;
    }
    if (!event.shiftKey) {
      return;
    }
    const columnKey = selectColumn.data as string;
    if (hot.colToProp(col) !== columnKey) {
      return;
    }

    const lastSelection = hot.getSelectedLast();
    const lastSelectedRow = lastSelection?.[1] === col ? lastSelection[0] : 0;

    const startRow = Math.min(lastSelectedRow, row);
    const targetRow = Math.max(lastSelectedRow, row);

    const changes: [number, string, Handsontable.CellValue][] = [];
    for (let i = startRow; i <= targetRow; i += 1) {
      changes.push([i, columnKey, true]);
    }
    hot.setDataAtRowProp(changes, 'shift.select');
  };
}
