
import { PropType, defineComponent } from 'vue';

import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

export default defineComponent({
  name: 'PestSelectSection',
  components: { FormFieldDropdown },
  props: {
    selectedDropdownItem: {
      type: Object as PropType<DropdownItem>,
      required: true,
    },
    dropdownItems: {
      type: Array as PropType<DropdownItemsPerCategory[]>,
      required: true,
    },
  },

  data() {
    return {
      defaultSelectedItem: {
        id: '',
        name: '',
      } as DropdownItem,
    };
  },
  computed: {
    showPestSelection(): boolean {
      const allDropdownItems = this.dropdownItems.map((category) => category.items).flat();
      if (allDropdownItems.length === 0) return false;

      return true;
    },
  },
  methods: {
    open(): void {
      this.$emit('open');
    },
    updateSelection(dropdownItem: DropdownItem): void {
      if (dropdownItem.id === null) {
        this.$emit('update', null, null);
      } else {
        const [pestId, pestIndicationId] = dropdownItem.id.split('_');
        this.$emit('update', pestId, pestIndicationId);
      }
    },
  },
});
