import {
  TerrazoFertilizerAdviceParamsWinterGrains1N,
  TerrazoFertilizerAdviceParamsWinterGrains3N,
} from 'farmdok-rest-api';

import { ActionPayloads } from '../../actions';
import { Application, ApplicationType, ZoneMap } from '../../types';

export default function buildPayload(
  fieldId: string,
  dbId: string,
  zoneMap: ZoneMap,
  application: Application,
): ActionPayloads['loadFeaturesAndZones'] {
  const { applicationType } = application;

  switch (applicationType) {
    case ApplicationType.FIRST:
      try {
        return buildPayloadForApplicationTypeFirst(fieldId, dbId, zoneMap, application);
      } catch {
        return buildBasePayload(fieldId, dbId, zoneMap);
      }
    case ApplicationType.THIRD:
      try {
        return buildPayloadForApplicationTypeThird(fieldId, dbId, zoneMap, application);
      } catch {
        return buildBasePayload(fieldId, dbId, zoneMap);
      }
    default:
      return buildPayloadForVisualizationModeZone(fieldId, dbId, zoneMap);
  }
}

function buildPayloadForApplicationTypeFirst(
  fieldId: string,
  dbId: string,
  zoneMap: ZoneMap,
  application: Application,
): ActionPayloads['loadFeaturesAndZones'] {
  return {
    ...buildBasePayload(fieldId, dbId, zoneMap),
    fertilizerAdviceParams: buildFertilizerAdviceParamsWinterGrains1N(application),
  };
}

function buildFertilizerAdviceParamsWinterGrains1N(
  application: Application,
): TerrazoFertilizerAdviceParamsWinterGrains1N {
  const {
    applicationTypeFirst: { targetNRate },
  } = application;

  if (targetNRate === null) {
    throw new Error('targetNRate must be set');
  }

  return {
    averageFertilizerRate: targetNRate,
  };
}

function buildPayloadForApplicationTypeThird(
  fieldId: string,
  dbId: string,
  zoneMap: ZoneMap,
  application: Application,
): ActionPayloads['loadFeaturesAndZones'] {
  return {
    ...buildBasePayload(fieldId, dbId, zoneMap),
    fertilizerAdviceParams: buildFertilizerAdviceParamsWinterGrains3N(application),
  };
}

function buildFertilizerAdviceParamsWinterGrains3N(
  application: Application,
): TerrazoFertilizerAdviceParamsWinterGrains3N {
  const {
    applicationTypeThird: {
      averageFertilizerRate1N,
      averageFertilizerRate2N,
      targetYield,
      humidityAreaType,
      targetGrainQuality,
    },
  } = application;

  if (averageFertilizerRate1N === null) {
    throw new Error('averageFertilizerRate1N must be set');
  }
  if (averageFertilizerRate2N === null) {
    throw new Error('averageFertilizerRate2N must be set');
  }
  if (targetYield === null) {
    throw new Error('targetYield must be set');
  }
  if (humidityAreaType === null) {
    throw new Error('humidityAreaType must be set');
  }
  if (targetGrainQuality === null) {
    throw new Error('targetGrainQuality must be set');
  }

  return {
    averageFertilizerRate1N,
    averageFertilizerRate2N,
    expectedYield: targetYield,
    humidityAreaType,
    targetGrainQuality,
  };
}

function buildPayloadForVisualizationModeZone(
  fieldId: string,
  dbId: string,
  zoneMap: ZoneMap,
): ActionPayloads['loadFeaturesAndZones'] {
  return buildBasePayload(fieldId, dbId, zoneMap);
}

function buildBasePayload(fieldId: string, dbId: string, zoneMap: ZoneMap): ActionPayloads['loadFeaturesAndZones'] {
  const { visualizationMode, numberOfZones, borderZoneCorrection, zoningMode } = zoneMap;

  return {
    fieldId,
    dbId,
    visualizationMode,
    numberOfZones,
    borderZoneCorrection,
    zoningMode,
  };
}
