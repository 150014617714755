import { Module } from 'vuex';

import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { RootState } from '@/store/types';

import subscribableStore from './common';
import { UsersState } from './types';

export function initialState(): UsersState {
  return {
    ...initialStateSubscribable(),
  };
}

const vuexModule: Module<UsersState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: { ...subscribableStore.mutations },
  actions: { ...subscribableStore.actions },
  getters: { ...subscribableStore.getters },
};

export default vuexModule;
