
import { PropType, defineComponent } from 'vue';

import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

export default defineComponent({
  name: 'ApplicationSelection',
  components: { FormFieldDropdown },
  props: {
    selectedApplicationType: {
      type: Object as PropType<DropdownItem>,
      required: false,
    },
    applicationTypeDropdownItems: {
      type: Array as PropType<DropdownItemsPerCategory[]>,
      required: true,
    },
  },
});
