export const MATERIAL_CATEGORY_ID_MINERAL_FERTILIZER = 'BD3BBCFF-01B8-11E7-BE5A-08606E6810DA';

export const UNIT_ID_KG_PER_HA = 'BE0318CF-01B8-11E7-BE5A-08606E6810DA';
export const UNIT_ID_GRAINS_PER_HA = 'BE031CFC-01B8-11E7-BE5A-08606E6810DA';
export const INSECTICIDES_GUIDS = [
  'B9548ED1-01B8-11E7-BE5A-08606E6810DA',
  '73ED8E62-470D-11E9-A0F6-0898B227FA22',
  '74E3F3BA-470D-11E9-AE99-0898B227BE5E',
  '760D80A8-470D-11E9-914B-0898B2271D36',
  '7BE5201C-470D-11E9-8645-0898B2274D69',
  '7E473020-470D-11E9-99F6-0898B227A561',
  '24F1C4B0-633C-11E7-8969-000000000000',
  'D1B62396-6E86-11EA-BCDA-309C23B8C040',
  'D1D40BC9-6E86-11EA-BCDA-309C23B8C040',
  '8C75AC9E-470D-11E9-B171-0898B2275CAA',
  '8E20B9F8-470D-11E9-9632-0898B22701B8',
  '8E431426-470D-11E9-87E5-0898B227F2A0',
];
