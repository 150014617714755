import Handsontable from 'handsontable';

import getFirstRowOfSubtable from '../utils/getFirstRowOfSubtable';
import getLastRowOfSubtable from '../utils/getLastRowOfSubtable';

/**
 * merged cells are only merged visually.
 * To keep the values of all merged cells in sync, we need to propagate changes to all merged cells
 * Therefore, we need to add the changes to all merged cells to the changes array
 * @param hot
 * @returns
 */
export default function propagateChangesToMergedCellsFactory(hot: Handsontable | null) {
  function propagateChangesToMergedCells(
    changes: Array<Handsontable.CellChange | null>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    source?: Handsontable.ChangeSource,
  ): void {
    if (!hot) return;

    changes.forEach((change) => {
      if (!change) return;

      changes.push(...createChangesForMergedCells(change, hot));
    });
  }

  return propagateChangesToMergedCells;
}

function createChangesForMergedCells(
  originalChange: Handsontable.CellChange,
  hot: Handsontable,
): Handsontable.CellChange[] {
  const [row, prop, oldValue, newValue] = originalChange;
  const column = hot.propToCol(prop);
  const cellMeta = hot.getCellMeta(row, column);

  const changes: Handsontable.CellChange[] = [];

  if (cellMeta.isSubtableColumn) return [];

  const firstRow = getFirstRowOfSubtable(cellMeta.visualRow, hot);
  const lastRow = getLastRowOfSubtable(cellMeta.visualRow, hot);

  for (let i = firstRow; i <= lastRow; i += 1) {
    if (row !== i) {
      changes.push([i, prop, oldValue, newValue] as Handsontable.CellChange);
    }
  }

  return changes;
}
