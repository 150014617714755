import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { BASE_PATH } from '../../common';
import { ApiRequestOptions, ApiResponse, Product } from '../../models';
import { apiOptionsToSearchParams } from '../../requestUtils';

// eslint-disable-next-line import/prefer-default-export
export async function fetchActiveCrops<Resolve = undefined, ResolveInSeparateList = undefined>(
  processOrderIds: string[],
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
): Promise<AxiosResponse<ApiResponse<Product[], Resolve, ResolveInSeparateList>>> {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  processOrderIds.forEach((processOrderId) => {
    searchParams.append('processOrderIds[]', processOrderId);
  });

  const url = `${BASE_PATH}/material/crop/active_for_process_order?${searchParams.toString()}`;

  return axios.get<ApiResponse<Product[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}
