<template>
  <ThePageSkeleton>
    <template #content>
      <div class="map-page-container">
        <div class="header-container">
          <RouterLink class="header__logo" :to="baseUrl">
            <IconTextLogoOriginal width="150" />
          </RouterLink>
        </div>
        <GeoJsonMap v-if="!loading && !error" :data="geoJson" @onDownloadClick="downloadFile" />
        <div v-if="loading" class="loading-global-wrapper">
          <LoadingGlobal />
        </div>
        <ErrorUserMessage v-else-if="error" :error-user-message="userErrorMessage" />
      </div>
    </template>
  </ThePageSkeleton>
</template>

<script>
import axios from 'axios';

import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import LoadingGlobal from '@/shared/components/LoadingGlobal.vue';
import { baseUrl } from '@/shared/constants';
import { urlToFile } from '@/shared/modules/urlHelpers';

import IconTextLogoOriginal from '../../layout/svg/IconTextLogoOriginal.vue';
import GeoJsonMap from './components/GeoJsonMap.vue';

export default {
  name: 'PageContractFileMap',
  components: {
    ThePageSkeleton,
    LoadingGlobal,
    ErrorUserMessage,
    GeoJsonMap,
    IconTextLogoOriginal,
  },

  data() {
    return {
      loading: true,
      error: false,
      userErrorMessage: null,
      contractFile: null,
      baseUrl,
      geoJson: null,
      supportedFileFormats: ['geojson', 'json'],
    };
  },

  computed: {
    fileId() {
      return this.$route.params.fileId;
    },
    fileTitle() {
      if (this.geoJson) {
        return this.geoJson.name;
      }
      return '';
    },
  },

  async mounted() {
    await this.loadData();
    this.loading = false;
  },

  methods: {
    async loadData() {
      try {
        const { data } = await axios.get(`/admin/rest/contractFile/download/${this.$route.params.fileId}`);
        const fileExtension = data.fileName.split('.').pop();
        if (!this.supportedFileFormats.includes(fileExtension)) {
          this.contractFile = data;
          await this.downloadFile(data);
          this.$router.push({ name: 'home' });
        }
        this.contractFile = data;
        this.geoJson = JSON.parse(atob(data.fileBody));
      } catch (e) {
        this.error = true;
        console.error(e);
        this.userErrorMessage = 'An error occured';
        this.loading = false;
      }
    },

    async downloadFile() {
      const file = await urlToFile(
        `data:text/plain;base64,${this.contractFile.fileBody}`,
        this.contractFile.fileName,
        this.contractFile.fileMimeType,
      );
      const { default: saveAs } = await import('jszip/vendor/FileSaver');
      saveAs(file, this.contractFile.fileName);
    },
  },
};
</script>

<style scoped>
.map-page-container {
  display: flex;
  height: 100%;
  flex-flow: column;
}
.loading-global-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background: rgba(255, 255, 255, 0.7);
}

.header-container {
  display: flex;
  place-items: center;
}

.header__logo {
  display: block;
  padding: 5px 8px;
  overflow: hidden;
  transition: width 0.1s ease;
}
</style>
