import { render, staticRenderFns } from "./FooterRuleCheckStatus.vue?vue&type=template&id=cc537200&scoped=true&"
import script from "./FooterRuleCheckStatus.vue?vue&type=script&lang=ts&"
export * from "./FooterRuleCheckStatus.vue?vue&type=script&lang=ts&"
import style0 from "./FooterRuleCheckStatus.vue?vue&type=style&index=0&id=cc537200&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc537200",
  null
  
)

export default component.exports