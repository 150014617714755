<template>
  <ThePageSkeleton
    :page-feature-title="$router.currentRoute.meta.label"
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
    :multiCompany="multipleCompaniesSelected"
  >
    <template #contentbar>
      <TheContentbar>
        <template #left="{ contentbarLarge }">
          <ButtonDropdown
            v-show="editMode"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonUpdateSelection"
            @click="selectedRows = []"
          />
          <Button
            v-show="editMode"
            color="lightest"
            icon="cloud-download"
            leading-icon
            component-namespace-name="ButtonExportFields"
            :disabled="exportDisabled"
            :title="$t('Auswahl exportieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="exportSelected"
          />
          <Button
            v-show="editMode"
            color="lightest"
            icon="paper-plane"
            leading-icon
            component-namespace-name="ButtonSendFieldPositions"
            :disabled="exportDisabled"
            :title="$t('Positionen senden')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="sendFieldPositions"
          />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar v-show="!editMode" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <ProcessOrderSwitch v-show="!editMode" class="ml-4" :slim="!contentbarLarge" />
          <CompanySwitch v-show="!editMode" class="ml-4" :slim="!contentbarLarge" />
          <DropdownPageOptions
            v-show="!editMode"
            class="ml-4"
            :restore-label="$t('Felder wiederherstellen')"
            restoreReadOnly
            :export-disabled="exportDisabled"
            :additional-options="additionalPageOptions"
            @settings="showSettings"
            @export="exportData"
          />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableSharedFieldsContainer
        ref="table"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
        :field-ids-with-harvesting-measure="fieldIdsWithHarvestingMeasure"
        @click:status="onClickStatus"
      />
      <ModalPageSettings ref="modalPageSettings" :columns="columnsForPageSettings" />
      <ModalTableExport
        ref="modalTableExport"
        :title="selectedRows.length > 0 ? $t('Selektierte Felder exportieren') : null"
        :filtered="selectedRows.length === 0 && $refs.table != null && !$refs.table.allVisible"
      />
    </template>
    <template #satellite>
      <SatelliteSharedFieldDetail
        v-show="satellite.visible"
        :loading="loading"
        :visible="satellite.visible"
        :expanded="satellite.expanded"
        :visible-fields="
          visibleRows.filter((fieldId) => sharedFields[fieldId] != null).map((fieldId) => sharedFields[fieldId])
        "
        :selected-fields="fieldsForSatellite"
        :current-field-id.sync="currentFieldIdForSatellite"
        :crops="crops"
        :varieties="varieties"
        :activityTypes="activityTypes"
      />
    </template>
    <template #featureNotEnabled>
      <MaxWidthContainer left size="xl">
        <FeatureNotAvailable :featureName="$t('Freigegebene Felder')" />
      </MaxWidthContainer>
    </template>
    <template #noMultiCompanySupport>
      <PageNoMultiCompanySupport />
    </template>
  </ThePageSkeleton>
</template>

<script>
import debounce from 'lodash.debounce';
import numbro from 'numbro';
import { mapGetters } from 'vuex';

import PageNoMultiCompanySupport from '@/auth/PageNoMultiCompanySupport.vue';
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import { exportFields } from '@/shared/api/rest/RestApi';
import { PermissionMode } from '@/shared/api/rest/models';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import DropdownPageOptions from '@/shared/components/DropdownPageOptions.vue';
import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import ModalPageSettings from '@/shared/components/ModalPageSettings.vue';
import ModalTableExport from '@/shared/components/ModalTableExport.vue';
import ProcessOrderSwitch from '@/shared/components/ProcessOrderSwitch.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import { ACTIVITY_ROUGH_GUIDS, ENTRY_REMOVED, ENTRY_REMOVING, ENTRY_TO_REMOVE } from '@/shared/constants';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import SatelliteSharedFieldDetail from './components/SatelliteSharedFieldDetail.vue';
import TableSharedFieldsContainer from './containers/TableSharedFieldsContainer.vue';
import { fullFieldName } from './handsontable/columns/columns';

export default {
  name: 'PageSharedFields',
  components: {
    ModalTableExport,
    DropdownPageOptions,
    TableSharedFieldsContainer,
    TheContentbar,
    ThePageSkeleton,
    MaxWidthContainer,
    PageNoMultiCompanySupport,
    CompanySwitch,
    ProcessOrderSwitch,
    SearchBar,
    SatelliteSharedFieldDetail,
    ModalPageSettings,
    Button,
    ButtonDropdown,
    FeatureNotAvailable,
  },
  mixins: [buttonUpdateSelection('table')],
  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      columnsForPageSettings: null,
      fieldIdsForModal: [],
      fieldStatusTarget: null,
      fieldStatusField: null,
      additionalPageOptions: null,
    };
  },
  created() {
    if (this.currentCompaniesHaveFeature.visible && this.currentCompaniesHaveFeature.enabled) {
      this.$store.dispatch('activities/sharedActivities/subscribe');
      this.$store.dispatch('sharedActivityTypes/subscribe');
    }
  },
  computed: {
    ...mapGetters({
      satellite: 'satellite',
      sharedFields: 'sharedFields/data',
      crops: 'products/crops/data',
      varieties: 'varieties/data',
    }),
    activities() {
      return this.$store.getters['activities/sharedActivities/data'];
    },
    activityTypes() {
      return this.$store.getters['sharedActivityTypes/data'];
    },
    currentCompanyId() {
      return this.$store.state.auth.currentCompanies?.[0]?.id;
    },
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_FIELDS_SHARED);
    },
    multipleCompaniesSelected() {
      return this.$store.state.auth.currentCompanies.length > 1;
    },
    loading() {
      if (this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS)) {
        return this.$store.getters['sharedFields/loading'] || this.$store.getters['customers/loading'];
      }
      return this.$store.getters['sharedFields/loading'];
    },
    fieldStatusFieldIsActiveAndHasHarvestingMeasure() {
      if (this.fieldStatusField == null) {
        return undefined;
      }
      return (
        this.fieldIdsWithHarvestingMeasure.includes(this.fieldStatusField.id) &&
        this.fieldStatusField.status === 'active'
      );
    },
    allSelectedRowsHaveHarvestingMeasuresAndAreActive() {
      return this.selectedRows
        .filter((id) => this.sharedFields[id] != null)
        .every((id) => this.fieldIdsWithHarvestingMeasure.includes(id) && this.sharedFields[id].status === 'active');
    },
    allSelectedRowsFieldStatus() {
      const uniqueStatuses = [
        ...new Set(
          this.selectedRows.filter((id) => this.sharedFields[id] != null).map((id) => this.sharedFields[id].status),
        ),
      ];
      return uniqueStatuses.length > 1 ? undefined : uniqueStatuses[0];
    },
    someSelectedRowsHaveFieldStatusCalculating() {
      return this.selectedRows
        .filter((id) => this.sharedFields[id] != null)
        .some((id) => this.sharedFields[id].status === 'calculating');
    },
    editMode() {
      return !this.loading && Object.keys(this.sharedFields).length > 0 && this.selectedRows.length > 0;
    },
    fieldsForSatellite() {
      let fieldsForSatellite = this.selectedRows;
      if (fieldsForSatellite.length === 0) {
        fieldsForSatellite = this.visibleRows;
      }
      return fieldsForSatellite
        .filter(
          (id) =>
            this.sharedFields[id] != null &&
            ![ENTRY_REMOVED, ENTRY_REMOVING, ENTRY_TO_REMOVE].includes(this.sharedFields[id].storeStatus),
        )
        .map((id) => this.sharedFields[id]);
    },
    currentFieldIdForSatellite: {
      get() {
        return this.$route.params.fieldId;
      },
      set(fieldId) {
        this.$router.push({ name: 'more/shared-fields/record', params: { fieldId } });
      },
    },
    exportDisabled() {
      return !this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_EXPORT_FIELD);
    },
    fieldIdsWithHarvestingMeasure() {
      const fieldIds = [];
      Object.values(this.activities).forEach((activity) => {
        const activityType = this.activityTypes[activity.activityTypeId];
        if (activityType?.roughId === ACTIVITY_ROUGH_GUIDS.HARVEST) {
          fieldIds.push(activity.fieldId);
        }
      });

      return fieldIds;
    },
  },
  watch: {
    currentFieldIdForSatellite() {
      this.updateSatelliteState();
    },
  },
  async mounted() {
    window.addEventListener('resize', this.onResize);
    if (this.currentCompaniesHaveFeature.visible && this.currentCompaniesHaveFeature.enabled) {
      this.unsubscribeFromStore = this.$store.subscribe(({ type }, state) => {
        if (['sharedFields/loadAll'].includes(type)) {
          this.reloadFieldsWithStatusCalculating();
        }
        if (type === 'satellite/toggleExpand') {
          if (
            state.satellite.expanded &&
            this.fieldsForSatellite.length > 0 &&
            this.currentFieldIdForSatellite == null
          ) {
            this.$router.push({
              name: 'more/shared-fields/record',
              params: { fieldId: this.fieldsForSatellite[0].id },
            });
          } else {
            this.$router.push({ name: 'more/shared-fields' });
          }
        }
      });
      this.updateSatelliteState();
      this.setInitialSatelliteState();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.unsubscribeFromStore();
  },
  methods: {
    updateSatelliteState() {
      if (this.currentFieldIdForSatellite != null) {
        this.$store.commit('satellite/setState', { visible: true, expanded: true });
      }
    },
    async setInitialSatelliteState() {
      if (this.$store.state.satellite.visible === true) {
        return;
      }
      await this.$store.dispatch('sharedFields/subscribe');
      const mediaQuery = this.$store.state.navbar.expanded === true ? '(min-width: 1550px)' : '(min-width: 1400px)';
      if (window.matchMedia(mediaQuery).matches === true && Object.keys(this.sharedFields).length > 0) {
        this.$store.commit('satellite/setState', { visible: true, expanded: false });
      }
    },
    urlToFile(url, filename, type) {
      return fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type }));
    },
    async exportSelected() {
      const { type, okay } = await this.$refs.modalTableExport.show();
      if (!okay) {
        return;
      }

      if (type === 'xls') {
        this.$refs.table.$refs.table.export(this.selectedRows);
      } else {
        const response = await exportFields(
          type,
          this.selectedRows,
          PermissionMode.fieldSharing,
          this.currentCompanyId,
        );
        const file = await this.urlToFile(
          `data:text/plain;base64,${response.data.fileBody}`,
          response.data.fileName,
          response.data.fileMimeType,
        );
        const { default: saveAs } = await import('jszip/vendor/FileSaver');
        saveAs(file, response.data.fileName);
      }
    },
    async exportData() {
      const { type, exportFiltered, okay } = await this.$refs.modalTableExport.show();
      if (!okay) {
        return;
      }

      let fieldIds = [];
      if (exportFiltered) {
        fieldIds = this.$refs.table.visibleRows;
      } else {
        fieldIds = Object.values(this.$refs.table.tableData).map((field) => field.id);
      }

      if (type === 'xls') {
        this.$refs.table.$refs.table.export(fieldIds);
      } else {
        const response = await exportFields(type, fieldIds, PermissionMode.fieldSharing, this.currentCompanyId);
        const file = await this.urlToFile(
          `data:text/plain;base64,${response.data.fileBody}`,
          response.data.fileName,
          response.data.fileMimeType,
        );
        const { default: saveAs } = await import('jszip/vendor/FileSaver');
        saveAs(file, response.data.fileName);
      }
    },
    sendFieldPositions() {
      const subject = this.$t('FARMDOK Feldpositionen');
      const body = this.$refs.table.selectedRows
        .map((id) => {
          const field = this.$refs.table.tableData[id];
          const name = fullFieldName.data(field);
          const crop =
            field.cropId != null && this.crops[field.cropId] != null ? ` (${this.crops[field.cropId].name})` : '';
          const size = field.fieldSize != null ? ` (${numbro(field.fieldSize).format()} ha)` : '';
          const zoom =
            field.fieldSize != null ? `${Math.max(Math.round(Math.sqrt(field.fieldSize) * 800), 3445)}m` : '15z';
          const googleMapsLink =
            typeof field.lat === 'number' && typeof field.lon === 'number'
              ? `https://www.google.com/maps/place/${field.lat},${field.lon}/@${field.lat},${field.lon},${zoom}/data=!3m1!1e3`
              : this.$t('Keine Feldposition');
          return `${name}${crop}${size}:\n${googleMapsLink}`;
        })
        .join('\n\n');
      const link = document.createElement('a');
      link.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      link.target = '_blank';
      link.click();
    },
    async showSettings() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      const settings = await this.$refs.modalPageSettings.show();
      if (settings != null && Array.isArray(settings.columns)) {
        this.$refs.table.$refs.table.updateColumnsFromPageSettings(settings.columns);
      }
    },
    reloadFieldsWithStatusCalculating: debounce(
      async function reloadFieldsWithStatusCalculating() {
        const fieldIds = Object.values(this.sharedFields)
          .filter(({ status }) => status === 'calculating')
          .map(({ id }) => id);
        if (fieldIds.length < 1) {
          return;
        }
        await this.$store.dispatch('sharedFields/refreshByIds', fieldIds);
      },
      30 * 1000,
      { maxWait: 120 * 1000 },
    ),
    async onClickStatus(payload) {
      if (payload == null) {
        this.fieldStatusTarget = null;
        this.fieldStatusField = null;
        return;
      }
      const { target, guid } = payload;
      if (this.fieldStatusTarget === target) {
        this.fieldStatusTarget = null;
        this.fieldStatusField = null;
        return;
      }
      this.fieldStatusTarget = null;
      this.fieldStatusField = null;
      if (this.sharedFields[guid] == null) {
        return;
      }

      await this.$nextTick();
      this.fieldStatusTarget = target;
      this.fieldStatusField = this.fields[guid];
    },
    onResize() {
      this.fieldStatusTarget = null;
      this.fieldStatusField = null;
    },
  },
};
</script>

<style scoped></style>
