import numbro from 'numbro';

/**
 * Calculates the size of all available fields.
 *
 * Prerequisites:
 * - Needs this.tableData to be available as an array of objects that have a 'fieldSize' numeric value.
 * - Needs this.selectedRows to be available as an array of indices that refer to objects in this.tableData.
 * - Needs this.visibleRows to be available as an array of indices that refer to objects in this.tableData.
 */
export default {
  computed: {
    sizeTotal() {
      let sizeTotal = 0;
      if (this.selectedRows != null && this.selectedRows.length > 0) {
        this.selectedRows
          .filter((id) => this.tableData[id] != null)
          .forEach((id) => {
            sizeTotal = this.calculateSizeTotalAddFieldSize(sizeTotal, this.tableData[id]);
          });
      } else if (this.visibleRows.length < Object.keys(this.tableData).length) {
        this.visibleRows
          .filter((id) => this.tableData[id] != null)
          .forEach((id) => {
            sizeTotal = this.calculateSizeTotalAddFieldSize(sizeTotal, this.tableData[id]);
          });
      } else {
        Object.values(this.tableData).forEach((field) => {
          sizeTotal = this.calculateSizeTotalAddFieldSize(sizeTotal, field);
        });
      }
      return sizeTotal;
    },
    sizeTotalFormatted() {
      return numbro(this.sizeTotal).format();
    },
  },
  methods: {
    calculateSizeTotalAddFieldSize(total, field) {
      const fieldSize = Number(field.fieldSize);
      if (Number.isNaN(fieldSize)) {
        return total;
      }
      return total + fieldSize;
    },
  },
};
