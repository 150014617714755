import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle, faClock, faDotCircle } from '@fortawesome/pro-solid-svg-icons';
import Handsontable from 'handsontable';

import ReadOnlyRenderer from '@/shared/handsontable/renderers/ReadOnlyRenderer';
import RequiredRenderer from '@/shared/handsontable/renderers/RequiredRenderer';
import setCellTitleAttribute from '@/shared/handsontable/renderers/helpers/setCellTitleAttribute';

library.add(faCheckCircle, faClock, faDotCircle, faSpinnerThird);

function FieldNameRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  Handsontable.dom.empty(TD);
  if (cellProperties.required && (typeof value !== 'string' || value.length === 0)) {
    RequiredRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);
    return;
  }

  const displayValue =
    typeof cellProperties.getRendererValue === 'function' ? cellProperties.getRendererValue({ value, row }) : value;
  setCellTitleAttribute(TD, displayValue);

  // remember the span as it is used as target for Bootstrap popovers
  let span = TD.querySelector('span.hot-floating-cell-icon');
  if (span == null) {
    span = document.createElement('span');
    span.className = 'hot-floating-cell-icon';
  }
  Handsontable.renderers.TextRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);

  TD.classList.add('hot-cell-with-floating-cell-icon');

  const statusReadOnly = cellProperties.getStatusReadOnly(row);

  // assign events like this as the span is re-used, so we dont add multiple listeners
  span.onmousedown = (event) => {
    event.stopPropagation();
  };
  span.onclick = () => {
    if (statusReadOnly) {
      return;
    }
    if (typeof cellProperties.onClick === 'function') {
      cellProperties.onClick({ target: span, visualRow: row });
    }
  };

  let status = null;
  if (typeof cellProperties.getStatus === 'function') {
    status = cellProperties.getStatus(row);
  }

  const displaySettings = {
    completed: {
      icon: { prefix: 'fas', iconName: 'check-circle' },
      className: 'hot-floating-cell-icon__status--completed',
    },
    active: {
      icon: { prefix: 'fas', iconName: 'dot-circle' },
      className: 'hot-floating-cell-icon__status--active',
    },
    planned: {
      icon: { prefix: 'fas', iconName: 'clock' },
      className: 'hot-floating-cell-icon__status--planned',
    },
    calculating: {
      icon: { prefix: 'fad', iconName: 'spinner-third' },
      className: 'hot-floating-cell-icon__status--calculating',
    },
  };

  if (displaySettings[status] != null && !span.classList.contains(displaySettings[status].className)) {
    span.className = 'hot-floating-cell-icon';
    span.innerHTML = '';
    const [statusIcon] = icon(displaySettings[status].icon).node;
    span.append(statusIcon);
    span.classList.add(displaySettings[status].className);
  }

  if (status === 'calculating') {
    span.onclick = null;
  }

  TD.append(span);

  if (
    status === 'active' &&
    typeof cellProperties.hasHarvestingMeasures === 'function' &&
    cellProperties.hasHarvestingMeasures(row)
  ) {
    span.classList.add('hot-floating-cell-icon--with-notification');
  }

  if (statusReadOnly) {
    span.classList.add('hot-floating-cell-icon--read-only');
  }

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);
  }
}

Handsontable.cellTypes.registerCellType('fieldName', {
  editor: Handsontable.editors.TextEditor,
  renderer: FieldNameRenderer,
});
