import Vue from 'vue';
import { GetterTree } from 'vuex';

import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { RootState } from '@/store/types';

import type { TableDataHistory } from '../handsontable/types';
import { subscribableStore } from './common';
import type { DaturaHistoryEntry, DaturaHistoryEntryWithoutName, DaturaHistoryState } from './types';

export type Getters = SubscribableGetters<DaturaHistoryEntry> & {
  list: DaturaHistoryEntryWithoutName[];
  tabular: TableDataHistory[];
};

const getterTree: GetterTree<DaturaHistoryState, RootState> = {
  ...subscribableStore.getters,
  list(state: DaturaHistoryState): DaturaHistoryEntryWithoutName[] {
    return Object.values<DaturaHistoryEntry>(state.data)
      .map((entry) => ({
        ...entry,
        importJobName: entry.name,
        stateDisplayValue: Vue.i18n.translate(entry.state),
      }))
      .map((entry) => {
        const { name, ...rest } = entry;
        return rest as DaturaHistoryEntryWithoutName;
      });
  },
  tableDataHistory(state: DaturaHistoryState, getters: Getters): TableDataHistory[] {
    // TODO: keep the table state in store
    return getters.list.map((e) => ({ ...e, select: false }));
  },
};

export default getterTree;
