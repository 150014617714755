/* eslint-disable import/prefer-default-export */
export const toNumber = (value) => {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value !== 'string') {
    const response = Number(value);
    if (Number.isNaN(value)) {
      return 0;
    }
    return response;
  }
  let negative = 1;
  if (value[0] === '-') {
    negative = -1;
  }
  const dotPos = value.indexOf('.');
  const commaPos = value.indexOf(',');
  let separatorPos = null;
  if (dotPos > commaPos && dotPos > -1) {
    separatorPos = dotPos;
  } else if (commaPos > dotPos && commaPos > -1) {
    separatorPos = commaPos;
  }

  if (separatorPos == null) {
    return negative * Number(value.replace(/[^0-9]/g, ''));
  }

  return (
    negative *
    Number(
      `${value.slice(0, separatorPos).replace(/[^0-9]/g, '')}.${value.slice(separatorPos + 1).replace(/[^0-9]/g, '')}`,
    )
  );
};
