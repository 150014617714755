import Handsontable from 'handsontable';

import parseNumber from '@/shared/modules/parseNumber';

// @ts-ignore
export default class NumericEditor extends Handsontable.editors.NumericEditor {
  init() {
    super.init();
  }

  prepare(
    visualRow: number,
    col: number,
    prop: string | number,
    td: HTMLTableCellElement,
    originalValue: string | number,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(visualRow, col, prop, td, originalValue, cellProperties);
  }

  getValue(): number {
    const amount = super.getValue();
    const numericAmount = parseNumber(amount);

    return numericAmount;
  }
}
