import { FarmdokColumnSettings } from '../../types';

const expandColumn: FarmdokColumnSettings = {
  data: 'expand',
  type: 'farmdok.expand',
  noHeaderContextMenu: true,
  hiddenInPageSettings: true,
  fixed: true,
  width: 45,
  disableColumnResize: true,
};

export default expandColumn;
