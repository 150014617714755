
import { BFormTextarea } from 'bootstrap-vue';
import { PropType, defineComponent } from 'vue';

import BaseFormField from './BaseFormField.vue';

export default defineComponent({
  name: 'FormFieldTextArea',
  components: {
    BFormTextarea,
    BaseFormField,
  },
  model: {
    prop: 'text',
    event: 'update',
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    text: {
      type: String as PropType<string>,
      default: '',
    },
    label: {
      type: String as PropType<string>,
      default: '',
    },
    placeholder: {
      type: String as PropType<string>,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value: string) =>
        ['horizontal', 'vertical', 'vertical-lg', 'no-label', 'no-label-sm'].includes(value),
    },
    rows: {
      type: String as PropType<string>,
      default: '2',
    },
    maxRows: {
      type: String as PropType<string>,
      default: '6',
    },
    noResize: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noAutoShrink: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    update(value: string) {
      this.$emit('update', value);
    },
  },
});
