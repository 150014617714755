<template>
  <ThePageSkeleton>
    <template #contentbar>
      <TheContentbar>
        <template #left>
          <ImgPartnerLogo
            v-if="contracting != null && contracting.imagePartnerLogo != null"
            :src="contracting.imagePartnerLogo"
            :width="100"
            :height="55"
          />
        </template>
        <template #middle>
          <Steps :current="current" :steps="steps" />
        </template>
        <template #right>
          <SearchBar v-show="current === 0 && !fetchingError" class="mr-1" :searchFilter.sync="filterString" />
          <ButtonWithArrow variant="lightest" type="left" class="mr-1" @click="back">
            {{ labelBack }}
          </ButtonWithArrow>
          <span
            id="page--contracting__button-next-tooltip"
            class="d-inline-block mr-1"
            tabindex="0"
            v-b-tooltip.bottomleft.viewport="{ container: '#the-contentbar', trigger: false }"
            :title="nextButtonTooltip"
            @mouseenter="$root.$emit('bv::show::tooltip', 'page--contracting__button-next-tooltip')"
            @mouseleave="$root.$emit('bv::hide::tooltip', 'page--contracting__button-next-tooltip')"
          >
            <ButtonWithArrow variant="primary" type="right" class="mr-1" :disabled="!nextButtonEnabled" @click="next">
              {{ labelNext }}
              <FontAwesomeIcon v-if="current === 1 && fetchingCompanyData" icon="circle-notch" spin />
            </ButtonWithArrow>
          </span>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <FetchingError
        v-if="current === 0 && fetchingError"
        :user-message="fetchingErrorUserMessage"
        @reload="loadFields"
      >
        <b-button
          v-if="fetchingErrorNoFieldsFound"
          variant="primary"
          class="mb-4"
          @click.prevent="navigateToFieldsInContractCropYear"
        >
          {{ $t('Verwalte hier deine Flächen') }}
        </b-button>
        <b-button
          v-if="fetchingErrorNoProcessOrderFound && canCreateContractProcessOrder"
          variant="primary"
          class="mb-4"
          @click.prevent="createNewProcessOrder"
        >
          {{ $t('Erstelle ein neues Erntejahr') }}
        </b-button>
        <p>
          <b-link
            v-if="fetchingErrorNoProcessOrderFound && !canCreateContractProcessOrder"
            @click.prevent="navigateToFieldsInLatestCropYear"
          >
            {{
              $t(`Lege alle Erntejahre bis inklusive {cropYear} an und überprüfe deine Felder.`, {
                cropYear: contractProcessOrderName,
              })
            }}
          </b-link>
        </p>
      </FetchingError>
      <TableSendFieldsContainer
        v-if="current === 0 && !fetchingError && fieldsLoaded"
        id="hot-contracting-fields"
        :contract-id="contractId"
        :company="company"
        :fields="fields"
        :already-sent-fields="alreadySentFields"
        :filter-string.sync="filterString"
        :visible-rows.sync="visibleRows"
        :selected-rows.sync="selectedRows"
        @update:selectedTableData="selectedFields = $event"
      />
      <div v-show="current === 1" class="contracting__info">
        <h3 class="h4 mb-4">{{ $t('Vertragsnehmer') }}</h3>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <ValidationObserver v-slot="{ handleSubmit, errors }" ref="formContractingValidationObserver">
          <BForm @submit.prevent="handleSubmit(submitForm)" novalidate ref="formContracting">
            <div class="contracting__info-grid mb-4">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                :name="$t('Betriebsnummer')"
                vid="companyBdmId"
                slim
              >
                <BFormGroup
                  :label="$t('Betriebsnummer')"
                  label-for="company-nr"
                  label-cols="5"
                  :state="errors.length > 0 ? false : null"
                  class="contracting__info-grid-item contracting__info-grid-item--left"
                >
                  <BaseInput
                    v-model="company.companyBdmId"
                    id="company-nr"
                    class="form-control--text-input-form-group-align"
                    :disabled="fetchingCompanyData"
                    :state="errors.length > 0 ? false : null"
                  />
                </BFormGroup>
              </ValidationProvider>
              <ValidationProvider
                :rules="partnerNumberValidationRule"
                v-slot="{ errors }"
                :name="$t('Partner-Nummer')"
                vid="foreignId"
                slim
              >
                <BFormGroup
                  :label="$t('Partner-Nummer')"
                  label-for="company-foreign-id"
                  label-cols="5"
                  :state="errors.length > 0 ? false : null"
                  class="contracting__info-grid-item contracting__info-grid-item--left"
                >
                  <BaseInput
                    v-model="company.foreignId"
                    id="company-foreign-id"
                    class="form-control--text-input-form-group-align"
                    :disabled="fetchingCompanyData"
                    :state="errors.length > 0 ? false : null"
                  />
                </BFormGroup>
              </ValidationProvider>
              <ValidationProvider
                v-if="sendByFieldSharing"
                rules="required"
                v-slot="{ errors }"
                :name="$t('Betriebsname')"
                vid="companyName"
                slim
              >
                <BFormGroup
                  :label="$t('Betriebsname')"
                  label-for="company-name"
                  label-cols="5"
                  :state="errors.length > 0 ? false : null"
                  class="contracting__info-grid-item contracting__info-grid-item--left"
                >
                  <BaseInput
                    v-model="company.companyName"
                    id="company-name"
                    class="form-control--text-input-form-group-align"
                    :disabled="fetchingCompanyData"
                    :state="errors.length > 0 ? false : null"
                  />
                </BFormGroup>
              </ValidationProvider>
              <ValidationProvider
                v-if="sendByFieldSharing"
                rules="required"
                v-slot="{ errors }"
                :name="$t('Verwendungszweck')"
                vid="senderReference"
                slim
              >
                <BFormGroup
                  :label="$t('Verwendungszweck')"
                  label-for="sender-reference"
                  label-cols="5"
                  :state="errors.length > 0 ? false : null"
                  class="contracting__info-grid-item contracting__info-grid-item--left"
                >
                  <BaseInput
                    v-model="contracting.name"
                    id="sender-reference"
                    class="form-control--text-input-form-group-align"
                    disabled
                    :state="errors.length > 0 ? false : null"
                  />
                </BFormGroup>
              </ValidationProvider>
            </div>
          </BForm>
        </ValidationObserver>
        <div v-if="hints" class="contracting__hints" v-html="hints" />
      </div>
      <ModalTermsContainer
        ref="modalTerms"
        :gtc="gtc"
        :checkbox-text="checkboxText"
        :field-ids="fieldIds"
        :company-data="company"
      />
      <ModalCreateProcessOrder
        ref="modalCreateProcessOrder"
        direction="next"
        :switch-to-new-process-order="false"
        :source-process-order-name="String(Number(contractProcessOrderName) - 1)"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import axios from 'axios';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import ModalCreateProcessOrder from '@/shared/components/ModalCreateProcessOrder.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Steps from '@/shared/components/Steps.vue';
import ButtonWithArrow from '@/shared/components/buttons/ButtonWithArrow.vue';
import BaseInput from '@/shared/components/form/BaseInput.vue';
import { baseUrl } from '@/shared/constants';
import FetchingError from '@/shared/handsontable/components/FetchingError.vue';

import ImgPartnerLogo from './components/ImgPartnerLogo.vue';
import ModalTermsContainer from './containers/ModalTermsContainer.vue';
import TableSendFieldsContainer from './containers/TableSendFieldsContainer.vue';

export default {
  name: 'PageContracting',
  components: {
    ModalTermsContainer,
    ModalCreateProcessOrder,
    TableSendFieldsContainer,
    TheContentbar,
    ThePageSkeleton,
    SearchBar,
    Steps,
    ButtonWithArrow,
    FetchingError,
    BaseInput,
    ImgPartnerLogo,
  },
  props: {
    modeResend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseUrl,
      filterString: '',
      visibleRows: [],
      selectedRows: [],
      // Steps
      current: 0,
      steps: [
        {
          number: '1',
          label: this.$t('Flächen'),
        },
        {
          number: '2',
          label: this.$t('Daten'),
        },
      ],
      // Review Info
      fetchingCompanyData: false,
      company: {
        nr: '',
        name: '',
        address: '',
        postcode: '',
        city: '',
        country: '',
        foreignId: '',
        user: {
          title: null,
          firstname: '',
          lastname: '',
          email: '',
        },
      },
      fields: [],
      checkboxText: null,
      fieldsLoaded: false,
      fetchingError: false,
      fetchingErrorUserMessage: '',
      fetchingErrorNoFieldsFound: false,
      fetchingErrorNoProcessOrderFound: false,
      alreadySentFields: [],
      selectedFields: null,
    };
  },
  computed: {
    labelBack() {
      if (this.current === 0) {
        return this.$t('Abbrechen');
      }
      return this.$t('Zurück');
    },
    labelNext() {
      if (this.current === 0) {
        return this.$t('Weiter');
      }
      if (this.fetchingCompanyData) {
        return '';
      }
      return this.$t('Absenden');
    },
    nextButtonEnabled() {
      if (this.current === 0) {
        return this.selectedFields !== null;
      }
      return this.current === 1 && !this.fetchingCompanyData;
    },
    contractId() {
      return this.$store.state.digitalContracting.contracting?.id || null;
    },
    contracting() {
      return this.$store.state.digitalContracting.contracting;
    },
    contractProcessOrderName() {
      return this.$store.state.digitalContracting.contracting?.processOrderName || null;
    },
    gtc() {
      return this.$store.state.digitalContracting.contracting?.gtc || null;
    },
    partner() {
      return this.$store.state.digitalContracting.contracting?.partner || null;
    },
    backendError() {
      const storeErrors = this.$store.state.digitalContracting.errors;
      if (storeErrors == null || storeErrors.length === 0) {
        return null;
      }
      return this.$store.state.digitalContracting.errors[0];
    },
    backendAttributeErrors() {
      if (this.backendError != null && this.backendError.status === 'attributeValueError') {
        return this.backendError.attributeErrorMessages;
      }
      return null;
    },
    backendGeneralError() {
      if (this.backendError != null && this.backendError.status === 'error') {
        return this.backendError.userMessage;
      }
      return null;
    },
    hints() {
      let hints = '';
      // TODO: store this information alongside form fields configuration - maybe in DB (bdm_contract)?
      if (this.partner === 'AGRANA') {
        hints += this.$t(
          'Partner-Nummer – Siehe Zugangsdaten auf <a href="https://ris.agrana.com" target="_blank">https://ris.agrana.com</a>',
        );
      }
      if (this.partner === 'Saatbau') {
        hints += this.$t('Die Partnernummer ist deine Identifikationsnummer bei SAATBAU LINZ eGen.');
        hints += '<br/> <br/>';
        hints += this.$t(
          'Durch die Freigabe der ausgewählten Felder erhält Dein Vertragspartner SAATBAU LINZ eGen lesenden Zugriff auf die Felder sowie die darauf gebuchten Maßnahmen und das Schlagblatt.',
        );
      }
      return hints;
    },
    nextButtonTooltip() {
      if (this.current === 0 && this.selectedFields === null) {
        return this.$t(
          'Wähle mindesten ein Feld mit dem Kontrollkästchen in der ersten Spalte. Alle ausgewählten Felder müssen eine Kultur haben.',
        );
      }
      return '';
    },
    fieldIds() {
      if (!Array.isArray(this.selectedFields)) {
        return [];
      }
      return this.selectedFields.map((field) => field.id);
    },
    contractProcessOrderExists() {
      return this.$store.getters['auth/availableProcessOrderNames'].includes(this.contractProcessOrderName);
    },
    canCreateContractProcessOrder() {
      const cropYearBeforeContractProcessOrder = String(Number(this.contractProcessOrderName) - 1);
      return this.$store.getters['auth/availableProcessOrderNames'].includes(cropYearBeforeContractProcessOrder);
    },
    sendByFieldSharing() {
      return (
        this.$store.state.digitalContracting.contracting?.sender.class ===
        '\\AgriDat\\App\\Components\\DigitalContracting\\ContractSenderFieldSharing'
      );
    },
    partnerNumberValidationRule() {
      if (!this.partner) {
        return 'required';
      }
      const partnerString = this.partner.toLowerCase();
      if (partnerString.includes('saatbau') || partnerString.includes('mauthner')) {
        return '';
      }
      return 'required';
    },
  },
  watch: {
    backendError(val) {
      if (val == null) {
        return;
      }
      this.$refs.modalTerms.hide();
    },
    backendAttributeErrors(val) {
      if (val == null) {
        return;
      }
      this.$refs.formContractingValidationObserver.setErrors(val);
    },
    contracting(val) {
      if (val == null) {
        this.finish();
      }
    },
  },
  async created() {
    const loadContractsAndSetContracting = async () => {
      await this.$store.dispatch('digitalContracting/subscribe');

      if (this.modeResend) {
        const contractCompanyId = Number.parseInt(this.$route.params.contractCompanyId, 10);
        const contract = this.$store.getters['digitalContracting/getByContractCompanyId'](contractCompanyId);
        this.$store.commit('digitalContracting/setContracting', contract);
        return contract;
      }

      const { contractGuid } = this.$route.params;
      const contract = this.$store.getters['digitalContracting/getByGuid'](contractGuid);
      this.$store.commit('digitalContracting/setContracting', contract);
      return contract;
    };

    const goToOverviewPage = () => {
      if (this.modeResend) {
        this.$router.push({ name: 'digital-contracting/my-contracts' });
        return;
      }

      this.$router.push({ name: 'digital-contracting/open-calls' });
    };

    const contract = await loadContractsAndSetContracting();
    if (!contract) {
      goToOverviewPage();
      return;
    }

    this.storeUnsubscribe = this.$store.subscribe(async ({ type }) => {
      if (type === 'afterResetData') {
        const contractLoaded = await loadContractsAndSetContracting();
        if (!contractLoaded) {
          goToOverviewPage();
        }
      }
    });

    await Promise.all([this.loadCompanyData(), this.loadFields()]);
  },
  beforeDestroy() {
    this.$store.commit('digitalContracting/setContracting', null);
  },
  methods: {
    async loadCompanyData() {
      this.fetchingCompanyData = true;
      let data = {};
      if (this.modeResend === true) {
        const { contractCompanyId } = this.$route.params;
        const response = await axios.get(`/admin/digitalContracting/getContractCompanyData/${contractCompanyId}`);
        ({ data } = response);
        this.alreadySentFields = data.contractFields != null ? data.contractFields : [];
      } else {
        const response = await axios.get(`/admin/digitalContracting/getCompanyDataForContract/${this.contractId}`);
        ({ data } = response);
        data.country = data.region.name;
      }
      this.company.companyBdmId = String(data.companyBdmId == null ? '' : data.companyBdmId);
      this.company.foreignId = String(data.foreignId != null ? data.foreignId : '');
      this.company.companyName = data.companyName;
      this.company.address = data.address;
      this.company.postcode = String(data.postcode);
      this.company.city = data.city;
      this.company.country = data.country;
      this.company.user.title = data.title;
      this.company.user.firstname = data.firstname;
      this.company.user.lastname = data.lastname;
      this.company.user.email = data.email;
      this.fetchingCompanyData = false;
    },
    async loadFields() {
      this.fetchingError = false;
      this.fetchingErrorNoFieldsFound = false;
      this.fetchingErrorNoProcessOrderFound = false;

      try {
        const { data } = await axios.get(`/admin/digitalContracting/getFields/${this.contractId}`);
        this.fields = data;
        this.fieldsLoaded = true;
      } catch (e) {
        this.fetchingError = true;
        this.fetchingErrorUserMessage = this.$t(`
          Die Daten konnten nicht geladen werden. Bitte überprüfe die Internetverbindung.
          Falls der Fehler weiterhin auftritt wende dich an support@farmdok.com
        `);
        if (e.response == null || e.response.data == null) {
          return;
        }
        if (e.response.data.type === 'noFieldsFound') {
          this.fetchingErrorNoFieldsFound = true;
          this.fetchingErrorUserMessage = this.$t(`
            Auf deinem Betrieb gibt es derzeit keine Felder, die die notwendigen Voraussetzungen für diese Flächenübermittlung erfüllen.
          `);
        }
        if (e.response.data.type === 'noProcessOrderFound') {
          this.fetchingErrorNoProcessOrderFound = true;
        }
        if (typeof e.response.data.userMessage === 'string') {
          this.fetchingErrorUserMessage = e.response.data.userMessage.replace(
            '{process_order_name}',
            this.contractProcessOrderName,
          );
        }
      }
    },
    back() {
      if (this.current > 0) {
        this.current = 0;
      } else if (this.modeResend === true) {
        this.$router.push({ name: 'digital-contracting/my-contracts' });
      } else {
        this.$router.push({ name: 'digital-contracting/open-calls' });
      }
    },
    next() {
      if (this.current === 0) {
        this.current = 1;
      } else {
        this.$refs.formContractingValidationObserver.handleSubmit(this.submitForm);
      }
    },
    finish() {
      this.$store.dispatch('digitalContracting/reset');
      this.$store.dispatch('digitalContracting/subscribe');
      this.$refs.modalTerms.hide();
      this.$router.push({ name: 'digital-contracting/my-contracts' });
    },
    submitForm() {
      this.checkboxText = null;
      if (this.partner != null && this.partner !== '') {
        this.checkboxText = this.$t(
          'Ich habe die Vereinbarungen gelesen und stimme der Datenübertragung von FARMDOK zu {partner} zu genannten Zwecken ausdrücklich zu.',
          { partner: this.partner },
        );
      }
      this.$refs.modalTerms.show();
    },
    firstError(errors) {
      let error = null;
      Object.values(errors).some((currErrors) => {
        if (currErrors.length < 1) {
          return false;
        }
        [error] = currErrors;
        return true;
      });
      return error;
    },
    async createNewProcessOrder() {
      const success = await this.$refs.modalCreateProcessOrder.show();
      if (!success) {
        return;
      }
      const processOrderName = this.contractProcessOrderName;
      await this.$router.push({ name: 'fields' });
      await this.$store.dispatch('auth/setCurrentProcessOrderName', processOrderName);
    },
    async navigateToFieldsInContractCropYear() {
      const processOrderName = this.contractProcessOrderName;
      await this.$router.push({ name: 'fields' });
      await this.$store.dispatch('auth/setCurrentProcessOrderName', processOrderName);
    },
    async navigateToFieldsInLatestCropYear() {
      const availableProcessOrderNames = this.$store.getters['auth/availableProcessOrderNames'];
      const latestCropYear = availableProcessOrderNames[availableProcessOrderNames.length - 1];
      await this.$router.push({ name: 'fields' });
      await this.$store.dispatch('auth/setCurrentProcessOrderName', latestCropYear);
    },
  },
};
</script>

<style scoped>
.contracting__info {
  padding: 40px 20px;
}

.contracting__info-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 500px 500px;
  grid-column-gap: 40px;
}

.contracting__info-grid-item--left {
  grid-column-start: 1;
}

.contracting__info-grid-item--right {
  grid-column-start: 2;
}
</style>
