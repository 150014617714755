import { Product } from '@/shared/api/rest/models';
import { DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

export function sortProducts(products: Product[]): Product[] {
  return products.sort((a, b) => (a.name > b.name ? 1 : -1));
}

export function sortDropdownItems(items: DropdownItemsPerCategory[]): DropdownItemsPerCategory[] {
  return items.sort((a, b) => {
    if (!a.name || !b.name) return 0;
    return a.name > b.name ? 1 : -1;
  });
}
