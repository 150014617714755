import { Module } from 'vuex';

import { RootState } from '@/store/types';

import sen4 from '../sen4/store';
import weather from '../weather/store';
import actions from './actions';
import getters from './getters';
import initialState from './initialState';
import mutations from './mutations';
import { MonitoringState } from './types';

const vuexModule: Module<MonitoringState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
  modules: { sen4, weather },
};

export default vuexModule;
