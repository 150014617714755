import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import Handsontable from 'handsontable';

import ReadOnlyRenderer from '@/shared/handsontable/renderers/ReadOnlyRenderer';

import applyMergeRowsBorderStyles from '../../features/nestedTable/utils/applyMergeRowsBorderStyles';
import applySubtableBorderStyles from '../../features/nestedTable/utils/applySubtableRendererBorderStyles';
import { ColumnSettingsTrashIcon } from './types';

library.add(faTrashAlt);

export default function TrashIconRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visualRow: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visualCol: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  prop: string | number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value: string | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cellProperties: ColumnSettingsTrashIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...args: unknown[]
) {
  Handsontable.dom.empty(TD);

  const [trashIcon] = icon({ prefix: 'fas', iconName: 'trash-alt' }).node;
  TD.appendChild(trashIcon);

  applySubtableBorderStyles(TD, visualRow, cellProperties, instance);
  applyMergeRowsBorderStyles(TD, visualRow, instance);
  TD.classList.add('trash-icon');

  if (cellProperties.readOnly) {
    ReadOnlyRenderer(instance, TD, visualRow, visualCol, prop);
    trashIcon.classList.add('opacity-35');
    // eslint-disable-next-line no-param-reassign
    TD.style.cursor = 'default';
  }
}
