
import { BButton } from 'bootstrap-vue';
import Handsontable from 'handsontable';
import { PropType, defineComponent } from 'vue';

import Pagination from '@/shared/components/Pagination.vue';
import exportXls, { filteredColumns } from '@/shared/handsontable/rework/features/export';

import { ExportOption } from '../handsontable/rework/features/export/types';
import { ColumnSettingsFlattenedSubtable } from '../handsontable/rework/features/nestedTable/types';
import { TableDataBase } from '../handsontable/rework/types';
import ModalWrapper from './ModalWrapper.vue';
import FormCheckbox from './form/FormCheckbox.vue';
import FormFieldDropdown from './form/FormFieldDropdown.vue';
import FormFieldRadioGroup from './form/FormFieldRadioGroup.vue';
import FormRadio from './form/FormRadio.vue';
import { DropdownItem } from './form/formFieldDropdownTypes';

type AvailableType = {
  value: string;
  label: string;
  text: string;
};

export type DropdownItemWithSubtableColumnData = DropdownItem & {
  subtableColumnData: string | undefined;
};

export default defineComponent({
  name: 'ModalTableExport',
  components: {
    FormCheckbox,
    FormRadio,
    FormFieldRadioGroup,
    ModalWrapper,
    Pagination,
    BButton,
    FormFieldDropdown,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean as PropType<boolean | null>,
      default: false,
    },
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    availableTypes: {
      type: Array as PropType<AvailableType[] | null>,
      default() {
        return [
          {
            value: 'xls',
            label: this.$t('XLS'),
            text: this.$t('Als Excel-Datei exportieren'),
          },
          {
            value: 'shape',
            label: this.$t('Shapefile'),
            text: this.$t('Im Shape-Format exportieren'),
          },
          {
            value: 'iso-xml',
            label: this.$t('ISOXML'),
            text: this.$t('Zum Import in dein ISOBUS-Terminal'),
          },
          {
            value: 'kmz',
            label: this.$t('KMZ'),
            text: this.$t('Bekannt, unter anderem dafür in Google Earth angezeigt zu werden'),
          },
        ];
      },
    },
    filtered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    dropdownItemsWithSubtableColumnData: {
      type: Array as PropType<DropdownItemWithSubtableColumnData[]>,
      default() {
        return [
          { id: ExportOption.PER_ROW_ID, name: this.$t('Export pro Tabellenzeile'), subtableColumnData: undefined },
        ];
      },
    },
    hot: {
      type: Object as PropType<Handsontable | null>,
      default: null,
    },
    columns: {
      type: Array as PropType<ColumnSettingsFlattenedSubtable[] | null>,
      default: null,
    },
    tableData: {
      type: Array as PropType<TableDataBase[] | null>,
      default: null,
    },
  },
  data() {
    let type: string | null = null;
    if (Array.isArray(this.availableTypes) && this.availableTypes.length > 0) {
      type = this.availableTypes[0].value;
    }
    const selectedOption: DropdownItemWithSubtableColumnData = this.dropdownItemsWithSubtableColumnData[0];
    return {
      type,
      exportFiltered: true,
      currentStep: 0,
      pages: null as number | null,
      selectedOption,
    };
  },
  computed: {
    titleComputed(): string {
      if (typeof this.title !== 'string' || this.title.length === 0) {
        return this.$t('Export') || 'Export';
      }
      return this.title;
    },
    filteredColumns(): Handsontable.ColumnSettings[] | null {
      if (!this.columns || !this.hot) return null;
      return filteredColumns(this.columns, this.hot);
    },
    dropdownItems(): { items: DropdownItemWithSubtableColumnData[] }[] {
      return [
        {
          items: this.dropdownItemsWithSubtableColumnData.map((dropdownItem: DropdownItemWithSubtableColumnData) => ({
            id: `${dropdownItem.id}${dropdownItem.subtableColumnData ? `_${dropdownItem.subtableColumnData}` : ''}`,
            name: dropdownItem.name,
            subtableColumnData: dropdownItem.subtableColumnData,
          })),
        },
      ];
    },
  },
  methods: {
    async show(): Promise<{ type: string | null; exportFiltered: boolean; okay: Promise<void> }> {
      this.type = null;
      if (Array.isArray(this.availableTypes) && this.availableTypes.length > 0) {
        this.type = this.availableTypes[0].value;
      }
      this.exportFiltered = true;
      if (this.tableDataIncludesSubtables()) {
        this.pages = 2;
      }
      // @ts-ignore
      const okay = await this.$refs.modalWrapper?.show();
      return {
        type: this.type,
        exportFiltered: this.exportFiltered,
        okay,
      };
    },
    dropdownSelect(value: DropdownItem): void {
      const dropdownItemWithSubtableColumnData = this.dropdownItems[0].items.find(
        (dropdownItem) => dropdownItem.id === value.id,
      );
      this.selectedOption = dropdownItemWithSubtableColumnData || { ...value, subtableColumnData: undefined };
    },
    async onExportSelected(selectedRows: string[]) {
      if (!this.columns || !this.hot || !this.tableData) {
        this.reset();
        console.error('Columns, handsontable, or tableData are not defined');
        return;
      }

      if (this.type === 'xls') {
        const { subtableColumnData } = this.selectedOption;
        exportXls(selectedRows, this.columns, this.tableData, this.hot, subtableColumnData);
      }
      this.reset();
    },
    tableDataIncludesSubtables(): boolean {
      if (!this.filteredColumns) return false;
      return this.filteredColumns.some((column: ColumnSettingsFlattenedSubtable) => column.isSubtableColumn);
    },
    cancel(): void {
      // @ts-ignore
      this.$refs.modalWrapper?.cancel();
      this.reset();
    },
    ok(): void {
      // @ts-ignore
      this.$refs.modalWrapper?.ok();
    },
    reset(): void {
      this.type = null;
      this.exportFiltered = true;
      this.currentStep = 0;
      this.pages = null;
      // eslint-disable-next-line prefer-destructuring
      this.selectedOption = this.dropdownItemsWithSubtableColumnData[0];
    },
  },
});
