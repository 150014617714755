import { deletedAt } from '@/shared/handsontable/columns';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';

import {
  crop,
  farmName,
  farmNumber,
  fieldGroupNumber,
  fieldName,
  fieldNumber,
  fieldSize,
  partnerNumber,
  variety,
} from './columns';

export default [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...deletedAt,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
    sortOrder: 'desc',
  },
  { ...partnerNumber, key: 'contractCompany.foreignId' },
  { ...farmNumber, key: 'contractCompany.companyBdmId' },
  { ...farmName, key: 'contractCompany.companyName' },
  { ...fieldGroupNumber, key: 'fieldGroupMfa' },
  fieldNumber,
  fieldName,
  fieldSize,
  crop,
  variety,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];
