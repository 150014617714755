import { AxiosRequestConfig } from 'axios';
import { Rule, RuleCheckApi } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

async function fetchAll(
  context: ActionContext<SubscribableDataState<Rule>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Rule>> {
  const { ruleCheckApi } = Vue.prototype.$api as Api;

  const apiResponse = loadAllPages<RuleCheckApi, Rule>(ruleCheckApi, 'ruleList', {}, axiosOptions);

  return apiResponse;
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<Rule>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Rule>> {
  throw new Error('API NOT IMPLEMENTED');
}

const subscribableStore = subscribableData(fetchAll, fetchByIds, [availableFeatures.FEATURE_RULE_CHECK]);

export default subscribableStore;
