
import { PropType, defineComponent } from 'vue';

import FormFieldInputNumberFormattedTwoDecimals from '@/shared/components/form/FormFieldInputNumberFormattedTwoDecimals.vue';

export default defineComponent({
  name: 'FirstApplicationForm',
  components: { FormFieldInputNumberFormattedTwoDecimals },
  model: {
    prop: 'targetNRate',
    event: 'update:targetNRate',
  },
  props: {
    targetNRate: {
      type: Number as PropType<number | null>,
    },
  },
});
