export default function buttonUpdateSelection(tableContainerRef) {
  return {
    data() {
      return {
        buttonUpdateSelection: [
          {
            text: this.$t('Auswahl aufheben'),
            handler: () => this.$refs[tableContainerRef].$refs.table.selectableRowsBatchAction('clear'),
          },
          {
            text: this.$t('Auswahl umkehren'),
            handler: () => this.$refs[tableContainerRef].$refs.table.selectableRowsBatchAction('reverse'),
          },
          {
            text: this.$t('Alle auswählen'),
            handler: () => this.$refs[tableContainerRef].$refs.table.selectableRowsBatchAction('all'),
          },
        ],
      };
    },
  };
}
