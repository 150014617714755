import { PesticideIndication } from 'farmdok-rest-api';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import initialState from './initialState';
import { PesticideIndicationsState } from './types';

const mutations: MutationTree<PesticideIndicationsState> = {
  setData(
    state,
    { pesticideId, pesticideIndications }: { pesticideId: string; pesticideIndications: PesticideIndication[] },
  ) {
    Vue.set(state.data, pesticideId, pesticideIndications);
  },
  setFetching(state, fetching: boolean) {
    state.fetching = fetching;
  },
  reset(state) {
    const newState = initialState();
    Object.entries(newState).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },
};

export default mutations;
