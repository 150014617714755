<template>
  <div class="feature-not-available">
    <h2 v-if="!$slots.featureName && featureName != null" class="my-4">
      {{ featureName }}
    </h2>
    <slot name="featureName"></slot>
    <p v-if="featureVisible">
      {{ $t('Diese Funktion ist sehr beliebt. Jetzt im Shop freischalten und weitere Vorteile nutzen!') }}
    </p>
    <p v-else>{{ $t('Du verfügst auf diesem Betrieb über keine Berechtigung für dieses Feature.') }}</p>
    <div v-if="addLinkToShopComputed" class="d-print-none">
      <Button
        size="sm"
        variant="danger"
        leading-icon
        :icon="['far', 'shopping-cart']"
        @click="() => $router.push({ name: 'shop' })"
      >
        {{ $t('Jetzt Lizenz upgraden') }}
      </Button>
    </div>
    <div v-else-if="!noBackLink" class="d-print-none">
      <ButtonLink @click="$router.push({ name: 'home' })">
        {{ $t('Zur Startseite') }}
      </ButtonLink>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart as faShoppingCartRegular } from '@fortawesome/pro-regular-svg-icons';

import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

library.add(faShoppingCartRegular);

export default {
  name: 'FeatureNotAvailable',
  components: {
    Button,
    ButtonLink,
  },
  props: {
    featureName: {
      type: String,
      default: null,
    },
    featureVisible: {
      type: Boolean,
      default: true,
    },
    addLinkToShop: {
      type: Boolean,
      default: null,
    },
    noBackLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addLinkToShopComputed() {
      if (this.addLinkToShop != null) {
        return this.addLinkToShop;
      }
      if (this.featureVisible === false) {
        return false;
      }
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.CAN_PURCHASE_LICENSE);
    },
  },
};
</script>
