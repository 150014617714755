
import { Activity } from 'farmdok-rest-api';
import { defineComponent } from 'vue';

import columnDefinition from '@/activities/modals/createEditActivity/columns/columnDefinitionEditActivity';
import FirstStepContainer from '@/activities/modals/createEditActivity/containers/FirstStepContainer.vue';
import MapContainer from '@/activities/modals/createEditActivity/containers/MapContainer.vue';
import SecondStepContainer from '@/activities/modals/createEditActivity/containers/SecondStepContainer.vue';
import ThirdStepContainer from '@/activities/modals/createEditActivity/containers/ThirdStepContainer.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Pagination from '@/shared/components/Pagination.vue';
import { RootState } from '@/store/types';

export default defineComponent({
  name: 'ModalEditActivity',
  components: {
    ModalWrapper,
    Pagination,
    FirstStepContainer,
    MapContainer,
    SecondStepContainer,
    ThirdStepContainer,
  },
  data() {
    return {
      currentStep: 0 as number,
      customColumnDefinition: columnDefinition,
    };
  },
  computed: {
    selectedFields(): string[] | null {
      return this.$store.getters['activities/createEditActivity/selectedFields'];
    },
    timeStart(): number | null | undefined {
      if (!(this.$store.state as RootState).activities.createEditActivity.activities) return null;
      const dates = (this.$store.state as RootState).activities.createEditActivity.activities.map(
        (activity: Activity) => activity.timeStart,
      );
      return dates[0];
    },
    activityTypeId(): string | null {
      if (!(this.$store.state as RootState).activities.createEditActivity.activities) return null;
      const activityTypeIds = (this.$store.state as RootState).activities.createEditActivity.activities.map(
        (activity: Activity) => (activity.activityTypeId ? activity.activityTypeId : null),
      );
      return activityTypeIds[0];
    },
    title(): string {
      switch (this.currentStep) {
        case 0: {
          return `1. ${this.$t('Maßnahme und Datum auswählen')}`;
        }
        case 1: {
          return `2. ${this.$t('Felder auswählen')}`;
        }
        case 2: {
          return `3. ${this.$t('Produkt- & Maschinenauswahl')}`;
        }
        default: {
          return this.$t('Maßnahme hinzufügen') as string;
        }
      }
    },
    paginationNextEnabled(): boolean {
      switch (this.currentStep as number) {
        case 0:
          if (this.timeStart && this.activityTypeId) {
            return true;
          }
          break;
        case 1:
          if (this.selectedFields && this.selectedFields?.length > 0) {
            return true;
          }
          break;
        case 2:
          return true;
        default:
          break;
      }
      return false;
    },
  },
  methods: {
    show(): void {
      // @ts-ignore
      this.$refs.modalWrapper?.show();
    },
    reset(): void {
      // @ts-ignore
      this.$refs.modalWrapper?.hide();
    },
    cancel(): void {
      this.currentStep = 0;
      this.$store.commit('activities/createEditActivity/reset');
    },
    next(): void {
      this.currentStep += 1;
    },
    back(): void {
      this.currentStep -= 1;
    },
    save(): void {
      this.$store.dispatch('activities/createEditActivity/save');
      // @ts-ignore
      this.$refs.modalWrapper?.hide();
    },
  },
});
