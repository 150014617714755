import { mapGetters } from 'vuex';

import { GUID_KEY } from '@/shared/constants';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';

/**
 * ATTENTION, USE WITH CARE!
 * Always use this mixin together with shared/handsontable/mixins/containers/tableContainerBase.
 * This mixin maps the data in the varieties store to Dropdown items.
 *
 * columnKeysToStore:
 * - key
 *   The column key for the column that gets the mapped data.
 * - value
 *   Is the sub-model key in the tableData object that can contain a variety.
 *   This is used to display variety info w/o loading the whole list from the BE.
 *
 * @param columnKeysToStore
 * @return Object
 */
export default function columnDropdownVarieties(columnKeysToStore = {}) {
  return {
    data() {
      return {
        columnSettingsMiddleware: [],
      };
    },
    computed: {
      ...mapGetters({
        varieties: 'varieties',
        varietiesUsed: 'varieties/used',
      }),
    },
    created() {
      this.columnSettingsMiddleware.push(this.columnDropdownVarietiesColumnSettings);
      this.addHotHook('afterSelection', this.columnDropdownVarietiesAfterSelection);
      this.addHotHook('afterChange', this.columnDropdownVarietiesAfterChange);
    },
    methods: {
      columnDropdownVarietiesColumnSettings(columns) {
        return columns.map((column) => {
          if (!Object.keys(columnKeysToStore).includes(column.key)) {
            return column;
          }
          return {
            ...column,
            getRendererValue: (...args) =>
              this.columnDropdownVarietiesGetRendererValue(columnKeysToStore[column.key], ...args),
            getItems: (...args) => this.columnDropdownVarietiesGetItems(...args),
          };
        });
      },
      columnDropdownVarietiesGetRendererValue(objectKey, { value, visualRow, guid: guidArg }) {
        let guid = guidArg;
        if (guid == null) {
          guid = this.$refs.table.visualRowToGuid(visualRow);
        }
        let text = '';
        if (this.varieties[value] != null && typeof this.varieties[value].name === 'string') {
          text = this.varieties[value].name;
        } else if (
          guid != null &&
          this.tableData[guid] != null &&
          getDescendantProp(this.tableData[guid], objectKey) != null &&
          typeof getDescendantProp(this.tableData[guid], objectKey).name === 'string'
        ) {
          text = getDescendantProp(this.tableData[guid], objectKey).name;
        }
        return text;
      },
      async columnDropdownVarietiesGetItems() {
        await this.$store.dispatch('varieties/subscribe');
        return [
          {
            name: this.$t('Zuletzt verwendet'),
            id: 'varieties-used',
            items: this.varietiesUsed.map((guid) => ({ id: guid, name: this.varieties[guid].name })),
            sort: false,
          },
          {
            name: this.$t('Sorten'),
            id: 'varieties',
            items: Object.values(this.varieties)
              .filter((variety) => !this.varietiesUsed.includes(variety[GUID_KEY]))
              .map((variety) => ({ id: variety[GUID_KEY], name: variety.name })),
            sort: true,
          },
        ];
      },
      columnDropdownVarietiesAfterSelection(visualRow, visualCol) {
        const physicalCol = this.hot.toPhysicalColumn(visualCol);
        if (Object.keys(columnKeysToStore).includes(this.tableSettings.columns[physicalCol].key)) {
          this.$store.dispatch('varieties/subscribe');
        }
      },
      columnDropdownVarietiesAfterChange(changes, source) {
        if (!['edit', 'CopyPaste.paste'].includes(source) || !Array.isArray(changes)) {
          return;
        }
        changes.forEach(([, prop, , newVarietyGuid]) => {
          const physicalCol = this.hot.propToCol(prop);
          if (!Object.keys(columnKeysToStore).includes(this.tableSettings.columns[physicalCol].key)) {
            return;
          }
          if (this.varieties[newVarietyGuid] != null) {
            this.$store.commit('varieties/useItem', newVarietyGuid);
          }
        });
      },
    },
  };
}
