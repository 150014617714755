
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import { RuleCheckStatusTypeLoading } from '@/activities/types';
import FooterRuleCheckStatus from '@/shared/components/FooterRuleCheckStatus.vue';

export default defineComponent({
  name: 'FooterLiveCheckContainer',
  components: { FooterRuleCheckStatus },
  computed: {
    ...mapState('precisionFarming/applicationMaps/spraying', {
      violations: 'violations',
      loading: 'checkingViolation',
    }),
    showRuleViolationModal: {
      get(): boolean {
        return this.$store.state.precisionFarming.applicationMaps.spraying.showRuleViolationModal;
      },
      set(show: boolean) {
        this.$store.state.precisionFarming.applicationMaps.spraying.showRuleViolationModal = show;
      },
    },
    status(): RuleCheckStatusTypeLoading {
      if (this.loading) {
        return 'LOADING';
      }

      if (this.violations.length) {
        return 'violation';
      }

      return 'OK';
    },
  },
});
