
import { defineComponent } from 'vue';

import { RootState } from '@/store/types';

import VisualizationSelect from '../../components/sidebarZones/VisualizationSelect.vue';
import { VisualizationMode } from '../../store/types';

export default defineComponent({
  name: 'VisualizationSelectContainer',
  components: { VisualizationSelect },
  computed: {
    selectedVisualizationMode: {
      get() {
        return (this.$store.state as RootState).precisionFarming.applicationMaps.fertilizationCereals.zoneMap
          .visualizationMode;
      },
      set(value: VisualizationMode) {
        this.$store.commit('precisionFarming/applicationMaps/fertilizationCereals/setVisualizationMode', value);
      },
    },
  },
});
