import { GeoObjectType } from 'farmdok-rest-api';
import { Module } from 'vuex';

import initialStateRemovable from '@/shared/mixins/store/removableData/initialState';
import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import initialStateSyncable from '@/shared/mixins/store/syncableData/initialState';
import { RootState } from '@/store/types';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { GeoObjectState } from './types';

export function initialState(): GeoObjectState {
  return {
    ...initialStateSubscribable(),
    ...initialStateSyncable(),
    ...initialStateRemovable(),
    isCreateActive: false,
    isEditActive: false,
    currentObjectType: GeoObjectType.IsolationZone,
  };
}

const geoObjectsModule: Module<GeoObjectState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};

export default geoObjectsModule;
