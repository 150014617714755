<template lang="html">
  <ModalWrapper
    ref="modalWrapper"
    size="max"
    body-class="modal-move-fields"
    :visible="visible"
    :title="copyInsteadOfMove ? $t('Felder kopieren') : $t('Felder verschieben')"
    @change="(value) => $emit('change', value)"
  >
    <template v-if="success" #default>
      <p>
        {{
          copyInsteadOfMove
            ? $t('Die Felder wurden erfolgreich kopiert.')
            : $t('Die Felder wurden erfolgreich verschoben.')
        }}
      </p>
    </template>
    <template v-else #default>
      <FormFieldSet class="modal-move-fields__form-field-set" :headline="$t('Ziel-Erntejahr')">
        <p v-if="singleField" class="mt-3">
          {{ description[0] }}<strong>{{ singleFieldNameLong }}</strong
          >{{ description[1] }}
        </p>
        <p v-else class="mt-3">
          {{ description }}
        </p>
        <FormFieldSelect
          v-if="availableCompanies.length > 1"
          v-model="selectedCompany"
          required
          :label="$t('Ziel-Betrieb')"
          :options="companyOptions"
          :disabled="fetching"
        />
        <FormFieldSelect
          v-model="selectedProcessOrder"
          required
          :label="$t('Ziel-Erntejahr')"
          :options="processOrderOptions"
          :disabled="fetching"
          :state="missingProcessOrder ? false : null"
        />
        <FormCheckbox v-model="copyInsteadOfMove" class="mt-3 mb-3" :disabled="fetching">
          {{ $t('Felder kopieren anstatt zu verschieben.') }}
        </FormCheckbox>
      </FormFieldSet>
      <FormFieldSet v-if="measuresExist" :headline="$t('Maßnahmen')">
        <p v-if="copyInsteadOfMove" class="mt-3">
          {{ $t('Es existieren Maßnahmen für die ausgewählten Felder. Möchtest du die Maßnahmen auch kopieren?') }}
        </p>
        <p v-else class="mt-3">
          {{ $t('Es existieren Maßnahmen für die ausgewählten Felder. Möchtest du die Maßnahmen auch verschieben?') }}
        </p>
        <FormFieldRadioGroup v-model="addMeasures" variant="no-label">
          <FormRadio v-if="copyInsteadOfMove" class="mt-3 mb-3" :value="true" :disabled="fetching">
            {{ $t('Ja, Maßnahmen auch in das Ziel-Erntejahr kopieren.') }}
          </FormRadio>
          <FormRadio v-else class="mt-3 mb-3" :value="true" :disabled="fetching">
            {{ $t('Ja, Maßnahmen auch in das Ziel-Erntejahr verschieben.') }}
          </FormRadio>
          <FormRadio v-if="copyInsteadOfMove" class="mt-3 mb-3" :value="false" :disabled="fetching">
            {{ $t('Nein, nur Felder kopieren.') }}
          </FormRadio>
          <FormRadio v-else class="mt-3 mb-3" :value="false" :disabled="fetching">
            {{ $t('Nein, Maßnahmen an der Quelle löschen.') }}
          </FormRadio>
        </FormFieldRadioGroup>
      </FormFieldSet>
      <FormFieldSet
        v-show="fieldIdsWithDuplicate != null && Object.keys(fieldIdsWithDuplicate).length > 0"
        ref="duplicateFieldSet"
        :headline="titleDuplicateFieldSet"
      >
        <p class="mt-3">
          {{ descriptionDuplicateFieldSet }}
        </p>
        <FormFieldRadioGroup v-model="duplicateAction" variant="no-label">
          <FormRadio
            v-if="copyInsteadOfMove"
            class="mt-3 mb-3"
            value="skip"
            :disabled="fetching"
            :state="
              fieldIdsWithDuplicate != null && Object.keys(fieldIdsWithDuplicate).length > 0 && duplicateAction == null
                ? false
                : null
            "
          >
            {{ $t('Felder nicht kopieren.') }}
          </FormRadio>
          <FormRadio
            v-else
            class="mt-3 mb-3"
            value="skip"
            :disabled="fetching"
            :state="
              fieldIdsWithDuplicate != null && Object.keys(fieldIdsWithDuplicate).length > 0 && duplicateAction == null
                ? false
                : null
            "
          >
            {{ $t('Felder nicht verschieben.') }}
          </FormRadio>
          <FormRadio
            ref="duplicateActionUpdate"
            class="mt-3 mb-3"
            value="update"
            :disabled="fetching || updateActionDisabled"
            :state="
              fieldIdsWithDuplicate != null &&
              Object.keys(fieldIdsWithDuplicate).length > 0 &&
              duplicateAction == null &&
              !updateActionDisabled &&
              !fetching
                ? false
                : null
            "
          >
            {{ $t('Felder aktualisieren.') }}
          </FormRadio>
          <FormRadio
            class="mt-3 mb-3"
            value="duplicate"
            :disabled="fetching"
            :state="
              fieldIdsWithDuplicate != null && Object.keys(fieldIdsWithDuplicate).length > 0 && duplicateAction == null
                ? false
                : null
            "
          >
            {{ $t('Duplikate erstellen.') }}
          </FormRadio>
        </FormFieldRadioGroup>
        <ContentBox>
          <p>
            {{ $t('Die folgenden Felder existieren bereits am Ziel.') }}
          </p>
          <ul class="modal-move-fields__duplicate-list">
            <li v-for="field in fieldsWithDuplicate" :key="field.id">
              {{ field.name }}
            </li>
          </ul>
        </ContentBox>
      </FormFieldSet>
      <BTooltip v-if="duplicateActionUpdateTarget != null" :target="duplicateActionUpdateTarget" delay="300">
        {{ $t('Es existieren mehrere Felder am Ziel.') }}
      </BTooltip>
    </template>
    <template #bottom-content>
      <ErrorUserMessage
        v-if="userErrorMessage != null"
        :error-user-message="userErrorMessage"
        class="m-0"
        show-first-only
      />
      <p
        v-else-if="
          fieldIdsWithDuplicate != null && Object.keys(fieldIdsWithDuplicate).length > 0 && duplicateAction == null
        "
        class="text-danger m-0"
      >
        <span>{{ $t('Bitte wähle wie Duplikate behandelt werden sollen.') }}</span>
      </p>
    </template>
    <template #map-container>
      <FieldsMap
        v-if="initialized"
        class="modal-insert-fields__map"
        ref="map"
        :companies="companies"
        :crops="crops"
        :disabledFields="disabledFields"
        :activeFields="activeFields"
      />
      <div v-else />
    </template>
    <template v-if="success" #buttons>
      <BButton variant="primary" @click="hide">
        <span>{{ $t('Schließen') }}</span>
      </BButton>
    </template>
    <template v-else #buttons>
      <BButton variant="primary" class="mr-3" :disabled="fetching" @click="move">
        <span>{{ copyInsteadOfMove ? $t('Felder kopieren') : $t('Felder verschieben') }}</span>
      </BButton>
      <BButton variant="outline_white" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import ContentBox from '@/shared/components/ContentBox.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';
import { comparatorAlphaNumeric } from '@/shared/modules/comparators';

import { combinedNameExclCrop } from '../handsontable/columns/columns';
import FieldsMap from './FieldsMap.vue';

export default {
  name: 'ModalMoveFields',
  components: {
    ErrorUserMessage,
    FormFieldRadioGroup,
    FormRadio,
    FormCheckbox,
    ContentBox,
    FormFieldSelect,
    FormFieldSet,
    FieldsMap,
    ModalWrapper,
  },
  mixins: [backendResponseErrorHelpers],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fieldIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      initialized: false,
      disabledFields: {},
      activeFields: {},
      fetching: false,
      fieldIdsWithDuplicate: null,
      success: false,

      missingProcessOrder: false,
      selectedCompany: null,
      selectedProcessOrder: null,
      copyInsteadOfMove: false,
      addMeasures: false,
      duplicateAction: null,
    };
  },
  async created() {
    await Promise.all([this.$store.dispatch('fields/subscribe'), this.$store.dispatch('activities/subscribe')]);
    this.disabledFields = Object.values(this.fields)
      .filter((field) => !this.fieldIds.includes(field.id))
      .reduce(
        (accumulator, field) => ({
          ...accumulator,
          [field.id]: field,
        }),
        {},
      );
    this.activeFields = this.fieldIds
      .filter((fieldId) => this.fields[fieldId] != null)
      .reduce(
        (accumulator, fieldId) => ({
          ...accumulator,
          [fieldId]: this.fields[fieldId],
        }),
        {},
      );

    this.selectedCompany = this.companies[0].id;
    this.initialized = true;
  },
  computed: {
    ...mapGetters({
      crops: 'products/crops',
      fields: 'fields/dataWithoutRemoved',
      measures: 'activities/data',
    }),
    companies() {
      return this.$store.state.auth.currentCompanies;
    },
    availableCompanies() {
      return Object.values(this.$store.state.auth.companiesById);
    },
    singleField() {
      return Object.values(this.activeFields).length === 1;
    },
    singleFieldNameLong() {
      if (!this.singleField) {
        return null;
      }
      return combinedNameExclCrop.data.call(this, this.fields[Object.values(this.activeFields)[0].id]);
    },
    description() {
      if (this.singleField) {
        if (this.availableCompanies.length === 1) {
          if (this.copyInsteadOfMove) {
            return this.$t('Wähle das Ziel-Erntejahr, wohin {field} kopiert werden soll.', {
              field: '#####',
            }).split('#####');
          }
          return this.$t('Wähle das Ziel-Erntejahr, wohin {field} verschoben werden soll.', {
            field: '#####',
          }).split('#####');
        }
        if (this.copyInsteadOfMove) {
          return this.$t('Wähle den Ziel-Betrieb und das Ziel-Erntejahr, wohin {field} kopiert werden soll.', {
            field: '#####',
          }).split('#####');
        }
        return this.$t('Wähle den Ziel-Betrieb und das Ziel-Erntejahr, wohin {field} verschoben werden soll.', {
          field: '#####',
        }).split('#####');
      }
      const description = [
        this.$t('Es sind {count} Felder ausgewählt.', { count: Object.values(this.activeFields).length }),
      ];
      if (this.availableCompanies.length === 1) {
        if (this.copyInsteadOfMove) {
          description.push(this.$t('Wähle das Ziel-Erntejahr, wohin die gewählten Felder kopiert werden sollen.'));
        } else {
          description.push(this.$t('Wähle das Ziel-Erntejahr, wohin die gewählten Felder verschoben werden sollen.'));
        }
      } else if (this.copyInsteadOfMove) {
        description.push(
          this.$t('Wähle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gewählten Felder kopiert werden sollen.'),
        );
      } else {
        description.push(
          this.$t(
            'Wähle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gewählten Felder verschoben werden sollen.',
          ),
        );
      }
      return description.join(' ');
    },
    companyOptions() {
      return [...this.availableCompanies]
        .sort((companyA, companyB) => {
          if (companyA.clusterId != null && companyB.clusterId == null) {
            return 1;
          }
          if (companyA.clusterId == null && companyB.clusterId != null) {
            return -1;
          }
          if (companyA.clusterId != null && companyB.clusterId != null) {
            if (companyA.clusterId < companyB.clusterId) {
              return -1;
            }
            if (companyA.clusterId > companyB.clusterId) {
              return 1;
            }
          }
          if (companyA.name < companyB.name) {
            return -1;
          }
          if (companyA.name > companyB.name) {
            return 1;
          }
          return 0;
        })
        .map((company) => ({
          value: company.id,
          text: company.name,
        }));
    },
    processOrderOptions() {
      const defaultOptions = { value: null, text: this.$t('Erntejahr wählen'), disabled: true };
      if (
        this.selectedCompany == null ||
        this.$store.state.auth.companiesById[this.selectedCompany] == null ||
        !Array.isArray(this.$store.state.auth.companiesById[this.selectedCompany].processOrders)
      ) {
        return [defaultOptions];
      }

      const companyIds = [];
      this.fieldIds.forEach((fieldId) => {
        if (
          this.fields[fieldId] == null ||
          this.fields[fieldId].processOrder == null ||
          companyIds.includes(this.fields[fieldId].processOrder.companyId)
        ) {
          return;
        }
        companyIds.push(this.fields[fieldId].processOrder.companyId);
      });
      return [
        defaultOptions,
        ...this.$store.state.auth.companiesById[this.selectedCompany].processOrders
          .filter((processOrder) => processOrder.type === 'inwork')
          .map((processOrder) => ({
            value: processOrder.id,
            text: processOrder.name,
            disabled:
              companyIds.includes(processOrder.companyId) &&
              processOrder.name === this.$store.state.auth.currentProcessOrderName,
          }))
          .sort((processOrderA, processOrderB) => processOrderA.text - processOrderB.text),
      ];
    },
    measuresExist() {
      if (Object.values(this.measures).length === 0) {
        return false;
      }
      let measureFieldIds = new Set();
      Object.values(this.measures).forEach((measure) => {
        measureFieldIds.add(measure.fieldId);
      });
      measureFieldIds = [...measureFieldIds];
      return this.fieldIds.some((fieldId) => measureFieldIds.includes(fieldId));
    },
    titleDuplicateFieldSet() {
      if (this.availableCompanies.length === 1) {
        return this.$t('Duplikate im Ziel-Erntejahr');
      }
      return this.$t('Duplikate im Ziel-Betrieb/Ziel-Erntejahr');
    },
    descriptionDuplicateFieldSet() {
      if (this.fieldIdsWithDuplicate == null) {
        return null;
      }
      if (this.fieldIds.length === 1) {
        return this.$t('Das gewählte Feld existiert bereits am Ziel. Wähle wie dieses Feld behandelt werden soll.');
      }
      if (Object.keys(this.fieldIdsWithDuplicate).length === 1) {
        return this.$t(
          '1 von {countTotal} Felder existiert bereits am Ziel. Wähle wie dieses Feld behandelt werden soll.',
          {
            countTotal: this.fieldIds.length,
          },
        );
      }
      return this.$t(
        '{count} von {countTotal} Felder existieren bereits am Ziel. Wähle wie diese Felder behandelt werden sollen.',
        {
          count: Object.keys(this.fieldIdsWithDuplicate).length,
          countTotal: this.fieldIds.length,
        },
      );
    },
    fieldsWithDuplicate() {
      if (this.fieldIdsWithDuplicate == null) {
        return null;
      }
      return Object.keys(this.fieldIdsWithDuplicate)
        .map((fieldId) => ({
          id: fieldId,
          name: this.fieldNameLong(fieldId),
        }))
        .sort((fieldA, fieldB) => comparatorAlphaNumeric(fieldA.name, fieldB.name));
    },
    updateActionDisabled() {
      if (this.fieldIdsWithDuplicate == null) {
        return false;
      }
      return Object.values(this.fieldIdsWithDuplicate).some(
        (targetIds) => Array.isArray(targetIds) && targetIds.length > 1,
      );
    },
    duplicateActionUpdateTarget() {
      if (!this.updateActionDisabled) {
        return null;
      }
      if (this.$refs.duplicateActionUpdate == null) {
        return null;
      }
      return this.$refs.duplicateActionUpdate.$el.querySelector('label');
    },
  },
  watch: {
    async selectedCompany() {
      this.selectedProcessOrder = null;
    },
    selectedProcessOrder() {
      this.userErrorMessage = null;
      this.fieldIdsWithDuplicate = null;
      this.duplicateAction = null;
      this.missingProcessOrder = false;
    },
  },
  methods: {
    async move() {
      if (this.selectedProcessOrder == null) {
        this.missingProcessOrder = true;
        this.userErrorMessage = this.$t('{field} ist ein Pflichtfeld.', { field: this.$t('Ziel-Erntejahr') });
        return;
      }
      this.userErrorMessage = null;
      this.fetching = true;

      if (this.fieldIdsWithDuplicate == null) {
        await this.checkForDuplicates();
      }
      if (
        this.userErrorMessage != null ||
        (this.fieldIdsWithDuplicate != null &&
          Object.keys(this.fieldIdsWithDuplicate).length > 0 &&
          this.duplicateAction == null)
      ) {
        this.$nextTick(() => {
          this.$refs.duplicateFieldSet.$el.scrollIntoView();
        });
        this.fetching = false;
        return;
      }

      const fields = this.fieldIds
        .filter((fieldId) => {
          if (this.duplicateAction !== 'skip') {
            return true;
          }
          return this.fieldIdsWithDuplicate == null || this.fieldIdsWithDuplicate[fieldId] == null;
        })
        .map((fieldId) => {
          let targetId = null;
          if (
            this.duplicateAction === 'update' &&
            this.fieldIdsWithDuplicate != null &&
            Array.isArray(this.fieldIdsWithDuplicate[fieldId]) &&
            this.fieldIdsWithDuplicate[fieldId].length === 1
          ) {
            [targetId] = this.fieldIdsWithDuplicate[fieldId];
          }
          return { id: fieldId, targetId };
        });

      if (fields.length === 0) {
        this.fetching = false;
        this.success = true;
        this.$emit('success');
        return;
      }

      try {
        this.source = axios.CancelToken.source();
        const { data } = await axios.post(
          '/admin/rest/field/move_into_processorder',
          {
            data: {
              processOrderId: this.selectedProcessOrder,
              fields,
              copyInsteadOfMove: this.copyInsteadOfMove,
              addMeasures: this.addMeasures,
            },
          },
          { cancelToken: this.source.token },
        );
        if (data == null || data.status !== 'success') {
          this.setUserErrorMessageFromResponse(data);
          this.fetching = false;
          return;
        }
      } catch (error) {
        this.setUserErrorMessageFromResponse(error);
        this.fetching = false;
        return;
      }

      await this.$store.dispatch('fields/refresh');

      if (!this.copyInsteadOfMove) {
        this.$store.commit('fields/syncAllRemovesFinish', fields);
      }

      this.fetching = false;
      this.success = true;
      this.$emit('success');
    },
    async checkForDuplicates() {
      try {
        this.source = axios.CancelToken.source();
        const { data } = await axios.post(
          '/admin/rest/field/check_for_duplicates_in_processorder',
          {
            data: {
              processOrderId: this.selectedProcessOrder,
              fieldIds: this.fieldIds,
            },
          },
          { cancelToken: this.source.token },
        );
        if (data == null || data.status !== 'success') {
          this.setUserErrorMessageFromResponse(data);
          return;
        }
        if (data.data != null && Object.keys(data.data).length > 0) {
          this.fieldIdsWithDuplicate = data.data;
        }
      } catch (error) {
        this.setUserErrorMessageFromResponse(error);
      }
    },
    hide() {
      if (this.source != null) {
        this.source.cancel();
      }
      this.$refs.modalWrapper.hide();
    },
    fieldNameLong(fieldId) {
      return combinedNameExclCrop.data.call(this, this.fields[fieldId]);
    },
  },
};
</script>

<style lang="css" scoped>
.modal-move-fields__duplicate-list {
  margin-bottom: 0;
  padding-inline-start: 25px;
}
</style>
