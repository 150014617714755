
import Handsontable from 'handsontable';
import numbro from 'numbro';
import { PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import columnDropdownCompanies from '@/auth/mixins/containers/columnDropdownCompanies';
import columnDropdownCustomers from '@/customers/mixins/containers/columnDropdownCustomers';
import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import { FieldsState } from '@/fields/store/types';
import columnDropdownCrops from '@/products/crops/mixins/containers/columnDropdownCrops';
import { Field } from '@/shared/api/rest/models';
import { REGION_GUIDS_ENUM } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import subscribableTableData from '@/shared/handsontable/mixins/containers/featureSubscribableTableData';
import syncableTableData from '@/shared/handsontable/mixins/containers/featureSyncableTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { Data } from '@/shared/mixins/store/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import columnDropdownVarieties from '@/varieties/mixins/containers/columnDropdownVarieties';

import {
  amaCode,
  catchCropVariant,
  companyId,
  crop,
  customer,
  gwArea,
  kindOfUse,
  preCrop,
  variety,
} from '../handsontable/columns/columns';
import columnsSidebarTableFields from '../handsontable/columns/sidebarTableFields';
import columnDropdownForFields from '../mixins/containers/columnDropdownForFields';
import {
  STORE_KEY_AMA_CODES,
  STORE_KEY_CATCH_CROP_VARIANTS,
  STORE_KEY_GW_AREAS,
  STORE_KEY_KIND_OF_USE_TYPES,
} from '../mixins/containers/dropdownItemsForFields';

type ColumnDefinition = {
  international: Handsontable.ColumnSettings[];
  [REGION_GUIDS_ENUM.AT]: Handsontable.ColumnSettings[];
  [REGION_GUIDS_ENUM.DE]: Handsontable.ColumnSettings[];
};

export default defineComponent({
  name: 'TableFieldsContainer',
  components: { TableBase, TableFooter },
  props: {
    tableId: {
      type: String as PropType<string>,
      required: true,
    },
    allowFieldsWithNoContour: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    filterString: {
      type: String as PropType<string>,
      default: '',
    },
    selectedRows: {
      type: Array as PropType<string[] | null>,
      default: null,
    },
    customColumnDefinition: {
      type: Object as PropType<ColumnDefinition | null>,
      default: null,
    },
    customTableData: {
      type: Object as PropType<Data<Field>>,
      default: null,
    },
    sumProcessedArea: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  mixins: [
    tableContainerBase,
    subscribableTableData('fields'),
    syncableTableData('fields'),
    columnDropdownCompanies(companyId.key) as never,
    columnDropdownCrops({
      [crop.key]: 'crop',
      [preCrop.key]: 'preCrop',
    }) as never,
    columnDropdownCustomers({
      [customer.key]: 'fieldGroup.customer',
    }) as never,
    columnDropdownVarieties({
      [variety.key]: 'variety',
    }) as never,
    columnDropdownForFields({
      [kindOfUse.key]: STORE_KEY_KIND_OF_USE_TYPES,
      [catchCropVariant.key]: STORE_KEY_CATCH_CROP_VARIANTS,
      [amaCode.key]: STORE_KEY_AMA_CODES,
      [gwArea.key]: STORE_KEY_GW_AREAS,
    }),
    calculateSizeTotal as never,
  ],
  data() {
    return {
      columnDefinition: columnsSidebarTableFields as unknown as ColumnDefinition,
    };
  },
  watch: {
    customColumnDefinition: {
      handler() {
        if (this.customColumnDefinition) {
          this.columnDefinition = this.customColumnDefinition;
        }
      },
      immediate: true,
    },
  },
  created() {
    // @ts-ignore
    this.addHotHook('beforeOnCellMouseOver', (event: MouseEvent, { row, col }: { row: number; col: number }) => {
      if (this.allowFieldsWithNoContour) {
        return;
      }
      // @ts-ignore
      const guid = this.$refs.table.visualRowToGuid(row);
      // @ts-ignore
      const physicalCol = this.hot.toPhysicalColumn(col);

      if (!guid) {
        return;
      }
      // @ts-ignore
      if (this.tableSettings.columns[physicalCol].key !== selectColumn.key || this.hasFieldContour(guid)) {
        return;
      }
      this.$emit('update:cellReadOnly', event.target);
    });
  },
  computed: {
    processedAreaOrFieldSizeTotal(): string {
      // @ts-ignore
      return this.sumProcessedArea ? numbro(this.sumProcessedArea).format() : this.sizeTotalFormatted;
    },
    ...mapState('fields', {
      loadingNdvi: (state: FieldsState): boolean => state.loadingNdvi,
      pendingNdvi(state: FieldsState): Field[] {
        return Object.values<Field>(state.data).filter((field: Field) => field.ndvi === undefined);
      },
      loadingMbi: (state: FieldsState): boolean => state.loadingMbi,
      pendingMbi: (state: FieldsState): Field[] =>
        Object.values<Field>(state.data).filter((field: Field) => field.mbi === undefined),
    }),
    loading(): boolean {
      const customersLoading = this.$store.getters.currentCompaniesHaveFeatureVisible(
        availableFeatures.FEATURE_CUSTOMERS,
      )
        ? this.$store.getters['customers/loading']
        : false;

      return (
        this.$store.getters['fields/loading'] ||
        this.$store.getters['products/crops/loading'] ||
        this.$store.getters['varieties/loading'] ||
        customersLoading
      );
    },
    customTableDataOrDefault(): Data<Field> {
      // @ts-ignore
      return this.customTableData || this.tableData;
    },
  },
  methods: {
    async loadNdviData() {
      if (!this.$refs.table) {
        return;
      }

      // wait until the table is fully rendered to access the right visibility states of the columns
      await (this.$refs.table as any).$nextTick();
      await (this.$refs.table as any).$nextTick(); // we need to wait two ticks on page refresh

      const ndviColumn = (this.$refs.table as any)
        .getColumnsForPageSettings()
        .find((column: any) => column.key === 'ndvi');

      if (ndviColumn && ndviColumn.visible && !this.loadingNdvi) {
        await this.$store.dispatch('fields/loadNdvi', this.pendingNdvi);
      }
    },
    async onTableSettingsChanged() {
      this.loadNdviData();
      this.loadMbiData();
    },
    async loadMbiData() {
      if (!this.$refs.table) {
        return;
      }

      // wait until the table is fully rendered to access the right visibility states of the columns
      await (this.$refs.table as any).$nextTick();
      await (this.$refs.table as any).$nextTick(); // we need to wait two ticks on page refresh

      const mbiColumn = (this.$refs.table as any)
        .getColumnsForPageSettings()
        .find((column: any) => column.key === 'mbi');

      if (mbiColumn && mbiColumn.visible && !this.loadingMbi) {
        await this.$store.dispatch('fields/loadMbi', this.pendingMbi);
      }
    },
    cells(physicalRow: number, physicalCol: number, props: any) {
      // @ts-ignore
      if (this.allowFieldsWithNoContour || this.tableSettings.columns[physicalCol].key !== selectColumn.key) {
        return props;
      }
      // @ts-ignore
      const guid = this.$refs.table.physicalRowToGuid(physicalRow);
      // @ts-ignore
      if (this.tableData[guid] == null) {
        return props;
      }

      return {
        ...props,
        readOnly: !this.hasFieldContour(guid),
      };
    },
    selectAllRows(): void {
      // @ts-ignore
      let selectedRows = [...new Array(this.hot.countRows()).keys()].map((visualRow) =>
        // @ts-ignore
        this.$refs.table.visualRowToGuid(visualRow),
      );
      if (!this.allowFieldsWithNoContour) {
        selectedRows = selectedRows.filter((guid) => this.hasFieldContour(guid));
      }
      this.$emit('update:selectedRows', selectedRows);
    },
    hasFieldContour(guid: string): boolean {
      // @ts-ignore
      const field = this.tableData[guid];
      return (
        field != null &&
        field.fieldContour != null &&
        field.fieldContour.geoJson != null &&
        field.fieldContour.geoJson.type === 'Polygon'
      );
    },
    loadAdditionalData(): void {
      // fields/subscribe will be called via mixin subscribableTableData
      this.$store.dispatch('products/crops/subscribe');
      this.$store.dispatch('varieties/subscribe');
      if (this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS)) {
        this.$store.dispatch('customers/subscribe');
      }
    },
    updateCell(data: { guid: string; columnKey: string; value: any }): void {
      this.$emit('update:cell', data);
    },
    async tableMounted() {
      this.loadNdviData();
      this.loadMbiData();
    },
  },
});
