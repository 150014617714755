
import { defineComponent } from 'vue';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';

export default defineComponent({
  name: 'ModalConfirm',
  components: {
    ModalWrapper,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
});
