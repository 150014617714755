import { render, staticRenderFns } from "./PageFieldGroups.vue?vue&type=template&id=3e3c3fb4&scoped=true&"
import script from "./PageFieldGroups.vue?vue&type=script&lang=js&"
export * from "./PageFieldGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e3c3fb4",
  null
  
)

export default component.exports