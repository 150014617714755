import Vue from 'vue';
import { MutationTree } from 'vuex';

import availableFeatureProperties from './availableFeatureProperties';
import availableFeatures from './availableFeatures';
import { AvailableFeatureProperties, FeaturesState } from './types';

const mutations: MutationTree<FeaturesState> = {
  addFeaturesToCompany(state, { companyId, features }: { companyId: string; features: string[] }) {
    let featuresByCompany: Record<string, AvailableFeatureProperties> = {};
    if (state.availableFeaturesByCompanyId[companyId] != null) {
      featuresByCompany = { ...state.availableFeaturesByCompanyId[companyId] };
    }
    Object.keys(features).forEach((featureName) => {
      // @ts-ignore
      if (availableFeatures[featureName] == null) {
        console.error(
          `availableFeatures: Invalid feature "${featureName}". Add the feature, including proper documentation, to availableFeatures.ts.`,
        );
      }
      // @ts-ignore
      if (typeof features[featureName] === 'boolean') {
        console.error(`availableFeatures: Invalid feature type "${featureName}".`);
        featuresByCompany[featureName] = {
          // @ts-ignore
          visible: features[featureName],
          // @ts-ignore
          enabled: features[featureName],
        };
        return;
      }
      // @ts-ignore
      const feature = { ...features[featureName] };
      Object.keys(feature).forEach((featurePropertyKey) => {
        // @ts-ignore
        if (availableFeatureProperties[featurePropertyKey] == null) {
          console.error(
            `availableFeatures: Unknown feature property "${featurePropertyKey}" for feature "${featureName}".`,
          );
          return;
        }
        if (
          // @ts-ignore
          typeof availableFeatureProperties[featurePropertyKey].validator === 'function' &&
          // @ts-ignore
          availableFeatureProperties[featurePropertyKey].validator(feature[featurePropertyKey]) === false
        ) {
          console.error(
            `availableFeatures: Invalid value for feature property "${featurePropertyKey}" for feature "${featureName}". Allowed types see availableFeatures.ts.`,
          );
        }
      });
      featuresByCompany[featureName] = feature;
    });
    Vue.set(state.availableFeaturesByCompanyId, companyId, featuresByCompany);
  },
};

export default mutations;
