import { crop } from '@/fields/handsontable/columns/columns';

import { agranaFieldNameCombined, amount, creationDate, fieldId, processedArea, userComment } from './columns';

export const materialKey = 'materialId';
export const agranaFieldName = 'feldname';
export const agranaFieldGroupNumber = 'feldstuecknr';

export const tableImportFieldsYieldsFieldMapping = [
  {
    ...creationDate,
    required: true,
    lockedVisibility: true,
  },
  agranaFieldNameCombined,
  {
    ...fieldId,
    required: true,
  },
  processedArea,
  {
    ...crop,
    key: materialKey,
    required: true,
    className: 'border-right',
    lockedVisibility: true,
    width: 220,
  },
  {
    ...amount,
    required: true,
    className: 'border-right',
  },
  {
    ...userComment,
    lockedVisibility: true,
  },
];

export default tableImportFieldsYieldsFieldMapping;
