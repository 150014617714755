
import { defineComponent } from 'vue';

import { ActivityDuplication } from '@/activities/store/types';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';

import ActivityDuplicationsContainer from './ActivityDuplicationsContainer.vue';

export default defineComponent({
  name: 'ModalDuplicateActivity',
  components: { ModalWrapper, ActivityDuplicationsContainer },
  data() {
    return {
      duplications: [] as ActivityDuplication[],
    };
  },
  methods: {
    async show(duplications: ActivityDuplication[]): Promise<boolean> {
      this.duplications = duplications;
      // @ts-ignore
      return this.$refs.modalWrapper.show();
    },
    hide(): void {
      this.duplications = [];
      // @ts-ignore
      this.$refs.modalWrapper.hide();
    },
  },
});
