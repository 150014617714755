
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Handsontable from 'handsontable';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ModalCreateActivity from '@/activities/modals/createEditActivity/containers/ModalCreateActivity.vue';
import ModalEditActivity from '@/activities/modals/createEditActivity/containers/ModalEditActivity.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import ModalTableExport, { DropdownItemWithSubtableColumnData } from '@/shared/components/ModalTableExport.vue';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { ExportOption } from '@/shared/handsontable/rework/features/export/types';
import { ColumnSettingsFlattenedSubtable } from '@/shared/handsontable/rework/features/nestedTable/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import ModalDuplicateActivity from './containers/ModalDuplicateActivity.vue';
import ModalPageSettingsContainer from './containers/table/ModalPageSettingsContainer.vue';
import TableActivitiesContainer from './containers/table/TableActivitiesContainer.vue';
import TheContentbarContainer from './containers/table/TheContentbarContainer.vue';
import activitiesColumns from './handsontable/columns';
import ModalMoveActivity from './modals/moveActivity/containers/ModalMoveActivity.vue';
import ModalRuleCheckViolations from './ruleCheck/containers/ModalRuleCheckViolationsContainer.vue';
import { ActivityDuplication } from './store/types';
import { TableDataActivity } from './types';

library.add(faCopy);

export default defineComponent({
  name: 'PageActivities',
  components: {
    TableActivitiesContainer,
    TheContentbarContainer,
    ThePageSkeleton,
    ModalPageSettingsContainer,
    ModalTableExport,
    ModalCreateActivity,
    ModalDuplicateActivity,
    ModalMoveActivity,
    ModalRuleCheckViolations,
    ModalEditActivity,
    FeatureNotAvailable,
  },
  mixins: [buttonUpdateSelection('table')],
  data() {
    return {
      filterString: '',
      hot: null as Handsontable | null,
    };
  },
  computed: {
    ...mapGetters('activities', ['loading', 'selectedActivityIds']),
    ...mapGetters({
      activities: 'activities/data',
    }),
    exportDisabled(): boolean {
      return !this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_EXPORT_FIELD);
    },
    tableRef(): any {
      if (!this.$refs.table) return null;
      return this.$refs.table.$refs.table;
    },
    tableData(): TableDataActivity[] {
      const tableData = this.$store.getters['activities/tableDataActivities'];
      return tableData;
    },
    activitiesColumns() {
      return activitiesColumns;
    },
    columns(): ColumnSettingsFlattenedSubtable[] | null {
      if (!this.hot || this.hot.isDestroyed) return null;
      const { columns } = this.hot.getSettings();
      return columns || null;
    },
    visibleRowsIds(): Set<string> | null {
      if (!this.$refs.table || !this.$refs.table.$refs.table) {
        return null;
      }
      return this.$refs.table.$refs.table.visibleRowsIds;
    },
    allRowsVisible(): boolean | null {
      if (!this.tableRef) return null;
      return this.tableRef.allRowsVisible;
    },
    groupedTableDataIds(): Set<string> | null {
      if (!this.tableRef) return null;
      return this.tableRef.groupedTableDataIds;
    },
    dropdownItemsWithSubtableColumnData(): DropdownItemWithSubtableColumnData[] {
      return [
        { id: ExportOption.PER_ROW_ID, name: this.$t('Eine Zeile pro Maßnahme'), subtableColumnData: undefined },
        { id: ExportOption.PER_SUBTABLE, name: this.$t('Eine Zeile pro Produkt'), subtableColumnData: 'product' },
        { id: ExportOption.PER_SUBTABLE, name: this.$t('Eine Zeile pro Maschine'), subtableColumnData: 'equipment' },
      ];
    },
    featureEnabled(): Boolean {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_ACTIVITIES);
    },
    featureVisible(): Boolean {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_ACTIVITIES);
    },
    featureReadonly(): Boolean {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_ACTIVITIES);
    },
  },
  methods: {
    updateHot(hot: Handsontable) {
      this.hot = hot;
    },
    onCreateButtonClick() {
      this.$refs.modalCreateActivity.show();
    },
    restoreActivities() {
      this.$router.push({ name: 'activities/restore' });
    },
    async onShowSettings() {
      this.$refs.modalPageSettings.show();

      /*
      OLD Code
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      const settings = await this.$refs.modalPageSettings.show();
      if (settings != null && Array.isArray(settings.columns)) {
        this.$refs.table.$refs.table.updateColumnsFromPageSettings(settings.columns);
      }
      */
    },
    async onExportSelected(selectedRows: string[]) {
      const { exportFiltered, okay } = await this.$refs.modalTableExport.show();

      if (!okay) {
        return;
      }

      let rowIds: string[] = [];

      if (exportFiltered) {
        const filteredSelectedRows = selectedRows.filter((selectedRow) => this.visibleRowsIds?.has(selectedRow));
        rowIds = filteredSelectedRows;
      } else {
        rowIds = selectedRows;
      }

      this.$refs.modalTableExport.onExportSelected(rowIds);
    },

    async onExportData() {
      const { exportFiltered, okay } = await this.$refs.modalTableExport.show();

      if (!okay) {
        return;
      }

      let rowIds: string[] = [];

      if (exportFiltered) {
        rowIds = Array.from(this.visibleRowsIds);
      } else {
        rowIds = Array.from(this.groupedTableDataIds);
      }

      this.$refs.modalTableExport.onExportSelected(rowIds);
    },
    async onDuplicateButtonClick(selectedRows: string[]) {
      const duplications: ActivityDuplication[] = await this.$store.dispatch('activities/duplicate', selectedRows);
      this.$refs.modalDuplicateActivity?.show(duplications);
    },
    onEditButtonClick(selectedRows: string[]) {
      this.$store.dispatch('activities/createEditActivity/setupEditActivityStore', selectedRows);
      this.$refs.modalEditActivity.show();
    },
    onMoveButtonClick() {
      this.$refs.modalMoveActivity.show();
    },
    onSelectAll() {
      this.$refs.table.$refs.table.selectAllVisible(true);
    },
    onDeselectAll() {
      this.$refs.table.$refs.table.selectAllVisible(false);
    },
    onInvertSelection() {
      this.$refs.table.$refs.table.invertSelection();
    },
  },
});
