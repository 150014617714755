import { render, staticRenderFns } from "./ModalContentViolationNotChecked.vue?vue&type=template&id=4892bd57&"
import script from "./ModalContentViolationNotChecked.vue?vue&type=script&setup=true&lang=ts&generic=T%20extends%20%7B%20links%3A%20%7Bhref%3A%20string%3B%20label%3A%20string%3B%7D%5B%5D%20%3B%7D&"
export * from "./ModalContentViolationNotChecked.vue?vue&type=script&setup=true&lang=ts&generic=T%20extends%20%7B%20links%3A%20%7Bhref%3A%20string%3B%20label%3A%20string%3B%7D%5B%5D%20%3B%7D&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports