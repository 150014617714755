export enum ExportOption {
  PER_ROW_ID = 'PER_ROW_ID',
  PER_SUBTABLE = 'PER_SUBTABLE',
}
// eslint-disable-next-line import/prefer-default-export
export enum LutIndex {
  HEADER,
  SUBHEADER,
  DEFAULT,
  NUMERIC,
  DATETIME,
}
