<template>
  <ThePageSkeleton
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #left="{ contentbarLarge }">
          <ButtonDropdown
            v-if="editMode"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonUpdateSelection"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="selectedRows = []"
          />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar class="mr-1" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <Button color="lightest" component-namespace-name="ButtonClose" @click="back">
            {{ $t('Schließen') }}
          </Button>
          <Button color="primary" component-namespace-name="ButtonRestore" :fetching="restoring" @click="restore">
            {{ $t('Wiederherstellen') }}
          </Button>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableFieldsRestoreContainer
        ref="table"
        :contractId="contractId"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import TableFieldsRestoreContainer from './containers/TableFieldsRestoreContainer.vue';

export default {
  name: 'PageManagedContractFieldsRestore',
  components: {
    TheContentbar,
    ThePageSkeleton,
    TableFieldsRestoreContainer,
    ButtonDropdown,
    SearchBar,
    Button,
  },
  mixins: [buttonUpdateSelection('table')],

  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      restoring: false,
    };
  },
  computed: {
    contractId() {
      return this.$route.params.contractId;
    },
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_MANAGED_CONTRACTS);
    },
    editMode() {
      return this.selectedRows.length > 0;
    },
  },
  methods: {
    back() {
      this.$router.push({ name: 'digital-contracting/managed-contracts/fields' });
    },
    async restore() {
      this.restoring = true;
      const success = await this.$refs.table.restore();
      this.restoring = false;
      if (success) {
        this.back();
      }
    },
  },
};
</script>
