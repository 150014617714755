import { GetterTree } from 'vuex';

import usedItems from '@/shared/mixins/store/usedItems';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { CropsState } from './types';

const getters: GetterTree<CropsState, RootState> = {
  ...subscribableStore.getters,
  ...usedItems.getters,
};

export default getters;
