import { CellCoords } from '@/shared/handsontable/types';

export default function selectClickHandlerFactory(onClick: (selected: boolean) => void) {
  return function selectClickHandler(event: MouseEvent, coords: CellCoords, TD: HTMLTableCellElement) {
    if (!TD.classList.contains('checkbox')) return;

    const checkbox = TD.querySelector('.checkbox-icon');
    if (!checkbox) return;

    const currentCheckboxState = (checkbox as HTMLElement).dataset.icon === 'square-check';
    onClick(!currentCheckboxState);
  };
}
