import { Module } from 'vuex';

import { RootState } from '@/store/types';

import applicationMaps from '../application-maps/store';
import monitoring from '../monitoring/store';
import { PrecisionFarmingState } from './types';

const vuexModule: Module<PrecisionFarmingState, RootState> = {
  namespaced: true,
  state: {} as PrecisionFarmingState,
  modules: {
    applicationMaps,
    monitoring,
  },
};

export default vuexModule;
