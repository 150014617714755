import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { BaseWorkflowStoreModuleState } from './types';
import { ColorCode, Heatmap } from './types/Heatmap';
import { Zone } from './types/Zone';

export type Getters = {
  paginationNextEnabled: boolean;
  selectedTaskDate: Date | null;
  fieldsWithHeatmap: string[];
  currentHeatmaps: Record<string, Heatmap>;
  heatmapsOfSelectedFields: Heatmap[];
  zonesByHeatmaps: Zone[];
};

const moduleGetters: GetterTree<BaseWorkflowStoreModuleState, RootState> = {
  paginationNextEnabled: () => false, // override in derived store module
  selectedTaskDate: (state: BaseWorkflowStoreModuleState) =>
    state.selectedTaskDate ? new Date(state.selectedTaskDate) : null,
  // fields
  fieldsWithHeatmap: (state: BaseWorkflowStoreModuleState) =>
    Object.keys(state.heatmaps.current).map((productId) => productId.split('_')[0]),
  currentHeatmaps: (state: BaseWorkflowStoreModuleState) => state.heatmaps.current,
  heatmapsOfSelectedFields: (state: BaseWorkflowStoreModuleState, getters: Getters): Heatmap[] => {
    const isInSelectedFields = ([key]: [string, Heatmap]): boolean =>
      state.selectedFields.find((fieldName: string) => key.startsWith(fieldName)) !== undefined;
    return Object.entries(getters.currentHeatmaps)
      .filter(isInSelectedFields)
      .map(([, value]) => value);
  },
  zonesByHeatmaps(state: BaseWorkflowStoreModuleState): Zone[] {
    const zones: Record<string, Zone> = {};
    Object.values(state.heatmaps.current).forEach((heatmap: Heatmap) => {
      heatmap.color_codes.forEach((colorCode: ColorCode) => {
        const color = colorCode.col;
        if (zones[color] == null) {
          zones[color] = {
            size: 0,
            color,
            name: colorCode.name,
          };
        }
        zones[color].size += colorCode.area / 10000;
      });
    });
    const sortedZones = Object.values(zones)
      .filter((zone: Zone) => zone.size > 0)
      .sort((first, second) => {
        let firstName: string | number = first.name;
        let secondName: string | number = second.name;

        if (typeof firstName === 'string' && typeof secondName === 'string') {
          if (firstName.includes('snow')) {
            return 1;
          }
          if (secondName.includes('snow')) {
            return -1;
          }
          if (firstName.includes('cloud')) {
            return 1;
          }
          if (secondName.includes('cloud')) {
            return -1;
          }
        }

        // if both names are numeric, convert them to numbers and use those for comparison
        if (Number.isFinite(Number.parseFloat(first.name)) && Number.isFinite(Number.parseFloat(second.name))) {
          firstName = Number.parseFloat(first.name);
          secondName = Number.parseFloat(second.name);
        }
        if (firstName > secondName) {
          return -1;
        }
        return 1;
      });
    return sortedZones;
  },
};

export default moduleGetters;
