import { GeoObjectType } from 'farmdok-rest-api';
import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';

import { removableStore, subscribableStore, syncableStore } from './common';
import { GeoObjectState } from './types';

const actions: ActionTree<GeoObjectState, RootState> = {
  ...subscribableStore.actions,
  ...syncableStore.actions,
  ...removableStore.actions,
  toggleIsCreateActive({ commit, state }) {
    commit('setIsCreateActive', !state.isCreateActive);
  },
  toggleIsEditActive({ commit, state }) {
    commit('setIsEditActive', !state.isEditActive);
  },
  setIsCreateActive({ commit }, isCreateActive: boolean) {
    commit('setIsCreateActive', isCreateActive);
  },
  setIsEditActive({ commit }, isEditActive: boolean) {
    commit('setIsEditActive', isEditActive);
  },
  setCurrentObjectType({ commit }, geoObjectType: GeoObjectType) {
    commit('setCurrentObjectType', geoObjectType);
  },
};

export default actions;
