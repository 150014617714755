/**
 *
 * @param timeString in format HH:MM (e.g. from an html input field of type "time")
 * @returns time in minutes
 */
export function parseTimeToSeconds(timeString: string): number {
  const timeMatcher = /(\d\d):(\d\d)/;
  const result = timeMatcher.exec(timeString);

  if (!result) throw new Error(`Could not parse ${timeString}`);

  const hours = Number(result[1]);
  const minutes = Number(result[2]);

  return hours * 3600 + minutes * 60;
}

/**
 *
 * @param seconds
 * @returns time in format HH:MM
 */
export function parseSecondsToTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const hoursDoubleDigit = toDoubleDigit(hours);
  const minutesLeft = Math.floor((seconds - hours * 3600) / 60);
  const minutesLeftDoubleDigit = toDoubleDigit(minutesLeft);

  return `${hoursDoubleDigit}:${minutesLeftDoubleDigit}`;
}

function toDoubleDigit(num: number): string {
  return num.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
}
