import Handsontable from 'handsontable';

import ReadOnlyRenderer from './ReadOnlyRenderer';
import RequiredRenderer from './RequiredRenderer';

export default function TextRenderer(instance, TD, visualRow, col, prop, value, cellProperties, ...args) {
  if (cellProperties.required && (typeof value !== 'string' || value.length === 0)) {
    RequiredRenderer.apply(this, [instance, TD, visualRow, col, prop, value, cellProperties, ...args]);
    return;
  }

  const values = cellProperties.getToggleValues({
    value,
    visualRow,
  });
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    TD,
    visualRow,
    col,
    prop,
    values.join(', '),
    cellProperties,
    ...args,
  ]);

  TD.classList.add('table-toggle-renderer');
  if (values.length > 0) {
    const span = document.createElement('span');
    span.classList.add('table-toggle-renderer__pill', 'bg-primary', 'text-white');
    span.style.display = 'block';
    span.style.position = 'absolute';
    span.style.top = '13px';
    span.style.right = '20px';
    span.style.padding = '0 9px';
    span.style.borderRadius = '7px';
    span.style.lineHeight = '1.2em';
    span.style.fontSize = '13px';
    span.innerHTML = values.length;
    TD.append(span);
  }

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, visualRow, col, prop, value, cellProperties, ...args]);
  }
}
