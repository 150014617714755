import dropdownItemsForFields from './dropdownItemsForFields';

/**
 * ATTENTION, USE WITH CARE!
 * Always use this mixin together with shared/handsontable/mixins/containers/tableContainerBase.
 * This mixin maps the data in the fields store (e.g. kindOfUseTypes) to Dropdown items.
 *
 * columnKeysToStore:
 * key has to be the column key
 * value has to be one of the exported keys in this mixin
 *
 * @param {object} columnKeysToStore
 * @return Object
 */
export default function columnDropdownForFields(columnKeysToStore = {}) {
  return {
    data() {
      return {
        columnSettingsMiddleware: [],
      };
    },
    computed: dropdownItemsForFields.computed,
    created() {
      this.columnSettingsMiddleware.push(this.columnDropdownForFieldsColumnSettings);
    },
    methods: {
      ...dropdownItemsForFields.methods,
      columnDropdownForFieldsColumnSettings(columns) {
        return columns.map((column) => {
          if (!Object.keys(columnKeysToStore).includes(column.key)) {
            return column;
          }
          return {
            ...column,
            getRendererValue: (...args) =>
              this.dropdownItemsForFieldsGetRendererValue(columnKeysToStore[column.key], ...args),
            getItems: async (...args) => this.dropdownItemsForFieldsGetItems(columnKeysToStore[column.key], ...args),
          };
        });
      },
    },
  };
}
