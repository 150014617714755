export default {
  props: {
    /**
     * If set to true on right click a context menu will be shown.
     */
    contextMenu: {
      type: Boolean,
      default: false,
    },
    contextMenuHideEdit: {
      type: Boolean,
      default: false,
    },
    contextMenuHideInsert: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets all columns to read-only and disables the context menu.
     */
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      featureTableContextMenuGuid: null,
    };
  },
  mounted() {
    this.addHotHook('afterOnCellContextMenu', this.featureTableContextMenuBeforeOnCellContextMenu);
  },
  methods: {
    featureTableContextMenuBeforeOnCellContextMenu(event, { row: visualRow }) {
      if (this.readOnly || !this.contextMenu || visualRow < 0) {
        return;
      }
      event.preventDefault();
      this.featureTableContextMenuShow(event, this.visualRowToGuid(visualRow));
    },
    featureTableContextMenuShow(event, guid) {
      if (this.$refs.tableContextMenu == null || guid == null) {
        return;
      }
      this.featureTableContextMenuGuid = guid;
      this.$refs.tableContextMenu.show({
        left: event.clientX,
        top: event.clientY,
      });
    },
  },
};
