
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import ApplicationSelection from '@/precision-farming/application-maps/fertilizationCereals/components/sidebarApplicationMap/ApplicationSelection.vue';
import FirstApplicationForm from '@/precision-farming/application-maps/fertilizationCereals/components/sidebarApplicationMap/FirstApplicationForm.vue';
import SecondApplicationForm from '@/precision-farming/application-maps/fertilizationCereals/components/sidebarApplicationMap/SecondApplicationForm.vue';
import ThirdApplicationForm from '@/precision-farming/application-maps/fertilizationCereals/components/sidebarApplicationMap/ThirdApplicationForm.vue';
import ZoneDosageTableContainer from '@/precision-farming/application-maps/fertilizationCereals/containers/sidebarApplicationMap/ZoneDosageTableContainer.vue';
import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

import { ApplicationType } from '../../store/types';

export default defineComponent({
  name: 'ApplicationMapContainer',
  components: {
    SecondApplicationForm,
    FirstApplicationForm,
    ZoneDosageTableContainer,
    ApplicationSelection,
    SidebarSubHeadline,
    SidebarContentWrapper,
    ThirdApplicationForm,
  },
  data() {
    const applicationTypes: DropdownItem[] = [
      {
        id: ApplicationType.MANUAL,
        name: this.$t('Kein Vorschlag (manuelle Eingabe)') || 'Kein Vorschlag (manuelle Eingabe)',
      },
      {
        id: ApplicationType.FIRST,
        name: this.$t('Wintergetreide {n}. Gabe', { n: '1' }) || 'Wintergetreide {n}. Gabe',
      },
      {
        id: ApplicationType.SECOND,
        name: this.$t('Wintergetreide {n}. Gabe', { n: '2' }) || 'Wintergetreide {n}. Gabe',
      },
      {
        id: ApplicationType.THIRD,
        name: this.$t('Wintergetreide {n}. Gabe', { n: '3' }) || 'Wintergetreide {n}. Gabe',
      },
    ];

    return {
      applicationTypes,
      debouncedLoadFeaturesAndZones: null,
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilizationCereals', ['calculation', 'application']),
    ...mapGetters('precisionFarming/applicationMaps/fertilizationCereals', ['selectedField']),
    applicationDropdownItems(): DropdownItemsPerCategory[] {
      return [
        {
          items: this.applicationTypes,
        },
      ];
    },
    selectedApplicationType(): DropdownItem {
      return (
        this.applicationTypes.find(
          (applicationType: DropdownItem) => this.application.applicationType === applicationType.id,
        ) || { id: null, name: '' }
      );
    },
    showZoneDosageTable(): boolean {
      return this.selectedApplicationType?.id !== 'second';
    },
    loadDefaultValuesTrigger(): string {
      const dependencies = [this.selectedField, this.application.applicationType];
      return dependencies.map((value) => JSON.stringify(value)).join('-');
    },
  },
  watch: {
    loadDefaultValuesTrigger: {
      handler() {
        this.loadDefaultValues();
      },
    },
  },
  methods: {
    loadDefaultValues() {
      this.loadAndUpdateTargetYield();
      this.loadAndUpdateNRates();
    },
    async loadAndUpdateTargetYield() {
      if (this.application.applicationType !== ApplicationType.THIRD) return;

      const targetYield = await this.loadTargetYield(this.selectedField);
      const roundedTargetYield = this.roundToTwoDecimals(targetYield);
      this.updateApplicationField('applicationTypeThird.targetYield', roundedTargetYield);
    },
    async loadTargetYield(fieldId: string): Promise<number | null> {
      // @ts-ignore
      const { cultivationPlanApi } = this.$api as Api;
      try {
        const response = await cultivationPlanApi.cultivationPlanAtList({ fieldIds: fieldId });
        const yieldInTonsPerHa = response.data.data?.[0].expectedYield ?? null;
        return this.convertToKgPerHa(yieldInTonsPerHa);
      } catch (e) {
        return null;
      }
    },
    async loadAndUpdateNRates() {
      if (this.application.applicationType !== ApplicationType.THIRD) return;

      const { nFertilizedFirstApplication, nFertilizedOtherApplications } = await this.loadNRates();
      const roundedNFertilizedFirstApplication = this.roundToTwoDecimals(nFertilizedFirstApplication);
      const roundedNFertilizedOtherApplications = this.roundToTwoDecimals(nFertilizedOtherApplications);

      this.updateApplicationField('applicationTypeThird.averageFertilizerRate1N', roundedNFertilizedFirstApplication);
      this.updateApplicationField('applicationTypeThird.averageFertilizerRate2N', roundedNFertilizedOtherApplications);
    },
    async loadNRates(): Promise<{
      nFertilizedFirstApplication: number | null;
      nFertilizedOtherApplications: number | null;
    }> {
      // @ts-ignore
      const { fertilizationPlanApi } = this.$api as Api;
      try {
        const response = await fertilizationPlanApi.fertilizationPlanFieldBasedList({ fieldIds: this.selectedField });
        const { nFertilizedFirstApplication, nFertilizedOtherApplications } = response.data.data?.[0] ?? {
          nFertilizedFirstApplication: null,
          nFertilizedOtherApplications: null,
        };
        return { nFertilizedFirstApplication, nFertilizedOtherApplications };
      } catch (e) {
        return { nFertilizedFirstApplication: null, nFertilizedOtherApplications: null };
      }
    },
    convertToKgPerHa(tonsPerHa: number | null): number | null {
      if (!tonsPerHa) return null;
      return tonsPerHa * 1000;
    },
    roundToTwoDecimals(value: number | null): number | null {
      if (value === null) return null;
      return Math.round(value * 100) / 100;
    },
    updateApplicationSelection(value: ApplicationType) {
      this.updateApplicationField('applicationType', value);

      if (value !== ApplicationType.MANUAL) {
        this.resetManualDosage();
      }
    },
    updateApplicationField(key: string, value: number | ApplicationType | null | boolean) {
      this.$store.commit('precisionFarming/applicationMaps/fertilizationCereals/setApplicationField', {
        key,
        value,
      });
    },
    resetManualDosage() {
      this.$store.commit('precisionFarming/applicationMaps/fertilizationCereals/setManualDosage', null);
    },
    onError(value: boolean): void {
      this.updateApplicationField('error', value);
    },
  },
});
