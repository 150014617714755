import { WeatherApiWeatherAggregateRequest } from 'farmdok-rest-api';

import { WeatherInfo } from '@/precision-farming/monitoring/weather/store/types';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import subscribableData from '@/shared/mixins/store/subscribableData/index';

export async function fetchAll(): Promise<ApiResponse<WeatherInfo>> {
  throw new Error('not implemented yet!');
}

async function fetchByIds(): Promise<ApiResponse<WeatherInfo>> {
  throw new Error('not implemented yet!');
}

export function identifier(requestParameters: WeatherApiWeatherAggregateRequest): string {
  return `${requestParameters.aggregate}-${requestParameters.startDate}-${requestParameters.endDate}-${requestParameters.longitude}-${requestParameters.latitude}-${requestParameters.statistics}-${requestParameters.variables}-${requestParameters.tempBaseCrop}-${requestParameters.dateAccumulate}`;
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
