import { AxiosError } from 'axios';
import {
  CnhApiInterfacesCnhDownloadTasksRequest,
  CnhApiInterfacesCnhLoginRequest,
  CnhApiInterfacesCnhTasksRequest,
  CnhTask,
} from 'farmdok-rest-api';
import Vue, { ref } from 'vue';
import { useRouter } from 'vue-router/composables';

import { Api } from '@/plugins/farmdokRestApi';
import { baseUrl } from '@/shared/constants';

const cnhCompanyId = ref<string | undefined>();
const cnhCompanyTasks = ref<CnhTask[]>([]);
const cnhCompanyTasksFiltered = ref<CnhTask[]>([]);
const cnhImportDataLoading = ref(false);
const cnhEmail = ref('');
const cnhUserName = ref('');

export default function useCnhImport() {
  const { cnhApi } = Vue.prototype.$api as Api;
  const router = useRouter();

  /* -- METHODS -- */
  /* API Actions */
  function getCnhConnectionStatus() {
    return cnhApi.interfacesCnhConnectionStatus();
  }

  function cnhLogin(authorizationCode: string) {
    const requestParameters: CnhApiInterfacesCnhLoginRequest = {
      interfacesCnhLoginRequest: {
        authorizationCode,
        redirectUrl: `${origin}${baseUrl}oauth/cnh`,
      },
    };
    return cnhApi.interfacesCnhLogin(requestParameters);
  }

  function cnhLogout() {
    return cnhApi.interfacesCnhLogout();
  }

  async function getCnhCompanyOverview() {
    return cnhApi.interfacesCnhOverview();
  }

  async function getCnhCompanyTasks() {
    if (cnhCompanyId.value) {
      const requestParameters: CnhApiInterfacesCnhTasksRequest = {
        companyId: cnhCompanyId.value,
      };
      const tasksResponse = await cnhApi.interfacesCnhTasks(requestParameters);
      cnhCompanyTasks.value = tasksResponse.data.data ?? [];
    }
  }

  async function postCnhDownloadTasks(taskIds: string[]) {
    if (cnhCompanyId.value) {
      const requestParameters: CnhApiInterfacesCnhDownloadTasksRequest = {
        interfacesCnhDownloadTasksRequest: { taskIds, companyId: cnhCompanyId.value },
      };
      return cnhApi.interfacesCnhDownloadTasks(requestParameters);
    }
    return Promise.reject();
  }

  /* Utilities */
  function cnhErrorResponseHandler(error: AxiosError) {
    switch (error.response?.data.errorCode) {
      case 'externalApiAuthorization':
        router.push({ name: 'activities/import/choose-method' });
        break;
      default:
        router.push({ name: 'activities/import/choose-method' });
        console.error(error);
    }
  }

  return {
    // Reactive
    cnhCompanyTasks,
    cnhImportDataLoading,
    cnhCompanyId,
    cnhCompanyTasksFiltered,
    cnhEmail,
    cnhUserName,
    // Methods
    getCnhConnectionStatus,
    cnhLogin,
    cnhLogout,
    getCnhCompanyOverview,
    getCnhCompanyTasks,
    postCnhDownloadTasks,
    cnhErrorResponseHandler,
  };
}
