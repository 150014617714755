<template>
  <ThePageSkeleton :page-feature-enabled="!fieldsReadOnly">
    <template #contentbar>
      <TheContentbar>
        <template #right="{ contentbarLarge }">
          <SearchBar class="mr-1" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <BButton variant="lightest" class="text-dark mr-1 px-4" @click="back">
            {{ $t('Schließen') }}
          </BButton>
          <BButton
            variant="primary"
            class="mr-1 px-4"
            :fetching="restoring"
            :disabled="fieldsReadOnly"
            @click="restore"
          >
            {{ $t('Wiederherstellen') }}
          </BButton>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableFieldsRestoreContainer
        ref="table"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import TableFieldsRestoreContainer from './containers/TableFieldGroupsRestoreContainer.vue';

export default {
  name: 'PageFieldGroupsRestore',
  components: {
    TheContentbar,
    ThePageSkeleton,
    SearchBar,
    TableFieldsRestoreContainer,
  },
  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      restoring: false,
    };
  },
  computed: {
    fieldsReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_FIELDS);
    },
  },
  methods: {
    back() {
      this.$router.push({ name: 'field-groups' });
    },
    async restore() {
      this.restoring = true;
      const success = await this.$refs.table.restore();
      this.restoring = false;
      if (success) {
        this.back();
      }
    },
  },
};
</script>

<style scoped></style>
