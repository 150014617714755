<template lang="html">
  <div class="linechart">
    <p class="linechart__no-data" v-if="chartDataValuesY.length === 0">
      {{ missingDataMessageComputed }}
    </p>
    <svg :width="width" :height="height" class="linechart__data">
      <g :style="{ transform: `translate(${margin.left - 7}px, ${margin.top}px)` }">
        <text
          v-for="(caption, index) in captionsY"
          :key="`caption-${index}`"
          x="0"
          :y="caption.y + 3"
          text-anchor="end"
          font-size="12"
        >
          {{ caption.value | numbro({ mantissa: 1 }) }}
        </text>
      </g>
      <g :style="{ transform: `translate(${margin.left - 7}px, ${margin.top}px)` }">
        <path
          v-for="(line, index) in lines"
          :key="`line-${index}`"
          :d="line.data"
          :class="{
            'linechart__horizontal-helper': line.isHorizontalHelper,
            'linechart__horizontal-helper--zero': line.isZero,
            linechart__line: !line.isHorizontalHelper,
            'linechart__line--mean': line.isMean,
            'linechart__line--forecast': line.isForecast,
            'linechart__line--mean-overall': line.isMeanOverall,
            'linechart__line--min-max': line.isMinMax,
            'linechart__line--mean--transparent': line.greyedOut,
          }"
        ></path>
        <line
          v-if="selectedValueXComputed != null && scaleX != null"
          :x1="scaleX(selectedValueXComputed)"
          :y1="0"
          :x2="scaleX(selectedValueXComputed)"
          :y2="chartHeight"
          class="linechart__vertical-helper"
        ></line>
        <circle
          v-for="(circle, index) in circles"
          :key="`circle-${index}`"
          :cx="circle.cx"
          :cy="circle.cy"
          :r="circle.r"
          class="linechart__circle"
          :class="{
            'linechart__circle--mean': circle.isMean,
            'linechart__circle--forecast': circle.isForecast,
            'linechart__circle--mean-overall': circle.isMeanOverall,
            'linechart__circle--min-max': circle.isMinMax,
          }"
        ></circle>
      </g>
    </svg>

    <ChartTooltip
      v-if="tooltip"
      :chart-width="width"
      :top="tooltip.top"
      :left="tooltip.left"
      :entries="tooltip.entries"
    >
    </ChartTooltip>

    <svg :width="width" :height="height" class="linechart__hover">
      <g :style="{ transform: `translate(${margin.left}px, ${margin.top}px)` }">
        <g
          v-for="(tooltipBar, index) in tooltipBars"
          :key="`tooltipBar-${index}`"
          :style="{ transform: `translate(${tooltipBar.left}px, 0px)` }"
        >
          <rect
            :width="tooltipBar.width"
            :height="chartHeight"
            :data-value-x="tooltipBar.valueX"
            @mouseover="mouseover"
            @mouseout="mouseout"
          ></rect>
        </g>
      </g>
    </svg>

    <ChartAxisBottom
      v-if="width != null"
      v-on="this.$listeners"
      :chartWidth="width"
      :navigationWidth="margin.left"
      :data="axisDataComputed"
    />
  </div>
</template>

<script>
import { scaleLinear } from 'd3-scale';
import { area, curveMonotoneX, line } from 'd3-shape';
import numbro from 'numbro';

import isUnique from '@/shared/modules/isUniqueFilter';

import ChartAxisBottom from './ChartAxisBottom.vue';
import ChartTooltip from './ChartTooltip.vue';

const margin = {
  top: 50,
  right: 0,
  bottom: 30,
  left: 57,
};

export default {
  name: 'LineChart',
  components: { ChartAxisBottom, ChartTooltip },
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    selectedValueX: {
      type: Number,
      default: null,
    },
    minX: {
      type: Number,
      default: null,
    },
    maxX: {
      type: Number,
      default: null,
    },
    minY: {
      type: Number,
      default: null,
    },
    maxY: {
      type: Number,
      default: null,
    },
    axisData: {
      type: Array,
      default: () => [],
    },
    missingDataMessage: {
      type: String,
      default: null,
    },
    nDecimals: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      margin,
      hoveredValueX: null,

      // calculated from this.$el
      width: null,
      height: null,
      chartWidth: null,
      chartHeight: null,
    };
  },
  computed: {
    chartDataValuesX() {
      return this.chartData
        .flatMap((currentLine) => currentLine.dataPoints)
        .map((dataPoint) => dataPoint.x)
        .filter(isUnique)
        .sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });
    },
    minXComputed() {
      if (this.minX != null) {
        return Math.min(this.minX, ...this.chartDataValuesX);
      }
      return Math.min(...this.chartDataValuesX);
    },
    maxXComputed() {
      if (this.maxX != null) {
        return Math.max(this.maxX, ...this.chartDataValuesX);
      }
      return Math.max(...this.chartDataValuesX);
    },
    scaleX() {
      if (this.chartWidth == null || this.chartData.length < 2) {
        return null;
      }
      return scaleLinear()
        .domain([this.minXComputed, this.maxXComputed])
        .range([10, this.chartWidth - 10]);
    },
    chartDataValuesY() {
      return this.chartData.reduce(
        (chartDataValuesY, currentLine) => [
          ...chartDataValuesY,
          ...currentLine.dataPoints.reduce((currentLineValuesY, dataPoint) => {
            const values = [];
            ['y', 'yMin', 'yMax'].forEach((key) => {
              if (typeof dataPoint[key] === 'number') {
                values.push(dataPoint[key]);
              }
            });
            return [...currentLineValuesY, ...values];
          }, []),
        ],
        [],
      );
    },
    minYComputed() {
      const minY = Math.min(...this.chartDataValuesY);
      if (this.minY != null && this.minY < minY) {
        return this.minY;
      }
      const maxY = Math.max(...this.chartDataValuesY);
      return minY - (maxY - minY) * 0.05;
    },
    maxYComputed() {
      const maxY = Math.max(...this.chartDataValuesY);
      if (this.maxY != null && this.maxY > maxY) {
        return this.maxY;
      }
      const minY = Math.min(...this.chartDataValuesY);
      return maxY + (maxY - minY) * 0.05;
    },
    scaleY() {
      if (this.chartHeight == null) {
        return null;
      }
      return scaleLinear().domain([this.minYComputed, this.maxYComputed]).range([this.chartHeight, 0]);
    },
    tickValues() {
      if (this.chartHeight == null || this.chartData.length < 1) {
        return null;
      }
      let base = 0.1;
      let multiplier = 1;
      const range = this.maxYComputed - this.minYComputed;
      while (range > base * multiplier) {
        switch (multiplier) {
          case 1:
            multiplier = 2.5;
            break;
          case 2.5:
            multiplier = 5;
            break;
          case 5:
            multiplier = 7.5;
            break;
          case 7.5:
            multiplier = 1;
            base *= 10;
            break;
          default:
            break;
        }
      }
      const tickValues = [];
      switch (multiplier) {
        case 1:
          base /= 4;
          break;
        case 2.5:
          base /= 2;
          break;
        case 5:
          break;
        case 7.5:
          base *= 1.5;
          break;
        default:
          break;
      }
      let x = Math.abs(this.minYComputed) / base;
      if (this.minYComputed < 0) {
        x = Math.floor(x) * -1;
      } else {
        x = Math.ceil(x);
      }
      for (; base * x < this.maxYComputed; x += 1) {
        tickValues.push(base * x);
      }
      return tickValues;
    },
    captionsY() {
      if (this.tickValues == null || this.scaleY == null) {
        return null;
      }
      return this.tickValues.map((value) => ({
        y: this.scaleY(value),
        value,
      }));
    },
    lines() {
      const lines = [];
      if (this.tickValues == null || this.scaleY == null) {
        return lines;
      }

      // add horizontal helpers
      const horizontalHelper = line().x((value) => this.scaleX(value));
      this.tickValues.forEach((tickValue) => {
        lines.push({
          isHorizontalHelper: true,
          isZero: tickValue === 0,
          data: horizontalHelper.y(() => this.scaleY(tickValue))([this.minXComputed, this.maxXComputed]),
        });
      });

      // add lines
      this.chartData.forEach((currentLine) => {
        let lineData = line()
          .defined((dataPoint) => !dataPoint.placeholder)
          .x((dataPoint) => this.scaleX(dataPoint.x))
          .y((dataPoint) => this.scaleY(dataPoint.y))
          .curve(curveMonotoneX);
        if (currentLine.isMinMax) {
          lineData = area()
            .defined((dataPoint) => !dataPoint.placeholder)
            .x((dataPoint) => this.scaleX(dataPoint.x))
            .y0((dataPoint) => this.scaleY(dataPoint.yMin))
            .y1((dataPoint) => this.scaleY(dataPoint.yMax))
            .curve(curveMonotoneX);
        }
        lines.push({
          isMean: currentLine.isMean,
          isMeanOverall: currentLine.isMeanOverall,
          isMinMax: currentLine.isMinMax,
          data: lineData(currentLine.dataPoints),
          isForecast: currentLine.isForecast,
          greyedOut: currentLine.greyedOut,
        });
      });
      return lines;
    },
    circles() {
      if (this.width == null || this.scaleX == null || this.scaleY == null) {
        return null;
      }
      const circles = [];
      this.chartData.forEach((currentLine) => {
        currentLine.dataPoints.forEach((dataPoint) => {
          if (dataPoint.placeholder || dataPoint.x !== this.selectedValueXComputed) {
            return;
          }
          if (currentLine.isMinMax) {
            circles.push({
              isMean: currentLine.isMean,
              isMinMax: currentLine.isMinMax,
              isMeanOverall: currentLine.isMeanOverall,
              cx: this.scaleX(dataPoint.x),
              cy: this.scaleY(dataPoint.yMin),
              r: 3.5,
            });
            circles.push({
              isMean: currentLine.isMean,
              isMinMax: currentLine.isMinMax,
              isMeanOverall: currentLine.isMeanOverall,
              cx: this.scaleX(dataPoint.x),
              cy: this.scaleY(dataPoint.yMax),
              r: 3.5,
            });
          } else {
            circles.push({
              isMean: currentLine.isMean,
              isMinMax: currentLine.isMinMax,
              isMeanOverall: currentLine.isMeanOverall,
              isForecast: currentLine.isForecast,
              cx: this.scaleX(dataPoint.x),
              cy: this.scaleY(dataPoint.y),
              r: 3.5,
            });
          }
        });
      });
      return circles;
    },
    tooltipBars() {
      if (this.width == null) {
        return null;
      }
      const tooltipBars = [];
      let currentX = this.minXComputed;
      this.chartDataValuesX.forEach((valueX, index) => {
        const left = this.scaleX(currentX);
        let nextX = this.maxXComputed;
        if (this.chartDataValuesX[index + 1] != null) {
          nextX = (valueX + this.chartDataValuesX[index + 1]) / 2;
        }
        const width = this.scaleX(nextX) - left;
        tooltipBars.push({
          left,
          width,
          valueX,
        });
        currentX = nextX;
      });
      return tooltipBars;
    },
    selectedValueXComputed() {
      if (this.hoveredValueX != null) {
        return this.hoveredValueX;
      }
      if (this.selectedValueX != null) {
        return this.selectedValueX;
      }
      return null;
    },
    axisDataComputed() {
      const axisData = [];
      if (this.scaleX == null) {
        return axisData;
      }

      this.axisData.forEach((entry) => {
        axisData.push({
          caption: entry.caption,
          left: this.scaleX(entry.x),
          highlight: entry.highlight,
        });
      });
      if (this.selectedValueXComputed != null) {
        let caption = null;
        this.chartData.some((currentLine) =>
          currentLine.dataPoints.some((dataPoint) => {
            if (dataPoint.x !== this.selectedValueXComputed || dataPoint.caption == null) {
              return false;
            }
            ({ caption } = dataPoint);
            return true;
          }),
        );
        if (caption != null) {
          axisData.push({
            caption,
            left: this.scaleX(this.selectedValueXComputed),
            hovered: true,
          });
        }
      }
      return axisData;
    },
    tooltip() {
      if (this.selectedValueXComputed == null || this.scaleX == null || this.scaleY == null) {
        return null;
      }
      const valuesY = [];
      const entries = [];
      this.chartData.forEach((currentLine) => {
        currentLine.dataPoints.some((dataPoint) => {
          if (dataPoint.x !== this.selectedValueXComputed) {
            return false;
          }
          if (currentLine.isMinMax) {
            entries.push({
              color: '#D3EAEF',
              description: this.$t('Maximum:'),
              value: numbro(dataPoint.yMax).format({ mantissa: this.nDecimals }),
              sort: 0,
            });
            entries.push({
              color: '#D3EAEF',
              description: this.$t('Minimum:'),
              value: numbro(dataPoint.yMin).format({ mantissa: this.nDecimals }),
              sort: 2,
            });
          }
          if (currentLine.isMean) {
            entries.push({
              color: '#218299',
              description: currentLine.name || this.$t('Aktuell:'),
              value: numbro(dataPoint.y).format({ mantissa: this.nDecimals }),
              sort: 1,
            });
          }
          if (currentLine.isForecast) {
            entries.push({
              color: '#218299',
              description: currentLine.name || `${this.$t('Prognose')}:`,
              value: numbro(dataPoint.y).format({ mantissa: this.nDecimals }),
              sort: 1,
            });
          }
          if (currentLine.isMeanOverall) {
            entries.push({
              color: '#FF614C',
              description: this.$t('Durchschnitt:'),
              value: numbro(dataPoint.y).format({ mantissa: this.nDecimals }),
              sort: 3,
            });
          }
          ['y', 'yMin', 'yMax'].forEach((key) => {
            if (typeof dataPoint[key] === 'number') {
              valuesY.push(dataPoint[key]);
            }
          });
          return true;
        });
      });
      return {
        top: this.margin.top + this.scaleY(Math.max(...valuesY)) - 25,
        left: this.margin.left + this.scaleX(this.selectedValueXComputed) - 7,
        entries: entries.sort((a, b) => {
          if (a.sort > b.sort) {
            return 1;
          }
          if (a.sort < b.sort) {
            return -1;
          }

          return Number(a.value) < Number(b.value) ? 1 : -1;
        }),
      };
    },
    missingDataMessageComputed() {
      if (this.missingDataMessage != null) {
        return this.missingDataMessage;
      }
      return this.$t('Keine Daten verfügbar.');
    },
  },
  mounted() {
    window.addEventListener('resize', this.recalculateSizes);
    this.$nextTick(this.recalculateSizes);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.recalculateSizes);
  },
  methods: {
    mouseover(event) {
      this.hoveredValueX = Number(event.target.dataset.valueX);
      this.$emit('mouseover', this.hoveredValueX);
    },
    mouseout() {
      this.hoveredValueX = null;
    },
    recalculateSizes() {
      this.width = this.$el.offsetWidth;
      this.height = Math.min(350, Math.max(250, Math.floor(this.width * 0.4)));
      this.chartWidth = this.width - margin.left - margin.right;
      this.chartHeight = this.height - margin.top - margin.bottom;
    },
  },
};
</script>

<style lang="css" scoped>
.linechart {
  position: relative;
}

.linechart__no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.linechart__hover {
  position: absolute;
  top: 0;
  left: 0;
}

.linechart__line {
  fill: none;
  stroke-width: 2;
}

.linechart__line--mean {
  stroke: #218299;
}

.linechart__line--mean--transparent {
  opacity: 0.2;
}

.linechart__line--forecast {
  stroke: #218299;
  stroke-dasharray: 1 3;
}

.linechart__line--mean-overall {
  stroke-width: 2;
  stroke: #ff614c;
}

.linechart__line--min-max {
  stroke-width: 0;
  fill: #d3eaef;
  opacity: 0.5;
}

.linechart__horizontal-helper {
  stroke-width: 1;
  stroke-dasharray: 2 3;
  stroke: #dedede;
}

.linechart__horizontal-helper--zero {
  stroke-dasharray: none;
}

.linechart__circle {
  stroke: white;
  stroke-width: 2;
  fill: #64bdd2;
}

.linechart__circle--min-max {
  stroke: #64bdd2;
  stroke-width: 1;
  fill: #d3eaef;
}

.linechart__circle--mean {
  fill: #218299;
}

.linechart__circle--forecast {
  fill: #218299;
}

.linechart__circle--mean-overall {
  fill: #ff614c;
}

.linechart__vertical-helper {
  stroke: #64bdd2;
  stroke-width: 1;
  stroke-dasharray: none;
}

rect {
  fill: transparent;
}
</style>
