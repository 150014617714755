
import { BButton } from 'bootstrap-vue';
import { MoveActivityMode } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import { Getters as MoveActivityGetters } from '@/activities/modals/moveActivity/store/getters';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Pagination from '@/shared/components/Pagination.vue';

import { MoveActivityParameterWithResult } from '../store/types';
import ConflictionStepContainer from './ConflictionStepContainer.vue';
import FirstStepContainer from './FirstStepContainer.vue';
import ThirdStepContainer from './ThirdStepContainer.vue';

export default defineComponent({
  name: 'ModalMoveActivity',
  components: {
    ModalWrapper,
    Pagination,
    FirstStepContainer,
    ThirdStepContainer,
    BButton,
    ConflictionStepContainer,
  },
  props: {
    selectedActivityIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 0,
    };
  },
  computed: {
    ...mapState('activities/moveActivity', [
      'selectedProcessOrderId',
      'trials',
      'counterCurrentMoveActivityConflict',
      'moveActivityParametersWithResult',
      'keepOption',
    ]),
    title(): string | undefined {
      return this.selectedActivityIds.length > 1 ? this.$t('Maßnahmen verschieben') : this.$t('Maßnahme verschieben');
    },
    moveActivityParametersWithoutMode(): MoveActivityParameterWithResult[] | null {
      return this.$store.getters[
        'activities/moveActivity/moveActivityParametersWithoutMode'
      ] as MoveActivityGetters['moveActivityParametersWithoutMode'];
    },
    moveActivityParametersWithConflict(): MoveActivityParameterWithResult[] | null {
      return this.$store.getters[
        'activities/moveActivity/moveActivityParametersWithConflict'
      ] as MoveActivityGetters['moveActivityParametersWithConflict'];
    },
    moveActivityParametersWithSuccess(): MoveActivityParameterWithResult[] | null {
      return this.$store.getters[
        'activities/moveActivity/moveActivityParametersWithSuccess'
      ] as MoveActivityGetters['moveActivityParametersWithSuccess'];
    },
    pageCount(): number {
      if (this.trials > 0) {
        return 3;
      }
      return 2;
    },
    moveEnabled(): boolean {
      switch (this.currentStep) {
        case 0:
          return this.selectedProcessOrderId != null;
        case 1:
          if (this.currentMoveActivityParameters?.mode === MoveActivityMode.CopyField) {
            return true;
          }
          if (
            this.currentMoveActivityParameters?.mode === MoveActivityMode.ReplaceField &&
            this.currentMoveActivityParameters?.destinationFieldId
          ) {
            return true;
          }
          return false;
        default:
          return false;
      }
    },
    currentMoveActivityParameters(): MoveActivityParameterWithResult | null {
      if (this.moveActivityParametersWithConflict) {
        return this.moveActivityParametersWithConflict[this.counterCurrentMoveActivityConflict];
      }
      return null;
    },
    counterReachedLastMoveActivity(): boolean {
      if (
        this.moveActivityParametersWithConflict &&
        this.counterCurrentMoveActivityConflict === Object.keys(this.moveActivityParametersWithConflict).length - 1
      ) {
        return true;
      }
      return false;
    },
    conflictsResolved(): boolean {
      return (
        (this.counterReachedLastMoveActivity && this.moveActivityParametersWithoutMode?.length === 0) || this.keepOption
      );
    },
    showPaginationCurrentStep(): number {
      switch (this.currentStep) {
        case 1:
          return 1;
        case 2:
          return this.trials > 0 ? 2 : 1;
        default:
          return this.currentStep;
      }
    },
  },
  methods: {
    show(): void {
      // @ts-ignore
      this.$refs.modalWrapper?.show();
    },
    reset(): void {
      this.removeSuccessfullyMovedActivitiesFromStore();
      // @ts-ignore
      this.$refs.modalWrapper?.hide();
    },
    resetModal(): void {
      this.currentStep = 0;
      this.$store.commit('activities/moveActivity/reset');
    },
    async next(): Promise<void> {
      await this.moveActivity();

      if (this.trials > 0) {
        this.currentStep += 1;
      } else {
        this.currentStep = 2;
      }
    },
    nextConflict(): void {
      this.$store.commit('activities/moveActivity/increaseCounterCurrentMoveActivityConflict');
    },
    async nextConflictOrMoveActivity(): Promise<void> {
      if (this.conflictsResolved) {
        await this.next();
      } else {
        this.nextConflict();
      }
    },
    async moveActivity(): Promise<void> {
      await this.$store.dispatch('activities/moveActivity/toProcessOrder', {
        activityIds: this.selectedActivityIds,
      });
    },
    removeSuccessfullyMovedActivitiesFromStore() {
      this.moveActivityParametersWithSuccess?.forEach(
        (moveActivityParameterWithResult: MoveActivityParameterWithResult) => {
          this.$store.commit('activities/removeActivity', moveActivityParameterWithResult.sourceActivityId);
        },
      );
    },
  },
});
