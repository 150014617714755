import initialStateBaseWorkflow from '../../store/baseWorkflowStore/initialState';
import { ApplicationMapsFertilizationCerealsState, ApplicationType, VisualizationMode, ZoningMode } from './types';

export default function initialState(): ApplicationMapsFertilizationCerealsState {
  return {
    ...initialStateBaseWorkflow(),
    fieldTimestamps: {
      loading: false,
      error: null,
      data: null,
    },
    zoneMap: {
      date: null,
      numberOfZones: 5,
      zoningMode: ZoningMode.JENKS_NATURAL_BREAKS,
      borderZoneCorrection: true,
      visualizationMode: VisualizationMode.SATELLITE_IMAGE,
    },
    calculation: {
      material: {
        id: null,
        name: '',
      },
      k: 0,
      n: 0,
      p: 0,
      manualDosage: null,
      unit: null,
    },
    application: {
      applicationType: ApplicationType.MANUAL,
      applicationTypeFirst: {
        targetNRate: null,
      },
      applicationTypeThird: {
        targetYield: null,
        humidityAreaType: null,
        targetGrainQuality: null,
        averageFertilizerRate1N: null,
        averageFertilizerRate2N: null,
      },
      validationError: false,
    },
    zones: {
      loading: false,
      error: null,
      data: null,
    },
  };
}
