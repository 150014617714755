<template>
  <div class="img-company-logo__container">
    <img class="img-company-logo" :src="src" alt="Company logo" />
  </div>
</template>

<script>
export default {
  name: 'ImgCompanyLogo',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.img-company-logo__container {
  background-color: #fff;
  width: 105px;
  height: 45px;
  border-radius: 3px;
  padding: 8px;
}
.img-company-logo {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
</style>
