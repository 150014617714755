import clevertap from 'clevertap-web-sdk';

const ACCOUNT_ID = 'RK8-84W-KR6Z';
const ACCOUNT_ID_TEST = 'TEST-KK8-84W-KR6Z';

clevertap.spa = true;
clevertap.privacy.push({ optOut: false });
clevertap.privacy.push({ useIP: false });

if (process.env.VUE_APP_TRACKING_USE_PRODUCTION_APP === '1') {
  clevertap.init(ACCOUNT_ID);
} else {
  clevertap.init(ACCOUNT_ID_TEST);
}

if (process.env.VUE_APP_CLEVERTAP_LOG_LEVEL != null) {
  clevertap.setLogLevel(process.env.VUE_APP_CLEVERTAP_LOG_LEVEL);
}

class ClevertapHelper {
  constructor(instance, router, store) {
    this.instance = instance;
    this.router = router;
    this.store = store;
    this.clevertap = clevertap;
    this.init();
  }

  init() {
    this.store.subscribe(({ type }) => {
      if (type === 'auth/setLoggedIn') {
        this.onUserLogin();
      } else if (type === 'auth/setUser') {
        this.onSetUser();
      } else if (type === 'auth/updateUser') {
        this.onSetUser();
      } else if (type === 'auth/updateCurrentCompany') {
        this.onSetUser();
      }
    });
    this.onUserLogin();
  }

  onUserLogin() {
    if (!this.store.state.auth.loggedIn) {
      return;
    }
    const profile = { Site: { Identity: this.store.state.auth.user.id } };
    this.clevertap.onUserLogin.push(profile);
  }

  onSetUser() {
    if (!this.store.state.auth.loggedIn) {
      return;
    }
    let Phone;
    if (
      this.store.state.auth.userCompanies.length === 1 &&
      this.store.state.auth.userCompanies[0].company != null &&
      this.store.state.auth.userCompanies[0].company.tel != null
    ) {
      Phone = this.store.state.auth.userCompanies[0].company.tel;
    }
    const newsletter = this.store.state.auth.user.emailIsConfirmed && !!this.store.state.auth.user.newsletter;
    const profile = {
      Site: {
        Name: this.store.getters['auth/userName'],
        Identity: this.store.state.auth.user.id,
        Email: this.store.state.auth.user.email,
        Phone,
        newsletter,
        'MSG-email': true,
      },
    };
    this.clevertap.profile.push(profile);
  }
}

export default ClevertapHelper;
