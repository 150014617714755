import Vue from 'vue';

import { baseUrl } from '@/shared/constants';

import PageActivities from './PageActivities.vue';
import PageActivitiesImport from './PageActivitiesImport.vue';
import PageActivitiesRestore from './PageActivitiesRestore.vue';
import ImportChooseMethod from './importActivities/components/ImportChooseMethod.vue';
import ImportLoadDataCnh from './importActivities/components/ImportLoadDataCnh.vue';
import ImportLoadDataUpload from './importActivities/components/ImportLoadDataUpload.vue';
import TableActivitiesImportContainer from './importActivities/containers/TableActivitiesImportContainer.vue';

const importLabel = Vue.i18n.translate('Maßnahmen importieren');

const importSubheader = Vue.i18n.translate('Importieren von Maßnahmen aus externen Quellen');

const importBack = {
  to: { name: 'activities' },
  label: Vue.i18n.translate('Zurück zu Maßnahmen'),
};

export default [
  {
    path: `${baseUrl}activities`,
    name: 'activities',
    component: PageActivities,
    meta: {
      requiresAuth: true,
      label: Vue.i18n.translate('Maßnahmen'),
    },
  },
  {
    path: `${baseUrl}activities/restore`,
    name: 'activities/restore',
    component: PageActivitiesRestore,
    meta: {
      requiresAuth: true,
      label: Vue.i18n.translate('Maßnahmen wiederherstellen'),
      subheader: Vue.i18n.translate('Maßnahmen wiederherstellen, die in den letzten 90 Tagen gelöscht wurden'),
      back: {
        to: { name: 'activities' },
        label: Vue.i18n.translate('Zurück zur Maßnahmen-Startseite'),
      },
    },
  },
  {
    path: `${baseUrl}activities/import`,
    name: 'activities/import',
    component: PageActivitiesImport,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'choose-method',
        name: 'activities/import/choose-method',
        component: ImportChooseMethod,
        meta: {
          label: importLabel,
          subheader: importSubheader,
          back: importBack,
          requiresAuth: true,
          step: 0,
        },
      },
      {
        path: 'load-data/cnh',
        name: 'activities/import/load-data/cnh',
        component: ImportLoadDataCnh,
        props: true,
        meta: {
          label: importLabel,
          subheader: importSubheader,
          back: importBack,
          requiresAuth: true,
          step: 1,
        },
      },
      {
        path: 'load-data/upload',
        name: 'activities/import/load-data/upload',
        component: ImportLoadDataUpload,
        props: true,
        meta: {
          label: importLabel,
          subheader: importSubheader,
          back: importBack,
          requiresAuth: true,
          step: 1,
        },
      },
      {
        path: 'import-table',
        name: 'activities/import/import-table',
        component: TableActivitiesImportContainer,
        props: true,
        meta: {
          label: importLabel,
          subheader: importSubheader,
          back: importBack,
          requiresAuth: true,
          step: 2,
        },
      },
    ],
  },
];
