import { fieldGroupNumber, name } from '@/fields/handsontable/columns/columns';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';

import { amount, amountPotato, amountSugar, ingredientPotato, ingredientSugar, processedArea } from './columns';

export const agranaFieldGroupNumber = 'feldstuecknr';

const tableImportFieldsYieldsShared = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...fieldGroupNumber,
    lockedVisibility: true,
    key: agranaFieldGroupNumber,
    readOnly: true,
    width: 120,
    className: 'border-right',
  },
  {
    ...name,
    lockedVisibility: true,
    key: 'feldname',
    readOnly: true,
    width: 250,
  },
  {
    ...processedArea,
    lockedVisibility: true,
    key: 'feldgroesse',
    required: false,
    readOnly: true,
    width: 130,
    className: 'border-right',
  },
  {
    ...amount,
    readOnly: true,
  },
];

export const tableImportFieldsYieldsSugarBeets = [
  ...tableImportFieldsYieldsShared,
  amountSugar,
  ingredientSugar,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export const tableImportFieldsYieldsPotatoes = [
  ...tableImportFieldsYieldsShared,
  amountPotato,
  ingredientPotato,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];
