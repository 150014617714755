<template>
  <ModalWrapper
    ref="modal"
    class="modal-terms"
    size="lg"
    bottom-bg-gray
    :title="$t('Vereinbarungen zur Flächenmeldung')"
  >
    <div v-html="gtcText" class="modal-terms__terms-content mt-3 mb-3" />
    <template #bottom-content>
      <FormCheckbox
        class="modal-terms__terms-checkbox"
        v-model="terms"
        name="terms"
        size="sm"
        :disabled="gtc == null || gtc.length === 0 || isCreating"
      >
        {{ checkboxText }}
      </FormCheckbox>
    </template>
    <template #buttons>
      <BButton
        class="mr-3"
        variant="primary"
        style="min-width: 120px"
        :disabled="!terms || isCreating"
        @click="$emit('accept')"
      >
        <span v-if="!isCreating">{{ $t('Absenden') }}</span>
        <FontAwesomeIcon v-if="isCreating" icon="circle-notch" spin />
      </BButton>
      <BButton class="text-black" variant="link" :disabled="isCreating" @click="hide">
        {{ $t('Abbrechen') }}
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';

library.add(faCircleNotch);

export default {
  name: 'ModalTerms',
  components: { FormCheckbox, ModalWrapper },
  props: {
    gtc: {
      type: String,
      default: null,
    },
    checkboxText: {
      type: String,
      default() {
        return this.$t(
          'Ich habe die Vereinbarungen gelesen und stimme der Datenübertragung zu genannten Zwecken ausdrücklich zu.',
        );
      },
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      terms: false,
    };
  },
  computed: {
    gtcText() {
      if (typeof this.gtc !== 'string' || this.gtc.length === 0) {
        return `<p class="text-danger">${this.$t('Die AGB konnten nicht geladen werden.')}</p>`;
      }
      const domHolder = document.createElement('div');
      domHolder.innerHTML = this.gtc;
      const links = Array.from(domHolder.querySelectorAll('a'));
      links.forEach((link) => {
        // eslint-disable-next-line no-param-reassign
        link.target = '_blank';
      });
      return domHolder.innerHTML;
    },
  },
  methods: {
    show() {
      this.terms = false;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style scoped lang="scss">
.modal-terms__terms-content {
  width: 100%;
  height: 400px;
  border: none;
  font-size: 0.9rem;
  padding-bottom: 0.8rem;
}

.modal-terms__terms-content::v-deep h1 {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.modal-terms__terms-content::v-deep h2 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}
</style>
