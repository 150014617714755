import { GetterTree } from 'vuex';

import usedItems from '@/shared/mixins/store/usedItems';
import { Getters as UsedGetters } from '@/shared/mixins/store/usedItems/types';
import { RootState } from '@/store/types';

import subscribableStore from './common';
import { EquipmentsState } from './types';

export type Getters = UsedGetters;

const moduleGetters: GetterTree<EquipmentsState, RootState> = {
  ...subscribableStore.getters,
  ...usedItems.getters,
};

export default moduleGetters;
