<template>
  <div class="content-box">
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'ContentBox',
};
</script>

<style scoped>
.content-box {
  padding: var(--spacer_3);
  border: 1px solid var(--medium);
  background: var(--lightest);
}
</style>
