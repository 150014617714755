
import { BButton } from 'bootstrap-vue';
import type { ActivityRuleViolation } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Pagination from '@/shared/components/Pagination.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';

export default defineComponent({
  name: 'ModalRuleCheckViolations',
  props: {
    violations: {
      type: Array as PropType<string[]>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    featureDismissViolationEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['hide'],
  model: {
    prop: 'visible',
    event: 'hide',
  },
  data: (): { currentPage: number } => ({
    currentPage: 0,
  }),
  components: {
    ModalWrapper,
    Pagination,
    BButton,
    FormCheckbox,
  },
  computed: {
    pages(): number {
      return this.violations.length;
    },
    primaryButtonLabel(): string {
      if (this.hasNextViolation) {
        return this.$t('Weiter') || 'Weiter';
      }

      return this.$t('Ok') || 'Ok';
    },
    hasNextViolation(): boolean {
      return this.currentPage < this.pages - 1;
    },
    hasPreviousViolation(): boolean {
      return this.currentPage > 0;
    },
    currentViolation(): string {
      return this.violations[this.currentPage];
    },
  },
  watch: {
    visible(visible: boolean) {
      if (visible) {
        this.goToFirstViolation();
      }
    },
  },
  methods: {
    onPrimaryClick() {
      if (this.hasNextViolation) {
        this.showNextViolation();
      } else {
        this.$emit('hide');
      }
    },

    showNextViolation() {
      this.currentPage += 1;
    },
    onPreviousClick() {
      if (this.hasPreviousViolation) {
        this.currentPage -= 1;
      }
    },
    goToFirstViolation() {
      this.currentPage = 0;
    },
    isViolationNotChecked(violation: ActivityRuleViolation): boolean {
      return !!violation;
    },
  },
});
