import { MutationTree } from 'vuex';

import usedItems from '@/shared/mixins/store/usedItems';

import { subscribableStore } from './common';
import { CropsState } from './types';

const mutations: MutationTree<CropsState> = {
  ...subscribableStore.mutations,
  ...usedItems.mutations,
};

export default mutations;
