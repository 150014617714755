import { AxiosRequestConfig } from 'axios';
import { ActionContext } from 'vuex';

import { fetchCustomers } from '@/shared/api/rest/RestApi';
import { ApiResponse, Customer } from '@/shared/api/rest/models';
import { Address } from '@/shared/api/rest/models/customer';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

type Resolve = Address;
const resolve = ['address'];

export async function fetchAll(
  context: ActionContext<SubscribableDataState<Customer>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Customer[], Resolve>> {
  const companyIds = context.rootGetters['auth/currentCompanyIds'];

  const { data } = await fetchCustomers(companyIds, axiosOptions, { resolve, itemsPerPage: 5000 });

  return data;
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<Customer>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Customer[]>> {
  throw new Error('NOT IMPLEMENTED');
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
