<template>
  <div class="file-upload">
    <MaxWidthContainer size="xl">
      <FilesUploadIntroDescription
        :headline="$t('Dateien hochladen')"
        :paragraph="
          $t(
            // eslint-disable-next-line max-len
            'Importiere Dateien in den Formaten Shape und GeoJSON. Für jede Datei wird ein Link generiert. GeoJSON-Links können im Webbrowser geöffnet werden.',
          )
        "
      />
      <FilesUploadForm
        ref="filesUploadForm"
        :supportedFileFormats="supportedFileFormats"
        :maxFileSize="maxFileSize"
        @uploadEnabledChanged="uploadEnabledChanged"
        @uploadSuccess="onUploadSuccess"
        :initialFiles="initialFiles"
      />
    </MaxWidthContainer>
  </div>
</template>

<script>
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';

import FilesUploadIntroDescription from './FilesUploadIntroDescription.vue';
import FilesUploadForm from './form/FilesUploadForm.vue';

export default {
  name: 'FilesUpload',
  components: {
    MaxWidthContainer,
    FilesUploadIntroDescription,
    FilesUploadForm,
  },
  props: {
    initialFiles: FileList,
  },
  data() {
    return {
      supportedFileFormats: [
        { fileEnding: 'zip', displayName: 'Shape' },
        { fileEnding: 'geojson', displayName: 'GeoJSON' },
        { fileEnding: 'json', displayName: 'JSON' },
      ],
      maxFileSize: 10,
      uploadEnabled: false,
    };
  },
  methods: {
    uploadEnabledChanged(enabled) {
      this.$emit('uploadEnabledChanged', enabled);
    },
    onSubmit() {
      this.$refs.filesUploadForm.onSubmit();
    },
    onUploadSuccess() {
      this.$emit('uploadSuccess');
    },
  },
};
</script>

<style scoped>
.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
