import { TerrazoFieldTimestampEntry } from 'farmdok-rest-api';
import _set from 'lodash.set';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import {
  FertilizationCalculation,
  FertilizationZoneDosage,
} from '@/precision-farming/application-maps/fertilization/store/types';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import initialState from './initialState';
import {
  Application,
  ApplicationMapsFertilizationCerealsState,
  ApplicationType,
  FertilizationCerealsZone,
  VisualizationMode,
  ZoneMap,
  ZoningMode,
} from './types';

const mutations: MutationTree<ApplicationMapsFertilizationCerealsState> = {
  ...baseWorkflowStore.mutations,
  reset(state) {
    const newState = initialState();
    Object.entries(newState).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },
  /**
   *  allows only one selected field at a time.
   */
  setSelectedFields(state, fieldIds: string[]) {
    if (fieldIds.length <= 1) {
      state.selectedFields = fieldIds;
      return;
    }

    const notYetSelectedFields = fieldIds.filter((fieldId) => !state.selectedFields.includes(fieldId));

    if (notYetSelectedFields.length > 1) {
      console.warn('Cannot select more than one field at a time. Use the first unselected field.');
      state.selectedFields = notYetSelectedFields.slice(0, 1);
    } else {
      state.selectedFields = notYetSelectedFields;
    }
  },
  setFieldTimestampsLoading(state, loading: boolean) {
    state.fieldTimestamps = { ...state.fieldTimestamps, loading };
  },
  setFieldTimestamps(state, { timestamps }: { timestamps: TerrazoFieldTimestampEntry[] | null }) {
    state.fieldTimestamps = { ...state.fieldTimestamps, data: timestamps };
  },
  setFieldTimestampsError(state, error: string | null) {
    state.fieldTimestamps = { ...state.fieldTimestamps, error };
  },
  setDate(state, unixTimestamp: number) {
    state.zoneMap.date = unixTimestamp;
  },
  setNumberOfZones(state, numberOfZones: number) {
    state.zoneMap.numberOfZones = numberOfZones;
  },
  setZoningMode(state, zoningMode: ZoningMode) {
    state.zoneMap.zoningMode = zoningMode;
  },
  setBorderZoneCorrection(state, borderZoneCorrection: boolean) {
    state.zoneMap.borderZoneCorrection = borderZoneCorrection;
  },
  setVisualizationMode(state, visualizationMode: VisualizationMode) {
    state.zoneMap.visualizationMode = visualizationMode;
  },
  setZoneMap(state, zoneMap: ZoneMap) {
    state.zoneMap = zoneMap;
  },
  setApplication(state, application: Application) {
    state.application = application;
  },
  setApplicationField(state, { key, value }: { key: keyof Application; value: number }) {
    if (key.split('.').length > 1) {
      const application = { ...state.application };
      _set(application, key, value);
      Vue.set(state, 'application', application);
    } else {
      const application = {
        ...state.application,
        [key]: value,
      };
      Vue.set(state, 'application', application);
    }
  },
  setMaterial(state, data: DropdownItem) {
    state.calculation.material = data;
  },
  setCalculation(state, calculation: FertilizationCalculation) {
    state.calculation = calculation;
  },
  setCalculationField(state, data: { key: keyof FertilizationCalculation; value: number | null }) {
    state.calculation = {
      ...state.calculation,
      [data.key]: data.value,
    };
  },
  setManualDosage(state, value: FertilizationZoneDosage[] | null) {
    state.calculation.manualDosage = value;
  },
  setApplicationType(state, value: ApplicationType) {
    state.application.applicationType = value;
  },
  setZonesLoading(state, loading: boolean) {
    state.zones = { ...state.zones, loading };
  },
  setZones(state, zones: FertilizationCerealsZone[]) {
    state.zones = { ...state.zones, data: zones };
  },
  setZonesError(state, error: string | null) {
    state.zones = { ...state.zones, error };
  },
};

export default mutations;
