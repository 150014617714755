<template>
  <BTable
    v-if="!grouped"
    show-empty
    :fields="fields"
    :items="items"
    :fixed="fixed"
    :busy="busy"
    :borderless="borderless"
    :class="[
      {
        'table--headerless': headerless,
        'table--transparent': transparent,
        'table--reduced-row-spacing': reducedRowSpacing,
      },
      `table--size-${size}`,
    ]"
  >
    <template #table-colgroup="{ fields }">
      <col
        v-for="field in fields"
        :key="field.key"
        :style="{
          width: field.width != null ? field.width : 'auto',
          maxWidth: field.maxWidth != null ? field.maxWidth : 'none',
          minWidth: field.minWidth != null ? field.minWidth : '0',
        }"
      />
    </template>
    <template v-for="field in headerSlots" v-slot:[`head(${field.key})`]="data">
      <slot
        v-bind:label="data.label"
        v-bind:column="data.column"
        v-bind:field="data.field"
        :name="`head(${field.key})`"
      />
    </template>
    <template v-for="field in fieldSlots" v-slot:[`cell(${field.key})`]="data">
      <slot v-bind:item="data.item" v-bind:field="data.field" v-bind:value="data.value" :name="`cell(${field.key})`" />
    </template>
    <template v-if="$scopedSlots['cell()'] != null" #cell()="data">
      <slot v-bind:item="data.item" v-bind:field="data.field" v-bind:value="data.value" name="cell()" />
    </template>
    <template #table-busy>
      <div class="text-center my-2">
        <FontAwesomeIcon size="3x" spin :icon="['fad', 'spinner-third']" />
      </div>
    </template>
    <template #empty>
      <slot name="empty">
        {{ $t('Noch keine Einträge vorhanden.') }}
      </slot>
    </template>
  </BTable>
  <div v-else>
    <BTable
      class="mb-1"
      :fields="fields"
      :fixed="fixed"
      :busy="$store.state.activities.fetching"
      :show-empty="items.length === 0"
      :borderless="borderless"
      :class="[
        {
          'table--headerless': headerless,
          'table--transparent': transparent,
          'table--reduced-row-spacing': reducedRowSpacing,
        },
        `table--size-${size}`,
      ]"
    >
      <template #table-colgroup="{ fields }">
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{
            width: field.width != null ? field.width : 'auto',
            maxWidth: field.maxWidth != null ? field.maxWidth : 'none',
            minWidth: field.minWidth != null ? field.minWidth : '0',
          }"
        />
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <FontAwesomeIcon size="3x" spin :icon="['fad', 'spinner-third']" />
        </div>
      </template>
      <template #empty>
        <slot name="empty">
          {{ $t('Noch keine Einträge vorhanden.') }}
        </slot>
      </template>
    </BTable>
    <BTable
      v-for="itemGroup in items"
      class="table--grouped mb-1"
      :key="itemGroup.key"
      :fields="fields"
      :items="itemGroup.items"
      :fixed="fixed"
      :class="[
        {
          'table--headerless': headerless,
          'table--transparent': transparent,
          'table--reduced-row-spacing': reducedRowSpacing,
        },
        `table--size-${size}`,
      ]"
    >
      <template #table-colgroup="{ fields }">
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{
            width: field.width != null ? field.width : 'auto',
            maxWidth: field.maxWidth != null ? field.maxWidth : 'none',
            minWidth: field.minWidth != null ? field.minWidth : '0',
          }"
        />
      </template>
      <template v-slot:[`head(${fields[0].key})`]>
        {{ itemGroup.label }}
      </template>
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template #head()="data">
        <span>&nbsp;</span>
      </template>
      <template v-for="field in fieldSlots" v-slot:[`cell(${field.key})`]="data">
        <slot
          v-bind:item="data.item"
          v-bind:field="data.field"
          v-bind:value="data.value"
          :name="`cell(${field.key})`"
        />
      </template>
      <template v-if="$scopedSlots['cell()'] != null" #cell()="data">
        <slot v-bind:item="data.item" v-bind:field="data.field" v-bind:value="data.value" name="cell()" />
      </template>
    </BTable>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

library.add(faSpinnerThird);

export default {
  name: 'Table',
  props: {
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    headerless: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    reducedRowSpacing: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
      validator: (size) => ['sm', 'md'].includes(size),
    },
  },
  computed: {
    headerSlots() {
      return this.fields.filter((field) => this.$scopedSlots[`head(${field.key})`] != null);
    },
    fieldSlots() {
      return this.fields.filter((field) => this.$scopedSlots[`cell(${field.key})`] != null);
    },
  },
};
</script>

<style scoped lang="scss">
.table--size-sm {
  font-size: 12px;
}

.table--size-md::v-deep td {
  font-size: 14px;
}

.table::v-deep th,
.table::v-deep td {
  padding-top: 0.57rem;
  padding-bottom: 0.57rem;
}

.table--size-md::v-deep th,
.table--size-md::v-deep td {
  padding-left: var(--spacer_4);
  padding-right: var(--spacer_4);
}

.table--reduced-row-spacing::v-deep tr:not(:first-child) td {
  padding-top: 0.2rem;
}

.table--reduced-row-spacing::v-deep tr:not(:last-child) td {
  padding-bottom: 0.2rem;
}

.table::v-deep thead th {
  border-bottom: 2px solid var(--white);
  background-color: var(--gray_300);
}

.table::v-deep td,
.table::v-deep th {
  border-top: 2px solid var(--white);
  background-color: var(--lightest);
}

.table-borderless::v-deep th,
.table-borderless::v-deep td,
.table-borderless::v-deep thead th,
.table-borderless::v-deep tbody + tbody {
  border: 0;
}

.table--headerless::v-deep thead {
  display: none;
}

.table--grouped::v-deep thead th {
  padding: 0.5rem 0.75rem;
}

.table--transparent::v-deep td {
  background: transparent;
}

.table--transparent::v-deep td {
  border-top: 1px solid var(--gray_400);
}

.table--transparent::v-deep th {
  border-top: 0;
  border-bottom: 0;
}

.table--transparent::v-deep td:not(:first-child, :last-child),
.table--transparent::v-deep th:not(:first-child, :last-child) {
  border-left: 1px solid var(--gray_400);
  border-right: 1px solid var(--gray_400);
}
</style>
