<template>
  <div class="table-footer bg-lightest">
    <div class="table-footer__row-count bg-medium">
      {{ rowCount }}
    </div>
    <div class="table-footer__additional-info">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableFooter',
  props: {
    totalRows: {
      type: Number,
      default: 0,
    },
    visibleRows: {
      type: Number,
      default: 0,
    },
    selectedRows: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    rowCount() {
      if (this.selectedRows > 0) {
        return this.$t('Ausgewählt: {selected} von {total}', {
          selected: this.selectedRows,
          total: this.totalRows,
        });
      }
      if (this.visibleRows < this.totalRows) {
        return this.$t('Angezeigt: {visible} von {total}', {
          visible: this.visibleRows,
          total: this.totalRows,
        });
      }
      return this.$t('Anzahl Einträge: {total}', {
        visible: this.visibleRows,
        total: this.totalRows,
      });
    },
  },
};
</script>

<style scoped>
.table-footer {
  display: flex;
  border-top: 2px solid var(--medium);
  font-size: 12px;
  font-weight: 600;
}

.table-footer__row-count {
  min-width: 170px;
  padding: 5px 17px;
}

.table-footer__additional-info {
  padding: 5px 17px;
}
</style>
