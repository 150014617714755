import { Company } from './types';

/**
 * A list of years for which process orders in all of the given companies exist.
 *
 * @param companies
 * @return {array}
 */
export function availableProcessOrderNames(companies: Company[]) {
  if (!Array.isArray(companies) || companies.length < 1) {
    return [];
  }
  return companies
    .map(({ processOrders }) => processOrders.map((processOrder) => String(processOrder.name)))
    .reduce((result, current) => result.filter((processOrderName) => current.includes(processOrderName)))
    .sort(new Intl.Collator(undefined, { numeric: true }).compare); // allows numerical sorting of strings
}

/**
 * Checks if the list of companies include the given processOrderName.
 * If not returns the latest available processOrderName.
 *
 * @param companies
 * @param processOrderName
 * @returns {string|null}
 */
export function validateProcessOrderName(companies: Company[], processOrderName: string) {
  if (companies.length === 0) {
    return String(processOrderName);
  }
  const processOrderNames = availableProcessOrderNames(companies);
  if (processOrderNames.length === 0) {
    return null;
  }
  if (processOrderNames.includes(processOrderName)) {
    return String(processOrderName);
  }
  if (processOrderName != null && Number(processOrderName) < Number(processOrderNames[0])) {
    return String(processOrderNames[0]);
  }
  return String(processOrderNames[processOrderNames.length - 1]);
}
