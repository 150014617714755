interface Circle {
  innerCircle: google.maps.Marker;
  outerCircle: google.maps.Circle;
}

export default ({ google, map }: { google: any; map: google.maps.Map | null }): Circle => {
  const innerCircle = new google.maps.Marker({
    map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: '#3CC961',
      fillOpacity: 1,
      scale: 6,
      strokeWeight: 2,
      strokeColor: 'white',
    },
    zIndex: 3,
  });

  const outerCircle = new google.maps.Circle({
    map,
    fillColor: '#3CC961',
    fillOpacity: 0.3,
    strokeWeight: 0,
    radius: 40,
  });
  outerCircle.bindTo('center', innerCircle, 'position');

  return { innerCircle, outerCircle };
};
