<template>
  <BFormInput
    v-model="valueComputed"
    class="form-input-number-onboarding"
    type="number"
    inputmode="decimal"
    pattern="[0-9]*"
    min="1"
    placeholder="0"
    size="lg"
    block
    :formatter="formatter"
  />
</template>

<script>
export default {
  name: 'FormInputNumberOnboarding',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    formatter: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    valueComputed: {
      get() {
        if (typeof this.value !== 'string') {
          return null;
        }
        return this.value;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
};
</script>

<style scoped>
.form-input-number-onboarding,
.form-input-number-onboarding:focus,
.form-input-number-onboarding:hover,
.form-input-number-onboarding:hover:not(.is-invalid):not(.is-valid):not(:focus) {
  padding: 33px 1rem;
  text-align: center;
  background: var(--white);
  font-size: 20px;
}
</style>
