import Handsontable from 'handsontable';

export function getFirstColumnOfSubtable(columns: Handsontable.ColumnSettings[], physicalColumn: number): number {
  if (columns[physicalColumn].isFirstSubtableColumn) return physicalColumn;

  return getFirstColumnOfSubtable(columns, physicalColumn - 1);
}

export function getLastColumnOfSubtable(columns: Handsontable.ColumnSettings[], physicalColumn: number): number {
  if (columns[physicalColumn].isLastSubtableColumn) return physicalColumn;

  return getLastColumnOfSubtable(columns, physicalColumn + 1);
}
