import { ActivityEquipment } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';

import { ImportActivityEquipmentCombined } from '@/activities/importActivities/store/types';
import EquipmentService from '@/equipments/services/EquipmentService';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { createUuid } from '@/shared/modules/uuid';

import { CellChangeWithIdsActivityTable } from '../types';

type ActivityEquipmentVariant = ActivityEquipment | ImportActivityEquipmentCombined;

export default class ActivityEquipmentService {
  private readonly equipmentService: EquipmentService;

  constructor(equipmentService: EquipmentService) {
    this.equipmentService = equipmentService;
  }

  public updateActivityEquipmentsFromChange(
    activityEquipments: ActivityEquipmentVariant[],
    change: CellChangeWithIdsActivityTable,
    companyId: string,
  ) {
    const [, combinedKey, , newValue, { activityEquipmentId }] = change;
    const key = combinedKey.split('.').slice(1).join('.');

    let activityEquipment = ActivityEquipmentService.findOrCreateActivityEquipment(
      activityEquipmentId,
      activityEquipments,
    );
    activityEquipment = this.updateActivityEquipment(activityEquipment, key, newValue, companyId);
    const updatedActivityEquipment = ActivityEquipmentService.addOrReplaceActivityEquipment(
      activityEquipment,
      activityEquipments,
    );

    return updatedActivityEquipment;
  }

  public static createActivityEquipment(id?: string, equipmentId?: string): ActivityEquipment {
    return {
      id: id ?? createUuid(),
      equipmentId: equipmentId ?? '',
      tstamp: toUnixTimestamp(Date.now()),
    };
  }

  public setEquipmentOrSibling(
    activityEquipment: ActivityEquipment,
    equipmentId: string,
    companyId: string,
  ): ActivityEquipment {
    const equipment = this.equipmentService.getEquipmentOrSibling(equipmentId, companyId);

    const updatedActivityEquipment = _cloneDeep(activityEquipment);
    updatedActivityEquipment.equipmentId = equipment.id;

    return updatedActivityEquipment;
  }

  public isSibling(activityEquipmentA: ActivityEquipment, activityEquipmentB: ActivityEquipment) {
    if (_isEqual(activityEquipmentA, activityEquipmentB)) return true;

    const equipmentA = this.equipmentService.findEquipmentById(activityEquipmentA.equipmentId);
    const equipmentB = this.equipmentService.findEquipmentById(activityEquipmentB.equipmentId);

    const bothEquipmentAreUndefined = !equipmentA && !equipmentB;
    const bothEquipmentAreDefined = !!(equipmentA && equipmentB);
    if (bothEquipmentAreUndefined || (bothEquipmentAreDefined && EquipmentService.isSibling(equipmentA, equipmentB))) {
      return true;
    }

    return false;
  }

  private static findOrCreateActivityEquipment(
    activityEquipmentId: string | undefined,
    activityEquipments: ActivityEquipmentVariant[],
  ) {
    if (!activityEquipmentId) {
      return ActivityEquipmentService.createActivityEquipment();
    }

    const activityEquipment = activityEquipments.find((equipment) => equipment.id === activityEquipmentId);

    if (!activityEquipment) {
      return ActivityEquipmentService.createActivityEquipment(activityEquipmentId);
    }

    return activityEquipment;
  }

  private updateActivityEquipment(
    activityEquipment: ActivityEquipmentVariant,
    key: string,
    value: DropdownItem,
    companyId: string,
  ): ActivityEquipment {
    switch (key) {
      case 'dropdownItem': {
        if (!value.id) {
          return { ...activityEquipment, equipmentId: '' };
        }

        const updatedActivityEquipment = this.setEquipmentOrSibling(activityEquipment, value.id, companyId);
        return updatedActivityEquipment;
      }
      default:
        throw new Error(`Unsupported key ${key}`);
    }
  }

  private static addOrReplaceActivityEquipment(
    activityEquipment: ActivityEquipmentVariant,
    activityEquipments: ActivityEquipmentVariant[],
  ) {
    const index = activityEquipments.findIndex((equipment) => equipment.id === activityEquipment.id);

    if (index === -1) {
      return [...activityEquipments, activityEquipment];
    }

    return [...activityEquipments.slice(0, index), activityEquipment, ...activityEquipments.slice(index + 1)];
  }

  //
  // ENTITY FUNCTIONS START
  // the following functions should be part of ActivityEquipment Entity
  //
  public getCompanyId(activityEquipment: ActivityEquipment): string | null {
    const equipment = this.equipmentService.findEquipmentById(activityEquipment.equipmentId);

    return equipment?.companyId ?? null;
  }
}
