import Handsontable from 'handsontable';

import { ColumnSettingsFlattenedSubtable } from '../nestedTable/types';

function hiddenColumns(columns: ColumnSettingsFlattenedSubtable[]): Handsontable.GridSettings['hiddenColumns'] {
  const hiddenColumnIndices = columns
    .map((column, index) => (column.hidden === true ? index : null))
    .filter((index) => index !== null) as number[];

  if (hiddenColumnIndices.length === 0) return false;

  return {
    columns: hiddenColumnIndices,
    indicators: false,
  };
}

export default {
  hiddenColumns,
};
