import { Pest, PesticideIndication } from 'farmdok-rest-api';
import numbro from 'numbro';

import { Unit } from '@/shared/api/rest/models';

export default function dropdownItemName(pest: Pest, pesticideIndication: PesticideIndication, unit?: Unit): string {
  const maxAmount =
    pesticideIndication.maxAmount !== null
      ? numbro(pesticideIndication.maxAmount).format({ thousandSeparated: true, mantissa: 3 })
      : null;

  const maxAmountString = maxAmount ? `${maxAmount}` : '';
  const unitString = unit ? `${unit.name}` : '';
  const spaceBetweenMaxAmountAndUnit = maxAmountString && unitString ? ' ' : '';
  const splittingString = pesticideIndication.isSplitting ? ', Splitting' : '';

  return `${pest.name} (${maxAmountString}${spaceBetweenMaxAmountAndUnit}${unitString}${splittingString})`;
}
