import { mapGetters } from 'vuex';

import { getDescendantProp } from '@/shared/modules/getDisplayValue';

/**
 * ATTENTION, USE WITH CARE!
 * Always use this mixin together with shared/handsontable/mixins/containers/tableContainerBase.
 * This mixin maps the data in the customers store to Dropdown items.
 *
 * columnKeysToStore:
 * - key
 *   The column key for the column that gets the mapped data.
 * - value
 *   Is the sub-model key in the tableData object that can contain a customer.
 *   This is used to display customer info w/o loading the whole list from the BE.
 *
 * @param columnKeysToStore
 * @return Object
 */
export default function columnDropdownCustomers(columnKeysToStore) {
  return {
    data() {
      return {
        columnSettingsMiddleware: [],
      };
    },
    computed: {
      ...mapGetters({
        customers: 'customers',
        customersById: 'customers/byId',
      }),
    },
    created() {
      this.columnSettingsMiddleware.push(this.columnDropdownCustomersColumnSettings);
      this.addHotHook('afterSelection', this.columnDropdownCustomersAfterSelection);
    },
    methods: {
      columnDropdownCustomersColumnSettings(columns) {
        return columns.map((column) => {
          if (!Object.keys(columnKeysToStore).includes(column.key)) {
            return column;
          }
          return {
            ...column,
            getRendererValue: (...args) =>
              this.columnDropdownCustomersGetRendererValue(columnKeysToStore[column.key], ...args),
            getItems: (...args) => this.columnDropdownCustomersGetItems(...args),
          };
        });
      },
      columnDropdownCustomersGetRendererValue(objectKey, { value, visualRow, guid: guidArg }) {
        if (!value || typeof value === 'object') {
          return '';
        }

        if (this.customers[value] != null && typeof this.customers[value].name === 'string') {
          return this.customers[value].name;
        }

        let guid = guidArg;
        if (guid == null) {
          guid = this.$refs.table.visualRowToGuid(visualRow);
        }
        if (
          guid != null &&
          this.tableData[guid] != null &&
          getDescendantProp(this.tableData[guid], objectKey) != null &&
          typeof getDescendantProp(this.tableData[guid], objectKey).name === 'string'
        ) {
          return getDescendantProp(this.tableData[guid], objectKey).name;
        }

        return this.$t('#FEHLER');
      },
      async columnDropdownCustomersGetItems(visualRowIndex) {
        await this.$store.dispatch('customers/subscribe');
        const guid = this.$refs.table.visualRowToGuid(visualRowIndex);

        const companyId = this.tableData[guid]?.fieldGroup?.companyId;
        const items = companyId
          ? Object.values(this.customers).filter((customer) => customer.companyId === companyId)
          : Object.values(this.customers);
        return [
          {
            name: this.$t('Kunden'),
            id: 'customers',
            items,
            sort: true,
          },
        ];
      },
      columnDropdownCustomersAfterSelection(visualRow, visualCol) {
        const physicalCol = this.hot.toPhysicalColumn(visualCol);
        if (Object.keys(columnKeysToStore).includes(this.tableSettings.columns[physicalCol].key)) {
          this.$store.dispatch('customers/subscribe');
        }
      },
    },
  };
}
