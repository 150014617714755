import { AxiosRequestConfig } from 'axios';
import {
  ActivitiesApiActivityDeleteRequest,
  ActivitiesApiActivityFavouriteProductsRequest,
  ActivitiesApiActivityListRequest,
  ActivitiesApiActivityObjectsRequest,
  ActivitiesApiActivityPutRequest,
  ActivitiesApiActivityUndeleteRequest,
  ActivityImportApiActivityImportMapDataRequest,
  ProductCategoriesApiProductCategoryListRequest,
  ProductCategoriesApiProductCategoryObjectsRequest,
} from 'farmdok-rest-api';

import { ApiResponse, isRestErrorResponse } from '../types';
import mergeApiResponses from './mergeApiResponses';

type PickMatching<T, V> = { [K in keyof T as T[K] extends V ? K : never]: T[K] };

type ExtractMethods<T> = PickMatching<T, Function>;

type RequestParameters =
  | ActivitiesApiActivityDeleteRequest
  | ActivitiesApiActivityListRequest
  | ActivitiesApiActivityFavouriteProductsRequest
  | ActivitiesApiActivityObjectsRequest
  | ActivitiesApiActivityPutRequest
  | ActivitiesApiActivityUndeleteRequest
  | ActivityImportApiActivityImportMapDataRequest
  | ProductCategoriesApiProductCategoryListRequest
  | ProductCategoriesApiProductCategoryObjectsRequest;

export default async function loadAllPages<Api, Entry>(
  api: Api,
  functionName: keyof ExtractMethods<Api>,
  requestParameters: RequestParameters,
  options?: AxiosRequestConfig,
  pageKey?: string,
): Promise<ApiResponse<Entry>> {
  const apiResponse = await loadNextPage<Api, Entry>(api, functionName, requestParameters, options, pageKey);

  if (isRestErrorResponse(apiResponse) || !apiResponse.pageKey) {
    return apiResponse;
  }

  const apiResponseNextPage = await loadAllPages<Api, Entry>(
    api,
    functionName,
    requestParameters,
    options,
    apiResponse.pageKey,
  );

  return mergeApiResponses([apiResponse, apiResponseNextPage]);
}

async function loadNextPage<Api, Entry>(
  api: Api,
  functionName: keyof ExtractMethods<Api>,
  requestParameters: RequestParameters,
  options?: AxiosRequestConfig,
  pageKey?: string,
): Promise<ApiResponse<Entry>> {
  // @ts-ignore
  const { data: apiResponse } = await api[functionName]({ ...requestParameters, pageKey }, options);

  return apiResponse;
}
