import Handsontable from 'handsontable';
import Vue from 'vue';

import ButtonLinkComponent from '@/shared/components/buttons/ButtonLink.vue';

import applySubtableBorderStyles from '../../features/nestedTable/utils/applySubtableRendererBorderStyles';
import { ColumnSettingsButton } from './types';

export default function ButtonRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: string | null,
  cellProperties: ColumnSettingsButton,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...args: unknown[]
) {
  const buttonInstance = new Vue({
    render: (createElement) =>
      createElement(
        ButtonLinkComponent,
        {
          props: {
            color: cellProperties.button.color,
          },
          on: {
            click: (event: any) => {
              if (!cellProperties.readOnly) {
                cellProperties.button.onClick({ event, visualRow, instance });
              }
            },
          },
        },
        [cellProperties.button.label],
      ),
  });

  buttonInstance.$mount();

  // eslint-disable-next-line no-param-reassign
  TD.innerText = '';
  // eslint-disable-next-line no-param-reassign
  TD.style.fontSize = '12px';

  if (cellProperties.readOnly) {
    // eslint-disable-next-line no-param-reassign
    buttonInstance.$el.classList.add('disabled');
  }

  applySubtableBorderStyles(TD, visualRow, cellProperties, instance);

  TD.appendChild(buttonInstance.$el);

  return TD;
}
