import { DisabledDates } from 'vuejs-datepicker';

/**
 * Whether a day is disabled
 * COPIED logic FROM vuejs-datepicker/src/components/PickerDay.vue
 * @param {Date}
 * @return {Boolean}
 */
export function isDisabledDate(date: Date, disabledDates?: DisabledDates): boolean {
  if (!disabledDates) return false;

  if (disabledDates.dates?.some((d) => date.getTime() === d.getTime())) {
    return true;
  }

  if (disabledDates.to && date <= disabledDates.to) {
    return true;
  }

  if (disabledDates.from && date >= disabledDates.from) {
    return true;
  }

  if (disabledDates.ranges?.some((range) => date <= range.to || date >= range.from)) {
    return true;
  }

  if (disabledDates.days && disabledDates.days?.indexOf(date.getDay()) !== -1) {
    return true;
  }

  if (disabledDates.daysOfMonth && disabledDates.daysOfMonth?.indexOf(date.getDate()) !== -1) {
    return true;
  }

  if (disabledDates.customPredictor?.(date)) {
    return true;
  }

  return false;
}

export function nextAvailableDateExists(currentDate: Date, disabledDates?: DisabledDates, step: number = 1): boolean {
  if (!disabledDates) return true;

  if (disabledDates.from && currentDate >= previousDate(disabledDates.from, step)) {
    return false;
  }

  if (disabledDates.days) {
    const allDaysOfWeek = Array.from(Array(6).keys());
    return !allDaysOfWeek.every((day) => disabledDates.days?.includes(day));
  }

  if (disabledDates.daysOfMonth) {
    const allDaysOfMonth = Array.from(Array(31).keys());
    return !allDaysOfMonth.every((day) => disabledDates.daysOfMonth?.includes(day));
  }

  return true;
}

export function previousAvailableDateExists(
  currentDate: Date,
  disabledDates?: DisabledDates,
  step: number = 1,
): boolean {
  if (!disabledDates) return true;

  if (disabledDates.to && currentDate <= nextDate(disabledDates.to, step)) {
    return false;
  }

  if (disabledDates.days) {
    const allDaysOfWeek = Array.from(Array(6).keys());
    return !allDaysOfWeek.every((day) => disabledDates.days?.includes(day));
  }

  if (disabledDates.daysOfMonth) {
    const allDaysOfMonth = Array.from(Array(31).keys());
    return !allDaysOfMonth.every((day) => disabledDates.daysOfMonth?.includes(day));
  }

  return true;
}

export function getNextAvailableDate(currentDate: Date, disabledDates?: DisabledDates, step: number = 1): Date | null {
  if (!nextAvailableDateExists(currentDate, disabledDates, step)) {
    return null;
  }

  let trials = 0;
  const MAX_TRIALS = 60;
  let nextAvailableDateCandidate = nextDate(currentDate, step);

  while (trials < MAX_TRIALS) {
    if (isDisabledDate(nextAvailableDateCandidate, disabledDates)) {
      nextAvailableDateCandidate = nextDate(nextAvailableDateCandidate);
    } else {
      return nextAvailableDateCandidate;
    }
    trials += 1;
  }

  return null;
}

export function getPreviousAvailableDate(
  currentDate: Date,
  disabledDates?: DisabledDates,
  step: number = 1,
): Date | null {
  if (!previousAvailableDateExists(currentDate, disabledDates)) {
    return null;
  }

  let trials = 0;
  const MAX_TRIALS = 60;
  let previousAvailableDateCandidate = previousDate(currentDate, step);

  while (trials < MAX_TRIALS) {
    if (isDisabledDate(previousAvailableDateCandidate, disabledDates)) {
      previousAvailableDateCandidate = previousDate(previousAvailableDateCandidate);
    } else {
      return previousAvailableDateCandidate;
    }
    trials += 1;
  }

  return null;
}

export function nextDate(currentDate: Date, step: number = 1): Date {
  const nextAvailableDate = new Date(currentDate);
  nextAvailableDate.setDate(currentDate.getDate() + step);
  return nextAvailableDate;
}

export function previousDate(currentDate: Date, step: number = 1): Date {
  const previousAvailableDate = new Date(currentDate);
  previousAvailableDate.setDate(currentDate.getDate() - step);
  return previousAvailableDate;
}
