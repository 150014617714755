import Handsontable from 'handsontable';

export default function addCheckboxClassToColumnHeadersFactory(hot: Handsontable) {
  function addCheckboxClassToColumnHeaders(visualCol: number, th: HTMLTableCellElement) {
    const physicalCol = hot.toPhysicalColumn(visualCol);
    const { columns } = hot.getSettings();

    if (!Array.isArray(columns)) throw new Error('columns must be an array');
    const column = columns[physicalCol];

    if (
      column != null &&
      column.type !== undefined &&
      ['checkbox', 'farmdok.checkbox.optional'].includes(column.type)
    ) {
      th.classList.add('checkbox');
    }
  }

  return addCheckboxClassToColumnHeaders;
}
