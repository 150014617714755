import Vue from 'vue';

import { getCustomerNamePart } from '@/fields/store/utils/getterUtils';
import { GUID_KEY } from '@/shared/constants';
import { companyId as companyIdShared } from '@/shared/handsontable/columns';
import { comparatorAlphaNumericEmptyLast } from '@/shared/modules/comparators';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';
import store from '@/store';

import '../renderers/FieldNameRenderer';
import '../renderers/FieldStatusRenderer';
import '../renderers/SharedFieldInfoRenderer';

// region - FIELD PROPERTIES
export const name = {
  key: 'name',
  header: {
    title: Vue.i18n.translate('Bezeichnung'),
    description: Vue.i18n.translate('Feld'),
  },
  type: 'text',
  placeholder: Vue.i18n.translate('<Bezeichnung>'),
  required: true,
  className: 'border-right text-bold',
  width: 270,
};

export const fieldNumber = {
  key: 'fieldNumber',
  header: {
    title: Vue.i18n.translate('Nr'),
    description: Vue.i18n.translate('Feld-Nummer'),
  },
  type: 'text',
  width: 70,
};

export const size = {
  key: 'fieldSize',
  header: {
    title: Vue.i18n.translate('Größe'),
    description: Vue.i18n.translate('ha'),
  },
  type: 'numeric',
  width: 100,
};

export const crop = {
  key: 'cropId',
  header: {
    title: Vue.i18n.translate('Kultur'),
  },
  type: 'dropdown',
  width: 350,
};

export const variety = {
  key: 'varietyId',
  header: {
    title: Vue.i18n.translate('Sorte'),
  },
  type: 'dropdown',
  width: 350,
};

export const preCrop = {
  key: 'preCropId',
  header: {
    title: Vue.i18n.translate('Vorfrucht'),
  },
  type: 'dropdown',
  width: 350,
};

export const catchCropVariant = {
  key: 'catchcropVariant',
  header: {
    title: Vue.i18n.translate('Begrünung'),
    description: Vue.i18n.translate('Zwischenfrucht'),
  },
  type: 'dropdown',
  width: 220,
};

export const kindOfUse = {
  key: 'kindofuse',
  header: {
    title: Vue.i18n.translate('Nutzungsart'),
  },
  type: 'dropdown',
  width: 350,
};

export const comment = {
  key: 'comment',
  header: {
    title: Vue.i18n.translate('Kommentar'),
  },
  type: 'text',
  width: 350,
};

export const amaCode = {
  key: 'amaCode',
  header: {
    title: Vue.i18n.translate('AMA Code'),
  },
  type: 'dropdown',
  width: 90,
};

export const cadastralCommunity = {
  key: 'additionalData.cadastralCommunity',
  header: {
    title: Vue.i18n.translate('Gemeinde'),
    description: Vue.i18n.translate('Katastralgemeinde'),
  },
  type: 'text',
  width: 350,
};

export const plots = {
  key: 'additionalData.plots',
  header: {
    title: Vue.i18n.translate('Parzellen Nr.'),
    description: Vue.i18n.translate('Beistrich-getrennt'),
  },
  type: 'text',
  width: 350,
};

export const owner = {
  key: 'additionalData.owner',
  header: {
    title: Vue.i18n.translate('Eigentümer'),
  },
  type: 'text',
  width: 350,
};

export const rightOfUse = {
  key: 'additionalData.rightOfUse',
  header: {
    title: Vue.i18n.translate('Nutzung durch'),
  },
  type: 'text',
  width: 350,
};

export const ndvi = {
  key: 'ndvi',
  header: {
    title: Vue.i18n.translate('NDVI'),
    description: Vue.i18n.translate('Durchschnittlicher NDVI-Index'),
  },
  type: 'numeric',
  width: 85,
  readOnly: true,
};

export const mbi = {
  key: 'mbi',
  header: {
    title: Vue.i18n.translate('MBI'),
    description: Vue.i18n.translate('Mehrjähriger Biomasseindex auf Basis Blattflächenindex'),
  },
  type: 'numeric',
  width: 85,
  readOnly: true,
};

export const finished = {
  key: 'finished',
  header: {
    title: Vue.i18n.translate('Abgeschlossen'),
  },
  type: 'checkbox',
  checkedTemplate: 1,
  uncheckedTemplate: 0,
  readOnly: true,
  width: 120,
};

export const gwArea = {
  key: 'gwArea',
  header: {
    title: Vue.i18n.translate('Aktionsgebiet'),
  },
  type: 'dropdown',
  width: 85,
  required: true,
};

export const waterProtectionZone = {
  key: 'inWaterProtectionZone',
  header: {
    title: Vue.i18n.translate('Wasserschutzgebiet'),
    description: Vue.i18n.translate('Feld befindet sich im Wasserschutzgebiet'),
  },
  type: 'checkbox',
  checkedTemplate: 1,
  uncheckedTemplate: 0,
  width: 120,
};

export const sharedFieldInfo = {
  key: 'sharedFieldInfo',
  header: {
    title: Vue.i18n.translate('Freigegeben'),
    description: Vue.i18n.translate('An angegebene Betriebe zum Lesen freigegeben.'),
  },
  type: 'sharedFieldInfo',
  readOnly: true,
  data(entry) {
    if (entry == null) {
      return null;
    }
    if (entry.sharedFieldInfo != null) {
      return entry.sharedFieldInfo;
    }
    if (
      this.$parent.sharedFieldsByFieldId == null ||
      !Array.isArray(this.$parent.sharedFieldsByFieldId[entry.id]) ||
      this.$parent.sharedFieldsCompaniesById == null
    ) {
      return null;
    }
    return this.$parent.sharedFieldsByFieldId[entry.id]
      .filter((shareId) => this.$parent.sharedFieldsByShareId[shareId] != null)
      .map((shareId) => ({
        ...this.$parent.sharedFieldsCompaniesById[this.$parent.sharedFieldsByShareId[shareId].companyId],
        shareId,
      }))
      .sort((a, b) => comparatorAlphaNumericEmptyLast(a, b, 1));
  },
  onClick(args) {
    this.$emit('click:sharedFieldInfo', args);
  },
};

export const senderReference = {
  key: 'fieldShare.senderReference',
  header: {
    title: Vue.i18n.translate('Referenz-Nummer'),
    description: Vue.i18n.translate('Partner-Nummer, Kunden-Nummer, CRM Id'),
  },
  type: 'text',
  width: 150,
};

export const senderFarmNumber = {
  key: 'fieldShare.farmNumber',
  header: {
    title: Vue.i18n.translate('Betriebsnummer'),
    description: Vue.i18n.translate('Betriebsnummer des Sender-Betriebs'),
  },
  type: 'text',
  width: 120,
};

export const senderFarmName = {
  key: 'fieldShare.farmName',
  header: {
    title: Vue.i18n.translate('Betriebsname'),
    description: Vue.i18n.translate('Name des Sender-Betriebs'),
  },
  type: 'text',
  width: 220,
};

export const senderMessage = {
  key: 'fieldShare.message',
  header: {
    title: Vue.i18n.translate('Bemerkung'),
    description: Vue.i18n.translate('Referenz oder Freigabegrund'),
  },
  type: 'text',
  width: 170,
};

export const senderEmail = {
  key: 'fieldShare.userEmail',
  header: {
    title: Vue.i18n.translate('Geteilt von'),
    description: Vue.i18n.translate('E-mail des Users, der freigegeben hat'),
  },
  type: 'text',
  width: 120,
};

export const sharedDate = {
  key: 'fieldShare.created',
  header: {
    title: Vue.i18n.translate('Freigabedatum'),
    description: Vue.i18n.translate('Freigabedatum'),
  },
  type: 'date',
  width: 120,
};

export const expiryDate = {
  key: 'fieldShare.expiryDate',
  header: {
    title: Vue.i18n.translate('Ablaufdatum'),
    description: Vue.i18n.translate('Datum an dem die Freigabe verfällt'),
  },
  type: 'date',
  width: 120,
};
// endregion - FIELD PROPERTIES

// region - RESOLVED PROPERTIES
export const companyId = {
  ...companyIdShared,
  key: 'processOrder.companyId',
};

export const cropName = {
  key: 'crop.name',
  header: {
    title: Vue.i18n.translate('Kultur'),
  },
  type: 'text',
  readOnly: true,
  width: 170,
};

export const fieldGroupNumber = {
  key: 'fieldGroup.mfa',
  header: {
    title: Vue.i18n.translate('FSNr'),
    description: Vue.i18n.translate('Feldstück-Nummer'),
  },
  type: 'text',
  width: 70,
};

export const customer = {
  key: 'fieldGroup.customerId',
  header: {
    title: Vue.i18n.translate('Kunde'),
  },
  type: 'dropdown',
  width: 170,
};

export const processOrderName = {
  key: 'processOrder.name',
  header: {
    title: Vue.i18n.translate('Erntejahr'),
  },
  type: 'text',
  readOnly: true,
  width: 100,
};
// endregion - RESOLVED PROPERTIES

// region - COMPUTED PROPERTIES
export const nameWithStatus = {
  ...name,
  type: 'fieldName',
  getStatus(visualRow) {
    if (typeof this.visualRowToGuid !== 'function' || this.tableData == null) {
      return null;
    }
    const id = this.visualRowToGuid(visualRow);
    const field = this.tableData[id];
    if (field == null) {
      return null;
    }
    return field.status;
  },
  hasHarvestingMeasures(visualRow) {
    if (typeof this.visualRowToGuid !== 'function' || this.tableData == null) {
      return false;
    }
    const id = this.visualRowToGuid(visualRow);
    if (this.$parent == null || !Array.isArray(this.$parent.fieldIdsWithHarvestingMeasure)) {
      return false;
    }
    return this.$parent.fieldIdsWithHarvestingMeasure.includes(id);
  },
  onClick(args) {
    this.$emit('click:status', args);
  },
  getStatusReadOnly() {
    return this.$parent.processOrdersReadOnly || this.$parent.fieldsReadOnly;
  },
};

export const status = {
  key: 'status',
  header: {
    title: Vue.i18n.translate('Erntejahr-Status'),
  },
  type: 'fieldStatus',
  getStatus: nameWithStatus.getStatus,
  hasHarvestingMeasures: nameWithStatus.hasHarvestingMeasures,
  onClick(args) {
    this.$emit('click:status', args);
  },
  getStatusReadOnly() {
    return this.$parent.processOrdersReadOnly;
  },
  width: 140,
};

export const combinedNameExclCrop = {
  key: 'combinedNameExclCrop',
  header: {
    title: Vue.i18n.translate('Bezeichnung'),
    description: Vue.i18n.translate('Kunde - Feldstück - Feld'),
  },
  type: 'text',
  placeholder: Vue.i18n.translate('<Bezeichnung>'),
  required: true,
  readOnly: true,
  className: 'border-right text-bold',
  width: 270,
  data(entry) {
    if (entry == null) {
      return '';
    }
    const guid = entry[GUID_KEY];
    let customerId;
    let fieldName = '';
    let mfa;
    if (this.tableDataComputed != null && guid != null && this.tableDataComputed[guid] != null) {
      customerId = getDescendantProp(this.tableDataComputed[guid], customer.key);
      fieldName = getDescendantProp(this.tableDataComputed[guid], name.key);
      mfa = getDescendantProp(this.tableDataComputed[guid], fieldGroupNumber.key);
    } else {
      customerId = getDescendantProp(entry, customer.key);
      fieldName = getDescendantProp(entry, name.key);
      mfa = getDescendantProp(entry, fieldGroupNumber.key);
    }
    const customerNamePart = getCustomerNamePart(customerId, store.state.customers.data);

    if (typeof mfa === 'string' && mfa.length > 0) {
      return `${customerNamePart}${[mfa, fieldName].join(' - ')}`;
    }
    return `${customerNamePart}${fieldName}`;
  },
};

export const fullFieldName = {
  key: 'fullFieldName',
  header: {
    title: Vue.i18n.translate('Bezeichnung'),
    description: Vue.i18n.translate('Feldstück - Feldnummer - Feld'),
  },
  type: 'text',
  placeholder: Vue.i18n.translate('<Bezeichnung>'),
  readOnly: true,
  data(entry) {
    if (entry == null) {
      return '';
    }
    const mfa = getDescendantProp(entry, fieldGroupNumber.key);
    const fieldNumberValue = getDescendantProp(entry, fieldNumber.key);
    const fieldName = getDescendantProp(entry, name.key);

    if (typeof fieldName !== 'string' || fieldName.length < 1) {
      return '';
    }
    const nameParts = [fieldName];
    if (typeof fieldNumberValue === 'string' && fieldNumberValue.length > 0) {
      nameParts.splice(0, 0, fieldNumberValue);
    }
    if (typeof mfa === 'string' && mfa.length > 0) {
      nameParts.splice(0, 0, mfa);
    }
    return nameParts.join(' - ');
  },
};
// endregion - COMPUTED PROPERTIES
