import Handsontable from 'handsontable';

import { ColumnSettingsFlattenedSubtable } from '../types';
import { isLastRowOfSubtableInstance } from './getLastRowOfSubtable';

export default function applySubtableBorderStyles(
  TD: HTMLTableCellElement,
  visualRow: number,
  cellProperties: ColumnSettingsFlattenedSubtable,
  instance: Handsontable,
) {
  if (!cellProperties.isSubtableColumn) return;
  TD.classList.add('subtable');

  const isExpanded = instance.getDataAtRowProp(visualRow, 'expand');
  if (isExpanded) {
    TD.classList.add('subtable-expanded');
  }

  if (cellProperties.isLastSubtableColumn) {
    TD.classList.add('subtable-last-column');
  }

  if (isLastRowOfSubtableInstance(visualRow, instance)) {
    TD.classList.add('subtable-last-row');
  }
}
