import { Activity, FavouriteProduct, FavouriteProducts } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import _difference from 'lodash.difference';
import { ActionTree } from 'vuex';

import AggregationService from '@/activities/modals/createEditActivity/services/AggregationService';
import FindService from '@/activities/modals/createEditActivity/services/FindService';
import { MergedActivityProduct } from '@/activities/modals/createEditActivity/services/MergedActivityProductsService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import ActivitySyncService from '@/activities/services/ActivitySyncService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import ProductService from '@/products/services/ProductService';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../types';
import applyStockLevelOnActivityProductInAllActivities from './applyStockLevelOnActivityProductInAllActivities';
import removeActivityEquipmentInAllActivities from './removeActivityEquipmentInAllActivities';
import removeActivityProductInAllActivities from './removeActivityProductInAllActivities';
import updateActivityType from './updateActivityType';
import updateEquipmentOnActivityEquipmentInAllActivities from './updateEquipmentOnActivityEquipmentInAllActivities';
import updatePestOnActivityProductInAllActivities from './updatePestOnActivityProductInAllActivities';
import updateProductOnActivityProductInAllActivities from './updateProductOnActivityProductInAllActivities';
import updateAmountsAndUnitsInActivities from './utils/updateAmountsAndUnitsInActivities';

const actions: ActionTree<CreateEditActivityState, RootState> = {
  setupEditActivityStore({ rootState, commit }, activityIds: string[]) {
    const activities: Activity[] = [];
    activityIds.forEach((activityId) => {
      const activity = _cloneDeep(rootState.activities.data[activityId]);
      activities.push(activity);
    });

    commit('setActivities', activities);

    if (!activities.some((activity) => activity.products.length > 0)) {
      commit('createActivityProduct');
    }

    if (!activities.some((activity) => activity.equipment.length > 0)) {
      commit('createActivityEquipment');
    }

    commit('setWorkflowKey', 'editActivity');
  },
  updateActivityType,
  addFavouriteProducts({ commit, dispatch, getters, state }, favouriteProducts: FavouriteProducts): void {
    commit('removeAllActivityProductsWithoutProductId');

    favouriteProducts.products.forEach((favouriteProduct: FavouriteProduct) => {
      const activityProduct = {
        ...ActivityProductService.createActivityProduct(),
        ...favouriteProduct,
      };

      commit('addActivityProductToAllActivities', activityProduct);
    });

    // update all activityProducts with the correct products
    if (state) {
      state.activities[0].products.forEach((activityProduct) => {
        dispatch(`updateProductOnActivityProductInAllActivities`, {
          activityProductId: activityProduct.id,
          updateValues: {
            referenceProductId: activityProduct.productId,
            storagePlaceId: activityProduct.storagePlaceId,
          },
        });
      });
    }

    const firstProduct = getters.mergedActivityProducts?.[0];
    if (firstProduct) {
      commit('setCurrentActivityProductId', firstProduct.id);
    }
  },
  updateActivitiesBasedOnSelectedFields({ rootState, state, commit, getters, rootGetters }, fieldIds: string[]): void {
    const { sumWorkingTime, sumDrivingTime, sumSetupTime, sumPauseTime } = new AggregationService(state.activities);
    const firstActivity = state.activities[0];

    // create new activities and set fieldId and processedArea
    if (fieldIds.length === 0) {
      const activity = _cloneDeep({
        ...firstActivity,
        fieldId: null,
        processedArea: null,
      });
      commit('setActivity', activity);
    } else {
      const { selectedFields } = getters;
      let updatedActivities: Activity[] = _cloneDeep(state.activities);

      const addedFieldIds = _difference(fieldIds, selectedFields);
      if (addedFieldIds.length > 0) {
        const fields = rootState.fields.data;
        addedFieldIds.forEach((addedFieldId) => {
          const addedField = fields[addedFieldId];

          const company = rootGetters['auth/findCompanyByProcessOrderId'](addedField.processOrderId);
          const activityType = rootGetters['activityTypes/findActivityTypeOfCompany'](
            firstActivity.activityTypeId,
            company.id,
          );

          const activity: Activity = _cloneDeep({
            ...firstActivity,
            fieldId: addedFieldId,
            processedArea: addedField.fieldSize,
            processOrderId: addedField.processOrderId,
            activityTypeId: activityType.id,
          });
          updatedActivities.push(activity);
        });
        updatedActivities = updatedActivities.filter((activity) => activity.fieldId);
      }

      const removedFieldIds = _difference(selectedFields, fieldIds);
      if (removedFieldIds.length > 0) {
        updatedActivities = updatedActivities.filter(
          (activity) => activity.fieldId && !removedFieldIds.includes(activity.fieldId),
        );
      }
      commit('setActivities', updatedActivities);
    }

    // update all ids
    commit('updateAllActivityIds');
    commit('updateAllActivityProductIds');
    commit('updateAllActivityEquipmentIds');

    // re-distribute times

    commit('distributeWorkingTimeToActivities', sumWorkingTime);
    commit('distributeDrivingTimeToActivities', sumDrivingTime);
    commit('distributeSetupTimeToActivities', sumSetupTime);
    commit('distributePauseTimeToActivities', sumPauseTime);

    // update amounts
    const activityProducts: MergedActivityProduct[] = getters.mergedActivityProducts;
    if (activityProducts.length < 1) return;
    const units = rootState.units.data;
    updateAmountsAndUnitsInActivities(activityProducts, units, commit);
  },
  updateActivitiesBasedOnProcessedArea(
    { rootState, state, commit, getters },
    { fieldId, processedArea }: { fieldId: string; processedArea: number },
  ): void {
    const { sumWorkingTime, sumDrivingTime, sumSetupTime, sumPauseTime } = new AggregationService(state.activities);
    const productService = new ProductService(
      rootState.products.mineralFertilizers.data,
      rootState.products.companyFertilizers.data,
      rootState.products.secondaryFertilizers.data,
      rootState.products.herbizides.data,
      rootState.products.crops.data,
      rootState.products.miscellaneous.data,
      rootState.products.harvests.data,
      rootState.products.seeds.data,
      rootState.products.otherProductsAndFertilizers.data,
    );
    const activityProductService = new ActivityProductService(
      rootState.units.data,
      productService,
      calculateAmountsAndUnits,
    );
    const findService = new FindService(state.activities, activityProductService);

    const activities = findService.findActivitiesByFieldId(fieldId);
    if (activities.length < 1) return;

    activities.forEach((a) => {
      commit('setProcessedArea', { activityId: a.id, processedArea });
    });

    if (state.workflowKey === 'createActivity') {
      // re-distribute times
      commit('distributeWorkingTimeToActivities', sumWorkingTime);
      commit('distributeDrivingTimeToActivities', sumDrivingTime);
      commit('distributeSetupTimeToActivities', sumSetupTime);
      commit('distributePauseTimeToActivities', sumPauseTime);

      // update amounts
      const activityProducts: MergedActivityProduct[] = getters.mergedActivityProducts;
      if (activityProducts.length < 1) return;
      const units = rootState.units.data;
      updateAmountsAndUnitsInActivities(activityProducts, units, commit);
    }
  },
  updateProductOnActivityProductInAllActivities,
  updatePestOnActivityProductInAllActivities,
  applyStockLevelOnActivityProductInAllActivities,
  updateEquipmentOnActivityEquipmentInAllActivities,
  removeActivityProductInAllActivities,
  removeActivityEquipmentInAllActivities, // TODO handle deletion of activityEquipment (set deleted flag if the activityEquipment was already synced to backend)
  save({ state, dispatch }) {
    if (!state.activities) throw new Error('No activity set');

    let syncableActivities = ActivitySyncService.toSyncableActivities(state.activities);
    const creationDate = toUnixTimestamp(Date.now()); // all activities should be created at the same timestamp
    syncableActivities = syncableActivities.map((activity) => ({
      ...activity,
      creationDate,
      tstamp: creationDate,
      isCopy: false,
      completed: true,
    }));

    syncableActivities.forEach((activity) => {
      switch (state.workflowKey) {
        case 'createActivity':
          dispatch('activities/insertAndSyncNewEntry', { entry: activity }, { root: true });
          break;
        case 'editActivity':
          dispatch('activities/updateAndSyncEntry', { entry: activity }, { root: true });
          break;
        default:
          throw new Error(`Unknown workflowKey ${state.workflowKey}`);
      }
    });
  },
};

export default actions;
