import Handsontable from 'handsontable';

function disableColumnResizeFactory(hot: Handsontable) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function disableColumnResize(newSize: number, visualColumn: number, isDoubleClick: boolean) {
    const physicalColumn = hot.toPhysicalColumn(visualColumn);
    const { columns } = hot.getSettings();

    if (!Array.isArray(columns)) throw new Error('columns must be an array');

    if (columns[physicalColumn].disableColumnResize === true) {
      const plugin = hot.getPlugin('manualColumnResize');
      plugin.clearManualSize(visualColumn);
    }
  };
}

export default {
  afterColumnResize: { disableColumnResizeFactory },
};
