export default {
  props: {
    preventWrapAround: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.preventWrapAround) {
      this.addHotHook('beforeKeyDown', this.preventWrapAroundBeforeKeyDown);
    }
  },
  methods: {
    preventWrapAroundBeforeKeyDown(event) {
      if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
        this.$nextTick(this.preventWrapAroundFixViewport);
      }
      if (event.code === 'ArrowUp') {
        const selected = this.hot.getSelected();
        if (!selected) {
          return;
        }
        if (selected[0] === 0) {
          event.stopImmediatePropagation();
        }
        return;
      }
      if (event.code === 'ArrowDown') {
        const selected = this.hot.getSelected();
        const data = this.hot.getData();
        if (!selected) {
          return;
        }
        if (selected[0] === data.length - 1) {
          event.stopImmediatePropagation();
        }
      }
    },
    /**
     * https://git.farmdok.com/farmdok/app-webclient/-/issues/466#note_95110
     * Looks like handsontable scrolling/snapping isn't working correctly, so we do it manually.
     */
    preventWrapAroundFixViewport() {
      if (this.hot == null) {
        return;
      }
      const selected = this.hot.getSelected();
      if (selected == null || selected[0] == null) {
        return;
      }
      const [visualRow, visualCol] = selected[0];
      const td = this.hot.getCell(visualRow, visualCol);
      if (td == null) {
        return;
      }
      let container = td.parentNode;
      while (container != null) {
        if (container.classList.contains('wtHolder')) {
          break;
        }
        container = container.parentNode;
      }
      const tdRect = td.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      if (tdRect.top < containerRect.top || tdRect.bottom > containerRect.bottom) {
        this.hot.scrollViewportTo(visualRow, visualCol);
      }
    },
  },
};
