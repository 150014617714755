<template>
  <ThePageSkeleton class="page-additional-data" scroll-y content-flex>
    <template #contentbar>
      <TheContentbar>
        <template #middle>
          <Steps :steps="steps" :current="currentStep" />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <div class="page-additional-data__content">
        <StepUpdateNames
          v-if="currentStep === 0"
          :fetching="fetching"
          :user-error-message="userErrorMessage"
          :firstname="$store.state.auth.user.firstname"
          :lastname="$store.state.auth.user.lastname"
          :companyName="currentCompany.name"
          @input="userErrorMessage = null"
          @submit="userErrorMessage = null"
          @save="saveNames"
        />
        <StepUpdateAddress
          v-if="currentStep === 1"
          :fetching="fetching"
          :user-error-message="userErrorMessage"
          :address="currentCompany.address"
          :postcode="currentCompany.postcode"
          :city="currentCompany.city"
          @input="userErrorMessage = null"
          @submit="userErrorMessage = null"
          @save="saveAddress"
        />
        <p>
          <ButtonLink :to="skipTarget.to" :href="skipTarget.href" @click="onSkip">
            {{ $t('Überspringen') }}
          </ButtonLink>
        </p>
      </div>
    </template>
  </ThePageSkeleton>
</template>

<script>
import Handsontable from 'handsontable';

import { FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE } from '@/auth/constants';
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import Steps from '@/shared/components/Steps.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import { baseUrl, originMobile } from '@/shared/constants';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';

import StepUpdateAddress from './components/StepUpdateAddress.vue';
import StepUpdateNames from './components/StepUpdateNames.vue';

const { isMobileBrowser } = Handsontable.helper;

const MOBILE_HOME_HREF = `${originMobile}${baseUrl}fields`;

export default {
  name: 'PageAdditionalData',
  components: {
    StepUpdateAddress,
    StepUpdateNames,
    Steps,
    TheContentbar,
    ThePageSkeleton,
    ButtonLink,
  },
  mixins: [backendResponseErrorHelpers],
  data() {
    return {
      steps: [
        { number: 1, label: this.$t('Betrieb') },
        { number: 2, label: this.$t('Adresse') },
      ],

      fetching: false,
      defaultUserErrorMessage: this.$t('Unbekannter Fehler. Bitte überprüfe deine Internetverbindung.'),
    };
  },
  computed: {
    currentStep() {
      if (this.$route.params.step === 'company') {
        return 0;
      }
      if (this.$route.params.step === 'address') {
        return 1;
      }
      return null;
    },
    currentCompany() {
      return this.$store.state.auth.currentCompanies[0];
    },
    skipTarget() {
      if (this.$route.params.step === 'company') {
        const to = { name: 'register-additional-data', params: { step: 'address' } };
        const href = null;

        return { to, href };
      }
      if (isMobileBrowser()) {
        const to = null;
        const href = MOBILE_HOME_HREF;

        return { to, href };
      }

      const to = { name: 'home' };
      const href = null;

      return { to, href };
    },
  },
  methods: {
    async saveNames({ firstname, lastname, companyName }) {
      if (this.fetching) {
        return;
      }
      this.fetching = true;

      // wait at least 200ms that the spinner looks better
      try {
        await Promise.all([
          // eslint-disable-next-line no-promise-executor-return
          new Promise((resolve) => setTimeout(resolve, 200)),
          this.saveUser({ firstname, lastname }),
          this.saveCompany({ firstname, lastname, name: companyName }),
        ]);
        await this.$router.push({ name: 'register-additional-data', params: { step: 'address' } });
      } catch (response) {
        this.setUserErrorMessageFromResponse(response);
      }

      this.fetching = false;
    },
    async saveUser({ firstname, lastname }) {
      const response = await this.$store.dispatch('auth/updateAndSyncUser', {
        firstname,
        lastname,
      });
      if (response.status !== 'success') {
        throw response;
      }
    },
    async saveCompany(data) {
      const response = await this.$store.dispatch('auth/updateAndSyncCurrentCompany', {
        id: this.currentCompany.id,
        data,
      });
      if (response.status !== 'success') {
        throw response;
      }
    },
    async saveAddress(companyData) {
      if (this.fetching) {
        return;
      }
      this.fetching = true;

      // wait at least 200ms that the spinner looks better
      try {
        // eslint-disable-next-line no-promise-executor-return
        await Promise.all([new Promise((resolve) => setTimeout(resolve, 200)), this.saveCompany(companyData)]);
        localStorage.removeItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE);

        if (isMobileBrowser()) {
          window.location.href = MOBILE_HOME_HREF;
        } else {
          await this.$router.push({ name: 'home' });
        }
      } catch (response) {
        this.setUserErrorMessageFromResponse(response);
      }

      this.fetching = false;
    },
    onSkip() {
      if (this.$route.params.step === 'address') {
        localStorage.removeItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE);
      }
    },
  },
};
</script>

<style scoped>
.page-additional-data__content {
  display: flex;
  max-width: calc(450px + 2rem);
  width: 100%;
  margin: auto;
  padding: 1rem 1rem 200px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
