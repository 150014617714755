import { mapGetters } from 'vuex';

import { GUID_KEY } from '@/shared/constants';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';

/**
 * Always use this mixin together with shared/handsontable/mixins/containers/tableContainerBase.
 *
 * This mixin automatically connects a handsontable container with a store that can
 * update the data + sync the changes to the BE.
 * Requires a store module that uses shared/mixins/store/subscribableData.js
 *
 * @param storeName {string}
 * @return {object}
 */
export default function featureSyncableTableData(storeName) {
  return {
    props: {
      selectedRows: {
        type: Array,
        default: null,
      },
      removeRowConfirmMessage: {
        type: [String, Function],
        default() {
          return (entries) => {
            let message = this.$t('Gewählte Einträge wirklich löschen?');
            if (entries.length === 1) {
              message = this.$t('Gewählten Eintrag wirklich löschen?');
            }
            return message;
          };
        },
      },
    },
    computed: {
      ...mapGetters({
        tableData: `${storeName}/data`,
        tableErrors: `${storeName}/errors`,
      }),
    },
    methods: {
      updateEntryByKeyAndValue({ guid, columnKey, value }) {
        if (guid == null || this.tableData[guid] == null) {
          return;
        }
        const missingData = this.tableSettings.columns.some((column) => {
          if (!column.required) {
            return false;
          }
          if (column.key === columnKey && value != null) {
            return false;
          }
          return getDescendantProp(this.tableData[guid], column.key) == null;
        });
        if (missingData) {
          this.$store.commit(`${storeName}/updateEntryByKeyAndValue`, {
            guid,
            key: columnKey,
            value,
          });
        } else {
          const entry = {
            ...this.tableData[guid],
            [columnKey]: value,
          };
          this.$store.dispatch(`${storeName}/updateAndSyncEntry`, { entry });
        }
      },
      insertAndSyncNewEntry(entry) {
        const guid = entry[GUID_KEY];
        if (guid == null) {
          return;
        }
        const missingData = this.tableSettings.columns.some((column) => {
          if (!column.required) {
            return false;
          }
          return entry[column.key] == null;
        });
        if (missingData) {
          this.$store.dispatch(`${storeName}/insertNewEntry`, { entry });
        } else {
          this.$store.dispatch(`${storeName}/insertAndSyncNewEntry`, { entry });
        }
      },
      removeSelectedEntries() {
        if (!Array.isArray(this.selectedRows)) {
          return;
        }
        this.removeEntries(this.selectedRows.map((guid) => this.tableData[guid]));
      },
      async removeEntries(entries) {
        const response = await this.$confirm({
          message: this.removeRowConfirmMessage(entries),
          okTitle: this.$t('Löschen'),
          okVariant: 'danger',
        });
        if (!response) {
          return;
        }
        entries.forEach((entry) => {
          this.$store.dispatch(`${storeName}/removeAndSyncEntry`, { entry });
        });
      },
    },
  };
}
