import { PesticideIndicationBase, ProductsApi } from 'farmdok-rest-api';

import { mergeApiResponsesErrors, mergeApiResponsesStatus } from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';

export default class ProductPesticideIndicationApiService {
  private readonly productsApi: ProductsApi;

  constructor(productsApi: ProductsApi) {
    this.productsApi = productsApi;
  }

  public async getProductPesticideIndications(
    pesticideIds: string[],
  ): Promise<Record<string, PesticideIndicationBase[]>> {
    const responses = await Promise.all(
      pesticideIds.map((pesticideId) => this.productsApi.productPesticideIndicationList({ pesticideId })),
    );
    const apiResponses = responses.map((response) => response.data);
    const responseStatus = mergeApiResponsesStatus(apiResponses);

    if (responseStatus !== 'success') {
      const responseErrors = mergeApiResponsesErrors(apiResponses);
      throw new Error(responseErrors.errorUserMessage.join(', '));
    }

    const indicationsByPesticideId = pesticideIds.reduce((acc, pesticideId, index) => {
      const { data } = apiResponses[index];
      if (data) {
        acc[pesticideId] = data;
      }
      return acc;
    }, {} as Record<string, PesticideIndicationBase[]>);

    return indicationsByPesticideId;
  }
}
