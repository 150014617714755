<template lang="html">
  <span class="simple-tooltip bg-black text-white" :style="style">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'SimpleTooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'right',
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    style() {
      const style = {
        top: `${this.y}px`,
        left: `${this.x}px`,
      };
      switch (this.position) {
        case 'right':
        default:
          style.transform = 'translate(23px, -50%)';
      }
      return style;
    },
  },
};
</script>

<style lang="css" scoped>
.simple-tooltip {
  position: absolute;
  border-radius: 2px;
  padding: 5px 10px;
  opacity: 1;
  box-shadow: 0 25px 50px rgba(0, 0, 50, 0.1), 0 8px 20px rgba(0, 0, 50, 0.15), 0 5px 7px rgba(0, 0, 0, 0.05);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
}
</style>
