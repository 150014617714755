import Vue from 'vue';

import {
  combinedNameExclCrop,
  companyId,
  crop,
  customer,
  fieldNumber,
  name,
  size,
  status,
  variety,
} from '@/fields/handsontable/columns/columns';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { tableSettingsColumn } from '@/shared/handsontable/mixins/featureTablesettingsColumn';

export const tableFields = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    requiredFeatures: (store: any) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...combinedNameExclCrop,
    header: {
      title: Vue.i18n.translate('Kunde - Feldstück - Feld'),
      description: Vue.i18n.translate('Kunde - Feldstück - Feld'),
    },
    width: 145,
    lockedPosition: true,
    sortOrder: 'asc',
    readOnly: true,
  },
  {
    ...size,
    key: 'processedAreaOrFieldSize',
  },
  {
    ...crop,
    lockedPosition: false,
    readOnly: true,
  },
  {
    ...variety,
    readOnly: true,
  },

  {
    ...fieldNumber,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...customer,
    hiddenPerDefault: true,
    readOnly: true,
  },
  {
    ...name,
    hiddenPerDefault: true,
    className: '',
    width: 145,
    readOnly: true,
  },

  {
    ...status,
    hiddenPerDefault: true,
    readOnly: true,
  },

  tableSettingsColumn,
];

export default {
  international: tableFields,
};
