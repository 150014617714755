<template>
  <BCard class="card" tag="article" no-body :class="{ 'card--ghost': isGhost }">
    <div v-if="fetching" class="bg-lightest" style="height: 100px" />
    <BCardImg
      v-else-if="imageTitleUrl && !isGhost"
      class="image bg-primary"
      :src="imageTitleUrl"
      alt="Card title image"
    />
    <div v-else-if="!isGhost" class="bg-primary image-placeholder"></div>
    <ImgCompanyLogo
      v-if="imageCompanyLogoUrl"
      class="title-logo"
      :class="{ 'title-logo--ghost': isGhost }"
      :src="imageCompanyLogoUrl"
    />
    <BCardBody class="body" :class="{ 'body--ghost': isGhost }">
      <Status :status="status" />
      <div class="body-text" :class="{ 'flex-grow-1': !isGhost }">
        <div class="title-wrapper" :class="{ 'title-wrapper--ghost': isGhost }">
          <div class="title-content">
            <h2 class="title">{{ headline }}</h2>
            <h6 v-if="subTitle && !isGhost" class="sub-title text-gray">
              {{ subTitle }}
            </h6>
          </div>
        </div>
        <p class="description" :class="{ description: isGhost }">
          {{ description }}
        </p>
      </div>
      <div class="btn-area">
        <BButton
          v-if="primaryButtonLabel"
          class="card-btn"
          variant="primary"
          :disabled="primaryButtonDisabled"
          @click="$emit('onPrimaryClick')"
        >
          {{ primaryButtonLabel }}
        </BButton>
        <BButton
          v-if="secondaryButtonLabel"
          class="card-btn"
          variant="inline-black"
          :disabled="secondaryButtonDisabled"
          @click="$emit('onSecondaryClick')"
        >
          {{ secondaryButtonLabel }}
        </BButton>
      </div>
    </BCardBody>
  </BCard>
</template>

<script>
import ImgCompanyLogo from './ImgCompanyLogo.vue';
import Status from './Status.vue';

export default {
  name: 'Card',
  components: {
    ImgCompanyLogo,
    Status,
  },
  props: {
    isGhost: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    imageTitleUrl: {
      type: String,
      default: '',
    },
    imageCompanyLogoUrl: {
      type: String,
      default: '',
    },
    headline: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    primaryButtonLabel: {
      type: String,
      default: null,
    },
    primaryButtonDisabled: {
      type: Boolean,
      default: false,
    },
    secondaryButtonLabel: {
      type: String,
      default: null,
    },
    secondaryButtonDisabled: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
      default() {
        return {
          label: null,
          type: null,
        };
      },
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  max-width: 322px;
  margin: 12px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.card--ghost {
  box-shadow: none;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0px;
}

.image {
  max-height: 100px;
  object-fit: cover;
  border-radius: 3px 3px 0px 0px;
}

.image-placeholder {
  height: 100px;
  border-radius: 3px 3px 0px 0px;
}

.body--ghost {
  align-items: center;
  justify-content: center;
}

.body-text {
  padding-top: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-wrapper {
  display: flex;
  align-items: flex-start;
}

.title-wrapper--ghost {
  flex-direction: column;
  align-items: center;
}

.title-content {
  flex: 1;
}

.title-logo {
  position: absolute;
  top: 29px;
  left: 50%;
  transform: translate(-50%, 0);
  object-position: right;
}

.title-logo--ghost {
  margin-left: 0;
  filter: grayscale(100%);
  opacity: 0.5;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.sub-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--medium_dark);
}

.description {
  font-size: 12px;
  font-weight: 400;
  color: var(--gray_800);
  margin-top: auto;
  height: 100%;
}

.description--ghost {
  text-align: center;
}

.btn-area {
  padding-bottom: 4px;
  padding-top: 3px;
}

.card-btn {
  margin-right: 4px;
}

.card-btn:last-child {
  margin-right: 0;
}

.card-btn.disabled,
.card-btn:disabled {
  color: initial;
  opacity: 0.25;
}
</style>
