import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import BootstrapVue, { BButton, BTooltip, ToastPlugin } from 'bootstrap-vue';
import { Buffer } from 'buffer';
import 'handsontable/languages/all';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
// @ts-ignore
import vClickOutside from 'v-click-outside';
import Vue from 'vue';

import '@/initI18n';

import App from '@/App.vue';
import '@/initJwtAuth';
// do not change order of the following 5 imports
import numbro from '@/initNumbro';
import initRoutes from '@/initRouter';
import '@/initVeeValidate';
import '@/initVueSanitize';
import FarmdokRestApiPlugin from '@/plugins/farmdokRestApi';
import axiosInstance from '@/plugins/farmdokRestApi/axiosInstance';
import vueConfirmDialog from '@/plugins/vue-confirm-dialog/vueConfirmDialog';
import router from '@/router';
import { origin } from '@/shared/constants';
import '@/shared/handsontable/registerCellTypes';
import store from '@/store';
import TrackingHelper from '@/tracking';

/**
 * basic vue setup
 */
initRoutes();
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(ToastPlugin);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('BButton', BButton);
Vue.component('BTooltip', BTooltip);

Vue.use(vClickOutside);
Vue.use(vueConfirmDialog);
Vue.use(FarmdokRestApiPlugin, { axiosInstance, store });

/**
 * initialize locale
 */
numbro.setLanguage(store.state.locale);
moment.locale(store.state.locale);
store.dispatch('checkLocale');
document.documentElement.lang = store.state.locale;

/**
 * add axios interceptor to check for logout
 */
axios.defaults.baseURL = origin;
axios.interceptors.request.use((config) => {
  if (
    config.url?.indexOf('/admin/') !== 0 ||
    config.url.indexOf('/admin/rest') === 0 ||
    config.url.indexOf('/admin/sen4') === 0 ||
    config.url.indexOf('/admin/comp') === 0
  ) {
    return config;
  }
  if (
    // @ts-ignore
    store.state.auth.currentCompanies.length !== 1 ||
    // @ts-ignore
    typeof store.state.auth.currentCompanies[0].databaseId !== 'number'
  ) {
    throw new Error(`Missing company databaseId for deprecated AgriDat request: ${config.url}`);
  }
  // @ts-ignore
  const { databaseId } = store.state.auth.currentCompanies[0];
  return {
    ...config,
    url: `/admin/comp/${databaseId}/${config.url.split('/admin/')[1]}`,
  };
});
axios.interceptors.request.use((config) => {
  const accessToken = store.getters['auth/accessToken']();
  if (accessToken == null || !config.url) {
    return config;
  }
  const accessTokenDecoded = jwtDecode(accessToken);
  const link = new URL(config.url, config.baseURL);
  // @ts-ignore
  if (!accessTokenDecoded.aud.includes(link.hostname)) {
    return config;
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
      'X-Process-Orders': JSON.stringify(store.getters['auth/currentProcessOrderIds']),
    },
  };
});
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('auth/logout');
    }
    throw error;
  },
);

/**
 * start app
 */
store.dispatch('auth/subscribe');
const instance = new Vue({
  router,
  store,
  render: (h) => h(App),
});

if (window.Cypress) {
  // only available during E2E tests
  window.app = instance;
}

window.Buffer = window.Buffer || Buffer;

const splashAnimationHasPlayed = sessionStorage.getItem('farmdok-splash-animation-has-played');
const splashAnimationFinishTime = splashAnimationHasPlayed != null ? parseInt(splashAnimationHasPlayed, 10) : 0;
const timeUntilSplashAnimationFinish = Math.max(splashAnimationFinishTime - new Date().getTime(), 0);
setTimeout(() => {
  instance.$mount('#app');
}, timeUntilSplashAnimationFinish);

/**
 * add app root to tracking so that it can track root events
 */
if (process.env.VUE_APP_DISABLE_TRACKING !== '1') {
  const trackingHelper = new TrackingHelper(instance, router, store);
  trackingHelper.init();
}

// add vue toast notification
Vue.use(ToastPlugin);
