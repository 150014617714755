<template lang="html">
  <ModalWrapper
    ref="modalWrapper"
    size="md"
    body-class="modal-remove-shared-fields"
    :visible="visible"
    :title="$t('Freigaben entfernen')"
    @change="(value) => $emit('change', value)"
  >
    <template #default>
      <p>{{ $t('Hier kannst du alle Freigaben für einen Betrieb im aktuellen Erntejahr entfernen.') }}</p>
      <p v-for="company in sharedFieldsCompaniesById" class="pr-3" :key="company.id">
        {{ company.name }} ({{ $t('{count} Felder', { count: sharedFieldCountByCompanyId[company.id] }) }})
        <FontAwesomeIcon
          icon="trash-alt"
          class="float-right text-danger cursor-pointer"
          @click="companyIdToRemove = company.id"
        />
        <ButtonLink
          v-if="companyIdToRemove === company.id"
          class="d-block mt-2"
          style="text-align: left"
          @click="remove"
        >
          {{ $t('Freigaben jetzt entfernen.', { companyName: company.name }) }}
        </ButtonLink>
      </p>
    </template>
    <template #bottom-content>
      <ErrorUserMessage :response-data="responseData" class="m-0" />
    </template>
    <template #buttons>
      <BButton variant="primary" class="mr-3" :disabled="fetching" @click="hide">
        <span>{{ $t('Schließen') }}</span>
      </BButton>
      <BButton variant="outline_white" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';

library.add(faTrashAlt);

export default {
  name: 'ModalRemoveSharedFields',
  components: {
    ButtonLink,
    ErrorUserMessage,
    ModalWrapper,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    sharedFieldsByFieldId: {
      type: Object,
      default: null,
    },
    sharedFieldsCompaniesById: {
      type: Object,
      default: null,
    },
    sharedFieldsByShareId: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
      source: null,
      responseData: null,
      companyIdToRemove: null,
    };
  },
  computed: {
    sharedFieldCountByCompanyId() {
      if (this.sharedFieldsByFieldId == null) {
        return null;
      }
      return Object.values(this.sharedFieldsByShareId).reduce((acc, fieldShare) => {
        const current = { ...acc };
        if (current[fieldShare.companyId] == null) {
          current[fieldShare.companyId] = 0;
        }
        current[fieldShare.companyId] += 1;
        return current;
      }, {});
    },
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.reset();
      }
    },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.fetching = false;
      this.responseData = null;
      this.companyIdToRemove = null;
    },
    async remove() {
      this.responseData = null;

      this.fetching = true;
      let response;
      try {
        const { data } = await axios.post('/admin/rest/sharedField/delete_for_target', {
          version: '2.0',
          data: {
            sourceProcessOrderIds: this.$store.getters['auth/currentProcessOrderIds'],
            targetCompanyId: this.companyIdToRemove,
          },
        });
        response = getRestResponseData(data);
      } catch (error) {
        console.error(error);
        response = getRestResponseData(error);
      }
      this.fetching = false;
      if (response != null && response.status === 'success') {
        this.$emit('success', response.data);
      } else {
        this.responseData = response;
      }
    },
    hide() {
      if (this.source != null) {
        this.source.cancel();
      }
      this.$refs.modalWrapper.hide();
    },
  },
};
</script>
