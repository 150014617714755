<template>
  <div v-if="visible" class="weather-info p-3" :class="`weather-info--${variant}`">
    <div class="weather-info__tabs border-bottom border-medium">
      <Tabs :tabs="chartTypes.slice(0, 3)" :size="variant === 'widget' ? 'lg' : 'md'" :fill="true" />
      <BDropdown class="m-0 p-0" size="md" :text="$t('Mehr')" variant="outline">
        <BDropdownItem
          v-for="chartType in chartTypes.slice(3)"
          :key="chartType.label"
          @click="
            () => {
              rotateBy = chartType.rotateBy;
              chartType.onClick();
            }
          "
          >{{ chartType.label }}
        </BDropdownItem>
      </BDropdown>
    </div>
    <div class="weather-info__caption" :class="{ 'weather-info__caption--blurred': !enabled }" v-if="showWeatherInfo">
      <div>
        <span>{{ metricUnit }}</span>
      </div>
      <div>
        <div v-if="variant === 'compact'" class="weather-info__fieldname">
          {{ $t('Wetter für:') }} {{ this.selectedField?.name }}
        </div>
        <WeatherPeriodSwitch v-if="aggregates.length > 1" :periods="aggregates" v-model="selectedAggregate" />
      </div>
    </div>
    <div v-if="showWeatherInfo" class="weather-info__chart" :class="{ 'weather-info__chart--blurred': !enabled }">
      <BarChart
        v-if="selectedChart === 'Precipitation'"
        :chartData="chartData"
        :showMean="selectedAggregate === 'Monthly' || selectedAggregate === 'Yearly'"
        :maxY="barChartMaxY"
        :axisData="axisData"
        :missingDataMessage="$t('Keine Wetterdaten für den ausgewählten Zeitraum verfügbar.')"
        @prev="previousDate"
        @restore="resetDate"
        @next="nextDate"
      />
      <LineChart
        v-if="
          [
            'Temperature2m',
            'HeatUnit',
            'PrecipitationAccumulated',
            'SoilTemperatureSurface',
            'SoilMoistureSurface',
          ].includes(selectedChart)
        "
        :chartData="chartData"
        :minX="minX.unix()"
        :maxX="maxX.unix()"
        :minY="0"
        :axisData="axisData"
        :missingDataMessage="$t('Keine Satellitendaten für den ausgewählten Zeitraum verfügbar.')"
        @prev="previousDate"
        @restore="resetDate"
        @next="nextDate"
      />
    </div>
    <FeatureNotAvailable v-if="!enabled" no-back-link class="mt-3" />
    <FormFieldSetBordered
      :headline="`${$t('Wetterdiagramm')} ${$t('Einstellungen')}`"
      :labelLinkOpen="$t('Einstellungen anzeigen')"
      :labelLinkClose="$t('Einstellungen verstecken')"
      class="overflow-hidden"
    >
      <template #description>
        <span>{{ $t('Startdatum') }}: {{ format(accumulationDate) }}</span>
        <span v-if="isMainSeeding"> ({{ $t('Saatdatum') }})</span>
        ,
        <span>{{ $t('Basistemperatur') }}: {{ baseTemperature }}°C </span>
        <span v-if="baseTemperature === 8"> ({{ $t('Mais') }})</span>
      </template>

      <FormFieldDatepicker
        variant="horizontal"
        :placeholder="$t('Wert')"
        v-model="accumulationDate"
        :label="$t('Startdatum')"
        class="mb-2"
        :description="
          $t(
            // eslint-disable-next-line max-len
            'Das Startdatum ist standardmäßig das Aussaatdatum der Maßnahme oder alternativ der Jahresbeginn. Es ist relevant für die Wärmesumme und die Niederschlagssumme.',
          )
        "
      />
      <FormFieldInput
        variant="horizontal"
        type="number"
        :placeholder="$t('Wert')"
        v-model="baseTemperature"
        :label="`${$t('Basistemperatur')} °C`"
        :description="
          $t(
            // eslint-disable-next-line max-len
            'Basistemperatur ist relevant für die Wärmesumme. Es wird angenommen, dass unter ihr kein Wachstum stattfindet.',
          )
        "
        required
        :lazy="false"
        :step="1"
        :min="-10"
        :max="50"
        class="mb-2"
      />
    </FormFieldSetBordered>

    <div v-if="showUnknownErrorMessage" class="weather-info__message weather-info__message--error">
      <p>{{ $t('Beim Laden der Wetterdaten ist ein Fehler aufgetreten.') }}</p>
    </div>
    <div v-if="showEmptyWeatherDataMessage" class="weather-info__message weather-info__message--error">
      <p>{{ $t('Leider können für dieses Feld keine Wetterdaten geladen werden.') }}</p>
    </div>
    <div v-if="showMissingGeoDataMessage" class="weather-info__message weather-info__message--error">
      <p>{{ $t('Für das ausgewählte Feld fehlen die Geo-Daten.') }}</p>
    </div>
    <div v-show="loading" class="loading">
      <FontAwesomeIcon class="text-primary" icon="circle-notch" spin />
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import debounce from 'lodash.debounce';
import zip from 'lodash.zip';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import { identifier } from '@/precision-farming/monitoring/weather/store/common';
import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import Tabs from '@/shared/components/Tabs.vue';
import BarChart from '@/shared/components/charts/BarChart.vue';
import LineChart from '@/shared/components/charts/LineChart.vue';
import FormFieldDatepicker from '@/shared/components/form/FormFieldDatepicker.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldSetBordered from '@/shared/components/form/FormFieldSetBordered.vue';
import { ACTIVITY_ROUGH_GUIDS } from '@/shared/constants';
import dateSwitcher from '@/shared/mixins/charts/dateSwitcher';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import WeatherPeriodSwitch from './WeatherPeriodSwitch.vue';

library.add(faCircleNotch);

export default {
  name: 'WeatherInfo',
  mixins: [dateSwitcher],
  components: {
    FormFieldInput,
    FormFieldDatepicker,
    FormFieldSetBordered,
    WeatherPeriodSwitch,
    FeatureNotAvailable,
    BarChart,
    LineChart,
    Tabs,
  },
  props: {
    selectedField: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: null,
      validator: (val) => ['compact', 'widget'].includes(val),
    },
    showWeatherInfo: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$store.dispatch('activities/subscribe');
    this.$store.dispatch('activityTypes/subscribe');
  },
  data() {
    const debounceBaseTemp = debounce((temp) => {
      this.manualBaseTemperature = temp;
      return temp;
    }, 500);
    return {
      selectedChart: 'Precipitation',
      showUnknownErrorMessage: false,
      showEmptyWeatherDataMessage: false,
      showMissingGeoDataMessage: false,
      manualAccumulationDate: null,
      manualBaseTemperature: null,
      debounceBaseTemp,
      rotateBy: 0,
    };
  },
  computed: {
    ...mapState('precisionFarming/monitoring/weather', {
      loading: 'fetching',
    }),
    ...mapGetters('activities', ['findAllByFieldIds']),
    ...mapState('products/crops', {
      crops: 'data',
    }),
    ...mapState('activityTypes', {
      activityTypes: 'data',
    }),
    relevantActivities() {
      const fieldIds = this.$store.state.precisionFarming.monitoring.selectedFields;
      return this.findAllByFieldIds(fieldIds)
        .filter((activity) => this.activityTypes[activity.activityTypeId]?.roughId === ACTIVITY_ROUGH_GUIDS.SEED_MAIN)
        .sort((activityA, activityB) => activityA.timeStart > activityB.timeStart)
        .map((activity) => ({ ...activity }));
    },
    isMainSeeding() {
      return this.relevantActivities[0] !== undefined;
    },
    selectedCrop() {
      return { ...this.crops[this.selectedField?.cropId ?? ''] };
    },
    baseTemperature: {
      set(temperature) {
        this.debounceBaseTemp(temperature);
      },
      get() {
        if (this.manualBaseTemperature) {
          return this.manualBaseTemperature;
        }
        return this.selectedCrop?.temperatureBase ?? 5;
      },
    },
    accumulationDate: {
      set(date) {
        this.manualAccumulationDate = date;
      },
      get() {
        if (this.manualAccumulationDate) {
          return this.manualAccumulationDate;
        }
        return this.isMainSeeding
          ? moment.unix(this.relevantActivities[0]?.timeStart).toDate()
          : moment(this.cropYear, 'YYYY').toDate();
      },
    },
    visible() {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_WEATHER_INFO);
    },
    enabled() {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_WEATHER_INFO);
    },
    aggregates() {
      const aggregates = [
        {
          period: 'Daily',
          label: this.$t('Tage'),
        },
      ];

      if (this.selectedChart === 'Temperature2m') {
        aggregates.push({
          period: 'Monthly',
          label: this.$t('Monate'),
        });
      }

      if (this.selectedChart === 'Precipitation') {
        aggregates.push(
          {
            period: 'Monthly',
            label: this.$t('Monate'),
          },
          {
            period: 'Yearly',
            label: this.$t('Jahre'),
          },
        );
      }
      return aggregates;
    },
    minX() {
      switch (this.selectedAggregate) {
        case 'Daily':
        case 'Monthly':
          return moment(this.navigationDate).startOf('year');
        case 'Yearly':
          return moment(this.navigationDate).subtract(5, 'years').startOf('year');
        default:
          throw new Error(`Unsupported aggregate period ${this.selectedAggregate}`);
      }
    },
    maxX() {
      switch (this.selectedAggregate) {
        case 'Daily':
        case 'Monthly':
          return this.minX.clone().endOf('year');
        case 'Yearly':
          return moment(this.navigationDate).endOf('year');
        default:
          throw new Error(`Unsupported aggregate period ${this.selectedAggregate}`);
      }
    },
    chartData() {
      const minMaxData = { isMinMax: true, dataPoints: [] };
      const meanData = { isMean: true, dataPoints: [] };
      const overallMeanData = { isMeanOverall: true, dataPoints: [] };
      const forecastData = { isForecast: true, dataPoints: [] };

      const [min, weatherVariable, max] = this.inferWeatherVariables(this.selectedChart);
      switch (this.selectedChart) {
        case 'Precipitation':
          // bar chart
          return zip(
            this.weatherInfoMean?.data?.time,
            this.weatherInfoActual?.data?.precipitation,
            this.weatherInfoMean?.data?.precipitation,
          )
            .map(([time, value, mean]) => [this.toMoment(time), value ?? 0, mean ?? 0])
            .map(([time, value, mean]) => ({
              value,
              mean,
              caption: this.caption(time),
              placeholder: value === 0,
            }));
        case 'Temperature2m':
        case 'PrecipitationAccumulated':
        case 'HeatUnit':
        case 'SoilMoistureSurface':
        case 'SoilTemperatureSurface':
          // line chart
          minMaxData.dataPoints = zip(
            this.weatherInfoMean?.data?.time,
            this.weatherInfoActual?.data?.[min],
            this.weatherInfoActual?.data?.[max],
          )
            .map(([time, yMin, yMax]) => [this.toMoment(time), yMin ?? 0, yMax ?? 0])
            .map(([time, yMin, yMax]) => ({
              x: time.unix(),
              yMin,
              yMax,
              caption: this.caption(time),
              placeholder: yMin === 0 || yMax === 0,
            }));
          meanData.dataPoints = zip(this.weatherInfoMean?.data?.time, this.weatherInfoActual?.data?.[weatherVariable])
            .map(([time, y]) => [this.toMoment(time), y ?? 0])
            .filter(([time]) => time.isSameOrBefore(moment(), 'day'))
            .map(([time, y]) => ({
              x: time.unix(),
              y,
              caption: this.caption(time),
              placeholder: y === 0,
            }));
          forecastData.dataPoints = zip(
            this.weatherInfoMean?.data?.time,
            this.weatherInfoActual?.data?.[weatherVariable],
          )
            .map(([time, y]) => [this.toMoment(time), y ?? 0])
            .filter(([time]) => time.isAfter(moment(), 'day'))
            .map(([time, y]) => ({
              x: time.unix(),
              y,
              caption: this.caption(time),
              placeholder: y === 0,
            }));
          overallMeanData.dataPoints = zip(
            this.weatherInfoMean?.data?.time,
            this.weatherInfoMean?.data?.[weatherVariable],
          )
            .map(([time, y]) => [this.toMoment(time), y ?? 0])
            .map(([time, y]) => ({
              x: time.unix(),
              y,
              caption: this.caption(time),
              placeholder: y === 0,
            }));
          return [minMaxData, overallMeanData, meanData, forecastData]; // order matters
        default:
          throw new Error(`Unsupported weather info chart: ${this.selectedChart}`);
      }
    },
    axisData() {
      const unit = this.unitOfAxis();
      const axisData = [];
      for (const iDate = this.minX.clone(); iDate.isSameOrBefore(this.maxX); iDate.add(1, unit)) {
        const isHighlight = this.isHighlight(iDate);
        if (isHighlight) {
          axisData.push({
            x: iDate.unix(),
            caption: this.axisLabel(iDate),
            highlight: isHighlight,
          });
        }
      }
      return axisData;
    },
    barChartMaxY() {
      return Math.max(...(this.weatherInfoMean?.data?.precipitation ?? []));
    },
    requestParamsActual() {
      const deadEnd = moment().add(13, 'days');
      return {
        aggregate: this.selectedAggregate,
        startDate: this.minX.format('YYYY-MM-DD'),
        endDate: this.maxX.isAfter(deadEnd) ? deadEnd.format('YYYY-MM-DD') : this.maxX.format('YYYY-MM-DD'),
        longitude: this.selectedField.lon,
        latitude: this.selectedField.lat,
        statistics: 'No',
        variables:
          this.selectedChart === 'Temperature2m'
            ? ['Temperature2mMin', this.selectedChart, 'Temperature2mMax']
            : [this.selectedChart],
        tempBaseCrop: this.baseTemperature,
        dateAccumulate: moment(this.accumulationDate).format('YYYY-MM-DD'),
      };
    },
    requestParamsMean() {
      return {
        aggregate: this.selectedAggregate,
        startDate: this.minX.format('YYYY-MM-DD'),
        endDate: this.maxX.format('YYYY-MM-DD'),
        longitude: this.selectedField.lon,
        latitude: this.selectedField.lat,
        statistics: 'MeanY5',
        variables: [this.selectedChart],
        tempBaseCrop: this.baseTemperature,
        dateAccumulate: moment(this.accumulationDate).format('YYYY-MM-DD'),
      };
    },
    weatherInfoActual() {
      const id = identifier(this.requestParamsActual);
      return this.$store.state.precisionFarming.monitoring.weather.data[id];
    },
    weatherInfoMean() {
      const id = identifier(this.requestParamsMean);
      return this.$store.state.precisionFarming.monitoring.weather.data[id];
    },
    metricUnit() {
      const [, weatherVariable] = this.inferWeatherVariables(this.selectedChart);
      return this.weatherInfoActual?.units?.[weatherVariable] ?? '';
    },
    chartTypes() {
      const charTypes = [
        {
          type: 'button',
          onClick: () => {
            this.selectedChart = 'Precipitation';
          },
          label: this.$t('Niederschlag'),
          active: this.selectedChart === 'Precipitation',
          rotateBy: 0,
        },
        {
          type: 'button',
          onClick: () => {
            if (this.selectedAggregate === 'Yearly') {
              this.selectedAggregate = 'Monthly';
            }
            this.selectedChart = 'Temperature2m';
          },
          label: this.$t('Temperatur'),
          active: this.selectedChart === 'Temperature2m',
          rotateBy: 1,
        },
        {
          type: 'button',
          onClick: () => {
            this.selectedAggregate = 'Daily';
            this.selectedChart = 'PrecipitationAccumulated';
          },
          label: this.$t('Kumulierter Niederschlag'),
          active: this.selectedChart === 'PrecipitationAccumulated',
          rotateBy: 2,
        },
        {
          type: 'button',
          onClick: () => {
            this.selectedAggregate = 'Daily';
            this.selectedChart = 'HeatUnit';
          },
          label: this.$t('Wärmesumme'),
          active: this.selectedChart === 'HeatUnit',
          rotateBy: 3,
        },
        {
          type: 'button',
          onClick: () => {
            this.selectedAggregate = 'Daily';
            this.selectedChart = 'SoilTemperatureSurface';
          },
          label: this.$t('Bodentemperatur'),
          active: this.selectedChart === 'SoilTemperatureSurface',
          rotateBy: 4,
        },
        {
          type: 'button',
          onClick: () => {
            this.selectedAggregate = 'Daily';
            this.selectedChart = 'SoilMoistureSurface';
          },
          label: this.$t('Bodenfeuchtigkeit'),
          active: this.selectedChart === 'SoilMoistureSurface',
          rotateBy: 5,
        },
      ];
      return this.rotateArrayBy(charTypes, this.rotateBy);
    },
  },
  methods: {
    format(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    rotateArrayBy(arr, times) {
      const newArr = [...arr];
      for (let i = 0; i < times; i += 1) {
        newArr.push(newArr.shift());
      }
      return newArr;
    },
    unitOfAxis() {
      switch (this.selectedAggregate) {
        case 'Daily':
        case 'Monthly':
          return 'days';
        case 'Yearly':
          return 'years';
        default:
          throw new Error(`Unsupported weather aggregate: ${this.selectedAggregate}`);
      }
    },
    inferWeatherVariables(selectedChart) {
      const firstChar = selectedChart.at(0);
      const rest = selectedChart.slice(1);
      return [
        `${firstChar.toLowerCase()}${rest}Min`,
        `${firstChar.toLowerCase()}${rest}`,
        `${firstChar.toLowerCase()}${rest}Max`,
      ];
    },
  },
  watch: {
    requestParamsActual(params) {
      this.$store.dispatch('precisionFarming/monitoring/weather/aggregate', params);
    },
    requestParamsMean(params) {
      this.$store.dispatch('precisionFarming/monitoring/weather/aggregate', params);
    },
  },
};
</script>

<style lang="css" scoped>
.weather-info {
  position: relative;
}

.weather-info__tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.weather-info__tabs > * {
  flex-shrink: 0;
}

.weather-info__caption {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 16px;
}

.weather-info--compact .weather-info__caption {
  margin-top: 10px;
  margin-bottom: -20px;
}

.weather-info__fieldname {
  text-align: right;
}

.weather-info__kpi span:nth-child(1) {
  font-size: 22px;
  font-weight: 600;
}

.weather-info__message {
  padding: 1rem 0;
  font-size: 12px;
}

.weather-info__caption--blurred,
.weather-info__chart--blurred,
.weather-info__period-switch--blurred {
  filter: blur(7px);
  pointer-events: none;
  user-select: none;
}

.loading {
  position: absolute;
  top: 30%;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20pt;
}
</style>
