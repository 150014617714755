import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';

import { BaseWorkflowStoreModuleState } from './types';

const actions: ActionTree<BaseWorkflowStoreModuleState, RootState> = {
  reset({ commit, state }) {
    Object.values(state.polygons).forEach((polygon) => {
      polygon.setMap(null);
    });
    commit('reset');
  },
  async setPaginationStep({ commit, dispatch, state }, step) {
    if (typeof state.paginationCallbackAsync === 'function') {
      const confirm = await state.paginationCallbackAsync(state.paginationStep, step);
      if (!confirm) {
        return;
      }
    }
    commit('setPaginationStep', step);
    dispatch('loadHeatmaps');
  },
  polygonSetState({ commit }, { key, state: polygonState }) {
    commit('polygonSetState', { key, state: polygonState });
  },
};

export default actions;
