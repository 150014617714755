import Handsontable from 'handsontable';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import { UserColumnSettings } from '@/shared/handsontable/rework/types';

import { removableStore, subscribableStore, syncableStore } from './common';
import { ActivitiesState } from './types';

const mutations: MutationTree<ActivitiesState> = {
  ...subscribableStore.mutations,
  ...syncableStore.mutations,
  ...removableStore.mutations,
  // ...restorableData.mutations,
  addOrUpdateTableDataMetadataByKeyAndValue(
    state,
    { activityId, key, value }: { activityId: string; key: string; value: any },
  ) {
    const metadata = { ...state.tableDataMetadata };
    metadata[activityId] = { ...metadata[activityId], [key]: value };
    state.tableDataMetadata = metadata;
  },
  removeActivity(state, activityId: string) {
    Vue.delete(state.data, activityId);
    Vue.delete(state.tableDataMetadata, activityId);
  },
  setHot(state, hot: Handsontable | null) {
    Vue.set(state, 'hot', hot);
  },
  setUserColumnSettings(state, userColumnSettings: Record<string, UserColumnSettings>) {
    Vue.set(state, 'userColumnSettings', userColumnSettings);
  },
};

export default mutations;
