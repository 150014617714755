/**
 *
 * @param {*} company
 * @returns Number
 */
export default function getDatabaseId(company) {
  const { databaseId } = company;

  if (typeof databaseId !== 'number') {
    throw new Error(`Missing company databaseId in current company: ${company}`);
  }

  return databaseId;
}
