import axios, { CancelTokenSource } from 'axios';
import { type ActivityRuleViolation, type TrackLiveCheckRequestData, TracksApi } from 'farmdok-rest-api';
import debounce from 'lodash.debounce';
import moment from 'moment';
import Vue from 'vue';
import { type ActionContext, ActionTree } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { RootState } from '@/store/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import { POLYGON_STATES } from '../../store/baseWorkflowStore/common';
import { ApplicationMapsSprayingState, SummaryData } from './types';

let multiPolySource: CancelTokenSource | null = null;

const actions: ActionTree<ApplicationMapsSprayingState, RootState> = {
  ...baseWorkflowStore.actions,
  // satellite images
  async setMultipolyTimestamp({ state, commit, getters }, polygonKey: string) {
    const polygon = state.polygons[polygonKey];
    const requestIdentifier = `${polygonKey}:${getters.coverageRatio}`;
    const multiPolyFromPolygon = state.multiPolyTimestamps.loaded[requestIdentifier];
    let multiPolyTimestamp = {};

    if (multiPolyFromPolygon) {
      multiPolyTimestamp = {
        key: requestIdentifier,
        data: multiPolyFromPolygon,
      };
    } else {
      const { coverageRatio } = getters;
      const timeStart = moment().startOf('day').subtract(1, 'year').unix();
      const timeEnd = moment().startOf('day').unix();
      const { data } = await axios.post('/admin/sen4/multiPolyTimestamps', {
        polygon: polygon.pathsForAxios,
        timeStart,
        timeEnd,
        coverageRatio,
      });
      multiPolyTimestamp = {
        key: requestIdentifier,
        data,
      };
      commit('addMultipolyTimestamp', multiPolyTimestamp);
    }

    if (
      Object.keys(state.multiPolyTimestamps.current)
        .map((key) => key.split(':')[0])
        .some((fieldId) => fieldId === polygonKey)
    ) {
      commit('unsetCurrentMultipolyTimestamp', `${polygonKey}:0`);
      commit('unsetCurrentMultipolyTimestamp', `${polygonKey}:2`);
    }

    commit('setCurrentMultipolyTimestamp', multiPolyTimestamp);

    const { timeArray } = getters.availableTimestamps;
    for (let i = 0; i < timeArray.length; i += 1) {
      if (state.selectedHeatmapTimestamp === timeArray[i]) {
        commit('setHeatmapTimestampSelectedIndex', i);
        break;
      }
    }
  },
  async loadHeatmaps(params) {
    await doLoadHeatmaps(params);
  },

  // fields
  async polygonSetState({ state, commit, dispatch }, { key, state: polygonState }) {
    commit('polygonSetState', { key, state: polygonState });
    if (polygonState === POLYGON_STATES.ACTIVE) {
      await dispatch('setMultipolyTimestamp', key);
    } else if (polygonState === POLYGON_STATES.INACTIVE) {
      commit('unsetCurrentMultipolyTimestamp', key);
    }
    if (state.selectedHeatmapDbId == null && Object.values(state.multiPolyTimestamps.current).length > 0) {
      commit('selectHeatmapDbId', Object.values(state.multiPolyTimestamps.current)[0].availableData[1].dbId);
    }
  },

  setHeatmapInformation({ commit, dispatch }, { dbId, timestamp }) {
    commit('selectHeatmapDbId', dbId);
    commit('setHeatmapTimestamp', timestamp);

    if (dbId && timestamp) {
      dispatch('loadHeatmaps');
    }
  },

  updateOverwrite({ commit }, { summaryData }: { summaryData: SummaryData }): void {
    commit('setOverwrite', summaryData);
  },

  resetOverwrite({ commit }): void {
    commit('setOverwrite', {
      reducedSprayMixPerZone: [],
      reducedVegetationPerZone: [],
    });
  },

  async liveCheck(
    context: ActionContext<ApplicationMapsSprayingState, RootState>,
    track: TrackLiveCheckRequestData['track'],
  ): Promise<ActivityRuleViolation[]> {
    const { tracksApi } = Vue.prototype.$api as Api;

    context.commit('setCheckingViolation', true);
    return doLiveCheck(context, tracksApi, track)
      .then((r) => r.data.data ?? [])
      .then((violations) => {
        context.commit('setViolations', violations);
        return violations;
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async setTask({ commit, rootState, dispatch, state, getters, rootGetters }, task) {
    await Promise.all([
      dispatch('auth/subscribe', undefined, { root: true }),
      dispatch('fields/subscribe', undefined, { root: true }),
    ]);
    commit('setTask', task);

    if (!task.applicationMap || !task.applicationMap.additionalData) {
      return;
    }

    if (Array.isArray(task.applicationMap.additionalData.fields)) {
      const selectedFields = task.applicationMap.additionalData.fields
        // @ts-ignore
        .filter(({ id: guid }) => !!rootState.fields.data[guid]);
      commit('setSelectedFields', selectedFields ?? []);
    }

    if (Array.isArray(task.fields)) {
      const selectedFields = task.fields
        // @ts-ignore // TODO fix this
        .map((taskField) => taskField.field.guid)
        .filter((guid: string) => !!rootState.fields.data[guid]);
      commit('setSelectedFields', selectedFields ?? []);
    }

    if (task.applicationMap.additionalData.selectedIndexType != null) {
      commit('setSelectedIndexType', task.applicationMap.additionalData.selectedIndexType);
    }

    if (task.applicationMap.additionalData.selectedHeatmapDbId != null) {
      commit('selectHeatmapDbId', task.applicationMap.additionalData.selectedHeatmapDbId);
    }

    if (task.applicationMap.additionalData.selectedHeatmapTimestamp != null) {
      commit('setHeatmapTimestamp', task.applicationMap.additionalData.selectedHeatmapTimestamp);
    }

    if (task.applicationMap.additionalData.selectedQuantisationCode != null) {
      commit('setSelectedQuantisationCode', task.applicationMap.additionalData.selectedQuantisationCode);
    }

    if (task.applicationMap.geoJson != null) {
      commit('setHeatmaps', task.applicationMap.geoJson);
    }

    commit('setPaginationStep', 2);

    if (!task.applicationMap.additionalData.calculation) {
      return;
    }

    const { calculation } = task.applicationMap.additionalData;
    commit('setOverwrite', calculation.overwrite);
    commit('setSprayMix', calculation.sprayMix);
    commit('setReduction', calculation.reduction);
    commit('setPlantProtections', calculation.products);
  },
};

/**
 * Debounced function to avoid subsequent requests, if loadHeatmaps is dispatched several times after one another
 */
const doLoadHeatmaps = debounce(
  async ({ state, commit, getters }: ActionContext<ApplicationMapsSprayingState, RootState>) => {
    if (multiPolySource != null) {
      multiPolySource.cancel();
    }

    const {
      selectedFields,
      selectedHeatmapTimestamp: satelliteTimestamp,
      polygons,
      selectedIndexType,
      selectedQuantisationCode,
      heatmaps,
      multiPolyTimestamps,
    } = state;
    const satelliteMoment = moment.unix(satelliteTimestamp);

    const productIds: string[] = selectedFields
      .filter((guid: string) => !!polygons[guid])
      .filter((guid: string) => {
        const clientId = getters.toClientId(guid);
        return !heatmaps.current[clientId];
      });

    if (productIds.length !== selectedFields.length) {
      return;
    }

    const products = productIds.map((guid: string) => {
      const polygon = polygons[guid].pathsForAxios;
      const key = `${guid}:${getters.coverageRatio}`;
      const dbId =
        multiPolyTimestamps.loaded[key]?.availableData?.find((ts: { dbId: string; timestamp: number }) =>
          moment.unix(ts.timestamp).isSame(satelliteMoment, 'day'),
        )?.dbId ?? '';

      const clientId = [guid, satelliteTimestamp, selectedIndexType, selectedQuantisationCode].join('_');
      const fieldKey = guid;
      return selectedIndexType.includes('DNN_')
        ? {
            timestamp: satelliteTimestamp,
            polygon,
            clientId,
            fieldKey,
          }
        : {
            dbId,
            polygon,
            clientId,
            fieldKey,
          };
    });

    multiPolySource = axios.CancelToken.source();
    let data: any = null;
    state.heatmaps.fetching = true;
    try {
      ({ data } = await axios.post(
        '/admin/sen4/multiPoly',
        {
          products,
          indexType: selectedIndexType,
          configSet: 'SET_A2',
          quantisationCode: selectedQuantisationCode,
        },
        { cancelToken: multiPolySource.token },
      ));
    } catch (e) {
      return;
    } finally {
      state.heatmaps.fetching = false;
    }
    multiPolySource = null;

    const newHeatmaps = {
      ...heatmaps.current,
      ...data.products,
    };

    commit('setHeatmaps', newHeatmaps);
  },
  100,
  { leading: false, trailing: true },
);

/**
 * Debounced function to avoid subsequent requests to execute live check on backend. Invokes the debounce call as soon as the function is called, debouncing subsequent calls.
 */
const doLiveCheck = debounce(
  async (
    { commit }: ActionContext<ApplicationMapsSprayingState, RootState>,
    tracksApi: TracksApi,
    track: TrackLiveCheckRequestData['track'],
  ) =>
    tracksApi
      .trackLiveCheck({
        trackLiveCheckRequest: {
          data: {
            track,
          },
        },
      })
      .finally(() => commit('setCheckingViolation', false)),
  5000,
  {
    leading: true,
    trailing: true,
    maxWait: 5000,
  },
);

export default actions;
