<template>
  <div>
    <div
      class="form-upload-zone-files"
      :class="{ 'form--is-dragover': isDragover }"
      @drag.prevent
      @dragstart.prevent
      @dragend.prevent="isDragover = false"
      @dragover.prevent="isDragover = true"
      @dragenter.prevent="isDragover = true"
      @dragleave.prevent="isDragover = false"
      @drop.prevent="onFileDrop"
    >
      <label class="position-absolute">
        <input
          ref="input"
          class="input"
          type="file"
          :accept="
            supportedFileFormats
              .map((format) => format.fileEnding)
              .map((ending) => `.${ending}`)
              .join(',')
          "
          @change="onFileChange"
        />
      </label>

      <EmptyState
        v-if="!errorMessage && (!uploadedZonesByFilename || Object.keys(uploadedZonesByFilename).length < 1)"
        :supportedFileFormats="supportedFileFormats"
        :maxFileSize="maxFileSize"
        @onEmptyFormClick="onEmptyFormClick"
      />

      <div v-else class="form-files-content">
        <ErrorUserMessage class="mx-3" :errorUserMessage="errorMessage" />
        <FilesList :fileGroups="filesList" />
        <FilesListButtons @onAddAdditionalFiles="onEmptyFormClick" @onReset="onReset" :uploadVisible="false" />
      </div>
    </div>

    <ModalAskRateKey ref="modalAskRateKey" />
    <ModalAskRateValue ref="modalAskRateValue" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import area from '@turf/area';
import { polygon } from '@turf/helpers';
import intersect from '@turf/intersect';
import { mapGetters, mapState } from 'vuex';

import { combinedNameExclCrop } from '@/fields/handsontable/columns/columns';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import EmptyState from '@/shared/components/filesUpload/EmptyState.vue';
import FilesList from '@/shared/components/filesUpload/FilesList.vue';
import FilesListButtons from '@/shared/components/filesUpload/FilesListButtons.vue';

import ModalAskRateKey from './ModalAskRateKey.vue';
import ModalAskRateValue from './ModalAskRateValue.vue';

library.add(faTimesCircle);

export default {
  name: 'FormUploadZoneFiles',
  components: {
    ModalAskRateKey,
    ModalAskRateValue,
    EmptyState,
    FilesList,
    FilesListButtons,
    ErrorUserMessage,
  },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: null,
      file: null,
      supportedFileFormats: [{ fileEnding: 'zip', displayName: 'Shape' }],
      maxFileSize: 10,
      isDragover: false,
    };
  },
  computed: {
    ...mapGetters({
      fields: 'fields',
    }),
    ...mapState({
      selectedFields(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].selectedFields;
      },
      uploadedZonesByFilename(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/uploadedZonesByFilename`];
      },
      zones(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/zones`];
      },
    }),

    filesList() {
      if (!this.uploadedZonesByFilename) {
        return [];
      }
      return Object.keys(this.uploadedZonesByFilename).map((file) => ({
        file: {
          name: file,
        },
        errors: [],
      }));
    },
  },
  created() {
    this.$store.commit(
      `precisionFarming/applicationMaps/${this.workflowKey}/setPaginationCallbackAsync`,
      (previousStep, nextStep) =>
        new Promise((resolve) => {
          if (previousStep !== 2 || nextStep !== 3) {
            resolve(true);
          } else {
            this.onNavigateNext(resolve);
          }
        }),
    );
  },
  beforeDestroy() {
    this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setPaginationCallbackAsync`, null);
  },
  methods: {
    onEmptyFormClick() {
      this.$refs.input.click();
    },

    onFileChange(event) {
      this.addFiles(event.target.files);
    },

    async addFiles(files) {
      Array.from(files).forEach((file) => this.importZonesFromFile(file));
    },

    onFileDrop(event) {
      this.isDragover = false;
      this.addFiles(event.dataTransfer.files);
    },

    onReset() {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setUploadedZonesByFilename`, {});
    },

    async importZonesFromFile(file) {
      if (file == null) {
        return;
      }

      this.errorMessage = null;
      if (file.name.substr(-3) !== 'zip' || !file.type.includes('zip')) {
        this.errorMessage = this.$t('Derzeit werden nur Zip-Dateien unterstützt.');
        return;
      }

      // read zip that contains a shp
      const { default: shp } = await import('shpjs');
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.readyState !== 2 || reader.error) {
          return;
        }
        try {
          let geoJson = await shp(reader.result);
          if (geoJson == null) {
            this.errorMessage = this.$t('Keine Shape-Datei im Zip gefunden.');
            return;
          }
          if (!Array.isArray(geoJson)) {
            geoJson = [geoJson];
          }

          await Promise.all(
            geoJson.map(async (currentGeoJson) => {
              const geoJsonWithProps = await this.addPropertiesToFeatures(currentGeoJson);
              if (geoJsonWithProps == null) {
                return;
              }
              this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/uploadedZoneFilesAddGeoJson`, {
                filename: geoJsonWithProps.fileName,
                geoJson: geoJsonWithProps,
              });
            }),
          );
        } catch (error) {
          console.error(error);
          this.errorMessage = this.$t('Keine Shape-Datei im Zip gefunden.');
        }
      };
      reader.readAsArrayBuffer(file);
    },

    async addPropertiesToFeatures(geoJson) {
      const rateKeys = new Set();
      geoJson.features.forEach((feature) => {
        if (feature.properties == null) {
          return;
        }
        Object.keys(feature.properties).forEach((key) => rateKeys.add(key));
      });
      let rateKey = 'RATE';
      if (rateKeys.size === 1) {
        [rateKey] = [...rateKeys];
      } else if (rateKeys.size > 1) {
        rateKey = await this.$refs.modalAskRateKey.show([...rateKeys]);
      }
      if (rateKey == null) {
        return null;
      }

      const fieldIdsWithOverlap = new Set();
      geoJson.features.forEach((feature) => {
        this.selectedFields.forEach((guid) => {
          const fieldPolygon = polygon(this.fields[guid].fieldContour.geoJson.coordinates);
          if (intersect(feature, fieldPolygon) != null) {
            fieldIdsWithOverlap.add(guid);
          }
        });
      });

      const rates = geoJson.features.map((feature) => {
        if (
          feature.properties == null ||
          feature.properties[rateKey] == null ||
          Number.isNaN(Number(feature.properties[rateKey]))
        ) {
          return 0;
        }
        return Number(feature.properties[rateKey]);
      });
      const rateMin = Math.min(...rates);
      const rateMax = Math.max(...rates);
      return {
        ...geoJson,
        features: geoJson.features.map((currentFeature) => {
          const properties = currentFeature.properties || {};
          let RATE = 0;
          if (!Number.isNaN(Number(properties[rateKey]))) {
            RATE = Number(properties[rateKey]);
          }
          const feature = {
            ...currentFeature,
            properties: {
              ...properties,
              RATE,
              'fill-opacity': 1,
              'stroke-opacity': 1,
              'stroke-width': 1,
              size: area(currentFeature),
            },
          };
          let color = 'white';
          if (feature.properties.fill != null) {
            color = feature.properties.fill;
          } else if (feature.properties[rateKey] != null) {
            const hue = (300 * (feature.properties[rateKey] - rateMin)) / (rateMax - rateMin);
            color = `hsl(${hue}, 100%, 50%)`;
          }
          feature.properties.fill = color;
          feature.properties.stroke = color;
          return feature;
        }),
        properties: {
          fieldIds: [...fieldIdsWithOverlap],
        },
      };
    },
    async onNavigateNext(resolve) {
      const fieldIdsWithOverlap = new Set();
      Object.values(this.uploadedZonesByFilename).forEach((zone) => {
        if (zone.properties != null && Array.isArray(zone.properties.fieldIds)) {
          zone.properties.fieldIds.forEach((fieldId) => {
            fieldIdsWithOverlap.add(fieldId);
          });
        }
      });
      if (this.selectedFields.every((guid) => fieldIdsWithOverlap.has(guid))) {
        resolve(true);
        return;
      }
      const rateValue = await this.$refs.modalAskRateValue.show(this.zones.map((zone) => zone.rate));
      if (rateValue != null) {
        this.createZonesForFields(
          this.selectedFields.filter((guid) => ![...fieldIdsWithOverlap].includes(guid)),
          rateValue,
        );
      }
      resolve(rateValue != null);
    },
    createZonesForFields(fieldsIds, rateValue) {
      let color = null;
      this.zones.some((zone) => {
        if (zone.rate === rateValue) {
          ({ color } = zone);
          return true;
        }
        return false;
      });
      const filenames = [];
      fieldsIds.forEach((guid) => {
        let filename = combinedNameExclCrop.data.call(this, this.fields[guid]);
        if (filenames.includes(filename)) {
          let x = '2';
          while (filenames.includes(`${filename} (${x})`)) {
            x += 1;
          }
          filename = `${filename} (${x})`;
        }
        filenames.push(filename);
        const polygonFeature = polygon(this.fields[guid].fieldContour.geoJson.coordinates);
        polygonFeature.properties = {
          RATE: rateValue,
          fill: color,
          'fill-opacity': 1,
          stroke: color,
          'stroke-opacity': 1,
          'stroke-width': 1,
          size: area(polygonFeature),
        };
        const featureCollection = {
          features: [polygonFeature],
          type: 'FeatureCollection',
          properties: {
            fieldIds: [guid],
          },
        };
        this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/uploadedZoneFilesAddGeoJson`, {
          filename,
          geoJson: featureCollection,
        });
      });
    },
  },
};
</script>

<style scoped>
.form-upload-zone-files {
  display: flex;
  width: 100%;
  border: 1px dashed var(--gray_600);
  border-radius: 3px;
  text-align: left;
}

.form-files-content {
  width: 100%;
}

.form--is-dragover {
  border-color: var(--primary_dark);
  background-color: var(--primary_light);
}

.input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
