import { Activity, ActivityProduct } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import Vue from 'vue';

import ActivityProductService from '@/activities/services/ActivityProductService';
import { createUuid } from '@/shared/modules/uuid';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../../types';
import initServices from './initServices';

export default function updateValuesOnActivityProductInAllActivities(
  state: CreateEditActivityState,
  data: {
    activityProductId: string;
    updateValues: Partial<ActivityProduct>;
    rootState: RootState; // TODO remove this hack and mv mutation to action
  },
): void {
  const { activityProductService, findService } = initServices(state, data.rootState);
  const originalActivityProduct = _cloneDeep(findService.findActivityProduct(data.activityProductId));
  if (!originalActivityProduct) return;

  state.activities.forEach((activity) => {
    const family = getFamily(activity, originalActivityProduct, activityProductService);

    if (family.length > 0) {
      updateProducts(family, data.updateValues);
    } else {
      addNewCopy(activity, originalActivityProduct, data.updateValues);
    }
  });
}

export function getFamily(
  activity: Activity,
  activityProduct: ActivityProduct,
  activityProductService: ActivityProductService,
) {
  return activity.products.filter(
    (product) => product.id === activityProduct.id || activityProductService.isSibling(product, activityProduct),
  );
}

export function updateProducts(products: ActivityProduct[], updateValues: Partial<ActivityProduct>) {
  products.forEach((p) => {
    updateProduct(p, updateValues);
  });
}

function updateProduct(product: ActivityProduct, updateValues: Partial<ActivityProduct>) {
  Object.entries(updateValues).forEach(([key, value]) => {
    if (key !== 'id') {
      Vue.set(product, key, value);
    }
  });
}

export function addNewCopy(
  activity: Activity,
  activityProduct: ActivityProduct,
  updateValues: Partial<ActivityProduct>,
): ActivityProduct {
  const clone = { ...activityProduct, id: createUuid(), ...updateValues };
  activity.products.push(clone);

  return clone;
}
