
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDrawPolygon } from '@fortawesome/pro-solid-svg-icons';
import { PropType, defineComponent } from 'vue';

library.add(faDrawPolygon);

export default defineComponent({
  name: 'MapUtilityButton',
  props: {
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.$emit('button:pressed', this.active);
    },
  },
});
