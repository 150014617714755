<template>
  <div class="overlay bg-white shadow" :class="{ 'overlay--folded': overlay.folded }">
    <div class="header my-4 mx-3">
      <slot name="header"></slot>
      <div>
        <BButton variant="white" class="mr-1" @click="toggleOverlay()">
          <FontAwesomeIcon v-if="!overlay.folded" :icon="['far', 'chevron-up']" />
          <FontAwesomeIcon v-if="overlay.folded" :icon="['far', 'chevron-down']" />
        </BButton>
        <BButton variant="white" @click="close()">
          <FontAwesomeIcon :icon="['far', 'times']" />
        </BButton>
      </div>
    </div>
    <slot name="main"></slot>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { mapGetters } from 'vuex';

library.add(faChevronDown, faChevronUp, faTimes);

export default {
  name: 'MonitoringOverlay',
  data() {
    return {
      widthNoScrollbars: null,
      widthScrollbars: null,
    };
  },
  methods: {
    close() {
      // TODO: use vuexConnection
      this.$store.dispatch('precisionFarming/monitoring/closeOverlay');
    },
    toggleOverlay() {
      this.$store.commit('precisionFarming/monitoring/toggleOverlayFold');
    },
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        this.close();
      }
    });
  },
  computed: {
    ...mapGetters({
      overlay: 'precisionFarming/monitoring/overlay',
    }),
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 9px;
  right: 9px;
  width: 615px;
  max-height: calc(100 * var(--vh, 1vh) - 150px);
  z-index: 10;
  overflow-y: scroll;
  overflow-x: hidden;
}
.overlay--folded {
  overflow: visible;
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loading {
  padding: 20px;
  text-align: center;
  font-size: 2rem;
}
</style>
