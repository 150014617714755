
import { BFormSelect } from 'bootstrap-vue';
import { PropType, defineComponent } from 'vue';

import BaseFormField from './BaseFormField.vue';
import { SelectItem } from './formFieldSelectTypes';

/**
 * Renders a BFormSelect inside a BFormGroup. Ideally placed within a FieldSet.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <div style="padding: 10px;">
 *   <FormFieldSelect
 *     value="a"
 *     label="Select"
 *     :options="[{ value: 'a', text: 'Item 1' }, { value: 'b', text: 'Item 2' }, { value: 'c', text: 'Item 3' }]"
 *   />
 *   <FormFieldSelect
 *     variant="vertical"
 *     value="b"
 *     label="Select Vertical"
 *     :options="[{ value: 'a', text: 'Item 1' }, { value: 'b', text: 'Item 2' }, { value: 'c', text: 'Item 3' }]"
 *   />
 * </div>
 */
export default defineComponent({
  name: 'FormFieldSelect',
  components: { BFormSelect, BaseFormField },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    /**
     * Id set for input. If not set a unique id is generated as bootstrap places the label next to the input.
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * Adds the `required` attribute to the form control.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Label rendered above or to the left, depending on the variant.
     * Required if variant is not 'no-label'.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Supported types:
     *  <table style="width:30%;">
     *    <tr><td>'horizontal'</td><td>Label to the left.</td></tr>
     *    <tr><td>'vertical'</td><td>Label above. Increased margin bottom.</td></tr>
     *    <tr><td>'vertical-lg'</td><td>Label above. Increases margin bottom and input paddings.</td></tr>
     *    <tr><td>'no-label'</td><td>Do not show a label.</td></tr>
     * </table>
     */
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value: string) => ['horizontal', 'vertical', 'vertical-lg', 'no-label'].includes(value),
    },
    /**
     * If set to `true` or `false` the input will be highlighted with colors and icons, depending on the state.<br>
     * Default `null`.
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * Array of items to render in the component.<br>
     * <pre>
     *   [{ value: 'a', text: 'Item 1' }, { value: 'b', text: 'Item 2' }]
     * </pre>
     */
    options: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
    /**
     * V-model.
     */
    value: {
      type: [String, Number] as PropType<string | number>,
      default: null,
    },
    /**
     * Disable select.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to `true` the following props will be overwritten:
     *   - options get extended with 'various'
     *   - value with 'various'
     */
    various: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    optionsComputed(): SelectItem[] {
      if (!this.various) {
        return this.options;
      }
      return [{ text: this.$t('(verschiedene)') || 'verschiedene', value: 'various', disabled: true }, ...this.options];
    },
    valueComputed(): string | number {
      if (this.various) {
        return 'various';
      }
      return this.value;
    },
  },
  methods: {
    style(iconCount: number) {
      let additionalPadding: string | number = 0;
      if (this.variant === 'vertical-lg') {
        additionalPadding = '10px';
      }
      if (iconCount > 0) {
        return { paddingRight: `calc(${iconCount} * (16px + 1.2rem) + ${additionalPadding})` };
      }
      return null;
    },
    /**
     * Emitted when the select value changes via user interaction.
     *
     * @event FormFieldSelect#change
     * @type {String} value
     */
    change(value: SelectItem) {
      this.$emit('change', value);
    },
    /**
     * Emitted when the select value changes. Emitted to update the v-model.
     *
     * @event FormFieldSelect#update
     * @type {String} value
     */
    update(value: SelectItem) {
      this.$emit('update', value);
    },
  },
});
