import _get from 'lodash.get';
import _isEqual from 'lodash.isequal';

type IS_EQUAL = 'IS_EQUAL';

export default function differenceObjectByKeys(
  oldObject: Object,
  newObject: Object,
  compareKeys: string[],
): Record<string, any> {
  const differences: Record<string, any> = {};

  compareKeys.forEach((key) => {
    const difference = compare(oldObject, newObject, key);
    if (difference !== 'IS_EQUAL') {
      differences[key] = difference;
    }
  });

  return differences;
}

function compare(oldObject: Object, newObject: Object, key: string): any | IS_EQUAL {
  const oldValue = _get(oldObject, key);
  const newValue = _get(newObject, key);
  if (oldValue === newValue) return 'IS_EQUAL'; // shallow comparison
  if (_isEqual(oldValue, newValue)) return 'IS_EQUAL'; // deep comparison

  return newValue;
}
