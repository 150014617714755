var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{staticClass:"weather-info p-3",class:`weather-info--${_vm.variant}`},[_c('div',{staticClass:"weather-info__tabs border-bottom border-medium"},[_c('Tabs',{attrs:{"tabs":_vm.chartTypes.slice(0, 3),"size":_vm.variant === 'widget' ? 'lg' : 'md',"fill":true}}),_c('BDropdown',{staticClass:"m-0 p-0",attrs:{"size":"md","text":_vm.$t('Mehr'),"variant":"outline"}},_vm._l((_vm.chartTypes.slice(3)),function(chartType){return _c('BDropdownItem',{key:chartType.label,on:{"click":() => {
            _vm.rotateBy = chartType.rotateBy;
            chartType.onClick();
          }}},[_vm._v(_vm._s(chartType.label)+" ")])}),1)],1),(_vm.showWeatherInfo)?_c('div',{staticClass:"weather-info__caption",class:{ 'weather-info__caption--blurred': !_vm.enabled }},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.metricUnit))])]),_c('div',[(_vm.variant === 'compact')?_c('div',{staticClass:"weather-info__fieldname"},[_vm._v(" "+_vm._s(_vm.$t('Wetter für:'))+" "+_vm._s(this.selectedField?.name)+" ")]):_vm._e(),(_vm.aggregates.length > 1)?_c('WeatherPeriodSwitch',{attrs:{"periods":_vm.aggregates},model:{value:(_vm.selectedAggregate),callback:function ($$v) {_vm.selectedAggregate=$$v},expression:"selectedAggregate"}}):_vm._e()],1)]):_vm._e(),(_vm.showWeatherInfo)?_c('div',{staticClass:"weather-info__chart",class:{ 'weather-info__chart--blurred': !_vm.enabled }},[(_vm.selectedChart === 'Precipitation')?_c('BarChart',{attrs:{"chartData":_vm.chartData,"showMean":_vm.selectedAggregate === 'Monthly' || _vm.selectedAggregate === 'Yearly',"maxY":_vm.barChartMaxY,"axisData":_vm.axisData,"missingDataMessage":_vm.$t('Keine Wetterdaten für den ausgewählten Zeitraum verfügbar.')},on:{"prev":_vm.previousDate,"restore":_vm.resetDate,"next":_vm.nextDate}}):_vm._e(),(
        [
          'Temperature2m',
          'HeatUnit',
          'PrecipitationAccumulated',
          'SoilTemperatureSurface',
          'SoilMoistureSurface',
        ].includes(_vm.selectedChart)
      )?_c('LineChart',{attrs:{"chartData":_vm.chartData,"minX":_vm.minX.unix(),"maxX":_vm.maxX.unix(),"minY":0,"axisData":_vm.axisData,"missingDataMessage":_vm.$t('Keine Satellitendaten für den ausgewählten Zeitraum verfügbar.')},on:{"prev":_vm.previousDate,"restore":_vm.resetDate,"next":_vm.nextDate}}):_vm._e()],1):_vm._e(),(!_vm.enabled)?_c('FeatureNotAvailable',{staticClass:"mt-3",attrs:{"no-back-link":""}}):_vm._e(),_c('FormFieldSetBordered',{staticClass:"overflow-hidden",attrs:{"headline":`${_vm.$t('Wetterdiagramm')} ${_vm.$t('Einstellungen')}`,"labelLinkOpen":_vm.$t('Einstellungen anzeigen'),"labelLinkClose":_vm.$t('Einstellungen verstecken')},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('Startdatum'))+": "+_vm._s(_vm.format(_vm.accumulationDate)))]),(_vm.isMainSeeding)?_c('span',[_vm._v(" ("+_vm._s(_vm.$t('Saatdatum'))+")")]):_vm._e(),_vm._v(" , "),_c('span',[_vm._v(_vm._s(_vm.$t('Basistemperatur'))+": "+_vm._s(_vm.baseTemperature)+"°C ")]),(_vm.baseTemperature === 8)?_c('span',[_vm._v(" ("+_vm._s(_vm.$t('Mais'))+")")]):_vm._e()]},proxy:true}],null,false,3148687157)},[_c('FormFieldDatepicker',{staticClass:"mb-2",attrs:{"variant":"horizontal","placeholder":_vm.$t('Wert'),"label":_vm.$t('Startdatum'),"description":_vm.$t(
          // eslint-disable-next-line max-len
          'Das Startdatum ist standardmäßig das Aussaatdatum der Maßnahme oder alternativ der Jahresbeginn. Es ist relevant für die Wärmesumme und die Niederschlagssumme.',
        )},model:{value:(_vm.accumulationDate),callback:function ($$v) {_vm.accumulationDate=$$v},expression:"accumulationDate"}}),_c('FormFieldInput',{staticClass:"mb-2",attrs:{"variant":"horizontal","type":"number","placeholder":_vm.$t('Wert'),"label":`${_vm.$t('Basistemperatur')} °C`,"description":_vm.$t(
          // eslint-disable-next-line max-len
          'Basistemperatur ist relevant für die Wärmesumme. Es wird angenommen, dass unter ihr kein Wachstum stattfindet.',
        ),"required":"","lazy":false,"step":1,"min":-10,"max":50},model:{value:(_vm.baseTemperature),callback:function ($$v) {_vm.baseTemperature=$$v},expression:"baseTemperature"}})],1),(_vm.showUnknownErrorMessage)?_c('div',{staticClass:"weather-info__message weather-info__message--error"},[_c('p',[_vm._v(_vm._s(_vm.$t('Beim Laden der Wetterdaten ist ein Fehler aufgetreten.')))])]):_vm._e(),(_vm.showEmptyWeatherDataMessage)?_c('div',{staticClass:"weather-info__message weather-info__message--error"},[_c('p',[_vm._v(_vm._s(_vm.$t('Leider können für dieses Feld keine Wetterdaten geladen werden.')))])]):_vm._e(),(_vm.showMissingGeoDataMessage)?_c('div',{staticClass:"weather-info__message weather-info__message--error"},[_c('p',[_vm._v(_vm._s(_vm.$t('Für das ausgewählte Feld fehlen die Geo-Daten.')))])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading"},[_c('FontAwesomeIcon',{staticClass:"text-primary",attrs:{"icon":"circle-notch","spin":""}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }