
import { defineComponent } from 'vue';

import EquipmentSelectSectionsContainer from '../containers/EquipmentSelectSectionsContainer.vue';
import FavouriteProductsSectionContainer from '../containers/FavouriteProductsSectionContainer.vue';
import ProductDetailsContainer from '../containers/ProductDetailsContainer.vue';
import ProductSelectSectionContainer from '../containers/ProductSelectSectionContainer.vue';
import AddSectionButton from './AddSectionButton.vue';

export default defineComponent({
  name: 'ThirdStep',
  components: {
    AddSectionButton,
    ProductSelectSectionContainer,
    EquipmentSelectSectionsContainer,
    ProductDetailsContainer,
    FavouriteProductsSectionContainer,
  },
  methods: {
    addActivityProduct() {
      this.$emit('addActivityProduct');
    },
  },
});
