export default {
  data() {
    return {
      tableHeaderElementsByVisualCol: {},
      tableHeaderTooltips: [],
    };
  },
  mounted() {
    this.addHotHook('afterGetColHeader', this.tableHeaderTooltipsAfterGetColHeader);
    this.addHotHook('afterRender', this.tableHeaderTooltipsAfterRender);
  },
  methods: {
    tableHeaderTooltipsAfterGetColHeader(visualCol, th) {
      this.tableHeaderElementsByVisualCol[visualCol] = { visualCol, th };
    },
    async tableHeaderTooltipsAfterRender() {
      this.tableHeaderTooltips = [];
      // we need bootstrap to fully remove the BTooltips and them re apply them so the targets don't get messed up
      await this.$nextTick();
      if (this.hot == null) {
        return;
      }
      const hiddenColumns = this.hot.getPlugin('hiddenColumns').getHiddenColumns();
      const tableHeaderTooltips = [];
      Object.values(this.tableHeaderElementsByVisualCol).forEach(({ visualCol, th }) => {
        if (hiddenColumns.includes(visualCol)) {
          return;
        }
        const physicalCol = this.hot.toPhysicalColumn(visualCol);
        const column = this.tableSettingsComputed.columns[physicalCol];
        if (column == null || column.header == null) {
          return;
        }
        const target = th.querySelector('.colHeader');
        if (target == null) {
          return;
        }
        tableHeaderTooltips.push({
          key: column.key,
          header: column.header,
          target,
          physicalCol,
          visualCol,
        });
      });
      this.tableHeaderTooltips = tableHeaderTooltips;
    },
  },
};
