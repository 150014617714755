import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';

import { subscribableStore, syncableStore } from './common';
import { DaturaAssessmentState } from './types';

const actions: ActionTree<DaturaAssessmentState, RootState> = {
  ...subscribableStore.actions,
  ...syncableStore.actions,
};

export default actions;
