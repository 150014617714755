import { MutationTree } from 'vuex';

import { RuleCheckState } from './types';

const mutations: MutationTree<RuleCheckState> = {
  showRuleViolationsModal(state, activityId: string) {
    state.showRuleViolationModalForActivity = activityId;
  },
  hideRuleViolationsModal(state) {
    state.showRuleViolationModalForActivity = null;
  },
};

export default mutations;
