import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { ActivityRuleViolationsState } from './types';

export type Getters = {
  loading: (state: ActivityRuleViolationsState) => boolean;
};

const moduleGetters: GetterTree<ActivityRuleViolationsState, RootState> = {
  loading: (state) => state.fetching,
};

export default moduleGetters;
