<template>
  <BaseFormField slim-margin :label="label" :variant="variant">
    <span class="form-field-read-only__text">
      {{ value }}
    </span>
  </BaseFormField>
</template>

<script>
import BaseFormField from './BaseFormField.vue';

export default {
  name: 'FormFieldReadOnly',
  components: { BaseFormField },
  props: {
    /**
     * Value to render.
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * Label rendered above or to the left, depending on the variant.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Supported types:
     *  <table style="width:30%;">
     *    <tr><td>'horizontal'</td><td>Label to the left.</td></tr>
     *    <tr><td>'vertical'</td><td>Label above. Increased margin bottom.</td></tr>
     *    <tr><td>'vertical-lg'</td><td>Label above. Increases margin bottom and input paddings.</td></tr>
     * </table>
     */
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical', 'vertical-lg'].includes(value),
    },
  },
};
</script>

<style scoped>
.form-field-read-only__text {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--black);
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 4px;
}

.form-field-read-only__text:hover {
  border-color: var(--lightest);
  background-color: var(--lightest);
}
</style>
