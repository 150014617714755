import Handsontable from 'handsontable';

import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';

import getFirstRowOfSubtable from '../../features/nestedTable/utils/getFirstRowOfSubtable';
import getLastRowOfSubtable from '../../features/nestedTable/utils/getLastRowOfSubtable';
import { ColumnSettingsCollapsedSubtable } from './types';

export default function CollapsedSubtableRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: DropdownItem | null,
  cellProperties: ColumnSettingsCollapsedSubtable,
  ...args: unknown[]
) {
  const values = getAllValuesOfSubtableColumn(visualRow, visualCol, instance);
  const valuesWithoutHotButton = values.filter((v) => v !== 'HOT_DISPLAY_BUTTON_RENDERER') as DropdownItem[];
  const displayValue = cellProperties.collapsedSubtable.getRendererValue({
    values: valuesWithoutHotButton,
    visualRow,
    instance,
  });

  // @ts-ignore
  Handsontable.renderers.BaseRenderer(instance, TD, visualRow, visualCol, prop, displayValue, cellProperties, ...args);

  const wrapper = document.createElement('button');

  const textWrapper = document.createElement('span');
  textWrapper.innerText = displayValue;
  textWrapper.classList.add('text-wrapper');

  wrapper.appendChild(textWrapper);

  const indicator = document.createElement('span');
  indicator.innerText = valuesWithoutHotButton.length.toString();
  indicator.classList.add('indicator');

  wrapper.appendChild(indicator);

  // eslint-disable-next-line no-param-reassign
  TD.innerHTML = '';
  TD.classList.add('collapsed-subtable-renderer');
  TD.appendChild(wrapper);
}

export function getAllValuesOfSubtableColumn(
  visualRow: number,
  visualCol: number,
  instance: Handsontable,
): Array<'HOT_DISPLAY_BUTTON_RENDERER' | DropdownItem> {
  const values: Array<'HOT_DISPLAY_BUTTON_RENDERER' | DropdownItem> = [];

  const firstRow = getFirstRowOfSubtable(visualRow, instance);
  const lastRow = getLastRowOfSubtable(visualRow, instance);

  for (let currentRow = firstRow; currentRow <= lastRow; currentRow += 1) {
    const currentValue = instance.getDataAtCell(currentRow, visualCol);
    if (currentValue !== null) {
      values.push(currentValue);
    }
  }

  return values;
}
