import { Activity } from 'farmdok-rest-api';

export default class AggregationService {
  private readonly activities: Activity[];

  constructor(activities: Activity[]) {
    this.activities = activities;
  }

  public get sumProcessedArea(): number {
    return this.activities.reduce((acc, activity) => acc + (activity.processedArea || 0), 0);
  }

  public get sumWorkingTime(): number {
    return this.activities.reduce((acc, activity) => acc + (activity.workingTime || 0), 0);
  }

  public get sumDrivingTime(): number {
    return this.activities.reduce((acc, activity) => acc + (activity.drivingTime || 0), 0);
  }

  public get sumSetupTime(): number {
    return this.activities.reduce((acc, activity) => acc + (activity.setupTime || 0), 0);
  }

  public get sumPauseTime(): number {
    return this.activities.reduce((acc, activity) => acc + (activity.pauseTime || 0), 0);
  }
}
