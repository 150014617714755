
import { Activity, MoveActivityMode } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import getFieldDisplayName from '@/activities/utils/getFieldDisplayName';
import { ActivityType, Customer, Field, Product } from '@/shared/api/rest/models';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { DropdownItems } from '@/shared/handsontable/types';
import { Data } from '@/shared/mixins/store/types';
import { fromUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';

export default defineComponent({
  name: 'ConflictionStep',
  components: { FormFieldDropdown, FormFieldSet, FormFieldRadioGroup, FormCheckbox, FormRadio },
  props: {
    activity: {
      type: [Object, null] as PropType<Activity | null>,
      required: true,
    },
    fieldDropdownItems: {
      type: Array as PropType<DropdownItems[]>,
      required: true,
    },
    selectedField: {
      type: [Object, null] as PropType<DropdownItem | null>,
      default: null,
    },
    selectedMode: {
      type: [String, null] as PropType<MoveActivityMode | null>,
      default: null,
    },
    keepOption: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    fields: {
      type: Object as PropType<Record<string, Field>>,
      required: true,
    },
    activityTypes: {
      type: Object as PropType<Record<string, ActivityType>>,
      required: true,
    },
    crops: {
      type: Object as PropType<Data<Product>>,
      required: true,
    },
    customers: {
      type: Object as PropType<Data<Customer>>,
      required: true,
    },
  },
  data() {
    return {
      defaultSelectedDropdownValue: {
        id: '',
        name: '',
      } as DropdownItem,
      copyField: MoveActivityMode.CopyField,
      replaceField: MoveActivityMode.ReplaceField,
    };
  },
  computed: {
    activityType(): ActivityType | null {
      if (this.activity) {
        return this.activityTypes[this.activity.activityTypeId];
      }
      return null;
    },
    fieldDropdownValue(): DropdownItem {
      return this.selectedField || this.defaultSelectedDropdownValue;
    },
    field(): Field | null {
      return this.activity?.fieldId ? this.fields[this.activity.fieldId] : null;
    },
    fieldDisplayName(): string | null {
      if (this.field) {
        return getFieldDisplayName(this.field, this.customers, this.crops);
      }
      return null;
    },
    date(): string | null {
      if (this.activity?.timeStart) {
        return new Date(fromUnixTimestamp(this.activity.timeStart)).toLocaleDateString('de-AT');
      }
      return null;
    },
    dropdownIsEmpty(): boolean {
      if (this.fieldDropdownItems[0].items.length === 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    updateFieldId(field: DropdownItem): void {
      this.$emit('update:fieldId', field.id);
      this.$emit('update:keepOption', false);
      this.$emit('update:moveActivityMode', MoveActivityMode.ReplaceField);
    },
    updateMoveActivityMode(mode: MoveActivityMode): void {
      this.$emit('update:moveActivityMode', mode);
    },
    updateKeepOption(keepOption: boolean) {
      this.$emit('update:keepOption', keepOption);
      this.$emit('update:moveActivityMode', MoveActivityMode.CopyField);
      this.$emit('update:fieldId', null);
    },
  },
});
