<template>
  <WelcomeContentContainer class="step-farmer-size" padding-top>
    <template #default>
      <MaxWidthContainer class="mt-auto" size="lg">
        <WelcomeTitle white>
          {{ $t('Wie viele Hektar werden bewirtschaftet?') }}
        </WelcomeTitle>
      </MaxWidthContainer>
      <MaxWidthContainer>
        <FormInputNumberOnboarding v-model="hectaresComputed" />
      </MaxWidthContainer>
      <MaxWidthContainer class="mt-auto" size="lg">
        <WelcomeTitle white>
          {{ $t('Wie viele Mitarbeiter gibt es?') }}
        </WelcomeTitle>
      </MaxWidthContainer>
      <MaxWidthContainer>
        <FormInputNumberOnboarding v-model="employeesComputed" />
      </MaxWidthContainer>
    </template>
    <template #cta-container>
      <MaxWidthContainer>
        <Button variant="primary" size="lg" block class="my-0" :disabled="hectaresComputed < 1" @click="$emit('next')">
          {{ $t('Weiter') }}
        </Button>
      </MaxWidthContainer>
    </template>
  </WelcomeContentContainer>
</template>

<script>
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';

import imageLandscape from '../assets/FARMDOK_Onboarding_Soil_Sampling_Landscape.jpg';
import image from '../assets/FARMDOK_Onboarding_Soil_Sampling_Portrait.jpg';
import FormInputNumberOnboarding from './FormInputNumberOnboarding.vue';
import WelcomeContentContainer from './WelcomeContentContainer.vue';
import WelcomeTitle from './WelcomeTitle.vue';

export default {
  name: 'StepFarmerSize',
  components: {
    FormInputNumberOnboarding,
    MaxWidthContainer,
    WelcomeContentContainer,
    WelcomeTitle,
    Button,
  },
  props: {
    size: {
      type: [Object, String, null],
      default: null,
    },
  },
  mounted() {
    this.$emit('background', { image, imageLandscape });
  },
  computed: {
    sizeComputed() {
      let employees = null;
      let hectares = null;
      if (this.size != null && typeof this.size.employees === 'string') {
        ({ employees } = this.size);
      }
      if (this.size != null && typeof this.size.hectares === 'string') {
        ({ hectares } = this.size);
      }
      return { employees, hectares };
    },
    hectaresComputed: {
      get() {
        return this.sizeComputed.hectares;
      },
      set(value) {
        this.$emit('change', {
          key: 'size',
          value: { ...this.size, hectares: value },
        });
      },
    },
    employeesComputed: {
      get() {
        return this.sizeComputed.employees;
      },
      set(value) {
        this.$emit('change', {
          key: 'size',
          value: { ...this.size, employees: value },
        });
      },
    },
  },
};
</script>

<style scoped></style>
