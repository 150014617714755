import { render, staticRenderFns } from "./EditFieldsSidebar.vue?vue&type=template&id=67b394d8&scoped=true&"
import script from "./EditFieldsSidebar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./EditFieldsSidebar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./EditFieldsSidebar.vue?vue&type=style&index=0&id=67b394d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b394d8",
  null
  
)

export default component.exports