<template>
  <BPopover ref="popover" show placement="bottom" custom-class="popover-field-status shadow" :id="id" :target="target">
    <h3 class="popover-field-status__title">
      {{ $t('Erntejahr-Status') }}
    </h3>
    <div class="popover-field-status__body">
      <p v-if="error" class="popover-field-status__error">
        {{ errorUserMessage }}
      </p>
      <FormFieldRadioGroup
        v-if="!error"
        v-model="status"
        class="m-0"
        variant="no-label"
        stacked
        @change="(value) => save(value)"
      >
        <FormRadio variant="bordered" value="completed" :disabled="fetching">
          <template #default>
            <span v-if="initialStatus === 'completed'">{{ $t('Abgeschlossen') }}</span>
            <span v-else>
              {{ $t('Abschließen') }}
              <span v-if="activeAndHasHarvestingMeasure" class="text-danger"> ({{ $t('empfohlen') }}) </span>
            </span>
            &nbsp;
            <FontAwesomeIcon
              class="popover-field-status__icon popover-field-status__icon--completed"
              icon="check-circle"
            />
          </template>
          <template #text v-if="initialStatus === 'completed'">
            <!-- eslint-disable max-len -->
            {{
              $t(
                'Die Ernte für das Erntejahr {current} ist bereits abgeschlossen. Das Feld ist in einem zukünftigen Erntejahr "aktiv".',
                { current },
              )
            }}
            <!-- eslint-enable max-len -->
          </template>
          <template #text v-else-if="activeAndHasHarvestingMeasure">
            <!-- eslint-disable max-len -->
            {{
              $t(
                'Auf diesem Feld wurde bereits eine Erntemaßnahme für das Erntejahr {current} gebucht. Schließe das Erntejahr für das Feld jetzt ab, es wird automatisch im nächsten Erntejahr {next} angelegt und "aktiv" gesetzt.',
                { current, next },
              )
            }}
            <!-- eslint-enable max-len -->
          </template>
          <template #text v-else>
            <!-- eslint-disable max-len -->
            {{
              $t(
                'Setze das Feld auf "abgeschlossen", sobald die Ernte für das Erntejahr {current} beendet wurde. Das Feld wird automatisch im nächsten Erntejahr {next} angelegt und "aktiv" gesetzt.',
                { current, next },
              )
            }}
            <!-- eslint-enable max-len -->
          </template>
        </FormRadio>
        <FormRadio variant="bordered" value="active" :disabled="fetching">
          <template #default>
            {{ $t('Aktiv') }}
            <FontAwesomeIcon class="popover-field-status__icon popover-field-status__icon--active" icon="dot-circle" />
          </template>
          <template #text v-if="initialStatus === 'active'">
            {{ $t('Das Feld wird derzeit im angezeigten Erntejahr {current} bewirtschaftet.', { current }) }}
          </template>
          <template #text v-else>
            <!-- eslint-disable max-len -->
            {{
              $t(
                'Setze das Feld "aktiv", wenn es ab sofort im angezeigten Erntejahr {current} bewirtschaftet werden soll. Vorangehende Erntejahre werden auf diesem Feld abgeschlossen.',
                { current },
              )
            }}
            <!-- eslint-enable max-len -->
          </template>
        </FormRadio>
        <FormRadio variant="bordered" value="planned" :disabled="fetching">
          <template #default>
            {{ $t('Geplant') }}
            <FontAwesomeIcon class="popover-field-status__icon popover-field-status__icon--planned" icon="clock" />
          </template>
          <template #text v-if="initialStatus === 'planned'">
            {{ $t('Das Erntejahr {current} für dieses Feld befindet sich in Planung.', { current }) }}
          </template>
          <template #text v-else>
            {{
              $t(
                'Setze das Feld auf "geplant", wenn das Erntejahr {current} auf diesem Feld noch nicht begonnen hat.',
                { current },
              )
            }}
          </template>
        </FormRadio>
      </FormFieldRadioGroup>
    </div>
  </BPopover>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faClock, faDotCircle } from '@fortawesome/pro-solid-svg-icons';
import vClickOutside from 'v-click-outside';

import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';

library.add(faCheckCircle, faClock, faDotCircle);

const { bind, unbind } = vClickOutside.directive;

let counter = 0;

export default {
  name: 'PopoverFieldStatus',
  components: { FormFieldRadioGroup, FormRadio },
  props: {
    target: {
      type: HTMLElement,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    activeAndHasHarvestingMeasure: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    counter += 1;
    return {
      id: `popover-field-status--nr-${counter}`,
      status: this.field.status,
      initialStatus: null,
      popover: null,
      error: false,
      fetching: false,
    };
  },
  computed: {
    current() {
      return this.field.processOrder.name;
    },
    next() {
      return Number(this.current) + 1;
    },
  },
  async mounted() {
    await this.$nextTick();
    this.popover = document.querySelector(`#${this.id}`);
    if (this.popover != null) {
      bind(this.popover, { value: this.onClickOutside });
    }
    document.addEventListener('keydown', this.onKeyDown);
    this.initialStatus = this.field.status;
  },
  beforeDestroy() {
    if (this.popover != null) {
      unbind(this.popover);
    }
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (e.key === 'Escape') {
        this.$emit('close');
      }
    },
    onClickOutside() {
      this.$emit('close');
    },
    async save(status) {
      if (this.error) {
        this.$emit('close');
        return;
      }
      this.fetching = true;
      // wait 100ms so that the BE request is not blocking the update of the UI (loading status)
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 100));
      const data = await this.$store.dispatch('fields/setStatus', {
        status,
        fieldIds: [this.field.id],
      });
      if (data.status === 'error') {
        this.fetching = false;
        this.error = true;
        this.errorUserMessage = data.errorUserMessage.join('\n');
        return;
      }
      await this.$store.dispatch('auth/refreshUserCompanies');
      this.fetching = false;
      this.$emit('close');
    },
  },
};
</script>

<style>
.popover.popover-field-status {
  min-width: 390px;
}

.popover-field-status.bs-popover-bottom > .arrow::before,
.popover-field-status.bs-popover-bottom > .arrow::after {
  border-bottom-color: var(--black);
}

.popover.popover-field-status .popover-body {
  padding: 0;
}
</style>

<style scoped>
.popover-field-status__title {
  padding: var(--spacer_2) var(--spacer_3);
  background: var(--black);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--white);
}

.popover-field-status__body {
  padding: var(--spacer_2) var(--spacer_3);
}

.popover-field-status__error {
  color: var(--danger_dark);
}

.popover-field-status__icon {
  font-size: 12px;
}

.popover-field-status__icon--completed {
  color: var(--gray_600);
}

.popover-field-status__icon--active {
  color: var(--primary);
}

.popover-field-status__icon--planned {
  color: var(--black);
}
</style>
