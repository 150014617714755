import router from '@/router';
import { origin } from '@/shared/constants';
import { JwtAuthVue } from '@/shared/modules/JwtAuth';
import resolveEnvVariableWithPlaceholder from '@/shared/modules/resolveEnvVariableWithPlaceholder';
import store from '@/store';

const authServiceUri = (() => {
  if (
    process.env.VUE_APP_IS_IN_AGRIDAT &&
    typeof window.FD === 'object' &&
    typeof window.FD.jwtAuthServerUri === 'string' &&
    window.FD.jwtAuthServerUri.length > 0
  ) {
    return window.FD.jwtAuthServerUri;
  }
  return resolveEnvVariableWithPlaceholder(process.env, 'VUE_APP_AUTH_ORIGIN');
})();

const jwtAuth = new JwtAuthVue({
  authServiceUri,
  async onAccessTokenRefresh(tokenBody) {
    if (store.state.auth.loggedIn !== true) {
      store.commit('auth/setLoggedIn', { userId: tokenBody.user_id });
    }
    if (
      router.currentRoute.name === 'login' ||
      (router.currentRoute.name === 'register' && !store.state.auth.fetchingRegister)
    ) {
      let targetRoute = { name: 'home' };
      if (typeof router.currentRoute.query.redirect === 'string' && router.currentRoute.query.redirect.length > 0) {
        const redirectRoute = router.resolve(router.currentRoute.query.redirect);
        if (redirectRoute != null && redirectRoute.resolved != null && redirectRoute.resolved.name !== '404') {
          targetRoute = redirectRoute.resolved;
        } else {
          store.commit('redirectToAgriDat');
          window.location = `${origin}${router.currentRoute.query.redirect}`;
          return;
        }
      }
      router.push(targetRoute).catch(() => {
        // #356 ignore route guard errors
        // https://stackoverflow.com/questions/62512168/redirecting-twice-in-a-single-vue-navigation/62563439#62563439
      });
    }
    // update the state, to reflect token update in the vuex store
    store.commit('auth/setJwtAuth', {});
    store.commit('auth/setJwtAuth', jwtAuth);
  },
  onLoggedOut() {
    if (store.state.auth.loggedIn !== false) {
      store.commit('auth/setLoggedOut');
    }
    if (router.currentRoute.meta.requiresAuth) {
      router.push({ name: 'login' }).catch(() => {
        // #356 ignore route guard errors
        // https://stackoverflow.com/questions/62512168/redirecting-twice-in-a-single-vue-navigation/62563439#62563439
      });
    }
  },
});

store.commit('auth/setJwtAuth', jwtAuth);
