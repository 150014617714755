<template>
  <ModalWrapper ref="modalWrapper" class="modal-ask-rate-key" :title="$t('Zonen erstellen')">
    <p class="mt-3 mb-3">
      <!-- eslint-disable max-len -->
      {{
        $t(
          'Die hochgeladenen Zonen stimmen nicht mit den selektierten Feldern überein. FARMDOK erstellt eine Zone für jedes Feld ohne Zone. Welcher Wert soll diesen Zonen zugewiesen werden?',
        )
      }}
      <!-- eslint-disable max-len -->
    </p>
    <BFormSelect class="w-50 mb-3" v-model="rate" :options="options" />
  </ModalWrapper>
</template>

<script>
import numbro from '@/initNumbro';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';

export default {
  name: 'ModalAskRateValue',
  components: { ModalWrapper },
  data() {
    return {
      rate: null,
      rates: null,
    };
  },
  computed: {
    options() {
      if (!Array.isArray(this.rates)) {
        return [];
      }
      return this.rates.map((rate) => ({ value: rate, text: numbro(rate).format() }));
    },
  },
  methods: {
    async show(rates) {
      this.rates = rates;
      [this.rate] = this.rates;
      const ok = await this.$refs.modalWrapper.show();
      if (!ok) {
        return null;
      }
      return this.rate;
    },
  },
};
</script>

<style scoped></style>
