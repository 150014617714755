<template>
  <div class="status">
    <div v-if="status.type" :class="`status-dot status-dot--${status.type}`" />
    <span v-if="status.label" class="status-label">{{ status.label }}</span>
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    status: {
      type: Object, // { label: String, type: [‘primary', 'orange', 'gray'] }
      required: true,
    },
  },
};
</script>

<style scoped>
.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: 100%;
  padding-top: 14px;
  min-height: 18px;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 6px;
}

.status-dot--primary {
  background-color: var(--primary);
}

.status-dot--orange {
  background-color: var(--warning_medium);
}

.status-dot--gray {
  background-color: var(--gray_600);
}

.status-label {
  font-size: 10px;
  color: var(--gray_600);
  font-weight: 600;
}
</style>
