
import { ActivityEquipment } from 'farmdok-rest-api';
import { defineComponent } from 'vue';

import AddSectionButton from '@/activities/modals/createEditActivity/components/AddSectionButton.vue';
import EquipmentSelectSection from '@/activities/modals/createEditActivity/components/EquipmentSelectSection.vue';
import EquipmentSelectSectionNotAvailable from '@/activities/modals/createEditActivity/components/EquipmentSelectSectionNotAvailable.vue';
import { MergedActivityEquipment } from '@/activities/modals/createEditActivity/services/MergedActivityEquipmentService';
import { Company } from '@/auth/store/types';
import EquipmentService from '@/equipments/services/EquipmentService';
import { ActivityType } from '@/shared/api/rest/models';
import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

export default defineComponent({
  name: 'EquipmentSelectSectionContainer',
  components: { AddSectionButton, EquipmentSelectSection, MultiRootWrapper, EquipmentSelectSectionNotAvailable },
  created() {
    this.$store.dispatch('equipments/subscribe');
  },
  data() {
    return {
      defaultDropdownItem: {
        id: '',
        name: '',
      } as DropdownItem,
    };
  },
  computed: {
    currentCompanies(): Company[] {
      const companyIds: string[] = this.$store.getters['activities/createEditActivity/selectedCompanyIds'];
      return companyIds.map((companyId) => this.$store.getters['auth/getCompanyById'](companyId));
    },
    compatibleEquipments(): DropdownItemsPerCategory[] {
      if (!this.currentCompanies || this.activityTypes.length === 0) {
        return [];
      }

      const equipmentService = new EquipmentService((this.$store.state as RootState).equipments.data);
      const usedEquipment = (this.$store.state as RootState).equipments.used;
      const compatibleEquipments = equipmentService.getCompatibleEquipmentsToDropdownItems(
        this.activityTypes,
        this.currentCompanies,
        usedEquipment,
      );

      return compatibleEquipments;
    },
    activityTypes(): ActivityType[] {
      const activityTypeIds =
        (this.$store.state as RootState).activities.createEditActivity.activities?.map(
          (activity) => activity.activityTypeId,
        ) ?? [];
      const activityTypes = activityTypeIds
        .map((id) => (this.$store.state as RootState).activityTypes.data[id])
        .filter(notNullOrUndefined);

      return activityTypes;
    },
    activityEquipments(): ActivityEquipment[] {
      return this.$store.getters[`activities/createEditActivity/mergedActivityEquipment`] as ActivityEquipment[];
    },
    fieldNamesPerActivity(): Record<string, string> {
      const activities = (this.$store.state as RootState).activities.createEditActivity.activities ?? [];
      const fields = (this.$store.state as RootState).fields.data;

      const fieldNamesPerActivity: Record<string, string> = {};
      activities.forEach((activity) => {
        const fieldName = fields[activity.fieldId ?? '']?.name ?? '';
        fieldNamesPerActivity[activity.id] = fieldName;
      });

      return fieldNamesPerActivity;
    },
    featureEquipmentEnabled(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_EQUIPMENT);
    },
    featureEquipmentVisible(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_EQUIPMENT);
    },
    equipmentService(): EquipmentService {
      return new EquipmentService((this.$store.state as RootState).equipments.data);
    },
  },
  methods: {
    getDropdownItem(equipmentId: string): DropdownItem {
      return this.equipmentService.findDropdownItem(equipmentId) ?? this.defaultDropdownItem;
    },
    createActivityEquipment() {
      this.$store.commit(`activities/createEditActivity/createActivityEquipment`);
    },
    updateActivityEquipment(activityEquipment: MergedActivityEquipment) {
      this.$store.dispatch(`activities/createEditActivity/updateEquipmentOnActivityEquipmentInAllActivities`, {
        activityEquipmentId: activityEquipment.ids[0],
        equipmentId: activityEquipment.equipmentId,
      });
    },
    removeActivityEquipment(activityEquipment: MergedActivityEquipment) {
      this.$store.dispatch(
        `activities/createEditActivity/removeActivityEquipmentInAllActivities`,
        activityEquipment.ids[0],
      );

      if (this.activityEquipments.length === 0) {
        this.createActivityEquipment();
      }
    },
  },
});
