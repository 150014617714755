import moment from 'moment';

import { MonitoringState } from './types';

export default function initialState(): MonitoringState {
  return {
    selectedFields: [],
    polygons: {},
    heatmaps: {
      loaded: {},
      current: {},
    },
    vegetationData: {
      loaded: {},
      current: {},
    },
    multiPolyTimestamps: {
      loaded: {},
      current: {},
    },
    selectedIndexType: 'DNN_NDVI',
    selectedTimestamp: moment().unix(),
    selectedHeatmapDbId: null,
    overlay: {
      folded: false,
      loading: false,
    },
  };
}
