import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { polygon } from '@turf/helpers';
import kinks from '@turf/kinks';

export default function isValidPolygon(coordinates: number[][][]) {
  if (coordinates.length === 0) {
    return false;
  }
  // creates a polygon from the coordinates (this also checks if every polygon ring has at least 4 points and if the first and last point are the same)
  let poly;
  try {
    poly = polygon(coordinates);
  } catch (error) {
    return false;
  }

  if (!poly) {
    return false;
  }

  // checks if polygon is SRID 4326 valid
  if (!coordinates.every((ring) => ring.every(([lng, lat]) => lng >= -180 && lng <= 180 && lat >= -90 && lat <= 90))) {
    return false;
  }

  // checks if the polygon has no self intersections
  const ints = kinks(poly);
  if (ints.features.length > 0) {
    return false;
  }

  // checks if interior rings are inside the exterior ring
  const exteriorRing = coordinates[0];
  const interiorRingsValid = coordinates
    .slice(1)
    .every((ring) => ring.every((point) => booleanPointInPolygon(point, polygon([exteriorRing]))));

  return interiorRingsValid;
}
