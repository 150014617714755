import { render, staticRenderFns } from "./InfoContainer.vue?vue&type=template&id=055c9594&scoped=true&"
import script from "./InfoContainer.vue?vue&type=script&lang=js&"
export * from "./InfoContainer.vue?vue&type=script&lang=js&"
import style0 from "./InfoContainer.vue?vue&type=style&index=0&id=055c9594&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055c9594",
  null
  
)

export default component.exports