import { Module } from 'vuex';

import { StoragePlacesState } from '@/storagePlaces/store/types';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import initialState from './initialState';

const vuexModule: Module<StoragePlacesState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: { ...subscribableStore.mutations },
  actions: { ...subscribableStore.actions },
  getters: { ...subscribableStore.getters },
};

export default vuexModule;
