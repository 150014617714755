
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import FormZones from '@/precision-farming/application-maps/containers/FormZones.vue';
import TableZonesContainer from '@/precision-farming/application-maps/containers/TableZonesContainer.vue';
import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';
import Tabs from '@/shared/components/Tabs.vue';
import { Tab } from '@/shared/components/tabsTypes';

import FormUploadZoneFiles from '../components/FormUploadZoneFiles.vue';
import { ZONE_GENERATION_MODE_SATELLITE, ZONE_GENERATION_MODE_UPLOAD } from '../store/baseWorkflowStore/common';

export default defineComponent({
  name: 'SidebarZones',
  components: {
    TableZonesContainer,
    FormZones,
    SidebarContentWrapper,
    SidebarHeadline,
    SidebarSubHeadline,
    Tabs,
    FormUploadZoneFiles,
  },
  computed: {
    ...mapState({
      zoneGenerationMode(state: any) {
        return state.precisionFarming.applicationMaps.spraying.zoneGenerationMode;
      },
      calculation(state: any, getters: any) {
        return getters[`precisionFarming/applicationMaps/spraying/calculation`];
      },
    }),
    buttonSatellites(): Tab {
      return {
        type: 'button',
        label: this.$t('Satellit') || 'Satellit',
        active: this.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE,
        onClick: () => {
          this.$store.commit(
            `precisionFarming/applicationMaps/spraying/setZoneGenerationMode`,
            ZONE_GENERATION_MODE_SATELLITE,
          );
        },
      };
    },
    buttonUpload(): Tab {
      return {
        type: 'button',
        label: this.$t('Upload') || 'Upload',
        active: this.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD,
        onClick: () => {
          this.$store.commit(
            `precisionFarming/applicationMaps/spraying/setZoneGenerationMode`,
            ZONE_GENERATION_MODE_UPLOAD,
          );
        },
      };
    },
  },
});
