
import { PropType, defineComponent } from 'vue';

import Export from '@/precision-farming/application-maps/components/export/Export.vue';

export default defineComponent({
  name: 'SidebarExport',
  components: { Export },
  props: {
    workflowKey: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
