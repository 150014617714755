var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ThePageSkeleton',{scopedSlots:_vm._u([{key:"contentbar",fn:function(){return [_c('TheContentbarContainer',{attrs:{"filterString":_vm.filterString,"leftSectionVisible":_vm.featureEnabled && _vm.featureVisible && !_vm.featureReadonly},on:{"update:filterString":function($event){_vm.filterString=$event},"update:filter-string":function($event){_vm.filterString=$event},"onCreateButtonClick":_vm.onCreateButtonClick,"onDuplicateButtonClick":_vm.onDuplicateButtonClick,"onShowSettings":_vm.onShowSettings,"onExportSelected":_vm.onExportSelected,"onExportData":_vm.onExportData,"onEditButtonClick":_vm.onEditButtonClick,"onMoveButtonClick":_vm.onMoveButtonClick,"onSelectAll":_vm.onSelectAll,"onDeselectAll":_vm.onDeselectAll,"onInvertSelection":_vm.onInvertSelection}})]},proxy:true},{key:"content",fn:function(){return [_c('TableActivitiesContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.featureEnabled && _vm.featureVisible),expression:"featureEnabled && featureVisible"}],ref:"table",attrs:{"filterString":_vm.filterString,"columns":_vm.activitiesColumns},on:{"update:filterString":function($event){_vm.filterString=$event},"update:filter-string":function($event){_vm.filterString=$event},"createActivity":_vm.onCreateButtonClick,"update:hot":_vm.updateHot}}),_c('ModalPageSettingsContainer',{ref:"modalPageSettings"}),_c('ModalCreateActivity',{ref:"modalCreateActivity"}),_c('ModalEditActivity',{ref:"modalEditActivity"}),_c('ModalTableExport',{ref:"modalTableExport",attrs:{"availableTypes":[
        {
          value: 'xls',
          label: _vm.$t('XLS'),
          text: _vm.$t('Als Excel-Datei exportieren'),
        },
      ],"filtered":!_vm.allRowsVisible,"hot":_vm.hot,"tableData":_vm.tableData,"columns":_vm.columns,"dropdownItemsWithSubtableColumnData":_vm.dropdownItemsWithSubtableColumnData}}),_c('ModalDuplicateActivity',{ref:"modalDuplicateActivity"}),_c('ModalMoveActivity',{ref:"modalMoveActivity",attrs:{"selectedActivityIds":_vm.selectedActivityIds}}),_c('ModalRuleCheckViolations'),(!_vm.featureEnabled)?_c('div',{staticClass:"feature-not-enabled"},[_c('FeatureNotAvailable',{attrs:{"featureName":_vm.$t('Aktivitäten'),"addLinkToShop":true,"featureVisible":_vm.featureVisible}})],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }