import { Company } from './store/types';

export default function isMulticompany(companies: Company[]): boolean {
  if (companies.length <= 1) return false;

  const { clusterId } = companies[0];
  const allCompaniesBelongToSameCluster = companies.every((company) => company.clusterId === clusterId);

  if (!allCompaniesBelongToSameCluster) return false;
  if (clusterId === null) {
    return false; // multiple companies selected, but they are not assigend to a cluster
  }

  return true;
}
