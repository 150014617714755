<template>
  <ThePageSkeleton class="page-no-company" scroll-y>
    <WelcomeContentContainer>
      <TopContentContainer>
        <WelcomeTitle class="m-0" white centered>
          {{ $t('Betrieb erstellen') }}
        </WelcomeTitle>
      </TopContentContainer>
      <MaxWidthContainer v-if="success" class="my-auto">
        <p>{{ $t('Dein Betrieb wurde erfolgreich erstellt.') }}</p>
        <ButtonLink class="mt-1 py-3" :to="{ name: 'register-additional-data' }">
          {{ $t('Weiter') }}
        </ButtonLink>
      </MaxWidthContainer>
      <MaxWidthContainer v-else class="my-auto">
        <p>
          {{ $t('Du hast derzeit keine Berechtigung auf einen Betrieb zuzugreifen.') }}
        </p>
        <p class="mb-6">
          {{ $t('Ein Betriebsleiter kann dir Zugriff gewähren, oder du erstellst dir hier deinen eigenen Betrieb.') }}
        </p>
        <FormFieldDropdown
          v-model="country"
          class="w-100 mt-0"
          name="country"
          variant="vertical-lg"
          required
          :label="$t('Land')"
          :placeholder="countriesLoading ? $t('Lade...') : $t('Wählen...')"
          :disabled="fetching"
          :state="country.id == null && !dirty ? false : null"
          :items="countries"
          :items-loading="countriesLoading"
        />
        <p v-if="country.id == null && !dirty" class="text-danger">
          {{ $t('{field} ist ein Pflichtfeld.', { field: $t('Land') }) }}&nbsp;
        </p>
        <ErrorUserMessage v-else :error-user-message="userErrorMessage" />
        <Button type="submit" variant="primary" size="lg" block class="my-0" :disabled="fetching" @click="submit">
          <FontAwesomeIcon v-if="fetching" icon="circle-notch" class="text-white" spin />
          <span v-else>{{ $t('Betrieb erstellen') }}</span>
        </Button>
      </MaxWidthContainer>
    </WelcomeContentContainer>
  </ThePageSkeleton>
</template>

<script>
import axios from 'axios';

import TopContentContainer from '@/auth/components/TopContentContainer.vue';
import WelcomeContentContainer from '@/auth/components/WelcomeContentContainer.vue';
import WelcomeTitle from '@/auth/components/WelcomeTitle.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';

import registerCountry from './mixins/registerCountry';

export default {
  name: 'PageNoCompany',
  components: {
    ErrorUserMessage,
    FormFieldDropdown,
    MaxWidthContainer,
    TopContentContainer,
    WelcomeTitle,
    WelcomeContentContainer,
    ThePageSkeleton,
    Button,
    ButtonLink,
  },
  mixins: [backendResponseErrorHelpers, registerCountry],
  data() {
    return {
      fetching: false,
      dirty: true,
      success: false,
    };
  },
  methods: {
    async submit() {
      this.userErrorMessage = null;
      this.dirty = false;
      if (this.country.id == null) {
        return;
      }
      this.fetching = true;
      try {
        const { data } = await axios.post('/admin/rest/user/create_company_for_no_company_user', {
          version: '2.0',
          data: {
            regionId: this.country.id,
          },
        });
        if (data == null || data.status !== 'success') {
          this.setUserErrorMessageFromResponse(data);
        } else {
          await this.$store.dispatch('auth/refreshUserCompanies');
          this.success = true;
        }
      } catch (error) {
        console.error(error);
        this.setUserErrorMessageFromResponse(error);
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>

<style scoped></style>
