import Handsontable from 'handsontable';

export default function setMinPlaceholderColumnWidthFactory(hot: Handsontable) {
  function setMinPlaceholderColumnWidth(
    newSize: number,
    visualCol: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isDoubleClick: boolean,
  ): void {
    const columnSettings = getColumnSettings(visualCol);

    if (columnSettings.type !== 'farmdok.placeholder') return undefined;
    if (typeof columnSettings.width === 'number' && newSize < columnSettings.width) {
      hot.getPlugin('manualColumnResize').setManualSize(visualCol, columnSettings.width);
    }

    return undefined;
  }

  function getColumnSettings(visualCol: number) {
    const settings = hot.getSettings();
    if (!Array.isArray(settings.columns)) throw new Error('columns must be of type ColumnSettings[]');

    const physicalColumn = hot.toPhysicalColumn(visualCol);
    return settings.columns[physicalColumn];
  }

  return setMinPlaceholderColumnWidth;
}
