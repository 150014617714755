<template>
  <div class="fetching-error">
    <p class="text-danger">
      {{ userMessage }}
    </p>
    <slot />
    <BButton variant="lightest" @click="() => $emit('reload')">
      {{ $t('Neu laden') }}
    </BButton>
  </div>
</template>

<script>
export default {
  name: 'FetchingError',
  props: {
    userMessage: {
      type: String,
      default() {
        return this.$t(`
          Die Daten konnten nicht geladen werden. Bitte überprüfe die Internetverbindung.
          Falls der Fehler weiterhin auftritt wende dich an support@farmdok.com
        `);
      },
    },
  },
};
</script>

<style scoped>
.fetching-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(40 * var(--vh, 1vh));
}
</style>
