import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationSquare } from '@fortawesome/pro-solid-svg-icons';
import Handsontable from 'handsontable';

library.add(faExclamationSquare);

export default function RequiredRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    TD,
    row,
    col,
    prop,
    'Pflichtfeld',
    cellProperties,
    ...args,
  ]);

  TD.classList.add('text-italic');
  const [exclamationSquare] = icon({ prefix: 'fas', iconName: 'exclamation-square' }).node;
  exclamationSquare.style.float = 'right';
  exclamationSquare.style.marginTop = '3px';
  exclamationSquare.classList.add('text-secondary');
  TD.append(exclamationSquare);
}
