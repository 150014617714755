import { AxiosRequestConfig } from 'axios';
import { ActionContext } from 'vuex';

import { ApiResponse, Unit } from '@/shared/api/rest/models';
import fetchUnits from '@/shared/api/rest/units/unitsApi';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<Unit>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Unit[]>> {
  const { data } = await fetchUnits(axiosOptions);

  return data;
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<Unit>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Unit[]>> {
  throw new Error('NOT IMPLEMENTED');
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
