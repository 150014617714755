import { Activity, ImportActivity } from 'farmdok-rest-api';

export default function findSimilarActivitiesForImportActivity(activities: Activity[], importActivity: ImportActivity) {
  return activities.filter(
    (activity) =>
      activity.activityTypeId === importActivity.activityTypeId &&
      activity.fieldId === importActivity.fieldId &&
      matchingProducts(activity, importActivity) &&
      matchingEquipments(activity, importActivity) &&
      matchingTimeStart(activity, importActivity),
  );
}

function matchingProducts(activity: Activity, importActivity: ImportActivity) {
  if (activity.products.length !== importActivity.products.length) return false;
  const productIds = activity.products.map((product) => product.productId);
  const importProductIds = importActivity.products.map((product) => product.productId);
  return productIds.every((productId) => importProductIds.includes(productId));
}

function matchingEquipments(activity: Activity, importActivity: ImportActivity) {
  if (activity.equipment.length !== importActivity.equipment.length) return false;
  const equipmentIds = activity.equipment.map((equipment) => equipment.equipmentId);
  const importEquipmentIds = importActivity.equipment.map((equipment) => equipment.equipmentId);
  return equipmentIds.every((equipmentId) => importEquipmentIds.includes(equipmentId));
}

function matchingTimeStart(activity: Activity, importActivity: ImportActivity) {
  if (activity.timeStart && importActivity.timeStart) {
    if (
      new Date(activity.timeStart * 1000).toLocaleDateString() !==
      new Date(importActivity.timeStart * 1000).toLocaleDateString()
    ) {
      return false;
    }
  }
  return true;
}
