
import { type PropType, defineComponent } from 'vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import numbro from '@/initNumbro';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';

export default defineComponent({
  name: 'FormSlider',
  components: {
    VueSlider,
    FormFieldInput,
    ErrorUserMessage,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Number,
      required: false,
      default: 50,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    hideSlider: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Specifies the range of values that the slider can scrub through. Use this parameter when min/max don't have a logical order, or want to have a custom set of values
     */
    data: {
      type: Array as PropType<number[] | string[]>,
      required: false,
      default: () => undefined,
    },
    interval: {
      type: Number,
      required: false,
      default: 1,
    },
    additionalInformation: {
      type: String,
      required: false,
      default: null,
    },
    formatter: {
      type: Function as PropType<(value: number) => string>,
      required: false,
      default: (value: number) => numbro(value).format({ mantissa: 0 }),
    },
    parser: {
      type: Function as PropType<(value: string) => number>,
      required: false,
      default: (value: string) => {
        const digits = /\d+/;
        const match = value.match(digits)?.shift();
        return Number(match);
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    /**
     * @deprecated use 'update:value instead
     */
    'setValue',
    /**
     * Reactively emits numeric value whether mutated by scrubber or keyboard. Prone to change, however, currently sliders in Farmdok support only numeric values.
     */
    'update:value',
  ],
  model: {
    prop: 'value',
    event: 'update:value',
  },
  data(): {
    inputErrorMessage: string | null;
    numbro: typeof numbro;
  } {
    return {
      inputErrorMessage: '',
      numbro,
    };
  },
  methods: {
    update(value: number) {
      const inRange = this.between(value, this.min, this.max);
      if (!inRange) {
        this.inputErrorMessage =
          this.$t('Wert muss zwischen {min} und {max} sein', {
            min: this.formatter(this.min),
            max: this.formatter(this.max),
          }) ?? '';
        return;
      }

      this.$emit('setValue', value);
      this.$emit('update:value', value);
      this.inputErrorMessage = null;
    },
    /**
     * Tests if an integer value is in the exclusive range (a, b) or in the inclusive range [a,b]. Order of a and b doesn't matter.
     * @param value at hand
     * @param a
     * @param b
     * @param inclusively whether range should be exclusive (a,b) or inclusive [a,b]
     * @returns {boolean}
     */
    between(value: number, a: number, b: number, inclusively = true): boolean {
      const min = Math.min(a, b);
      const max = Math.max(a, b);
      return inclusively ? min <= value && value <= max : min < value && value < max;
    },
  },
});
