import { ImportActivity } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import Vue from 'vue';

import {
  activityTypeColumn as activityActivityTypeColumn,
  fieldColumn as activityFieldColumn,
  company,
  date,
  drivingTime,
  id,
  pauseTime,
  processedArea,
  selectColumn,
  setupTime,
  totalFieldSize,
  user,
  userComment,
  workingTime,
} from '@/activities/handsontable/columns';
import equipment from '@/activities/importActivities/handsontable/columns/equipments';
import products from '@/activities/importActivities/handsontable/columns/products';
import { Getters as ActivityTypeGetters } from '@/activityTypes/store/getters';
import { Getters as FieldGetters } from '@/fields/store/getters';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { columnSorting as columnSortingDropdown } from '@/shared/handsontable/rework/cellTypes/dropdown';
import { ColumnSettingsDropdownOptional } from '@/shared/handsontable/rework/cellTypes/dropdown/types';
import { expandColumn } from '@/shared/handsontable/rework/cellTypes/expandRenderer';
import { ColumnSettingsRuleCheckStatusOptional } from '@/shared/handsontable/rework/cellTypes/ruleCheckStatusRenderer/types';
import isFirstRowOfEntry from '@/shared/handsontable/rework/features/nestedTable/utils/isFirstRowOfEntry';
import placeholderColumn from '@/shared/handsontable/rework/features/placeholderColumn/column';
import storeStatusColumn from '@/shared/handsontable/rework/features/rowBackgroundStoreStatus/column';
import type { FarmdokColumnSettings } from '@/shared/handsontable/rework/types';
import store from '@/store';

import { DropdownMatch } from '../../types/TableDataImportActivity.types';

function showWarning(value: DropdownItem | null) {
  return value?.id === DropdownMatch.NO_MATCH || !value;
}

const renderEmpty = (visualRow: number, instance: Handsontable) => !isFirstRowOfEntry(visualRow, instance);

export const activityTypeColumn: ColumnSettingsDropdownOptional = {
  ...activityActivityTypeColumn,
  showWarning,
  dropdown: {
    async getItems(visualRow, hot) {
      const importActivityId = hot.getDataAtRowProp(visualRow, 'id');
      const importActivity = store.getters['activities/importActivities/getById'](importActivityId) as ImportActivity;

      const { currentCompanies } = store.state.auth;
      const dropdownItems = (store.getters['activityTypes/dropdownItems'] as ActivityTypeGetters['dropdownItems'])(
        currentCompanies,
      );
      const matchDropdownItems = getDropdownItemsForAdditionalActivityTypeMatches(
        importActivity.activityTypeIdMatch.additionalMatches,
        importActivity.activityTypeIdMatch.originalValue,
      );

      return matchDropdownItems.concat(dropdownItems);
    },
  },
  warningIcon: {
    tooltipLabel: Vue.i18n.translate(
      'Der Wert in der Datenquelle (aus dem Import) konnte nicht eindeutig erkannt werden. ' +
        'Bitte wähle eine Eintrag aus der Liste. Andernfalls kann kein Wert importiert werden.',
    ),
  },
};

export const fieldColumn: ColumnSettingsDropdownOptional = {
  ...activityFieldColumn,
  showWarning,
  dropdown: {
    async getItems(visualRow, hot) {
      const importActivityId = hot.getDataAtRowProp(visualRow, 'id');
      const importActivity = store.getters['activities/importActivities/getById'](importActivityId) as ImportActivity;

      const processOrderId: string | undefined = hot.getDataAtRowProp(visualRow, 'processOrderId');
      if (!processOrderId) return [];

      const fieldMatchDropdownItems = getDropdownItemsForAdditionalFieldMatches(
        importActivity.fieldIdMatch.additionalMatches,
        importActivity.fieldIdMatch.originalValue,
      );

      return fieldMatchDropdownItems.concat(
        (store.getters['fields/dropdownItems'] as FieldGetters['dropdownItems'])(processOrderId),
      );
    },
  },
  columnSorting: columnSortingDropdown,
  warningIcon: {
    tooltipLabel: Vue.i18n.translate(
      'Der Wert in der Datenquelle (aus dem Import) konnte nicht eindeutig erkannt werden. ' +
        'Bitte wähle eine Eintrag aus der Liste. Andernfalls kann kein Wert importiert werden.',
    ),
  },
};

function getDropdownItemsForAdditionalActivityTypeMatches(additionalMatches: string[], originalValue: string | null) {
  const items: DropdownItem[] = additionalMatches.map((activityId) => ({
    id: activityId,
    name:
      (store.getters['activityTypes/activityTypeDisplayName'] as ActivityTypeGetters['activityTypeDisplayName'])(
        activityId,
      ) ?? activityId,
  }));

  const matchingItems: DropdownItemsPerCategory = {
    name: 'Matching activity types',
    id: 'activityTypes-match',
    items,
    sort: false,
  };

  const originalValueItems: DropdownItemsPerCategory = {
    name: 'value from data source (from import)',
    id: 'activityType-originalValue',
    items: originalValue ? [{ id: originalValue, name: originalValue }] : [],
    sort: false,
  };

  return [originalValueItems, matchingItems];
}

function getDropdownItemsForAdditionalFieldMatches(additionalFieldMatchIds: string[], originalValue: string | null) {
  const items: DropdownItem[] = additionalFieldMatchIds.map((fieldId) => ({
    id: fieldId,
    name: (store.getters['fields/fieldDisplayName'] as FieldGetters['fieldDisplayName'])(fieldId) ?? fieldId,
  }));

  const matchingFieldItems: DropdownItemsPerCategory = {
    name: 'Matching fields',
    id: 'fields-match',
    items,
    sort: false,
  };

  const originalValueItems: DropdownItemsPerCategory = {
    name: 'value from data source (from import)',
    id: 'fields-originalValue',
    items: originalValue ? [{ id: originalValue, name: originalValue }] : [],
    sort: false,
  };

  return [originalValueItems, matchingFieldItems];
}

export const importStatus: ColumnSettingsRuleCheckStatusOptional = {
  data: 'importStatus',
  type: 'farmdok.ruleCheckStatus',
  renderer: 'farmdok.optional',
  noHeaderContextMenu: true,
  hiddenInPageSettings: true,
  width: 45,
  renderEmpty,
  disableColumnResize: true,
};

const tableColumns: FarmdokColumnSettings[] = [
  id,
  storeStatusColumn,
  selectColumn,
  expandColumn,
  importStatus,
  company,
  date,
  activityTypeColumn,
  fieldColumn,
  processedArea,
  products,
  equipment,
  workingTime,
  drivingTime,
  setupTime,
  pauseTime,
  userComment,
  user,
  totalFieldSize,
  placeholderColumn,
];

export default tableColumns;
