<template>
  <div class="download-control" @click="$emit('onClick')">
    <FontAwesomeIcon icon="cloud-download" />
    <span>{{ $t('Datei herunterladen') }}</span>
  </div>
</template>

<script>
export default {
  name: 'DownloadFileMapControl',
};
</script>
<style scoped>
.download-control {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 15px);
  z-index: 1;

  height: 30px;
  padding: 20px 15px;

  display: flex;
  flex-flow: row;
  gap: 10px;
  place-items: center;
  cursor: pointer;

  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;

  white-space: nowrap;
}
</style>
