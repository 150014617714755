import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { SharedActivitiesState } from './types';

const getters: GetterTree<SharedActivitiesState, RootState> = {
  ...subscribableStore.getters,
};

export default getters;
