import type { ImportActivityProduct } from 'farmdok-rest-api';

import { TableDataImportActivityProduct } from '@/activities/importActivities/types/TableDataImportActivity.types';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import ProductService from '@/products/services/ProductService';
import { Unit } from '@/shared/api/rest/models';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { Data } from '@/shared/mixins/store/types';

export default function tableDataActivitiesImportProductService() {
  /* -- METHODS -- */

  function convertToActivityImportProductTableData(
    activityImportProduct: ImportActivityProduct | undefined,
    processedArea: number | null | undefined,
    units: Data<Unit>,
    productService: ProductService,
    productStorageDropdownItem: DropdownItem | null,
    isLoading: boolean,
  ): TableDataImportActivityProduct | undefined {
    if (!activityImportProduct) return undefined;
    const { amount, unitId, ...restOfActivityImportProduct } = activityImportProduct;
    const amountFallback = amount ?? 0;
    const unitIdFallback = findUnitIdWithFallback(activityImportProduct, productService);
    const amountsAndUnits = calculateAmountsAndUnits(amountFallback, unitIdFallback, processedArea, units);

    // there is no pesticide info in ImportActivityProduct
    const pesticideIndicationDropdownItem = undefined;

    return {
      ...restOfActivityImportProduct,
      productStorageDropdownItem: productStorageDropdownItem ?? undefined,
      pesticideIndicationDropdownItem,
      ...amountsAndUnits,
      isLoading,
    };
  }

  function findUnitIdWithFallback(
    activityImportProduct: ImportActivityProduct,
    productService: ProductService,
  ): string | undefined {
    const { productId, unitId } = activityImportProduct;
    if (unitId) return unitId;

    if (!productId) return undefined;
    const product = productService.findProductById(productId);
    return product?.unitId;
  }

  function dummyProductForAddProductButton(): TableDataImportActivityProduct {
    return {
      productId: '',
      productStorageDropdownItem: 'HOT_DISPLAY_BUTTON_RENDERER',
      pesticideIndicationDropdownItem: undefined,
      amountUnitRelative: {
        amount: null,
        unitId: null,
        isFixed: false,
      },
      amountUnitTotal: {
        amount: null,
        unitId: null,
        isFixed: false,
      },
      isLoading: false,
    };
  }

  return {
    convertToActivityImportProductTableData,
    dummyProductForAddProductButton,
  };
}
