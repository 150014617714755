
import { PropType, defineComponent } from 'vue';

import numbro from '@/initNumbro';
import FormFieldInputNumberFormatted from '@/shared/components/form/FormFieldInputNumberFormatted.vue';

export default defineComponent({
  name: 'FormFieldInputNumberTwoDecimals',
  components: { FormFieldInputNumberFormatted },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number] as PropType<number | string | null>,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Label rendered above or to the left, depending on the variant.
     *
     * If variant is 'no-label' the label can be omitted.
     */
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value: string) =>
        ['horizontal', 'vertical', 'vertical-lg', 'no-label', 'no-label-sm'].includes(value),
    },
    /**
     * If set to `true` or `false` the input will be highlighted with colors and icons, depending on the state.<br>
     * Default `null`.
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * If set to `true` the following props will be overwritten:
     *   - value with `null`
     *   - placeholder with `'(verschiedene)'`
     */
    various: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    stateMessage: {
      type: String,
      default: null,
    },
  },
  methods: {
    formatter(value: number | string | null): string {
      if (value === null || value === '') return '';
      return numbro(value).format({ thousandSeparated: true, mantissa: 2 });
    },
    parser(value: string | null): number | null {
      if (value === null) return null;
      return numbro.unformat(value);
    },
  },
});
