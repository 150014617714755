<template>
  <form
    class="form"
    method="post"
    action=""
    enctype="multipart/form-data"
    :class="{ 'form--is-dragover': isDragover }"
    @drag.prevent
    @dragstart.prevent
    @dragend.prevent="isDragover = false"
    @dragover.prevent="isDragover = true"
    @dragenter.prevent="isDragover = true"
    @dragleave.prevent="isDragover = false"
    @drop.prevent="onFileDrop"
    @submit.prevent="onSubmit"
  >
    <label class="position-absolute">
      <input
        ref="input"
        class="input"
        type="file"
        :accept="supportedFileFormatFileEndings.map((ending) => `.${ending}`).join(',')"
        name="file[]"
        multiple
        @change="onFileChange"
      />
    </label>
    <EmptyState
      v-if="fileGroups.length === 0"
      :supportedFileFormats="supportedFileFormats"
      :maxFileSize="maxFileSize"
      @onEmptyFormClick="onEmptyFormClick"
    />
    <div v-else class="form-files-content">
      <FilesList :fileGroups="fileGroups" />
      <FilesListButtons
        :uploadDisabled="!uploadEnabled"
        :fetching="fetching"
        @onAddAdditionalFiles="onAddAdditionalFiles"
        @onReset="onReset"
        @onUpload="onSubmit"
      />
      <ErrorUserMessage class="mx-3" :errorUserMessage="submitErrorUserMessages" />
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import JSZip from 'jszip';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import EmptyState from '@/shared/components/filesUpload/EmptyState.vue';
import FilesList from '@/shared/components/filesUpload/FilesList.vue';
import FilesListButtons from '@/shared/components/filesUpload/FilesListButtons.vue';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';

export default {
  name: 'FilesUploadForm',
  components: {
    EmptyState,
    FilesList,
    FilesListButtons,
    ErrorUserMessage,
  },
  props: {
    supportedFileFormats: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    maxFileSize: {
      type: Number,
      default: 0,
    },
    initialFiles: {
      type: FileList,
    },
  },
  data() {
    return {
      isDragover: false,
      fileGroups: [],
      fetching: false,
      submitErrorUserMessages: null,
    };
  },
  created() {
    if (this.initialFiles) {
      this.addFilesToFileGroups(this.initialFiles);
    }
  },
  computed: {
    uploadEnabled() {
      const hasSomeFiles = this.fileGroups.length > 0 && this.fileGroups.some((fileGroup) => fileGroup.file);
      const hasErrors = this.fileGroups.some((fileGroup) => fileGroup.errors.length > 0);

      return hasSomeFiles && !hasErrors && this.submitErrorUserMessages === null;
    },
    supportedFileFormatFileEndings() {
      return this.$props.supportedFileFormats.map((format) => format.fileEnding);
    },
  },
  methods: {
    onEmptyFormClick() {
      this.$refs.input.click();
    },
    onFileDrop(event) {
      this.isDragover = false;
      this.addFilesToFileGroups(event.dataTransfer.files);
    },
    onFileChange(event) {
      this.addFilesToFileGroups(event.target.files);
    },
    async addFilesToFileGroups(files) {
      const filesArray = Array.from(files);
      const fileGroups = await Promise.all(
        filesArray.map(async (file) => {
          if (file.type === 'application/zip') {
            const unzippedFile = await JSZip.loadAsync(file);
            const subFiles = Object.values(unzippedFile.files).map((subFile) => ({ name: subFile.name }));

            return {
              key: file.name,
              file,
              subFiles,
              errors: this.validateFile(file, subFiles),
            };
          }

          return {
            key: file.name,
            file,
            subFiles: [],
            errors: this.validateFile(file),
          };
        }),
      );
      this.fileGroups.push(...fileGroups);
    },
    validateFile(file, optionalUnzippedFile) {
      const errors = [];

      const fileSizeError = this.validateFileSize(file);
      if (fileSizeError) {
        errors.push(fileSizeError);
      }

      const fileExtensionError = this.validateFileExtension(file);
      if (fileExtensionError) {
        errors.push(fileExtensionError);
      }

      if (file.type === 'application/zip') {
        const shapeFileError = this.validateShapeFile(file, optionalUnzippedFile);
        if (shapeFileError) {
          errors.push(shapeFileError);
        }
      }

      return errors;
    },
    validateFileSize(file) {
      const maxFileSizeBytes = this.maxFileSize * 2 ** 20;
      const fileIsTooBig = file.size > maxFileSizeBytes;

      if (fileIsTooBig) {
        return {
          key: 'fileSizeError',
          message: this.$t('Die Datei ist zu gro\u00df. (max. {maxFileSize} MB)', {
            maxFileSize: this.$props.maxFileSize,
          }),
        };
      }
      return null;
    },
    validateFileExtension(file) {
      const fileExtension = file.name.split('.').pop();

      if (!this.$props.supportedFileFormats.map((format) => format.fileEnding).includes(fileExtension)) {
        return {
          key: 'fileFormatError',
          message: this.$t('Dieses Dateiformat wird nicht unterst\u00fctzt.'),
        };
      }

      return null;
    },
    validateShapeFile(file, unzippedSubFiles) {
      const subFilenames = unzippedSubFiles.map((subFile) => subFile.name);
      const subFileEndings = subFilenames.map((filename) => filename.split('.').pop());
      const mandatoryShapeFileEndings = ['shp', 'shx', 'dbf'];
      const containsAllMandatoryShapeFiles = mandatoryShapeFileEndings.every((mandatorysShapeFileEnding) =>
        subFileEndings.includes(mandatorysShapeFileEnding),
      );

      if (!containsAllMandatoryShapeFiles) {
        return {
          key: 'shapeFileError',
          message: this.$t('Keine Shape-Datei im Zip gefunden.'),
        };
      }

      return null;
    },
    getFileKey(file) {
      return `${file.name}_${file.lastModified}`;
    },
    onAddAdditionalFiles() {
      this.$refs.input.click();
    },
    onReset() {
      this.fileGroups = [];
      this.$refs.input.value = null;
      this.submitErrorUserMessages = null;
    },
    async onSubmit() {
      const formData = new FormData();
      formData.append('contractId', this.$route.params.contractId);
      this.fileGroups.forEach((fileGroup) => {
        formData.append('file[]', fileGroup.file);
      });

      let responseData;
      try {
        this.fetching = true;
        const { data } = await axios.post('/admin/rest/contractFile/upload', formData);
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      } finally {
        this.fetching = false;
      }

      switch (responseData.status) {
        case 'success': {
          this.$emit('uploadSuccess');
          this.submitErrorUserMessages = null;
          return;
        }
        case 'partialSuccess': {
          this.submitErrorUserMessages = responseData.errors.map((error) => error.errorUserMessage[0]);
          return;
        }
        case 'error': {
          this.submitErrorUserMessages = responseData.errorUserMessage;
          return;
        }
        default: {
          this.submitErrorUserMessages = this.$t('Es ist ein unbekannter Fehler aufgetreten.');
        }
      }
    },
  },
  watch: {
    uploadEnabled(newUploadEnabled) {
      this.$emit('uploadEnabledChanged', newUploadEnabled);
    },
  },
};
</script>

<style scoped>
.form {
  width: 100%;
  border: 1px dashed var(--gray_600);
  border-radius: 3px;
  text-align: left;
}

.form--is-dragover {
  border-color: var(--primary_dark);
  background-color: var(--primary_light);
}

.input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
