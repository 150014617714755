import { Activity, ActivityEquipment, ActivityProduct, ImportActivityEquipment } from 'farmdok-rest-api';

import i18n from '@/initI18n';

import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { createUuid } from '@/shared/modules/uuid';

import { ImportActivityProductCombined, ImportActivityWithId } from '../store/types';

const $t = i18n.translate;

export default function convertImportActivitiesToActivities(importActivities: ImportActivityWithId[]): Activity[] {
  const activities: Activity[] = importActivities.map((importActivity) => ({
    activityTypeId: importActivity.activityTypeId ?? '',
    completed: false,
    id: importActivity.id,
    isCopy: false,
    processOrderId: importActivity.processOrderId ?? '',
    tstamp: toUnixTimestamp(Date.now()),
    creationDate: toUnixTimestamp(Date.now()),
    drivingTime: importActivity.drivingTime,
    fieldId: importActivity.fieldId,
    pauseTime: importActivity.pauseTime,
    processedArea: importActivity.processedArea,
    setupTime: importActivity.setupTime,
    workingTime: importActivity.workingTime,
    processingState: null,
    timeFinish: importActivity.timeFinish,
    timeStart: importActivity.timeStart,
    userId: importActivity.userId,
    trackId: null,
    userComment: `${importActivity.userComment ?? ''} (${$t('importiert')} @ ${new Date().toLocaleDateString()})`,
    workingWidth: importActivity.workingWidth,
    equipment: importActivity.equipment.map((tableDataImportActivityEquipment) =>
      convertImportActivityEquipmentToActivityEquipment(tableDataImportActivityEquipment),
    ),
    products: (importActivity.products as ImportActivityProductCombined[]).map((tableDataImportActivityProduct) =>
      convertImportActivityProductToActivityProduct(tableDataImportActivityProduct),
    ),
    files: [],
  }));
  return activities;
}

function convertImportActivityProductToActivityProduct(activityImportProduct: ImportActivityProductCombined) {
  const activityProduct: ActivityProduct = {
    id: activityImportProduct.id,
    productId: activityImportProduct.productId ?? '',
    tstamp: toUnixTimestamp(Date.now()),
    amount: activityImportProduct.amount,
    deleted: null,
    pesticideIndicationId: null,
    pestId: null,
    unitId: activityImportProduct.unitId,
    storagePlaceId: null,
  };
  return activityProduct;
}

function convertImportActivityEquipmentToActivityEquipment(activityImportEquipment: ImportActivityEquipment) {
  const activityEquipment: ActivityEquipment = {
    id: createUuid(),
    equipmentId: activityImportEquipment.equipmentId ?? '',
    tstamp: toUnixTimestamp(Date.now()),
    deleted: null,
  };
  return activityEquipment;
}
