<template>
  <div>
    <slot name="default">
      <ButtonBorderedIcon
        v-for="option in options"
        class="button-bordered-icon-options__option"
        :active="option.value === value"
        :key="option.value"
        :icon="option.icon"
        :title="option.title"
        @click="$emit('update', option.value)"
      />
    </slot>
  </div>
</template>

<script>
import ButtonBorderedIcon from './ButtonBorderedIcon.vue';

export default {
  name: 'ButtonBorderedIconOptions',
  components: { ButtonBorderedIcon },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    /**
     * V-model.
     */
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.button-bordered-icon-options__option {
  margin: 0;
}

.button-bordered-icon-options__option:not(:last-child),
.button-bordered-icon-options__option:not(:last-child):active,
.button-bordered-icon-options__option:not(:last-child):not(:disabled):not(.disabled):active,
.button-bordered-icon-options__option:not(:last-child):not(:disabled):not(.disabled):active:focus,
.button-bordered-icon-options__option:not(:last-child).active,
.button-bordered-icon-options__option:not(:last-child):not(:disabled):not(.disabled).active:focus,
.button-bordered-icon-options__option:not(:last-child):not(:disabled):not(.disabled).active {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button-bordered-icon-options__option:not(:first-child),
.button-bordered-icon-options__option:not(:first-child):active,
.button-bordered-icon-options__option:not(:first-child):not(:disabled):not(.disabled):active,
.button-bordered-icon-options__option:not(:first-child):not(:disabled):not(.disabled):active:focus,
.button-bordered-icon-options__option:not(:first-child).active,
.button-bordered-icon-options__option:not(:first-child):not(:disabled):not(.disabled).active:focus,
.button-bordered-icon-options__option:not(:first-child):not(:disabled):not(.disabled).active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
