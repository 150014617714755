
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { PropType, defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import type { ApplicationMapsState } from '@/precision-farming/application-maps/store/types';
import type { ProductCategoriesState } from '@/productCategories/store/types';
import type { ProductVisibilitiesState } from '@/productVisibilities/store/types';
import { Getters as ProductGetters } from '@/products/store/getters';
import type { ProductsState } from '@/products/store/types';
import { ActivityType, Field } from '@/shared/api/rest/models';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInputNumberFormattedDisplayValue from '@/shared/components/form/FormFieldInputNumberFormattedDisplayValue.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import type { StoragePlacesState } from '@/storagePlaces/store/types';

library.add(faTrashAlt);

/**
 * A form control that enables two-way binding of products independently of given context/domain. Some forms in precision farming utilize this control, but you can also use it separately, outside those forms.
 */
export default defineComponent({
  name: 'ControlProductSelection',
  components: { FormFieldDropdown },
  props: {
    workflowKey: {
      type: String as PropType<keyof ApplicationMapsState>,
      required: true,
    },
    selectedProduct: {
      type: Object as PropType<DropdownItem<{ id: string; name: string }>>,
      required: false,
      default: () => ({ id: '', name: '' }),
    },
    activityType: {
      type: Object as PropType<ActivityType>,
      required: false,
      default: () => undefined,
    },
    variant: FormFieldInputNumberFormattedDisplayValue.props.variant,
  },
  emits: ['update:selectedProduct'],
  model: {
    prop: 'selectedProduct',
    event: 'update:selectedProduct',
  },
  created() {
    this.$store.dispatch('products/subscribe');
    this.$store.dispatch('productVisibilities/subscribe');
    this.$store.dispatch('productCategories/subscribe');
    this.$store.dispatch('storagePlaces/subscribe');
    this.$store.dispatch('activityTypes/subscribe');
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps', {
      selectedFieldIds(state: ApplicationMapsState): string[] {
        return state[this.workflowKey as keyof ApplicationMapsState].selectedFields;
      },
    }),
    ...mapState('fields', {
      fields: 'data',
    }),
    selectedFields(): Field[] {
      return this.selectedFieldIds.map((fieldId: string) => this.fields[fieldId]).filter(notNullOrUndefined);
    },
    ...mapState('auth', {
      currentCompanies: 'currentCompanies',
    }),
    ...mapGetters('auth', {
      processOrders: 'currentProcessOrders',
    }),
    ...mapState('productVisibilities', {
      productVisibilities(state: ProductVisibilitiesState) {
        return Object.values(state.data);
      },
    }),
    ...mapState('productCategories', {
      productCategories(state: ProductCategoriesState) {
        return Object.values(state.data);
      },
    }),
    ...mapState('products', {
      availableProducts(state: ProductsState, getters: ProductGetters): DropdownItemsPerCategory[] {
        if (!this.isDataLoaded) {
          return [];
        }

        return getters.compatibleProductsToDropdownItems(
          this.activityType,
          this.currentCompanies,
          this.processOrders,
          this.productVisibilities,
          this.productCategories,
          this.selectedFields,
          this.storagePlaces,
          // prone to change
          [moment().unix()],
        );
      },
    }),
    ...mapGetters('products', ['allProducts']),
    ...mapState('storagePlaces', {
      storagePlaces(state: StoragePlacesState) {
        return Object.values(state.data);
      },
    }),
    isDataLoaded(): boolean {
      return (
        this.activityType &&
        this.currentCompanies &&
        this.processOrders &&
        this.productVisibilities &&
        this.productCategories
      );
    },
  },
});
