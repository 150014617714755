import { MoveActivityMode } from 'farmdok-rest-api';
import { MutationTree } from 'vuex';

import initialState from './state';
import { MoveActivityParameterWithResult, MoveActivityState } from './types';

const mutations: MutationTree<MoveActivityState> = {
  increaseCounterCurrentMoveActivityConflict(state: MoveActivityState) {
    state.counterCurrentMoveActivityConflict += 1;
  },
  increaseTrials(state: MoveActivityState) {
    state.trials += 1;
  },
  setSelectedCompanyId(state: MoveActivityState, selectedCompanyId: string) {
    state.selectedCompanyId = selectedCompanyId;
  },
  setSelectedProcessOrderId(state: MoveActivityState, selectedProcessOrderId: string) {
    state.selectedProcessOrderId = selectedProcessOrderId;
  },
  setKeepOption(state: MoveActivityState, keepOption: boolean) {
    state.keepOption = keepOption;
  },
  setDestinationFieldIdOnMoveActivityParametersWithResult(
    state: MoveActivityState,
    { id, destinationFieldId }: { id: string; destinationFieldId: string | null },
  ) {
    if (state.moveActivityParametersWithResult) {
      state.moveActivityParametersWithResult[id].destinationFieldId = destinationFieldId;
    }
  },
  setModeOnMoveActivityParametersWithResult(
    state: MoveActivityState,
    { id, mode }: { id: string; mode: MoveActivityMode },
  ) {
    if (state.moveActivityParametersWithResult) {
      state.moveActivityParametersWithResult[id].mode = mode;
    }
  },
  setMoveActivityParametersWithResult(
    state: MoveActivityState,
    moveActivityParamtersWithResult: Record<string, MoveActivityParameterWithResult>,
  ) {
    state.moveActivityParametersWithResult = moveActivityParamtersWithResult;
  },
  reset(state: MoveActivityState) {
    Object.assign(state, initialState());
  },
};

export default mutations;
