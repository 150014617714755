import subscribableDataStore from '@/shared/mixins/store/subscribableData';

export const dataUrl = '/admin/rest/note';
export const filter = ({ rootState }) => {
  if (!rootState.auth.currentProcessOrderName) {
    return false;
  }
  return ['processOrder.name', '=', rootState.auth.currentProcessOrderName];
};

export const additionalQueryParams = ({ rootState }) => {
  const params = ['permissionMode=fieldSharing'];
  if (!(rootState.auth.currentCompanies && rootState.auth.currentCompanies[0])) {
    return params;
  }
  params.push(`fieldSharingCompanyId=${rootState.auth.currentCompanies[0].id}`);
  return params;
};

const store = subscribableDataStore(dataUrl, { filter, additionalQueryParams });

export function initialState() {
  return {
    ...store.state,
  };
}

export default {
  ...store,
  state: initialState(),
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
  getters: {
    ...store.getters,
  },
};
