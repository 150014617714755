import { MutationTree } from 'vuex';

import usedItems from '@/shared/mixins/store/usedItems';

import { subscribableStore } from './common';
import { ActivityTypesState } from './types';

const mutations: MutationTree<ActivityTypesState> = {
  ...subscribableStore.mutations,
  ...usedItems.mutations,
};

export default mutations;
