<template>
  <!-- eslint-disable vue/no-unused-vars -->
  <ThePageSkeleton class="page-password-lost" scroll-y content-flex>
    <div class="page-password-lost__content">
      <FontAwesomeIcon v-if="fetching" class="text-medium m-auto" size="3x" spin :icon="['fad', 'spinner-third']" />
      <div v-else-if="passwordReset">
        <FontAwesomeIcon class="mb-6 text-primary" size="7x" :icon="['fal', 'paper-plane']" />
        <h1 class="page-check-mail__headline mb-4">
          {{ $t('Bitte überprüfe deine E-Mails!') }}
        </h1>
        <p class="mb-2">
          {{ messageEmailParts[0] }}
          <strong>{{ email }}</strong>
          {{ messageEmailParts[1] }}
        </p>
        <p class="mb-4">
          {{ $t('Klicke auf den Link in der E-Mail um dein Passwort zurückzusetzen.') }}
        </p>
        <p class="mb-4">
          {{ $t('Du hast keine E-Mail bekommen? Überprüfe bitte den Spam-Ordner.') }}
          {{ messageResendParts[0] }}
          <ButtonLink @click="reset">{{ messageResendParts[1] }}</ButtonLink>
          {{ messageResendParts[2] }}
        </p>
        <p v-if="userErrorMessage" class="text-danger">
          {{ userErrorMessage }}
        </p>
        <p>
          <ButtonLink :to="{ name: 'login' }">{{ $t('Zurück zum Login.') }}</ButtonLink>
        </p>
      </div>
      <div v-else>
        <LogoAuthPages />
        <h1 class="page-password-lost__headline">
          {{ $t('Passwort zurücksetzen') }}
        </h1>
        <p class="page-password-lost__subline">
          {{ $t('Wir senden dir ein E-Mail zu, mit Hilfe dessen du ein neues Passwort setzen kannst.') }}
          <ButtonLink :to="{ name: 'login' }">{{ $t('Zurück zum Login.') }}</ButtonLink>
        </p>
        <ValidationObserver v-slot="{ errors }" ref="validationObserver" slim>
          <BForm class="w-100" novalidate @submit.prevent="submit">
            <ValidationProvider
              v-slot="{ errors, dirty, failed }"
              rules="required|email"
              slim
              :name="$t('E-Mail-Adresse')"
            >
              <FormFieldInput
                v-model="emailInternal"
                ref="email"
                class="w-100 mt-0"
                name="email"
                type="email"
                autocomplete="email"
                variant="vertical-lg"
                :label="$t('E-Mail-Adresse')"
                :placeholder="$t('E-Mail-Adresse')"
                :disabled="fetching"
                :state="errors.length > 0 && !dirty ? false : null"
                @input="userErrorMessage = null"
              />
            </ValidationProvider>
            <p v-if="firstError(errors)" class="text-danger">{{ firstError(errors) }}&nbsp;</p>
            <ErrorUserMessage v-else show-first-only :error-user-message="userErrorMessage" />
            <Button type="submit" variant="primary" size="lg" block class="my-0" :disabled="fetching">
              <FontAwesomeIcon v-if="fetching" icon="circle-notch" class="text-white" spin />
              <span v-else>{{ $t('Passwort zurücksetzen') }}</span>
            </Button>
          </BForm>
        </ValidationObserver>
      </div>
    </div>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';

import LogoAuthPages from '@/auth/components/LogoAuthPages.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import veeValidateHelpers from '@/shared/mixins/form/veeValidateHelpers';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';

library.add(faCircleNotch, faPaperPlane, faSpinnerThird);

export default {
  name: 'PagePasswordLost',
  components: {
    ErrorUserMessage,
    ThePageSkeleton,
    LogoAuthPages,
    FormFieldInput,
    Button,
    ButtonLink,
  },
  mixins: [veeValidateHelpers, backendResponseErrorHelpers],
  props: {
    email: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      emailInternal: this.email,
      fetching: false,
      passwordReset: false,
      messageEmail: this.$t('Wir haben dir eine E-Mail an {email} gesendet.', { email: '#####' }),
      messageResend: this.$t(
        'Sollte die E-Mail nicht auffindbar sein, {linkStart}klicke hier, um sie nochmal zu senden.{linkEnd}',
        {
          linkStart: '#####',
          linkEnd: '#####',
        },
      ),
      defaultUserErrorMessage: this.$t('Unbekannter Fehler. Bitte überprüfe deine Internetverbindung.'),
    };
  },
  computed: {
    messageEmailParts() {
      return this.messageEmail.split('#####');
    },
    messageResendParts() {
      return this.messageResend.split('#####');
    },
  },
  mounted() {
    if (this.$refs.email == null) {
      return;
    }
    this.$refs.email.$el.querySelector('input').focus();
  },
  methods: {
    submit() {
      this.userErrorMessage = null;
      this.$refs.validationObserver.reset();
      this.$refs.validationObserver.handleSubmit(this.reset);
    },
    async reset() {
      if (this.fetching) {
        return;
      }
      this.fetching = true;

      // wait at least 200ms that the spinner looks better
      let response;
      await Promise.all([
        // eslint-disable-next-line no-promise-executor-return
        new Promise((resolve) => setTimeout(resolve, 200)),
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          try {
            const { data } = await axios.post('/admin/rest/user/password_lost', {
              version: '2.0',
              data: {
                email: this.emailInternal,
              },
            });
            response = data;
          } catch (error) {
            if (error != null && error.response != null) {
              response = error.response.data;
            }
          }
          resolve();
        }),
      ]);

      if (response != null && response.status === 'success') {
        this.passwordReset = true;
      } else {
        this.setUserErrorMessageFromResponse(response);
      }
      this.fetching = false;
    },
  },
};
</script>

<style scoped>
.page-password-lost__content {
  display: flex;
  max-width: calc(500px + 2rem);
  width: 100%;
  margin: auto;
  padding: 1rem 1rem 200px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.page-password-lost__headline {
  margin-bottom: var(--spacer_3);
  font-size: 28px;
  line-height: 38px;
}

.page-password-lost__subline {
  margin-bottom: var(--spacer_6);
}
</style>
