
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import type { PesticideIndicationsState } from '@/pesticideIndications/store/types';
import type { ApplicationMapsState } from '@/precision-farming/application-maps/store/types';
import { Field } from '@/shared/api/rest/models';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInputNumberFormattedDisplayValue from '@/shared/components/form/FormFieldInputNumberFormattedDisplayValue.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import isUnique from '@/shared/modules/isUniqueFilter';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

library.add(faTrashAlt);

/**
 * A form control that enables two-way binding of products independently of given context/domain. Some forms in precision farming utilize this control, but you can also use it separately, outside those forms.
 */
export default defineComponent({
  name: 'ControlPestSelection',
  components: { FormFieldDropdown },
  props: {
    workflowKey: {
      type: String as PropType<keyof ApplicationMapsState>,
      required: true,
    },
    selectedPest: {
      type: Object as PropType<DropdownItem<{ id: string; name: string }>>,
      required: false,
      default: () => ({ id: '', name: '' }),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: FormFieldInputNumberFormattedDisplayValue.props.variant,
    productId: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:selectedPest'],
  model: {
    prop: 'selectedPest',
    event: 'update:selectedPest',
  },
  created() {
    this.$store.dispatch('pests/subscribe');
  },
  computed: {
    ...mapState('pesticideIndications', {
      availablePests(state: PesticideIndicationsState, getters): DropdownItemsPerCategory[] {
        // start time is prone to change, but is sufficient for this workflow
        return getters.dropdownItems(this.productId, this.cropIds, [moment().unix()]);
      },
    }),
    ...mapState('precisionFarming/applicationMaps', {
      selectedFieldIds(state: ApplicationMapsState) {
        return state[this.workflowKey as keyof ApplicationMapsState].selectedFields;
      },
    }),
    ...mapState('fields', {
      fields: 'data',
    }),
    selectedFields(): Field[] {
      return this.selectedFieldIds.map((fieldId: string) => this.fields[fieldId]).filter(notNullOrUndefined);
    },
    cropIds(): string[] {
      return this.selectedFields
        .map((field: Field) => field.cropId)
        .filter(notNullOrUndefined)
        .filter(isUnique);
    },
  },
  watch: {
    productId(productId: string) {
      if (productId) {
        this.$store.dispatch('pesticideIndications/loadByIds', [productId]);
      }
    },
  },
});
