<template>
  <TableBase
    ref="table"
    context-menu
    hover-buttons
    class="table-fields-container"
    :id="id"
    :cells="columnDropdownCompaniesCells"
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :visible-rows="visibleRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    :hover-buttons-main-button-icon="['fas', 'arrow-to-left']"
    :hover-buttons-main-button-title="$t('Details anzeigen')"
    read-only
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @remove:row="(entry) => removeEntries([entry])"
    @afterScrollVertically="$emit('click:status')"
    @afterScrollHorizontally="$emit('click:status')"
    @reload="loadData"
    @rowHoverMainButton="showDetailPage"
  >
    <template #footer>
      <TableFooter
        :total-rows="Object.keys(tableData).length"
        :visible-rows="visibleRows.length"
        :selected-rows="selectedRows.length"
      >
        {{ $t('Summe Größe: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}
      </TableFooter>
    </template>
    <template #no-content>
      <NoContent
        :type="noContentType.type"
        :variant="noContentType.variant"
        :fetching="noContentFetching"
        :error-response-data="noContentError"
        read-only
        @import="$router.push({ name: 'fields/import' })"
        @copyFromPreviousProcessOrder="() => {}"
        @copyFromNextProcessOrder="() => {}"
        @refresh="refresh"
      />
    </template>
    <template v-if="!isDragover && !isUploading" #no-search-results>
      <NoContent type="search" read-only @clearAllFilters="clearAllFilters" />
    </template>
  </TableBase>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFlag } from '@fortawesome/pro-regular-svg-icons';
import { faArrowToLeft, faFlagCheckered } from '@fortawesome/pro-solid-svg-icons';

import columnDropdownCompanies from '@/auth/mixins/containers/columnDropdownCompanies';
import columnDropdownCustomers from '@/customers/mixins/containers/columnDropdownCustomers';
import columnDropdownCrops from '@/products/crops/mixins/containers/columnDropdownCrops';
import { REGION_GUIDS } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import subscribableTableData from '@/shared/handsontable/mixins/containers/featureSubscribableTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import columnDropdownVarieties from '@/varieties/mixins/containers/columnDropdownVarieties';

import NoContent from '../components/NoContent.vue';
import {
  amaCode,
  catchCropVariant,
  companyId,
  crop,
  customer,
  gwArea,
  kindOfUse,
  preCrop,
  variety,
} from '../handsontable/columns/columns';
import columnsTableSharedFields from '../handsontable/columns/tableSharedFields';
import calculateSizeTotal from '../mixins/calculateSizeTotal';
import columnDropdownForFields from '../mixins/containers/columnDropdownForFields';
import {
  STORE_KEY_AMA_CODES,
  STORE_KEY_CATCH_CROP_VARIANTS,
  STORE_KEY_GW_AREAS,
  STORE_KEY_KIND_OF_USE_TYPES,
} from '../mixins/containers/dropdownItemsForFields';

library.add(faFlagCheckered, faFlag, faArrowToLeft);

export default {
  name: 'TableSharedFieldsContainer',
  components: {
    TableFooter,
    TableBase,
    NoContent,
  },
  props: {
    filterString: {
      type: String,
      default: '',
    },
    fieldIdsWithHarvestingMeasure: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mixins: [
    tableContainerBase,
    subscribableTableData('sharedFields', { forceRefresh: true }),
    columnDropdownCompanies(companyId.key),
    columnDropdownCustomers({
      [customer.key]: 'fieldGroup.customer',
    }),
    columnDropdownCrops({
      [crop.key]: 'crop',
      [preCrop.key]: 'preCrop',
    }),
    columnDropdownVarieties({
      [variety.key]: 'variety',
    }),
    columnDropdownForFields({
      [kindOfUse.key]: STORE_KEY_KIND_OF_USE_TYPES,
      [catchCropVariant.key]: STORE_KEY_CATCH_CROP_VARIANTS,
      [amaCode.key]: STORE_KEY_AMA_CODES,
      [gwArea.key]: STORE_KEY_GW_AREAS,
    }),
    calculateSizeTotal,
  ],
  data() {
    return {
      columnDefinition: columnsTableSharedFields,
      isDragover: false,
      isUploading: false,
      noContentFetching: false,
      noContentError: null,
      nextCropYearHasFields: null,
      previousCropYearHasFields: null,
    };
  },
  computed: {
    id() {
      return ['hot-shared-fields', ...this.$store.state.auth.currentCompanies.map((company) => company.id)].join('-');
    },
    loading() {
      const customersLoading = this.$store.getters.currentCompaniesHaveFeatureVisible(
        availableFeatures.FEATURE_CUSTOMERS,
      )
        ? this.$store.getters['customers/loading']
        : false;

      return (
        this.$store.getters['products/crops/loading'] ||
        this.$store.getters['varieties/loading'] ||
        this.$store.getters['sharedFields/loading'] ||
        customersLoading
      );
    },
    cropYearNeighbourInfo() {
      const availableProcessOrderNames = this.$store.getters['auth/availableProcessOrderNames'];
      const current = this.$store.state.auth.currentProcessOrderName;
      const previous = String(Number(current) - 1);
      const next = String(Number(current) + 1);
      return {
        current,
        previous: availableProcessOrderNames.includes(previous) ? previous : null,
        next: availableProcessOrderNames.includes(next) ? next : null,
      };
    },
    noContentType() {
      if (this.previousCropYearHasFields === true) {
        return { type: 'cropYear', variant: 'copyFromPrevious' };
      }
      if (this.nextCropYearHasFields === true) {
        return { type: 'cropYear', variant: 'copyFromNext' };
      }
      if (this.previousCropYearHasFields === null || this.nextCropYearHasFields === null) {
        return { type: 'loading' };
      }
      if (
        this.$store.state.auth.currentCompanies.some((company) =>
          [REGION_GUIDS.AT, REGION_GUIDS.DE].includes(company.regionId),
        )
      ) {
        return { type: 'company', variant: 'import' };
      }
      return { type: 'company', variant: 'create' };
    },
    processOrdersReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_PROCESS_ORDER);
    },
    fieldsReadOnly() {
      return true;
    },
  },
  watch: {
    fieldIdsWithHarvestingMeasure() {
      if (this.$refs.table != null) {
        this.$refs.table.hotRender();
      }
    },
    tableData() {
      this.noContentError = null;
      this.checkForFieldsInNeighbourCropYears();
    },
    loading() {
      this.checkForFieldsInNeighbourCropYears();
    },
  },
  methods: {
    showDetailPage([fieldId]) {
      if (this.selectedRows.length > 0 && !this.selectedRows.includes(fieldId)) {
        this.$emit('update:selectedRows', [...this.selectedRows, fieldId]);
      }
      this.$router.push({ name: 'more/shared-fields/record', params: { fieldId } });
    },
    loadAdditionalData() {
      // sharedFields/subscribe will be called via mixin subscribableTableData
      this.$store.dispatch('products/crops/subscribe');
      this.$store.dispatch('varieties/subscribe');

      if (this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS)) {
        this.$store.dispatch('customers/subscribe');
      }
    },
    onClickStatus({ target, visualRow }) {
      this.$emit('click:status', {
        target,
        guid: this.$refs.table.visualRowToGuid(visualRow),
      });
    },
    async checkForFieldsInNeighbourCropYears() {
      this.previousCropYearHasFields = null;
      this.nextCropYearHasFields = null;
      if (this.loading || Object.keys(this.tableData).length > 0) {
        return;
      }
      const hasFields = await Promise.all(
        [this.cropYearNeighbourInfo.previous, this.cropYearNeighbourInfo.next].map(async (processOrderName) => {
          if (processOrderName == null) {
            return false;
          }
          return this.$store.getters['auth/currentCompaniesHaveFieldsInCropYear'](processOrderName);
        }),
      );
      [this.previousCropYearHasFields, this.nextCropYearHasFields] = hasFields;
    },
    clearAllFilters() {
      this.$refs.table.clearAllFilters();
    },
    async refresh() {
      this.noContentError = null;
      await this.$store.dispatch('sharedFields/refresh');
    },
  },
};
</script>

<style scoped>
.table-fields-container--is-dragover::after,
.table-fields-container--is-uploading::after {
  --from-top: 46px; /* should match the padding-top from NoContent.vue */
  content: attr(data-dragover-label);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  padding-top: calc(var(--from-top) + 200px);
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
  color: var(--black);
  /* eslint-disable-nextline max-len */
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 295 295" xmlns="http://www.w3.org/2000/svg"><mask id="a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="295" height="295"><circle cx="147.5" cy="147.5" r="147.5" fill="%23C4C4C4"/></mask><g mask="url(%23a)"><path fill="%23F7F7F7" d="M-9-8h312v312H-9z"/></g><path d="M199.605 91.91c-.926-13.422-12.034-24.298-25.687-24.298-1.85 0-3.703.23-5.322.694-7.406-9.256-18.745-15.504-31.704-15.504-19.67 0-36.1 13.884-40.034 32.397-13.885 5.785-22.91 19.207-22.91 34.248 0 20.596 16.431 37.026 37.026 37.026h77.754c18.282 0 33.323-14.81 33.323-33.323 0-14.116-9.256-26.612-22.446-31.24zm-10.877 53.455h-77.754c-14.347 0-25.918-11.57-25.918-25.918 0-12.96 9.488-23.834 22.216-25.454v-.463c0-16.2 13.19-29.621 29.62-29.621 12.265 0 22.91 7.637 27.307 18.513 2.545-2.314 6.016-3.702 9.72-3.702 8.098 0 14.81 6.71 14.81 14.81 0 2.777-.926 5.322-2.083 7.636.694 0 1.388-.231 2.082-.231 12.265 0 22.216 9.95 22.216 22.215s-9.95 22.215-22.216 22.215z" fill="%233CC960"/><path d="M155.76 116.83h-29.736l-4.188 68.476 33.924 1.256 18.01-1.256v-42.3l-4.818-16.334-13.192-9.842z" fill="%23C7EDD1" stroke="%233CC960"/><path d="M174.624 127.227l-12.635-12.484c-1.354-1.354-3.159-2.256-4.964-2.256H126.19a7.324 7.324 0 00-7.069 7.37v62.573a7.062 7.062 0 007.07 7.07h43.32c4.061 0 7.37-3.16 7.37-7.07v-50.088c0-1.805-.903-3.761-2.256-5.115zm-17.148-6.92l11.582 11.583h-11.582v-11.582zm12.184 62.123h-43.47v-62.573h24.066V135.5c0 1.956 1.655 3.61 3.61 3.61h15.794v43.32zm-23.164-35.648l-10.83 10.83c-1.053 1.203-.3 3.158 1.353 3.158h7.371v12.635c0 .903.752 1.805 1.805 1.805h3.61c.902 0 1.805-.902 1.805-1.805V160.77h7.22c1.654 0 2.407-1.955 1.354-3.158l-10.83-10.83c-.752-.752-2.106-.752-2.858 0z" fill="%233CC960"/></svg>');
  background-size: 295px 295px;
  background-repeat: no-repeat;
  background-position: center var(--from-top);
  background-color: var(--primary_opacity_25);
  pointer-events: none;
}

.table-fields-container--is-uploading::after {
  font-size: 1rem;
}
</style>
