import { Module } from 'vuex';

import { RemoveEntries } from '@/shared/mixins/store/removableData/types';
import { RootState } from '@/store/types';

import { DataEntry, RemovableDataState } from '../types';
import actions from './actions';
import initialState from './initialState';
import mutations from './mutations';

export default function removableData<Entry extends DataEntry>(
  removeEntries: RemoveEntries<Entry>,
): Module<RemovableDataState<Entry>, RootState> {
  return {
    namespaced: true,
    state: initialState<Entry>(),
    mutations,
    actions: actions(removeEntries),
  };
}
