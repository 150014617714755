import Vue from 'vue';

import '@/initI18n';

import { baseUrl } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

import PageManagedContractFields from './PageManagedContractFields.vue';
import PageManagedContractFieldsRestore from './PageManagedContractFieldsRestore.vue';
import PageManagedContractFiles from './PageManagedContractFiles.vue';
import PageManagedContractFilesUpload from './PageManagedContractFilesUpload.vue';
import PageManagedContractPartners from './PageManagedContractPartners.vue';
import PageManagedContracts from './PageManagedContracts.vue';

const tabs = () => {
  const currentTabs = ['digital-contracting/my-contracts', 'digital-contracting/open-calls'];
  if (store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_MANAGED_CONTRACTS)) {
    currentTabs.push('digital-contracting/managed-contracts');
  }
  return currentTabs;
};

export const viewManagedContractTabs = [
  'digital-contracting/managed-contracts/partners',
  'digital-contracting/managed-contracts/fields',
  'digital-contracting/managed-contracts/files',
];

export const viewManagedContractBeforeEnter = async (to, from, next) => {
  const { contractId } = to.params;
  await store.dispatch('managedContracts/contracts/subscribe');
  if (!Object.values(store.state.managedContracts.contracts.data).some((contract) => contract.id === contractId)) {
    next({ name: 'digital-contracting/managed-contracts' });
    return;
  }
  next();
};

export const viewManagedContractLabel = (route) => {
  let label = Vue.i18n.translate('Vertragsproduktion');
  const { contractId } = route.params;
  Object.values(store.state.managedContracts.contracts.data).some((contract) => {
    if (contract.id !== contractId) {
      return false;
    }
    label = contract.name;
    return true;
  });
  return label;
};

const viewManagedContractMetaOptions = {
  requiresAuth: true,
  tabs: viewManagedContractTabs,
  label: viewManagedContractLabel,
  noMultiCompany: true,
  featureControl: availableFeatures.FEATURE_MANAGED_CONTRACTS,
  back: {
    to: { name: 'digital-contracting/managed-contracts' },
    label: Vue.i18n.translate('Zurück zur Vertrags-Übersicht'),
  },
};

export default [
  {
    path: `${baseUrl}digital-contracting/managed-contracts`,
    name: 'digital-contracting/managed-contracts',
    component: PageManagedContracts,
    meta: {
      requiresAuth: true,
      tabs,
      label: Vue.i18n.translate('Vertragsproduktion'),
      labelTab: Vue.i18n.translate('Verwaltete Verträge'),
      noMultiCompany: true,
      featureControl: availableFeatures.FEATURE_MANAGED_CONTRACTS,
    },
  },
  {
    path: `${baseUrl}digital-contracting/managed-contracts/:contractId`,
    redirect: `${baseUrl}digital-contracting/managed-contracts/:contractId/partners`,
  },
  {
    path: `${baseUrl}digital-contracting/managed-contracts/:contractId/partners`,
    name: 'digital-contracting/managed-contracts/partners',
    component: PageManagedContractPartners,
    beforeEnter: viewManagedContractBeforeEnter,
    meta: {
      ...viewManagedContractMetaOptions,
      labelTab: Vue.i18n.translate('Partner'),
    },
  },
  {
    path: `${baseUrl}digital-contracting/managed-contracts/:contractId/fields`,
    name: 'digital-contracting/managed-contracts/fields',
    component: PageManagedContractFields,
    beforeEnter: viewManagedContractBeforeEnter,
    meta: {
      ...viewManagedContractMetaOptions,
      labelTab: Vue.i18n.translate('Felder'),
    },
  },
  {
    path: `${baseUrl}digital-contracting/managed-contracts/:contractId/files/upload`,
    name: 'digital-contracting/managed-contracts/files/upload',
    component: PageManagedContractFilesUpload,
    props: true,
    meta: {
      ...viewManagedContractMetaOptions,
      label: Vue.i18n.translate('Datei-Import'),
      subheader: Vue.i18n.translate('Dateien hochladen und mit einem Link mit anderen teilen'),
      labelTab: Vue.i18n.translate('Dateien'),
      back: {
        to: { name: 'digital-contracting/managed-contracts/files' },
        label: Vue.i18n.translate('Zurück zur Dateien-Übersicht'),
      },
    },
  },
  {
    path: `${baseUrl}digital-contracting/managed-contracts/:contractId/fields/restore`,
    name: 'digital-contracting/managed-contracts/fields/restore',
    component: PageManagedContractFieldsRestore,
    meta: {
      requiresAuth: true,
      label: Vue.i18n.translate('Felder wiederherstellen'),
      subheader: Vue.i18n.translate('Felder wiederherstellen, die in den letzten 90 Tagen gelöscht wurden'),
      back: {
        to: { name: 'digital-contracting/managed-contracts/fields' },
        label: Vue.i18n.translate('Zurück zur Vertragsansicht'),
      },
      featureControl: availableFeatures.FEATURE_MANAGED_CONTRACTS,
    },
  },

  {
    path: `${baseUrl}digital-contracting/managed-contracts/:contractId/files`,
    name: 'digital-contracting/managed-contracts/files',
    component: PageManagedContractFiles,
    beforeEnter: viewManagedContractBeforeEnter,
    meta: {
      ...viewManagedContractMetaOptions,
      labelTab: Vue.i18n.translate('Dateien'),
    },
  },
];
