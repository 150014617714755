<template>
  <ThePageSkeleton :page-feature-enabled="!fieldsReadOnly">
    <template #contentbar>
      <TheContentbar>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #left="{ contentbarLarge }">
          <ButtonDropdown
            v-show="selectedRows.length > 0"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonsUpdateSelection"
            @click="selectedRows = []"
          />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar class="mr-1" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <Button color="lightest" component-namespace-name="ButtonClose" @click="back">
            {{ $t('Schließen') }}
          </Button>
          <Button
            color="primary"
            component-namespace-name="ButtonRestore"
            :fetching="restoring"
            :disabled="fieldsReadOnly"
            @click="restore"
          >
            {{ $t('Wiederherstellen') }}
          </Button>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableFieldsRestoreContainer
        ref="table"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import TableFieldsRestoreContainer from './containers/TableFieldsRestoreContainer.vue';

export default {
  name: 'PageFieldsRestore',
  components: {
    TheContentbar,
    ThePageSkeleton,
    SearchBar,
    TableFieldsRestoreContainer,
    Button,
    ButtonDropdown,
  },
  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      buttonsUpdateSelection: [
        {
          text: this.$t('Auswahl aufheben'),
          handler: () => this.$refs.table.$refs.table.selectableRowsBatchAction('clear'),
        },
        {
          text: this.$t('Auswahl umkehren'),
          handler: () => this.$refs.table.$refs.table.selectableRowsBatchAction('reverse'),
        },
        {
          text: this.$t('Alle auswählen'),
          handler: () => this.$refs.table.$refs.table.selectableRowsBatchAction('all'),
        },
      ],
      restoring: false,
    };
  },
  computed: {
    fieldsReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_FIELDS);
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async restore() {
      this.restoring = true;
      const success = await this.$refs.table.restore();
      this.restoring = false;
      if (success) {
        this.back();
      }
    },
  },
};
</script>

<style scoped></style>
