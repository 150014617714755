import axios from 'axios';
import { ActionTree } from 'vuex';

import { isApiResponse as isApiResponseOldRestApi } from '@/shared/api/rest/models/apiResponse';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';
import { RootState } from '@/store/types';

import { DataEntry, SubscribableDataState } from '../types';
import { FetchAll, FetchByIds, RequiredFeatures } from './types';

function actions<Entry extends DataEntry, Resolve>(
  fetchAll: FetchAll<Entry, Resolve>,
  fetchByIds: FetchByIds<Entry>,
  requiredFeatures: RequiredFeatures = [],
): ActionTree<SubscribableDataState<Entry>, RootState> {
  return {
    /**
     * - If data hasn't been loaded yet, load it.
     * - If data has already been loaded and forceReset is true: do a reset of the loaded data first, then load it.
     * - If data has already been loaded and forceRefresh is true: re-load the data
     *   without resetting the current data (it gets overwritten when the fetching is done).
     * - forceReset trumps forceRefresh.
     * - If data has been loaded and neither forceReset or forceRefresh is true it returns immediatly.
     *
     * @param commit
     * @param dispatch
     * @param state
     * @param forceReset
     * @param forceRefresh
     * @return {Promise<void>}
     */
    async subscribe(
      { commit, dispatch, state },
      { forceReset, forceRefresh }: { forceReset: boolean; forceRefresh: boolean } = {
        forceReset: false,
        forceRefresh: false,
      },
    ) {
      if (state.loaded != null && !forceReset) {
        if (forceRefresh) {
          await dispatch('refresh');
        }
        return;
      }
      // eslint-disable-next-line no-promise-executor-return
      const promise = new Promise((callback) => commit('addObserver', callback));
      if (!state.fetching) {
        dispatch('loadAll', { forceReset });
      }
      await promise;
    },
    async refresh({ commit, dispatch, state }) {
      if (state.loaded == null) {
        return dispatch('subscribe');
      }
      // eslint-disable-next-line no-promise-executor-return
      const promise = new Promise((callback) => commit('addObserver', callback));
      if (!state.fetching) {
        dispatch('loadAll');
      }
      return promise;
    },
    async refreshByIds(context, ids) {
      const { rootGetters } = context;
      if (!rootGetters['features/currentCompaniesHaveFeaturesEnabled'](requiredFeatures)) {
        return getRestResponseData({});
      }

      let response;
      try {
        const apiResponse = await fetchByIds(ids, context);
        response = getRestResponseData(apiResponse);
      } catch (error) {
        response = getRestResponseData(error);
      }
      if (response.status === 'success') {
        context.commit('updateEntries', response);
      }
      return response;
    },
    async loadAll(context, { forceReset = false } = {}) {
      const { commit, dispatch, state, rootGetters } = context;

      if (!rootGetters['features/currentCompaniesHaveFeaturesEnabled'](requiredFeatures)) {
        commit('loadAll', { data: [] });
        commit('finishFetching');
        return;
      }

      if (forceReset) {
        await dispatch('reset');
      }

      const source = axios.CancelToken.source();
      commit('startFetching', source);

      try {
        const apiResponse = await fetchAll(context, { cancelToken: source.token });
        if (apiResponse.status !== 'success') {
          commit('fetchingError', source);
        } else {
          if (isApiResponseOldRestApi(apiResponse) && apiResponse.resolved) {
            await dispatch('loadResolved', apiResponse.resolved);
          }
          commit('loadAll', { data: apiResponse.data });
          commit('finishFetching', source);
        }
      } catch (error) {
        console.error(error);
        if (!axios.isCancel(error)) {
          commit('fetchingError', source);
        }
      } finally {
        state.observers.forEach((callback) => callback());
        commit('clearObservers');
      }
    },
    /**
     * Use this action in custom stores to add resolved objects to other stores.
     *
     * @override
     */
    loadResolved() {},
    reset({ commit, state }) {
      state.axiosSources.forEach((source) => source.cancel());
      commit('reset');
    },
  };
}
/**
 * Convert filter or resolve arrays into base64 encoded strings.
 */
export const dataToBase64 = (data: any) => (data != null ? btoa(JSON.stringify(data)) : '');

export default actions;
