function calculateBoundsCenter(feature: google.maps.Data.Feature): google.maps.LatLng {
  const bounds = new google.maps.LatLngBounds();
  const geometry = feature.getGeometry();
  if (geometry) {
    geometry.forEachLatLng((latLng: google.maps.LatLng) => bounds.extend(latLng));
    return bounds.getCenter();
  }
  return new google.maps.LatLng(0, 0);
}

export default calculateBoundsCenter;
