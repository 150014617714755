import { icon } from '@fortawesome/fontawesome-svg-core';
import type { IconName } from '@fortawesome/sharp-solid-svg-icons';
import { DaturaImportJobState } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import moment from 'moment';
import numbro from 'numbro';
import Vue from 'vue';

import type { HexCode } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { ColumnSettingsCheckboxOptional } from '@/shared/handsontable/renderers/types';
import { columnSorting as columnSortingDate } from '@/shared/handsontable/rework/cellTypes/date';
import { ColumnSettingsOptional } from '@/shared/handsontable/rework/cellTypes/optionalRenderer/types';
import defaultPlaceholderColumn from '@/shared/handsontable/rework/features/placeholderColumn/column';
import { FarmdokColumnSettings } from '@/shared/handsontable/rework/types';

import CellValue = Handsontable.CellValue;

const t = Vue.i18n.translate;
export const id: Handsontable.ColumnSettings = {
  data: 'id',
  header: {
    title: 'ID',
  },
  hiddenInPageSettings: true,
  hidden: true,
  width: 200,
};

export const selectColumn: ColumnSettingsCheckboxOptional = {
  data: 'select',
  type: 'farmdok.checkbox.optional',
  noHeaderContextMenu: true,
  fixedColumnsLeftUntilHere: true,
  hiddenInPageSettings: true,
  hidden: false,
  width: 45,
  disableColumnResize: true,
};

export const createdOn: ColumnSettingsOptional = {
  data: 'created',
  header: {
    title: t('Erstellt'),
  },
  readOnly: true,
  type: 'date',
  width: 120,
  hiddenInPageSettings: false,
  hidden: false,
  columnSorting: columnSortingDate,
  initialSortOrder: 'desc',
  className: 'bg-transparent',
};

export const assessmentName: ColumnSettingsOptional = {
  data: 'importJobName',
  type: 'text',
  header: {
    title: t('Assessment Name'),
  },
  readOnly: true,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  className: 'bg-transparent',
};

export const imageCount: ColumnSettingsOptional = {
  data: 'imageCount',
  header: {
    title: t('Anzahl Bilder'),
    description: t('Anzahl der hochgeladenen Bilder'),
  },
  type: 'numeric',
  readOnly: true,
  width: 100,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = numbro(value ?? 0).format({
      mantissa: 0,
    });
    td.classList.add('htRight');
    return td;
  },
};

export const progress: ColumnSettingsOptional = {
  data: 'progress',
  header: {
    title: t('Fortschritt'),
    description: t('Bereits analysierte Bilder'),
  },
  readOnly: true,
  type: 'numeric',
  width: 100,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = numbro(value).format({
      mantissa: 0,
      postfix: ' %',
    });
    td.classList.add('htRight');
    return td;
  },
};

export const state: ColumnSettingsOptional = {
  data: 'stateDisplayValue',
  header: {
    title: t('Status'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 200,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    const [statusIcon] = icon({ prefix: 'fas', iconName: iconType(value) }).node;
    statusIcon.setAttribute('style', `color:${iconColor(value)}`);

    // eslint-disable-next-line no-param-reassign
    td.innerHTML = `<div class="d-flex justify-content-between">${value ?? '-'} ${statusIcon.outerHTML}</div>`;
    return td;
  },
};

function iconType(jobState: DaturaImportJobState): IconName {
  switch (jobState) {
    case t('UploadPending'):
      return 'clock';
    case t('Calculating'):
      return 'circle-dot';
    case t('Finished'):
      return 'circle-check';
    case t('UploadExpired'):
      return 'circle-xmark';
    case t('Failed'):
      return 'circle-dot';
    default:
      throw new Error(`State ${jobState} is not supported.`);
  }
}

function iconColor(jobState: DaturaImportJobState): HexCode {
  switch (jobState) {
    case t('UploadPending'):
      return '#13353C';
    case t('Calculating'):
      return '#3CC961';
    case t('Finished'):
    case t('UploadExpired'):
      return '#828282';
    case t('Failed'):
      return '#FF614C';
    default:
      throw new Error(`State ${jobState} is not supported.`);
  }
}

export const completed: ColumnSettingsOptional = {
  data: 'completed',
  header: {
    title: t('Abgeschlossen'),
  },
  readOnly: true,
  type: 'date',
  width: 200,
  hiddenInPageSettings: false,
  hidden: false,
  columnSorting: columnSortingDate,
  initialSortOrder: 'desc',
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = value ? moment.unix(value).format('L LT') : '-';
    return td;
  },
};

const placeholderColumn = { ...defaultPlaceholderColumn, width: 40 };

export const columns: FarmdokColumnSettings[] = [
  id,
  selectColumn,
  createdOn,
  assessmentName,
  imageCount,
  progress,
  state,
  completed,
  placeholderColumn,
];

export default columns;
