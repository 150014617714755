import { Module } from 'vuex';

import { RootState } from '@/store/types';

import getters from './getters';
import mutations from './mutations';
import { FeaturesState } from './types';

export function initialState(): FeaturesState {
  return {
    availableFeaturesByCompanyId: {},
  };
}

const vuexModule: Module<FeaturesState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
};

export default vuexModule;
