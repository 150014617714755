import Vue from 'vue';

import removableData, { initialState as initialStateRemovable } from '@/shared/mixins/store/removableData';
import restorableData, { initialState as initialStateRestorable } from '@/shared/mixins/store/restorableData';
import subscribableData from '@/shared/mixins/store/subscribableData';
import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import syncableData from '@/shared/mixins/store/syncableData';
import initialStateSyncable from '@/shared/mixins/store/syncableData/initialState';

export const dataUrl = '/admin/rest/fieldGroup';
export const filter = ({ state, rootState }) => {
  if (rootState.auth.currentCompanies.length === 0) {
    return false;
  }
  const currentFilter = ['AND', ['companyId', 'IN', rootState.auth.currentCompanies.map((company) => company.id)]];
  if (!state.addArchived) {
    currentFilter.push(['archived', '=', 0]);
  }
  return currentFilter;
};

export function initialState() {
  return {
    ...initialStateSubscribable(),
    ...initialStateSyncable(),
    ...initialStateRemovable(),
    ...initialStateRestorable(),
    addArchived: false,
  };
}

export const PropertyCompanyId = 'companyId';
export const PropertyNumber = 'mfa';
export const PropertyCustomerId = 'customerId';
export const PropertyName = 'name';
export const PropertySize = 'fieldSize';
export const PropertyArchived = 'archived';
export const PropertyComment = 'userComment';

export const model = {
  [PropertyCompanyId]: null,
  [PropertyNumber]: null,
  [PropertyCustomerId]: null,
  [PropertyName]: null,
  [PropertySize]: null,
  [PropertyArchived]: 0,
  [PropertyComment]: null,
};

export const modelDefinition = {
  [PropertySize]: {
    type: 'number',
    nullable: true,
  },
};

const subscribableStore = subscribableData(dataUrl, { filter });
const syncableStore = syncableData(dataUrl, { model, modelDefinition });
const removableStore = removableData(dataUrl);

export const prepareEntryForSync = (entry, { rootState }) => {
  if (entry[PropertyCompanyId] != null || rootState.auth.currentCompanies.length !== 1) {
    return entry;
  }
  return {
    ...model,
    ...entry,
    [PropertyCompanyId]: rootState.auth.currentCompanies[0].id,
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    ...subscribableStore.mutations,
    ...syncableStore.mutations,
    ...removableStore.mutations,
    ...restorableData.mutations,
    setAddArchived(state, addArchived) {
      Vue.set(state, 'addArchived', addArchived);
    },
  },
  actions: {
    ...subscribableStore.actions,
    ...syncableStore.actions,
    ...removableStore.actions,
    ...restorableData.actions,
    async insertNewEntry(context, { entry }) {
      const { commit } = context;
      const newEntry = prepareEntryForSync(entry, context);
      commit('insert', { entry: newEntry });
    },
    async insertAndSyncNewEntry(context, { entry }) {
      const { dispatch } = context;
      const newEntry = prepareEntryForSync(entry, context);
      await dispatch('insertNewEntry', { entry: newEntry });
      await dispatch('syncAll');
    },
    async updateAndSyncEntry(context, { entry }) {
      const { commit, dispatch } = context;
      const updatedEntry = prepareEntryForSync(entry, context);
      commit('updateEntry', { entry: updatedEntry });
      await dispatch('syncAll');
    },
    addArchived({ state, commit, dispatch }) {
      if (state.addArchived) {
        return;
      }
      dispatch('reset');
      commit('setAddArchived', true);
    },
  },
  getters: {
    ...subscribableStore.getters,
  },
};
