import Handsontable from 'handsontable';

import EmptyRenderer from '../../rework/cellTypes/emptyRenderer/EmptyRenderer';
import CheckboxRenderer from '../checkboxRenderer/CheckboxRenderer';
import { ColumnSettingsCheckboxOptional } from '../types';

export default function OptionalCheckboxRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: boolean | null,
  cellProperties: ColumnSettingsCheckboxOptional,
  ...args: unknown[]
) {
  if (typeof cellProperties.renderNone === 'function' && cellProperties.renderNone(visualRow, instance)) {
    Handsontable.dom.empty(TD);
    // eslint-disable-next-line no-param-reassign
    TD.style.display = 'none';
    return;
  }

  if (typeof cellProperties.renderEmpty === 'function' && cellProperties.renderEmpty(visualRow, instance)) {
    EmptyRenderer(
      instance,
      TD,
      visualRow,
      visualCol,
      prop,
      value,
      cellProperties as unknown as Handsontable.CellProperties,
    );
    return;
  }

  CheckboxRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args);
}
