import numbro from '@/initNumbro';
import parseNumber from '@/shared/modules/parseNumber';

export default function displayValue(value: number | string | null): string {
  if (!value) return '';

  try {
    const formattedValue = typeof value === 'string' ? numbro(parseNumber(value)).format() : numbro(value).format();

    return formattedValue;
  } catch {
    // do nothing
  }

  return `${value}`;
}
