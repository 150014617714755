<template>
  <ThePageSkeleton
    :page-feature-title="$router.currentRoute.meta.label"
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <template #right="{ contentbarLarge }">
          <ProcessOrderSwitch class="ml-4" :slim="!contentbarLarge" />
          <CompanySwitch class="ml-4" :slim="!contentbarLarge" />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <div class="w-100 h-100 d-flex">
        <FontAwesomeIcon class="text-medium m-auto" size="3x" spin :icon="['fad', 'spinner-third']" />
      </div>
    </template>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import ProcessOrderSwitch from '@/shared/components/ProcessOrderSwitch.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

library.add(faSpinnerThird);

export default {
  name: 'PageCropRotation',
  components: {
    ThePageSkeleton,
    TheContentbar,
    ProcessOrderSwitch,
    CompanySwitch,
  },
  data() {
    return {};
  },
  computed: {
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_CROP_ROTATION);
    },
  },
  watch: {
    soilSamplesEnabled: {
      handler() {
        if (this.currentCompaniesHaveFeature.enabled === true) {
          this.$router.replace({ name: 'crop-rotation--agridat' });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
