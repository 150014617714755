import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faDotCircle } from '@fortawesome/pro-solid-svg-icons';
import Handsontable from 'handsontable';

import setCellTitleAttribute from '@/shared/handsontable/renderers/helpers/setCellTitleAttribute';

library.add(faDotCircle);

function FarmNameRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  const displayValue =
    typeof cellProperties.getRendererValue === 'function' ? cellProperties.getRendererValue({ value, row }) : value;
  setCellTitleAttribute(TD, displayValue);

  let span = TD.querySelector('span.hot-floating-cell-icon');
  if (span == null) {
    span = document.createElement('span');
    span.className = 'hot-floating-cell-icon';
  }
  Handsontable.renderers.TextRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);

  TD.classList.add('hot-cell-with-floating-cell-icon');

  span.onmousedown = (event) => {
    event.stopPropagation();
  };
  span.onclick = () => {
    if (typeof cellProperties.onClick === 'function') {
      cellProperties.onClick({ target: span, visualRow: row });
    }
  };

  let status = null;
  if (typeof cellProperties.getStatus === 'function') {
    status = cellProperties.getStatus(row);
  }

  span.className = 'hot-floating-cell-icon';
  span.innerHTML = '';
  const [statusIcon] = icon({ prefix: 'fas', iconName: 'dot-circle' }).node;
  span.append(statusIcon);
  span.classList.add(`hot-floating-cell-icon__partner-status--${status}`);

  TD.append(span);
}

Handsontable.cellTypes.registerCellType('farmName', {
  renderer: FarmNameRenderer,
});
