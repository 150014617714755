import groupby from 'lodash.groupby';
import moment from 'moment';
import Vue from 'vue';
import { Dispatch, GetterTree } from 'vuex';

import numbro from '@/initNumbro';
import { type Feature, Heatmap } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { ACTIVITY_ROUGH_GUIDS } from '@/shared/constants';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { RootState } from '@/store/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import { ZONE_GENERATION_MODE_SATELLITE, ZONE_GENERATION_MODE_UPLOAD } from '../../store/baseWorkflowStore/common';
import { Getters as BaseWorkflowGetters } from '../../store/baseWorkflowStore/getters';
import { WorkflowKeyForRoutes } from '../../types';
import {
  ApplicationMapsSprayingState,
  Calculation,
  IndexType,
  MultipolyTimestamp,
  MultipolyTimestampAvailableData,
  Zone,
} from './types';

export type Getters = BaseWorkflowGetters & {
  timestampsByCoverageRatio(coverageRatio: number): MultipolyTimestamp[];
  paginationNextEnabled: boolean;
  paginationNextDisabledReason: string | null | undefined;
  selectedIndexType: IndexType;
  coverageRatio: number;
  selectedQuantisationCode: string;
  heatmapTimestampSelectedIndex: boolean;
  heatmapTimestampManuallySelected: boolean;
  calculation: Calculation;
  productListIsProperlyPopulated: boolean;
  zonesByUploadedZones: Zone[];
  zonesByHeatmaps: Zone[];
  zones: Zone[];
  areas: number[];
  toClientId(fieldId: string): string;
  vegetationValues: number[];
  minVegetation: number;
  maxVegetation: number;
  vegetationPerZone: number[];
  isOverwritten: boolean;
  reducedVegetationPerZone: number[];
  weightedVegetation: number;
  reducedWeightedVegetation: number;
  reducedSprayMixPerZone: number[];
  atLeastOneProductSelected: boolean;
};

const moduleGetters: GetterTree<ApplicationMapsSprayingState, RootState> = {
  ...baseWorkflowStore.getters,
  timestampsByCoverageRatio:
    (state: ApplicationMapsSprayingState) =>
    (coverageRatio: number): MultipolyTimestamp[] =>
      Object.entries(state.multiPolyTimestamps.current)
        .filter(([key]) => key.endsWith(`${coverageRatio}`))
        .map(([, value]) => value),
  // ui states
  paginationNextEnabled: (state, getters): boolean => {
    if (!state.selectedFields.length) {
      return false;
    }

    if (!Object.keys(state.multiPolyTimestamps.current).length) {
      return false;
    }

    if (
      state.paginationStep === 2 &&
      state.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD &&
      Object.values(state.uploadedZonesByFilename).length === 0
    ) {
      return false;
    }

    if (state.paginationStep === 2 && state.heatmaps.fetching) {
      return false;
    }

    if (state.paginationStep === 3 && !getters.productListIsProperlyPopulated) {
      return false;
    }

    if (state.paginationStep === 4 && !state.stepsCompleted) {
      return false;
    }

    return true;
  },
  paginationNextDisabledReason: (state, getters: Getters): string | null | undefined => {
    if (getters.paginationNextEnabled) {
      return null;
    }

    switch (state.paginationStep) {
      case 1:
        return Vue.i18n.translate('Bitte w\u00e4hle ein Feld aus, bevor du fortfährst.');
      case 3:
        return Vue.i18n.translate('Bitte w\u00e4hle ein Produkt und eine Produktmenge aus, bevor du fortfährst.');
      default:
        return null;
    }
  },
  // satellite images
  selectedIndexType: (state: ApplicationMapsSprayingState): IndexType => state.selectedIndexType,
  coverageRatio: (state: ApplicationMapsSprayingState, getters: Getters): number =>
    getters.selectedIndexType.includes('DNN_') ? 0 : 2,
  selectedQuantisationCode: (state) => state.selectedQuantisationCode,
  selectedHeatmapTimestamp: (state) => state.selectedHeatmapTimestamp,
  selectedHeatmapDbId: (state) => state.selectedHeatmapDbId,
  heatmapTimestampSelectedIndex: (state) => state.heatmapTimestampSelectedIndex,
  heatmapTimestampManuallySelected: (state) => state.heatmapTimestampManuallySelected,
  availableTimestamps: (state, getters: Getters) => {
    const timeArray: number[] = [];
    const mapping: Record<number, string> = {};

    getters.timestampsByCoverageRatio(getters.coverageRatio).forEach((polygon: MultipolyTimestamp) => {
      polygon.availableData.forEach((data: MultipolyTimestampAvailableData) => {
        const currentTimestamp = data.timestamp;
        const currentDbID = data.dbId;
        const currentDay = moment.unix(data.timestamp).startOf('day').unix();
        let dayIsInArray = false;

        timeArray.forEach((timestamp) => {
          const day = moment.unix(timestamp).startOf('day').unix();
          if (currentDay === day) {
            dayIsInArray = true;
          }
        });

        if (!dayIsInArray) {
          timeArray.push(currentTimestamp);
          mapping[currentTimestamp] = currentDbID;
        }
      });
    });
    return {
      timeArray: timeArray.sort((a, b) => a - b),
      mapping,
    };
  },
  // uploaded zones
  uploadedZonesByFilename: (state) => state.uploadedZonesByFilename,
  // dosage calculation
  calculation: (state) => state.calculation,
  zonesByUploadedZones(state) {
    const zones: Record<string, Zone> = {};
    Object.values(state.uploadedZonesByFilename).forEach((geoJson) => {
      geoJson.features.forEach((feature) => {
        const color = feature.properties.fill;
        if (zones[color] == null) {
          zones[color] = {
            size: 0,
            color,
            name: numbro(feature.properties.RATE || 0).format(),
            rate: feature.properties.RATE || 0,
          };
        }
        if (typeof feature.properties.size === 'number') {
          zones[color].size += feature.properties.size / 10000;
        }
      });
    });
    return Object.values(zones)
      .filter((zone) => zone.size > 0)
      .sort((first, second) => {
        if (second.rate && first.rate) {
          return second.rate - first.rate;
        }
        return -1;
      });
  },
  zones(state, getters: Getters): Zone[] {
    if (state.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
      return getters.zonesByHeatmaps;
    }
    if (state.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
      return getters.zonesByUploadedZones;
    }
    return [];
  },
  /**
   * override the default behavior
   */
  zonesByHeatmaps(state: ApplicationMapsSprayingState, getters: Getters): Zone[] {
    const toZone = (feature: Feature) => ({
      name: `${feature.vegetation.value_q}`,
      rate: feature.vegetation.value_q,
      size: feature.area,
      color: feature.properties.fill,
    });
    const clientIds = state.selectedFields.map(getters.toClientId);
    const allZones = Object.entries(state.heatmaps.current)
      .filter(([key]) => clientIds.includes(key))
      .map(([, value]) => value)
      .flatMap((heatmap: Heatmap) => heatmap.features)
      .map<Zone>(toZone)
      .filter((zone: Zone) => zone.size > 0);

    const toHectares = (zone: Zone): Zone => ({ ...zone, size: zone.size / 10000 });
    const sumArea = (zones: Zone[]): Zone =>
      zones.map(toHectares).reduce((z1, z2) => ({ ...z1, size: z1.size + z2.size }));

    return Object.values(groupby(allZones, 'name'))
      .map<Zone>(sumArea)
      .sort((first, second) => {
        const firstName: string | number = first.name;
        const secondName: string | number = second.name;

        if (typeof firstName === 'string' && typeof secondName === 'string') {
          if (firstName.includes('snow')) {
            return 1;
          }
          if (secondName.includes('snow')) {
            return -1;
          }
          if (firstName.includes('cloud')) {
            return 1;
          }
          if (secondName.includes('cloud')) {
            return -1;
          }
        }

        // if both names are numeric, convert them to numbers and use those for comparison
        const firstZoneName = Number.parseFloat(first.name);
        const secondZoneName = Number.parseFloat(second.name);
        if (Number.isFinite(firstZoneName) && Number.isFinite(secondZoneName)) {
          return firstZoneName > secondZoneName ? -1 : 1;
        }

        return 1;
      });
  },
  toClientId(state: ApplicationMapsSprayingState): (fieldId: string) => string {
    const { selectedHeatmapTimestamp, selectedIndexType, selectedQuantisationCode } = state;
    return (fieldId: string) =>
      [fieldId, selectedHeatmapTimestamp, selectedIndexType, selectedQuantisationCode].join('_');
  },
  areas(state: ApplicationMapsSprayingState, getters: Getters): number[] {
    return getters.zones.map(({ size }) => size);
  },
  vegetationValues(state: ApplicationMapsSprayingState, getters: Getters): number[] {
    return getters.zones.map(({ rate }) => rate).filter(notNullOrUndefined);
  },
  minVegetation(state: ApplicationMapsSprayingState, getters: Getters): number {
    return Math.min(...getters.vegetationValues);
  },
  maxVegetation(state: ApplicationMapsSprayingState, getters: Getters): number {
    return Math.max(...getters.vegetationValues);
  },
  /**
   * For evey cluster calculates Area * q_value
   */
  vegetationPerZone(state: ApplicationMapsSprayingState, getters: Getters): number[] {
    return getters.zones.map(({ rate, size }) => (rate ?? 1) * size);
  },
  reducedVegetationPerZone(state: ApplicationMapsSprayingState, getters: Getters): number[] {
    if (getters.isOverwritten) {
      return state.calculation.overwrite.reducedVegetationPerZone;
    }

    const { vegetationValues } = getters;
    const { reduction } = state.calculation;
    return normalize(vegetationValues).map((normalized: number) => normalized * reduction + (1 - reduction));
  },
  weightedVegetation(state: ApplicationMapsSprayingState, getters: Getters): number {
    const { vegetationPerZone, areas } = getters;
    const totalVegetation = vegetationPerZone.reduce((a, b) => a + b, 0);
    const totalArea = areas.reduce((a, b) => a + b, 0);
    return totalVegetation / totalArea;
  },
  reducedWeightedVegetation(state: ApplicationMapsSprayingState, getters: Getters): number {
    const { minVegetation, maxVegetation, weightedVegetation } = getters;
    const normalized = doNormalize(weightedVegetation, minVegetation, maxVegetation);
    const { reduction } = state.calculation;
    return normalized * reduction + (1 - reduction);
  },
  reducedSprayMixPerZone(state: ApplicationMapsSprayingState, getters: Getters): number[] {
    if (getters.isOverwritten) {
      return state.calculation.overwrite.reducedSprayMixPerZone;
    }

    const { reducedVegetationPerZone, reducedWeightedVegetation } = getters;
    return reducedVegetationPerZone.map(
      (reduction) => (reduction * state.calculation.sprayMix) / reducedWeightedVegetation,
    );
  },
  isOverwritten(state: ApplicationMapsSprayingState): boolean {
    return !!state.calculation.overwrite.reducedVegetationPerZone.length;
  },
  /**
   * Override the default behavior
   */
  heatmapsOfSelectedFields: (state: ApplicationMapsSprayingState, getters: Getters): Heatmap[] => {
    const isInSelectedFields = ([key]: [string, Heatmap]): boolean =>
      state.selectedFields.find((fieldName: string) => key.startsWith(fieldName)) !== undefined;
    return Object.entries(getters.currentHeatmaps)
      .filter(isInSelectedFields)
      .filter(([key]) => key.includes(`${state.selectedHeatmapTimestamp}`))
      .filter(([key]) => key.includes(state.selectedIndexType))
      .filter(([key]) => key.includes(state.selectedQuantisationCode))
      .map(([, value]) => value);
  },
  taskDataAsync: (state, getters: Getters, rootState, rootGetters) => async (dispatch: Dispatch) => {
    await dispatch('activityTypes/subscribe');

    const { currentHeatmaps, selectedTaskDate } = getters;
    const {
      zoneGenerationMode,
      uploadedZonesByFilename,
      selectedFields,
      selectedIndexType,
      selectedHeatmapDbId,
      selectedHeatmapTimestamp,
      selectedQuantisationCode,
      calculation,
      selectedCompany,
    } = state;

    const companyId = selectedCompany.id ? selectedCompany.id : rootState.auth.currentCompanies[0].id;

    let geoJson = null;
    if (zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
      geoJson = currentHeatmaps;
    }
    if (zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
      geoJson = uploadedZonesByFilename;
    }
    const processOrder = await rootGetters['auth/processOrderByCompanyIdAndNameAndTypeAsync'](
      rootState.auth.currentCompanies[0].id,
      rootState.auth.currentProcessOrderName,
      'service',
    );

    const timeStartDate = selectedTaskDate || new Date();
    const timeStart = Math.floor(timeStartDate.getTime() / 1000);

    const taskData = {
      version: '2.0',
      data: {
        companyId,
        processOrderId: processOrder.id,
        activityId: rootGetters['activityTypes/byRoughAndFineId'](ACTIVITY_ROUGH_GUIDS.PROTECT).id,
        timeStart,
        state: 'planned',
        fields: selectedFields.map((guid) => ({
          fieldId: guid,
          processedArea: rootGetters.fields[guid].fieldSize,
        })),
        workingMeans: [],
        applicationMap: {
          additionalData: {
            fields: selectedFields.map((guid) => {
              const field = {
                id: guid,
              };
              if (zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
                // @ts-ignore // TODO: fix this
                field.geoJsonId = Object.keys(currentHeatmaps).find((heatmapId) => heatmapId.startsWith(guid)) || null;
              }
              return field;
            }),
            zoneGenerationMode,
            selectedIndexType,
            selectedHeatmapDbId,
            selectedHeatmapTimestamp,
            selectedQuantisationCode,
            calculation,
          },
          geoJson,
          companyId,
          workflowKey: WorkflowKeyForRoutes.SPRAYING,
        },
      },
    };

    return taskData;
  },
  productListIsProperlyPopulated(state: ApplicationMapsSprayingState): boolean {
    const areSelected = state.calculation.products.every((prod) => !!prod.product.id);
    const amountIsSet = state.calculation.products.every((prod) => !!prod.amount);
    return areSelected && amountIsSet;
  },
};

/**
 * Given a range of numbers [a, b], normalizes the entire range to range [0, 1]. The method is known as [Min-Max Normalization]{@link https://www.aampe.com/blog/how-to-normalize-data-in-excel#:~:text=Implementing%20Min%2DMax%20Normalization,min)%2F(max%2Dmin).}.
 * Normalization reduces the overhead when computing values that are differently distributed.
 * @param range an array of numbers [a, b]. Order of element in the array is irrelevant
 * @returns an array of numbers containing all normalized values in [0, 1]
 */
function normalize(range: number[]): number[] {
  const min = Math.min(...range);
  const max = Math.max(...range);
  return range.map((v) => doNormalize(v, min, max));
}

/**
 * Normalizes a single value from an arbitrary range [a, b] to [0, 1]. The method is known as [Min-Max Normalization]{@link https://www.aampe.com/blog/how-to-normalize-data-in-excel#:~:text=Implementing%20Min%2DMax%20Normalization,min)%2F(max%2Dmin).}.
 * Because this method is "blind" to the original range. The caller is ought to provide both the min and max value of the original range.
 * @param v target value to be normalized, where v ∊ [a,b]
 * @param vMin the minimum value in range [a,b]. I.e. a
 * @param vMax the maximum value in range [a,b]. I.e. b
 */
function doNormalize(v: number, vMin: number, vMax: number) {
  return (v - vMin) / (vMax - vMin);
}

export default moduleGetters;
