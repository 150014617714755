import { AxiosInstance } from 'axios';
import {
  ActivitiesApi,
  ActivityImportApi,
  ApplicationMapsApi,
  CnhApi,
  CultivationPlanApi,
  DaturaApi,
  EquipmentApi,
  FertilizationPlanApi,
  FieldsApi,
  MapApi,
  PestsApi,
  PlantVarietiesApi,
  ProductCategoriesApi,
  ProductsApi,
  RuleCheckApi,
  Sen4Api,
  StockMovementApi,
  StoragePlacesApi,
  TracksApi,
  UploadApi,
  WeatherApi,
} from 'farmdok-rest-api';
import _Vue from 'vue';
import { Store } from 'vuex';

import { origin } from '@/shared/constants';

type Options = {
  axiosInstance?: AxiosInstance;
  store: Store<any>;
};

export type Api = {
  applicationMapsApi: ApplicationMapsApi;
  activitiesApi: ActivitiesApi;
  cultivationPlanApi: CultivationPlanApi;
  equipmentsApi: EquipmentApi;
  fertilizationPlanApi: FertilizationPlanApi;
  productsApi: ProductsApi;
  productCategoriesApi: ProductCategoriesApi;
  pestsApi: PestsApi;
  ruleCheckApi: RuleCheckApi;
  storagePlacesApi: StoragePlacesApi;
  stockMovementsApi: StockMovementApi;
  plantVarietiesApi: PlantVarietiesApi;
  mapApi: MapApi;
  cnhApi: CnhApi;
  activityImportApi: ActivityImportApi;
  fieldsApi: FieldsApi;
  tracksApi: TracksApi;
  sen4Api: Sen4Api;
  weatherApi: WeatherApi;
  uploadApi: UploadApi;
  daturaApi: DaturaApi;
};

export default {
  install(Vue: typeof _Vue, { axiosInstance, store }: Options) {
    const activitiesApi = new ActivitiesApi(undefined, `${origin}/rest`, axiosInstance);
    const applicationMapsApi = new ApplicationMapsApi(undefined, `${origin}/rest`, axiosInstance);
    const cultivationPlanApi = new CultivationPlanApi(undefined, `${origin}/rest`, axiosInstance);
    const equipmentsApi = new EquipmentApi(undefined, `${origin}/rest`, axiosInstance);
    const fertilizationPlanApi = new FertilizationPlanApi(undefined, `${origin}/rest`, axiosInstance);
    const productsApi = new ProductsApi(undefined, `${origin}/rest`, axiosInstance);
    const productCategoriesApi = new ProductCategoriesApi(undefined, `${origin}/rest`, axiosInstance);
    const pestsApi = new PestsApi(undefined, `${origin}/rest`, axiosInstance);
    const ruleCheckApi = new RuleCheckApi(undefined, `${origin}/rest`, axiosInstance);
    const storagePlacesApi = new StoragePlacesApi(undefined, `${origin}/rest`, axiosInstance);
    const stockMovementsApi = new StockMovementApi(undefined, `${origin}/rest`, axiosInstance);
    const plantVarietiesApi = new PlantVarietiesApi(undefined, `${origin}/rest`, axiosInstance);
    const mapApi = new MapApi(undefined, `${origin}/rest`, axiosInstance);
    const cnhApi = new CnhApi(undefined, `${origin}/rest`, axiosInstance);
    const activityImportApi = new ActivityImportApi(undefined, `${origin}/rest`, axiosInstance);
    const fieldsApi = new FieldsApi(undefined, `${origin}/rest`, axiosInstance);
    const tracksApi = new TracksApi(undefined, `${origin}/rest`, axiosInstance);
    const sen4Api = new Sen4Api(undefined, `${origin}/rest`, axiosInstance);
    const weatherApi = new WeatherApi(undefined, `${origin}/rest`, axiosInstance);
    const uploadApi = new UploadApi(undefined, `${origin}/rest`, axiosInstance);
    const daturaApi = new DaturaApi(undefined, `${origin}/rest`, axiosInstance);

    const api: Api = {
      activitiesApi,
      applicationMapsApi,
      cultivationPlanApi,
      equipmentsApi,
      fertilizationPlanApi,
      productsApi,
      productCategoriesApi,
      pestsApi,
      ruleCheckApi,
      storagePlacesApi,
      stockMovementsApi,
      plantVarietiesApi,
      mapApi,
      cnhApi,
      activityImportApi,
      fieldsApi,
      tracksApi,
      sen4Api,
      weatherApi,
      uploadApi,
      daturaApi,
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = api;
    // eslint-disable-next-line no-param-reassign
    store.$api = api;
  },
};
