import Handsontable from 'handsontable';

import ReadOnlyRenderer from '@/shared/handsontable/renderers/ReadOnlyRenderer';

function onCellDoubleClick(event) {
  if (
    event.currentTarget == null ||
    !(event.currentTarget instanceof HTMLElement) ||
    event.currentTarget.querySelector('button') == null
  ) {
    return;
  }
  event.currentTarget.querySelector('button').click();
}

function FieldStatusRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  [...TD.childNodes].forEach((childNode) => {
    if (childNode instanceof HTMLElement && childNode.matches('button.hot-btn-field-status')) {
      return;
    }
    TD.removeChild(childNode);
  });
  const statusReadOnly = cellProperties.getStatusReadOnly(row);

  // remember the button as it is used as target for Bootstrap popovers
  let button = TD.querySelector('.hot-btn-field-status');
  if (button == null) {
    button = document.createElement('button');
  }
  button.innerHTML = '';
  button.className = '';
  button.classList.add('hot-btn-field-status');

  let status = null;
  if (typeof cellProperties.getStatus === 'function') {
    status = cellProperties.getStatus(row);
    button.innerText = cellProperties.getDisplayValue(value);
    button.classList.add(`hot-btn-field-status--${status}`);
  }

  button.disabled = true;
  TD.removeEventListener('dblclick', onCellDoubleClick);

  if (status !== 'calculating') {
    button.disabled = false;
    TD.addEventListener('dblclick', onCellDoubleClick);

    // assign events like this as the button is re-used, so we dont add multiple listeners
    button.onclick = () => {
      if (statusReadOnly) {
        return;
      }
      if (typeof cellProperties.onClick === 'function') {
        cellProperties.onClick({ target: button, visualRow: row });
      }
    };
    button.onmousedown = (event) => {
      event.stopPropagation();
    };
  }

  TD.append(button);

  if (
    status === 'active' &&
    typeof cellProperties.hasHarvestingMeasures === 'function' &&
    cellProperties.hasHarvestingMeasures(row)
  ) {
    button.classList.add('hot-btn-field-status__with-notification');
  }

  if (statusReadOnly) {
    button.disabled = true;
    button.classList.add('hot-btn-field-status--read-only');
  }

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);
  }
}

Handsontable.cellTypes.registerCellType('fieldStatus', {
  renderer: FieldStatusRenderer,
});
