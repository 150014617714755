import { crop, fieldGroupNumber, name, processOrderName, size } from '@/fields/handsontable/columns/columns';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';

export default [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...fieldGroupNumber,
    lockedVisibility: true,
    readOnly: true,
  },
  {
    ...name,
    readOnly: true,
  },
  {
    ...size,
    readOnly: true,
  },
  {
    ...crop,
  },
  {
    ...processOrderName,
    readOnly: true,
  },
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];
