
import { defineComponent } from 'vue';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';

export default defineComponent({
  name: 'VueConfirmDialog',
  components: { ModalWrapper },
  data() {
    return {
      clicked: false,
    };
  },
  computed: {
    title(): string {
      // @ts-ignore
      if (typeof this.$root.dialogData.data.title === 'string') {
        // @ts-ignore
        return this.$root.dialogData.data.title;
      }
      // @ts-ignore
      return this.$t('Achtung') ?? 'Achtung';
    },
    message(): string {
      // @ts-ignore
      if (typeof this.$root.dialogData.data.message === 'string') {
        // @ts-ignore
        return this.$root.dialogData.data.message;
      }
      // @ts-ignore
      return this.$t('Aktion sicher ausführen?') ?? 'Aktion sicher ausführen?';
    },
    okTitle(): string | undefined {
      // @ts-ignore
      if (typeof this.$root.dialogData.data.okTitle === 'string') {
        // @ts-ignore
        return this.$root.dialogData.data.okTitle;
      }
      return undefined;
    },
    okVariant(): string | undefined {
      // @ts-ignore
      if (typeof this.$root.dialogData.data.okVariant === 'string') {
        // @ts-ignore
        return this.$root.dialogData.data.okVariant;
      }
      return undefined;
    },
  },
  methods: {
    show(): any {
      // @ts-ignore
      return this.$refs.modal.show();
    },
  },
});
