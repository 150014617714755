import { MutationTree } from 'vuex';

import { validateProcessOrderName } from './common';
import { initialStateUserInfo } from './state';
import { AuthState, Company, FetchingKey, User, UserCompany } from './types';

const mutations: MutationTree<AuthState> = {
  setJwtAuth(state, jwtAuth) {
    state.jwtAuth = jwtAuth;
  },
  startFetching(state, key: FetchingKey) {
    state[key] = true;
  },
  endFetching(state, key: FetchingKey) {
    state[key] = false;
  },
  addObserver(state, resolve) {
    state.observers = [...state.observers, resolve];
  },
  clearObservers(state) {
    state.observers = [];
  },
  setLoggedIn(state, { userId }: { userId: string }) {
    state.loggedIn = true;
    state.user.id = userId;
  },
  setLoggedOut(state) {
    state.loggedIn = false;
    Object.assign(state, initialStateUserInfo());
  },
  setUserCompanies(state, userCompanies: UserCompany[]) {
    state.userCompanies = userCompanies;

    state.companiesById = userCompanies.reduce(
      (companiesById, userCompany) => ({
        ...companiesById,
        [userCompany.company.id]: userCompany.company,
      }),
      {},
    );

    state.userInfoLoaded = true;
  },
  setUser(state, user) {
    state.user = user;
  },
  updateUser(state, data: User) {
    Object.keys(data).forEach((key) => {
      if (key === 'id' || key === 'guid') {
        return;
      }
      // @ts-ignore
      state.user[key] = data[key];
    });

    state.user.displayName = `${state.user.firstname} ${state.user.lastname}`;
  },
  setCurrentCompaniesAndProcessOrderName(state, { companies, processOrderName }) {
    state.currentCompanies = companies;
    state.currentProcessOrderName = validateProcessOrderName(companies, processOrderName);
  },
  setCurrentProcessOrderName(state, processOrderName) {
    state.currentProcessOrderName = validateProcessOrderName(state.currentCompanies, processOrderName);
  },
  /**
   * Use updateCompanyById instead #326
   *
   * @deprecated
   */
  updateCurrentCompany(state, { id, data }: { id: string; data: Company }) {
    Object.keys(data).forEach((key) => {
      if (key === 'id' || key === 'guid') {
        return;
      }
      // @ts-ignore
      state.companiesById[id][key] = data[key];
    });
    state.currentCompanies = state.currentCompanies.map((company) => {
      if (company.id !== id) {
        return company;
      }
      return state.companiesById[id];
    });
  },
};

export default mutations;
