import { ActivityEquipment } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import { ActionContext } from 'vuex';

import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../types';
import initServices from './utils/initServices';

export default function removeActivityEquipmentInAllActivities(
  { state, commit, rootState, rootGetters }: ActionContext<CreateEditActivityState, RootState>,
  activityEquipmentId: string,
): void {
  const { activityService, findService } = initServices(state, rootState, rootGetters);
  const referenceActivityEquipment = _cloneDeep(findService.getActivityEquipment(activityEquipmentId));

  state.activities.forEach((activity) => {
    const family = activityService.findActivityEquipmentsOrSiblings(activity, referenceActivityEquipment);

    if (family.length > 0) {
      removeEquipments(activity.id, family, commit);
    }
  });
}

function removeEquipments(activityId: string, activityEquipments: ActivityEquipment[], commit: any) {
  activityEquipments.forEach((activityEquipment) => {
    commit('removeActivityEquipment', { activityId, activityEquipmentId: activityEquipment.id });
  });
}
