import Handsontable from 'handsontable';

import { CellChangeWithIdsActivityTable } from '@/activities/types';

export default function addIdsToChanges(
  changes: Handsontable.CellChange[],
  hot: Handsontable,
): CellChangeWithIdsActivityTable[] {
  return changes.map((change) => {
    const [visualRow, prop, oldValue, newValue] = change;

    const activityId = hot.getDataAtRowProp(visualRow, 'id');
    const activityProductId = hot.getDataAtRowProp(visualRow, 'product.id');
    const activityEquipmentId = hot.getDataAtRowProp(visualRow, 'equipment.id');

    return [visualRow, `${prop}`, oldValue, newValue, { id: activityId, activityProductId, activityEquipmentId }];
  });
}
