var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_vm.tooltipOptionsWithDefaults),expression:"tooltipOptionsWithDefaults"}],staticClass:"tooltip-wrapper"},[_c('BButton',_vm._g({staticClass:"button-with-arrow",attrs:{"variant":_vm.variant,"disabled":_vm.disabled}},_vm.$listeners),[_c('span',{staticClass:"button-with-arrow__inner",class:{
        'button-with-arrow__inner--icon-left': _vm.type === 'left',
        'button-with-arrow__inner--icon-right': _vm.type === 'right',
      }},[_c('FontAwesomeIcon',{staticClass:"button-with-arrow__icon",class:{
          'button-with-arrow__icon--left': _vm.type === 'left',
          'button-with-arrow__icon--right': _vm.type === 'right',
        },attrs:{"icon":['far', _vm.icon]}}),_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }