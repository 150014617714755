import merge from 'lodash.merge';

import { guidColumn } from '../tableBase';

/**
 * Use this mixin in a table container component.
 * This should be accompanied by a sub-component that uses the shared/handsontable/mixins/tableBase.js mixin.
 * (e.g. shared/handsontable/components/TableBase)
 * The table component needs the ref set to "table".
 */
export default {
  props: {
    filterString: {
      type: String,
      default: '',
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    visibleRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      /**
       * Reference to the handsontable core inside the child component TableBase.
       */
      hot: null,
      /**
       * Stored hooks that will be applied to the handsontable as soon as it is created.
       */
      hotHooks: [],
      /**
       * Use this to set container specific handsontable settings.
       */
      tableSettingsInternal: {},
      /**
       * Set the localized column settings here (e.g. fields/handsontable/columns/tableFields).
       */
      columnDefinition: null,
      /**
       * Push mixins here during created() hook. (e.g. products/crops/mixins/containers/columnDropdownCrops)
       */
      columnSettingsMiddleware: [],
    };
  },
  computed: {
    tableSettings() {
      return merge({}, this.tableSettingsInternal, this.columnSettings);
    },
    columnSettings() {
      if (this.columnDefinition == null) {
        return {};
      }

      // 1. select columns depending on company region
      let columns = [];
      if (Array.isArray(this.columnDefinition)) {
        columns = this.columnDefinition;
      } else if (Array.isArray(this.columnDefinition.international)) {
        columns = this.columnDefinition.international;
        const regionIds = this.$store.getters['auth/currentCompaniesRegionIds'];
        if (regionIds.length === 1 && Array.isArray(this.columnDefinition[regionIds[0]])) {
          columns = this.columnDefinition[regionIds[0]];
        }
      }

      // 2. apply middleware (for dropdown data, requiredFeatures, etc)
      this.columnSettingsMiddleware.forEach((callback) => {
        columns = callback(columns);
      });

      // 3. retrieve hiddenColumns and fixedColumnsLeft settings for:
      // - hiddenColumns
      // - fixedColumnsLeft
      // - columnSorting
      let fixedColumnsLeft = 0;
      const hiddenColumns = {
        indicators: false,
        columns: [],
      };
      const columnSorting = {};
      let fixedColumns = true;
      columns.forEach((column, physicalColumn) => {
        if (fixedColumns && column.lockedPosition) {
          fixedColumnsLeft += 1;
        } else {
          fixedColumns = false;
        }
        if (column.hiddenPerDefault) {
          hiddenColumns.columns.push(physicalColumn);
        }
        if (typeof column.sortOrder === 'string') {
          columnSorting.initialConfig = {
            column: physicalColumn,
            sortOrder: column.sortOrder,
          };
        }
        if (column.disableSorting) {
          columnSorting.indicator = false;
        }
      });
      if (window.matchMedia('(max-width: 767px)').matches) {
        fixedColumnsLeft = 0;
      }

      // 4. set noHeaderContextMenu option automatically to true for column type checkbox (if not set explicitly)
      columns = columns.map((column) => {
        let noHeaderContextMenu = false;
        if (['checkbox'].includes(column.type)) {
          noHeaderContextMenu = true;
        }
        if (column.noHeaderContextMenu != null) {
          ({ noHeaderContextMenu } = column);
        }
        return {
          ...column,
          noHeaderContextMenu,
        };
      });

      return {
        columns,
        hiddenColumns,
        fixedColumnsLeft,
        columnSorting,
      };
    },
    allVisible() {
      if (this.tableData == null || this.loading) {
        return true;
      }
      return Object.values(this.tableData).length === this.visibleRows.length;
    },
  },
  created() {
    this.columnSettingsMiddleware.push(this.columnSettingsCheckFeatures);
    this.columnSettingsMiddleware.push(this.columnSettingsAddBoundary);
    this.columnSettingsMiddleware.push(this.columnSettingsAddGuidColumn);
  },
  methods: {
    addHotHook(key, callback) {
      this.hotHooks.push({ key, callback });
    },
    tableMounted() {
      this.hot = this.$refs.table.hot;
      this.hotHooks.forEach(({ key, callback }) => this.hot.addHook(key, callback));
    },
    columnSettingsCheckFeatures(columns) {
      return columns.filter((column) => {
        if (typeof column.requiredFeatures !== 'function') {
          return true;
        }
        return column.requiredFeatures(this.$store, this);
      });
    },
    columnSettingsAddBoundary(columns) {
      return columns.map((column) => {
        if (column.type !== 'dropdown' || column.dropdownBoundary != null) {
          return column;
        }
        return {
          ...column,
          getDropdownBoundary: column.getDropdownBoundary || (() => this.$el),
        };
      });
    },
    columnSettingsAddGuidColumn(columns) {
      if (columns.some((column) => column.key === guidColumn.key)) {
        return columns;
      }
      return [guidColumn, ...columns];
    },
  },
};
