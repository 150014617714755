import { Module } from 'vuex';

import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import getters from './getters';
import { ProductCategoriesState } from './types';

export function initialState(): ProductCategoriesState {
  return { ...initialStateSubscribable() };
}

const vuexModule: Module<ProductCategoriesState, RootState> = {
  namespaced: true,
  state: initialState() as ProductCategoriesState,
  mutations: { ...subscribableStore.mutations },
  actions: { ...subscribableStore.actions },
  getters,
};

export default vuexModule;
