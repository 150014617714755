import Handsontable from 'handsontable';
import _isEqual from 'lodash.isequal';

import difference from './differenceByKeys/differenceArrayByKeys';
import { RowPropChange } from './types';

export default function detectChanges(
  oldData: Object[],
  newData: Object[],
  columns: Handsontable.ColumnSettings[],
  hot: Handsontable,
): RowPropChange[] {
  const compareKeys = columns.map((col) => col.data).filter((data) => typeof data === 'string') as string[];

  return difference(oldData, newData, compareKeys).map(toVisualRow(hot)).filter(byCellData(hot));
}

export function toVisualRow(hot: Handsontable) {
  return (change: RowPropChange) => {
    const [physicalRow, key, newValue] = change;
    const visualRow = hot.toVisualRow(physicalRow);

    return [visualRow, key, newValue] as RowPropChange;
  };
}

export function byCellData(hot: Handsontable) {
  return (change: RowPropChange) => {
    const [visualRow, key, newValue] = change;
    const oldValue = hot.getDataAtRowProp(visualRow, key);

    return !_isEqual(oldValue, newValue);
  };
}
