import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import { archived, comment, companyId, customer, name, number, size } from './columns';

export default [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...number,
    lockedVisibility: true,
    lockedPosition: true,
    sortOrder: 'asc',
  },
  {
    ...customer,
    lockedPosition: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length > 0,
  },
  {
    ...customer,
    hiddenPerDefault: true,
    lockedPosition: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length === 0,
  },
  name,
  size,
  archived,
  comment,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];
