import { render, staticRenderFns } from "./PopoverFieldGroupArchived.vue?vue&type=template&id=7072b5dc&scoped=true&"
import script from "./PopoverFieldGroupArchived.vue?vue&type=script&lang=js&"
export * from "./PopoverFieldGroupArchived.vue?vue&type=script&lang=js&"
import style0 from "./PopoverFieldGroupArchived.vue?vue&type=style&index=0&id=7072b5dc&prod&lang=css&"
import style1 from "./PopoverFieldGroupArchived.vue?vue&type=style&index=1&id=7072b5dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7072b5dc",
  null
  
)

export default component.exports