import axios from 'axios';

import store from '@/store';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
  const accessToken = store.getters['auth/accessToken']();

  if (!accessToken) return config;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
});

export default axiosInstance;
