import Vue from 'vue';
import { GetterTree } from 'vuex';

import isMulticompany from '@/auth/isMulticompany';
import { Company } from '@/auth/store/types';
import { ActivityType, ProductCategoryType } from '@/shared/api/rest/models';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import usedItems from '@/shared/mixins/store/usedItems';
import { Getters as UsedGetters } from '@/shared/mixins/store/usedItems/types';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { filterMulticompany, findSiblingsOfActivityType, syncName } from './compatibleActivityTypesFilter';
import { ActivityTypesState } from './types';

export type Getters = UsedGetters &
  SubscribableGetters<ActivityType> & {
    byRoughAndFineId: (roughId: string, fineId: string | null) => ActivityType[];
    allActivityTypes: ActivityType[];
    activityTypeByType: (type: ProductCategoryType) => ActivityType;
    compatibleActivityTypes: (companies: Company[]) => ActivityType[];
    compatibleActivityTypesWithoutSyncNameDuplicates: (companies: Company[]) => ActivityType[];
    dropdownItem: (activityTypeId: string) => DropdownItem | null;
    dropdownItems: (companies: Company[]) => DropdownItemsPerCategory[];
    findActivityTypeOfCompany: (activityTypeId: string, companyId: string) => ActivityType | undefined;
    findActivityTypeOfCompanyBySyncName: (syncName: string, companyId: string) => ActivityType | undefined;
    getActivityTypeOfCompanyBySyncName: (syncName: string, companyId: string) => ActivityType;
    findBySyncName: (name: string) => ActivityType | undefined;
    getBySyncName: (name: string) => ActivityType;
    activityTypeDisplayName: (id: string) => string;
  };

const moduleGetters: GetterTree<ActivityTypesState, RootState> = {
  ...subscribableStore.getters,
  ...usedItems.getters,
  byRoughAndFineId:
    (state: ActivityTypesState) =>
    (roughId: string, fineId: string | null = null): ActivityType | null => {
      const activityType = Object.values(state.data).find(
        (currentActivityType) => currentActivityType.roughId === roughId && currentActivityType.fineId === fineId,
      );
      return activityType ?? null;
    },
  allActivityTypes: (state: ActivityTypesState): ActivityType[] => Object.values(state.data),
  activityTypeByType: (state: ActivityTypesState) => (type: ProductCategoryType) =>
    Object.values(state.data).find((activityType: ActivityType) => activityType.type === type),
  compatibleActivityTypes:
    (state: ActivityTypesState, getters: Getters) =>
    (companies: Company[]): ActivityType[] => {
      if (isMulticompany(companies)) {
        return getters.allActivityTypes.filter((activityType: ActivityType) => {
          const activityTypeSiblings = findSiblingsOfActivityType(activityType, getters.allActivityTypes);
          return filterMulticompany(activityType, activityTypeSiblings, companies);
        });
      }

      return Object.values(state.data);
    },

  compatibleActivityTypesWithoutSyncNameDuplicates:
    (state: ActivityTypesState, getters: Getters) =>
    (companies: Company[]): ActivityType[] => {
      const compatibleActivityTypes = getters.compatibleActivityTypes(companies);

      const bySyncName = new Map<string, ActivityType>();
      compatibleActivityTypes.forEach((activityType) => {
        bySyncName.set(syncName(activityType), activityType);
      });

      return Array.from(bySyncName.values());
    },
  dropdownItem:
    (state: ActivityTypesState) =>
    (activityTypeId: string): DropdownItem | null => {
      const activityType = state.data[activityTypeId];
      if (!activityType) return null;

      return {
        id: activityType.id,
        name: activityType.displayName,
      };
    },
  dropdownItems:
    (state: ActivityTypesState, getters: Getters) =>
    (companies: Company[]): DropdownItemsPerCategory[] => {
      const activityTypes = getters.compatibleActivityTypesWithoutSyncNameDuplicates(companies);
      const usedActivityTypes = getters.used;

      const items: DropdownItem[] = activityTypes.map((activityType) => ({
        id: activityType.id,
        name: activityType.displayName,
      }));

      const lastUsed: DropdownItemsPerCategory = {
        name: Vue.i18n.translate('Zuletzt verwendet') || '',
        id: 'activityTypes-used',
        items: items.filter((item) => usedActivityTypes.includes(item.id || '')),
        sort: false,
      };
      const others: DropdownItemsPerCategory = {
        name: Vue.i18n.translate('Tätigkeiten') || '',
        id: 'activityTypes',
        items: items.filter((item) => !usedActivityTypes.includes(item.id || '')),
        sort: true,
      };
      return [lastUsed, others];
    },
  findActivityTypeOfCompany:
    (state: ActivityTypesState, getters: Getters) =>
    (activityTypeId: string, companyId: string): ActivityType | undefined => {
      const candidate: ActivityType = state.data[activityTypeId];
      if (!candidate) return undefined;
      if (candidate.companyId === companyId) return candidate;

      const activityTypeSiblings = findSiblingsOfActivityType(candidate, getters.allActivityTypes);
      return activityTypeSiblings.find((activityType) => activityType.companyId === companyId);
    },
  findActivityTypeOfCompanyBySyncName:
    (state: ActivityTypesState) =>
    (name: string, companyId: string): ActivityType | undefined =>
      Object.values(state.data).find((candidate) => syncName(candidate) === name && candidate.companyId === companyId),
  getActivityTypeOfCompanyBySyncName:
    (state: ActivityTypesState, getters: Getters) =>
    (name: string, companyId: string): ActivityType => {
      const activityType = getters.findActivityTypeOfCompanyBySyncName(name, companyId);
      if (!activityType) throw new Error(`ActivityType with syncName ${name} not found`);
      return activityType;
    },
  findBySyncName:
    (state: ActivityTypesState) =>
    (name: string): ActivityType | undefined =>
      Object.values(state.data).find((candidate) => syncName(candidate) === name),
  getBySyncName:
    (state: ActivityTypesState, getters: Getters) =>
    (name: string): ActivityType => {
      const activityType = getters.findBySyncName(name);
      if (!activityType) throw new Error(`ActivityType with syncName ${name} not found`);
      return activityType;
    },
  activityTypeDisplayName: (state: ActivityTypesState) => (id: string) => {
    const activityType = state.data[id];
    if (!activityType) return '';
    return activityType.displayName || '';
  },
};

export default moduleGetters;
