
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { ActivityProduct, ProductCategory, StoragePlace } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import getProductAndStorageId from '@/activities/utils/getProductAndStorageId';
import { Company } from '@/auth/store/types';
import { Getters as ProductGetters } from '@/products/store/getters';
import { ActivityType, Field, ProcessOrder, Product, ProductVisibility } from '@/shared/api/rest/models';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { Data } from '@/shared/mixins/store/types';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { RootState } from '@/store/types';

import { Getters as CreateEditActivityGetters } from '../store/getters';

library.add(faTrashAlt);

export default defineComponent({
  name: 'ProductDropdownContainer',
  components: { FormFieldDropdown },
  props: {
    selectedProduct: {
      type: Object as PropType<DropdownItem | null>,
      required: false,
    },
    activityProduct: {
      type: Object as PropType<ActivityProduct>,
      required: true,
    },
  },
  data() {
    return {
      defaultSelectedDropdownValue: {
        id: '',
        name: '',
      },
    };
  },
  created() {
    this.$store.dispatch('products/subscribe');
    this.$store.dispatch('productVisibilities/subscribe');
    this.$store.dispatch('productCategories/subscribe');
    this.$store.dispatch('storagePlaces/subscribe');
    this.$store.dispatch('activityTypes/subscribe');
  },
  computed: {
    activityType(): ActivityType | undefined {
      const activityTypeId = (this.$store.state as RootState).activities.createEditActivity.activities?.[0]
        .activityTypeId; // TODO #957 use all activityTypeIds
      if (!activityTypeId) return undefined;
      return this.$store.state.activityTypes.data[activityTypeId];
    },
    activityTimeStarts(): number[] {
      return this.$store.getters[
        `activities/createEditActivity/activityTimeStarts`
      ] as CreateEditActivityGetters['activityTimeStarts'];
    },
    currentCompanies(): Company[] {
      return (this.$store.state as RootState).auth.currentCompanies;
    },
    currentProcessOrders(): ProcessOrder[] {
      return this.$store.getters['auth/currentProcessOrders'];
    },
    productDropdownValue(): DropdownItem {
      if (!this.selectedProduct) {
        return this.defaultSelectedDropdownValue;
      }
      return this.selectedProduct;
    },
    productVisibilities(): ProductVisibility[] {
      return Object.values((this.$store.state as RootState).productVisibilities.data);
    },
    productCategories(): Data<ProductCategory> {
      return (this.$store.state as RootState).productCategories.data;
    },
    selectedFields(): string[] {
      return (
        (this.$store.state as RootState).activities.createEditActivity.activities
          ?.map((activity) => activity.fieldId)
          .filter(notNullOrUndefined) ?? []
      );
    },
    storagePlaces(): StoragePlace[] {
      return Object.values((this.$store.state as RootState).storagePlaces.data);
    },
    products(): Product[] {
      return this.$store.getters['products/allProducts'];
    },
    productDropdownItems(): DropdownItemsPerCategory[] {
      if (
        !this.activityType ||
        !this.currentCompanies ||
        !this.currentProcessOrders ||
        !this.productVisibilities ||
        !this.productCategories
      ) {
        return [];
      }
      const fields: Field[] = this.selectedFields
        .map((fieldId: string) => this.$store.state.fields.data[fieldId])
        .filter((fieldOrNotFound: Field | undefined) => fieldOrNotFound !== undefined);
      const products = (
        this.$store.getters[
          'products/compatibleProductsToDropdownItems'
        ] as ProductGetters['compatibleProductsToDropdownItems']
      )(
        this.activityType,
        this.currentCompanies,
        this.currentProcessOrders,
        this.productVisibilities,
        Object.values(this.productCategories),
        fields,
        this.storagePlaces,
        this.activityTimeStarts,
      );
      return products;
    },
  },
  methods: {
    updateProduct(dropdownItem: DropdownItem): void {
      const { productId, storagePlaceId } = getProductAndStorageId(dropdownItem.id);

      this.$store.dispatch(`activities/createEditActivity/updateProductOnActivityProductInAllActivities`, {
        activityProductId: this.activityProduct.id,
        updateValues: {
          referenceProductId: productId,
          storagePlaceId,
        },
      });
    },
    updateCurrentActivityProductId() {
      this.$store.commit(`activities/createEditActivity/setCurrentActivityProductId`, this.activityProduct.id);
    },
  },
});
