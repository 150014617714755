
import moment from 'moment';
import { PropType, defineComponent } from 'vue';

import { ActivityWithDisplayNames } from '@/activities/types';
import { ActivityType } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

export default defineComponent({
  name: 'ActivitySummary',
  props: {
    activity: {
      type: Object as PropType<ActivityWithDisplayNames>,
      required: true,
    },
    activityTypes: {
      type: Object as PropType<Data<ActivityType>>,
      required: true,
    },
  },
  computed: {
    title(): string {
      const parts = [];
      if (this.activity.timeStart) {
        parts.push(this.formatTime(this.activity.timeStart));
      }
      if (this.activity.activityTypeId) {
        parts.push(this.getActivityTypeDisplayName(this.activity.activityTypeId));
      }
      parts.push('on');
      parts.push(this.activity.fieldDisplayName);
      return parts.join(' ');
    },
  },
  methods: {
    formatTime(time: number): string {
      const date = new Date(time * 1000);
      return moment(date).format('L');
    },
    getActivityTypeDisplayName(activityTypeId: string): string {
      const activityType = this.activityTypes[activityTypeId];
      if (activityType) {
        return activityType.displayName;
      }
      return '';
    },
  },
});
