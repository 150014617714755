<template>
  <WelcomeContentContainer class="step-farmer-goals" padding-top>
    <template #default>
      <MaxWidthContainer size="lg">
        <WelcomeTitle white>
          {{ $t('Was sind deine Ziele?') }}
        </WelcomeTitle>
      </MaxWidthContainer>
      <MaxWidthContainer class="mt-auto" grid>
        <ButtonOnboarding :active="goalsComputed.includes('paperless')" @click="() => onClick('paperless')">
          {{ $t('Papierlos arbeiten') }}
        </ButtonOnboarding>
        <ButtonOnboarding
          :active="goalsComputed.includes('better_organisation')"
          @click="() => onClick('better_organisation')"
        >
          {{ $t('Organisation verbessern') }}
        </ButtonOnboarding>
        <ButtonOnboarding
          :active="goalsComputed.includes('legal_requirements')"
          @click="() => onClick('legal_requirements')"
        >
          {{ $t('Aufzeichnungspflichten erfüllen') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="goalsComputed.includes('reduce_costs')" @click="() => onClick('reduce_costs')">
          {{ $t('Kosten senken') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="goalsComputed.includes('maximize_output')" @click="() => onClick('maximize_output')">
          {{ $t('Ertrag maximieren') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="goalsComputed.includes('others')" @click="() => onClick('others')">
          {{ $t('Andere') }}
        </ButtonOnboarding>
      </MaxWidthContainer>
    </template>
    <template #cta-container>
      <MaxWidthContainer>
        <Button
          variant="primary"
          size="lg"
          block
          class="my-0"
          :disabled="goalsComputed.length < 2"
          @click="$emit('next')"
        >
          {{ titleNext }}
        </Button>
      </MaxWidthContainer>
    </template>
  </WelcomeContentContainer>
</template>

<script>
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';

import imageLandscape from '../assets/FARMDOK_Onboarding_Crop_Landscape.jpg';
import image from '../assets/FARMDOK_Onboarding_Crop_Portrait.jpg';
import ButtonOnboarding from './ButtonOnboarding.vue';
import WelcomeContentContainer from './WelcomeContentContainer.vue';
import WelcomeTitle from './WelcomeTitle.vue';

export default {
  name: 'StepFarmerGoals',
  components: {
    ButtonOnboarding,
    MaxWidthContainer,
    WelcomeContentContainer,
    WelcomeTitle,
    Button,
  },
  props: {
    goals: {
      type: [Array, String, null],
      default: null,
    },
  },
  computed: {
    goalsComputed() {
      if (!Array.isArray(this.goals)) {
        return [];
      }
      return this.goals;
    },
    titleNext() {
      if (this.goalsComputed.length < 2) {
        return this.$t('Wähle zumindest 2');
      }
      return this.$t('Weiter');
    },
  },
  mounted() {
    this.$emit('background', { image, imageLandscape });
  },
  methods: {
    onClick(value) {
      if (this.goalsComputed.includes(value)) {
        this.$emit('change', { key: 'goals', value: this.goalsComputed.filter((current) => current !== value) });
        return;
      }
      this.$emit('change', { key: 'goals', value: [...this.goalsComputed, value] });
    },
  },
};
</script>

<style scoped></style>
