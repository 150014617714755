<template>
  <TableBase
    ref="table"
    :id="id"
    disable-read-only-bg
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :visible-rows="visibleRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @reload="loadData"
    @click:status="onClickStatus"
  >
    <template #footer>
      <TableFooter
        :total-rows="Object.keys(tableData).length"
        :visible-rows="visibleRows.length"
        :selected-rows="selectedRows.length"
      >
        {{ $t('Summe Größe: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}
      </TableFooter>
    </template>
    <template #no-content>
      <NoContent type="partners" @copyLinkToClipboard="copyLinkToClipboard" />
    </template>
    <template #no-search-results>
      <NoContent type="search" @clearAllFilters="clearAllFilters" />
    </template>
  </TableBase>
</template>

<script>
import { mapGetters } from 'vuex';

import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import { baseUrl, origin } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import subscribableTableData from '@/shared/handsontable/mixins/containers/featureSubscribableTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import NoContent from '../components/NoContent.vue';
import columnsTablePartners from '../handsontable/columns/tablePartners';

export default {
  name: 'TablePartnersContainer',
  components: {
    TableBase,
    TableFooter,
    NoContent,
  },
  mixins: [tableContainerBase, subscribableTableData('managedContracts/companies'), calculateSizeTotal],

  props: {
    contractId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      columnDefinition: columnsTablePartners,
    };
  },
  computed: {
    ...mapGetters({
      contractCompanyData: 'managedContracts/contractCompanyData',
    }),
    id() {
      return ['hot-partners', ...this.$store.state.auth.currentCompanies.map((company) => company.id)].join('-');
    },
    tableData() {
      return this.contractCompanyData(this.contractId).reduce(
        (tableData, company) => ({
          ...tableData,
          [company.guid]: company,
        }),
        {},
      );
    },
    loading() {
      return (
        this.$store.getters['products/crops/loading'] ||
        this.$store.getters['varieties/loading'] ||
        this.$store.getters['managedContracts/fields/loading'] ||
        this.$store.getters['managedContracts/companies/loading']
      );
    },
  },

  methods: {
    export() {
      this.$refs.table.export();
    },
    loadAdditionalData() {
      this.$store.dispatch('managedContracts/fields/loadByContractId', { contractId: this.contractId });
      this.$store.dispatch('managedContracts/fields/subscribe');
      this.$store.dispatch('products/crops/subscribe');
      this.$store.dispatch('varieties/subscribe');
    },

    clearAllFilters() {
      this.$refs.table.clearAllFilters();
    },
    onClickStatus({ target, visualRow }) {
      this.$emit('click:status', {
        target,
        guid: this.$refs.table.visualRowToGuid(visualRow),
      });
    },

    async copyLinkToClipboard() {
      const linkToContract = `${origin}${baseUrl}digital-contracting/contracting/${this.contractId}`;
      if (!navigator.clipboard) {
        return;
      }
      await navigator.clipboard.writeText(linkToContract);
    },
  },
};
</script>
