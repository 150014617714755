<template>
  <div id="the-contentbar" class="contentbar border-top border-bottom border-medium bg-lightest">
    <div class="contentbar__left">
      <slot name="left" :contentbar-large="large" />
    </div>
    <slot class="contentbar__middle" name="middle" :contentbar-large="large" />
    <div class="contentbar__right">
      <slot name="right" :contentbar-large="large" />
    </div>
  </div>
</template>

<script>
import resizeObserver from '../../shared/mixins/resizeObserver';

export default {
  name: 'TheContentbar',
  mixins: [resizeObserver],
  computed: {
    large() {
      return this.resizeObserverSize.width >= 1400;
    },
  },
};
</script>

<style scoped>
.contentbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 15px;
  min-height: 60px;
}

@media (max-width: 1100px) {
  .contentbar {
    overflow-x: auto;
  }
}

.contentbar__left,
.contentbar__right {
  flex: 1;
  display: flex;
  align-items: center;
}

.contentbar__right {
  justify-content: flex-end;
}
</style>
