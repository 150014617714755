/**
 * This feature gives the possibility to add a 'selectColumn' to a handsontable.
 * Therefore you have to use a table mixin and this, e.g. `mixins: [tableBase, featureSelectableRows]`.
 * Then you have to add the selectColumn as usual: `tableSettings: { columns: [bindColumn(selectColumn), ...], ... }`
 *
 * If you want to update the selected rows programmatically use updateSelection, toggleAllRowsSelected or
 * set selectedRowsComputed directly. Also for reading the selected rows always read selectedRowsComputed.
 *
 * You can also set the selected rows as a prop using <ComponentWithTableFeature :selected-rows="variableInParent"/>
 */
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';

import { GUID_KEY } from '@/shared/constants';

library.add(faSquare, faCheckSquare);

/**
 * Allows the user to see which rows are selected and to update the selected rows.
 * Always wrap withing bindColumn() before adding it to tableSettings.
 */
export const selectColumn = {
  // Handsontable settings
  type: 'checkbox',
  className: 'select-rows border-right-0',
  width: 45,
  // TableBase settings
  key: '__select',
  data(entry, value) {
    const guid = entry[GUID_KEY];

    if (typeof value === 'undefined') {
      return this.selectedRowsComputed.includes(guid);
    }
    return this.updateSelection(guid, value);
  },
  hiddenInPageSettings: true,
  lockedVisibility: true,
  lockedPosition: true,
  noHeaderContextMenu: true,
};

export default {
  props: {
    /**
     * If you do not set this prop the selected rows are stored (and read) internally.
     * When using this prop also listen to 'update:selectedRows' emits (either by using sync option for binds or manually).
     */
    selectedRows: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selectedRowsInternal: [],
      selectedRowsMultiUpdateTarget: null,
    };
  },
  computed: {
    /**
     * If selected rows are set via prop those are used. Otherwise return the internal array.
     * Updates always update both.
     *
     * @return {Array}
     */
    selectedRowsComputed: {
      get() {
        if (Array.isArray(this.selectedRows)) {
          return this.selectedRows;
        }
        return this.selectedRowsInternal;
      },
      set(selectedRows) {
        this.selectedRowsInternal = selectedRows;
        this.$emit('update:selectedRows', selectedRows);
      },
    },
    selectColumnPhysicalIndex() {
      let physicalColumn = null;
      this.tableSettingsComputed.columns.some((column, index) => {
        if (column.key === selectColumn.key) {
          physicalColumn = index;
          return true;
        }
        return false;
      });
      return physicalColumn;
    },
  },
  watch: {
    selectedRowsComputed() {
      this.hotRender();
    },
    tablePhysicalRowByGuid() {
      const selectedRows = this.selectedRowsComputed.filter((id) =>
        Object.keys(this.tablePhysicalRowByGuid).includes(id),
      );
      if (selectedRows.length !== this.selectedRowsComputed) {
        this.selectedRowsComputed = selectedRows;
      }
    },
  },
  created() {
    this.tableHooks.colHeaders.push(this.selectableRowsColHeaders);
  },
  mounted() {
    this.$el.addEventListener('click', (e) => {
      // noinspection JSUnresolvedVariable
      if (
        e.target != null &&
        e.target.className != null &&
        typeof e.target.className.indexOf === 'function' &&
        e.target.className.indexOf('header-checkbox--select-rows') > -1
      ) {
        this.toggleAllRowsSelected();
      }
      return true;
    });
    this.addHotHook('afterRenderer', this.selectableRowsAfterRenderer);
    this.addHotHook('beforeOnCellMouseDown', this.selectableRowsBeforeOnCellMouseDown);
    this.addHotHook('afterOnCellMouseUp', this.selectableRowsAfterOnCellMouseUp);
  },
  methods: {
    /**
     * Set a given row to be either selected or not selected.
     * This method will use the set() of selectedRowsComputed.
     *
     * @param {string} guid
     * @param {boolean} selected
     */
    updateSelection(guid, selected) {
      const selectedRows = this.selectedRowsComputed.filter((currentGuid) => currentGuid !== guid);
      if (selected) {
        selectedRows.push(guid);
      }
      this.selectedRowsComputed = selectedRows;
    },
    /**
     * If all VISIBLE rows are currently selected this function will set the selectedRows to an empty array.
     *
     * Otherwise adds all VISIBLE rows to selectedRows. In this case tableBase.cellsInternal will be called for every row
     * and rows with a readOnly selection checkbox will NOT be selected.
     *
     * This method will use the set() of selectedRowsComputed.
     */
    toggleAllRowsSelected() {
      if (this.hot == null) {
        return;
      }

      const selectableRows = this.getSelectableRows();
      if (this.selectedRowsComputed.length === selectableRows.length) {
        this.selectedRowsComputed = [];
      } else {
        this.selectedRowsComputed = selectableRows;
      }
    },
    /**
     * This will render a checkbox for the selectColumn header.
     * The checkbox will show 'selected' if all visible/selectable rows are selected.
     * Clicking the header will trigger toggleAllRowsSelected().
     *
     * @param {{data(*=, *=): (*), key: string}} column
     * @return {string|null}
     */
    selectableRowsColHeaders(column) {
      if (this.hot == null || column.key !== selectColumn.key) {
        return null;
      }

      const selectableRows = this.getSelectableRows();
      const wrapper = document.createElement('div');
      let [checkbox] = icon({ prefix: 'far', iconName: 'square' }).node;
      if (selectableRows.length > 0 && this.selectedRowsComputed.length === selectableRows.length) {
        // noinspection JSValidateTypes
        [checkbox] = icon({ prefix: 'far', iconName: 'check-square' }).node;
        wrapper.classList.add('text-black');
      } else {
        wrapper.classList.add('text-medium');
      }
      wrapper.classList.add('header-checkbox--select-rows');
      checkbox.style.pointerEvents = 'none';
      wrapper.appendChild(checkbox);
      return wrapper.outerHTML;
    },
    /**
     * Adds 'selected' class to all cells in all selected rows.
     *
     * @param {HTMLElement} td
     * @param {number} visualRow
     */
    selectableRowsAfterRenderer(td, visualRow) {
      if (this.hot == null) {
        return;
      }

      const guid = this.visualRowToGuid(visualRow);
      if (this.selectedRowsComputed.includes(guid)) {
        td.classList.add('selected');
      }
    },
    selectableRowsBeforeOnCellMouseDown(event, { row, col }) {
      const physicalCol = this.hot.toPhysicalColumn(col);
      if (this.tableSettingsComputed.columns[physicalCol].key !== selectColumn.key) {
        return;
      }
      if (!event.shiftKey) {
        return;
      }
      const selected = this.hot.getSelected();
      if (!Array.isArray(selected) || selected.length === 0) {
        return;
      }
      const [[selectedRow]] = selected;
      if (selectedRow === row) {
        return;
      }
      const startRow = Math.min(selectedRow, row);
      const targetRow = Math.max(selectedRow, row);
      const selectedIds = [];
      for (let visualRow = startRow; visualRow <= targetRow; visualRow += 1) {
        selectedIds.push(this.visualRowToGuid(visualRow));
      }
      this.selectedRowsMultiUpdateTarget = {
        targetRow: row,
        selectedIds,
        selected: !this.selectedRowsComputed.includes(this.visualRowToGuid(row)),
      };
    },
    async selectableRowsAfterOnCellMouseUp(event, { row, col }) {
      if (this.selectedRowsMultiUpdateTarget == null || this.selectedRowsMultiUpdateTarget.targetRow !== row) {
        return;
      }
      const physicalCol = this.hot.toPhysicalColumn(col);
      if (this.tableSettingsComputed.columns[physicalCol].key !== selectColumn.key) {
        return;
      }

      // give the handsontable time to update the selection
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 10));
      const { selected, selectedIds } = this.selectedRowsMultiUpdateTarget;
      const selectedRows = this.selectedRowsComputed.filter((id) => !selectedIds.includes(id));
      if (selected) {
        this.selectedRowsComputed = [...selectedRows, ...selectedIds];
      } else {
        this.selectedRowsComputed = selectedRows;
      }
    },
    getSelectableRows() {
      if (this.selectColumnPhysicalIndex == null) {
        return [];
      }
      return [...new Array(this.hot.countRows()).keys()]
        .map((visualRow) => this.visualRowToGuid(visualRow))
        .filter((guid) => {
          const physicalRow = this.guidToPhysicalRow(guid);
          const { readOnly } = this.cellsInternal(physicalRow, this.selectColumnPhysicalIndex);
          return !readOnly;
        });
    },
    selectableRowsBatchAction(action = 'clear') {
      if (action === 'clear') {
        this.selectedRowsComputed = [];
      } else if (action === 'all') {
        this.selectedRowsComputed = this.getSelectableRows();
      } else if (action === 'reverse') {
        this.selectedRowsComputed = this.getSelectableRows().filter((id) => !this.selectedRowsComputed.includes(id));
      }
    },
  },
};
