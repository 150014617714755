import Handsontable from 'handsontable';
import _get from 'lodash.get';

import getDisplayValue from '@/shared/modules/getDisplayValue';

import { HOT_DISPLAY_BUTTON_RENDERER } from '../../cellTypes/subtablePrimaryColumn/constants';
import { TableDataBase } from '../../types';

export default function prepareTableData(
  ids: string[],
  columns: Handsontable.ColumnSettings[],
  columnTitles: string[],
  columnDescriptions: string[],
  tableData: TableDataBase[],
  subtableColumnData?: string,
): any[][] {
  const data = [];
  data.push(columnTitles);
  data.push(columnDescriptions);

  if (subtableColumnData) {
    return linePerSubtable(tableData, columns, ids, data, subtableColumnData);
  }
  return linePerRowId(tableData, columns, ids, data);
}

export function linePerSubtable(
  tableData: TableDataBase[],
  columns: Handsontable.ColumnSettings[],
  ids: string[],
  data: any[],
  subtableColumnData: string,
): any[][] {
  const tableDataForIds = tableData.filter((td) => ids.includes(td.id));
  const tableDataFiltered = filterByPrimaryColumnValue(tableDataForIds, columns, subtableColumnData);

  tableDataFiltered.forEach((filteredTableData) => {
    const rowData: any[] = [];

    columns.forEach((column) => {
      if (!column.isSubtableColumn || column.isPartOfSubtable === subtableColumnData) {
        const displayValue = getDisplayValue(filteredTableData, column);
        appendDisplayValues(displayValue, column.type, rowData);
      } else if (column.isPartOfSubtable !== subtableColumnData && column.isFirstSubtableColumn) {
        const displayValue = getCollapsedDisplayValueOfId(filteredTableData.id, tableData, column);
        appendDisplayValues(displayValue, column.type, rowData);
      }
    });
    data.push(rowData);
  });
  return data;
}

function filterByPrimaryColumnValue(
  tableData: TableDataBase[],
  columns: Handsontable.ColumnSettings[],
  subtableColumnData: string,
) {
  const primaryColumn = columns.find(
    (column) => column.isFirstSubtableColumn && column.isPartOfSubtable === subtableColumnData,
  );
  if (!primaryColumn) throw new Error('No primary column found');

  return tableData.filter((td) => {
    const displayValue = getDisplayValue(td, primaryColumn);
    return displayValue !== HOT_DISPLAY_BUTTON_RENDERER;
  });
}

export function linePerRowId(
  tableData: TableDataBase[],
  columns: Handsontable.ColumnSettings[],
  ids: string[],
  data: any[],
): any[][] {
  ids.forEach((id) => {
    const rowData: any[] = [];
    columns.forEach((column) => {
      if (!column.isSubtableColumn) {
        const tableDataForId = tableData.find((td) => td.id === id);
        const displayValue = getDisplayValue(tableDataForId, column);
        appendDisplayValues(displayValue, column.type, rowData);
      } else if (column.isFirstSubtableColumn) {
        const displayValue = getCollapsedDisplayValueOfId(id, tableData, column);
        appendDisplayValues(displayValue, column.type, rowData);
      }
    });
    data.push(rowData);
  });
  return data;
}

function getCollapsedDisplayValueOfId(id: string, tableData: TableDataBase[], column: Handsontable.ColumnSettings) {
  if (typeof column.data !== 'string') throw new Error('column.data must be a string');
  if (column.type !== 'farmdok.subtablePrimaryColumn')
    throw new Error('column.type must be farmdok.subtablePrimaryColumn');

  const tableDataForId = tableData.filter((td) => td.id === id);
  const tableDataValues = tableDataForId
    .map((td) => _get(td, column.data as string, null))
    .filter((v) => v && v !== 'HOT_DISPLAY_BUTTON_RENDERER');

  const displayValue = getDisplayValue(tableDataValues, column);
  return displayValue;
}

function appendDisplayValues(displayValue: string, columnType: string | undefined, rowData: any[]) {
  if (columnType === 'farmdok.amountUnit') {
    const displayValues: (string | number)[] = displayValue.split(' ');
    if (displayValues.length === 1) displayValues.push(''); // always push two placeholders (if amount and unit are empty)

    rowData.push(...displayValues);
    return;
  }

  rowData.push(displayValue);
}
