import axios from 'axios';
import { ActionTree } from 'vuex';

import { dataToBase64 } from '@/shared/api/rest/requestUtils';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { GUID_KEY } from '@/shared/constants';
import { RootState } from '@/store/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import {
  calcAreaWeightedVarietyMeanRoundedAndClamped,
  calcBasicSeedStrengthFromManualSeedrate,
  calcHeatmapColors,
  calcKDiffFromManualSeedrate,
  findMaterialBasedOnSelectedFields,
  findMaterialBasedOnVariety,
  getFirstSelectedField,
  getMostFrequentWorkflowVarietyInFields,
} from './actionUtils';
import { Getters } from './getters';
import initialState from './initialState';
import { ApplicationMapsSeedingState } from './types';

const indexType = 'RATE_SEED_MAIZE';
const configSet = 'SET_A2';

const actions: ActionTree<ApplicationMapsSeedingState, RootState> = {
  ...baseWorkflowStore.actions,
  async loadHeatmaps({ state, commit, dispatch }) {
    const requestIdentifier = [
      ...state.selectedFields,
      state.selectedIndexType,
      state.selectedHeatmapDbId,
      state.selectedQuantisationCode,
      state.calculation.variety.id, // reload original heatmaps on variety change
    ].join('_');
    if (state.multiPolyRequestIdentifier === requestIdentifier) {
      return;
    }
    commit('setMultiPolyRequestIdentifier', requestIdentifier);
    commit('fetchHeatmaps');
    if (state.paginationStep < 2 || state.selectedFields.length < 1) {
      state.multiPolyRequestIdentifier = null;
      commit('setHeatmaps', {});
      return;
    }
    let products = state.selectedFields.filter((guid: string) => state.polygons[guid] != null);
    if (products.length !== state.selectedFields.length) {
      commit('setHeatmaps', {});
      return;
    }

    const quantisationCode = state.selectedQuantisationCode;
    // @ts-ignore // TODO fix this
    products = products.map((guid) => {
      const dbId = null;
      const polygon = state.polygons[guid].pathsForAxios;
      const clientId = `${guid}_${dbId}_${indexType}_${quantisationCode}`;
      const fieldKey = guid;
      const timestamp = Math.floor(Date.now() / 1000);
      return {
        dbId,
        polygon,
        clientId,
        fieldKey,
        timestamp,
      };
    });

    let data: any = null;
    try {
      ({ data } = await axios.post('/admin/sen4/multiPoly', {
        products,
        indexType,
        configSet,
        quantisationCode,
      }));
    } catch (e) {
      return;
    }

    commit('setHeatmaps', state.task?.applicationMap?.geoJson ?? data.products);
    dispatch('setHeatmapColors');
  },
  // fields
  async loadVarieties({ commit, state }, { forceRefresh = false }) {
    // @ts-ignore // TODO fix this
    if (this.state.varieties.fetching || (state.varieties.current.length !== 0 && !forceRefresh)) {
      return;
    }
    commit('setVarietiesFetching', true);
    try {
      const { regionId } = this.state.auth.currentCompanies[0];
      const filter = ['regionId', '=', regionId];
      const { data } = await axios.get(
        `/admin/rest/plantVariety/workflow/seedingSaatbauMaize?itemsPerPage=5000&filter=${dataToBase64(filter)}`,
      );

      commit('setVarieties', data.data);
    } catch (e) {
      console.error(e);
    }
    commit('setVarietiesFetching', false);
  },
  async setVariety({ state, commit, dispatch }, variety) {
    if (variety.id === 'manual') {
      commit('setCalculationField', { key: 'variety', value: { id: variety.id, name: variety.name } });
      return;
    }
    // @ts-ignore // TODO fix this
    const { seedrateMin, seedrateMax, k, d, kDiff, name, id } = state.varieties.current.find(
      // @ts-ignore // TODO fix this
      (v) => v.id === variety.id,
    );
    const varietyDropdownItem: DropdownItem = {
      id,
      name,
    };
    const resetManualSeedrates = {
      manualMaxSeedrate: null,
      manualMinSeedrate: null,
      manualSeedRates: {},
    };
    commit('setCalculation', {
      ...state.calculation,
      ...resetManualSeedrates,
      variety: varietyDropdownItem,
      seedrateMin,
      seedrateMax,
      k,
      d,
      kDiff,
    });
    dispatch('updateVarietyDependencies');
  },
  updateVarietyDependencies({ dispatch }) {
    dispatch('setAreaWeightedVarietyMean');
    dispatch('loadHeatmaps');
  },
  setManualSeedRate({ commit, state }, { zoneName, manualSeedRate }) {
    const { manualSeedRates } = state.calculation;
    const newManualSeedRates = { [zoneName]: manualSeedRate };
    commit('setCalculationField', { key: 'manualSeedRates', value: { ...manualSeedRates, ...newManualSeedRates } });
  },
  setAreaWeightedVarietyMean({
    state,
    commit,
    getters,
  }: {
    state: ApplicationMapsSeedingState;
    commit: any;
    getters: Getters;
  }) {
    if (
      !state.calculation.variety.id ||
      state.heatmaps.fetching ||
      Object.values(state.heatmaps.current).length === 0
    ) {
      return;
    }

    const areaWeightedVarietyMean = calcAreaWeightedVarietyMeanRoundedAndClamped(getters.zones, state.calculation);

    commit('setCalculationField', { key: 'areaWeightedVarietyMean', value: areaWeightedVarietyMean });
    commit('setCalculationField', { key: 'basicSeedStrength', value: areaWeightedVarietyMean });
  },

  setManualMinMaxSeedrate({ commit, state, getters }, seedrate: { seedrateMin: number; seedrateMax: number }) {
    commit('setCalculationField', { key: 'manualMinSeedrate', value: seedrate.seedrateMin });
    commit('setCalculationField', { key: 'manualMaxSeedrate', value: seedrate.seedrateMax });

    const kDiff = calcKDiffFromManualSeedrate(getters.zones, state.calculation);
    commit('setCalculationField', { key: 'kDiff', value: kDiff });

    const basicSeedStrength = calcBasicSeedStrengthFromManualSeedrate(getters.zones, state.calculation);
    commit('setCalculationField', { key: 'basicSeedStrength', value: basicSeedStrength });
  },

  setHeatmapColors({ commit, state, getters }) {
    if (!state.heatmaps.current || state.heatmaps.fetching || !state.calculation.variety.id) {
      return;
    }
    const newHeatmaps = calcHeatmapColors(state.heatmaps.current, getters.zones);
    commit('setHeatmaps', newHeatmaps);
  },
  setAverageDosage({ commit }, averageDosage) {
    commit('setCalculationField', { key: 'averageDosage', value: averageDosage });
  },
  async findAndSetMaterial({ commit, state, rootState }) {
    const selectedField = getFirstSelectedField(state.selectedFields, rootState.fields.data);
    if (!selectedField) {
      return;
    }

    commit('setFetchingMaterial', true);

    let material = await findMaterialBasedOnVariety(state.calculation.variety, selectedField);
    if (!material) {
      material = await findMaterialBasedOnSelectedFields(selectedField);
    }

    if (material) {
      commit('setCalculationField', {
        key: 'material',
        value: {
          id: material.id,
          name: material.name,
        },
      });
    }

    commit('setFetchingMaterial', false);
  },

  async setTask({ commit, rootState, dispatch, state }, task) {
    await dispatch('auth/subscribe', undefined, { root: true });
    await Promise.all([
      dispatch('fields/subscribe', undefined, { root: true }),
      dispatch('products/mineralFertilizers/subscribe', undefined, { root: true }),
    ]);
    commit('setTask', task);
    // select fields
    const selectedFields: string[] = [];
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      Array.isArray(task.applicationMap.additionalData.fields)
    ) {
      // @ts-ignore
      task.applicationMap.additionalData.fields.forEach(({ [GUID_KEY]: guid }) => {
        if (rootState.fields.data[guid] != null) {
          selectedFields.push(guid);
        }
      });
    } else if (Array.isArray(task.fields)) {
      task.fields.forEach((taskField: any) => {
        const { guid } = taskField.field;
        if (rootState.fields.data[guid] != null) {
          selectedFields.push(guid);
        }
      });
    }
    commit('setSelectedFields', selectedFields);
    if (task.applicationMap != null && task.applicationMap.geoJson != null) {
      commit('setHeatmaps', task.applicationMap.geoJson);
      const multiPolyRequestIdentifier = [
        ...state.selectedFields,
        state.selectedIndexType,
        state.selectedHeatmapDbId,
        state.selectedQuantisationCode,
      ].join('_');
      commit('setMultiPolyRequestIdentifier', multiPolyRequestIdentifier);
    }
    // set pagination to step2 if fields are selected
    if (selectedFields.length > 0) {
      commit('setPaginationStep', 2);
    }

    // set calculation values
    if (
      task.applicationMap != null &&
      task.applicationMap.additionalData != null &&
      task.applicationMap.additionalData.calculation != null
    ) {
      const { calculation } = task.applicationMap.additionalData;
      commit('setCalculation', { ...state.calculation, ...calculation });
    }
  },
  resetManualSeedrates({ commit, state, dispatch }) {
    const { manualMaxSeedrate, manualMinSeedrate, manualSeedRates } = initialState().calculation;
    // @ts-ignore // TODO fix this
    const { kDiff } = state.varieties.current.find((variety) => variety.id === state.calculation.variety.id);
    commit('setCalculation', {
      ...state.calculation,
      manualMaxSeedrate,
      manualMinSeedrate,
      manualSeedRates,
      kDiff,
    });
    dispatch('setAreaWeightedVarietyMean');
  },
  async setDefaultVariety({ state, rootState, dispatch }) {
    const selectedFields = Object.values(rootState.fields.data).filter((field) =>
      state.selectedFields.includes(field.id),
    );
    const mostFrequentVariety = getMostFrequentWorkflowVarietyInFields(
      selectedFields,
      // @ts-ignore // TODO fix this
      rootState.varieties.data,
      state.varieties.current,
    );

    if (!mostFrequentVariety) {
      dispatch('loadHeatmaps');
      return;
    }
    dispatch('setVariety', {
      id: mostFrequentVariety.id,
      name: mostFrequentVariety.name,
    });
  },
};

export default actions;
