import { mapGetters } from 'vuex';

export default function columnDropdownContractCompanies(columnKey) {
  return {
    data() {
      return {
        columnSettingsMiddleware: [],
      };
    },
    created() {
      this.columnSettingsMiddleware.push(this.columnDropdownContractCompaniesColumnSettings);
    },
    computed: {
      ...mapGetters({
        companies: 'managedContracts/companies/data',
        companiesByContractId: 'managedContracts/companiesByContractId',
      }),
    },
    methods: {
      async columnDropdownContractCompaniesGetItems() {
        return [
          {
            items: this.companiesByContractId(this.contractId).map((c) => ({
              id: c.id,
              name: c.companyName,
            })),
            sort: true,
          },
        ];
      },

      columnDropdownContractCompaniesGetRendererValue({ value }) {
        if (!value) {
          return '';
        }
        return this.companies[value].companyName;
      },

      columnDropdownContractCompaniesColumnSettings(columns) {
        return columns.map((column) => {
          if (column.key !== columnKey) {
            return column;
          }

          return {
            ...column,
            getRendererValue: (...args) => this.columnDropdownContractCompaniesGetRendererValue(...args),
            getItems: () => this.columnDropdownContractCompaniesGetItems(),
          };
        });
      },
    },
  };
}
