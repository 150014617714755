
import { TerrazoFieldTimestampEntry } from 'farmdok-rest-api';
import moment from 'moment';
import { defineComponent } from 'vue';
import { DisabledDates, HighlightedDates } from 'vuejs-datepicker';
import { mapGetters, mapState } from 'vuex';

import { fromUnixTimestamp, toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { RootState } from '@/store/types';

import Datepicker from '../../components/sidebarZones/Datepicker.vue';
import { ActionPayloads } from '../../store/actions';
import { FieldTimestamps } from '../../store/types';

export default defineComponent({
  name: 'DatepickerContainer',
  components: {
    Datepicker,
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilizationCereals', ['fieldTimestamps']),
    ...mapGetters('precisionFarming/applicationMaps/fertilizationCereals', ['selectedField']),
    selectedDate: {
      get(): Date | null {
        const timestamp = (this.$store.state as RootState).precisionFarming.applicationMaps.fertilizationCereals.zoneMap
          .date;
        if (!timestamp) return null;

        return new Date(fromUnixTimestamp(timestamp));
      },
      set(value: Date) {
        this.$store.commit(
          'precisionFarming/applicationMaps/fertilizationCereals/setDate',
          toUnixTimestamp(value.getTime()),
        );
      },
    },
    loading(): boolean {
      return (this.fieldTimestamps as FieldTimestamps).loading;
    },
    cloudyDates(): HighlightedDates {
      return {
        customPredictor: (date: Date) => {
          const currentDay = moment(date);
          const availableTimestamps: TerrazoFieldTimestampEntry[] =
            (this.fieldTimestamps as FieldTimestamps).data ?? [];

          const currentTimestamp = availableTimestamps.find((availableTimestamp) =>
            moment.unix(availableTimestamp.timestamp || 0).isSame(currentDay, 'day'),
          );

          return currentTimestamp?.coverageRatio?.cloud === 1;
        },
      };
    },
    disabledDates(): DisabledDates {
      const lastAvailableTimestamp = this.getLastAvailableTimestamp();
      const from = lastAvailableTimestamp ? moment.unix(lastAvailableTimestamp).add(1, 'day').toDate() : undefined;
      return {
        from,
        customPredictor: (date: Date) => {
          const currentDay = moment(date);
          const availableTimestamps: TerrazoFieldTimestampEntry[] =
            (this.fieldTimestamps as FieldTimestamps).data ?? [];

          return !availableTimestamps.some((availableTimestamp) =>
            moment.unix(availableTimestamp.timestamp || 0).isSame(currentDay, 'day'),
          );
        },
      };
    },
  },
  watch: {
    selectedField: {
      handler() {
        if (this.selectedField) {
          const payload: ActionPayloads['loadFieldTimestamps'] = this.selectedField;
          this.$store.dispatch('precisionFarming/applicationMaps/fertilizationCereals/loadFieldTimestamps', payload);
        }
      },
      immediate: true,
    },
    fieldTimestamps() {
      this.initSelectedDateIfNecessary();
    },
  },
  methods: {
    initSelectedDateIfNecessary() {
      if (this.fieldTimestamps.loading) {
        return;
      }
      if (!this.selectedDate || !this.isAvailableDate(this.selectedDate)) {
        this.initSelectedDate();
      }
    },
    isAvailableDate(date: Date): boolean {
      const availableTimestamps: TerrazoFieldTimestampEntry[] = (this.fieldTimestamps as FieldTimestamps).data ?? [];

      return availableTimestamps.some((availableTimestamp) =>
        moment.unix(availableTimestamp.timestamp || 0).isSame(date, 'day'),
      );
    },
    initSelectedDate() {
      const lastAvailableTimestamp = this.getLastAvailableTimestamp();
      if (!lastAvailableTimestamp) {
        throw new Error('no timestamps available to init date');
      }

      this.selectedDate = new Date(fromUnixTimestamp(lastAvailableTimestamp));
    },
    getLastAvailableTimestamp(): number | null {
      const availableTimestamps = (this.fieldTimestamps as FieldTimestamps).data ?? [];
      const filteredAvailableTimestampsSortAsc = (
        availableTimestamps
          .map((timestamp) => timestamp.timestamp)
          .filter((timestamp) => timestamp !== undefined) as number[]
      ).sort((a, b) => a - b);

      if (filteredAvailableTimestampsSortAsc.length === 0) {
        return null;
      }

      const [lastTimestamp] = filteredAvailableTimestampsSortAsc.slice(-1);
      return lastTimestamp;
    },
  },
});
