import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';
import { TracksGpsState } from '@/tracks/store/types';

import { subscribableStore } from './common';

const getters: GetterTree<TracksGpsState, RootState> = {
  ...subscribableStore.getters,
};

export default getters;
