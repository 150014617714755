import { cropName, fieldGroupNumber, name, processOrderName, size } from '@/fields/handsontable/columns/columns';

export default [
  {
    ...fieldGroupNumber,
    key: 'fieldGroupMfa',
    lockedVisibility: true,
    readOnly: true,
  },
  {
    ...name,
    key: 'fieldName',
    readOnly: true,
  },
  {
    ...size,
    readOnly: true,
  },
  {
    ...processOrderName,
    readOnly: true,
  },
  {
    ...cropName,
    readOnly: true,
  },
];
