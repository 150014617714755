import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { FeaturesState } from './types';

export type Getters = {
  companyFeatureVisible: (companyId: string, feature: string) => boolean;
  companyFeatureEnabled: (companyId: string, feature: string) => boolean;
  companyFeatureReadonly: (companyId: string, feature: string) => boolean;
  currentCompaniesHaveFeatureVisible: (feature: string) => boolean;
  currentCompaniesHaveFeatureEnabled: (feature: string) => boolean;
  currentCompaniesHaveFeaturesEnabled: (features: string[]) => boolean;
  currentCompaniesHaveFeatureReadonly: (feature: string) => boolean;
};

const moduleGetters: GetterTree<FeaturesState, RootState> = {
  companyFeatureVisible: (state) => (companyId: string, feature: string) =>
    state.availableFeaturesByCompanyId[companyId] != null &&
    state.availableFeaturesByCompanyId[companyId][feature] != null &&
    state.availableFeaturesByCompanyId[companyId][feature].visible === true,

  companyFeatureEnabled: (state) => (companyId: string, feature: string) =>
    state.availableFeaturesByCompanyId[companyId] != null &&
    state.availableFeaturesByCompanyId[companyId][feature] != null &&
    state.availableFeaturesByCompanyId[companyId][feature].enabled === true,

  companyFeatureReadonly: (state) => (companyId: string, feature: string) =>
    state.availableFeaturesByCompanyId[companyId] != null &&
    state.availableFeaturesByCompanyId[companyId][feature] != null &&
    state.availableFeaturesByCompanyId[companyId][feature].readonly === true,

  // returns true if AT LEAST one company has this feature visible
  currentCompaniesHaveFeatureVisible: (state, getters, rootState) => (feature: string) =>
    rootState.auth.currentCompanies.length > 0 &&
    rootState.auth.currentCompanies.some((company) => getters.companyFeatureVisible(company.id, feature)),

  // returns true if ALL companies have the feature enabled
  currentCompaniesHaveFeatureEnabled: (state, getters, rootState) => (feature: string) =>
    rootState.auth.currentCompanies.length > 0 &&
    rootState.auth.currentCompanies.every((company) => getters.companyFeatureEnabled(company.id, feature)),

  // returns true if ALL companies have all the features enabled
  currentCompaniesHaveFeaturesEnabled: (state, getters, rootState) => (features: string[]) =>
    rootState.auth.currentCompanies.length > 0 &&
    features.every((feature) => getters.currentCompaniesHaveFeatureEnabled(feature)),

  // returns true if AT LEAST one companies has readonly === true for this feature
  currentCompaniesHaveFeatureReadonly: (state, getters, rootState) => (feature: string) =>
    rootState.auth.currentCompanies.length > 0 &&
    rootState.auth.currentCompanies.some((company) => getters.companyFeatureReadonly(company.id, feature)),
};

export default moduleGetters;
