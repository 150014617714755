<template>
  <ModalWrapper
    ref="modalWrapper"
    size="max"
    body-class="modal-combine-fields"
    :visible="visible"
    :title="$t('Felder zusammenlegen')"
    @change="hide"
  >
    <template #default>
      <Pagination :pages="3" :current="currentStep" />
      <div v-if="currentStep === 0">
        <FormInfoContainer :headline="$t('Felder auswählen')">
          <p v-if="fieldIds.length >= 2">
            {{
              $t('Es wurden {count} Felder ausgewählt. Die Felder werden im nächsten Schritt zu einem vereint.', {
                count: fieldIds.length,
              })
            }}
            {{ $t('Klicke in die Karte, um die Auswahl zu verändern.') }}
          </p>
          <p v-else>
            {{ $t('Es müssen mindestens 2 Felder gewählt sein.') }}
            {{ $t('Klicke in die Karte, um die Auswahl zu verändern.') }}
          </p>
        </FormInfoContainer>
        <FormFieldSet v-for="field in activeFields" :key="field.id" :headline="field.name">
          <FormFieldReadOnly :value="getFormFieldValue(field, 'fieldGroup.mfa')" :label="$t('Feldstück Nr')" />
          <FormFieldReadOnly
            v-if="columnsByKey.fieldNumber != null && columnsByKey.fieldNumber.visible"
            :value="getFormFieldValue(field, 'fieldNumber')"
            :label="$t('Schlag Nr')"
          />
          <FormFieldReadOnly :value="getFormFieldValue(field, 'name')" :label="$t('Bezeichnung')" />
          <FormFieldReadOnly :value="getFormFieldValue(field, 'fieldSize') | numbro" :label="$t('Größe (in ha)')" />
          <FormFieldReadOnly :value="getCropName(field)" :label="$t('Kultur')" />
        </FormFieldSet>
      </div>
      <div v-if="currentStep === 1 && newField != null">
        <FormInfoContainer :headline="$t('Ergebnis anpassen')">
          <p>
            {{
              $t('Die Felder wurden erfolgreich vereint. Du kannst die Eigenschaften des neuen Felds jetzt anpassen.')
            }}
            <span v-if="measuresExist">
              {{ $t('Sollen bereits gebuchte Maßnahmen für das neue Feld übernommen werden?') }}
            </span>
          </p>
          <FormFieldRadioGroup
            v-if="measuresExist"
            v-model="measuresAction"
            variant="no-label"
            :stacked="false"
            :options="[
              { text: $t('Maßnahmen übernehmen'), value: 'copy', disabled: fetching },
              { text: $t('Maßnahmen löschen'), value: 'delete', disabled: fetching },
            ]"
          />
        </FormInfoContainer>
        <FormFieldSet v-if="companies.length > 1 || useCustomers" :headline="$t('Betrieb')">
          <FormFieldSelect
            v-if="companies.length > 1"
            v-model="newFieldCompanyId"
            required
            :label="$t('Betrieb')"
            :options="companyOptions"
            :state="missingCompany ? false : null"
            @update="missingCompany = false"
          />
          <FormFieldDropdown
            v-if="useCustomers"
            required
            :label="$t('Kunde')"
            :value="customerValueForDropdown"
            :items="customersToDropdownItems"
            :items-loading="$store.state.customers.fetching"
            @update="(value) => (newField.customerId = value.id)"
          />
        </FormFieldSet>
        <FormFieldSetEditFields
          v-model="newField"
          :columns="columns"
          :missing-name="missingName"
          :fetching="fetching"
        />
      </div>
      <div v-if="currentStep === 2">
        <FormInfoContainer :headline="$t('Erledigt')">
          <p>
            {{ $t('Die Felder wurden erfolgreich zusammengefügt.') }}
          </p>
        </FormInfoContainer>
      </div>
    </template>
    <template #map-container>
      <FieldsMap
        v-if="initialized"
        class="modal-combine-fields__map"
        ref="map"
        hide-all-map-actions
        :crops="crops"
        :disabledFields="disabledFields"
        :activeFields="activeFields"
        :editableFields="editableFields"
        @click:polygon="onClickField"
        @click:marker="onClickField"
        @change:polygon="changePolygon"
        @change:marker="changeMarker"
        @change:fieldName="changeFieldName"
      />
      <div v-else />
    </template>
    <template #bottom-content>
      <ErrorUserMessage :error-user-message="userErrorMessage" class="m-0" show-first-only />
      <p class="text-danger m-0">
        <span v-if="missingContour">{{ $t('Keines der gewählten Felder besitzt eine Kontur.') }}</span>
        <span v-if="unableToCombine">{{ $t('Die Feldkonturen konnten nicht kombiniert werden.') }}</span>
        <span v-if="missingName">{{ $t('Bezeichnung fehlt.') }}</span>
        <span v-if="missingCompany">{{ $t('Betrieb fehlt.') }}</span>
      </p>
    </template>
    <template #buttons>
      <BButton v-if="currentStep === 0" variant="primary" class="mr-3" :disabled="fieldIds.length < 2" @click="combine">
        <span>{{ $t('Kombinieren') }}</span>
      </BButton>
      <BButton
        v-if="currentStep === 1"
        variant="primary"
        class="mr-3"
        :disabled="newField.name == null || newField.name.length === 0 || fetching"
        @click="save"
      >
        <span>
          {{ $t('Speichern') }}
          <FontAwesomeIcon v-if="fetching" icon="circle-notch" spin />
        </span>
      </BButton>
      <BButton v-if="currentStep === 1" variant="white" :disabled="fetching" @click="back">
        <span>{{ $t('Zurück') }}</span>
      </BButton>
      <BButton v-if="currentStep < 2" variant="white" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
      <BButton v-if="currentStep === 2" variant="primary" @click="hide">
        <span>{{ $t('Schließen') }}</span>
      </BButton>
    </template>
    <template #footer-content-right>
      <InfoContainer v-if="currentStep === 0 && fieldIds.length < 2">
        {{ $t('Klicke in der Karte auf ein Feld um es hinzuzufügen.') }}
      </InfoContainer>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import area from '@turf/area';
import convex from '@turf/convex';
import union from '@turf/union';
import axios from 'axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import InfoContainer from '@/shared/components/InfoContainer.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Pagination from '@/shared/components/Pagination.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormFieldReadOnly from '@/shared/components/form/FormFieldReadOnly.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import FormInfoContainer from '@/shared/components/form/FormInfoContainer.vue';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';
import { requestDataCreateFromEntryAndModel } from '@/shared/modules/restApiHelpers';
import { createUuid } from '@/shared/modules/uuid';

import { customer as columnCustomer } from '../handsontable/columns/columns';
import { model, modelDefinition } from '../store/common';
import FieldsMap from './FieldsMap.vue';
import FormFieldSetEditFields from './FormFieldSetEditFields.vue';

library.add(faCircleNotch);

export default {
  name: 'ModalCombineFields',
  components: {
    ErrorUserMessage,
    FormFieldDropdown,
    FormFieldSelect,
    FormFieldSetEditFields,
    FormFieldRadioGroup,
    InfoContainer,
    FormFieldReadOnly,
    FieldsMap,
    FormInfoContainer,
    FormFieldSet,
    ModalWrapper,
    Pagination,
  },
  mixins: [backendResponseErrorHelpers],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fieldIds: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      default: null,
      validator(columns) {
        if (columns == null) {
          return true;
        }
        if (!Array.isArray(columns)) {
          return false;
        }
        return columns.every(
          (column) => column != null && typeof column.key === 'string' && typeof column.visible === 'boolean',
        );
      },
    },
  },
  data() {
    return {
      initialized: false,
      currentStep: 0,
      missingContour: false,
      unableToCombine: false,

      measuresAction: 'copy',
      newField: null,
      missingName: false,
      missingCompany: false,
      newFieldCompanyId: null,

      fetching: false,
      source: null,
    };
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('products/crops/subscribe'),
      this.$store.dispatch('fields/subscribe'),
      this.$store.dispatch('activities/subscribe'),
    ]);

    // do not render map before activeFields are set, otherwise the initial map centering isn't working
    this.initialized = true;
  },
  computed: {
    ...mapGetters({
      crops: 'products/crops/data',
      fields: 'fields/dataWithoutRemoved',
      measures: 'activities/data',
    }),
    disabledFields() {
      return Object.values(this.fields)
        .filter((field) => this.currentStep === 1 || !this.fieldIds.includes(field.id))
        .reduce(
          (accumulator, field) => ({
            ...accumulator,
            [field.id]: field,
          }),
          {},
        );
    },
    activeFields() {
      if (this.currentStep === 2) {
        return {
          [this.newField.id]: this.newField,
        };
      }
      return this.fieldIds
        .filter((fieldId) => this.currentStep === 0 && this.fields[fieldId] != null)
        .reduce(
          (accumulator, fieldId) => ({
            ...accumulator,
            [fieldId]: { ...this.fields[fieldId] },
          }),
          {},
        );
    },
    editableFields() {
      if (this.currentStep !== 1 || this.newField == null) {
        return {};
      }
      return {
        [this.newField.id]: this.newField,
      };
    },
    columnsByKey() {
      if (this.columns == null) {
        return {};
      }
      return this.columns.reduce(
        (columnsByKey, column) => ({
          ...columnsByKey,
          [column.key]: column,
        }),
        {},
      );
    },
    measuresExist() {
      if (Object.values(this.measures).length === 0) {
        return false;
      }
      return Object.values(this.measures).some((measure) => this.fieldIds.includes(measure.fieldId));
    },
    companies() {
      return this.$store.state.auth.currentCompanies;
    },
    companyOptions() {
      return [
        { value: null, text: this.$t('Betrieb wählen'), disabled: true },
        ...this.companies.map((company) => ({ value: company.id, text: company.name })),
      ];
    },
    useCustomers() {
      if (this.customers == null) {
        return false;
      }
      return (
        this.columnsByKey[columnCustomer.key] != null &&
        this.columnsByKey[columnCustomer.key].visible &&
        Object.keys(this.customers).length > 0
      );
    },
    customerValueForDropdown() {
      const customerId = this.newField['fieldGroup.customerId'];
      if (customerId === 'own') {
        return { id: 'own', name: this.$t('Eigener Betrieb') };
      }
      if (this.customers[customerId] != null) {
        return {
          id: customerId,
          name: this.customers[customerId].name,
        };
      }
      return { id: null, name: '' };
    },
    customersToDropdownItems() {
      return [
        {
          id: 'own-company',
          items: [{ id: 'own', name: this.$t('Eigener Betrieb') }],
          sort: false,
        },
        {
          name: this.$t('Kunden'),
          id: 'customers',
          items: Object.values(this.customers),
          sort: true,
        },
      ];
    },
  },
  watch: {
    fieldIds() {
      this.missingContour = false;
      this.unableToCombine = false;
      this.measuresAction = 'copy';
      this.missingName = false;
      this.userErrorMessage = null;
    },
  },
  methods: {
    onClickField(fieldId) {
      if (this.currentStep !== 0) {
        return;
      }
      if (this.fieldIds.includes(fieldId)) {
        this.$emit(
          'change:fieldIds',
          this.fieldIds.filter((currentFieldId) => currentFieldId !== fieldId),
        );
      } else {
        this.$emit('change:fieldIds', [...this.fieldIds, fieldId]);
      }
    },
    getFormFieldValue(field, key) {
      return getDescendantProp(field, key);
    },
    getCropName(field) {
      const { cropId } = field;
      if (this.crops[cropId] == null) {
        return '';
      }
      return this.crops[cropId].name;
    },
    combine() {
      this.unableToCombine = false;

      const polygons = [];
      Object.values(this.activeFields).forEach((field) => {
        if (
          field.fieldContour == null ||
          field.fieldContour.geoJson == null ||
          field.fieldContour.geoJson.type !== 'Polygon'
        ) {
          return;
        }
        polygons.push(field.fieldContour.geoJson);
      });
      if (polygons.length === 0) {
        this.missingContour = true;
        return;
      }

      let combinedPolygons = polygons.reduce((previousPolygon, nextPolygon) => {
        if (previousPolygon == null) {
          return {
            type: 'Feature',
            geometry: nextPolygon,
            properties: {},
          };
        }
        return union(previousPolygon, nextPolygon);
      }, null);
      if (!combinedPolygons || combinedPolygons.geometry.type !== 'Polygon') {
        combinedPolygons = convex(combinedPolygons);
      }

      if (!combinedPolygons || combinedPolygons.geometry.type !== 'Polygon') {
        this.unableToCombine = true;
        return;
      }

      const [lon, lat] = combinedPolygons.geometry.coordinates[0].reduce(
        ([totalLon, totalLat], [nextLon, nextLat]) => [totalLon + nextLon, totalLat + nextLat],
        [0, 0],
      );
      const fieldSize = Math.floor(area(combinedPolygons.geometry)) / 10000;
      this.newField = {
        ...this.fields[this.fieldIds[0]],
        id: createUuid(),
        lon: lon / combinedPolygons.geometry.coordinates[0].length,
        lat: lat / combinedPolygons.geometry.coordinates[0].length,
        fieldContour: {
          geoJson: combinedPolygons.geometry,
          contourSize: fieldSize,
          tstamp: Math.floor(new Date() / 1000),
        },
        fieldSize,
      };

      this.currentStep = 1;
    },
    back() {
      this.currentStep = 0;
      this.newField = null;
    },
    async save() {
      if (typeof this.newField.name !== 'string' || this.newField.name.length < 1) {
        this.missingName = true;
        return;
      }
      if (this.companies.length > 1 && this.newFieldCompanyId == null) {
        this.missingCompany = true;
        return;
      }
      this.missingName = false;
      this.missingCompany = false;
      this.userErrorMessage = null;
      this.fetching = true;

      const newField = requestDataCreateFromEntryAndModel(this.newField, model, modelDefinition);
      if (this.newFieldCompanyId != null) {
        const processOrder = this.$store.getters['auth/processOrderByCompanyIdAndNameAndType'](
          this.newFieldCompanyId,
          this.$store.state.auth.currentProcessOrderName,
        );
        newField.processOrderId = processOrder.id;
      }
      newField.id = this.newField.id;
      newField.fieldContour = this.newField.fieldContour;

      try {
        this.source = axios.CancelToken.source();
        const { data } = await axios.post(
          '/admin/rest/field/combine_fields',
          {
            data: {
              fieldIds: this.fieldIds,
              measuresAction: this.measuresAction,
              newField,
            },
          },
          { cancelToken: this.source.token },
        );
        if (data == null || data.status !== 'success') {
          this.setUserErrorMessageFromResponse(data);
          this.fetching = false;
          return;
        }
      } catch (error) {
        this.setUserErrorMessageFromResponse(error);
        this.fetching = false;
        return;
      }
      await this.$store.dispatch('fields/refresh');
      this.$emit('combined', this.newField.id);
      this.fetching = false;
      this.currentStep = 2;
    },
    changePolygon({ fieldContour }) {
      const fieldSize = Math.floor(area(fieldContour.geoJson)) / 10000;
      Vue.set(this.newField, 'fieldSize', fieldSize);
      Vue.set(this.newField, 'fieldContour', {
        geoJson: fieldContour.geoJson,
        fieldSize,
        tstamp: Math.floor(new Date() / 1000),
      });
    },
    changeMarker({ lat, lon }) {
      Vue.set(this.newField, 'lat', lat);
      Vue.set(this.newField, 'lon', lon);
    },
    changeFieldName({ name }) {
      Vue.set(this.newField, 'name', name);
    },
    hide() {
      if (this.source != null) {
        this.source.cancel();
      }
      this.$emit('change', false);
    },
  },
};
</script>

<style scoped></style>
