import { ENTRY_ERROR_INSERTING, ENTRY_INSERTING, ENTRY_NEW, GUID_KEY } from '@/shared/constants';
import { createUuid } from '@/shared/modules/uuid';

export default {
  mounted() {
    this.addHotHook('afterCreateRow', this.featureInsertAfterCreateRow);
    this.addHotHook('afterRenderer', this.featureInsertRowAfterRenderer);
  },
  computed: {
    guidColumnPhysicalIndex() {
      let physicalColumn = null;
      this.tableSettingsComputed.columns.some((column, index) => {
        if (column.key === GUID_KEY) {
          physicalColumn = index;
          return true;
        }
        return false;
      });
      return physicalColumn;
    },
  },
  methods: {
    insert({ visualRow, physicalRow, guid }) {
      if (this.hot == null) {
        return;
      }
      if (visualRow != null) {
        this.hot.alter('insert_row', visualRow, 1, 'featureInsertRow.rowAbove');
      } else if (physicalRow != null) {
        this.hot.alter('insert_row', this.hot.toVisualRow(physicalRow), 1, 'featureInsertRow.rowAbove');
      } else if (guid != null) {
        this.hot.alter('insert_row', this.guidToVisualRow(guid), 1, 'featureInsertRow.rowAbove');
      }
    },
    insertEntryFromStore(entry) {
      const guid = entry[GUID_KEY];
      if (this.tablePhysicalRowByGuid[guid] != null) {
        return;
      }

      this.hot.alter('insert_row', 0, 1, `featureInsertRow.insertEntryFromStore.${guid}`);
    },
    featureInsertAfterCreateRow(visualRow, amount, source) {
      if (source === 'featureInsertRow.rowAbove') {
        this.featureInsertRowCreateGuid(visualRow);
      } else if (typeof source === 'string' && source.indexOf('featureInsertRow.insertEntryFromStore') === 0) {
        this.featureInsertRowSetGuid(visualRow, amount, source);
      }
    },
    featureInsertRowCreateGuid(visualRow) {
      const guid = createUuid();
      if (this.guidColumnPhysicalIndex != null) {
        const visualColumn = this.hot.toVisualColumn(this.guidColumnPhysicalIndex);
        this.hot.setDataAtCell(visualRow, visualColumn, guid, 'featureInsertRow.createGuid');
      }

      const physicalRow = this.hot.toPhysicalRow(visualRow);
      this.tableGuidByPhysicalRow = [
        ...this.tableGuidByPhysicalRow.slice(0, physicalRow),
        guid,
        ...this.tableGuidByPhysicalRow.slice(physicalRow),
      ];
      for (let x = physicalRow; x < this.tableGuidByPhysicalRow.length; x += 1) {
        this.tablePhysicalRowByGuid[this.tableGuidByPhysicalRow[x]] = x;
      }

      const entry = { [GUID_KEY]: guid };
      this.$emit('create:newRow', entry);
      this.$emit('update:tableData', {
        ...this.tableData,
        [guid]: entry,
      });
    },
    featureInsertRowSetGuid(visualRow, amount, source) {
      const [, , guid] = source.split('.');
      if (this.tablePhysicalRowByGuid[guid] != null || this.guidColumnPhysicalIndex == null) {
        return;
      }

      const visualColumn = this.hot.toVisualColumn(this.guidColumnPhysicalIndex);
      this.hot.setDataAtCell(visualRow, visualColumn, guid, 'tableBase.setGuidForInsertedRow');

      const physicalRow = this.hot.toPhysicalRow(visualRow);
      this.tableGuidByPhysicalRow = [
        ...this.tableGuidByPhysicalRow.slice(0, physicalRow),
        guid,
        ...this.tableGuidByPhysicalRow.slice(physicalRow),
      ];

      this.tablePhysicalRowByGuid = {};
      for (let x = 0; x < this.tableGuidByPhysicalRow.length; x += 1) {
        this.tablePhysicalRowByGuid[this.tableGuidByPhysicalRow[x]] = x;
      }
    },
    /**
     * Adds status classes to cells where necessary.
     *
     * @param {HTMLElement} td
     * @param {number} visualRow
     */
    featureInsertRowAfterRenderer(td, visualRow) {
      const guid = this.visualRowToGuid(visualRow);
      if (guid == null || this.tableData[guid] == null) {
        return;
      }
      const { storeStatus } = this.tableData[guid];
      if (storeStatus === ENTRY_NEW) {
        td.classList.add('new');
      } else if (storeStatus === ENTRY_INSERTING) {
        td.classList.add('inserting');
      } else if (storeStatus === ENTRY_ERROR_INSERTING) {
        td.classList.add('error');
      }
    },
  },
};
