<template>
  <ThePageSkeleton
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <template #left="{ contentbarLarge }">
          <ButtonDropdown
            v-if="editMode"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonUpdateSelection"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="selectedRows = []"
          />

          <div v-if="editMode" id="ButtonDeleteContractedFieldsWrapper">
            <Button
              class="text-danger"
              color="lightest"
              icon="trash-alt"
              leading-icon
              component-namespace-name="ButtonDeleteContractedFields"
              :title="$t('Löschen')"
              :icon-only="!contentbarLarge"
              @click="() => $refs.table.removeSelectedEntries()"
              :disabled="!$refs.table.selectedFieldsRemovable"
            />
            <BTooltip v-if="!$refs.table.selectedFieldsRemovable" target="ButtonDeleteContractedFieldsWrapper">
              {{/* eslint-disable max-len*/}}
              {{
                $t(
                  'Deine Auswahl enthält Felder mit abgeschlossener Kontrahierung. Ändere den Partner-Status, um die Einträge zu löschen.',
                )
              }}
              {{/* eslint-enable max-len*/}}
            </BTooltip>
          </div>
          <Button
            color="lightest"
            icon="cloud-download"
            leading-icon
            component-namespace-name="ButtonExportManagedContractFields"
            :title="editMode ? $t('Auswahl exportieren') : $t('Exportieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="exportData"
          />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar v-show="!editMode" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <DropdownPageOptions
            v-show="!editMode"
            class="ml-4"
            @export="exportData"
            @settings="showSettings"
            @restore="$router.push({ name: 'digital-contracting/managed-contracts/fields/restore' })"
            :restore-label="$t('Felder wiederherstellen')"
          />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableFieldsContainer
        ref="table"
        :contractId="contractId"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
        :remove-row-confirm-message="removeRowConfirmMessage"
      />
      <ModalPageSettings ref="modalPageSettings" :columns="columnsForPageSettings" />
      <ModalTableExport
        ref="modalTableExport"
        :title="selectedRows.length > 0 ? $t('Selektierte Felder exportieren') : null"
        :filtered="selectedRows.length === 0 && $refs.table != null && !$refs.table.allVisible"
        :availableTypes="[
          {
            value: 'xls',
            label: $t('XLS'),
            text: $t('Als Excel-Datei exportieren'),
          },
          {
            value: 'shape',
            label: $t('Shapefile'),
            text: $t('Im Shape-Format exportieren'),
          },
        ]"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import DropdownPageOptions from '@/shared/components/DropdownPageOptions.vue';
import ModalPageSettings from '@/shared/components/ModalPageSettings.vue';
import ModalTableExport from '@/shared/components/ModalTableExport.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { urlToFile } from '@/shared/modules/urlHelpers';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import TableFieldsContainer from './containers/TableFieldsContainer.vue';

export default {
  name: 'PageManagedContractFields',
  components: {
    ThePageSkeleton,
    TheContentbar,
    TableFieldsContainer,
    ButtonDropdown,
    SearchBar,
    DropdownPageOptions,
    ModalPageSettings,
    ModalTableExport,
    Button,
  },
  mixins: [buttonUpdateSelection('table')],

  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      columnsForPageSettings: null,
    };
  },

  computed: {
    ...mapGetters({
      contractCompanyData: 'managedContracts/contractCompanyData',
    }),
    editMode() {
      return this.selectedRows.length > 0;
    },

    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_MANAGED_CONTRACTS);
    },

    contractId() {
      return this.$route.params.contractId;
    },
  },

  methods: {
    async exportData() {
      const { type, exportFiltered, okay } = await this.$refs.modalTableExport.show();
      if (!okay) {
        return;
      }

      let fieldIds = [];
      if (this.editMode) {
        fieldIds = this.selectedRows;
      } else if (exportFiltered) {
        fieldIds = this.visibleRows;
      } else {
        fieldIds = Object.values(this.$refs.table.tableData).map((field) => field.id);
      }

      if (type === 'xls') {
        this.$refs.table.$refs.table.export(fieldIds);
        return;
      }
      const response = await axios.post('/admin/rest/contractField/export', {
        version: '2.0',
        data: {
          type,
          fieldIds,
        },
      });
      const file = await urlToFile(
        `data:text/plain;base64,${response.data.fileBody}`,
        response.data.fileName,
        response.data.fileMimeType,
      );
      const { default: saveAs } = await import('jszip/vendor/FileSaver');
      saveAs(file, response.data.fileName);
    },

    async showSettings() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      const settings = await this.$refs.modalPageSettings.show();
      if (settings != null && Array.isArray(settings.columns)) {
        this.$refs.table.$refs.table.updateColumnsFromPageSettings(settings.columns);
      }
    },

    removeRowConfirmMessage(entries) {
      if (entries.length === 1) {
        return this.$t(
          'Du entfernst ein Feld, das von deinem Vertragspartner bereitgestellt wurde. Gewählten Eintrag wirklich löschen?',
        );
      }
      return this.$t(
        'Du entfernst Felder, die von deinem Vertragspartner bereitgestellt wurden. Gewählte Einträge wirklich löschen?',
      );
    },
  },
};
</script>
