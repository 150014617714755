<template>
  <div>
    <ImportEAma
      v-if="$route.params.method === 'eAma'"
      ref="loadDataComponent"
      :import-next-enabled="importNextEnabled"
      :fetching="fetching"
      :import-target-process-order-id="importTargetProcessOrderId"
      @update:importNextEnabled="(value) => $emit('update:importNextEnabled', value)"
      @update:fetching="(value) => $emit('update:fetching', value)"
      @update:importData="(value) => $emit('update:importData', value)"
      @next="() => $emit('next')"
    />
    <ImportFileUpload
      v-else-if="$route.params.method === 'upload'"
      ref="loadDataComponent"
      :import-next-enabled="importNextEnabled"
      :fetching="fetching"
      :file-upload-response-data="fileUploadResponseData"
      :import-target-process-order-id="importTargetProcessOrderId"
      :selected-file-group-name="selectedFileGroupName"
      @update:importNextEnabled="(value) => $emit('update:importNextEnabled', value)"
      @update:fetching="(value) => $emit('update:fetching', value)"
      @update:importData="(value) => $emit('update:importData', value)"
      @update:selectedFileGroupName="(value) => $emit('update:selectedFileGroupName', value)"
      @next="() => $emit('next')"
    />
    <ErrorUserMessage :centerText="true" :error-user-message="userErrorMessage" />
  </div>
</template>

<script>
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';

import ImportEAma from './ImportEAma.vue';
import ImportFileUpload from './ImportFileUpload.vue';

export default {
  name: 'ImportLoadData',

  components: { ImportFileUpload, ImportEAma, ErrorUserMessage },
  props: {
    importNextEnabled: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    fileUploadResponseData: {
      type: Object,
      default: null,
    },
    importTargetProcessOrderId: {
      type: String,
      default: null,
    },
    selectedFileGroupName: {
      type: String,
      default: null,
    },
    userErrorMessage: {
      type: String,
      default: null,
    },
  },
  mounted() {
    if (!['eAma', 'upload'].includes(this.$route.params.method)) {
      this.$emit('back');
    }
  },
  methods: {
    async next() {
      if (this.$refs.loadDataComponent == null) {
        return false;
      }
      return this.$refs.loadDataComponent.next();
    },
  },
};
</script>

<style scoped></style>
