import Vue from 'vue';
import { Module } from 'vuex';

import { ENTRY_RECENTLY_RESTORED, ENTRY_SYNCED } from '@/shared/constants';
import { RootState } from '@/store/types';

import initialStateSubscribable from './subscribableData/initialState';
import { DataEntry, SubscribableDataState } from './types';

export function initialState<E extends DataEntry>(): SubscribableDataState<E> {
  return initialStateSubscribable();
}

const restorableDataModule: Module<SubscribableDataState<DataEntry>, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: {
    recentlyRestoredSetByGuids(state, guids: string[]) {
      guids.forEach((guid) => {
        Vue.set(state.data, guid, {
          ...state.data[guid],
          storeStatus: ENTRY_RECENTLY_RESTORED,
        });
      });
    },
    recentlyRestoredUnsetByGuids(state, guids: string[]) {
      guids.forEach((guid) => {
        if (state.data[guid].storeStatus !== ENTRY_RECENTLY_RESTORED) {
          return;
        }
        Vue.set(state.data, guid, {
          ...state.data[guid],
          storeStatus: ENTRY_SYNCED,
        });
      });
    },
  },
  actions: {
    async recentlyRestoredSetByGuids({ commit }, guids) {
      commit('recentlyRestoredSetByGuids', guids);
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      commit('recentlyRestoredUnsetByGuids', guids);
    },
  },
};

export default restorableDataModule;
