import { ActivityProduct } from 'farmdok-rest-api';
import { Commit } from 'vuex';

import { Unit } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

function updateAmountsAndUnitsInActivities(
  activityProducts: ActivityProduct[],
  units: Data<Unit>,
  commit: Commit,
): void {
  activityProducts.forEach((activityProduct) => {
    const { amount, unitId } = activityProduct;
    if (!amount || !unitId) return;
    const unit = units[unitId];

    commit('updateAmountAndUnitOnActivityProductInAllActivities', {
      activityProductId: activityProduct.id,
      updateValues: {
        amount,
        unit,
      },
    });
  });
}

export default updateAmountsAndUnitsInActivities;
