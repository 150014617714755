import {
  TerrazoFertilizerAdviceParamsWinterGrains1N,
  TerrazoFertilizerAdviceParamsWinterGrains3N,
  TerrazoFertilizerAdviceType,
} from 'farmdok-rest-api';

export default function detectFertilizerAdviceType(
  fertilizerAdviceParams:
    | TerrazoFertilizerAdviceParamsWinterGrains1N
    | TerrazoFertilizerAdviceParamsWinterGrains3N
    | undefined,
): TerrazoFertilizerAdviceType {
  if (isFertilizerAdviceParamsWinterGrains1N(fertilizerAdviceParams)) {
    return TerrazoFertilizerAdviceType.WinterGrains1N;
  }
  if (isFertilizerAdviceParamsWinterGrains3N(fertilizerAdviceParams)) {
    return TerrazoFertilizerAdviceType.WinterGrains3N;
  }

  return TerrazoFertilizerAdviceType.None;
}

export function isFertilizerAdviceParamsWinterGrains1N(
  fertilizerAdviceParams:
    | TerrazoFertilizerAdviceParamsWinterGrains1N
    | TerrazoFertilizerAdviceParamsWinterGrains3N
    | undefined,
): fertilizerAdviceParams is TerrazoFertilizerAdviceParamsWinterGrains1N {
  if (
    fertilizerAdviceParams &&
    'averageFertilizerRate' in fertilizerAdviceParams &&
    typeof fertilizerAdviceParams.averageFertilizerRate === 'number'
  ) {
    return true;
  }
  return false;
}

export function isFertilizerAdviceParamsWinterGrains3N(
  fertilizerAdviceParams:
    | TerrazoFertilizerAdviceParamsWinterGrains1N
    | TerrazoFertilizerAdviceParamsWinterGrains3N
    | undefined,
): fertilizerAdviceParams is TerrazoFertilizerAdviceParamsWinterGrains3N {
  if (
    fertilizerAdviceParams &&
    'averageFertilizerRate1N' in fertilizerAdviceParams &&
    typeof fertilizerAdviceParams.averageFertilizerRate1N === 'number' &&
    'averageFertilizerRate2N' in fertilizerAdviceParams &&
    typeof fertilizerAdviceParams.averageFertilizerRate2N === 'number' &&
    'expectedYield' in fertilizerAdviceParams &&
    typeof fertilizerAdviceParams.expectedYield === 'number' &&
    'humidityAreaType' in fertilizerAdviceParams &&
    'targetGrainQuality' in fertilizerAdviceParams
  ) {
    return true;
  }
  return false;
}
