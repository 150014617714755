<template>
  <div class="welcome-content-container" :class="{ 'welcome-content-container--padding-top': paddingTop }">
    <slot name="default" />
    <div
      v-if="$slots['cta-container'] != null"
      class="welcome-content-container__cta-container"
      :class="{ 'welcome-content-container__cta-container--transparent': ctaContainerTransparent }"
    >
      <slot name="cta-container" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeContentContainer',
  props: {
    paddingTop: {
      type: Boolean,
      default: false,
    },
    ctaContainerTransparent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.welcome-content-container {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.welcome-content-container--padding-top {
  padding-top: calc(68px - var(--spacer_4));
}

@media (min-width: 768px) and (min-height: 800px) {
  .welcome-content-container--padding-top {
    padding-top: calc(170px - var(--spacer_5));
  }
}

.welcome-content-container__cta-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .welcome-content-container__cta-container:not(.welcome-content-container__cta-container--transparent) {
    position: sticky;
    bottom: 0;
    background: var(--white);
    box-shadow: 0 -7px 7px -2px rgba(19, 53, 60, 0.3);
  }
}
</style>
