<template>
  <div class="import-preview-data">
    <ErrorUserMessage v-if="userErrorMessage != null" class="m-3" :error-user-message="userErrorMessage" />
    <TableBase
      id="hot-import-preview-data"
      class="import-preview-data__table"
      ref="table"
      invert-selection-bg
      :cells="cells"
      :loading="loading"
      :table-settings="tableSettings"
      :table-data="tableData"
      :selected-rows="selectedRows"
      :filter-string="filterString"
      @tableMounted="tableMounted"
      @update:selectedRows="(value) => $emit('update:selectedRows', value)"
      @update:visibleRows="(value) => $emit('update:visibleRows', value)"
      @update:filterString="(value) => $emit('update:filterString', value)"
      @update:cell="updateEntryByKeyAndValue"
      @click:columnHeaderLink="onClickColumnHeaderLink"
      @hoverNotification="onHoverNotification"
    >
      <template #footer>
        <TableFooter
          :total-rows="Object.keys(tableData).length"
          :visible-rows="visibleRows.length"
          :selected-rows="selectedRows.length"
        >
          {{ $t('Summe Größe: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}
        </TableFooter>
      </template>
    </TableBase>
    <!-- eslint-disable max-len -->
    <BTooltip v-if="mappingNotificationTarget" placement="bottom" show :target="mappingNotificationTarget">
      {{
        $t(
          'Der Wert in der Datenquelle (aus dem Import) konnte nicht eindeutig erkannt werden. Bitte wähle eine Eintrag aus der Liste. Andernfalls kann kein Wert importiert werden.',
        )
      }}
    </BTooltip>
    <!-- eslint-enable max-len -->
  </div>
</template>

<script>
import area from '@turf/area';
import axios from 'axios';
import chunk from 'lodash.chunk';
import { mapGetters } from 'vuex';

import columnDropdownCrops from '@/products/crops/mixins/containers/columnDropdownCrops';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import { UUID5_FIELD_CONTOUR_NODE } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';
import { getRestResponseData, requestDataCreateFromEntryAndModel } from '@/shared/modules/restApiHelpers';
import { getLatLonFromGeoJson } from '@/shared/modules/shapeHelpers';
import { toNumber } from '@/shared/modules/typeCastHelpers';
import { createUuid, createUuidv5 } from '@/shared/modules/uuid';
import columnDropdownVarieties from '@/varieties/mixins/containers/columnDropdownVarieties';

import {
  columnAmaCodeImported,
  columnAmaCodePreview,
  columnCadastralCommunityImported,
  columnCadastralCommunityPreview,
  columnCommentImported,
  columnCommentPreview,
  columnCropNameImported,
  columnCropPreview,
  columnImportedFieldGroupNr,
  columnImportedFieldName,
  columnImportedFieldNumber,
  columnKindOfUseImported,
  columnKindOfUsePreview,
  columnMatchedCustomerId,
  columnMatchedFieldId,
  columnOwnerImported,
  columnOwnerPreview,
  columnPlotsImported,
  columnPlotsPreview,
  columnPreCropImported,
  columnPreCropPreview,
  columnRightOfUseImported,
  columnRightOfUsePreview,
  columnSizeImported,
  columnSizePreview,
  columnVarietyImported,
  columnVarietyPreview,
  tableFieldsImport,
  tableFieldsImportEmptyProcessOrder,
} from '../handsontable/columns/tableFieldsImport';
import calculateSizeTotal from '../mixins/calculateSizeTotal';
import columnDropdownForFields from '../mixins/containers/columnDropdownForFields';
import { STORE_KEY_AMA_CODES, STORE_KEY_KIND_OF_USE_TYPES } from '../mixins/containers/dropdownItemsForFields';
import { model, modelDefinition } from '../store/common';

export default {
  name: 'ImportPreviewData',
  components: {
    ErrorUserMessage,
    TableFooter,
    TableBase,
  },
  mixins: [
    tableContainerBase,
    calculateSizeTotal,
    columnDropdownCrops(),
    columnDropdownForFields(),
    columnDropdownVarieties(),
    backendResponseErrorHelpers,
  ],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    importData: {
      type: Object,
      default: null,
    },
    importTargetProcessOrderId: {
      type: String,
      required: true,
    },
    manualMapping: {
      type: Object,
      default: null,
    },
  },
  data() {
    let columnDefinition = tableFieldsImport;

    // do not show field mapping if there are no fields in the current process order
    if (Object.values(this.fields).length === 0) {
      columnDefinition = tableFieldsImportEmptyProcessOrder;
    }

    const hasNoCustomers = Object.values(this.$store.getters.customers).length === 0;
    if (hasNoCustomers) {
      columnDefinition = columnDefinition.filter((col) => col.key !== 'mappedCustomer.id');
    }

    return {
      loading: true,
      columnDefinition,
      tableData: {},
      importSuccess: false,
      mappingNotificationTarget: null,
      cropImportOption: 'fill',
      fieldSizeImportOption: 'fill',
      kindOfUseImportOption: 'fill',
      varietyImportOption: 'fill',
      preCropImportOption: 'fill',
      commentImportOption: 'fill',
      amaCodeImportOption: 'fill',
      cadastralCommunityImportOption: 'fill',
      plotsImportOption: 'fill',
      ownerImportOption: 'fill',
      rightOfUseImportOption: 'fill',
    };
  },
  computed: {
    ...mapGetters({
      crops: 'products/crops/data',
      varieties: 'varieties/data',
    }),
  },
  watch: {
    selectedRows() {
      this.userErrorMessage = null;
    },
  },
  async mounted() {
    this.$store.dispatch('fields/subscribe', { forceRefresh: true });

    if (this.importData == null || this.importData.mappedData == null || !Array.isArray(this.importData.mappedData)) {
      this.$emit('back');
      return;
    }
    this.$emit('update:importNextEnabled', false);
    await Promise.all([
      // eslint-disable-next-line no-promise-executor-return
      new Promise((resolve) => setTimeout(resolve, 300)),
      this.$store.dispatch('products/crops/subscribe'),
      this.$store.dispatch('varieties/subscribe'),
    ]);
    const selectedRows = [];
    this.tableData = this.importData.mappedData.reduce((tableData, newField, index) => {
      const id = String(index);
      selectedRows.push(id);
      let mappedFieldId = null;
      if (newField[columnMatchedFieldId.key] != null && this.fields[newField[columnMatchedFieldId.key]] != null) {
        mappedFieldId = newField[columnMatchedFieldId.key];
      }

      let mappedCustomerId = null;
      if (Array.isArray(newField['mappedCustomer.id']) && newField['mappedCustomer.id'].length === 1) {
        [mappedCustomerId] = newField['mappedCustomer.id'];
      }

      let importedCropId = null;
      if (Array.isArray(newField['mappedCrop.id']) && newField['mappedCrop.id'].length === 1) {
        [importedCropId] = newField['mappedCrop.id'];
      }
      let importedVarietyId = null;
      if (Array.isArray(newField['mappedVariety.id']) && newField['mappedVariety.id'].length === 1) {
        [importedVarietyId] = newField['mappedVariety.id'];
      }
      let importedPreCropId = null;
      if (Array.isArray(newField['mappedPreCrop.id']) && newField['mappedPreCrop.id'].length === 1) {
        [importedPreCropId] = newField['mappedPreCrop.id'];
      }
      let importedKindOfUseId = null;
      if (newField['mappedKindofuse.id'] != null) {
        importedKindOfUseId = newField['mappedKindofuse.id'];
      }
      let importedFieldSize = newField[columnSizeImported.key];
      if (
        (importedFieldSize == null || importedFieldSize === 0) &&
        newField['importedField.shape'] != null &&
        newField['importedField.shape'].type === 'Polygon'
      ) {
        importedFieldSize = Math.floor(area(newField['importedField.shape'])) / 10000;
      }
      return {
        ...tableData,
        [id]: {
          ...newField,
          id,
          [columnMatchedFieldId.key]: mappedFieldId,
          [columnMatchedCustomerId.key]: mappedCustomerId,
          'importedCustomer.name': newField['importedCustomer.name'],
          'importedCustomer.hbnr': newField['importedCustomer.hbnr'],
          'importedCustomer.tbnr': newField['importedCustomer.tbnr'],
          'importedCustomer.number': newField['importedCustomer.number'],
          [columnSizeImported.key]: importedFieldSize,
          [columnCropNameImported.key]: importedCropId,
          [columnVarietyImported.key]: importedVarietyId,
          [columnPreCropImported.key]: importedPreCropId,
          [columnKindOfUseImported.key]: importedKindOfUseId,
        },
      };
    }, {});
    this.$emit('update:selectedRows', selectedRows);
    this.loading = false;
    this.$emit('update:importNextEnabled', true);
    window.onbeforeunload = () =>
      this.$t('Die Felder wurden noch nicht erstellt. Bist du sicher, dass du den Import abbrechen willst?');
  },
  beforeDestroy() {
    window.onbeforeunload = undefined;
  },
  async beforeRouteLeave(to, from, next) {
    if (to.name.indexOf('fields/import') === 0 || this.importSuccess) {
      next();
      return;
    }
    const confirmed = await this.$confirm(
      'Die Felder wurden noch nicht erstellt. Bist du sicher, dass du den Import abbrechen willst?',
    );
    next(confirmed);
  },
  methods: {
    getCropIdForImportedRow(id) {
      if (this.cropImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return this.fields[fieldId].cropId;
      }
      if (this.cropImportOption === 'overwrite') {
        return this.tableData[id][columnCropNameImported.key];
      }
      if (this.cropImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId != null && this.fields[fieldId] != null && this.fields[fieldId].cropId != null) {
          return this.fields[fieldId].cropId;
        }
        return this.tableData[id][columnCropNameImported.key];
      }
      return null;
    },
    getCropNameForImportedRow(id) {
      const cropId = this.getCropIdForImportedRow(id);
      if (cropId == null || this.crops[cropId] == null) {
        return '';
      }
      return this.crops[cropId].name;
    },
    getFieldSizeForImportedRow(id) {
      if (this.fieldSizeImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return toNumber(this.fields[fieldId].fieldSize);
      }
      if (this.fieldSizeImportOption === 'overwrite') {
        return toNumber(this.tableData[id][columnSizeImported.key]);
      }
      if (this.fieldSizeImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId != null && this.fields[fieldId] != null && this.fields[fieldId].fieldSize != null) {
          return toNumber(this.fields[fieldId].fieldSize);
        }
        return toNumber(this.tableData[id][columnSizeImported.key]);
      }
      return null;
    },
    getKindOfUseIdForImportedRow(id) {
      if (this.kindOfUseImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return this.fields[fieldId].kindofuse;
      }
      if (this.kindOfUseImportOption === 'overwrite') {
        return this.tableData[id][columnKindOfUseImported.key];
      }
      if (this.kindOfUseImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId != null && this.fields[fieldId] != null && this.fields[fieldId].cropId != null) {
          return this.fields[fieldId].kindofuse;
        }
        return this.tableData[id][columnKindOfUseImported.key];
      }
      return null;
    },
    getKindOfUseForImportedRow(id) {
      const kindOfUseId = this.getKindOfUseIdForImportedRow(id);
      return this.dropdownItemsForFieldsGetRendererValue(STORE_KEY_KIND_OF_USE_TYPES, { value: kindOfUseId });
    },
    getVarietyIdForImportedRow(id) {
      if (this.varietyImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return this.fields[fieldId].varietyId;
      }
      if (this.varietyImportOption === 'overwrite') {
        return this.tableData[id][columnVarietyImported.key];
      }
      if (this.varietyImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId != null && this.fields[fieldId] != null && this.fields[fieldId].varietyId != null) {
          return this.fields[fieldId].varietyId;
        }
        return this.tableData[id][columnVarietyImported.key];
      }
      return null;
    },
    getVarietyNameForImportedRow(id) {
      const varietyId = this.getVarietyIdForImportedRow(id);
      if (varietyId == null || this.varieties[varietyId] == null) {
        return '';
      }
      return this.varieties[varietyId].name;
    },
    getPreCropIdForImportedRow(id) {
      if (this.preCropImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return this.fields[fieldId].preCropId;
      }
      if (this.preCropImportOption === 'overwrite') {
        return this.tableData[id][columnPreCropImported.key];
      }
      if (this.preCropImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId != null && this.fields[fieldId] != null && this.fields[fieldId].preCropId != null) {
          return this.fields[fieldId].preCropId;
        }
        return this.tableData[id][columnPreCropImported.key];
      }
      return null;
    },
    getPreCropNameForImportedRow(id) {
      const preCropId = this.getPreCropIdForImportedRow(id);
      if (preCropId == null || this.crops[preCropId] == null) {
        return '';
      }
      return this.crops[preCropId].name;
    },
    getCommentForImportedRow(id) {
      if (this.commentImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return this.fields[fieldId].comment;
      }
      if (this.commentImportOption === 'overwrite') {
        return this.tableData[id][columnCommentImported.key];
      }
      if (this.commentImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (
          fieldId != null &&
          this.fields[fieldId] != null &&
          typeof this.fields[fieldId].comment === 'string' &&
          this.fields[fieldId].comment.length > 0
        ) {
          return this.fields[fieldId].comment;
        }
        return this.tableData[id][columnCommentImported.key];
      }
      return null;
    },
    getAmaCodeIdForImportedRow(id) {
      if (this.amaCodeImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return this.fields[fieldId].amaCode;
      }
      if (this.amaCodeImportOption === 'overwrite') {
        return this.tableData[id][columnAmaCodeImported.key];
      }
      if (this.amaCodeImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (
          fieldId != null &&
          this.fields[fieldId] != null &&
          typeof this.fields[fieldId].amaCode === 'string' &&
          this.fields[fieldId].amaCode.length > 0
        ) {
          return this.fields[fieldId].amaCode;
        }
        return this.tableData[id][columnAmaCodeImported.key];
      }
      return null;
    },
    getAmaCodeForImportedRow(id) {
      const amaCodeId = this.getAmaCodeIdForImportedRow(id);
      return this.dropdownItemsForFieldsGetRendererValue(STORE_KEY_AMA_CODES, { value: amaCodeId });
    },
    getCadastralCommunityForImportedRow(id) {
      if (this.cadastralCommunityImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return getDescendantProp(this.fields[fieldId], 'additionalData.cadastralCommunity');
      }
      if (this.cadastralCommunityImportOption === 'overwrite') {
        return this.tableData[id][columnCadastralCommunityImported.key];
      }
      if (this.cadastralCommunityImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (
          fieldId != null &&
          this.fields[fieldId] != null &&
          typeof getDescendantProp(this.fields[fieldId], 'additionalData.cadastralCommunity') === 'string' &&
          getDescendantProp(this.fields[fieldId], 'additionalData.cadastralCommunity').length > 0
        ) {
          return getDescendantProp(this.fields[fieldId], 'additionalData.cadastralCommunity');
        }
        return this.tableData[id][columnCadastralCommunityImported.key];
      }
      return null;
    },
    getPlotsForImportedRow(id) {
      if (this.plotsImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return getDescendantProp(this.fields[fieldId], 'additionalData.plots');
      }
      if (this.plotsImportOption === 'overwrite') {
        return this.tableData[id][columnPlotsImported.key];
      }
      if (this.plotsImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (
          fieldId != null &&
          this.fields[fieldId] != null &&
          typeof getDescendantProp(this.fields[fieldId], 'additionalData.plots') === 'string' &&
          getDescendantProp(this.fields[fieldId], 'additionalData.plots').length > 0
        ) {
          return getDescendantProp(this.fields[fieldId], 'additionalData.plots');
        }
        return this.tableData[id][columnPlotsImported.key];
      }
      return null;
    },
    getOwnerForImportedRow(id) {
      if (this.ownerImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return getDescendantProp(this.fields[fieldId], 'additionalData.owner');
      }
      if (this.ownerImportOption === 'overwrite') {
        return this.tableData[id][columnOwnerImported.key];
      }
      if (this.ownerImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (
          fieldId != null &&
          this.fields[fieldId] != null &&
          typeof getDescendantProp(this.fields[fieldId], 'additionalData.owner') === 'string' &&
          getDescendantProp(this.fields[fieldId], 'additionalData.owner').length > 0
        ) {
          return getDescendantProp(this.fields[fieldId], 'additionalData.owner');
        }
        return this.tableData[id][columnOwnerImported.key];
      }
      return null;
    },
    getRightOfUseForImportedRow(id) {
      if (this.rightOfUseImportOption === 'keep') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || this.fields[fieldId] == null) {
          return null;
        }
        return getDescendantProp(this.fields[fieldId], 'additionalData.rightOfUse');
      }
      if (this.rightOfUseImportOption === 'overwrite') {
        return this.tableData[id][columnRightOfUseImported.key];
      }
      if (this.rightOfUseImportOption === 'fill') {
        const fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (
          fieldId != null &&
          this.fields[fieldId] != null &&
          typeof getDescendantProp(this.fields[fieldId], 'additionalData.rightOfUse') === 'string' &&
          getDescendantProp(this.fields[fieldId], 'additionalData.rightOfUse').length > 0
        ) {
          return getDescendantProp(this.fields[fieldId], 'additionalData.rightOfUse');
        }
        return this.tableData[id][columnRightOfUseImported.key];
      }
      return null;
    },
    async onHoverNotification({ target }) {
      if (target === this.mappingNotificationTarget) {
        return;
      }
      this.mappingNotificationTarget = null;
      await this.$nextTick();
      this.mappingNotificationTarget = target;
    },
    cells(physicalRow, physicalCol, props) {
      if (
        this.tableSettings == null ||
        this.tableSettings.columns == null ||
        this.tableSettings.columns[physicalCol] == null
      ) {
        return props;
      }
      const column = this.tableSettings.columns[physicalCol];

      if (column.key === columnMatchedCustomerId.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        const id = this.$refs.table.physicalRowToGuid(physicalRow);
        let dropdownComparableItem = null;
        const dataEntry = this.tableData[id];
        if (dataEntry != null && dataEntry['mappedCustomer.id'] != null) {
          dropdownComparableItem = {
            category: this.$t('Kunde in der Datenquelle (aus dem Import)'),
            value:
              dataEntry['importedCustomer.name'] ??
              dataEntry['importedCustomer.hbnr'] ??
              dataEntry['importedCustomer.tbnr'] ??
              dataEntry['importedCustomer.number'],
          };
        }
        if (dataEntry == null || dataEntry[column.key] != null) {
          return {
            ...props,
            className: classNames.join(' '),
            showNotification: false,
            dropdownComparableItem,
          };
        }
        return {
          ...props,
          className: [...classNames, 'warning'].join(' '),
          showNotification: true,
          dropdownComparableItem,
        };
      }

      if (column.key === columnCropNameImported.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        const id = this.$refs.table.physicalRowToGuid(physicalRow);
        let dropdownComparableItem = null;
        if (this.tableData[id] != null && this.tableData[id]['importedCrop.name'] != null) {
          dropdownComparableItem = {
            category: this.$t('Kultur in der Datenquelle (aus dem Import)'),
            value: this.tableData[id]['importedCrop.name'],
          };
        }
        if (this.tableData[id] == null || this.tableData[id][column.key] != null) {
          return {
            ...props,
            className: classNames.join(' '),
            showNotification: false,
            dropdownComparableItem,
          };
        }
        return {
          ...props,
          className: [...classNames, 'warning'].join(' '),
          showNotification: true,
          dropdownComparableItem,
        };
      }

      if (column.key === columnCropPreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.cropImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.cropImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]].cropId == null
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnSizePreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.fieldSizeImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.fieldSizeImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]].fieldSize == null
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnKindOfUseImported.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        const id = this.$refs.table.physicalRowToGuid(physicalRow);
        let dropdownComparableItem = null;
        if (this.tableData[id] != null && this.tableData[id]['importedField.kindofuse'] != null) {
          dropdownComparableItem = {
            category: this.$t('Nuzungsart in der Datenquelle (aus dem Import)'),
            value: this.tableData[id]['importedField.kindofuse'],
          };
        }
        if (this.tableData[id] == null || this.tableData[id][column.key] != null) {
          return {
            ...props,
            className: classNames.join(' '),
            showNotification: false,
            dropdownComparableItem,
          };
        }
        return {
          ...props,
          className: [...classNames, 'warning'].join(' '),
          showNotification: true,
          dropdownComparableItem,
        };
      }

      if (column.key === columnKindOfUsePreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.kindOfUseImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.kindOfUseImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]].kindofuse == null
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnVarietyImported.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        const id = this.$refs.table.physicalRowToGuid(physicalRow);
        let dropdownComparableItem = null;
        if (this.tableData[id] != null && this.tableData[id]['importedVariety.name'] != null) {
          dropdownComparableItem = {
            category: this.$t('Sorte in der Datenquelle (aus dem Import)'),
            value: this.tableData[id]['importedVariety.name'],
          };
        }
        if (this.tableData[id] == null || this.tableData[id][column.key] != null) {
          return {
            ...props,
            className: classNames.join(' '),
            showNotification: false,
            dropdownComparableItem,
          };
        }
        return {
          ...props,
          className: [...classNames, 'warning'].join(' '),
          showNotification: true,
          dropdownComparableItem,
        };
      }

      if (column.key === columnVarietyPreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.varietyImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.varietyImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]].varietyId == null
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnPreCropImported.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        const id = this.$refs.table.physicalRowToGuid(physicalRow);
        let dropdownComparableItem = null;
        if (this.tableData[id] != null && this.tableData[id]['importedPreCrop.name'] != null) {
          dropdownComparableItem = {
            category: this.$t('Vorfrucht in der Datenquelle (aus dem Import)'),
            value: this.tableData[id]['importedPreCrop.name'],
          };
        }
        if (this.tableData[id] == null || this.tableData[id][column.key] != null) {
          return {
            ...props,
            className: classNames.join(' '),
            showNotification: false,
            dropdownComparableItem,
          };
        }
        return {
          ...props,
          className: [...classNames, 'warning'].join(' '),
          showNotification: true,
          dropdownComparableItem,
        };
      }

      if (column.key === columnPreCropPreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.preCropImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.preCropImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]].preCropId == null
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnCommentPreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.commentImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.commentImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            typeof this.fields[this.tableData[id][columnMatchedFieldId.key]].comment !== 'string' ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]].comment.length === 0
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnAmaCodeImported.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        const id = this.$refs.table.physicalRowToGuid(physicalRow);
        let dropdownComparableItem = null;
        if (this.tableData[id] != null && this.tableData[id]['importedField.amaCode'] != null) {
          dropdownComparableItem = {
            category: this.$t('AMA Code in der Datenquelle (aus dem Import)'),
            value: this.tableData[id]['importedField.amaCode'],
          };
        }
        if (this.tableData[id] == null || this.tableData[id][column.key] != null) {
          return {
            ...props,
            className: classNames.join(' '),
            showNotification: false,
            dropdownComparableItem,
          };
        }
        return {
          ...props,
          className: [...classNames, 'warning'].join(' '),
          showNotification: true,
          dropdownComparableItem,
        };
      }

      if (column.key === columnAmaCodePreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.amaCodeImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.amaCodeImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            typeof this.fields[this.tableData[id][columnMatchedFieldId.key]].amaCode !== 'string' ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]].amaCode.length === 0
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnCadastralCommunityPreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.cadastralCommunityImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.cadastralCommunityImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            typeof getDescendantProp(
              this.fields[this.tableData[id][columnMatchedFieldId.key]],
              'additionalData.cadastralCommunity',
            ) !== 'string' ||
            getDescendantProp(
              this.fields[this.tableData[id][columnMatchedFieldId.key]],
              'additionalData.cadastralCommunity',
            ).length === 0
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnPlotsPreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.plotsImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.plotsImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            typeof getDescendantProp(
              this.fields[this.tableData[id][columnMatchedFieldId.key]],
              'additionalData.plots',
            ) !== 'string' ||
            getDescendantProp(this.fields[this.tableData[id][columnMatchedFieldId.key]], 'additionalData.plots')
              .length === 0
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnOwnerPreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.ownerImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.ownerImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            typeof getDescendantProp(
              this.fields[this.tableData[id][columnMatchedFieldId.key]],
              'additionalData.owner',
            ) !== 'string' ||
            getDescendantProp(this.fields[this.tableData[id][columnMatchedFieldId.key]], 'additionalData.owner')
              .length === 0
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      if (column.key === columnRightOfUsePreview.key) {
        let classNames = [];
        if (typeof column.className === 'string') {
          classNames = column.className.split(' ');
        }
        if (this.rightOfUseImportOption === 'overwrite') {
          classNames.push('overwrite-data');
        } else if (this.rightOfUseImportOption === 'fill') {
          const id = this.$refs.table.physicalRowToGuid(physicalRow);
          if (
            this.tableData[id] == null ||
            this.tableData[id][columnMatchedFieldId.key] == null ||
            this.fields[this.tableData[id][columnMatchedFieldId.key]] == null ||
            typeof getDescendantProp(
              this.fields[this.tableData[id][columnMatchedFieldId.key]],
              'additionalData.rightOfUse',
            ) !== 'string' ||
            getDescendantProp(this.fields[this.tableData[id][columnMatchedFieldId.key]], 'additionalData.rightOfUse')
              .length === 0
          ) {
            classNames.push('overwrite-data');
          }
        }
        return {
          ...props,
          className: classNames.join(' '),
        };
      }

      return props;
    },
    onClickColumnHeaderLink({ column, link }) {
      if (column.key === columnCropPreview.key) {
        this.cropImportOption = link.value;
      }
      if (column.key === columnSizePreview.key) {
        this.fieldSizeImportOption = link.value;
      }
      if (column.key === columnKindOfUsePreview.key) {
        this.kindOfUseImportOption = link.value;
      }
      if (column.key === columnVarietyPreview.key) {
        this.varietyImportOption = link.value;
      }
      if (column.key === columnPreCropPreview.key) {
        this.preCropImportOption = link.value;
      }
      if (column.key === columnCommentPreview.key) {
        this.commentImportOption = link.value;
      }
      if (column.key === columnAmaCodePreview.key) {
        this.amaCodeImportOption = link.value;
      }
      if (column.key === columnCadastralCommunityPreview.key) {
        this.cadastralCommunityImportOption = link.value;
      }
      if (column.key === columnPlotsPreview.key) {
        this.plotsImportOption = link.value;
      }
      if (column.key === columnOwnerPreview.key) {
        this.ownerImportOption = link.value;
      }
      if (column.key === columnRightOfUsePreview.key) {
        this.rightOfUseImportOption = link.value;
      }
      this.$refs.table.hotRender();
    },
    updateEntryByKeyAndValue({ columnKey, guid, value }) {
      this.userErrorMessage = null;
      // use this list to update a dropdown value for ALL rows that have the same imported value
      const importedValueByColumnKey = {
        [columnCropNameImported.key]: 'importedCrop.name',
        [columnKindOfUseImported.key]: 'importedField.kindofuse',
        [columnVarietyImported.key]: 'importedVariety.name',
        [columnPreCropImported.key]: 'importedPreCrop.name',
        [columnAmaCodeImported.key]: 'importedField.amaCode',
        [columnMatchedCustomerId.key]: 'mappedCustomer.id',
      };
      if (columnKey === columnMatchedCustomerId.key) {
        const referenceValue = this.tableData[guid][columnKey];
        this.overwriteRowsOfMatchingValues({
          referenceColumn: columnMatchedCustomerId.key,
          targetColumn: columnMatchedFieldId.key,
          referenceValue,
          targetValue: 'new_field',
        });
      }

      if (columnKey === columnMatchedFieldId.key) {
        const foundField = this.fields[value];
        const customerId = foundField?.fieldGroup.customerId ?? '';
        this.tableData[guid][columnMatchedCustomerId.key] = customerId;
      }

      if (Object.keys(importedValueByColumnKey).includes(columnKey)) {
        this.updateMultipleEntries({
          columnKey,
          importedValueColumnKey: importedValueByColumnKey[columnKey],
          guid,
          value,
        });
        return;
      }
      this.tableData = {
        ...this.tableData,
        [guid]: {
          ...this.tableData[guid],
          [columnKey]: value,
        },
      };
    },
    /**
     * Set the value in the given column key for:
     *   - the field with the given id
     *   - AND all fields with the same value for importedValueColumnKey as the field with the given id
     *
     * @param columnKey
     * @param importedValueColumnKey
     * @param guid
     * @param value
     */
    updateMultipleEntries({ columnKey, importedValueColumnKey, guid, value }) {
      const importedValue = this.tableData[guid][importedValueColumnKey];
      this.tableData = Object.values(this.tableData).reduce((currentTableData, newField) => {
        if (
          newField.id === guid ||
          (newField[columnKey] == null && newField[importedValueColumnKey] === importedValue)
        ) {
          return {
            ...currentTableData,
            [newField.id]: {
              ...newField,
              [columnKey]: value,
            },
          };
        }
        return currentTableData;
      }, this.tableData);
    },
    /**
     * Overwrites all values in the target column, if matches the source value found in the reference column.
     *
     * @param referenceColumn the column key for which rows are matched
     * @param targetColumn the colum key whose values should be overwritten if matching
     * @param referenceValue the value to compare against
     * @param targetValue the value that will be written in the target column
     */
    overwriteRowsOfMatchingValues({ referenceColumn, targetColumn, referenceValue, targetValue }) {
      this.tableData = Object.values(this.tableData).reduce((currentTableData, newField) => {
        if (newField[referenceColumn] === referenceValue) {
          return {
            ...currentTableData,
            [newField.id]: {
              ...newField,
              [targetColumn]: targetValue,
            },
          };
        }
        return currentTableData;
      }, this.tableData);
    },
    calculateSizeTotalAddFieldSize(total, field) {
      const fieldSize = Number(this.getFieldSizeForImportedRow(field.id));
      if (Number.isNaN(fieldSize)) {
        return total;
      }
      return total + fieldSize;
    },
    async next() {
      this.userErrorMessage = null;
      await this.$nextTick();
      if (this.selectedRows.length < 1) {
        this.userErrorMessage = this.$t('Du musst zumindest ein Feld für den Import wählen.');
        return;
      }

      let overwriteExistingFieldsCount = 0;
      let missingCrops = false;
      const requestData = this.selectedRows.map((id) => {
        let fieldId = this.tableData[id][columnMatchedFieldId.key];
        if (fieldId == null || fieldId === 'new_field') {
          fieldId = createUuid();
        } else {
          overwriteExistingFieldsCount += 1;
        }
        if (this.tableData[id][columnCropNameImported.key] == null) {
          missingCrops = true;
        }
        const newField = requestDataCreateFromEntryAndModel(
          {
            processOrderId: this.importTargetProcessOrderId,
            'fieldGroup.mfa': this.tableData[id][columnImportedFieldGroupNr.key],
            'fieldGroup.customerId': this.tableData[id][columnMatchedCustomerId.key],
            fieldNumber: this.tableData[id][columnImportedFieldNumber.key],
            name: this.tableData[id][columnImportedFieldName.key],
            cropId: this.getCropIdForImportedRow(id) || null,
            fieldSize: this.getFieldSizeForImportedRow(id),
            kindofuse: this.getKindOfUseIdForImportedRow(id),
            varietyId: this.getVarietyIdForImportedRow(id),
            preCropId: this.getPreCropIdForImportedRow(id),
            comment: this.getCommentForImportedRow(id),
            amaCode: this.getAmaCodeIdForImportedRow(id),
            additionalData: {
              cadastralCommunity: this.getCadastralCommunityForImportedRow(id),
              plots: this.getPlotsForImportedRow(id),
              owner: this.getOwnerForImportedRow(id),
              rightOfUse: this.getRightOfUseForImportedRow(id),
            },
          },
          model,
          modelDefinition,
        );
        if (
          this.tableData[id]['importedField.shape'] != null &&
          this.tableData[id]['importedField.shape'].type === 'Polygon'
        ) {
          const { lat, lon } = getLatLonFromGeoJson(this.tableData[id]['importedField.shape']);
          newField.fieldContour = {
            geoJson: this.tableData[id]['importedField.shape'],
            fieldId,
          };
          newField.fieldContour.id = createUuidv5(UUID5_FIELD_CONTOUR_NODE, fieldId);
          newField.lat = lat;
          newField.lon = lon;
        }
        return {
          ...newField,
          id: fieldId,
        };
      });

      let confirmed = true;
      if (
        overwriteExistingFieldsCount > 0 ||
        requestData.length / Object.keys(this.tableData).length < 0.5 ||
        (missingCrops && ['overwrite', 'fill'].includes(this.cropImportOption))
      ) {
        const messages = [];
        if (requestData.length > 1) {
          messages.push(this.$t('Du importierst {count} Felder.', { count: requestData.length }));
        } else if (requestData.length === 1) {
          messages.push(this.$t('Du importierst ein Feld.'));
        }
        if (overwriteExistingFieldsCount > 1) {
          messages.push(
            this.$t('Dabei werden {count} bestehende Felder verändert.', { count: overwriteExistingFieldsCount }),
          );
        } else if (overwriteExistingFieldsCount === 1) {
          messages.push(this.$t('Dabei wird ein bestehendes Feld verändert.'));
        }
        if (
          this.tableSettings.columns.some((column) => column.key === columnCropNameImported.key) &&
          missingCrops &&
          ['overwrite', 'fill'].includes(this.cropImportOption)
        ) {
          messages.push(this.$t('Nicht alle importierten Kulturen konnten FARMDOK Kulturen zugeordnet werden.'));
        }
        messages.push(this.$t('Möchtest du fortfahren?'));
        confirmed = await this.$confirm({
          title: this.$t('Importieren'),
          message: messages.join(' '),
        });
      }
      if (!confirmed) {
        return;
      }

      this.$emit('update:fetching', true);
      let responseData;
      try {
        const chunks = chunk(requestData, 100);
        const promises = chunks.map((c) =>
          axios
            .put('/admin/rest/field/', {
              version: '2.0',
              data: c,
            })
            .then((response) => response.data),
        );
        const data = (await Promise.all(promises)).reduce((res1, res2) => ({
          ...res1,
          data: [...res1.data, ...res2.data],
          total: res1.total + res2.total,
        }));
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      }

      if (responseData.status !== 'success') {
        this.setUserErrorMessageFromResponse(responseData);
        await this.$router.push({
          name: 'fields/import/load-data',
          params: { userErrorMessage: responseData.errorUserMessage[0], importNextEnabled: true, method: 'upload' },
        });
      } else {
        this.importSuccess = true;
        this.userErrorMessage = null;

        if (this.manualMapping != null && Object.keys(this.manualMapping).length > 0) {
          await Promise.all([
            axios.post('/admin/rest/field/import_store_custom_mapping'),
            axios.post('/admin/rest/field/import_upload_files_reset'),
            this.$store.dispatch('fields/reset'),
          ]);
        } else {
          await Promise.all([
            axios.post('/admin/rest/field/import_upload_files_reset'),
            this.$store.dispatch('fields/reset'),
          ]);
        }
        await this.$router.push({ name: 'fields' });
      }

      this.$emit('update:fetching', false);
    },
  },
};
</script>

<style scoped>
.import-preview-data {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.import-preview-data__table {
  flex: 1;
}
</style>
