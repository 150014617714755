<template>
  <!-- have to use id as tooltip.container doesnt work with HTMLElement as of v2.0.4 -->

  <div id="application-maps__export" class="export text-dark">
    <SidebarContentWrapper>
      <h2 class="text-black">{{ $t('Sende Applikationskarte') }}</h2>
      <div>
        <Tabs class="mt-3" :tabs="tabs" fill />
        <DownloadTab
          v-if="buttonDownload.active"
          :workflowKey="workflowKey"
          :buttonsDisabled="downloadButtonDisabled"
          @exportTriggered="setStepsCompleted"
          @createApplicationMapEvent="createApplicationMapEvent"
        />
        <SendToTab
          v-if="buttonSendTo.active"
          :workflowKey="workflowKey"
          :buttonsDisabled="buttonsDisabled"
          @sendApplicationMapTriggered="setStepsCompleted"
          @createApplicationMapEvent="createApplicationMapEvent"
        />
        <PlanTaskTab
          v-if="buttonPlanTask.active"
          :workflowKey="workflowKey"
          :buttonsDisabled="planTaskButtonDisabled"
          @planTaskTriggered="setStepsCompleted"
          @update:errorMessage="(e) => $emit('update:errorMessage', e)"
        />
      </div>
      <SidebarSubHeadline v-if="$slots.default" class="pl-0" :text="$t('Zusammenfassung je Schlag')" />
      <slot></slot>
    </SidebarContentWrapper>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';

import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';
import Tabs from '@/shared/components/Tabs.vue';

import { ZONE_GENERATION_MODE_SATELLITE, ZONE_GENERATION_MODE_UPLOAD } from '../../store/baseWorkflowStore/common';
import DownloadTab from './DownloadTab.vue';
import PlanTaskTab from './PlanTaskTab.vue';
import SendToTab from './SendToTab.vue';
import getDatabaseId from './companyUtils';

export default {
  name: 'Export',
  components: {
    SidebarContentWrapper,
    SidebarSubHeadline,
    Tabs,
    DownloadTab,
    SendToTab,
    PlanTaskTab,
  },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttonDownload: {
        type: 'button',
        label: this.$t('Download'),
        active: true,
        onClick: () => {
          this.buttonDownload.active = true;
          this.buttonSendTo.active = false;
          this.buttonPlanTask.active = false;
        },
      },
      buttonSendTo: {
        type: 'button',
        label: this.$t('Senden an'),
        active: false,
        onClick: () => {
          this.buttonDownload.active = false;
          this.buttonSendTo.active = true;
          this.buttonPlanTask.active = false;
        },
      },
      buttonPlanTask: {
        type: 'button',
        label: this.$t('Auftrag planen'),
        active: false,
        onClick: () => {
          this.buttonDownload.active = false;
          this.buttonSendTo.active = false;
          this.buttonPlanTask.active = true;
        },
      },
    };
  },
  watch: {
    selectedCompany: {
      immediate: true,
      handler() {
        if (!this.selectedCompany.id) {
          this.setSelectedCompany(this.currentCompanies[0]);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      fields: 'fields',
      byRoughAndFineId: 'activityTypes/byRoughAndFineId',
      processOrderByCompanyIdAndNameAndType: 'auth/processOrderByCompanyIdAndNameAndType',
    }),
    ...mapState({
      currentHeatmaps(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/currentHeatmaps`];
      },
      uploadedZonesByFilename(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/uploadedZonesByFilename`];
      },
      zoneGenerationMode(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].zoneGenerationMode;
      },
      fetchingTask(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].fetchingTask;
      },
      fetchingMaterial(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].fetchingMaterial;
      },
      currentCompanies(state) {
        return state.auth.currentCompanies;
      },
      selectedCompany(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].selectedCompany;
      },
    }),
    tabs() {
      return [this.buttonDownload, this.buttonSendTo, this.buttonPlanTask];
    },
    buttonsDisabled() {
      if (this.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
        return Object.values(this.currentHeatmaps).length === 0;
      }
      if (this.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
        return Object.values(this.uploadedZonesByFilename).length === 0;
      }
      return true;
    },
    downloadButtonDisabled() {
      return this.fetchingMaterial || this.buttonsDisabled;
    },
    planTaskButtonDisabled() {
      return this.fetchingTask || this.fetchingMaterial || this.buttonsDisabled;
    },
  },
  methods: {
    setStepsCompleted() {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setStepsCompleted`, true);
    },
    setSelectedCompany(selectedCompany) {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setSelectedCompany`, selectedCompany);
    },
    async createApplicationMapEvent(taskData) {
      try {
        const databaseId = getDatabaseId(this.selectedCompany);
        await axios.post(`/admin/comp/${databaseId}/precision-farming/application-maps/event`, taskData);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.export {
  height: calc(100% - 105px);
  overflow: auto;
  overflow-x: hidden;
}

h2 {
  font-size: 20px;
}

h2,
.subline {
  font-weight: 600;
}
</style>
