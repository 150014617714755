import { ProductCategory } from 'farmdok-rest-api';

import { Company } from '@/auth/store/types';
import { ProcessOrder, Product, ProductVisibility } from '@/shared/api/rest/models';

import isHerbicide from '../herbizides/isHerbicide';

export function filterSgdVersion(product: Product, processOrders: ProcessOrder[]): boolean {
  if (!product.sgdVersion) return true;

  const sgdVersion = processOrders[0]?.sgdVersion;
  const haveSameSgdVersion = processOrders.every((processOrder) => processOrder.sgdVersion === sgdVersion);
  if (!haveSameSgdVersion) {
    throw new Error('processOrders must have same sgdVersion defined');
  }

  if (sgdVersion === null) {
    return true;
  }

  return sgdVersion === product.sgdVersion;
}

export function filterExpiredHerbizideProducts(
  product: Product,
  activityTimeStarts: number[],
  productCategories: Record<string, ProductCategory>,
): boolean {
  return (
    !isHerbicide(product, productCategories) ||
    !product?.pesticide?.gracePeriodUsageDate ||
    product.pesticide.gracePeriodUsageDate > (activityTimeStarts.length > 0 ? Math.min(...activityTimeStarts) : 0)
  );
}

export function filterMulticompany(product: Product, companies: Company[]): boolean {
  if (isGlobalProduct(product)) {
    const regionId = companies[0]?.regionId;
    const allCompaniesInSameRegion = companies.every((company) => company.regionId === regionId);

    if (!allCompaniesInSameRegion) return false;

    const productInSameRegionAsCompanies = product.regionId === regionId;
    return productInSameRegionAsCompanies;
  }

  return product.companyClusterSync === 1;
}

export function filterProductVisibilities(
  product: Product,
  productSiblings: Product[],
  companies: Company[],
  productVisibilities: ProductVisibility[],
): boolean {
  const productFamily = [product, ...productSiblings];

  if (productFamily.length === 0 || companies.length === 0) {
    return true; // ignore this filter
  }

  const productIds = productFamily.map((p) => p.id);
  const companyIds = companies.map((c) => c.id);

  const visibilityPairs = productVisibilities.filter(
    (candidate) => productIds.includes(candidate.productId) && companyIds.includes(candidate.companyId),
  );

  if (visibilityPairs.length === 0) {
    return true; // no match, ignore filter
  }

  const showProduct = visibilityPairs.some((pair) => pair.visible === 1);

  return showProduct;
}

/**
 * filters the list of products and returns a list of products without duplicates from synced companies
 * @param products
 * @returns
 */
export function filterSiblingDuplicates(products: Product[]): Product[] {
  const filtered: Product[] = [];

  products.forEach((product) => {
    if (isProductWithoutSiblings(product)) {
      filtered.push(product);
    }

    if (!containsSibling(filtered, product)) {
      filtered.push(product);
    }
  });

  return filtered;
}

export function findFamilyOfProduct(product: Product, allProducts: Product[]): Product[] {
  if (isProductWithoutSiblings(product)) return [];

  return allProducts.filter((candidate) => candidate.relatedMaterialId === product.relatedMaterialId);
}

export function filterFavoriteProducts(product: Product, fieldCropIds: string[]): boolean {
  if (!product.mainCropId) {
    return false;
  }
  return fieldCropIds.includes(product.mainCropId);
}

export function filterOtherProducts(product: Product, primaryCategoryId: string | undefined): boolean {
  if (!primaryCategoryId) {
    return true;
  }
  return product.categoryId !== primaryCategoryId;
}

export function filterCompatibleProducOfCategory(product: Product, productIdsOfCategory: string[]): boolean {
  const productIdWithoutStorageId = product.id.split('_')[0];
  return productIdsOfCategory.includes(productIdWithoutStorageId);
}

export function isGlobalProduct(product: Product): boolean {
  return isProductWithoutSiblings(product) && !product.companyId;
}

export function isSibling(productA: Product, productB: Product): boolean {
  if (productA.id === productB.id) return true;
  if (
    productA.relatedMaterialId === productB.relatedMaterialId &&
    productA.relatedMaterialId !== null &&
    productB.relatedMaterialId !== null
  )
    return true;

  return false;
}

function isProductWithoutSiblings(product: Product): boolean {
  return !product.relatedMaterialId;
}

function containsSibling(products: Product[], candidate: Product): boolean {
  return products.some((product) => product.relatedMaterialId === candidate.relatedMaterialId);
}
