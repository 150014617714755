import Handsontable from 'handsontable';

function handleKeyClickFactory(hot: Handsontable | null) {
  function handleKeyClick(event: KeyboardEvent) {
    if (!hot) return;

    if (event.key === 'ArrowRight' || event.key === 'Tab') {
      const selected = hot.getSelected();
      if (!selected) return;
      const visualRow = selected[0][0];
      const visualColumn = selected[0][1];

      const nextCellMeta = hot.getCellMeta(visualRow, visualColumn + 1);

      if (
        (typeof nextCellMeta.renderNone === 'function' && nextCellMeta.renderNone(visualRow, hot)) ||
        nextCellMeta.hidden
      ) {
        event.stopImmediatePropagation();

        for (let col = visualColumn + 1; col < hot.countCols(); col += 1) {
          const currentCellMeta = hot.getCellMeta(visualRow, col);

          const currentCellRenderNone =
            typeof currentCellMeta.renderNone === 'function' ? currentCellMeta.renderNone(visualRow, hot) : null;

          if (!currentCellRenderNone && !currentCellMeta.hidden) {
            hot.selectCell(visualRow, col);
            break;
          }
        }
      }
    }
    if (event.key === 'ArrowLeft') {
      const selected = hot.getSelected();
      if (!selected) return;
      const visualRow = selected[0][0];
      const visualColumn = selected[0][1];
      const beforeCellMeta = hot.getCellMeta(visualRow, visualColumn - 1);

      if (
        (typeof beforeCellMeta.renderNone === 'function' && beforeCellMeta.renderNone(visualRow, hot)) ||
        beforeCellMeta.hidden
      ) {
        event.stopImmediatePropagation();

        for (let col = visualColumn - 1; col > 1; col -= 1) {
          const currentCellMeta = hot.getCellMeta(visualRow, col);

          const currentCellRenderNone =
            typeof currentCellMeta.renderNone === 'function' ? currentCellMeta.renderNone(visualRow, hot) : null;

          if (!currentCellRenderNone && !currentCellMeta.hidden) {
            hot.selectCell(visualRow, col);
            break;
          }
        }
      }
    }
    if (event.key === 'ArrowDown') {
      const selected = hot.getSelected();
      if (!selected) return;
      const visualRow = selected[0][0];
      const visualColumn = selected[0][1];
      const nextVisualRow = visualRow + 1;
      const nextCellMeta = hot.getCellMeta(nextVisualRow, visualColumn);

      if (
        (typeof nextCellMeta.renderNone === 'function' && nextCellMeta.renderNone(nextVisualRow, hot)) ||
        nextCellMeta.hidden
      ) {
        event.stopImmediatePropagation();

        for (let row = nextVisualRow; row < hot.countRows(); row += 1) {
          const currentCellMeta = hot.getCellMeta(row, visualColumn);

          const currentCellRenderNone =
            typeof currentCellMeta.renderNone === 'function' ? currentCellMeta.renderNone(row, hot) : null;

          if (!currentCellRenderNone && !currentCellMeta.hidden) {
            hot.selectCell(row, visualColumn);
            break;
          }
        }
      }
    }
    if (event.key === 'ArrowUp') {
      const selected = hot.getSelected();
      if (!selected) return;
      const visualRow = selected[0][0];
      const visualColumn = selected[0][1];
      const beforeVisualRow = visualRow - 1;
      const beforeCellMeta = hot.getCellMeta(beforeVisualRow, visualColumn);

      if (
        (typeof beforeCellMeta.renderNone === 'function' && beforeCellMeta.renderNone(beforeVisualRow, hot)) ||
        beforeCellMeta.hidden
      ) {
        event.stopImmediatePropagation();

        for (let row = beforeVisualRow; row > 1; row -= 1) {
          const currentCellMeta = hot.getCellMeta(row, visualColumn);

          const currentCellRenderNone =
            typeof currentCellMeta.renderNone === 'function' ? currentCellMeta.renderNone(row, hot) : null;

          if (!currentCellRenderNone && !currentCellMeta.hidden) {
            hot.selectCell(row, visualColumn);
            break;
          }
        }
      }
    }
  }

  return handleKeyClick;
}

// add these hooks to your handsontable instance
const hooks = {
  beforeKeyDown: { handleKeyClickFactory },
};

export default hooks;
