import Handsontable from 'handsontable';

import ReadOnlyRenderer from '@/shared/handsontable/renderers/ReadOnlyRenderer';

function onCellDoubleClick(event) {
  if (
    event.currentTarget == null ||
    !(event.currentTarget instanceof HTMLElement) ||
    event.currentTarget.querySelector('button') == null
  ) {
    return;
  }
  event.currentTarget.querySelector('button').click();
}

function FieldGroupArchivedRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  [...TD.childNodes].forEach((childNode) => {
    if (childNode instanceof HTMLElement && childNode.matches('button.hot-btn-field-group-archived')) {
      return;
    }
    TD.removeChild(childNode);
  });
  const statusReadOnly = cellProperties.getStatusReadOnly(row);

  // remember the button as it is used as target for Bootstrap popovers
  let button = TD.querySelector('.hot-btn-field-group-archived');
  if (button == null) {
    button = document.createElement('button');
  }
  button.innerHTML = '';
  button.className = '';
  button.innerText = cellProperties.getDisplayValue(value);
  button.className = 'hot-btn-field-group-archived';
  if (value) {
    button.classList.add('hot-btn-field-group-archived--archived');
  } else {
    button.classList.add('hot-btn-field-group-archived--active');
  }
  TD.removeEventListener('dblclick', onCellDoubleClick);
  TD.addEventListener('dblclick', onCellDoubleClick);

  // assign events like this as the button is re-used, so we dont add multiple listeners
  button.onclick = () => {
    if (statusReadOnly) {
      return;
    }
    if (typeof cellProperties.onClick === 'function') {
      cellProperties.onClick({ target: button, visualRow: row });
    }
  };
  button.onmousedown = (event) => {
    event.stopPropagation();
  };
  TD.append(button);

  if (statusReadOnly) {
    button.disabled = true;
    button.classList.add('hot-btn-field-group-archived--read-only');
  }

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);
  }
}

Handsontable.cellTypes.registerCellType('fieldGroupArchived', {
  renderer: FieldGroupArchivedRenderer,
});
