import Handsontable from 'handsontable';

import { AmountUnitPair } from '@/activities/types';
import parseNumber from '@/shared/modules/parseNumber';

// @ts-ignore
export default class AmountUnitEditor extends Handsontable.editors.NumericEditor {
  amountUnitPair: AmountUnitPair | undefined;

  init(): void {
    super.init();
    // TODO create html element for unit
  }

  prepare(
    row: number,
    col: number,
    prop: string | number,
    TD: HTMLTableCellElement,
    originalValue: AmountUnitPair | undefined,
    cellProperties: Handsontable.CellProperties,
  ): void {
    super.prepare(row, col, prop, TD, originalValue?.amount, cellProperties);

    this.amountUnitPair = originalValue;
  }

  getValue(): AmountUnitPair {
    const amount = super.getValue();
    const numericAmount = parseNumber(amount);

    if (this.amountUnitPair === undefined) {
      throw new Error('AmountUnitEditor.amountUnitPair is undefined, call prepare() first');
    }

    return { ...this.amountUnitPair, amount: numericAmount, isFixed: true };
  }
}
