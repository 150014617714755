import Vue from 'vue';
import { GetterTree } from 'vuex';

import { Field } from '@/shared/api/rest/models';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { DropdownItems } from '@/shared/handsontable/types';
import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { FieldsState } from './types';
import { getCropNamePart, getCustomerNamePart, getFieldNamePart } from './utils/getterUtils';

export type Getters = SubscribableGetters<Field> & {
  kindOfUseTypes: FieldsState['kindOfUseTypes'];
  kindOfUseTypesById: Record<string, FieldsState['kindOfUseTypes']>;
  catchCropVariants: FieldsState['catchCropVariants'];
  catchCropVariantsById: Record<string, FieldsState['catchCropVariants']>;
  amaCodes: FieldsState['amaCodes'];
  amaCodesById: Record<string, FieldsState['amaCodes']>;
  gwAreas: FieldsState['gwAreas'];
  gwAreasById: Record<string, FieldsState['gwAreas']>;
  fieldDisplayName(fieldId: string): string | null;
  dropdownItem(fieldId: string): DropdownItem | null;
  dropdownItems(processOrderId: string): DropdownItems[];
  isUniqueFieldName(fieldName: string): boolean;
};

const gettersModule: GetterTree<FieldsState, RootState> = {
  ...subscribableStore.getters,
  kindOfUseTypes: (state) => state.kindOfUseTypes,
  kindOfUseTypesById: (state) =>
    state.kindOfUseTypes.reduce(
      (kindOfUseTypesById, kindOfUse) => ({
        ...kindOfUseTypesById,
        [kindOfUse.id]: kindOfUse,
      }),
      {},
    ),
  catchCropVariants: (state) => state.catchCropVariants,
  catchCropVariantsById: (state) =>
    state.catchCropVariants.reduce(
      (catchCropVariantsById, catchCropVariant) => ({
        ...catchCropVariantsById,
        [catchCropVariant.id]: catchCropVariant,
      }),
      {},
    ),
  amaCodes: (state) => state.amaCodes,
  amaCodesById: (state) =>
    state.amaCodes.reduce(
      (amaCodesById, amaCode) => ({
        ...amaCodesById,
        [amaCode.id]: amaCode,
      }),
      {},
    ),
  gwAreas: (state) => state.gwAreas,
  gwAreasById: (state) =>
    state.gwAreas.reduce(
      (gwAreasById, gwArea) => ({
        ...gwAreasById,
        [gwArea.id]: gwArea,
      }),
      {},
    ),
  fieldDisplayName:
    (state: FieldsState, getters: Getters, rootState: RootState) =>
    (fieldId: string): string | null => {
      const field = state.data[fieldId];
      if (!field) return null;

      // @ts-ignore
      const customerNamePart = getCustomerNamePart(field.fieldGroup?.customerId, rootState.customers.data);
      const fieldNamePart = getFieldNamePart(field);
      const cropNamePart = getCropNamePart(field.cropId, rootState.products.crops.data);

      return `${customerNamePart}${fieldNamePart}${cropNamePart}`;
    },
  dropdownItem:
    (state: FieldsState, getters: Getters) =>
    (fieldId: string): DropdownItem | null => {
      const field = state.data[fieldId];
      if (!field) return null;

      return {
        id: field.id,
        name: getters.fieldDisplayName(field.id) ?? '',
      };
    },
  dropdownItems:
    (state: FieldsState, getters: Getters) =>
    (processOrderId: string): DropdownItemsPerCategory[] => {
      const fields = state.data;
      return [
        {
          name: Vue.i18n.translate('Felder'),
          id: 'fields',
          items: Object.values(fields)
            .filter((f: Field) => f.processOrderId === processOrderId)
            .map((f: Field): DropdownItem => ({ id: f.id, name: getters.fieldDisplayName(f.id) ?? '' })),
          sort: true,
        },
      ];
    },
  isUniqueFieldName: (state: FieldsState) => (fieldName: string) =>
    Object.values(state.data)
      .map((field) => field.name)
      .includes(fieldName),
};

export default gettersModule;
