import { render, staticRenderFns } from "./TableContextMenu.vue?vue&type=template&id=5a32704d&scoped=true&"
import script from "./TableContextMenu.vue?vue&type=script&lang=js&"
export * from "./TableContextMenu.vue?vue&type=script&lang=js&"
import style0 from "./TableContextMenu.vue?vue&type=style&index=0&id=5a32704d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a32704d",
  null
  
)

export default component.exports