import { AuthState, UserInfo } from './types';

export function initialState(): AuthState {
  return {
    jwtAuth: null,
    loggedIn: false,
    fetchingInitialAccessToken: false,
    fetchingLogin: false,
    fetchingUserInfo: false,
    fetchingRegister: false,
    fetchingCompanies: false,
    observers: [],
    ...initialStateUserInfo(),
  };
}

export function initialStateUserInfo(): UserInfo {
  return {
    userInfoLoaded: false,

    userCompanies: [],
    companiesById: {},
    currentCompanies: [],
    currentProcessOrderName: null,

    user: {
      anonymousAccount: 0,
      businessCustomer: 0,
      companyId: '',
      confirmToken: null,
      created: 0,
      deleted: null,
      email: '',
      emailIsConfirmed: false,
      firstname: '',
      id: '',
      isPartner: 0,
      languageId: '',
      lastname: '',
      newsletter: 0,
      superAdmin: 0,
      tstamp: 0,
      vatInformation: null,
    },
  };
}
