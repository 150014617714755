import { MoveActivityState } from './types';

function initialState(): MoveActivityState {
  return {
    selectedProcessOrderId: null,
    selectedCompanyId: null,
    moveActivityParametersWithResult: null,
    trials: 0,
    counterCurrentMoveActivityConflict: 0,
    keepOption: false,
  };
}

export default initialState;
