<template>
  <BFormInput
    v-on="$listeners"
    v-model="internalValue"
    :placeholder="internalPlaceholder"
    :id="id"
    :type="type"
    :required="internalRequired"
    :disabled="internalDisabled"
    :state="state"
    v-b-tooltip="tooltipOptions"
    class="form-control--text-input"
    trim
  />
</template>

<script>
import baseInput from '@/shared/mixins/form/baseInput';

/**
 * Use FormFieldInput instead.
 *
 * @deprecated
 */
export default {
  name: 'BaseInput',
  mixins: [baseInput],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
    },
  },
};
</script>
