export type DropdownItemsPerCategory = {
  name?: string;
  id?: string;
  items: DropdownItem[];
  sort?: boolean;
};

export type DropdownItem<T = never> = {
  id: string | null;
  name: string;
  value?: T;
};

export function isDropdownItem(value: unknown): value is DropdownItem {
  return typeof value === 'object' && value != null && 'id' in value && 'name' in value;
}
