<template>
  <FontAwesomeIcon
    v-if="status === 'completed'"
    v-b-tooltip.hover="$t('Abgeschlossen')"
    icon="check-circle"
    class="field-status-icon field-status-icon--completed"
  />
  <FontAwesomeIcon
    v-else-if="status === 'active'"
    v-b-tooltip.hover="$t('Aktiv')"
    icon="dot-circle"
    class="field-status-icon field-status-icon--active"
  />
  <FontAwesomeIcon v-else-if="status === 'planned'" v-b-tooltip.hover="$t('Geplant')" icon="clock" />
  <FontAwesomeIcon
    v-else-if="status === 'calculating'"
    v-b-tooltip.hover="$t('Berechnen ...')"
    :icon="['fad', 'spinner-third']"
  />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle, faClock, faDotCircle } from '@fortawesome/pro-solid-svg-icons';

library.add(faCheckCircle, faDotCircle, faClock, faSpinnerThird);

export default {
  name: 'FieldStatusIcon',
  props: {
    status: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.field-status-icon--completed {
  color: var(--gray_600);
}

.field-status-icon--active {
  color: var(--primary);
}
</style>
