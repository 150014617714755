<template>
  <ModalWrapper
    size="xl"
    no-cancel-button
    :visible="visible"
    :title="$t('Felder exportieren')"
    :ok-title="$t('Schließen')"
    @change="(value) => $emit('change', value)"
  >
    <div v-if="showEAmaInfo">
      <!-- not translated because it's only visible for AT companies -->
      <h2>Export aus dem eAMA INVEKOS-GIS Client</h2>
      <p>Tut uns leid, die Schritte liegen nicht in unserer Macht...</p>
      <ol>
        <li>
          Steige bei eAMA mit deiner Betriebsnummer und deinem Passwort ein (<a
            href="http://www.eama.at"
            target="_blank"
            >www.eama.at</a
          >)
        </li>
        <li>
          Wähle im Registerblatt
          <strong>"Flächen" - "Flächen erfassen" - "INVEKOS-GIS" - "INVEKOS-GIS ab 2015 aufrufen".</strong>
        </li>
        <li>
          Die <strong>Datei "default.jnlp"</strong> wird auf deinen Rechner geladen. Öffne die Datei. Der Java GeoMedia
          Smart Client wird geöffnet.
        </li>
        <li>
          Wechsle in das Fenster mit der Landkarte. Gehe am oberen Rand auf
          <strong>"Extras" - "GIS Datenexport"</strong> (ganz links).
        </li>
        <li>Wähle das Kontrollkästchen für das <strong>Dateiformat "XML"</strong> an und drücke weiter.</li>
        <li>
          Wähle das Kontrollkästchen <strong>"beantragte Schläge inkl. GSTK (XML)"</strong> und drücke
          <strong>"Anfordern"</strong>.
        </li>
        <li>
          In der darunter liegenden Liste erscheint ein neuer Eintrag. Gehe rechts auf <strong>"Download"</strong> und
          speichere die Datei lokal auf deinem Rechner (am besten am Desktop, da findest du sie einfach wieder).
        </li>
      </ol>
    </div>

    <div v-if="showGermanInfo">
      <!-- not translated because it's only visible for DE companies -->
      <h2>Export aus Antragssystem</h2>
      <p>
        Exportiere deine Flächen- und Geodaten aus dem in Ihrem Bundesland verwendeten Antragssystem.<br />
        In der Tabelle unterhalb sind die unterstützten Dateiformate für Ihr Bundesland angeführt.
      </p>
    </div>

    <div>
      <!-- this part will be visible for all companies -->
      <h2>{{ $t('Import in Farmdok') }}</h2>
      <ol>
        <li>
          <!-- eslint-disable max-len -->
          {{
            $t(
              'Ziehe die exportierte(n) Datei(en) mit der Maus in das weiße Rechteck mit dem strichlierten Rahmen (Drag &amp; Drop).',
            )
          }}
          <!-- eslint-enable max-len -->
          <div v-if="!showEAmaInfo">
            <!-- eslint-disable max-len -->
            {{
              $t(
                'Falls deine Exportdateien als zip-Datei zur Verfügung stehen, kannst du ganz einfach diese zip-Datei inkl. aller Daten in das weiße Rechteck ziehen.',
              )
            }}
            <!-- eslint-enable max-len -->
          </div>
        </li>
        <li>{{ $t('Die Importvorschau wird geladen. Hier kannst du bereits Anpassungen vornehmen.') }}</li>
        <li>{{ $t('Drücke "Importieren" um den Import abzuschließen.') }}</li>
      </ol>
      <p>
        <!-- eslint-disable max-len -->
        {{
          $t(
            'Fertig! Die Felder/Feldstücke inkl. Geokonturen wurden importiert und stehen für die Maßnahmenbuchung zur Verfügung!',
          )
        }}
        <!-- eslint-enable max-len -->
      </p>
      <p>
        {{ $t('Probleme? Bitte schreib uns an support@farmdok.com und übermittle die verwendeten Importdateien.') }}
      </p>
    </div>

    <div v-if="showGermanInfo">
      <!-- not translated because it's only visible for DE companies -->
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>
              <p><strong>Bundesland</strong></p>
            </td>
            <td>
              <p><strong>Dateiformat</strong></p>
            </td>
            <td>
              <p><strong>Erklärung zum Import</strong></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Bayern</p>
            </td>
            <td>
              <p>shape</p>
            </td>
            <td>
              <p><a target="_blank" href="https://www.farmdok.com/import-anleitung/">Zur Anleitung</a></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Nordrhein-Westfalen</p>
            </td>
            <td>
              <p>
                Shape<br /><em>oder</em><u><br /></u>gml
              </p>
            </td>
            <td>
              <p>
                Verwende die Dateien im Ordner TS_SHAPE. Importiere alle 4 Dateien (.dbf .prj .shp .shx)<br /><em
                  >oder</em
                ><u><br /></u>Verwende die TS .gml Datei (eine Datei)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Mecklenburg-Vorpommern</p>
            </td>
            <td>
              <p>shape</p>
            </td>
            <td>
              <p>Importiere alle 4 Dateien (.dbf .prj .shp .shx)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                Brandenburg<br />
                Berlin
              </p>
            </td>
            <td>
              <p>xml</p>
            </td>
            <td>
              <p>Verwende die .xml Datei (eine Datei)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Sachsen-Anhalt</p>
            </td>
            <td>
              <p>
                Xml<br /><em>und</em><u><br /></u>shape
              </p>
            </td>
            <td>
              <p>
                Verwende <em>beide</em> Dateiformate (importiere am besten alle Dateien gleichzeitig):<br />
                xml (eine Datei)<br /><em>und</em><u><br /></u>Shape (Teilflächen; 4 Dateien – .dbf .prj .shp .shx)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                Schleswig-Holstein<br />
                Hamburg
              </p>
            </td>
            <td>
              <p>xml</p>
            </td>
            <td>
              <p>Verwende die .xml Datei (eine Datei)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Sachsen</p>
            </td>
            <td>
              <p>shape</p>
            </td>
            <td>
              <p>Verwende die Dateien mit der Bezeichnung „Schläge“. Importiere alle 4 Dateien (.dbf .prj .shp .shx)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Hessen</p>
            </td>
            <td>
              <p>shape</p>
            </td>
            <td>
              <p>Verwende die Dateien mit der Bezeichnung „SCHLAG“. Importiere alle 4 Dateien (.dbf .prj .shp .shx)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Saarland</p>
            </td>
            <td>
              <p>shape</p>
            </td>
            <td>
              <p>Verwende die Dateien mit der Bezeichnung „schlag“. Importiere alle 4 Dateien (.dbf .prj .shp .shx)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Rheinland-Pfalz</p>
            </td>
            <td>
              <p>shape</p>
            </td>
            <td>
              <p>
                Verwende die Dateien mit der Bezeichnung „schlagdaten“. Importiere alle 4 Dateien (.dbf .prj .shp .shx)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Thüringen</p>
            </td>
            <td>
              <p>shape</p>
            </td>
            <td>
              <p>Verwende die Dateien im Ordner Teilflächen. Importiere alle 5 Dateien (.dat .dbf .prj .shp .shx)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Baden-Württemberg</p>
            </td>
            <td>
              <p>shape</p>
            </td>
            <td>
              <p><a target="_blank" href="https://www.farmdok.com/import-anleitung/">Zur Anleitung</a></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                Niedersachsen<br />
                Bremen
              </p>
            </td>
            <td>
              <p>
                Shape<br /><em>und</em><u><br /></u>xml
              </p>
            </td>
            <td>
              <p>
                Verwende <em>beide</em> Dateiformate (importiere am besten alle Dateien gleichzeitig):<br />
                xml (eine Datei)<br /><em>und</em><u><br /></u>Shape (Teilschläge; 3 Dateien - .dbf .shp .shx)
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import { REGION_GUIDS } from '@/shared/constants';

export default {
  name: 'ModalImportFileUploadInfo',
  components: { ModalWrapper },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showEAmaInfo() {
      return this.$store.state.auth.currentCompanies[0].regionId === REGION_GUIDS.AT;
    },
    showGermanInfo() {
      return this.$store.state.auth.currentCompanies[0].regionId === REGION_GUIDS.DE;
    },
  },
};
</script>

<style scoped></style>
