import Handsontable from 'handsontable';

function onCellDoubleClick(event) {
  if (
    !event.currentTarget ||
    !(event.currentTarget instanceof HTMLElement) ||
    event.currentTarget.querySelector('button') == null
  ) {
    return;
  }
  event.currentTarget.querySelector('button').click();
}

function PartnerStatusRenderer(instance, TD, row, col, prop, value, cellProperties) {
  let button = TD.querySelector('.hot-btn-partner-status');
  if (button === null) {
    button = document.createElement('button');
  }
  button.innerHTML = '';
  button.className = '';
  button.classList.add('hot-btn-partner-status');

  let status = value;
  status = value;
  button.innerText = cellProperties.getDisplayValue(value);
  button.classList.add(`hot-btn-partner-status--${status}`);

  TD.addEventListener('dblclick', onCellDoubleClick);

  button.onclick = () => {
    if (typeof cellProperties.onClick === 'function') {
      cellProperties.onClick({ target: button, visualRow: row });
    }
  };

  button.onmousedown = (event) => {
    event.stopPropagation();
  };

  TD.append(button);
}

Handsontable.cellTypes.registerCellType('partnerStatus', {
  renderer: PartnerStatusRenderer,
});
