<template>
  <BTooltip v-if="rowError" :target="rowError.target" placement="bottom" custom-class="handsontable-error-tooltip">
    <p>{{ $t('Fehler') }}</p>
    <p>{{ rowError.errorUserMessage }}</p>
    <FontAwesomeIcon icon="exclamation-square" />
  </BTooltip>
</template>

<script>
export default {
  name: 'TooltipRowError',
  props: {
    rowError: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped></style>
