var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.tableItemsFarmdok.length > 0)?_c('Widget',{attrs:{"title":_vm.$t('Bodenproben'),"subtitle":_vm.tableItemsAgrana.length ? 'FARMDOK' : null}},[_c('Table',{attrs:{"fixed":"","fields":_vm.tableFieldsFarmdok,"items":_vm.tableItemsFarmdok},scopedSlots:_vm._u([{key:"head(samples)",fn:function(data){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},_vm._l((_vm.tableFieldsSamplesFarmdok),function(field){return _c('div',{key:field.key,style:({
              'flex-grow': field.width == null ? '1' : '0',
              width: field.width,
              padding: '0.75rem',
            })},[_vm._v(" "+_vm._s(field.label)+" ")])}),0)]}},{key:"cell(samples)",fn:function(data){return [_c('Table',{staticClass:"m-0",attrs:{"fixed":"","borderless":"","headerless":"","fields":_vm.tableFieldsSamplesFarmdok,"items":data.item.samples}})]}},{key:"cell(userComment)",fn:function(data){return [(typeof data.item.userComment === 'string' && data.item.userComment.length > 0)?_c('ButtonLink',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:({ title: data.item.userComment }),expression:"{ title: data.item.userComment }",modifiers:{"hover":true,"left":true}}],staticClass:"widget-measures__user-comment",attrs:{"color":"black"}},[_vm._v(" "+_vm._s(data.item.userComment)+" ")]):_vm._e()]}}],null,false,1478207531)})],1):_vm._e(),(_vm.tableItemsAgrana.length > 0)?_c('Widget',{attrs:{"title":_vm.$t('Bodenproben'),"subtitle":_vm.tableItemsFarmdok.length ? 'EUF' : null}},[_c('Table',{attrs:{"fixed":"","fields":_vm.tableFieldsAgrana,"items":_vm.tableItemsAgrana},scopedSlots:_vm._u([{key:"head(samples)",fn:function(data){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},_vm._l((_vm.tableFieldsSamplesAgrana),function(field){return _c('div',{key:field.key,style:({
              'flex-grow': field.width == null ? '1' : '0',
              width: field.width,
              padding: '0.75rem',
            })},[_vm._v(" "+_vm._s(field.label)+" ")])}),0)]}},{key:"cell(samples)",fn:function(data){return [_c('Table',{staticClass:"m-0",attrs:{"fixed":"","borderless":"","headerless":"","fields":_vm.tableFieldsSamplesAgrana,"items":data.item.samples}})]}},{key:"cell(pdfLink)",fn:function(data){return [(data.item.pdfLink != null)?_c('ButtonLink',{attrs:{"target":"_blank","href":data.item.pdfLink}},[_vm._v(" "+_vm._s(_vm.$t('Herunterladen'))+" ")]):_vm._e()]}},{key:"cell(userComment)",fn:function(data){return [(typeof data.item.userComment === 'string' && data.item.userComment.length > 0)?_c('ButtonLink',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:({ title: data.item.userComment }),expression:"{ title: data.item.userComment }",modifiers:{"hover":true,"left":true}}],staticClass:"widget-measures__user-comment",attrs:{"color":"black"}},[_vm._v(" "+_vm._s(data.item.userComment)+" ")]):_vm._e()]}}],null,false,1050249297)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }