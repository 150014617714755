import { GetterTree } from 'vuex';

import usedItems from '@/shared/mixins/store/usedItems';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { SharedActivityTypesState } from './types';

const getters: GetterTree<SharedActivityTypesState, RootState> = {
  ...subscribableStore.getters,
  ...usedItems.getters,
  byRoughAndFineId:
    (state) =>
    (roughId: string, fineId: string | null = null) => {
      let activityType = null;
      Object.values(state.data).some((currentActivityType) => {
        if (currentActivityType.roughId === roughId && currentActivityType.fineId === fineId) {
          activityType = currentActivityType;
          return true;
        }
        return false;
      });
      return activityType;
    },
};

export default getters;
