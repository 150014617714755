import axios from 'axios';
import Vue from 'vue';

import subscribableDataStore from '@/shared/mixins/store/subscribableData';

const contracts = subscribableDataStore('/admin/digitalContracting/getContracts');

export function initialState() {
  return {
    contracting: null,
    creating: null,
  };
}

export default {
  ...contracts,
  namespaced: true,
  state: {
    ...contracts.state,
    ...initialState(),
  },
  mutations: {
    ...contracts.mutations,
    reset(state) {
      contracts.mutations.reset(state);
      Object.keys(initialState()).forEach((key) => {
        Vue.set(state, key, initialState()[key]);
      });
    },
    setContracting(state, contract) {
      Vue.set(state, 'contracting', contract);
      Vue.set(state, 'errors', null);
    },
    setCreating(state, contract) {
      Vue.set(state, 'creating', contract);
      Vue.set(state, 'errors', null);
    },
    accept(state, { contract, contractCompanyId }) {
      const newContracts = { ...state.data };
      newContracts[contract.id] = {
        ...contract,
        contractCompanyId,
      };
      Vue.set(state, 'data', newContracts);
      Vue.set(state, 'errors', null);
      Vue.set(state, 'contracting', null);
    },
    errors(state, { errors }) {
      const stateErrors = state.errors == null ? [...errors] : [...errors, ...state.errors];
      Vue.set(state, 'errors', stateErrors);
    },
  },
  actions: {
    ...contracts.actions,
    reset(context) {
      contracts.actions.reset(context);
      context.commit('reset');
    },
    async loadAll({ commit, dispatch, state }) {
      dispatch('reset');
      const source = axios.CancelToken.source();
      commit('startFetching', source);
      try {
        const { data } = await axios.get('/admin/digitalContracting/getContracts', { cancelToken: source.token });
        if (!Array.isArray(data)) {
          commit('fetchingError', source);
        } else {
          commit('loadAll', { data });
          commit('finishFetching', source);
        }
      } catch (error) {
        console.error(error);
        commit('fetchingError', source);
      } finally {
        state.observers.forEach((callback) => callback());
        commit('clearObservers');
      }
    },
    async accept({ commit, state }, { fieldIds, companyData, newsletter }) {
      if (state.contracting == null) {
        return;
      }
      const contract = state.contracting;
      commit('setCreating', contract);
      try {
        const { data } = await axios.post(`/admin/digitalContracting/contractCompany/${contract.id}`, {
          fieldIds,
          ...companyData,
          ...companyData.user,
          companyBdmId: companyData.companyBdmId,
          postcode: companyData.postcode,
          contract_additional_data: [],
          newsletter,
        });
        commit('setCreating', null);
        commit('accept', {
          contract,
          contractCompanyId: data.contractCompanyId,
        });
      } catch (e) {
        commit('setCreating', null);
        commit('errors', {
          errors: [e.response.data],
        });
      }
    },
  },
  getters: {
    ...contracts.getters,
    unsignedExpired(state, getters, rootState) {
      let availableProcessOrderNames = [];
      if (
        rootState.auth.currentCompanies[0] != null &&
        Array.isArray(rootState.auth.currentCompanies[0].processOrders)
      ) {
        availableProcessOrderNames = String(
          rootState.auth.currentCompanies[0].processOrders.map((processOrder) => processOrder.name),
        );
      }
      return (
        Object.values(state.data)
          .filter(
            (contract) =>
              contract.contractCompanyId == null &&
              contract.periodEnd * 1000 < new Date() &&
              availableProcessOrderNames.includes(String(contract.processOrderName)),
          )
          // only AGRANA contracts for now https://git.farmdok.com/farmdok/AgriDat/-/issues/3838#note_77076
          .filter((contract) => contract.partner === 'AGRANA')
      );
    },
    getByGuid: (state) => (contractGuid) =>
      Object.values(state.data).find((contract) => contract.guid === contractGuid),
    getByContractCompanyId: (state) => (contractCompanyId) =>
      Object.values(state.data).find((contract) => contract.contractCompanyId === contractCompanyId),
  },
};
