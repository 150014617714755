import { render, staticRenderFns } from "./ImgCompanyLogo.vue?vue&type=template&id=af9b93ea&scoped=true&"
import script from "./ImgCompanyLogo.vue?vue&type=script&lang=js&"
export * from "./ImgCompanyLogo.vue?vue&type=script&lang=js&"
import style0 from "./ImgCompanyLogo.vue?vue&type=style&index=0&id=af9b93ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af9b93ea",
  null
  
)

export default component.exports