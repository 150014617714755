<template lang="html">
  <ModalWrapper
    ref="modalWrapper"
    size="max"
    body-class="modal-edit-fields"
    :visible="visible"
    :title="$t('Felder bearbeiten')"
    @change="(value) => $emit('change', value)"
  >
    <template #default>
      <FormFieldSetEditFields
        v-model="changes"
        :fields="fieldsForFormField"
        :columns="columns"
        :missing-name="missingName"
        :uniqueNameViolation="uniqueNameViolation"
      />
    </template>
    <template #bottom-content>
      <p class="text-danger m-0">
        <span v-if="missingName != null">{{ $t('Bezeichnung fehlt.') }}</span>
        <span v-if="uniqueNameViolation">{{ errorMessage }}</span>
        <span v-else-if="invalidNewFields.length > 0">{{
          $t('Ein oder mehrere Felder haben eine ungültige Kontur')
        }}</span>
        <span v-else style="display: inline-block" />
      </p>
    </template>
    <template #map-container>
      <FieldsMap
        v-if="initialized"
        class="modal-insert-fields__map"
        ref="map"
        hide-map-actions-discard
        :initialCenterIncludeDisabled="!activeFieldsHaveGeoData"
        :companies="companies"
        :crops="crops"
        :disabledFields="disabledFields"
        :activeFields="activeFields"
        :editableFields="editableFields"
        :insertEnabled="insertEnabled"
        :invalidNewFields="invalidNewFields"
        @insert="addPolygon"
        @addPolygon="addPolygon"
        @change:polygon="changePolygon"
        @change:marker="changeMarker"
        @change:fieldName="changeFieldName"
        @click:polygon="onClickPolygon"
        @click:marker="(fieldId) => (currentFieldIdForGeoDataChange = fieldId)"
        @action:save="currentFieldIdForGeoDataChange = null"
        @action:removePolygon="onRemovePolygon"
      />
    </template>
    <template #buttons>
      <BButton variant="primary" class="mr-3" @click="save" :disabled="invalidNewFields.length > 0">
        <span>{{ $t('Speichern') }}</span>
      </BButton>
      <BButton variant="white" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import area from '@turf/area';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import { UUID5_FIELD_CONTOUR_NODE } from '@/shared/constants';
import { getLatLonFromGeoJson } from '@/shared/modules/shapeHelpers';
import { createUuidv5 } from '@/shared/modules/uuid';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import isValidPolygon from '../utils/validatePolygon';
import FieldsMap from './FieldsMap.vue';
import FormFieldSetEditFields from './FormFieldSetEditFields.vue';

export default {
  name: 'ModalEditFields',
  components: {
    FormFieldSetEditFields,
    FieldsMap,
    ModalWrapper,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fieldIds: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      default: null,
      validator(columns) {
        if (columns == null) {
          return true;
        }
        if (!Array.isArray(columns)) {
          return false;
        }
        return columns.every(
          (column) => column != null && typeof column.key === 'string' && typeof column.visible === 'boolean',
        );
      },
    },
  },
  data() {
    return {
      initialized: false,
      changes: {},
      geoDataChanges: {},
      currentFieldIdForGeoDataChange: null,
      missingName: null,
      uniqueNameViolation: null,
      invalidNewFields: [],
      errorMessage: null,
    };
  },
  watch: {
    changes() {
      this.missingName = null;
      this.uniqueNameViolation = false;
    },
  },
  async created() {
    await this.$store.dispatch('fields/subscribe');

    this.checkMissingLatLon();

    // do not render map before activeFields are set, otherwise the initial map centering isn't working
    this.initialized = true;
  },
  computed: {
    ...mapGetters({
      crops: 'products/crops',
      fields: 'fields/dataWithoutRemoved',
    }),
    insertEnabled() {
      return (
        this.fieldIds.length === 1 &&
        !this.activeFieldsHaveGeoData &&
        Object.values(this.editableFields).length === 0 &&
        Object.values(this.geoDataChanges).length === 0
      );
    },
    disabledFields() {
      return Object.values(this.fields)
        .filter((field) => !this.fieldIds.includes(field.id))
        .reduce(
          (accumulator, field) => ({
            ...accumulator,
            [field.id]: field,
          }),
          {},
        );
    },
    activeFields() {
      return this.fieldIds
        .filter((fieldId) => this.fields[fieldId] != null && fieldId !== this.currentFieldIdForGeoDataChange)
        .reduce(
          (accumulator, fieldId) => ({
            ...accumulator,
            [fieldId]: {
              ...this.fields[fieldId],
              ...this.changes,
              ...(this.geoDataChanges[fieldId] != null ? this.geoDataChanges[fieldId] : {}),
            },
          }),
          {},
        );
    },
    editableFields() {
      const fieldId = this.currentFieldIdForGeoDataChange;
      if (fieldId == null || this.fields[fieldId] == null) {
        return {};
      }
      return {
        [fieldId]: {
          ...this.fields[fieldId],
          ...this.changes,
          ...(this.geoDataChanges[fieldId] != null ? this.geoDataChanges[fieldId] : {}),
        },
      };
    },
    fieldsForFormField() {
      return this.fieldIds.filter((fieldId) => this.fields[fieldId] != null).map((fieldId) => this.fields[fieldId]);
    },
    companies() {
      return this.$store.state.auth.currentCompanies;
    },
    activeFieldsHaveGeoData() {
      return Object.values(this.activeFields).some(
        (field) =>
          (typeof field.lat === 'number' && typeof field.lon === 'number') ||
          (field.fieldContour != null &&
            field.fieldContour.geoJson != null &&
            field.fieldContour.geoJson.type === 'Polygon'),
      );
    },
    requireUniqueFieldName() {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_FIELD_NAMES_UNIQUE);
    },
  },
  methods: {
    onClickPolygon(fieldId) {
      if (!this.fieldIds.includes(fieldId)) {
        return;
      }
      this.currentFieldIdForGeoDataChange = fieldId;
    },
    addPolygon({ id, lat, lon, fieldContour }) {
      let fieldId = id;
      if (this.fieldIds.length === 1) {
        [fieldId] = this.fieldIds;
      }
      if (fieldId == null) {
        return;
      }
      const fieldSize = Math.floor(area(fieldContour.geoJson)) / 10000;
      const newGeoDataChanges = {
        lat,
        lon,
        fieldContour: {
          ...fieldContour,
          area: fieldSize,
          id: createUuidv5(UUID5_FIELD_CONTOUR_NODE, fieldId),
        },
      };
      if (this.fieldIds.length === 1) {
        Vue.set(this.changes, 'fieldSize', fieldSize);
      } else {
        newGeoDataChanges.fieldSize = fieldSize;
      }
      Vue.set(this.geoDataChanges, fieldId, newGeoDataChanges);
      this.currentFieldIdForGeoDataChange = fieldId;
    },
    changePolygon({ fieldContour, fieldId }) {
      const field = this.fields[fieldId];
      if (field == null) {
        return;
      }
      const fieldSize = Math.floor(area(fieldContour.geoJson)) / 10000;
      let newFieldContour = {};
      if (field.fieldContour != null) {
        newFieldContour = { ...field.fieldContour };
      }
      if (this.geoDataChanges[fieldId] != null && this.geoDataChanges[fieldId].fieldContour != null) {
        newFieldContour = { ...newFieldContour, ...this.geoDataChanges[fieldId].fieldContour };
      }
      newFieldContour.geoJson = fieldContour.geoJson;
      newFieldContour.area = fieldSize;
      newFieldContour.tstamp = Math.floor(new Date() / 1000);
      const newGeoDataChanges = {
        ...(this.geoDataChanges[fieldId] != null ? this.geoDataChanges[fieldId] : {}),
        fieldContour: newFieldContour,
      };

      const isValid = isValidPolygon(fieldContour.geoJson.coordinates);
      if (isValid) {
        if (this.invalidNewFields.some((f) => f.id === field.id)) {
          this.invalidNewFields = this.invalidNewFields.filter((f) => f.id !== field.id);
        }
      } else {
        this.invalidNewFields.push(field);
      }

      if (this.fieldIds.length === 1) {
        Vue.set(this.changes, 'fieldSize', fieldSize);
      } else {
        newGeoDataChanges.fieldSize = fieldSize;
      }
      Vue.set(this.geoDataChanges, fieldId, newGeoDataChanges);
    },
    changeMarker({ lat, lon, fieldId }) {
      Vue.set(this.geoDataChanges, fieldId, {
        ...(this.geoDataChanges[fieldId] != null ? this.geoDataChanges[fieldId] : {}),
        lat,
        lon,
      });
    },
    changeFieldName({ fieldId, name }) {
      if (this.fieldIds.length === 1) {
        Vue.set(this.changes, 'name', name);
        return;
      }
      Vue.set(this.geoDataChanges, fieldId, { name });
    },
    checkMissingLatLon() {
      Object.values(this.activeFields).forEach((field) => {
        if (
          (field.lat != null && field.lon != null) ||
          field.fieldContour == null ||
          field.fieldContour.geoJson == null ||
          field.fieldContour.geoJson.type !== 'Polygon'
        ) {
          return;
        }
        Vue.set(this.geoDataChanges, field.id, getLatLonFromGeoJson(field.fieldContour.geoJson));
      });
    },
    save() {
      if (typeof this.changes.name === 'string' && this.changes.name.length === 0) {
        this.missingName = true;
        return;
      }

      if (this.changes.name && this.requireUniqueFieldName) {
        if (this.fieldIds.length > 1) {
          this.uniqueNameViolation = true;
          this.errorMessage = this.$t('Felder dürfen nicht die gleichen Namen enthalten.');
          return;
        }

        const isNameViolation = this.$store.getters['fields/isUniqueFieldName'](this.changes.name);
        if (isNameViolation) {
          this.uniqueNameViolation = true;
          this.errorMessage = this.$t('Ein Feld mit diesem Namen existiert bereits.');
          return;
        }
      }

      this.fieldIds.forEach((fieldId) => {
        if (Object.values(this.changes).length === 0 && this.geoDataChanges[fieldId] == null) {
          return;
        }
        const entry = {
          ...this.changes,
          ...(this.geoDataChanges[fieldId] != null ? this.geoDataChanges[fieldId] : {}),
          id: fieldId,
        };
        if (this.changes['fieldGroup.customerId'] === 'own') {
          entry['fieldGroup.customerId'] = null;
        }
        this.$store.dispatch('fields/updateAndSyncEntry', { entry });
      });
      this.hide();
    },
    hide() {
      this.$refs.modalWrapper.hide();
    },
    onRemovePolygon(fieldId) {
      const newGeoDataChanges = {
        ...(this.geoDataChanges[fieldId] != null ? this.geoDataChanges[fieldId] : {}),
        fieldContour: null,
      };
      Vue.set(this.geoDataChanges, fieldId, newGeoDataChanges);
    },
  },
};
</script>

<style scoped></style>
