import { ENTRY_REMOVING, ENTRY_TO_REMOVE } from '@/shared/constants';

export default {
  mounted() {
    this.addHotHook('afterRemoveRow', this.featureRemoveRowAfterRemoveRow);
    this.addHotHook('afterRenderer', this.featureRemoveRowAfterRenderer);
  },
  methods: {
    removeEntryFromStore({ id }) {
      if (this.hot == null) {
        return;
      }
      this.hot.alter('remove_row', this.guidToVisualRow(id), 1, `featureRemoveRow.remove.${id}`);
    },
    featureRemoveRowAfterRemoveRow(visualRow, amount, physicalRows, source) {
      if (typeof source !== 'string' || source.indexOf('featureRemoveRow.remove') !== 0) {
        return;
      }
      const guid = source.split('.')[2];
      const physicalRow = this.tablePhysicalRowByGuid[guid];
      this.tableGuidByPhysicalRow = [
        ...this.tableGuidByPhysicalRow.slice(0, physicalRow),
        ...this.tableGuidByPhysicalRow.slice(physicalRow + 1),
      ];

      this.tablePhysicalRowByGuid = {};
      for (let x = 0; x < this.tableGuidByPhysicalRow.length; x += 1) {
        this.tablePhysicalRowByGuid[this.tableGuidByPhysicalRow[x]] = x;
      }
    },
    /**
     * Adds status classes to cells where necessary.
     *
     * @param {HTMLElement} td
     * @param {number} visualRow
     */
    featureRemoveRowAfterRenderer(td, visualRow) {
      const guid = this.visualRowToGuid(visualRow);
      if (guid == null || this.tableData[guid] == null) {
        return;
      }
      const { storeStatus } = this.tableData[guid];
      if (storeStatus === ENTRY_REMOVING || storeStatus === ENTRY_TO_REMOVE) {
        td.classList.add('removing');
      }
    },
  },
};
