import { AxiosRequestConfig } from 'axios';
import {
  ActivitiesApi,
  ActivitiesApiActivityListRequest,
  ActivitiesApiActivityObjectsRequest,
  Activity,
} from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Getters as AuthGetters } from '@/auth/store/getters';
import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

export const dataUrl = '/admin/rest/measure';
export const resolve = ['processOrder', 'workingMeans'];

function emptyFakeResponse(): ApiResponse<Activity> {
  return {
    version: '2.0',
    status: 'success',
    data: [],
  };
}

export async function fetchAll(
  context: ActionContext<SubscribableDataState<Activity>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Activity>> {
  const processOrderIds = context.rootGetters['auth/currentProcessOrderIds'] as AuthGetters['currentProcessOrderIds'];
  const fieldSharingCompanyIds = context.rootGetters['auth/currentCompanyIds'] as AuthGetters['currentCompanyIds'];
  const permissionMode = 'fieldSharing';

  if (processOrderIds?.length === 0 || fieldSharingCompanyIds.length === 0) {
    return emptyFakeResponse();
  }

  const { activitiesApi } = Vue.prototype.$api as Api;

  const promises: Promise<ApiResponse<Activity>>[] = [];
  fieldSharingCompanyIds.forEach((fieldSharingCompanyId) => {
    processOrderIds.forEach((processOrderId) => {
      const requestParameters: ActivitiesApiActivityListRequest = {
        processOrderId,
        permissionMode,
        fieldSharingCompanyId,
        deleted: false,
      };
      promises.push(
        loadAllPages<ActivitiesApi, Activity>(activitiesApi, 'activityList', requestParameters, axiosOptions),
      );
    });
  });

  const apiResponses = await Promise.all(promises);
  const apiResponse = mergeApiResponses(apiResponses);

  return apiResponse;
}

async function fetchByIds(
  ids: string[],
  context: ActionContext<SubscribableDataState<Activity>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Activity>> {
  const fieldSharingCompanyIds = context.rootGetters['auth/currentCompanyIds'] as AuthGetters['currentCompanyIds'];
  const permissionMode = 'fieldSharing';

  if (fieldSharingCompanyIds.length === 0) {
    return emptyFakeResponse();
  }

  const { activitiesApi } = Vue.prototype.$api as Api;

  const promises = fieldSharingCompanyIds.map((fieldSharingCompanyId) => {
    const requestParameters: ActivitiesApiActivityObjectsRequest = {
      objectIds: ids.join(','),
      permissionMode,
      fieldSharingCompanyId,
      deleted: false,
    };
    return activitiesApi.activityObjects(requestParameters, axiosOptions);
  });

  const axiosResponses = await Promise.all(promises);
  const apiResponses = axiosResponses.map((axiosResponse) => axiosResponse.data);
  const apiResponse = mergeApiResponses(apiResponses as ApiResponse<Activity>[]);

  return apiResponse;
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
