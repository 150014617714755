import Handsontable from 'handsontable';

/**
 * prevents populating nulls to cells (required for mergeCells plugin)
 * THIS HOOK MUST BE ADDED TO THE HANDSONTABLE INSTANCE VIA TABLE SETTINGS TO PREVENT MERGECELLS PLUGIN FROM CHANGING CELLS TO NULLS
 * see: https://github.com/handsontable/handsontable/blob/56b93f7a7feebefc0e6571674c25fac3a0227aea/handsontable/src/plugins/mergeCells/mergeCells.js#L251)
 * altering the changes must be done directly on the changes array
 */
export default function removeChangesToNulls(
  changes: Array<Handsontable.CellChange | null>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  source?: Handsontable.ChangeSource,
): void {
  changes.forEach((change, index) => {
    if (!change) return;

    const [, , , newValue] = change;
    if (newValue === null) {
      // eslint-disable-next-line no-param-reassign
      changes[index] = null;
    }
  });
}
