
import { faCloud as faCloudDuotone } from '@fortawesome/pro-duotone-svg-icons';
import moment, { type Moment } from 'moment';
import Vue, { type PropType, defineComponent } from 'vue';
import { Day } from 'vuejs-datepicker';
import { type GetterTree, mapState } from 'vuex';

import ControlNumZones from '@/precision-farming/application-maps/controls/ControlNumZones.vue';
import ControlSatelliteIndex from '@/precision-farming/application-maps/controls/ControlSatelliteIndex.vue';
import type { ApplicationMapsState } from '@/precision-farming/application-maps/store/types';
import FormFieldDatepicker from '@/shared/components/form/FormFieldDatepicker.vue';
import FormFieldSetBordered from '@/shared/components/form/FormFieldSetBordered.vue';
import type { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import iconAsSvgString from '@/shared/modules/iconAsSvgString';
import type { RootState } from '@/store/types';

const t = Vue.i18n.translate;

const cloudIcon = iconAsSvgString(faCloudDuotone);

/**
 * A form is container-like. I.e. it can contain references to the store, and can execute actions and sync state
 */
export default defineComponent({
  name: 'FormZones',
  components: {
    FormFieldDatepicker,
    FormFieldSetBordered,
    ControlNumZones,
    ControlSatelliteIndex,
  },
  props: {
    workflowKey: {
      type: String as PropType<keyof ApplicationMapsState>,
      required: true,
    },
  },
  data(): {
    activeDate: Date;
    activeIndexType: DropdownItem<{ id: string; name: string }>;
    activeQuantisationCode: DropdownItem<{ id: string; name: string }>;
  } {
    return {
      activeDate: new Date(),
      activeIndexType: { id: 'DNN_NDVI', name: t('NDVI - Biomasse')! },
      activeQuantisationCode: { id: 'medium', name: t('Mittel')! },
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps', {
      selectedFieldIds(state: ApplicationMapsState): string[] {
        return state[this.workflowKey as keyof ApplicationMapsState].selectedFields;
      },
      indexTypeSelected(state: ApplicationMapsState, getters: GetterTree<ApplicationMapsState, RootState>) {
        return getters[`${this.workflowKey}/selectedIndexType`];
      },
      heatmaps(state: ApplicationMapsState) {
        const heatmaps = state[this.workflowKey as keyof ApplicationMapsState].heatmaps.current;
        return Object.entries(heatmaps)
          .filter(([key]) => key.includes(this.selectedQuantisationCode.id))
          .filter(([key]) => key.includes(this.selectedIndex.id))
          .map(([, value]) => value);
      },
      loadingHeatmaps(state: ApplicationMapsState) {
        return state[this.workflowKey as keyof ApplicationMapsState].heatmaps.fetching;
      },
      availability(state: ApplicationMapsState, getters: GetterTree<ApplicationMapsState, RootState>) {
        return getters[`${this.workflowKey as keyof ApplicationMapsState}/availableTimestamps`];
      },
      heatmapTimestampSelectedIndex(state: ApplicationMapsState, getters: GetterTree<ApplicationMapsState, RootState>) {
        return getters[`${this.workflowKey}/heatmapTimestampSelectedIndex`];
      },
      heatmapTimestampManuallySelected(
        state: ApplicationMapsState,
        getters: GetterTree<ApplicationMapsState, RootState>,
      ) {
        return getters[`${this.workflowKey}/heatmapTimestampManuallySelected`];
      },
    }),
    availableTimestamps(): { timeArray: number[]; mapping: Record<number, string> } {
      // important: indexing the getters makes the type unidentifiable to TS, and we cannot type the computed property
      return this.availability as unknown as { timeArray: number[]; mapping: Record<number, string> };
    },
    availableDates(): Moment[] {
      const { timeArray } = this.availableTimestamps;
      return timeArray.sort((a, b) => a - b).map((stamp) => moment.unix(stamp));
    },
    selectedDate: {
      set(date: Date) {
        this.activeDate = date;
        const selectedDate = moment(date);
        const { timeArray } = this.availableTimestamps as unknown as {
          timeArray: number[];
          mapping: Record<number, string>;
        };
        const matchingTimestamp =
          timeArray.find((unix) => moment.unix(unix).isSame(selectedDate, 'day')) ?? selectedDate.unix();
        this.$store.commit('precisionFarming/applicationMaps/spraying/setHeatmapTimestamp', matchingTimestamp);
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/loadHeatmaps`);
      },
      get(): Date {
        return this.activeDate;
      },
    },
    disableIf() {
      const { selectedIndex, isForecastable, availableDates } = this;
      return (date: Date) => {
        if (isForecastable(selectedIndex)) {
          return false;
        }

        const selectedDate = moment(date);
        return !availableDates.some((d) => d.isSame(selectedDate, 'day'));
      };
    },
    highlightIf() {
      return (date: Date) => {
        const selectedDate = moment(date);
        const matchingHeatmap = this.heatmaps.find((hm) => {
          const estimationDate = moment(hm.satellite_products.estimation_date);
          return estimationDate.isSame(selectedDate, 'day');
        });
        return matchingHeatmap?.coverage_ratio.cloud === 1;
      };
    },
    selectedIndex: {
      set(newIndexType: DropdownItem<{ id: string; name: string }>) {
        this.activeIndexType = newIndexType;
        this.$store.commit(
          `precisionFarming/applicationMaps/${this.workflowKey}/setSelectedIndexType`,
          newIndexType.id,
        );
      },
      get(): DropdownItem<{ id: string; name: string }> {
        return this.activeIndexType;
      },
    },
    selectedQuantisationCode: {
      set(quantisationCode: DropdownItem<{ id: string; name: string }>) {
        this.activeQuantisationCode = quantisationCode;
        this.$store.commit(
          `precisionFarming/applicationMaps/${this.workflowKey}/setSelectedQuantisationCode`,
          quantisationCode.id,
        );
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/loadHeatmaps`);
      },
      get(): DropdownItem<{ id: string; name: string }> {
        return this.activeQuantisationCode;
      },
    },
  },
  created() {
    this.selectedDate = this.isForecastable(this.selectedIndex) ? this.mostRecentDate() : new Date();
  },
  methods: {
    onPrev(): Date | undefined {
      const selectedDate = moment(this.selectedDate);
      if (this.isForecastable(this.selectedIndex)) {
        return selectedDate.subtract(5, 'days').toDate();
      }

      const index = this.availableDates.findIndex((date) => date.isSame(selectedDate, 'day'));
      return index > 0 ? this.availableDates[index - 1].toDate() : undefined;
    },
    onNext(): Date | undefined {
      const selectedDate = moment(this.selectedDate);
      if (this.isForecastable(this.selectedIndex)) {
        return selectedDate.add(5, 'days').toDate();
      }

      return this.availableDates.find((date) => date.isAfter(selectedDate))?.toDate();
    },
    mostRecentDate(): Date {
      const mostRecent = this.availableDates.find((_, index, arr) => index === arr.length - 1)?.toDate();
      return mostRecent ?? new Date();
    },
    isForecastable(indexType: DropdownItem<{ id: string; name: string }>): boolean {
      return indexType.id?.includes('DNN_') ?? false;
    },
    dayCellContent(day: Day): string {
      if (day.isHighlighted) {
        return `${day.date}${cloudIcon}`;
      }

      return `${day.date}`;
    },
  },
  watch: {
    async selectedIndex(
      current: DropdownItem<{ id: string; name: string }>,
      previous: DropdownItem<{ id: string; name: string }>,
    ) {
      const promises = this.selectedFieldIds.map((fieldKey: string) =>
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setMultipolyTimestamp`, fieldKey),
      );
      await Promise.all(promises);

      if (this.isForecastable(previous) && !this.isForecastable(current)) {
        this.selectedDate = this.mostRecentDate();
      }

      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/loadHeatmaps`);
    },
  },
});
