import _cloneDeep from 'lodash.clonedeep';
import { ActionContext } from 'vuex';

import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../types';
import initServices from './utils/initServices';

export default function removeActivityProductInAllActivities(
  { state, commit, rootState, rootGetters }: ActionContext<CreateEditActivityState, RootState>,
  activityProductId: string,
): void {
  const { activityService, findService } = initServices(state, rootState, rootGetters);
  const referenceActivityProduct = _cloneDeep(findService.getActivityProduct(activityProductId));

  state.activities.forEach((activity) => {
    const family = activityService.findActivityProductsOrSiblings(activity, referenceActivityProduct);

    if (family.length > 0) {
      removeProducts(activity.id, family, commit);
    }
  });
}

function removeProducts(activityId: string, activityProducts: any[], commit: any) {
  activityProducts.forEach((activityProduct) => {
    commit('removeActivityProduct', { activityId, activityProductId: activityProduct.id });
  });
}
