
import Handsontable from 'handsontable';
import { PropType, defineComponent } from 'vue';

import SidebarFields from '@/fields/components/SidebarFields.vue';
import { Field } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

export default defineComponent({
  name: 'SecondStep',
  components: {
    SidebarFields,
  },
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
    customColumnDefinition: {
      type: Object as PropType<{ international: Handsontable.ColumnSettings[] } | null>,
      default: null,
    },
    customTableData: {
      type: Object as PropType<Data<Field>>,
      default: null,
    },
    sumProcessedArea: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
});
