<template>
  <BFormInput
    v-model="internalValue"
    :placeholder="internalPlaceholder"
    :id="id"
    type="number"
    :required="internalRequired"
    class="form-control--text-input"
    :class="{ 'form-control--text-right': align === 'right' }"
    :disabled="internalDisabled"
    :state="state"
    v-b-tooltip="tooltipOptions"
    :step="step"
    :min="min"
    :max="max"
    :formatter="roundNumber"
  />
</template>

<script>
import baseInput from '@/shared/mixins/form/baseInput';

/**
 * Use FormFieldInput instead.
 *
 * @deprecated
 */
export default {
  name: 'BaseInputNumber',
  mixins: [baseInput],
  props: {
    value: {
      type: Number,
    },
    min: {
      type: String,
      default: '0',
    },
    max: {
      type: String,
      default: null,
    },
    step: {
      type: String,
      default: '1',
    },
    round: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: 'right',
    },
  },
  methods: {
    roundNumber(value) {
      let roundedValue = value;
      if (this.round) {
        let mantissa = 0;
        if (this.step.includes('.')) {
          mantissa = this.step.split('.')[1].length;
        }
        const roundFactor = 10 ** mantissa;
        roundedValue = Math.round(value * roundFactor) / roundFactor;
      }
      return roundedValue;
    },
  },
  computed: {
    internalValue: {
      get() {
        if (this.value == null) {
          return null;
        }
        return this.roundNumber(this.value);
      },
      set(value) {
        this.$emit('input', Number(value));
      },
    },
  },
};
</script>
