<template>
  <div class="resetButton">
    <ButtonLink class="text-small pb-4" @click="onClick" :disabled="disabled">
      {{ buttonText }}
    </ButtonLink>
  </div>
</template>

<script>
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';

export default {
  name: 'ResetButton',
  components: {
    ButtonLink,
  },
  props: {
    buttonText: {
      type: String,
      default: 'Reset',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.resetButton {
  margin-bottom: 16px;
}
</style>
