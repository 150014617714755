<template>
  <div class="sidebar-sub-headline">
    <h3 v-if="text != null" class="sidebar-sub-headline__title">
      {{ text }}
    </h3>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SidebarSubHeadline',
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.sidebar-sub-headline__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
}
</style>
