import Handsontable from 'handsontable';

import ReadOnlyRenderer from '@/shared/handsontable/renderers/ReadOnlyRenderer';
import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';

import formatDuration from './formatDuration';

export default function DurationRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: string | number | undefined | null, // expects time in seconds
  cellProperties: Handsontable.CellProperties,
  ...args: unknown[]
) {
  if (cellProperties.required && value == null) {
    // @ts-ignore
    RequiredRenderer.apply(this, [instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args]);
    return;
  }

  const displayValue = formatDuration(value);
  // @ts-ignore
  Handsontable.renderers.TextRenderer.apply(this, [
    // @ts-ignore
    instance,
    TD,
    visualRow,
    visualCol,
    prop,
    displayValue,
    cellProperties,
    ...args,
  ]);

  if (cellProperties.readOnly) {
    // @ts-ignore
    ReadOnlyRenderer(instance, TD, visualRow, visualCol, prop);
  }

  applyMergeRowsBorderStyles(TD, visualRow, instance);
}
