
import { defineComponent } from 'vue';

import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';

export default defineComponent({
  name: 'AddSectionButton',
  components: { ButtonLink },
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
