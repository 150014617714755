import axios from 'axios';
import Vue from 'vue';
import { Module } from 'vuex';

import {
  ENTRY_DIRTY,
  ENTRY_REMOVED,
  ENTRY_REMOVING,
  ENTRY_SYNCED,
  ENTRY_TO_REMOVE,
  ENTRY_UPDATING,
  ERROR_FETCHING,
} from '@/shared/constants';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';
import { RootState } from '@/store/types';

import {
  AdditionalQueryParams,
  DataEntry,
  Filter,
  Resolve,
  ResolveInSeparateList,
  SubscribableDataState,
} from './types';

/**
 * @deprecated use subscribableData module instead
 * @returns
 */
export function initialState<E extends DataEntry>(): SubscribableDataState<E> {
  return {
    data: {},
    errors: [],
    fetching: false,
    loaded: null,
    observers: [],
    axiosSources: [],
  };
}

/**
 * Convert filter or resolve arrays into base64 encoded strings.
 */
export const dataToBase64 = (data: any) => (data != null ? btoa(JSON.stringify(data)) : '');

/**
 * Loads data from BE once the subscribe action is dispatched at least once. As soon as the request is finished every
 * dispatched subscribe action will be resolved.
 *
 * You can add filter+resolves (will be handles by AgriDat BE) in form of an array:
 * https://git.farmdok.com/farmdok/AgriDat/-/wikis/coding-guidelines/7.1.-Model-Loading-1#filtering
 *
 * If filter is `false` no request will be sent.
 *
 * Filter + Resolve can also be functions that will be called on load and get the module state and the rootState as parameters.
 * (e.g. to add the current processOrder id to the filter)
 *
 * @deprecated use @/shared/mixins/store/subscribableData module instead
 */
export default function subscribableData<Entry extends DataEntry>(
  dataUrl: string,
  {
    filter = [],
    resolve = [],
    resolveInSeparateList = [],
    additionalQueryParams = [],
  }: {
    filter?: Filter;
    resolve?: Resolve;
    resolveInSeparateList?: ResolveInSeparateList;
    additionalQueryParams?: AdditionalQueryParams;
  } = {},
): Module<SubscribableDataState<Entry>, RootState> {
  return {
    namespaced: true,
    state: initialState<Entry>(),
    mutations: {
      startFetching(state, source) {
        Vue.set(state, 'fetching', true);
        Vue.set(state, 'axiosSources', [...state.axiosSources, source]);
      },
      addObserver(state, callback) {
        Vue.set(state, 'observers', [...state.observers, callback]);
      },
      clearObservers(state) {
        Vue.set(state, 'observers', []);
      },
      addOne(state, entry: Entry) {
        Vue.set(state.data, entry.id, entry);
      },
      loadAll(state, { data }: { data: Entry[] }) {
        Vue.set(
          state,
          'data',
          data.reduce((dataCurrent, entry) => {
            const storeStatus = state.data[entry.id]?.storeStatus;
            if (storeStatus && [ENTRY_DIRTY, ENTRY_UPDATING].includes(storeStatus)) {
              return {
                ...dataCurrent,
                [entry.id]: state.data[entry.id],
              };
            }
            return {
              ...dataCurrent,
              [entry.id]: {
                ...entry,
                storeStatus: ENTRY_SYNCED,
              },
            };
          }, {}),
        );
        Vue.set(state, 'loaded', new Date());
      },
      updateEntries(state, { data }: { data: Entry[] }) {
        Vue.set(
          state,
          'data',
          data.reduce((dataCurrent, entry) => {
            const storeStatus = state.data[entry.id]?.storeStatus;
            if (storeStatus && [ENTRY_DIRTY, ENTRY_UPDATING].includes(storeStatus)) {
              return {
                ...dataCurrent,
                [entry.id]: state.data[entry.id],
              };
            }
            return {
              ...dataCurrent,
              [entry.id]: {
                ...entry,
                storeStatus: ENTRY_SYNCED,
              },
            };
          }, state.data),
        );
      },
      finishFetching(state, source) {
        Vue.set(state, 'fetching', false);
        Vue.set(
          state,
          'axiosSources',
          state.axiosSources.filter((currentSource) => currentSource !== source),
        );
      },
      fetchingError(state, source) {
        Vue.set(state, 'fetching', false);
        Vue.set(
          state,
          'axiosSources',
          state.axiosSources.filter((currentSource) => currentSource !== source),
        );
        Vue.set(state, 'errors', [...state.errors, { type: ERROR_FETCHING }]);
      },
      resetLoaded(state) {
        Vue.set(state, 'loaded', null);
      },
      reset(state) {
        const newState = initialState<Entry>();

        Object.entries(newState).forEach(([key, value]) => {
          if (key === 'observers') {
            return;
          }
          Vue.set(state, key, value);
        });
      },
    },
    actions: {
      /**
       * - If data hasn't been loaded yet, load it.
       * - If data has already been loaded and forceReset is true: do a reset of the loaded data first, then load it.
       * - If data has already been loaded and forceRefresh is true: re-load the data
       *   without resetting the current data (it gets overwritten when the fetching is done).
       * - forceReset trumps forceRefresh.
       * - If data has been loaded and neither forceReset or forceRefresh is true it returns immediatly.
       *
       * @param commit
       * @param dispatch
       * @param state
       * @param forceReset
       * @param forceRefresh
       * @return {Promise<void>}
       */
      async subscribe(
        { commit, dispatch, state },
        { forceReset, forceRefresh }: { forceReset: boolean; forceRefresh: boolean } = {
          forceReset: false,
          forceRefresh: false,
        },
      ) {
        if (state.loaded != null && !forceReset) {
          if (forceRefresh) {
            await dispatch('refresh');
          }
          return;
        }
        // eslint-disable-next-line no-promise-executor-return
        const promise = new Promise((callback) => commit('addObserver', callback));
        if (!state.fetching) {
          dispatch('loadAll', { forceReset });
        }
        await promise;
      },
      async refresh({ commit, dispatch, state }) {
        if (state.loaded == null) {
          return dispatch('subscribe');
        }
        // eslint-disable-next-line no-promise-executor-return
        const promise = new Promise((callback) => commit('addObserver', callback));
        if (!state.fetching) {
          dispatch('loadAll');
        }
        return promise;
      },
      async refreshByIds(context, ids) {
        const filterCurrent = ['id', 'IN', ids];
        let resolveCurrent = resolve;
        if (typeof resolve === 'function') {
          resolveCurrent = resolve(context);
        }

        let urlParts = [
          `${dataUrl}?version=2.0`,
          'itemsPerPage=11000',
          `filter=${dataToBase64(filterCurrent)}`,
          `resolve=${dataToBase64(resolveCurrent)}`,
        ];

        if (Array.isArray(additionalQueryParams)) {
          urlParts = [...urlParts, ...additionalQueryParams];
        }

        let response;
        try {
          const { data } = await axios.get(urlParts.join('&'));
          response = getRestResponseData(data);
        } catch (error) {
          response = getRestResponseData(error);
        }
        if (response.status === 'success') {
          context.commit('updateEntries', response);
        }
        return response;
      },
      async loadAll(context, { forceReset = false } = {}) {
        const { commit, dispatch, state } = context;
        if (forceReset) {
          await dispatch('reset');
        }
        const source = axios.CancelToken.source();
        commit('startFetching', source);

        let filterCurrent = filter;
        if (typeof filter === 'function') {
          filterCurrent = await filter(context);
        }
        // @ts-ignore
        if (filterCurrent === false) {
          state.observers.forEach((callback) => callback());
          commit('finishFetching', source);
          commit('clearObservers');
          return;
        }
        let resolveCurrent = resolve;
        if (typeof resolve === 'function') {
          resolveCurrent = resolve(context);
        }

        let additionalQueryParamsCurrent: string[];
        if (typeof additionalQueryParams === 'function') {
          additionalQueryParamsCurrent = await additionalQueryParams(context);
        } else {
          additionalQueryParamsCurrent = additionalQueryParams;
        }

        const urlParts = [
          `${dataUrl}?version=2.0`,
          'itemsPerPage=11000',
          `filter=${dataToBase64(filterCurrent)}`,
          `resolve=${dataToBase64(resolveCurrent)}`,
          ...additionalQueryParamsCurrent,
        ];
        if (typeof resolveInSeparateList === 'function') {
          urlParts.push(`resolveInSeparateList=${dataToBase64(resolveInSeparateList(context))}`);
        } else if (Array.isArray(resolveInSeparateList) && resolveInSeparateList.length > 0) {
          urlParts.push(`resolveInSeparateList=${dataToBase64(resolveInSeparateList)}`);
        }

        try {
          const { data } = await axios.get(urlParts.join('&'), { cancelToken: source.token });
          if (data.status !== 'success') {
            commit('fetchingError', source);
          } else {
            if (data.resolved != null) {
              await dispatch('loadResolved', data);
            }
            commit('loadAll', { data: data.data });
            commit('finishFetching', source);
          }
        } catch (error) {
          console.error(error);
          if (!axios.isCancel(error)) {
            commit('fetchingError', source);
          }
        } finally {
          state.observers.forEach((callback) => callback());
          commit('clearObservers');
        }
      },
      /**
       * Use this action in custom stores to add resolved objects to other stores.
       *
       * @override
       */
      loadResolved() {},
      reset({ commit, state }) {
        state.axiosSources.forEach((source) => source.cancel());
        commit('reset');
      },
    },
    getters: {
      data: (state) => state.data,
      dataWithoutRemoved: (state) =>
        Object.values(state.data).reduce((dataWithoutRemoved, entry) => {
          if (
            entry.storeStatus === ENTRY_REMOVED ||
            entry.storeStatus === ENTRY_REMOVING ||
            entry.storeStatus === ENTRY_TO_REMOVE
          ) {
            return dataWithoutRemoved;
          }
          return {
            ...dataWithoutRemoved,
            [entry.id]: entry,
          };
        }, {}),
      errors: (state) => state.errors,
      fetching: (state) => state.fetching,
      loaded: (state) => state.loaded,
      /**
       * Returns true if data is being loaded initially or the data has been reset.
       * (I.e. a loading animation should be shown)
       *
       * @param state
       * @return {boolean}
       */
      loading: (state) => {
        if (state.loaded != null) {
          return false;
        }
        if (state.fetching) {
          return true;
        }
        return state.errors.length === 0;
      },
    },
  };
}
