import Handsontable from 'handsontable';
import Vue from 'vue';

import Dropdown from '@/shared/components/Dropdown.vue';
import {
  DropdownItem,
  DropdownItemsPerCategory,
  isDropdownItem,
} from '@/shared/components/form/formFieldDropdownTypes';

import { ColumnSettingsDropdown } from './types';

const DROPDOWN_ITEM_FALLBACK: DropdownItem = {
  id: null,
  name: '',
};

// @ts-ignore
export default class DropdownEditor extends Handsontable.editors.TextEditor {
  init() {
    super.init();
    // @ts-ignore
    this.dropdownItems = {
      data: [],
      loading: true,
      selected: {
        id: null,
        name: '',
      },
    };
    // @ts-ignore
    this.dropdownComparableItem = {
      category: null,
      value: null,
    };
    // @ts-ignore
    this.dropdownBoundary = null;
  }

  prepare(
    visualRow: number,
    col: number,
    prop: number | string,
    td: HTMLTableCellElement,
    originalValue: DropdownItem | null,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(visualRow, col, prop, td, originalValue, cellProperties);
    const typedCellProperties = cellProperties as unknown as ColumnSettingsDropdown;
    if (typeof typedCellProperties.getDropdownBoundary === 'function') {
      // @ts-ignore
      this.dropdownBoundary = typedCellProperties.getDropdownBoundary();
    }

    const selected = originalValue ?? DROPDOWN_ITEM_FALLBACK;

    // @ts-ignore
    Vue.set(this.dropdownItems, 'data', []);
    // @ts-ignore
    Vue.set(this.dropdownItems, 'loading', true);
    // @ts-ignore
    Vue.set(this.dropdownItems, 'selected', selected);
    const now = +new Date();
    // @ts-ignore
    this.getItemsToken = now;
    typedCellProperties.dropdown.getItems(visualRow, this.instance).then((items: DropdownItemsPerCategory[]) => {
      // @ts-ignore
      if (this.getItemsToken !== now) {
        return;
      }
      // @ts-ignore
      Vue.set(this.dropdownItems, 'data', items);
      // @ts-ignore
      Vue.set(this.dropdownItems, 'loading', false);
    });
    if (typedCellProperties.dropdownComparableItem != null) {
      // @ts-ignore
      Vue.set(this.dropdownComparableItem, 'category', typedCellProperties.dropdownComparableItem.category);
      // @ts-ignore
      Vue.set(this.dropdownComparableItem, 'value', typedCellProperties.dropdownComparableItem.value);
    } else {
      // @ts-ignore
      Vue.set(this.dropdownComparableItem, 'category', null);
      // @ts-ignore
      Vue.set(this.dropdownComparableItem, 'value', null);
    }
  }

  createElements() {
    super.createElements();
    const container = this.TEXTAREA_PARENT.appendChild(document.createElement('div'));
    const provide = {
      // @ts-ignore
      dropdownItems: this.dropdownItems,
      // @ts-ignore
      dropdownComparableItem: this.dropdownComparableItem,
      dropdownVariant: 'table-editor',
      dropdownBoundary: () => {
        // @ts-ignore
        if (this.dropdownBoundary == null) {
          return window;
        }
        // @ts-ignore
        return this.dropdownBoundary;
      },
    };
    // @ts-ignore
    this.dropdown = new Vue({
      render: (h) => h(Dropdown),
      provide,
    }).$mount(container);
    // @ts-ignore
    this.dropdown.$children[0].$on('select', (selected: DropdownItem) => {
      this.setValue(selected);
      this.finishEditing(false);
    });
    // @ts-ignore
    this.dropdown.$children[0].$on('close', () => {
      this.finishEditing(true);
    });
  }

  getValue() {
    // @ts-ignore
    const { selected } = this.dropdownItems;
    if (!selected.id) return undefined;

    return selected;
  }

  setValue(newValue?: DropdownItem | string | undefined | null): void {
    if (isDropdownItem(newValue)) {
      // @ts-ignore
      Vue.set(this.dropdownItems, 'selected', newValue);
    } else if (newValue === undefined || newValue === null || newValue === '') {
      // @ts-ignore
      Vue.set(this.dropdownItems, 'selected', DROPDOWN_ITEM_FALLBACK);
    }
  }

  open() {
    super.open();
    (this.TEXTAREA_PARENT.getElementsByClassName('handsontableInput')[0] as HTMLElement).style.display = 'none';
    this.TEXTAREA_PARENT.style.width = `${this.TD.clientWidth}px`;
    this.TEXTAREA_PARENT.style.zIndex = '200';
    // @ts-ignore
    this.dropdown.$children[0].open();
  }

  finishEditing(restoreOriginalValue?: boolean, ctrlDown?: boolean, callback?: () => void) {
    const value = this.getValue();
    if (value == null) {
      super.finishEditing(true, ctrlDown, callback);
    } else if (this.state === 'STATE_EDITING' && value === this.originalValue) {
      super.finishEditing(true, ctrlDown, callback);
    } else {
      super.finishEditing(restoreOriginalValue, ctrlDown, callback);
    }
  }

  /**
   * this is a copy of baseEditor.js saveValue but instead of calling populateFromArray it calls setDataAtCell, because populateFromArray doesn't work with complex objects
   */
  saveValue(value?: any, ctrlDown?: boolean | undefined): void {
    let visualRow;
    let visualColumn;

    // if ctrl+enter and multiple cells selected, behave like Excel (finish editing and apply to all cells)
    if (ctrlDown) {
      console.warn("DropdownEditor can only update single cells. Don't select multiple cells");
      const selectedLast = this.hot.getSelectedLast();
      if (!selectedLast) throw new Error('selectedLast is null');

      visualRow = Math.max(Math.min(selectedLast[0], selectedLast[2]), 0); // Math.max eliminate headers coords.
      visualColumn = Math.max(Math.min(selectedLast[1], selectedLast[3]), 0); // Math.max eliminate headers coords.
    } else {
      [visualRow, visualColumn] = [this.row, this.col];
    }

    const modifiedCellCoords = this.hot.runHooks('modifyGetCellCoords', visualRow, visualColumn);

    if (Array.isArray(modifiedCellCoords)) {
      [visualRow, visualColumn] = modifiedCellCoords;
    }

    this.hot.setDataAtCell(visualRow, visualColumn, value[0][0], 'edit');
  }

  close() {
    this.TEXTAREA_PARENT.style.removeProperty('z-index');
    (this.TEXTAREA_PARENT.getElementsByClassName('handsontableInput')[0] as HTMLElement).style.display = 'block';
    // @ts-ignore
    this.dropdown.$children[0].close();
    super.close();
    this.hot.listen();
  }
}
