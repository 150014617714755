
import type { ActivityRuleViolation, TrackField, TrackLiveCheckRequestData, TrackProduct } from 'farmdok-rest-api';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import ControlProductList from '@/precision-farming/application-maps/controls/ControlProductList.vue';
import type { PlantProtection } from '@/precision-farming/application-maps/spraying/store/types';
import type { Unit } from '@/shared/api/rest/models';
import { ACTIVITY_ROUGH_GUIDS } from '@/shared/constants';
import { createUuid } from '@/shared/modules/uuid';

export default defineComponent({
  name: 'ProductListContainer',
  components: { ControlProductList },
  computed: {
    products: {
      get(): PlantProtection[] {
        return this.$store.state.precisionFarming.applicationMaps.spraying.calculation.products;
      },
      set(products: PlantProtection[]) {
        this.$store.state.precisionFarming.applicationMaps.spraying.calculation.products = products;
      },
    },
    ...mapGetters('products', ['findProductById']),
    ...mapState('units', {
      units: 'data',
    }),
    ...mapGetters('auth', {
      processOrderIds: 'currentProcessOrderIds',
      companyIds: 'currentCompanyIds',
    }),
    ...mapState('precisionFarming/applicationMaps/spraying', {
      selectedFieldIds: 'selectedFields',
    }),
    ...mapState('fields', {
      fields: 'data',
    }),
    ...mapGetters('activityTypes', {
      byRoughId: 'byRoughAndFineId',
    }),
  },
  watch: {
    products(protections: PlantProtection[]) {
      const pendingUserInput = protections.some((protection) => !protection.amount);
      if (pendingUserInput) {
        return;
      }

      const protection = this.byRoughId(ACTIVITY_ROUGH_GUIDS.PROTECT);
      const currentProcessOrderId = this.processOrderIds.find(() => true) ?? '';
      const currentCompanyId = this.companyIds.find(() => true) ?? '';

      const request: TrackLiveCheckRequestData['track'] = {
        id: createUuid(),
        timeStart: moment().unix(), // TODO #1432 read from route if available, once task is created
        processOrderId: currentProcessOrderId,
        activityTypeId: protection.id,
        companyId: currentCompanyId,
        fields: this.selectedFieldIds.map(this.toTrackField),
        tstamp: moment().unix(),
        products: protections.map(this.toTrackProduct),
        equipment: [], // irrelevant for this workflow
        loads: [], // irrelevant for this workflow
      };
      this.liveCheck(request);
    },
  },
  methods: {
    /**
     * Shothand for mapActions('precisionFarming/applicationMaps/spraying', ['liveCheck']), but this one makes the type not inferrable 🤦‍
     * @param payload
     */
    async liveCheck(payload: TrackLiveCheckRequestData['track']): Promise<ActivityRuleViolation[]> {
      return this.$store.dispatch('precisionFarming/applicationMaps/spraying/liveCheck', payload);
    },
    unit(productId: string): Unit | undefined {
      const product = this.findProductById(productId);
      return this.units[product?.unitId];
    },
    toTrackProduct(product: PlantProtection): TrackProduct {
      const productId = product.product.id!;

      return {
        id: createUuid(),
        tstamp: moment().unix(),
        productId,
        pestId: product.pest.id || undefined,
        amount: product.amount,
        unitId: this.unit(productId)?.byUnitId ?? '',
      };
    },
    toTrackField(fieldId: string): TrackField {
      const field = this.fields[fieldId];

      return {
        id: createUuid(),
        tstamp: moment().unix(),
        fieldId,
        processedArea: field?.fieldSize,
        manual: false,
      };
    },
  },
});
