
import { PropType, defineComponent } from 'vue';

import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';
import FormFieldInputNumberFormattedInteger from '@/shared/components/form/FormFieldInputNumberFormattedInteger.vue';
import TableLoading from '@/shared/handsontable/components/TableLoading.vue';

import ZoneBadge from './ZoneBadge.vue';
import displayValue from './displayValue';
import { ColumnSettings, TableData } from './types';

export default defineComponent({
  name: 'ResultTable',
  components: {
    FormFieldInputNumberFormattedInteger,
    ZoneBadge,
    TableLoading,
    MultiRootWrapper,
  },
  props: {
    columns: {
      type: Array as PropType<ColumnSettings[]>,
      required: true,
    },
    data: {
      type: Array as PropType<TableData[]>,
      required: true,
    },
    footer: {
      type: Object as PropType<TableData | null>,
      required: false,
    },
    variant: {
      type: String as PropType<'with-borders' | 'without-borders' | 'centered'>,
      default: 'without-borders',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    displayValue,
    thClass(column: ColumnSettings): string {
      switch (column.align) {
        case 'left':
          return 'text-left';
        case 'center':
          return 'text-center';
        case 'right':
          return 'text-right';
        default:
          return '';
      }
    },
    tdClass(column: ColumnSettings): string {
      const classNames: string[] = [];
      if (column.type === 'numberInput') {
        classNames.push(`table-simple-${this.variant}-number-input`);
      }

      switch (column.align) {
        case 'left':
          classNames.push('content-left');
          break;
        case 'center':
          classNames.push('content-center');
          break;
        case 'right':
          classNames.push('content-right');
          break;
        default:
          break;
      }

      return classNames.join(' ');
    },
  },
  computed: {
    tableClass(): string {
      const classNames: string[] = ['result-table'];
      classNames.push(`table-simple-${this.variant}`);
      if (this.variant === 'with-borders') {
        classNames.push('border-medium');
        classNames.push('border-bottom');
      }
      return classNames.join(' ');
    },
  },
});
