import Handsontable from 'handsontable';

import { UserColumnSettings } from '@/shared/handsontable/rework/types';

export function toColumnsForPageSettings(
  columns: Handsontable.ColumnSettings[],
  hot: Handsontable,
): ColumnForPageSettings[] {
  const fixedColumnsLeft = hot.getSettings().fixedColumnsLeft ?? 0;

  return columns.map((column, physicalColumn) => {
    if (typeof column.data !== 'string') throw new Error('column.data is not a string');

    const prop = getColumnProp(column);
    const visualColumn = hot.propToCol(prop);

    return {
      ...column,
      physicalColumn,
      visualColumn,
      fixed: visualColumn < fixedColumnsLeft,
      visible: getVisible(visualColumn, hot),
    };
  });
}

export function toUserColumnSettings(
  columnsForPageSettings: ColumnForPageSettings[],
): Record<string, UserColumnSettings> {
  return columnsForPageSettings.reduce((acc, column) => {
    if (typeof column.data !== 'string') throw new Error('column.data is not a string');
    return {
      ...acc,
      [column.data]: {
        visualColumn: column.visualColumn,
        physicalColumn: column.physicalColumn,
        hidden: !column.visible,
      },
    };
  }, {} as Record<string, UserColumnSettings>);
}

function getColumnProp(column: Handsontable.ColumnSettings): string {
  if (typeof column.data !== 'string') throw new Error('column.data is not a string');
  if (column.type === 'subtable') return column.subtableColumns[0].data;
  return column.data;
}

function getVisible(visualColumn: number, hot: Handsontable) {
  const plugin = hot.getPlugin('hiddenColumns');
  if (!plugin.isEnabled()) return true;

  const hiddenColumns = plugin.getHiddenColumns();
  return !hiddenColumns.includes(visualColumn);
}

export type ColumnForPageSettings = Handsontable.ColumnSettings & {
  physicalColumn: number;
  visualColumn: number;
  fixed: boolean;
  visible: boolean;
};
