import type { ActivityRuleViolation } from 'farmdok-rest-api';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import { ColorCode, Feature } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import initialState from './initialState';
import { ApplicationMapsSprayingState, IndexType, type PlantProtection, SummaryData } from './types';

const mutations: MutationTree<ApplicationMapsSprayingState> = {
  ...baseWorkflowStore.mutations,
  reset(state) {
    const newState = initialState();
    Object.entries(newState).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },

  // satellite images
  addMultipolyTimestamp(state, timestamp) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.loaded };

    newMultiPolyTimestamps[timestamp.key] = timestamp.data;
    Vue.set(state.multiPolyTimestamps, 'loaded', newMultiPolyTimestamps);
  },
  setCurrentMultipolyTimestamp(state, timestamp) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.current };

    newMultiPolyTimestamps[timestamp.key] = timestamp.data;
    Vue.set(state.multiPolyTimestamps, 'current', newMultiPolyTimestamps);
  },
  unsetCurrentMultipolyTimestamp(state: ApplicationMapsSprayingState, polygonKey) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.current };

    delete newMultiPolyTimestamps[polygonKey];
    Vue.set(state.multiPolyTimestamps, 'current', newMultiPolyTimestamps);
  },

  setHeatmapTimestamp(state: ApplicationMapsSprayingState, timestamp: number) {
    Vue.set(state, 'selectedHeatmapTimestamp', timestamp);
  },
  setHeatmapTimestampSelectedIndex(state: ApplicationMapsSprayingState, value) {
    Vue.set(state, 'heatmapTimestampSelectedIndex', value);
  },
  setHeatmapTimestampManuallySelected(state, value) {
    Vue.set(state, 'heatmapTimestampManuallySelected', value);
  },
  setSelectedIndexType(state: ApplicationMapsSprayingState, indexType: IndexType) {
    Vue.set(state, 'selectedIndexType', indexType);
  },
  setSelectedQuantisationCode(state, quantisationCode) {
    Vue.set(state, 'selectedQuantisationCode', quantisationCode);
  },
  setViolations(state, violations: ActivityRuleViolation[]) {
    Vue.set(state, 'violations', violations);
  },
  setCheckingViolation(state, value: boolean) {
    Vue.set(state, 'checkingViolation', value);
  },
  // uploaded zones
  setUploadedZonesByFilename(state, uploadedZonesByFilename) {
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  uploadedZoneFilesAddGeoJson(state, { filename, geoJson }) {
    const uploadedZonesByFilename = {
      ...state.uploadedZonesByFilename,
      [filename]: geoJson,
    };
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  uploadedZoneFilesRemoveGeoJson(state: ApplicationMapsSprayingState, filename) {
    const uploadedZonesByFilename = Object.keys(state.uploadedZonesByFilename).reduce((acc, currentFilename) => {
      if (currentFilename === filename) {
        return acc;
      }
      return {
        ...acc,
        [currentFilename]: state.uploadedZonesByFilename[currentFilename],
      };
    }, {});
    Vue.set(state, 'uploadedZonesByFilename', uploadedZonesByFilename);
  },
  updateFeature(state: ApplicationMapsSprayingState, feature: Feature): void {
    if (feature.heatmapKey === undefined || feature.featureIndex === undefined) {
      throw new Error(
        'Cannot update features with missing heatmap key or feature index in spraying workflow. Features are subordinates of heatmaps and therefore need to provide a heatmap key and a feature index for ease of reference.',
      );
    }

    const targetColorCode = state.heatmaps.current[feature.heatmapKey].color_codes.find(
      (code: ColorCode) => code.col === feature.properties.fill,
    );
    if (!targetColorCode) {
      return;
    }

    Vue.set(targetColorCode, 'col', feature.properties.previewFill);
    Vue.set(targetColorCode, 'name', feature.vegetation.custom_value);
    Vue.set(
      state.heatmaps.current[feature.heatmapKey].features[feature.featureIndex].properties,
      'fill',
      feature.properties.previewFill,
    );
    Vue.set(
      state.heatmaps.current[feature.heatmapKey].features[feature.featureIndex].properties,
      'stroke',
      feature.properties.previewFill,
    );
  },
  setOverwrite(state: ApplicationMapsSprayingState, overwrite: SummaryData): void {
    state.calculation.overwrite = overwrite;
  },
  setSprayMix(state: ApplicationMapsSprayingState, value: number): void {
    state.calculation.sprayMix = value;
  },
  setReduction(state: ApplicationMapsSprayingState, value: number): void {
    state.calculation.reduction = value;
  },
  setPlantProtections(state: ApplicationMapsSprayingState, protections: PlantProtection[]): void {
    state.calculation.products = protections;
  },
};

export default mutations;
