<template>
  <span class="bg-white border text-dark number-indicator" data-cy="number-indicator-body" @click="onClick">
    {{ value }}
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    onClick: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style>
.number-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 9px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 1.2em;
  font-size: 12px;
  cursor: pointer;
}
</style>
