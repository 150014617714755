<template>
  <div
    class="max-width-container"
    :class="[
      `max-width-container--${size}`,
      { 'max-width-container--grid': grid },
      { 'max-width-container--left': left },
    ]"
  >
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'MaxWidthContainer',
  props: {
    size: {
      type: String,
      validator: (val) => ['md', 'lg', 'xl'].includes(val),
      default: 'md',
    },
    grid: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.max-width-container {
  --container-padding: var(--spacer_4);
  display: flex;
  max-width: calc(450px + 2 * var(--container-padding));
  width: 100%;
  padding: var(--container-padding);
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.max-width-container--left {
  align-items: flex-start;
  text-align: left;
}

@media (min-width: 768px) {
  .max-width-container--lg {
    max-width: calc(600px + 2 * var(--container-padding));
  }

  .max-width-container--xl {
    max-width: calc(700px + 2 * var(--container-padding));
  }
}

@media (min-width: 768px) AND (min-height: 768px) {
  .max-width-container {
    --container-padding: var(--spacer_5);
  }
}

.max-width-container::v-deep .btn-block + .btn-block {
  margin-top: var(--spacer_3);
}

.max-width-container--grid {
  display: grid;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  align-items: stretch;
  column-gap: 1rem;
  row-gap: 1rem;
}

.max-width-container--grid::v-deep .btn-block + .btn-block {
  margin-top: 0;
}
</style>
