<template>
  <ThePageSkeleton class="page-check-mail" scroll-y>
    <WelcomeContentContainer>
      <TopContentContainer>
        <FontAwesomeIcon class="m-4" size="3x" :icon="['fal', 'paper-plane']" />
        <WelcomeTitle class="m-0" white centered>
          {{ $t('Aktiviere deinen sicheren Zugang!') }}
        </WelcomeTitle>
      </TopContentContainer>
      <FontAwesomeIcon v-if="fetching" class="text-medium m-auto" size="3x" spin :icon="['fad', 'spinner-third']" />
      <MaxWidthContainer v-if="!fetching" class="mt-auto pb-0" size="lg">
        <WelcomeTitle centered>
          {{ $t('Bitte überprüfe deine E-Mails') }}
        </WelcomeTitle>
      </MaxWidthContainer>
      <MaxWidthContainer v-if="!fetching" class="mb-auto pt-0">
        <p class="mb-2">
          {{ messageEmailParts[0] }}
          <strong>{{ $store.state.auth.user.email }}</strong>
          {{ messageEmailParts[1] }}
        </p>
        <p class="mb-4">
          {{ $t('Klicke auf den Link in der E-Mail um deinen Account zu aktivieren.') }}
        </p>
        <p class="mb-4">
          {{ $t('Du hast keine E-Mail bekommen? Überprüfe bitte den Spam-Ordner.') }}
          {{ messageResendParts[0] }}
          <ButtonLink @click="onResend">{{ messageResendParts[1] }}</ButtonLink>
          {{ messageResendParts[2] }}
        </p>
        <ErrorUserMessage :error-user-message="userErrorMessage" />
      </MaxWidthContainer>
    </WelcomeContentContainer>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import axios from 'axios';
import moment from 'moment';

import { FARMDOK_AUTH_CHECK_EMAIL_VISITED } from '@/auth/constants';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';

import TopContentContainer from './components/TopContentContainer.vue';
import WelcomeContentContainer from './components/WelcomeContentContainer.vue';
import WelcomeTitle from './components/WelcomeTitle.vue';
import checkForAuthRegistrationProcessActive from './mixins/checkForAuthRegistrationProcessActive';

library.add(faPaperPlane, faSpinnerThird);

export default {
  name: 'PageCheckMail',
  components: {
    ErrorUserMessage,
    ThePageSkeleton,
    WelcomeContentContainer,
    MaxWidthContainer,
    TopContentContainer,
    WelcomeTitle,
    ButtonLink,
  },
  mixins: [backendResponseErrorHelpers, checkForAuthRegistrationProcessActive],
  data() {
    return {
      fetching: false,
      messageEmail: this.$t('Wir haben dir eine E-Mail an {email} gesendet.', { email: '#####' }),
      messageResend: this.$t(
        'Sollte die E-Mail nicht auffindbar sein, {linkStart}klicke hier, um sie nochmal zu senden.{linkEnd}',
        {
          linkStart: '#####',
          linkEnd: '#####',
        },
      ),
      defaultUserErrorMessage: this.$t('Unbekannter Fehler. Bitte überprüfe deine Internetverbindung.'),
    };
  },
  computed: {
    messageEmailParts() {
      return this.messageEmail.split('#####');
    },
    messageResendParts() {
      return this.messageResend.split('#####');
    },
  },
  mounted() {
    localStorage.setItem(FARMDOK_AUTH_CHECK_EMAIL_VISITED, moment().format('YYYY-MM-DD'));
  },
  methods: {
    async onResend() {
      this.fetching = true;
      this.userErrorMessage = null;
      // eslint-disable-next-line no-promise-executor-return
      await Promise.all([new Promise((resolve) => setTimeout(resolve, 300)), this.resend()]);
      this.fetching = false;
    },
    async resend() {
      try {
        const { data } = await axios.get('/admin/rest/user/resend_email_confirmation_email');
        if (data.status !== 'success') {
          this.setUserErrorMessageFromResponse(data);
        }
      } catch (error) {
        let response = null;
        if (error != null && error.response != null) {
          response = error.response.data;
        }
        this.setUserErrorMessageFromResponse(response);
      }
    },
  },
};
</script>

<style scoped></style>
