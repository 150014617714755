import { AxiosRequestConfig } from 'axios';
import { Equipment, EquipmentApi, EquipmentApiEquipmentListRequest } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

async function fetchAll(
  context: ActionContext<SubscribableDataState<Equipment>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Equipment>> {
  const companyIds = context.rootState.auth.currentCompanies.map((company) => company.id);

  const { equipmentsApi } = Vue.prototype.$api as Api;
  const apiResponses = await Promise.all(
    companyIds.map((companyId) => {
      const requestParameters: EquipmentApiEquipmentListRequest = { companyId };
      return loadAllPages<EquipmentApi, Equipment>(equipmentsApi, 'equipmentList', requestParameters, axiosOptions);
    }),
  );

  const apiResponse = mergeApiResponses(apiResponses);

  return apiResponse;
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<Equipment>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Equipment>> {
  const { equipmentsApi } = Vue.prototype.$api as Api;
  const { data } = await equipmentsApi.equipmentObjects({ objectIds: ids.join(',') }, axiosOptions);

  return data as ApiResponse<Equipment>;
}

const subscribableStore = subscribableData(fetchAll, fetchByIds, [availableFeatures.FEATURE_EQUIPMENT]);
export default subscribableStore;
