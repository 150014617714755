<template>
  <ThePageSkeleton>
    <template #contentbar>
      <TheContentbar>
        <template #left="{ contentbarLarge }">
          <Button
            v-if="!editMode"
            icon="plus-circle"
            leading-icon
            component-namespace-name="ButtonAddFields"
            :disabled="fieldsReadOnly"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="create"
          >
            {{ $t('Felder hinzufügen') }}
          </Button>
          <ButtonDropdown
            v-show="editMode"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonUpdateSelection"
            @click="selectedRows = []"
          />
          <Button
            v-show="editMode"
            color="lightest"
            icon="pencil-alt"
            leading-icon
            component-namespace-name="ButtonEditFields"
            :disabled="fieldsReadOnly"
            :title="$t('Bearbeiten')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="edit"
          />
          <Button
            v-show="editMode"
            class="text-danger"
            color="lightest"
            icon="trash-alt"
            leading-icon
            component-namespace-name="ButtonDeleteFields"
            :disabled="fieldsReadOnly"
            :title="$t('Löschen')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="() => $refs.table.removeSelectedEntries()"
          />
          <Button
            v-show="editMode"
            color="lightest"
            icon="cloud-download"
            leading-icon
            component-namespace-name="ButtonExportFields"
            :disabled="exportDisabled"
            :title="$t('Auswahl exportieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="exportSelected"
          />
          <Button
            v-show="editMode"
            color="lightest"
            icon="paper-plane"
            leading-icon
            component-namespace-name="ButtonSendFieldPositions"
            :disabled="exportDisabled"
            :title="$t('Positionen senden')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="sendFieldPositions"
          />
          <Button
            v-show="!editMode"
            color="lightest"
            icon="cloud-upload"
            leading-icon
            component-namespace-name="ButtonImportFields"
            :disabled="fieldsReadOnly"
            :title="$t('Felder importieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="importData"
          />
          <Button
            variant="lightest"
            icon="flag-checkered"
            leading-icon
            component-namespace-name="ButtonCompleteCropYear"
            :disabled="processOrdersReadOnly || fieldsReadOnly || someSelectedRowsHaveFieldStatusCalculating"
            :title="btnTitleCompleteCropYear"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            :notification="recommendCompleteYear"
            @click="completeCropYear"
          />
          <Button
            v-show="editMode && selectedRows.length === 1"
            color="lightest"
            leading-icon
            component-namespace-name="ButtonSplitField"
            :disabled="fieldsReadOnly || !fieldsAdvancedEnabled"
            :icon="['far', 'cut']"
            :title="$t('Feld aufteilen')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="split"
          />
          <Button
            v-show="editMode && selectedRows.length > 1"
            color="lightest"
            leading-icon
            component-namespace-name="ButtonCombineFields"
            :disabled="fieldsReadOnly || !fieldsAdvancedEnabled"
            :icon="['far', 'object-group']"
            :title="$t('Felder kombinieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="combine"
          />
          <Button
            v-show="editMode"
            color="lightest"
            icon="share-square"
            leading-icon
            component-namespace-name="ButtonMoveFields"
            :disabled="fieldsReadOnly || !fieldsAdvancedEnabled"
            :title="$t('Ausgewählte Felder in ein anderes Erntejahr verschieben')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="moveSelected"
          >
            <span v-if="selectedRows.length === 1">
              {{ $t('Feld verschieben') }}
            </span>
            <span v-else>
              {{ $t('Felder verschieben') }}
            </span>
          </Button>
          <Button
            v-show="editMode"
            color="lightest"
            leading-icon
            component-namespace-name="ButtonShareFields"
            :disabled="fieldsReadOnly"
            :icon="['far', 'share-alt']"
            :title="$t('Felder freigeben')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="share"
          />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar v-show="!editMode" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <ProcessOrderSwitch v-show="!editMode" class="ml-4" :slim="!contentbarLarge" />
          <CompanySwitch v-show="!editMode" class="ml-4" :slim="!contentbarLarge" />
          <DropdownPageOptions
            v-show="!editMode"
            class="ml-4"
            :restore-label="$t('Felder wiederherstellen')"
            :restore-read-only="fieldsReadOnly"
            :export-disabled="exportDisabled"
            :additional-options="additionalPageOptions"
            @restore="restoreFields"
            @settings="showSettings"
            @export="exportData"
          />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableFieldsContainer
        ref="table"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
        :field-ids-with-harvesting-measure="fieldIdsWithHarvestingMeasure"
        @create="create"
        @edit="editFromContainer"
        @click:status="onClickStatus"
        @click:sharedFieldInfo="editShare"
        @updateSharedFieldInfo="updateAdditionalPageOptions"
      />
      <ModalPageSettings ref="modalPageSettings" :columns="columnsForPageSettings" />
      <ModalEditFields
        v-if="modalEditFieldsVisible"
        v-model="modalEditFieldsVisible"
        :field-ids="fieldIdsForModal"
        :columns="columnsForPageSettings"
      />
      <ModalSplitField
        v-if="modalSplitFieldVisible"
        v-model="modalSplitFieldVisible"
        :field-id="fieldIdsForModal[0]"
        :columns="columnsForPageSettings"
        @split="(fieldIds) => updateSelectedForModal(fieldIds)"
      />
      <ModalCombineFields
        v-if="modalCombineFieldsVisible"
        v-model="modalCombineFieldsVisible"
        :field-ids="fieldIdsForModal"
        :columns="columnsForPageSettings"
        @change:field-ids="updateSelectedForModal"
        @combined="(id) => updateSelectedForModal([id])"
      />
      <ModalInsertFields
        v-if="modalInsertFieldsVisible"
        v-model="modalInsertFieldsVisible"
        :columns="columnsForPageSettings"
      />
      <ModalMoveFields
        v-if="modalMoveFieldsVisible"
        v-model="modalMoveFieldsVisible"
        :field-ids="fieldIdsForModal"
        @success="selectedRows = []"
      />
      <PopoverFieldStatus
        v-if="fieldStatusTarget != null"
        :field="fieldStatusField"
        :target="fieldStatusTarget"
        :active-and-has-harvesting-measure="fieldStatusFieldIsActiveAndHasHarvestingMeasure"
        @close="fieldStatusTarget = null"
      />
      <ModalCompleteCropYear
        v-if="modalCompleteCropYearVisible"
        v-model="modalCompleteCropYearVisible"
        :field-ids-with-harvesting-measure="fieldIdsWithHarvestingMeasure"
      />
      <ModalEditStatus
        v-if="modalEditStatusVisible"
        v-model="modalEditStatusVisible"
        :field-ids="selectedRows"
        :all-active-and-have-harvesting-measures="allSelectedRowsHaveHarvestingMeasuresAndAreActive"
        @success="selectedRows = []"
      />
      <ModalTableExport
        ref="modalTableExport"
        :title="selectedRows.length > 0 ? $t('Selektierte Felder exportieren') : null"
        :filtered="selectedRows.length === 0 && $refs.table != null && !$refs.table.allVisible"
      />
      <ModalShareFields
        v-model="modalShareFieldsVisible"
        :field-ids="fieldIdsForModal"
        :shared-field-data="modalShareFieldsData"
        @success="updateSharedFields((resetSharedFields = false))"
        @resetSharedFieldData="resetModalShareFieldsdata"
      />
      <ModalRemoveSharedFields
        v-model="modalRemoveShareFieldsVisible"
        :shared-fields-by-field-id="
          modalRemoveShareFieldsData != null ? modalRemoveShareFieldsData.sharedFieldsByFieldId : null
        "
        :shared-fields-companies-by-id="
          modalRemoveShareFieldsData != null ? modalRemoveShareFieldsData.sharedFieldsCompaniesById : null
        "
        :shared-fields-by-share-id="
          modalRemoveShareFieldsData != null ? modalRemoveShareFieldsData.sharedFieldsByShareId : null
        "
        @success="updateSharedFields((resetSharedFields = true))"
      />
    </template>
    <template #satellite>
      <SatelliteFieldDetail
        v-show="satellite.visible"
        :loading="loading"
        :visible="satellite.visible"
        :expanded="satellite.expanded"
        :visible-fields="visibleRows.filter((fieldId) => fields[fieldId] != null).map((fieldId) => fields[fieldId])"
        :selected-fields="fieldsForSatellite"
        :current-field-id.sync="currentFieldIdForSatellite"
        :crops="crops"
        :varieties="varieties"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCut, faObjectGroup, faShareAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  faCloudDownload,
  faCloudUpload,
  faFlagCheckered,
  faPaperPlane,
  faPencilAlt,
  faPlusCircle,
  faShareSquare,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import debounce from 'lodash.debounce';
import numbro from 'numbro';
import { mapGetters } from 'vuex';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import { exportFields } from '@/shared/api/rest/RestApi';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import DropdownPageOptions from '@/shared/components/DropdownPageOptions.vue';
import ModalPageSettings from '@/shared/components/ModalPageSettings.vue';
import ModalTableExport from '@/shared/components/ModalTableExport.vue';
import ProcessOrderSwitch from '@/shared/components/ProcessOrderSwitch.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import { ACTIVITY_ROUGH_GUIDS, ENTRY_REMOVED, ENTRY_REMOVING, ENTRY_TO_REMOVE } from '@/shared/constants';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import ModalCombineFields from './components/ModalCombineFields.vue';
import ModalCompleteCropYear from './components/ModalCompleteCropYear.vue';
import ModalEditFields from './components/ModalEditFields.vue';
import ModalEditStatus from './components/ModalEditStatus.vue';
import ModalInsertFields from './components/ModalInsertFields.vue';
import ModalMoveFields from './components/ModalMoveFields.vue';
import ModalRemoveSharedFields from './components/ModalRemoveSharedFields.vue';
import ModalShareFields from './components/ModalShareFields.vue';
import ModalSplitField from './components/ModalSplitField.vue';
import PopoverFieldStatus from './components/PopoverFieldStatus.vue';
import SatelliteFieldDetail from './components/SatelliteFieldDetail.vue';
import TableFieldsContainer from './containers/TableFieldsContainer.vue';
import { fullFieldName } from './handsontable/columns/columns';

library.add(
  faPencilAlt,
  faPlusCircle,
  faCloudDownload,
  faCloudUpload,
  faFlagCheckered,
  faTrashAlt,
  faShareSquare,
  faPaperPlane,
  faObjectGroup,
  faCut,
  faShareAlt,
);

export default {
  name: 'PageFields',
  components: {
    ModalRemoveSharedFields,
    ModalSplitField,
    ModalCombineFields,
    ModalTableExport,
    ModalEditStatus,
    ModalCompleteCropYear,
    PopoverFieldStatus,
    DropdownPageOptions,
    TableFieldsContainer,
    TheContentbar,
    ThePageSkeleton,
    CompanySwitch,
    ProcessOrderSwitch,
    SearchBar,
    SatelliteFieldDetail,
    ModalPageSettings,
    ModalEditFields,
    ModalInsertFields,
    ModalMoveFields,
    Button,
    ButtonDropdown,
    ModalShareFields,
  },
  mixins: [buttonUpdateSelection('table')],
  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      columnsForPageSettings: null,
      fieldIdsForModal: [],
      modalEditFieldsVisible: false,
      modalSplitFieldVisible: false,
      modalCombineFieldsVisible: false,
      modalInsertFieldsVisible: false,
      modalMoveFieldsVisible: false,
      modalShareFieldsVisible: false,
      modalShareFieldsData: null,
      modalRemoveShareFieldsVisible: false,
      modalRemoveShareFieldsData: null,
      fieldStatusTarget: null,
      fieldStatusField: null,
      modalCompleteCropYearVisible: false,
      modalEditStatusVisible: false,
      additionalPageOptions: null,
    };
  },
  created() {
    this.$store.dispatch('activities/subscribe');
    this.$store.dispatch('activityTypes/subscribe');
  },
  computed: {
    revalidateProducts() {
      return {
        currentCompanies: this.$store.state.auth.currentCompanies,
        cropYear: Number(this.$store.state.auth.currentProcessOrderName),
      };
    },
    activities() {
      return this.$store.getters['activities/data'];
    },
    activityTypes() {
      return this.$store.getters['activityTypes/data'];
    },
    ...mapGetters({
      satellite: 'satellite',
      fields: 'fields/data',
      crops: 'products/crops/data',
      varieties: 'varieties/data',
    }),
    loading() {
      if (this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS)) {
        return this.$store.getters['fields/loading'] || this.$store.getters['customers/loading'];
      }
      return this.$store.getters['fields/loading'];
    },
    fieldStatusFieldIsActiveAndHasHarvestingMeasure() {
      if (this.fieldStatusField == null) {
        return undefined;
      }
      return (
        this.fieldIdsWithHarvestingMeasure.includes(this.fieldStatusField.id) &&
        this.fieldStatusField.status === 'active'
      );
    },
    allSelectedRowsHaveHarvestingMeasuresAndAreActive() {
      return this.selectedRows
        .filter((id) => this.fields[id] != null)
        .every((id) => this.fieldIdsWithHarvestingMeasure.includes(id) && this.fields[id].status === 'active');
    },
    allSelectedRowsFieldStatus() {
      const uniqueStatuses = [
        ...new Set(this.selectedRows.filter((id) => this.fields[id] != null).map((id) => this.fields[id].status)),
      ];
      return uniqueStatuses.length > 1 ? undefined : uniqueStatuses[0];
    },
    someSelectedRowsHaveFieldStatusCalculating() {
      return this.selectedRows
        .filter((id) => this.fields[id] != null)
        .some((id) => this.fields[id].status === 'calculating');
    },
    editMode() {
      return !this.loading && Object.keys(this.fields).length > 0 && this.selectedRows.length > 0;
    },
    fieldsForSatellite() {
      let fieldsForSatellite = this.selectedRows;
      if (fieldsForSatellite.length === 0) {
        fieldsForSatellite = this.visibleRows;
      }
      return fieldsForSatellite
        .filter(
          (id) =>
            this.fields[id] != null &&
            ![ENTRY_REMOVED, ENTRY_REMOVING, ENTRY_TO_REMOVE].includes(this.fields[id].storeStatus),
        )
        .map((id) => this.fields[id]);
    },
    currentFieldIdForSatellite: {
      get() {
        return this.$route.params.fieldId;
      },
      set(fieldId) {
        this.$router.push({ name: 'fields/record', params: { fieldId } });
      },
    },
    /**
     * #387
     * Return true if for one crop all active fields have a harvesting measure.
     *
     * @return {boolean}
     */
    recommendCompleteYear() {
      if (this.fields.length === 0 || this.fieldIdsWithHarvestingMeasure.length === 0) {
        return false;
      }
      if (this.selectedRows.length > 0) {
        return this.allSelectedRowsHaveHarvestingMeasuresAndAreActive;
      }
      const missingFieldsByCrop = {};
      Object.values(this.fields).forEach(({ id, cropId, status }) => {
        if (status !== 'active' || cropId == null) {
          return;
        }
        if (missingFieldsByCrop[cropId] == null) {
          missingFieldsByCrop[cropId] = [];
        }
        if (!this.fieldIdsWithHarvestingMeasure.includes(id)) {
          missingFieldsByCrop[cropId].push(id);
        }
      });
      return Object.values(missingFieldsByCrop).some((fieldIds) => fieldIds.length === 0);
    },
    btnTitleCompleteCropYear() {
      if (this.selectedRows.length === 0 || this.allSelectedRowsFieldStatus === 'active') {
        return this.$t('Erntejahr abschließen');
      }
      if (this.allSelectedRowsFieldStatus === 'planned') {
        return this.$t('Erntejahr beginnen');
      }
      return this.$t('Erntejahr-Status');
    },
    processOrdersReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_PROCESS_ORDER);
    },
    fieldsReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_FIELDS);
    },
    fieldsAdvancedEnabled() {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_FIELDS_ADVANCED);
    },
    exportDisabled() {
      return !this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_EXPORT_FIELD);
    },
    fieldIdsWithHarvestingMeasure() {
      const fieldIds = [];
      Object.values(this.activities).forEach((activity) => {
        const activityType = this.activityTypes[activity.activityTypeId];
        if (activityType?.roughId === ACTIVITY_ROUGH_GUIDS.HARVEST) {
          fieldIds.push(activity.fieldId);
        }
      });

      return fieldIds;
    },
  },
  watch: {
    revalidateProducts() {
      this.$store.dispatch('products/subscribe');
    },
    currentFieldIdForSatellite() {
      this.updateSatelliteState();
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.unsubscribeFromStore = this.$store.subscribe(({ type }, state) => {
      if (['fields/loadAll', 'fields/updateEntries', 'fields/syncAllFinish'].includes(type)) {
        this.reloadFieldsWithStatusCalculating();
      }
      if (type === 'satellite/toggleExpand') {
        if (state.satellite.expanded && this.fieldsForSatellite.length > 0 && this.currentFieldIdForSatellite == null) {
          this.$router.push({ name: 'fields/record', params: { fieldId: this.fieldsForSatellite[0].id } });
        } else {
          this.$router.push({ name: 'fields' });
        }
      }
    });
    this.updateSatelliteState();
    this.setInitialSatelliteState();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.unsubscribeFromStore();
  },
  methods: {
    updateAdditionalPageOptions() {
      if (this.$refs.table == null || Object.keys(this.$refs.table.sharedFieldsByFieldId).length === 0) {
        this.additionalPageOptions = null;
        this.modalRemoveShareFieldsData = null;
        this.modalShareFieldsData = null;
        return;
      }
      this.modalRemoveShareFieldsData = {
        sharedFieldsByFieldId: this.$refs.table.sharedFieldsByFieldId,
        sharedFieldsCompaniesById: this.$refs.table.sharedFieldsCompaniesById,
        sharedFieldsByShareId: this.$refs.table.sharedFieldsByShareId,
      };
      this.additionalPageOptions = [
        {
          key: 'ButtonRemoveSharedFields',
          disabled: false,
          onClick: () => {
            this.modalRemoveShareFieldsVisible = true;
          },
          icon: ['far', 'share-alt'],
          label: this.$t('Freigaben entfernen'),
        },
      ];
    },
    updateSatelliteState() {
      if (this.currentFieldIdForSatellite != null) {
        this.$store.commit('satellite/setState', { visible: true, expanded: true });
      }
    },
    async setInitialSatelliteState() {
      if (this.$store.state.satellite.visible === true) {
        return;
      }
      await this.$store.dispatch('fields/subscribe');
      const mediaQuery = this.$store.state.navbar.expanded === true ? '(min-width: 1550px)' : '(min-width: 1400px)';
      if (window.matchMedia(mediaQuery).matches === true && Object.keys(this.fields).length > 0) {
        this.$store.commit('satellite/setState', { visible: true, expanded: false });
      }
    },
    updateSelectedForModal(fieldIds) {
      this.selectedRows = fieldIds;
      this.fieldIdsForModal = this.selectedRows;
    },
    create() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      this.modalInsertFieldsVisible = true;
    },
    edit() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      this.fieldIdsForModal = this.selectedRows;
      this.modalEditFieldsVisible = true;
    },
    editFromContainer(guids) {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      this.fieldIdsForModal = guids;
      this.modalEditFieldsVisible = true;
    },
    split() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      this.fieldIdsForModal = this.selectedRows;
      this.modalSplitFieldVisible = true;
    },
    combine() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      this.fieldIdsForModal = this.selectedRows;
      this.modalCombineFieldsVisible = true;
    },
    importData() {
      this.$router.push({ name: 'fields/import' });
    },
    async completeCropYear() {
      if (this.selectedRows.length === 0) {
        this.modalCompleteCropYearVisible = true;
        return;
      }
      this.modalEditStatusVisible = true;
    },
    urlToFile(url, filename, type) {
      return fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type }));
    },
    async exportSelected() {
      const { type, okay } = await this.$refs.modalTableExport.show();
      if (!okay) {
        return;
      }

      if (type === 'xls') {
        this.$refs.table.$refs.table.export(this.selectedRows);
      } else {
        const response = await exportFields(type, this.selectedRows);
        const file = await this.urlToFile(
          `data:text/plain;base64,${response.data.fileBody}`,
          response.data.fileName,
          response.data.fileMimeType,
        );
        const { default: saveAs } = await import('jszip/vendor/FileSaver');
        saveAs(file, response.data.fileName);
      }
    },
    async exportData() {
      const { type, exportFiltered, okay } = await this.$refs.modalTableExport.show();
      if (!okay) {
        return;
      }

      let fieldIds = [];
      if (exportFiltered) {
        fieldIds = this.$refs.table.visibleRows;
      } else {
        fieldIds = Object.values(this.$refs.table.tableData).map((field) => field.id);
      }

      if (type === 'xls') {
        this.$refs.table.$refs.table.export(fieldIds);
      } else {
        const response = await exportFields(type, fieldIds);
        const file = await this.urlToFile(
          `data:text/plain;base64,${response.data.fileBody}`,
          response.data.fileName,
          response.data.fileMimeType,
        );
        const { default: saveAs } = await import('jszip/vendor/FileSaver');
        saveAs(file, response.data.fileName);
      }
    },
    sendFieldPositions() {
      const subject = this.$t('FARMDOK Feldpositionen');
      const body = this.$refs.table.selectedRows
        .map((id) => {
          const field = this.$refs.table.tableData[id];
          const name = fullFieldName.data(field);
          const crop =
            field.cropId != null && this.crops[field.cropId] != null ? ` (${this.crops[field.cropId].name})` : '';
          const size = field.fieldSize != null ? ` (${numbro(field.fieldSize).format()} ha)` : '';
          const zoom =
            field.fieldSize != null ? `${Math.max(Math.round(Math.sqrt(field.fieldSize) * 800), 3445)}m` : '15z';
          const googleMapsLink =
            typeof field.lat === 'number' && typeof field.lon === 'number'
              ? `https://www.google.com/maps/place/${field.lat},${field.lon}/@${field.lat},${field.lon},${zoom}/data=!3m1!1e3`
              : this.$t('Keine Feldposition');
          return `${name}${crop}${size}:\n${googleMapsLink}`;
        })
        .join('\n\n');
      const link = document.createElement('a');
      link.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      link.target = '_blank';
      link.click();
    },
    moveSelected() {
      this.fieldIdsForModal = this.selectedRows;
      this.modalMoveFieldsVisible = true;
    },
    share() {
      this.fieldIdsForModal = [...this.selectedRows];
      this.modalShareFieldsVisible = true;
    },
    editShare(fieldShare) {
      this.fieldIdsForModal = [fieldShare.fieldId];
      this.modalShareFieldsVisible = true;
      this.modalShareFieldsData = fieldShare;
    },
    resetModalShareFieldsdata() {
      this.modalShareFieldsData = null;
    },
    restoreFields() {
      this.$router.push({ name: 'fields/restore' });
    },
    async showSettings() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      const settings = await this.$refs.modalPageSettings.show();
      if (settings != null && Array.isArray(settings.columns)) {
        this.$refs.table.$refs.table.updateColumnsFromPageSettings(settings.columns);
      }
    },
    reloadFieldsWithStatusCalculating: debounce(
      async function reloadFieldsWithStatusCalculating() {
        const fieldIds = Object.values(this.fields)
          .filter(({ status }) => status === 'calculating')
          .map(({ id }) => id);
        if (fieldIds.length < 1) {
          return;
        }
        await this.$store.dispatch('fields/refreshByIds', fieldIds);
      },
      30 * 1000,
      { maxWait: 120 * 1000 },
    ),
    async onClickStatus(payload) {
      if (payload == null) {
        this.fieldStatusTarget = null;
        this.fieldStatusField = null;
        return;
      }
      const { target, guid } = payload;
      if (this.fieldStatusTarget === target) {
        this.fieldStatusTarget = null;
        this.fieldStatusField = null;
        return;
      }
      this.fieldStatusTarget = null;
      this.fieldStatusField = null;
      if (this.fields[guid] == null) {
        return;
      }

      await this.$nextTick();
      this.fieldStatusTarget = target;
      this.fieldStatusField = this.fields[guid];
    },
    onResize() {
      this.fieldStatusTarget = null;
      this.fieldStatusField = null;
    },
    updateSharedFields(resetSharedFields) {
      if (this.$refs.table != null) {
        this.$refs.table.updateSharedFields(resetSharedFields);
      }
    },
  },
};
</script>

<style scoped></style>
