import moveAllColumnsOfSubtableFactory from './beforeColumnMove/moveAllColumnsOfSubtableFactory';
import preventMoveBetweenSubtableColumnsFactory from './beforeColumnMove/preventMoveBetweenSubtableColumnsFactory';
import filterChangesToMergedCellsFactory from './filterChangesToMergedCellsFactory';
import mergeByColspanSettingFactory from './mergeByColspanSettingFactory';
import propagateChangesToMergedCellsFactory from './propagateChangesToMergedCellsFactory';
import {
  reinitializeMergedCellsAfterColumnMoveFactory,
  reinitializeMergedCellsAfterColumnSortFactory,
  reinitializeMergedCellsAfterFilterFactory,
} from './reinitializeMergedCellsFactories';
import removeChangesToNulls from './removeChangesToNulls';

// add these hooks to your handsontable instance
const hooks = {
  beforeChange: {
    removeChangesToNulls,
    propagateChangesToMergedCellsFactory,
    mergeByColspanSettingFactory,
  },
  beforeColumnMove: {
    moveAllColumnsOfSubtableFactory,
    preventMoveBetweenSubtableColumnsFactory,
  },
  afterChange: { filterChangesToMergedCellsFactory },
  afterFilter: reinitializeMergedCellsAfterFilterFactory,
  afterColumnSort: reinitializeMergedCellsAfterColumnSortFactory,
  afterColumnMove: { reinitializeMergedCellsAfterColumnMoveFactory },
};

export default hooks;
