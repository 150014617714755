import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import initialState from './initialState';
import { ProductVisibilitiesState } from './types';

const vuexModule: Module<ProductVisibilitiesState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: { ...subscribableStore.mutations },
  actions: { ...subscribableStore.actions },
  getters: { ...subscribableStore.getters },
};

export default vuexModule;
