
import { PropType, defineComponent } from 'vue';

import googleMapsApi from '@/shared/modules/googleMapsApi';

export default defineComponent({
  name: 'MapDistanceLabel',
  props: {
    polyline: {
      type: Object as PropType<google.maps.Polyline>,
      required: true,
    },
    map: {
      type: Object as PropType<google.maps.Map>,
      required: true,
    },
  },
  data() {
    return {
      google: null as any,
      overlayViews: [] as google.maps.OverlayView[],
      positions: [] as { top: string; left: string }[],
    };
  },
  async created() {
    const google = await googleMapsApi();
    this.google = google;
    this.polyline
      .getPath()
      .getArray()
      .forEach((point, index) => {
        const overlay = new this.google.maps.OverlayView();

        if (overlay) {
          overlay.onAdd = () => null;
          overlay.draw = () => {
            if (overlay) {
              const { x, y } = overlay.getProjection().fromLatLngToContainerPixel(point);
              const pos = {
                top: `${y}px`,
                left: `${x + 10}px`,
              };
              if (!this.positions[index]) {
                this.positions.push(pos);
              } else {
                this.$set(this.positions, index, pos);
              }
            }
          };
          overlay.onRemove = () => null;
          overlay.setMap(this.map);
        }
      });
  },
  methods: {},
  computed: {
    getDistances(): number[] {
      const points = this.polyline.getPath().getArray();
      const distances: number[] = [];
      const sum = points.reduce((distance, point, index) => {
        if (index === 0) {
          return 0;
        }

        const dis = google.maps.geometry.spherical.computeDistanceBetween(point, points[index - 1]);
        distances.push(dis);
        return distance + dis;
      }, 0);
      distances.push(sum);
      return distances;
    },
  },
});
