
import { type PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import AddSectionButton from '@/activities/modals/createEditActivity/components/AddSectionButton.vue';
import RemoveSectionButton from '@/activities/modals/createEditActivity/components/RemoveSectionButton.vue';
import type { ActivityTypesState } from '@/activityTypes/store/types';
import ControlPlantProtection from '@/precision-farming/application-maps/controls/ControlPlantProtection.vue';
import type { PlantProtection } from '@/precision-farming/application-maps/spraying/store/types';
import type { ApplicationMapsState } from '@/precision-farming/application-maps/store/types';
import type { ActivityType } from '@/shared/api/rest/models';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import { ACTIVITY_ROUGH_GUIDS } from '@/shared/constants';

/**
 * A form control that enables two-way binding of a product list. The list in this control is reactively bound and is independent of given context/domain. Some forms in precision farming utilize this control, but you can also use it separately, outside those forms.
 */
export default defineComponent({
  name: 'ControlProductList',
  components: {
    FormFieldSet,
    RemoveSectionButton,
    AddSectionButton,
    ControlPlantProtection,
  },
  props: {
    workflowKey: {
      type: String as PropType<keyof ApplicationMapsState>,
      required: true,
    },
    products: {
      type: Array as PropType<PlantProtection[]>,
      required: false,
      default: () => [],
    },
    /**
     * Maximum number of products that can be placed in the list
     */
    maxSize: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  emits: ['update:products'],
  model: {
    prop: 'products',
    event: 'update:products',
  },
  computed: {
    ...mapState('activityTypes', {
      protection(state: ActivityTypesState, getters): ActivityType {
        return getters.byRoughAndFineId(ACTIVITY_ROUGH_GUIDS.PROTECT);
      },
    }),
  },
  methods: {
    add(newProduct: PlantProtection) {
      const updatedProducts = [...this.products, newProduct];
      this.$emit('update:products', updatedProducts);
    },
    update(updatedProduct: PlantProtection, targetIndex: number) {
      const updatedProducts = this.products.map((product, index) => (index === targetIndex ? updatedProduct : product));
      this.$emit('update:products', updatedProducts);
    },
    newProduct(): PlantProtection {
      return {
        product: { id: '', name: '' },
        pest: { id: '', name: '' },
        amount: 0,
        unit: '',
      };
    },
    remove(targetIndex: number) {
      const updatedProducts = this.products.filter((_, index) => index !== targetIndex);
      this.$emit('update:products', updatedProducts);
    },
  },
});
