<template>
  <Widget v-if="visible" class="widget-crop-ratio" :title="$t('Kulturverhältnis')">
    <div v-if="enabled" class="widget-crop-ratio__description">
      <span v-if="pieChartPaths.length === 0">
        {{ $t('Keine Kulturdaten vorhanden.') }}
      </span>
      <span v-else> </span>
    </div>
    <div
      v-if="pieChartPaths.length > 0"
      class="widget-crop-ratio__content"
      :class="{ 'widget-crop-ratio__content--blurred': !enabled }"
    >
      <svg class="widget-crop-ratio__pie" :width="width" :height="height">
        <g :transform="`translate(${width / 2},${height / 2})`">
          <path
            v-for="pieChartPath in pieChartPaths"
            :class="{ 'widget-crop-ratio__pie--hovered': pieChartPath.cropId === currentCropId }"
            :key="pieChartPath.cropId"
            :d="pieChartPath.d"
            :fill="pieChartPath.fill"
            stroke="black"
            stroke-width="0px"
            :transform="`translate(${pieChartPath.translateX}, ${pieChartPath.translateY})`"
            @mouseover="() => onmouseover(pieChartPath)"
          />
          <text
            v-for="pieChartText in pieChartTexts"
            class="widget-crop-ratio__pie-label"
            :key="`text--${pieChartText.cropId}`"
            :transform="pieChartText.transform"
            :fill="pieChartText.fill"
          >
            <tspan v-for="(labelPart, index) in pieChartText.label" x="0" :key="labelPart" :dy="index > 0 ? 14 : 0">
              {{ labelPart }}
            </tspan>
          </text>
        </g>
      </svg>
      <div v-if="currentCropInfo != null" class="widget-crop-ratio__crop-description">
        <h2>{{ currentCropInfo.name }}</h2>
        <p>
          {{ $t('{size} ha', { size: currentCropInfo.sizeFormatted }) }}<br />
          {{ $t('{value} %', { value: currentCropInfo.percentageFormatted }) }}
        </p>
        <p>
          <span v-for="varietyInfo in currentCropInfo.varieties" :key="varietyInfo.varietyId">
            {{ $t('{name}: {sizeFormatted} ha', varietyInfo) }}<br />
          </span>
        </p>
      </div>
    </div>
    <FeatureNotAvailable v-if="!enabled" no-back-link />
  </Widget>
</template>

<script>
import { entries } from 'd3-collection';
import { arc, pie } from 'd3-shape';
import numbro from 'numbro';

import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import Widget from '@/shared/components/Widget.vue';
import { hexToHsl } from '@/shared/modules/colorHelpers';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

export default {
  name: 'WidgetCropRatio',
  components: { Widget, FeatureNotAvailable },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    cropsById: {
      type: Object,
      default: () => ({}),
    },
    varietiesById: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      width: 250,
      height: 250,
      currentCropId: null,
    };
  },
  computed: {
    enabled() {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_CROP_RATIO);
    },
    visible() {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CROP_RATIO);
    },
    cropsData() {
      const cropsData = {};
      this.fields.forEach((field) => {
        let { cropId } = field;
        const { fieldSize } = field;
        if (cropId == null) {
          cropId = 'no-crop';
        } else if (this.cropsById[cropId] == null || fieldSize <= 0) {
          return;
        }
        if (cropsData[cropId] == null) {
          cropsData[cropId] = {
            area: 0,
            varieties: {},
          };
        }
        cropsData[cropId].area += fieldSize;

        let { varietyId } = field;
        if (varietyId == null) {
          varietyId = 'none';
        }
        if (cropsData[cropId].varieties[varietyId] == null) {
          cropsData[cropId].varieties[varietyId] = {
            area: 0,
          };
        }
        cropsData[cropId].varieties[varietyId].area += fieldSize;
      });
      return cropsData;
    },
    pieChartPaths() {
      const fallbackColors = ['#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56'];

      const pieHelper = pie().value((dataPoint) => dataPoint.value.area);
      const pieSegments = pieHelper(
        entries(this.cropsData).sort((entryA, entryB) => entryA.value.area - entryB.value.area),
      );
      return pieSegments.map((pieSegment, index) => {
        const cropId = pieSegment.data.key;
        let outerRadius = this.width / 2 - 25;
        let translateX = 0;
        let translateY = 0;
        const deltaAngle = pieSegment.endAngle - pieSegment.startAngle;
        if (cropId === this.currentCropId) {
          const middleAngle = pieSegment.startAngle + deltaAngle / 2;
          const normalizer = Math.min(pieSegment.endAngle - pieSegment.startAngle, Math.PI * 0.75);
          translateX += (12 * Math.sin(middleAngle)) / normalizer;
          translateY += (-12 * Math.cos(middleAngle)) / normalizer;
          const outerRadiusNormalizer = (translateX ** 2 + translateY ** 2) ** 0.5;
          outerRadius += 10 - outerRadiusNormalizer;
        }
        const pathArc = arc()
          .innerRadius(0)
          .outerRadius(outerRadius)
          .startAngle(pieSegment.startAngle)
          .endAngle(pieSegment.endAngle);
        let fill = fallbackColors[index % 5];
        if (cropId === 'no-crop') {
          fill = 'var(--medium)';
        } else if (
          this.cropsById[cropId] != null &&
          typeof this.cropsById[cropId].mapPolygonColor === 'string' &&
          this.cropsById[cropId].mapPolygonColor.length > 0
        ) {
          fill = this.cropsById[cropId].mapPolygonColor;
        }
        return {
          cropId,
          d: pathArc(),
          fill,
          translateX,
          translateY,
          deltaAngle,
          centroid: pathArc.centroid(),
        };
      });
    },
    pieChartTexts() {
      return this.pieChartPaths
        .filter(({ deltaAngle }) => deltaAngle > Math.PI * 0.3)
        .map((path) => {
          let label;
          if (path.cropId === 'no-crop') {
            label = this.$t('Keine');
          } else if (this.cropsById[path.cropId] != null) {
            label = this.cropsById[path.cropId].shortcut;
            if (path.deltaAngle > Math.PI * 0.5) {
              label = this.cropsById[path.cropId].name;
            }
          }
          if (typeof label !== 'string') {
            label = [];
          } else if (label.length > 12) {
            label = [`${label.substr(0, 8)}-`, `${label.substr(8, 4)}...`];
          } else if (label.length > 10) {
            label = [`${label.substr(0, 8)}-`, label.substr(8, 4)];
          } else {
            label = [label];
          }

          let fill = 'var(--black)';
          const hsl = hexToHsl(path.fill);
          if (hsl[2] < 50) {
            fill = 'var(--white)';
          }

          const translateX = path.centroid[0] + path.translateX;
          const translateY = path.centroid[1] + path.translateY;
          return {
            ...path,
            transform: `translate(${translateX}, ${translateY})`,
            label,
            fill,
          };
        });
    },
    areaTotal() {
      return Object.values(this.cropsData).reduce((total, crop) => total + crop.area, 0);
    },
    currentCropInfo() {
      const crop = this.cropsById[this.currentCropId];
      const cropData = this.cropsData[this.currentCropId];
      if (cropData == null) {
        return null;
      }
      let percentage = 1;
      if (this.areaTotal > 0) {
        percentage = cropData.area / this.areaTotal;
      }
      const varieties = Object.keys(cropData.varieties).map((varietyId) => {
        let name = this.$t('Keine Sorte');
        if (this.varietiesById[varietyId] != null) {
          ({ name } = this.varietiesById[varietyId]);
        }
        return {
          varietyId,
          name,
          sizeFormatted: numbro(cropData.varieties[varietyId].area).format(),
        };
      });
      return {
        name: crop != null ? crop.name : this.$t('Keine Kultur gepflegt'),
        sizeFormatted: numbro(cropData.area).format(),
        percentageFormatted: numbro(percentage * 100).format(),
        varieties: this.currentCropId === 'no-crop' ? null : varieties,
      };
    },
  },
  watch: {
    pieChartPaths() {
      this.initCurrentCropId();
    },
  },
  mounted() {
    this.initCurrentCropId();
  },
  methods: {
    onmouseover(pieChartPath) {
      this.currentCropId = pieChartPath.cropId;
    },
    initCurrentCropId() {
      if (this.pieChartPaths.length === 0) {
        return;
      }
      if (this.currentCropId != null && this.cropsData[this.currentCropId] != null) {
        return;
      }
      this.currentCropId = this.pieChartPaths[this.pieChartPaths.length - 1].cropId;
    },
  },
};
</script>

<style scoped>
.widget-crop-ratio__description {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.widget-crop-ratio__content {
  display: flex;
  min-height: 300px;
  flex-direction: row;
  align-items: center;
}

.widget-crop-ratio__content--blurred {
  filter: blur(7px);
  pointer-events: none;
}

.widget-crop-ratio__crop-description {
  flex: 1;
}

.widget-crop-ratio__pie--hovered {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.widget-crop-ratio__pie-label {
  text-anchor: middle;
  pointer-events: none;
  font-size: 12px;
  line-height: 1;
}
</style>
