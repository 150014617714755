
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faDotCircle } from '@fortawesome/pro-solid-svg-icons';
import { PropType, defineComponent } from 'vue';

import Button from '@/shared/components/buttons/Button.vue';

library.add(faChevronLeft, faDotCircle, faChevronRight);

export default defineComponent({
  name: 'Page-Navigation',
  components: { Button },

  props: {
    pages: {
      type: Number as PropType<number>,
      required: true,
    },
    current: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  computed: {
    disableBackButton(): boolean {
      if (this.pages === 1) {
        return true;
      }
      if (this.current === 0) {
        return true;
      }
      return false;
    },
    disableNextButton(): boolean {
      if (this.pages === 1) {
        return true;
      }
      if (this.current === this.pages - 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    back(): void {
      if (!this.disableBackButton) {
        this.$emit('back');
      }
    },
    next(): void {
      if (!this.disableNextButton) {
        this.$emit('next');
      }
    },
  },
});
