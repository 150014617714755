<template>
  <Widget :title="$t('Flächennutzung')">
    <Table fixed headerless :fields="tableFields" :items="tableItems" />
  </Widget>
</template>

<script>
import numbro from 'numbro';

import Table from '@/shared/components/Table.vue';
import Widget from '@/shared/components/Widget.vue';
import { LICENSE_PACKAGE_GUIDS } from '@/shared/constants';

export default {
  name: 'WidgetKPIs',
  components: { Widget, Table },
  props: {
    selectedFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    kindOfUseTypes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tableFields() {
      return [
        {
          key: 'label',
          tdClass: (value, key, item) => this.addBlurClass(item),
        },
        {
          key: 'size',
          width: '80px',
          tdClass: (value, key, item) => this.addBlurClass(item, ['pl-1', 'pr-0', 'text-right']),
        },
        {
          key: 'labelHa',
          width: '30px',
          tdClass: (value, key, item) => this.addBlurClass(item, ['pl-1', 'pr-0']),
        },
        {
          key: 'percentage',
          width: '50px',
          tdClass: (value, key, item) => this.addBlurClass(item, ['pl-1', 'pr-0', 'text-right']),
        },
        {
          key: 'labelPercentage',
          width: '30px',
          tdClass: (value, key, item) => this.addBlurClass(item, ['pl-1', 'pr-0']),
        },
      ];
    },
    tableItems() {
      return [
        {
          label: this.$t('Gesamtfläche'),
          size: this.fieldSizeTotalFormatted,
          labelHa: this.$t('ha'),
          isTotal: true,
        },
        ...this.usages.map((usage) => ({
          ...usage,
          label: usage.title,
          labelHa: this.$t('ha'),
          labelPercentage: '%',
        })),
      ];
    },
    fieldSizeTotal() {
      return this.selectedFields.reduce((total, field) => {
        if (typeof field.fieldSize !== 'number') {
          return total;
        }
        return total + field.fieldSize;
      }, 0);
    },
    fieldSizeTotalFormatted() {
      return numbro(this.fieldSizeTotal).format();
    },
    usages() {
      const usagesById = {};
      this.selectedFields.forEach(({ fieldSize, kindofuse }) => {
        if (typeof fieldSize !== 'number' || kindofuse == null || kindofuse < 2) {
          return;
        }
        if (usagesById[kindofuse] == null) {
          usagesById[kindofuse] = {
            key: kindofuse,
            title: this.kindOfUseById[kindofuse].name,
            size: 0,
            percentage: 100,
          };
        }
        usagesById[kindofuse].size += fieldSize;
      });
      return Object.values(usagesById)
        .sort((a, b) => b.size - a.size)
        .map((usage) => {
          if (this.fieldSizeTotal === 0) {
            return usage;
          }
          return {
            ...usage,
            size: numbro(usage.size).format(),
            percentage: numbro((100 * usage.size) / this.fieldSizeTotal).format({ mantissa: 1 }),
          };
        });
    },
    kindOfUseById() {
      return this.kindOfUseTypes.reduce(
        (kindOfUseById, kindOfUse) => ({
          ...kindOfUseById,
          [kindOfUse.id]: kindOfUse,
        }),
        {},
      );
    },
    companyHasFreeLicense() {
      return this.$store.state.auth.currentCompanies.some(
        (company) => company.licensePackageId === LICENSE_PACKAGE_GUIDS.FARMDOK_FREE,
      );
    },
    companyHasNoLicense() {
      return this.$store.state.auth.currentCompanies.some(
        (company) => company.licensePackageId === LICENSE_PACKAGE_GUIDS.NO_LICENSE,
      );
    },
  },
  methods: {
    addBlurClass(item, classes = []) {
      if (!item.isTotal && (this.companyHasFreeLicense || this.companyHasNoLicense)) {
        return [...classes, 'table-cell--blurred'];
      }
      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .table-cell--blurred {
  filter: blur(4px);
  user-select: none;
}
</style>
