export type Result = {
  x: number | null;
  y: number | null;
  onLine1: boolean;
  onLine2: boolean;
};

export type CheckLineIntersectionResult = {
  x: number | null;
  y: number | null;
};

/**
 * checks if two lines intersect
 * returns false or the point (object with x and y) where they intersect
 *
 * @param line1StartX int
 * @param line1StartY int
 * @param line1EndX int
 * @param line1EndY int
 * @param line2StartX int
 * @param line2StartY int
 * @param line2EndX int
 * @param line2EndY int
 * @returns {false|object}
 */
export const checkLineIntersection = (
  line1StartX: number,
  line1StartY: number,
  line1EndX: number,
  line1EndY: number,
  line2StartX: number,
  line2StartY: number,
  line2EndX: number,
  line2EndY: number,
): CheckLineIntersectionResult | boolean => {
  // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite)
  // and booleans for whether line segment 1 or line segment 2 contain the point
  const denominator =
    (line2EndY - line2StartY) * (line1EndX - line1StartX) - (line2EndX - line2StartX) * (line1EndY - line1StartY);
  let a;
  let b;
  const result: Result = {
    x: null,
    y: null,
    onLine1: false,
    onLine2: false,
  };
  if (denominator === 0) {
    return false;
  }
  a = line1StartY - line2StartY;
  b = line1StartX - line2StartX;
  const numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
  const numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;
  a = numerator1 / denominator;
  b = numerator2 / denominator;

  // if we cast these lines infinitely in both directions, they intersect here:
  result.x = line1StartX + a * (line1EndX - line1StartX);
  result.y = line1StartY + a * (line1EndY - line1StartY);
  /*
   // it is worth noting that this should be the same as:
   x = line2StartX + (b * (line2EndX - line2StartX));
   y = line2StartX + (b * (line2EndY - line2StartY));
   */
  // if line1 is a segment and line2 is infinite, they intersect if:
  if (a > 0 && a < 1) {
    result.onLine1 = true;
  }
  // if line2 is a segment and line1 is infinite, they intersect if:
  if (b > 0 && b < 1) {
    result.onLine2 = true;
  }
  // if line1 and line2 are segments, they intersect if both of the above are true
  if (result.onLine1 && result.onLine2) {
    return {
      x: result.x,
      y: result.y,
    };
  }
  return false;
};

type LatLon = {
  lat: number | null;
  lon: number | null;
};

/**
 * geoJson has to be of type 'Polygon'.
 * Assumes first parameter of points array as longitude and the second as latitude.
 *
 * @param geoJson
 */
export const getLatLonFromGeoJson = (geoJson: any): LatLon => {
  if (geoJson == null || !Array.isArray(geoJson.coordinates) || !Array.isArray(geoJson.coordinates[0])) {
    console.error('shapeHelpers.js getLatLonFromGeoJson: invalid geoJson', geoJson);
    return { lon: null, lat: null };
  }
  const [lon, lat] = geoJson.coordinates[0].reduce(
    ([totalLon, totalLat], [nextLon, nextLat]) => [totalLon + nextLon, totalLat + nextLat],
    [0, 0],
  );
  return {
    lon: lon / geoJson.coordinates[0].length,
    lat: lat / geoJson.coordinates[0].length,
  };
};
