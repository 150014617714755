import Handsontable from 'handsontable';

import { ruleCheckStatus, selectColumn } from '@/activities/handsontable/columns';
import { expandColumn } from '@/shared/handsontable/rework/cellTypes/expandRenderer';
import placeholderColumn from '@/shared/handsontable/rework/features/placeholderColumn/column';

export default function filteredColumns(
  columns: Handsontable.ColumnSettings[],
  hot: Handsontable,
): Handsontable.ColumnSettings[] {
  return columns.filter((column, physicalCol) => {
    const visualCol = hot.toVisualColumn(physicalCol);
    return (
      column.type !== selectColumn.type &&
      column.type !== placeholderColumn.type &&
      column.type !== expandColumn.type &&
      column.type !== ruleCheckStatus.type &&
      column.type !== 'farmdok.trashIcon' &&
      !hot.getPlugin('hiddenColumns').isHidden(visualCol)
    );
  });
}
