<template>
  <TableBase
    ref="table"
    disable-read-only-bg
    read-only
    :id="id"
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @reload="loadData"
  >
    <template #footer>
      <TableFooter
        :total-rows="Object.keys(tableData).length"
        :visible-rows="visibleRows.length"
        :selected-rows="selectedRows.length"
      >
        {{ $t('Summe Größe: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}
      </TableFooter>
    </template>
  </TableBase>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import columnDropdownCompanies from '@/auth/mixins/containers/columnDropdownCompanies';
import columnDropdownCrops from '@/products/crops/mixins/containers/columnDropdownCrops';
import { dataToBase64 } from '@/shared/api/rest/requestUtils';
import { ERROR_UPDATING } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import loadRestTableData from '@/shared/handsontable/mixins/containers/featureLoadRestTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import { companyId, crop } from '../handsontable/columns/columns';
import columnsTableFieldsRestore from '../handsontable/columns/tableFieldsRestore';
import calculateSizeTotal from '../mixins/calculateSizeTotal';
import { resolve } from '../store/common';

export default {
  name: 'TableFieldsRestoreContainer',
  components: { TableFooter, TableBase },
  props: {
    filterString: {
      type: String,
      default: '',
    },
  },
  mixins: [
    tableContainerBase,
    loadRestTableData,
    columnDropdownCompanies(companyId.key),
    columnDropdownCrops({ [crop.key]: 'crop' }),
    calculateSizeTotal,
  ],
  data() {
    return {
      columnDefinition: columnsTableFieldsRestore,
    };
  },
  computed: {
    id() {
      return ['hot-fields-restore', ...this.$store.state.auth.currentCompanies.map((company) => company.id)].join('-');
    },
    currentProcessOrderIds() {
      return this.$store.getters['auth/currentProcessOrderIds'];
    },
  },
  watch: {
    currentProcessOrderIds() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const todayMinusNinetyDays = moment().startOf('day').subtract({ days: 90 });

      if (!this.currentProcessOrderIds || this.currentProcessOrderIds.length === 0) {
        return;
      }

      const filterCurrent = [
        'AND',
        ['processOrderId', 'IN', this.currentProcessOrderIds],
        ['deleted', '>=', Math.round(todayMinusNinetyDays / 1000)],
      ];
      this.loadTableData(
        `/admin/rest/field?showDeleted=1&disablePagination=1&filter=${dataToBase64(
          filterCurrent,
        )}&resolve=${dataToBase64(resolve)}`,
      );
    },
    async restore() {
      const selectedRows = [...this.selectedRows];
      try {
        await Promise.all(
          [...new Array(Math.ceil(selectedRows.length / 50)).keys()].map((index) => {
            const start = index * 50;
            const end = (index + 1) * 50;
            const filterCurrent = ['id', 'IN', selectedRows.slice(start, end)];
            return axios.put(`/admin/rest/field/undelete/?filter=${dataToBase64(filterCurrent)}`);
          }),
        );
        await this.$store.dispatch('fields/reset');
        await this.$store.dispatch('fields/subscribe');
        await this.$store.dispatch('fields/recentlyRestoredSetByGuids', selectedRows);
      } catch (error) {
        console.error(error);
        this.addErrorsAfterRestore(selectedRows);
        return false;
      }
      return true;
    },
    addErrorsAfterRestore(selectedRows) {
      this.tableErrors = [
        ...this.tableErrors,
        ...selectedRows.map((guid) => ({
          type: ERROR_UPDATING,
          guid,
          key: null,
          errorUserMessage: this.$t('Das Feld konnte nicht wieder hergestellt werden.'),
        })),
      ];
    },
  },
};
</script>

<style scoped></style>
