
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import type { ActivityRuleViolation } from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import type { HexCode } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { ThemeColors } from '@/shared/constants';

library.add(faBell);
const __default__ = defineComponent({
  name: 'AlertLiveCheckContainer',
  props: {
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created(): void {
    this.$store.dispatch('activities/ruleCheck/rules/subscribe');
  },
  data(): {
    bgColor: HexCode;
  } {
    return {
      bgColor: ThemeColors.DANGER,
    };
  },
  computed: {
    showModal: {
      get(): boolean {
        return this.$store.state.precisionFarming.applicationMaps.spraying.showRuleViolationModal;
      },
      set(show: boolean) {
        this.$store.state.precisionFarming.applicationMaps.spraying.showRuleViolationModal = show;
      },
    },
    ...mapState('precisionFarming/applicationMaps/spraying', ['violations']),
    ...mapState('activities/ruleCheck/rules', {
      rules: 'data',
    }),
    violationMessage(): string {
      const rules = this.violations.map((violation: ActivityRuleViolation) => this.rules[violation.ruleId]);
      if (rules.length) {
        return rules[0].shortMessage;
      }

      return 'Unbekannter Fehler';
    },
  },
  methods: {
    toggle(): void {
      if (this.clickable) {
        this.showModal = true;
      }
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "46de5512": (_vm.bgColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__