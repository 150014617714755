var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.grouped)?_c('BTable',{class:[
    {
      'table--headerless': _vm.headerless,
      'table--transparent': _vm.transparent,
      'table--reduced-row-spacing': _vm.reducedRowSpacing,
    },
    `table--size-${_vm.size}`,
  ],attrs:{"show-empty":"","fields":_vm.fields,"items":_vm.items,"fixed":_vm.fixed,"busy":_vm.busy,"borderless":_vm.borderless},scopedSlots:_vm._u([{key:"table-colgroup",fn:function({ fields }){return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({
        width: field.width != null ? field.width : 'auto',
        maxWidth: field.maxWidth != null ? field.maxWidth : 'none',
        minWidth: field.minWidth != null ? field.minWidth : '0',
      })})})}},_vm._l((_vm.headerSlots),function(field){return {key:`head(${field.key})`,fn:function(data){return [_vm._t(`head(${field.key})`,null,{"label":data.label,"column":data.column,"field":data.field})]}}}),_vm._l((_vm.fieldSlots),function(field){return {key:`cell(${field.key})`,fn:function(data){return [_vm._t(`cell(${field.key})`,null,{"item":data.item,"field":data.field,"value":data.value})]}}}),(_vm.$scopedSlots['cell()'] != null)?{key:"cell()",fn:function(data){return [_vm._t("cell()",null,{"item":data.item,"field":data.field,"value":data.value})]}}:null,{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('FontAwesomeIcon',{attrs:{"size":"3x","spin":"","icon":['fad', 'spinner-third']}})],1)]},proxy:true},{key:"empty",fn:function(){return [_vm._t("empty",function(){return [_vm._v(" "+_vm._s(_vm.$t('Noch keine Einträge vorhanden.'))+" ")]})]},proxy:true}],null,true)}):_c('div',[_c('BTable',{staticClass:"mb-1",class:[
      {
        'table--headerless': _vm.headerless,
        'table--transparent': _vm.transparent,
        'table--reduced-row-spacing': _vm.reducedRowSpacing,
      },
      `table--size-${_vm.size}`,
    ],attrs:{"fields":_vm.fields,"fixed":_vm.fixed,"busy":_vm.$store.state.activities.fetching,"show-empty":_vm.items.length === 0,"borderless":_vm.borderless},scopedSlots:_vm._u([{key:"table-colgroup",fn:function({ fields }){return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({
          width: field.width != null ? field.width : 'auto',
          maxWidth: field.maxWidth != null ? field.maxWidth : 'none',
          minWidth: field.minWidth != null ? field.minWidth : '0',
        })})})}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('FontAwesomeIcon',{attrs:{"size":"3x","spin":"","icon":['fad', 'spinner-third']}})],1)]},proxy:true},{key:"empty",fn:function(){return [_vm._t("empty",function(){return [_vm._v(" "+_vm._s(_vm.$t('Noch keine Einträge vorhanden.'))+" ")]})]},proxy:true}],null,true)}),_vm._l((_vm.items),function(itemGroup){return _c('BTable',{key:itemGroup.key,staticClass:"table--grouped mb-1",class:[
      {
        'table--headerless': _vm.headerless,
        'table--transparent': _vm.transparent,
        'table--reduced-row-spacing': _vm.reducedRowSpacing,
      },
      `table--size-${_vm.size}`,
    ],attrs:{"fields":_vm.fields,"items":itemGroup.items,"fixed":_vm.fixed},scopedSlots:_vm._u([{key:"table-colgroup",fn:function({ fields }){return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({
          width: field.width != null ? field.width : 'auto',
          maxWidth: field.maxWidth != null ? field.maxWidth : 'none',
          minWidth: field.minWidth != null ? field.minWidth : '0',
        })})})}},{key:`head(${_vm.fields[0].key})`,fn:function(){return [_vm._v(" "+_vm._s(itemGroup.label)+" ")]},proxy:true},{key:"head()",fn:function(data){return [_c('span',[_vm._v(" ")])]}},_vm._l((_vm.fieldSlots),function(field){return {key:`cell(${field.key})`,fn:function(data){return [_vm._t(`cell(${field.key})`,null,{"item":data.item,"field":data.field,"value":data.value})]}}}),(_vm.$scopedSlots['cell()'] != null)?{key:"cell()",fn:function(data){return [_vm._t("cell()",null,{"item":data.item,"field":data.field,"value":data.value})]}}:null],null,true)})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }