import { Equipment, ImportActivity, ImportActivityProduct } from 'farmdok-rest-api';

import tableDataActivitiesImportEquipmentService from '@/activities/importActivities/services/TableDataActivitiesImportEquipmentService';
import tableDataActivitiesImportProductService from '@/activities/importActivities/services/TableDataActivitiesImportProductService';
import type { ImportActivityWithId } from '@/activities/importActivities/store/types';
import {
  DropdownMatch,
  ImportStatus,
  ImportStatusType,
  type TableDataImportActivity,
} from '@/activities/importActivities/types/TableDataImportActivity.types';
import isImportActivityValid from '@/activities/importActivities/utils/isImportActivityValid';
import type { Getters as ActivityGetters } from '@/activities/store/getters';
import type { TableDataMetadata } from '@/activities/store/types';
import type { Getters as ActivityTypeGetters } from '@/activityTypes/store/getters';
import type { Getters as AuthGetters } from '@/auth/store/getters';
import type { Company, User } from '@/auth/store/types';
import type { Getters as FieldGetters } from '@/fields/store/getters';
import ProductService from '@/products/services/ProductService';
import type { Getters as ProductGetters } from '@/products/store/getters';
import type { Field, Unit } from '@/shared/api/rest/models';
import { ProcessOrder } from '@/shared/api/rest/models';
import type { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { ENTRY_DIRTY } from '@/shared/constants';
import type { Data, DataEntry } from '@/shared/mixins/store/types';

export default function tableDataActivitiesImportService() {
  const { convertToActivityImportProductTableData, dummyProductForAddProductButton } =
    tableDataActivitiesImportProductService();
  const { convertToActivityImportEquipmentTableData, dummyEquipmentForAddEquipmentButton } =
    tableDataActivitiesImportEquipmentService();

  /* -- METHODS -- */
  function fieldsDropdownItemForNoMatch(importActivity: ImportActivity): DropdownItem {
    return {
      id: DropdownMatch.NO_MATCH,
      name: importActivity.fieldIdMatch.originalValue ?? '',
    };
  }

  function productDropdownItemForNoMatch(product: ImportActivityProduct): DropdownItem {
    return {
      id: DropdownMatch.NO_MATCH,
      name: product.productIdMatch?.originalValue ?? '',
    };
  }

  function activityTypeDropdownItemNoMatch(importActivity: ImportActivity): DropdownItem {
    return {
      id: DropdownMatch.NO_MATCH,
      name: importActivity.activityTypeIdMatch.originalValue ?? '',
    };
  }

  function convertToActivitiesImportTableData(
    importActivities: ImportActivityWithId[],
    tableDataMetadata: Record<string, TableDataMetadata>,
    productsLoading: boolean,
    currentFields: Data<Field>,
    currentUsers: Data<User>,
    currentUnits: Data<Unit>,
    currentEquipments: Data<Equipment>,
    currentCompanies: Data<Company>,
    productService: ProductService,
    activityTypesDropdownItem: ActivityTypeGetters['dropdownItem'],
    fieldsDropdownItem: FieldGetters['dropdownItem'],
    authProcessOrdersById: AuthGetters['processOrdersById'],
    productsDropdownItem: ProductGetters['dropdownItem'],
    findSimilarActivities: ActivityGetters['findSimilarActivitiesForImportActivity'],
  ): TableDataImportActivity[] {
    const tableData: TableDataImportActivity[] = [];

    importActivities.forEach((importActivity) => {
      const { products, equipment, id, activityTypeId, fieldId, storeStatus, userId, ...restOfImportActivity } =
        importActivity as ImportActivityWithId & DataEntry;
      const metadataWithFallback = getMetadataWithFallback(id, tableDataMetadata);
      const activityTypeDropdownItem =
        activityTypesDropdownItem(activityTypeId || '') ?? activityTypeDropdownItemNoMatch(importActivity);
      const fieldDropdownItem = fieldId
        ? fieldsDropdownItem(fieldId) ?? undefined
        : fieldsDropdownItemForNoMatch(importActivity);
      const fieldSize = getFieldSize(currentFields, importActivity.fieldId);
      const companyName = getCompanyName(authProcessOrdersById, importActivity.processOrderId, currentCompanies);
      const user = getUserName(currentUsers, userId);
      const importStatus = getImportStatus(importActivity, findSimilarActivities);

      const longestSubtableLength = Math.max(products.length, equipment.length);
      if (longestSubtableLength === 0) {
        tableData.push({
          ...restOfImportActivity,
          importStatus,
          id,
          activityTypeDropdownItem,
          fieldDropdownItem,
          ...metadataWithFallback,
          fieldSize,
          companyName,
          user,
          storeStatus: ENTRY_DIRTY,
        });
      } else {
        for (let i = 0; i < longestSubtableLength; i += 1) {
          const singleProduct = products[i];
          const singleEquipment = equipment[i];

          tableData.push({
            ...restOfImportActivity,
            id,
            importStatus,
            activityTypeDropdownItem,
            fieldDropdownItem,
            ...metadataWithFallback,
            product: singleProduct
              ? convertToActivityImportProductTableData(
                  singleProduct,
                  importActivity.processedArea,
                  currentUnits,
                  productService,
                  singleProduct.productId
                    ? productsDropdownItem(singleProduct.productId, null)
                    : productDropdownItemForNoMatch(singleProduct),
                  productsLoading,
                )
              : undefined,
            equipment: convertToActivityImportEquipmentTableData(singleEquipment, currentEquipments),
            fieldSize,
            companyName,
            user,
            storeStatus: ENTRY_DIRTY,
          });
        }
      }

      tableData.push(
        rowForAddButtons(
          id,
          activityTypeDropdownItem,
          fieldDropdownItem,
          fieldSize,
          restOfImportActivity,
          metadataWithFallback,
          companyName,
          user,
        ),
      );
    });

    return tableData;
  }

  function getCompanyName(
    processOrdersById: Data<ProcessOrder>,
    processOrderId: string | null,
    currentCompanies: Data<Company>,
  ): string {
    if (!processOrderId) return '';
    const { companyId } = processOrdersById[processOrderId];
    return currentCompanies[companyId].abbreviation ?? currentCompanies[companyId].name;
  }

  function getUserName(currentUsers: Data<User>, userId: string | null | undefined): string | undefined {
    const user = userId ? currentUsers[userId] : undefined;
    if (!user) return undefined;
    return `${user.firstname} ${user.lastname}`;
  }

  function getMetadataWithFallback(activityId: string, metadata: Record<string, TableDataMetadata>): TableDataMetadata {
    const metadataSelect = metadata[activityId]?.select ?? true;
    const metadataExpand = metadata[activityId]?.expand ?? true;
    return { select: metadataSelect, expand: metadataExpand };
  }

  function getFieldSize(fields: Data<Field>, fieldId: string | null | undefined): number | undefined {
    if (!fieldId) return undefined;
    const field = fields[fieldId];

    return field?.fieldSize;
  }

  function getImportStatus(
    importActivity: ImportActivityWithId,
    findSimilarActivities: ActivityGetters['findSimilarActivitiesForImportActivity'],
  ): ImportStatus {
    if (!isImportActivityValid(importActivity)) {
      return {
        type: ImportStatusType.INCOMPLETE,
        tooltipLabel: 'Fehlerhaft/unvollständig',
      };
    }

    if (findSimilarActivities(importActivity)?.length > 0) {
      return {
        type: ImportStatusType.DUPLICATE,
        tooltipLabel: 'Eine ähnliche Maßnahme mit gleichen Datum, Tätigkeit, Feld und Produkten existiert bereits.',
      };
    }
    return {
      type: ImportStatusType.OK,
      tooltipLabel: null,
    };
  }

  function rowForAddButtons(
    id: string,
    activityTypeDropdownItem: DropdownItem | undefined,
    fieldDropdownItem: DropdownItem | undefined,
    fieldSize: number | undefined,
    restOfImportActivity: Omit<
      ImportActivity,
      'products' | 'id' | 'equipment' | 'activityTypeId' | 'fieldId' | 'userId'
    >,
    metadata: TableDataMetadata,
    companyName: string,
    user: string | undefined,
  ): TableDataImportActivity {
    return {
      ...restOfImportActivity,
      id,
      activityTypeDropdownItem,
      fieldDropdownItem,
      fieldSize,
      ...metadata,
      product: dummyProductForAddProductButton(),
      equipment: dummyEquipmentForAddEquipmentButton(),
      companyName,
      user,
      storeStatus: ENTRY_DIRTY,
    };
  }

  return {
    convertToActivitiesImportTableData,
  };
}
