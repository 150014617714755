
import { BButton } from 'bootstrap-vue';
import { PropType, defineComponent } from 'vue';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Pagination from '@/shared/components/Pagination.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';

import { Violation, ViolationNotChecked, isViolationNotChecked } from '../types';
import ModalContentViolation from './ModalContentViolation.vue';
import ModalContentViolationNotChecked from './ModalContentViolationNotChecked.vue';

export default defineComponent({
  name: 'ModalRuleCheckViolations',
  props: {
    violations: {
      type: Array as PropType<Array<Violation | ViolationNotChecked>>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    featureDismissViolationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data: (): { currentPage: number; dismissViolations: boolean[] } => ({
    currentPage: 0,
    dismissViolations: [],
  }),
  components: {
    ModalWrapper,
    Pagination,
    BButton,
    ModalContentViolation,
    ModalContentViolationNotChecked,
    FormCheckbox,
  },
  computed: {
    pages(): number {
      return this.violations.length;
    },
    primaryButtonLabel(): string {
      if (this.hasNextViolation) {
        return this.$t('Weiter') || 'Weiter';
      }

      return this.$t('Ok') || 'Ok';
    },
    hasNextViolation(): boolean {
      return this.currentPage < this.pages - 1;
    },
    hasPreviousViolation(): boolean {
      return this.currentPage > 0;
    },
    currentViolation(): Violation | ViolationNotChecked | undefined {
      return this.violations[this.currentPage];
    },
    violationsToDismiss(): Array<Violation | ViolationNotChecked> {
      return this.violations.filter((_, index) => this.dismissViolations[index]);
    },
  },
  watch: {
    visible(visible: boolean) {
      if (visible) {
        this.goToFirstViolation();
        this.initDismissViolations();
      }
    },
    violations: {
      handler() {
        this.initDismissViolations();
      },
      immediate: true,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    onPrimaryClick() {
      if (this.hasNextViolation) {
        this.showNextViolation();
      } else {
        this.dismissViolationsIfSomeToDismiss();
        this.cancel();
      }
    },

    showNextViolation() {
      this.currentPage += 1;
    },
    onPreviousClick() {
      if (this.hasPreviousViolation) {
        this.currentPage -= 1;
      }
    },
    goToFirstViolation() {
      this.currentPage = 0;
    },
    isViolationNotChecked(violation: Violation | ViolationNotChecked): boolean {
      return isViolationNotChecked(violation);
    },
    initDismissViolations() {
      this.dismissViolations = this.violations.map(() => false);
    },
    dismissViolationsIfSomeToDismiss() {
      if (this.violationsToDismiss.length > 0) {
        this.$emit('dismissViolations', this.violationsToDismiss);
      }
    },
  },
});
