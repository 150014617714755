
import { PropType, defineComponent } from 'vue';

import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';

export type IconType = {
  id: string;
  displayName: string;
  iconPath: string;
};

export default defineComponent({
  name: 'IconSelectSection',
  components: {
    FormFieldSet,
  },
  props: {
    headline: {
      type: String as PropType<string>,
      default: '',
    },
    types: {
      type: Array as PropType<IconType[]>,
      required: true,
    },
    selectedTypes: {
      type: Array as PropType<IconType[]>,
      required: true,
    },
  },
  computed: {
    headlineAdditionalInfo(): string {
      if (this.selectedTypes.length === 1 || this.isEqual(this.selectedTypes)) {
        return this.headline;
      }
      return `${this.headline} (${this.$t('verschiedene')})`;
    },
  },
  methods: {
    setType(value: IconType): void {
      this.$emit('setType', value);
    },

    isSelected(type: IconType): boolean {
      if (this.selectedTypes.some((selectedType) => selectedType.id === type.id)) {
        return true;
      }
      return false;
    },
    isEqual(array: IconType[]): boolean {
      if (array.every((val, i, arr) => val === arr[0])) {
        return true;
      }
      return false;
    },
  },
});
