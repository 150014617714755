import { ActionContext } from 'vuex';

import setActivityTypeOrSibling from '@/activities/utils/activity/setActivityTypeOrSibling';
import { syncName } from '@/activityTypes/store/compatibleActivityTypesFilter';
import { RootState } from '@/store/types';

import { ActivitiesState } from '../types';

type Payload = {
  activityId: string;
  activityTypeId: string;
};

export default function updateActivityTypeAndSync(
  { getters, rootGetters, dispatch }: ActionContext<ActivitiesState, RootState>,
  { activityId, activityTypeId }: Payload,
) {
  const activity = getters.getById(activityId);
  const activityType = rootGetters['activityTypes/getById'](activityTypeId);
  const updatedActivity = setActivityTypeOrSibling(
    activity,
    syncName(activityType),
    rootGetters['auth/getCompanyByProcessOrderId'],
    rootGetters['activityTypes/getActivityTypeOfCompanyBySyncName'],
    rootGetters['activityTypes/getBySyncName'],
  );

  dispatch('updateEntryByKeyAndValueAndSync', {
    guid: activityId,
    key: 'activityTypeId',
    value: updatedActivity.activityTypeId,
  });
}
