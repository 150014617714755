/* eslint-disable no-param-reassign */
import { Activity, ActivityEquipment, ActivityProduct } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import { createUuid } from '@/shared/modules/uuid';

import calcAmountBasedOnProcessedArea from '../actions/utils/calcAmountBasedOnProcessedArea';
import initialState from '../initialState';
import { CreateEditActivityState, Polygon, WorkflowKey } from '../types';
import updateAmountAndUnitOnActivityProductInAllActivities from './updateAmountAndUnitOnActivityProductInAllActivities';
import distributeTimeToActivities from './utils/distributeTimeToActivities';
import getActivityById from './utils/getActivityById';

const mutations: MutationTree<CreateEditActivityState> = {
  //
  // Distribute times
  //
  distributeWorkingTimeToActivities(state: CreateEditActivityState, time: number): void {
    distributeTimeToActivities(state, { time, propertyKey: 'workingTime' });
  },
  distributeDrivingTimeToActivities(state: CreateEditActivityState, time: number): void {
    distributeTimeToActivities(state, { time, propertyKey: 'drivingTime' });
  },
  distributeSetupTimeToActivities(state: CreateEditActivityState, time: number): void {
    distributeTimeToActivities(state, { time, propertyKey: 'setupTime' });
  },
  distributePauseTimeToActivities(state: CreateEditActivityState, time: number): void {
    distributeTimeToActivities(state, { time, propertyKey: 'pauseTime' });
  },
  //
  // Update ids
  //
  updateAllActivityIds(state: CreateEditActivityState): void {
    state.activities.forEach((activity) => {
      activity.id = createUuid();
    });
  },
  updateAllActivityProductIds(state: CreateEditActivityState): void {
    state.activities.forEach((activity) => {
      activity.products.forEach((product) => {
        product.id = createUuid();
      });
    });
  },
  updateAllActivityEquipmentIds(state: CreateEditActivityState): void {
    state.activities.forEach((activity) => {
      activity.equipment.forEach((equipment) => {
        equipment.id = createUuid();
      });
    });
  },
  //
  // ActivityProduct
  //
  createActivityProduct(state: CreateEditActivityState) {
    state.activities.forEach((activity) => activity.products.push(ActivityProductService.createActivityProduct()));
  },
  addActivityProductToAllActivities(state: CreateEditActivityState, activityProduct: ActivityProduct) {
    state.activities.forEach((activity) => {
      const amount = calcAmountBasedOnProcessedArea(activity, state.activities, activityProduct.amount ?? 0);
      activity.products.push(_cloneDeep({ ...activityProduct, id: createUuid(), amount }));
    });
  },
  addActivityProduct(
    state: CreateEditActivityState,
    { activityId, activityProduct }: { activityId: string; activityProduct: ActivityProduct },
  ) {
    const activity = getActivityById(state, activityId);

    activity.products.push(activityProduct);
  },
  setActivityProduct(
    state: CreateEditActivityState,
    { activityId, activityProduct }: { activityId: string; activityProduct: ActivityProduct },
  ) {
    const activity = getActivityById(state, activityId);

    const index = activity.products.findIndex((product) => product.id === activityProduct.id);
    if (index === -1) throw new Error('ActivityProduct not found in activity');

    Vue.set(activity.products, index, activityProduct);
  },
  updateAmountAndUnitOnActivityProductInAllActivities,
  removeActivityProduct(
    state: CreateEditActivityState,
    { activityId, activityProductId }: { activityId: string; activityProductId: string },
  ) {
    const activity = getActivityById(state, activityId);

    activity.products = activity.products.filter((product) => product.id !== activityProductId);
  },
  removeAllActivityProductsWithoutProductId(state: CreateEditActivityState) {
    state.activities.forEach((activity) => {
      activity.products = activity.products.filter((product) => product.productId);
    });
  },
  setCurrentActivityProductId(state: CreateEditActivityState, data: string | null) {
    state.currentActivityProductId = data;
  },

  //
  // ActivityEquipment
  //
  addActivityEquipment(
    state: CreateEditActivityState,
    { activityId, activityEquipment }: { activityId: string; activityEquipment: ActivityEquipment },
  ) {
    const activity = getActivityById(state, activityId);

    activity.equipment.push(activityEquipment);
  },
  // TODO mv to action and use addActivityEquipment mutation
  createActivityEquipment(state: CreateEditActivityState) {
    state.activities.forEach((activity) => activity.equipment.push(ActivityEquipmentService.createActivityEquipment()));
  },
  setActivityEquipment(
    state: CreateEditActivityState,
    { activityId, activityEquipment }: { activityId: string; activityEquipment: ActivityEquipment },
  ) {
    const activity = getActivityById(state, activityId);

    const index = activity.equipment.findIndex((equipment) => equipment.id === activityEquipment.id);
    if (index === -1) throw new Error('ActivityEquipment not found in activity');

    Vue.set(activity.equipment, index, activityEquipment);
  },
  removeActivityEquipment(
    state: CreateEditActivityState,
    { activityId, activityEquipmentId }: { activityId: string; activityEquipmentId: string },
  ) {
    const activity = getActivityById(state, activityId);

    activity.equipment = activity.equipment.filter((equipment) => equipment.id !== activityEquipmentId);
  },
  //
  // Polygon
  //
  addPolygon(state: CreateEditActivityState, data: Polygon) {
    state.polygons[data.field_key] = data;
  },
  polygonSetState(state, { key, state: polygonState }) {
    state.polygons[key].state = polygonState;
  },
  //
  // Activity
  //
  setActivity(state: CreateEditActivityState, activity: Activity) {
    Vue.set(state, 'activities', [activity]);
  },
  setActivities(state: CreateEditActivityState, activities: Activity[]) {
    Vue.set(state, 'activities', activities);
  },
  setActivityTypeId(
    state: CreateEditActivityState,
    { activityId, activityTypeId }: { activityId: string; activityTypeId: string },
  ) {
    const activity = getActivityById(state, activityId);
    activity.activityTypeId = activityTypeId;
  },
  setTimeStart(state: CreateEditActivityState, timeStart: number) {
    if (!state.activities) return;
    state.activities.forEach((activity) => {
      activity.timeStart = timeStart;
    });
  },
  setUserComment(state: CreateEditActivityState, userComment: string) {
    if (!state.activities) return;
    state.activities.forEach((activity) => {
      activity.userComment = userComment;
    });
  },
  setProcessedArea(state, { activityId, processedArea }: { activityId: string; processedArea: number }) {
    const activity = state.activities?.find((a) => a.id === activityId);
    if (!activity) return;
    activity.processedArea = processedArea;
  },
  setWorkflowKey(state: CreateEditActivityState, workflowKey: WorkflowKey) {
    state.workflowKey = workflowKey;
  },
  reset(state) {
    Object.assign(state, initialState());
  },
};

export default mutations;
