import { UNIT_ID_GRAINS_PER_HA } from '@/products/constants';

import initialStateBaseWorkflow from '../../store/baseWorkflowStore/initialState';
import { ApplicationMapsSeedingState } from './types';

export default function initialState(): ApplicationMapsSeedingState {
  return {
    ...initialStateBaseWorkflow(),
    selectedIndexType: null,
    selectedHeatmapDbId: null,
    selectedHeatmapTimestamp: null,
    selectedQuantisationCode: 'medium',
    varieties: {
      fetching: false,
      current: [],
    },
    calculation: {
      averageDosage: null,
      material: {
        id: null,
        name: '',
      },
      unit: UNIT_ID_GRAINS_PER_HA,
      variety: {
        id: null,
        name: '',
        count: 0,
      },
      kDiff: 0,
      k: 0,
      d: 0,
      areaWeightedVarietyMean: 0,
      basicSeedStrength: 70000,
      lossCompensation: 0,
      seedrateMin: 0,
      seedrateMax: 0,
      manualMinSeedrate: null,
      manualMaxSeedrate: null,
      manualSeedRates: {},
    },
  };
}
