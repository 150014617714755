
import Handsontable from 'handsontable/base';
import Vue, { PropType } from 'vue';

import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';
import { CellCoords, SubTableColumnDefinition } from '@/shared/handsontable/types';

enum keyDirection {
  ArrowLeft = 'left',
  ArrowRight = 'right',
  ArrowUp = 'up',
  ArrowDown = 'down',
}

export default Vue.extend({
  components: {
    TableBase,
    ButtonLink,
  },
  data() {
    return {
      hotInstance: null as Handsontable | null | undefined,
      tableInFocus: false,
      clickedOutside: false,
      preventSelection: false,
      enablePropagation: false,
      newRowEditorActive: true,
    };
  },
  mixins: [tableContainerBase],
  props: {
    subTableData: {
      type: Array as PropType<Array<unknown>>,
      default: () => [],
    },
    subTableColumnDefinition: {
      type: Array as PropType<Array<SubTableColumnDefinition>>,
      required: true,
    },
    columnKey: {
      type: String as PropType<String>,
      required: true,
    },
    addButtonTitle: {
      type: String as PropType<String>,
    },
    expanded: {
      type: Boolean as PropType<Boolean>,
      default: false,
    },
  },
  computed: {
    collapsedString(): string {
      // @ts-ignore
      return this.subTableData.map((value) => value.displayName).join(', ');
    },
    id(): string {
      return `hot-subtable-${this.columnKey}`;
    },
    tableBounds(): { rows: number; columns: number } {
      return {
        rows: this.subTableData.length,
        columns: this.subTableColumnDefinition.length,
      };
    },
    subTableSettings(): Handsontable.GridSettings {
      const hooks = {
        afterOnCellMouseDown: (event: MouseEvent) => this.afterOnCellMouseDown(event),
        beforeKeyDown: (event: KeyboardEvent) => this.beforeKeyDown(event),
        afterSelectionEnd: (row: number, column: number) => this.afterSelectionEnd(row, column),
        afterDeselect: () => this.afterDeselect(),
        afterChange: () => this.afterChange(),
        beforeOnCellMouseOver: (event: MouseEvent) => this.beforeOnCellMouseOver(event),
      };
      return {
        ...this.tableSettings,
        height: `${this.tableHeight}px`,
        colHeaders: false,
        observeChanges: false,
        stretchH: 'all',
        outsideClickDeselects: () => {
          if (!this.tableInFocus) {
            return true;
          }
          this.clickedOutside = true;
          return true;
        },
        ...hooks,
      };
    },

    tableDataComputed(): Record<string, unknown> {
      return this.subTableData.reduce(
        (acc: Record<string, any>, item: any) => ({
          ...acc,
          [item.id]: item,
        }),
        {},
      );
    },
    tableHeight(): number {
      const tableRowHeight = 41;
      let countRows = 0;
      if (this.subTableData?.length > 0 && this.expanded) {
        countRows = this.subTableData.length;
      }
      return (countRows + 1) * tableRowHeight;
    },
    tableRows(): number {
      return this.hotInstance?.countRows() || 0;
    },
    currentCellCoords(): CellCoords | null {
      if (!this.hotInstance) {
        return null;
      }
      // @ts-ignore
      const selectedRange = this.hotInstance.getSelected();
      if (!selectedRange) {
        return null;
      }
      return {
        row: selectedRange[0][0],
        col: selectedRange[0][1],
      };
    },
  },
  methods: {
    updateCell(changes: { guid: string; columnKey: string; value: unknown }) {
      // @ts-ignore
      const visualRow = this.$refs.table?.guidToVisualRow(changes.guid);
      this.$emit('updateCell', visualRow, changes);
      if (!this.expanded) {
        this.$emit('expand');
      }
    },
    onParentCellDoubleClick() {
      if (this.expanded) {
        return;
      }
      if (!this.subTableData || this.subTableData.length === 0) {
        this.addButtonClicked(new MouseEvent('click'));
        return;
      }
      this.$emit('expand');
    },
    removeRow(visualRow: number) {
      this.$emit('remove:row', visualRow);
    },

    selectParentTableCell(direction: string) {
      this.$emit('exitSubTable', direction);
    },

    deselectParentTableCell() {
      this.$emit('deselectParentCell');
    },

    triggerMouseEvent(type: string, target: HTMLElement) {
      const event = document.createEvent('MouseEvent');

      event.initMouseEvent(type, true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      target.dispatchEvent(event);
    },

    // this method is called from parent components
    selectFirstCell() {
      const cell = this.hotInstance?.getCell(0, 1);
      if (!cell) {
        return;
      }
      // a click event on the element has to be triggered, because a direct selection
      // of the cell via hot.select() does not work in the nested table
      this.triggerMouseEvent('mousedown', cell);
    },

    nextCellOutsideBounds(direction: string) {
      if (!this.currentCellCoords) {
        return false;
      }
      if (direction === 'left') {
        if (this.currentCellCoords.col === 1 && this.currentCellCoords.row === 0) {
          return true;
        }
      }

      if (direction === 'right') {
        if (
          this.currentCellCoords.col === this.tableBounds.columns &&
          this.currentCellCoords.row === this.tableBounds.rows - 1
        ) {
          return true;
        }
      }

      if (direction === 'up') {
        if (this.currentCellCoords.row === 0) {
          return true;
        }
      }

      if (direction === 'down') {
        if (this.currentCellCoords.row === this.tableBounds.rows - 1) {
          return true;
        }
      }
      return false;
    },

    handleArrowKeys(event: KeyboardEvent) {
      const { key } = event;
      if (!Object.keys(keyDirection).includes(key)) {
        return;
      }
      const direction = keyDirection[key as keyof typeof keyDirection];
      if (this.nextCellOutsideBounds(direction)) {
        this.hotInstance?.deselectCell();
        this.selectParentTableCell(direction);
      }
    },

    focusSubTableCell(row: number, column: number) {
      if (this.enablePropagation) {
        this.enablePropagation = false;
        return;
      }
      this.tableInFocus = true;
      const cell = this.hotInstance?.getCell(row, column);
      if (!cell) {
        return;
      }
      this.triggerMouseEvent('mousedown', cell);
    },

    afterOnCellMouseDown(event: MouseEvent) {
      if (this.propagateToTarget(event.target as Element)) {
        this.enablePropagation = true;
        return;
      }

      // prevent selection of the parent table cell to prevent the subtable cell from losing focus
      if (this.tableInFocus) {
        event.stopPropagation();
      }
    },

    propagateToTarget(target: Element | null): boolean {
      const clickableClasses: string[] = [
        'htAutocompleteArrow', // used in dropdown cells to open the dropdown
      ];
      if (!target) {
        return false;
      }

      const targetClassList = target.classList;
      return clickableClasses.some((classname) => targetClassList.contains(classname));
    },

    beforeKeyDown(event: KeyboardEvent) {
      this.handleArrowKeys(event);
    },

    afterSelectionEnd(row: number, column: number) {
      if (this.preventSelection) {
        this.hotInstance?.deselectCell();
        this.clickedOutside = false;
        this.preventSelection = false;
        return;
      }

      this.clickedOutside = false;
      this.deselectParentTableCell();
      if (!this.tableInFocus) {
        this.focusSubTableCell(row, column);
      }
    },

    afterDeselect() {
      this.tableInFocus = false;
    },

    afterChange() {
      if (this.clickedOutside) {
        this.preventSelection = true;
      }
      this.newRowEditorActive = false;
    },

    beforeOnCellMouseOver(event: MouseEvent) {
      event.stopImmediatePropagation();
    },

    subTableMounted() {
      // @ts-ignore
      this.hotInstance = this.$refs.table.hot;
    },

    openCellEditor(row: number, col: number): void {
      this.newRowEditorActive = true;
      this.hotInstance?.selectCell(row, col);
      this.hotInstance?.getActiveEditor()?.beginEditing();
    },

    addButtonClicked(event: MouseEvent): void {
      event?.stopImmediatePropagation();
      const newVisualRow = this.subTableData?.length || 0;
      // @ts-ignore
      this.$refs.table.featureInsertRowCreateGuid(newVisualRow);
      this.openCellEditor(newVisualRow, 1);
    },

    closeEditor(): void {
      if (this.newRowEditorActive) {
        this.hotInstance?.getActiveEditor()?.finishEditing();
      }
    },
  },

  created() {
    // @ts-ignore
    this.columnDefinition = this.subTableColumnDefinition;
  },
});
