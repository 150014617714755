<template>
  <div class="h-100">
    <TableBase
      id="hot-contracting-import-fields-yields-field-mapping"
      ref="table"
      disable-read-only-bg
      :filter-string="filterString"
      :table-settings="tableSettings"
      :selected-rows="selectedRows"
      :table-data="tableData"
      :loading="loading"
      :cells="tableHookCells"
      @tableMounted="tableMounted"
      @update:visibleRows="(value) => $emit('update:visibleRows', value)"
      @update:selectedRows="(value) => $emit('update:selectedRows', value)"
      @update:cell="updateCell"
      @update:filterString="(value) => $emit('update:filterString', value)"
    >
      <template #footer>
        <TableFooter
          :total-rows="Object.keys(tableData).length"
          :visible-rows="Object.keys(tableData).length"
          :selected-rows="selectedRows.length"
        >
          {{ $t('Gesamtfläche {totalArea} ha', { totalArea: sizeTotalFormatted }) }}
        </TableFooter>
      </template>
    </TableBase>
  </div>
</template>

<script>
import axios from 'axios';

import { crop as columnCrop } from '@/fields/handsontable/columns/columns';
import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import loadTableData from '@/shared/handsontable/mixins/containers/featureLoadTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import columnsTableSendFields from '../handsontable/columns/tableSendFields';

export default {
  name: 'TableSendFieldsContainer',
  components: { TableFooter, TableBase },
  mixins: [tableContainerBase, loadTableData, calculateSizeTotal],
  props: {
    filterString: {
      type: String,
      default: '',
    },
    contractId: {
      type: Number,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    alreadySentFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    const columnDefinition = columnsTableSendFields.map((column) => {
      if (column.key === columnCrop.key) {
        return {
          ...column,
          getRendererValue: ({ value }) => {
            if (this.cropsById[value] != null && typeof this.cropsById[value].name === 'string') {
              return this.cropsById[value].name;
            }
            return '';
          },
          getItems: async () => [
            {
              name: 'Crops',
              id: 'crops',
              items: this.crops,
              sort: false,
            },
          ],
        };
      }
      return column;
    });

    return {
      columnDefinition,
      loading: true,
      crops: [],
    };
  },
  computed: {
    cropsById() {
      return this.crops.reduce(
        (cropsById, crop) => ({
          ...cropsById,
          [crop.id]: crop,
        }),
        {},
      );
    },
    alreadySentFieldsById() {
      return this.alreadySentFields.reduce(
        (fieldById, field) => ({
          ...fieldById,
          [field.fieldId]: field,
        }),
        {},
      );
    },
    selectedTableData() {
      if (this.selectedRows.length === 0) {
        return null;
      }
      if (this.selectedRows.some((id) => this.tableData[id] == null || this.tableData[id][columnCrop.key] == null)) {
        return null;
      }
      return this.selectedRows.map((id) => this.tableData[id]);
    },
  },
  watch: {
    selectedTableData(newVal) {
      this.$emit('update:selectedTableData', newVal);
    },
  },
  async mounted() {
    await this.loadCrops();
    this.tableData = this.fields.reduce(
      (tableData, field) => ({
        ...tableData,
        [field.id]: field,
      }),
      {},
    );
    this.loading = false;
  },
  methods: {
    async loadCrops() {
      const { data } = await axios.get(`/admin/digitalContracting/getAllowedCrops/${this.contractId}`);
      this.crops = data;
    },
    async tableMounted() {
      tableContainerBase.methods.tableMounted.call(this);
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 30));
      if (this.selectedRows.length === 0) {
        const selectedRows = this.fields
          .filter((field) => {
            if (this.alreadySentFields.length > 0) {
              return field[columnCrop.key] !== null && this.alreadySentFieldsById[field.id] != null;
            }
            return field[columnCrop.key] !== null;
          })
          .map((field) => field.id);
        this.$emit('update:selectedRows', selectedRows);
      }
    },
    updateCell({ columnKey, guid: id, value }) {
      let columnType = 'text';
      this.tableSettings.columns.some((column) => {
        if (column.key === columnKey) {
          columnType = column.type;
          return true;
        }
        return false;
      });
      if (columnType === 'numeric' && Number.isNaN(Number(value))) {
        // eslint-disable-next-line no-param-reassign
        value = null;
      }

      if (columnKey === columnCrop.key && value !== null) {
        const data = {
          type: 'update',
          id,
          columns: { cropId: value },
        };
        axios.post('/admin/fields/submit?dataRequest', [data]);
      }

      this.tableData[id][columnKey] = value;
    },
    tableHookCells(physicalRow, physicalCol, props) {
      if (this.tableSettings.columns[physicalCol].key !== columnCrop.key) {
        return props;
      }

      const id = this.$refs.table.physicalRowToGuid(physicalRow);
      if (this.tableData[id] == null) {
        return props;
      }

      if (this.selectedRows.includes(id)) {
        return {
          ...props,
          required: true,
        };
      }
      return {
        ...props,
        required: false,
      };
    },
  },
};
</script>
