<template>
  <ThePageSkeleton
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <template #left="{ contentbarLarge }">
          <ButtonDropdown
            v-if="editMode"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonUpdateSelection"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="selectedRows = []"
          />
          <Button
            v-if="editMode"
            class="text-danger"
            color="lightest"
            icon="trash-alt"
            leading-icon
            component-namespace-name="ButtonDeleteContractedFiles"
            :title="$t('Löschen')"
            :icon-only="!contentbarLarge"
            @click="() => $refs.table.removeSelectedEntries()"
          />
          <Button
            v-show="!editMode"
            color="primary"
            icon="cloud-upload"
            leading-icon
            component-namespace-name="ButtonImportFields"
            :title="$t('Datei hochladen')"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="uploadFiles"
          />
          <Button
            color="lightest"
            icon="cloud-download"
            leading-icon
            component-namespace-name="ButtonExportManagedContracFiles"
            :title="editMode ? $t('Auswahl exportieren') : $t('Exportieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="exportData"
          />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar v-show="!editMode" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <DropdownPageOptions
            v-show="!editMode"
            class="ml-4"
            @export="exportData"
            @settings="showSettings"
            :hideRestore="true"
          />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableFilesContainer
        ref="table"
        :contractId="contractId"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
        @uploadFiles="uploadFiles"
      />
      <ModalPageSettings ref="modalPageSettings" :columns="columnsForPageSettings" />
      <ModalTableExport
        ref="modalTableExport"
        :title="selectedRows.length > 0 ? $t('Auswahl exportieren') : null"
        :filtered="selectedRows.length === 0 && $refs.table != null && !$refs.table.allVisible"
        :availableTypes="[
          {
            value: 'xls',
            label: $t('XLS'),
            text: $t('Als Excel-Datei exportieren'),
          },
        ]"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import DropdownPageOptions from '@/shared/components/DropdownPageOptions.vue';
import ModalPageSettings from '@/shared/components/ModalPageSettings.vue';
import ModalTableExport from '@/shared/components/ModalTableExport.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import TableFilesContainer from './containers/TableFilesContainer.vue';

export default {
  name: 'PageManagedContractFiles',
  components: {
    ThePageSkeleton,
    TheContentbar,
    TableFilesContainer,
    Button,
    SearchBar,
    DropdownPageOptions,
    ModalPageSettings,
    ButtonDropdown,
    ModalTableExport,
  },
  mixins: [buttonUpdateSelection('table')],

  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      columnsForPageSettings: null,
    };
  },

  computed: {
    editMode() {
      return this.selectedRows.length > 0;
    },
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_MANAGED_CONTRACTS);
    },
    contractId() {
      return this.$route.params.contractId;
    },
  },

  methods: {
    uploadFiles() {
      this.$router.push({
        name: 'digital-contracting/managed-contracts/files/upload',
        params: { contractId: this.$route.params.contractId },
      });
    },
    async exportData() {
      const { exportFiltered, okay } = await this.$refs.modalTableExport.show();
      if (!okay) {
        return;
      }

      let fieldIds = [];
      if (this.editMode) {
        fieldIds = this.selectedRows;
      } else if (exportFiltered) {
        fieldIds = this.visibleRows;
      } else {
        fieldIds = Object.values(this.$refs.table.tableData).map((file) => file.id);
      }
      this.$refs.table.$refs.table.export(fieldIds);
    },

    async showSettings() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      const settings = await this.$refs.modalPageSettings.show();
      if (settings != null && Array.isArray(settings.columns)) {
        this.$refs.table.$refs.table.updateColumnsFromPageSettings(settings.columns);
      }
    },
  },
};
</script>
