<template>
  <div class="center-control" @click="$emit('onClick')">
    <FontAwesomeIcon v-if="actionType === 'mapData'" icon="map-marked-alt" class="control-icon" />
    <FontAwesomeIcon v-else-if="actionType === 'currentLocation'" icon="location-arrow" class="control-icon" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationArrow, faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons';

library.add(faLocationArrow, faMapMarkedAlt);

export default {
  name: 'CenterMapControl',

  props: {
    actionType: {
      type: String,
      default: 'mapData',
    },
  },
};
</script>

<style scopted>
.center-control {
  display: flex;
  place-items: center;
  justify-content: center;

  position: fixed;
  bottom: 110px;
  right: 0px;
  transform: translate(-25%, -25%);
  z-index: 1;

  height: 40px;
  width: 40px;
  cursor: pointer;

  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.control-icon {
  color: #666666;
  font-size: 20px;
}
</style>
