<template>
  <WelcomeContentContainer class="step-farmer-crops" padding-top>
    <template #default>
      <MaxWidthContainer size="lg">
        <WelcomeTitle white>
          {{ $t('Welche Kulturen baust du an?') }}
        </WelcomeTitle>
      </MaxWidthContainer>
      <MaxWidthContainer class="mt-auto" grid>
        <ButtonOnboarding :active="cropsComputed.includes('agriculture')" @click="() => onClick('agriculture')">
          {{ $t('Ackerkulturen') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="cropsComputed.includes('greening')" @click="() => onClick('greening')">
          {{ $t('Grünland und Feldfutterbau') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="cropsComputed.includes('vegetables')" @click="() => onClick('vegetables')">
          {{ $t('Gemüse') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="cropsComputed.includes('herbs')" @click="() => onClick('herbs')">
          {{ $t('Heil- und Gewürzpflanzen') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="cropsComputed.includes('wine')" @click="() => onClick('wine')">
          {{ $t('Wein') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="cropsComputed.includes('fruit_and_hops')" @click="() => onClick('fruit_and_hops')">
          {{ $t('Obst und Hopfen') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="cropsComputed.includes('christmas_trees')" @click="() => onClick('christmas_trees')">
          {{ $t('Christbaumkulturen') }}
        </ButtonOnboarding>
        <ButtonOnboarding :active="cropsComputed.includes('others')" @click="() => onClick('others')">
          {{ $t('Andere') }}
        </ButtonOnboarding>
      </MaxWidthContainer>
    </template>
    <template #cta-container>
      <MaxWidthContainer>
        <Button
          variant="primary"
          size="lg"
          block
          class="my-0"
          :disabled="cropsComputed.length < 1"
          @click="$emit('next')"
        >
          {{ titleNext }}
        </Button>
      </MaxWidthContainer>
    </template>
  </WelcomeContentContainer>
</template>

<script>
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';

import imageLandscape from '../assets/FARMDOK_Onboarding_Soy_Landscape.jpg';
import image from '../assets/FARMDOK_Onboarding_Soy_Portrait.jpg';
import ButtonOnboarding from './ButtonOnboarding.vue';
import WelcomeContentContainer from './WelcomeContentContainer.vue';
import WelcomeTitle from './WelcomeTitle.vue';

export default {
  name: 'StepFarmerCrops',
  components: {
    ButtonOnboarding,
    MaxWidthContainer,
    WelcomeContentContainer,
    WelcomeTitle,
    Button,
  },
  props: {
    crops: {
      type: [Array, String, null],
      default: null,
    },
  },
  computed: {
    cropsComputed() {
      if (!Array.isArray(this.crops)) {
        return [];
      }
      return this.crops;
    },
    titleNext() {
      if (this.cropsComputed.length < 1) {
        return this.$t('Wähle zumindest 1');
      }
      return this.$t('Weiter');
    },
  },
  mounted() {
    this.$emit('background', { image, imageLandscape });
  },
  methods: {
    onClick(value) {
      if (this.cropsComputed.includes(value)) {
        this.$emit('change', { key: 'crops', value: this.cropsComputed.filter((current) => current !== value) });
        return;
      }
      this.$emit('change', { key: 'crops', value: [...this.cropsComputed, value] });
    },
  },
};
</script>

<style scoped></style>
