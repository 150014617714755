
import { PropType, defineComponent } from 'vue';

import { MergedActivityProduct } from '@/activities/modals/createEditActivity/services/MergedActivityProductsService';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';

import AmountSectionContainer from '../containers/AmountSectionContainer.vue';
import PestSelectSectionContainer from '../containers/PestSelectSectionContainer.vue';
import ProductDropdownContainer from '../containers/ProductDropdownContainer.vue';
import RemoveSectionButton from './RemoveSectionButton.vue';

export default defineComponent({
  name: 'ProductSelectSection',
  components: {
    FormFieldSet,
    PestSelectSectionContainer,
    ProductDropdownContainer,
    AmountSectionContainer,
    RemoveSectionButton,
  },
  props: {
    selectedProduct: {
      type: Object as PropType<DropdownItem | null>,
      required: false,
    },
    activityProduct: {
      type: Object as PropType<MergedActivityProduct>,
      required: true,
    },
    fieldNamesPerActivity: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },

  computed: {
    headline(): string {
      if (this.selectedProduct) {
        if (this.activityProduct.activityIds.length !== Object.keys(this.fieldNamesPerActivity).length) {
          if (this.activityProduct.activityIds.length === 1) {
            return `${this.selectedProduct.name} (${this.fieldNamesPerActivity[this.activityProduct.activityIds[0]]})`;
          }
          return `${this.selectedProduct.name} (${this.$t('verschiedene')})`;
        }

        return this.selectedProduct.name;
      }
      return this.$t('Produkt') as string;
    },
  },
  methods: {
    remove(): void {
      this.$emit('remove:activityProduct', this.activityProduct.id);
    },
  },
});
