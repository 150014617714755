
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCloud as faCloudDuotone } from '@fortawesome/pro-duotone-svg-icons';
import { PropType, defineComponent } from 'vue';
import { Day, DisabledDates, HighlightedDates } from 'vuejs-datepicker';

import FormFieldDatepicker from '@/shared/components/form/FormFieldDatepicker.vue';
import iconAsSvgString from '@/shared/modules/iconAsSvgString';

library.add(faCloudDuotone);
const cloudIcon = iconAsSvgString(faCloudDuotone);

export default defineComponent({
  name: 'Datepicker',
  components: {
    FormFieldDatepicker,
  },
  model: {
    prop: 'selectedDate',
    event: 'setSelectedDate',
  },
  props: {
    selectedDate: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    cloudyDates: {
      type: Object as PropType<HighlightedDates>,
      default: undefined,
    },
    disabledDates: {
      type: Object as PropType<DisabledDates>,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dayCellContent(day: Day) {
      if (day.isHighlighted) {
        return `${day.date}${cloudIcon}`;
      }

      return day.date;
    },
  },
});
