import Vue from 'vue';
import { MutationTree } from 'vuex';

import { ENTRY_ERROR_UPDATING, ENTRY_NEW, ENTRY_REMOVING, ENTRY_TO_REMOVE, ERROR_UPDATING } from '@/shared/constants';
import { DataEntry, RemovableDataState } from '@/shared/mixins/store/types';

const mutations: MutationTree<RemovableDataState<DataEntry>> = {
  /**
   * Call this before sending (bulk) delete to the BE.
   * Update the storeStatus for all given entries to ENTRY_REMOVING.
   * Additionally, set state.removing to true.
   * Removes errors for all entries that get deleted.
   *
   * @param state
   * @param entries
   */
  syncAllRemovesStart(state, entries: DataEntry[]) {
    Vue.set(state, 'removing', true);
    Vue.set(
      state,
      'data',
      entries.reduce((currentState, entry) => {
        if (currentState[entry.id] == null) {
          return currentState;
        }
        return {
          ...currentState,
          [entry.id]: {
            ...currentState[entry.id],
            storeStatus: ENTRY_REMOVING,
          },
        };
      }, state.data),
    );
    const entryIds = entries.map(({ id }) => id);
    Vue.set(
      state,
      'errors',
      state.errors.filter((error) => !entryIds.includes(error.id)),
    );
  },

  /**
   * Call this after successful (bulk) delete.
   * Updates all given entries in the store.
   * Additionally, set state.syncing to false.
   *
   * @param state
   * @param entries
   */
  syncAllRemovesFinish(state, entries) {
    if (!state.removing) {
      return;
    }
    Vue.set(state, 'removing', false);
    if (!Array.isArray(entries)) {
      return;
    }
    const data = { ...state.data };
    entries.forEach(({ id }) => {
      delete data[id];
    });
    Vue.set(state, 'data', data);
  },

  /**
   * Add a callback that is called as soon as all entries are synced or an error occurred.
   *
   * @param state
   * @param callback
   */
  addRemovableObserver(state, callback) {
    Vue.set(state, 'removableObservers', [...state.removableObservers, callback]);
  },

  /**
   * Resolve and clear all current callbacks. Call this after all entries are synced or an error occurred.
   *
   * @param state
   */
  clearRemovableObservers(state) {
    if (state.removableObservers && state.removableObservers.length > 0) {
      state.removableObservers.forEach((callback) => callback());
    }
    Vue.set(state, 'removableObservers', []);
  },

  /**
   * Set an entry to 'removed' if it's new or 'to_remove' otherwise.
   * 'to_remove' entries have to be synced to the BE.
   *
   * @param state
   * @param entry
   */
  removeEntry(state, { entry }) {
    if (state.data[entry.id] == null) {
      return;
    }
    if (state.data[entry.id].storeStatus === ENTRY_NEW) {
      const data = { ...state.data };
      delete data[entry.id];
      Vue.set(state, 'data', data);
    } else {
      Vue.set(state, 'data', {
        ...state.data,
        [entry.id]: {
          ...state.data[entry.id],
          storeStatus: ENTRY_TO_REMOVE,
        },
      });
    }
  },

  /**
   * Add error into store.
   *
   * @param state
   * @param guid
   * @param key
   * @param errorUserMessage
   */
  addRemovalError(state, { guid, key, errorUserMessage }) {
    Vue.set(state, 'data', {
      ...state.data,
      [guid]: {
        ...state.data[guid],
        storeStatus: ENTRY_ERROR_UPDATING,
      },
    });
    Vue.set(state, 'errors', [
      ...state.errors,
      {
        type: ERROR_UPDATING,
        guid,
        key,
        errorUserMessage,
      },
    ]);
  },
};

export default mutations;
