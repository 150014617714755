import { ActionTree } from 'vuex';

import { TimestampsGpsState } from '@/activities/gpsLog/store/types';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';

const actions: ActionTree<TimestampsGpsState, RootState> = {
  ...subscribableStore.actions,
};

export default actions;
