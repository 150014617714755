<template>
  <ThePageSkeleton>
    <template #contentbar>
      <TheContentbar>
        <template #left>
          <ImgPartnerLogo
            v-if="contract.imagePartnerLogo != null"
            :src="contract.imagePartnerLogo"
            :width="100"
            :height="55"
          />
        </template>
        <template #middle>
          <Steps :current="currentStepKey" :steps="steps" />
        </template>
        <template #right>
          <SearchBar class="mr-1" :searchFilter.sync="filterString" />
          <ButtonWithArrow variant="lightest" type="left" class="mr-1" @click="onBack">
            {{ $t('Zurück') }}
          </ButtonWithArrow>
          <span
            id="page-import-contract__button-next-tooltip"
            class="d-inline-block mr-1"
            tabindex="0"
            v-b-tooltip.bottomleft.viewport="{ container: '#the-contentbar', trigger: false }"
            :title="nextButtonTooltip"
            @mouseenter="$root.$emit('bv::show::tooltip', 'page-import-contract__button-next-tooltip')"
            @mouseleave="$root.$emit('bv::hide::tooltip', 'page-import-contract__button-next-tooltip')"
          >
            <ButtonWithArrow
              v-if="!nextButtonEnabled"
              variant="primary"
              class="page-import-contract__button-next"
              disabled
              style="pointer-events: none"
              @click="onNext()"
            >
              {{ currentStepKey === 0 ? $t('Maßnahmen buchen') : $t('Maßnahmen speichern') }}
            </ButtonWithArrow>
            <ButtonWithArrow
              v-else
              variant="primary"
              class="page-import-contract__button-next mr-1"
              :disabled="creatingMeasures"
              @click="onNext()"
            >
              {{ currentStepKey === 0 ? $t('Maßnahmen buchen') : $t('Maßnahmen speichern') }}
              <FontAwesomeIcon v-if="creatingMeasures" icon="circle-notch" spin />
            </ButtonWithArrow>
          </span>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <FetchingError v-if="fetchingError" :user-message="errorUserMessage" @reload="reload" />
      <Loading v-if="!fetchingError && fetchingYields && totalYields === null && fieldYields === null" />
      <div
        v-if="!fetchingError && !fetchingYields && totalYields === null && fieldYields === null"
        class="page-import-contract__no_content"
      >
        <p v-html="$t('Noch keine Erntedaten für <strong>{name}</strong> verfügbar.', contract)" />
      </div>
      <TableImportTotalYieldsContainer
        v-if="!fetchingError && totalYields != null && currentStepKey === 0"
        id="import-total-yields-container"
        :total-yields="totalYields"
        :filter-string.sync="filterString"
        :visible-rows.sync="visibleRows"
      />
      <TableImportFieldYieldsContainer
        v-if="!fetchingError && fieldYields != null && currentStepKey === 0"
        id="import-field-yields-container"
        :fields-yields="fieldYields"
        :filter-string.sync="filterString"
        :visible-rows.sync="visibleRows"
        :selected-rows.sync="selectedRows"
        @update:selectedTableData="fieldsMappingData = $event"
      />
      <TableImportTotalYieldsFieldMappingContainer
        v-if="!fetchingError && totalYields != null && currentStepKey === 1"
        id="import-total-yields-field-mapping-container"
        :contract="contract"
        :total-yields="totalYields"
        :filter-string.sync="filterString"
        :visible-rows.sync="visibleRows"
        :selected-rows.sync="selectedRows"
        @update:selectedTableData="importMeasuresData = $event"
      />
      <TableImportFieldsYieldsFieldMappingContainer
        v-if="!fetchingError && fieldYields != null && fieldsMappingData && currentStepKey === 1"
        id="import-field-yields-field-mapping-container"
        :contract="contract"
        :fields-mapping-data="fieldsMappingData"
        :filter-string.sync="filterString"
        :visible-rows.sync="visibleRows"
        @update:selectedTableData="importMeasuresData = $event"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import Loading from '@/shared/components/Loading.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Steps from '@/shared/components/Steps.vue';
import ButtonWithArrow from '@/shared/components/buttons/ButtonWithArrow.vue';
import FetchingError from '@/shared/handsontable/components/FetchingError.vue';

import ImgPartnerLogo from './components/ImgPartnerLogo.vue';
import TableImportFieldYieldsContainer from './containers/TableImportFieldYieldsContainer.vue';
import TableImportFieldsYieldsFieldMappingContainer from './containers/TableImportFieldsYieldsFieldMappingContainer.vue';
import TableImportTotalYieldsContainer from './containers/TableImportTotalYieldsContainer.vue';
import TableImportTotalYieldsFieldMappingContainer from './containers/TableImportTotalYieldsFieldMappingContainer.vue';
import {
  amount as columnAmount,
  creationDate as columnCreationDate,
  processedArea as columnProcessedArea,
  userComment as columnUserComment,
} from './handsontable/columns/columns';
import { fieldIdKey, materialKey } from './handsontable/columns/tableImportTotalYieldsFieldMapping';

library.add(faCircleNotch);

export default {
  name: 'PageImportContract',
  components: {
    Loading,
    TableImportFieldYieldsContainer,
    TableImportTotalYieldsContainer,
    TableImportTotalYieldsFieldMappingContainer,
    TableImportFieldsYieldsFieldMappingContainer,
    Steps,
    ThePageSkeleton,
    FetchingError,
    SearchBar,
    TheContentbar,
    ImgPartnerLogo,
    ButtonWithArrow,
  },
  data() {
    return {
      // steps
      currentStepKey: 0,
      steps: [
        {
          number: '1',
          label: this.$t('Erntedaten'),
        },
        {
          number: '2',
          label: this.$t('Maßnahmen'),
        },
      ],

      // tables
      fetchingYields: false,
      fetchingError: false,
      defaultErrorUserMessage: this.$t('Unbekannter Fehler'),
      errorUserMessage: this.defaultErrorUserMessage,
      filterString: '',
      visibleRows: [],
      fieldYields: null,
      totalYields: null,
      selectedRows: [],
      importMeasuresData: null,
      fieldsMappingData: null,
      creatingMeasures: false,
    };
  },
  computed: {
    contract() {
      let contract = {};
      Object.values(this.$store.state.digitalContracting.data).some((current) => {
        if (Number(current.contractCompanyId) === Number(this.$route.params.contractCompanyId)) {
          contract = current;
          return true;
        }
        return false;
      });
      return contract;
    },
    contractCompanyId() {
      return this.$route.params.contractCompanyId;
    },
    nextButtonEnabled() {
      if (this.totalYields != null) {
        if (this.currentStepKey === 0) {
          return true;
        }
        return this.importMeasuresData != null;
      }
      if (this.fieldYields != null) {
        if (this.currentStepKey === 0 && this.fieldsMappingData != null) {
          return true;
        }
        return this.importMeasuresData != null;
      }
      return false;
    },
    nextButtonTooltip() {
      if (this.currentStepKey === 0) {
        if (this.fieldYields != null && this.fieldYields.length > 0) {
          return this.$t(
            'Willst du Ernte-Maßnahmen in FARMDOK erstellen? Aktiviere die Kontrollkästchen in der ersten Spalte, dann klicke hier um zum nächsten Schritt zu gehen.',
          );
        }
        if (this.totalYields != null) {
          return this.$t(
            'Die Gesamt-Erntemengen wurden von {partner} geladen. Klicke hier, um im nächsten Schritt Maßnahmen auf deinen Feldern zu erstellen.',
            this.contract,
          );
        }
      }
      if (this.currentStepKey === 1) {
        if (this.fieldYields != null && this.fieldYields.length > 0) {
          return this.$t('Vervollständige die Ernte-Maßnahmen und klicke dann hier, um sie in FARMDOK zu buchen.');
        }
        if (this.totalYields != null) {
          return this.$t(
            'Wähle die Felder, auf denen du Ernte-Maßnahmen erstellen möchtest, vervollständige die Informationen und klicke dann hier, um zu speichern.',
          );
        }
      }
      return '';
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    onBack() {
      if (this.currentStepKey > 0) {
        this.currentStepKey -= 1;
        return;
      }
      this.$router.push({ name: 'digital-contracting/my-contracts' });
    },
    onNext() {
      if (this.currentStepKey === 0) {
        this.currentStepKey += 1;
        this.$root.$emit('bv::disable::tooltip', 'page-import-contract__button-next-tooltip');
        this.$root.$emit('bv::hide::tooltip', 'page-import-contract__button-next-tooltip');
        setTimeout(() => {
          this.$root.$emit('bv::enable::tooltip', 'page-import-contract__button-next-tooltip');
          this.$root.$emit('bv::show::tooltip', 'page-import-contract__button-next-tooltip');
        }, 2000);
        return;
      }
      this.createActivities();
    },
    reload() {
      // If an error occurs on step 1, this means, that it happened, during measures creation attempt.
      // If everything functions normally, this shouldn't happen at all. So offer the customer a chance to refresh the page.
      if (this.currentStepKey === 1) {
        window.location.reload();
      }
      this.loadData();
    },
    async loadData() {
      this.fetchingYields = true;
      this.fetchingError = false;
      this.errorUserMessage = this.defaultErrorUserMessage;

      try {
        await this.$store.dispatch('digitalContracting/subscribe');
        const { data } = await axios.get(`/admin/digitalContracting/agrana/getFieldsYield/${this.contractCompanyId}`);
        if (data.fields != null) {
          this.fieldYields = data.fields.length > 0 ? data.fields : null;
        } else {
          this.totalYields = data.total;
        }
        this.fetchingYields = false;
        setTimeout(() => this.$root.$emit('bv::show::tooltip', 'page-import-contract__button-next-tooltip'), 2000);
      } catch (e) {
        if (e.response != null && e.response.data != null && typeof e.response.data.userMessage === 'string') {
          this.errorUserMessage = e.response.data.userMessage;
        }
        console.error('unable to load data contract data', e);
        this.fetchingError = true;
        this.fetchingYields = false;
      }
    },
    async createActivities() {
      this.creatingMeasures = true;
      const data = {
        version: '2.0',
        data: this.importMeasuresData.map((entry) => ({
          fieldId: Number(entry[fieldIdKey]),
          processedArea: Number(entry[columnProcessedArea.key]),
          cropId: Number(entry[materialKey]),
          amount: entry[columnAmount.key] !== null ? Number(entry[columnAmount.key]) : 0,
          creationDate: Number(entry[columnCreationDate.key]),
          userComment: entry[columnUserComment.key],
        })),
      };
      try {
        await axios.post('/admin/digitalContracting/agrana/createHarvestingMeasures', data);
        await this.$store.dispatch('auth/setCurrentProcessOrderName', this.contract.processOrderName);
        await this.$router.push({ name: 'activities' });
      } catch (e) {
        this.creatingMeasures = false;
        this.fetchingError = true;
        if (e.response != null && e.response.data != null && typeof e.response.data.userMessage === 'string') {
          this.errorUserMessage = e.response.data.userMessage;
        }
      }
    },
  },
};
</script>

<style scoped>
.page-import-contract__button-next {
  padding-left: 2rem;
  padding-right: 2rem;
}
.page-import-contract__no_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(40 * var(--vh, 1vh));
}
</style>
