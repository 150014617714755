import Handsontable from 'handsontable';
import HandsontableInstance from 'handsontable/base';
import Vue, { Component } from 'vue';

import NumberIndicator from '@/shared/components/NumberIndicator.vue';
import SubTable from '@/shared/handsontable/components/SubTable.vue';
import { CellCoords } from '@/shared/handsontable/types';

export const currentCellMetadata = (
  instance: HandsontableInstance,
): { coords: CellCoords; type: string | undefined } | null => {
  const selected = instance.getSelected();
  if (!selected) {
    return null;
  }
  const currentCellCoords = { row: selected[0][0], col: selected[0][1] };
  const { type } = instance.getCellMeta(currentCellCoords.row, currentCellCoords.col);
  return {
    coords: currentCellCoords,
    type,
  };
};

export const selectNeighborCell = (
  direction: string,
  visualRow: number,
  visualCol: number,
  instance: HandsontableInstance,
) => {
  const neighborCell: Record<string, CellCoords> = {
    left: { row: visualRow, col: visualCol - 1 },
    right: { row: visualRow, col: visualCol + 1 },
    up: { row: visualRow > 0 ? visualRow - 1 : 0, col: visualCol },
    down: { row: visualRow + 1, col: visualCol },
  };
  if (!direction || !neighborCell[direction]) {
    return;
  }
  instance.selectCell(neighborCell[direction].row, neighborCell[direction].col);
};

export const appendSubTable = (
  values: Array<unknown>,
  cellProperties: Handsontable.CellProperties,
  TD: HTMLTableCellElement,
  visualRow: number,
  expanded: boolean,
): Component => {
  const SubTableClass = Vue.extend(SubTable);
  const subTable = new SubTableClass({
    propsData: {
      subTableData: values,
      subTableColumnDefinition: cellProperties.subTableColumns(visualRow),
      columnKey: `${visualRow}-${cellProperties.key}`,
      addButtonTitle: cellProperties.addButtonTitle,
      expanded,
    },
  });
  subTable.$mount();
  TD.appendChild(subTable.$el);
  return subTable as Component;
};

export const appendNumberIndicator = (
  values: Array<unknown>,
  TD: HTMLTableCellElement,
  onClick: (event: Event) => void,
) => {
  const IndicatorClass = Vue.extend(NumberIndicator);
  const indicator = new IndicatorClass({
    propsData: {
      value: values.length,
      onClick,
    },
  });
  indicator.$mount();
  indicator.$el.classList.add('subtable__pill');
  TD.append(indicator.$el);
};
