import { ENTRY_DIRTY, ENTRY_ERROR_UPDATING, ENTRY_UPDATING, ERROR_UPDATING, GUID_KEY } from '@/shared/constants';
import { setDescendantProp } from '@/shared/modules/getDisplayValue';

import table from './tableBase';

/**
 * This mixin emits updates from handsontable to the container + handles displaying of updates and errors.
 * Requires a store module that uses shared/mixins/store/subscribableData.js
 *
 * @param storeName {string}
 * @return {object}
 */
export default {
  data() {
    return {
      cellError: null,
      rowError: null,
    };
  },
  mounted() {
    this.addHotHook('afterRenderer', this.featureEditableTableDataAfterRenderer);
    this.addHotHook('beforeOnCellMouseOver', this.featureEditableTableDataBeforeOnCellMouseOver);
    this.addHotHook('afterChange', this.featureEditableTableDataAfterChange);
    this.tableHooks.cells.push(this.featureEditableTableDataCells);
  },
  methods: {
    property(columnKey) {
      return (entry, value) => {
        if (typeof value === 'undefined') {
          return table.methods.property.call(this, columnKey)(entry);
        }

        // after creating a new row a GUID has to be created and added to the row
        if (columnKey === GUID_KEY) {
          if (entry[GUID_KEY] == null) {
            // eslint-disable-next-line no-param-reassign
            entry[GUID_KEY] = value;
          }
          return null;
        }

        const guid = entry[GUID_KEY];
        this.$emit('update:cell', { guid, columnKey, value });

        const tableData = {
          ...this.tableData,
          [guid]: { ...this.tableData[guid] },
        };
        setDescendantProp(tableData[guid], columnKey, value);
        this.$emit('update:tableData', tableData);

        return null;
      };
    },
    featureEditableTableDataCells(physicalRow, physicalCol, currentProps) {
      const guid = this.physicalRowToGuid(physicalRow);
      const column = this.tableSettingsComputed.columns[physicalCol];
      const cellHasError = this.tableErrorsComputed.some((error) => error.guid === guid && error.key === column.key);
      if (!cellHasError || ['checkbox'].includes(column.type) || currentProps.readOnly) {
        return currentProps;
      }
      return {
        ...currentProps,
        renderer: 'error',
      };
    },
    /**
     * Adds status classes to cells where necessary.
     *
     * @param {HTMLElement} td
     * @param {number} visualRow
     * @param {number} visualCol
     */
    featureEditableTableDataAfterRenderer(td, visualRow, visualCol) {
      const guid = this.visualRowToGuid(visualRow);
      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      if (this.tableData[guid] == null) {
        return;
      }
      const { storeStatus } = this.tableData[guid];

      if (storeStatus === ENTRY_UPDATING) {
        td.classList.add('updating');
      } else if (storeStatus === ENTRY_DIRTY) {
        td.classList.add('dirty');
      } else if (storeStatus === ENTRY_ERROR_UPDATING) {
        td.classList.add('error');
      }

      if (
        this.tableErrorsComputed.some(
          (error) =>
            error.type === ERROR_UPDATING &&
            error.guid === guid &&
            (error.key == null || error.key === this.tableSettingsComputed.columns[physicalCol].key),
        )
      ) {
        td.classList.add('error');
      }
    },
    /**
     * Adds status classes to cells where necessary.
     *
     * @param {UIEvent} event
     * @param {number} visualRow
     * @param {number} visualCol
     * @param {HTMLElement} td
     */
    featureEditableTableDataBeforeOnCellMouseOver(event, { row: visualRow, col: visualCol }, td) {
      this.cellError = null;
      this.rowError = null;

      const guid = this.visualRowToGuid(visualRow);
      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      if (this.tableData[guid] == null) {
        return;
      }

      const hasCellError = this.tableErrorsComputed.some((error) => {
        if (error.guid !== guid || error.key !== this.tableSettingsComputed.columns[physicalCol].key) {
          return false;
        }

        // Add cellError in nextTick to force Vue.js to remove the BTooltip component and re-create it
        // This is necessary as the BTooltip target can only be set on creation of the component
        this.$nextTick(() => {
          this.cellError = {
            target: td,
            errorUserMessage: error.errorUserMessage,
          };
        });
        return true;
      });
      if (hasCellError) {
        return;
      }

      this.tableErrorsComputed.some((error) => {
        if (error.guid !== guid || error.key != null) {
          return false;
        }

        // Add cellError in nextTick to force Vue.js to remove the BTooltip component and re-create it
        // This is necessary as the BTooltip target can only be set on creation of the component
        this.$nextTick(() => {
          this.rowError = {
            target: td,
            errorUserMessage: error.errorUserMessage,
          };
        });
        return true;
      });
    },
    /**
     * #144 Select the current cell to force re-initialize of copy-paste plugin
     *
     * @param {Array|null} changes
     * @param {String|undefined} source
     */
    featureEditableTableDataAfterChange(changes, source) {
      if (source !== 'edit' || !Array.isArray(changes)) {
        return;
      }
      const [visualRow, prop] = changes[0];
      const visualCol = this.hot.propToCol(prop);
      this.$nextTick(() => {
        this.hot.selectCell(visualRow, visualCol);
      });
    },
  },
};
