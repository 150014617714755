
import { type PropType, defineComponent } from 'vue';

import InitI18n from '@/initI18n';

import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import { type DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

const t = InitI18n.translate;

export default defineComponent({
  name: 'ControlSatelliteIndex',
  components: { FormFieldDropdown },
  props: {
    selectedIndex: {
      type: Object as PropType<DropdownItem<{ id: string; name: string }>>,
      required: false,
      default: () => ({ id: '', name: '' }),
    },
  },
  emits: ['update:selectedIndex'],
  model: {
    prop: 'selectedIndex',
    event: 'update:selectedIndex',
  },
  data(): { indexTypes: DropdownItem[]; dropdownItems: DropdownItemsPerCategory[] } {
    const items = [
      { id: 'DNN_NDVI', name: t('NDVI - Biomasse')! },
      { id: 'REIP', name: t('REIP - Stickstoffgehalt')! },
      { id: 'DNN_CIRE', name: t('CIre - Chlorophyll')! },
      { id: 'DNN_NDWI', name: t('NDWI - Wassergehalt')! },
      { id: 'DNN_LAI', name: t('LAI - Blattflächenindex')! },
      { id: 'MBI', name: t('MBI - Mehrjährige Biomasse')! },
    ];
    return {
      indexTypes: items,
      dropdownItems: [
        {
          items,
        },
      ],
    };
  },
});
