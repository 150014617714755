<template lang="html">
  <div class="map__map-actions map-actions" :style="{ left: position.x + 'px', top: position.y + 'px' }">
    <span
      v-if="showSave"
      v-b-tooltip.hover.bottom="{ title: titleSave }"
      class="map-actions__button bg-white text-primary"
      @click="$emit('action:save')"
    >
      <FontAwesomeIcon :icon="iconSave" class="fill-primary" />
    </span>
    <span
      v-if="showPolygon"
      v-b-tooltip.hover.bottom="{ title: $t('Feldkontur zeichnen') }"
      class="map-actions__button bg-white"
      @click="$emit('action:polygon')"
    >
      <FontAwesomeIcon icon="draw-polygon" class="text-secondary" />
    </span>
    <span
      v-if="showRemovePolygon"
      v-b-tooltip.hover.bottom="{ title: $t('Feldkontur löschen') }"
      class="map-actions__button bg-white position-relative"
      @click="$emit('action:removePolygon')"
    >
      <FontAwesomeIcon icon="draw-polygon" class="map-actions__icon--draw-polygon position-absolute" />
      <FontAwesomeIcon icon="slash" class="position-absolute text-white" size="3x" />
      <FontAwesomeIcon icon="slash" class="position-absolute text-black" />
    </span>
    <span
      v-if="showDiscard"
      v-b-tooltip.hover.bottom="{ title: titleDiscard }"
      class="map-actions__button bg-white"
      @click="$emit('action:discard')"
    >
      <FontAwesomeIcon icon="times" class="text-danger" />
    </span>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faDrawPolygon, faSlash, faTimes } from '@fortawesome/pro-solid-svg-icons';

library.add(faCheck, faDrawPolygon, faTimes, faSlash);

export default {
  props: {
    position: {
      type: Object,
      default() {
        return {
          x: 0,
          y: 0,
        };
      },
    },
    showSave: {
      type: Boolean,
      default: true,
    },
    showPolygon: {
      type: Boolean,
      default: true,
    },
    showRemovePolygon: {
      type: Boolean,
      default: false,
    },
    showDiscard: {
      type: Boolean,
      default: true,
    },
    titleSave: {
      type: String,
      default() {
        return this.$t('Speichern');
      },
    },
    titleDiscard: {
      type: String,
      default() {
        return this.$t('Verwerfen');
      },
    },
    iconSave: {
      type: [String, Array],
      default: 'check',
    },
  },
};
</script>

<style scoped>
.map-actions {
  position: absolute;
  transform: translate(-50%, 15px);
  display: flex;
}

.map-actions__button {
  display: grid;
  overflow: hidden;

  width: 25px;
  height: 25px;
  margin: 3px;

  place-items: center;

  cursor: pointer;
  border-radius: 50%;

  box-shadow: 0 25px 50px rgba(0, 0, 50, 0.3), 0 8px 20px rgba(0, 0, 50, 0.3), 0 5px 7px rgba(0, 0, 0, 0.3);
}

.map-actions__icon--draw-polygon {
  color: var(--gray_600);
}
</style>
