<template>
  <div class="form-info-container">
    <h3>{{ headline }}</h3>
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'FormInfoContainer',
  props: {
    headline: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.form-info-container {
  display: flow-root;
  margin: var(--spacer_2) 0;
  padding: 0 var(--spacer_3);
}
</style>
