<template>
  <BaseFormField type="checkbox" :label="label" :variant="variant">
    <BFormCheckboxGroup :stacked="stacked" :checked="checked" @change="change" @input="input">
      <!--
        @slot
        The rendered content inside the FormCheckboxGroup. 
        Should contain a list of FormCheckboxes and replaces the options prop.
      -->
      <slot name="default">
        <FormCheckbox v-for="option in options" :key="option.value" :value="option.value" :disabled="option.disabled">
          {{ option.text }}
        </FormCheckbox>
      </slot>
    </BFormCheckboxGroup>
  </BaseFormField>
</template>

<script>
import { BFormCheckboxGroup } from 'bootstrap-vue';

import BaseFormField from './BaseFormField.vue';
import FormCheckbox from './FormCheckbox.vue';

/**
 * Use this component for a checkbox group. Ideally placed inside a FieldSet.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <div style="padding: 10px;">
 *   <FormFieldCheckboxGroup
 *     label="This is a group"
 *     :checked="['first', 'last']"
 *   >
 *     <FormCheckbox value="first">
 *       A checkbox.
 *     </FormCheckbox>
 *     <FormCheckbox switch value="second">
 *       A switch.
 *     </FormCheckbox>
 *     <FormCheckbox disabled value="last">
 *       A disabled checkbox.
 *     </FormCheckbox>
 *   </FormFieldCheckboxGroup>
 *   <FormFieldCheckboxGroup
 *     label="A vertical group with options i/o slot"
 *     variant="vertical"
 *     :options="[
 *       { value: 'first', text: 'first' },
 *       { value: 'second', text: 'second' },
 *       { value: 'third', text: 'third' },
 *     ]"
 *     :checked="['first', 'second']"
 *   />
 * </div>
 */
export default {
  name: 'FormFieldCheckboxGroup',
  components: { BFormCheckboxGroup, FormCheckbox, BaseFormField },
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    /**
     * If variant is 'no-label' this can be left empty as it will not be rendered anyways.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Supported types:
     *  <table style="width:30%;">
     *    <tr><td>'horizontal'</td><td>Label to the left.</td></tr>
     *    <tr><td>'vertical'</td><td>Label above. Increased margin bottom.</td></tr>
     *    <tr><td>'vertical-lg'</td><td>Label above. Increases margin bottom and input paddings.</td></tr>
     *    <tr><td>'no-label'</td><td>No label.</td></tr>
     * </table>
     */
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical', 'vertical-lg', 'no-label'].includes(value),
    },
    /**
     * List of checkboxes to render. Overwritten by default slot.
     */
    options: {
      type: Array,
      default: null,
    },
    /**
     * The list of the checked checkboxes (actually the list of their values).
     */
    checked: {
      type: Array,
      default: null,
    },
    /**
     * When set, renders the checkbox group in stacked mode.
     */
    stacked: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /**
     * Emitted when selected value(s) is changed due to user interaction.<br>
     * Also used to update the model.
     *
     * @event FormFieldCheckboxGroup#input
     * @type {Array} value
     */
    input(value) {
      this.$emit('input', value);
    },
    /**
     * Emitted when the selected value(s) is changed.
     *
     * @event FormFieldCheckboxGroup#change
     * @type {Array} value
     */
    change(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style scoped></style>
