var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BFormRadio',{staticClass:"form-radio",class:_vm.classes,attrs:{"value":_vm.value,"checked":_vm.isInGroup ? null : _vm.checked,"disabled":_vm.disabled},on:{"change":_vm.change,"input":_vm.input},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}}},[_c('FontAwesomeIcon',{staticClass:"form-radio__icon",class:[
      `form-radio__icon--${_vm.size}`,
      `form-radio__icon--${_vm.variant}`,
      { 'form-radio__icon--checked': _vm.checkedComputed },
    ],attrs:{"icon":_vm.checkedComputed ? ['far', 'dot-circle'] : ['far', 'circle']}}),(_vm.$slots.image != null)?_c('span',{staticClass:"form-radio__image",class:[
      `form-radio__image--${_vm.size}`,
      `form-radio__image--${_vm.variant}`,
      { 'form-radio__image--checked': _vm.checkedComputed },
    ]},[_vm._t("image")],2):_vm._e(),_c('span',{staticClass:"form-radio__title",class:[
      `form-radio__title--${_vm.size}`,
      `form-radio__title--${_vm.variant}`,
      { 'form-radio__title--checked': _vm.checkedComputed },
    ]},[_vm._t("default")],2),(_vm.$slots.text != null)?_c('span',{staticClass:"form-radio__text",class:[
      `form-radio__text--${_vm.size}`,
      `form-radio__text--${_vm.variant}`,
      { 'form-radio__text--checked': _vm.checkedComputed },
    ]},[_vm._t("text")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }