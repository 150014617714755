
import numbro from 'numbro';
import { PropType, defineComponent } from 'vue';

import { combinedNameExclCrop } from '@/fields/handsontable/columns/columns';
import { Product } from '@/shared/api/rest/models';
import { Field, FieldContour } from '@/shared/api/rest/models/field';
import MapItemTooltip from '@/shared/components/map/MapItemTooltip.vue';
import { GOOGLE_MAPS_SETTINGS } from '@/shared/constants';
import googleMapsApi from '@/shared/modules/googleMapsApi';

import { FieldInfo, Fields, PolygonHoveredData, PolygonStates, Polygons } from './types';

let google: null | any = null;

export default defineComponent({
  name: 'MapSelectFields',
  components: { MapItemTooltip },
  model: {
    prop: 'selectedFields',
    event: 'update:selectedFields',
  },
  props: {
    fields: {
      type: Object as PropType<Fields>,
      default: () => {},
      required: true,
    },
    selectedFields: {
      type: Array as PropType<Array<Field['id']>>,
      default: () => [],
      required: true,
    },
    polygons: {
      type: Object as PropType<Polygons>,
      default: () => {},
      required: true,
    },
    crops: {
      type: Object as PropType<Record<string, Product>>,
      required: true,
    },
    selectDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null as null | google.maps.Map,
      polygonHovered: null as null | string,
      googleMapsSettings: GOOGLE_MAPS_SETTINGS,
    };
  },
  watch: {
    fieldsSelected(selectedKeys, oldSelectedKeys) {
      this.fieldsSelectedOnUpdate(selectedKeys, oldSelectedKeys);
      this.polygonsRedraw();
      this.$emit('update:selectedFieldsData', this.selectedFieldsData);
    },
    polygons() {
      this.polygonsRedraw();
    },
  },
  computed: {
    fieldsSelected: {
      get(): string[] {
        return this.selectedFields;
      },
      set(selected: Field['id']) {
        this.$emit('update:selectedFields', selected);
      },
    },
    selectedFieldsData(): Fields {
      return Object.entries(this.fields)
        .filter(([key]) => this.selectedFields.includes(key))
        .reduce<Fields>((fields, [key, value]) => {
          // eslint-disable-next-line no-param-reassign
          fields[key] = value;
          return fields;
        }, {});
    },
    polygonHoveredData(): PolygonHoveredData {
      if (this.polygonHovered == null || this.polygons[this.polygonHovered] == null) {
        return {
          color: '',
          name: '',
          crop: '',
          size: '',
        };
      }
      const { crop: cropId, lineChartColor: color, name } = this.polygons[this.polygonHovered];
      let cropName = '';
      if (cropId) {
        cropName = this.crops[cropId]?.name ?? '';
      }
      let { size }: { size: string | number } = this.polygons[this.polygonHovered];

      if (size == null && google != null) {
        size = google.maps.geometry.spherical.computeArea(this.polygons[this.polygonHovered].getPath()) / 10000;
      }
      size = this.$t('{size} ha', { size: numbro(size).format() }) || '';
      return {
        color,
        name,
        crop: cropName,
        size,
      };
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    async initMap(): Promise<void> {
      google = await googleMapsApi();
      if (google == null) return;

      const options = {
        tilt: 0,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        disableDefaultUI: true as boolean | null,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.RIGHT_TOP,
          mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        fullscreenControl: false,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ],
      } as google.maps.MapOptions;
      this.map = new google.maps.Map(this.$refs['map-container'] as HTMLElement, options) as google.maps.Map;
      await this.initFields();
    },
    async initFields(): Promise<void> {
      const bounds = new google.maps.LatLngBounds() as google.maps.LatLngBounds;
      Object.keys(this.fields).forEach((key: Field['id']) => {
        const field: Field = this.fields[key];
        if (
          field.fieldContour == null ||
          field.fieldContour.geoJson == null ||
          field.fieldContour.geoJson.type !== 'Polygon'
        ) {
          return;
        }
        const { geoJson }: { geoJson: FieldContour['geoJson'] } = field.fieldContour;
        const fieldInfo: FieldInfo = {
          name: combinedNameExclCrop.data.call(this, field),
          crop: field.cropId,
          size: field.fieldSize,
        };
        const { lat, lon } = field;
        const latLng = {
          lat,
          lon,
        };

        if (geoJson != null) {
          this.polygonAdd(key, latLng, geoJson, fieldInfo);
          this.polygonsRedraw();
          const coordinates = geoJson.coordinates[0];
          coordinates.forEach((point) => bounds.extend({ lng: point[0], lat: point[1] }));
        }
      });
      if (Object.values(this.fields).length > 0) {
        this.mapFitBounds({ bounds });
      } else if (this.map) {
        this.map.setOptions(this.googleMapsSettings.GOOGLE_MAPS_DEFAULT_CENTER);
      }

      this.fieldsSelectedOnUpdate(this.fieldsSelected, []);
      this.polygonsRedraw();
    },
    /**
     * Update google maps position and zoom to fit the bounds (used for initial field rendering).
     *
     * @param bounds
     * @param maxZoom
     */
    mapFitBounds({
      bounds,
      maxZoom = 16,
      usePan = false,
    }: {
      bounds: google.maps.LatLngBounds;
      maxZoom?: number;
      usePan?: boolean;
    }): void {
      if (!this.map) return;

      google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        this.map?.setOptions({ maxZoom: null });
      });
      this.map.setOptions({ maxZoom });
      if (usePan) {
        this.map.setZoom(maxZoom);
        this.map.panToBounds(bounds, 500);
      } else {
        this.map.fitBounds(bounds);
      }
    },
    fieldsSelectedOnUpdate(selectedKeys: Field['id'][], oldSelectedKeys: Field['id'][]) {
      const selectArray = selectedKeys.filter((key) => !oldSelectedKeys.includes(key) && this.polygons[key]);
      const deselectArray = oldSelectedKeys.filter((key) => !selectedKeys.includes(key) && this.polygons[key]);

      selectArray.forEach((key) => {
        this.activatePolygon(key);
      });
      deselectArray.forEach((key) => {
        this.deactivatePolygon(key);
      });
    },
    /**
     * Create a new polygon for the given field key and add it to the map and store.
     *
     * @param key
     * @param latLng
     * @param geoJson
     * @param fieldInfo
     */
    polygonAdd(
      key: Field['id'],
      latLng: { lat: number; lon: number },
      geoJson: FieldContour['geoJson'],
      fieldInfo: FieldInfo,
    ): void {
      const polygon = new google.maps.Polygon({
        ...fieldInfo,
        field_key: key,
        paths: geoJson.coordinates[0].map((point) => ({ lng: point[0], lat: point[1] })),
        strokeColor: this.googleMapsSettings.POLYGON_VISIBLE_STROKE_COLOR,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: this.googleMapsSettings.POLYGON_VISIBLE_FILL_COLOR,
        fillOpacity: 0.3,
        state: PolygonStates.INACTIVE,
        lat: latLng.lat,
        lon: latLng.lon,
      });
      [polygon.pathsForAxios] = geoJson.coordinates;

      if (!this.selectDisabled) {
        google.maps.event.addListener(polygon, 'click', () => {
          const polygonState = this.polygons[key].state;
          this.polygonSwitchState(key, polygonState);
        });
      }

      google.maps.event.addListener(polygon, 'mouseover', () => {
        this.polygonHovered = key;
      });
      google.maps.event.addListener(polygon, 'mouseout', () => {
        if (this.polygonHovered === key) {
          this.polygonHovered = null;
        }
      });
      this.$emit('addPolygon', polygon);
    },
    polygonsRedraw(): void {
      Object.values(this.polygons).forEach((polygon) => {
        const { state }: { state: PolygonStates } = polygon;
        let polygonOptions = {};
        if (state === PolygonStates.ACTIVE) {
          polygonOptions = {
            strokeColor: this.googleMapsSettings.POLYGON_ACTIVE_STROKE_COLOR,
            fillColor: this.googleMapsSettings.POLYGON_ACTIVE_FILL_COLOR,
            fillOpacity: 0.3,
            strokeOpacity: 1,
          };
        } else if (state === PolygonStates.INACTIVE) {
          polygonOptions = {
            fillColor: this.googleMapsSettings.POLYGON_VISIBLE_STROKE_COLOR,
            strokeColor: this.googleMapsSettings.POLYGON_VISIBLE_FILL_COLOR,
            fillOpacity: 0.3,
            strokeOpacity: 1,
          };
        }
        polygon.setMap(this.map);
        polygon.setOptions(polygonOptions);
        this.centerOnFields({ fields: this.selectedFieldsData, usePan: true });
      });
    },
    activatePolygon(key: Field['id']): void {
      this.$emit('polygonSetState', {
        key,
        state: PolygonStates.ACTIVE,
      });
    },
    deactivatePolygon(key: Field['id']): void {
      this.$emit('polygonSetState', {
        key,
        state: PolygonStates.INACTIVE,
      });
    },
    addToSelected(key: string): void {
      if (!this.fieldsSelected.includes(key)) {
        this.fieldsSelected = [...this.fieldsSelected, key];
      }
    },
    removeFromSelected(key: string): void {
      if (this.fieldsSelected.includes(key)) {
        this.fieldsSelected = this.fieldsSelected.filter((fieldKey) => fieldKey !== key);
      }
    },
    polygonSwitchState(key: Field['id'], state: PolygonStates) {
      if (state === PolygonStates.INACTIVE) {
        this.addToSelected(key);
      } else {
        this.removeFromSelected(key);
      }
    },
    centerOnFields({ fields, usePan = false }: { fields: Fields; usePan?: boolean }) {
      let latLngFound = false;
      const bounds = new google.maps.LatLngBounds();
      Object.values(fields).forEach((field) => {
        if (
          field.fieldContour != null &&
          field.fieldContour.geoJson != null &&
          field.fieldContour.geoJson.type === 'Polygon'
        ) {
          latLngFound = true;
          field.fieldContour.geoJson.coordinates.forEach((ring) => {
            ring.forEach((point) => {
              bounds.extend({ lat: point[1], lng: point[0] });
            });
          });
        } else if (field.lat != null && field.lon != null) {
          latLngFound = true;
          bounds.extend({ lat: field.lat, lng: field.lon });
        }
      });

      if (latLngFound) {
        this.mapFitBounds({ bounds, maxZoom: 16, usePan });
      }
    },
  },
});
