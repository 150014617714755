import moment from 'moment';

export default function getRendererValueDate(value: string): string {
  const d = new Date(Number.parseInt(value, 10) * 1000);
  const validDate = !Number.isNaN(d.getTime());

  if (validDate) {
    return moment(d).format('L');
  }

  return '';
}
