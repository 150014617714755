import { ActivityRuleViolation, Rule, RuleViolationType } from 'farmdok-rest-api';

import ActivityService from '@/activities/services/ActivityService';
import { Data } from '@/shared/mixins/store/types';

import ActivityRuleViolationService from '../activityRuleViolations/services/ActivityRuleViolationService';
import { IndicationLink, Violation, ViolationNotChecked } from '../types';

type IndicationData = {
  externalId: string;
  pest: string;
};

export default class ViolationService {
  private readonly rules: Data<Rule>;

  private readonly activityService: ActivityService;

  private readonly activityRuleViolationService: ActivityRuleViolationService;

  private readonly regionCode: string;

  constructor(
    rules: Data<Rule>,
    activityService: ActivityService,
    activityRuleViolationService: ActivityRuleViolationService,
    regionCode: string,
  ) {
    this.rules = rules;
    this.activityService = activityService;
    this.activityRuleViolationService = activityRuleViolationService;
    this.regionCode = regionCode;
  }

  public getViolations(
    activityId?: string,
    includeDeletedActivities: boolean = false,
  ): Array<Violation | ViolationNotChecked> | null {
    let activityRuleViolations = this.activityRuleViolationService.getActivityRuleViolations(activityId);

    if (!includeDeletedActivities) {
      activityRuleViolations = activityRuleViolations.filter(this.filterActivityRuleViolationOfDeletedActivities());
    }

    return activityRuleViolations.map((activityRuleViolation) => this.createViolation(activityRuleViolation));
  }

  private filterActivityRuleViolationOfDeletedActivities() {
    return (activityRuleViolation: ActivityRuleViolation): boolean => {
      const activity = this.activityService.getActivity(activityRuleViolation.activityId);
      if (!activity) return false;
      return activity.deleted == null;
    };
  }

  private createViolation(activityRuleViolation: ActivityRuleViolation): Violation | ViolationNotChecked {
    switch (activityRuleViolation.type) {
      case RuleViolationType.Violation:
        return this.createViolationTypeViolation(activityRuleViolation);
      case RuleViolationType.NotChecked:
        return this.createViolationTypeNotChecked(activityRuleViolation);
      default:
        throw new Error(`Unknown violation type ${activityRuleViolation.type}`);
    }
  }

  private createViolationTypeViolation(activityRuleViolation: ActivityRuleViolation): Violation {
    const rule = this.rules[activityRuleViolation.ruleId];
    if (!rule) throw new Error(`Rule with id ${activityRuleViolation.ruleId} not found`);

    return {
      ruleId: rule.id,
      shortMessage: rule.shortMessage,
      longMessageHtml: ViolationService.createLongMessageHtmlTypeViolation(activityRuleViolation, rule),
      activityId: activityRuleViolation.activityId,
      activityRuleViolationId: activityRuleViolation.id,
    };
  }

  private createViolationTypeNotChecked(activityRuleViolation: ActivityRuleViolation): ViolationNotChecked {
    const rule = this.rules[activityRuleViolation.ruleId];
    if (!rule) throw new Error(`Rule with id ${activityRuleViolation.ruleId} not found`);

    return {
      ruleId: rule.id,
      shortMessage: `${rule.name} konnte nicht geprüft werden`,
      links: this.createViolationNotCheckedLinks(activityRuleViolation),
      activityId: activityRuleViolation.activityId,
      activityRuleViolationId: activityRuleViolation.id,
    };
  }

  private static createLongMessageHtmlTypeViolation(activityRuleViolation: ActivityRuleViolation, rule: Rule): string {
    const { violationData } = activityRuleViolation;
    const { longMessage, parameterNames } = rule;
    const parameters = parameterNames.map((parameterName) => violationData[parameterName]);

    const longMessageHtml = ViolationService.replaceParameters(longMessage, parameters);
    return longMessageHtml;
  }

  private static replaceParameters(message: string, parameters: string[]): string {
    return message.replace(/%[^ ]+/g, () => `<u>${parameters.shift() ?? ''}</u>`);
  }

  private createViolationNotCheckedLinks(activityRuleViolation: ActivityRuleViolation): IndicationLink[] {
    return activityRuleViolation.violationData.indicationData.map((indicationData: IndicationData) =>
      this.createIndicationLink(indicationData, activityRuleViolation.activityId),
    );
  }

  private createIndicationLink(indicationData: IndicationData, activityId: string): IndicationLink {
    const label = this.createIndicationLinkLabel(indicationData, activityId);
    const href = this.createIndicationLinkHref(indicationData);

    return {
      label,
      href,
    };
  }

  private createIndicationLinkLabel(indicationData: IndicationData, activityId: string): string {
    const { externalId, pest } = indicationData;
    const crop = this.activityService.getCropOfActivity(activityId);

    if (crop) {
      return `${crop.name} - ${pest} [${externalId}]`;
    }
    const indicationLinkText = `${pest} [${externalId}]`;
    return indicationLinkText;
  }

  private createIndicationLinkHref(indicationData: IndicationData): string {
    const { externalId } = indicationData;

    if (this.regionCode === 'de') {
      const [kennNr] = externalId.split('/');
      return `https://apps2.bvl.bund.de/psm/jsp/BlattAnwendg.jsp?awg_id=${externalId}&kennr=${kennNr}`;
    }
    const [refNr, indNr] = externalId.split('/');
    return `https://psmregister.baes.gv.at/psmregister/faces/indication.jspx?refNr=${refNr}&indNr=${indNr}&locale=de`;
  }
}
