
import { defineComponent } from 'vue';

import numbro from '@/initNumbro';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import RequiredAmountTable from '@/shared/components/RequiredAmountTable.vue';
import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import ResetButton from '@/shared/components/buttons/ResetButton.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldSetBordered from '@/shared/components/form/FormFieldSetBordered.vue';
import FormSlider from '@/shared/components/form/FormSlider.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { REGION_GUIDS_ENUM } from '@/shared/constants';
import { RootState } from '@/store/types';

import ScrollableContent from '../components/ScrollableContent.vue';
import { Heatmap } from '../store/baseWorkflowStore/types/Heatmap';
import ModalLossCompensationInfo from './ModalLossCompensationInfo.vue';
import ZonesTableContainer from './containers/sidebarApplicationMap/ZonesTableContainer.vue';
import { Getters as SeedingGetters } from './store/getters';
import { Calculation, SeedingZone, WorkflowVarieties } from './store/types';
import { round } from './utils';

export default defineComponent({
  name: 'SidebarCreateApplicationMapSeeding',
  components: {
    Button,
    SidebarContentWrapper,
    SidebarHeadline,
    SidebarSubHeadline,
    RequiredAmountTable,
    FormFieldDropdown,
    FormSlider,
    FormFieldSetBordered,
    FormFieldInput,
    ErrorUserMessage,
    ResetButton,
    ModalLossCompensationInfo,
    ButtonLink,
    ScrollableContent,
    ZonesTableContainer,
  },

  data(): {
    precision: number;
    errorMessages: {
      seedrateMin: string | null | undefined;
      seedrateMax: string | null | undefined;
    };
    standardPackageSize: number;
    quantisationCategories: DropdownItemsPerCategory[];
    selectedQuantisation: DropdownItem;
  } {
    const quantisationCodes: DropdownItem[] = [
      { id: 'high', name: this.$t('Hoch') || '' },
      { id: 'medium', name: this.$t('Mittel') || '' },
      { id: 'low', name: this.$t('Gering') || '' },
    ];
    const quantisationCategories: DropdownItemsPerCategory[] = [
      {
        name: 'Quantisation',
        id: '0',
        items: quantisationCodes,
        sort: false,
      },
    ];
    const selectedQuantisation: DropdownItem = quantisationCodes[1];
    return {
      precision: 500,
      errorMessages: {
        seedrateMin: null,
        seedrateMax: null,
      },
      standardPackageSize: 50000,
      quantisationCategories,
      selectedQuantisation,
    };
  },

  computed: {
    getSeedOrderButtonHref() {
      return (this.$store.state as RootState).auth.currentCompanies[0].regionId === REGION_GUIDS_ENUM.SI
        ? 'https://www.saatbau.com/si/maisbutler/'
        : 'https://www.sesam24.at/saatbau-spa/EUR/login';
    },
    getSeedOrderButtonText() {
      return (this.$store.state as RootState).auth.currentCompanies[0].regionId === REGION_GUIDS_ENUM.SI
        ? this.$t('MAISBUTLER Anfrage')
        : this.$t('Saatgut jetzt bestellen SESAM24');
    },
    varieties(): WorkflowVarieties {
      return (this.$store.state as RootState).precisionFarming.applicationMaps.seeding.varieties;
    },
    calculation(): Calculation {
      return (this.$store.state as RootState).precisionFarming.applicationMaps.seeding.calculation;
    },
    zones(): SeedingZone[] {
      return this.$store.getters['precisionFarming/applicationMaps/seeding/zones'] as SeedingGetters['zones'];
    },
    zonesMinSeedrate(): number {
      return this.$store.getters['precisionFarming/applicationMaps/seeding/zonesMinSeedrate'];
    },
    zonesMaxSeedrate(): number {
      return this.$store.getters['precisionFarming/applicationMaps/seeding/zonesMaxSeedrate'];
    },
    currentHeatmaps(): Record<string, Heatmap> {
      return (this.$store.state as RootState).precisionFarming.applicationMaps.seeding.heatmaps.current;
    },
    selectedFields(): string[] {
      return (this.$store.state as RootState).precisionFarming.applicationMaps.seeding.selectedFields;
    },
    varietiesToDropdownItems(): DropdownItemsPerCategory[] {
      return [
        {
          items: [{ id: 'manual', name: this.$t('Eigene Saatstärke') ?? '' }],
        },
        {
          items: this.varieties.current.map((variety) => {
            const name = variety.fao ? `${variety.name} (${variety.fao})` : variety.name;
            return { id: variety.id, name };
          }),
        },
      ];
    },
    fieldDistributionMinMaxDescription(): string {
      return (
        this.$t('{seedrateMin} bis {seedrateMax} Körner pro Hektar', {
          seedrateMin: this.fieldDistributionMinInput,
          seedrateMax: this.fieldDistributionMaxInput,
        }) ?? ''
      );
    },
    fieldDistributionMin(): number {
      return this.zonesMinSeedrate;
    },
    fieldDistributionMax(): number {
      return this.zonesMaxSeedrate;
    },
    fieldDistributionMinInput(): string {
      if (this.calculation.manualMinSeedrate !== null) {
        return this.numberFormat(this.calculation.manualMinSeedrate.toString());
      }

      return this.numberFormat(this.fieldDistributionMin.toString());
    },
    fieldDistributionMaxInput(): string {
      if (this.calculation.manualMaxSeedrate !== null) {
        return this.numberFormat(this.calculation.manualMaxSeedrate.toString());
      }

      return this.numberFormat(this.fieldDistributionMax.toString());
    },
    manualSeedRatesDefined(): boolean {
      const { manualSeedRates } = this.calculation;
      return Object.keys(manualSeedRates).length !== 0;
    },
    zoneSizesTotal(): number {
      return this.zones.reduce((total, zone) => total + zone.size, 0);
    },
    packagesTotal(): number {
      const sum = this.zones.reduce((total, zone) => {
        if (!zone.manualSeedRate) {
          return total + (zone.seedRate ?? 0) * zone.size;
        }
        return total + zone.manualSeedRate * zone.size;
      }, 0);
      if (Number.isNaN(sum)) {
        return 0;
      }
      return Math.ceil(sum / this.standardPackageSize);
    },
    standardPackageSizeFormatted(): string {
      return numbro(this.standardPackageSize).format({ mantissa: 0 });
    },
  },

  watch: {
    currentHeatmaps() {
      this.$store.dispatch(`precisionFarming/applicationMaps/seeding/setAreaWeightedVarietyMean`);
    },
    selectedFields: {
      async handler() {
        await this.$store.dispatch(`precisionFarming/applicationMaps/seeding/loadVarieties`, {
          forceRefresh: false,
        });
        if (this.calculation.variety.id === null) {
          this.$store.dispatch(`precisionFarming/applicationMaps/seeding/setDefaultVariety`);
        } else {
          this.$store.dispatch('precisionFarming/applicationMaps/seeding/updateVarietyDependencies');
        }
      },
      immediate: true,
    },
  },

  methods: {
    setVariety(value: DropdownItem) {
      if (value.id === 'manual') {
        this.setManualSeedRatesToZero();
      }
      this.$store.dispatch(`precisionFarming/applicationMaps/seeding/setVariety`, value);
      this.errorMessages = {
        seedrateMin: null,
        seedrateMax: null,
      };
    },
    setBasicSeedStrength(value: number) {
      this.$store.commit(`precisionFarming/applicationMaps/seeding/setCalculationField`, {
        key: 'basicSeedStrength',
        value,
      });
    },
    setLossCompensation(value: number) {
      const valueToPercentage = value / 100;
      this.$store.commit(`precisionFarming/applicationMaps/seeding/setCalculationField`, {
        key: 'lossCompensation',
        value: valueToPercentage,
      });
    },
    setManualSeedRate(zoneName: string, manualSeedRate: string) {
      const numberValue = numbro.unformat(manualSeedRate);

      this.$store.dispatch(`precisionFarming/applicationMaps/seeding/setManualSeedRate`, {
        zoneName,
        manualSeedRate: numberValue,
      });
    },
    setManualFieldDistributionMin(value: string) {
      const min = numbro.unformat(value);
      const max = numbro.unformat(this.fieldDistributionMaxInput);

      if (min > max) {
        this.errorMessages.seedrateMin = this.$t(
          'Die minimale Saatstärke darf nicht größer als die maximale Saatstärke sein.',
        );
        return;
      }

      this.errorMessages.seedrateMin = null;
      this.$store.dispatch('precisionFarming/applicationMaps/seeding/setManualMinMaxSeedrate', {
        seedrateMin: min,
        seedrateMax: max,
      });
    },
    setManualFieldDistributionMax(value: string) {
      const min = numbro.unformat(this.fieldDistributionMinInput);
      const max = numbro.unformat(value);

      if (max < min) {
        this.errorMessages.seedrateMax = this.$t(
          'Die maximale Saatstärke darf nicht kleiner als die minimale Saatstärke sein.',
        );
        return;
      }

      this.errorMessages.seedrateMax = null;
      this.$store.dispatch('precisionFarming/applicationMaps/seeding/setManualMinMaxSeedrate', {
        seedrateMin: min,
        seedrateMax: max,
      });
    },
    setManualSeedRatesToZero() {
      const newManualSeedRates: Record<string, number> = {};
      this.zones.forEach((zone) => {
        newManualSeedRates[zone.name] = 0;
      });
      this.$store.commit(`precisionFarming/applicationMaps/seeding/setCalculationField`, {
        key: 'manualSeedRates',
        value: newManualSeedRates,
      });
    },
    resetManualSeedRates() {
      this.$store.dispatch(`precisionFarming/applicationMaps/seeding/setVariety`, this.calculation.variety);
    },
    numberFormat(value: string) {
      const numberValue = Number(value.replace(/\D/g, ''));
      return numbro(numberValue).format({ mantissa: 0 });
    },

    roundValue(key: keyof Calculation) {
      const value = this.calculation[key];
      if (!value) {
        return;
      }

      const roundedValue = round(value, this.precision);

      this.$store.commit(`precisionFarming/applicationMaps/seeding/setCalculationField`, {
        key,
        value: roundedValue,
      });
    },

    showLossCompensationInfoModal() {
      // @ts-ignore
      this.$refs.lossCompensationInfoModal.show();
    },
    updateQuantisation(selectedQuantisation: DropdownItem): void {
      this.$store.commit('precisionFarming/applicationMaps/seeding/setQuantisationCode', selectedQuantisation.id);
      this.$store.dispatch('precisionFarming/applicationMaps/seeding/loadHeatmaps');
    },
  },
});
