import { Module } from 'vuex';

import { RootState } from '@/store/types';

import actions from './actions';
import getters from './getters';
import initialState from './initialState';
import mutations from './mutations';
import { ApplicationMapsFertilizationState } from './types';

const vuexModule: Module<ApplicationMapsFertilizationState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};

export default vuexModule;
