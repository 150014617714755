import Handsontable from 'handsontable';

import { CellCoords } from '@/shared/handsontable/types';

import isOutsideDataCellClick from '../../utils/isOutsideDataCellClick';
import { ColumnSettingsOptional } from './types';

function handleKeyClickFactory(hot: Handsontable | null) {
  function handleKeyClick(event: KeyboardEvent) {
    if (!hot) return;

    if (event.key === 'Enter') {
      const selected = hot.getSelected();
      if (!selected) return;
      const visualRow = selected[0][0];
      const visualColumn = selected[0][1];
      if (isOutsideDataCellClick(visualRow, visualColumn)) return;
      const cellMeta = hot.getCellMeta(visualRow, visualColumn);

      if (cellMeta.renderer === 'farmdok.optional') {
        const typedCellMeta = cellMeta as unknown as ColumnSettingsOptional;
        if (typedCellMeta.renderNone?.(visualRow, hot)) return;
        if (typedCellMeta.renderEmpty?.(visualRow, hot)) return;

        const value = hot.getDataAtCell(visualRow, visualColumn);
        if (typedCellMeta.renderLoading?.(visualRow, value, hot)) return;

        typedCellMeta.onClick?.(visualRow, hot);
      }
    }
  }

  return handleKeyClick;
}

function triggerOnClickFactory(hot: Handsontable | null) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function triggerOnClick(event: MouseEvent, coords: CellCoords, TD: HTMLTableCellElement) {
    if (!hot) return;
    const visualRow = coords.row;
    const visualColumn = coords.col;
    if (isOutsideDataCellClick(visualRow, visualColumn)) return;
    const cellMeta = hot.getCellMeta(visualRow, visualColumn);
    if (cellMeta.renderer === 'farmdok.optional') {
      const typedCellMeta = cellMeta as unknown as ColumnSettingsOptional;

      if (typedCellMeta.renderNone?.(visualRow, hot)) return;
      if (typedCellMeta.renderEmpty?.(visualRow, hot)) return;

      const value = hot.getDataAtCell(visualRow, visualColumn);
      if (typedCellMeta.renderLoading?.(visualRow, value, hot)) return;

      typedCellMeta.onClick?.(visualRow, hot);
    }
  };
}

// add these hooks to your handsontable instance
// additionally add hooks from rework/feature/keyboardNavigation
const hooks = {
  beforeKeyDown: { handleKeyClickFactory },
  afterOnCellMouseDown: { triggerOnClickFactory },
};

export default hooks;
