
import { ActivityProduct, StoragePlace } from 'farmdok-rest-api';
import { defineComponent } from 'vue';

import ProductSelectSection from '@/activities/modals/createEditActivity/components/ProductSelectSection.vue';
import { MergedActivityProduct } from '@/activities/modals/createEditActivity/services/MergedActivityProductsService';
import { Product } from '@/shared/api/rest/models';
import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { RootState } from '@/store/types';

export default defineComponent({
  name: 'ProductSelectSectionContainer',
  components: { ProductSelectSection, MultiRootWrapper },
  created() {
    this.$store.dispatch('products/subscribe');
    this.$store.dispatch('units/subscribe');
    this.$store.dispatch('storagePlaces/subscribe');
  },
  computed: {
    activityProducts(): MergedActivityProduct[] {
      return (
        (this.$store.getters[`activities/createEditActivity/mergedActivityProducts`] as MergedActivityProduct[]) ?? []
      );
    },
    storagePlaces(): StoragePlace[] {
      return Object.values((this.$store.state as RootState).storagePlaces.data);
    },
    products(): Product[] {
      return this.$store.getters['products/allProducts'];
    },
    fieldNamesPerActivity(): Record<string, string> {
      const activities = (this.$store.state as RootState).activities.createEditActivity.activities ?? [];
      const fields = (this.$store.state as RootState).fields.data;

      const fieldNamesPerActivity: Record<string, string> = {};
      activities.forEach((activity) => {
        const fieldName = fields[activity.fieldId ?? '']?.name ?? '';
        fieldNamesPerActivity[activity.id] = fieldName;
      });

      return fieldNamesPerActivity;
    },
  },
  methods: {
    selectedProduct(activityProduct: ActivityProduct): DropdownItem | null {
      if (activityProduct.productId) {
        const selectedProduct = this.products.find((product) => product.id === activityProduct.productId);
        if (!selectedProduct) return null;

        const { storagePlaceId } = activityProduct;
        if (storagePlaceId) {
          return this.getStorageProductDropdownItem(activityProduct, selectedProduct);
        }
        return {
          id: selectedProduct.id,
          name: selectedProduct.name,
        };
      }
      return null;
    },
    getStorageProductDropdownItem(activityProduct: ActivityProduct, selectedProduct: Product): DropdownItem | null {
      const { storagePlaceId } = activityProduct;
      if (!storagePlaceId) return null;
      const storagePlace = this.storagePlaces.find((storage) => storage.id === storagePlaceId);
      if (!storagePlace) return null;

      return {
        id: `${selectedProduct.id}_${storagePlaceId}`,
        name: `${selectedProduct.name} (${storagePlace.name})`,
      };
    },
    createActivityProduct() {
      this.$store.commit(`activities/createEditActivity/createActivityProduct`);
    },
    removeActivityProductInAllActivities(activityProductId: string) {
      this.$store.dispatch(`activities/createEditActivity/removeActivityProductInAllActivities`, activityProductId);

      if (this.activityProducts.length === 0) {
        this.createActivityProduct();
      }

      if ((this.$store.state as RootState).activities.createEditActivity.currentActivityProductId !== null) {
        this.$store.commit(`activities/createEditActivity/setCurrentActivityProductId`, null);
      }
    },
  },
});
