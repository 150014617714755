import Vue from 'vue';
import { MutationTree } from 'vuex';

import { GUID_KEY } from '@/shared/constants';

import { subscribableStore } from './common';
import { ImportActivitiesState } from './types';

const mutations: MutationTree<ImportActivitiesState> = {
  ...subscribableStore.mutations,

  addOrUpdateTableDataMetadataByKeyAndValue(
    state,
    { importActivityId, key, value }: { importActivityId: string; key: string; value: any },
  ) {
    const metadata = { ...state.tableDataMetadata };
    metadata[importActivityId] = { ...metadata[importActivityId], [key]: value };
    state.tableDataMetadata = metadata;
  },

  updateEntryByKeyAndValue(state, { guid, key, value }) {
    if (state.data[guid] == null) {
      return;
    }
    const data = { ...state.data };
    data[guid] = {
      ...state.data[guid],
      [key]: value,
    };
    state.data = data;
    state.errors = state.errors.filter((error) => error[GUID_KEY] !== guid || error.key !== key);
  },
  removeImportActivity(state, importActivityId: string) {
    Vue.delete(state.data, importActivityId);
    Vue.delete(state.tableDataMetadata, importActivityId);
  },
  updateActivityImportMapDataRequestParameters(state, { source, objectIds = undefined }) {
    if (source) state.activityImportMapDataRequestParameters.source = source;
    state.activityImportMapDataRequestParameters.objectIds = objectIds;
  },

  resetTableData(state) {
    state.data = {};
  },
  resetTableDataMetadata(state) {
    state.tableDataMetadata = {};
  },
};

export default mutations;
