export default function setCellTitleAttribute(TD, displayValue) {
  if (typeof displayValue === 'string' && displayValue.length > 0) {
    new ResizeObserver(() => {
      if (TD.scrollWidth > TD.clientWidth) {
        TD.setAttribute('title', displayValue);
      } else {
        TD.removeAttribute('title');
      }
    }).observe(TD);
    return;
  }
  TD.removeAttribute('title');
}
