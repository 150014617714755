<template>
  <div class="map-info-box">
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'MapInfoBox',
};
</script>

<style scoped>
.map-info-box {
  padding: var(--spacer_3);
  border-radius: 3px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: var(--white);
}
</style>
