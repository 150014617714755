import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleDown } from '@fortawesome/pro-solid-svg-icons';

import getDisplayValue from '@/shared/modules/getDisplayValue';

library.add(faChevronCircleDown, faFilter);

const getTarget = (elem) => {
  if (elem == null || elem.classList == null) {
    return null;
  }
  if (elem.classList.contains('table-header-context-menu__icon')) {
    return elem;
  }
  return getTarget(elem.parentNode);
};

/**
 * Use together with TableHeaderContextMenu component.
 *
 * The filter functionality in the context menu depends on the mixin featureFilterTable.
 */
export default {
  data() {
    return {
      tableHeaderContextMenuTarget: null,
      tableHeaderContextMenuAvailableValues: null,
    };
  },
  mounted() {
    this.addHotHook('afterGetColHeader', this.tableHeaderContextMenuAfterGetColHeaders);
    this.addHotHook('afterScrollHorizontally', this.tableHeaderContextMenuClose);
  },
  computed: {
    tableHeaderContextMenuFilter() {
      if (this.tableHeaderContextMenuTarget == null) {
        return null;
      }
      const columnKey = this.tableHeaderContextMenuTarget.column.key;
      if (this.filtersByColumnKey == null || this.filtersByColumnKey[columnKey] == null) {
        return null;
      }
      return this.filtersByColumnKey[columnKey];
    },
  },
  methods: {
    /**
     * Remove the set target column. Call this function when TableHeaderContextMenu emits the "close" event.
     */
    tableHeaderContextMenuClose() {
      if (this.tableHeaderContextMenuTarget != null) {
        this.tableHeaderContextMenuTarget.element.classList.remove('table-header-context-menu__icon--clicked');
      }
      this.tableHeaderContextMenuTarget = null;
      this.tableHeaderContextMenuAvailableValues = null;
    },
    /**
     * Sorts the table. Call this function when TableHeaderContextMenu emits the "sort" event.
     *
     * @param {string} sortOrder
     */
    tableHeaderContextMenuSort(sortOrder) {
      if (this.tableHeaderContextMenuTarget == null) {
        return;
      }
      const physicalCol = this.getPhysicalColByKey(this.tableHeaderContextMenuTarget.column.key);
      const column = this.hot.toVisualColumn(physicalCol);
      this.hot.getPlugin('columnSorting').sort({ column, sortOrder });
      this.tableHeaderContextMenuTarget.element.classList.remove('table-header-context-menu__icon--clicked');
      this.tableHeaderContextMenuTarget = null;
      this.tableHeaderContextMenuAvailableValues = null;
    },
    /**
     * Updates the column filter. Call this function after the user updates the search field in TableHeaderContextMenu component.<br>
     * Valid types: contains, not, exact
     * <pre>
     *   { value: 'foo', type: 'contains' }
     * </pre>
     *
     * @param {object|null} filter
     */
    tableHeaderContextMenuUpdateFilter(filter) {
      this.setColumnFilter(filter, this.tableHeaderContextMenuTarget.column.key);
      if (
        filter != null &&
        ((typeof filter.value === 'string' && filter.value.length > 0) ||
          (Array.isArray(filter.selectedValues) &&
            Array.isArray(this.tableHeaderContextMenuAvailableValues) &&
            filter.selectedValues.length !== this.tableHeaderContextMenuAvailableValues.length))
      ) {
        this.tableHeaderContextMenuTarget.element.classList.add('table-header-context-menu__icon--filtered');
      } else {
        this.tableHeaderContextMenuTarget.element.classList.remove('table-header-context-menu__icon--filtered');
      }
    },
    /**
     * Renders the icon that opens the context menu on click inside the table header.
     *
     * @private
     * @param {number} visualCol
     * @param {HTMLElement} th
     */
    tableHeaderContextMenuAfterGetColHeaders(visualCol, th) {
      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      const column = this.tableSettingsComputed.columns[physicalCol];

      if (column == null) {
        return;
      }

      let span = th.querySelector('.table-header-context-menu__icon');
      if (column.noHeaderContextMenu) {
        if (span != null) {
          span.remove();
        }
        return;
      }

      if (span != null && span.dataset.columnKey === column.key) {
        return;
      }
      if (span != null) {
        span.remove();
      }

      span = document.createElement('span');
      span.dataset.columnKey = column.key;
      span.addEventListener('click', this.tableHeaderContextMenuClick);
      span.classList.add('table-header-context-menu__icon');
      const [chevronCircleDown] = icon({ prefix: 'fas', iconName: 'chevron-circle-down' }).node;
      chevronCircleDown.classList.add('table-header-context-menu__icon-chevron-circle-down');
      span.append(chevronCircleDown);
      const [filter] = icon({ prefix: 'far', iconName: 'filter' }).node;
      filter.classList.add('table-header-context-menu__icon-filter');
      span.append(filter);
      th.firstChild.append(span);
    },
    /**
     * On click on table header icon sets the target column so that TableHeaderContextMenu component can be rendered.
     *
     * @private
     * @param {Event} e
     * @returns {Promise<void>}
     */
    async tableHeaderContextMenuClick(e) {
      e.preventDefault();
      e.stopPropagation();
      const element = getTarget(e.target);
      element.classList.add('table-header-context-menu__icon--clicked');
      const column = this.getColumnByKey(element.dataset.columnKey);
      let previousColumn = null;

      if (this.tableHeaderContextMenuTarget != null) {
        this.tableHeaderContextMenuTarget.element.classList.remove('table-header-context-menu__icon--clicked');
        previousColumn = this.tableHeaderContextMenuTarget.column;
      }
      this.tableHeaderContextMenuTarget = null;
      this.tableHeaderContextMenuAvailableValues = null;
      if (previousColumn != null && column.key === previousColumn.key) {
        return;
      }

      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => this.$nextTick(resolve));
      const visualCol = this.hot.toVisualColumn(this.getPhysicalColByKey(column.key));
      const sortConfig = this.hot.getPlugin('columnSorting').getSortConfig(visualCol);
      let sortOrder = null;
      if (sortConfig != null) {
        ({ sortOrder } = sortConfig);
      }
      this.tableHeaderContextMenuTarget = {
        element,
        column,
        sortOrder,
      };

      const tableHeaderContextMenuAvailableValues = new Set();
      Object.values(this.tableData).forEach((entry) => {
        const displayValue = getDisplayValue(entry, column);
        if (Array.isArray(displayValue)) {
          displayValue.forEach((value) => tableHeaderContextMenuAvailableValues.add(value));
        } else {
          tableHeaderContextMenuAvailableValues.add(getDisplayValue(entry, column));
        }
      });
      this.tableHeaderContextMenuAvailableValues = [...tableHeaderContextMenuAvailableValues];
    },
  },
};
