<template>
  <div v-if="empty" class="satellite satellite--empty border-dark" :class="{ 'satellite--expanded': expanded }">
    <div class="satellite__grid">
      <slot name="empty" />
    </div>
  </div>
  <div v-else class="satellite border-dark" :class="{ 'satellite--expanded': expanded }">
    <div class="satellite__header">
      <div class="satellite__header-title">
        <slot name="header-title">
          <h2>{{ title }}</h2>
        </slot>
      </div>
      <div class="satellite__header-center">
        <slot name="header-center" />
      </div>
      <div class="satellite__header-buttons">
        <slot name="header-buttons" />
      </div>
    </div>
    <div class="satellite__grid">
      <div v-if="$slots.default != null" class="satellite__left">
        <slot name="default" />
      </div>
      <div v-if="expanded" class="satellite__right">
        <slot name="expanded-area" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import componentNamespace from '@/shared/mixins/componentNamespace';

export default {
  name: 'TheSatellite',
  mixins: [componentNamespace],
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    expandButtonLabel: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      satellite: 'satellite',
    }),
    visible() {
      return this.satellite.visible;
    },
  },
  watch: {
    expanded(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.onExpanded();
      } else if (!newVal && oldVal) {
        this.onShrunk();
      }
    },
    visible(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.onShown();
      } else if (!newVal && oldVal) {
        this.onHidden();
      }
    },
  },
  mounted() {
    this.$store.commit('satellite/setAvailability', true);
    this.$store.commit('satellite/setExpandability', {
      expandAvailable: true,
      expandButtonLabel: this.expandButtonLabel,
    });
    this.onShown();
    this.onExpanded();
  },
  beforeDestroy() {
    this.$store.commit('satellite/reset', false);
    this.onHidden();
  },
  methods: {
    onShown() {
      if (!this.satellite.visible) {
        return;
      }
      /**
       *
       * @event root#the-satellite--shown
       * @type {String}
       */
      this.$root.$emit('the-satellite--shown', this.componentNamespace);
      this.hiddenEventTriggered = false;
    },
    onExpanded() {
      if (!this.satellite.visible || !this.expanded) {
        return;
      }
      /**
       *
       * @event root#the-satellite--expanded
       * @type {String}
       */
      this.$root.$emit('the-satellite--expanded', this.componentNamespace);
    },
    onShrunk() {
      if (!this.satellite.visible || this.expanded) {
        return;
      }
      /**
       *
       * @event root#the-satellite--shrunk
       * @type {String}
       */
      this.$root.$emit('the-satellite--shrunk', this.componentNamespace);
    },
    onHidden() {
      if (this.satellite.visible || this.hiddenEventTriggered) {
        return;
      }
      /**
       *
       * @event root#the-satellite--hidden
       * @type {String}
       */
      this.$root.$emit('the-satellite--hidden', this.componentNamespace);
      this.hiddenEventTriggered = true;
    },
  },
};
</script>

<style lang="css" scoped>
.satellite {
  position: relative;
  width: 480px;
  padding: 0;
  background: white;
  z-index: 1;
  max-width: 100%;
  height: 100%;
  overflow-y: auto;
  border-left: 20px solid;
  display: flex;
  flex-direction: column;
}

.satellite__header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: var(--spacer_3);
  border: 1px solid var(--medium);
  border-bottom: 2px solid var(--medium);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--lightest);
}

.satellite__header-title {
  display: flex;
  align-items: center;
  flex: 1;
}

.satellite__header-center {
}

.satellite__header-buttons {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.satellite__grid {
  flex: 1;
  display: flex;
  padding: var(--spacer_3);
  align-content: flex-start;
}

.satellite--expanded {
  width: 100%;
}

.satellite--expanded .satellite__right {
  display: block;
}

.satellite__left {
  width: 420px;
}

.satellite__right {
  flex: 1;
}

@media print {
  .satellite {
    border-left: none;
  }
}
</style>
