
import zip from 'lodash.zip';
import { type PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import numbro from '@/initNumbro';
import type {
  ApplicationMapsSprayingState,
  PlantProtection,
  Zone,
} from '@/precision-farming/application-maps/spraying/store/types';
import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';

export default defineComponent({
  name: 'DemandTable',
  components: { MultiRootWrapper },
  props: {
    products: {
      type: Array as PropType<PlantProtection[]>,
      required: false,
      default: () => [],
    },
    zones: {
      type: Array as PropType<Zone[]>,
      required: false,
      default: () => [],
    },
    reducedVegetationPerZone: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => [],
    },
    reducedSprayMixPerZone: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => [],
    },
  },
  data(): { numbro: typeof numbro } {
    return { numbro };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/spraying', {
      areas: (state: ApplicationMapsSprayingState, getters): number[] => getters.areas,
    }),
    totalArea(): number {
      return this.areas.reduce((a, b) => a + b, 0);
    },
    totalDeployedSpray(): number {
      return zip(this.reducedSprayMixPerZone, this.areas)
        .map(([a, b]) => [a ?? 1, b ?? 1])
        .map(([a, b]) => a * b)
        .reduce((a, b) => a + b, 0);
    },
  },
  methods: {
    totalAmount(product: PlantProtection): number {
      return this.reducedVegetationPerZone.map((rv) => rv * product.amount).reduce((a, b) => a + b, 0);
    },
    unit(product: PlantProtection): string {
      return product.unit.split('/').shift() ?? '';
    },
    per100Liter(value: number): number {
      return (value / this.totalDeployedSpray) * 100;
    },
  },
});
