<template>
  <div class="steps">
    <div
      v-for="(step, index) in steps"
      :key="`step-${index}`"
      class="steps__step"
      :class="{ 'steps__step--active': current >= index }"
    >
      <div class="steps__ellipse" :class="{ 'steps__ellipse--active': current >= index }">
        {{ step.number }}
      </div>
      <span class="steps__step-label" :class="{ 'steps__step-label--active': current >= index }">
        {{ step.label }}
      </span>
    </div>
  </div>
</template>

<script>
/**
 * Use this component for a multi-page workflow.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <Steps
 *   :current="0"
 *   :steps="[{ number: 1, label: 'First' }, { number: 2, label: 'Second' }]"
 * />
 * @example
 * <Steps :current="1" :steps="[{ number: 1, label: 'First' }, { number: 2, label: 'Second' }, { number: 3, label: 'Third' }]"/>
 */
export default {
  name: 'Steps',
  props: {
    current: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.steps {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  --steps-color: var(--primary);
  --steps-color-alpha: rgba(60, 201, 97, 0.42);
}

.steps__step {
  position: relative;
  width: 102px;
  text-align: center;
}

.steps__step:nth-child(1) {
  z-index: 10;
}

.steps__step:nth-child(2) {
  z-index: 9;
}

.steps__step:nth-child(3) {
  z-index: 8;
}

.steps__step:nth-child(4) {
  z-index: 7;
}

.steps__step:nth-child(5) {
  z-index: 6;
}

.steps__step:nth-child(6) {
  z-index: 5;
}

.steps__step:nth-child(7) {
  z-index: 4;
}

.steps__step:nth-child(8) {
  z-index: 3;
}

.steps__step::before {
  content: '';
  position: absolute;
  width: 70px;
  left: -35px;
  top: 16px;
  border-bottom: 1px solid var(--steps-color-alpha);
  z-index: 5;
}

.steps__step--active::before {
  border-color: var(--steps-color);
}

.steps__step:first-child::before {
  display: none;
}

.steps__ellipse {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border: 1px solid var(--steps-color-alpha);
  border-radius: 50%;
  background: #ffffff;
  color: var(--steps-color-alpha);
  font-weight: 600;
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  vertical-align: middle;
  z-index: 10;
}

.steps__ellipse--active {
  background: var(--steps-color);
  color: #fff;
  border-color: var(--steps-color);
}

.steps__step-label {
  display: block;
  margin: 0.5em 0;
  color: var(--steps-color-alpha);
  font-weight: 600;
  font-size: 14px;
}

.steps__step-label--active {
  color: var(--steps-color);
}
</style>
