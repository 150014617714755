<template>
  <ThePageSkeleton
    class="page-welcome"
    scroll-y
    :class="{ 'page-welcome--with-background-image': backgroundImages != null }"
    :style="styleBackgroundImages"
  >
    <button v-if="step !== 'welcome'" class="page-welcome__button-back" @click="previousStep">
      <FontAwesomeIcon class="page-welcome__button-back-icon" icon="angle-left" />
    </button>
    <Component
      class="page-welcome__content"
      :is="stepComponent"
      :type="type"
      :farmingSystem="farmingSystem"
      :activityTypes="activityTypes"
      :crops="crops"
      :goals="goals"
      :size="size"
      @background="(value) => (backgroundImages = value)"
      @change="change"
      @next="nextStep"
    />
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';

import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';

import LogoAuthPages from './components/LogoAuthPages.vue';
import StepFarmerCrops from './components/StepFarmerCrops.vue';
import StepFarmerGoals from './components/StepFarmerGoals.vue';
import StepFarmerSize from './components/StepFarmerSize.vue';
import StepFarmerWork from './components/StepFarmerWork.vue';
import StepWelcome from './components/StepWelcome.vue';

library.add(faAngleLeft);

export const FARMDOK_ONBOARDING_INFO = 'FARMDOK_ONBOARDING_INFO';

const steps = {
  welcome: {
    component: StepWelcome,
    next: { name: 'welcome', params: { type: 'farmer', step: 'work' } },
  },
  'farmer/work': {
    component: StepFarmerWork,
    next: { name: 'welcome', params: { type: 'farmer', step: 'crops' } },
    previous: { name: 'welcome', params: { type: 'type' } },
  },
  'farmer/crops': {
    component: StepFarmerCrops,
    next: { name: 'welcome', params: { type: 'farmer', step: 'goals' } },
    previous: { name: 'welcome', params: { type: 'farmer', step: 'work' } },
  },
  'farmer/goals': {
    component: StepFarmerGoals,
    next: { name: 'welcome', params: { type: 'farmer', step: 'size' } },
    previous: { name: 'welcome', params: { type: 'farmer', step: 'crops' } },
  },
  'farmer/size': {
    component: StepFarmerSize,
    next: { name: 'register' },
    previous: { name: 'welcome', params: { type: 'farmer', step: 'goals' } },
  },
};

export default {
  name: 'PageWelcome',
  components: { ThePageSkeleton, LogoAuthPages, MaxWidthContainer },
  data() {
    return {
      backgroundImages: null,
      type: 'farmer',
      farmingSystem: null,
      activityTypes: null,
      crops: null,
      goals: null,
      size: null,
    };
  },
  computed: {
    /**
     * welcome -> type -> farmer/work     -> farmer/crops     -> farmer/goals    -> farmer/size
     *                 -> contractor/work -> contractor/goals -> contractor/size
     * @type {string}
     */
    step() {
      const { type, step } = this.$route.params;
      if (type == null) {
        return 'welcome';
      }
      if (type === 'type' && step == null) {
        return 'type';
      }
      return [type, step].join('/');
    },
    stepComponent() {
      if (steps[this.step] == null) {
        return StepWelcome;
      }
      return steps[this.step].component;
    },
    styleBackgroundImages() {
      if (this.backgroundImages == null) {
        return null;
      }
      return {
        '--image': `url('${this.backgroundImages.image}')`,
        '--image-landscape': `url('${this.backgroundImages.imageLandscape}')`,
      };
    },
  },
  created() {
    if (localStorage.getItem(FARMDOK_ONBOARDING_INFO) != null) {
      try {
        this.initFromLocalStorage();
      } catch (error) {
        console.error(error);
        localStorage.removeItem(FARMDOK_ONBOARDING_INFO);
      }
    }
    if (steps[this.step] == null) {
      this.$router.push({ name: 'welcome' });
    }
  },
  methods: {
    initFromLocalStorage() {
      if (localStorage.getItem(FARMDOK_ONBOARDING_INFO) == null) {
        return;
      }
      const info = JSON.parse(localStorage.getItem(FARMDOK_ONBOARDING_INFO));
      Object.keys(info).forEach((key) => {
        this[key] = info[key];
      });
    },
    change({ key, value }) {
      this[key] = value;
      if (key === 'type') {
        this.farmingSystem = null;
        this.activityTypes = null;
        this.crops = null;
        this.goals = null;
        this.size = null;
      }
      localStorage.setItem(
        FARMDOK_ONBOARDING_INFO,
        JSON.stringify({
          type: this.type,
          farmingSystem: this.farmingSystem,
          activityTypes: this.activityTypes,
          crops: this.crops,
          goals: this.goals,
          size: this.size,
        }),
      );
    },
    nextStep() {
      if (steps[this.step] == null) {
        return;
      }
      let { next } = steps[this.step];
      if (typeof next === 'function') {
        next = next(this.type);
      }
      this.$router.push(next);
    },
    previousStep() {
      if (steps[this.step] == null) {
        return;
      }
      this.$router.push(steps[this.step].previous);
    },
  },
};
</script>

<style scoped lang="scss">
.page-welcome {
  --dimmer: linear-gradient(rgba(19, 53, 60, 0.6) 0%, rgba(19, 53, 60, 0.3) 30%, rgba(19, 53, 60, 0.3) 100%);
  --dimmer-landscape: linear-gradient(rgba(19, 53, 60, 0.6) 0%, rgba(19, 53, 60, 0.3) 45%, rgba(19, 53, 60, 0.2) 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
}

.page-welcome--with-background-image {
  background-image: var(--dimmer), var(--image);
}

@media (orientation: landscape) {
  .page-welcome--with-background-image {
    background-image: var(--dimmer-landscape), var(--image-landscape);
  }
}

::v-deep .page-skeleton__content-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-welcome__content {
  width: 100%;
  flex: 1;
  align-items: center;
}

.page-welcome__button-back {
  position: absolute;
  top: calc(var(--spacer_4) - 1rem);
  left: calc(var(--spacer_4) - 1rem);
  padding: 1rem;
  border: none;
  color: var(--white);
  font-size: 18px;
}

.page-welcome__button-back-icon {
  margin: 0;
}

@media (min-width: 490px) {
  .page-welcome__button-back {
    position: fixed;
  }
}

@media (min-width: 768px) {
  .page-welcome__button-back {
    top: calc(var(--spacer_5) - 1rem);
    left: calc(var(--spacer_5) - 1rem);
  }
}
</style>
