import { ApiResponse, ProcessOrder } from '@/shared/api/rest/models';

export type AuthState = UserInfo & {
  jwtAuth: JwtAuth | null;
  loggedIn: boolean;
  fetchingInitialAccessToken: boolean;
  fetchingLogin: boolean;
  fetchingUserInfo: boolean;
  fetchingRegister: boolean;
  fetchingCompanies: boolean;
  observers: Function[];
};

export type JwtAuth = {
  initTokenChecking: () => boolean;
  login: (email: string, password: string) => Promise<ApiResponse<LoginInfo>>;
  loginOneTime: (oneTimeToken: string) => Promise<ApiResponse<LoginInfo>>;
  logout: () => Promise<void>;
  getAccessToken: () => unknown;
};

export type LoginInfo = {
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  expires_in: number;
  // eslint-disable-next-line camelcase
  token_type: string;
};

export type UserInfo = {
  userInfoLoaded: boolean;

  userCompanies: UserCompany[];
  companiesById: Record<string, Company>;
  currentCompanies: Company[];
  currentProcessOrderName: string | null;

  user: User;
};

export type UserCompany = {
  active: number;
  company: Company;
  companyId: string;
  deleted: boolean;
  id: string;
  tstamp: number;
  userId: string;
  userRoleId: string;
};

export type Company = {
  abbreviation: string | null;
  address: string;
  bdmId: string;
  city: string;
  clusterId: unknown;
  country: string;
  databaseId: number;
  deleted: unknown;
  email: string;
  farmingSystem: string;
  farmname: string;
  features: Features;
  firstname: string;
  id: string;
  lastname: string;
  licensePackageId: string;
  licensePackageName: string;
  location: Location;
  name: string;
  postcode: string;
  processOrderId: string;
  processOrders: ProcessOrder[];
  publishInSharingDict: number;
  region: Region;
  regionId: string;
  sharingBackground: string;
  sharingInconUrl: string;
  sharingLogoUrl: string;
  tel: string;
  title: string;
  tstamp: number;
};

export type Features = Partial<Record<FeatureName, Feature>>;

export enum FeatureName {
  CAN_PURCHASE_LICENSE = 'CAN_PURCHASE_LICENSE',
  FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC = 'FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC',
  FEATURE_APPLICATION_MAPS_SEEDING_SAATBAU_MAIZE = 'FEATURE_APPLICATION_MAPS_SEEDING_SAATBAU_MAIZE',
  FEATURE_COST_ACCOUNTING = 'FEATURE_COST_ACCOUNTING',
  FEATURE_CROPPING_PLAN = 'FEATURE_CROPPING_PLAN',
  FEATURE_CROP_RATIO = 'FEATURE_CROP_RATIO',
  FEATURE_CROP_ROTATION = 'FEATURE_CROP_ROTATION',
  FEATURE_CUSTOMERS = 'FEATURE_CUSTOMERS',
  FEATURE_DIGITAL_CONTRACTING = 'FEATURE_DIGITAL_CONTRACTING',
  FEATURE_EXPORT_FIELD = 'FEATURE_EXPORT_FIELD',
  FEATURE_FERTILIZATION_PLAN = 'FEATURE_FERTILIZATION_PLAN',
  FEATURE_FERTILIZER_REGULATION = 'FEATURE_FERTILIZER_REGULATION',
  FEATURE_FIELD_DETAIL_CROP_ROTATION = 'FEATURE_FIELD_DETAIL_CROP_ROTATION',
  FEATURE_FIELDS = 'FEATURE_FIELDS',
  FEATURE_FIELDS_ADVANCED = 'FEATURE_FIELDS_ADVANCED',
  FEATURE_FIELDS_NDVI_MEAN = 'FEATURE_FIELDS_NDVI_MEAN',
  FEATURE_FIELDS_SHARED = 'FEATURE_FIELDS_SHARED',
  FEATURE_GROUNDWATER_PROTECTION = 'FEATURE_GROUNDWATER_PROTECTION',
  FEATURE_MANAGED_CONTRACTS = 'FEATURE_MANAGED_CONTRACTS',
  FEATURE_MOVE_ACTIVITIES = 'FEATURE_MOVE_ACTIVITIES',
  FEATURE_NOTES = 'FEATURE_NOTES',
  FEATURE_NUTRIENT_BALANCE = 'FEATURE_NUTRIENT_BALANCE',
  FEATURE_NUTRIENT_COMPARISON = 'FEATURE_NUTRIENT_COMPARISON',
  FEATURE_PROCESS_ORDER = 'FEATURE_PROCESS_ORDER',
  FEATURE_SOIL_SAMPLES = 'FEATURE_SOIL_SAMPLES',
  FEATURE_STOCK_MOVEMENT = 'FEATURE_STOCK_MOVEMENT',
  FEATURE_SUBSTANCE_FLOW = 'FEATURE_SUBSTANCE_FLOW',
  FEATURE_TASK_PLAN = 'FEATURE_TASK_PLAN',
  FEATURE_VEGETATION_MONITORING = 'FEATURE_VEGETATION_MONITORING',
  FEATURE_WEATHER_INFO = 'FEATURE_WEATHER_INFO',
}

export type Feature = {
  enabled: boolean;
  visible: boolean;
};

export type Location = {
  lat: number;
  lon: number;
};

export type Region = {
  billTaxesBusinessCustomers: number;
  code: string;
  defaultLanguageId: string;
  defaultLocaleId: string;
  deleted: unknown;
  description: unknown;
  id: string;
  name: string;
  taxFactor: number;
  tstamp: number;
};

export type User = {
  anonymousAccount: number;
  businessCustomer: number;
  companyId: string;
  confirmToken: null;
  created: number;
  deleted: null;
  email: string;
  emailIsConfirmed: boolean;
  firstname: string;
  id: string;
  isPartner: number;
  languageId: string;
  lastname: string;
  newsletter: number;
  superAdmin: number;
  tstamp: number;
  vatInformation: null;
  displayName?: string;
};

export enum FetchingKey {
  FETCHING_INITIAL_ACCESS_TOKEN = 'fetchingInitialAccessToken',
  FETCHING_LOGIN = 'fetchingLogin',
  FETCHING_USER_INFO = 'fetchingUserInfo',
  FETCHING_REGISTER = 'fetchingRegister',
  FETCHING_COMPANIES = 'fetchingCompanies',
}
