export default {
  props: {
    componentNamespaceName: {
      type: String,
      default: null,
    },
  },
  computed: {
    componentNamespace(): string {
      const names = [];
      // @ts-ignore
      if (this.componentNamespaceName != null) {
        // @ts-ignore
        names.push(this.componentNamespaceName);
      }
      // @ts-ignore
      for (let parent = this.$parent; parent != null; parent = parent.$parent) {
        if (typeof parent.$options.name === 'string') {
          names.push(parent.$options.name);
        }
      }
      return names.join('.');
    },
  },
};
