import Handsontable from 'handsontable';

import toTableData from '@/shared/handsontable/rework/toTableData';

import PluginService from '../../../services/PluginService';
import { TableDataBaseExpandable } from '../../../types';
import hotSettings from '../settings';
import { ColumnSettingsFlattenedSubtable, MergeArgs, MergeCellsDetailedSettings } from '../types';

export function reinitializeMergedCellsAfterFilterFactory(hot: Handsontable | null) {
  function reinitializeMergedCellsAfterFilter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    conditionsStack: Handsontable.plugins.FiltersPlugin.ColumnConditions[],
  ): void {
    if (!hot) return;
    const { columns } = hot.getSettings();
    if (!Array.isArray(columns)) throw new Error('columns must be of type ColumnSettings[]');
    reinitializeMergedCells(columns, hot);
  }

  return reinitializeMergedCellsAfterFilter;
}

export function reinitializeMergedCellsAfterColumnSortFactory(hot: Handsontable | null) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function reinitializeMergedCellsAfterColumn(currentSortConfig: unknown[], destinationSortConfigs: unknown[]): void {
    if (!hot) return;
    const { columns } = hot.getSettings();
    if (!Array.isArray(columns)) throw new Error('columns must be of type ColumnSettings[]');
    reinitializeMergedCells(columns, hot);
  }

  return reinitializeMergedCellsAfterColumn;
}

export function reinitializeMergedCellsAfterColumnMoveFactory(hot: Handsontable | null) {
  function reinitializeMergedCellsAfterColumnMove(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    movedColumns: number[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    finalIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropIndex: number | void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    movePossible: boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    orderChanged: boolean,
  ): void {
    if (!hot) return;
    const { columns } = hot.getSettings();
    if (!Array.isArray(columns)) throw new Error('columns must be of type ColumnSettings[]');
    reinitializeMergedCells(columns, hot);
  }

  return reinitializeMergedCellsAfterColumnMove;
}

function reinitializeMergedCells(tableColumns: ColumnSettingsFlattenedSubtable[], hot: Handsontable) {
  const mergePlugin = new PluginService(hot).getMergePlugin();

  mergePlugin.clearCollections();

  const rawTableData = hot.getData();
  const tableData = toTableData<TableDataBaseExpandable>(rawTableData, tableColumns, hot);
  const mergeSettings = hotSettings.mergeCells(tableData, tableColumns, hot);
  mergeSettings.forEach((mergeSetting) => {
    const { startRow, startColumn, endRow, endColumn } = toMergeArgs(mergeSetting);
    mergePlugin.merge(startRow, startColumn, endRow, endColumn);
  });
}

function toMergeArgs(mergeSettings: MergeCellsDetailedSettings): MergeArgs {
  return {
    startRow: mergeSettings.row,
    startColumn: mergeSettings.col,
    endRow: mergeSettings.row + mergeSettings.rowspan - 1,
    endColumn: mergeSettings.col + mergeSettings.colspan - 1,
  };
}
