<template>
  <ThePageSkeleton :scroll-y="stepsList[stepsCurrent].hasHot !== true" :page-feature-enabled="!fieldsReadOnly">
    <template #contentbar>
      <TheContentbar>
        <template #left>
          <ButtonDropdown
            v-show="currentStep === 'fields/import/preview-data' && selectedRows.length > 0"
            class="mr-2"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonUpdateSelection"
            @click="selectedRows = []"
          />
          <Button
            v-if="currentStep === 'fields/import/preview-data' && usedMappingIsCompanyMapping"
            class="text-danger"
            color="lightest"
            icon="trash-alt"
            leading-icon
            component-namespace-name="ButtonImportResetCompanyMapping"
            :disabled="fetching"
            :title="$t('Import zurücksetzen')"
            @click="resetCompanyMapping"
          />
        </template>
        <template #middle>
          <Steps :current="stepsCurrent" :steps="stepsList" />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar
            v-show="currentStep === 'fields/import/preview-data' && selectedRows.length === 0"
            :type="contentbarLarge ? 'large' : ''"
            :search-filter.sync="filterString"
          />
          <Button color="lightest" @click="back">
            {{ stepsCurrent === 0 ? $t('Abbrechen') : $t('Zurück') }}
          </Button>
          <Button :icon="['far', 'arrow-right']" :disabled="!importNextEnabled" :fetching="fetching" @click="next">
            {{ buttonNextLabel }}
          </Button>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <RouterView
        ref="currentStepComponent"
        :fields="fields"
        :import-next-enabled.sync="importNextEnabled"
        :import-method.sync="importMethod"
        :fetching.sync="fetching"
        :import-data.sync="importData"
        :import-target-company-id.sync="importTargetCompanyId"
        :import-target-process-order-id="importTargetProcessOrderId"
        :selected-rows.sync="selectedRows"
        :visible-rows.sync="visibleRows"
        :filter-string.sync="filterString"
        :manual-mapping.sync="manualMapping"
        :selected-file-group-name.sync="selectedFileGroupName"
        @back="back"
        @next="next"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { mapGetters } from 'vuex';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Steps from '@/shared/components/Steps.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import { REGION_GUIDS } from '@/shared/constants';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

library.add(faArrowRight);

export default {
  name: 'PageFieldsImport',
  components: {
    SearchBar,
    ButtonDropdown,
    Button,
    TheContentbar,
    ThePageSkeleton,
    Steps,
  },
  mixins: [buttonUpdateSelection('currentStepComponent')],
  data() {
    let importTargetCompanyId = null;
    if (this.$store.state.auth.currentCompanies.length === 1) {
      importTargetCompanyId = this.$store.state.auth.currentCompanies[0].id;
    }
    return {
      importNextEnabled: false,
      importMethod: 'upload',
      fetching: false,
      importData: null,
      importTargetCompanyId,
      selectedRows: [],
      visibleRows: [],
      filterString: '',
      manualMapping: {},
      selectedFileGroupName: null,
    };
  },
  computed: {
    ...mapGetters({
      fields: 'fields/data',
      customers: 'customers/data',
    }),
    currentStep() {
      return this.$route.name;
    },
    availableSteps() {
      const availableSteps = [];
      if (this.useStepChooseMethod) {
        availableSteps.push({
          name: 'fields/import/choose-method',
          label: this.$t('Datenquelle'),
        });
      }
      availableSteps.push({
        name: 'fields/import/load-data',
        label: this.$t('Daten laden'),
      });
      if (this.useStepMapData) {
        availableSteps.push({
          name: 'fields/import/map-data',
          label: this.$t('Daten'),
        });
      }
      availableSteps.push({
        name: 'fields/import/preview-data',
        label: this.$t('Vorschau'),
        hasHot: true,
      });
      return availableSteps;
    },
    stepsCurrent() {
      let stepsCurrent = 0;
      this.availableSteps.some((step, index) => {
        if (this.currentStep === step.name) {
          stepsCurrent = index;
          return true;
        }
        return false;
      });
      return stepsCurrent;
    },
    stepsList() {
      return this.availableSteps.map((step, index) => ({ number: index + 1, label: step.label }));
    },
    buttonNextLabel() {
      if (this.currentStep === 'fields/import/preview-data') {
        return this.$t('Importieren');
      }
      return this.$t('Weiter');
    },
    useStepChooseMethod() {
      return this.$store.state.auth.currentCompanies.some((company) => company.regionId === REGION_GUIDS.AT);
    },
    useStepMapData() {
      if (this.importData == null) {
        return false;
      }
      if (
        this.importData.mappedData == null ||
        !Array.isArray(this.importData.mappedData) ||
        this.importData.mappedData.length === 0
      ) {
        return true;
      }
      if (Array.isArray(this.importData.mappings) && this.importData.mappings.length > 1) {
        return true;
      }
      if (
        this.importData.usedMappingId != null &&
        Array.isArray(this.importData.mappings) &&
        this.importData.mappings.some(
          (mapping) => mapping.mapping.id === this.importData.usedMappingId && mapping.type === 'Manual',
        )
      ) {
        return true;
      }
      return false;
    },
    importTargetProcessOrderId() {
      if (this.importTargetCompanyId == null || this.$store.state.auth.currentProcessOrderName == null) {
        return null;
      }
      const processOrder = this.$store.getters['auth/processOrderByCompanyIdAndNameAndType'](
        this.importTargetCompanyId,
        this.$store.state.auth.currentProcessOrderName,
      );
      if (processOrder != null) {
        return processOrder.id;
      }
      return null;
    },
    fieldsReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_FIELDS);
    },
    usedMappingIsCompanyMapping() {
      if (
        this.importData == null ||
        this.importData.usedMappingId == null ||
        !Array.isArray(this.importData.mappings)
      ) {
        return false;
      }
      return this.importData.mappings.some(
        ({ mapping, type }) => mapping.id === this.importData.usedMappingId && type === 'Company',
      );
    },
  },
  mounted() {
    this.$store.dispatch('fields/subscribe');
    this.$store.dispatch('customers/subscribe');

    if (this.$route.params.method != null) {
      this.importMethod = this.$route.params.method;
    } else if (this.useStepChooseMethod) {
      this.importMethod = 'eAma';
    }

    if (this.$route.name === 'fields/import') {
      this.$router.replace({
        ...this.availableSteps[0],
        params: { method: this.importMethod },
      });
    }
  },
  methods: {
    back() {
      if (this.stepsCurrent === 0) {
        this.$router.push({ name: 'fields' });
        return;
      }
      this.$router.push({
        ...this.availableSteps[this.stepsCurrent - 1],
        params: { method: this.importMethod },
      });
    },
    async next() {
      if (this.$refs.currentStepComponent == null) {
        return;
      }
      let success = true;
      if (typeof this.$refs.currentStepComponent.next === 'function') {
        success = await this.$refs.currentStepComponent.next();
      }
      if (!success) {
        return;
      }
      this.$router.push({
        ...this.availableSteps[this.stepsCurrent + 1],
        params: { method: this.importMethod },
      });
    },
    async resetCompanyMapping() {
      this.fetching = true;
      const ok = await this.$confirm(
        'Der Import für diese Datenquelle wurde eigens für deinen Betrieb eingerichtet. Möchtest du die Einstellungen für genau diese Datenquelle zurücksetzen? Du kannst den Import anschließend erneut durchführen und die Einstellungen nochmals vornehmen.',
      );
      if (!ok) {
        this.fetching = false;
        return;
      }
      await axios.post(`/admin/rest/field/import_delete_custom_mapping/${this.importData.usedMappingId}`);
      this.fetching = false;
      this.back();
    },
  },
};
</script>

<style scoped></style>
