import { render, staticRenderFns } from "./PageNoMultiCompanySupport.vue?vue&type=template&id=21f4c3c4&scoped=true&"
import script from "./PageNoMultiCompanySupport.vue?vue&type=script&lang=js&"
export * from "./PageNoMultiCompanySupport.vue?vue&type=script&lang=js&"
import style0 from "./PageNoMultiCompanySupport.vue?vue&type=style&index=0&id=21f4c3c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f4c3c4",
  null
  
)

export default component.exports