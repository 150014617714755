<template>
  <div class="intro-description">
    <h2 class="h1 my-3">{{ headline }}</h2>
    <p>
      {{ paragraph }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FilesUploadIntroDescription',
  props: {
    headline: {
      type: String,
      default: function headlineDefault() {
        return this.$t('Datei Hochladen');
      },
    },
    paragraph: {
      type: String,
      default: '',
    },
  },
};
</script>
