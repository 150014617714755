<template lang="html">
  <ModalWrapper
    ref="modalWrapper"
    size="xl"
    body-class="modal-share-fields"
    :visible="visible"
    :title="$t('Felder freigeben')"
    @change="(value) => $emit('change', value)"
  >
    <template #default>
      <p>
        <!-- eslint-disable max-len -->
        {{
          $t(
            'Du kannst deine Felder anderen Benutzern freigeben. Du musst dafür lediglich die E-Mail des Empfängers angeben.',
          )
        }}
        <!-- eslint-enable max-len -->
      </p>
      <div class="two-cols__wrapper">
        <div class="two-cols__column">
          <ValidationObserver v-slot="{ errors }" ref="validationObserver" slim>
            <FormFieldSet :headline="$t('Empfänger')">
              <ValidationProvider v-slot="{ errors, dirty }" rules="required|email" slim :name="$t('E-Mail-Adresse')">
                <FormFieldInput
                  v-model="receiverEmail"
                  name="receiverEmail"
                  type="email"
                  autocomplete="off"
                  required
                  :label="$t('E-Mail-Adresse')"
                  :placeholder="$t('E-Mail-Adresse eingeben')"
                  :disabled="fetching"
                  :state="errors.length > 0 && !dirty ? false : null"
                  @input="veeValidateErrors = null"
                />
              </ValidationProvider>
            </FormFieldSet>
            <FormFieldSet :headline="$t('Sender')">
              <FormFieldInput
                v-model="senderReference"
                name="senderReference"
                type="text"
                autocomplete="off"
                :label="$t('Sender-Referenz')"
                :placeholder="$t('Sender-Referenz')"
                :disabled="fetching"
              />
              <ValidationProvider v-slot="{ errors, dirty }" rules="required" slim :name="$t('Betriebsnummer')">
                <FormFieldInput
                  v-model="farmNumber"
                  name="farmNumber"
                  type="text"
                  autocomplete="off"
                  required
                  :label="$t('Betriebsnummer')"
                  :placeholder="$t('Betriebsnummer')"
                  :disabled="fetching"
                  :state="errors.length > 0 && !dirty ? false : null"
                  @input="veeValidateErrors = null"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors, dirty }" rules="required" slim :name="$t('Betriebsname')">
                <FormFieldInput
                  v-model="farmName"
                  name="farmName"
                  type="text"
                  autocomplete="off"
                  required
                  :label="$t('Betriebsname')"
                  :placeholder="$t('Betriebsname')"
                  :disabled="fetching"
                  :state="errors.length > 0 && !dirty ? false : null"
                  @input="veeValidateErrors = null"
                />
              </ValidationProvider>
              <FormFieldInput
                v-model="message"
                name="message"
                type="text"
                autocomplete="off"
                :label="$t('Bemerkung')"
                :placeholder="$t('Bemerkung')"
                :disabled="fetching"
                :state="errors.length > 0 && !dirty ? false : null"
                @input="veeValidateErrors = null"
              />
            </FormFieldSet>
          </ValidationObserver>
        </div>
        <div class="two-cols__column">
          <FormFieldSetBordered :headline="$t('Geteilte Informationen:')" :description="shareInfoDescription">
            <FormFieldCheckboxGroup v-model="shareOptions" variant="no-label" class="mt-0 mb-0">
              <FormCheckbox value="fields" switch disabled>
                {{ $t('Felder und Geodaten teilen') }}
              </FormCheckbox>
              <FormCheckbox value="measures" switch>
                {{ $t('Maßnahmen teilen') }}
              </FormCheckbox>
              <FormCheckbox value="soilSamples" switch>
                {{ $t('Bodenproben teilen') }}
              </FormCheckbox>
              <FormCheckbox value="fertilizationData" switch>
                {{ $t('Nährstoffbilanz teilen') }}
              </FormCheckbox>
            </FormFieldCheckboxGroup>
          </FormFieldSetBordered>
          <FormFieldSetBordered :headline="$t('Ablaufdatum:')" :description="shareExpirationDescription">
            <FormFieldRadioGroup v-model="shareExpirationRadio" variant="no-label" class="mt-0 mb-0">
              <FormRadio value="no-expiration">{{ $t('Kein Enddatum festlegen') }}</FormRadio>
              <FormRadio value="share-until">{{ $t('Freigeben bis:') }}</FormRadio>
            </FormFieldRadioGroup>
            <BaseFormField class="mt-1 mb-1" :label="$t('Ablaufdatum')">
              <Datepicker
                class="pt-1"
                calendarClass="modal-share-fields__datepicker"
                maximumView="day"
                :monday-first="$store.getters.datepickerTranslation.language !== 'English'"
                :value="shareExpiration"
                :disabledDates="handleCalendarDate"
                :language="$store.getters.datepickerTranslation"
                @selected="setExpirationDateFromDatepicker"
              />
            </BaseFormField>
          </FormFieldSetBordered>
        </div>
      </div>
    </template>
    <template #bottom-content>
      <ErrorUserMessage :response-data="responseData" :vee-validate-errors="veeValidateErrors" class="m-0" />
    </template>
    <template #buttons>
      <BButton variant="primary" class="mr-3" :disabled="fetching" @click="share">
        <span>{{ $t('Freigeben') }}</span>
      </BButton>
      <BButton v-if="sharedFieldData" variant="outline_white" class="text-danger" @click="remove">
        <span>{{ $t('Freigabe entfernen') }}</span>
      </BButton>
      <BButton variant="outline_white" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { mapGetters } from 'vuex';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import BaseFormField from '@/shared/components/form/BaseFormField.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import FormFieldCheckboxGroup from '@/shared/components/form/FormFieldCheckboxGroup.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import FormFieldSetBordered from '@/shared/components/form/FormFieldSetBordered.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';

export default {
  name: 'ModalShareFields',
  components: {
    BaseFormField,
    FormRadio,
    FormFieldRadioGroup,
    FormFieldInput,
    ErrorUserMessage,
    ModalWrapper,
    FormFieldSet,
    FormFieldSetBordered,
    FormFieldCheckboxGroup,
    FormCheckbox,
    Datepicker,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fieldIds: {
      type: Array,
      required: true,
    },
    sharedFieldData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      responseData: null,
      fetching: false,
      source: null,
      receiverEmail: '',
      senderReference: '',
      farmNumber: '',
      farmName: '',
      message: '',
      shareOptions: ['fields'],
      shareExpiration: null,
      veeValidateErrors: null,
      handleCalendarDate: {
        customPredictor: (date) => date < new Date(),
      },
    };
  },
  computed: {
    ...mapGetters({
      fields: 'fields/dataWithoutRemoved',
    }),
    companyForFieldIds() {
      let company = null;
      this.fieldIds.some((fieldId) => {
        const field = this.fields[fieldId];
        if (
          field == null ||
          field.processOrderId == null ||
          this.$store.getters['auth/processOrdersById'][field.processOrderId] == null
        ) {
          return false;
        }
        const { companyId } = this.$store.getters['auth/processOrdersById'][field.processOrderId];
        if (companyId == null || this.$store.state.auth.companiesById[companyId] == null) {
          return false;
        }
        company = this.$store.state.auth.companiesById[companyId];
        return true;
      });
      return company;
    },
    shareInfoDescription() {
      const selectedOptions = [this.$t('Felder'), this.$t('Feldpositionen')];
      if (this.shareOptions.includes('measures')) {
        selectedOptions.push(this.$t('Maßnahmen'));
      }
      if (this.shareOptions.includes('soilSamples')) {
        selectedOptions.push(this.$t('Bodenproben'));
      }
      if (this.shareOptions.includes('fertilizationData')) {
        selectedOptions.push(this.$t('Nährstoffbilanz'));
      }
      let infoDescription = `${selectedOptions.join(', ')}.`;
      // eslint-disable-next-line no-useless-concat
      infoDescription = infoDescription.replace(/,([^,]*)$/, ` ${this.$t('und')}${'$1'}`);
      return infoDescription;
    },
    shareExpirationDescription() {
      if (this.shareExpiration == null) {
        return this.$t('Kein Enddatum gesetzt.');
      }
      return this.$t('Freigeben bis {date}.', { date: moment(this.shareExpiration).format('L') });
    },
    shareExpirationRadio: {
      get() {
        if (this.shareExpiration == null) {
          return 'no-expiration';
        }
        return 'share-until';
      },
      set(value) {
        if (value === 'no-expiration') {
          this.shareExpiration = null;
        } else if (this.shareExpiration == null) {
          const aYearFromNow = new Date();
          aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
          this.shareExpiration = aYearFromNow;
        }
      },
    },
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.reset();
      }
      if (newValue === false && oldValue === true) {
        this.$emit('resetSharedFieldData');
      }
    },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      if (this.sharedFieldData) {
        this.receiverEmail = this.sharedFieldData.sharingEmail;
        this.senderReference = this.sharedFieldData.senderReference;
        this.farmNumber = this.sharedFieldData.farmNumber;
        this.farmName = this.sharedFieldData.farmName;
        this.message = this.sharedFieldData.message;
        this.shareOptions = ['fields'];
        if (this.sharedFieldData.includeMeasures) {
          this.shareOptions.push('measures');
        }
        if (this.sharedFieldData.includeSoilSamples) {
          this.shareOptions.push('soilSamples');
        }
        if (this.sharedFieldData.includeFertilizationData) {
          this.shareOptions.push('fertilizationData');
        }
        this.shareExpiration = this.sharedFieldData.expiryDate
          ? new Date(this.sharedFieldData.expiryDate * 1000)
          : null;
        return;
      }
      this.receiverEmail = '';
      this.senderReference = '';
      this.farmNumber = this.companyForFieldIds != null ? this.companyForFieldIds.bdmId : '';
      this.farmName = this.companyForFieldIds != null ? this.companyForFieldIds.name : '';
      this.message = '';
      this.shareOptions = ['fields'];
      this.shareExpiration = null;
    },
    setExpirationDateFromDatepicker(date) {
      this.shareExpiration = date;
    },
    async share() {
      this.responseData = null;
      this.$refs.validationObserver.reset();
      await this.$refs.validationObserver.handleSubmit();
      if (!this.$refs.validationObserver.flags.valid) {
        this.veeValidateErrors = this.$refs.validationObserver.errors;
        return;
      }

      this.fetching = true;
      let response;
      try {
        const { data } = await axios.post('/admin/rest/sharedField/share', {
          version: '2.0',
          data: {
            receiverEmail: this.receiverEmail,
            fieldIds: this.fieldIds,
            includeMeasures: this.shareOptions.includes('measures'),
            includeSoilSamples: this.shareOptions.includes('soilSamples'),
            includeFertilizationData: this.shareOptions.includes('fertilizationData'),
            senderReference: this.senderReference,
            farmNumber: this.farmNumber,
            farmName: this.farmName,
            message: this.message,
            expiryDate: this.shareExpiration != null ? Math.floor(this.shareExpiration / 1000) : null,
          },
        });
        response = getRestResponseData(data);
      } catch (error) {
        console.error(error);
        response = getRestResponseData(error);
      }
      this.fetching = false;
      if (response != null && response.status === 'success') {
        this.$emit('success', response.data);
        this.$emit('change', false);
      } else {
        this.responseData = response;
      }
    },
    async remove() {
      if (!this.sharedFieldData.id) {
        return;
      }
      this.responseData = null;

      this.fetching = true;
      let response;

      try {
        const { data } = await axios.delete(`/admin/rest/sharedField/${this.sharedFieldData.id}`);
        response = getRestResponseData(data);
      } catch (error) {
        console.error(error);
        response = getRestResponseData(error);
      }

      this.fetching = false;
      if (response != null && response.status === 'success') {
        this.$emit('success', response.data);
        this.$emit('change', false);
      } else {
        this.responseData = response;
      }
    },
    hide() {
      if (this.source != null) {
        this.source.cancel();
      }
      this.$refs.modalWrapper.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.two-cols__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.two-cols__column {
  width: calc(50% - var(--spacer_3));
}

::v-deep .modal-share-fields__datepicker {
  position: fixed;
}
</style>
