
import { DaturaDroneAssessment } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import TableActivitiesLoading from '@/activities/components/table/TableActivitiesLoading.vue';
import NoContentContainer from '@/activities/containers/table/NoContentContainer.vue';
import CompanyService from '@/auth/services/CompanyService';
import TableAssessments from '@/datura/assessments/components/TableAssessments.vue';
import type { CellChangeWithIdsAssessmentTable, TableDataAssessment } from '@/datura/assessments/handsontable/types';
import TableFooterContainer from '@/shared/containers/TableFooterContainer.vue';
import TableColumnsService from '@/shared/handsontable/rework/services/TableColumnsService';
import { ColumnSettingsWithUserSettings, FarmdokColumnSettings } from '@/shared/handsontable/rework/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

export default defineComponent({
  name: 'TableAssessmentsContainer',
  props: {
    filterString: { type: String, default: '' },
    columns: {
      type: Array as PropType<Array<FarmdokColumnSettings>>,
      required: true,
    },
  },
  data() {
    return {
      debouncedRuleCheck: () => {},
      assessmentsLoaded: false,
    };
  },
  components: { TableFooterContainer, TableAssessments, TableActivitiesLoading, NoContentContainer },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters('auth', ['currentProcessOrderIds', 'currentCompanyIds']),
    assessments(): DaturaDroneAssessment[] {
      return Object.values(this.$store.state.datura.assessments.data);
    },
    tableData(): TableDataAssessment[] {
      return this.$store.getters['datura/assessments/tableDataAssessments'];
    },
    columnsForHandsontable(): ColumnSettingsWithUserSettings[] {
      const { userColumnSettings } = this.$store.state.datura.assessments;
      const tableColumnsService = new TableColumnsService(this.columns);
      const columnsForHot = tableColumnsService.getColumnsForHandsontable(userColumnSettings);
      if (this.featureDaturaReadonly) {
        return this.withReadOnly(columnsForHot);
      }
      return columnsForHot;
    },
    fixedColumnsLeft(): number {
      const fixedColumns = this.columnsForHandsontable.findIndex((column) => column.fixedColumnsLeftUntilHere === true);
      if (fixedColumns === -1) return 0;

      return fixedColumns + 1;
    },
    loading(): boolean {
      return (
        this.$store.getters['datura/assessments/loading'] ||
        this.$store.getters['fields/loading'] ||
        this.$store.getters['customers/loading']
      );
    },
    companyService(): CompanyService {
      return new CompanyService((this.$store.state as RootState).auth.companiesById);
    },
    featureDaturaReadonly(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_DATURA_CONTROL);
    },
  },
  watch: {
    currentProcessOrderIds() {
      this.loadData();
    },
    currentCompanyIds() {
      this.loadData();
    },
    assessments() {
      if (!this.assessmentsLoaded) {
        this.assessmentsLoaded = true;
      } else {
        this.debouncedRuleCheck();
      }
    },
  },
  methods: {
    withReadOnly(columnsForHot: ColumnSettingsWithUserSettings[]): ColumnSettingsWithUserSettings[] {
      return columnsForHot.map((column) => {
        if ((column.data as string).includes('expand')) {
          return column;
        }

        if (column.subtableColumns) {
          return {
            ...column,
            readOnly: true,
            subtableColumns: column.subtableColumns.map((subtableColumn: any) => ({
              ...subtableColumn,
              readOnly: true,
            })),
          };
        }

        return {
          ...column,
          readOnly: true,
        };
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(changes: CellChangeWithIdsAssessmentTable[], source: Handsontable.ChangeSource) {
      changes.forEach((change) => {
        const [, key, , newValue, { id: assessmentId }] = change;
        const metadataKeys = ['expand', 'select'];

        if (!metadataKeys.includes(key)) {
          this.debouncedRuleCheck();
        }

        if (key === 'fieldDropdownItem') {
          this.onChangeFieldDropdownItem(change);
          return;
        }

        if (metadataKeys.includes(key)) {
          this.$store.commit('datura/assessments/addOrUpdateTableDataMetadataByKeyAndValue', {
            assessmentId,
            key,
            value: newValue,
          });
          return;
        }

        this.$store.dispatch('dataura/assessments/updateEntryByKeyAndValueAndSync', {
          guid: assessmentId,
          key,
          value: newValue,
        });
      });
    },
    onChangeFieldDropdownItem(change: CellChangeWithIdsAssessmentTable) {
      const { id: fieldId } = change[3];
      const { id: assessmentId } = change[4];

      const updatedField = (this.$store.state as RootState).fields.data[fieldId];

      this.$store.commit('datura/assessments/updateEntryByKeyAndValue', {
        guid: assessmentId,
        key: 'processedArea',
        value: updatedField.fieldSize,
      });

      this.$store.dispatch('datura/assessments/updateEntryByKeyAndValueAndSync', {
        guid: assessmentId,
        key: 'fieldId',
        value: fieldId,
      });
    },
    loadData() {
      this.$store.dispatch('fields/subscribe');
      this.$store.dispatch('datura/assessments/subscribe');
      this.$store.dispatch('customers/subscribe');
    },
    refreshData() {
      this.$store.dispatch('datura/assessments/refresh');
      this.$store.dispatch('fields/refresh');
      this.$store.dispatch('customers/refresh');
    },
    onUpdateHot(hot: Handsontable | null) {
      this.$store.commit('datura/assessments/setHot', hot);
      this.$emit('update:hot', hot);
    },
  },
});
