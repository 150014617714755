const availableFeatures = {
  /**
   * Adds customer objects to the company. Customer can be used for field-groups (and indirectly fields), as they can be assigned to a customer.
   * Visible and enabled get defined by the license.
   * Only farm managers should be able to manage (view, create, delete, ...) customers.
   *
   * The customer column in the fields and field-groups tables should only be available to the user if the company has at least one customer.
   * This is done in the column management directly (fields/handsontable/columns/tableFieldsImport.js) and not handled by the features.
   *
   * @type {string}
   */
  FEATURE_CUSTOMERS: 'FEATURE_CUSTOMERS',

  /**
   * Adds an additional column to the fields table (gwArea). If a field is set to have a specific value for this column this
   * changes certain nutrient values in the cropping plan.
   *
   * @type {string}
   */
  FEATURE_GROUNDWATER_PROTECTION: 'FEATURE_GROUNDWATER_PROTECTION',

  /**
   * Adds the soil sample feature to the company. They can be accessed by a separate tab on the fields pages
   * and are displayed on the SatelliteFieldDetail page.
   */
  FEATURE_SOIL_SAMPLES: 'FEATURE_SOIL_SAMPLES',

  /**
   * Adds the crop rotation feature. Shows the crop rotation on the field records page if visible.
   */
  FEATURE_CROP_ROTATION: 'FEATURE_CROP_ROTATION',

  /**
   * Adds the notes feature. Show the notes on the field records page if visible.
   */
  FEATURE_NOTES: 'FEATURE_NOTES',

  /**
   * Specifies if the current user can buy licenses for the company. Only the 'enabled' flag should be used.
   */
  CAN_PURCHASE_LICENSE: 'CAN_PURCHASE_LICENSE',

  /**
   * Adds the crop rotation feature in field details (e.g. WidgetCropRotation.vue)
   */
  FEATURE_FIELD_DETAIL_CROP_ROTATION: 'FEATURE_FIELD_DETAIL_CROP_ROTATION',

  /**
   * Adds the main feature 'Fields'. This means mainly, but not exclusively, the handsontable.
   * Currently enabled for all licenses but read-only for user roles Employee and Read.
   * If this feature is read-only the user should not be able to edit/insert/delete fields in any way. (e.g. field import, insert modal, etc.)
   */
  FEATURE_FIELDS: 'FEATURE_FIELDS',

  /**
   * Extends the fields feature with the following actions:
   * - split
   * - combine
   * - move
   */
  FEATURE_FIELDS_ADVANCED: 'FEATURE_FIELDS_ADVANCED',

  /**
   * Adds the feature to view shared fields (i.e. fields from other companies shared with the current company/companies)
   */
  FEATURE_FIELDS_SHARED: 'FEATURE_FIELDS_SHARED',

  /**
   * Adds the main feature 'Task plan'.
   * Currently available for Premium, Performance and Contractor licenses.
   */
  FEATURE_TASK_PLAN: 'FEATURE_TASK_PLAN',

  /**
   * Adds the main feature 'Stock movements and Stock levels'.
   * Currently available for Premium, Performance and Contractor licenses.
   */
  FEATURE_STOCK_MOVEMENT: 'FEATURE_STOCK_MOVEMENT',

  /**
   * Adds the main feature 'Application maps' (precision farming). If not enabled but visible, a preview page should be shown.
   * Also used to control the availability of application maps workflows that are available in all regions
   * Currently only enabled in Performance licenses.
   */
  FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC: 'FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC',

  /**
   * Adds the availability feature for the Saatbau application maps workflow, which is only available for AT companies.
   */
  FEATURE_APPLICATION_MAPS_SEEDING_SAATBAU_MAIZE: 'FEATURE_APPLICATION_MAPS_SEEDING_SAATBAU_MAIZE',

  /**
   * Adds the availability feature for the Pflanzenschutz application maps workflow.
   */
  FEATURE_APPLICATION_MAPS_SPRAYING_FD_BASIC: 'FEATURE_APPLICATION_MAPS_SPRAYING_FD_BASIC',
  /**
   * Adds the main feature 'Vegetation monitoring' (precision farming). If not enabled but visible, a preview page should be shown.
   * Currently only enabled in Performance licenses.
   */
  FEATURE_VEGETATION_MONITORING: 'FEATURE_VEGETATION_MONITORING',

  /**
   * Adds the main feature 'Cost accounting'.
   * Currently available in Premium, Performance and Contractor licenses.
   */
  FEATURE_COST_ACCOUNTING: 'FEATURE_COST_ACCOUNTING',

  /**
   * Adds the main feature 'Cropping plan'.
   * Currently available in Premium and Performance licenses and in Pro licenses (deprecated) for AT companies.
   */
  FEATURE_CROPPING_PLAN: 'FEATURE_CROPPING_PLAN',

  /**
   * Adds the main feature 'Fertilization plan'.
   * Currently available in Premium and Performance licenses and in Pro licenses (deprecated) for AT companies.
   */
  FEATURE_FERTILIZATION_PLAN: 'FEATURE_FERTILIZATION_PLAN',

  /**
   * Adds the main feature 'Digital contracting'.
   * Currently available only in AT for Premium and Performance licenses.
   */
  FEATURE_DIGITAL_CONTRACTING: 'FEATURE_DIGITAL_CONTRACTING',

  /**
   * Adds the main feature 'Fertilizer regulation'.
   * Currently available only in Germany (DE) for Premium and Performance licenses.
   */
  FEATURE_FERTILIZER_REGULATION: 'FEATURE_FERTILIZER_REGULATION',

  /**
   * Adds the main feature 'Nutrient comparison'.
   * Currently available only in Germany (DE) for Premium and Performance licenses.
   */
  FEATURE_NUTRIENT_COMPARISON: 'FEATURE_NUTRIENT_COMPARISON',

  /**
   * Adds the main feature 'Substance flow'.
   * Currently available only in Germany (DE) for Premium and Performance licenses.
   */
  FEATURE_SUBSTANCE_FLOW: 'FEATURE_SUBSTANCE_FLOW',

  /**
   * Determines whether the crop ratio pie chart in the small satellite should be shown.
   */
  FEATURE_CROP_RATIO: 'FEATURE_CROP_RATIO',

  /**
   * Adds the field export (all formats):
   * - export as pdf/xls/isoxml
   * - send field positions via email
   */
  FEATURE_EXPORT_FIELD: 'FEATURE_EXPORT_FIELD',

  /**
   * Adds the nutrient balance charts widget in the field detail page (field record).
   */
  FEATURE_NUTRIENT_BALANCE: 'FEATURE_NUTRIENT_BALANCE',

  /**
   * Used to check if the user can add process orders to the current companies.
   * Visible and enabled should always be true, readonly can be true.
   */
  FEATURE_PROCESS_ORDER: 'FEATURE_PROCESS_ORDER',

  /**
   * Adds the weather info widget to the field detail page (field record)
   * as well as to the Vegetation monitoring feature.
   */
  FEATURE_WEATHER_INFO: 'FEATURE_WEATHER_INFO',

  FEATURE_FIELDS_NDVI_MEAN: 'FEATURE_FIELDS_NDVI_MEAN',
  FEATURE_MANAGED_CONTRACTS: 'FEATURE_MANAGED_CONTRACTS',

  /**
   * Adds the possibility to create a fertilization plan for fields. Especially for "fertilization for cereals".
   */
  FEATURE_APPLICATION_MAPS_TERRAZO: 'FEATURE_APPLICATION_MAPS_TERRAZO',

  /**
   * Adds the main feature 'Activities'. This means mainly, but not exclusively, the handsontable.
   * Currently enabled for all licenses but read-only for user roles Employee and Read.
   * If this feature is read-only the user should not be able to create/edit/delete/duplicate activities in any way. (e.g. activity import, create modal, etc.)
   */
  FEATURE_ACTIVITIES: 'FEATURE_ACTIVITIES',

  /**
   * Adds the possibility to move activities to other companies or to other crop years.
   */
  FEATURE_MOVE_ACTIVITIES: 'FEATURE_MOVE_ACTIVITIES',

  /**
   * Adds the possibility to export activities.
   */
  FEATURE_ACTIVITIES_EXPORT: 'FEATURE_ACTIVITIES_EXPORT',

  /**
   * Adds the possibility to create/edit/remove equipments
   */
  FEATURE_EQUIPMENT: 'FEATURE_EQUIPMENT',

  /**
   * Adds the possibility to create/edit/remove pests
   */
  FEATURE_PESTS: 'FEATURE_PESTS',

  /**
   * Adds the possibility to perform rule check (=live check) on activities.
   * If this feature is in read-only the user should not be able to dismiss rule check warnings
   */
  FEATURE_RULE_CHECK: 'FEATURE_RULE_CHECK',

  /**
   * Adds the possibility to view pesticide details
   */
  FEATURE_ACTIVITIES_PESTICIDE_DETAILS: 'FEATURE_ACTIVITIES_PESTICIDE_DETAILS',

  /**
   * Adds the possibility to view the map feature
   */
  FEATURE_MAP: 'FEATURE_MAP',
  /**
   * Adds the possibility to draw land cadastres on the map
   */
  FEATURE_MAP_LAND_CADASTRE: 'FEATURE_MAP_LAND_CADASTRE',
  /**
   * Adds the possibility to draw isolation zones or multiplier groups on the map
   */
  FEATURE_MAP_GEO_OBJECTS: 'FEATURE_MAP_GEO_OBJECTS',
  /**
   * Adds the possibility to restrict field names to be unique inside of one crop year
   */
  FEATURE_FIELD_NAMES_UNIQUE: 'FEATURE_FIELD_NAMES_UNIQUE',
  /**
   * Enables the Datura control center feature depending on the license
   */
  FEATURE_DATURA_CONTROL: 'FEATURE_DATURA_CONTROL',
};

export default availableFeatures;
