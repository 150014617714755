import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/**
 * use a fontawesome icon definition to generate a string of svg markup
 * e.g. use in Datepicker custom day content renderer (where you cannot use vue components)
 */
export default function iconAsSvgString(icon: IconDefinition): string {
  return `<svg
    aria-hidden="true"
    focusable="false"
    data-prefix="${icon.prefix}"
    data-icon="${icon.iconName}"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 ${icon.icon[0]} ${icon.icon[1]}"
    class="svg-inline--fa ${icon.iconName}"
  >
    ${icon.prefix === 'fad' ? '<g class="fa-duotone-group">' : ''}
      <path
        fill="currentColor"
        d="${icon.icon[4]}"
        class="${icon.prefix === 'fad' ? 'fa-secondary' : ''}"
      ></path>
    ${icon.prefix === 'fad' ? '</g>' : ''}
  </svg>`;
}
