import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { RuleViolationType } from 'farmdok-rest-api';
import Handsontable from 'handsontable';

import { ImportStatus, ImportStatusType } from '@/activities/importActivities/types/TableDataImportActivity.types';
import { RuleCheckStatus } from '@/activities/types';
import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';

import { WarningIconRenderer } from '../warningIconRenderer';
import { ColumnSettingsWarningIcon } from '../warningIconRenderer/types';
import { ColumnSettingsRuleCheckStatus } from './types';

library.add(faCircleExclamation);

export default function RuleCheckStatusRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visualRow: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visualCol: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  prop: string | number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value: RuleCheckStatus | ImportStatus | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cellProperties: ColumnSettingsRuleCheckStatus,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...args: unknown[]
) {
  switch (value?.type) {
    case RuleViolationType.NotChecked:
    case ImportStatusType.DUPLICATE:
      setWarningIconCellProperties(cellProperties, 'gray', value.tooltipLabel);
      WarningIconRenderer(
        instance,
        TD,
        visualRow,
        visualCol,
        prop,
        '',
        cellProperties as ColumnSettingsWarningIcon,
        ...args,
      );
      return;
    case RuleViolationType.Violation:
    case ImportStatusType.INCOMPLETE:
      setWarningIconCellProperties(cellProperties, 'red', value.tooltipLabel);
      WarningIconRenderer(
        instance,
        TD,
        visualRow,
        visualCol,
        prop,
        '',
        cellProperties as ColumnSettingsWarningIcon,
        ...args,
      );
      return;
    default: // render empty cell
      Handsontable.dom.empty(TD);
      applyMergeRowsBorderStyles(TD, visualRow, instance);
  }
}

/* eslint-disable no-param-reassign */
function setWarningIconCellProperties(
  cellProperties: ColumnSettingsRuleCheckStatus,
  color: 'gray' | 'red',
  tooltipLabel: string | null,
) {
  if (!cellProperties.warningIcon) {
    cellProperties.warningIcon = {};
  }
  (cellProperties as ColumnSettingsWarningIcon).warningIcon.color = color;
  (cellProperties as ColumnSettingsWarningIcon).warningIcon.tooltipLabel = tooltipLabel;
}
/* eslint-enable no-param-reassign */
