import { Activity, ActivityBase } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionTree } from 'vuex';

import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import ActivitySyncService from '@/activities/services/ActivitySyncService';
import { ApiResponse, isRestErrorResponse } from '@/shared/api/farmdokRestApi/types';
import { ENTRY_SYNCED } from '@/shared/constants';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { createUuid } from '@/shared/modules/uuid';
import { RootState } from '@/store/types';

import { patchEntry, removableStore, subscribableStore, syncableStore } from '../common';
import { ActivitiesState, ActivityDuplication } from '../types';
import updateActivityTypeAndSync from './updateActivityTypeAndSync';

type ActivityIdActivityProductId = { activityId: string; activityProductId: string };
type ActivityIdActivityEquipmentId = { activityId: string; activityEquipmentId: string };

export type ActionPayloads = {
  addActivityProduct: string;
  addActivityEquipment: string;
  removeOrDeleteActivityProductAndSync: ActivityIdActivityProductId;
  removeActivityProduct: ActivityIdActivityProductId;
  deleteActivityProduct: ActivityIdActivityProductId;
  deleteActivityProductAndSync: ActivityIdActivityProductId;
  removeOrDeleteActivityEquipmentAndSync: ActivityIdActivityEquipmentId;
  removeActivityEquipment: ActivityIdActivityEquipmentId;
  deleteActivityEquipment: ActivityIdActivityEquipmentId;
  deleteActivityEquipmentAndSync: ActivityIdActivityEquipmentId;
  updateActivityTypeAndSync: { activityId: string; activityTypeId: string };
  duplicate: string[];
  delete: Activity[];
};

const actions: ActionTree<ActivitiesState, RootState> = {
  ...subscribableStore.actions,
  ...syncableStore.actions,
  ...removableStore.actions,
  // ...restorableData.actions,
  addActivityProduct({ state, commit }, activityId: ActionPayloads['addActivityProduct']) {
    const activity = state.data[activityId];
    if (!activity) return;

    const newActivityProduct = ActivityProductService.createActivityProduct();

    const updatedActivityProducts = [...activity.products, newActivityProduct];
    commit('updateEntryByKeyAndValue', { guid: activity.id, key: 'products', value: updatedActivityProducts });
  },
  addActivityEquipment({ state, commit }, activityId: ActionPayloads['addActivityEquipment']) {
    const activity = state.data[activityId];
    if (!activity) return;

    const newActivityEquipment = ActivityEquipmentService.createActivityEquipment();

    const updatedActivityEquipment = [...activity.equipment, newActivityEquipment];
    commit('updateEntryByKeyAndValue', { guid: activity.id, key: 'equipment', value: updatedActivityEquipment });
  },
  removeOrDeleteActivityProductAndSync(
    { state, commit, dispatch },
    { activityId, activityProductId }: ActionPayloads['removeOrDeleteActivityProductAndSync'],
  ) {
    const activity = state.data[activityId];
    if (!activity) return;

    const activityProduct = activity.products.find((product) => product.id === activityProductId);
    if (!activityProduct) return;

    if (ActivitySyncService.isSyncableActivityProduct(activityProduct)) {
      dispatch('deleteActivityProductAndSync', { activityId, activityProductId });
    } else {
      // activityProduct wasn't synced to backend yet, so we can just remove it from store and set storeStatus to synced
      dispatch('removeActivityProduct', { activityId, activityProductId });
      commit('updateStoreStatusOfEntry', { guid: activity.id, value: ENTRY_SYNCED });
    }
  },
  removeActivityProduct({ state, commit }, { activityId, activityProductId }: ActionPayloads['removeActivityProduct']) {
    const activity = state.data[activityId];
    if (!activity) return;

    const updatedActivityProducts = activity.products.filter((product) => product.id !== activityProductId);
    commit('updateEntryByKeyAndValue', { guid: activity.id, key: 'products', value: updatedActivityProducts });
  },
  deleteActivityProduct({ state, commit }, { activityId, activityProductId }: ActionPayloads['deleteActivityProduct']) {
    const activity = state.data[activityId];
    if (!activity) return;

    const activityProduct = activity.products.find((product) => product.id === activityProductId);
    if (!activityProduct) return;

    activityProduct.deleted = toUnixTimestamp(Date.now());
    commit('updateEntryByKeyAndValue', { guid: activity.id, key: 'products', value: activity.products });
  },
  async deleteActivityProductAndSync(
    { state, commit, dispatch },
    { activityId, activityProductId }: ActionPayloads['deleteActivityProductAndSync'],
  ) {
    dispatch('deleteActivityProduct', { activityId, activityProductId });

    commit('syncAllStart');
    const activity = state.data[activityId];
    if (!activity) throw new Error(`Activity not found: ${activityId}`);
    commit('syncAllAddEntries', [activity]);

    const patch: ActivityBase = {
      processOrderId: activity.processOrderId,
      id: activity.id,
      products: ActivitySyncService.toSyncableActivityProducts(activity.products),
    };

    let response: ApiResponse<Activity>;
    try {
      response = await patchEntry(patch);
      switch (response.status) {
        case 'success':
          commit('syncAllFinish', response.data);
          return;
        case 'partialSuccess':
        case 'error':
          if (isRestErrorResponse(response)) {
            commit('addSyncError', {
              guid: activityId,
              key: null,
              errorUserMessage:
                response.errorUserMessage?.[0] || Vue.i18n.translate('Es ist ein unbekannter Fehler aufgetreten.'),
            });
          }
          return;
        default:
          commit('addSyncError', {
            guid: activityId,
            key: null,
            errorUserMessage: Vue.i18n.translate('Es ist ein unbekannter Fehler aufgetreten.'),
          });
      }
    } catch (error) {
      console.error(error);
      commit('addSyncError', {
        guid: activityId,
        key: null,
        errorUserMessage: [Vue.i18n.translate('Es ist ein unbekannter Fehler aufgetreten.')],
      });
    }
  },
  removeOrDeleteActivityEquipmentAndSync(
    { state, dispatch },
    { activityId, activityEquipmentId }: ActionPayloads['removeOrDeleteActivityEquipmentAndSync'],
  ) {
    const activity = state.data[activityId];
    if (!activity) return;

    const activityEquipment = activity.equipment.find((equipment) => equipment.id === activityEquipmentId);
    if (!activityEquipment) return;

    if (ActivitySyncService.isSyncableActivityEquipment(activityEquipment)) {
      dispatch('deleteActivityEquipmentAndSync', { activityId, activityEquipmentId });
    } else {
      // activityEquipment wasn't synced to backend yet, so we can just remove it from store
      dispatch('removeActivityEquipment', { activityId, activityEquipmentId });
    }
  },
  removeActivityEquipment(
    { state, commit },
    { activityId, activityEquipmentId }: ActionPayloads['removeActivityEquipment'],
  ) {
    const activity = state.data[activityId];
    if (!activity) return;

    const updatedActivityEquipment = activity.equipment.filter((equipment) => equipment.id !== activityEquipmentId);
    commit('updateEntryByKeyAndValue', { guid: activity.id, key: 'equipment', value: updatedActivityEquipment });
  },
  deleteActivityEquipment(
    { state, commit },
    { activityId, activityEquipmentId }: ActionPayloads['deleteActivityEquipment'],
  ) {
    const activity = state.data[activityId];
    if (!activity) return;

    const activityEquipment = activity.equipment.find((equipment) => equipment.id === activityEquipmentId);
    if (!activityEquipment) return;

    activityEquipment.deleted = toUnixTimestamp(Date.now());
    commit('updateEntryByKeyAndValue', { guid: activity.id, key: 'equipment', value: activity.equipment });
  },
  async deleteActivityEquipmentAndSync(
    { state, commit, dispatch },
    { activityId, activityEquipmentId }: ActionPayloads['deleteActivityEquipmentAndSync'],
  ) {
    dispatch('deleteActivityEquipment', { activityId, activityEquipmentId });

    commit('syncAllStart');
    const activity = state.data[activityId];
    if (!activity) throw new Error(`Activity not found: ${activityId}`);
    commit('syncAllAddEntries', [activity]);

    const patch: ActivityBase = {
      id: activity.id,
      processOrderId: '',
      equipment: activity.equipment,
    };

    let response: ApiResponse<Activity>;
    try {
      response = await patchEntry(patch);
      switch (response.status) {
        case 'success':
          commit('syncAllFinish', response.data);
          return;
        case 'partialSuccess':
        case 'error':
          if (isRestErrorResponse(response)) {
            commit('addSyncError', {
              guid: activityId,
              key: null,
              errorUserMessage:
                response.errorUserMessage?.[0] || Vue.i18n.translate('Es ist ein unbekannter Fehler aufgetreten.'),
            });
          }
          return;
        default:
          commit('addSyncError', {
            guid: activityId,
            key: null,
            errorUserMessage: Vue.i18n.translate('Es ist ein unbekannter Fehler aufgetreten.'),
          });
      }
    } catch (error) {
      console.error(error);
      commit('addSyncError', {
        guid: activityId,
        key: null,
        errorUserMessage: [Vue.i18n.translate('Es ist ein unbekannter Fehler aufgetreten.')],
      });
    }
  },
  updateActivityTypeAndSync,
  async duplicate({ state, dispatch }, activityIds: ActionPayloads['duplicate']): Promise<ActivityDuplication[]> {
    const duplications: ActivityDuplication[] = [];
    activityIds.forEach((activityId, index) => {
      const activity = state.data[activityId];
      if (!activity) return;
      const creationDate = toUnixTimestamp(Date.now());
      const duplicateId = createUuid();

      const duplicatedProducts = activity.products.map((p) => ({ ...p, id: createUuid() }));
      const duplicatedEquipment = activity.equipment.map((e) => ({ ...e, id: createUuid() }));
      const duplicatedActivity = {
        ...activity,
        id: duplicateId,
        creationDate,
        products: duplicatedProducts,
        equipment: duplicatedEquipment,
      };
      if (index === activityIds.length - 1) {
        dispatch('insertAndSyncNewEntry', { entry: duplicatedActivity });
      } else {
        dispatch('insertNewEntry', { entry: duplicatedActivity });
      }
      duplications.push({ originalId: activityId, duplicateId });
    });
    return duplications;
  },

  async delete({ dispatch, commit }, activities: ActionPayloads['delete']) {
    activities.forEach((activity: Activity) => {
      commit('removeEntry', { entry: activity });
    });
    dispatch('syncAllRemovals');
  },
};

export default actions;
