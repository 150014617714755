import Handsontable from 'handsontable';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import { UserColumnSettings } from '@/shared/handsontable/rework/types';

import { DaturaState } from './types';

const mutations: MutationTree<DaturaState> = {
  setHot(state, hot: Handsontable | null) {
    Vue.set(state, 'hot', hot);
  },
  setUserColumnSettings(state, userColumnSettings: Record<string, UserColumnSettings>) {
    Vue.set(state, 'userColumnSettings', userColumnSettings);
  },
};

export default mutations;
