import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      varieties: 'varieties',
      varietiesUsed: 'varieties/used',
    }),
    varietiesToDropdownItems() {
      if (this.$store.state.auth.currentCompanies.length === 0) {
        return [];
      }
      // In multiCompany mode only allow synced materials. Also only add the synced material once.
      const multiCompany = this.$store.state.auth.currentCompanies.length > 1;
      const items = Object.values(this.varieties).filter((variety) => {
        if (this.varietiesUsed.includes(variety.id)) {
          return false;
        }
        if (!multiCompany) {
          return true;
        }
        return variety.companyId == null;
      });
      return [
        {
          name: this.$t('Zuletzt verwendet'),
          id: 'varieties-used',
          items: this.varietiesUsed.map((id) => ({ id, name: this.varieties[id].name })),
          sort: false,
        },
        {
          name: this.$t('Sorten'),
          id: 'varieties',
          items: items.map((variety) => ({ id: variety.id, name: variety.name })),
          sort: true,
        },
      ];
    },
  },
};
