import { Activity } from 'farmdok-rest-api';

import { CreateEditActivityState } from '../../types';

export default function getActivityById(state: CreateEditActivityState, id: string): Activity {
  const activity = state.activities.find((a) => a.id === id);
  if (!activity) throw new Error(`Activity with id ${id} not found`);

  return activity;
}
