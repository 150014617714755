import { ENTRY_REMOVED, ENTRY_REMOVING, ENTRY_TO_REMOVE } from '@/shared/constants';

/**
 * Depends on tableBase.js, featureInsertRow.js and featureRemoveRow.js
 * Whenever tableData changes the watch function checks if all rows that should be rendered are set as handsontable data.
 * Then calls the corresponding functions from the other features.
 *
 * You can disable this watch (as it can affect performance for bigger tables), but then you have to manually call the
 * insert/remove functions when tableData changes happen. (e.g. after a store mutation occurs)
 */
export default {
  props: {
    observeTableDataDisable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    async tableDataComputed() {
      if (this.observeTableDataDisable || this.hot == null) {
        return;
      }
      Object.keys(this.tableDataComputed).forEach((id) => {
        if (
          this.tableDataComputed[id] != null &&
          ![ENTRY_REMOVED, ENTRY_TO_REMOVE, ENTRY_REMOVING].includes(this.tableDataComputed[id].storeStatus) &&
          this.tablePhysicalRowByGuid[id] == null
        ) {
          this.insertEntryFromStore(this.tableDataComputed[id]);
        }
      });

      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 30));
      this.tableGuidByPhysicalRow.forEach((id) => {
        if (this.tableDataComputed[id] == null || this.tableDataComputed[id].storeStatus === ENTRY_REMOVED) {
          this.removeEntryFromStore({ id });
        }
      });
    },
  },
};
