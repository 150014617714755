<template>
  <div class="h-100">
    <TableBase
      id="hot-contracting-import-total-yields-field-mapping"
      ref="table"
      disable-read-only-bg
      :filter-string="filterString"
      :table-settings="tableSettings"
      :selected-rows="selectedRows"
      :table-data="tableDataComputed"
      :loading="loading"
      :cells="tableHookCells"
      @tableMounted="tableMounted"
      @update:visibleRows="(value) => $emit('update:visibleRows', value)"
      @update:selectedRows="(value) => $emit('update:selectedRows', value)"
      @update:cell="updateCell"
      @update:filterString="(value) => $emit('update:filterString', value)"
    >
      <template #footer>
        <TableFooter
          :total-rows="Object.keys(tableData).length"
          :visible-rows="Object.keys(tableData).length"
          :selected-rows="selectedRows.length"
        >
          <span class="mx-2">{{ $t('Gewählte Fläche: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}</span>
          <span class="mx-2">{{
            $t('Gesamtfläche aus Erntedaten: {totalSize} ha', { totalSize: totalYieldsFormatted.flaeche })
          }}</span>
          <span class="mx-2">{{
            $t('Erntemenge gesamt: {totalPotatoes} t', { totalPotatoes: totalYieldsFormatted.reinekartoffel })
          }}</span>
          <span class="mx-2">{{
            $t('Stärkegehalt gesamt: {totalStarch} t', { totalStarch: totalYieldsFormatted.staerkegehalt })
          }}</span>
        </TableFooter>
      </template>
    </TableBase>
  </div>
</template>

<script>
import axios from 'axios';
import numbro from 'numbro';

import { crop as columnFieldCrop } from '@/fields/handsontable/columns/columns';
import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import loadTableData from '@/shared/handsontable/mixins/containers/featureLoadTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import {
  amount as columnAmount,
  creationDate as columnCreationDate,
  processedArea as columnProcessedArea,
  userComment as columnUserComment,
} from '../handsontable/columns/columns';
import columnsTableImportTotalYieldsFieldMapping, {
  fieldIdKey,
  fieldSizeKey,
  materialKey,
} from '../handsontable/columns/tableImportTotalYieldsFieldMapping';

const manualUserCommentKey = 'manualUserComment';
const manualAmountKey = 'manualAmount';

export default {
  name: 'TableImportTotalYieldsFieldMappingContainer',
  components: { TableFooter, TableBase },
  mixins: [tableContainerBase, loadTableData, calculateSizeTotal],
  props: {
    filterString: {
      type: String,
      default: '',
    },
    totalYields: {
      type: Object,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columnDefinition: columnsTableImportTotalYieldsFieldMapping,
      loading: true,
      selectedRows: [],
      crops: [],
    };
  },
  computed: {
    totalYieldsFormatted() {
      return {
        flaeche: numbro(this.totalYields.flaeche).format(),
        reinekartoffel: numbro(this.totalYields.reinekartoffel).format(),
        staerkegehalt: numbro(this.totalYields.staerkegehalt).format(),
      };
    },
    fieldsWithManualPotatoAmounts() {
      return Object.values(this.tableData).filter((row) => row[manualAmountKey] && this.selectedRows.includes(row.id));
    },
    sumOfManualPotatoAmounts() {
      return this.fieldsWithManualPotatoAmounts.reduce((accumulator, row) => accumulator + row[columnAmount.key], 0);
    },
    sumOfFieldSizesForFieldsWithManualPotatoAmounts() {
      return this.fieldsWithManualPotatoAmounts.reduce((accumulator, row) => accumulator + row[fieldSizeKey], 0);
    },
    tableDataComputed() {
      return Object.values(this.tableData).reduce((tableData, row) => {
        if (!this.selectedRows.includes(row.id)) {
          return {
            ...tableData,
            [row.id]: row,
          };
        }
        let potatoAmount = null;
        let starchAmount = null;
        if (row[manualAmountKey]) {
          potatoAmount = row[columnAmount.key];
        } else if (row[fieldSizeKey] != null && row[fieldSizeKey] !== '') {
          potatoAmount =
            ((this.totalYields.reinekartoffel - this.sumOfManualPotatoAmounts) /
              (this.sizeTotal - this.sumOfFieldSizesForFieldsWithManualPotatoAmounts)) *
            row[fieldSizeKey];
        }

        if (potatoAmount !== null) {
          starchAmount =
            Math.round((this.totalYields.staerkegehalt / this.totalYields.reinekartoffel) * potatoAmount * 100) / 100;
          potatoAmount = Math.round(potatoAmount * 100) / 100;
        }

        let userComment = row[columnUserComment.key];
        if (!row[manualUserCommentKey] && potatoAmount != null) {
          userComment = this.$t('Reinekartoffel: {amountPotato} Stärkegehalt: {amountStarch}', {
            amountPotato: numbro(potatoAmount).format(),
            amountStarch: numbro(starchAmount).format(),
          });
        }

        return {
          ...tableData,
          [row.id]: {
            ...row,
            [columnProcessedArea.key]: row[fieldSizeKey],
            [columnAmount.key]: potatoAmount,
            [columnUserComment.key]: userComment,
          },
        };
      }, {});
    },
    cropsById() {
      return this.crops.reduce(
        (cropsById, crop) => ({
          ...cropsById,
          [crop.id]: crop,
        }),
        {},
      );
    },
    selectedTableData() {
      if (this.selectedRows.length === 0) {
        return null;
      }

      if (this.sumOfManualPotatoAmounts > this.totalYields.reinekartoffel) {
        return null;
      }

      if (
        this.selectedRows.some((id) => {
          const row = this.tableData[id];
          return (
            row[materialKey] == null ||
            row[columnCreationDate.key] == null ||
            row[fieldSizeKey] == null ||
            row[fieldSizeKey] <= 0 ||
            row[columnAmount.key] == null ||
            row[columnAmount.key] < 0
          );
        })
      ) {
        return null;
      }
      return this.selectedRows.map((id) => this.tableData[id]);
    },
  },
  watch: {
    selectedTableData(newVal) {
      this.$emit('update:selectedTableData', newVal);
    },
  },
  created() {
    this.columnSettingsMiddleware.push(this.columnDropdownData);
  },
  async mounted() {
    await Promise.all([
      this.loadTableData(`/admin/digitalContracting/getFields/${this.contract.id}?ignoreFieldLimitations=true`),
      this.loadCrops(),
    ]);
    this.tableData = Object.values(this.tableData).reduce((tableData, row, index) => {
      const id = String(index + 1);
      let materialId = null;
      if (Object.keys(this.cropsById).includes(String(row[columnFieldCrop.key]))) {
        materialId = row[columnFieldCrop.key];
      }
      return {
        ...tableData,
        [id]: {
          ...row,
          id,
          [fieldIdKey]: row.id,
          [materialKey]: materialId,
          [columnCreationDate.key]: null,
          [columnAmount.key]: null,
          [columnUserComment.key]: null,
          [manualUserCommentKey]: false,
          [manualAmountKey]: false,
        },
      };
    }, {});
    this.loading = false;
    // loadTableData already set loading to false and triggered setData() once. So we have to manually call it again
    // do it on $nextTick to get tableDataComputed to be calculated first.
    this.$nextTick(() => {
      this.$refs.table.setData(true);
    });
  },
  methods: {
    async loadCrops() {
      const { data } = await axios.get(`/admin/digitalContracting/getAllowedCrops/${this.contract.id}`);
      this.crops = data;
    },
    updateCell({ columnKey, guid: id, value }) {
      let columnType = 'text';
      this.tableSettings.columns.some((column) => {
        if (column.key === columnKey) {
          columnType = column.type;
          return true;
        }
        return false;
      });
      if (columnType === 'numeric' && Number.isNaN(Number(value))) {
        // eslint-disable-next-line no-param-reassign
        value = null;
      }

      const tableData = { ...this.tableData };
      if (columnKey === columnUserComment.key) {
        tableData[id][manualUserCommentKey] = value != null;
      } else if (columnKey === columnAmount.key) {
        tableData[id][manualAmountKey] = value != null && value !== '';
      }
      tableData[id][columnKey] = value;
      this.tableData = tableData;
    },
    tableHookCells(physicalRow, physicalCol, props) {
      const id = this.$refs.table.physicalRowToGuid(physicalRow);
      if (this.tableData[id] == null) {
        return props;
      }

      if (
        (this.tableSettings.columns[physicalCol].key === materialKey ||
          this.tableSettings.columns[physicalCol].key === columnCreationDate.key ||
          this.tableSettings.columns[physicalCol].key === fieldSizeKey ||
          this.tableSettings.columns[physicalCol].key === columnAmount.key) &&
        this.selectedRows.includes(id)
      ) {
        return {
          ...props,
          required: true,
        };
      }
      return props;
    },
    columnDropdownData(columns) {
      return columns.map((column) => {
        if (column.key !== materialKey) {
          return column;
        }
        return {
          ...column,
          getRendererValue: ({ value }) => {
            if (this.cropsById[value] != null && typeof this.cropsById[value].name === 'string') {
              return this.cropsById[value].name;
            }
            return '';
          },
          getItems: async () => [
            {
              name: 'Crops',
              id: 'crops',
              items: this.crops,
              sort: false,
            },
          ],
        };
      });
    },
  },
};
</script>
