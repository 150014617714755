import axios from 'axios';

import { ENTRY_SYNCED, ERROR_FETCHING } from '@/shared/constants';

/**
 * Always use this mixin together with shared/handsontable/mixins/containers/tableContainerBase.
 *
 * Use this mixin when loading data directly from the REST backend.
 * Usable for a list of a single object.
 * To trigger, call method loadTableData(dataUrl), usually on mounted()
 *
 * @param dataUrlCallback {function}
 * @return {object}
 */
export default {
  data() {
    return {
      loading: true,
      source: null,
      tableData: {},
      tableErrors: [],
    };
  },
  beforeDestroy() {
    if (this.source != null) {
      this.source.cancel();
    }
  },
  methods: {
    async loadTableData(dataUrl) {
      this.loading = true;
      this.tableErrors = [];
      this.source = axios.CancelToken.source();
      try {
        const { data } = await axios.get(dataUrl, { cancelToken: this.source.token });
        this.tableData = data.data.reduce(
          (tableData, entry) => ({
            ...tableData,
            [entry.id]: {
              ...entry,
              storeStatus: ENTRY_SYNCED,
            },
          }),
          {},
        );
        this.loading = false;
      } catch (e) {
        this.tableErrors = [{ type: ERROR_FETCHING }];
      }
    },
  },
};
