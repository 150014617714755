import {
  ApplicationMapsApiApplicationMapsTerrazoFieldTimestampRequest,
  ApplicationMapsApiApplicationMapsTerrazoMapRequest,
  TerrazoFertilizerAdviceParamsWinterGrains1N,
  TerrazoFertilizerAdviceParamsWinterGrains3N,
} from 'farmdok-rest-api';
import moment from 'moment';
import Vue from 'vue';
import { ActionTree } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import {
  FertilizationCalculation,
  FertilizationZoneDosage,
  NutrientDosage,
} from '@/precision-farming/application-maps/fertilization/store/types';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { GUID_KEY } from '@/shared/constants';
import { RootState } from '@/store/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import detectFertilizerAdviceType, {
  isFertilizerAdviceParamsWinterGrains1N,
  isFertilizerAdviceParamsWinterGrains3N,
} from './actionUtils/detectFertilizerAdviceType';
import { toFertilizationCerealsZones } from './actionUtils/toFertilizationCerealsZone';
import toHeatmaps from './actionUtils/toHeatmaps';
import toMapType from './actionUtils/toMapType';
import {
  ApplicationMapsFertilizationCerealsState,
  ApplicationType,
  FertilizationCerealsZone,
  TaskData,
  VisualizationMode,
  ZoningMode,
} from './types';

export type ActionPayloads = {
  loadFieldTimestamps: string;
  loadFeaturesAndZones: {
    fieldId: string;
    dbId: string;
    visualizationMode: VisualizationMode;
    numberOfZones: number;
    borderZoneCorrection: boolean;
    zoningMode: ZoningMode;
    fertilizerAdviceParams?: TerrazoFertilizerAdviceParamsWinterGrains1N | TerrazoFertilizerAdviceParamsWinterGrains3N;
  };
  setMaterial: DropdownItem;
  setManualDosage: { key: keyof NutrientDosage; index: number; value: number };
};

const actions: ActionTree<ApplicationMapsFertilizationCerealsState, RootState> = {
  ...baseWorkflowStore.actions,
  loadHeatmaps() {
    // this action is not used in fertilization cereals workflow but is here to prevent console errors
    // heatmaps will be loaded via loadFeaturesAndZones action
  },
  async loadFieldTimestamps({ commit }, fieldId: ActionPayloads['loadFieldTimestamps']) {
    commit('setFieldTimestampsLoading', true);

    const timeStart = moment().startOf('day').subtract(1, 'year').unix();
    const timeEnd = moment().startOf('day').unix();
    const requestParameters: ApplicationMapsApiApplicationMapsTerrazoFieldTimestampRequest = {
      requestTerrazoFieldTimestamp: {
        fieldId,
        timeStart,
        timeEnd,
      },
    };

    const { applicationMapsApi } = Vue.prototype.$api as Api;
    try {
      const response = await applicationMapsApi.applicationMapsTerrazoFieldTimestamp(requestParameters);
      const apiResponse = response.data;

      if (apiResponse.status !== 'success') {
        throw new Error(`API response status is not success: ${apiResponse}`);
      }

      commit('setFieldTimestamps', { fieldId, timestamps: apiResponse.data });
      commit('setFieldTimestampsError', null);
    } catch (error) {
      console.error(error);
      commit('setFieldTimestampsError', 'Failed to load field timestamps');
      commit('setFieldTimestamps', { fieldId, timestamps: null });
    } finally {
      commit('setFieldTimestampsLoading', false);
    }
  },
  async loadFeaturesAndZones(
    { commit },
    {
      fieldId,
      dbId,
      visualizationMode,
      numberOfZones,
      borderZoneCorrection,
      zoningMode,
      fertilizerAdviceParams,
    }: ActionPayloads['loadFeaturesAndZones'],
  ) {
    commit('setZonesLoading', true);
    const mapType = toMapType(visualizationMode);
    const fertilizerAdviceType = detectFertilizerAdviceType(fertilizerAdviceParams);

    const requestParameters: ApplicationMapsApiApplicationMapsTerrazoMapRequest = {
      requestTerrazoMap: {
        fieldId,
        dbId,
        mapType,
        numberOfZones,
        corrected: borderZoneCorrection,
        zoningMode,
        fertilizerAdviceType,
        fertilizerAdviceParamsWinterGrains1N: isFertilizerAdviceParamsWinterGrains1N(fertilizerAdviceParams)
          ? fertilizerAdviceParams
          : undefined,
        fertilizerAdviceParamsWinterGrains3N: isFertilizerAdviceParamsWinterGrains3N(fertilizerAdviceParams)
          ? fertilizerAdviceParams
          : undefined,
      },
    };

    const { applicationMapsApi } = Vue.prototype.$api as Api;
    try {
      const response = await applicationMapsApi.applicationMapsTerrazoMap(requestParameters);
      const apiResponse = response.data;

      if (apiResponse.status !== 'success') {
        throw new Error(`API response status is not success: ${apiResponse}`);
      }

      if (apiResponse.data?.meta?.zones && apiResponse.data.features) {
        const zones: FertilizationCerealsZone[] = toFertilizationCerealsZones(
          apiResponse.data.meta.zones,
          apiResponse.data.features,
        );

        commit('setZones', zones);

        const heatmaps = toHeatmaps(zones, apiResponse.data.features, fieldId);
        commit('setHeatmaps', heatmaps);
      }
    } catch (error) {
      console.error(error);
      commit('setZonesError', 'Failed to load zones');
      commit('setZones', null);
      commit('setHeatmaps', {});
    } finally {
      commit('setZonesLoading', false);
    }
  },
  setMaterial({ rootState, commit }, material: ActionPayloads['setMaterial']) {
    commit('setMaterial', material);
    if (!material || !material.id || material.id === 'manual') {
      return;
    }
    const { n, p, k } = rootState.products.mineralFertilizers.data[material.id];
    commit('setCalculationField', { key: 'n', value: n });
    commit('setCalculationField', { key: 'p', value: p });
    commit('setCalculationField', { key: 'k', value: k });
  },
  setCalculationField({ commit }, data: { key: keyof FertilizationCalculation; value: number | null }) {
    commit('setCalculationField', data);
    if (['n', 'p', 'k'].includes(data.key)) {
      commit('setMaterial', { id: 'manual', name: Vue.i18n.translate('Eigener Dünger') });
    }
  },
  setManualDosage({ state, commit, getters }, { key, index, value }: ActionPayloads['setManualDosage']) {
    let currentDosage: FertilizationZoneDosage[] = [];
    if (state.calculation.manualDosage == null) {
      currentDosage = getters.zoneDosage;
    } else {
      currentDosage = state.calculation.manualDosage;
    }
    let dosage = value;
    if (key !== 'dosage') {
      const calculationValue = state.calculation[key];
      if (calculationValue !== 0) {
        dosage = value / calculationValue;
      }
    }
    currentDosage[index].dosage = dosage;
    currentDosage[index].n = state.calculation.n * dosage;
    currentDosage[index].p = state.calculation.p * dosage;
    currentDosage[index].k = state.calculation.k * dosage;
    if (key !== 'dosage') {
      currentDosage[index][key] = value;
    }

    commit('setManualDosage', currentDosage);
    commit('setApplicationField', { key: 'applicationType', value: ApplicationType.MANUAL });
  },
  async setTask({ commit, rootState, dispatch, state }, task: TaskData) {
    await dispatch('auth/subscribe', undefined, { root: true });
    await Promise.all([
      dispatch('fields/subscribe', undefined, { root: true }),
      dispatch('products/mineralFertilizers/subscribe', undefined, { root: true }),
    ]);
    commit('setTask', task);
    if (!task.applicationMap || !task.applicationMap.additionalData) return;
    // select fields
    const selectedFields: string[] = [];
    if (Array.isArray(task.applicationMap.additionalData.fields)) {
      task.applicationMap.additionalData.fields.forEach(({ [GUID_KEY]: guid }) => {
        if (rootState.fields.data[guid] != null) {
          selectedFields.push(guid);
        }
      });
    } else if (Array.isArray(task.fields)) {
      task.fields.forEach((taskField) => {
        if (rootState.fields.data[taskField.fieldId] != null) {
          selectedFields.push(taskField.fieldId);
        }
      });
    }
    commit('setSelectedFields', selectedFields);
    // set pagination to step2 if fields are selected
    if (selectedFields.length > 0) {
      commit('setPaginationStep', 2);
    }

    // set zoneMap
    commit('setZoneMap', task.applicationMap.additionalData.zoneMap);

    // set calculation values
    if (task.applicationMap.additionalData.calculation != null) {
      const { calculation } = task.applicationMap.additionalData;
      const workflowCalculation: FertilizationCalculation = {
        n: calculation.n,
        p: calculation.p,
        k: calculation.k,
        manualDosage: calculation.manualDosage,
        material: calculation.material,
        unit: calculation.unit,
      };
      commit('setCalculation', {
        ...state.calculation,
        ...workflowCalculation,
      });
    }
    // set application values
    if (task.applicationMap.additionalData.application) {
      commit('setApplication', task.applicationMap.additionalData.application);
    }
  },
};

export default actions;
