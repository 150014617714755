import { Activity, ActivityProduct } from 'farmdok-rest-api';

import ActivityProductService from '@/activities/services/ActivityProductService';
import { isRelativeUnit } from '@/activities/utils/amountsAndUnits/findUnit';
import { Unit } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

// TODO align with MergedActivityEquipment
export type MergedActivityProduct = ActivityProduct & { activityIds: string[] };

type AmountUnit = {
  amount: number | null | undefined;
  unitId: string | null | undefined;
};
export default class MergedActivityProductsService {
  private readonly activities: Activity[];

  private readonly units: Data<Unit>; // TODO replace by UnitService

  private readonly activityProductService: ActivityProductService;

  constructor(activities: Activity[], units: Data<Unit>, activityProductService: ActivityProductService) {
    this.activities = activities;
    this.units = units;
    this.activityProductService = activityProductService;
  }

  public get mergedActivityProducts(): MergedActivityProduct[] {
    const activityProducts: MergedActivityProduct[] = [];

    this.activities.forEach((activity) => {
      if (activityProducts.length === 0) {
        const productsWithActivityIds: MergedActivityProduct[] = activity.products.map((product) =>
          MergedActivityProductsService.toMergedActivityProduct(product, activity.id),
        );
        activityProducts.push(...productsWithActivityIds);
      } else {
        activity.products.forEach((product) => {
          const siblingIndex = activityProducts.findIndex((activityProduct) =>
            this.activityProductService.isSibling(activityProduct, product),
          );
          if (siblingIndex > -1) {
            const productWithActivityId = MergedActivityProductsService.toMergedActivityProduct(product, activity.id);

            activityProducts[siblingIndex] = this.mergeActivityProducts(
              activityProducts[siblingIndex],
              productWithActivityId,
            );
          } else {
            activityProducts.push(MergedActivityProductsService.toMergedActivityProduct(product, activity.id));
          }
        });
      }
    });

    return activityProducts;
  }

  private mergeActivityProducts(
    productA: MergedActivityProduct,
    productB: MergedActivityProduct,
  ): MergedActivityProduct {
    if (!this.activityProductService.isSibling(productA, productB))
      throw new Error('Cannot merge activity products that are not siblings');

    const mergedProduct: MergedActivityProduct = {
      ...productA,
      ...this.mergeAmountAndUnit(
        { amount: productA.amount, unitId: productA.unitId },
        { amount: productB.amount, unitId: productB.unitId },
      ),
      activityIds: [...productA.activityIds, ...productB.activityIds],
    };

    if (productA.pestId !== productB.pestId) mergedProduct.pestId = 'DIFFERENT';
    if (productA.pesticideIndicationId !== productB.pesticideIndicationId)
      mergedProduct.pesticideIndicationId = 'DIFFERENT';

    return mergedProduct;
  }

  private static toMergedActivityProduct(product: ActivityProduct, activityId: string): MergedActivityProduct {
    return { ...product, activityIds: [activityId] };
  }

  private mergeAmountAndUnit(a: AmountUnit, b: AmountUnit): AmountUnit {
    if (!a.unitId || !b.unitId) return { amount: null, unitId: null };
    if (a.unitId !== b.unitId) throw new Error('Cannot merge amounts with different units');

    const unitA = this.units[a.unitId];
    if (isRelativeUnit(unitA)) {
      return MergedActivityProductsService.mergeRelativeAmountUnit(a, b);
    }

    return MergedActivityProductsService.mergeTotalAmountUnit(a, b);
  }

  private static mergeRelativeAmountUnit(a: AmountUnit, b: AmountUnit): AmountUnit {
    if (a.amount === null || a.amount === undefined) return { amount: null, unitId: a.unitId };
    if (b.amount === null || b.amount === undefined) return { amount: null, unitId: a.unitId };

    if (a.amount !== b.amount) return { amount: -1, unitId: a.unitId };
    return a;
  }

  private static mergeTotalAmountUnit(a: AmountUnit, b: AmountUnit): AmountUnit {
    if (a.amount == null && b.amount == null) return { amount: null, unitId: a.unitId };

    // sum the total amounts
    return { amount: (a.amount ?? 0) + (b.amount ?? 0), unitId: a.unitId };
  }

  public static toActivityProduct(mergedActivityProduct: MergedActivityProduct): ActivityProduct {
    const { activityIds, ...activityProduct } = mergedActivityProduct;
    return activityProduct;
  }
}
