import { TerrazoFieldTimestampEntry } from 'farmdok-rest-api';
import moment from 'moment';

import { UnixTimestamp } from '@/shared/modules/unixTimestampHelpers';

export default function findFieldTimestampOfSameDay(
  fieldTimestamps: TerrazoFieldTimestampEntry[],
  selectedUnixTimestamp: UnixTimestamp,
): TerrazoFieldTimestampEntry | undefined {
  return fieldTimestamps.find((entry) => {
    if (!entry.timestamp) return false;

    const selectedMoment = moment.unix(selectedUnixTimestamp);
    return moment.unix(entry.timestamp).isSame(selectedMoment, 'day');
  });
}
