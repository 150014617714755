
import { PropType, defineComponent } from 'vue';

import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

export default defineComponent({
  name: 'FirstStep',
  components: { FormFieldDropdown },
  props: {
    companyDropdownItems: {
      type: Array as PropType<DropdownItemsPerCategory[]>,
      required: true,
    },
    processOrderDropdownItems: {
      type: Array as PropType<DropdownItemsPerCategory[]>,
      required: true,
    },
    selectedProcessOrder: {
      type: Object as PropType<DropdownItem | null>,
    },
    selectedCompany: {
      type: Object as PropType<DropdownItem | null>,
    },
  },
  data() {
    return {
      defaultSelectedDropdownValue: {
        id: '',
        name: '',
      } as DropdownItem,
    };
  },
  computed: {
    companyDropdownValue(): DropdownItem {
      return this.selectedCompany || this.defaultSelectedDropdownValue;
    },
    processOrderDropdownValue(): DropdownItem {
      return this.selectedProcessOrder || this.defaultSelectedDropdownValue;
    },
  },
  methods: {
    updateCompanyId(company: DropdownItem): void {
      this.$emit('update:companyId', company.id);
    },
    updateProcessOrderId(processOrder: DropdownItem): void {
      this.$emit('update:processOrderId', processOrder.id);
    },
  },
});
