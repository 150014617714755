import { Module } from 'vuex';

import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { CustomersState } from './types';

export function initialState(): CustomersState {
  return {
    ...initialStateSubscribable(),
  };
}

const customersModule: Module<CustomersState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: { ...subscribableStore.mutations },
  actions: { ...subscribableStore.actions },
  getters: { ...subscribableStore.getters },
};

export default customersModule;
