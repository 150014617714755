import moment from 'moment';

export const DATE_FORMAT = 'YYYYMMDD';

export default {
  props: {
    navigationDate: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    const defaultDate = new Date();
    const cropYear = parseInt(this.$store.state.auth.currentProcessOrderName, 10);
    if (cropYear < defaultDate.getFullYear()) {
      defaultDate.setFullYear(cropYear);
    }
    return {
      selectedAggregate: 'Monthly',
      defaultDate,
      cropYear,
    };
  },
  mounted() {
    this.resetDate();
  },
  emits: ['update:navigationDate'],
  methods: {
    previousDate() {
      const oneYearEarlier = moment(this.navigationDate).subtract(1, 'years').toDate();
      this.$emit('update:navigationDate', oneYearEarlier);
    },
    resetDate() {
      this.$emit('update:navigationDate', this.defaultDate);
    },
    nextDate() {
      const oneYearLater = moment(this.navigationDate).add(1, 'years').toDate();
      this.$emit('update:navigationDate', oneYearLater);
    },
    axisLabel(time) {
      const yearBegin = time.isSame(time.clone().startOf('year'), 'day');
      switch (this.selectedAggregate) {
        case 'Daily':
        case 'Monthly':
          return yearBegin ? time.format('MMM YYYY') : time.format('MMM');
        case 'Yearly':
          return time.format('YYYY');
        default:
          throw new Error(`Unsupported aggregate period ${this.selectedAggregate}`);
      }
    },
    caption(time) {
      switch (this.selectedAggregate) {
        case 'Daily':
          return time.format('MMM Do');
        case 'Monthly':
          return time.format('MMM');
        case 'Yearly':
          return time.format('YYYY');
        default:
          throw new Error(`Unsupported aggregate period ${this.selectedAggregate}`);
      }
    },
    isHighlight(time) {
      const monthBegin = time.isSame(time.clone().startOf('month'), 'day');
      switch (this.selectedAggregate) {
        case 'Daily':
        case 'Monthly':
          return time.month() % 3 === 0 && monthBegin;
        case 'Yearly':
          return true;
        default:
          throw new Error(`Unsupported aggregate period ${this.selectedAggregate}`);
      }
    },
    toMoment(time) {
      return moment(time, 'YYYY-MM-DD');
    },
  },
};
