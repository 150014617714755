import { StoragePlace } from 'farmdok-rest-api';

import { Product } from '@/shared/api/rest/models';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';

export default class Dropdown {
  product: Product;

  storagePlace?: StoragePlace;

  constructor(product: Product, storagePlace?: StoragePlace) {
    this.product = product;
    this.storagePlace = storagePlace;
  }

  toData(): DropdownItem {
    return {
      id: this.getId(),
      name: this.getName(),
    };
  }

  private getId(): string {
    const { product, storagePlace } = this;
    const { id } = product;
    return storagePlace ? `${id}_${storagePlace.id}` : id;
  }

  private getName(): string {
    const { product } = this;
    const { name } = product;

    const pesticideNamePart = this.getPesticideNamePart();
    const storageNamePart = this.getStorageNamePart();

    return `${name}${pesticideNamePart}${storageNamePart}`;
  }

  private getPesticideNamePart(): string {
    const { product } = this;
    const { pesticide } = product;
    if (!pesticide) return '';

    const { registerNr, registerAdd } = pesticide;
    if (registerNr != null && registerAdd == null) return ` (${registerNr})`;
    if (registerNr != null && registerAdd != null) return ` (${registerNr}/${registerAdd})`;

    return '';
  }

  private getStorageNamePart(): string {
    const { storagePlace } = this;
    if (!storagePlace) return '';

    const { name } = storagePlace;
    if (!name) return '';

    return ` (${name})`;
  }
}
