import Handsontable from 'handsontable';

import { CellCoords } from '@/shared/handsontable/types';

import isOutsideDataCellClick from '../../utils/isOutsideDataCellClick';
import { ColumnSettingsCollapsedSubtable } from './types';

function handleKeyClickFactory(hot: Handsontable | null) {
  function handleKeyClick(event: KeyboardEvent) {
    if (!hot) return;
    const selected = hot.getSelected();
    if (!selected) return;
    const visualRow = selected[0][0];
    const visualColumn = selected[0][1];

    if (event.key === 'Enter') {
      if (isOutsideDataCellClick(visualRow, visualColumn)) return;
      const cellMeta = hot.getCellMeta(visualRow, visualColumn);
      const expandValue = hot.getDataAtRowProp(visualRow, 'expand');

      if (cellMeta.type === 'farmdok.subtablePrimaryColumn' && expandValue === false) {
        event.stopImmediatePropagation();

        cellMeta.collapsedSubtable.onClick({
          event,
          visualRow,
          instance: hot,
        });
      }
    }
  }

  return handleKeyClick;
}

function triggerOnClickFactory(hot: Handsontable | null) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function triggerOnClick(event: MouseEvent, coords: CellCoords, TD: HTMLTableCellElement) {
    if (!hot) return;
    if (isOutsideDataCellClick(coords.row, coords.col)) return;
    const cellMeta = hot.getCellMeta(coords.row, coords.col);
    if (cellMeta.type === 'farmdok.subtablePrimaryColumn') {
      const typedCellMeta = cellMeta as unknown as ColumnSettingsCollapsedSubtable;
      const expandValue = hot.getDataAtRowProp(coords.row, 'expand');

      if (expandValue === false) {
        typedCellMeta.collapsedSubtable.onClick({
          event,
          visualRow: coords.row,
          instance: hot,
        });
      }
    }
  };
}

// add these hooks to your handsontable instance
const hooks = {
  beforeKeyDown: { handleKeyClickFactory },
  afterOnCellMouseDown: { triggerOnClickFactory },
};

export default hooks;
