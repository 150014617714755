
import { DaturaImportJob } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import TableActivitiesLoading from '@/activities/components/table/TableActivitiesLoading.vue';
import NoContentContainer from '@/activities/containers/table/NoContentContainer.vue';
import TableHistory from '@/datura/history/components/TableHistory.vue';
import type { CellChangeWithIdsHistoryTable, TableDataHistory } from '@/datura/history/handsontable/types';
import TableFooterContainer from '@/shared/containers/TableFooterContainer.vue';
import TableColumnsService from '@/shared/handsontable/rework/services/TableColumnsService';
import { ColumnSettingsWithUserSettings, FarmdokColumnSettings } from '@/shared/handsontable/rework/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

export default defineComponent({
  name: 'TableHistoryContainer',
  props: {
    filterString: { type: String, default: '' },
    columns: {
      type: Array as PropType<Array<FarmdokColumnSettings>>,
      required: true,
    },
  },
  data() {
    return {
      debouncedRuleCheck: () => {},
      historyLoaded: false,
    };
  },
  components: { TableHistory, TableFooterContainer, TableActivitiesLoading, NoContentContainer },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters('auth', ['currentProcessOrderIds', 'currentCompanyIds']),
    histories(): DaturaImportJob[] {
      return Object.values(this.$store.state.datura.history.data);
    },
    tableData(): TableDataHistory[] {
      return this.$store.getters['datura/history/tableDataHistory'];
    },
    columnsForHandsontable(): ColumnSettingsWithUserSettings[] {
      const { userColumnSettings } = this.$store.state.datura.history;
      const tableColumnsService = new TableColumnsService(this.columns);
      const columnsForHot = tableColumnsService.getColumnsForHandsontable(userColumnSettings);
      if (this.featureDaturaReadonly) {
        return this.withReadOnly(columnsForHot);
      }
      return columnsForHot;
    },
    fixedColumnsLeft(): number {
      const fixedColumns = this.columnsForHandsontable.findIndex((column) => column.fixedColumnsLeftUntilHere === true);
      if (fixedColumns === -1) return 0;

      return fixedColumns + 1;
    },
    loading(): boolean {
      return (
        this.$store.getters['datura/history/loading'] ||
        this.$store.getters['fields/loading'] ||
        this.$store.getters['customers/loading']
      );
    },
    featureDaturaReadonly(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_DATURA_CONTROL);
    },
  },
  watch: {
    currentProcessOrderIds() {
      this.loadData();
    },
    currentCompanyIds() {
      this.loadData();
    },
    histories() {
      if (!this.historyLoaded) {
        this.historyLoaded = true;
      } else {
        this.debouncedRuleCheck();
      }
    },
  },
  methods: {
    withReadOnly(columnsForHot: ColumnSettingsWithUserSettings[]): ColumnSettingsWithUserSettings[] {
      return columnsForHot.map((column) => {
        if ((column.data as string).includes('expand')) {
          return column;
        }

        if (column.subtableColumns) {
          return {
            ...column,
            readOnly: true,
            subtableColumns: column.subtableColumns.map((subtableColumn: any) => ({
              ...subtableColumn,
              readOnly: true,
            })),
          };
        }

        return {
          ...column,
          readOnly: true,
        };
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(changes: CellChangeWithIdsHistoryTable[], source: Handsontable.ChangeSource) {
      // TODO move this function into History service
      changes.forEach((change) => {
        const [, key, , newValue, { id: historyId }] = change;
        const metadataKeys = ['expand', 'select'];

        if (!metadataKeys.includes(key)) {
          this.debouncedRuleCheck();
        }

        if (key === 'fieldDropdownItem') {
          this.onChangeFieldDropdownItem(change);
          return;
        }

        if (metadataKeys.includes(key)) {
          this.$store.commit('datura/history/addOrUpdateTableDataMetadataByKeyAndValue', {
            historyId,
            key,
            value: newValue,
          });
        }
      });
    },
    onChangeFieldDropdownItem(change: CellChangeWithIdsHistoryTable) {
      const { id: fieldId } = change[3];
      const { id: historyId } = change[4];

      const updatedField = (this.$store.state as RootState).fields.data[fieldId];

      this.$store.commit('datura/history/updateEntryByKeyAndValue', {
        guid: historyId,
        key: 'processedArea',
        value: updatedField.fieldSize,
      });

      this.$store.dispatch('datura/history/updateEntryByKeyAndValueAndSync', {
        guid: historyId,
        key: 'fieldId',
        value: fieldId,
      });
    },
    loadData() {
      this.$store.dispatch('fields/subscribe');
      this.$store.dispatch('datura/history/subscribe');
      this.$store.dispatch('customers/subscribe');
    },
    refreshData() {
      this.$store.dispatch('datura/history/refresh');
      this.$store.dispatch('fields/refresh');
      this.$store.dispatch('customers/refresh');
    },
    onUpdateHot(hot: Handsontable | null) {
      this.$store.commit('datura/history/setHot', hot);
      this.$emit('update:hot', hot);
    },
  },
});
