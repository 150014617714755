<template>
  <ThePageSkeleton scroll-y content-flex>
    <div class="page-no-multi-company-support__content">
      <LogoAuthPages />
      <p>{{ $t('Dieses Feature unterstützt derzeit nur einen gewählten Betrieb.') }}</p>
      <CompanySwitch block :right="false" />
    </div>
  </ThePageSkeleton>
</template>

<script>
import LogoAuthPages from '@/auth/components/LogoAuthPages.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';

export default {
  name: 'PageNoMultiCompanySupport',
  components: { CompanySwitch, ThePageSkeleton, LogoAuthPages },
};
</script>

<style scoped>
.page-no-multi-company-support__content {
  display: block;
  max-width: calc(500px + 2rem);
  width: 100%;
  margin: 200px auto auto;
  padding: 1rem 1rem 200px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
