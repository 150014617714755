import Vue from 'vue';

import subscribableData from '@/shared/mixins/store/subscribableData';
import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';

import { ENTRY_DIRTY, ENTRY_SYNCED, ENTRY_UPDATING } from '../shared/constants';
import { getDescendantProp } from '../shared/modules/getDisplayValue';

/* eslint-disable camelcase */
import { F_FieldGroupCustomer, F_FieldGroupNumber } from './store/common';

const dataToBase64 = (data) => (data != null ? btoa(JSON.stringify(data)) : '');

export const dataUrl = '/admin/rest/field';
export const filter = ({ rootState }) => {
  if (!rootState.auth.currentProcessOrderName) {
    return false;
  }
  return ['processOrder.name', '=', rootState.auth.currentProcessOrderName];
};
export const resolve = ['fieldGroup', 'fieldContour', 'processOrder'];
export const resolveInSeparateList = ['crop', 'preCrop', 'variety'];
export const additionalQueryParams = ({ rootState }) => {
  const params = ['permissionMode=fieldSharing'];

  const additionalData = ['fieldShare'];
  params.push(`additional=${dataToBase64(additionalData)}`);

  if (!(rootState.auth.currentCompanies && rootState.auth.currentCompanies[0])) {
    return params;
  }
  params.push(`fieldSharingCompanyId=${rootState.auth.currentCompanies[0].id}`);
  return params;
};

const subscribableStore = subscribableData(dataUrl, {
  filter,
  resolve,
  resolveInSeparateList,
  additionalQueryParams,
});

export function initialState() {
  return {
    ...initialStateSubscribable(),
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    ...subscribableStore.mutations,
    loadAll(state, { data }) {
      Vue.set(
        state,
        'data',
        data.reduce((dataCurrent, entry) => {
          if (
            state.data[entry.id] != null &&
            [ENTRY_DIRTY, ENTRY_UPDATING].includes(state.data[entry.id].storeStatus)
          ) {
            return {
              ...dataCurrent,
              [entry.id]: state.data[entry.id],
            };
          }
          return {
            ...dataCurrent,
            [entry.id]: {
              ...entry,
              [F_FieldGroupNumber]: getDescendantProp(entry, F_FieldGroupNumber),
              [F_FieldGroupCustomer]: getDescendantProp(entry, F_FieldGroupCustomer),
              storeStatus: ENTRY_SYNCED,
            },
          };
        }, {}),
      );
      Vue.set(state, 'loaded', new Date());
    },
  },
  actions: {
    ...subscribableStore.actions,
    loadResolved({ commit }, { resolved }) {
      if (resolved == null) {
        return;
      }
      if (Array.isArray(resolved.crop)) {
        resolved.crop.forEach((crop) => {
          commit('products/crops/addOne', crop, { root: true });
        });
      }
      if (Array.isArray(resolved.preCrop)) {
        resolved.preCrop.forEach((crop) => {
          commit('products/crops/addOne', crop, { root: true });
        });
      }
      if (Array.isArray(resolved.variety)) {
        resolved.variety.forEach((variety) => {
          commit('varieties/addOne', variety, { root: true });
        });
      }
    },
  },
  getters: {
    ...subscribableStore.getters,
  },
};
