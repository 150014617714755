<template>
  <img class="img-partner-logo" :src="src" :width="width" :height="height" alt="Partner logo" />
</template>

<script>
export default {
  name: 'ImgPartnerLogo',
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 55,
    },
  },
};
</script>

<style scoped>
.img-partner-logo {
  margin: 10px 0;
  object-fit: contain;
  object-position: left;
}
</style>
