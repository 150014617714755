
import { BFormRadioGroup } from 'bootstrap-vue';
import { PropType, defineComponent } from 'vue';

import BaseFormField from './BaseFormField.vue';
import FormRadio from './FormRadio.vue';
import { RadioOption } from './formRadioTypes';

/**
 * Use this component for a radio button group. Ideally placed inside a FieldSet.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <div style="padding: 10px;">
 *   <FormFieldRadioGroup
 *     label="A vertical group with options i/o slot"
 *     variant="vertical"
 *     :options="[
 *       { value: 'first', text: 'first' },
 *       { value: 'second', text: 'second' },
 *       { value: 'third', text: 'third' },
 *     ]"
 *     :checked="'first'"
 *   />
 *   <FormFieldRadioGroup
 *     label="Radio group label"
 *     checked="first"
 *   >
 *     <FormRadio value="first">First.</FormRadio>
 *     <FormRadio value="second">Second.</FormRadio>
 *     <FormRadio value="third">Third.</FormRadio>
 *   </FormFieldRadioGroup>
 * </div>
 */
export default defineComponent({
  name: 'FormFieldRadioGroup',
  components: { BFormRadioGroup, FormRadio, BaseFormField },
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    /**
     * If variant is 'no-label' this can be left empty as it will not be rendered anyways.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Supported types:
     *  <table style="width:30%;">
     *    <tr><td>'horizontal'</td><td>Label to the left.</td></tr>
     *    <tr><td>'vertical'</td><td>Label above. Increased margin bottom.</td></tr>
     *    <tr><td>'vertical-lg'</td><td>Label above. Increases margin bottom and input paddings.</td></tr>
     *    <tr><td>'no-label'</td><td>No label.</td></tr>
     * </table>
     */
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value: string) => ['horizontal', 'vertical', 'vertical-lg', 'no-label'].includes(value),
    },
    /**
     * List of checkboxes to render. Overwritten by default slot.
     */
    options: {
      type: Array as PropType<RadioOption[]>,
      default: null,
    },
    /**
     * The value of the selected radio button (or null if nothing is selected).
     */
    checked: {
      default: null,
    },
    /**
     * When set, renders the radio group in stacked mode.
     */
    stacked: {
      type: Boolean,
      default: true,
    },
    /**
     * Classes for the inner wrapper of the FormRadio elements.
     */
    innerContainerClass: {
      type: String,
      default: null,
    },
  },
  methods: {
    /**
     * Emitted when selected value(s) is changed due to user interaction.<br>
     * Also used to update the model.
     *
     * @event FormFieldRadioGroup#input
     * @type {String} value
     */
    input(value: string) {
      this.$emit('input', value);
    },
    /**
     * Emitted when the selected value(s) is changed.
     *
     * @event FormFieldRadioGroup#change
     * @type {String} value
     */
    change(value: string) {
      this.$emit('change', value);
    },
  },
});
