<template>
  <div class="tooltip-wrapper" v-b-tooltip="tooltipOptionsWithDefaults">
    <BButton class="button-with-arrow" :variant="variant" :disabled="disabled" v-on="$listeners">
      <span
        class="button-with-arrow__inner"
        :class="{
          'button-with-arrow__inner--icon-left': type === 'left',
          'button-with-arrow__inner--icon-right': type === 'right',
        }"
      >
        <FontAwesomeIcon
          class="button-with-arrow__icon"
          :class="{
            'button-with-arrow__icon--left': type === 'left',
            'button-with-arrow__icon--right': type === 'right',
          }"
          :icon="['far', icon]"
        />
        <slot />
      </span>
    </BButton>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';

library.add(faArrowRight, faArrowLeft);

const defaultTooltipOptions = {
  delay: 500,
  placement: 'bottom',
};

export default {
  name: 'ButtonWithArrow',
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'right',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Will be propagated to v-b-tooltip, see https://bootstrap-vue.org/docs/directives/tooltip#directive-syntax-and-usage
     */
    tooltipOptions: {
      type: Object,
      default: null,
    },
  },
  computed: {
    icon() {
      if (this.type === 'right') {
        return 'arrow-right';
      }
      if (this.type === 'left') {
        return 'arrow-left';
      }
      return null;
    },
    tooltipOptionsWithDefaults() {
      if (!this.tooltipOptions) {
        return null;
      }

      return {
        ...defaultTooltipOptions,
        ...this.tooltipOptions,
      };
    },
  },
};
</script>

<style scoped>
.button-with-arrow {
  padding-right: 3.5em;
  padding-left: 3.5em;
  position: relative;
  width: 100%;
}

.button-with-arrow__inner {
  position: relative;
}

.button-with-arrow__inner--icon-right {
  margin-left: -0.5em;
}

.button-with-arrow__inner--icon-left {
  margin-right: -0.5em;
}

.button-with-arrow__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.btn .button-with-arrow__icon--right {
  left: 100%;
  margin-left: 0.8em;
}

.btn .button-with-arrow__icon--left {
  right: 100%;
  margin-right: 0.8em;
}
</style>
