import subscribableDataStore from '@/shared/mixins/store/subscribableData';

export const dataUrl = '/admin/rest/note';
export const filter = ({ rootGetters }) => {
  if (rootGetters['auth/currentProcessOrders'].length === 0) {
    return false;
  }
  return ['processOrderId', 'IN', rootGetters['auth/currentProcessOrderIds']];
};

const store = subscribableDataStore(dataUrl, { filter });

export function initialState() {
  return {
    ...store.state,
  };
}

export default {
  ...store,
  state: initialState(),
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
  getters: {
    ...store.getters,
  },
};
