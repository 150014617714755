<template lang="html">
  <div class="pagebar">
    <Tabs v-if="!satellite.expanded" :tabs="tabs" />
    <TheSatelliteActions v-if="satellite.available" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TheSatelliteActions from '@/layout/components/TheSatelliteActions.vue';
import Tabs from '@/shared/components/Tabs.vue';

export default {
  name: 'ThePagebar',
  components: {
    Tabs,
    TheSatelliteActions,
  },
  computed: {
    ...mapGetters(['satellite']),
    tabs() {
      let { tabs } = this.$route.meta;
      if (typeof tabs === 'function') {
        tabs = tabs(this.$route);
      }
      if (!Array.isArray(tabs)) {
        return [];
      }
      return tabs.map((name) => {
        const { route } = this.$router.resolve({ name });
        let label = name;
        if (route.meta != null) {
          if (typeof route.meta.labelTab === 'string') {
            label = route.meta.labelTab;
          } else if (typeof route.meta.labelTab === 'function') {
            label = route.meta.labelTab(this.$route);
          } else if (typeof route.meta.label === 'string') {
            ({ label } = route.meta);
          } else if (typeof route.meta.label === 'function') {
            label = route.meta.label(this.$route);
          }
        }

        if (route.matched.length === 0) {
          const params = name.split('-');
          if (params[0] === 'button') {
            return {
              type: 'text',
              label: params[1],
              onClick: () => window.open(`${process.env.VUE_APP_ORIGIN}/${params[2]}`, '_blank'),
            };
          }
          return {
            type: 'text',
            label,
            tooltip: this.$t('Demnächst verfügbar'),
          };
        }
        return {
          type: 'route',
          label,
          route,
        };
      });
    },
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.pagebar {
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media print {
  .pagebar {
    display: none;
  }
}
</style>
