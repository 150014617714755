import parseNumber from '@/shared/modules/parseNumber';

/**
 * @param value parses a string as a number of hours or minutes
 * if number is < 10 it is assumend to be hours
 * if number is >= 10 it is assumend to be minutes
 * @returns the number of seconds
 */
export default function parseNumberAsHoursOrMinutes(value: string): number | null {
  const parsedValue = parseNumber(value);
  if (Number.isNaN(parsedValue)) return null;

  if (parsedValue < 10) return parsedValue * 3600;

  return parsedValue * 60;
}
