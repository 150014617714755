import { Module } from 'vuex';

import { RootState } from '@/store/types';

import assessments from '../assessments/store';
import history from '../history/store';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import type { DaturaState } from './types';

export function initialState(): DaturaState {
  return {
    tableDataMetadata: {},
    hot: null,
    userColumnSettings: {},
  };
}

const vuexModule: Module<DaturaState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
  modules: {
    history,
    assessments,
  },
};

export default vuexModule;
