import { AxiosResponse } from 'axios';
import { ActivitiesGpsTrack200Response } from 'farmdok-rest-api';
import Vue from 'vue';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { TracksGps } from '@/tracks/store/types';

function emptyFakeResponse(): ApiResponse<TracksGps> {
  return {
    version: '2.0',
    status: 'success',
    data: [],
  };
}

export async function fetchAll(): Promise<ApiResponse<TracksGps>> {
  throw new Error('not implemented yet!');
}

function toApiResponse(response: ActivitiesGpsTrack200Response, trackId: string): ApiResponse<TracksGps> {
  return {
    ...response,
    data: [{ id: trackId, ...response.data }],
  } as ApiResponse<TracksGps>;
}

async function fetchByIds(ids: string[]): Promise<ApiResponse<TracksGps>> {
  if (ids.length === 0) {
    return emptyFakeResponse();
  }

  const { tracksApi } = Vue.prototype.$api as Api;

  const promises: Promise<ApiResponse<TracksGps>>[] = ids.map((trackId: string) =>
    tracksApi
      .tracksGpsTrack({ trackId })
      .then((response: AxiosResponse<ActivitiesGpsTrack200Response>) => response.data)
      .then((response: ActivitiesGpsTrack200Response) => toApiResponse(response, trackId)),
  );
  const responses: ApiResponse<TracksGps>[] = await Promise.all(promises);
  return mergeApiResponses(responses);
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
