import axios from 'axios';
import moment from 'moment';
import { ActionTree } from 'vuex';

import router from '@/router';
import { dataToBase64 } from '@/shared/api/rest/requestUtils';
import { getCookie, setCookie } from '@/shared/modules/cookieHelpers';
import { getRestResponseData, mergeRestResponseData } from '@/shared/modules/restApiHelpers';
import { RootState } from '@/store/types';

import {
  FARMDOK_AUTH_CHECK_EMAIL_VISITED,
  FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME,
  FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE,
} from '../constants';
import { AuthState, FetchingKey, UserCompany } from './types';

const actions: ActionTree<AuthState, RootState> = {
  /**
   * Sets the currentCompanies. Also updates currentProcessOrderName if the current one is out of range.
   *
   * @param dispatch
   * @param commit
   * @param state
   * @param companies
   */
  setCurrentCompanies({ dispatch, commit, state }, companies) {
    commit('setCurrentCompaniesAndProcessOrderName', {
      companies,
      processOrderName: state.currentProcessOrderName,
    });
    dispatch('persistCurrentCompanies');
    dispatch('resetData', null, { root: true });
  },
  /**
   * Updates currentProcessOrderName in the store state, but only if one of the currentCompanies has a process order in this year.
   *
   * @param {function} dispatch
   * @param {function}commit
   * @param {object} getters
   * @param {string} processOrderName
   * @throws RangeError
   */
  setCurrentProcessOrderName({ dispatch, commit, getters }, processOrderName) {
    if (!getters.availableProcessOrderNames.includes(processOrderName)) {
      throw new RangeError(`
            setCurrentProcessOrderName: processOrderName "${processOrderName}"
            out of range: [${getters.availableProcessOrderNames.join(', ')}]
          `);
    }
    commit('setCurrentProcessOrderName', processOrderName);
    dispatch('persistCurrentCompanies');
    dispatch('resetData', null, { root: true });
  },
  /**
   * Use setCurrentCompanies instead #326
   *
   * @deprecated
   */
  selectCompanyById({ dispatch, commit, state }, companyId) {
    const currentCompany = state.companiesById[companyId];
    let currentProcessOrder = null;
    if (Array.isArray(currentCompany.processOrders)) {
      currentCompany.processOrders.some((processOrder) => {
        currentProcessOrder = processOrder;
        if (processOrder.name === state.currentProcessOrderName) {
          return true;
        }
        return false;
      });
    }
    commit('setCurrentProcessOrder', {
      currentCompany,
      currentProcessOrder,
    });
    dispatch('resetData', null, { root: true });
  },
  async subscribe({ commit, dispatch, state }) {
    if (state.userInfoLoaded) {
      return null;
    }
    if (!state.loggedIn) {
      if (!state.fetchingInitialAccessToken && state.jwtAuth != null) {
        commit('startFetching', FetchingKey.FETCHING_INITIAL_ACCESS_TOKEN);
        const loggedIn = await state.jwtAuth.initTokenChecking();
        commit('endFetching', FetchingKey.FETCHING_INITIAL_ACCESS_TOKEN);
        if (!loggedIn) {
          dispatch('clearObservers');
          return null;
        }
      }
    }
    // check loggedIn again as it could have changed due to initTokenChecking
    if (state.loggedIn && !state.fetchingUserInfo) {
      dispatch('updateUserInfo');
    }
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => commit('addObserver', resolve));
  },
  /**
   * @private
   * @param commit
   * @param state
   * @param dispatch
   * @returns {Promise<void>}
   */
  async updateUserInfo({ commit, dispatch, state }) {
    commit('startFetching', FetchingKey.FETCHING_USER_INFO);
    try {
      await Promise.all([dispatch('loadCurrentUser'), dispatch('loadUserCompanies')]);
    } catch (e) {
      console.error('updateUserInfo failed', e);
      dispatch('logout');
    } finally {
      commit('endFetching', FetchingKey.FETCHING_USER_INFO);
      dispatch('clearObservers', { status: 'success' });
    }

    if (!state.user.emailIsConfirmed) {
      const checkEmailVisited = localStorage.getItem(FARMDOK_AUTH_CHECK_EMAIL_VISITED);
      if (
        checkEmailVisited == null ||
        moment(checkEmailVisited, 'YYYY-MM-DD').format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')
      ) {
        router.push({ name: 'register-check-email' }).catch(() => {
          // #356 ignore route guard errors
          // https://stackoverflow.com/questions/62512168/redirecting-twice-in-a-single-vue-navigation/62563439#62563439
        });
        return;
      }
    }

    if (state.userCompanies.length === 0) {
      router.push({ name: 'no-company' }).catch(() => {
        // #356 ignore route guard errors
        // https://stackoverflow.com/questions/62512168/redirecting-twice-in-a-single-vue-navigation/62563439#62563439
      });
    }
  },
  /**
   * Call if user/companies are already loaded but you want to reload them from the server.
   *
   * @return {Promise<void>}
   */
  async refreshUserCompanies({ commit, dispatch, state }) {
    if (!state.userInfoLoaded) {
      return dispatch('subscribe');
    }
    if (state.fetchingUserInfo || state.fetchingCompanies) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => commit('addObserver', resolve));
    }
    commit('startFetching', FetchingKey.FETCHING_COMPANIES);
    try {
      await dispatch('loadUserCompanies');
    } catch (error) {
      console.error('refreshUserCompanies failed', error);
      dispatch('logout');
    } finally {
      commit('endFetching', FetchingKey.FETCHING_COMPANIES);
      dispatch('clearObservers', { status: 'success' });
    }
    return null;
  },
  async loadCurrentUser({ commit, state }) {
    const { data } = await axios.get('/admin/rest/user/current');
    if (state.user.id !== data.data.id) {
      throw new Error('loadCurrentUser: id mismatch!');
    }
    commit('setUser', data.data);
  },
  /**
   * Get current companies from cookie. Can also be set by BE.
   * #340 vue_pages needs to be able to overwrite this behaviour.
   *
   * @override
   * @returns {null}
   */
  getCurrentCompanies() {
    return getCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME);
  },
  async loadUserCompanies({ commit, dispatch, state, getters }) {
    const filter = ['AND', ['userId', '=', state.user.id], ['active', '=', 1]];
    let data = { data: [] };
    try {
      const response = await axios.get(`/admin/rest/companyUser?itemsPerPage=5000&filter=${dataToBase64(filter)}`);
      ({ data } = response);
    } catch (error) {
      if (
        error == null ||
        // @ts-ignore
        error.response == null ||
        // @ts-ignore
        error.response.data == null ||
        // @ts-ignore
        error.response.data.errorCode !== 'noCompany'
      ) {
        console.error(error);
        throw error;
      }
    }
    const userCompanies: UserCompany[] = [];
    await Promise.all(
      data.data.map(async (userCompany) => {
        const resolve = ['region'];
        const response = await axios.get(
          // @ts-ignore
          `/admin/rest/company/${userCompany.companyId}?resolve=${dataToBase64(resolve)}`,
        );
        userCompanies.push({
          // @ts-ignore
          ...userCompany,
          company: response.data.data,
        });
        if (response.data.data.features != null) {
          commit(
            'features/addFeaturesToCompany',
            { companyId: response.data.data.id, features: response.data.data.features },
            { root: true },
          );
        }
      }),
    );
    commit('setUserCompanies', userCompanies);

    // initialize currentCompanies
    // 1. Try to get from FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME cookie
    // 2. If the cookie is not set try to set the user default company
    // 3. If default company isn't available just use the first company that is available
    const companies = [];
    let processOrderName = moment().format('YYYY');
    const currentCompanies = await dispatch('getCurrentCompanies');
    if (currentCompanies != null && Array.isArray(currentCompanies.companies)) {
      currentCompanies.companies.forEach((id: string) => {
        if (state.companiesById[id] == null) {
          return;
        }
        companies.push(state.companiesById[id]);
      });
      ({ processOrderName } = currentCompanies);
    }
    if (companies.length === 0) {
      // @ts-ignore
      if (state.user.companyId != null && state.companiesById[state.user.companyId] != null) {
        // @ts-ignore
        companies.push(state.companiesById[state.user.companyId]);
        // @ts-ignore
        const { processOrderId } = state.companiesById[state.user.companyId];
        if (processOrderId != null && getters.processOrdersById[processOrderId] != null) {
          processOrderName = getters.processOrdersById[processOrderId].name;
        }
      } else if (userCompanies.length > 0) {
        companies.push(userCompanies[0].company);
      }
    }
    commit('setCurrentCompaniesAndProcessOrderName', {
      companies,
      processOrderName,
    });
    dispatch('persistCurrentCompanies');
  },
  async login({ commit, state, dispatch }, { email, password }) {
    localStorage.removeItem(FARMDOK_AUTH_CHECK_EMAIL_VISITED);
    localStorage.removeItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE);
    dispatch('resetData', null, { root: true });
    commit('startFetching', FetchingKey.FETCHING_LOGIN);
    const loginResponse = await state.jwtAuth?.login(email, password);
    commit('endFetching', FetchingKey.FETCHING_LOGIN);
    if (loginResponse?.status !== 'success') {
      return loginResponse;
    }
    // #667 reset current companies cookie if SU (super admin) logs in to prevent unwillingingly editing customer companies
    if (loginResponse.data != null && loginResponse.data.access_token != null) {
      // @ts-ignore
      const body = state.jwtAuth?.constructor.getDataFromToken(loginResponse.data.access_token);
      if (body != null && body.super_admin === true) {
        setCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME, null);
      }
    }
    return dispatch('subscribe');
  },
  async loginWithOneTimeToken({ commit, state, dispatch }, { oneTimeToken }) {
    localStorage.removeItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE);
    dispatch('resetData', null, { root: true });
    commit('startFetching', FetchingKey.FETCHING_LOGIN);
    const loginResponse = await state.jwtAuth?.loginOneTime(oneTimeToken);
    commit('endFetching', FetchingKey.FETCHING_LOGIN);
    if (loginResponse?.status !== 'success') {
      return loginResponse;
    }
    if (loginResponse?.data != null && loginResponse.data.access_token != null) {
      // @ts-ignore
      const body = state.jwtAuth?.constructor.getDataFromToken(loginResponse.data.access_token);
      if (body != null && body.super_admin === true) {
        setCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME, null);
      }
    }
    return dispatch('subscribe');
  },
  async logout({ commit, dispatch, state }) {
    localStorage.removeItem(FARMDOK_AUTH_CHECK_EMAIL_VISITED);
    localStorage.removeItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE);
    commit('startFetching', FetchingKey.FETCHING_LOGIN);
    await state.jwtAuth?.logout();
    commit('setLoggedOut');
    dispatch('resetData', null, { root: true });
    commit('endFetching', FetchingKey.FETCHING_LOGIN);
  },
  async register({ commit, dispatch }, { email, password, regionId, onboarding = null }) {
    commit('startFetching', FetchingKey.FETCHING_REGISTER);
    try {
      const response = await axios.post('/admin/rest/user/register', {
        version: '2.0',
        data: {
          email,
          password,
          regionId,
          onboarding,
        },
      });
      if (response.data.status !== 'success') {
        return response;
      }
    } catch (error) {
      // @ts-ignore
      if (error == null || error.response == null) {
        return null;
      }
      // @ts-ignore
      return error.response.data;
    }
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const loginResponse = await dispatch('login', { email, password });
    commit('endFetching', FetchingKey.FETCHING_REGISTER);
    localStorage.setItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE, JSON.stringify(true));
    return loginResponse;
  },
  async updateAndSyncUser({ state, commit }, data) {
    commit('updateUser', data);
    let responseData = null;
    try {
      const response = await axios.put(`/admin/rest/user/${state.user.id}`, {
        version: '2.0',
        data,
      });
      responseData = response.data;
    } catch (error) {
      // @ts-ignore
      if (error != null && error.response != null) {
        // @ts-ignore
        responseData = error.response.data;
      }
    }
    return responseData;
  },
  /**
   * Use updateAndSyncCompanyById insead #326
   *
   * @param commit
   * @param id
   * @param data
   * @returns {Promise<any|T>}
   */
  async updateAndSyncCurrentCompany({ commit }, { id, data }) {
    commit('updateCurrentCompany', { id, data });
    let responseData = null;
    try {
      const response = await axios.put(`/admin/rest/company/${id}`, {
        version: '2.0',
        data,
      });
      responseData = response.data;
    } catch (error) {
      // @ts-ignore
      if (error != null && error.response != null) {
        // @ts-ignore
        responseData = error.response.data;
      }
    }
    return responseData;
  },
  clearObservers({ state, commit }, response) {
    state.observers.forEach((resolve) => resolve(response));
    commit('clearObservers');
  },
  async createProcessOrder({ state, getters }, { direction, copyFields = true, sourceProcessOrderName = null }) {
    if (!['next', 'previous'].includes(direction)) {
      console.error('createProcessOrder called with invalid direction');
      return { status: 'error' };
    }
    let finalSourceProcessOrderName = state.currentProcessOrderName;
    if (sourceProcessOrderName != null) {
      finalSourceProcessOrderName = sourceProcessOrderName;
    }
    const sourceProcessOrders = state.currentCompanies.map((company) =>
      getters.processOrderByCompanyIdAndNameAndType(company.id, finalSourceProcessOrderName),
    );
    const responsesData = await Promise.all(
      sourceProcessOrders.map(async (sourceProcessOrder) => {
        try {
          const { data } = await axios.post(`/admin/rest/processOrder/create_${direction}`, {
            version: '2.0',
            data: {
              id: sourceProcessOrder.id,
              copyFields,
            },
          });
          return getRestResponseData(data);
        } catch (error) {
          return getRestResponseData(error);
        }
      }),
    );
    return mergeRestResponseData(...responsesData);
  },
  persistCurrentCompanies({ state, getters }) {
    if (state.currentCompanies.length === 0 || state.currentProcessOrderName == null) {
      setCookie(FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME, null);
      return;
    }
    setCookie(
      FARMDOK_AUTH_CURRENT_COMPANIES_AND_PROCESS_ORDER_NAME,
      {
        companies: state.currentCompanies.map((company) => company.id),
        processOrderName: state.currentProcessOrderName,
      },
      // @ts-ignore
      60 * 60 * 24 * 30,
    );
    try {
      const requestData = state.currentCompanies.map((company) => ({
        id: company.id,
        processOrderId: getters.processOrderByCompanyIdAndNameAndType(company.id, state.currentProcessOrderName).id,
      }));
      axios.put('/admin/rest/company/', {
        version: '2.0',
        data: requestData,
      });
    } catch (error) {
      console.error('Unable to update company processOrderIds', error);
    }
  },
};

export default actions;
