import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';

import { Violation, ViolationNotChecked } from '../types';
import { RuleCheckState } from './types';

const actions: ActionTree<RuleCheckState, RootState> = {
  dismissViolations({ dispatch }, violations: Array<Violation | ViolationNotChecked>) {
    const activityRuleViolationIds = violations.map((violation) => violation.activityRuleViolationId);
    dispatch('activityRuleViolations/dismiss', activityRuleViolationIds);
  },
};

export default actions;
