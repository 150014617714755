<template>
  <FormFieldDropdown
    :label="$t('W\u00e4hle den ausf\u00fchrenden Betrieb')"
    :placeholder="$t('W\u00e4hle den ausf\u00fchrenden Betrieb')"
    :value="dropdownValue"
    :items="dropdownItems"
    @update="update"
    :id="id"
    :required="required"
    :variant="variant"
    :state="state"
    :itemsLoading="itemsLoading"
    :various="various"
    :disabled="disabled"
    :disableDelete="disableDelete"
    :slimMargin="slimMargin"
    :hideLabel="hideLabel"
  />
</template>

<script>
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';

export default {
  name: 'CompanySelectFormFieldDropdown',
  components: { FormFieldDropdown },
  props: {
    companies: {
      type: Array,
      required: true,
    },
    selectedCompany: {
      type: Object,
      required: true,
      validator: (value) => ['id', 'name'].every((key) => Object.keys(value).includes(key)),
    },
    // props passed to FormFieldDropdown
    /**
     * Id set for input. If not set a unique id is generated as bootstrap places the label next to the input.
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * Adds the `required` attribute to the form control.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Supported types:
     *  <table style="width:30%;">
     *    <tr><td>'horizontal'</td><td>Label to the left.</td></tr>
     *    <tr><td>'vertical'</td><td>Label above. Increased margin bottom.</td></tr>
     *    <tr><td>'vertical-lg'</td><td>Label above. Increases margin bottom and input paddings.</td></tr>
     * </table>
     */
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical', 'vertical-lg'].includes(value),
    },
    /**
     * If set to `true` or `false` the input will be highlighted with colors and icons, depending on the state.<br>
     * Default `null`.
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * If the items are not fetched yet set this to `true`. A spinner will be shown when the user opens the dropdown.
     */
    itemsLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to `true` the following props will be overwritten:
     *   - value with `null`
     *   - placeholder with `'(verschiedene)'`
     */
    various: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable the form element.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    slimMargin: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'selectedCompany',
    event: 'update:selectedCompany',
  },
  computed: {
    dropdownValue() {
      return this.selectedCompany;
    },
    dropdownItems() {
      return [{ items: this.companies }];
    },
  },
  methods: {
    update(dropdownValue) {
      const selectedCompany = this.companies.find((company) => company.id === dropdownValue.id);
      this.$emit('update:selectedCompany', selectedCompany);
    },
  },
};
</script>
