var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.d500",value:(_vm.tooltipOptions),expression:"tooltipOptions",modifiers:{"hover":true,"bottom":true,"d500":true}}],staticClass:"button",class:[
    `button--${_vm.variantComputed}`,
    `button--${_vm.size}`,
    {
      'button--has-next-sibling': _vm.hasNextSibling,
      'button--has-transparent-next-sibling': _vm.hasTransparentNextSibling,
    },
  ],style:(_vm.buttonStyle),attrs:{"size":_vm.size,"variant":_vm.variantComputed,"title":_vm.iconOnly || _vm.tooltipOptions ? _vm.title : '',"block":_vm.block,"disabled":_vm.disabled || _vm.fetching,"data-namespace":_vm.componentNamespace,"data-testid":"button"},on:{"click":_vm.onClick}},[(_vm.iconComputed != null && _vm.leadingIcon)?_c('span',{staticClass:"button__leading-icon",class:{
      'button__leading-icon--icon-only': _vm.iconOnly,
      'button__leading-icon--with-notification': _vm.notificationComputed != null,
    }},[_c('FontAwesomeIcon',{staticClass:"mx-0",attrs:{"icon":_vm.iconComputed,"spin":_vm.fetching}})],1):_vm._e(),(!_vm.iconOnly)?_c('span',{staticClass:"button__label",class:{ 'button__label--with-notification': _vm.iconComputed == null && _vm.notificationComputed != null }},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2):_vm._e(),(_vm.iconComputed != null && !_vm.leadingIcon)?_c('span',{staticClass:"button__trailing-icon",class:{
      'button__trailing-icon--icon-only': _vm.iconOnly,
      'button__trailing-icon--with-notification': _vm.notificationComputed != null,
    }},[_c('FontAwesomeIcon',{staticClass:"mx-0",attrs:{"icon":_vm.iconComputed,"spin":_vm.fetching}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }