import { Activity, ActivityEquipment, ActivityProduct } from 'farmdok-rest-api';

import ActivityProductService from '@/activities/services/ActivityProductService';

export default class FindService {
  private readonly activities: Activity[];

  private readonly activityProductService: ActivityProductService;

  constructor(activities: Activity[], activityProductService: ActivityProductService) {
    this.activities = activities;
    this.activityProductService = activityProductService;
  }

  public findActivityByFieldId(fieldId: string): Activity | undefined {
    return this.activities.find((activity) => activity.fieldId === fieldId);
  }

  public findActivitiesByFieldId(fieldId: string): Activity[] {
    return this.activities.filter((activity) => activity.fieldId === fieldId);
  }

  public findActivityProduct(id: string): ActivityProduct | undefined {
    for (let i = 0; i < this.activities.length; i += 1) {
      const activity = this.activities[i];
      const activityProduct = activity.products.find((product) => product.id === id);
      if (activityProduct) return activityProduct;
    }

    return undefined;
  }

  public getActivityProduct(id: string): ActivityProduct {
    const activityProduct = this.findActivityProduct(id);
    if (!activityProduct) throw new Error(`ActivityProduct not found: ${id}`);

    return activityProduct;
  }

  public findActivityEquipment(id: string): ActivityEquipment | undefined {
    for (let i = 0; i < this.activities.length; i += 1) {
      const activity = this.activities[i];
      const activityEquipment = activity.equipment.find((equipment) => equipment.id === id);
      if (activityEquipment) return activityEquipment;
    }

    return undefined;
  }

  public getActivityEquipment(id: string): ActivityEquipment {
    const activityEquipment = this.findActivityEquipment(id);
    if (!activityEquipment) throw new Error(`ActivityEquipment not found: ${id}`);

    return activityEquipment;
  }

  public findActivityProductFamilyIds(id: string): string[] {
    const activityProduct = this.findActivityProduct(id);
    if (!activityProduct) return [];

    const activityProductFamilyIds: string[] = [];
    this.activities.forEach((activity) => {
      activity.products.forEach((product) => {
        if (this.activityProductService.isSibling(product, activityProduct)) {
          activityProductFamilyIds.push(product.id);
        }
      });
    });

    return activityProductFamilyIds;
  }
}
