import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';
import { TracksGpsState } from '@/tracks/store/types';

import { subscribableStore } from './common';

const actions: ActionTree<TracksGpsState, RootState> = {
  ...subscribableStore.actions,
};

export default actions;
