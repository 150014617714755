<template>
  <MaxWidthContainer class="fields-no-content" size="xl">
    <img :src="imageSrc" alt="no-content" class="fields-no-content__icon" />
    <h1 class="mt-6 mb-4">
      <span v-if="type === 'company'">
        {{ $t('Es wurden noch keine Felder angelegt. Erstelle neue Felder!') }}
      </span>
      <span v-if="type === 'cropYear'">
        {{ $t('In diesem Erntejahr sind noch keine Flächen verfügbar.') }}
      </span>
      <span v-if="type === 'search'">
        {{ $t('Keine Ergebnisse gefunden. Ändere den Suchbegriff.') }}
      </span>
    </h1>
    <p>
      <span v-if="type === 'company'">
        <!-- eslint-disable max-len -->
        {{
          $t(
            'Du kannst das auch bequem mit dem Smartphone oder Tablet auf der Couch machen oder auch alle deine Flächen auf einmal importieren.',
          )
        }}
        <!-- eslint-enable max-len -->
      </span>
      <span v-if="type === 'cropYear' && variant === 'copyFromPrevious'">
        {{ $t('Sollen die Flächen aus dem Vorjahr übernommen werden?') }}
      </span>
      <span v-if="type === 'cropYear' && variant === 'copyFromNext'">
        {{ $t('Sollen die Flächen aus dem nachfolgenden Erntejahr übernommen werden?') }}
      </span>
    </p>
    <div class="mt-4">
      <Button
        v-if="type === 'company' && variant === 'import'"
        leading-icon
        icon="cloud-upload"
        :disabled="readOnly"
        @click="$emit('import')"
      >
        {{ $t('Felder importieren') }}
      </Button>
      <Button
        v-if="type === 'company' && variant === 'create'"
        leading-icon
        icon="plus-circle"
        :disabled="readOnly"
        @click="$emit('create')"
      >
        {{ $t('Felder hinzufügen') }}
      </Button>
      <Button
        v-if="type === 'cropYear' && variant === 'copyFromPrevious'"
        leading-icon
        icon="file-import"
        :fetching="fetching"
        :disabled="readOnly || errorResponseData != null"
        @click="$emit('copyFromPreviousProcessOrder')"
      >
        {{ $t('Flächen aus dem Vorjahr übernehmen') }}
      </Button>
      <Button
        v-if="type === 'cropYear' && variant === 'copyFromNext'"
        leading-icon
        icon="file-import"
        :fetching="fetching"
        :disabled="readOnly || errorResponseData != null"
        @click="$emit('copyFromNextProcessOrder')"
      >
        {{ $t('Flächen aus dem Nachfolgejahr übernehmen') }}
      </Button>
      <ButtonLink v-if="type === 'search'" @click="$emit('clearAllFilters')">
        {{ $t('Suche zurücksetzen') }}
      </ButtonLink>
    </div>
    <div v-if="errorResponseData != null">
      <ErrorUserMessage :response-data="errorResponseData" />
      <div class="mt-2">
        <ButtonLink @click="$emit('refresh')">
          {{ $t('Neu laden') }}
        </ButtonLink>
      </div>
    </div>
  </MaxWidthContainer>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCloudUpload, faFileImport, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';

library.add(faFileImport, faPlusCircle, faCloudUpload);

const images = require.context('../assets/');

export default {
  name: 'NoContent',
  components: {
    Button,
    ButtonLink,
    MaxWidthContainer,
    ErrorUserMessage,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => ['company', 'cropYear', 'search', 'loading'].includes(val),
    },
    variant: {
      type: String,
      default: null,
      validator(val) {
        return ['import', 'create', 'copyFromPrevious', 'copyFromNext'].includes(val);
      },
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    errorResponseData: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSrc() {
      if (!['company', 'cropYear', 'search'].includes(this.type)) {
        return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
      }
      return images(`./no-content-${this.type}.svg`);
    },
  },
};
</script>

<style scoped>
.fields-no-content {
  padding-top: 46px; /* If you change this, consider changing the --from-top css variable in TableFieldsContainer.vue */
  text-align: center;
}
.fields-no-content__icon {
  width: 295px;
  height: 295px;
  object-fit: cover;
  background-color: var(--gray_100);
  border-radius: 50%;
}
</style>
