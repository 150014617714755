
import { defineComponent } from 'vue';

import SidebarFields from '@/fields/components/SidebarFields.vue';
import columnsSidebarTableFields from '@/fields/handsontable/columns/applicationMapsSidebarTableFields';

export default defineComponent({
  name: 'SidebarFieldsContainer',
  components: {
    SidebarFields,
  },
  data() {
    return {
      columnDefinition: columnsSidebarTableFields,
    };
  },
  computed: {
    selectedFields: {
      get(): string[] {
        return this.$store.state.precisionFarming.applicationMaps.fertilizationCereals.selectedFields;
      },
      set(selected: string[]) {
        this.$store.commit('precisionFarming/applicationMaps/fertilizationCereals/setSelectedFields', selected);
      },
    },
  },
});
