<template>
  <ul class="file-groups-list">
    <li v-for="fileGroup in fileGroups" class="file-group" :key="fileGroup.key">
      <div v-if="fileGroup.subFiles && fileGroup.subFiles.length > 0" class="file-group-title">
        {{ fileGroup.file.name }}
      </div>
      <div v-else class="item">
        <div class="item-label">{{ fileGroup.file.name }}</div>
        <FontAwesomeIcon
          v-if="fileGroup.errors.length === 0"
          class="item-icon item-icon--success"
          :icon="['far', 'check-circle']"
          data-cy="success-icon"
        />
      </div>
      <ul class="file-subgroup">
        <li v-for="subFile in fileGroup.subFiles" :key="`${fileGroup.key}_${subFile.name}`" class="item">
          <div class="item-label">
            {{ subFile.name }}
          </div>
          <FontAwesomeIcon
            v-if="fileGroup.errors.length === 0"
            class="item-icon item-icon--success"
            :icon="['far', 'check-circle']"
            data-cy="success-icon"
          />
        </li>
        <li v-for="error in fileGroup.errors" class="text-danger item" :key="`${fileGroup.key}_${error.key}`">
          <div class="item-label">
            {{ error.message }}
          </div>
          <FontAwesomeIcon class="text-danger item-icon" :icon="['far', 'exclamation-circle']" data-cy="error-icon" />
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FilesList',
  props: {
    fileGroups: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.file-groups-list {
  margin: 0;
  padding: var(--spacer_2);
  list-style: none;
  font-size: 0.857rem;
}

.file-group {
  margin: var(--spacer_2);
  padding: var(--spacer_3);
  border: 1px solid var(--gray_400);
  background-color: var(--gray_100);
  cursor: pointer;
}

.file-group--selected {
  border-color: var(--primary_dark);
  background-color: var(--primary_light);
}

.file-group-title {
  font-weight: 600;
  margin-bottom: var(--spacer_2);
}

.file-subgroup {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  margin: var(--spacer_1) 0;
}

.item-label {
  flex: 1;
}

.item-icon {
  font-size: 1rem;
}

.item-icon--success {
  color: var(--primary_darker);
}
</style>
