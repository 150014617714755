import Handsontable from 'handsontable';
import Vue from 'vue';

import DateEditorComponent from './DateEditor.vue';

export default class DateEditor extends Handsontable.editors.NumericEditor {
  init() {
    super.init();
  }

  prepare(visualRow, col, prop, td, originalValue, cellProperties) {
    super.prepare(visualRow, col, prop, td, originalValue, cellProperties);
    if (originalValue == null || Number.isNaN(originalValue)) {
      this.dropdown.$children[0].setDate(null);
    } else {
      this.dropdown.$children[0].setDate(new Date(originalValue * 1000));
    }
  }

  createElements() {
    super.createElements();
    const container = this.TEXTAREA_PARENT.appendChild(document.createElement('div'));
    this.dropdown = new Vue({
      render: (h) => h(DateEditorComponent),
    }).$mount(container);
    this.dropdown.$children[0].$on('input', (date) => {
      this.setValue(Math.floor(date / 1000));
      this.finishEditing(false);
    });
    this.dropdown.$children[0].$on('hidden', () => {
      this.finishEditing(true);
    });
  }

  open() {
    super.open();
    this.TEXTAREA_PARENT.firstChild.style.display = 'none';
    this.TEXTAREA_PARENT.style.height = `${this.TD.clientHeight + 1}px`;
    this.TEXTAREA_PARENT.style.backgroundColor = 'white';
  }

  finishEditing(restoreOriginalValue, ctrlDown, callback) {
    if (this.getValue() == null || this.getValue().length === 0) {
      super.finishEditing(true, ctrlDown, callback);
    } else if (this.state === 'STATE_EDITING' && Number(this.getValue()) === this.originalValue) {
      super.finishEditing(true, ctrlDown, callback);
    } else {
      super.finishEditing(restoreOriginalValue, ctrlDown, callback);
    }
  }

  close() {
    super.close();
    this.TEXTAREA_PARENT.firstChild.style.display = 'block';
    this.hot.listen();
  }
}
