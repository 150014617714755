import Vue from 'vue';
import { ActionTree } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

import ProductPesticideIndicationApiService from '../services/ProductPesticideIndicationApiService';
import { PesticideIndicationsState } from './types';

export type ActionPayloads = {
  loadByIds: string[];
};

const requiredFeatures = [availableFeatures.FEATURE_PESTS, availableFeatures.FEATURE_ACTIVITIES_PESTICIDE_DETAILS];

const actions: ActionTree<PesticideIndicationsState, RootState> = {
  async loadByIds({ commit, rootGetters, state }, pesticideIds: string[]) {
    if (!rootGetters['features/currentCompaniesHaveFeaturesEnabled'](requiredFeatures)) {
      return;
    }

    const pesticideIdsToLoad = pesticideIds.filter((id) => !state.data[id]);

    commit('setFetching', true);

    const { productsApi } = Vue.prototype.$api as Api;
    const api = new ProductPesticideIndicationApiService(productsApi);

    try {
      const indicationsByPesticideId = await api.getProductPesticideIndications(pesticideIdsToLoad);

      pesticideIdsToLoad.forEach((pesticideId) => {
        commit('setData', { pesticideId, pesticideIndications: indicationsByPesticideId[pesticideId] });
      });
    } catch (error) {
      console.error(error);
      pesticideIdsToLoad.forEach((pesticideId) => {
        commit('setData', { pesticideId, pesticideIndications: [] });
      });
    }

    commit('setFetching', false);
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default actions;
