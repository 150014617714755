import axios from 'axios';
import { GetterTree } from 'vuex';

import { dataUrl as fieldsDataUrl } from '@/fields/store/common';
import { ProcessOrder } from '@/shared/api/rest/models';
import { dataToBase64 } from '@/shared/api/rest/requestUtils';
import { RootState } from '@/store/types';

import isMulticompany from '../isMulticompany';
import { availableProcessOrderNames } from './common';
import { AuthState, Company, FeatureName, UserCompany } from './types';

export type Getters = {
  initialFetching: boolean;
  processOrderByCompanyIdAndNameAndType: (companyId: string, name: string, type?: string) => ProcessOrder | null;
  processOrderByCompanyIdAndNameAndTypeAsync: (
    companyId: string,
    name: string,
    type?: string,
  ) => Promise<ProcessOrder | null>;
  findCompanyByProcessOrderId: (processOrderId: string) => Company | undefined;
  getCompanyByProcessOrderId: (processOrderId: string) => Company;
  findCompanyById: (companyId: string) => Company | undefined;
  getCompanyById: (companyId: string) => Company;
  accessToken: string | null;
  currentProcessOrders: ProcessOrder[];
  currentProcessOrderIds: string[];
  currentCompanyIds: string[];
  currentCompaniesRegionIds: string[];
  currentCompaniesRegionCodes: string[];
  processOrdersById: Record<string, ProcessOrder>;
  availableProcessOrderNames: string[];
  currentCompaniesHaveFieldsInCropYear: (processOrderName: string) => Promise<boolean>;
  currentCompaniesIsMulticompany: boolean;
  userName: string;
  userCompaniesWithFeatureEnabled: (featureName: FeatureName) => UserCompany[];
};

const authGetters: GetterTree<AuthState, RootState> = {
  initialFetching: (state) => state.fetchingInitialAccessToken || state.fetchingUserInfo,
  processOrderByCompanyIdAndNameAndType:
    (state) =>
    (companyId: string, name: string, type = 'inwork') => {
      let processOrder = null;
      if (state.companiesById[companyId] == null || !Array.isArray(state.companiesById[companyId].processOrders)) {
        return processOrder;
      }
      state.companiesById[companyId].processOrders.some((currentProcessOrder) => {
        if (Number(currentProcessOrder.name) === Number(name) && currentProcessOrder.type === type) {
          processOrder = currentProcessOrder;
          return true;
        }
        return false;
      });
      return processOrder;
    },
  processOrderByCompanyIdAndNameAndTypeAsync:
    (state, getters) =>
    async (companyId: string, name: string, type = 'inwork') => {
      const processOrder = getters.processOrderByCompanyIdAndNameAndType(companyId, name, type);
      if (processOrder != null) {
        return processOrder;
      }
      const filter = [`companyId=${companyId}`, `name=${name}`, `type=${type}`];
      try {
        const response = await axios.get(`/admin/rest/processOrder/by_company_name_type?${filter.join('&')}`);
        if (response.data != null && response.data.status === 'success') {
          return response.data.data;
        }
      } catch {
        return processOrder;
      }
      return processOrder;
    },
  /**
   * @deprecated use CompanyService.findCompanyByProcessOrder instead
   * @param state
   * @returns
   */
  findCompanyByProcessOrderId: (state) => (processOrderId: string) =>
    state.currentCompanies.find((company: Company) =>
      company.processOrders.some((processOrder: ProcessOrder) => processOrder.id === processOrderId),
    ),
  getCompanyByProcessOrderId: (state, getters) => (processOrderId: string) => {
    const company = getters.findCompanyByProcessOrderId(processOrderId);
    if (!company) throw new Error(`Company not found for process order id ${processOrderId}`);

    return company;
  },
  findCompanyById: (state) => (companyId: string) => state.companiesById[companyId],
  getCompanyById: (state, getters) => (companyId: string) => {
    const company = getters.findCompanyById(companyId);
    if (!company) throw new Error(`Company with id ${companyId} not found`);

    return company;
  },
  /**
   * Reflects changes in the access token. I.e. if access token is refreshed. The getter is re-evaluated.
   */
  accessToken: (state) => () => state.jwtAuth?.getAccessToken(),
  currentProcessOrders(state): ProcessOrder[] {
    if (state.currentProcessOrderName == null) {
      return [];
    }
    return state.currentCompanies
      .map((company) =>
        company.processOrders.filter(
          (processOrder) => String(processOrder.name) === String(state.currentProcessOrderName),
        ),
      )
      .reduce((accumulator, processOrders) => [...accumulator, ...processOrders], []);
  },
  currentProcessOrderIds(state, getters) {
    return getters.currentProcessOrders.map((processOrder: ProcessOrder) => processOrder.id);
  },
  currentCompanyIds(state) {
    return state.currentCompanies.map((company) => company.id);
  },
  currentCompaniesRegionIds: (state) => {
    const regionIds = new Set();
    state.currentCompanies.forEach((company) => regionIds.add(company.regionId));
    return [...regionIds].filter((id) => id != null);
  },
  currentCompaniesRegionCodes: (state) => {
    const regionCodes = new Set();
    state.currentCompanies.forEach((company) => regionCodes.add(company.region.code));
    return [...regionCodes].filter((code) => code != null);
  },
  processOrdersById(state) {
    return Object.values(state.companiesById).reduce(
      (accumulator, company) => ({
        ...accumulator,
        ...company.processOrders.reduce(
          (accumulatorInner, processOrder) => ({
            ...accumulatorInner,
            [processOrder.id]: processOrder,
          }),
          {},
        ),
      }),
      {},
    );
  },
  /**
   * A list of years for which process orders in all of the current companies exist.
   *
   * @returns {array}
   */
  availableProcessOrderNames(state) {
    return availableProcessOrderNames(state.currentCompanies);
  },
  /**
   * Checks in all currentCompanies whether at least one of them has at least one field in a given crop year (inwork only)
   *
   * @returns {(function(processOrderName:string): Promise<boolean>)|*}
   */
  currentCompaniesHaveFieldsInCropYear: (state, getters) => async (processOrderName: string) => {
    const processOrderIds = state.currentCompanies.map(
      (company) => getters.processOrderByCompanyIdAndNameAndType(company.id, processOrderName).id,
    );
    const filter = ['processOrderId', 'IN', processOrderIds];
    const url = `${fieldsDataUrl}/?itemsPerPage=1&filter=${dataToBase64(filter)}`;
    try {
      const { data } = await axios.get(url);
      if (data.data.length > 0) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
    return false;
  },
  currentCompaniesIsMulticompany(state: AuthState): boolean {
    return isMulticompany(state.currentCompanies);
  },
  userName(state) {
    const userName = [];
    if (state.user.firstname != null) {
      userName.push(state.user.firstname);
    }
    if (state.user.lastname != null) {
      userName.push(state.user.lastname);
    }
    return userName.join(' ');
  },
  userCompaniesWithFeatureEnabled: (state: AuthState) => (featureName: FeatureName) =>
    state.userCompanies.filter((userCompany) => userCompany.company.features[featureName]),
};

export default authGetters;
