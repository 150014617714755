import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { DataEntry, SyncableDataState } from '../types';
import actions from './actions';
import initialState from './initialState';
import mutations from './mutations';
import { UpdateEntries } from './types';

export default function syncableData<Entry extends DataEntry>(
  updateEntries: UpdateEntries<Entry>,
): Module<SyncableDataState<Entry>, RootState> {
  return {
    namespaced: true,
    state: initialState<Entry>(),
    mutations,
    actions: actions(updateEntries),
  };
}
