<template>
  <div class="import-map-data">
    <MaxWidthContainer size="xl">
      <div class="import__intro">
        <h2 class="h1 my-3">{{ $t('Daten in FARMDOK zuordnen.') }}</h2>
      </div>
      <ErrorUserMessage :error-user-message="userErrorMessage" />
      <div v-if="mappings.length > 0" class="w-100">
        <p>
          <!-- eslint-disable max-len -->
          {{
            $t(
              'FARMDOK konnte den Ursprung deiner Daten leider nicht eindeutig feststellen. Bitte wähle aus der folgenden Liste den richtigen Eintrag aus:',
            )
          }}
          <!-- eslint-enable max-len -->
        </p>
        <FormFieldSelect
          v-model="mappingId"
          class="w-100"
          required
          :disabled="fetching"
          :label="$t('Daten Ursprung')"
          :options="mappings"
        />
      </div>
    </MaxWidthContainer>
    <div v-if="useMappingsTable" class="w-100">
      <Table fixed transparent size="md" :fields="tableFields" :items="Object.values(importDataColumns)">
        <template #cell(status)="{ item }">
          <FontAwesomeIcon
            v-if="selectedFarmdokColumns[item.key] != null"
            class="text-primary import-map-data__status-icon"
            :icon="['far', 'check-circle']"
          />
          <FontAwesomeIcon
            v-else
            class="text-danger import-map-data__status-icon"
            :icon="['far', 'exclamation-circle']"
          />
        </template>
        <template #cell(examples)="{ value }">
          <div v-for="(example, index) in value" :key="index">
            {{ example }}
          </div>
        </template>
        <template #cell(mapping)="{ item }">
          <FormFieldSelect
            class="w-100"
            variant="no-label"
            :disabled="fetching"
            :value="selectedFarmdokColumns[item.key]"
            :options="farmdokColumns"
            @update="(value) => updateFarmdokColumn(item, value)"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import Vue from 'vue';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Table from '@/shared/components/Table.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';

library.add(faCheckCircle, faExclamationCircle);

const DO_NOT_MAP = 'DO_NOT_MAP';

export default {
  name: 'ImportMapData',
  components: {
    ErrorUserMessage,
    FormFieldSelect,
    MaxWidthContainer,
    Table,
  },
  mixins: [backendResponseErrorHelpers],
  props: {
    fetching: {
      type: Boolean,
      default: false,
    },
    importTargetCompanyId: {
      type: String,
      default: null,
    },
    importData: {
      type: Object,
      default: null,
    },
    importTargetProcessOrderId: {
      type: String,
      default: null,
    },
    manualMapping: {
      type: Object,
      default: null,
    },
    selectedFileGroupName: {
      type: String,
      default: null,
    },
  },
  data() {
    const farmdokColumns = [
      { value: null, text: this.$t('Wählen ...'), disabled: true },
      { value: DO_NOT_MAP, text: this.$t('Nicht übernehmen') },
      { value: 'fieldGroup.number', text: this.$t('Feldstück Nummer') },
      { value: 'field.number', text: this.$t('Nummer') },
      { value: 'field.name', text: this.$t('Bezeichnung') },
      { value: 'field.fieldSize', text: this.$t('Größe') },
      { value: 'crop.name', text: this.$t('Kultur') },
      { value: 'variety.name', text: this.$t('Sorte') },
      { value: 'preCrop.name', text: this.$t('Vorfrucht') },
      { value: 'field.kindofuse', text: this.$t('Nutzungsart') },
      { value: 'field.comment', text: this.$t('Kommentar') },
      { value: 'field.amaCode', text: this.$t('AMA Code') },
      { value: 'field.additionalData.cadastralCommunity', text: this.$t('Gemeinde') },
      { value: 'field.additionalData.plots', text: this.$t('Parzellen') },
      { value: 'field.additionalData.owner', text: this.$t('Eigentümer') },
      { value: 'field.additionalData.rightOfUse', text: this.$t('Nutzung durch') },
    ];

    return {
      mappingId: null,
      farmdokColumns,
      selectedFarmdokColumns: {},
    };
  },
  computed: {
    tableFields() {
      return [
        {
          label: this.$t('Zugeordnet'),
          key: 'status',
          width: '100px',
          tdClass: 'text-center align-middle',
        },
        {
          label: this.$t('Spaltenüberschrift aus Datei'),
          key: 'key',
          width: '20%',
          tdClass: 'align-middle font-weight-bold',
        },
        {
          label: this.$t('Daten-Vorschau'),
          key: 'examples',
          width: '25%',
          tdClass: 'align-middle',
        },
        {
          label: this.$t('FARMDOK Eigenschaft'),
          key: 'mapping',
          tdClass: 'align-middle',
        },
      ];
    },
    mappings() {
      if (this.importData == null || !Array.isArray(this.importData.mappings) || this.importData.mappings.length < 2) {
        return [];
      }
      return [
        { value: null, text: this.$t('Wählen'), disabled: true },
        ...this.importData.mappings.map(({ mapping }) => ({ value: mapping.id, text: mapping.name })),
      ];
    },
    mappedData() {
      if (
        this.importData == null ||
        !Array.isArray(this.importData.mappedData) ||
        this.importData.mappedData.length === 0
      ) {
        return null;
      }
      return this.importData.mappedData;
    },
    rawData() {
      if (this.importData == null) {
        return null;
      }
      return this.importData.rawData;
    },
    importDataColumns() {
      if (this.rawData == null || !Array.isArray(this.rawData.headers) || !Array.isArray(this.rawData.records)) {
        return null;
      }
      return this.rawData.headers.reduce(
        (columns, header, index) => ({
          ...columns,
          [header]: {
            key: header,
            examples: this.rawData.records
              .map((record) => record[index])
              .filter((value) => typeof value === 'string' && value.length > 0)
              .slice(0, 4),
          },
        }),
        {},
      );
    },
    useMappingsTable() {
      return this.mappings.length === 0 && this.mappedData == null && this.rawData != null;
    },
  },
  watch: {
    mappings() {
      this.updateImportNextEnabled();
    },
    mappingId() {
      this.userErrorMessage = null;
      this.updateImportNextEnabled();
    },
  },
  created() {
    if (this.manualMapping != null && this.importDataColumns != null) {
      Object.keys(this.manualMapping).forEach((key) => {
        if (this.importDataColumns[key] == null) {
          return;
        }
        Vue.set(this.selectedFarmdokColumns, key, this.manualMapping[key]);
      });
      this.$emit('update:manualMapping', this.selectedFarmdokColumns);
    }
  },
  mounted() {
    if (this.mappings.length === 0 && !this.useMappingsTable) {
      this.$emit('back');
    }
    this.updateImportNextEnabled();
  },
  methods: {
    updateImportNextEnabled() {
      this.$emit('update:importNextEnabled', this.mappings.length < 2 || this.mappingId != null);
    },
    updateFarmdokColumn(column, value) {
      this.userErrorMessage = null;
      if (this.importDataColumns[column.key] == null) {
        return;
      }
      if (value !== DO_NOT_MAP) {
        Object.keys(this.selectedFarmdokColumns).forEach((key) => {
          if (this.selectedFarmdokColumns[key] === value) {
            Vue.set(this.selectedFarmdokColumns, key, null);
          }
        });
      }
      Vue.set(this.selectedFarmdokColumns, column.key, value);
      this.$emit('update:manualMapping', this.selectedFarmdokColumns);
    },
    async next() {
      this.userErrorMessage = null;
      if (this.mappings.length > 1 && this.mappingId == null) {
        this.userErrorMessage = this.$t('Bitte wähle einen Eintrag aus.');
        return false;
      }
      if (
        this.useMappingsTable &&
        !Object.values(this.importDataColumns).some(
          (column) => this.selectedFarmdokColumns[column.key] === 'field.name',
        )
      ) {
        this.userErrorMessage = this.$t('FARMDOK Eigenschaft "{fieldName}" muss gewählt sein.', {
          fieldName: this.$t('Bezeichnung'),
        });
        return false;
      }
      let manualMapping = null;
      if (this.useMappingsTable) {
        manualMapping = Object.keys(this.importDataColumns).reduce(
          (mapping, key) => ({
            ...mapping,
            [key]: this.selectedFarmdokColumns[key] || null,
          }),
          {},
        );
      }

      this.$emit('update:fetching', true);
      let responseData;
      try {
        const { data } = await axios.post('/admin/rest/field/import_map_data_from_uploaded_files', {
          version: '2.0',
          data: {
            mappingId: this.mappingId,
            manualMapping,
            processOrderId: this.importTargetProcessOrderId,
            fileGroupName: this.selectedFileGroupName,
          },
        });
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      }
      this.$emit('update:fetching', false);
      if (responseData.status !== 'success') {
        this.setUserErrorMessageFromResponse(responseData);
        return false;
      }
      this.$emit('update:importData', responseData.data);
      return true;
    },
  },
};
</script>

<style scoped>
.import-map-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.import__intro {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.import-map-data__status-icon {
  font-size: 16px;
}
</style>
