<template>
  <ThePageSkeleton scroll-y>
    <template #contentbar>
      <TheContentbar>
        <template #left>
          <Button variant="primary" component-namespace-name="ButtonTest"> This is a test button </Button>
          <Button color="primary" outline component-namespace-name="ButtonOtherTest">
            This is another test button
          </Button>
          <Button color="lightest" component-namespace-name="ButtonPseudoTransparent">
            Pseudo Transparent Button
          </Button>
          <Button color="lightest" component-namespace-name="ButtonOtherPseudoTransparent" notification icon="bell">
            Another Pseudo Transparent Button
          </Button>
        </template>
        <template #right="{ contentbarLarge }">
          <ProcessOrderSwitch class="ml-4" :slim="!contentbarLarge" />
          <CompanySwitch class="ml-4" :slim="!contentbarLarge" />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <div class="page-button-test">
        <h2>Button Anatomy</h2>

        <div class="page-button-test--examples-container">
          <h3>Sizes</h3>
          <div class="d-inline-block bg-medium mt-1 mb-1">
            <Button size="sm">Small Button</Button>
            <Button size="sm" outline notification> Another Small Button </Button>
          </div>
          <div class="d-inline-block bg-medium mt-1 mb-1">
            <Button>Default Button</Button>
            <Button outline>Another Default Button</Button>
          </div>
          <div class="d-inline-block bg-medium mt-1 mb-1">
            <Button size="lg">Large Button</Button>
            <Button size="lg" outline> Another Large Button </Button>
          </div>
        </div>

        <div class="page-button-test--examples-container">
          <h3>Colors</h3>
          <div>
            <Button color="primary">Primary Button</Button>
            <Button color="danger">Danger Button</Button>
            <Button color="black">Black Button</Button>
          </div>
          <div>
            <Button color="primary" outline> Primary Outline Button </Button>
            <Button color="danger" outline notification> Danger Outline Button </Button>
            <Button color="black" outline> Black Outline Button </Button>
            <Button color="medium" outline> Medium Outline Button </Button>
          </div>
        </div>

        <div class="page-button-test--examples-container">
          <h3>Pseudo Transparency</h3>
          <div class="bg-lightest">
            <Button>Default Button</Button>
            <Button color="lightest">Lightest Button</Button>
            <Button color="lightest">Another Lightest Button</Button>
          </div>
          <div class="bg-white">
            <Button>Default Button</Button>
            <Button color="white" notification="primary">White Button with notification</Button>
            <Button color="white">Another White Button</Button>
          </div>
        </div>

        <div class="page-button-test--examples-container">
          <h3>Icons</h3>
          <div>
            <Button icon="plus-circle" leading-icon> Default Add </Button>
            <Button :icon="['far', 'arrow-right']"> Default Next </Button>
            <Button
              color="white"
              icon="pencil-alt"
              leading-icon
              title="Default Edit Icon Only"
              :icon-only="iconOnly"
              @click="iconOnly = !iconOnly"
            >
              Default Edit Icon Only
            </Button>
            <Button
              color="white"
              icon="trash-alt"
              leading-icon
              title="Default Scissors Icon Only"
              notification="warning"
              :icon-only="iconOnly"
              @click="iconOnly = !iconOnly"
            >
              Default Delete Icon Only
            </Button>
          </div>
          <div>
            <Button size="sm" color="black" icon="plus-circle" leading-icon> Small Add </Button>
            <Button size="sm" color="black" :icon="['far', 'arrow-right']"> Small Next </Button>
          </div>
          <Button size="lg" block notification> Block Button </Button>
        </div>

        <div class="page-button-test--examples-container">
          <h3>Links</h3>
          <ButtonLink>Hello Link</ButtonLink>
          <ButtonLink icon="pencil-alt">Hello Link With Icon</ButtonLink>
          <ButtonLink color="black" icon="pencil-alt">Hello Black Link With Icon</ButtonLink>
        </div>

        <div class="page-button-test--examples-container">
          <h3>Bordered Icons</h3>
          <div>
            <ButtonBorderedIcon icon="pencil-alt" />
            <ButtonBorderedIcon icon="pencil-alt" disabled />
          </div>
        </div>

        <div class="page-button-test--examples-container">
          <h3>Split Button</h3>
          <div>
            <ButtonDropdown text="Split Button" :buttons="splitButtons" />
            <ButtonDropdown text="Outline Split Button" outline :buttons="splitButtons" />
          </div>
        </div>
      </div>
    </template>
  </ThePageSkeleton>
</template>

<script>
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import ProcessOrderSwitch from '@/shared/components/ProcessOrderSwitch.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonBorderedIcon from '@/shared/components/buttons/ButtonBorderedIcon.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';

export default {
  name: 'PageButtonTest',
  components: {
    ButtonDropdown,
    TheContentbar,
    ThePageSkeleton,
    Button,
    ButtonBorderedIcon,
    ButtonLink,
    CompanySwitch,
    ProcessOrderSwitch,
  },
  data() {
    return {
      iconOnly: true,
      splitButtons: [
        {
          text: 'First Button',
          handler: () => null,
          disabled: false,
          componentNamespaceName: 'SplitButtonFirst',
        },
        {
          text: 'Second Button',
          handler: () => null,
          disabled: false,
          componentNamespaceName: 'SplitButtonSecond',
        },
        {
          text: 'Third Button',
          handler: () => null,
          disabled: true,
          componentNamespaceName: 'SplitButtonThird',
        },
      ],
    };
  },
};
</script>

<style scoped>
.page-button-test {
  padding: var(--spacer_3);
}

.page-button-test--examples-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--spacer_3);
}
</style>
