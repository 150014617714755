const placeholderColumn = {
  data: 'placeholderColumn', // this is just a dummy value, the column is not bound to any data (required for move columns via user column settings)
  type: 'farmdok.placeholder',
  width: 190,
  readOnly: true,
  hiddenInPageSettings: true,
  noHeaderContextMenu: true,
};

export default placeholderColumn;
