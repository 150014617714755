import Handsontable from 'handsontable';

import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import ReadOnlyRenderer from '@/shared/handsontable/renderers/ReadOnlyRenderer';

import { ButtonRenderer } from '../buttonRenderer';
import CollapsedSubtableRenderer from '../collapsedSubtableRenderer/CollapsedSubtableRenderer';
import { DropdownRenderer } from '../dropdown';
import { HOT_DISPLAY_BUTTON_RENDERER } from './constants';
import {
  ColumnSettingsSubtablePrimaryColumn,
  HOT_DISPLAY_BUTTON_RENDERER as HOT_DISPLAY_BUTTON_RENDERER_TYPE,
} from './types';

export default function SubtablePrimaryColumnRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: DropdownItem | HOT_DISPLAY_BUTTON_RENDERER_TYPE | null,
  cellProperties: ColumnSettingsSubtablePrimaryColumn,
  ...args: unknown[]
) {
  if (cellProperties.readOnly) {
    ReadOnlyRenderer(instance, TD, visualRow, visualCol, prop);
  }

  if (value === null) {
    DropdownRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args);
    return;
  }

  if (value === HOT_DISPLAY_BUTTON_RENDERER) {
    ButtonRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args);
    return;
  }
  const isExpanded = instance.getDataAtRowProp(visualRow, 'expand');
  if (!isExpanded) {
    CollapsedSubtableRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args);
    return;
  }

  DropdownRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args);
}
