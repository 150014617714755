
import { PropType, defineComponent } from 'vue';

import { Tab } from './tabsTypes';

/**
 * Tabs can be links to different pages or simple buttons that contain an "onClick" handler.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <Tabs
 *   :tabs="[{ type: 'button', label: 'First', active: true }, { type: 'button', label: 'Second', active: false }]"
 * />
 * @example
 * <Tabs
 *   :tabs="[
 *     { type: 'button', label: 'First', active: false },
 *     { type: 'button', label: 'Second', active: true },
 *     { type: 'button', label: 'Third', active: false }
 *   ]"
 * />
 * @example
 * <Tabs
 *   :tabs="[{ type: 'button', label: 'First', active: true }, { type: 'button', label: 'Second', active: false }]"
 *   :fill="true"
 * />
 */
export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
      validator: (val: string) => ['md', 'lg'].includes(val),
    },
  },
  data() {
    return {
      activeClass: 'text-black tab--active',
      inactiveClass: 'text-dark',
    };
  },
});
