<template>
  <TableBase
    id="hot-contracting-import-fields-yields-field-mapping"
    ref="table"
    disable-read-only-bg
    :filter-string="filterString"
    :table-settings="tableSettings"
    :table-data="tableData"
    :loading="loading"
    @tableMounted="tableMounted"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @update:cell="updateCell"
    @update:filterString="(value) => $emit('update:filterString', value)"
  >
    <template #footer>
      <TableFooter :total-rows="Object.keys(tableData).length" :visible-rows="Object.keys(tableData).length">
      </TableFooter>
    </template>
  </TableBase>
</template>

<script>
import axios from 'axios';

import { crop as columnFieldCrop } from '@/fields/handsontable/columns/columns';
import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import {
  agranaFieldNameCombined as columnAgranaFieldNameCombined,
  amount as columnAmount,
  creationDate as columnCreationDate,
  fieldId as columnFieldId,
  processedArea as columnProcessedArea,
} from '../handsontable/columns/columns';
import columnsTableImportFieldsYieldsFieldMapping, {
  agranaFieldGroupNumber,
  agranaFieldName,
  materialKey,
} from '../handsontable/columns/tableImportFieldsYieldsFieldMapping';

export default {
  name: 'TableImportFieldsYieldsFieldMappingContainer',
  components: { TableFooter, TableBase },
  mixins: [tableContainerBase, calculateSizeTotal],
  props: {
    filterString: {
      type: String,
      default: '',
    },
    fieldsMappingData: {
      type: Array,
      required: true,
    },
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columnDefinition: columnsTableImportFieldsYieldsFieldMapping,
      loading: true,
      crops: [],
      fields: [],
      tableData: {},
    };
  },
  computed: {
    cropsById() {
      return this.crops.reduce(
        (cropsById, crop) => ({
          ...cropsById,
          [crop.id]: crop,
        }),
        {},
      );
    },
    fieldsById() {
      return this.fields.reduce(
        (fieldsById, field) => ({
          ...fieldsById,
          [field.id]: field,
        }),
        {},
      );
    },
    selectedTableData() {
      const tableData = Object.values(this.tableData);
      if (
        tableData.some(
          (row) =>
            row[materialKey] == null ||
            row[columnCreationDate.key] == null ||
            row[columnFieldId.key] == null ||
            row[columnAmount.key] == null,
        )
      ) {
        return null;
      }
      return tableData;
    },
  },
  watch: {
    selectedTableData(newVal) {
      this.$emit('update:selectedTableData', newVal);
    },
  },
  created() {
    this.columnSettingsMiddleware.push(this.columnDropdownData);
  },
  async mounted() {
    await Promise.all([this.loadFields(), this.loadCrops()]);
    this.tableData = this.fieldsMappingData.reduce((tableData, row, index) => {
      const id = String(index + 1);
      let materialId = null;
      if (Object.keys(this.cropsById).includes(String(row[columnFieldCrop.key]))) {
        materialId = row[columnFieldCrop.key];
      }

      let processedArea = null;
      if (row[columnFieldId.key] !== null) {
        processedArea = this.fieldsById[row[columnFieldId.key]].fieldSize;
      }

      return {
        ...tableData,
        [id]: {
          ...row,
          id,
          [columnCreationDate.key]: null,
          [columnAgranaFieldNameCombined.key]: `${row[agranaFieldGroupNumber]} - ${row[agranaFieldName]}`,
          [materialKey]: materialId,
          [columnProcessedArea.key]: processedArea,
        },
      };
    }, {});
    this.loading = false;
  },
  methods: {
    async loadCrops() {
      const { data } = await axios.get(`/admin/digitalContracting/getAllowedCrops/${this.contract.id}`);
      this.crops = data;
    },
    async loadFields() {
      const { data } = await axios.get(
        `/admin/digitalContracting/getFields/${this.contract.id}?ignoreFieldLimitations=true`,
      );
      this.fields = data;
    },
    updateCell({ columnKey, guid: id, value }) {
      let columnType = 'text';
      this.tableSettings.columns.some((column) => {
        if (column.key === columnKey) {
          columnType = column.type;
          return true;
        }
        return false;
      });
      if (columnType === 'numeric' && Number.isNaN(Number(value))) {
        // eslint-disable-next-line no-param-reassign
        value = null;
      }

      if (columnKey === columnFieldId.key) {
        this.tableData[id][columnProcessedArea.key] = this.fieldsById[value].fieldSize;
      }
      this.tableData[id][columnKey] = value;
    },
    columnDropdownData(columns) {
      return columns.map((column) => {
        if (column.key === materialKey) {
          return {
            ...column,
            getRendererValue: ({ value }) => {
              if (this.cropsById[value] != null && typeof this.cropsById[value].name === 'string') {
                return this.cropsById[value].name;
              }
              return '';
            },
            getItems: async () => [
              {
                name: 'Crops',
                id: 'crops',
                items: this.crops,
                sort: false,
              },
            ],
          };
        }
        if (column.key === columnFieldId.key) {
          return {
            ...column,
            getRendererValue: ({ value }) => {
              if (this.fieldsById[value] != null && typeof this.fieldsById[value].combinedName === 'string') {
                return this.fieldsById[value].combinedName;
              }
              return '';
            },
            getItems: async () => [
              {
                name: 'Fields',
                id: 'fields',
                items: this.fields.map((field) => ({
                  id: field.id,
                  name: field.combinedName,
                })),
                sort: false,
              },
            ],
          };
        }
        return column;
      });
    },
  },
};
</script>
