export function hexToRgb(color: string) {
  let hex = color[0] === '#' ? color.slice(1) : color;

  // expand the short hex by doubling each character, fc0 -> ffcc00
  if (hex.length !== 6) {
    hex = (() => {
      const result: any = [];
      Array.from(hex).forEach((c) => {
        result.push(`${c}${c}`);
      });
      return result;
    })().join('');
  }
  const colorStr = hex.match(/#?(.{2})(.{2})(.{2})/)?.slice(1);
  const rgb = colorStr?.map((col: any) => parseInt(col, 16)) ?? [];
  rgb.push(1);
  return rgb;
}

export function rgbtoHex(rgb: any) {
  return `#${rgb
    .map((c: any) => {
      const hex = c.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;
}

export function rgbToHsl(rgb: number[]) {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const diff = max - min;
  const add = max + min;

  let hue;
  if (min === max) {
    hue = 0;
  } else if (r === max) {
    hue = ((60 * (g - b)) / diff + 360) % 360;
  } else if (g === max) {
    hue = (60 * (b - r)) / diff + 120;
  } else {
    hue = (60 * (r - g)) / diff + 240;
  }

  const lum = 0.5 * add;

  let sat;
  if (lum === 0) {
    sat = 0;
  } else if (lum === 1) {
    sat = 1;
  } else if (lum <= 0.5) {
    sat = diff / add;
  } else {
    sat = diff / (2 - add);
  }

  const h = Math.round(hue);
  const s = Math.round(sat * 100);
  const l = Math.round(lum * 100);
  const a = rgb[3] || 1;

  return [h, s, l, a];
}

export function hexToHsl(color: string) {
  const rgb = hexToRgb(color);
  return rgbToHsl(rgb);
}

export function getFontColor(backgroundColor: string) {
  const rgb = hexToRgb(backgroundColor);
  const contrast = rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114;
  if (contrast < 150) {
    return '#ffffff';
  }
  return '#13353c';
}

export function isHexCode(color: string): boolean {
  return /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(color);
}
