
import { defineComponent } from 'vue';

import { availableFeatures } from '@/shared/storeDynamicFeatures';

import ModalRuleCheckViolations from '../components/ModalRuleCheckViolations.vue';
import { Getters as RuleCheckGetters } from '../store/getters';
import { Violation, ViolationNotChecked } from '../types';

export default defineComponent({
  name: 'ModalRuleCheckViolationsContainer',
  components: { ModalRuleCheckViolations },
  computed: {
    showRuleViolationModalForActivity(): string | null {
      return this.$store.state.activities.ruleCheck.showRuleViolationModalForActivity;
    },
    visible(): boolean {
      return !!this.showRuleViolationModalForActivity;
    },
    violations(): Array<Violation | ViolationNotChecked> {
      if (!this.showRuleViolationModalForActivity) return [];
      return (this.$store.getters['activities/ruleCheck/violations'] as RuleCheckGetters['violations'])(
        this.showRuleViolationModalForActivity,
      );
    },
    featureDismissViolationEnabled(): boolean {
      return !this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_RULE_CHECK);
    },
  },
  methods: {
    cancel() {
      this.$store.commit('activities/ruleCheck/hideRuleViolationsModal');
    },
    dismissViolations(violations: Array<Violation | ViolationNotChecked>) {
      this.$store.dispatch('activities/ruleCheck/dismissViolations', violations);
    },
  },
});
