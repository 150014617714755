import type { ImportActivity, ImportActivityEquipment, ImportActivityProduct } from 'farmdok-rest-api';

import { AmountsAndUnits } from '@/activities/types';
import type { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import type { Expandable, Selectable, TableDataBase } from '@/shared/handsontable/rework/types';

export type TableDataImportActivity = TableDataBase &
  Expandable &
  Selectable &
  Omit<ImportActivity, 'activityTypeId' | 'fieldId' | 'products' | 'equipment' | 'userId'> & {
    importStatus?: ImportStatus;
    activityTypeDropdownItem?: DropdownItem;
    fieldDropdownItem?: DropdownItem;
    product?: TableDataImportActivityProduct;
    equipment?: TableDataImportActivityEquipment;
    fieldSize?: number;
    companyName: string;
    user: string | undefined;
  } & {
    storeStatus: symbol | undefined;
  };

export type TableDataImportActivityProduct = Omit<ImportActivityProduct, 'amount' | 'unitId'> & {
  productStorageDropdownItem?: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER';
  pesticideIndicationDropdownItem?: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER';
  isLoading: boolean;
} & AmountsAndUnits;

export type TableDataImportActivityEquipment = Omit<ImportActivityEquipment, 'equipmentId' | 'equipmentIdMatch'> & {
  dropdownItem?: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER';
};

export enum DropdownMatch {
  MATCH = 'MATCH',
  NO_MATCH = 'NO_MATCH',
}

export type ImportStatus = {
  type: ImportStatusType;
  tooltipLabel: string | null;
};

export enum ImportStatusType {
  OK = 'OK',
  DUPLICATE = 'DUPLICATE',
  INCOMPLETE = 'INCOMPLETE',
}
