<template>
  <TableBase
    ref="table"
    context-menu
    context-menu-hide-edit
    hover-buttons
    hover-buttons-no-main-button
    :id="id"
    :cells="columnDropdownCompaniesCells"
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    :read-only="fieldsReadOnly"
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @update:cell="updateEntryByKeyAndValue"
    @create:newRow="insertAndSyncNewEntry"
    @remove:row="(entry) => removeEntries([entry])"
    @reload="loadData"
    @edit="(guids) => $emit('edit', guids)"
    @click:archived="(payload) => $emit('click:archived', payload)"
    @afterScrollVertically="$emit('click:archived')"
    @afterScrollHorizontally="$emit('click:archived')"
  >
    <template #footer>
      <TableFooter
        :total-rows="Object.keys(tableData).length"
        :visible-rows="visibleRows.length"
        :selected-rows="selectedRows.length"
      >
        {{ $t('Summe Größe: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}
      </TableFooter>
    </template>
  </TableBase>
</template>

<script>
import columnDropdownCompanies from '@/auth/mixins/containers/columnDropdownCompanies';
import columnDropdownCustomers from '@/customers/mixins/containers/columnDropdownCustomers';
import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import subscribableTableData from '@/shared/handsontable/mixins/containers/featureSubscribableTableData';
import syncableTableData from '@/shared/handsontable/mixins/containers/featureSyncableTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import { companyId, customer } from '../handsontable/columns/columns';
import columnsTableFieldGroups from '../handsontable/columns/tableFieldGroups';
import { PropertyArchived } from '../store';

export default {
  name: 'TableFieldGroupsContainer',
  components: { TableFooter, TableBase },
  props: {
    showArchived: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [
    tableContainerBase,
    subscribableTableData('fieldGroups'),
    syncableTableData('fieldGroups'),
    columnDropdownCompanies(companyId.key),
    columnDropdownCustomers({
      [customer.key]: 'customer',
    }),
    calculateSizeTotal,
  ],
  computed: {
    loading() {
      if (this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS)) {
        return this.$store.getters['fieldGroups/loading'] || this.$store.getters['customers/loading'];
      }
      return this.$store.getters['fieldGroups/loading'];
    },
    id() {
      return ['hot-field-groups', ...this.$store.state.auth.currentCompanies.map((company) => company.id)].join('-');
    },
    tableData() {
      if (this.showArchived) {
        return this.$store.state.fieldGroups.data;
      }
      return Object.values(this.$store.state.fieldGroups.data).reduce((tableData, fieldGroup) => {
        if (fieldGroup.archived) {
          return tableData;
        }
        return {
          ...tableData,
          [fieldGroup.id]: fieldGroup,
        };
      }, {});
    },
    fieldsReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_FIELDS);
    },
  },
  created() {
    this.updateColumnDefinition();
  },
  watch: {
    showArchived() {
      this.updateColumnDefinition();
    },
  },
  methods: {
    updateColumnDefinition() {
      if (this.showArchived) {
        this.columnDefinition = columnsTableFieldGroups;
        return;
      }
      this.columnDefinition = columnsTableFieldGroups.filter((column) => column.key !== PropertyArchived);
    },
    loadAdditionalData() {
      if (this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS)) {
        this.$store.dispatch('customers/subscribe');
      }
    },
    export() {
      this.$refs.table.export();
    },
  },
};
</script>

<style scoped></style>
