
import { PropType, defineComponent } from 'vue';
import Datepicker, { HighlightedDates } from 'vuejs-datepicker';

import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';

export default defineComponent({
  name: 'DatepickerSection',
  model: {
    prop: 'date',
    event: 'setDate',
  },
  props: {
    selectedDates: {
      type: Array as PropType<Date[] | undefined>,
      default: undefined,
    },
    headline: {
      type: String as PropType<string>,
      default: '',
    },
    workingTime: {
      type: String as PropType<string | null | undefined>,
      default: '',
    },
    setupTime: {
      type: String as PropType<string | null | undefined>,
      default: '',
    },
    drivingTime: {
      type: String as PropType<string | null | undefined>,
      default: '',
    },
    pauseTime: {
      type: String as PropType<string | null | undefined>,
      default: '',
    },
  },
  components: {
    Datepicker,
    FormFieldSet,
    FormFieldInput,
  },
  computed: {
    headlineAdditionalInfo(): string {
      if (this.highlightedDates?.dates && !this.isEqual(this.highlightedDates.dates)) {
        return `${this.headline} (${this.$t('verschiedene')})`;
      }
      return this.headline;
    },
    selectedDate(): Date | null {
      if (this.selectedDates && this.selectedDates.length === 1) {
        return this.selectedDates[0];
      }
      return null;
    },
    highlightedDates(): HighlightedDates {
      if (this.selectedDates && this.selectedDates.length > 1) {
        return {
          dates: this.selectedDates,
        };
      }
      return {};
    },
  },
  methods: {
    isEqual(array: Date[]): boolean {
      if (array.every((val, i, arr) => val.getTime() === arr[0].getTime())) {
        return true;
      }
      return false;
    },
    setSelectedDate(date: Date) {
      this.$emit('setSelectedDate', date);
    },
    setWorkingTime(time: string): void {
      this.$emit('setWorkingTime', time);
    },
    setDrivingTime(time: string): void {
      this.$emit('setDrivingTime', time);
    },
    setSetupTime(time: string): void {
      this.$emit('setSetupTime', time);
    },
    setPauseTime(time: string): void {
      this.$emit('setPauseTime', time);
    },
  },
});
