import Handsontable from 'handsontable';

import { TableDataBaseExpandable } from '../../../types';
import { ColumnSettingsFlattenedSubtable, MergeCellsDetailedSettings } from '../types';
import getLastRowOfSubtable from '../utils/getLastRowOfSubtable';

/**
 * calculates the mergeCells settings
 * @param tableData
 * @param tableColumns
 * @param hot must be defined if tableColumns is of type ColumnSettingsFlattenedSubtable[]
 * @returns
 */
export default function mergeCells(
  tableData: TableDataBaseExpandable[],
  tableColumns: ColumnSettingsFlattenedSubtable[],
  hot?: Handsontable,
): MergeCellsDetailedSettings[] {
  const mergeCellsSettings: MergeCellsDetailedSettings[] = [];

  mergeCellsSettings.push(...mergeRowsOfSameEntryExceptSubtableColumns(tableData, tableColumns, hot));
  mergeCellsSettings.push(...mergeByColspanSetting(tableData, tableColumns, hot));

  return mergeCellsSettings;
}

function mergeRowsOfSameEntryExceptSubtableColumns(
  tableData: TableDataBaseExpandable[],
  tableColumns: ColumnSettingsFlattenedSubtable[],
  hot?: Handsontable,
): MergeCellsDetailedSettings[] {
  if (tableData.length === 0) return [];

  const mergeCellsSettings: MergeCellsDetailedSettings[] = [];
  let firstRowIndexOfEntry: number = 0;
  while (firstRowIndexOfEntry < tableData.length) {
    const lastRowIndexOfEntry = getLastRowOfSubtable(firstRowIndexOfEntry, tableData);
    mergeCellsSettings.push(
      ...mergeRowsExceptSubtableColumns(firstRowIndexOfEntry, lastRowIndexOfEntry, tableColumns, hot),
    );

    firstRowIndexOfEntry = lastRowIndexOfEntry + 1;
  }

  return mergeCellsSettings;
}

export function mergeRowsExceptSubtableColumns(
  firstRowOfEntryIndex: number,
  lastRowOfEntryIndex: number,
  tableColumns: ColumnSettingsFlattenedSubtable[],
  hot?: Handsontable,
): MergeCellsDetailedSettings[] {
  if (firstRowOfEntryIndex === lastRowOfEntryIndex) {
    return []; // is a single row entry - no need to merge
  }

  const mergeCellsSettings: MergeCellsDetailedSettings[] = [];
  tableColumns.forEach((col, physicalColIndex) => {
    if (col.type === 'subtable' || col.isSubtableColumn) return;

    if (typeof col.data !== 'string') throw new Error('data property of column must be a string');
    const visualColIndex = hot?.propToCol(col.data) ?? physicalColIndex;

    mergeCellsSettings.push({
      row: firstRowOfEntryIndex,
      col: visualColIndex,
      rowspan: lastRowOfEntryIndex - firstRowOfEntryIndex + 1,
      colspan: 1,
    });
  });

  return mergeCellsSettings;
}

export function mergeByColspanSetting(
  tableData: TableDataBaseExpandable[],
  tableColumns: ColumnSettingsFlattenedSubtable[],
  hot?: Handsontable,
): MergeCellsDetailedSettings[] {
  const mergeCellsSettings: MergeCellsDetailedSettings[] = [];

  tableColumns.forEach((col, physicalColIndex) => {
    if (!col.colspan || typeof col.colspan !== 'function') return;

    if (typeof col.data !== 'string') throw new Error('data property of column must be a string');
    const visualColIndex = hot?.propToCol(col.data) ?? physicalColIndex;

    const rows = tableData.length;
    for (let rowIndex = 0; rowIndex < rows; rowIndex += 1) {
      const value = hot?.getDataAtCell(rowIndex, visualColIndex) ?? undefined;
      const expand = hot?.getDataAtRowProp(rowIndex, 'expand') ?? tableData[rowIndex].expand;
      const colspan = col.colspan(rowIndex, visualColIndex, value, expand, hot);

      if (colspan > 1) {
        mergeCellsSettings.push({
          row: rowIndex,
          col: visualColIndex,
          rowspan: 1,
          colspan,
        });
      }
    }
  });

  return mergeCellsSettings;
}
