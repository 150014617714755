import { Equipment } from 'farmdok-rest-api';
import _isEqual from 'lodash.isequal';
import Vue from 'vue';

import isMulticompany from '@/auth/isMulticompany';
import { Company } from '@/auth/store/types';
import { ActivityType } from '@/shared/api/rest/models';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { Data } from '@/shared/mixins/store/types';

export default class EquipmentService {
  private readonly equipment: Data<Equipment>;

  constructor(equipment: Data<Equipment>) {
    this.equipment = equipment;
  }

  public findEquipmentById(id: string): Equipment | undefined {
    return this.equipment[id];
  }

  public getEquipmentById(id: string): Equipment {
    const equipment = this.findEquipmentById(id);
    if (!equipment) throw new Error(`Equipment with id ${id} not found`);
    return equipment;
  }

  public findEquipmentOfCompany(id: string, companyId: string): Equipment | undefined {
    const equipment = this.findEquipmentById(id);
    if (!equipment || !equipment.companyId) return undefined;

    if (equipment.companyId === companyId) return equipment;

    const siblings = this.findSiblingsOfEquipment(equipment);
    return siblings.find((sibling) => sibling.companyId === companyId);
  }

  /**
   *
   * @returns the equipment with the given id or the related equipment of the given company
   */
  public getEquipmentOrSibling(id: string, companyId: string): Equipment {
    const equipment = this.getEquipmentById(id);
    if (!EquipmentService.isCompanySpecificEquipment(equipment)) return equipment;

    const equipmentOfCompany = this.findEquipmentOfCompany(id, companyId);
    if (!equipmentOfCompany) throw new Error(`Equipment of company with id ${id} not found`);
    return equipmentOfCompany;
  }

  public findEquipmentForActivityEquipment(equipmentId: string, companyId: string): Equipment | undefined {
    const equipment = this.findEquipmentById(equipmentId);
    if (!equipment) return undefined;

    if (EquipmentService.isCompanySpecificEquipment(equipment)) {
      return this.findEquipmentOfCompany(equipmentId, companyId);
    }

    return equipment;
  }

  public static isCompanySpecificEquipment(equipment: Equipment): boolean {
    return equipment.companyId !== null;
  }

  public getCompatibleEquipments(activityTypes: ActivityType[], companies: Company[]): Equipment[] {
    let compatibleEquipments = Object.values(this.equipment).filter((equipment) =>
      EquipmentService.filterUsableEquipments(equipment, activityTypes),
    );

    if (companies.length === 1) {
      compatibleEquipments = compatibleEquipments.filter((equipment) => equipment.companyId === companies[0].id);
    } else if (isMulticompany(companies)) {
      compatibleEquipments = compatibleEquipments.filter(EquipmentService.filterMulticompany);
    }

    return compatibleEquipments;
  }

  public getCompatibleEquipmentsToDropdownItems(
    activityTypes: ActivityType[],
    companies: Company[],
    usedEquipment: string[],
  ): DropdownItemsPerCategory[] {
    const compatibleEquipment = this.getCompatibleEquipments(activityTypes, companies);
    const filteredEquipment = EquipmentService.filterSiblingDuplicates(compatibleEquipment);

    const items: DropdownItem[] = filteredEquipment.map((equipment) => EquipmentService.toDropdownItem(equipment));

    const lastUsed: DropdownItemsPerCategory = {
      name: Vue.i18n.translate('Zuletzt verwendet') || 'Zuletzt verwendet',
      id: 'equipment-used',
      items: items.filter((item) => usedEquipment.includes(item.id || '')),
      sort: false,
    };
    const others: DropdownItemsPerCategory = {
      name: Vue.i18n.translate('Maschinen & Geräte') || 'Maschinen & Geräte',
      id: 'equipment-others',
      items: items.filter((item) => !usedEquipment.includes(item.id || '')),
      sort: true,
    };

    return [lastUsed, others];
  }

  public findDropdownItem(equipmentId: string): DropdownItem | undefined {
    const equipment = this.findEquipmentById(equipmentId);
    if (!equipment) return undefined;

    return EquipmentService.toDropdownItem(equipment);
  }

  public static toDropdownItem(equipment: Equipment): DropdownItem {
    return {
      id: equipment.id,
      name: equipment.name,
    };
  }

  public static isSibling(equipmentA: Equipment, equipmentB: Equipment): boolean {
    if (_isEqual(equipmentA, equipmentB)) return true;
    if (
      EquipmentService.isEquipmentWithoutSiblings(equipmentA) ||
      EquipmentService.isEquipmentWithoutSiblings(equipmentB)
    )
      return false;

    return equipmentA.relatedEquipmentId === equipmentB.relatedEquipmentId;
  }

  private findSiblingsOfEquipment(equipment: Equipment): Equipment[] {
    if (equipment.companyId === null) return [];

    return Object.values(this.equipment).filter((e) => EquipmentService.isSibling(equipment, e));
  }

  private static filterUsableEquipments(equipment: Equipment, activityTypes: ActivityType[]): boolean {
    return activityTypes.some((activityType) =>
      activityType.usableEquipmentCategories.includes(equipment.equipmentCategoryId),
    );
  }

  private static filterMulticompany(equipment: Equipment): boolean {
    return equipment.companyClusterSync;
  }

  /**
   * filters the list of equipment and returns a list of equipment without duplicates from synced companies
   * @param equipments
   * @returns
   */
  private static filterSiblingDuplicates(equipments: Equipment[]): Equipment[] {
    const filtered: Equipment[] = [];

    equipments.forEach((equipment) => {
      if (EquipmentService.isEquipmentWithoutSiblings(equipment)) {
        filtered.push(equipment);
      }

      if (!EquipmentService.containsSibling(filtered, equipment)) {
        filtered.push(equipment);
      }
    });

    return filtered;
  }

  private static isEquipmentWithoutSiblings(equipment: Equipment): boolean {
    return equipment.relatedEquipmentId === null;
  }

  private static containsSibling(equipments: Equipment[], candidate: Equipment): boolean {
    return equipments.some((equipment) => equipment.relatedEquipmentId === candidate.relatedEquipmentId);
  }
}
