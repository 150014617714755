import axios, { AxiosResponse } from 'axios';
import { WeatherAggregate200Response, WeatherApiWeatherAggregateRequest } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { WeatherInfo, WeatherState } from '@/precision-farming/monitoring/weather/store/types';
import { RootState } from '@/store/types';

import { identifier, subscribableStore } from './common';

const actions: ActionTree<WeatherState, RootState> = {
  ...subscribableStore.actions,
  async aggregate(
    { state, commit }: ActionContext<WeatherState, RootState>,
    requestParameters: WeatherApiWeatherAggregateRequest,
  ): Promise<WeatherInfo> {
    const id = identifier(requestParameters);
    const weatherInfo = state.data[id];
    if (weatherInfo) {
      return Promise.resolve().then(() => weatherInfo);
    }

    const source = axios.CancelToken.source();
    commit('startFetching', source);
    const { weatherApi } = Vue.prototype.$api as Api;
    return weatherApi
      .weatherAggregate(requestParameters)
      .then((response: AxiosResponse<WeatherAggregate200Response>) => ({ id, ...response.data }))
      .then((dataEntry: WeatherInfo) => {
        commit('addOne', dataEntry);
        return dataEntry;
      })
      .catch((error) => {
        console.error('Failed to load weather data due to the following error', error);
        return weatherInfo;
      })
      .finally(() => commit('finishFetching', source));
  },
};

export default actions;
