import { cs, de, en, hu, sk, slSI } from 'vuejs-datepicker/dist/locale';

export default {
  cs,
  de,
  en,
  hu,
  sk,
  sl: slSI,
};
