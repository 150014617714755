import { ActivityProduct, StockLevel } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';

import { ImportActivityProductCombined } from '@/activities/importActivities/store/types';
import ProductService from '@/products/services/ProductService';
import { isSibling } from '@/products/store/compatibleProductsFilter';
import { Product, Unit } from '@/shared/api/rest/models';
import { DropdownItem, isDropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { Data } from '@/shared/mixins/store/types';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { createUuid } from '@/shared/modules/uuid';

import { AmountUnitPair, AmountsAndUnits, CellChangeWithIdsActivityTable, isAmountUnitPair } from '../types';
import calculateAmountsAndUnitsFunction from '../utils/amountsAndUnits/calculateAmountsAndUnits';
// TODO move to units service
import { findRelativeUnit, findTotalUnit, isRelativeUnit, isTotalUnit } from '../utils/amountsAndUnits/findUnit';

type ActivityProductVariant = ActivityProduct | ImportActivityProductCombined;

export default class ActivityProductService {
  private readonly units: Data<Unit>;

  private readonly productService: ProductService;

  private readonly calculateAmountsAndUnits: typeof calculateAmountsAndUnitsFunction;

  constructor(
    units: Data<Unit>,
    productService: ProductService,
    calculateAmountsAndUnits: typeof calculateAmountsAndUnitsFunction,
  ) {
    this.units = units;
    this.productService = productService;
    this.calculateAmountsAndUnits = calculateAmountsAndUnits; // TODO replace by AmountsAndUnitsService
  }

  public updateActivityProductsFromChange(
    activityProducts: ActivityProductVariant[],
    change: CellChangeWithIdsActivityTable,
    companyId: string,
  ) {
    const [, combinedKey, , newValue, { activityProductId }] = change;
    const key = combinedKey.split('.').slice(1).join('.');

    let activityProduct = ActivityProductService.findOrCreateActivityProduct(activityProductId, activityProducts);
    activityProduct = this.updateActivityProduct(activityProduct, key, newValue, companyId);
    const updatedActivityProducts = ActivityProductService.addOrReplaceActivityProduct(
      activityProduct,
      activityProducts,
    );

    return updatedActivityProducts;
  }

  public static createActivityProduct(id?: string): ActivityProduct {
    return {
      id: id ?? createUuid(),
      productId: '',
      amount: null,
      deleted: null,
      tstamp: toUnixTimestamp(Date.now()),
    };
  }

  public findUnitIdWithFallback(activityProduct: ActivityProduct): string | undefined {
    const { productId, unitId } = activityProduct;
    if (unitId) return unitId;

    if (!productId) return undefined;
    const product = this.productService.findProductById(productId);

    return product?.unitId;
  }

  public getUnitIdWithFallback(activityProduct: ActivityProduct): string {
    const unitId = this.findUnitIdWithFallback(activityProduct);
    if (!unitId) throw new Error(`UnitId of activityProduct ${activityProduct.id} is not set`);

    return unitId;
  }

  private static findOrCreateActivityProduct(
    activityProductId: string | undefined,
    activityProducts: ActivityProductVariant[],
  ) {
    if (!activityProductId) {
      return ActivityProductService.createActivityProduct();
    }

    const activityProduct = activityProducts.find((product) => product.id === activityProductId);

    if (!activityProduct) {
      return ActivityProductService.createActivityProduct(activityProductId);
    }

    return activityProduct;
  }

  private updateActivityProduct(
    activityProduct: ActivityProductVariant,
    key: string,
    value: DropdownItem | AmountUnitPair,
    companyId: string,
  ): ActivityProductVariant {
    switch (key) {
      case 'productStorageDropdownItem': {
        if (!isDropdownItem(value))
          throw new Error(`Cannot update product and storage place with non DropdownItem value: ${value}`);
        return this.updateProductStorageAndOptionalUnit(activityProduct, value, companyId);
      }
      case 'pesticideIndicationDropdownItem': {
        if (!isDropdownItem(value))
          throw new Error(`Cannot update pesticide indication with non DropdownItem value: ${value}`);
        return ActivityProductService.updatePestAndPesticideIndication(activityProduct, value);
      }
      case 'amountUnitRelative':
      case 'amountUnitTotal':
        if (!isAmountUnitPair(value))
          throw new Error(`Cannot update amount and unit with non AmountUnitPair value: ${value}`);
        return this.updateAmountAndUnit(activityProduct, key, value);
      default:
        throw new Error(`Unsupported key ${key}`);
    }
  }

  private updateProductStorageAndOptionalUnit(
    activityProduct: ActivityProduct,
    dropdownItem: DropdownItem,
    companyId: string,
  ) {
    if (!dropdownItem.id) throw new Error('DropdownItem id is not set');
    const [productId, storagePlaceId] = dropdownItem.id.split('_');
    const { amount } = activityProduct;

    const unitId = this.getUnitId(productId);
    let updatedActivityProduct = this.setProductOrSibling(activityProduct, productId, companyId);
    updatedActivityProduct = ActivityProductService.setStoragePlaceId(updatedActivityProduct, storagePlaceId);
    updatedActivityProduct = ActivityProductService.setUnitId(updatedActivityProduct, unitId);
    updatedActivityProduct = ActivityProductService.setAmount(updatedActivityProduct, amount);

    return updatedActivityProduct;
  }

  private static updatePestAndPesticideIndication(activityProduct: ActivityProduct, dropdownItem: DropdownItem) {
    if (!dropdownItem.id) throw new Error('DropdownItem id is not set');
    const [pestId, pesticideIndicationId] = dropdownItem.id.split('_');

    return {
      ...activityProduct,
      pestId,
      pesticideIndicationId,
    };
  }

  public updateAmountAndUnit(activityProduct: ActivityProduct, key: string, value: AmountUnitPair): ActivityProduct {
    const numericValue = value.amount;
    const currentUnitId = this.getUnitIdWithFallback(activityProduct);
    const unitId = this.findUnitIdOfAmountType(key, currentUnitId);

    return {
      ...activityProduct,
      amount: numericValue,
      unitId,
    };
  }

  public updateUnit(activityProduct: ActivityProduct, unitId: string, processedArea: number | null): ActivityProduct {
    if (!activityProduct.unitId || !activityProduct.amount) {
      return {
        ...activityProduct,
        unitId,
      };
    }

    const amountsAndUnits = this.calculateAmountsAndUnits(
      activityProduct.amount,
      activityProduct.unitId,
      processedArea,
      this.units,
    );
    const newUnit = this.units[unitId];
    if (isTotalUnit(newUnit)) {
      return {
        ...activityProduct,
        unitId,
        amount: amountsAndUnits.amountUnitTotal.amount,
      };
    }

    if (isRelativeUnit(newUnit)) {
      return {
        ...activityProduct,
        unitId,
        amount: amountsAndUnits.amountUnitRelative.amount,
      };
    }

    throw new Error(`Unsupported unit ${newUnit}`);
  }

  public getAmountsAndUnits(
    activityProduct: ActivityProduct,
    processedArea: number | null | undefined,
  ): AmountsAndUnits {
    const { amount } = activityProduct;
    const unitId = this.findUnitIdWithFallback(activityProduct);

    return this.calculateAmountsAndUnits(amount, unitId, processedArea, this.units);
  }

  public static setStockLevelValues(activityProduct: ActivityProduct, stockLevel: StockLevel): ActivityProduct {
    return {
      ...activityProduct,
      ...stockLevel,
    };
  }

  private static addOrReplaceActivityProduct(
    activityProduct: ActivityProductVariant,
    activityProducts: ActivityProductVariant[],
  ): ActivityProductVariant[] {
    const index = activityProducts.findIndex((product) => product.id === activityProduct.id);

    if (index === -1) {
      return [...activityProducts, activityProduct];
    }

    return [...activityProducts.slice(0, index), activityProduct, ...activityProducts.slice(index + 1)];
  }

  private getUnitId(productId: string): string {
    const product = this.productService.getProductById(productId);
    if (!product.unitId) throw new Error(`UnitId not set for product ${productId}`);

    return product.unitId;
  }

  private findUnitIdOfAmountType(key: string, currentUnitId: string): string | undefined {
    const unit = this.units[currentUnitId];
    if (!unit) throw new Error(`Unit ${currentUnitId} not found`);

    switch (key) {
      case 'amountUnitRelative': {
        if (isRelativeUnit(unit)) return currentUnitId;
        return findRelativeUnit(unit, this.units)?.id;
      }
      case 'amountUnitTotal': {
        if (isTotalUnit(unit)) return currentUnitId;
        return findTotalUnit(unit, this.units)?.id;
      }
      default:
        throw new Error(`Unsupported key ${key}`);
    }
  }

  public setProductOrSibling(activityProduct: ActivityProduct, productId: string, companyId: string): ActivityProduct {
    const product = this.productService.getProductOrSibling(productId, companyId);
    const updatedActivityProduct = _cloneDeep(activityProduct);
    updatedActivityProduct.productId = product.id;

    return updatedActivityProduct;
  }

  public isSibling(activityProductA: ActivityProduct, activityProductB: ActivityProduct): boolean {
    // eslint-disable-next-line eqeqeq
    if (activityProductA.unitId != activityProductB.unitId) return false;
    // eslint-disable-next-line eqeqeq
    if (activityProductA.storagePlaceId != activityProductB.storagePlaceId) return false;

    const productA = this.productService.findProductById(activityProductA.productId);
    const productB = this.productService.findProductById(activityProductB.productId);

    const bothProductsAreUndefined = !productA && !productB;
    const bothProductsAreDefined = !!(productA && productB);
    if (bothProductsAreUndefined || (bothProductsAreDefined && isSibling(productA, productB))) {
      return true;
    }

    return false;
  }

  //
  // ENTITY FUNCTIONS START
  // the following functions should be part of ActivityProduct entity
  //
  public getCompanyId(activityProduct: ActivityProduct): string | null {
    const product = this.productService.findProductById(activityProduct.productId);

    return product?.companyId ?? null;
  }

  public getProduct(activityProduct: ActivityProduct): Product | undefined {
    if (!activityProduct.productId) return undefined;

    return this.productService.getProductById(activityProduct.productId);
  }

  public static setUnitId(activityProduct: ActivityProduct, unitId: string | null): ActivityProduct {
    const updatedActivityProduct = _cloneDeep(activityProduct);
    // TODO check if unitId is compatible with product
    updatedActivityProduct.unitId = unitId;

    return updatedActivityProduct;
  }

  public static setStoragePlaceId(
    activityProduct: ActivityProduct,
    storagePlaceId: string | null | undefined,
  ): ActivityProduct {
    const updatedActivityProduct = _cloneDeep(activityProduct);
    // TODO check if storagePlaceId is compatible with product
    updatedActivityProduct.storagePlaceId = storagePlaceId;

    return updatedActivityProduct;
  }

  public static setAmount(activityProduct: ActivityProduct, amount: number | null | undefined): ActivityProduct {
    const updatedActivityProduct = _cloneDeep(activityProduct);
    updatedActivityProduct.amount = amount;

    return updatedActivityProduct;
  }
}
