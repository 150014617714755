/* eslint-disable no-param-reassign */
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import Handsontable from 'handsontable';

import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';

import ReadOnlyRenderer from '../ReadOnlyRenderer';

library.add(faSquare, faCheckSquare);

export default function CheckboxRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  Handsontable.renderers.CheckboxRenderer(instance, TD, row, col, prop, value, cellProperties, ...args);

  const checkbox = TD.firstChild;

  let [square] = icon({ prefix: 'far', iconName: 'square' }).node;
  if (value === cellProperties.checkedTemplate) {
    [square] = icon({ prefix: 'far', iconName: 'check-square' }).node;
    TD.classList.add('text-black');
  } else {
    TD.classList.add('text-medium');
  }
  TD.classList.add('checkbox');
  TD.insertBefore(square, checkbox);

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);
  }

  applyMergeRowsBorderStyles(TD, row, instance);
}
