import { MoveActivityMode, MoveActivityParameters, MoveActivityResponse } from 'farmdok-rest-api';

import { MoveActivityParameterWithResult } from '../types';

export function createMoveActivityParametersWithResult(
  data: MoveActivityResponse[],
): Record<string, MoveActivityParameterWithResult> {
  return data.reduce((obj, item) => {
    let mode: MoveActivityMode | null = null;
    if (item.result === MoveActivityMode.CopyField || item.result === MoveActivityMode.ReplaceField) {
      mode = item.result;
    }
    const moveActivityParametersWithResult: MoveActivityParameterWithResult = { ...item, mode };
    return { ...obj, [item.sourceActivityId]: moveActivityParametersWithResult };
  }, {} as Record<string, MoveActivityParameterWithResult>);
}

export function createMoveActivitiesForRequestParameters(
  moveActivityParametersWithResult: Record<string, MoveActivityParameterWithResult> | null,
  activityIds: string[],
): MoveActivityParameters[] {
  if (moveActivityParametersWithResult) {
    const moveActivityParameters = Object.values(moveActivityParametersWithResult).map(
      (moveActivityParameterWithResult) => ({
        sourceActivityId: moveActivityParameterWithResult.sourceActivityId,
        mode: moveActivityParameterWithResult.mode ?? MoveActivityMode.Autodetect,
        destinationFieldId: moveActivityParameterWithResult.destinationFieldId,
      }),
    );
    return moveActivityParameters;
  }

  const mode = MoveActivityMode.Autodetect;
  return activityIds.map((activityId) => ({
    sourceActivityId: activityId,
    mode,
  }));
}
