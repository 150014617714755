/**
 * Calculates the center of a polygon more accurately,
 * without the assumption that a polygon has a rectangular area such as in <code>bound.getCenter()</code>.
 * This is more applicable to heatmaps where polygons have a high degree of skewness.
 * Note: Here we calculate the average of coordinates, constituting the polygon.
 * However, take note that polygons in heatmaps are heavily skewed, and we should compensate for that skewness by calculating the skewed median.
 * This implies a couple of mathematical calculations.
 * The current implementation is sufficient enough for our geometric use.
 * @param points data points constituting the polygon's border
 */
export default function calculatePolygonCenter(points: google.maps.LatLng[]): google.maps.LatLng {
  const sumOfLongitudes = points
    .map((point: google.maps.LatLng) => point.lng())
    .reduce((previousValue: number, currentValue: number) => previousValue + currentValue);
  const sumOfLatitudes = points
    .map((point: google.maps.LatLng) => point.lat())
    .reduce((previousValue: number, currentValue: number) => previousValue + currentValue);
  const averageLongitudes = sumOfLongitudes / points.length;
  const averageLatitudes = sumOfLatitudes / points.length;
  return new google.maps.LatLng({ lng: averageLongitudes, lat: averageLatitudes });
}
