import Handsontable from 'handsontable';

import { TableDataBase, TableDataBaseExpandable } from '../../../types';

export default function hiddenRows(
  tableData: TableDataBase[] | TableDataBaseExpandable[],
): Handsontable.hiddenRows.Settings {
  const rows: number[] = [];

  let currentReferenceRow: TableDataBase | TableDataBaseExpandable | null = null;
  tableData.forEach((row, rowIndex) => {
    if (row.id !== currentReferenceRow?.id) {
      currentReferenceRow = row;
    } else if (currentReferenceRow !== null) {
      if ('expand' in currentReferenceRow && currentReferenceRow.expand === true) {
        return;
      }

      rows.push(rowIndex);
    }
  });

  return {
    indicators: true,
    rows,
  };
}
