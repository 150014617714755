
import { type PropType, defineComponent } from 'vue';

import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';

export type Row = {
  title: string;
  name: string;
  values: string[];
};

export default defineComponent({
  name: 'ExportTable',
  components: {
    MultiRootWrapper,
  },
  props: {
    tableHeaders: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => [],
    },
    tableRows: {
      type: Array as PropType<Row[]>,
      required: true,
      default: () => [],
    },
    tableHint: {
      type: String,
      required: false,
      default: '',
    },
  },
});
