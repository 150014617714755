import { Module } from 'vuex';

import { Product, ProductCategoryType } from '@/shared/api/rest/models';
import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import usedItemsStore, { initialState as initialStateUsedItems } from '@/shared/mixins/store/usedItems';
import { RootState } from '@/store/types';

import subscribableStoreFactory from './common';
import { ProductsModuleState } from './types';

export function initialState<Entry extends Product>(): ProductsModuleState<Entry> {
  return {
    ...initialStateSubscribable<Entry>(),
    ...initialStateUsedItems(),
  };
}

export default function productsModuleFactory<Entry extends Product = Product, Resolve = undefined>(
  categoryType: ProductCategoryType,
  resolve?: string[],
): Module<ProductsModuleState<Entry>, RootState> {
  const subscribableStore = subscribableStoreFactory<Entry, Resolve>(categoryType, resolve);

  return {
    namespaced: true,
    state: initialState<Entry>(),
    mutations: { ...subscribableStore.mutations, ...usedItemsStore.mutations },
    actions: { ...subscribableStore.actions },
    getters: { ...subscribableStore.getters, ...usedItemsStore.getters },
  };
}
