<template>
  <div>
    <BTooltip
      v-for="column in tableHeaderTooltips"
      placement="bottomright"
      delay="500"
      custom-class="b-tooltip--margin-1"
      :key="`tooltip-table-header--${column.key}-${column.visualCol}`"
      :target="column.target"
    >
      <div class="text-left">
        <strong class="mb-1">
          {{ column.header.title }}
        </strong>
        <p v-if="typeof column.header.description === 'string'" class="mb-0">
          {{ column.header.description }}
        </p>
      </div>
    </BTooltip>
  </div>
</template>

<script>
export default {
  name: 'TooltipTableHeader',
  props: {
    tableHeaderTooltips: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
