<template>
  <h2
    class="welcome-title"
    :class="{
      'welcome-title--white': white,
      'welcome-title--centered': centered,
    }"
  >
    <slot name="default" />
  </h2>
</template>

<script>
export default {
  name: 'WelcomeTitle',
  props: {
    white: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.welcome-title {
  width: 100%;
  margin-bottom: var(--spacer_3);
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  color: var(--black);
}

@media (min-width: 768px) {
  .welcome-title {
    text-align: center;
  }
}

@media (min-width: 768px) AND (min-height: 600px) {
  .welcome-title {
    font-size: 32px;
  }
}

.welcome-title--centered {
  text-align: center;
}

.welcome-title--white {
  color: var(--white);
}
</style>
