import { ProductCategory, ProductCategoryType } from 'farmdok-rest-api';
import { GetterTree } from 'vuex';

import { subscribableStore } from '@/productCategories/store/common';
import { ProductCategoriesState } from '@/productCategories/store/types';
import { INSECTICIDES_GUIDS } from '@/products/constants';
import type { Product } from '@/shared/api/rest/models';
import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { RootState } from '@/store/types';

export type Getters = SubscribableGetters<ProductCategory> & {
  isHerbicide(productCategoryId: string): boolean;
  isInsecticide(productId: string): boolean;
};

const moduleGetters: GetterTree<ProductCategoriesState, RootState> = {
  ...subscribableStore.getters,
  isHerbicide:
    (state: ProductCategoriesState) =>
    (productCategoryId: string): boolean => {
      const productCategory = state.data[productCategoryId];
      return productCategory?.type === ProductCategoryType.Herb;
    },

  isInsecticide:
    (state: ProductCategoriesState, getters, rootState, rootGetters) =>
    (productId: string): boolean => {
      const product: Product | undefined = rootGetters['products/findProductById'](productId);
      const functionId = product?.pesticide?.functionId ?? '';
      return INSECTICIDES_GUIDS.includes(functionId);
    },
};

export default moduleGetters;
