<template>
  <TableBase
    ref="table"
    :id="id"
    disable-read-only-bg
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :visible-rows="visibleRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @reload="loadData"
    @remove:row="(entry) => removeEntries([entry])"
  >
    <template #footer>
      <TableFooter
        :total-rows="Object.keys(tableData).length"
        :visible-rows="visibleRows.length"
        :selected-rows="selectedRows.length"
      >
        {{ $t('Summe Größe: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}
      </TableFooter>
    </template>
    <template #no-content>
      <NoContent type="fields" @copyLinkToClipboard="copyLinkToClipboard" />
    </template>
    <template #no-search-results>
      <NoContent type="search" @clearAllFilters="clearAllFilters" />
    </template>
  </TableBase>
</template>
<script>
import { mapGetters } from 'vuex';

import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import { baseUrl, origin } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import subscribableTableData from '@/shared/handsontable/mixins/containers/featureSubscribableTableData';
import syncableTableData from '@/shared/handsontable/mixins/containers/featureSyncableTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import NoContent from '../components/NoContent.vue';
import columnsTableFields from '../handsontable/columns/tableFieldsManagedContracts';

export default {
  name: 'TableFieldsContainer',
  components: {
    TableBase,
    TableFooter,
    NoContent,
  },
  mixins: [
    tableContainerBase,
    subscribableTableData('managedContracts/fields'),
    syncableTableData('managedContracts/fields'),
    calculateSizeTotal,
  ],
  props: {
    contractId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      columnDefinition: columnsTableFields,
    };
  },
  async created() {
    await this.$store.dispatch('managedContracts/fields/loadByContractId', { contractId: this.contractId });
  },
  computed: {
    ...mapGetters({
      contractFieldData: 'managedContracts/contractFieldData',
    }),

    id() {
      return [
        'hot-fields-managed-contracts',
        ...this.$store.state.auth.currentCompanies.map((company) => company.id),
      ].join('-');
    },
    tableData() {
      return this.contractFieldData(this.contractId).reduce(
        (tableData, field) => ({
          ...tableData,
          [field.guid]: field,
        }),
        {},
      );
    },
    loading() {
      return (
        this.$store.getters['products/crops/loading'] ||
        this.$store.getters['varieties/loading'] ||
        this.$store.getters['managedContracts/fields/loading'] ||
        this.$store.getters['managedContracts/companies/loading']
      );
    },

    selectedFieldsRemovable() {
      if (!this.tableData || Object.keys(this.tableData).length === 0) {
        return false;
      }
      if (this.selectedRows.length > 0) {
        const nonRemovableStatuses = ['contracted', 'canceled'];
        const selectedRowStatuses = this.selectedRows.map((rowId) => this.tableData[rowId].status);
        return !selectedRowStatuses.some((status) => nonRemovableStatuses.includes(status));
      }
      return false;
    },
  },
  methods: {
    async loadAdditionalData() {
      // fields/subscribe will be called via mixin subscribableTableData
      this.$store.dispatch('managedContracts/companies/subscribe');
      this.$store.dispatch('products/crops/subscribe');
      this.$store.dispatch('varieties/subscribe');
    },

    clearAllFilters() {
      this.$refs.table.clearAllFilters();
    },

    async copyLinkToClipboard() {
      const linkToContract = `${origin}${baseUrl}digital-contracting/contracting/${this.contractId}`;
      if (!navigator.clipboard) {
        return;
      }
      await navigator.clipboard.writeText(linkToContract);
    },
  },
};
</script>
