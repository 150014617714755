
import { PropType, defineComponent } from 'vue';

import { Unit } from '@/shared/api/rest/models/unit';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';

type RadioGroupOption = {
  value: string;
  text: string;
};

export default defineComponent({
  name: 'UnitSelection',
  components: { FormFieldRadioGroup },

  props: {
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
    selectedUnitId: {
      type: String as PropType<string | null>,
      default: '',
    },
  },
  computed: {
    unitValues(): RadioGroupOption[] {
      return this.units.map((unit) => ({
        text: unit.name,
        value: unit.id,
      }));
    },
  },
  methods: {
    updateUnit(unitId: string): void {
      this.$emit('update:unit', unitId);
    },
  },
});
