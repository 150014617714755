export default {
  methods: {
    /**
     * Check all the errors given by a vee-validate observer and returns the first one.
     * ATTENTION: does not return the error of a 'dirty' field.
     *
     * @param errors
     * @returns {null}
     */
    firstError(errors) {
      let error = null;
      Object.keys(errors).some((field) => {
        if (
          this.$refs.validationObserver != null &&
          this.$refs.validationObserver.fields[field] != null &&
          this.$refs.validationObserver.fields[field].dirty
        ) {
          return false;
        }
        const currErrors = errors[field];
        if (currErrors.length < 1) {
          return false;
        }
        [error] = currErrors;
        return true;
      });
      return error;
    },
  },
};
