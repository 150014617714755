export default function resolveEnvVariableWithPlaceholder(processEnv, variableName) {
  const env = { ...processEnv };
  let authOrigin = env[variableName];
  if (!authOrigin.includes('{')) {
    return authOrigin;
  }
  if (env.VUE_APP_ORIGIN == null || env.VUE_APP_ORIGIN === '') {
    env.VUE_APP_ORIGIN = `${window.location.protocol}//${window.location.hostname}`;
  }
  authOrigin = authOrigin.replace(/\{([a-zA-Z0-9_]+)(\.([A-Za-z]+))?\}/g, (full, envVariable, group2, urlPart) => {
    if (urlPart == null) {
      return env[envVariable];
    }
    const link = new URL(env[envVariable]);
    return link[urlPart];
  });
  return authOrigin;
}
