import { ResponseStatus } from '../../shared/types';
import { ApiVersion } from './apiVersion';

type ApiResponseBase = {
  version: ApiVersion;
};

export type ApiResponse<Data, Resolve = undefined, ResolveInSeparateList = undefined> = ApiResponseBase & {
  status: ResponseStatus;
  data: Data;
  page: number;
  itemsPerPage: number;
  total: number;
  resolved?: Resolve;
  resolveInSeparateList?: ResolveInSeparateList;
};

export function isApiResponse<Data, Resolve = undefined, ResolveInSeparateList = undefined>(
  candidate: any,
): candidate is ApiResponse<Data, Resolve, ResolveInSeparateList> {
  return (
    ['success', 'partialSuccess', 'error'].includes(candidate.status) &&
    typeof candidate.page === 'number' &&
    typeof candidate.itemsPerPage === 'number' &&
    typeof candidate.total === 'number'
  );
}

export type ApiResponseExport = ApiResponseBase & {
  status: ExportStatus;
  fileName: string;
  fileBody: string;
  fileMimeType: FileMimeType;
};

export type ExportStatus = 'downloadSuccess';

export type FileMimeType = 'application/octet-stream';
