import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { Sen4State } from './types';

const getters: GetterTree<Sen4State, RootState> = {
  ...subscribableStore.getters,
};

export default getters;
