import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      country: { id: null, name: null },
      userCountry: null,
    };
  },
  computed: {
    ...mapGetters({
      countriesLoading: 'regions/fetching',
    }),
    countries() {
      return [
        {
          name: 'countries',
          id: 'countries',
          items: Object.values(this.$store.state.regions.data),
        },
      ];
    },
  },
  async created() {
    await Promise.all([this.$store.dispatch('regions/subscribe'), this.getUserCountry()]);
    this.countries[0].items.some((country) => {
      if (country.code === this.userCountry) {
        this.country = country;
        return true;
      }
      return false;
    });
  },
  methods: {
    async getUserCountry() {
      try {
        const response = await axios.get('/admin/rest/region/userCountryCode');
        if (response.data.status === 'success') {
          this.userCountry = response.data.data.toLowerCase();
        }
      } catch (e) {
        console.error('PageRegister: Unable to fetch user country code.', e.response.data);
      }
    },
  },
};
