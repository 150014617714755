import { Pest, PesticideIndication } from 'farmdok-rest-api';
import { GetterTree } from 'vuex';

import isHerbicide from '@/products/herbizides/isHerbicide';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import { RootState } from '@/store/types';

import {
  filterByApprovalTimes,
  filterByCropIds,
  filterCompanySpecificCropId,
} from './getterUtils/compatiblePesticideIndicationsFilter';
import dropdownItemName from './getterUtils/dropdownDisplayName';
import { PesticideIndicationsState } from './types';

export type Getters = {
  compatiblePesticideIndications: (
    pesticideId: string,
    cropIds: string[],
    activityTimeStarts: number[],
  ) => PesticideIndication[];
  findById: (pesticideId: string, pesticideIndicationId: string) => PesticideIndication | undefined;
  dropdownItem: (pesticideId: string, pestId: string, pesticideIndicationId: string) => DropdownItem;
  dropdownItems: (pesticideId: string, cropIds: string[], activityTimeStarts: number[]) => DropdownItemsPerCategory[];
  dropdownItemsForProductAndField: (
    productId: string,
    fieldId: string,
    activityTimeStarts: number[],
  ) => DropdownItemsPerCategory[] | null;
};

const moduleGetters: GetterTree<PesticideIndicationsState, RootState> = {
  compatiblePesticideIndications:
    (state: PesticideIndicationsState, getters: Getters, rootState: RootState, rootGetters) =>
    (pesticideId: string, cropIds: string[], activityTimeStarts: number[]): PesticideIndication[] => {
      const globalCropIds = cropIds.filter((cropId) =>
        filterCompanySpecificCropId(cropId, rootGetters['products/findProductById']),
      );
      return (
        state.data[pesticideId]
          ?.filter((pesticideIndication) => filterByCropIds(pesticideIndication, globalCropIds))
          .filter((pesticideIndication) => filterByApprovalTimes(pesticideIndication, activityTimeStarts)) ?? []
      );
    },
  findById: (state: PesticideIndicationsState) => (pesticideId: string, pesticideIndicationId: string) => {
    const pesticideIndications = state.data[pesticideId];
    if (!pesticideIndications) return undefined;
    return Object.values(state.data[pesticideId]).find((indication) => indication.id === pesticideIndicationId);
  },
  dropdownItem:
    (state: PesticideIndicationsState, getters: Getters, rootState: RootState) =>
    (pesticideId: string, pestId: string, pesticideIndicationId: string) => {
      const pest = rootState.pests.data[pestId];
      const pesticideIndication = getters.findById(pesticideId, pesticideIndicationId);

      if (!pest || !pesticideIndication) return { id: '', name: '' };

      const unit = pesticideIndication.maxAmountUnitId
        ? rootState.units.data[pesticideIndication.maxAmountUnitId]
        : undefined;

      return { id: `${pest.id}_${pesticideIndication.id}`, name: dropdownItemName(pest, pesticideIndication, unit) };
    },
  dropdownItems:
    (state: PesticideIndicationsState, getters: Getters, rootState: RootState) =>
    (pesticideId: string, cropIds: string[], activityTimeStarts: number[]): DropdownItemsPerCategory[] => {
      const items: DropdownItem[] = [];

      const compatiblePesticideIndications = getters.compatiblePesticideIndications(
        pesticideId,
        cropIds,
        activityTimeStarts,
      );
      compatiblePesticideIndications.forEach((pesticideIndication: PesticideIndication) => {
        pesticideIndication.pestIds.forEach((pestId) => {
          const pest: Pest | undefined = rootState.pests.data[pestId];
          if (pest) {
            items.push(getters.dropdownItem(pesticideId, pestId, pesticideIndication.id));
          }
        });
      });
      return [{ items }];
    },
  dropdownItemsForProductAndField:
    (state: PesticideIndicationsState, getters: Getters, rootState: RootState, rootGetters) =>
    (productId: string, fieldId: string, activityTimeStarts: number[]) => {
      // move from getItems to here
      const product = rootGetters['products/findProductById'](productId);
      if (!product) return null;

      const productCategories = rootState.productCategories.data;
      if (!isHerbicide(product, productCategories)) return null;

      const { cropId } = rootState.fields.data[fieldId];
      if (!cropId) return null;

      return getters.dropdownItems(product.id, [cropId], activityTimeStarts);
    },
};

export default moduleGetters;
