import { render, staticRenderFns } from "./UnitSelection.vue?vue&type=template&id=01246169&"
import script from "./UnitSelection.vue?vue&type=script&lang=ts&"
export * from "./UnitSelection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports