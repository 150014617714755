import _Vue from 'vue';

import VueConfirmDialogComponent from './components/VueConfirmDialog.vue';

const initialState = (): { title: string | null; message: string | null } => ({ title: null, message: null });

const dialogData = {
  data: initialState(),
};

export type Confirm = (data: any) => Promise<boolean>;

class VueConfirmDialog {
  // eslint-disable-next-line class-methods-use-this
  install(Vue: typeof _Vue) {
    const root = new Vue({
      data: { dialogData },
      render: (createElement) => createElement(VueConfirmDialogComponent),
    });
    root.$mount(document.body.appendChild(document.createElement('div')));

    const confirm: Confirm = async (data: any) => {
      dialogData.data = initialState();
      if (typeof data === 'string') {
        dialogData.data.message = data;
      } else if (data != null) {
        dialogData.data = { ...data };
      }
      // @ts-ignore
      return root.$children[0].show();
    };
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$confirm = confirm;
  }
}

export default new VueConfirmDialog();
