export const baseUrl = (() => {
  if (
    process.env.VUE_APP_IS_IN_AGRIDAT &&
    typeof window.FD === 'object' &&
    typeof window.FD.appWebclientUri === 'string' &&
    window.FD.appWebclientUri.length > 0
  ) {
    try {
      return new URL(window.FD.appWebclientUri).pathname;
    } catch (_) {
      /* do nothing if it's not a URL */
    }
  }
  return process.env.VUE_APP_BASEURL;
})();

export const origin =
  process.env.NODE_ENV === 'development' &&
  typeof process.env.VUE_APP_ORIGIN === 'string' &&
  process.env.VUE_APP_ORIGIN.length > 0
    ? process.env.VUE_APP_ORIGIN
    : window.location.origin;

export const originMobile = origin.replace('://', '://m.');

export const originStandalone = (() => {
  if (
    process.env.VUE_APP_IS_IN_AGRIDAT &&
    typeof window.FD === 'object' &&
    typeof window.FD.appWebclientUri === 'string' &&
    window.FD.appWebclientUri.length > 0
  ) {
    try {
      return new URL(window.FD.appWebclientUri).origin;
    } catch (_) {
      /* do nothing if it's not a URL */
    }
  }
  if (typeof process.env.VUE_APP_STANDALONE_ORIGIN === 'string' && process.env.VUE_APP_STANDALONE_ORIGIN.length > 0) {
    return process.env.VUE_APP_STANDALONE_ORIGIN;
  }
  return window.location.origin;
})();

export const GOOGLE_MAPS_API_KEY = 'AIzaSyA8F_fPvskHkZaOykCyDNKbQAEJkljNKBY';

export const HOT_LICENSE_KEY = '3ac83-2345c-e48b3-6432f-26032';

export enum ThemeColors {
  PRIMARY = 'rgb(60, 201, 97)', // #3cc961
  SECONDARY = 'rgb(55, 151, 174)', // #3797ae
  DANGER = 'rgb(255, 97, 76)', // #ff614c
  DANGER_DARK = 'rgb(224, 73, 53)', // #e04935
  WARNING = 'rgb(215, 147, 14)', // #d7930e
  WHITE = 'rgb(255, 255, 255)', // #ffffff
  LIGHTEST = 'rgb(247, 247, 247)', // #f7f7f7
  MEDIUM = 'rgb(222, 222, 222)', // #dedede
  DARK = 'rgb(72, 67, 92)', // #48435c
  BLACK = 'rgb(19, 53, 60)', // #13353c
}

export const KEY_CODES = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
};

/*
 * global column keys
 */
export const GUID_KEY = 'id';

/*
 * store data entries status codes
 */
export const ENTRY_NEW = Symbol('ENTRY_NEW');
export const ENTRY_INSERTING = Symbol('ENTRY_INSERTING');
export const ENTRY_ERROR_INSERTING = Symbol('ENTRY_ERROR_INSERTING');

export const ENTRY_SYNCED = Symbol('ENTRY_SYNCED');
export const ENTRY_DIRTY = Symbol('ENTRY_DIRTY');
export const ENTRY_UPDATING = Symbol('ENTRY_UPDATING');
export const ENTRY_ERROR_UPDATING = Symbol('ENTRY_ERROR_UPDATING');

export const ENTRY_TO_REMOVE = Symbol('ENTRY_TO_REMOVE');
export const ENTRY_REMOVING = Symbol('ENTRY_REMOVING');
export const ENTRY_REMOVED = Symbol('ENTRY_REMOVED');

export const ENTRY_RECENTLY_RESTORED = Symbol('ENTRY_RECENTLY_RESTORED');

/*
 * store data custom rows
 */
export const ROW_FOOTER = Symbol('ROW_FOOTER');

/*
 * store data errors
 */
export const ERROR_FETCHING = Symbol('ERROR_FETCHING');
export const ERROR_INSERTING = Symbol('ERROR_INSERTING');
export const ERROR_UPDATING = Symbol('ERROR_UPDATING');

/* google maps */
export const GOOGLE_MAPS_SETTINGS = {
  POLYGON_DISABLED_STROKE_COLOR: '#828282',
  POLYGON_DISABLED_FILL_COLOR: '#828282',
  POLYGON_VISIBLE_STROKE_COLOR: '#000000',
  POLYGON_VISIBLE_FILL_COLOR: '#000000',
  POLYGON_ACTIVE_STROKE_COLOR: '#3CC961',
  POLYGON_ACTIVE_FILL_COLOR: '#3CC961',
  POLYGON_ACTIVE_HOVER_COLOR: '#81D897',
  POLYGON_DEFAULT_STROKE_COLOR: '#000',
  POLYGON_DEFAULT_FILL_COLOR: '#13353C',
  POLYGON_INVALID_COLOR: '#f54242',
  POLYLINE_ISOLATION_ZONE_COLOR: '#FF9B8D',
  POLYLINE_MULTIPLIER_GROUP_COLOR: '#3797AE',
  GOOGLE_MAPS_DEFAULT_CENTER: {
    center: {
      lat: 47.561838,
      lng: 14.633302,
    },
    zoom: 9,
  },
  MEASURE_DISTANCE_LINE_COLOR: '#64BDD2',
};

/* units */
/* currently still incomplete, for the full list see db table bdm_unit_group */
export const UNIT_GROUP_IDS = {
  WEIGHT: 1,
  VOLUME_SOLID: 3,
  VOLUME_LIQUID: 5,
};

export const UNIT_GROUP_GUIDS = {
  WEIGHT: 'BE03870E-01B8-11E7-BE5A-08606E6810DA',
};

export const UNIT_IDS = {
  HA: 'BE031F28-01B8-11E7-BE5A-08606E6810DA',
};

export const REGION_GUIDS = {
  AT: '3C52DB9A-6337-11E7-8CCD-08606E6810DA',
  DE: '71C1454A-6337-11E7-8CCD-08606E6810DA',
  SI: 'CB156A8A-BFFD-11E8-A355-529269FB1459',
};

export enum REGION_GUIDS_ENUM {
  AT = '3C52DB9A-6337-11E7-8CCD-08606E6810DA',
  DE = '71C1454A-6337-11E7-8CCD-08606E6810DA',
  SI = 'CB156A8A-BFFD-11E8-A355-529269FB1459',
}

export const ACTIVITY_ROUGH_GUIDS = {
  // seeding
  SEED_MAIN: 'BDF13D42-01B8-11E7-BE5A-08606E6810DA',
  SEED_CATCH_CROP: 'BDF13EFF-01B8-11E7-BE5A-08606E6810DA',
  // fertilization
  FEMI: 'BDF140A3-01B8-11E7-BE5A-08606E6810DA',
  FECO_SLURRY: 'BDF1456E-01B8-11E7-BE5A-08606E6810DA',
  FECO_MANURE: 'BDF14621-01B8-11E7-BE5A-08606E6810DA',
  FECO_LOW_LOSS: 'BDF146D3-01B8-11E7-BE5A-08606E6810DA',
  FECO: '51650914-8DB8-11E9-A204-91D0637C46E8',
  // plant protection
  PROTECT: 'BDF14158-01B8-11E7-BE5A-08606E6810DA',
  // harvest
  HARVEST: 'BDF13FE3-01B8-11E7-BE5A-08606E6810DA',
  // others
  NOTE: '07D2F427-01BE-11E7-BE5A-08606E6810DA',
};

export const LICENSE_PACKAGE_GUIDS = {
  NO_LICENSE: 'D612483A-34EA-4CEC-BDAC-8E5167CC8975',
  FARMDOK_FREE: 'FF89699C-0B35-11E7-BE5A-08606E6810DA',
  PRECISION: 'A768463C-B9FC-467F-A175-2FD6D672778D',
};

export const REST_BASE_MODEL_KEYS = [GUID_KEY, 'tstamp', 'deleted'];

export const NAVBAR_EXPANDED_COOKIE = 'FD_NAVBAR_EXPANDED';

export const CNH_STATE_KEY = 'cnhState';

export const CNH_BRAND_PORTAL_URL = 'https://caseih.afsconnect.com/cnhisso';

export const UUID5_FIELD_CONTOUR_NODE = 'fieldContour';

export const DRAWING_PROPS = {
  activities: {
    stroke: {
      color: '#3DA45C',
      opacity: 0.6,
      width: 8,
    },
  },
  fields: {
    stroke: {
      color: '#81D897',
      opacity: 1,
      style: 'solid',
      width: 5,
    },
  },
  distance: {
    stroke: {
      color: '#FFFFFF',
      opacity: 1,
      style: 'dashed',
      width: 4,
    },
  },
  tracks: {
    stroke: {
      color: '#63BED2',
      opacity: 1,
      width: 2,
    },
    start_circle: {
      outline: {
        color: '#FFFFFF',
        opacity: 1,
        width: 1,
      },
      fill: {
        color: '#3BC961',
        opacity: 1,
      },
      radius: 2,
    },
    end_circle: {
      outline: {
        color: '#FFFFFF',
        opacity: 1,
        width: 1,
      },
      fill: {
        color: '#EA6B54',
        opacity: 1,
      },
      radius: 2,
    },
  },
  timestamps: {
    circle: {
      outline: {
        color: '#FFFFFF',
        opacity: 1,
        width: 0,
      },
      fill: {
        color: '#FFFFFF',
        opacity: 1,
      },
      radius: 0.7,
    },
    text: {
      outline: '#FFFFFF',
      fill: '#3698AD',
    },
  },
};
