import { ActionTree } from 'vuex';

import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

import ActivityRuleViolationsApiService from '../services/ActivityRuleViolationsApiService';
import { ActivityRuleViolationsState } from './types';

export type ActionPayloads = {
  subscribe: string;
};

const requiredFeatures = [availableFeatures.FEATURE_RULE_CHECK];

const actions: ActionTree<ActivityRuleViolationsState, RootState> = {
  async subscribe({ commit, rootGetters }) {
    if (!rootGetters['features/currentCompaniesHaveFeaturesEnabled'](requiredFeatures)) {
      return;
    }

    commit('setFetching', true);

    const processOrderIds: string[] = rootGetters['auth/currentProcessOrderIds'];

    const { ruleCheckApi } = this.$api;
    const activityRuleViolationApiService = new ActivityRuleViolationsApiService(ruleCheckApi);
    try {
      const { data } = await activityRuleViolationApiService.getActivityRuleViolations(processOrderIds);

      commit('setData', data ?? []);
    } catch (error) {
      console.error(error);
      commit('setData', []);
    }

    commit('setFetching', false);
  },
  async recheck({ commit, rootGetters }) {
    if (!rootGetters['features/currentCompaniesHaveFeaturesEnabled'](requiredFeatures)) {
      return;
    }

    commit('setFetching', true);

    const processOrderIds: string[] = rootGetters['auth/currentProcessOrderIds'];

    const { ruleCheckApi } = this.$api;
    const activityRuleViolationApiService = new ActivityRuleViolationsApiService(ruleCheckApi);
    try {
      const { data } = await activityRuleViolationApiService.recheck(processOrderIds);

      commit('setData', data ?? []);
    } catch (error) {
      console.error(error);
      commit('setData', []);
    }

    commit('setFetching', false);
  },
  async dismiss({ commit }, activityRuleViolationIds: string[]) {
    const { ruleCheckApi } = this.$api;
    const activityRuleViolationApiService = new ActivityRuleViolationsApiService(ruleCheckApi);
    try {
      await activityRuleViolationApiService.dismiss(activityRuleViolationIds);

      commit('removeData', activityRuleViolationIds);
    } catch (error) {
      console.error(error);
    }
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default actions;
