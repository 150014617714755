<template>
  <main class="page-skeleton">
    <div class="page-skeleton__content-wrapper-outer">
      <slot name="contentbar" />
      <div class="page-skeleton__content-wrapper-inner">
        <aside
          v-if="$slots.sidebar != null && pageFeatureVisible && pageFeatureEnabled"
          class="page-skeleton__sidebar-wrapper"
        >
          <button
            class="page-skeleton__sidebar-toggle bg-dark text-white border-0"
            @click="isSidebarVisible = !isSidebarVisible"
          >
            <FontAwesomeIcon v-if="!isSidebarVisible" icon="chevron-right" />
            <FontAwesomeIcon v-else icon="chevron-left" />
          </button>
          <div v-show="isSidebarVisible" class="page-skeleton__sidebar-area overflow-auto">
            <slot name="sidebar" />
          </div>
        </aside>
        <div
          class="page-skeleton__content-area"
          :class="{
            'page-skeleton__content-area--scroll-y': scrollY,
            'page-skeleton__content-area--flex': contentFlex,
          }"
        >
          <slot v-if="pageFeatureVisible && pageFeatureEnabled" name="default" />
          <slot v-if="pageFeatureVisible && pageFeatureEnabled" name="content" />
          <slot v-if="multiCompany" name="noMultiCompanySupport" />
          <slot v-if="pageFeatureVisible && !pageFeatureEnabled && !multiCompany" name="featureNotEnabled">
            <MaxWidthContainer left>
              <FeatureNotAvailable :feature-name="pageFeatureTitle" />
            </MaxWidthContainer>
          </slot>
          <slot v-if="!pageFeatureVisible" name="featureNotVisible">
            <MaxWidthContainer left>
              <FeatureNotAvailable :feature-name="pageFeatureTitle" :feature-visible="false" />
            </MaxWidthContainer>
          </slot>
        </div>
      </div>
    </div>
    <slot v-if="pageFeatureVisible && pageFeatureEnabled" name="satellite" />
  </main>
</template>

<script>
import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';

export default {
  name: 'ThePageSkeleton',
  components: { FeatureNotAvailable, MaxWidthContainer },
  props: {
    scrollY: {
      type: Boolean,
      default: false,
    },
    contentFlex: {
      type: Boolean,
      default: false,
    },
    pageFeatureTitle: {
      type: String,
      default: null,
    },
    pageFeatureVisible: {
      type: Boolean,
      default: true,
    },
    pageFeatureEnabled: {
      type: Boolean,
      default: true,
    },
    multiCompany: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSidebarVisible: true,
    };
  },
};
</script>

<style scoped>
.page-skeleton {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.page-skeleton__content-wrapper-outer {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page-skeleton__content-wrapper-inner {
  overflow: hidden;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.page-skeleton__sidebar-wrapper {
  display: flex;
  position: relative;
  height: 100%;
  border-right: 10px solid var(--dark);
  z-index: 100;
}

.page-skeleton__sidebar-toggle {
  position: absolute;
  top: 58px;
  left: 100%;
  padding: 15px 6px 15px 8px;
  border-radius: 0 2px 2px 0;
  z-index: 2;
}

.page-skeleton__sidebar-toggle svg {
  margin: 0;
}

.page-skeleton__sidebar-area {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 33.33vw;
}

@media (max-width: 767px) {
  .page-skeleton__sidebar-area {
    max-width: 50vw;
  }
}

.page-skeleton__content-area {
  position: relative;
  overflow: hidden;
  height: 100%;
  flex: 1;
  z-index: 50;
}

.page-skeleton__content-area--scroll-y {
  overflow-y: auto;
}

.page-skeleton__content-area--flex {
  display: flex;
}
</style>
