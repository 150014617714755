import { RestErrorResponse, RestResponse } from 'farmdok-rest-api';

export type ApiResponse<Entry> =
  | (RestResponse & {
      data: Entry[];
      pageKey?: string;
    })
  | (RestErrorResponse & {
      data?: Entry[];
    });

export function isRestErrorResponse<Entry>(candidate: ApiResponse<Entry>): candidate is RestErrorResponse {
  return (
    !!(candidate as RestErrorResponse).errorCode || Array.isArray((candidate as RestErrorResponse).errorUserMessage)
  );
}
