/**
 * Sets a cookie, hostname being window.location.hostname
 *
 * @param name
 * @param value
 * @param maxAgeInSeconds
 */
export function setCookie(name: string, value: any, maxAgeInSeconds = null) {
  const encodedValue = encodeURIComponent(JSON.stringify(value));
  const cookie = `${name}=${encodedValue};domain=${window.location.hostname};path=/`;
  if (maxAgeInSeconds != null) {
    document.cookie = `${cookie};max-age=${maxAgeInSeconds}`;
  } else {
    document.cookie = cookie;
  }
}

/**
 * Reads a cookie by name
 *
 * @param name
 * @returns {*}
 */
export function getCookie(name: string) {
  let cookie: any = null;
  document.cookie.split('; ').some((currentCookie) => {
    const parts = currentCookie.split('=');
    if (parts[0] === name) {
      [, cookie] = parts;
      return true;
    }
    return false;
  });
  try {
    cookie = JSON.parse(decodeURIComponent(cookie));
  } catch {
    cookie = null;
  }
  return cookie;
}
