import Handsontable from 'handsontable';
import Vue from 'vue';

import Dropdown from '@/shared/components/Dropdown.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

// @ts-ignore
export default class DropdownEditor extends Handsontable.editors.TextEditor {
  init() {
    super.init();
    // @ts-ignore
    this.dropdownItems = {
      data: [],
      loading: true,
      selected: {
        id: null,
        name: '',
      },
    };
    // @ts-ignore
    this.dropdownComparableItem = {
      category: null,
      value: null,
    };
    // @ts-ignore
    this.dropdownBoundary = null;
  }

  prepare(
    visualRow: number,
    col: number,
    prop: number | string,
    td: HTMLTableCellElement,
    originalValue: string | null,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(visualRow, col, prop, td, originalValue, cellProperties);
    if (typeof cellProperties.getDropdownBoundary === 'function') {
      // @ts-ignore
      this.dropdownBoundary = cellProperties.getDropdownBoundary();
    }
    const displayValue = cellProperties.getRendererValue({
      value: originalValue,
      visualRow,
      instance: this.instance,
    });
    const selected = {
      id: originalValue,
      name: displayValue,
    };

    // @ts-ignore
    Vue.set(this.dropdownItems, 'data', []);
    // @ts-ignore
    Vue.set(this.dropdownItems, 'loading', true);
    // @ts-ignore
    Vue.set(this.dropdownItems, 'selected', selected);
    const now = +new Date();
    // @ts-ignore
    this.getItemsToken = now;
    cellProperties.getItems(visualRow, this.instance).then((items: DropdownItemsPerCategory[]) => {
      // @ts-ignore
      if (this.getItemsToken !== now) {
        return;
      }
      // @ts-ignore
      Vue.set(this.dropdownItems, 'data', items);
      // @ts-ignore
      Vue.set(this.dropdownItems, 'loading', false);
    });
    if (cellProperties.dropdownComparableItem != null) {
      // @ts-ignore
      Vue.set(this.dropdownComparableItem, 'category', cellProperties.dropdownComparableItem.category);
      // @ts-ignore
      Vue.set(this.dropdownComparableItem, 'value', cellProperties.dropdownComparableItem.value);
    } else {
      // @ts-ignore
      Vue.set(this.dropdownComparableItem, 'category', null);
      // @ts-ignore
      Vue.set(this.dropdownComparableItem, 'value', null);
    }
  }

  createElements() {
    super.createElements();
    const container = this.TEXTAREA_PARENT.appendChild(document.createElement('div'));
    const provide = {
      // @ts-ignore
      dropdownItems: this.dropdownItems,
      // @ts-ignore
      dropdownComparableItem: this.dropdownComparableItem,
      dropdownVariant: 'table-editor',
      dropdownBoundary: () => {
        // @ts-ignore
        if (this.dropdownBoundary == null) {
          return window;
        }
        // @ts-ignore
        return this.dropdownBoundary;
      },
    };
    // @ts-ignore
    this.dropdown = new Vue({
      render: (h) => h(Dropdown),
      provide,
    }).$mount(container);
    // @ts-ignore
    this.dropdown.$children[0].$on('select', (selected: DropdownItem) => {
      // @ts-ignore
      Vue.set(this.dropdownItems, 'selected', selected);
      this.setValue(selected.id);
      this.finishEditing(false);
    });
    // @ts-ignore
    this.dropdown.$children[0].$on('close', () => {
      this.finishEditing(true);
    });
  }

  open() {
    super.open();
    (this.TEXTAREA_PARENT.getElementsByClassName('handsontableInput')[0] as HTMLElement).style.display = 'none';
    this.TEXTAREA_PARENT.style.width = `${this.TD.clientWidth}px`;
    this.TEXTAREA_PARENT.style.zIndex = '200';
    // @ts-ignore
    this.dropdown.$children[0].open();
  }

  finishEditing(restoreOriginalValue?: boolean, ctrlDown?: boolean, callback?: () => void) {
    if (this.getValue() == null) {
      super.finishEditing(true, ctrlDown, callback);
    } else if (this.state === 'STATE_EDITING' && this.getValue() === this.originalValue) {
      super.finishEditing(true, ctrlDown, callback);
    } else {
      super.finishEditing(restoreOriginalValue, ctrlDown, callback);
    }
  }

  close() {
    this.TEXTAREA_PARENT.style.removeProperty('z-index');
    (this.TEXTAREA_PARENT.getElementsByClassName('handsontableInput')[0] as HTMLElement).style.display = 'block';
    // @ts-ignore
    this.dropdown.$children[0].close();
    super.close();
    this.hot.listen();
  }
}
