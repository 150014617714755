export default function parseNumber(value: string): number {
  const cleanedAmount = value.replaceAll(',', '.');
  const numberParts = cleanedAmount.split('.');
  if (numberParts.length === 1) return parseInt(numberParts[0], 10);

  const integerPart = numberParts.slice(0, -1).join('');
  const decimalPart = numberParts[numberParts.length - 1];
  const numericAmount = parseFloat(`${integerPart}.${decimalPart}`);

  return numericAmount;
}
