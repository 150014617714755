import { Module } from 'vuex';

import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { initialState as initialStateUsedItems } from '@/shared/mixins/store/usedItems';
import { RootState } from '@/store/types';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { CropsState } from './types';

export function initialState(): CropsState {
  return {
    ...initialStateSubscribable(),
    ...initialStateUsedItems(),
  };
}

const vuexModule: Module<CropsState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};

export default vuexModule;
