
import { defineComponent } from 'vue';

import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';

export default defineComponent({
  name: 'EquipmentSelectSectionNotAvailable',
  components: { FeatureNotAvailable, FormFieldSet },
});
