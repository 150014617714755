
import numbro from 'numbro';
import { PropType, defineComponent } from 'vue';

import { Company } from '@/auth/store/types';
import { ActivityType, Customer, Field, Product } from '@/shared/api/rest/models';
import MapItemTooltip from '@/shared/components/map/MapItemTooltip.vue';
import { baseUrl } from '@/shared/constants';
import { Data } from '@/shared/mixins/store/types';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';

export default defineComponent({
  name: 'MapFieldTooltip',
  components: { MapItemTooltip },
  data() {
    return {
      baseUrl,
    };
  },
  props: {
    /**
     * The fields model.
     */
    field: {
      type: Object as PropType<Field>,
      required: true,
    },
    /**
     * Renders the field name as an input field.
     * On change emits 'change:fieldName'.
     */
    editable: {
      type: Boolean,
      default: false,
    },
    /**
     * Reference to the google map. This is used to calculate the position of the tooltip.
     * For the correct positioning to work place the MapFieldTooltip in the same container as the google map
     * and give the container `position: relative`.
     */
    map: {
      type: Object as PropType<google.maps.Map>,
      default: null,
    },
    /**
     * The tooltip will be rendered above of this marker.
     */
    marker: {
      type: Object as PropType<google.maps.Marker>,
      default: null,
    },
    /**
     * The tooltip will be rendered above of this polygon if no marker is available.
     */
    polygon: {
      type: Object as PropType<google.maps.Polygon>,
      default: null,
    },
    /**
     * The tooltip will also add the cropname if crops area available and the field has a set cropId.
     */
    crops: {
      type: Object as PropType<Data<Product>>,
      default: null,
    },
    /**
     * The tooltip will display the field size by default
     */
    showFieldSize: {
      type: Boolean,
      default: true,
    },
    /**
     * The tooltip will display the company name
     */
    companies: {
      type: Array as PropType<Company[]>,
      default() {
        return [];
      },
    },
    customers: {
      type: Object as PropType<Data<Customer>>,
      default() {
        return {};
      },
    },
    /**
     * If true, the tooltip will be drawn on the center of the polygon
     */
    drawOnCenter: {
      type: Boolean,
      default: false,
    },
    /**
     * The tooltip will display the activity type displayName if ActivityType is not null
     */
    activityType: {
      type: Object as PropType<ActivityType>,
      default: null,
    },
    /**
     * The tooltip will be displayed on the position of the marker if this is true
     */
    drawOnMarker: {
      type: Boolean,
      default: false,
    },
    /**
     * The style of the tooltip (dark or light)
     */
    colorTheme: {
      type: String,
      default: 'dark',
      validator: (value: string) => ['dark', 'light'].includes(value),
    },
    showDetailsLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fieldGroup(): string | null {
      const fieldGroup = getDescendantProp(this.field, 'fieldGroup.mfa');
      if (typeof fieldGroup !== 'string' || fieldGroup.length === 0) {
        return null;
      }
      return fieldGroup;
    },
    placeholder(): string {
      // @ts-ignore
      if (typeof this.field.placeholderName === 'string') {
        // @ts-ignore
        return this.field.placeholderName;
      }
      return this.$t('Feld') ?? 'Feld';
    },
    fieldSizeFormatted(): string | null {
      if (!Number.isNaN(Number(this.field.fieldSize)) && this.field.fieldSize > 0 && this.showFieldSize) {
        return numbro(this.field.fieldSize).format();
      }
      return null;
    },
    cropName(): string | null {
      if (this.crops == null || this.field.cropId == null || this.crops[this.field.cropId] == null) {
        return null;
      }
      return this.crops[this.field.cropId].name;
    },
    showCompanyName(): boolean {
      return this.companies.length > 1;
    },
    companyName(): string | null {
      if (this.showCompanyName) {
        const company = this.companies.find((c) =>
          c.processOrders.some((processOrder) => processOrder.id === this.field.processOrderId),
        );
        if (company != null) {
          return company.abbreviation;
        }
      }

      return null;
    },
    customerName(): string | null {
      if (!this.field['fieldGroup.customerId']) return null;

      return this.customers[this.field['fieldGroup.customerId']]?.name ?? null;
    },
  },
});
