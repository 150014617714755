/* eslint-disable no-param-reassign */
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import Handsontable from 'handsontable';

import { currentCellMetadata } from '@/shared/handsontable/renderers/helpers/subTableRendererHelpers';

import ReadOnlyRenderer from './ReadOnlyRenderer';

library.add(faChevronUp, faChevronDown);

export default function ExpandRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties) {
  const onEnterKeyDown = (event) => {
    const currentCell = currentCellMetadata(instance);
    if (!currentCell) {
      return;
    }
    if (currentCell.type === 'expand' && visualRow === currentCell.coords.row && visualCol === currentCell.coords.col) {
      if (event.isImmediatePropagationEnabled === false) {
        return;
      }
      event.stopImmediatePropagation();
      cellProperties.toggleExpand(visualRow);
    }
  };

  Handsontable.renderers.CheckboxRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties);

  const checkbox = TD.firstChild;

  let [expandIcon] = icon({ prefix: 'fas', iconName: 'chevron-down' }).node;
  if (value) {
    [expandIcon] = icon({ prefix: 'fas', iconName: 'chevron-up' }).node;
  }
  TD.classList.add('text-black');
  TD.classList.add('expand');
  if (typeof cellProperties.expandableForRenderer === 'function' && cellProperties.expandableForRenderer(visualRow)) {
    TD.insertBefore(expandIcon, checkbox);
    instance.addHook('beforeKeyDown', (event) => {
      if (event.key === 'Enter') {
        onEnterKeyDown(event);
      }
    });
  }

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, visualRow, visualCol, prop, value, cellProperties]);
  }
}
