import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { Company } from '@/auth/store/types';

import { BASE_PATH } from '../common';
import { ApiRequestOptions, ApiResponse, Filter, ProductVisibility } from '../models';
import { apiOptionsToSearchParams, dataToBase64, urlSearchParamsToUnencodedString } from '../requestUtils';

// eslint-disable-next-line import/prefer-default-export
export async function fetchProductVisibilities<Resolve = undefined, ResolveInSeparateList = undefined>(
  companies: Company[],
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
): Promise<AxiosResponse<ApiResponse<ProductVisibility[], Resolve, ResolveInSeparateList>>> {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  const filter = getFilter(companies);
  searchParams.append('filter', dataToBase64(filter));

  const url = `${BASE_PATH}/productVisibility?${urlSearchParamsToUnencodedString(searchParams)}`;

  return axios.get<ApiResponse<ProductVisibility[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}

function getFilter(companies: Company[]) {
  const companyIds = companies.map((company) => company.id);

  const filter: Filter = ['companyId', 'IN', companyIds];

  return filter;
}
