<template>
  <DebugComponentWrapper>
    <BBadge pill class="debug-instance-badge" title="Hide this by pressing 'ctrl + alt + b'" :variant="variant">
      {{ instance.toUpperCase() }}
    </BBadge>
  </DebugComponentWrapper>
</template>

<script>
import { BBadge } from 'bootstrap-vue';

import DebugComponentWrapper from './DebugComponentWrapper.vue';

export default {
  name: 'DebugInstanceBadge',
  components: { DebugComponentWrapper, BBadge },
  data() {
    return {
      instance: window.location.hostname.split('.')[0],
    };
  },
  computed: {
    variant() {
      let variant = 'dark';
      if (this.instance === 'local') {
        variant = 'secondary';
      } else if (this.instance === 'test') {
        variant = 'danger';
      } else if (this.instance === 'develop') {
        variant = 'warning';
      }
      return variant;
    },
  },
};
</script>

<style scoped>
.debug-instance-badge {
  position: absolute;
  right: 0;
  top: 50%;
  padding: 0.5rem 1rem;
  transform: translate(calc(100% + 1rem), -50%);
}
</style>
