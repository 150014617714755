import Vue from 'vue';
import Vuex from 'vuex';

import activities from '@/activities/store';
import activityTypes from '@/activityTypes/store';
import activityTypesRough from '@/activityTypes/storeRough';
import sharedActivityTypes from '@/activityTypes/storeSharedActivityTypes';
import auth from '@/auth/store';
import customers from '@/customers/store';
import datepickerTranslations from '@/datepickerTranslations';
import datura from '@/datura/store';
import managedContracts from '@/digital-contracting/managed-contracts/store';
import digitalContracting from '@/digital-contracting/store';
import equipments from '@/equipments/store';
import fieldGroups from '@/field-groups/store';
import sharedFields from '@/fields/sharedFieldsStore';
import fields from '@/fields/store';
import geoObjects from '@/geoObjects/store/index';
import satellite from '@/layout/storeSatellite';
import sharedNotes from '@/notes/sharedNotesStore';
import notes from '@/notes/store';
import notifications from '@/notifications/store';
import pesticideIndications from '@/pesticideIndications/store';
import pests from '@/pests/store';
import precisionFarming from '@/precision-farming/store';
import productCategories from '@/productCategories/store';
import productVisibilities from '@/productVisibilities/store';
import products from '@/products/store';
import regions from '@/regions/store';
import { Field } from '@/shared/api/rest/models';
import { NAVBAR_EXPANDED_COOKIE } from '@/shared/constants';
import { Data } from '@/shared/mixins/store/types';
import { getCookie, setCookie } from '@/shared/modules/cookieHelpers';
import features from '@/shared/storeDynamicFeatures/store';
import storagePlaces from '@/storagePlaces/store';
import tracks from '@/tracks/store/index';
import units from '@/units/store';
import users from '@/users/store';
import varieties from '@/varieties/store/index';

import { RootState, RootStateWithoutModules } from './types';

Vue.use(Vuex);

const availableLanguages = ['cs', 'de', 'en', 'hu', 'sk', 'sl'];

export function initialState(): RootStateWithoutModules {
  let locale = 'en-US';
  navigator.languages.some((currentLocale) => {
    if (availableLanguages.includes(currentLocale.substring(0, 2))) {
      locale = currentLocale;
      return true;
    }
    return false;
  });

  return {
    locale,
    fetchingTranslation: false,
    navbar: {
      expanded: getCookie(NAVBAR_EXPANDED_COOKIE) != null ? getCookie(NAVBAR_EXPANDED_COOKIE) : false,
    },
    redirectingToAgriDat: false,
  };
}

const store = new Vuex.Store<RootState>({
  state: initialState() as RootState,
  mutations: {
    afterResetData() {},
    startFetchingTranslation(state) {
      Vue.set(state, 'fetchingTranslation', true);
    },
    finishFetchingTranslation(state) {
      Vue.set(state, 'fetchingTranslation', false);
    },
    selectLocale(state, locale) {
      Vue.set(state, 'locale', locale);
    },
    toggleNavbar(state) {
      setCookie(NAVBAR_EXPANDED_COOKIE, !state.navbar.expanded);
      Vue.set(state.navbar, 'expanded', !state.navbar.expanded);
    },
    redirectToAgriDat(state) {
      Vue.set(state, 'redirectingToAgriDat', true);
    },
  },
  actions: {
    async checkLocale({ state, dispatch }) {
      dispatch('selectLocale', state.locale);
    },
    async selectLocale({ commit, state }, locale) {
      commit('selectLocale', locale);
      const language = locale.substring(0, 2);
      // @ts-ignore
      if (Object.keys(state.i18n.translations).includes(language)) {
        return;
      }
      let module = null;
      commit('startFetchingTranslation');
      switch (language) {
        case 'cs':
          module = await import(/* webpackChunkName: "translation.cs" */ '../translations/cs');
          break;
        case 'hu':
          module = await import(/* webpackChunkName: "translation.hu" */ '../translations/hu');
          break;
        case 'sk':
          module = await import(/* webpackChunkName: "translation.sk" */ '../translations/sk');
          break;
        case 'sl':
          module = await import(/* webpackChunkName: "translation.sl" */ '../translations/sl');
          break;
        default:
          break;
      }
      commit('finishFetchingTranslation');
      if (module != null && typeof module.default === 'function') {
        Vue.i18n.replace(language, module.default());
      }
    },
    resetData({ dispatch, commit }) {
      dispatch('activityTypes/reset');
      dispatch('activityTypesRough/reset');
      dispatch('activities/reset');
      dispatch('activities/gpsTrack/reset');
      dispatch('activities/gpsLog/reset');
      dispatch('activities/ruleCheck/rules/reset');
      dispatch('activities/ruleCheck/activityRuleViolations/reset');
      dispatch('activities/sharedActivities/reset');
      dispatch('activities/importActivities/reset');
      dispatch('equipments/reset');
      dispatch('sharedActivityTypes/reset');
      dispatch('customers/reset');
      dispatch('fieldGroups/reset');
      dispatch('fields/reset');
      dispatch('sharedFields/reset');
      dispatch('products/reset');
      dispatch('productVisibilities/reset');
      dispatch('pesticideIndications/reset');
      dispatch('notes/reset');
      dispatch('sharedNotes/reset');
      dispatch('varieties/reset');
      dispatch('digitalContracting/reset');
      dispatch('managedContracts/reset');
      dispatch('pests/reset');
      dispatch('pesticideIndications/reset');
      dispatch('precisionFarming/applicationMaps/resetAll');
      dispatch('precisionFarming/monitoring/reset');
      dispatch('productCategories/reset');
      dispatch('storagePlaces/reset');
      dispatch('users/reset');
      dispatch('geoObjects/reset');
      dispatch('tracks/reset');
      dispatch('datura/history/reset');
      dispatch('datura/assessments/reset');

      commit('afterResetData');
    },
  },
  modules: {
    activities,
    activityTypes,
    sharedActivityTypes,
    activityTypesRough,
    auth,
    users,
    customers,
    digitalContracting,
    equipments,
    features,
    fieldGroups,
    fields,
    sharedFields,
    managedContracts,
    notes,
    sharedNotes,
    notifications,
    precisionFarming,
    pests,
    pesticideIndications,
    products,
    productVisibilities,
    productCategories,
    regions,
    satellite,
    units,
    varieties,
    storagePlaces,
    geoObjects,
    tracks,
    datura,
  },
  getters: {
    // UI
    locale: (state) => state.locale,
    // @ts-ignore
    satellite: (state) => state.satellite,
    datepickerTranslation: (state) => {
      let language = 'en';
      availableLanguages.some((key) => {
        if (state.locale.includes(key)) {
          language = key;
          return true;
        }
        return false;
      });
      // @ts-ignore
      return datepickerTranslations[language];
    },

    // DATA
    // @ts-ignore
    activityTypes: (state) => state.activityTypes.data,
    // @ts-ignore
    customers: (state) => state.customers.data,
    // @ts-ignore
    fields: (state): Data<Field> => state.fields.data,
    // @ts-ignore
    varieties: (state) => state.varieties.data,
    companyFeatureVisible: (state, getters) => (companyId: string, feature: unknown) =>
      getters['features/companyFeatureVisible'](companyId, feature),
    companyFeatureEnabled: (state, getters) => (companyId: string, feature: unknown) =>
      getters['features/companyFeatureEnabled'](companyId, feature),
    companyFeatureReadonly: (state, getters) => (companyId: string, feature: unknown) =>
      getters['features/companyFeatureEnabled'](companyId, feature),
    currentCompaniesHaveFeatureVisible: (state, getters) => (feature: unknown) =>
      getters['features/currentCompaniesHaveFeatureVisible'](feature),
    currentCompaniesHaveFeatureEnabled: (state, getters) => (feature: unknown) =>
      getters['features/currentCompaniesHaveFeatureEnabled'](feature),
    currentCompaniesHaveFeatureReadonly: (state, getters) => (feature: unknown) =>
      getters['features/currentCompaniesHaveFeatureReadonly'](feature),
    currentCompaniesHaveFeature: (state, getters) => (feature: unknown) => ({
      visible: getters['features/currentCompaniesHaveFeatureVisible'](feature),
      enabled: getters['features/currentCompaniesHaveFeatureEnabled'](feature),
      readonly: getters['features/currentCompaniesHaveFeatureReadonly'](feature),
    }),
  },
});

export default store;
export const useStore = () => store;
