import Vue from 'vue';
import { Dispatch, GetterTree } from 'vuex';

import {
  FertilizationCalculation,
  FertilizationZoneDosage,
} from '@/precision-farming/application-maps/fertilization/store/types';
import findFieldTimestampOfSameDay from '@/precision-farming/application-maps/fertilizationCereals/store/getterUtils/findFieldTimestampOfSameDay';
import { Getters as ProductGetters } from '@/products/store/getters';
import { ACTIVITY_ROUGH_GUIDS } from '@/shared/constants';
import weightedAverage from '@/shared/modules/weightedAverage';
import { RootState } from '@/store/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import { WorkflowKeyForRoutes } from '../../types';
import { ApplicationMapsFertilizationCerealsState, ApplicationType, TaskDataBody } from './types';

export type Getters = {
  paginationNextEnabled: boolean;
  paginationNextDisabledReason: string | null;
  calculation: FertilizationCalculation;
  zoneDosage: FertilizationZoneDosage[] | undefined;
  selectedField: string | null;
  dbId: string | null;
  taskDataAsync: TaskDataBody | null;
  weightedAverageDosage: number;
};

const moduleGetters: GetterTree<ApplicationMapsFertilizationCerealsState, RootState> = {
  ...baseWorkflowStore.getters,
  paginationNextEnabled: (state) => {
    if (state.selectedFields.length !== 1) {
      return false;
    }

    switch (state.paginationStep) {
      case 1:
        return true;
      case 2:
        return !state.fieldTimestamps.loading;
      case 3:
        return !!state.calculation.material.id;
      case 4:
        return state.stepsCompleted;
      default:
        return false;
    }
  },
  paginationNextDisabledReason: (state, getters) => {
    if (getters.paginationNextEnabled) return null;

    switch (state.paginationStep) {
      case 1:
        return Vue.i18n.translate('Bitte w\u00e4hle ein Feld aus, bevor du fortfährst.');
      default:
        return null;
    }
  },
  // dosage calculation
  calculation: (state) => state.calculation,
  zoneDosage: (state): FertilizationZoneDosage[] | undefined => {
    if (state.application.applicationType === ApplicationType.MANUAL && state.calculation.manualDosage != null) {
      return state.calculation.manualDosage;
    }
    const zones = state.zones.data?.filter((zone) => zone.size > 0);
    return zones?.map((zone) => {
      const dosage = (zone.rate || 0) / (state.calculation.n || 1);
      return {
        color: zone.color,
        name: zone.name,
        dosage,
        n: zone.rate || 0,
        p: dosage * state.calculation.p,
        k: dosage * state.calculation.k,
      };
    });
  },
  selectedField: (state) => (state.selectedFields[0] ? state.selectedFields[0] : null),
  dbId: (state) => {
    if (state.zoneMap.date === null || state.fieldTimestamps.data === null) return null;

    return findFieldTimestampOfSameDay(state.fieldTimestamps.data, state.zoneMap.date)?.dbId ?? null;
  },
  weightedAverageDosage: (state, getters) => {
    const zoneDosages = (getters.zoneDosage as Getters['zoneDosage'])?.map((zone) => zone.dosage) ?? [];
    const zoneSizes = state.zones.data?.map((zone) => zone.size) ?? [];
    return weightedAverage(zoneDosages, zoneSizes);
  },
  taskDataAsync:
    (state, getters, rootState, rootGetters) =>
    async (dispatch: Dispatch): Promise<TaskDataBody | null> => {
      // TODO taskDataAsync is somehow redundant with state.task -> remove this method and adapt and use state.task
      await dispatch('activityTypes/subscribe', {}, { root: true });

      const { currentHeatmaps, zoneDosage, selectedTaskDate } = getters;
      const { zoneGenerationMode, selectedFields, calculation, selectedCompany, application, zoneMap } = state;

      const companyId = selectedCompany.id || rootState.auth.currentCompanies[0].id || '';
      const materials = [];
      if (calculation.material.id != null && calculation.material.id !== 'manual') {
        materials.push({
          workingMeanId: calculation.material.id,
          amount: getters.weightedAverageDosage,
          unitId:
            (rootGetters['products/allProducts'] as ProductGetters['allProducts']).find(
              (product) => product.id === calculation.material.id,
            )?.unitId ?? '',
        });
      }

      const processOrder = await rootGetters['auth/processOrderByCompanyIdAndNameAndTypeAsync'](
        rootState.auth.currentCompanies[0].id,
        rootState.auth.currentProcessOrderName,
        'service',
      );

      const timeStartDate = selectedTaskDate || new Date();
      const timeStart = Math.floor(timeStartDate.getTime() / 1000);

      const taskData: TaskDataBody = {
        version: '2.0',
        data: {
          companyId,
          processOrderId: processOrder.id,
          activityId: rootGetters['activityTypes/byRoughAndFineId'](ACTIVITY_ROUGH_GUIDS.FEMI).id,
          timeStart,
          state: 'planned',
          fields: selectedFields.map((guid) => ({
            fieldId: guid,
            processedArea: rootGetters.fields[guid].fieldSize,
          })),
          workingMeans: materials,
          applicationMap: {
            additionalData: {
              fields: selectedFields.map((guid) => ({
                id: guid,
                geoJsonId: Object.keys(currentHeatmaps).find((heatmapId) => heatmapId.startsWith(guid)) || null,
              })),
              zoneGenerationMode,
              calculation: {
                ...calculation,
                averageDosage: getters.weightedAverageDosage,
              },
              zoneDosage,
              application,
              zoneMap,
            },
            geoJson: currentHeatmaps,
            companyId,
            workflowKey: WorkflowKeyForRoutes.FERTILIZATION_CEREALS,
          },
          userComment: null,
        },
      };
      return taskData;
    },
};

export default moduleGetters;
