import { Activity, ActivityEquipment, ActivityProduct, Equipment, StoragePlace } from 'farmdok-rest-api';

import getFieldDisplayName from '@/activities/utils/getFieldDisplayName';
import { Getters as ProductsGetters } from '@/products/store/getters';
import { Customer, Field, Product } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

import { ActivityProductWithDisplayName, ActivityWithDisplayNames } from '../types';

export function convertToActivitiesWithDisplayNames(
  activities: Data<Activity>,
  findProductById: ProductsGetters['findProductById'],
  productsLoading: boolean,
  storagePlaces: Data<StoragePlace>,
  equipments: Data<Equipment>,
  fields: Data<Field>,
  customers: Data<Customer>,
  crops: Data<Product>,
): Data<ActivityWithDisplayNames> {
  return Object.fromEntries(
    Object.entries(activities).map(([activityId, activity]) => {
      const activityNew = convertToActivityWithDisplayNames(
        activity,
        findProductById,
        productsLoading,
        storagePlaces,
        equipments,
        fields,
        customers,
        crops,
      );
      return [activityId, activityNew];
    }),
  );
}

export function convertToActivityWithDisplayNames(
  activity: Activity,
  findProductById: ProductsGetters['findProductById'],
  productsLoading: boolean,
  storagePlaces: Data<StoragePlace>,
  equipments: Data<Equipment>,
  fields: Data<Field>,
  customers: Data<Customer>,
  crops: Data<Product>,
): ActivityWithDisplayNames {
  const field = activity.fieldId ? fields[activity.fieldId] : null;
  const fieldDisplayName = field ? getFieldDisplayName(field, customers, crops) : '';

  return {
    ...activity,
    fieldDisplayName,
    products: convertToActivityProductsWithDisplayName(
      activity.products,
      findProductById,
      productsLoading,
      storagePlaces,
    ),
    equipment: convertToActivityEquipmentsWithDisplayName(activity.equipment, equipments),
    workingTime: activity.workingTime ? `${activity.workingTime}` : null,
    pauseTime: activity.pauseTime ? `${activity.pauseTime}` : null,
    drivingTime: activity.drivingTime ? `${activity.drivingTime}` : null,
    setupTime: activity.setupTime ? `${activity.setupTime}` : null,
  };
}

function convertToActivityProductsWithDisplayName(
  activityProducts: ActivityProduct[],
  findProductById: ProductsGetters['findProductById'],
  productsLoading: boolean,
  storagePlaces: Data<StoragePlace>,
): ActivityProductWithDisplayName[] {
  if (!activityProducts || activityProducts.length === 0) return [];

  return activityProducts.map((activityProduct) => {
    if (!activityProduct.productId) {
      return { ...activityProduct, productId: undefined, displayName: '', productStorageDropdownId: undefined };
    }

    const product = findProductById(activityProduct.productId);
    if (!product) {
      return {
        ...activityProduct,
        displayName: productsLoading ? 'loading...' : '', // TODO change to loading circle
        productStorageDropdownId: undefined,
      };
    }

    if (activityProduct.storagePlaceId) {
      const storagePlace = storagePlaces[activityProduct.storagePlaceId];
      if (storagePlace) {
        return {
          ...activityProduct,
          displayName: `${product.name} (${storagePlace.name})`,
          productStorageDropdownId: `${product.id}_${storagePlace.id}`,
        };
      }
    }

    return { ...activityProduct, displayName: product.name, productStorageDropdownId: product.id };
  });
}

function convertToActivityEquipmentsWithDisplayName(
  activityEquipments: ActivityEquipment[],
  equipments: Data<Equipment>,
) {
  if (!activityEquipments || activityEquipments.length === 0) return [];

  return activityEquipments.map((activityEquipment) => {
    const equipment = equipments[activityEquipment.equipmentId];
    if (!equipment) {
      return { ...activityEquipment, displayName: '' };
    }

    return { ...activityEquipment, displayName: equipment.name };
  });
}
