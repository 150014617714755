import Vue from 'vue';

import '@/initI18n';

import PageActivitiesMap from '@/map/PageActivitiesMap.vue';
import { baseUrl } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

import PageCropRotation from './PageCropRotation.vue';
import PageFields from './PageFields.vue';
import PageFieldsImport from './PageFieldsImport.vue';
import PageFieldsMap from './PageFieldsMap.vue';
import PageFieldsRestore from './PageFieldsRestore.vue';
import PageSharedFields from './PageSharedFields.vue';
import PageSoilSamples from './PageSoilSamples.vue';
import ImportChooseMethod from './components/ImportChooseMethod.vue';
import ImportLoadData from './components/ImportLoadData.vue';
import ImportMapData from './components/ImportMapData.vue';
import ImportPreviewData from './components/ImportPreviewData.vue';

const tabs = () => {
  const currentTabs = ['field-groups', 'fields'];
  if (store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CROP_ROTATION)) {
    currentTabs.push('crop-rotation');
  }
  if (store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_SOIL_SAMPLES)) {
    currentTabs.push('soil-samples');
  }
  return currentTabs;
};

const back = {
  to: { name: 'fields' },
  label: Vue.i18n.translate('Zurück zu Felder'),
};

export default [
  {
    path: `${baseUrl}fields`,
    name: 'fields',
    component: PageFields,
    meta: {
      requiresAuth: true,
      tabs,
      label: Vue.i18n.translate('Felder'),
      featureControl: availableFeatures.FEATURE_FIELDS,
    },
    async beforeEnter(to, from, next) {
      if (from != null && typeof from.name === 'string' && from.name.indexOf('fields') !== 0) {
        await store.dispatch('fields/reset');
      }
      next();
    },
  },
  {
    path: `${baseUrl}fields/record/:fieldId`,
    name: 'fields/record',
    component: PageFields,
    meta: {
      requiresAuth: true,
      tabs,
      label: Vue.i18n.translate('Felder'),
      featureControl: availableFeatures.FEATURE_FIELDS,
    },
  },
  {
    path: `${baseUrl}fields/restore`,
    name: 'fields/restore',
    component: PageFieldsRestore,
    meta: {
      requiresAuth: true,
      label: Vue.i18n.translate('Felder wiederherstellen'),
      subheader: Vue.i18n.translate('Felder wiederherstellen, die in den letzten 90 Tagen gelöscht wurden'),
      back,
      featureControl: availableFeatures.FEATURE_FIELDS,
    },
  },
  {
    path: `${baseUrl}fields/import`,
    name: 'fields/import',
    component: PageFieldsImport,
    meta: {
      requiresAuth: true,
      label: Vue.i18n.translate('Felder importieren'),
      back,
      featureControl: availableFeatures.FEATURE_FIELDS,
    },
    children: [
      {
        path: 'choose-method',
        name: 'fields/import/choose-method',
        component: ImportChooseMethod,
        meta: {
          requiresAuth: true,
          label: Vue.i18n.translate('Felder importieren'),
          back,
        },
      },
      {
        path: 'load-data/:method',
        name: 'fields/import/load-data',
        component: ImportLoadData,
        props: true,
        meta: {
          requiresAuth: true,
          label: Vue.i18n.translate('Felder importieren'),
          back,
        },
      },
      {
        path: 'map-data',
        name: 'fields/import/map-data',
        component: ImportMapData,
        meta: {
          requiresAuth: true,
          label: Vue.i18n.translate('Felder importieren'),
          back,
        },
      },
      {
        path: 'preview-data',
        name: 'fields/import/preview-data',
        component: ImportPreviewData,
        meta: {
          requiresAuth: true,
          label: Vue.i18n.translate('Felder importieren'),
          back,
        },
      },
    ],
  },
  {
    path: `${baseUrl}fields/crop-rotation`,
    name: 'crop-rotation',
    component: PageCropRotation,
    meta: {
      requiresAuth: true,
      tabs,
      label: Vue.i18n.translate('Fruchtfolge'),
      featureControl: availableFeatures.FEATURE_CROP_ROTATION,
    },
  },
  {
    path: `${baseUrl}fields/soil-samples`,
    name: 'soil-samples',
    component: PageSoilSamples,
    meta: {
      requiresAuth: true,
      tabs,
      label: Vue.i18n.translate('Bodenproben'),
      featureControl: availableFeatures.FEATURE_SOIL_SAMPLES,
    },
  },
  {
    path: `${baseUrl}more/shared-fields`,
    name: 'more/shared-fields',
    component: PageSharedFields,
    meta: {
      requiresAuth: true,
      tabs: ['more/shared-fields'],
      label: Vue.i18n.translate('Freigegebene Felder'),
      featureControl: availableFeatures.FEATURE_FIELDS_SHARED,
    },
  },
  {
    path: `${baseUrl}more/shared-fields/record/:fieldId`,
    name: 'more/shared-fields/record',
    component: PageSharedFields,
    meta: {
      requiresAuth: true,
      tabs: ['more/shared-fields'],
      label: Vue.i18n.translate('Freigegebene Felder'),
      featureControl: availableFeatures.FEATURE_FIELDS_SHARED,
    },
  },
  {
    path: `${baseUrl}map`,
    name: 'map',
    redirect: { name: 'map/fields' },
  },
  {
    path: `${baseUrl}map/fields`,
    name: 'map/fields',
    component: PageFieldsMap,
    meta: {
      requiresAuth: true,
      tabs: ['map/fields', 'map/activities'],
      label: Vue.i18n.translate('Karte'),
      labelTab: Vue.i18n.translate('Felder'),
      featureControl: availableFeatures.FEATURE_FIELDS,
    },
  },
  {
    path: `${baseUrl}map/activities`,
    name: 'map/activities',
    component: PageActivitiesMap,
    meta: {
      requiresAuth: true,
      tabs: ['map/fields', 'map/activities'],
      label: Vue.i18n.translate('Karte'),
      labelTab: Vue.i18n.translate('Maßnahmen'),
      featureControl: availableFeatures.FEATURE_ACTIVITIES,
    },
  },
];
