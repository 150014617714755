import { ActivityImportStatus200Response } from 'farmdok-rest-api';
import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

import useCnhActivityImport from './useCnhActivityImport';
import useCnhImport from './useCnhImport';

const state = reactive({
  importMethodPath: 'load-data/cnh',
  importNextEnabled: true,
  selectedUploadFile: '',
  cnhModalVisible: false,
  isFetching: false,
});

export default function useActivitiesImports() {
  const route = useRoute();
  const router = useRouter();
  const { getCnhConnectionStatus, postCnhDownloadTasks, cnhCompanyTasksFiltered, cnhUserName, cnhEmail } =
    useCnhImport();
  const { startPollingImportStatus, resetImport, cnhImportStatus } = useCnhActivityImport();

  /* -- Computed -- */
  const currentStep = computed<number>(() => route.meta?.step ?? 0);

  /* -- Methods -- */
  function goBack() {
    if (currentStep.value === 0) {
      router.push({ name: 'activities' });
    } else if (currentStep.value === 1) {
      router.push({ name: 'activities/import/choose-method' });
    } else if (currentStep.value === 2) {
      router.push({ path: state.importMethodPath });
    } else {
      router.push({ name: 'activities/import/choose-method' });
    }
  }

  async function goNext() {
    if (currentStep.value === 0 && state.importMethodPath === 'load-data/cnh') {
      const { data } = await getCnhConnectionStatus();
      if (data.data?.loggedIn) {
        cnhEmail.value = data.data?.email ?? '';
        cnhUserName.value = data.data?.userName ?? '';
        router.push({ path: state.importMethodPath });
      } else {
        state.cnhModalVisible = true;
      }
    } else if (currentStep.value === 1 && state.importMethodPath === 'load-data/cnh') {
      const resetResponse = await resetImport();
      handleCnhStatusResponse(resetResponse.data);
    } else if (currentStep.value === 1 && state.importMethodPath === 'load-data/upload') {
      router.push({ name: 'activities/import/import-table' });
    } else {
      router.push({ path: state.importMethodPath });
    }
  }

  function handleCnhStatusResponse(response: ActivityImportStatus200Response) {
    if (response.data?.status === 'Canceling') {
      setTimeout(async () => {
        const resetResponse = await cnhImportStatus();
        handleCnhStatusResponse(resetResponse.data);
      }, 2000);
    } else if (cnhCompanyTasksFiltered.value.length > 0) {
      postCnhDownloadTasks(cnhCompanyTasksFiltered.value.map((task) => task.taskId));
      startPollingImportStatus();
    }
  }

  return {
    // Reactive
    state,
    // Computed
    currentStep,
    // Methods
    goBack,
    goNext,
  };
}
