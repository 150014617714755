import { GetterTree } from 'vuex';

import usedItems from '@/shared/mixins/store/usedItems';
import { RootState } from '@/store/types';
import { subscribableStore } from '@/varieties/store/common';

import { PlantVarietyState } from './types';

const moduleGetters: GetterTree<PlantVarietyState, RootState> = {
  ...subscribableStore.getters,
  ...usedItems.getters,
};

export default moduleGetters;
