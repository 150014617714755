var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.SidebarHeadline,{staticClass:"text-center",attrs:{"text":_vm.$t('Drohnenbilder hochladen') ?? ''}}),_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.$t( `Anleitung: Der Umfang auszuwertender Bilddaten kann sehr groß sein. Es können Einzelbilder, eine .zip-Datei mit Bildern oder ein Order mit Bildern hochgeladen werden. Bei großen Datenmengen oder schlechter Internetverbindung kann das Hochladen mehrere Stunden dauern. Achtung: Die Benennung der Dateien muss eindeutig sein. Duplikate überschreiben das Original.`, ))+" ")]),_c(_setup.FileUploadTile,{attrs:{"successfulFiles":_setup.uploadedFiles,"failedFiles":_setup.failedFiles,"required":true,"accept":['image/jpeg', 'application/zip'],"hint":_vm.$t('Es können Dateien in den Formaten {fileFormats} und {lastFileFormat} importiert werden.', {
        fileFormats: '.jpg, .JPG, .jpeg',
        lastFileFormat: '.zip',
      }),"actionLabel":_vm.$t('Upload'),"progress":_setup.totalProgress},on:{"action":_setup.upload,"abort":() => (_setup.aboutToAbort = true)}}),(_setup.totalProgress)?_c('p',{staticClass:"text-primary my-3"},[_vm._v(" "+_vm._s(_vm.$t('Upload in Arbeit ... DIESES FENSTER NICHT SCHLIESSEN!'))+" ")]):_vm._e(),(_setup.exceededMaximumUploadSize)?_c(_setup.ErrorUserMessage,{attrs:{"errorUserMessage":_vm.$t(
        'Die zulässige Größe für ausstehende Uploads wurde überschritten. ' +
          'Limit wird in ca. 1 Woche automatisch zurückgesetzt.',
      )}}):_vm._e(),_c('BModal',{attrs:{"cancelTitle":_vm.$t('Zurück'),"okTitle":_vm.$t('Seite verlassen'),"okVariant":"light","cancelVariant":"primary","headerClass":"border-0","centered":"","title":_vm.$t('Upload verlassen'),"autofocusButton":"cancel","visible":_setup.aboutToLeave,"lazy":""},on:{"cancel":() => (_setup.aboutToLeave = false),"ok":function($event){$event.preventDefault();return _setup.nextRoute.apply(null, arguments)}}},[_c('p',{staticClass:"my-4"},[_vm._v(_vm._s(_vm.$t('Achtung! Beim Verlassen der Seite wird das Hochladen abgebrochen!')))])]),_c('BModal',{attrs:{"cancelTitle":_vm.$t('Nein'),"okTitle":_vm.$t('Ja'),"okVariant":"light","cancelVariant":"primary","headerClass":"border-0","centered":"","title":`${_vm.$t('Abbrechen')}?`,"autofocusButton":"cancel","visible":_setup.aboutToAbort,"lazy":""},on:{"cancel":() => (_setup.aboutToAbort = false),"ok":function($event){$event.preventDefault();return _setup.abort.apply(null, arguments)}}},[_c('p',{staticClass:"my-4"},[_vm._v(_vm._s(_vm.$t('Möchten Sie das Assessment wirklich abbrechen?')))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }