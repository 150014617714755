import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      crops: 'products/crops',
      cropsUsed: 'products/crops/used',
    }),
    cropsToDropdownItems() {
      if (this.$store.state.auth.currentCompanies.length === 0) {
        return [];
      }
      // In multiCompany mode only allow synced materials. Also only add the synced material once.
      const multiCompany = this.$store.state.auth.currentCompanies.length > 1;
      const companyId = this.$store.state.auth.currentCompanies[0].id;
      const items = Object.values(this.crops).filter((crop) => {
        if (this.cropsUsed.includes(crop.id)) {
          return false;
        }
        if (!multiCompany) {
          return true;
        }
        return crop.companyId == null || (crop.companyId === companyId && crop.relatedMaterialId != null);
      });
      return [
        {
          name: this.$t('Zuletzt verwendet'),
          id: 'crops-used',
          items: this.cropsUsed.filter((id) => this.crops[id] != null).map((id) => ({ id, name: this.crops[id].name })),
          sort: false,
        },
        {
          name: this.$t('Kulturen'),
          id: 'crops',
          items: items.map((crop) => ({ id: crop.id, name: crop.name })),
          sort: true,
        },
      ];
    },
  },
};
