<template>
  <div>
    {{ $t('404: Nicht gefunden.') }}
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style lang="css" scoped></style>
