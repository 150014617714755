import { MutationTree } from 'vuex';

import { subscribableStore } from './common';
import { ActivityTypesRoughState } from './types';

const mutations: MutationTree<ActivityTypesRoughState> = {
  ...subscribableStore.mutations,
};

export default mutations;
