<template>
  <div class="meta-nav" :class="{ 'meta-nav--slim': slim }">
    <Button
      v-if="canPurchaseLicenses"
      leading-icon
      :size="btnSize"
      :class="btnClass"
      :variant="shopButtonVariant"
      :icon="slim ? ['fas', 'shopping-cart'] : ['far', 'shopping-cart']"
      :icon-only="slim"
      @click="() => $router.push({ name: 'shop' })"
    >
      {{ companiesHaveOnlyFreeLicenses || companiesHaveNoLicense ? $t('Jetzt kaufen') : $t('Shop') }}
    </Button>
    <Button
      icon="bell"
      :size="btnSize"
      :class="btnClass"
      :variant="btnVariant"
      :icon-only="slim"
      @click="toggleNotificationsOverlay"
    >
      {{ $t('Benachrichtigungen') }}
      <span v-if="unreadNotifications > 0 && !slim" class="meta-nav__btn-pill bg-danger text-white">{{
        unreadNotifications
      }}</span>
    </Button>
    <Button
      icon="question-circle"
      :size="btnSize"
      :class="btnClass"
      :variant="btnVariant"
      :icon-only="slim"
      @click="showHelp"
    >
      {{ $t('Hilfe') }}
    </Button>

    <BDropdown
      menu-class="shadow mt-1 p-2"
      no-caret
      right
      :size="btnSize"
      :toggle-class="btnClass"
      :variant="btnVariant"
    >
      <template slot="button-content">
        <span v-if="!slim">{{ $t('Mein Profil') }}</span>
        <FontAwesomeIcon icon="user-circle" />
      </template>
      <BDropdownHeader> {{ $store.state.auth.user.firstname }} {{ $store.state.auth.user.lastname }} </BDropdownHeader>
      <BDropdownDivider />
      <BDropdownItem class="text-black font-weight-bold" :to="{ name: 'profile' }">
        <FontAwesomeIcon icon="user" class="mr-1" />
        {{ $t('Persönliche Einstellungen') }}
      </BDropdownItem>
      <BDropdownItem class="text-black font-weight-bold" :to="{ name: 'my-invoices' }">
        <FontAwesomeIcon icon="envelope" class="mr-1" />
        {{ $t('Meine Rechnungen') }}
      </BDropdownItem>
      <BDropdownItem class="text-black font-weight-bold" :to="{ name: 'about-us' }">
        <FontAwesomeIcon icon="info-circle" class="mr-1" />
        {{ $t('Impressum') }}
      </BDropdownItem>
      <BDropdownDivider />
      <BDropdownItem class="text-black font-weight-bold" :to="{ name: 'logout' }">
        <FontAwesomeIcon icon="sign-out-alt" class="text-danger mr-1" />
        {{ $t('Abmelden') }}
      </BDropdownItem>
    </BDropdown>

    <NotificationsOverlay />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart as faShoppingCartRegular } from '@fortawesome/pro-regular-svg-icons';
import {
  faBell,
  faEnvelope,
  faInfoCircle,
  faQuestionCircle,
  faShoppingCart,
  faSignOutAlt,
  faUser,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons';

import NotificationsOverlay from '@/notifications/components/NotificationsOverlay.vue';
import Button from '@/shared/components/buttons/Button.vue';
import { LICENSE_PACKAGE_GUIDS } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

library.add(
  faShoppingCart,
  faBell,
  faQuestionCircle,
  faUserCircle,
  faUser,
  faEnvelope,
  faInfoCircle,
  faSignOutAlt,
  faShoppingCartRegular,
);

export default {
  name: 'MetaNav',
  components: { Button, NotificationsOverlay },
  props: {
    slim: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unreadNotifications() {
      return Object.values(this.$store.state.notifications.data).filter((notification) => !notification.read).length;
    },
    shopButtonVariant() {
      if (this.slim) {
        return 'primary';
      }
      if (this.companiesHaveOnlyFreeLicenses || this.companiesHaveNoLicense) {
        return 'danger';
      }
      return 'outline-primary';
    },
    btnSize() {
      return this.slim ? 'lg' : 'sm';
    },
    btnVariant() {
      return this.slim ? 'primary' : 'white-black';
    },
    btnClass() {
      return this.slim ? ['text-white', 'm-0', 'p-2'] : null;
    },
    canPurchaseLicenses() {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.CAN_PURCHASE_LICENSE);
    },
    companiesHaveOnlyFreeLicenses() {
      return this.$store.state.auth.currentCompanies.every(
        (company) => company.licensePackageId === LICENSE_PACKAGE_GUIDS.FARMDOK_FREE,
      );
    },
    companiesHaveNoLicense() {
      return this.$store.state.auth.currentCompanies.every(
        (company) => company.licensePackageId === LICENSE_PACKAGE_GUIDS.NO_LICENSE,
      );
    },
  },
  async created() {
    await this.$store.dispatch('auth/subscribe');
    this.$store.dispatch('notifications/subscribe');
  },
  methods: {
    toggleNotificationsOverlay(e) {
      // prevent the v-click-outside directive inside NotificationsOverlay to immediately close the overlay again
      e.stopPropagation();
      this.$store.commit('notifications/toggleVisibility');
    },
    showHelp() {
      window.open('https://www.farmdok.com/support/');
    },
  },
};
</script>

<style scoped>
.meta-nav--slim {
  display: flex;
}

.meta-nav__btn-pill {
  position: absolute;
  display: inline-block;
  padding: 0 5px;
  border-radius: 7px;
  transform: translate(13px, -2px);
  line-height: 13px;
}

::v-deep .dropdown-header {
  font-weight: 600;
  font-size: 1em;
  color: inherit;
  padding-bottom: 10px;
}

::v-deep .dropdown-item {
  font-weight: 600;
  padding: 10px;
}
</style>
