import Handsontable from 'handsontable';

import ReadOnlyRenderer from '@/shared/handsontable/renderers/ReadOnlyRenderer';
import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';

import applySubtableBorderStyles from '../../features/nestedTable/utils/applySubtableRendererBorderStyles';

export default function EmptyRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: any,
  cellProperties: Handsontable.CellProperties,
) {
  Handsontable.dom.empty(TD);

  if (cellProperties.readOnly) {
    ReadOnlyRenderer(instance, TD, visualRow, visualCol, prop);
  } else {
    Handsontable.renderers.BaseRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties);
  }

  // eslint-disable-next-line no-param-reassign
  cellProperties.editor = false;
  applyMergeRowsBorderStyles(TD, visualRow, instance);
  applySubtableBorderStyles(TD, visualRow, cellProperties, instance);
}
