
import { PropType, defineComponent } from 'vue';

import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';

import ScrollableContent from '../../../components/ScrollableContent.vue';
import AccordionContainer from '../../containers/sidebarZones/AccordionContainer.vue';
import DatepickerContainer from '../../containers/sidebarZones/DatepickerContainer.vue';
import ResultTableContainer from '../../containers/sidebarZones/ResultTableContainer.vue';
import VisualizationSelectContainer from '../../containers/sidebarZones/VisualizationSelectContainer.vue';
import { VisualizationMode } from '../../store/types';

export default defineComponent({
  name: 'SidebarZones',
  components: {
    SidebarHeadline,
    SidebarContentWrapper,
    DatepickerContainer,
    AccordionContainer,
    VisualizationSelectContainer,
    ResultTableContainer,
    ScrollableContent,
  },
  props: {
    visualizationMode: {
      type: String as PropType<VisualizationMode>,
      required: true,
    },
  },
  computed: {
    showResultTable(): boolean {
      return this.visualizationMode === VisualizationMode.ZONES;
    },
  },
});
