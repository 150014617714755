
import Handsontable from 'handsontable';
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { Field } from '@/shared/api/rest/models';
import ModalPageSettings from '@/shared/components/ModalPageSettings.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';
import { Data } from '@/shared/mixins/store/types';

import SidebarTableFieldsContainer from '../containers/SidebarTableFieldsContainer.vue';
import CropFilters from './CropFilters.vue';

export default defineComponent({
  name: 'SidebarFields',
  components: {
    SearchBar,
    SidebarHeadline,
    CropFilters,
    SidebarTableFieldsContainer,
    ModalPageSettings,
  },
  props: {
    tableId: {
      type: String as PropType<string>,
      required: true,
    },
    allowFieldsWithNoContour: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
    customColumnDefinition: {
      type: Object as PropType<{ international: Handsontable.ColumnSettings[] } | null>,
      default: null,
    },
    customTableData: {
      type: Object as PropType<Data<Field>>,
      default: null,
    },
    headlineLabel: {
      type: String,
      default() {
        return this.$t('Felder auswählen');
      },
    },
    sumProcessedArea: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  data() {
    return {
      visibleRows: [],
      filterString: '',
      cellReadOnly: null as { target: any; message: string } | null,
      columnsForPageSettings: null,
    };
  },
  created() {
    this.$store.dispatch('fields/subscribe');
    this.$store.dispatch('products/crops/subscribe');
  },
  methods: {
    updateCellReadOnly(cellReadOnly: null | any) {
      this.cellReadOnly = null;
      if (cellReadOnly == null) {
        return;
      }
      // add cellReadOnly in nextTick to force vuejs to remove the BTooltip component and re-create it
      // this is necessary as the target can only be set on creation of the component
      this.$nextTick(() => {
        this.cellReadOnly = {
          target: cellReadOnly,
          message: this.$t('Das Feld besitzt keine Kontur') || 'Das Feld besitzt keine Kontur',
        };
      });
    },

    async showTableSettings(): Promise<void> {
      // @ts-ignore
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      // @ts-ignore
      const settings = await this.$refs.modalPageSettings.show();
      if (settings != null && Array.isArray(settings.columns)) {
        // @ts-ignore
        this.$refs.table.$refs.table.updateColumnsFromPageSettings(settings.columns);
      }
    },

    onFilterChange(filterName: string): void {
      // @ts-ignore
      this.$refs.table.$refs.table.setColumnFilter(filterName, 'cropId');
    },
    updateCell(data: { guid: string; columnKey: string; value: any }): void {
      this.$emit('update:cell', data);
    },
  },
  computed: {
    ...mapGetters({
      fields: 'fields/data',
      crops: 'products/crops/data',
    }),
  },
});
