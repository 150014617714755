import Handsontable from 'handsontable';
import _isEqual from 'lodash.isequal';

function removeChangesToSameValues(
  changes: Array<Handsontable.CellChange | null>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  source?: Handsontable.ChangeSource,
): void {
  changes.forEach((change, index) => {
    if (!change) return;

    const [, , oldValue, newValue] = change;
    if (oldValue === newValue || (typeof newValue === 'object' && _isEqual(oldValue, newValue))) {
      // eslint-disable-next-line no-param-reassign
      changes[index] = null;
    }
  });
}

export default {
  beforeChange: removeChangesToSameValues,
};
