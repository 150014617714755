import {
  ActivitiesApiActivityMoveToProcessOrderRequest,
  ActivityMoveToProcessOrder200Response,
  MoveActivityParameters,
  MoveActivityResponse,
  RestErrorResponse,
} from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionTree } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { RootState } from '@/store/types';

import {
  createMoveActivitiesForRequestParameters,
  createMoveActivityParametersWithResult,
} from './actionUtils/actionUtils';
import { MoveActivityParameterWithResult, MoveActivityState } from './types';

const actions: ActionTree<MoveActivityState, RootState> = {
  async toProcessOrder({ state, commit }, { activityIds }: { activityIds: string[] }) {
    const destinationProcessOrderId = state.selectedProcessOrderId;

    if (!destinationProcessOrderId) {
      throw new Error('DestinationProcessOrderId is not defined');
    }

    const moveActivities: MoveActivityParameters[] = createMoveActivitiesForRequestParameters(
      state.moveActivityParametersWithResult,
      activityIds,
    );

    const requestParameters: ActivitiesApiActivityMoveToProcessOrderRequest = {
      activityMoveToProcessOrderRequest: {
        destinationProcessOrderId,
        activities: moveActivities,
      },
    };

    const { activitiesApi } = Vue.prototype.$api as Api;
    let apiResponse:
      | ActivityMoveToProcessOrder200Response
      | (RestErrorResponse & { data: MoveActivityResponse[] })
      | undefined;

    try {
      const response = await activitiesApi.activityMoveToProcessOrder(requestParameters);
      apiResponse = response.data;
      commit('setMoveActivityParametersWithResult', { moveActivityParametersWithResult: response.data.data });
    } catch (error: any) {
      if (error.response) {
        apiResponse = error.response.data;

        if (apiResponse?.status === 'conflict') {
          commit('increaseTrials');
        }
      }
    } finally {
      if (apiResponse?.data) {
        const moveActivityParametersWithResult: Record<string, MoveActivityParameterWithResult> =
          createMoveActivityParametersWithResult(apiResponse.data);

        commit('setMoveActivityParametersWithResult', moveActivityParametersWithResult);
      }
    }
  },
};

export default actions;
