<template>
  <ThePageSkeleton class="page-register-complete" scroll-y>
    <WelcomeContentContainer>
      <TopContentContainer>
        <WelcomeTitle v-if="!fetching && !userErrorMessage" class="m-0" white centered>
          {{ $t('Geschafft!') }}
        </WelcomeTitle>
      </TopContentContainer>
      <FontAwesomeIcon v-if="fetching" class="text-medium m-auto" size="3x" spin :icon="['fad', 'spinner-third']" />
      <MaxWidthContainer v-if="!fetching" class="mt-auto pb-0" size="lg">
        <WelcomeTitle centered>
          {{ $t('Deine E-Mail ist bestätigt.') }}
        </WelcomeTitle>
      </MaxWidthContainer>
      <MaxWidthContainer v-if="!fetching" class="mb-auto pt-0">
        <ErrorUserMessage v-if="userErrorMessage != null" :error-user-message="userErrorMessage" class="mb-4" />
        <p v-if="userEmail != null" class="mb-2">
          {{ messageEmailParts[0] }}
          <strong>{{ userEmail }}</strong>
          {{ messageEmailParts[1] }}
        </p>
        <Button
          v-if="$store.state.auth.loggedIn && userErrorMessage == null"
          variant="primary"
          size="lg"
          block
          @click="onContinueClick"
        >
          {{ $t('Weiter') }}
        </Button>
        <Button v-else variant="primary" size="lg" block @click="onBackClick">
          {{ $t('Zurück zum Login') }}
        </Button>
      </MaxWidthContainer>
    </WelcomeContentContainer>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import axios from 'axios';
import Handsontable from 'handsontable';

import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import { baseUrl, originMobile } from '@/shared/constants';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';

import TopContentContainer from './components/TopContentContainer.vue';
import WelcomeContentContainer from './components/WelcomeContentContainer.vue';
import WelcomeTitle from './components/WelcomeTitle.vue';

const { isMobileBrowser } = Handsontable.helper;

library.add(faSpinnerThird);

const MOBILE_HOME_HREF = `${originMobile}${baseUrl}fields`;
const MOBILE_ADDIONALDATA_HREF = `${originMobile}${baseUrl}register/additional-data/company`;

export default {
  name: 'PageRegisterComplete',
  components: {
    ErrorUserMessage,
    ThePageSkeleton,
    WelcomeContentContainer,
    MaxWidthContainer,
    TopContentContainer,
    WelcomeTitle,
    Button,
  },
  mixins: [backendResponseErrorHelpers],
  data() {
    return {
      fetching: true,
      userEmail: null,
      messageEmail: this.$t(
        'Danke, dass Du Dich bei Farmdok registriert hast! Deine E-Mail {email} ist jetzt bestätigt. Klicke auf Weiter und danach helfen wir Dir beim Einstieg.',
        {
          email: '#####',
        },
      ),
      defaultUserErrorMessage: this.$t('Unbekannter Fehler. Bitte überprüfe deine Internetverbindung.'),
    };
  },
  computed: {
    messageEmailParts() {
      return this.messageEmail.split('#####');
    },
  },
  async created() {
    try {
      const { data } = await axios.post('/admin/rest/user/confirm_email', {
        version: '2.0',
        data: {
          emailConfirmToken: this.$route.params.token,
        },
      });
      if (data.status !== 'success') {
        this.setUserErrorMessageFromResponse(data);
      } else {
        if (this.$store.state.auth.user.id != null && data.data.user.id !== this.$store.state.auth.user.id) {
          this.$store.dispatch('auth/logout');
        }
        this.userEmail = data.data.user.email;
        this.$root.$emit('register--email-confirmed', data.data.user.email);
        if (data.data.missingPassword) {
          await this.$router.push({
            name: 'password-reset',
            params: { token: this.$route.params.token },
          });
        }
      }
    } catch (error) {
      if (error != null && error.response != null) {
        this.setUserErrorMessageFromResponse(error.response.data);
      } else {
        this.setUserErrorMessageFromResponse(null);
      }
    }
    this.fetching = false;
  },
  methods: {
    onContinueClick() {
      if (isMobileBrowser()) {
        window.location.href = MOBILE_ADDIONALDATA_HREF;
        return;
      }
      this.$router.push({ name: 'register-additional-data', params: { step: 'company' } });
    },
    onBackClick() {
      if (this.userErrorMessage) {
        if (isMobileBrowser()) {
          window.location.href = MOBILE_HOME_HREF;
          return;
        }
        this.$router.push({ name: 'login' });
        return;
      }
      this.onContinueClick();
    },
  },
};
</script>

<style scoped></style>
