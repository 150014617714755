<template>
  <Widget v-if="visible" class="widget-nutrient-balance" :title="$t('Nährstoffbilanz')" :subtitle="subtitle">
    <div v-if="fetching" class="text-primary">
      <FontAwesomeIcon icon="circle-notch" spin />
    </div>
    <div v-else class="nutrient-balance__container" :class="{ 'nutrient-balance__container--blurred': !enabled }">
      <div class="widget-nutrient-balance__legend">
        <div>{{ $t('Einheit kg/ha') }}</div>
        <div>
          <span class="widget-nutrient-balance__legend-dot widget-nutrient-balance__legend-dot--planned" />
          <span
            class="widget-nutrient-balance__legend-description widget-nutrient-balance__legend-description--planned"
          >
            {{ useNeededAsPlanned ? $t('Bedarf') : $t('Geplant') }}
          </span>
          <span class="widget-nutrient-balance__legend-dot widget-nutrient-balance__legend-dot--fertilized" />
          <span
            class="widget-nutrient-balance__legend-description widget-nutrient-balance__legend-description--fertilized"
          >
            {{ $t('Gedüngt') }}
          </span>
          <span class="widget-nutrient-balance__legend-dot widget-nutrient-balance__legend-dot--remaining" />
          <span
            class="widget-nutrient-balance__legend-description widget-nutrient-balance__legend-description--remaining"
          >
            {{ $t('Frei') }}
          </span>
          <span
            v-if="!useNeededAsPlanned"
            class="widget-nutrient-balance__legend-dot widget-nutrient-balance__legend-dot--needed"
          />
          <span
            v-if="!useNeededAsPlanned"
            class="widget-nutrient-balance__legend-description widget-nutrient-balance__legend-description--needed"
          >
            {{ $t('Bedarf') }}
          </span>
        </div>
      </div>
      <div class="nutrient-balance__chart-container">
        <div v-for="nutrient in nutrients" :key="nutrient.key">
          <span v-if="nutrient.data == null || nutrient.data.length === 0" class="nutrient-balance__no-data">
            {{ $t('Die Werte konnten nicht geladen werden.') }}
          </span>
          <svg v-else :width="nutrient.chartWidth" :height="nutrient.chartHeight">
            <g
              v-for="bar in nutrient.data"
              class="nutrient-balance__bar"
              :key="bar.key"
              :transform="`translate(${bar.x}, ${bar.y})`"
              :class="bar.status"
            >
              <rect rx="2" :width="bar.width" :height="bar.height"></rect>
              <text
                class="widget-nutrient-balance__bar-value"
                :x="bar.valueX"
                :y="bar.valueY"
                :class="{ 'widget-nutrient-balance__bar-value--negative': bar.value < 0 }"
              >
                {{ bar.valueFormatted }}
              </text>
            </g>
            <g
              v-if="!useNeededAsPlanned"
              class="nutrient-balance__needed"
              :transform="`translate(${nutrient.neededX}, ${nutrient.neededY})`"
            >
              <rect height="1" :width="nutrient.neededWidth" />
              <rect
                v-b-tooltip.hover="nutrient.neededFormatted"
                transform="translate(0, -5)"
                height="11"
                :width="nutrient.neededWidth"
              />
            </g>
          </svg>
          <h5>{{ nutrient.label }}</h5>
        </div>
      </div>
    </div>
    <FeatureNotAvailable v-if="!enabled" no-back-link />
  </Widget>
</template>

<script>
import axios from 'axios';
import { max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import numbro from 'numbro';

import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import Widget from '@/shared/components/Widget.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

const BAR_ZERO_VALUE_HEIGHT = 2;
const CHART_HEIGHT = 170;
const VALUE_HEIGHT = 30;
const CAPTION_HEIGHT = 0;
const BAR_WIDTH = 40;
const BAR_SPACING = 5;
const NUTRIENTS = [
  {
    key: 'n',
    label: 'N',
  },
  {
    key: 'p',
    label: 'P₂O₅',
  },
  {
    key: 'k',
    label: 'K₂O',
  },
];

export default {
  name: 'WidgetNutrientBalance',
  components: { Widget, FeatureNotAvailable },
  props: {
    selectedFields: {
      type: Array,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    isSharedField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      fetching: false,
      source: null,
    };
  },
  computed: {
    companyId() {
      if (!(this.$store.state.auth.currentCompanies && this.$store.state.auth.currentCompanies[0].id)) {
        return null;
      }
      return this.$store.state.auth.currentCompanies[0].id;
    },
    enabled() {
      if (this.companyId == null) {
        return false;
      }
      return this.$store.getters.companyFeatureEnabled(this.companyId, availableFeatures.FEATURE_NUTRIENT_BALANCE);
    },
    fieldId() {
      if (this.selectedFields.length < 1) {
        return null;
      }
      return this.selectedFields[0].id;
    },
    useNeededAsPlanned() {
      return NUTRIENTS.every((nutrient) => this.data[`${nutrient.key}Planned`] === 0);
    },
    visible() {
      return !this.isSharedField || (this.isSharedField && this.data);
    },
    nutrients() {
      const domainMax = max(
        Object.keys(this.data)
          .filter((key) => key.indexOf('Fertilized') > 0 || key.indexOf('Planned') > 0 || key.indexOf('Needed') > 0)
          .map((key) => this.data[key])
          .filter((value) => typeof value === 'number'),
      );
      const scaleY = scaleLinear()
        .domain([0, domainMax])
        .range([0, CHART_HEIGHT - VALUE_HEIGHT - CAPTION_HEIGHT]);

      return NUTRIENTS.map((nutrient) => {
        const labelFertilized = `${nutrient.key}Fertilized`;
        const labelPlanned = `${nutrient.key}Planned`;
        const labelRemainingToFertilize = `${nutrient.key}RemainingToFertilize`;
        const labelNeeded = `${nutrient.key}Needed`;
        if (
          this.data[labelFertilized] == null ||
          this.data[labelPlanned] == null ||
          this.data[labelRemainingToFertilize] == null ||
          this.data[labelNeeded] == null
        ) {
          return nutrient;
        }

        let remainingValue = this.data[labelRemainingToFertilize];
        if (this.useNeededAsPlanned) {
          remainingValue = this.data[labelNeeded] - this.data[labelFertilized];
        }
        let data = [
          {
            key: this.useNeededAsPlanned ? labelNeeded : labelPlanned,
            value: this.useNeededAsPlanned ? this.data[labelNeeded] : this.data[labelPlanned],
          },
          {
            key: labelFertilized,
            value: this.data[labelFertilized],
          },
          {
            key: labelRemainingToFertilize,
            value: remainingValue,
            status: {
              'nutrient-balance__bar--negative': remainingValue < 0,
            },
          },
        ];
        data = data.map((bar, index) => {
          const x = index * (BAR_WIDTH + BAR_SPACING) + BAR_SPACING;
          let y;
          if (index < 2) {
            y = CHART_HEIGHT - scaleY(bar.value) - CAPTION_HEIGHT;
          } else {
            y = CHART_HEIGHT - scaleY(max(data, (currBar) => currBar.value)) - CAPTION_HEIGHT;
          }
          let labelY;
          if (index < 2) {
            labelY = scaleY(bar.value) + CAPTION_HEIGHT - 5;
          } else {
            labelY = scaleY(max(data, (currBar) => currBar.value)) + CAPTION_HEIGHT - 5;
          }
          let height;
          if (index < 2) {
            height = scaleY(Math.abs(bar.value));
          } else {
            height = Math.min(
              scaleY(Math.abs(bar.value)),
              scaleY(max(data.map((currentBar) => currentBar.value))) - BAR_ZERO_VALUE_HEIGHT,
            );
          }
          if (bar.value === 0) {
            height = BAR_ZERO_VALUE_HEIGHT;
            y = CHART_HEIGHT - CAPTION_HEIGHT - BAR_ZERO_VALUE_HEIGHT;
          }
          return {
            ...bar,
            x,
            y,
            width: BAR_WIDTH,
            height,
            valueX: BAR_WIDTH / 2,
            valueY: -5,
            valueFormatted: numbro(bar.value).format({ mantissa: 1 }),
            labelX: BAR_WIDTH / 2,
            labelY,
          };
        });

        return {
          ...nutrient,
          data,
          chartHeight: CHART_HEIGHT,
          chartWidth: BAR_WIDTH * data.length + BAR_SPACING * (data.length + 1),
          neededX: BAR_SPACING,
          neededY: CHART_HEIGHT - scaleY(this.data[labelNeeded]) - CAPTION_HEIGHT,
          neededWidth: BAR_WIDTH * 3 + BAR_SPACING * 2,
          neededFormatted: numbro(this.data[labelNeeded]).format({ mantissa: 1 }),
        };
      });
    },
  },
  watch: {
    fieldId() {
      this.loadData();
    },
  },
  created() {
    this.source = axios.CancelToken.source();
    this.loadData();
  },
  deactivated() {
    this.source.cancel();
  },
  methods: {
    async loadData() {
      if (this.fieldId == null) {
        return;
      }
      if (!this.enabled) {
        this.data = JSON.parse(
          '{"id":null,"fieldId":null,"tstamp":null,"deleted":null,"nPlanned":0,"nFertilized":87.92,"nRemainingToFertilize":67.08,"pPlanned":0,"pFertilized":5.89,"pRemainingToFertilize":64.11,"kPlanned":0,"kFertilized":13.15,"kRemainingToFertilize":76.85,"nNeeded":155,"pNeeded":70,"kNeeded":90}',
        );
        return;
      }
      this.data = {};
      this.fetching = true;
      const sharedFieldQueryParams = `?permissionMode=fieldSharing&fieldSharingCompanyId=${this.$store.state.auth.currentCompanies[0].id}`;
      try {
        const { data } = await axios.get(
          `/admin/rest/field/fertilization_data_for_field/${this.fieldId}${
            this.isSharedField ? sharedFieldQueryParams : ''
          }`,
          { cancelToken: this.source.token },
        );
        this.fetching = false;
        if (data.status !== 'success' || data.data.length < 1) {
          return;
        }
        this.data = data.data;
      } catch (error) {
        this.fetching = false;
        this.data = null;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.widget-nutrient-balance {
  max-width: 600px;
}

.nutrient-balance__container--blurred {
  filter: blur(6px);
  pointer-events: none;
  user-select: none;
}

.nutrient-balance__chart-container {
  display: flex;
  height: auto;
  min-height: 120px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  text-align: center;
}

.widget-nutrient-balance__legend {
  display: flex;
  margin-bottom: var(--spacer_3);
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}

.widget-nutrient-balance__legend-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  border-radius: 2px;
}

.widget-nutrient-balance__legend-dot:not(:first-child) {
  margin-left: var(--spacer_3);
}

.widget-nutrient-balance__legend-dot--planned {
  background: var(--medium);
}

.widget-nutrient-balance__legend-dot--fertilized {
  background: var(--secondary);
}

.widget-nutrient-balance__legend-dot--remaining {
  background: var(--primary);
}

.widget-nutrient-balance__legend-dot--needed {
  height: 1px;
  margin-bottom: 3px;
  background: var(--danger);
}

.widget-nutrient-balance__legend-description {
  font-weight: 600;
}

.widget-nutrient-balance__legend-description--planned {
  color: var(--dark);
}

.widget-nutrient-balance__legend-description--fertilized {
  color: var(--secondary);
}

.widget-nutrient-balance__legend-description--remaining {
  color: var(--primary_dark);
}

.widget-nutrient-balance__legend-description--needed {
  color: var(--danger_dark);
}

.nutrient-balance__bar:nth-child(1) rect {
  fill: var(--medium);
}

.nutrient-balance__bar:nth-child(2) rect {
  fill: var(--secondary);
}

.nutrient-balance__bar:nth-child(3) rect {
  fill: var(--primary);
}

.nutrient-balance__bar.nutrient-balance__bar--negative rect {
  fill: var(--danger);
}

.widget-nutrient-balance__bar-value {
  font-style: italic;
}

.widget-nutrient-balance__bar-value--negative {
  fill: var(--danger);
}

.nutrient-balance__needed rect:first-child {
  fill: var(--danger);
}

.nutrient-balance__needed rect:last-child {
  fill: transparent;
}

.nutrient-balance__no-data {
  display: inline-block;
  position: relative;
  max-width: 180px;
  left: 50%;
  margin: 50px 0 0 0;
  transform: translateX(-50%);
  background: var(--white);
  color: var(--danger_dark);
}

h5 {
  font-weight: 700;
  font-size: 12px;
  margin: 10px 0;
}

text {
  font-size: 12px;
  text-anchor: middle;
}
</style>
