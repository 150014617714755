<template lang="html">
  <div
    class="chart-tooltip"
    :class="{
      'chart-tooltip--move-left-90': overflowPercent > 0.3,
      'chart-tooltip--move-left-80': overflowPercent > 0.2 && overflowPercent <= 0.3,
      'chart-tooltip--move-left-70': overflowPercent > 0.1 && overflowPercent <= 0.2,
      'chart-tooltip--move-left-60': overflowPercent > 0 && overflowPercent <= 0.1,
    }"
    :style="{
      top: `${top}px`,
      left: `${left}px`,
    }"
  >
    <div v-if="showColors" class="chart-tooltip__colors">
      <span v-for="(color, index) in colors" :key="`color-${index}`">
        <span :style="{ background: color }"></span>
      </span>
    </div>
    <div class="chart-tooltip__descriptions">
      <span v-for="(description, index) in descriptions" :key="`description-${index}`">
        {{ description }}
      </span>
    </div>
    <div class="chart-tooltip__values">
      <span v-for="(value, index) in values" :key="`value-${index}`">
        {{ value }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartTooltip',
  props: {
    chartWidth: {
      type: Number,
      required: true,
    },
    top: {
      type: Number,
      required: true,
    },
    left: {
      type: Number,
      required: true,
    },
    entries: {
      type: Array,
      required: true,
    },
  },
  computed: {
    overflowPercent() {
      let width = 30; // paddings
      if (this.entries.length > 1) {
        width += 12; // add color width
      }
      let maxDescription = 0;
      let maxValue = 0;
      this.entries.forEach((entry) => {
        maxDescription = Math.max(maxDescription, entry.description.length);
        maxValue = Math.max(maxValue, entry.value.length);
      });
      maxDescription = Math.min(maxDescription * 7, 150);
      maxValue *= 7;
      width += maxDescription + maxValue;
      const overflow = this.left + width / 2 - this.chartWidth;
      if (overflow < 0) {
        return 0;
      }
      return overflow / width;
    },
    showColors() {
      return this.entries.length > 1;
    },
    colors() {
      return this.entries.map((entry) => entry.color);
    },
    descriptions() {
      return this.entries.map((entry) => entry.description);
    },
    values() {
      return this.entries.map((entry) => entry.value);
    },
  },
};
</script>

<style lang="css" scoped>
.chart-tooltip {
  display: flex;
  flex-direction: row;
  position: absolute;
  padding: 5px 10px;
  border-radius: 2px;
  background: #13353c;
  box-shadow: 0 25px 50px rgba(0, 0, 50, 0.1), 0 8px 20px rgba(0, 0, 50, 0.15), 0 5px 7px rgba(0, 0, 0, 0.05);
  transform: translate(-50%, -100%);
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  z-index: 1;
}

.chart-tooltip:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #13353c;
  transform: translate(-50%, 0);
}

.chart-tooltip--move-left-90 {
  transform: translate(-90%, -100%);
}

.chart-tooltip--move-left-90:after {
  left: 90%;
}

.chart-tooltip--move-left-80 {
  transform: translate(-80%, -100%);
}

.chart-tooltip--move-left-80:after {
  left: 80%;
}

.chart-tooltip--move-left-70 {
  transform: translate(-70%, -100%);
}

.chart-tooltip--move-left-70:after {
  left: 70%;
}

.chart-tooltip--move-left-60 {
  transform: translate(-60%, -100%);
}

.chart-tooltip--move-left-60:after {
  left: 60%;
}

.chart-tooltip > div > span {
  display: block;
  position: relative;
}

.chart-tooltip__colors > span {
  width: 7px;
  margin-right: 5px;
}

.chart-tooltip__colors > span > span {
  display: block;
  position: relative;
  top: 5px;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.chart-tooltip__colors > span:not(:first-child) > span {
  margin-top: 10px;
}

.chart-tooltip__descriptions {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
}

.chart-tooltip__descriptions > span {
  display: block;
  margin-right: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chart-tooltip__values > span {
  text-align: right;
}
</style>
