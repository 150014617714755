import Handsontable from 'handsontable';

/**
 * Converts raw table data to table data by using the tableColumns to map the data.
 */
export default function toTableData<TableDataType>(
  rawTableData: any[][],
  tableColumns: Handsontable.ColumnSettings[],
  hot: Handsontable,
): TableDataType[] {
  const tableData: TableDataType[] = [];

  rawTableData.forEach((rawRow) => {
    const row: TableDataType = {} as TableDataType;
    tableColumns.forEach((column) => {
      if (typeof column.data !== 'string') {
        throw new Error(`Cannot convert to tableData because col.data is not a string ${column.data}}`);
      }

      const prop = column.data;
      const columnIndex = hot.propToCol(prop);
      const value = rawRow[columnIndex];

      // @ts-ignore
      row[prop] = value;
    });

    tableData.push(row);
  });

  return tableData;
}
