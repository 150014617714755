<template>
  <BFormCheckbox
    class="form-checkbox"
    :class="[
      `form-checkbox--${this.size}`,
      {
        'form-checkbox--in-group': isInGroup,
        'form-checkbox--slim': slim,
        'form-checkbox--switch': isSwitch,
        'form-checkbox--empty': $slots.default == null,
      },
    ]"
    :checked="isInGroup ? null : checked"
    :disabled="disabled"
    :value="value"
    @change="change"
    @input="input"
  >
    <span
      class="form-checkbox__icon-wrapper"
      :class="[
        `form-checkbox__icon-wrapper--${size}`,
        { 'form-checkbox__icon-wrapper--switch': isSwitch },
        { 'form-checkbox__icon-wrapper--checked': checkedComputed },
        { 'form-checkbox__icon-wrapper--empty': $slots.default == null },
      ]"
    >
      <FontAwesomeIcon :icon="icon" :flip="isSwitch && !checkedComputed ? 'horizontal' : null" />
    </span>
    <span class="form-checkbox__title" :class="`form-checkbox__title--${size}`">
      <!--
        @slot
        The rendered content inside the FormCheckbox, the text describing the checkbox.
      -->
      <slot name="default" />
    </span>
  </BFormCheckbox>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faSquare, faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BFormCheckbox } from 'bootstrap-vue';

library.add(faSquare, faCheckSquare, faToggleOn);

/**
 * Use this component for checkboxes. Either placed directly into a FieldSet or within a FormFieldCheckboxGroup.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <div style="padding: 10px;">
 *   <FormCheckbox :checked="false">
 *     A standalone checkbox.
 *   </FormCheckbox>
 *   <FormCheckbox
 *     switch
 *     :checked="false"
 *   >
 *     A standalone switch.
 *   </FormCheckbox>
 *   <FormCheckbox
 *     switch
 *     :checked="true"
 *   >
 *     A standalone switch.
 *   </FormCheckbox>
 *   <FormCheckbox
 *     size="sm"
 *     :checked="true"
 *   >
 *     A standalone small checkbox.
 *   </FormCheckbox>
 *   <FormCheckbox
 *     size="lg"
 *     :checked="false"
 *   >
 *     A standalone large checkbox.
 *   </FormCheckbox>
 * </div>
 */
export default {
  name: 'FormCheckbox',
  components: { FontAwesomeIcon, BFormCheckbox },
  model: {
    prop: 'checked',
    event: 'input',
  },
  inject: {
    bvGroup: {
      from: 'bvCheckGroup',
      default: false,
    },
  },
  props: {
    /**
     * Changes the size of the text and the icon.
     */
    size: {
      type: String,
      default: 'md',
      validator: (size) => ['sm', 'md', 'lg'].includes(size),
    },
    /**
     * Render a switch instead of a checkbox, but still with only two states (on/off).
     */
    switch: {
      type: Boolean,
      default: false,
    },
    /**
     * Render a standalone FormCheckbox with the same spacing as if it was in a group.
     */
    slim: {
      type: Boolean,
      default: false,
    },
    /**
     * The value should only be used when placed inside a FormFieldCheckboxGroup. The value is used to check if the checkbox is checked.
     */
    value: {
      default: undefined,
    },
    /**
     * This prop is only used if the checkbox is used as standalone and NOT inside a FormFieldCheckboxGroup.
     */
    checked: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables the checkbox.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSwitch() {
      return this.switch;
    },
    isInGroup() {
      return !!this.bvGroup;
    },
    checkedComputed() {
      if (this.isInGroup) {
        return this.bvGroup.localChecked.includes(this.value);
      }
      return this.checked;
    },
    icon() {
      if (this.switch) {
        return ['far', 'toggle-on'];
      }
      if (this.checkedComputed) {
        return ['far', 'check-square'];
      }
      return ['far', 'square'];
    },
  },
  methods: {
    /**
     * Emitted when selected value(s) is changed due to user interaction.<br>
     * Also used to update the model.
     *
     * @event FormCheckbox#input
     * @type {Boolean} value
     */
    input(value) {
      this.$emit('input', value);
    },
    /**
     * Emitted when the selected value(s) is changed.
     *
     * @event FormCheckbox#change
     * @type {Boolean} value
     */
    change(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style scoped lang="scss">
.form-checkbox {
  margin: 1rem 0;
  padding: 0.375rem 0.75rem 0.375rem 24px;
}

.form-checkbox--empty {
  margin: 0;
  padding: 0;
}

.form-checkbox--in-group,
.form-checkbox--slim {
  margin: 0;
}

.form-checkbox--sm {
  padding-left: 22px;
}

.form-checkbox--lg {
  padding-left: 28px;
}

.form-checkbox--switch {
  padding-left: 32px;
}

.form-checkbox--switch.form-checkbox--sm {
  padding-left: 28px;
}

.form-checkbox--switch.form-checkbox--lg {
  padding-left: 39px;
}

.form-checkbox__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 15.75px;
  width: 15.75px;
  border-radius: 2px;
  font-size: 18px;
  transform: translate(-23px, 2px);
}

.form-checkbox__icon-wrapper--sm {
  width: 14px;
  height: 14px;
  font-size: 16px;
  transform: translate(-21px, 2px);
}

.form-checkbox__icon-wrapper--lg {
  width: 19.25px;
  height: 19.25px;
  font-size: 22px;
  transform: translate(-27px, -1px);
}

.form-checkbox__icon-wrapper--switch {
  width: 23.63px;
  height: 15.75px;
  border-radius: 8px;
  font-size: 21px;
  transform: translate(-31px, 2px);
}

.form-checkbox__icon-wrapper--switch.form-checkbox__icon-wrapper--sm {
  width: 20.25px;
  height: 13.5px;
  font-size: 18px;
  transform: translate(-27px, 2.5px);
}

.form-checkbox__icon-wrapper--switch.form-checkbox__icon-wrapper--lg {
  width: 30.38px;
  height: 20.25px;
  border-radius: 12px;
  font-size: 27px;
  transform: translate(-38px, -1px);
}

.form-checkbox__icon-wrapper--empty,
.form-checkbox__icon-wrapper--empty.form-checkbox__icon-wrapper--switch {
  transform: translateY(2px);
}

.form-checkbox__icon-wrapper--empty.form-checkbox__icon-wrapper--sm,
.form-checkbox__icon-wrapper--empty.form-checkbox__icon-wrapper--switch.form-checkbox__icon-wrapper--sm {
  transform: translateY(2px);
}

.form-checkbox__icon-wrapper--empty.form-checkbox__icon-wrapper--lg,
.form-checkbox__icon-wrapper--empty.form-checkbox__icon-wrapper--switch.form-checkbox__icon-wrapper--lg {
  transform: translateY(-1px);
}

.form-checkbox__icon-wrapper--switch.form-checkbox__icon-wrapper--checked {
  color: var(--primary);
}

.form-checkbox::v-deep .custom-control-input:focus-visible ~ .custom-control-label .form-checkbox__icon-wrapper,
.form-checkbox::v-deep .custom-control-input:active ~ .custom-control-label .form-checkbox__icon-wrapper {
  box-shadow: 0 0 0 2px var(--primary_opacity_60);
}

.form-checkbox__title {
  display: block;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.form-checkbox__title--sm {
  font-size: 12px;
}

.form-checkbox__title--lg {
  font-size: 16px;
}

.form-checkbox::v-deep .custom-control-label::before,
.form-checkbox::v-deep .custom-control-label::after,
.form-checkbox::v-deep .custom-control-input:checked ~ .custom-control-label::after {
  display: none;
}
</style>
