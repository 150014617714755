import Handsontable from 'handsontable';

export default function LoadingRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  Handsontable.renderers.TextRenderer.apply(this, [instance, TD, row, col, prop, ' ', cellProperties, ...args]);

  const span = document.createElement('span');
  span.classList.add('bg-lightest');
  span.style.display = 'block';
  span.style.width = '85%';
  span.style.height = '21px';
  TD.append(span);
}
