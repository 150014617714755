
import { PropType, defineComponent } from 'vue';

import googleMapsApi from '@/shared/modules/googleMapsApi';

export default defineComponent({
  name: 'MapItemTooltip',
  props: {
    /**
     * Reference to the google map. This is used to calculate the position of the tooltip.
     * For the correct positioning to work place the MapItemTooltip in the same container as the google map
     * and give the container `position: relative`.
     */
    map: {
      type: Object as PropType<google.maps.Map>,
      required: true,
    },
    /**
     * The tooltip will be rendered above of this marker.
     */
    marker: {
      type: Object as PropType<google.maps.Marker>,
      default: null,
    },
    /**
     * The tooltip will be rendered above of this polygon if no marker is available.
     */
    polygon: {
      type: Object as PropType<google.maps.Polygon>,
      default: null,
    },
    /**
     * If this is set to true, the tooltip will be rendered at the center of the polygon
     */
    drawOnCenter: {
      type: Boolean,
      default: false,
    },
    /**
     * The tooltip will be displayed on the position of the marker if this is true
     */
    drawOnMarker: {
      type: Boolean,
      default: false,
    },
    /**
     * The style of the tooltip (dark or light)
     */
    colorTheme: {
      type: String,
      default: 'dark',
      validator: (value: string) => ['dark', 'light'].includes(value),
    },
  },
  computed: {
    showTooltip(): boolean {
      return this.tooltipPosition != null;
    },
    getStyle(): { backgroundColor: string; color: string; fontWeight: number; padding: string; lineHeight: number } {
      return {
        backgroundColor: this.colorTheme === 'dark' ? 'var(--black)' : '#fff',
        color: this.colorTheme === 'dark' ? '#fff' : '#000', // set to real black to increase contrast
        fontWeight: this.colorTheme === 'dark' ? 400 : 600,
        padding: this.colorTheme === 'dark' ? '.4rem .7rem' : '0',
        lineHeight: 1,
      };
    },
    getArrowStyle(): { '--arrow-color': string } {
      return {
        '--arrow-color': this.colorTheme === 'dark' ? 'var(--black)' : '#fff',
      };
    },
  },
  data() {
    return {
      google: null as any | null,
      overlayView: null as google.maps.OverlayView | null,
      tooltipPosition: null as { top: string; left: string } | null,
    };
  },
  async created() {
    this.google = await googleMapsApi();

    if (this.google) {
      this.overlayView = new this.google.maps.OverlayView();

      if (this.overlayView) {
        this.overlayView.onAdd = () => null;
        this.overlayView.draw = () => {
          this.tooltipPosition = this.calcTooltipPosition();
        };
        this.overlayView.onRemove = () => null;
        this.overlayView.setMap(this.map);
      }
    }
  },
  beforeDestroy() {
    if (this.overlayView != null) {
      this.overlayView.setMap(null);
    }
  },
  methods: {
    calcTooltipPosition() {
      if (this.google == null || this.overlayView == null || this.overlayView.getProjection() == null) {
        return null;
      }
      if (this.marker != null) {
        return this.getTooltipPositionForMarker(this.marker);
      }
      if (this.polygon != null) {
        return this.getTooltipPositionForPolygon(this.polygon);
      }
      return null;
    },
    getTooltipPositionForMarker(marker: google.maps.Marker) {
      const point = this.overlayView?.getProjection().fromLatLngToContainerPixel(marker.getPosition()!);
      const y = point?.y ?? 0;
      const x = point?.x ?? 0;

      return {
        top: `${this.drawOnMarker ? y : y - 50}px`,
        left: `${x}px`,
      };
    },
    getTooltipPositionForPolygon(polygon: google.maps.Polygon) {
      const bounds = new this.google.maps.LatLngBounds();
      polygon
        .getPath()
        .getArray()
        .forEach((latLng) => {
          bounds.extend(latLng);
        });
      const latLng = this.drawOnCenter
        ? new this.google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng())
        : new this.google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getCenter().lng());
      const point = this.overlayView?.getProjection().fromLatLngToContainerPixel(latLng);
      return {
        top: `${point?.y ?? 0}px`,
        left: `${point?.x ?? 0}px`,
      };
    },
  },
});
