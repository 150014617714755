import { ActionTree } from 'vuex';

import usedItems from '@/shared/mixins/store/usedItems';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { PlantVarietyState } from './types';

const actions: ActionTree<PlantVarietyState, RootState> = {
  ...subscribableStore.actions,
  ...usedItems.actions,
};

export default actions;
