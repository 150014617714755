import Handsontable from 'handsontable';

export default class PluginService {
  private hot: Handsontable;

  constructor(hot: Handsontable) {
    this.hot = hot;
  }

  public getMergePlugin() {
    const mergePlugin = this.hot.getPlugin('mergeCells');
    if (!mergePlugin || !mergePlugin.isEnabled()) throw new Error(PluginService.errorMessage('mergeCells'));
    return mergePlugin;
  }

  private static errorMessage(pluginName: string): string {
    return `${pluginName} plugin must be activated.`;
  }
}
