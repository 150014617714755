
import { defineComponent } from 'vue';
import { Day } from 'vuejs-datepicker';

import numbro from '@/initNumbro';
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import ProcessOrderSwitch from '@/shared/components/ProcessOrderSwitch.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import FormFieldCheckboxGroup from '@/shared/components/form/FormFieldCheckboxGroup.vue';
import FormFieldDatepicker from '@/shared/components/form/FormFieldDatepicker.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldInputNumberFormatted from '@/shared/components/form/FormFieldInputNumberFormatted.vue';
import FormFieldInputNumberFormattedDisplayValue from '@/shared/components/form/FormFieldInputNumberFormattedDisplayValue.vue';
import FormFieldInputNumberFormattedInteger from '@/shared/components/form/FormFieldInputNumberFormattedInteger.vue';
import FormFieldInputNumberFormattedTwoDecimals from '@/shared/components/form/FormFieldInputNumberFormattedTwoDecimals.vue';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import FormFieldSetBordered from '@/shared/components/form/FormFieldSetBordered.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';
import parseNumber from '@/shared/modules/parseNumber';
import roundToDecimals from '@/shared/modules/roundToDecimals';

export default defineComponent({
  name: 'PageFormTest',
  components: {
    FormCheckbox,
    FormFieldCheckboxGroup,
    FormFieldDatepicker,
    FormRadio,
    FormFieldRadioGroup,
    FormFieldDropdown,
    CompanySwitch,
    ProcessOrderSwitch,
    TheContentbar,
    ThePageSkeleton,
    FormFieldSet,
    FormFieldSetBordered,
    FormFieldSelect,
    FormFieldInput,
    FormFieldInputNumberFormatted,
    FormFieldInputNumberFormattedTwoDecimals,
    FormFieldInputNumberFormattedInteger,
    FormFieldInputNumberFormattedDisplayValue,
  },
  data() {
    return {
      firstname: 'foo',
      lastname: null,
      number: 1234.567,
      numberFormattedNumbro: 1234.567,
      numberFormattedTwoDecimals: 1234.567,
      numberFormattedInteger: 1234.567,
      numberFormattedDisplayValueOnly: 1234.567,
      something: 'gotcha',
      selected: 'b',
      dropdownSelected: { id: null, name: '' },
      dropdownItemsLoading: true,
      dropdownItems: [
        {
          name: 'Category X',
          id: 'category_x',
          items: [
            { id: 'a', name: 'Item 1' },
            { id: 'b', name: 'Item 2' },
            { id: 'c', name: 'Item 3' },
          ],
          sort: true,
        },
        {
          name: 'Category Y',
          id: 'category_y',
          items: [
            { id: 'x', name: 'Foo' },
            { id: 'y', name: 'Bar' },
            { id: 'z', name: 'Gotcha' },
          ],
          sort: true,
        },
      ],
      selectedRadio: 'fourth',
      checkboxOne: false,
      checkboxTwo: true,
      checkboxThree: false,
      selectedCheckBoxes: ['fourth'],

      selectedDate: new Date(),
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
      highlightedDates: {
        dates: [new Date(), new Date(new Date().setDate(new Date().getDate() + 1))],
      },
      dayCellContent: (day: Day) => {
        if (day.isHighlighted) {
          return `${day.date}!`;
        }
        return day.date;
      },

      collapsed: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.dropdownItemsLoading = false;
    }, 3000);
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    numberFormatterNumbro(value: number | string | null): string {
      if (value === '' || value === null) return '';
      return numbro(value).format({ thousandSeparated: true, mantissa: 3 });
    },
    numberParserNumbro(value: string | null): number | null {
      if (value === null) return null;
      return numbro.unformat(value);
    },
    numberFormatterTwoDecimals(value: number | null): string {
      if (value === null) return '';
      return numbro(value).format({ thousandSeparated: true, mantissa: 2 });
    },
    numberParserTwoDecimals(value: string | null): number | null {
      if (value === null) return null;
      return roundToDecimals(parseNumber(value), 2);
    },
    parseNumber(value: string | null): number | null {
      if (value === null) return null;
      return parseNumber(value);
    },
  },
});
