import Handsontable from 'handsontable';
import Vue from 'vue';

import Button from '@/shared/components/buttons/Button.vue';

import ReadOnlyRenderer from './ReadOnlyRenderer';

export default function ButtonRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties) {
  Handsontable.renderers.CheckboxRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties);

  // eslint-disable-next-line no-param-reassign
  TD.innerHTML = '';
  TD.classList.add('button-cell');
  const IconButton = Vue.extend(Button);
  const button = new IconButton({
    propsData: {
      size: 'sm',
      color: 'white',
      icon: 'trash-alt',
      iconOnly: true,
    },
  });
  button.$mount();
  button.$on('click', () => {
    cellProperties.onClick(visualRow);
  });
  instance.addHook('beforeKeyDown', (event) => {
    const selectedCell = instance.getSelected();
    if (!selectedCell || selectedCell[0][0] !== visualRow || selectedCell[0][1] !== visualCol) {
      return;
    }
    if (event.key === 'Enter') {
      cellProperties.onClick(visualRow);
    }
  });
  TD.appendChild(button.$el);
  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, visualRow, visualCol, prop, value, cellProperties]);
  }
}
