import { Feature } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { Zone } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Zone';

import { clampInRange, roundToPrecision } from '../common';
import { Calculation } from '../types';

export function calcVarietyValueOfZone(zone: Zone, features: Feature[], calculation: Calculation) {
  if (!calculation.variety.id || calculation.variety.id === 'manual') {
    return null;
  }

  const feature = findFeature(zone, features);
  if (!feature) {
    return null;
  }
  return calcVarietyValue(feature, calculation);
}

function findFeature(zone: Zone, features: Feature[]) {
  return features.find((feature) => feature.vegetation.value_q.toString() === zone.name);
}

function calcVarietyValue(feature: Feature, calculation: Calculation) {
  const { k, d } = calculation;
  const zoneValue = feature.vegetation.custom_value ?? feature.vegetation.value_q;
  const varietyValue = zoneValue * k + d;
  return varietyValue;
}

export function calcSeedRateOfZone(varietyValue: number | null, calculation: Calculation) {
  if (!calculation.variety.id || !varietyValue) {
    return null;
  }

  return calcSeedRate(varietyValue, calculation);
}

function calcSeedRate(varietyValue: number, calculation: Calculation) {
  const {
    kDiff,
    areaWeightedVarietyMean,
    basicSeedStrength,
    seedrateMin,
    seedrateMax,
    manualMinSeedrate,
    manualMaxSeedrate,
  } = calculation;

  let seedRate = kDiff * (varietyValue - areaWeightedVarietyMean) + basicSeedStrength;
  seedRate = roundToPrecision(seedRate);

  const min = manualMinSeedrate ?? seedrateMin;
  const max = manualMaxSeedrate ?? seedrateMax;

  return clampInRange(seedRate, min, max);
}

export function getManualSeedRateOfZone(zoneName: string, calculation: Calculation) {
  if (calculation.manualSeedRates[zoneName] === null || calculation.manualSeedRates[zoneName] === undefined) {
    return null;
  }
  return calculation.manualSeedRates[zoneName];
}

export function applyLossCompensation(seedRate: number | null, calculation: Calculation) {
  if (seedRate === null) return null;

  const { lossCompensation, seedrateMin, seedrateMax, manualMinSeedrate, manualMaxSeedrate } = calculation;

  let withLossCompensation = seedRate * (1 + lossCompensation);
  withLossCompensation = roundToPrecision(withLossCompensation);

  if (manualMinSeedrate !== null || manualMaxSeedrate !== null) {
    return withLossCompensation; // do not clamp in range if manual min/max is set
  }

  return clampInRange(withLossCompensation, seedrateMin, seedrateMax);
}
