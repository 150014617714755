
import { PropType, defineComponent } from 'vue';

import NutrientAmountTable from '@/precision-farming/application-maps/components/NutrientAmountTable.vue';
import { FertilizationNutrients } from '@/precision-farming/application-maps/fertilization/store/types';
import FormFieldSetBordered from '@/shared/components/form/FormFieldSetBordered.vue';

export default defineComponent({
  name: 'NutrientAmountSelection',
  components: { NutrientAmountTable, FormFieldSetBordered },
  props: {
    calculationNutrients: {
      type: Object as PropType<FertilizationNutrients>,
      required: true,
    },
    averageDosage: {
      type: Number as PropType<number | null>,
    },
    disableAmountLine: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const nutrients: string[] = ['n', 'p', 'k'];
    return {
      nutrients,
    };
  },
  computed: {
    descriptionText(): string {
      if (this.nutrientAmountTableData) {
        return this.$t('Inhaltsstoffe des Düngers in %') || 'Inhaltsstoffe des Düngers in %';
      }
      return (
        this.$t('Inhaltsstoffe des Düngers in % und Ausbringmenge am Feld in kg/ha') ||
        'Inhaltsstoffe des Düngers in % und Ausbringmenge am Feld in kg/ha'
      );
    },
    nutrientKeys(): (keyof FertilizationNutrients)[] {
      return this.nutrients as (keyof FertilizationNutrients)[];
    },
    shareNutrientAmountDescription(): string {
      const nutrientAmount: string[] = [];
      this.nutrientKeys.forEach((nutrientKey) => {
        const nutrientDescription = this.getNutrientAmountDescription(nutrientKey);
        if (nutrientDescription) {
          nutrientAmount.push(nutrientDescription);
        }
      });
      return nutrientAmount.join(', ');
    },
    nutrientTableData(): FertilizationNutrients {
      return {
        n: this.getNutrient('n'),
        p: this.getNutrient('p'),
        k: this.getNutrient('k'),
      };
    },
    nutrientAmountTableData(): FertilizationNutrients | null {
      if (this.disableAmountLine) {
        return null;
      }
      return {
        n: this.getNutrientAmount('n') ?? 0,
        p: this.getNutrientAmount('p') ?? 0,
        k: this.getNutrientAmount('k') ?? 0,
      };
    },
  },

  methods: {
    getNutrient(key: keyof FertilizationNutrients): number {
      const nutrient = this.calculationNutrients[key];
      if (!nutrient) return 0;
      return nutrient * 100;
    },
    getNutrientAmount(key: keyof FertilizationNutrients): number | null {
      const nutrientValue = this.calculationNutrients[key];
      if (!this.averageDosage || !nutrientValue) {
        return null;
      }
      return this.averageDosage * nutrientValue;
    },
    getNutrientAmountDescription(key: keyof FertilizationNutrients): string | null {
      const nutrientAmount = this.getNutrientAmount(key);
      if (nutrientAmount !== null && this.getNutrient(key) !== 0) {
        return `${key.toString().toUpperCase()} ${nutrientAmount.toFixed()} kg/ha`;
      }
      return null;
    },
    updateNutrient(key: keyof FertilizationNutrients, value: number | null) {
      this.$emit('update:nutrient', key, value);
    },
    updateNutrientAmount(key: string, value: number | null) {
      this.$emit('update:nutrientAmount', key, value);
    },
  },
});
