// eslint-disable-next-line max-classes-per-file
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const INSTANCES = [];
const ACCESS_TOKENS = {};

/** Class for handling JWT tokens (login, logout, check for expiration, refresh) */
export default class JwtAuth {
  /**
   * Create a new JwtAuth instance
   * @param {Object} options
   * @param {string} options.authServiceUri - Base URL where the JWT API is located
   * @param {function} [options.onLoggedOut] - Callback that will get called when checkAccessToken could not retrieve a new refresh token
   * @param {function} [options.onAccessTokenRefresh] - Callback that will get called when checkAccessToken just retrieved a new refresh token
   * @param {boolean} [options.debuggingEnabled] - If true, will output debug logs to console, default false
   */
  constructor(options) {
    this.options = {
      authServiceUri: null,
      onLoggedOut() {},
      onAccessTokenRefresh() {},
      debuggingEnabled: false,
    };
    this.setOptions(options);

    if (this.options.authServiceUri == null) {
      console.error('JwtAuth: authServiceUri not provided');
      return;
    }

    INSTANCES.push(this);

    this.TOKEN_STORAGE_KEY = Symbol('TOKEN_STORAGE_KEY');
    this.EXPIRATION_TIMESTAMP_KEY = `jwtAuth_accessTokenExpiration_${INSTANCES.indexOf(this)}`;
    this.LOGOUT_KEY = `jwtAuth_logout_${INSTANCES.indexOf(this)}`;

    this.ignoreLocalStorageExpiration = false;

    this.tokenLifetime = null;

    this.checkAccessToken = this.checkAccessToken.bind(this);
    this.syncLogout = this.syncLogout.bind(this);
  }

  /**
   * Set or update the options object
   * @param {Object} options
   * @param {string} [options.authServiceUri] - Base URL where the JWT API is located
   * @param {function} [options.onLoggedOut] - Callback that will get called when checkAccessToken could not retrieve a new refresh token
   * @param {function} [options.onAccessTokenRefresh] - Callback that will get called when checkAccessToken just retrieved a new refresh token
   */
  setOptions(options) {
    this.options = { ...this.options, ...options };
    if (options.authServiceUri != null) {
      this.options.authServiceUri = this.options.authServiceUri.replace(/\/$/, ''); // remove trailing slash if exists
    }
  }

  /**
   * Initializes the periodical checking and refreshing of access token
   * @returns {Promise<void>}
   */
  async initTokenChecking() {
    window.removeEventListener('focus', this.checkAccessToken);
    window.removeEventListener('storage', this.syncLogout);
    window.addEventListener('focus', this.checkAccessToken);
    window.addEventListener('storage', this.syncLogout);
    return this.checkAccessToken();
  }

  /**
   * Log in our JWT service and start repeating token checking
   * @param {string} email
   * @param {string} password
   * @returns {Promise<{errorCode: string, status: string}|*|{status: string}|{status: string}>}
   */
  async login(email, password) {
    const refreshTokenResponse = await this.requestRefreshToken(email, password);
    if (refreshTokenResponse.status !== 'success') {
      return refreshTokenResponse;
    }
    await this.initTokenChecking();
    return refreshTokenResponse;
  }

  async loginOneTime(oneTimeToken) {
    const requestData = {
      version: '1.0',
      data: {
        oneTimeToken,
      },
    };
    let data;

    try {
      const response = await axios.post(`${this.options.authServiceUri}/loginOneTime`, requestData, {
        withCredentials: true,
      });
      ({ data } = response);
      this.debugLog('loginOneTime response:', data);
    } catch (error) {
      if (error != null && error.response != null && error.response.data != null) {
        ({ data } = error.response);
      }
    }

    if (data == null || data.status !== 'success') {
      if (data == null || data.status !== 'error' || data.errorCode == null) {
        return {
          status: 'error',
          errorCode: 'unknownError',
        };
      }
      return data;
    }

    this.handleNewAccessToken(data.data.access_token);

    return data;
  }

  /**
   * Log out from our JWT service
   * @returns {Promise<*|{errorCode: string, status: string}|{status: string}>}
   */
  async logout() {
    const { data } = await axios.get(`${this.options.authServiceUri}/logout`, {
      withCredentials: true,
    });

    this.handleNewAccessToken(null);

    if (data.status !== 'success') {
      if (data.status !== 'error' || data.errorCode == null) {
        return {
          status: 'error',
          errorCode: 'unknownError',
        };
      }
      return data;
    }

    localStorage.setItem(this.LOGOUT_KEY, `${Date.now()}`);

    return {
      status: 'success',
    };
  }

  /**
   * Handles localStorage events when the "logout" key gets changed, then unsets the token and calls the onLoggedOut callback
   * @param {StorageEvent} e
   * @protected
   */
  syncLogout(e) {
    if (e.key !== this.LOGOUT_KEY) {
      return;
    }
    this.debugLog('syncLogout');
    this.handleNewAccessToken(null);
  }

  /**
   * Request a new refresh token and access token
   * @param {string} email
   * @param {string} password
   * @returns {Promise<*|{errorUserMessage: [string], errorCode: string, status: string}|{status: string}>}
   * @protected
   */
  async requestRefreshToken(email, password) {
    const requestData = {
      version: '1.0',
      data: {
        email,
        password,
      },
    };

    let data;
    try {
      const response = await axios.post(`${this.options.authServiceUri}/login`, requestData, {
        withCredentials: true,
      });
      ({ data } = response);
      this.debugLog('requestRefreshToken response:', data);
    } catch (error) {
      if (error != null && error.response != null && error.response.data != null) {
        ({ data } = error.response);
      }
    }

    if (data == null || data.status !== 'success') {
      if (data == null || data.status !== 'error' || data.errorCode == null) {
        return {
          status: 'error',
          errorCode: 'unknownError',
        };
      }
      return data;
    }

    this.handleNewAccessToken(data.data.access_token);

    return data;
  }

  /**
   * Request a new access token
   * @returns {Promise<*|{errorCode: string, status: string}|{status: string}>}
   * @protected
   */
  async requestAccessToken() {
    const { data } = await axios.get(`${this.options.authServiceUri}/refresh`, {
      withCredentials: true,
    });

    this.debugLog('requestAccessToken response:', data);

    if (data.status !== 'success') {
      if (data.status !== 'error' || data.errorCode == null) {
        return {
          status: 'error',
          errorCode: 'unknownError',
        };
      }
      return data;
    }

    this.handleNewAccessToken(data.data.access_token);

    return {
      status: 'success',
    };
  }

  /**
   * Store the acces token in memory (only accessible inside this class) and save its expiration time to localStorage
   * @param {string|null} accessToken - an encoded JWT access token or null
   * @protected
   */
  handleNewAccessToken(accessToken) {
    ACCESS_TOKENS[this.TOKEN_STORAGE_KEY] = accessToken;
    if (accessToken != null) {
      const expiration = this.constructor.getExpirationTimeFromToken(accessToken);
      this.tokenLifetime = expiration - new Date().getTime();
      const body = this.constructor.getDataFromToken(accessToken);
      localStorage.setItem(this.EXPIRATION_TIMESTAMP_KEY, JSON.stringify(expiration));
      this.options.onAccessTokenRefresh.call(null, body);
    } else {
      localStorage.removeItem(this.EXPIRATION_TIMESTAMP_KEY);
      this.loggedOut();
    }
  }

  /**
   * Get the expiration time (in milliseconds) from a JWT
   * @param {string} token - an encoded JWT
   * @returns {number} - expiration date of the JWT in milliseconds
   */
  static getExpirationTimeFromToken(token) {
    const decodedToken = jwtDecode(token);
    return parseInt(decodedToken.exp, 10) * 1000;
  }

  /**
   * Get the decoded JWT token body
   * @param {string} token - an encoded JWT
   * @returns {object} - body content of token
   */
  static getDataFromToken(token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.data;
  }

  /**
   * Check whether the access token is near its expiration time and if so, try to retrieve another one
   * Will call itself after a defined time (currently every minute)
   * Promise resolve returns loggedIn status
   * @returns {Promise<boolean>}
   */
  async checkAccessToken() {
    if (window.Cypress) {
      return false;
    }

    if (this.checkAccessTokenTimeout != null) {
      clearTimeout(this.checkAccessTokenTimeout);
    }

    let currentAccessTokenExpiration;

    this.debugLog('Checking for existing access token.');

    if (ACCESS_TOKENS[this.TOKEN_STORAGE_KEY] != null) {
      const currentAccessToken = ACCESS_TOKENS[this.TOKEN_STORAGE_KEY];
      currentAccessTokenExpiration = this.constructor.getExpirationTimeFromToken(currentAccessToken);
    } else if (!this.ignoreLocalStorageExpiration) {
      currentAccessTokenExpiration = JSON.parse(localStorage.getItem(this.EXPIRATION_TIMESTAMP_KEY));
    }

    const GRACE_PERIOD = this.tokenLifetime != null ? Math.max(this.tokenLifetime / 5, 60 * 1000) : 60 * 1000;
    const CHECK_TIMEOUT = Math.min(GRACE_PERIOD / 6, 30 * 1000);

    if (currentAccessTokenExpiration != null && new Date().getTime() < currentAccessTokenExpiration - GRACE_PERIOD) {
      this.debugLog(`Token existing, will check again in ${Math.round(CHECK_TIMEOUT / 1000)}s.`);
      this.checkAccessTokenTimeout = setTimeout(this.checkAccessToken, CHECK_TIMEOUT);
      return true;
    }

    this.debugLog('No token found or token expired.');

    this.debugLog('Requesting new token now.');
    const accessTokenResponse = await this.requestAccessToken();

    if (accessTokenResponse.status === 'success') {
      this.debugLog(`New token acquired, will check again in ${Math.round(CHECK_TIMEOUT / 1000)}s.`);
      this.checkAccessTokenTimeout = setTimeout(() => this.checkAccessToken(), CHECK_TIMEOUT);
      return true;
    }

    this.debugLog('Access token could not be retrieved.');
    this.checkAccessTokenTimeout = setTimeout(() => this.checkAccessToken(), CHECK_TIMEOUT);

    this.loggedOut();
    return false;
  }

  /**
   * Will be called after the refresh token has expired or is invalid or has been deleted
   * Calls the callback function onLoggedOut
   * @protected
   */
  loggedOut() {
    this.debugLog('Call onLoggedOut callback.');
    this.options.onLoggedOut.call();
  }

  /**
   * Logs to the console if options.debuggingEnabled is true and prepends the current time to the log output
   * @param {...*} var_args - arguments that will get handed over to console.log
   */
  debugLog(...args) {
    if (this.options.debuggingEnabled !== true) {
      return;
    }
    const time = new Date().toLocaleTimeString();
    // eslint-disable-next-line no-console
    console.log(time, ...args);
  }
}

/** Extends JwtAuth by:
 * - adding functionality to retrieve the token
 * - ignoring the token's expiration stored in localStorage
 *   (Reason: In our Vue app we only rely on the token that is stored in memory.)
 */
export class JwtAuthVue extends JwtAuth {
  constructor(...args) {
    super(...args);
    this.ignoreLocalStorageExpiration = true;
  }

  /**
   * Retrieve the current access token from the memory
   * @returns {*} the current access token
   */
  getAccessToken() {
    return ACCESS_TOKENS[this.TOKEN_STORAGE_KEY];
  }
}
