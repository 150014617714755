import Handsontable from 'handsontable';

export default function preventMoveOfPlaceholderColumnFactory(hot: Handsontable) {
  function preventMoveOfPlaceholderColumn(
    movedColumns: number[],
    finalIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropIndex: number | void,
    movePossible: boolean,
  ): boolean {
    const { columns } = hot.getSettings();
    if (!Array.isArray(columns)) throw new Error('columns must be of type ColumnSettings[]');

    const containsMoveOfPlaceholderColumn = movedColumns.some((movedColumn, index) => {
      const physicalColumn = hot.toPhysicalColumn(movedColumn);
      if (isPlaceholderColumn(columns[physicalColumn]) && !isLastColumnOfMovedColumns(movedColumns, index)) {
        return true;
      }

      return false;
    });

    if (containsMoveOfPlaceholderColumn) {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
      movePossible = false;
      return false;
    }

    return true;
  }

  return preventMoveOfPlaceholderColumn;
}

function isPlaceholderColumn(column: Handsontable.ColumnSettings): boolean {
  return column.type === 'farmdok.placeholder';
}

function isLastColumnOfMovedColumns(movedColumns: number[], index: number): boolean {
  return movedColumns.length - 1 === index;
}
