
import {
  ActivityProduct,
  RestErrorResponse,
  StockLevel,
  StockMovementApiStockMovementsStockLevelsRequest,
  StockMovementsStockLevels200Response,
  StoragePlace,
} from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import StorageSelectSection from '@/activities/modals/createEditActivity/components/storageSelection/StorageSelectSection.vue';
import StorageSelectSectionEmpty from '@/activities/modals/createEditActivity/components/storageSelection/StorageSelectSectionEmpty.vue';
import StorageSelectionService from '@/activities/modals/createEditActivity/services/StorageSelectionService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import { Api } from '@/plugins/farmdokRestApi';
import ProductService from '@/products/services/ProductService';
import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import { Data } from '@/shared/mixins/store/types';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

import { StorageSelection } from '../components/storageSelection/types';
import { Getters as CreateEditActivityGetters } from '../store/getters';

export default defineComponent({
  name: 'StorageSelectSectionContainer',
  components: { StorageSelectSection, StorageSelectSectionEmpty, FeatureNotAvailable },
  data() {
    const stockLevels: StockLevel[] = [];
    return {
      stockLevels,
    };
  },
  created() {
    this.$store.dispatch('units/subscribe');
    this.$store.dispatch('storagePlaces/subscribe');
    this.loadStockLevels();
  },
  computed: {
    ...mapGetters({
      currentCompanyIds: 'auth/currentCompanyIds',
      units: 'units/data',
    }),
    activityProducts(): ActivityProduct[] {
      return (this.$store.getters[`activities/createEditActivity/mergedActivityProducts`] as ActivityProduct[]) ?? [];
    },
    currentActivityProductId(): string | null {
      return this.$store.state.activities.createEditActivity.currentActivityProductId;
    },
    currentActivityProduct(): ActivityProduct | null {
      return this.$store.getters[
        `activities/createEditActivity/currentActivityProduct`
      ] as CreateEditActivityGetters['currentActivityProduct'];
    },
    storagePlaces(): Data<StoragePlace> {
      return this.$store.state.storagePlaces.data;
    },
    storageSelectionService(): StorageSelectionService {
      return new StorageSelectionService(this.stockLevels, this.storagePlaces, this.units);
    },
    productService(): ProductService {
      const rootState = this.$store.state as RootState;
      return new ProductService(
        rootState.products.mineralFertilizers.data,
        rootState.products.companyFertilizers.data,
        rootState.products.secondaryFertilizers.data,
        rootState.products.herbizides.data,
        rootState.products.crops.data,
        rootState.products.miscellaneous.data,
        rootState.products.harvests.data,
        rootState.products.seeds.data,
        rootState.products.otherProductsAndFertilizers.data,
      );
    },
    activityProductService(): ActivityProductService {
      return new ActivityProductService(this.units, this.productService, calculateAmountsAndUnits);
    },
    currentStorageSelection(): StorageSelection | null {
      if (!this.currentActivityProduct?.productId) return null;

      return this.storageSelectionService.get(
        this.currentActivityProduct.productId,
        this.currentActivityProduct.storagePlaceId,
      );
    },
    otherStorageSelections(): StorageSelection[] {
      if (!this.currentStorageSelection) return [];

      return this.storageSelectionService.getAllExcept(this.currentStorageSelection);
    },
    storageSelectionsAvailable(): boolean {
      return !!this.currentStorageSelection || this.otherStorageSelections.length > 0;
    },
    featureEnabledAndVisible(): boolean {
      return this.featureStockMovementEnabled && this.featureStockMovementVisible;
    },
    showStorageSelectSection(): boolean {
      if (!this.featureEnabledAndVisible) return false;

      return this.storageSelectionsAvailable;
    },
    showStorageSelectSectionEmpty(): boolean {
      if (!this.featureEnabledAndVisible) return false;

      return !this.storageSelectionsAvailable;
    },
    showFeatureNotAvailable(): boolean {
      return !this.featureStockMovementEnabled && this.featureStockMovementVisible;
    },
    featureStockMovementEnabled(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_STOCK_MOVEMENT);
    },
    featureStockMovementVisible(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_STOCK_MOVEMENT);
    },
  },
  methods: {
    applyStockLevel(stockLevel: StockLevel): void {
      this.$store.dispatch(`activities/createEditActivity/applyStockLevelOnActivityProductInAllActivities`, {
        activityProductId: this.currentActivityProductId,
        updateValues: stockLevel,
        rootState: this.$store.state,
      });
    },
    addProductWithStockLevel(stockLevel: StockLevel): void {
      let newActivityProduct = ActivityProductService.createActivityProduct();
      newActivityProduct = {
        ...newActivityProduct,
        ...ActivityProductService.setStockLevelValues(newActivityProduct, stockLevel),
      };

      this.$store.commit(`activities/createEditActivity/addActivityProductToAllActivities`, newActivityProduct);
    },
    async loadStockLevels() {
      // @ts-ignore
      const { stockMovementsApi } = this.$api as Api;
      const requestParameters: StockMovementApiStockMovementsStockLevelsRequest = {
        companyId: this.currentCompanyIds[0], // TODO #962 enable multicompany
        timestamp: toUnixTimestamp(Date.now()),
      };
      let apiResponse: StockMovementsStockLevels200Response | RestErrorResponse | undefined;
      try {
        const response = await stockMovementsApi.stockMovementsStockLevels(requestParameters);
        apiResponse = response.data;
      } catch (error) {
        console.error(error);
        this.stockLevels = [];
      }
      if (apiResponse) {
        if (apiResponse.status === 'success') {
          this.stockLevels = (apiResponse as StockMovementsStockLevels200Response).data || [];
        } else {
          this.stockLevels = [];
        }
      }
    },
  },
});
