<template>
  <TableBase
    id="hot-contracting-view-contract"
    ref="table"
    disable-read-only-bg
    :filter-string="filterString"
    :table-settings="tableSettings"
    :table-data="tableData"
    :loading="loading"
    disableReadOnlyBg
    @tableMounted="tableMounted"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @update:filterString="(value) => $emit('update:filterString', value)"
  >
    <template #footer>
      <TableFooter :total-rows="Object.keys(tableData).length" :visible-rows="Object.keys(tableData).length">
        {{ $t('Gesamtfläche {totalArea} ha', { totalArea: sizeTotalFormatted }) }}
      </TableFooter>
    </template>
  </TableBase>
</template>

<script>
import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import columnsTableViewContract from '../handsontable/columns/tableViewContract';

export default {
  name: 'TableViewContractContainer',
  components: { TableFooter, TableBase },
  mixins: [tableContainerBase, calculateSizeTotal],
  props: {
    filterString: {
      type: String,
      default: '',
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columnDefinition: columnsTableViewContract,
      loading: true,
    };
  },
  mounted() {
    this.loading = false;
  },
};
</script>
