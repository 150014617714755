
import { PropType, defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Getters as ActivityTypeGetters } from '@/activityTypes/store/getters';
import { ProductVisibilitiesState } from '@/productVisibilities/store/types';
import { Getters as ProductGetters } from '@/products/store/getters';
import { ActivityType, Field, ProductCategoryType } from '@/shared/api/rest/models';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { DropdownItems } from '@/shared/handsontable/types';
import { StoragePlacesState } from '@/storagePlaces/store/types';

export default defineComponent({
  name: 'SidebarCreateApplicationMapFertilization',
  components: {
    FormFieldDropdown,
  },
  props: {
    workflowKey: {
      type: String as PropType<string>,
      required: true,
    },
    productCategoryType: {
      type: String as PropType<ProductCategoryType>,
      required: true,
    },
    productCategoryTypeName: {
      type: String as PropType<string>,
      required: true,
    },
    dropdownTooltipTitle: {
      type: String as PropType<string>,
    },
    nameForManualInput: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['currentCompanies']),
    ...mapState('productVisibilities', {
      productVisibilities: (state) => Object.values((state as ProductVisibilitiesState).data),
    }),
    ...mapState('productCategories', {
      productCategories: 'data',
    }),
    ...mapState('storagePlaces', {
      storagePlaces: (state) => Object.values((state as StoragePlacesState).data),
    }),
    ...mapState({
      calculation(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/calculation`];
      },
      selectedFields(state) {
        // @ts-ignore
        return state.precisionFarming.applicationMaps[this.workflowKey].selectedFields;
      },
    }),
    ...mapGetters({
      currentProcessOrders: 'auth/currentProcessOrders',
    }),
    dropdownItems(): DropdownItems[] {
      const activityType: ActivityType = (
        this.$store.getters['activityTypes/activityTypeByType'] as ActivityTypeGetters['activityTypeByType']
      )(this.productCategoryType);

      if (
        !activityType ||
        !this.currentCompanies ||
        !this.currentProcessOrders ||
        !this.productVisibilities ||
        !this.productCategories
      ) {
        return [];
      }
      const fields: Field[] = this.selectedFields
        .map((fieldId: string) => this.$store.state.fields.data[fieldId])
        .filter((fieldOrNotFound: Field | undefined) => fieldOrNotFound !== undefined);
      const products = (
        this.$store.getters[
          'products/compatibleProductsToDropdownItems'
        ] as ProductGetters['compatibleProductsToDropdownItems']
      )(
        activityType,
        this.currentCompanies,
        this.currentProcessOrders,
        this.productVisibilities,
        Object.values(this.productCategories),
        fields,
        this.storagePlaces,
      );
      return [
        {
          name: this.$t('Manuelle Eingabe'),
          id: 'manual',
          items: [{ id: 'manual', name: this.nameForManualInput }],
          sort: false,
        },
        ...products,
      ];
    },
  },
  created() {
    this.$store.dispatch(`products/${this.productCategoryTypeName}/subscribe`);
    this.$store.dispatch('activityTypes/subscribe');
    this.$store.dispatch('auth/subscribe');
    this.$store.dispatch('productCategories/subscribe');
    this.$store.dispatch('productVisibilities/subscribe');
    this.$store.dispatch('storagePlaces/subscribe');
  },
  methods: {
    dropdownSelect(material: DropdownItem) {
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setMaterial`, material);
    },
    // workaround as bootstrap has no option to hide tooltip when hovering over the popover
    onTooltipMouseleave() {
      this.$root.$emit('bv::hide::tooltip');
    },
  },
});
