<template>
  <!-- eslint-disable vue/no-unused-vars -->
  <ThePageSkeleton class="page-register" scroll-y>
    <WelcomeContentContainer>
      <button class="page-register__button-back" @click="onButtonBack">
        <FontAwesomeIcon class="page-register__button-back-icon" icon="angle-left" />
      </button>
      <TopContentContainer class="page-register__top-content-container">
        <WelcomeTitle class="m-0" white centered>
          {{ $t('Wähle dein Land und melde dich an.') }}
        </WelcomeTitle>
      </TopContentContainer>
      <MaxWidthContainer class="my-auto">
        <ValidationObserver v-slot="{ errors }" class="w-100" ref="validationObserver">
          <BForm class="w-100" novalidate @submit.prevent="submit">
            <ValidationProvider v-slot="{ errors, dirty, failed }" rules="FormFieldDropdownRequired" :name="$t('Land')">
              <FormFieldDropdown
                v-model="country"
                class="w-100 mt-0"
                name="country"
                variant="vertical-lg"
                required
                :label="$t('Land')"
                :placeholder="countriesLoading ? $t('Lade...') : $t('Wählen...')"
                :disabled="fetching"
                :state="errors.length > 0 && !dirty ? false : null"
                :items="countries"
                :items-loading="countriesLoading"
              />
            </ValidationProvider>
            <Button v-if="false" class="page-register__button-google my-0" size="lg" block>
              {{ $t('Mit Google anmelden') }}
            </Button>
            <div v-if="false" class="page-register__divider w-100">
              <div class="flex-grow-1">
                <hr />
              </div>
              <span class="page-register__divider-text">ODER</span>
              <div class="flex-grow-1">
                <hr />
              </div>
            </div>
            <ValidationProvider
              v-slot="{ errors, dirty, failed }"
              rules="required|email"
              slim
              :name="$t('E-Mail-Adresse')"
            >
              <FormFieldInput
                v-model="email"
                ref="email"
                class="w-100"
                name="email"
                type="email"
                autocomplete="email"
                variant="vertical-lg"
                :label="$t('E-Mail-Adresse')"
                :placeholder="$t('E-Mail-Adresse')"
                :disabled="fetching"
                :state="errors.length > 0 && !dirty ? false : null"
                @input="userErrorMessage = null"
              />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors, dirty }" rules="required|min:8" slim :name="$t('Passwort')">
              <FormFieldInput
                v-model="password"
                class="w-100"
                name="password"
                type="password"
                autocomplete="new-password"
                variant="vertical-lg"
                :label="$t('Passwort')"
                :placeholder="$t('Passwort')"
                :disabled="fetching"
                :state="errors.length > 0 && !dirty ? false : null"
                @input="userErrorMessage = null"
              />
            </ValidationProvider>
            <p v-if="$store.state.regions.errors.length > 0" class="text-danger">
              <!-- eslint-disable max-len -->
              {{
                $t(
                  'Die Liste der verfügbaren Länder konnte nicht geladen werden. Bitte überprüfe deine Internetverbindung und lade die Seite neu.',
                )
              }}
              <!-- eslint-enable max-len -->
            </p>
            <p v-else-if="firstError(errors)" class="text-danger">{{ firstError(errors) }}&nbsp;</p>
            <ErrorUserMessage v-else :error-user-message="userErrorMessage" />
            <Button type="submit" variant="primary" size="lg" block class="my-0" :disabled="fetching">
              <FontAwesomeIcon v-if="fetching" icon="circle-notch" class="text-white" spin />
              <span v-else>{{ $t('Jetzt starten!') }}</span>
            </Button>
          </BForm>
        </ValidationObserver>
        <p class="page-register__bottom-link mt-3 mb-0">
          {{ agbMessagePartOne }}
          <ButtonLink href="https://www.farmdok.com/agb/" target="_blank">{{
            $t('Allgemeinen Geschäftsbedingungen (AGB)')
          }}</ButtonLink>
          {{ agbMessagePartTwo }}
        </p>
        <p class="mt-4 mb-0">
          {{ $t('Du hast bereits ein Konto?') }}
          <ButtonLink :to="{ name: 'login' }">{{ $t('Klicke hier um dich einzuloggen.') }}</ButtonLink>
        </p>
      </MaxWidthContainer>
    </WelcomeContentContainer>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import veeValidateHelpers from '@/shared/mixins/form/veeValidateHelpers';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';

import { FARMDOK_ONBOARDING_INFO } from './PageWelcome.vue';
import TopContentContainer from './components/TopContentContainer.vue';
import WelcomeContentContainer from './components/WelcomeContentContainer.vue';
import WelcomeTitle from './components/WelcomeTitle.vue';
import registerCountry from './mixins/registerCountry';

library.add(faCircleNotch, faAngleLeft);

export default {
  name: 'PageRegister',
  components: {
    ErrorUserMessage,
    WelcomeTitle,
    ThePageSkeleton,
    FormFieldDropdown,
    FormFieldInput,
    WelcomeContentContainer,
    MaxWidthContainer,
    TopContentContainer,
    Button,
    ButtonLink,
  },
  mixins: [veeValidateHelpers, backendResponseErrorHelpers, registerCountry],
  data() {
    return {
      fetching: false,
      email: null,
      password: null,
      agbMessage: this.$t('Durch erstellen eines FARMDOK Accounts stimmst du den {agb} von FARMDOK zu.', {
        agb: '#####',
      }),
      defaultUserErrorMessage: this.$t('Unbekannter Fehler. Bitte überprüfe deine Internetverbindung.'),
    };
  },
  computed: {
    agbMessagePartOne() {
      return this.agbMessage.split('#####')[0];
    },
    agbMessagePartTwo() {
      return this.agbMessage.split('#####')[1];
    },
  },
  mounted() {
    if (this.$refs.email == null) {
      return;
    }
    this.$refs.email.$el.querySelector('input').focus();
  },
  methods: {
    onButtonBack() {
      this.$router.go(-1);
    },
    submit() {
      this.userErrorMessage = null;
      this.$refs.validationObserver.reset();
      this.$refs.validationObserver.handleSubmit(this.register);
    },
    async register() {
      if (this.fetching) {
        return;
      }
      this.fetching = true;

      let onboarding = null;
      if (localStorage.getItem(FARMDOK_ONBOARDING_INFO) != null) {
        try {
          onboarding = JSON.parse(localStorage.getItem(FARMDOK_ONBOARDING_INFO));
          if (onboarding.size != null && onboarding.size.hectares != null) {
            onboarding.size.hectares = Number(onboarding.size.hectares);
          }
          if (onboarding.size != null && onboarding.size.employees != null) {
            onboarding.size.employees = Number(onboarding.size.employees);
          }
          if (onboarding.size != null && onboarding.size.customers != null) {
            onboarding.size.customers = Number(onboarding.size.customers);
          }
        } catch (error) {
          console.error(error);
        }
      }

      // wait at least 200ms that the spinner looks better
      let response;
      await Promise.all([
        // eslint-disable-next-line no-promise-executor-return
        new Promise((resolve) => setTimeout(resolve, 200)),
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          response = await this.$store.dispatch('auth/register', {
            regionId: this.country.id,
            email: this.email,
            password: this.password,
            onboarding,
          });
          resolve();
        }),
      ]);

      if (response != null && response.status === 'success') {
        this.$root.$emit('register--success', onboarding);
        localStorage.removeItem(FARMDOK_ONBOARDING_INFO);
        await this.$router.push({ name: 'register-support' });
      } else {
        this.setUserErrorMessageFromResponse(response);
      }
      this.fetching = false;
    },
  },
};
</script>

<style scoped>
.page-register__button-back {
  position: absolute;
  top: calc(var(--spacer_4) - 1rem);
  left: calc(var(--spacer_4) - 1rem);
  padding: 1rem;
  border: none;
  color: var(--white);
  font-size: 18px;
}

.page-register__button-back-icon {
  margin: 0;
}

@media (min-width: 768px) {
  .page-register__button-back {
    top: calc(var(--spacer_5) - 1rem);
    left: calc(var(--spacer_5) - 1rem);
  }
}

/* need space for back button */
.page-register__top-content-container {
  padding-top: var(--spacer_5);
  padding-left: var(--spacer_5);
  padding-right: var(--spacer_5);
}

.page-register__button-google {
  margin-bottom: var(--spacer_4);
}

.page-register__divider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacer_4);
}

.page-register__divider-text {
  padding: 0 1.5rem;
}

hr {
  border-color: var(--gray_600);
}

.page-register__bottom-link {
  text-align: center;
  font-size: 12px;
}
</style>
