import 'firebase/analytics';
import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDAHsKHKUlu7nvm_ojd0RsuwzR8OdqjxTM',
  authDomain: 'farmdok-3ecc4.firebaseapp.com',
  databaseURL: 'https://farmdok-3ecc4.firebaseio.com',
  projectId: 'farmdok-3ecc4',
  storageBucket: 'farmdok-3ecc4.appspot.com',
  messagingSenderId: '1097049611199',
  appId: '1:1097049611199:web:14d264da11647050b15f0a',
  measurementId: 'G-LQ44VD5SG2',
};

const firebaseConfigDevelop = {
  apiKey: 'AIzaSyDAHsKHKUlu7nvm_ojd0RsuwzR8OdqjxTM',
  authDomain: 'farmdok-3ecc4.firebaseapp.com',
  databaseURL: 'https://farmdok-3ecc4.firebaseio.com',
  projectId: 'farmdok-3ecc4',
  storageBucket: 'farmdok-3ecc4.appspot.com',
  messagingSenderId: '1097049611199',
  appId: '1:1097049611199:web:9652fa1fcef10a18b15f0a',
  measurementId: 'G-RH0L03BQYZ',
};

if (process.env.VUE_APP_TRACKING_USE_PRODUCTION_APP === '1') {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.initializeApp(firebaseConfigDevelop);
}

class AnalyticsHelper {
  constructor(instance, router, store) {
    this.instance = instance;
    this.router = router;
    this.store = store;
    this.analytics = firebase.analytics();
  }

  logEvent(event, payload) {
    this.analytics.logEvent(event, payload);
  }
}

export default AnalyticsHelper;
