
import { ActivityProduct } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import PestSelectSection from '@/activities/modals/createEditActivity/components/PestSelectSection.vue';
import { ActionPayloads } from '@/pesticideIndications/store/actions';
import { Getters } from '@/pesticideIndications/store/getters';
import isHerbicide from '@/products/herbizides/isHerbicide';
import { Product } from '@/shared/api/rest/models';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

import { Getters as CreateEditActivityGetters } from '../store/getters';

export default defineComponent({
  name: 'PestSelectSectionContainer',
  components: { PestSelectSection },
  props: {
    activityProduct: {
      type: Object as PropType<ActivityProduct>,
      required: true,
    },
  },
  created() {
    this.$store.dispatch('units/subscribe');
    this.$store.dispatch('pests/subscribe');
  },
  computed: {
    ...mapState('pests', {
      pests: 'data',
    }),
    ...mapState('fields', {
      fields: 'data',
    }),
    ...mapState('productCategories', {
      productCategories: 'data',
    }),
    activityTimeStarts(): number[] {
      return this.$store.getters[
        `activities/createEditActivity/activityTimeStarts`
      ] as CreateEditActivityGetters['activityTimeStarts'];
    },
    selectedProduct(): Product | undefined {
      return this.$store.getters['products/findProductById'](this.activityProduct.productId);
    },
    selectedFields(): string[] {
      return (
        (this.$store.state as RootState).activities.createEditActivity.activities
          ?.map((activity) => activity.fieldId)
          .filter(notNullOrUndefined) ?? []
      );
    },
    selectedFieldsCropIds(): string[] {
      const cropIds: string[] = this.selectedFields
        .map((selectedFieldId: string) => this.fields[selectedFieldId]?.cropId)
        .filter((cropIdOrNotFound: string) => !!cropIdOrNotFound);

      const uniqueCropIds = [...new Set(cropIds)];
      return uniqueCropIds;
    },
    selectedDropdownItem(): DropdownItem {
      if (!this.selectedProduct?.id || !this.activityProduct.pestId || !this.activityProduct.pesticideIndicationId) {
        return { id: '', name: '' };
      }

      return (this.$store.getters['pesticideIndications/dropdownItem'] as Getters['dropdownItem'])(
        this.selectedProduct.id,
        this.activityProduct.pestId,
        this.activityProduct.pesticideIndicationId,
      );
    },
    dropdownItems(): DropdownItemsPerCategory[] {
      if (!this.selectedProduct || !isHerbicide(this.selectedProduct, this.productCategories)) {
        return [];
      }

      return (this.$store.getters['pesticideIndications/dropdownItems'] as Getters['dropdownItems'])(
        this.selectedProduct.id,
        this.selectedFieldsCropIds,
        this.activityTimeStarts,
      );
    },
    featurePestsEnabled(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_PESTS);
    },
    featurePestsVisible(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_PESTS);
    },
    showPestSelectSection(): boolean {
      return (
        this.featurePestsEnabled &&
        this.featurePestsVisible &&
        isHerbicide(this.selectedProduct, this.productCategories)
      );
    },
  },
  watch: {
    selectedProduct: {
      handler(newProduct: Product) {
        if (isHerbicide(newProduct, this.productCategories)) {
          const payload: ActionPayloads['loadByIds'] = [newProduct.id];
          this.$store.dispatch('pesticideIndications/loadByIds', payload);
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateSelection(pestId: string, pesticideIndicationId: string): void {
      this.$store.dispatch(`activities/createEditActivity/updatePestOnActivityProductInAllActivities`, {
        updateValues: {
          pestId,
          pesticideIndicationId,
        },
        activityProductId: this.activityProduct.id,
      });
    },
    updateCurrentActivityProductId() {
      this.$store.commit(`activities/createEditActivity/setCurrentActivityProductId`, this.activityProduct.id);
    },
  },
});
