import Handsontable from 'handsontable';

import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';

import ReadOnlyRenderer from './ReadOnlyRenderer';
import RequiredRenderer from './RequiredRenderer';
import setCellTitleAttribute from './helpers/setCellTitleAttribute';

export default function TextRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  if (cellProperties.required && (typeof value !== 'string' || value.length === 0)) {
    RequiredRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);
    return;
  }

  const displayValue =
    typeof cellProperties.getRendererValue === 'function' ? cellProperties.getRendererValue({ value, row }) : value;
  setCellTitleAttribute(TD, displayValue);

  Handsontable.renderers.TextRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);
  }

  applyMergeRowsBorderStyles(TD, row, instance);
}
