import { AxiosRequestConfig } from 'axios';
import { ActionContext } from 'vuex';

import { fetchActivityTypesRough } from '@/shared/api/rest/RestApi';
import { ActivityTypeRough, ApiResponse } from '@/shared/api/rest/models';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<ActivityTypeRough>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ActivityTypeRough[]>> {
  const { data } = await fetchActivityTypesRough(axiosOptions);

  return data;
}

async function fetchByIds(
  ids: string[],
  context: ActionContext<SubscribableDataState<ActivityTypeRough>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ActivityTypeRough[]>> {
  const { data } = await fetchActivityTypesRough(axiosOptions);

  if (data.status === 'success') {
    const activityTypesRough = data.data;
    const filteredActivityTypesRough = activityTypesRough.filter((activityTypeRough) =>
      ids.includes(activityTypeRough.id),
    );
    data.data = filteredActivityTypesRough;
  }

  return data;
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
