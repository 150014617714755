import { render, staticRenderFns } from "./SidebarHeadline.vue?vue&type=template&id=22a88371&scoped=true&"
import script from "./SidebarHeadline.vue?vue&type=script&lang=js&"
export * from "./SidebarHeadline.vue?vue&type=script&lang=js&"
import style0 from "./SidebarHeadline.vue?vue&type=style&index=0&id=22a88371&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a88371",
  null
  
)

export default component.exports