import { ProcessOrder } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

import { Company } from '../store/types';

export default class CompanyService {
  private readonly companies: Data<Company>;

  constructor(companies: Data<Company>) {
    this.companies = companies;
  }

  public getCompanyByProcessOrder(processOrderId: string): Company {
    const company = this.findCompanyByProcessOrder(processOrderId);
    if (!company) throw new Error(`Company with process order id ${processOrderId} not found`);
    return company;
  }

  public findCompanyByProcessOrder(processOrderId: string): Company | undefined {
    return Object.values(this.companies).find((company) =>
      company.processOrders.some((processOrder: ProcessOrder) => processOrder.id === processOrderId),
    );
  }
}
