
import { PropType, defineComponent } from 'vue';

import NutrientAmountSelectionContainer from '@/precision-farming/application-maps/containers/NutrientAmountSelectionContainer.vue';
import ProductsDropdownContainer from '@/precision-farming/application-maps/containers/ProductsDropdownContainer.vue';
import { ProductCategoryType } from '@/shared/api/rest/models';
import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';

export default defineComponent({
  name: 'ProductSelectionContainer',
  components: {
    SidebarSubHeadline,
    SidebarContentWrapper,
    ProductsDropdownContainer,
    NutrientAmountSelectionContainer,
  },
  props: {
    workflowKey: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    const productCategoryType: ProductCategoryType = ProductCategoryType.MINERAL_FERTILIZER;
    const mineralFertilizerDropdownTooltipTitle: string =
      this.$t(
        'Durch das Produkt werden die Inhaltsstoffe (N, P₂O₅, K₂O) definiert. Inhaltsstoffe in Prozent und Menge in kg/ha können aber auch manuell eingegeben werden.',
      ) ||
      'Durch das Produkt werden die Inhaltsstoffe (N, P₂O₅, K₂O) definiert. Inhaltsstoffe in Prozent und Menge in kg/ha können aber auch manuell eingegeben werden.';
    return { productCategoryType, mineralFertilizerDropdownTooltipTitle };
  },
});
