import type { DaturaImportJob, DaturaImportJobRequest } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionTree } from 'vuex';

import type { Api } from '@/plugins/farmdokRestApi';
import { RootState } from '@/store/types';

import { DaturaState } from './types';

const actions: ActionTree<DaturaState, RootState> = {
  async import(context, request: DaturaImportJobRequest): Promise<DaturaImportJob> {
    const { daturaApi } = Vue.prototype.$api as Api;
    return daturaApi
      .daturaImportJobStart({
        daturaImportJobStartRequest: {
          data: request,
        },
      })
      .then((response) => response.data.data!);
  },
};

export default actions;
