import { RowPropChange } from '../types';
import differenceObjectByKeys from './differenceObjectByKeys';

export default function differenceArray(oldData: Object[], newData: Object[], compareKeys: string[]): RowPropChange[] {
  const changes: RowPropChange[] = [];

  if (oldData.length !== newData.length) {
    throw new Error('Cannot detect changes between two arrays of different length');
  }

  oldData.forEach((oldObject, index) => {
    const newObject = newData[index];

    if (oldObject === newObject) return; // shallow comparison
    const differences = differenceObjectByKeys(oldObject, newObject, compareKeys);

    Object.entries(differences).forEach(([key, value]) => {
      changes.push([index, key, value]);
    });
  });

  return changes;
}
