import Handsontable from 'handsontable';

import LoadingRenderer from '../../../renderers/LoadingRenderer';
import EmptyRenderer from '../emptyRenderer/EmptyRenderer';
import { ColumnSettingsOptional } from './types';

export default function OptionalRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: unknown,
  cellProperties: ColumnSettingsOptional,
) {
  if (shouldRenderNone(visualRow, instance, cellProperties)) {
    Handsontable.dom.empty(TD);
    // eslint-disable-next-line no-param-reassign
    TD.style.display = 'none';
    return;
  }

  if (shouldRenderEmpty(visualRow, instance, cellProperties)) {
    EmptyRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties as any);
    return;
  }

  if (shouldRenderLoading(visualRow, value, instance, cellProperties)) {
    LoadingRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties as any);
    return;
  }

  if (typeof cellProperties.type !== 'string') {
    throw new Error('cellProperties.type is not a string');
  }

  const Renderer = Handsontable.renderers.getRenderer(cellProperties.type);
  Renderer(instance, TD, visualRow, visualCol, prop, value, cellProperties as any);
}

/* -- Conditions -- */

function shouldRenderNone(visualRow: number, instance: Handsontable, cellProperties: ColumnSettingsOptional): boolean {
  return typeof cellProperties.renderNone === 'function' && cellProperties.renderNone(visualRow, instance);
}

function shouldRenderEmpty(visualRow: number, instance: Handsontable, cellProperties: ColumnSettingsOptional): boolean {
  return typeof cellProperties.renderEmpty === 'function' && cellProperties.renderEmpty(visualRow, instance);
}

function shouldRenderLoading(
  visualRow: number,
  value: unknown,
  instance: Handsontable,
  cellProperties: ColumnSettingsOptional,
): boolean {
  return typeof cellProperties.renderLoading === 'function' && cellProperties.renderLoading(visualRow, value, instance);
}
