
import { library } from '@fortawesome/fontawesome-svg-core';
import { faObjectGroup, faObjectUngroup } from '@fortawesome/pro-regular-svg-icons';
import { Activity } from 'farmdok-rest-api';
import moment from 'moment';
import numbro from 'numbro';
import { PropType, defineComponent } from 'vue';

import { Getters as ProductGetters } from '@/products/store/getters';
import { ActivityType, Field, Unit } from '@/shared/api/rest/models';
import Table from '@/shared/components/Table.vue';
import Widget from '@/shared/components/Widget.vue';
import ButtonBorderedIconOptions from '@/shared/components/buttons/ButtonBorderedIconOptions.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import { ACTIVITY_ROUGH_GUIDS, UNIT_IDS } from '@/shared/constants';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

library.add(faObjectGroup, faObjectUngroup);

type TablaDataActivityWidgetActivityProduct = {
  id: string;
  name: string;
  amount: string;
  unitName: string;
  amountPerHa: string;
  unitNamePerHa: string;
  amountTotal: string;
  unitNameTotal: string;
};

type TableDataActivityWidget = {
  date: string;
  activityType: ActivityType;
  activityTypeName: string;
  processedArea: string;
  products: TablaDataActivityWidgetActivityProduct[];
  userComment: string;
};

type ActivityTypeGroup = {
  label?: string;
  roughIds: string[] | null;
};

type TableDataActivityWidgetGrouped = ActivityTypeGroup & {
  items: TableDataActivityWidget[];
};

type TableMode = { value: 'loose' | 'grouped'; icon: string[]; title?: string };

type TableField = {
  key: string;
  label?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  tdClass?: string;
};

export default defineComponent({
  name: 'WidgetActivities',
  components: {
    ButtonBorderedIconOptions,
    ButtonLink,
    Widget,
    Table,
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    selectedFields: {
      type: Array as PropType<Field[]>,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    activities: {
      type: Object as PropType<Record<string, Activity>>,
      default: () => ({}),
    },
    activityTypes: {
      type: Object as PropType<Record<string, ActivityType>>,
      default: () => ({}),
    },
    units: {
      type: Object as PropType<Record<string, Unit>>,
      default: () => ({}),
    },
    activitiesFetching: {
      type: Boolean,
      default: false,
    },
    findProductStorageDropdownItem: {
      type: Function as PropType<ProductGetters['dropdownItem']>,
      required: true,
    },
  },
  data() {
    const mode = 'loose';
    const modes: TableMode[] = [
      {
        value: 'loose',
        icon: ['far', 'object-ungroup'],
        title: this.$t('Chronologisch'),
      },
      {
        value: 'grouped',
        icon: ['far', 'object-group'],
        title: this.$t('Gruppiert'),
      },
    ];
    const activityTypeGroups: ActivityTypeGroup[] = [
      {
        label: this.$t('Ernte'),
        roughIds: [ACTIVITY_ROUGH_GUIDS.HARVEST],
      },
      {
        label: this.$t('Pflanzenschutz'),
        roughIds: [ACTIVITY_ROUGH_GUIDS.PROTECT],
      },
      {
        label: this.$t('Düngung'),
        roughIds: [
          ACTIVITY_ROUGH_GUIDS.FEMI,
          ACTIVITY_ROUGH_GUIDS.FECO_SLURRY,
          ACTIVITY_ROUGH_GUIDS.FECO_MANURE,
          ACTIVITY_ROUGH_GUIDS.FECO_LOW_LOSS,
          ACTIVITY_ROUGH_GUIDS.FECO,
        ],
      },
      {
        label: this.$t('Aussaat'),
        roughIds: [ACTIVITY_ROUGH_GUIDS.SEED_MAIN, ACTIVITY_ROUGH_GUIDS.SEED_CATCH_CROP],
      },
      {
        label: this.$t('Andere Tätigkeiten'),
        roughIds: null,
      },
    ];

    return {
      mode,
      modes,
      activityTypeGroups,
    };
  },
  computed: {
    tableFields(): TableField[] {
      return [
        {
          key: 'date',
          label: this.$t('Datum'),
          width: '85px',
        },
        {
          key: 'activityTypeName',
          label: this.$t('Tätigkeit'),
          width: '15%',
          minWidth: '150px',
        },
        {
          key: 'processedArea',
          label: this.$t('Bearbeitete Fläche'),
          width: '10%',
          tdClass: 'text-right',
        },
        {
          key: 'products',
          label: this.$t('Betriebsmittel'),
          width: '48%',
          minWidth: '320px',
          tdClass: 'p-0',
        },
        {
          key: 'userComment',
          label: this.$t('Bemerkung'),
          minWidth: '120px',
        },
      ];
    },
    tableProductsFields(): TableField[] {
      return [
        {
          key: 'name',
          width: '50%',
          tdClass: 'text-truncate',
        },
        {
          key: 'amountPerHa',
          width: '15%',
          maxWidth: '80px',
          tdClass: 'pr-1 text-right text-nowrap',
        },
        {
          key: 'unitNamePerHa',
          width: '10%',
          maxWidth: '80px',
          tdClass: 'pl-1',
        },
        {
          key: 'amountTotal',
          width: '15%',
          maxWidth: '80px',
          tdClass: 'pr-1 text-right text-nowrap',
        },
        {
          key: 'unitNameTotal',
          width: '10%',
          maxWidth: '80px',
          tdClass: 'pl-1',
        },
      ];
    },
    tableFieldsCondensed(): TableField[] {
      return [
        {
          key: 'date',
          label: this.$t('Datum'),
          width: '85px',
          tdClass: 'text-truncate',
        },
        {
          key: 'activityTypeName',
          label: this.$t('Tätigkeit'),
          width: '110px',
          tdClass: 'pr-1 text-truncate',
        },
        {
          key: 'products',
          label: this.$t('Betriebsmittel'),
          tdClass: 'p-0 text-truncate',
        },
      ];
    },
    tableProductsFieldsCondensed() {
      return [
        {
          key: 'name',
          tdClass: 'pr-1 text-truncate',
        },
        {
          key: 'amount',
          width: '65px',
          tdClass: 'pr-1 text-right text-truncate',
        },
        {
          key: 'unitName',
          width: '60px',
          tdClass: 'pl-1 text-truncate',
        },
      ];
    },
    tableItems(): TableDataActivityWidget[] {
      return Object.values(this.activities)
        .filter((activity) => this.selectedFields.some((field) => activity.fieldId === field.id))
        .sort((a, b) => {
          if (!b.timeStart) return -1;
          if (!a.timeStart) return 1;

          return b.timeStart - a.timeStart;
        })
        .map((activity) => {
          const activityType = this.activityTypes[activity.activityTypeId];
          if (!activityType) return undefined;
          const activityTypeName = activityType.displayName;
          if (!activityTypeName) return undefined;

          let processedArea = numbro().format();
          if (typeof activity.processedArea === 'number') {
            processedArea = numbro(activity.processedArea).format();
          }

          const activityProducts: TablaDataActivityWidgetActivityProduct[] = activity.products
            .map((activityProduct) => {
              if (!activityProduct.unitId) return null;
              const unit = this.units[activityProduct.unitId];
              if (!unit) throw new Error(`Couldn't find unit: ${activityProduct.unitId}`);

              const { amountPerHa, unitNamePerHa, amountTotal, unitNameTotal } = this.resolveTotalAndPerHa({
                amount: activityProduct.amount ?? 0,
                unit,
                area: activity.processedArea ?? 0,
              });
              let amount = 0;
              if (typeof activityProduct.amount === 'number') {
                ({ amount } = activityProduct);
              }

              const productStorageDropdownItem = this.findProductStorageDropdownItem(
                activityProduct.productId,
                activityProduct.storagePlaceId,
              );
              if (!productStorageDropdownItem || !productStorageDropdownItem.id) return null;

              return {
                id: productStorageDropdownItem.id,
                name: productStorageDropdownItem.name,
                amount: numbro(amount).format(),
                unitName: unit.name,
                amountPerHa: numbro(amountPerHa).format(),
                unitNamePerHa,
                amountTotal: numbro(amountTotal).format(),
                unitNameTotal,
              };
            })
            .filter(notNullOrUndefined);

          return {
            date: moment((activity.timeStart ?? 0) * 1000).format('L'),
            activityType,
            activityTypeName,
            processedArea: `${processedArea} ha`,
            products: activityProducts,
            userComment: activity.userComment ?? '',
          };
        })
        .filter(notNullOrUndefined);
    },
    tableItemsGrouped(): TableDataActivityWidgetGrouped[] {
      const activityByRoughId = this.tableItems.reduce((tableItemsGroupedAcc, activity) => {
        if (activity.activityType == null || activity.activityType.roughId == null) {
          return tableItemsGroupedAcc;
        }
        const response: Record<string, TableDataActivityWidget[]> = { ...tableItemsGroupedAcc };
        if (response[activity.activityType.roughId] == null) {
          response[activity.activityType.roughId] = [];
        }
        return {
          ...response,
          [activity.activityType.roughId]: [...response[activity.activityType.roughId], activity],
        };
      }, {} as Record<string, TableDataActivityWidget[]>);

      const roughIds = this.activityTypeGroups.reduce((total, group) => {
        if (!group.roughIds) return total;

        return [...total, ...group.roughIds];
      }, [] as string[]);

      return this.activityTypeGroups
        .map((group) => {
          let activities: TableDataActivityWidget[] = [];
          if (Array.isArray(group.roughIds)) {
            group.roughIds.forEach((roughId) => {
              if (activityByRoughId[roughId] == null) {
                return;
              }
              activities = [...activities, ...activityByRoughId[roughId]];
            });
          } else {
            Object.keys(activityByRoughId).forEach((roughId) => {
              if (roughIds.includes(roughId)) {
                return;
              }
              activities = [...activities, ...activityByRoughId[roughId]];
            });
          }
          return {
            ...group,
            items: activities,
          };
        })
        .filter((group) => group.items.length > 0);
    },
  },
  methods: {
    perHaToTotal({ amount, unit, area }: { amount: number; unit: Unit; area: number }) {
      let amountTotal = 0;
      let unitNameTotal = '';
      const unitTotal = unit.numeratorUnitId ? this.units[unit.numeratorUnitId] : null;
      if (unitTotal != null && area > 0) {
        amountTotal = amount * area;
        unitNameTotal = unitTotal.name;
      }
      return {
        amountTotal,
        unitNameTotal,
      };
    },
    totalToPerHa({ amount, unit, area }: { amount: number; unit: Unit; area: number }) {
      let amountPerHa = 0;
      let unitNamePerHa = '';
      const unitTotal = Object.values(this.units).find(
        (currentUnit) => currentUnit.numeratorUnitId === unit.id && currentUnit.byUnitId === UNIT_IDS.HA,
      );
      if (unitTotal != null && area > 0) {
        amountPerHa = amount / area;
        unitNamePerHa = unitTotal.name;
      }
      return {
        amountPerHa,
        unitNamePerHa,
      };
    },
    resolveTotalAndPerHa({ amount, unit, area }: { amount: number; unit: Unit; area: number }) {
      let amountPerHa = 0;
      let unitNamePerHa = '';
      let amountTotal = 0;
      let unitNameTotal = '';
      if (unit.byUnitId === UNIT_IDS.HA) {
        amountPerHa = amount;
        unitNamePerHa = unit.name;
        ({ amountTotal, unitNameTotal } = this.perHaToTotal({ amount, unit, area }));
      } else {
        amountTotal = amount;
        unitNameTotal = unit.name;
        ({ amountPerHa, unitNamePerHa } = this.totalToPerHa({ amount, unit, area }));
      }

      return {
        amountPerHa,
        unitNamePerHa,
        amountTotal,
        unitNameTotal,
      };
    },
  },
});
