import Handsontable from 'handsontable';

import { ENTRY_DIRTY, ENTRY_ERROR_UPDATING, ENTRY_UPDATING } from '@/shared/constants';

function addSyncStatusBackgroundFactory(hot: Handsontable | null) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function addSyncStatusBackground(td: HTMLTableCellElement, visualRow: number, visualCol: number) {
    if (!hot) return;

    const storeStatus = hot.getDataAtRowProp(visualRow, 'storeStatus');

    switch (storeStatus) {
      case ENTRY_UPDATING:
        td.classList.add('updating');
        return;
      case ENTRY_DIRTY:
        td.classList.add('dirty');
        return;
      case ENTRY_ERROR_UPDATING:
        td.classList.add('error');
        // eslint-disable-next-line no-useless-return
        return;
      default:
      // do nothing
    }
  };
}

const hooks = {
  afterRenderer: { addSyncStatusBackgroundFactory },
};

export default hooks;
