import i18n from '@/initI18n';

import { getRestResponseData } from '@/shared/modules/restApiHelpers';

export default function useActivitiesImports() {
  const $t = i18n.translate;

  const defaultUserErrorMessage = $t('Es ist ein unbekannter Fehler aufgetreten.');

  /* -- Methods -- */
  /**
   * Extract first error message returned by REST backend: https://git.farmdok.com/farmdok/AgriDat/-/wikis/REST/Response-standard
   *
   * If the error messages contains one of these keys it will be translated:
   * https://git.farmdok.com/farmdok/jwt/-/blob/master/README.md#error-codes
   *
   * Response can also be an error thrown by axios. Then the response (and errorMessage) will be extracted from the error object.
   *
   * Then sets userErrorMessage.
   *
   * @param {object} error
   * @returns void
   */
  function getUserErrorMessageFromResponse(error: any) {
    const responseData = getRestResponseData(error, { errorUserMessage: [defaultUserErrorMessage] });
    if (responseData.status === 'success') return null;
    if (responseData.errorCode) return translateErrorCode(responseData.errorCode);
    return responseData.errorUserMessage;
  }

  function getUserErrorMessageFromPartialSuccess(errors: any) {
    return Object.values(errors).reduce((messages: any[], error: any) => {
      if (!error.errorUserMessage) return messages;
      if (Array.isArray(error.errorUserMessage)) {
        return [...messages, ...error.errorUserMessage];
      }
      return [...messages, error.errorUserMessage];
    }, []);
  }

  function translateErrorCode(errorCode: string) {
    if (errorCode === 'invalidLoginRequestInvalidEmail') {
      return $t('Die E-Mail-Adresse ist ungültig.');
    }
    if (errorCode === 'invalidLoginRequestInvalidCredentials') {
      return [
        $t('Benutzername oder Passwort ungültig.'),
        $t(
          'Bitte achte darauf, die gleiche E-Mail-Adresse wie in der FARMDOK App am Smartphone oder Tablet zu verwenden. Du findest die E-Mail-Adresse in der FARMDOK App im Menüpunkt "Mehr".',
        ),
      ];
    }
    return '';
  }

  return {
    getUserErrorMessageFromResponse,
    getUserErrorMessageFromPartialSuccess,
  };
}
