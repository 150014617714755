
import { StockLevel, StoragePlace } from 'farmdok-rest-api';
import numbro from 'numbro';
import { PropType, defineComponent } from 'vue';

import { Unit } from '@/shared/api/rest/models';
import Button from '@/shared/components/buttons/Button.vue';

import { StorageSelection } from './types';

export default defineComponent({
  name: 'StorageSelection',
  components: { Button },
  props: {
    storageSelection: {
      type: Object as PropType<StorageSelection>,
      required: true,
    },
  },
  data() {
    return {
      applyButtonClicked: false,
    };
  },
  computed: {
    stockLevel(): StockLevel {
      return this.storageSelection.stockLevel;
    },
    storagePlace(): StoragePlace | null {
      return this.storageSelection.storagePlace;
    },
    unit(): Unit {
      return this.storageSelection.unit;
    },
    leftOnStorageDescription(): string {
      const { name } = this.storagePlace ?? { name: null };

      if (name === null) {
        return `${this.$t('Übrig am Betrieb (kein spezifisches Lager)')}: ${this.amountFormatted} ${this.unit.name}`;
      }

      return `${this.$t('Übrig in')} ${name}: ${this.amountFormatted} ${this.unit.name}`;
    },
    amountFormatted(): string {
      const amount = this.applyButtonClicked ? 0 : this.stockLevel.amount;
      return numbro(amount).format({ mantissa: this.unit.ddiDecimals });
    },
    hasValidAmount(): boolean {
      return this.stockLevel.amount > 0;
    },
    applyButtonEnabled(): boolean {
      return this.hasValidAmount && !this.applyButtonClicked;
    },
  },
  methods: {
    onApplyClick() {
      if (!this.stockLevel || !this.applyButtonEnabled) return;
      this.$emit('applyStockLevel', this.stockLevel);
      this.applyButtonClicked = true;
    },
  },
});
