import Vue from 'vue';
import VueSanitize from 'vue-sanitize';

const VueSanitizeOptions = {
  ...VueSanitize.defaults,
  allowedTags: [...VueSanitize.defaults.allowedTags, 'img'],
  transformTags: {
    a: (tagName, attribs) => ({
      tagName,
      attribs: {
        ...attribs,
        target: '_blank',
      },
    }),
  },
};

export default VueSanitizeOptions;

Vue.use(VueSanitize, VueSanitizeOptions);
