import { Module } from 'vuex';

import gpsLog from '@/activities/gpsLog/store';
import gpsTrack from '@/activities/gpsTrack/store';
import createEditActivity from '@/activities/modals/createEditActivity/store';
import initialStateRemovable from '@/shared/mixins/store/removableData/initialState';
import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import initialStateSyncable from '@/shared/mixins/store/syncableData/initialState';
import { RootState } from '@/store/types';

import importActivities from '../importActivities/store';
import moveActivity from '../modals/moveActivity/store';
import ruleCheck from '../ruleCheck/store';
import sharedActivities from '../sharedActivities/store';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { ActivitiesState, ActivitiesStateWithoutModules } from './types';

export function initialState(): ActivitiesStateWithoutModules {
  return {
    ...initialStateSubscribable(),
    ...initialStateSyncable(),
    ...initialStateRemovable(),
    tableDataMetadata: {},
    hot: null,
    userColumnSettings: {},
  };
}

const vuexModule: Module<ActivitiesState, RootState> = {
  namespaced: true,
  state: initialState() as ActivitiesState,
  mutations,
  actions,
  getters,
  modules: { createEditActivity, moveActivity, ruleCheck, sharedActivities, importActivities, gpsTrack, gpsLog },
};

export default vuexModule;
