import { ActionTree } from 'vuex';

import { ENTRY_TO_REMOVE } from '@/shared/constants';
import { RemoveEntries } from '@/shared/mixins/store/removableData/types';
import { DataEntry, RemovableDataState, ResponseData } from '@/shared/mixins/store/types';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';
import { RootState } from '@/store/types';

function actions<Entry extends DataEntry>(
  removeEntries: RemoveEntries<Entry>,
): ActionTree<RemovableDataState<Entry>, RootState> {
  return {
    /**
     * Updates the entry storeStatus and then sends delete request to BE.
     *
     * @param commit
     * @param dispatch
     * @param entry
     * @return {Promise<void>}
     */
    async removeAndSyncEntry({ commit, dispatch }, { entry }) {
      commit('removeEntry', { entry });
      await dispatch('syncAllRemovals');
    },

    /**
     * Send delete request to BE for all objects.
     */
    async syncAllRemovals(context) {
      const { state, commit, dispatch } = context;
      if (state.removing) {
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((callback) => commit('addRemovableObserver', callback));
        return;
      }
      const entries = Object.values(state.data).filter((entry) => entry.storeStatus === ENTRY_TO_REMOVE);
      if (entries.length < 1) {
        commit('clearRemovableObservers');
        return;
      }
      commit('syncAllRemovesStart', entries);
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });

      let responseData: ResponseData<Entry> | null;
      try {
        const response = await removeEntries(entries, context);
        responseData = getRestResponseData(response);
      } catch (error) {
        responseData = getRestResponseData(error);
      }

      if (responseData?.status === 'success') {
        commit('syncAllRemovesFinish', responseData.data);
        await dispatch('syncAllRemovals');
      } else if (responseData?.status === 'partialSuccess') {
        dispatch('addRemoveErrors', { entries, responseData });
        commit(
          'syncAllRemovesFinish',
          // @ts-ignore
          responseData.data?.filter((entry) => responseData?.errors?.[entry.id] == null),
        );
        await dispatch('syncAllRemovals');
      } else {
        dispatch('addRemoveErrors', { entries, responseData });
        commit('clearRemovableObservers');
        commit('syncAllRemovesFinish');
      }
    },

    /**
     * Adds dummy error objects for every entry that was to be removed if the whole request failed.
     * In case of a 'partialSuccess' errors will be added for every entry that failed.
     *
     * @param commit
     * @param entries
     * @param responseData
     */
    addRemoveErrors({ commit }, { entries, responseData }: { entries: Entry[]; responseData: ResponseData<Entry> }) {
      if (responseData.status !== 'partialSuccess') {
        entries.forEach((entry) => {
          commit('addRemovalError', {
            guid: entry.id,
            key: null,
            errorUserMessage: responseData.errorUserMessage[0],
          });
        });
        return;
      }
      Object.keys(responseData.errors || {}).forEach((guid) => {
        // @ts-ignore
        const error = getRestResponseData(responseData.errors[guid]);
        commit('addRemovalError', {
          guid,
          key: null,
          errorUserMessage: error.errorUserMessage[0],
        });
      });
    },
  };
}

export default actions;
