import { GetterTree } from 'vuex';

import { Company } from '@/auth/store/types';
import { ProductCategoryType, Unit } from '@/shared/api/rest/models';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { filterByUnitGroupId, filterByUsefulList, filterNotPerAreaUnit } from './compatibleUnitsFilter';
import { UnitsState } from './types';

export type Getters = {
  allUnits: Unit[];
  compatibleUnits: (groupId: string, companies: Company[], productCategoryType: string, regionGuid?: string) => Unit[];
};

const moduleGetters: GetterTree<UnitsState, RootState> = {
  ...subscribableStore.getters,
  allUnits: (state): Unit[] => Object.values(state.data),
  compatibleUnits:
    (state: UnitsState, getters: Getters) =>
    (groupId: string, companies: Company[], productCategoryType: ProductCategoryType): Unit[] => {
      const compatibleUnits = getters.allUnits
        .filter((unit) => filterByUnitGroupId(unit, groupId))
        .filter((unit) => filterByUsefulList(unit, productCategoryType, companies))
        .filter((unit) => filterNotPerAreaUnit(unit));

      return compatibleUnits;
    },
};

export default moduleGetters;
