
import _debounce from 'lodash.debounce';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

// @ts-ignore
import TerrazoLogo from '@/assets/images/Terrazo_green_black.svg';
import SidebarExport from '@/precision-farming/application-maps/fertilizationCereals/components/sidebarExport/SidebarExport.vue';
import { WorkflowKey } from '@/precision-farming/application-maps/types';

import PageApplicationMaps from '../PageApplicationMaps.vue';
import HeaderLogo from '../components/HeaderLogo.vue';
import SidebarCreateApplicationMap from './components/sidebarApplicationMap/SidebarCreateApplicationMap.vue';
import SidebarFieldsContainer from './containers/SidebarFieldsContainer.vue';
import SidebarZonesContainer from './containers/sidebarZones/SidebarZonesContainer.vue';
import buildPayloadForLoadFeaturesAndZones from './store/actionUtils/loadFeaturesAndZones/buildPayload';
import { ActionPayloads } from './store/actions';
import { VisualizationMode } from './store/types';

export default defineComponent({
  name: 'PageApplicationMapsFertilizationCereals',
  components: {
    PageApplicationMaps,
    SidebarFieldsContainer,
    HeaderLogo,
    SidebarZonesContainer,
    SidebarCreateApplicationMap,
    SidebarExport,
  },
  data() {
    return {
      workflowKey: WorkflowKey.FERTILIZATION_CEREALS,
      headerLogo: {
        src: TerrazoLogo,
        alt: 'Terrazo Logo',
      },
    };
  },
  created() {
    // @ts-ignore
    this.unwatch = this.$watch(
      'loadFeaturesAndZonesTrigger',
      _debounce(this.dispatchLoadFeaturesAndZonesIfPayloadAvailable, 500),
      { immediate: true },
    );
  },
  beforeDestroy() {
    // @ts-ignore
    this.unwatch();
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilizationCereals', ['paginationStep', 'zoneMap', 'application']),
    ...mapGetters('precisionFarming/applicationMaps/fertilizationCereals', ['selectedField', 'dbId']),
    loadFeaturesAndZonesPayload(): ActionPayloads['loadFeaturesAndZones'] | null {
      if (!this.selectedField || !this.dbId || this.application.error) return null;
      return buildPayloadForLoadFeaturesAndZones(this.selectedField, this.dbId, this.zoneMap, this.application);
    },
    loadFeaturesAndZonesTrigger(): string {
      return JSON.stringify(this.loadFeaturesAndZonesPayload);
    },
  },
  methods: {
    onNavigationNext(callback: Function) {
      switch (this.paginationStep) {
        case 1: {
          this.$store.commit(
            'precisionFarming/applicationMaps/fertilizationCereals/setVisualizationMode',
            VisualizationMode.SATELLITE_IMAGE,
          );
          break;
        }
        case 2: {
          this.$store.commit(
            'precisionFarming/applicationMaps/fertilizationCereals/setVisualizationMode',
            VisualizationMode.ZONES,
          );
          break;
        }
        default: {
          // do nothing
        }
      }
      callback();
    },
    dispatchLoadFeaturesAndZonesIfPayloadAvailable() {
      if (this.loadFeaturesAndZonesPayload) {
        this.$store.dispatch(
          'precisionFarming/applicationMaps/fertilizationCereals/loadFeaturesAndZones',
          this.loadFeaturesAndZonesPayload,
        );
      }
    },
  },
});
