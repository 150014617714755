
import { Activity } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import { ActivitiesState, ActivityDuplication } from '@/activities/store/types';
import { ActivityWithDisplayNames } from '@/activities/types';
import { convertToActivitiesWithDisplayNames } from '@/activities/utils/convertToActivityWithDisplayName';
import Loading from '@/shared/components/Loading.vue';
import { Data } from '@/shared/mixins/store/types';

import ActivityStatusIndicator from '../components/ActivityStatusIndicator.vue';

export default defineComponent({
  name: 'ActivityDuplicationsContainer',
  components: {
    ActivityStatusIndicator,
    Loading,
  },
  props: {
    duplications: {
      type: Array as PropType<ActivityDuplication[]>,
      required: true,
    },
  },
  watch: {
    successfulDuplications: {
      handler() {
        this.selectSuccessfulDuplicates();
      },
      immediate: true,
    },
    failedDuplications: {
      handler() {
        this.deselectFailedDuplicates();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('activities', {
      activities: (state) => (state as ActivitiesState).data as Data<Activity>,
      syncing: (state) => (state as ActivitiesState).syncing,
      errors: (state) => {
        const errors = Object.values((state as ActivitiesState).errors);
        return errors.map((error) => error.guid);
      },
    }),
    ...mapGetters({
      activityTypes: 'activityTypes/data',
      fields: 'fields/data',
      customers: 'customers/data',
      crops: 'products/crops/data',
    }),
    successfulDuplications(): ActivityDuplication[] {
      return this.duplications.filter((duplication) => !this.errors.includes(duplication.duplicateId));
    },
    failedDuplications(): ActivityDuplication[] {
      return this.duplications.filter((duplication) => this.errors.includes(duplication.duplicateId));
    },
    successTitle(): string | undefined {
      if (this.successfulDuplications.length === 1) {
        return this.$t('Maßnahme erfolgreich dupliziert');
      }
      return this.$t('Maßnahmen erfolgreich dupliziert');
    },
    errorsTitle(): string | undefined {
      if (this.failedDuplications.length === 1) {
        return this.$t('Maßnahme konnte nicht dupliziert werden');
      }
      return this.$t('Maßnahmen konnten nicht dupliziert werden');
    },
  },

  methods: {
    getOriginalActivities(duplicationIds: ActivityDuplication[]): ActivityWithDisplayNames[] {
      const duplicates = duplicationIds.map((duplication) => this.activities[duplication.originalId]);
      return Object.values(
        convertToActivitiesWithDisplayNames(
          duplicates.reduce((acc, activity) => ({ ...acc, [activity.id]: activity }), {}),
          () => undefined,
          false,
          {},
          {},
          this.fields,
          this.customers,
          this.crops,
        ),
      );
    },
    selectSuccessfulDuplicates(): void {
      this.successfulDuplications.forEach((duplication) => {
        this.$store.commit('activities/addOrUpdateTableDataMetadataByKeyAndValue', {
          activityId: duplication.duplicateId,
          key: 'select',
          value: true,
        });
      });
    },
    deselectFailedDuplicates(): void {
      this.failedDuplications.forEach((duplication) => {
        this.$store.commit('activities/addOrUpdateTableDataMetadataByKeyAndValue', {
          activityId: duplication.duplicateId,
          key: 'select',
          value: false,
        });
      });
    },
  },
});
