import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';

import {
  contactEmail,
  crops,
  farmNameWithStatus,
  farmNumber,
  fieldCount,
  fieldSize,
  lastUpdate,
  partnerNumber,
  status,
  varieties,
} from './columns';

export default [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...partnerNumber,
    lockedVisibility: true,
    lockedPosition: true,
    sortOrder: 'asc',
  },
  farmNumber,
  farmNameWithStatus,
  fieldCount,
  fieldSize,
  crops,
  varieties,
  lastUpdate,
  status,
  contactEmail,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];
