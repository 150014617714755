import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

export function createUuid() {
  return uuidv4().toUpperCase();
}

export function createUuidv5(name: string, namespace: string) {
  return uuidv5(name, uuidStringToUint8Array(namespace.toUpperCase()));
}

// this function is used to convert a UUID string to a Uint8Array, because the backend generates some non-valid RFC4122 UUIDs,
// which the frontend library rejects as invalid. However when we convert the string to a Uint8Array, the library
// does not validate the used uuid.
function uuidStringToUint8Array(s: string) {
  const byteArray = new Uint8Array(16);

  const string = s.replace(/-/g, '');

  for (let i = 0; i < 16; i += 1) {
    byteArray[i] = parseInt(string.substring(i * 2, i * 2 + 2), 16);
  }

  return byteArray;
}
