<template>
  <TableBase
    ref="table"
    disable-read-only-bg
    read-only
    :id="id"
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @reload="loadData"
  >
    <template #footer>
      <TableFooter
        :total-rows="tableData.length"
        :visible-rows="visibleRows.length"
        :selected-rows="selectedRows.length"
      >
        {{ $t('Summe Größe: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}
      </TableFooter>
    </template>
  </TableBase>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import columnDropdownCompanies from '@/auth/mixins/containers/columnDropdownCompanies';
import columnDropdownCustomers from '@/customers/mixins/containers/columnDropdownCustomers';
import calculateSizeTotal from '@/fields/mixins/calculateSizeTotal';
import { ERROR_UPDATING } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import loadRestTableData from '@/shared/handsontable/mixins/containers/featureLoadRestTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import { companyId, customer } from '../handsontable/columns/columns';
import columnsTableFieldsRestore from '../handsontable/columns/tableFieldGroupsRestore';
import { filter } from '../store';

const dataToBase64 = (data) => (data != null ? btoa(JSON.stringify(data)) : '');

export default {
  name: 'TableFieldGroupsRestoreContainer',
  components: { TableFooter, TableBase },
  props: {
    filterString: {
      type: String,
      default: '',
    },
    selectedRows: {
      type: Array,
      default: null,
    },
  },
  mixins: [
    tableContainerBase,
    loadRestTableData,
    columnDropdownCompanies(companyId.key),
    columnDropdownCustomers({
      [customer.key]: 'customer',
    }),
    calculateSizeTotal,
  ],
  data() {
    return {
      columnDefinition: columnsTableFieldsRestore,
    };
  },
  computed: {
    id() {
      return ['hot-field-groups-restore', ...this.$store.state.auth.currentCompanies.map((company) => company.id)].join(
        '-',
      );
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.commit('fieldGroups/setAddArchived', true);
      const todayMinusNinetyDays = moment().startOf('day').subtract({ days: 90 });
      const filterCurrent = [
        ...filter({ state: this.$store.state.fieldGroups, rootState: this.$store.state }),
        ['deleted', '>=', Math.round(todayMinusNinetyDays / 1000)],
      ];
      this.loadTableData(
        `/admin/rest/fieldGroup?showDeleted=1&disablePagination=1&filter=${dataToBase64(filterCurrent)}`,
      );
    },
    async restore() {
      const selectedRows = [...this.selectedRows];
      try {
        await Promise.all(
          [...new Array(Math.ceil(selectedRows.length / 50)).keys()].map((index) => {
            const start = index * 50;
            const end = (index + 1) * 50;
            const filterCurrent = ['id', 'IN', selectedRows.slice(start, end)];
            return axios.put(`/admin/rest/fieldGroup/undelete/?filter=${dataToBase64(filterCurrent)}`);
          }),
        );
        await this.$store.dispatch('fieldGroups/reset');
        await this.$store.dispatch('fieldGroups/subscribe');
        await this.$store.dispatch('fieldGroups/recentlyRestoredSetByGuids', selectedRows);
      } catch (error) {
        console.error(error);
        this.addErrorsAfterRestore(selectedRows);
        return false;
      }
      return true;
    },
    addErrorsAfterRestore(selectedRows) {
      this.tableErrors = [
        ...this.tableErrors,
        ...selectedRows.map((guid) => ({
          type: ERROR_UPDATING,
          guid,
          key: null,
          errorUserMessage: this.$t('Feldstück konnte nicht wieder hergestellt werden.'),
        })),
      ];
    },
  },
};
</script>

<style scoped></style>
