
import { ActivityProduct, StoragePlace } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import { Product } from '@/shared/api/rest/models';
import PageNavigation from '@/shared/components/PageNavigation.vue';

import PestDetailsContainer from '../containers/PestDetailsContainer.vue';
import StorageSelectSectionContainer from '../containers/StorageSelectSectionContainer.vue';
import UnitSelectionContainer from '../containers/UnitSelectionContainer.vue';

export default defineComponent({
  name: 'ProductDetails',
  components: { PageNavigation, UnitSelectionContainer, PestDetailsContainer, StorageSelectSectionContainer },

  props: {
    activityProducts: {
      type: Array as PropType<ActivityProduct[]>,
      required: true,
    },
    currentActivityProductId: {
      type: String as PropType<string | null>,
      default: null,
    },
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    storagePlaces: {
      type: Array as PropType<StoragePlace[]>,
      required: true,
    },
  },
  computed: {
    pagination(): number {
      if (this.currentActivityProductId === null) return 0;
      const index = this.activityProducts.findIndex(
        (activityProduct) => activityProduct.id === this.currentActivityProductId,
      );
      if (index === -1) return 0;

      return index;
    },
    visibleProduct(): Product | null {
      if (!this.currentActivityProductId) return null;
      const currentActivityProduct: ActivityProduct | undefined = this.activityProducts.find(
        (activityProduct) => activityProduct.id === this.currentActivityProductId,
      );

      if (currentActivityProduct?.productId) {
        return this.products.find((product) => product.id === currentActivityProduct.productId) ?? null;
      }
      return null;
    },
    visibleProductName(): string {
      if (this.visibleProduct) {
        return this.visibleProduct.name;
      }
      return '';
    },
  },
  methods: {
    back(): void {
      const previousIndex = this.pagination - 1;
      if (previousIndex < 0) return;
      this.$emit('update:currentActivityProductId', this.activityProducts[previousIndex].id);
    },
    next(): void {
      const nextIndex = this.pagination + 1;
      if (nextIndex >= this.activityProducts.length) return;
      this.$emit('update:currentActivityProductId', this.activityProducts[nextIndex].id);
    },
  },
});
