import Handsontable from 'handsontable';

import { LutIndex } from './types';

export default function createCellsMeta(rows: number, columnsForExport: Handsontable.ColumnSettings[]): any[][] {
  const cellsMeta: any[][] = []; // cellsMeta[col][row]
  columnsForExport.forEach((column) => {
    const colMeta = [LutIndex.HEADER, LutIndex.SUBHEADER];
    let lutIndex = LutIndex.DEFAULT;
    if (column.type === 'numeric') {
      lutIndex = LutIndex.NUMERIC;
    } else if (typeof column.type === 'string' && ['date', 'datetime'].includes(column.type)) {
      lutIndex = LutIndex.DATETIME;
    }

    for (let i = 0; i < rows; i += 1) {
      colMeta.push(lutIndex);
    }
    cellsMeta.push(colMeta);
  });

  return cellsMeta;
}
