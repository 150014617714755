import Handsontable from 'handsontable';

import { TableDataBase } from '../../types';
import exportBuildJson from './exportBuildJson';
import filteredColumns from './filteredColumns';

export default function exportXls(
  ids: string[],
  columns: Handsontable.ColumnSettings[],
  tableData: TableDataBase[],
  hot: Handsontable,
  subtableColumnData?: string,
) {
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'data';
  input.value = exportBuildJson(ids, filteredColumns(columns, hot), tableData, hot, subtableColumnData);
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = `${process.env.VUE_APP_ORIGIN}/admin/reports/generate/xls`;
  form.target = '_blank';
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export { default as filteredColumns } from './filteredColumns';
