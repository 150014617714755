import numbro from 'numbro';
// @ts-ignore
import numbroLanguages from 'numbro/dist/languages.min';
import Vue from 'vue';

Object.values(numbroLanguages).forEach((language: any) => {
  if (language.languageTag.includes('de-')) {
    numbro.registerLanguage({ ...language, delimiters: { thousands: '.', decimal: ',' } });
    return;
  }
  numbro.registerLanguage(language);
});
const numbroDefaults = {
  thousandSeparated: true,
  mantissa: 2,
};
numbro.setDefaults(numbroDefaults);

Vue.filter('numbro', (value: any, options: any) => {
  if (Number.isNaN(value)) {
    return null;
  }
  let numbroOptions = { ...numbroDefaults };
  if (typeof options === 'number') {
    numbroOptions.mantissa = options;
  }
  if (typeof options === 'object') {
    numbroOptions = {
      ...numbroDefaults,
      ...options,
    };
  }
  return numbro(Number(value)).format(numbroOptions);
});

export default numbro;
