import { Company } from '@/auth/store/types';
import { ProductCategoryType, Unit } from '@/shared/api/rest/models';

import materialUnitsJson from './materialUnits.json';

type MaterialUnitsType = Record<string, Record<string, string[]>>;

export function filterByUnitGroupId(unit: Unit, groupId: string): boolean {
  return unit.groupId === groupId;
}

export function filterNotPerAreaUnit(unit: Unit): boolean {
  return unit.byUnitId !== null && !unit.isPerLoad;
}

export function filterByUsefulList(
  unit: Unit,
  productCategoryType: ProductCategoryType,
  companies: Company[],
): boolean {
  const materialUnits: MaterialUnitsType = materialUnitsJson;
  const listOfValidUnits = companies.map((company) => materialUnits[company.regionId][productCategoryType]);
  return listOfValidUnits.flat().includes(unit.id);
}
