<template>
  <div
    class="navbar-item"
    :class="[{ 'navbar-item--has-submenu': hasSubmenu }, { 'navbar-item--submenu-open': submenuOpen }]"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <RouterLink
      v-if="to != null"
      class="navbar-link"
      :disabled="disabled"
      :event="!disabled ? 'click' : ''"
      :class="{ 'navbar-link--expanded': expanded }"
      :to="to"
      :data-to="typeof to === 'string' ? to : to.name"
      @click.native="(event) => linkClick(event, hasSubmenu)"
      @focus.native="onFocus"
      @blur.native="onBlur"
    >
      <FontAwesomeIcon :icon="icon" />
      <span class="navbar-link__text" :class="{ 'navbar-link__text--expanded': expanded }">{{ labelComputed }}</span>
      <FontAwesomeIcon v-if="hasSubmenu" icon="chevron-right" />
    </RouterLink>
    <a
      v-else
      class="navbar-link navbar-link--no-link"
      :class="{ 'navbar-link--expanded': expanded }"
      tabindex="0"
      @click="(event) => linkClick(event, hasSubmenu)"
      @focus="onFocus"
      @blur="onBlur"
    >
      <FontAwesomeIcon :icon="icon" />
      <span class="navbar-link__text" :class="{ 'navbar-link__text--expanded': expanded }">{{ labelComputed }}</span>
      <FontAwesomeIcon v-if="hasSubmenu" icon="chevron-right" />
    </a>
    <div v-if="hasSubmenu" class="sub-menu">
      <div v-for="(section, index) in submenuComputed" class="sub-menu__inner bg-black" :key="index">
        <h4 class="sub-menu__headline">{{ section.headline }}</h4>
        <RouterLink
          v-for="(link, index) in section.links"
          class="sub-menu__link text-white"
          :key="index"
          :to="link.to"
          :data-to="typeof link.to === 'string' ? link.to : link.to.name"
          @click.native="linkClick"
          @focus.native="onFocus"
          @blur.native="onBlur"
        >
          {{ link.label }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { getFeatureSettingsForRoute } from '@/initRouter';

export default {
  name: 'NavbarItem',
  components: {},
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    icon: {
      type: [String, Array],
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    submenu: {
      type: Array,
      default() {
        return [];
      },
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submenuOpen: false,
      closeSubmenuTimeout: null,
    };
  },
  computed: {
    labelComputed() {
      if (this.label != null) {
        return this.label;
      }
      if (typeof this.$router.resolve(this.to).route.meta.label === 'function') {
        return this.$router.resolve(this.to).route.meta.label(this.$router.resolve(this.to).route);
      }
      return this.$router.resolve(this.to).route.meta.label;
    },
    submenuComputed() {
      return this.submenu.reduce((accumulator, section) => {
        const visibleLinks = section.links
          .filter((link) => getFeatureSettingsForRoute(this.$router.resolve(link.to).route).visible === true)
          .map((link) => ({
            ...link,
            label: (() => {
              if (link.label != null) {
                return link.label;
              }
              if (typeof this.$router.resolve(link.to).route.meta.label === 'function') {
                return this.$router.resolve(link.to).route.meta.label(this.$router.resolve(link.to).route);
              }
              return this.$router.resolve(link.to).route.meta.label;
            })(),
          }));
        if (visibleLinks.length < 1) {
          return [...accumulator];
        }
        return [
          ...accumulator,
          {
            headline: section.headline,
            links: visibleLinks,
          },
        ];
      }, []);
    },
    hasSubmenu() {
      return this.submenuComputed.length > 0;
    },
  },
  mounted() {
    this.$root.$on('navbar::closeAllSubmenus', this.closeSubmenu);
  },
  methods: {
    linkClick(event, handleSubmenu = false) {
      if (event != null && event.currentTarget != null) {
        event.currentTarget.blur();
      }
      if (!handleSubmenu) {
        this.$emit('navigationLinkClicked');
        return;
      }
      if (!this.submenuOpen) {
        this.openSubmenu();
        return;
      }
      this.closeSubmenu();
    },
    openSubmenu() {
      if (this.closeSubmenuTimeout != null) {
        clearTimeout(this.closeSubmenuTimeout);
      }
      this.submenuOpen = true;
    },
    closeSubmenu() {
      if (this.closeSubmenuTimeout != null) {
        clearTimeout(this.closeSubmenuTimeout);
      }
      this.submenuOpen = false;
    },
    onMouseEnter() {
      if (this.isMobile()) {
        return;
      }
      this.openSubmenu();
    },
    async onMouseLeave() {
      if (this.isMobile()) {
        return;
      }
      if (!this.hasSubmenu) {
        this.closeSubmenu();
        return;
      }
      await new Promise((resolve) => {
        this.closeSubmenuTimeout = setTimeout(resolve, 400);
      });
      this.closeSubmenu();
    },
    onFocus() {
      if (this.isMobile()) {
        return;
      }
      this.openSubmenu();
    },
    onBlur() {
      if (this.isMobile()) {
        return;
      }
      this.closeSubmenu();
    },
    isMobile() {
      return window.matchMedia('(max-width: 767px)').matches;
    },
  },
};
</script>

<style scoped lang="scss">
.navbar-item {
  position: relative;
  color: white;
}

.navbar-link {
  position: relative;
  display: flex;
  padding: 9px 10px 10px;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 2px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  min-height: 38px;

  svg {
    min-width: 16px;
    margin-right: 14px;

    &:last-child {
      margin-right: 0;
      opacity: 0.5;
      display: none;
    }
  }
}

.navbar-link--no-link > * {
  pointer-events: none;
  user-select: none;
}

.navbar-link--expanded {
  width: 100%;

  svg:last-child {
    display: block;
  }
}

.navbar-link__text {
  display: none;
  flex-grow: 1;
  position: absolute;
  background: #13353c;
  padding: 7px 10px;
  align-items: center;
  border-radius: 2px;
  left: calc(100% + 15px);
  white-space: nowrap;
}

.navbar-link__text--expanded {
  display: block;
  background: transparent;
  position: relative;
  left: auto;
  padding: 0;
}

@media (min-width: 768px) {
  .navbar-link__text--expanded {
    animation: b-icon-animation-fade 0.1s ease-in;
    animation-delay: 0.35s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
}

/* SUB MENU */
.sub-menu {
  position: absolute;
  left: 100%;
  bottom: 0;
  display: none;
  padding-left: 15px;
  max-height: calc(70 * var(--vh, 1vh));
  min-width: 200px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .sub-menu {
    position: relative;
    left: auto;
    bottom: auto;
    max-height: none;
    padding-left: 0;
  }
}

.sub-menu__inner {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  border-radius: 2px;
  overflow: hidden;
}

.sub-menu__headline {
  text-transform: none;
  padding: 5px 15px;
  margin: 0 0 5px 0;
  background: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  line-height: 16px;
}

.sub-menu__link {
  padding: 5px 15px;
  margin: 0;
  white-space: nowrap;
}

.navbar-item--submenu-open {
  &:not(.navbar-item--has-submenu) .navbar-link__text,
  .sub-menu {
    display: block;
  }
  .navbar-link {
    background: rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 768px) {
  .navbar-item:focus-within {
    &:not(.navbar-item--has-submenu) .navbar-link__text,
    .sub-menu {
      display: block;
    }
    .navbar-link {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.navbar-link.router-link-active {
  background: #13353c;
}
</style>
