<template>
  <ThePageSkeleton>
    <template #contentbar>
      <TheContentbar>
        <template #right="{ contentbarLarge }">
          <SearchBar class="mr-1" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <BButton variant="lightest" class="text-dark mr-1 px-4" @click="back">
            {{ $t('Schließen') }}
          </BButton>
          <BButton variant="primary" class="mr-1 px-4" :fetching="restoring" @click="restore">
            {{ $t('Wiederherstellen') }}
          </BButton>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableActivitiesRestoreContainer
        ref="table"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import SearchBar from '@/shared/components/SearchBar.vue';

import TableActivitiesRestoreContainer from './containers/TableActivitiesRestoreContainer.vue';

export default {
  name: 'PageActivitiesRestore',
  components: {
    TheContentbar,
    ThePageSkeleton,
    SearchBar,
    TableActivitiesRestoreContainer,
  },
  data() {
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      restoring: false,
    };
  },
  methods: {
    back() {
      this.$router.push({ name: 'activities' });
    },
    async restore() {
      this.restoring = true;
      const success = await this.$refs.table.restore();
      this.restoring = false;
      if (success) {
        this.back();
      }
    },
  },
};
</script>
