import { StockLevel, StoragePlace } from 'farmdok-rest-api';

import { Unit } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

import { StorageSelection } from '../components/storageSelection/types';

export default class StorageSelectionService {
  private readonly stockLevels: StockLevel[];

  private readonly storagePlaces: Data<StoragePlace>;

  private readonly units: Data<Unit>;

  constructor(stockLevels: StockLevel[], storagePlaces: Data<StoragePlace>, units: Data<Unit>) {
    this.stockLevels = stockLevels;
    this.storagePlaces = storagePlaces;
    this.units = units;
  }

  public getAllExcept(storageSelection: StorageSelection): StorageSelection[] {
    const {
      stockLevel: { productId, storagePlaceId },
    } = storageSelection;
    const allSelections = this.getAll(productId);

    return allSelections.filter((selection) => selection.stockLevel.storagePlaceId !== storagePlaceId);
  }

  public get(productId: string, storagePlaceId?: string | null): StorageSelection | null {
    const stockLevel = this.stockLevels.find(
      (level) => level.productId === productId && level.storagePlaceId === storagePlaceId,
    );

    if (!stockLevel) return null;

    const storagePlace = this.getStoragePlace(stockLevel);
    const unit = this.getUnit(stockLevel);

    return {
      stockLevel,
      storagePlace,
      unit,
    };
  }

  private getAll(productId: string): StorageSelection[] {
    const stockLevelsOfProduct = this.stockLevels.filter((stockLevel) => stockLevel.productId === productId);

    return stockLevelsOfProduct
      .map(({ storagePlaceId }) => this.get(productId, storagePlaceId))
      .filter(notNullOrUndefined);
  }

  private getStoragePlace(stockLevel: StockLevel): StoragePlace | null {
    if (!stockLevel.storagePlaceId) return null;

    const storagePlace = this.storagePlaces[stockLevel.storagePlaceId];
    if (!storagePlace) {
      throw new Error(`Storage place not found: ${stockLevel.storagePlaceId}`);
    }
    return storagePlace;
  }

  private getUnit(stockLevel: StockLevel): Unit {
    const unit = this.units[stockLevel.unitId];
    if (!unit) {
      throw new Error(`Unit not found: ${stockLevel.unitId}`);
    }
    return unit;
  }
}
