import Handsontable from 'handsontable';
import Vue from 'vue';

export default function ErrorRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    TD,
    row,
    col,
    prop,
    Vue.i18n.translate('#FEHLER'),
    cellProperties,
    ...args,
  ]);
}
