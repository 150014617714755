
import { TerrazoGrainQuality, TerrazoHumidityAreaType } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import { Application } from '@/precision-farming/application-maps/fertilizationCereals/store/types';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInputNumberFormattedTwoDecimals from '@/shared/components/form/FormFieldInputNumberFormattedTwoDecimals.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

const EMPTY_DROPDOWNITEM = {
  id: null,
  name: '',
};

export default defineComponent({
  name: 'ThirdApplicationForm',
  components: { FormFieldDropdown, FormFieldInputNumberFormattedTwoDecimals },
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
  },
  data() {
    const humidityAreaTypes: DropdownItemsPerCategory[] = [
      {
        items: [
          {
            id: TerrazoHumidityAreaType.HumidArea,
            name: this.$t('Feuchtgebiet') || 'Feuchtgebiet',
          },
          {
            id: TerrazoHumidityAreaType.DryArea,
            name: this.$t('Trockengebiet') || 'Trockengebiet',
          },
        ],
      },
    ];
    const grainQualityTypes: DropdownItemsPerCategory[] = [
      {
        items: [
          {
            id: TerrazoGrainQuality.LiveStock,
            name: this.$t('Futter') || 'Futter',
          },
          {
            id: TerrazoGrainQuality.Milling,
            name: this.$t('Mahlen') || 'Mahlen',
          },
          {
            id: TerrazoGrainQuality.Quality,
            name: this.$t('Qualität') || 'Qualität',
          },
          {
            id: TerrazoGrainQuality.Premium,
            name: this.$t('Premium') || 'Premium',
          },
        ],
      },
    ];
    return {
      humidityAreaTypes,
      grainQualityTypes,
    };
  },
  computed: {
    selectedHumidtyAreaTypeDropdownItem(): DropdownItem {
      return (
        this.humidityAreaTypes[0].items.find(
          (item) => item.id === this.application.applicationTypeThird.humidityAreaType,
        ) ?? EMPTY_DROPDOWNITEM
      );
    },
    selectedTargedGrainQualityDropdownItem(): DropdownItem {
      return (
        this.grainQualityTypes[0].items.find(
          (item) => item.id === this.application.applicationTypeThird.targetGrainQuality,
        ) ?? EMPTY_DROPDOWNITEM
      );
    },
  },
  methods: {
    updateApplicationField(key: string, value: number) {
      this.$emit('update:applicationField', key, value);
    },
  },
});
