import {
  ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner,
  ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner,
} from 'farmdok-rest-api';

import { FertilizationCerealsZone } from '../types';

export function toFertilizationCerealsZones(
  zones: ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner[],
  features: ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner[],
): FertilizationCerealsZone[] {
  return zones.map((zone) => toFertilizationCerealsZone(zone, features));
}

export function toFertilizationCerealsZone(
  zone: ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner,
  features: ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner[],
): FertilizationCerealsZone {
  const color = findColorOfZone(zone, features) || '#000000';

  return {
    color,
    name: zone.index?.toString() || 'Unknown',
    minNdvi: zone.min,
    maxNdvi: zone.max,
    avgNdvi: zone.avg,
    size: zone.area ? zone.area / 10000 : 0,
    rate: zone.nRate,
  };
}

function findColorOfZone(
  zone: ApplicationMapsTerrazoMap200ResponseAllOfDataMetaZonesInner,
  features: ApplicationMapsTerrazoMap200ResponseAllOfDataFeaturesInner[],
): string | undefined {
  const feature = features.find((f) => f.properties.ZONE === zone.index?.toString());

  return feature?.properties.fill;
}
