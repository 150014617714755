/* eslint-disable */
export default function en() {
  return {
    "": "",
    "#FEHLER": "#FEHLER",
    "(Alle ausw\u00e4hlen)": "(Alle ausw\u00e4hlen)",
    "(leer)": "(leer)",
    "(verschiedene)": "(verschiedene)",
    "1 Feld mit Erntema\u00dfnahme": "1 Feld mit Erntema\u00dfnahme",
    "1 Feld ohne Erntema\u00dfnahme": "1 Feld ohne Erntema\u00dfnahme",
    "1 Fl\u00e4che ({area} ha) an {partner} gesendet.": "1 Fl\u00e4che ({area} ha) an {partner} gesendet.",
    "404: Nicht gefunden.": "404: Nicht gefunden.",
    "<Bezeichnung>": "<Bezeichnung>",
    "<strong>Lege die Datei hier ab<\/strong> <small>oder dr\u00fccke hier, um eine Datei hinzuzuf\u00fcgen.<\/small>": "<strong>Lege die Datei hier ab<\/strong> <small>oder dr\u00fccke hier, um eine Datei hinzuzuf\u00fcgen.<\/small>",
    "AGRANA FSNr - Feldname": "AGRANA FSNr - Feldname",
    "AMA Code": "AMA Code",
    "AMA Code in der Datenquelle (aus dem Import)": "AMA Code in der Datenquelle (aus dem Import)",
    "AMA Codes": "AMA Codes",
    "Abbrechen": "Abbrechen",
    "Abgeschlossen": "Abgeschlossen",
    "Ablaufdatum": "Ablaufdatum",
    "Ablaufdatum:": "Ablaufdatum:",
    "Abmelden": "Abmelden",
    "Abschlie\u00dfen": "Abschlie\u00dfen",
    "Absenden": "Absenden",
    "Abstand prüfen": "Abstand prüfen",
    "Achtung": "Achtung",
    "Die Saatstärke ist in einer oder mehreren Zonen beträchtlich {comparative} als üblich.": "Die Saatstärke ist in einer oder mehreren Zonen beträchtlich {comparative} als üblich.",
    "Achtung! Beim Verlassen der Seite wird das Hochladen abgebrochen!": "Achtung! Beim Verlassen der Seite wird das Hochladen abgebrochen!",
    "Ackerkulturen": "Ackerkulturen",
    "Adresse": "Adresse",
    "Adresse suchen": "Adresse suchen",
    "Aktion sicher ausf\u00fchren?": "Aktion sicher ausf\u00fchren?",
    "Aktionsgebiet": "Aktionsgebiet",
    "Aktiv": "Aktiv",
    "Aktiv setzen": "Aktiv setzen",
    "Aktiviere deinen sicheren Zugang!": "Aktiviere deinen sicheren Zugang!",
    "Aktivieren": "Aktivieren",
    "Aktivität konnte nicht importiert werden. Fehler: ": "Aktivität konnte nicht importiert werden. Fehler: ",
    "Aktualisiere den in der Arbeitsplanung geplanten Auftrag und f\u00fcge die eben erstellte Applikationskarte hinzu.": "Aktualisiere den in der Arbeitsplanung geplanten Auftrag und f\u00fcge die eben erstellte Applikationskarte hinzu.",
    "Aktuell unterst\u00fctzt FARMDOK Applikationskarten nur f\u00fcr Minerald\u00fcngung.": "Aktuell unterst\u00fctzt FARMDOK Applikationskarten nur f\u00fcr Minerald\u00fcngung.",
    "Aktuell:": "Aktuell:",
    "Alle": "Alle",
    "Alle Dateien entfernen": "Alle Dateien entfernen",
    "Alle Eintr\u00e4ge": "Alle Eintr\u00e4ge",
    "Alle Farmen": "Alle Farmen",
    "Alle Felder": "Alle Felder",
    "Alle Werte in kg\/ha": "Alle Werte in kg\/ha",
    "Alle Werte in K\u00f6rner/ha": "Alle Werte in K\u00f6rner/ha",
    "Alle anzeigen": "Alle anzeigen",
    "Alle ausw\u00e4hlen": "Alle ausw\u00e4hlen",
    "Alle w\u00e4hlen": "Alle w\u00e4hlen",
    "Alle ausgewählten Polygone werden zu einem vereint. Willst du fortsetzen?": "Alle ausgewählten Polygone werden zu einem vereint. Willst du fortsetzen?",
    "Allgemeinen Gesch\u00e4ftsbedingungen (AGB)": "Allgemeinen Gesch\u00e4ftsbedingungen (AGB)",
    "Als Excel-Datei exportieren": "Als Excel-Datei exportieren",
    "An angegebene Betriebe zum Lesen freigegeben.": "An angegebene Betriebe zum Lesen freigegeben.",
    "An diesem Datum wurde das Satellitenbild aufgenommen. Alle 5 Tage passiert der Satellit die gleiche Stelle. Bedingt durch Wolken und Wetter ist nicht jedes Bild verwendbar.": "An diesem Datum wurde das Satellitenbild aufgenommen. Alle 5 Tage passiert der Satellit die gleiche Stelle. Bedingt durch Wolken und Wetter ist nicht jedes Bild verwendbar.",
    "Anbau": "Anbau",
    "Anbauplanung": "Anbauplanung",
    "Andere": "Andere",
    "Andere T\u00e4tigkeiten": "Andere T\u00e4tigkeiten",
    "Angezeigt: {visible} von {total}": "Angezeigt: {visible} von {total}",
    "Anleitung: Woher bekomme ich eine Datei mit meinen Feldern?": "Anleitung: Woher bekomme ich eine Datei mit meinen Feldern?",
    "Anleitung: Woher bekomme ich eine ISOXML Datei mit meinen Ma\u00dfnahmen?": "Anleitung: Woher bekomme ich eine ISOXML Datei mit meinen Ma\u00dfnahmen?",
    "Anleitung: Die KI-unterstützte Auswertung von Drohnenbilder ermöglicht es Stechapfelpflanzen im Soja zu erkennen und geolokalisiert in einer Karte zu kennzeichnen. Woher bekomme ich geeignete Drohnenbilder für diese Auswertung?": "Anleitung: Die KI-unterstützte Auswertung von Drohnenbilder ermöglicht es Stechapfelpflanzen im Soja zu erkennen und geolokalisiert in einer Karte zu kennzeichnen. Woher bekomme ich geeignete Drohnenbilder für diese Auswertung?",
    "Anleitung: Der Umfang auszuwertender Bilddaten kann sehr groß sein. Es können Einzelbilder, eine .zip-Datei mit Bildern oder ein Order mit Bildern hochgeladen werden. Bei großen Datenmengen oder schlechter Internetverbindung kann das Hochladen mehrere Stunden dauern. Achtung: Die Benennung der Dateien muss eindeutig sein. Duplikate überschreiben das Original.": "Anleitung: Der Umfang auszuwertender Bilddaten kann sehr groß sein. Es können Einzelbilder, eine .zip-Datei mit Bildern oder ein Order mit Bildern hochgeladen werden. Bei großen Datenmengen oder schlechter Internetverbindung kann das Hochladen mehrere Stunden dauern. Achtung: Die Benennung der Dateien muss eindeutig sein. Duplikate überschreiben das Original.",
    "Antrag": "Antrag",
    "Anzahl Eintr\u00e4ge: {total}": "Anzahl Eintr\u00e4ge: {total}",
    "Anzahl der Tasks auf der CNH-Plattform:": "Anzahl der Tasks auf der CNH-Plattform:",
    "Anzahl der Zonen:": "Anzahl der Zonen:",
    "Anzahl Felder": "Anzahl Felder",
    "Anzahl Packungen": "Anzahl Packungen",
    "Anzahl Bilder": "Anzahl Bilder",
    "Anzeige": "Anzeige",
    "Anzeigen": "Anzeigen",
    "Applikationskarte an John Deere senden": "Applikationskarte an John Deere senden",
    "Applikationskarte erstellen": "Applikationskarte erstellen",
    "Applikationskarte senden an ...": "Applikationskarte senden an ...",
    "Applikationskarte": "Applikationskarte",
    "Applikationskarten": "Applikationskarten",
    "Applikationskarten f\u00fcr Ausgleichs- oder Qualit\u00e4tsd\u00fcngung. Zonierung mittels Satelliten oder importierten Daten.": "Applikationskarten f\u00fcr Ausgleichs- oder Qualit\u00e4tsd\u00fcngung. Zonierung mittels Satelliten oder importierten Daten.",
    "Applikationskarten f\u00fcr Ausgleichs- oder Qualit\u00e4tsd\u00fcngung von Getreide. Ein Projekt von Josephinum Research.": "Applikationskarten f\u00fcr Ausgleichs- oder Qualit\u00e4tsd\u00fcngung von Getreide. Ein Projekt von Josephinum Research.",
    "Applikationskarten für die variable Ausbringung von Wachstumsregler und Fungiziden auf Basis von Satellitendaten.": "Applikationskarten für die variable Ausbringung von Wachstumsregler und Fungiziden auf Basis von Satellitendaten.",
    "Arbeitsplanung": "Arbeitsplanung",
    "Arbeitszeit": "Arbeitszeit",
    "Archiv editieren": "Archiv editieren",
    "Archivieren": "Archivieren",
    "Archiviert": "Archiviert",
    "Archivierte ausblenden": "Archivierte ausblenden",
    "Archivierte einblenden": "Archivierte einblenden",
    "Auf diesem Feld wurde bereits eine Erntema\u00dfnahme f\u00fcr das Erntejahr {current} gebucht. Schlie\u00dfe das Erntejahr f\u00fcr das Feld jetzt ab, es wird automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.": "Auf diesem Feld wurde bereits eine Erntema\u00dfnahme f\u00fcr das Erntejahr {current} gebucht. Schlie\u00dfe das Erntejahr f\u00fcr das Feld jetzt ab, es wird automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.",
    "Auf diesen Feldern wurden bereits Erntema\u00dfnahmen f\u00fcr das Erntejahr {current} gebucht. Schlie\u00dfe das Erntejahr f\u00fcr die Felder jetzt ab, sie werden automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.": "Auf diesen Feldern wurden bereits Erntema\u00dfnahmen f\u00fcr das Erntejahr {current} gebucht. Schlie\u00dfe das Erntejahr f\u00fcr die Felder jetzt ab, sie werden automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.",
    "Auf welches Feld sollen bereits gebuchte Ma\u00dfnahmen \u00fcbernommen werden?": "Auf welches Feld sollen bereits gebuchte Ma\u00dfnahmen \u00fcbernommen werden?",
    "Aufteilen": "Aufteilen",
    "Aufteilen beenden": "Aufteilen beenden",
    "Aufteilen eines Polygons": "Aufteilen eines Polygons",
    "Auftrag": "Auftrag",
    "Auftrag aktualisieren": "Auftrag aktualisieren",
    "Auftrag erstellen": "Auftrag erstellen",
    "Auftrag planen": "Auftrag planen",
    "Auftrag\/Applikationskarte": "Auftrag\/Applikationskarte",
    "Auftragsnummer": "Auftragsnummer",
    "Aufzeichnungspflichten erf\u00fcllen": "Aufzeichnungspflichten erf\u00fcllen",
    "Aus importierter Datei.": "Aus importierter Datei.",
    "Ausgew\u00e4hlt: {selected} von {total}": "Ausgew\u00e4hlt: {selected} von {total}",
    "Ausgew\u00e4hlte Felder in ein anderes Erntejahr verschieben": "Ausgew\u00e4hlte Felder in ein anderes Erntejahr verschieben",
    "Ausgew\u00e4hlte Maßnahmen in ein anderes Erntejahr oder in eine andere Farm verschieben": "Ausgew\u00e4hlte Maßnahmen in ein anderes Erntejahr oder in eine andere Farm verschieben",
    "Ausgleichsd\u00fcngung (1. Gabe)": "Ausgleichsd\u00fcngung (1. Gabe)",
    "Aussaat": "Aussaat",
    "AUSSAAT": "AUSSAAT",
    "Auswahl aufheben": "Auswahl aufheben",
    "Auswahl exportieren": "Auswahl exportieren",
    "Auswahl umkehren": "Auswahl umkehren",
    "Auswertung": "Auswertung",
    "B": "B",
    "Basissaatst\u00e4rke": "Basissaatst\u00e4rke",
    "Basistemperatur": "Basistemperatur",
    "Basistemperatur ist relevant für die Wärmesumme. Es wird angenommen, dass unter ihr kein Wachstum stattfindet.": "Basistemperatur ist relevant für die Wärmesumme. Es wird angenommen, dass unter ihr kein Wachstum stattfindet.",
    "BMMY - Mehrj\u00e4hrige Biomasse (3 Jahre)": "BMMY - Mehrj\u00e4hrige Biomasse (3 Jahre)",
    "Bearbeiten": "Bearbeiten",
    "Bearbeitete Fl\u00e4che": "Bearbeitete Fl\u00e4che",
    "Bedarf": "Bedarf",
    "Begr\u00fcnung": "Begr\u00fcnung",
    "Begr\u00fcnung (Zwischenfrucht)": "Begr\u00fcnung (Zwischenfrucht)",
    "Behalte diese Option bei den folgenden Konflikten bei": "Behalte diese Option bei den folgenden Konflikten bei",
    "Bei Wintergetreide wird f\u00fcr die 2. Gabe eine konstante D\u00fcngermenge empfohlen. Wurde die erste Gabe nicht mittels Applikationskarte ausgleichend ged\u00fcngt, so kann für die 2. Gabe der Berechnungsvorschlag der erste Gabe angewendet werden.": "Bei Wintergetreide wird f\u00fcr die 2. Gabe eine konstante D\u00fcngermenge empfohlen. Wurde die erste Gabe nicht mittels Applikationskarte ausgleichend ged\u00fcngt, so kann für die 2. Gabe der Berechnungsvorschlag der erste Gabe angewendet werden.",
    "Beim Laden der Wetterdaten ist ein Fehler aufgetreten.": "Beim Laden der Wetterdaten ist ein Fehler aufgetreten.",
    "Beistrich-getrennt": "Beistrich-getrennt",
    "Bekannt, unter anderem dafür in Google Earth angezeigt zu werden": "Bekannt, unter anderem dafür in Google Earth angezeigt zu werden",
    "Bemerkung": "Bemerkung",
    "Benachrichtigungen": "Benachrichtigungen",
    "Benutzer": "Benutzer",
    "Benutzername oder Passwort ung\u00fcltig.": "Benutzername oder Passwort ung\u00fcltig.",
    "Berater-Tipp": "Berater-Tipp",
    "Berater-Tipp: Hast du Verluste durch mechanische Pflege, Wild oder \u00e4hnliche Einflüsse ber\u00fccksichtigt?": "Berater-Tipp: Hast du Verluste durch mechanische Pflege, Wild oder \u00e4hnliche Einflüsse ber\u00fccksichtigt?",
    "Berechnen ...": "Berechnen ...",
    "Berechne einen Vorschlag f\u00fcr ...": "Berechne einen Vorschlag f\u00fcr ...",
    "Berichte": "Berichte",
    "Bericht erstellen": "Bericht erstellen",
    "Beschreibung": "Beschreibung",
    "Beschr\u00e4nkungen bei der Ernte:": "Beschr\u00e4nkungen bei der Ernte:",
    "Beschr\u00e4nkungen f\u00fcr Sch\u00e4dlinge:": "Beschr\u00e4nkungen f\u00fcr Sch\u00e4dlinge:",
    "Betrieb": "Betrieb",
    "Betrieb erstellen": "Betrieb erstellen",
    "Betrieb fehlt.": "Betrieb fehlt.",
    "Betrieb w\u00e4hlen": "Betrieb w\u00e4hlen",
    "Betriebe": "Betriebe",
    "Betriebsbezeichnung": "Betriebsbezeichnung",
    "Betriebsmittel": "Betriebsmittel",
    "Betriebsmittelkategorien": "Betriebsmittelkategorien",
    "Betriebsname": "Betriebsname",
    "Betriebsnummer": "Betriebsnummer",
    "Betriebsnummer des Sender-Betriebs": "Betriebsnummer des Sender-Betriebs",
    "Bezeichnung": "Bezeichnung",
    "Bezeichnung fehlt.": "Bezeichnung fehlt.",
    "Bezeichnung f\u00fcr Feld {number} fehlt.": "Bezeichnung f\u00fcr Feld {number} fehlt.",
    "Bilder": "Bilder",
    "Biologisch": "Biologisch",
    "Bitte achte darauf, die gleiche E-Mail-Adresse wie in der FARMDOK App am Smartphone oder Tablet zu verwenden. Du findest die E-Mail-Adresse in der FARMDOK App im Men\u00fcpunkt \"Mehr\".": "Bitte achte darauf, die gleiche E-Mail-Adresse wie in der FARMDOK App am Smartphone oder Tablet zu verwenden. Du findest die E-Mail-Adresse in der FARMDOK App im Men\u00fcpunkt \"Mehr\".",
    "Bitte w\u00e4hle eine Option und klicke auf Weiter.": "Bitte w\u00e4hle eine Option und klicke auf Weiter.",
    "Bitte w\u00e4hle einen Eintrag aus.": "Bitte w\u00e4hle einen Eintrag aus.",
    "Bitte w\u00e4hle wie Duplikate behandelt werden sollen.": "Bitte w\u00e4hle wie Duplikate behandelt werden sollen.",
    "Bitte \u00fcberpr\u00fcfe deine E-Mails": "Bitte \u00fcberpr\u00fcfe deine E-Mails",
    "Bitte \u00fcberpr\u00fcfe deine E-Mails!": "Bitte \u00fcberpr\u00fcfe deine E-Mails!",
    "Bitte w\u00e4hle ein Feld aus, bevor du fortfährst.": "Bitte w\u00e4hle ein Feld aus, bevor du fortfährst.",
    "Bitte w\u00e4hle ein Produkt und eine Produktmenge aus, bevor du fortfährst.": "Bitte w\u00e4hle ein Produkt und eine Produktmenge aus, bevor du fortfährst.",
    "Bitte w\u00e4hle eine Sorte aus, bevor du fortfährst.": "Bitte w\u00e4hle eine Sorte aus, bevor du fortfährst.",
    "Bitte beachte die technischen Möglichkeiten deiner Feldspritze. Prüfe ob deine Feldspritze in der Lage ist die festgelegte Menge Spritzbrühe auszubringen.": "Bitte beachte die technischen Möglichkeiten deiner Feldspritze. Prüfe ob deine Feldspritze in der Lage ist die festgelegte Menge Spritzbrühe auszubringen.",
    "Bodenproben": "Bodenproben",
    "Bodenproben teilen": "Bodenproben teilen",
    "Bodenfeuchtigkeit": "Bodenfeuchtigkeit",
    "Bodentemperatur": "Bodentemperatur",
    "Ca": "Ca",
    "Ca-Klasse": "Ca-Klasse",
    "Christbaumkulturen": "Christbaumkulturen",
    "Chronologisch": "Chronologisch",
    "CIre - Chlorophyll": "CIre - Chlorophyll",
    "CNH CONNECT Plattform": "CNH CONNECT Plattform",
    "Dabei werden {count} bestehende Felder ver\u00e4ndert.": "Dabei werden {count} bestehende Felder ver\u00e4ndert.",
    "Dabei wird ein bestehendes Feld ver\u00e4ndert.": "Dabei wird ein bestehendes Feld ver\u00e4ndert.",
    "Danke, dass Du Dich bei Farmdok registriert hast! Deine E-Mail {email} ist jetzt best\u00e4tigt. Klicke auf Weiter und danach helfen wir Dir beim Einstieg.": "Danke, dass Du Dich bei Farmdok registriert hast! Deine E-Mail {email} ist jetzt best\u00e4tigt. Klicke auf Weiter und danach helfen wir Dir beim Einstieg.",
    "Darstellung": "Darstellung",
    "Das Abrufen der Maschinenliste von John Deere fehlgeschlagen.": "Das Abrufen der Maschinenliste von John Deere fehlgeschlagen.",
    "Die Karte auf der rechten Seite zeigt den bedruckbaren Bereich. Du kannst eine Legende erstellen und sie irgendwo im druckbaren Bereich platzieren. Die Legende wird auf der Grundlage der Kulturen erstellt (Du kannst dies mit der Einstellung \"Einfärben nach...\" im Menü ändern).": "Die Karte auf der rechten Seite zeigt den bedruckbaren Bereich. Du kannst eine Legende erstellen und sie irgendwo im druckbaren Bereich platzieren. Die Legende wird auf der Grundlage der Kulturen erstellt (Du kannst dies mit der Einstellung \"Einfärben nach...\" im Menü ändern).",
    "Die zulässige Größe für ausstehende Uploads wurde überschritten. Limit wird in ca. 1 Woche automatisch zurückgesetzt.": "Die zulässige Größe für ausstehende Uploads wurde überschritten. Limit wird in ca. 1 Woche automatisch zurückgesetzt.",
    "Die Variation von Ausbringmengen bei der Applikation von Herbiziden birgt das Risiko, dass der Schaderreger nicht effektiv bekämpft wird. Die variable Ausbringung von Herbiziden wir nicht empfohlen.": "Die Variation von Ausbringmengen bei der Applikation von Herbiziden birgt das Risiko, dass der Schaderreger nicht effektiv bekämpft wird. Die variable Ausbringung von Herbiziden wir nicht empfohlen.",
    "Die Variation von Ausbringmengen bei der Applikation von Insektiziden birgt hohes Risiko der Bildung von Resistenzen. Die variable Ausbringung von Insektiziden wird nicht empfohlen.": "Die Variation von Ausbringmengen bei der Applikation von Insektiziden birgt hohes Risiko der Bildung von Resistenzen. Die variable Ausbringung von Insektiziden wird nicht empfohlen.",
    "Das Erntejahr {current} f\u00fcr die ausgew\u00e4hlten Felder befindet sich in Planung.": "Das Erntejahr {current} f\u00fcr die ausgew\u00e4hlten Felder befindet sich in Planung.",
    "Das Erntejahr {current} f\u00fcr dieses Feld befindet sich in Planung.": "Das Erntejahr {current} f\u00fcr dieses Feld befindet sich in Planung.",
    "Das Erntejahr {newYear} wurde hinzugef\u00fcgt.": "Das Erntejahr {newYear} wurde hinzugef\u00fcgt.",
    "Das Feld besitzt keine Kontur": "Das Feld besitzt keine Kontur",
    "Das Feld der folgenden Maßnahme konnte im Ziel nicht gefunden werden.": "Das Feld der folgenden Maßnahme konnte im Ziel nicht gefunden werden.",
    "Das Feld konnte nicht wieder hergestellt werden.": "Das Feld konnte nicht wieder hergestellt werden.",
    "Das Feld wird derzeit im angezeigten Erntejahr {current} bewirtschaftet.": "Das Feld wird derzeit im angezeigten Erntejahr {current} bewirtschaftet.",
    "Das Feld wurde erfolgreich geteilt.": "Das Feld wurde erfolgreich geteilt.",
    "Das gew\u00e4hlte Feld existiert bereits am Ziel. W\u00e4hle wie dieses Feld behandelt werden soll.": "Das gew\u00e4hlte Feld existiert bereits am Ziel. W\u00e4hle wie dieses Feld behandelt werden soll.",
    "Das manuelle Mapping für Werte dieser Datenquelle wird zurückgesetzt in den Originalzustand.": "Das manuelle Mapping für Werte dieser Datenquelle wird zurückgesetzt in den Originalzustand.",
    "Das Startdatum ist standardmäßig das Aussaatdatum der Maßnahme oder alternativ der Jahresbeginn. Es ist relevant für die Wärmesumme und die Niederschlagssumme.": "Das Startdatum ist standardmäßig das Aussaatdatum der Maßnahme oder alternativ der Jahresbeginn. Es ist relevant für die Wärmesumme und die Niederschlagssumme.",
    "Datei hochladen": "Datei hochladen",
    "Dateien": "Dateien",
    "Datei herunterladen": "Datei herunterladen",
    "Dateien hochladen": "Dateien hochladen",
    "Datei wird hochgeladen": "Datei wird hochgeladen",
    "Dateien erfolgreich an John Deere gesendet.": "Dateien erfolgreich an John Deere gesendet.",
    "Dateiformat": "Dateiformat",
    "Dateiname": "Dateiname",
    "Datei-Import": "Datei-Import",
    "Dateien hochladen und mit einem Link mit anderen teilen": "Dateien hochladen und mit einem Link mit anderen teilen",
    "Dateien werden für den Maßnahmenimport nicht unterstützt.": "Dateien werden für den Maßnahmenimport nicht unterstützt.",
    "Dateityp": "Dateityp",
    "Daten": "Daten",
    "Daten werden importiert...": "Daten werden importiert...",
    "Daten Ursprung": "Daten Ursprung",
    "Daten in FARMDOK zuordnen.": "Daten in FARMDOK zuordnen.",
    "Daten laden": "Daten laden",
    "Daten-Vorschau": "Daten-Vorschau",
    "Datenquelle": "Datenquelle",
    "Datum": "Datum",
    "Datum an dem die Freigabe verf\u00e4llt": "Datum an dem die Freigabe verf\u00e4llt",
    "Datum an dem die Datei hochgeladen wurde": "Datum an dem die Datei hochgeladen wurde",
    "Datum bis": "Datum bis",
    "Datum von": "Datum von",
    "Deaktiviere das Kontrollk\u00e4stchen um Spalten zu verstecken. Zum \u00c4ndern der Spaltenreihenfolge in der Tabelle klicke auf das Feld und ziehe es an die gew\u00fcnschte Stelle.": "Deaktiviere das Kontrollk\u00e4stchen um Spalten zu verstecken. Zum \u00c4ndern der Spaltenreihenfolge in der Tabelle klicke auf das Feld und ziehe es an die gew\u00fcnschte Stelle.",
    "Dein Betrieb wurde erfolgreich erstellt.": "Dein Betrieb wurde erfolgreich erstellt.",
    "Dein Partner hat Felder für diesen Vertrag bereitgestellt. Dein Partner kann die Felder jederzeit aktualisieren.": "Dein Partner hat Felder für diesen Vertrag bereitgestellt. Dein Partner kann die Felder jederzeit aktualisieren.",
    "Dein Passwort wurde erfolgreich gespeichert.": "Dein Passwort wurde erfolgreich gespeichert.",
    "Dein Profil wird f\u00fcr dich angepasst!": "Dein Profil wird f\u00fcr dich angepasst!",
    "Deine Auswahl enth\u00e4lt Felder mit abgeschlossener Kontrahierung. \u00c4ndere den Partner-Status, um die Eintr\u00e4ge zu l\u00f6schen.": "Deine Auswahl enth\u00e4lt Felder mit abgeschlossener Kontrahierung. \u00c4ndere den Partner-Status, um die Eintr\u00e4ge zu l\u00f6schen.",
    "Deine E-Mail ist best\u00e4tigt.": "Deine E-Mail ist best\u00e4tigt.",
    "Deine Maschinen in MyJohnDeere.com": "Deine Maschinen in MyJohnDeere.com",
    "Deine Organisationen in MyJohnDeere": "Deine Organisationen in MyJohnDeere",
    "Demn\u00e4chst verf\u00fcgbar": "Demn\u00e4chst verf\u00fcgbar",
    "Der Erntejahr-Status konnte nicht ge\u00e4ndert werden.": "Der Erntejahr-Status konnte nicht ge\u00e4ndert werden.",
    "Der Index wird aus Satellitendaten berechnet und bildet die Basis f\u00fcr die Teilfl\u00e4chen (Zonen).": "Der Index wird aus Satellitendaten berechnet und bildet die Basis f\u00fcr die Teilfl\u00e4chen (Zonen).",
    "Der Status der gew\u00e4hlten Felder wurde aktualisiert.": "Der Status der gew\u00e4hlten Felder wurde aktualisiert.",
    "Der Vertragsabschluss ist erfolgt. Dein Partner kann keine Aktualisierungen senden.": "Der Vertragsabschluss ist erfolgt. Dein Partner kann keine Aktualisierungen senden.",
    "Der Vertrag wurde storniert. Die bereitgestellten Felder werden nicht mehr ber\u00fccksichtigt.": "Der Vertrag wurde storniert. Die bereitgestellten Felder werden nicht mehr ber\u00fccksichtigt.",
    "Der Wert in der Datenquelle (aus dem Import) konnte nicht eindeutig erkannt werden. Bitte w\u00e4hle eine Eintrag aus der Liste. Andernfalls kann kein Wert importiert werden.": "Der Wert in der Datenquelle (aus dem Import) konnte nicht eindeutig erkannt werden. Bitte w\u00e4hle eine Eintrag aus der Liste. Andernfalls kann kein Wert importiert werden.",
    "Der Upload der Drohnenbilder wurde erfolgreich abgeschlossen und die Analyse der Drohnenbilder gestartet. Sobald die Ergebnisse verfügbar sind, werden sie im Datura control center angezeigt. Du kannst den aktuellen Status des Assessments in der “Job history” verfolgen.": "Der Upload der Drohnenbilder wurde erfolgreich abgeschlossen und die Analyse der Drohnenbilder gestartet. Sobald die Ergebnisse verfügbar sind, werden sie im Datura control center angezeigt. Du kannst den aktuellen Status des Assessments in der “Job history” verfolgen.",
    "Derzeit werden nur Zip-Dateien unterst\u00fctzt.": "Derzeit werden nur Zip-Dateien unterst\u00fctzt.",
    "Deshalb wurde keine Aktivit\u00e4t verschoben nach {cropYear} auf {companyName}": "Deshalb wurde keine Aktivit\u00e4t verschoben nach {cropYear} auf {companyName}",
    "Detaillierte T\u00e4tigkeiten": "Detaillierte T\u00e4tigkeiten",
    "Details anzeigen": "Details anzeigen",
    "Den Status des aktuellen Auftrags und den Auftragsverlauf anzeigen": "Den Status des aktuellen Auftrags und den Auftragsverlauf anzeigen",
    "Die AGB konnten nicht geladen werden.": "Die AGB konnten nicht geladen werden.",
    "Die aktuelle Tabelle enth\u00e4lt Sub-Tabellen. Bitte w\u00e4hle f\u00fcr welche die Eintr\u00e4ge im exportieren XLS in einer eigenen Zeile dargestellt werden sollen.": "Die aktuelle Tabelle enth\u00e4lt Sub-Tabellen. Bitte w\u00e4hle f\u00fcr welche die Eintr\u00e4ge im exportieren XLS in einer eigenen Zeile dargestellt werden sollen.",
    "Die aktuelle Trennlinie ist ungültig. Zeichne eine Linie die das aktuelle Feld in genau zwei Teile teilt. Du kannst die Trennlinie durch Ziehen der Eckpunkte anpassen.": "Die aktuelle Trennlinie ist ungültig. Zeichne eine Linie die das aktuelle Feld in genau zwei Teile teilt. Du kannst die Trennlinie durch Ziehen der Eckpunkte anpassen.",
    "Die Datei ist zu gro\u00df. (max. {maxFileSize} MB)": "Die Datei ist zu gro\u00df. (max. {maxFileSize} MB)",
    "Die E-Mail-Adresse ist ung\u00fcltig.": "Die E-Mail-Adresse ist ung\u00fcltig.",
    "Die Ernte f\u00fcr das Erntejahr {current} ist auf den ausgew\u00e4hlten Feldern bereits abgeschlossen. Sie sind zur Zeit in einem zuk\u00fcnftigen Erntejahr \"aktiv\".": "Die Ernte f\u00fcr das Erntejahr {current} ist auf den ausgew\u00e4hlten Feldern bereits abgeschlossen. Sie sind zur Zeit in einem zuk\u00fcnftigen Erntejahr \"aktiv\".",
    "Die Ernte f\u00fcr das Erntejahr {current} ist bereits abgeschlossen. Das Feld ist in einem zuk\u00fcnftigen Erntejahr \"aktiv\".": "Die Ernte f\u00fcr das Erntejahr {current} ist bereits abgeschlossen. Das Feld ist in einem zuk\u00fcnftigen Erntejahr \"aktiv\".",
    "Die Felder aus dem nachfolgenden Erntejahr {sourceYear} \u00fcbernehmen.": "Die Felder aus dem nachfolgenden Erntejahr {sourceYear} \u00fcbernehmen.",
    "Die Felder aus dem vorangehenden Erntejahr {sourceYear} werden \u00fcbernommen.": "Die Felder aus dem vorangehenden Erntejahr {sourceYear} werden \u00fcbernommen.",
    "Die Felder werden derzeit im angezeigten Erntejahr {current} bewirtschaftet.": "Die Felder werden derzeit im angezeigten Erntejahr {current} bewirtschaftet.",
    "Die Felder wurden aus {sourceYear} \u00fcbernommen.": "Die Felder wurden aus {sourceYear} \u00fcbernommen.",
    "Die Felder wurden erfolgreich erstellt.": "Die Felder wurden erfolgreich erstellt.",
    "Die Felder wurden erfolgreich kopiert.": "Die Felder wurden erfolgreich kopiert.",
    "Die Felder wurden erfolgreich vereint. Du kannst die Eigenschaften des neuen Felds jetzt anpassen.": "Die Felder wurden erfolgreich vereint. Du kannst die Eigenschaften des neuen Felds jetzt anpassen.",
    "Die Felder wurden erfolgreich verschoben.": "Die Felder wurden erfolgreich verschoben.",
    "Die Felder wurden erfolgreich zusammengef\u00fcgt.": "Die Felder wurden erfolgreich zusammengef\u00fcgt.",
    "Die Felder wurden noch nicht erstellt. Bist du sicher, dass du den Import abbrechen willst?": "Die Felder wurden noch nicht erstellt. Bist du sicher, dass du den Import abbrechen willst?",
    "Die Feldkontur konnte nicht aktualisiert werden.": "Die Feldkontur konnte nicht aktualisiert werden.",
    "Die Feldkonturen konnten nicht kombiniert werden.": "Die Feldkonturen konnten nicht kombiniert werden.",
    "Die Fl\u00e4chen wurden nicht \u00fcber FARMDOK an {partner} gemeldet.": "Die Fl\u00e4chen wurden nicht \u00fcber FARMDOK an {partner} gemeldet.",
    "Die Gesamt-Erntemengen wurden von {partner} geladen. Klicke hier, um im n\u00e4chsten Schritt Ma\u00dfnahmen auf deinen Feldern zu erstellen.": "Die Gesamt-Erntemengen wurden von {partner} geladen. Klicke hier, um im n\u00e4chsten Schritt Ma\u00dfnahmen auf deinen Feldern zu erstellen.",
    "Die Importvorschau wird geladen. Hier kannst du bereits Anpassungen vornehmen.": "Die Importvorschau wird geladen. Hier kannst du bereits Anpassungen vornehmen.",
    "Die Liste der verf\u00fcgbaren L\u00e4nder konnte nicht geladen werden. Bitte \u00fcberpr\u00fcfe deine Internetverbindung und lade die Seite neu.": "Die Liste der verf\u00fcgbaren L\u00e4nder konnte nicht geladen werden. Bitte \u00fcberpr\u00fcfe deine Internetverbindung und lade die Seite neu.",
    "Die minimale Saatst\u00e4rke darf nicht gr\u00f6\u00dfer als die maximale Saatst\u00e4rke sein.": "Die minimale Saatst\u00e4rke darf nicht gr\u00f6\u00dfer als die maximale Saatst\u00e4rke sein.",
    "Die maximale Saatst\u00e4rke darf nicht kleiner als die minimale Saatst\u00e4rke sein.": "Die maximale Saatst\u00e4rke darf nicht kleiner als die minimale Saatst\u00e4rke sein.",
    "Die Partnernummer ist deine Identifikationsnummer bei SAATBAU LINZ eGen.": "Die Partnernummer ist deine Identifikationsnummer bei SAATBAU LINZ eGen.",
    "Die Werte konnten nicht geladen werden.": "Die Werte konnten nicht geladen werden.",
    "Die aktuelle Trennlinie ist ung\u00fclitg. Zeichne eine Linie die das aktuelle Feld in genau zwei Teile teilt.": "Die aktuelle Trennlinie ist ung\u00fclitg. Zeichne eine Linie die das aktuelle Feld in genau zwei Teile teilt.",
    "Die folgenden Felder existieren bereits am Ziel.": "Die folgenden Felder existieren bereits am Ziel.",
    "Die gew\u00e4hlten Feldst\u00fccke wurden erfolgreich archiviert.": "Die gew\u00e4hlten Feldst\u00fccke wurden erfolgreich archiviert.",
    "Die gew\u00e4hlten Feldst\u00fccke wurden wieder aktiviert.": "Die gew\u00e4hlten Feldst\u00fccke wurden wieder aktiviert.",
    "Die gew\u00e4hlten Kulturen wurden abgeschlossen. Die entsprechenden Felder sind jetzt f\u00fcr {next} aktiv.": "Die gew\u00e4hlten Kulturen wurden abgeschlossen. Die entsprechenden Felder sind jetzt f\u00fcr {next} aktiv.",
    "Die hochgeladenen Zonen stimmen nicht mit den selektierten Feldern \u00fcberein. FARMDOK erstellt eine Zone f\u00fcr jedes Feld ohne Zone. Welcher Wert soll diesen Zonen zugewiesen werden?": "Die hochgeladenen Zonen stimmen nicht mit den selektierten Feldern \u00fcberein. FARMDOK erstellt eine Zone f\u00fcr jedes Feld ohne Zone. Welcher Wert soll diesen Zonen zugewiesen werden?",
    "Diese Eigenschaft ist ein Pflichtfeld und kann nicht deaktiviert oder verschoben werden.": "Diese Eigenschaft ist ein Pflichtfeld und kann nicht deaktiviert oder verschoben werden.",
    "Diese Eigenschaft ist ein Pflichtfeld und kann nicht deaktiviert werden.": "Diese Eigenschaft ist ein Pflichtfeld und kann nicht deaktiviert werden.",
    "Diese Eigenschaft kann nicht verschoben werden.": "Diese Eigenschaft kann nicht verschoben werden.",
    "Diese Funktion ist sehr beliebt. Jetzt im Shop freischalten und weitere Vorteile nutzen!": "Diese Funktion ist sehr beliebt. Jetzt im Shop freischalten und weitere Vorteile nutzen!",
    "Dieses Feature unterst\u00fctzt derzeit nur einen gew\u00e4hlten Betrieb.": "Dieses Feature unterst\u00fctzt derzeit nur einen gew\u00e4hlten Betrieb.",
    "Dieses Dateiformat wird nicht unterst\u00fctzt": "Dieses Dateiformat wird nicht unterst\u00fctzt",
    "Dieses Produkt in anderen Lagern": "Dieses Produkt in anderen Lagern",
    "Dieses Problem nicht mehr anzeigen": "Dieses Problem nicht mehr anzeigen",
    "Differenz zwischen Zonen (%)": "Differenz zwischen Zonen (%)",
    "Direkt von eAMA": "Direkt von eAMA",
    "Dosierung": "Dosierung",
    "Download": "Download",
    "Dr\u00fcck bei Datenempf\u00e4nger FARMDOK GmbH die Taste \u201eEinwilligung starten\u201c und folge den Anweisungen.": "Dr\u00fcck bei Datenempf\u00e4nger FARMDOK GmbH die Taste \u201eEinwilligung starten\u201c und folge den Anweisungen.",
    "Dr\u00fccke \"Importieren\" um den Import abzuschlie\u00dfen.": "Dr\u00fccke \"Importieren\" um den Import abzuschlie\u00dfen.",
    "Du verfügst auf diesem Betrieb über keine Berechtigung für dieses Feature.": "Du verfügst auf diesem Betrieb über keine Berechtigung für dieses Feature.",
    "Du bist derzeit bei CNH-Industrial als {userEmail} eingeloggt. Wenn du mehr als ein Unternehmen, eine Landwirtschaft oder einen Betrieb hast, wähle bitte aus.": "Du bist derzeit bei CNH-Industrial als {userEmail} eingeloggt.  Wenn du mehr als ein Unternehmen, eine Landwirtschaft oder einen Betrieb hast, wähle bitte aus.",
    "Du bist auf MyJohnDeere mit <u>{userEmail}<\/u> eingeloggt. Falls du mehr als eine Organisation auf MyJohnDeere hast w\u00e4hle bitte die passende aus der Liste.": "Du bist auf MyJohnDeere mit <u>{userEmail}<\/u> eingeloggt. Falls du mehr als eine Organisation auf MyJohnDeere hast w\u00e4hle bitte die passende aus der Liste.",
    "Du entfernst ein Feld, das von deinem Vertragspartner bereitgestellt wurde. Gewählten Eintrag wirklich l\u00f6schen?": "Du entfernst ein Feld, das von deinem Vertragspartner bereitgestellt wurde. Gewählten Eintrag wirklich l\u00f6schen?",
    "Du entfernst Felder, die von deinem Vertragspartner bereitgestellt wurden. Gew\u00e4hlte Eintr\u00e4ge wirklich l\u00f6schen?": "Du entfernst Felder, die von deinem Vertragspartner bereitgestellt wurden. Gew\u00e4hlte Eintr\u00e4ge wirklich l\u00f6schen?",
    "Du hast bereits ein Konto?": "Du hast bereits ein Konto?",
    "Du hast derzeit keine Berechtigung auf einen Betrieb zuzugreifen.": "Du hast derzeit keine Berechtigung auf einen Betrieb zuzugreifen.",
    "Du hast keine Berechtigung ein neues Erntejahr hinzuzuf\u00fcgen.": "Du hast keine Berechtigung ein neues Erntejahr hinzuzuf\u00fcgen.",
    "Du hast keine E-Mail bekommen? \u00dcberpr\u00fcfe bitte den Spam-Ordner.": "Du hast keine E-Mail bekommen? \u00dcberpr\u00fcfe bitte den Spam-Ordner.",
    "Du hast noch kein Konto?": "Du hast noch kein Konto?",
    "Du hast noch keine Kontrakte angenommen.": "Du hast noch keine Kontrakte angenommen.",
    "Du importierst ein Feld.": "Du importierst ein Feld.",
    "Du importierst {count} Felder.": "Du importierst {count} Felder.",
    "Du kannst Fl\u00e4chen mit Dateien im Format Shape, XML, KML, XLS, XLSX und CSV hochladen.": "Du kannst Fl\u00e4chen mit Dateien im Format Shape, XML, KML, XLS, XLSX und CSV hochladen.",
    "Du kannst aber Erntedaten importieren, sobald sie bei {partner} verf\u00fcgbar sind.": "Du kannst aber Erntedaten importieren, sobald sie bei {partner} verf\u00fcgbar sind.",
    "Du kannst das auch bequem mit dem Smartphone oder Tablet auf der Couch machen oder auch alle deine Fl\u00e4chen auf einmal importieren.": "Du kannst das auch bequem mit dem Smartphone oder Tablet auf der Couch machen oder auch alle deine Fl\u00e4chen auf einmal importieren.",
    "Du kannst deine Felder anderen Benutzern freigeben. Du musst daf\u00fcr lediglich die E-Mail des Empf\u00e4ngers angeben.": "Du kannst deine Felder anderen Benutzern freigeben. Du musst daf\u00fcr lediglich die E-Mail des Empf\u00e4ngers angeben.",
    "Du kannst die Eigenschaften der neuen Felder noch anpassen.": "Du kannst die Eigenschaften der neuen Felder noch anpassen.",
    "Du kannst die Fl\u00e4chen direkt von eAMA mit AMA-Betriebsnummer und AMA-Pin importieren.": "Du kannst die Fl\u00e4chen direkt von eAMA mit AMA-Betriebsnummer und AMA-Pin importieren.",
    "Du kannst die Trennlinie durch Ziehen der Eckpunkte anpassen.": "Du kannst die Trennlinie durch Ziehen der Eckpunkte anpassen.",
    "Du l\u00f6schst damit auch alle zugeh\u00f6rigen Felder in allen Erntejahren.": "Du l\u00f6schst damit auch alle zugeh\u00f6rigen Felder in allen Erntejahren.",
    "Du musst in eAMA einmalig die Erlaubnis geben, dass deine Fl\u00e4chen in FARMDOK importieren werden d\u00fcrfen.": "Du musst in eAMA einmalig die Erlaubnis geben, dass deine Fl\u00e4chen in FARMDOK importieren werden d\u00fcrfen.",
    "Du musst zumindest ein Feld f\u00fcr den Import w\u00e4hlen.": "Du musst zumindest ein Feld f\u00fcr den Import w\u00e4hlen.",
    "Duplikate erstellen.": "Duplikate erstellen.",
    "Duplikate im Ziel-Betrieb\/Ziel-Erntejahr": "Duplikate im Ziel-Betrieb\/Ziel-Erntejahr",
    "Duplikate im Ziel-Erntejahr": "Duplikate im Ziel-Erntejahr",
    "Durch das Produkt werden die Inhaltsstoffe (N, P\u2082O\u2085, K\u2082O) definiert. Inhaltsstoffe in Prozent und Menge in kg\/ha k\u00f6nnen aber auch manuell eingegeben werden.": "Durch das Produkt werden die Inhaltsstoffe (N, P\u2082O\u2085, K\u2082O) definiert. Inhaltsstoffe in Prozent und Menge in kg\/ha k\u00f6nnen aber auch manuell eingegeben werden.",
    "Durch die Freigabe der ausgew\u00e4hlten Felder erh\u00e4lt Dein Vertragspartner SAATBAU LINZ eGen lesenden Zugriff auf die Felder sowie die darauf gebuchten Ma\u00dfnahmen und das Schlagblatt.": "Durch die Freigabe der ausgew\u00e4hlten Felder erh\u00e4lt Dein Vertragspartner SAATBAU LINZ eGen lesenden Zugriff auf die Felder sowie die darauf gebuchten Ma\u00dfnahmen und das Schlagblatt.",
    "Durch erstellen eines FARMDOK Accounts stimmst du den {agb} von FARMDOK zu.": "Durch erstellen eines FARMDOK Accounts stimmst du den {agb} von FARMDOK zu.",
    "Durchf\u00fchrungsdatum": "Durchf\u00fchrungsdatum",
    "Durchschnitt:": "Durchschnitt:",
    "Durchschnittlicher NDVI-Index": "Durchschnittlicher NDVI-Index",
    "Mehrjähriger Biomasseindex auf Basis Blattflächenindex": "Mehrjähriger Biomasseindex auf Basis Blattflächenindex",
    "D\u00fcngekarte": "D\u00fcngekarte",
    "D\u00fcngekarte f\u00fcr alle Kulturen": "D\u00fcngekarte f\u00fcr alle Kulturen",
    "D\u00fcngekarte f\u00fcr Getreide": "D\u00fcngekarte f\u00fcr Getreide",
    "D\u00fcngeplanung": "D\u00fcngeplanung",
    "D\u00fcngeverordnung": "D\u00fcngeverordnung",
    "D\u00fcngeziel": "D\u00fcngeziel",
    "D\u00fcngung": "D\u00fcngung",
    "D\u00dcNGUNG": "D\u00dcNGUNG",
    "Ebenen": "Ebenen",
    "E-Mail-Adresse": "E-Mail-Adresse",
    "E-Mail-Adresse eingeben": "E-Mail-Adresse eingeben",
    "E-mail des Users, der freigegeben hat": "E-mail des Users, der freigegeben hat",
    "Eigene Saatst\u00e4rke": "Eigene Saatst\u00e4rke",
    "Eigener Betrieb": "Eigener Betrieb",
    "Eigener D\u00fcnger": "Eigener D\u00fcnger",
    "Eigent\u00fcmer": "Eigent\u00fcmer",
    "Ein oder mehrere Felder haben eine ungültige Kontur": "Ein oder mehrere Felder haben eine ungültige Kontur",
    "Ein Feld mit diesem Namen existiert bereits.": "Ein Feld mit diesem Namen existiert bereits. Da Sie die Option 'Feldname eindeutig erforderlich' aktiviert haben, müssen Sie einen anderen Namen wählen.",
    "Ein Betriebsleiter kann dir Zugriff gew\u00e4hren, oder du erstellst dir hier deinen eigenen Betrieb.": "Ein Betriebsleiter kann dir Zugriff gew\u00e4hren, oder du erstellst dir hier deinen eigenen Betrieb.",
    "Eine \u00e4hnliche Ma\u00dfnahme mit gleichen Datum, T\u00e4tigkeit, Feld und Produkten existiert bereits.": "Eine \u00e4hnliche Ma\u00dfnahme mit gleichen Datum, T\u00e4tigkeit, Feld und Produkten existiert bereits.",
    "Eine Zeile pro Maschine": "Eine Zeile pro Maschine",
    "Eine Zeile pro Ma\u00dfnahme": "Eine Zeile pro Ma\u00dfnahme",
    "Eine Zeile pro Produkt": "Eine Zeile pro Produkt",
    "Einf\u00fcgen (oberhalb)": "Einf\u00fcgen (oberhalb)",
    "Einheit": "Einheit",
    "Einheit kg\/ha": "Einheit kg\/ha",
    "Einheiten": "Einheiten",
    "Einloggen": "Einloggen",
    "Einstellungen": "Einstellungen",
    "Einstellungen anzeigen": "Einstellungen anzeigen",
    "Einstellungen f\u00fcr die Zonierung": "Einstellungen f\u00fcr die Zonierung",
    "Einstellungen verstecken": "Einstellungen verstecken",
    "Einzelne, vollständige TASKDATA-Dateien müssen die Bezeichnung \"TASKDATA\" beinhalten. Zusammengehörige Dateien können nur gezipped hochgeladen werden.": "Einzelne, vollständige TASKDATA-Dateien müssen die Bezeichnung \"TASKDATA\" beinhalten. Zusammengehörige Dateien können nur gezipped hochgeladen werden.",
    "Empfangen": "Empfangen",
    "Empf\u00e4nger": "Empf\u00e4nger",
    "Enth\u00e4lt nicht:": "Enth\u00e4lt nicht:",
    "Enth\u00e4lt:": "Enth\u00e4lt:",
    "Ergebnis anpassen": "Ergebnis anpassen",
    "Erhalten": "Received",
    "Erledigt": "Erledigt",
    "Erledigt!": "Erledigt!",
    "Erneut senden": "Erneut senden",
    "Ernte": "Ernte",
    "Ernte abschlie\u00dfen": "Ernte abschlie\u00dfen",
    "Erntedaten": "Erntedaten",
    "Erntedaten f\u00fcr Fl\u00e4chen laden, die nicht \u00fcber FARMDOK an {partner} gemeldet wurden.": "Erntedaten f\u00fcr Fl\u00e4chen laden, die nicht \u00fcber FARMDOK an {partner} gemeldet wurden.",
    "Erntedaten f\u00fcr {processOrderName} von {partner} importieren.": "Erntedaten f\u00fcr {processOrderName} von {partner} importieren.",
    "Erntedaten laden": "Erntedaten laden",
    "Erntedatenimport von {partner}": "Erntedatenimport von {partner}",
    "Erntefl\u00e4che gesamt (ha)": "Erntefl\u00e4che gesamt (ha)",
    "Ernteg\u00fcter": "Ernteg\u00fcter",
    "Erntejahr": "Erntejahr",
    "Erntejahr abschlie\u00dfen": "Erntejahr abschlie\u00dfen",
    "Erntejahr beginnen": "Erntejahr beginnen",
    "Erntejahr w\u00e4hlen": "Erntejahr w\u00e4hlen",
    "Erntejahr w\u00e4hlen...": "Erntejahr w\u00e4hlen...",
    "Erntejahr {current} f\u00fcr die gew\u00e4hlten Kulturen abschlie\u00dfen und {next} als das aktive Jahr setzen.": "Erntejahr {current} f\u00fcr die gew\u00e4hlten Kulturen abschlie\u00dfen und {next} als das aktive Jahr setzen.",
    "Erntejahr {newYear} hinzuf\u00fcgen": "Erntejahr {newYear} hinzuf\u00fcgen",
    "Erntejahr-Status": "Erntejahr-Status",
    "Erntejahr:": "Erntejahr:",
    "Erntema\u00dfnahmen vorhanden": "Erntema\u00dfnahmen vorhanden",
    "Erntemenge gesamt (t)": "Erntemenge gesamt (t)",
    "Erntemenge gesamt: {totalPotatoes} t": "Erntemenge gesamt: {totalPotatoes} t",
    "Ersetze das Feld im Ziel": "Ersetze das Feld im Ziel",
    "Erstelle eine Kopie des Feldes im Ziel": "Erstelle eine Kopie des Feldes im Ziel",
    "Erstelle ein neues Erntejahr": "Erstelle ein neues Erntejahr",
    "Erstellt": "Erstellt",
    "Ertrag maximieren": "Ertrag maximieren",
    "Es existieren Ma\u00dfnahmen f\u00fcr die ausgew\u00e4hlten Felder. M\u00f6chtest du die Ma\u00dfnahmen auch kopieren?": "Es existieren Ma\u00dfnahmen f\u00fcr die ausgew\u00e4hlten Felder. M\u00f6chtest du die Ma\u00dfnahmen auch kopieren?",
    "Es existieren Ma\u00dfnahmen f\u00fcr die ausgew\u00e4hlten Felder. M\u00f6chtest du die Ma\u00dfnahmen auch verschieben?": "Es existieren Ma\u00dfnahmen f\u00fcr die ausgew\u00e4hlten Felder. M\u00f6chtest du die Ma\u00dfnahmen auch verschieben?",
    "Es existieren mehrere Felder am Ziel.": "Es existieren mehrere Felder am Ziel.",
    "Es hat noch kein Partner Felder bereitgestellt. Lade deine Partner ein, Felder in Farmdok zu kontrahieren.": "Es hat noch kein Partner Felder bereitgestellt. Lade deine Partner ein, Felder in Farmdok zu kontrahieren.",
    "Es ist ein unbekannter Fehler aufgetreten.": "Es ist ein unbekannter Fehler aufgetreten.",
    "Es k\u00f6nnen Dateien im Format {fileFormat} importiert werden.": "Es k\u00f6nnen Dateien im Format {fileFormat} importiert werden.",
    "Es k\u00f6nnen Dateien im Format ISOXML Taskdata oder CSV importiert werden.": "Es k\u00f6nnen Dateien im Format ISOXML Taskdata oder CSV importiert werden.",
    "Es k\u00f6nnen Dateien in den Formaten Shape, XML, KML, XLS, XLSX und CSV importiert werden.": "Es k\u00f6nnen Dateien in den Formaten Shape, XML, KML, XLS, XLSX und CSV importiert werden.",
    "Es konnte keine Task-Datei heruntergeladen werden": "Es konnte keine Task-Datei heruntergeladen werden",
    "Es konnten nicht alle Dateien hochgeladen werden": "Es konnten nicht alle Dateien hochgeladen werden",
    "Es k\u00f6nnen Dateien in den Formaten {fileFormats} und {lastFileFormat} importiert werden.": "Es k\u00f6nnen Dateien in den Formaten {fileFormats} und {lastFileFormat} importiert werden.",
    "Es m\u00fcssen mindestens 2 Felder gew\u00e4hlt sein.": "Es m\u00fcssen mindestens 2 Felder gew\u00e4hlt sein.",
    "Es sind Filter aktiv. Die folgenden Daten werden exportiert:": "Es sind Filter aktiv. Die folgenden Daten werden exportiert:",
    "Es sind {count} Felder ausgew\u00e4hlt.": "Es sind {count} Felder ausgew\u00e4hlt.",
    "Es trat ein Fehler auf, bitte versuchen Sie es erneut.": "Es trat ein Fehler auf, bitte versuchen Sie es erneut.",
    "Es werden keine Felder in {newYear} erstellt.": "Es werden keine Felder in {newYear} erstellt.",
    "Es wurden mehrere Operationen durchgef\u00fchrt. Bei mindestens einer ist ein Fehler aufgetreten.": "Es wurden mehrere Operationen durchgef\u00fchrt. Bei mindestens einer ist ein Fehler aufgetreten.",
    "Es wurden noch keine Felder angelegt. Erstelle neue Felder!": "Es wurden noch keine Felder angelegt. Erstelle neue Felder!",
    "Es wurden noch keine Felder \u00fcbermittelt. Lade deine Partner ein, Felder in Farmdok freizugeben.": "Es wurden noch keine Felder \u00fcbermittelt. Lade deine Partner ein, Felder in Farmdok freizugeben.",
    "Es wurden noch keine Maßnahmen angelegt. Erstelle neue Maßnahmen!": "Es wurden noch keine Maßnahmen angelegt. Erstelle neue Maßnahmen!",
    "Es wurden noch keine Dateien hochgeladen!": "Es wurden noch keine Dateien hochgeladen!",
    "Es wurden {count} Felder ausgew\u00e4hlt. Die Felder werden im n\u00e4chsten Schritt zu einem vereint.": "Es wurden {count} Felder ausgew\u00e4hlt. Die Felder werden im n\u00e4chsten Schritt zu einem vereint.",
    "Es wurden Felder mit verschiedenen Kulturen oder Sorten ausgewählt. Bitte beachte den Sorteneinfluss bei der Wahl der Mittel und Mengen. Mittel und Aufwandmengen müssen für alle ausgewählten Kulturen und Sorten geeignet sein!": "Es wurden Felder mit verschiedenen Kulturen oder Sorten ausgewählt. Bitte beachte den Sorteneinfluss bei der Wahl der Mittel und Mengen. Mittel und Aufwandmengen müssen für alle ausgewählten Kulturen und Sorten geeignet sein!",
    "Export": "Export",
    "Exporte": "Exporte",
    "Exportieren": "Exportieren",
    "Export pro Tabellenzeile": "Export pro Tabellenzeile",
    "FARMDOK Eigenschaft": "FARMDOK Eigenschaft",
    "FARMDOK Eigenschaft \"{fieldName}\" muss gew\u00e4hlt sein.": "FARMDOK Eigenschaft \"{fieldName}\" muss gew\u00e4hlt sein.",
    "FARMDOK Feldpositionen": "FARMDOK Feldpositionen",
    "FARMDOK konnte den Ursprung deiner Daten leider nicht eindeutig feststellen. Bitte w\u00e4hle aus der folgenden Liste den richtigen Eintrag aus:": "FARMDOK konnte den Ursprung deiner Daten leider nicht eindeutig feststellen. Bitte w\u00e4hle aus der folgenden Liste den richtigen Eintrag aus:",
    "FSNr": "FSNr",
    "FSNr - Schlag": "FSNr - Schlag",
    "Fahrzeit": "Fahrzeit",
    "Fahrzeit minimieren": "Fahrzeit minimieren",
    "Falls deine Exportdateien als zip-Datei zur Verf\u00fcgung stehen, kannst du ganz einfach diese zip-Datei inkl. aller Daten in das wei\u00dfe Rechteck ziehen.": "Falls deine Exportdateien als zip-Datei zur Verf\u00fcgung stehen, kannst du ganz einfach diese zip-Datei inkl. aller Daten in das wei\u00dfe Rechteck ziehen.",
    "Falls du einen anderen MyJohnDeere Account verwenden m\u00f6chtest logge dich bitte auf MyJohnDeere.com aus. Dann trenne die Verbindung in FARMDOK. Danach kannst du dich mit dem neuen Account verbinden.": "Falls du einen anderen MyJohnDeere Account verwenden m\u00f6chtest logge dich bitte auf MyJohnDeere.com aus. Dann trenne die Verbindung in FARMDOK. Danach kannst du dich mit dem neuen Account verbinden.",
    "Fast am Ziel!": "Fast am Ziel!",
    "Favoriten": "Favoriten",
    "Fehler": "Fehler",
    "Fehlerhaft/unvollst\u00e4ndig": "Fehlerhaft/unvollst\u00e4ndig",
    "Feld": "Feld",
    "Feld befindet sich im Wasserschutzgebiet": "Feld befindet sich im Wasserschutzgebiet",
    "Feld 1": "Feld 1",
    "Feld 2": "Feld 2",
    "Feld Nr": "Feld Nr",
    "Feld Nummer:": "Feld Nummer:",
    "Feld aufteilen": "Feld aufteilen",
    "Feld ausw\u00e4hlen": "Feld ausw\u00e4hlen",
    "Feld verschieben": "Feld verschieben",
    "Feld {number}": "Feld {number}",
    "Feld-Nummer": "Feld-Nummer",
    "Feld-\u00dcbersicht": "Feld-\u00dcbersicht",
    "Felder": "Felder",
    "Felder aktualisieren.": "Felder aktualisieren.",
    "Felder ausw\u00e4hlen": "Felder ausw\u00e4hlen",
    "Felder bearbeiten": "Felder bearbeiten",
    "Felder dürfen nicht die gleichen Namen enthalten.": "Sie haben die Option 'Feldname eindeutig erforderlich' aktiviert. Daher müssen Feldernamen einzigartig sein.",
    "Felder einfärben": "Felder einfärben",
    "Felder exportieren": "Felder exportieren",
    "Felder freigeben": "Felder freigeben",
    "Felder hinzuf\u00fcgen": "Felder hinzuf\u00fcgen",
    "Felder importieren": "Felder importieren",
    "Felder kombinieren": "Felder kombinieren",
    "Felder kopieren": "Felder kopieren",
    "Felder kopieren anstatt zu verschieben.": "Felder kopieren anstatt zu verschieben.",
    "Felder nicht kopieren.": "Felder nicht kopieren.",
    "Felder nicht verschieben.": "Felder nicht verschieben.",
    "Felder teilen ist nur für Einzelpolygone möglich": "Felder teilen ist nur für Einzelpolygone möglich",
    "Felder und Geodaten teilen": "Felder und Geodaten teilen",
    "Felder verschieben": "Felder verschieben",
    "Felder wiederherstellen": "Felder wiederherstellen",
    "Felder wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden": "Felder wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden",
    "Felder zusammenlegen": "Felder zusammenlegen",
    "Feldgr\u00f6\u00dfe": "Feldgr\u00f6\u00dfe",
    "Feldkontur l\u00f6schen": "Feldkontur l\u00f6schen",
    "Feldkontur zeichnen": "Feldkontur zeichnen",
    "Feldname immer anzeigen": "Feldname immer anzeigen",
    "Feldnutzung": "Feldnutzung",
    "Fortschritt": "Fortschritt",
    "Feldmitte markieren": "Feldmitte markieren",
    "Feldpositionen": "Feldpositionen",
    "Feldst\u00fcck": "Feldst\u00fcck",
    "Kunde - Feldst\u00fcck - Feld": "Kunde - Feldst\u00fcck - Feld",
    "Feldst\u00fcck - Feldnummer - Feld": "Feldst\u00fcck - Feldnummer - Feld",
    "Feldst\u00fcck Nr": "Feldst\u00fcck Nr",
    "Feldst\u00fcck Nummer": "Feldst\u00fcck Nummer",
    "Feldst\u00fcck Nummer:": "Feldst\u00fcck Nummer:",
    "Feldst\u00fcck hinzuf\u00fcgen": "Feldst\u00fcck hinzuf\u00fcgen",
    "Feldst\u00fcck konnte nicht wieder hergestellt werden.": "Feldst\u00fcck konnte nicht wieder hergestellt werden.",
    "Feldst\u00fcck-Nummer": "Feldst\u00fcck-Nummer",
    "Feldst\u00fccke": "Feldst\u00fccke",
    "Feldst\u00fccke wiederherstellen": "Feldst\u00fccke wiederherstellen",
    "Feldst\u00fccke wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden": "Feldst\u00fccke wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden",
    "Feldst\u00fccknummer - Feldname": "Feldst\u00fccknummer - Feldname",
    "Feldst\u00fccksliste": "Feldst\u00fccksliste",
    "Fertig! Die Felder\/Feldst\u00fccke inkl. Geokonturen wurden importiert und stehen f\u00fcr die Ma\u00dfnahmenbuchung zur Verf\u00fcgung!": "Fertig! Die Felder\/Feldst\u00fccke inkl. Geokonturen wurden importiert und stehen f\u00fcr die Ma\u00dfnahmenbuchung zur Verf\u00fcgung!",
    "Fertigstellen": "Fertigstellen",
    "Feuchtgebiet": "Feuchtgebiet",
    "Fileupload zu John Deere fehlgeschlagen.": "Fileupload zu John Deere fehlgeschlagen.",
    "Filter aktiviert - {linkStart}Filter deaktivieren{linkEnd} um alle Eintr\u00e4ge anzuzeigen.": "Filter aktiviert - {linkStart}Filter deaktivieren{linkEnd} um alle Eintr\u00e4ge anzuzeigen.",
    "Filter entfernen": "Filter entfernen",
    "Fl\u00e4chen": "Fl\u00e4chen",
    "Fl\u00e4chen ansehen": "Fl\u00e4chen ansehen",
    "Fl\u00e4chen aus dem Nachfolgejahr \u00fcbernehmen": "Fl\u00e4chen aus dem Nachfolgejahr \u00fcbernehmen",
    "Fl\u00e4chen aus dem Vorjahr \u00fcbernehmen": "Fl\u00e4chen aus dem Vorjahr \u00fcbernehmen",
    "Fl\u00e4chen senden": "Fl\u00e4chen senden",
    "Fl\u00e4chennutzung": "Fl\u00e4chennutzung",
    "Fl\u00e4chensumme": "Fl\u00e4chensumme",
    "Fl\u00e4chen\u00fcbermittlung": "Fl\u00e4chen\u00fcbermittlung",
    "Frei": "Frei",
    "Freigabedatum": "Freigabedatum",
    "Freigaben entfernen": "Freigaben entfernen",
    "Freigaben jetzt entfernen.": "Freigaben jetzt entfernen.",
    "Freigeben": "Freigeben",
    "Freigeben bis {date}.": "Freigeben bis {date}.",
    "Freigeben bis:": "Freigeben bis:",
    "Freigegeben": "Freigegeben",
    "Freigegebene Felder": "Freigegebene Felder",
    "Freigabe entfernen": "Freigabe entfernen",
    "Freunde einladen": "Freunde einladen",
    "Fruchtfolge": "Fruchtfolge",
    "FSNr-Schlag": "FSNr-Schlag",
    "Futter": "Futter",
    "F\u00fcr das ausgew\u00e4hlte Feld fehlen die Geo-Daten.": "F\u00fcr das ausgew\u00e4hlte Feld fehlen die Geo-Daten.",
    "F\u00fcr die Auswertung werden Daten der Sentinel Erdbeobachtungs\u00adsatelliten verwendet. Satelliten- & Wetterdaten zeigen Biomassezuwachs (NDVI- und MSAVI2-Index), Stickstoffverbrauch (REIP-Index) und Wassergehalt (NDWI-Index) auf. Sie sind die optimale Unterst\u00fctzung f\u00fcr effektive Produktionsentscheidungen.": "F\u00fcr die Auswertung werden Daten der Sentinel Erdbeobachtungs\u00adsatelliten verwendet. Satelliten- & Wetterdaten zeigen Biomassezuwachs (NDVI- und MSAVI2-Index), Stickstoffverbrauch (REIP-Index) und Wassergehalt (NDWI-Index) auf. Sie sind die optimale Unterst\u00fctzung f\u00fcr effektive Produktionsentscheidungen.",
    "F\u00fcr diese Einheit ist eine Gesamteingabe nicht m\u00f6glich.": "F\u00fcr diese Einheit ist eine Gesamteingabe nicht m\u00f6glich.",
    "F\u00fcr Fragen steht Ihr regionaler Berater gerne zur Verf\u00fcgung (Beraterzuordnung nach Region).": "F\u00fcr Fragen steht Ihr regionaler Berater gerne zur Verf\u00fcgung (Beraterzuordnung nach Region).",
    "F\u00fcr jede Datei wird ein Link generiert. GeoJSON-Links k\u00f6nnen im Webbrowser ge\u00f6ffnet werden. Max. 10 MB pro Datei.": "F\u00fcr jede Datei wird ein Link generiert. GeoJSON-Links k\u00f6nnen im Webbrowser ge\u00f6ffnet werden. Max. 10 MB pro Datei.",
    "GW Gebiete": "GW Gebiete",
    "GW-Gebiet": "GW-Gebiet",
    "Feuchtgebiet oder Trockengebiet": "Feuchtgebiet oder Trockengebiet",
    "Ged\u00fcngt": "Ged\u00fcngt",
    "Gemeinde": "Gemeinde",
    "Gem\u00fcse": "Gem\u00fcse",
    "Geplant": "Geplant",
    "Geplant setzen": "Geplant setzen",
    "Gering": "Gering",
    "gering": "gering",
    "Gesamtanzahl der bereitgestellten Felder": "Gesamtanzahl der bereitgestellten Felder",
    "Gesamtanzahl": "Gesamtanzahl",
    "Gesamtfl\u00e4che": "Gesamtfl\u00e4che",
    "Gesamtfl\u00e4che aus Erntedaten: {totalSize} ha": "Gesamtfl\u00e4che aus Erntedaten: {totalSize} ha",
    "Gesamtfl\u00e4che {totalArea} ha": "Gesamtfl\u00e4che {totalArea} ha",
    "Gesamtfl\u00e4che in ha": "Gesamtfl\u00e4che in ha",
    "Gesamt": "Gesamt",
    "Gesamtmenge": "Gesamtmenge",
    "Geschafft!": "Geschafft!",
    "Gesendet": "Gesendet",
    "Geteilt von": "Geteilt von",
    "Geteilte Informationen:": "Geteilte Informationen:",
    "GIS-Layer": "GIS-Layer",
    "Gew\u00e4hlten Eintrag wirklich l\u00f6schen?": "Gew\u00e4hlten Eintrag wirklich l\u00f6schen?",
    "Gew\u00e4hlte Eintr\u00e4ge wirklich l\u00f6schen?": "Gew\u00e4hlte Eintr\u00e4ge wirklich l\u00f6schen?",
    "Gew\u00e4hlte Fl\u00e4che: {sizeTotalFormatted} ha": "Gew\u00e4hlte Fl\u00e4che: {sizeTotalFormatted} ha",
    "Gleiche Zonengr\u00f6\u00dfe": "Gleiche Zonengröße",
    "Gleicher Indexabstand": "Gleicher Indexabstand",
    "Grobe T\u00e4tigkeiten": "Grobe T\u00e4tigkeiten",
    "Gruppiert": "Gruppiert",
    "Gr\u00f6\u00dfe": "Gr\u00f6\u00dfe",
    "Gr\u00f6\u00dfe (ha)": "Gr\u00f6\u00dfe (ha)",
    "Gr\u00f6\u00dfe (in ha)": "Gr\u00f6\u00dfe (in ha)",
    "Gr\u00f6\u00dfe in ha": "Gr\u00f6\u00dfe in ha",
    "Gr\u00f6\u00dfe (in ha):": "Gr\u00f6\u00dfe (in ha):",
    "Gr\u00f6\u00dfer als:": "Gr\u00f6\u00dfer als:",
    "Gr\u00fcnland und Feldfutterbau": "Gr\u00fcnland und Feldfutterbau",
    "Gutscheine verwalten": "Gutscheine verwalten",
    "Heil- und Gew\u00fcrzpflanzen": "Heil- und Gew\u00fcrzpflanzen",
    "Hektar": "Hektar",
    "Herbstantrag (HA)": "Herbstantrag (HA)",
    "Herunterladen": "Herunterladen",
    "Hier gehts zur Registrierung.": "Hier gehts zur Registrierung.",
    "Hier kannst du alle Freigaben f\u00fcr einen Betrieb im aktuellen Erntejahr entfernen.": "Hier kannst du alle Freigaben f\u00fcr einen Betrieb im aktuellen Erntejahr entfernen.",
    "Hier kannst du dein neues Passwort eingeben.": "Hier kannst du dein neues Passwort eingeben.",
    "Hier kannst du deine eigenen Zonenkarten hochladen um daraus eine Applikationskarte zu erstellen. Bitte verwende Daten im Shape-Format.": "Hier kannst du deine eigenen Zonenkarten hochladen um daraus eine Applikationskarte zu erstellen. Bitte verwende Daten im Shape-Format.",
    "Hilfe": "Hilfe",
    "Low": "Niedrig ::: niedriger",
    "Medium": "Mittel",
    "High": "Hoch ::: höher",
    "Hinweise f\u00fcr": "Hinweise f\u00fcr",
    "Hinweis: die maximale Prüfdistanz beträgt": "Hinweis: die maximale Prüfdistanz beträgt {maxDistance}.",
    "Hoch": "Hoch",
    "hoch": "hoch",
    "Hochgeladen": "Hochgeladen",
    "Hochformat": "Hochformat",
    "Humus": "Humus",
    "Häufigkeit": "Häufigkeit",
    "Isolationszonen": "Isolationszonen",
    "Isolationszone erstellen": "Isolationszone erstellen",
    "Isolationszone bearbeiten": "Isolationszone bearbeiten",
    "Isolationszone löschen": "Isolationszone löschen",
    "ISOXML": "ISOXML",
    "Ich habe bereits einen Account": "Ich habe bereits einen Account",
    "Ich habe die Vereinbarungen gelesen und stimme der Daten\u00fcbertragung von FARMDOK zu {partner} zu genannten Zwecken ausdr\u00fccklich zu.": "Ich habe die Vereinbarungen gelesen und stimme der Daten\u00fcbertragung von FARMDOK zu {partner} zu genannten Zwecken ausdr\u00fccklich zu.",
    "Ich habe die Vereinbarungen gelesen und stimme der Daten\u00fcbertragung zu genannten Zwecken ausdr\u00fccklich zu.": "Ich habe die Vereinbarungen gelesen und stimme der Daten\u00fcbertragung zu genannten Zwecken ausdr\u00fccklich zu.",
    "Ich verstehe, Services & Support erfolgen per E-Mail.": "Ich verstehe, Services & Support erfolgen per E-Mail.",
    "Ihre Organisation auf MyPLM® Connect, AFS Connect oder S-FLEET": "Ihre Organisation auf MyPLM® Connect, AFS Connect oder S-FLEET",
    "Im Erntejahr {previous} wurde ein Feld auf aktiv gestellt.": "Im Erntejahr {previous} wurde ein Feld auf aktiv gestellt.",
    "Im Erntejahr {previous} wurden {numberOfNewActiveFieldsInPrevious} Felder auf aktiv gestellt.": "Im Erntejahr {previous} wurden {numberOfNewActiveFieldsInPrevious} Felder auf aktiv gestellt.",
    "Im Shape-Format exportieren": "Im Shape-Format exportieren",
    "Import in Farmdok": "Import in Farmdok",
    "Import zur\u00fccksetzen": "Import zur\u00fccksetzen",
    "importiert": "importiert",
    "Importieren": "Importieren",
    "Importiere Dateien in den Formaten Shape und GeoJSON.": "Importiere Dateien in den Formaten Shape und GeoJSON.",
    "Importieren von Ma\u00dfnahmen aus externen Quellen": "Importieren von Ma\u00dfnahmen aus externen Quellen",
    "Importiere Dateien in den Formaten Shape und GeoJSON. F\u00fcr jede Datei wird ein Link generiert. GeoJSON-Links k\u00f6nnen im Webbrowser ge\u00f6ffnet werden.": "Dateien im Format Shape oder GeoJSON importieren. F\u00fcr jede Datei wird ein Link generiert. GeoJSON-Links k\u00f6nnen im Webbrowser ge\u00f6ffnet werden.",
    "Maßnahmen aus AFS Connect, S-Fleet und PLMConnect importieren": "Maßnahmen aus AFS Connect, S-Fleet und PLMConnect importieren",
    "Impressum": "Impressum",
    "In FARMDOK nach dem Import.": "In FARMDOK nach dem Import.",
    "In der hochgeladenen Datei befinden sich mehrere Werte. Welcher davon soll zum Erstellen der Zonen benutzt werden?": "In der hochgeladenen Datei befinden sich mehrere Werte. Welcher davon soll zum Erstellen der Zonen benutzt werden?",
    "In diesem Erntejahr sind noch keine Fl\u00e4chen verf\u00fcgbar.": "In diesem Erntejahr sind noch keine Fl\u00e4chen verf\u00fcgbar.",
    "Index ausw\u00e4hlen": "Index ausw\u00e4hlen",
    "Inhaltsstoffe des D\u00fcngers in %": "Inhaltsstoffe des D\u00fcngers in %",
    "Inhaltsstoffe des D\u00fcngers in % und Ausbringmenge am Feld in kg/ha": "Inhaltsstoffe des D\u00fcngers in % und Ausbringmenge am Feld in kg/ha",
    "Inhaltsstoffe in %": "Inhaltsstoffe in %",
    "Ist genau:": "Ist genau:",
    "Ja, Ma\u00dfnahmen auch in das Ziel-Erntejahr kopieren.": "Ja, Ma\u00dfnahmen auch in das Ziel-Erntejahr kopieren.",
    "Ja, Ma\u00dfnahmen auch in das Ziel-Erntejahr verschieben.": "Ja, Ma\u00dfnahmen auch in das Ziel-Erntejahr verschieben.",
    "Ja, ich will den FARMDOK Newsletter bekommen!": "Ja, ich will den FARMDOK Newsletter bekommen!",
    "Ja": "Ja",
    "Jahr": "Jahr",
    "Jahre": "Jahre",
    "Jetzt Lizenz upgraden": "Jetzt Lizenz upgraden",
    "Jetzt kaufen": "Jetzt kaufen",
    "Jetzt starten": "Jetzt starten",
    "Jetzt starten!": "Jetzt starten!",
    "Jetzt verschieben": "Jetzt verschieben",
    "Jetzt upgraden auf FARMDOK PERFORMANCE!": "Jetzt upgraden auf FARMDOK PERFORMANCE!",
    "K": "K",
    "Karte": "Karte",
    "Karte erstellen": "Karte erstellen",
    "Karte anzeigen": "Karte anzeigen",
    "Katastralgemeinde": "Katastralgemeinde",
    "Kein Betrieb gew\u00e4hlt": "Kein Betrieb gew\u00e4hlt",
    "Kein Enddatum festlegen": "Kein Enddatum festlegen",
    "Kein Enddatum gesetzt.": "Kein Enddatum gesetzt.",
    "Kein g\u00fcltiger Sch\u00e4dling vorhanden": "Kein g\u00fcltiger Sch\u00e4dling vorhanden",
    "Kein weiteres Feld": "Kein weiteres Feld",
    "Kein Vorschlag (manuelle Eingabe)": "Kein Vorschlag (manuelle Eingabe)",
    "Keine": "Keine",
    "Keine Daten verf\u00fcgbar.": "Keine Daten verf\u00fcgbar.",
    "Keine Eintr\u00e4ge vorhanden.": "Keine Eintr\u00e4ge vorhanden.",
    "Keine Ergebnisse gefunden. \u00c4ndere den Suchbegriff.": "Keine Ergebnisse gefunden. \u00c4ndere den Suchbegriff.",
    "Keine Felder vorhanden": "Keine Felder vorhanden",
    "Keine Feldposition": "Keine Feldposition",
    "Keine Kontrakte vorhanden.": "Keine Kontrakte vorhanden.",
    "Keine Kultur": "Keine Kultur",
    "Keine Tätigkeit": "Keine Tätigkeit",
    "Keine Kultur gepflegt": "Keine Kultur gepflegt",
    "Keine Kulturdaten vorhanden.": "Keine Kulturdaten vorhanden.",
    "Keine Organisation in deinem John Deere Account vorhanden.": "Keine Organisation in deinem John Deere Account vorhanden.",
    "Keine Satellitendaten f\u00fcr den ausgew\u00e4hlten Zeitraum verf\u00fcgbar.": "Keine Satellitendaten f\u00fcr den ausgew\u00e4hlten Zeitraum verf\u00fcgbar.",
    "Keine Wetterdaten für den ausgewählten Zeitraum verfügbar.": "Keine Wetterdaten für den ausgewählten Zeitraum verfügbar.",
    "Keine Shape-Datei im Zip gefunden.": "Keine Shape-Datei im Zip gefunden.",
    "Keine Sorte": "Keine Sorte",
    "Keine aktiven Kulturen im aktuellen Erntejahr vorhanden.": "Keine aktiven Kulturen im aktuellen Erntejahr vorhanden.",
    "Keine Warnhinweise gefunden": "Keine Warnhinweise gefunden",
    "Keines der gew\u00e4hlten Felder besitzt eine Kontur.": "Keines der gew\u00e4hlten Felder besitzt eine Kontur.",
    "Kleiner als:": "Kleiner als:",
    "Klicke auf \"Aufteilen\" um zwei neue Felder zu erstellen.": "Klicke auf \"Aufteilen\" um zwei neue Felder zu erstellen.",
    "Klicke auf den Link in der E-Mail um dein Passwort zur\u00fcckzusetzen.": "Klicke auf den Link in der E-Mail um dein Passwort zur\u00fcckzusetzen.",
    "Klicke auf den Link in der E-Mail um deinen Account zu aktivieren.": "Klicke auf den Link in der E-Mail um deinen Account zu aktivieren.",
    "Klicke hier f\u00fcr mehr Infos ...": "Klicke hier f\u00fcr mehr Infos ...",
    "Klicke hier um dich einzuloggen.": "Klicke hier um dich einzuloggen.",
    "Klicke in der Karte auf ein Feld um es hinzuzuf\u00fcgen.": "Klicke in der Karte auf ein Feld um es hinzuzuf\u00fcgen.",
    "Klicke in der Karte um ein neues Feld hinzuzuf\u00fcgen.": "Klicke in der Karte um ein neues Feld hinzuzuf\u00fcgen.",
    "Klicke in der Karte um eine Trennlinie zu zeichnen. Klicke zwei mal schnell hintereinander um das Zeichnen zu beenden.": "Klicke in der Karte um eine Trennlinie zu zeichnen. Klicke zwei mal schnell hintereinander um das Zeichnen zu beenden.",
    "Klicke in die Karte, um die Auswahl zu ver\u00e4ndern.": "Klicke in die Karte, um die Auswahl zu ver\u00e4ndern.",
    "Klicken oder Zip-Datei hier ablegen.": "Klicken oder Zip-Datei hier ablegen.",
    "KMZ": "KMZ",
    "Kombinieren": "Kombinieren",
    "Kommentar": "Kommentar",
    "Kontakt E-Mail": "Kontakt E-Mail",
    "Kontrahiert": "Kontrahiert",
    "Konventionell": "Konventionell",
    "Konflikt": "Konflikt",
    "Kornqualit\u00e4t": "Kornqualit\u00e4t",
    "Kosten senken": "Kosten senken",
    "Kostenrechnung": "Kostenrechnung",
    "Kultur": "Kultur",
    "Kultur f\u00fcr alle Felder \u00fcbernehmen": "Kultur f\u00fcr alle Felder \u00fcbernehmen",
    "Kultur in der Datenquelle (aus dem Import)": "Kultur in der Datenquelle (aus dem Import)",
    "Kultur:": "Kultur:",
    "Kulturen": "Kulturen",
    "Kulturen & Greening-Kennzahlen": "Kulturen & Greening-Kennzahlen",
    "Kulturen ohne Erntema\u00dfnahmen": "Kulturen ohne Erntema\u00dfnahmen",
    "Kulturen, Feldst\u00fccknutzung, Anbaudiversivizierung und \u00f6kologische Vorrangfl\u00e4chen.": "Kulturen, Feldst\u00fccknutzung, Anbaudiversivizierung und \u00f6kologische Vorrangfl\u00e4chen.",
    "Kulturverh\u00e4ltnis": "Kulturverh\u00e4ltnis",
    "Kunde": "Kunde",
    "Kunden": "Kunden",
    "Kundennummer": "Kundennummer",
    "Kundenverwaltung": "Kundenverwaltung",
    "Kundenzufriedenheit erh\u00f6hen": "Kundenzufriedenheit erh\u00f6hen",
    "K\u2082O-Klasse": "K\u2082O-Klasse",
    "LAI - Blattfl\u00e4chenindex": "LAI - Blattfl\u00e4chenindex",
    "Lade hoch ...": "Lade hoch ...",
    "Lade..": "Lade..",
    "Lade...": "Lade...",
    "Drohnenbilder hochladen, um ein neues Assessment zu starten": "Drohnenbilder hochladen, um ein neues Assessment zu starten",
    "Lager": "Lager",
    "Lagerbewegungen": "Lagerbewegungen",
    "Lagerbewegung anlegen": "Lagerbewegung anlegen",
    "Lagerst\u00e4nde": "Lagerst\u00e4nde",
    "Lagerstand anwenden": "Lagerstand anwenden",
    "Lagerstand zu niedrig": "Lagerstand zu niedrig",
    "Land": "Land",
    "Grundstückskataster": "Grundstückskataster",
    "Landwirt": "Landwirt",
    "Lass uns zusammen arbeiten!": "Lass uns zusammen arbeiten!",
    "Leg deine Datei hier ab": "Leg deine Datei hier ab",
    "Legendentitel": "Legendentitel",
    "Legende": "Legende",
    "Leider k\u00f6nnen f\u00fcr dieses Feld keine Wetterdaten geladen werden.": "Leider k\u00f6nnen f\u00fcr dieses Feld keine Wetterdaten geladen werden.",
    "Link": "Link",
    "Link kopieren": "Link kopieren",
    "Link in die Zwischenablage kopieren": "Link in die Zwischenablage kopieren",
    "Link zu AGES Register": "Link zu AGES Register",
    "Link zur Datei": "Link zur Datei",
    "Liste aller Felder gruppiert nach Kulturen.": "Liste aller Felder gruppiert nach Kulturen.",
    "Liste aller Feldgruppen und Felder im gew\u00e4hlten Erntejahr.": "Liste aller Feldgruppen und Felder im gew\u00e4hlten Erntejahr.",
    "Liste aller Feldst\u00fccke und Schl\u00e4ge im gew\u00e4hlten Erntejahr.": "Liste aller Feldst\u00fccke und Schl\u00e4ge im gew\u00e4hlten Erntejahr.",
    "Liter pro m\u00b2 Niederschlag im {month} {year}": "Liter pro m\u00b2 Niederschlag im {month} {year}",
    "Liter pro m\u00b2 Niederschlag in {year}": "Liter pro m\u00b2 Niederschlag in {year}",
    "Live check l\u00e4uft...": "Live check l\u00e4uft...",
    "Login": "Login",
    "Login mit MyJohnDeere": "Login mit MyJohnDeere",
    "Login mit CNH Platformen": "Login mit CNH Platformen",
    "Lohnunternehmer": "Lohnunternehmer",
    "L\u00f6schen": "L\u00f6schen",
    "MSAVI2 - Biomasse": "MSAVI2 - Biomasse",
    "MAISBUTLER Anfrage": "MAISBUTLER Anfrage",
    "Mais": "Mais",
    "Manuelle Eingabe": "Manuelle Eingabe",
    "Maschine": "Maschine",
    "Mahlen": "Mahlen",
    "Maschine ausw\u00e4hlen": "Maschine ausw\u00e4hlen",
    "Maschine entfernen": "Maschine entfernen",
    "Maschine hinzuf\u00fcgen": "Maschine hinzuf\u00fcgen",
    "Maschinen": "Maschinen",
    "Maschinen & Ger\u00e4te": "Maschinen & Ger\u00e4te",
    "Maschinen & Ger\u00e4te Kategorien": "Maschinen & Ger\u00e4te Kategorien",
    "Maschinen & Ger\u00e4te Listen": "Maschinen & Ger\u00e4te Listen",
    "Material": "Material",
    "Materialauswahl": "Materialauswahl",
    "Max. Dateigr\u00f6\u00dfe: {maxFileSize} MB": "Max. Dateigr\u00f6\u00dfe: {maxFileSize} MB",
    "Maximale Anzahl von Anwendungen:": "Maximale Anzahl von Anwendungen:",
    "Maximale Anzahl von Anwendungen pro Erntejahr:": "Maximale Anzahl von Anwendungen pro Erntejahr:",
    "Maximale Saatst\u00e4rke": "Maximale Saatst\u00e4rke",
    "Maximale Menge": "Maximale Menge",
    "Maximale Reduktion der Produkt-Konzentration": "Maximale Reduktion der Produkt-Konzentration",
    "Maximum:": "Maximum:",
    "Maximale Anwendungsmenge überschritten!": "Maximale Anwendungsmenge überschritten!",
    'Ma\u00dfnahme erfolgreich verschoben nach {cropYear} auf {companyName}': 'Ma\u00dfnahme erfolgreich verschoben nach {cropYear} auf {companyName}',
    'Ma\u00dfnahmen erfolgreich verschoben nach {cropYear} auf {companyName}': 'Ma\u00dfnahmen erfolgreich verschoben nach {cropYear} auf {companyName}',
    "Ma\u00dfnahme konnte nicht wieder hergestellt werden.": "Ma\u00dfnahme konnte nicht wieder hergestellt werden.",
    "Ma\u00dfnahme hinzufügen": "Ma\u00dfnahme hinzufügen",
    "Ma\u00dfnahme und Datum ausw\u00e4hlen": "Ma\u00dfnahme und Datum ausw\u00e4hlen",
    "Ma\u00dfnahmen": "Ma\u00dfnahmen",
    "Ma\u00dfnahmen Neu - Work In Progress": "Ma\u00dfnahmen Neu - Work In Progress",
    "Ma\u00dfnahmen auf beide Felder kopieren": "Ma\u00dfnahmen auf beide Felder kopieren",
    "Ma\u00dfnahmen auf {fieldName} ({fieldSize} ha) \u00fcbernehmen": "Ma\u00dfnahmen auf {fieldName} ({fieldSize} ha) \u00fcbernehmen",
    "Ma\u00dfnahmen buchen": "Ma\u00dfnahmen buchen",
    "Ma\u00dfnahme duplizieren": "Ma\u00dfnahme duplizieren",
    "Ma\u00dfnahmen duplizieren": "Ma\u00dfnahmen duplizieren",
    "Ma\u00dfnahme erfolgreich dupliziert": "Ma\u00dfnahme erfolgreich dupliziert",
    "Ma\u00dfnahmen erfolgreich dupliziert": "Ma\u00dfnahmen erfolgreich dupliziert",
    "Ma\u00dfnahmen importieren": "Ma\u00dfnahmen importieren",
    "Ma\u00dfnahme konnte nicht dupliziert werden": "Ma\u00dfnahme konnte nicht dupliziert werden",
    "Ma\u00dfnahmen konnten nicht dupliziert werden": "Ma\u00dfnahmen konnten nicht dupliziert werden",
    "Ma\u00dfnahmen l\u00f6schen": "Ma\u00dfnahmen l\u00f6schen",
    "Ma\u00dfnahme konnte nicht erfolgreich verschoben werden nach {cropYear} auf {companyName}": "Ma\u00dfnahme konnte nicht erfolgreich verschoben werden nach {cropYear} auf {companyName}",
    "Ma\u00dfnahmen konnten nicht erfolgreich verschoben werden nach {cropYear} auf {companyName}": "Ma\u00dfnahmen konnten nicht erfolgreich verschoben werden nach {cropYear} auf {companyName}",
    "Ma\u00dfnahmen mit ISOXML Taskdata importieren": "Ma\u00dfnahmen mit ISOXML Taskdata importieren",
    "Ma\u00dfnahmen speichern": "Ma\u00dfnahmen speichern",
    "Ma\u00dfnahmen teilen": "Ma\u00dfnahmen teilen",
    "Ma\u00dfnahme verschieben": "Ma\u00dfnahme verschieben",
    "Ma\u00dfnahmen verschieben": "Ma\u00dfnahmen verschieben",
    "Ma\u00dfnahmen wiederherstellen": "Ma\u00dfnahmen wiederherstellen",
    "Ma\u00dfnahmen wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden": "Ma\u00dfnahmen wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden",
    "Ma\u00dfnahmen \u00fcbernehmen": "Ma\u00dfnahmen \u00fcbernehmen",
    "Maßnahmen von CNH-Industrial importieren": "Maßnahmen von CNH-Industrial importieren",
    "Mapping zur\u00fccksetzen": "Mapping zur\u00fccksetzen",
    "MBI": "MBI",
    "MBI - Mehrjährige Biomasse": "MBI - Mehrjährige Biomasse",
    "mechanische Pflegema\u00dfnahmen": "mechanische Pflegema\u00dfnahmen",
    "Mehr": "Mehr",
    "Mehr anzeigen": "Mehr anzeigen",
    "Mehr erfahren": "Mehr erfahren",
    "Mehrfachantrag (MFA)": "Mehrfachantrag (MFA)",
    "Mein Profil": "Mein Profil",
    "Meine aktuellen Favoriten": "Meine aktuellen Favoriten",
    "Meine Rechnungen": "Meine Rechnungen",
    "Meine Vertr\u00e4ge": "Meine Vertr\u00e4ge",
    "Meinen pers\u00f6nlichen Berater kontaktieren...": "Meinen pers\u00f6nlichen Berater kontaktieren...",
    "Menge": "Menge",
    "Menge (t)": "Menge (t)",
    "Menge in kg\/ha": "Menge in kg\/ha",
    "Menge teilfl\u00e4chen-spezifisch verteilen": "Menge teilfl\u00e4chen-spezifisch verteilen",
    "Men\u00fc": "Men\u00fc",
    "Men\u00fc einklappen": "Men\u00fc einklappen",
    "Messen beenden": "Messen beenden",
    "Messen von Strecken": "Messen von Strecken",
    "Mg": "Mg",
    "Mg-Klasse": "Mg-Klasse",
    "Mindestabstand": "Mindestabstand",
    "Minerald\u00fcnger": "Minerald\u00fcnger",
    "Minerald\u00fcngung": "Minerald\u00fcngung",
    "Minimum:": "Minimum:",
    "Minimale Saatst\u00e4rke": "Minimale Saatst\u00e4rke",
    "Minimale & maximale Saatst\u00e4rke": "Minimale & maximale Saatst\u00e4rke",
    "Min/max Saatst\u00e4rke \u00e4ndern": "Min/max Saatst\u00e4rke \u00e4ndern",
    "Mit diesem Schieberegler kannst du kalkulierbare Verluste ber\u00fccksichtigen und die Saatstärke pauschal korrigieren (1-15%). Pflanzenverluste k\u00f6nnen beispielsweise auftreten durch:": "Mit diesem Schieberegler kannst du kalkulierbare Verluste ber\u00fccksichtigen und die Saatstärke pauschal korrigieren (1-15%). Pflanzenverluste k\u00f6nnen beispielsweise auftreten durch:",
    "Mit Farmdok können Sie die Dokumentation der durchgeführten Feldarbeiten direkt von der CNH-Plattform laden und automatisch Maßnahmen in Farmdok erstellen. Alles, was du dazu brauchst, ist ein Account bei New Holland MyPLM® Connect, Case AFS Connect oder STEYR S-FLEET und ein geeingetes ISOBUS Display auf deinem Traktor.": "Mit Farmdok können Sie die Dokumentation der durchgeführten Feldarbeiten direkt von der CNH-Plattform laden und automatisch Maßnahmen in Farmdok erstellen. Alles, was du dazu brauchst, ist ein Account bei New Holland MyPLM® Connect, Case AFS Connect oder STEYR S-FLEET und ein geeingetes ISOBUS Display auf deinem Traktor.",
    "Mit FARMDOK kannst du Auftr\u00e4ge bzw. Applikationskarten direkt an deine John Deere Maschinen schicken. Daf\u00fcr ben\u00f6tist du lediglich einen Account auf MyJohnDeere.com und musst deine Maschinen mit diesem Account verkn\u00fcpfen. Du kannst die Applikationskarte auch ohne Account \u00fcbertragen indem du sie herunterl\u00e4dst und h\u00e4ndisch mit einem USB-Stick \u00fcbertr\u00e4gst.": "Mit FARMDOK kannst du Auftr\u00e4ge bzw. Applikationskarten direkt an deine John Deere Maschinen schicken. Daf\u00fcr ben\u00f6tist du lediglich einen Account auf MyJohnDeere.com und musst deine Maschinen mit diesem Account verkn\u00fcpfen. Du kannst die Applikationskarte auch ohne Account \u00fcbertragen indem du sie herunterl\u00e4dst und h\u00e4ndisch mit einem USB-Stick \u00fcbertr\u00e4gst.",
    "Mit Google anmelden": "Mit Google anmelden",
    "Mittel": "Mittel",
    "mittel": "mittel",
    "Mittlere Dosierung (kg\/ha)": "Mittlere Dosierung (kg\/ha)",
    "Mittlere Dosierung in kg\/ha \u00fcber alle ausgew\u00e4hlten Fl\u00e4chen, N\u00e4hrstoffe in kg\/ha": "Mittlere Dosierung in kg\/ha \u00fcber alle ausgew\u00e4hlten Fl\u00e4chen, N\u00e4hrstoffe in kg\/ha",
    "Monate": "Monate",
    "Monitoring": "Monitoring",
    "Variable Pflanzenschutzkarte": "Variable Pflanzenschutzkarte",
    "Vermehrungsgemeinschaft": "Vermehrungsgemeinschaft",
    "Vermehrungsgemeinschaften": "Vermehrungsgemeinschaften",
    "Vermehrungsgemeinschaft erstellen": "Vermehrungsgemeinschaft erstellen",
    "Vermehrungsgemeinschaft bearbeiten": "Vermehrungsgemeinschaft bearbeiten",
    "Vermehrungsgemeinschaft löschen": "Vermehrungsgemeinschaft löschen",
    "MyJohnDeere": "MyJohnDeere",
    "MyJohnDeere \u00f6ffnen": "MyJohnDeere \u00f6ffnen",
    "M\u00e4hdrusch": "M\u00e4hdrusch",
    "M\u00f6chtest Du mit diesen Werten fortsetzten oder die Einstellungen auf die empfohlenen Werte zur\u00fccksetzen?": "M\u00f6chtest Du mit diesen Werten fortsetzten oder die Einstellungen auf die empfohlenen Werte zur\u00fccksetzen?",
    "M\u00f6chtest du fortfahren?": "M\u00f6chtest du fortfahren?",
    "N": "N",
    "NDVI": "NDVI",
    "NDVI - Biomasse": "NDVI - Biomasse",
    "NDWI - Wassergehalt": "NDWI - Wassergehalt",
    "Nachname": "Nachname",
    "nach Kultur": "nach Kultur",
    "nach letzter Tätigkeit": "nach letzter Tätigkeit",
    "nach NDVI": "nach NDVI",
    "nach MBI": "nach MBI",
    "nach Sorte": "nach Sorte",
    "N\u00e4chster Konflikt": "N\u00e4chster Konflikt",
    "N\u00e4chster Schritt": "N\u00e4chster Schritt",
    "Name": "Name",
    "Name des Sender-Betriebs": "Name des Sender-Betriebs",
    "Nein, Ma\u00dfnahmen an der Quelle l\u00f6schen.": "Nein, Ma\u00dfnahmen an der Quelle l\u00f6schen.",
    "Nein, nur Felder kopieren.": "Nein, nur Felder kopieren.",
    "Nein": "Nein",
    "Neu": "Neu",
    "Neu laden": "Neu laden",
    "Neues Feld": "Neues Feld",
    "Neues Assessment starten": "Neues Assessment starten",
    "Nicht alle importierten Kulturen konnten FARMDOK Kulturen zugeordnet werden.": "Nicht alle importierten Kulturen konnten FARMDOK Kulturen zugeordnet werden.",
    "Nicht gepflegt": "Nicht gepflegt",
    "Nicht \u00fcbernehmen": "Nicht \u00fcbernehmen",
    "Niederschlag": "Niederschlag",
    "Kumulierter Niederschlag": "Kumulierter Niederschlag",
    "Noch keine Eintr\u00e4ge vorhanden.": "Noch keine Eintr\u00e4ge vorhanden.",
    "Noch keine Erntedaten f\u00fcr <strong>{name}<\/strong> verf\u00fcgbar.": "Noch keine Erntedaten f\u00fcr <strong>{name}<\/strong> verf\u00fcgbar.",
    "Noch keine Fruchtfolge vorhanden.": "Noch keine Fruchtfolge vorhanden.",
    "Noch keine Ma\u00dfnahmen vorhanden.": "Noch keine Ma\u00dfnahmen vorhanden.",
    "Noch keine Notizen vorhanden.": "Noch keine Notizen vorhanden.",
    "Notizen": "Notizen",
    "Notizen und Arbeitsanweisungen": "Notizen und Arbeitsanweisungen",
    "NPK in %": "NPK in %",
    "Nr": "Nr",
    "Nummer": "Nummer",
    "Nutzung durch": "Nutzung durch",
    "Nutzungsart": "Nutzungsart",
    "Nutzungsarten": "Nutzungsarten",
    "Nuzungsart in der Datenquelle (aus dem Import)": "Nuzungsart in der Datenquelle (aus dem Import)",
    "N\u00e4hrstoffbilanz": "N\u00e4hrstoffbilanz",
    "N\u00e4hrstoffbilanz teilen": "N\u00e4hrstoffbilanz teilen",
    "N\u00e4hrstoffe eingeben": "N\u00e4hrstoffe eingeben",
    "N\u00e4hrstoffmenge": "N\u00e4hrstoffmenge",
    "N\u00e4hrstoffmenge ändern": "N\u00e4hrstoffmenge ändern",
    "N\u00e4hrstoffvergleich": "N\u00e4hrstoffvergleich",
    "N-Rate 1. Gabe (kg/ha)": "N-Rate 1. Gabe (kg/ha)",
    "N-Rate 2. Gabe (kg/ha)": "N-Rate 2. Gabe (kg/ha)",
    "OK": "OK",
    "Obst und Hopfen": "Obst und Hopfen",
    "Offene Vertr\u00e4ge": "Offene Vertr\u00e4ge",
    "Optionen": "Optionen",
    "Optionen schlie\u00dfen": "Optionen schlie\u00dfen",
    "Organisation": "Organisation",
    "Organisation verbessern": "Organisation verbessern",
    "Originalgr\u00f6\u00dfe": "Originalgr\u00f6\u00dfe",
    "Ort": "Ort",
    "Other": "Other",
    "P": "P",
    "PDF Herunterladen": "PDF Herunterladen",
    "PLZ": "PLZ",
    "Packungen": "Packungen",
    "Papierlos arbeiten": "Papierlos arbeiten",
    "Partner": "Partner",
    "Partner-Nummer": "Partner-Nummer",
    "Partner-Nummer \u2013 Siehe Zugangsdaten auf <a href=\"https:\/\/ris.agrana.com\" target=\"_blank\">https:\/\/ris.agrana.com<\/a>": "Partner-Nummer \u2013 Siehe Zugangsdaten auf <a href=\"https:\/\/ris.agrana.com\" target=\"_blank\">https:\/\/ris.agrana.com<\/a>",
    "Partner-Nummer, Kunden-Nummer, CRM Id": "Partner-Nummer, Kunden-Nummer, CRM Id",
    "Partner-Status": "Partner-Status",
    "Partner w\u00e4hlen": "Partner w\u00e4hlen",
    "Parzellen": "Parzellen",
    "Parzellen Nr.": "Parzellen Nr.",
    "Parzellennummer suchen": "Parzellennummer suchen",
    "Passende Kulturen": "Passende Kulturen",
    "Passende Sorten": "Passende Sorten",
    "Passwort": "Passwort",
    "Passwort speichern": "Passwort speichern",
    "Passwort vergessen?": "Passwort vergessen?",
    "Passwort zur\u00fccksetzen": "Passwort zur\u00fccksetzen",
    "Pause": "Pause",
    "Permissions": "Permissions",
    "Personen": "Personen",
    "Pers\u00f6nliche Einstellungen": "Pers\u00f6nliche Einstellungen",
    "PFLANZENSCHUTZ": "PFLANZENSCHUTZ",
    "Pflanzenschutz": "Pflanzenschutz",
    "Pflanzenschutzmittel": "Pflanzenschutzmittel",
    "Pflanzenschutzmittelkatalog \u00f6ffnen": "Pflanzenschutzmittelkatalog \u00f6ffnen",
    "Pflichtfeld": "Pflichtfeld",
    "Planung": "Planung",
    "Plattform": "Plattform",
    "Plattform öffnen": "Plattform öffnen",
    "Positionen senden": "Positionen senden",
    "Postleitzahl": "Postleitzahl",
    "Precision Farming": "Precision Farming",
    "Premium": "Premium",
    "Probleme? Bitte schreib uns an support@farmdok.com und \u00fcbermittle die verwendeten Importdateien.": "Probleme? Bitte schreib uns an support@farmdok.com und \u00fcbermittle die verwendeten Importdateien.",
    "Produkt": "Produkt",
    "Produkt ausw\u00e4hlen": "Produkt ausw\u00e4hlen",
    "Produkt entfernen": "Produkt entfernen",
    "Produkt hinzuf\u00fcgen": "Produkt hinzuf\u00fcgen",
    "Produkte": "Produkte",
    "Produkt vor Menge einstellen": "Produkt vor Menge einstellen",
    "Produkt w\u00e4hlen": "Produkt w\u00e4hlen",
    "Produkt- & Maschinenauswahl": "Produkt- & Maschinenauswahl",
    "Produktivit\u00e4t steigern": "Produktivit\u00e4t steigern",
    "Produktkombination": "Produktkombination",
    "Profil": "Profil",
    "Prognose": "Prognose",
    "Prüfstand in m": "Prüfstand in m",
    "P\u2082O\u2085-Klasse": "P\u2082O\u2085-Klasse",
    "Randbereich korrigiert": "Randbereich korrigiert",
    "Randbereich nicht korrigiert": "Randbereich nicht korrigiert",
    "REIP - Stickstoffgehalt": "REIP - Stickstoffgehalt",
    "Referenz oder Freigabegrund": "Referenz oder Freigabegrund",
    "Referenz-Nummer": "Referenz-Nummer",
    "Reine Kartoffel (t)": "Reine Kartoffel (t)",
    "Reine R\u00fcbe (t)": "Reine R\u00fcbe (t)",
    "Reinekartoffel: {amountPotato} St\u00e4rkegehalt: {amountStarch}": "Reinekartoffel: {amountPotato} St\u00e4rkegehalt: {amountStarch}",
    "Report": "Report",
    "Reports": "Reports",
    "R\u00fcstzeit": "R\u00fcstzeit",
    "Risikokategorie": "Risikokategorie",
    "Saatbau Mais Aussaatkarte": "Saatbau Mais Aussaatkarte",
    "Saatbett-Bedingungen (Scholligkeit, Wasserverf\u00fcgbarkeit, …)": "Saatbett-Bedingungen (Scholligkeit, Wasserverf\u00fcgbarkeit, …)",
    "Saatgut": "Saatgut",
    "Saatdatum": "Saatdatum",
    "Saatgut jetzt bestellen SESAM24": "Saatgut jetzt bestellen SESAM24",
    "Saatst\u00e4rke": "Saatst\u00e4rke",
    "Saatst\u00e4rke in K\u00f6rner/ha": "Saatst\u00e4rke in K\u00f6rner/ha",
    "Saatzeitpunkt au\u00dferhalb des Optimums": "Saatzeitpunkt au\u00dferhalb des Optimums",
    "Sales": "Sales",
    "Satellit": "Satellit",
    "Satellitenbild": "Satellitenbild",
    "Satellitenbilder": "Satellitenbilder",
    "Satellitenbilder am gew\u00e4hlten Datum anzeigen": "Satellitenbilder am gew\u00e4hlten Datum anzeigen",
    "Satellitenindex": "Satellitenindex",
    "Schlag": "Schlag",
    "Schlag Nr": "Schlag Nr",
    "Schlagblatt": "Schlagblatt",
    "Schlagblatt f\u00fcr {fieldName}": "Schlagblatt f\u00fcr {fieldName}",
    "Schlagblatt teilen": "Schlagblatt teilen",
    "Schlie\u00dfen": "Schlie\u00dfen",
    "Schnee": "Schnee",
    "Schritt zur\u00fcck": "Schritt zur\u00fcck",
    "Sch\u00e4dling ausw\u00e4hlen": "Sch\u00e4dling ausw\u00e4hlen",
    "Seite drucken": "Seite drucken",
    "Seite verlassen": "Seite verlassen",
    "Sekund\u00e4rrohstoffe": "Sekund\u00e4rrohstoffe",
    "Selektierte Felder exportieren": "Selektierte Felder exportieren",
    "Sende Applikationskarte": "Sende Applikationskarte",
    "Sende die Applikationskarte direkt an die Cloud-Plattform deines Maschinenherstellers.": "Sende die Applikationskarte direkt an die Cloud-Plattform deines Maschinenherstellers.",
    "Sende deinem Partner einen Link, der ihn direkt zum Vertrag führt. Er kann dort mit wenigen Klicks Felder freigeben.": "Sende deinem Partner einen Link, der ihn direkt zum Vertrag führt. Er kann dort mit wenigen Klicks Felder freigeben.",
    "Senden": "Senden",
    "Senden an": "Senden an",
    "Sender": "Sender",
    "Sender-Referenz": "Sender-Referenz",
    "Setze das Feld \"aktiv\", wenn es ab sofort im angezeigten Erntejahr {current} bewirtschaftet werden soll. Vorangehende Erntejahre werden auf diesem Feld abgeschlossen.": "Setze das Feld \"aktiv\", wenn es ab sofort im angezeigten Erntejahr {current} bewirtschaftet werden soll. Vorangehende Erntejahre werden auf diesem Feld abgeschlossen.",
    "Setze das Feld auf \"abgeschlossen\", sobald die Ernte f\u00fcr das Erntejahr {current} beendet wurde. Das Feld wird automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.": "Setze das Feld auf \"abgeschlossen\", sobald die Ernte f\u00fcr das Erntejahr {current} beendet wurde. Das Feld wird automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.",
    "Setze das Feld auf \"geplant\", wenn das Erntejahr {current} auf diesem Feld noch nicht begonnen hat.": "Setze das Feld auf \"geplant\", wenn das Erntejahr {current} auf diesem Feld noch nicht begonnen hat.",
    "Setze dein neues Passwort": "Setze dein neues Passwort",
    "Setze den Status auf \"geplant\", wenn das Erntejahr {current} auf den ausgew\u00e4hlten Feldern noch nicht begonnen hat.": "Setze den Status auf \"geplant\", wenn das Erntejahr {current} auf den ausgew\u00e4hlten Feldern noch nicht begonnen hat.",
    "Setze die ausgew\u00e4hlten Felder \"aktiv\", wenn sie ab sofort im angezeigten Erntejahr {current} bewirtschaftet werden sollen. Vorangehende Erntejahre werden auf diesen Feldern abgeschlossen.": "Setze die ausgew\u00e4hlten Felder \"aktiv\", wenn sie ab sofort im angezeigten Erntejahr {current} bewirtschaftet werden sollen. Vorangehende Erntejahre werden auf diesen Feldern abgeschlossen.",
    "Setze die ausgew\u00e4hlten Felder auf \"abgeschlossen\", sobald die Ernte f\u00fcr das Erntejahr {current} beendet wurde. Die Felder werden automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.": "Setze die ausgew\u00e4hlten Felder auf \"abgeschlossen\", sobald die Ernte f\u00fcr das Erntejahr {current} beendet wurde. Die Felder werden automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.",
    "Setze mittlere Dosierung in kg/ha": "Setze mittlere Dosierung in kg/ha",
    "Seitenausrichtung": "Seitenausrichtung",
    "Shapefile": "Shapefile",
    "Shop": "Shop",
    "Sichtbare Eintr\u00e4ge": "Sichtbare Eintr\u00e4ge",
    "Sie sind nun im Erntejahr {current} aktiv und werden in den vorangehenden Erntejahren auf abgeschlossen gestellt.": "Sie sind nun im Erntejahr {current} aktiv und werden in den vorangehenden Erntejahren auf abgeschlossen gestellt.",
    "Sie wurden f\u00fcr {current} auf geplant gestellt.": "Sie wurden f\u00fcr {current} auf geplant gestellt.",
    "Sie wurden im Erntejahr {current} abgeschlossen und werden in {next} aktiv gestellt.": "Sie wurden im Erntejahr {current} abgeschlossen und werden in {next} aktiv gestellt.",
    "Sollen bereits gebuchte Ma\u00dfnahmen f\u00fcr das neue Feld \u00fcbernommen werden?": "Sollen bereits gebuchte Ma\u00dfnahmen f\u00fcr das neue Feld \u00fcbernommen werden?",
    "Sollen die Fl\u00e4chen aus dem Vorjahr \u00fcbernommen werden?": "Sollen die Fl\u00e4chen aus dem Vorjahr \u00fcbernommen werden?",
    "Sollen die Fl\u00e4chen aus dem nachfolgenden Erntejahr \u00fcbernommen werden?": "Sollen die Fl\u00e4chen aus dem nachfolgenden Erntejahr \u00fcbernommen werden?",
    "Sollte die E-Mail nicht auffindbar sein, {linkStart}klicke hier, um sie nochmal zu senden.{linkEnd}": "Sollte die E-Mail nicht auffindbar sein, {linkStart}klicke hier, um sie nochmal zu senden.{linkEnd}",
    "Sonstige": "Sonstige",
    "Sonstige Gr\u00fcnlandarbeiten": "Sonstige Gr\u00fcnlandarbeiten",
    "Sonstige Hilfsstoffe und D\u00fcnger": "Sonstige Hilfsstoffe und D\u00fcnger",
    "Sonstige organische D\u00fcngemittel": "Sonstige organische D\u00fcngemittel",
    "Sorte": "Sorte",
    "Sorte in der Datenquelle (aus dem Import)": "Sorte in der Datenquelle (aus dem Import)",
    "Sorte:": "Sorte:",
    "Sorten": "Sorten",
    "Sorte w\u00e4hlen": "Sorte w\u00e4hlen",
    "Sortieren: A {arrowRight} Z": "Sortieren: A {arrowRight} Z",
    "Sortieren: Z {arrowRight} A": "Sortieren: Z {arrowRight} A",
    "Sortieren: absteigend": "Sortieren: absteigend",
    "Sortieren: aufsteigend": "Sortieren: aufsteigend",
    "Spaltenreihenfolge \u00e4ndern": "Spaltenreihenfolge \u00e4ndern",
    "Spalten\u00fcberschrift aus Datei": "Spalten\u00fcberschrift aus Datei",
    "Speichern": "Speichern",
    "Spritzbrühe": "Spritzbrühe",
    "Standard": "Standard",
    "Standard - Shapefile": "Standard - Shapefile",
    "Standardeinheit {amount} K\u00f6rner je Packung": "Standardeinheit {amount} K\u00f6rner je Packung",
    "Status": "Status",
    "Startdatum": "Startdatum",
    "Start": "Start",
    "Steig auf www.eama.at mit Betriebsnummer und Pincode (oder Handysignatur) ein.": "Steig auf www.eama.at mit Betriebsnummer und Pincode (oder Handysignatur) ein.",
    "Steige um auf FARMDOK PERFORMANCE und du erh\u00e4ltst Zugang zu FARMDOK Monitoring. Die Anwendung erm\u00f6glicht es Vegetations\u00adunterschiede teilfl\u00e4chenspezifisch in der Karte darzustellen und den zeitlichen Verlauf zu analysieren.": "Steige um auf FARMDOK PERFORMANCE und du erh\u00e4ltst Zugang zu FARMDOK Monitoring. Die Anwendung erm\u00f6glicht es Vegetations\u00adunterschiede teilfl\u00e4chenspezifisch in der Karte darzustellen und den zeitlichen Verlauf zu analysieren.",
    "Steige um auf FARMDOK PERFORMANCE und du erh\u00e4ltst Zugang zu Freigegebenen Feldern.": "Steige um auf FARMDOK PERFORMANCE und du erh\u00e4ltst Zugang zu Freigegebenen Feldern.",
    "Stoffstrombilanz": "Stoffstrombilanz",
    "Storniert": "Storniert",
    "Stra\u00dfe": "Stra\u00dfe",
    "Stra\u00dfe, Hausnummer, T\u00fcr": "Stra\u00dfe, Hausnummer, T\u00fcr",
    "St\u00e4rkegehalt (t)": "St\u00e4rkeLehalt (t)",
    "St\u00e4rkegehalt gesamt (t)": "St\u00e4rkegehalt gesamt (t)",
    "St\u00e4rkegehalt gesamt: {totalStarch} t": "St\u00e4rkegehalt gesamt: {totalStarch} t",
    "Suchbegriff eingeben ...": "Suchbegriff eingeben ...",
    "Suche zur\u00fccksetzen": "Suche zur\u00fccksetzen",
    "Suchen": "Suchen",
    "Summe": "Summe",
    "Summe Gr\u00f6\u00dfe: {sizeTotalFormatted} ha": "Summe Gr\u00f6\u00dfe: {sizeTotalFormatted} ha",
    "Summe Hektar: {processedAreaTotalFormatted} ha": "Summe Hektar: {processedAreaTotalFormatted} ha",
    "Superuser": "Superuser",
    "Tabelle zur\u00fccksetzen": "Tabelle zur\u00fccksetzen",
    "Tage": "Tage",
    "Telefonnummer": "Telefonnummer",
    "Temperatur": "Temperatur",
    "tierische Sch\u00e4den (Bsp.: Wild, V\u00f6gel, Insekten, …)": "tierische Sch\u00e4den (Bsp.: Wild, V\u00f6gel, Insekten, …)",
    "Tierkategorien und N\u00e4hrstoffanfall": "Tierkategorien und N\u00e4hrstoffanfall",
    "Tippen um zu suchen": "Tippen um zu suchen",
    "Trennlinie fertigstellen": "Trennlinie fertigstellen",
    "Trockengebiet": "Trockengebiet",
    "T\u00e4tigkeit": "T\u00e4tigkeit",
    "T\u00e4tigkeit w\u00e4hlen": "T\u00e4tigkeit w\u00e4hlen",
    "T\u00e4tigkeiten": "T\u00e4tigkeiten",
    "UBB": "UBB",
    "Um Polygone kombinieren zu können, müssen Sie mindestens 2 Polygone ausgewählt haben.": "Um Polygone kombinieren zu können, müssen Sie mindestens 2 Polygone ausgewählt haben.",
    "Umweltgerechte und biodiversit\u00e4tsf\u00f6rdernde Bewirtschaftung.": "Umweltgerechte und biodiversit\u00e4tsf\u00f6rdernde Bewirtschaftung.",
    "Unbekannter Fehler": "Unbekannter Fehler",
    "Unbekannter Fehler. Bitte \u00fcberpr\u00fcfe deine Internetverbindung.": "Unbekannter Fehler. Bitte \u00fcberpr\u00fcfe deine Internetverbindung.",
    "Unerwarteter Fehler.": "Unerwarteter Fehler.",
    "Ung\u00fcltige Trennlinie": "Ung\u00fcltige Trennlinie",
    "Ung\u00fcltiger Wert.": "Ung\u00fcltiger Wert.",
    "Upload": "Upload",
    "Upload fehlgeschlagen! Die Datei scheint defekt zu sein. Probiere den Export aus der Quellapplikation zu einem sp\u00e4teren Zeitpunkt nochmal. Sollte das Problem weiterhin auftreten, sende die Datei bitten an den Support.": "Upload fehlgeschlagen! Die Datei scheint defekt zu sein. Probiere den Export aus der Quellapplikation zu einem sp\u00e4teren Zeitpunkt nochmal. Sollte das Problem weiterhin auftreten, sende die Datei bitten an den Support.",
    "Upload in Arbeit ... DIESES FENSTER NICHT SCHLIESSEN!": "Upload in Arbeit ... DIESES FENSTER NICHT SCHLIESSEN!",
    "Upload verlassen": "Upload verlassen",
    "Users and Companies": "Users and Companies",
    "Vegetation": "Vegetation",
    "Datum der Vegetationskarte": "Datum der Vegetationskarte",
    "Datura-Kontrollzentrum": "Datura-Kontrollzentrum",
    "Drohnenassessments": "Drohnenassessments",
    "Drohnenbilder hochladen": "Drohnenbilder hochladen",
    "Vegetationsmonitoring": "Vegetationsmonitoring",
    "Verbindung trennen": "Verbindung trennen",
    "Verbindung trennen fehlgeschlagen.": "Verbindung trennen fehlgeschlagen.",
    "Verbindung zu CNH Industria": "Verbindung zu CNH Industria",
    "Verbindung zu MyJohnDeere trennen": "Verbindung zu MyJohnDeere trennen",
    "Vereinbarungen zur Fl\u00e4chenmeldung": "Vereinbarungen zur Fl\u00e4chenmeldung",
    "Verf\u00fcgbare Maschinen": "Verf\u00fcgbare Maschinen",
    "Verkleinern": "Verkleinern",
    "Verlustausgleich (%)": "Verlustausgleich (%)",
    "Verlauf": "Verlauf",
    "Job history öffnen": "Job history öffnen",
    "verschiedene": "verschiedene",
    "Verstecken": "Verstecken",
    "Verteilung auf dem Feld": "Verteilung auf dem Feld",
    "Vertrag": "Vertrag",
    "Vertrag w\u00e4hlen": "Vertrag w\u00e4hlen",
    "Vertragsnehmer": "Vertragsnehmer",
    "Vertragsproduktion": "Vertragsproduktion",
    "Vervollst\u00e4ndige die Ernte-Ma\u00dfnahmen und klicke dann hier, um sie in FARMDOK zu buchen.": "Vervollst\u00e4ndige die Ernte-Ma\u00dfnahmen und klicke dann hier, um sie in FARMDOK zu buchen.",
    "Verwalte hier deine Fl\u00e4chen": "Verwalte hier deine Fl\u00e4chen",
    "Verwaltete Vertr\u00e4ge": "Verwaltete Vertr\u00e4ge",
    "Verwaltung": "Verwaltung",
    "Verwendbar bis:": "Verwendbar bis:",
    "Verwendungszweck": "Verwendungszweck",
    "Verwerfen": "Verwerfen",
    "Verwirkliche deinen Erfolg am Feld!": "Verwirkliche deinen Erfolg am Feld!",
    "Von der Aussaat bis zur Ernte, alles \u00fcber deine Felder an einem Ort.": "Von der Aussaat bis zur Ernte, alles \u00fcber deine Felder an einem Ort.",
    "Von dort kannst du sie vom Terminal deines Traktors abrufen ohne einen USB-Stick.": "Von dort kannst du sie vom Terminal deines Traktors abrufen ohne einen USB-Stick.",
    "Voraussetzung: Einverst\u00e4ndnis in eAMA abgeben!": "Voraussetzung: Einverst\u00e4ndnis in eAMA abgeben!",
    "Vorfrucht": "Vorfrucht",
    "Vorfrucht in der Datenquelle (aus dem Import)": "Vorfrucht in der Datenquelle (aus dem Import)",
    "Vorlage im Format .CSV {linkStart}herunterladen{linkEnd}": "Vorlage im Format .CSV {linkStart}herunterladen{linkEnd}",
    "Vorname": "Vorname",
    "Vorschau": "Vorschau",
    "Vordefinierte Notizen": "Vordefinierte Notizen",
    "Warnhinweise gefunden: {count}": "Warnhinweise gefunden: {count}",
    "Wartezeit bis zur Ernte:": "Wartezeit bis zur Ernte:",
    "Wartezeit zwischen Anwendungen:": "Wartezeit zwischen Anwendungen:",
    "Wasserschutzgebiet": "Wasserschutzgebiet",
    "Was ist dein Beruf?": "Was ist dein Beruf?",
    "Was machst du?": "Was machst du?",
    "Was sind deine Ziele?": "Was sind deine Ziele?",
    "Wechsle zum Reiter Kundendaten, Men\u00fcpunkt Datenfreigabe > \u00dcbersicht.": "Wechsle zum Reiter Kundendaten, Men\u00fcpunkt Datenfreigabe > \u00dcbersicht.",
    "Wegzeit": "Wegzeit",
    "Wein": "Wein",
    "Weiter": "Weiter",
    "Weitere Dateien durch Ablegen hinzuf\u00fcgen": "Weitere Dateien durch Ablegen hinzuf\u00fcgen",
    "Welche Kulturen baust du an?": "Welche Kulturen baust du an?",
    "Weniger anzeigen": "Weniger anzeigen",
    "Wenn du den Lagerstand sehen m\u00f6chtest, f\u00fchre eine Lagerbewegung durch.": "Wenn du den Lagerstand sehen m\u00f6chtest, f\u00fchre eine Lagerbewegung durch.",
    "Wert": "Wert",
    "Wert muss zwischen {min} und {max} sein": "Wert muss zwischen {min} und {max} sein",
    "Werte:": "Werte:",
    "Wetter f\u00fcr:": "Wetter f\u00fcr:",
    "Wetterdiagramm": "Wetterdiagramm",
    "Wie bewirtschaftest du?": "Wie bewirtschaftest du?",
    "Wie m\u00f6chtest du importieren?": "Wie m\u00f6chtest du importieren?",
    "Wie viele Hektar werden bewirtschaftet?": "Wie viele Hektar werden bewirtschaftet?",
    "Wie viele Kunden werden betreut?": "Wie viele Kunden werden betreut?",
    "Wie viele Mitarbeiter gibt es?": "Wie viele Mitarbeiter gibt es?",
    "Wiederherstellen": "Wiederherstellen",
    "Willst du Ernte-Ma\u00dfnahmen in FARMDOK erstellen? Aktiviere die Kontrollk\u00e4stchen in der ersten Spalte, dann klicke hier um zum n\u00e4chsten Schritt zu gehen.": "Willst du Ernte-Ma\u00dfnahmen in FARMDOK erstellen? Aktiviere die Kontrollk\u00e4stchen in der ersten Spalte, dann klicke hier um zum n\u00e4chsten Schritt zu gehen.",
    "Willst du ein neues Erntejahr {newYear} hinzuf\u00fcgen?": "Willst du ein neues Erntejahr {newYear} hinzuf\u00fcgen?",
    "Willst du wirklich das gew\u00e4hlte Feldst\u00fcck l\u00f6schen?": "Willst du wirklich das gew\u00e4hlte Feldst\u00fcck l\u00f6schen?",
    "Willst du wirklich {count} Feldst\u00fccke l\u00f6schen?": "Willst du wirklich {count} Feldst\u00fccke l\u00f6schen?",
    "Willst du wirklich {name} l\u00f6schen?": "Willst du wirklich {name} l\u00f6schen?",
    "Winterdienst": "Winterdienst",
    "Wintergetreide {n}. Gabe": "Wintergetreide {n}. Gabe",
    "Wir f\u00fchlen uns dazu verpflichtet dir ma\u00dfgeschneiderte Unterst\u00fctzung zu bieten!": "Wir f\u00fchlen uns dazu verpflichtet dir ma\u00dfgeschneiderte Unterst\u00fctzung zu bieten!",
    "Wir haben dir eine E-Mail an {email} gesendet.": "Wir haben dir eine E-Mail an {email} gesendet.",
    "Wir senden dir ein E-Mail zu, mit Hilfe dessen du ein neues Passwort setzen kannst.": "Wir senden dir ein E-Mail zu, mit Hilfe dessen du ein neues Passwort setzen kannst.",
    "Wirtschaftsd\u00fcnger": "Wirtschaftsd\u00fcnger",
    "Wolken": "Wolken",
    "W\u00e4hle das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder kopiert werden sollen.": "W\u00e4hle das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder kopiert werden sollen.",
    "W\u00e4hle das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder verschoben werden sollen.": "W\u00e4hle das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder verschoben werden sollen.",
    "W\u00e4hle das Ziel-Erntejahr, wohin {field} kopiert werden soll.": "W\u00e4hle das Ziel-Erntejahr, wohin {field} kopiert werden soll.",
    "W\u00e4hle das Ziel-Erntejahr, wohin {field} verschoben werden soll.": "W\u00e4hle das Ziel-Erntejahr, wohin {field} verschoben werden soll.",
    "W\u00e4hle dein Land und melde dich an.": "W\u00e4hle dein Land und melde dich an.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder kopiert werden sollen.": "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder kopiert werden sollen.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder verschoben werden sollen.": "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder verschoben werden sollen.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin {field} kopiert werden soll.": "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin {field} kopiert werden soll.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin {field} verschoben werden soll.": "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin {field} verschoben werden soll.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Maßnahmen verschoben werden sollen.": "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Maßnahmen verschoben werden sollen.",
    "W\u00e4hle den ausf\u00fchrenden Betrieb": "W\u00e4hle den ausf\u00fchrenden Betrieb",
    "W\u00e4hle die Felder in der Karte oder in der Liste.": "W\u00e4hle die Felder in der Karte oder in der Liste.",
    "W\u00e4hle die Felder, auf denen du Ernte-Ma\u00dfnahmen erstellen m\u00f6chtest, vervollst\u00e4ndige die Informationen und klicke dann hier, um zu speichern.": "W\u00e4hle die Felder, auf denen du Ernte-Ma\u00dfnahmen erstellen m\u00f6chtest, vervollst\u00e4ndige die Informationen und klicke dann hier, um zu speichern.",
    "W\u00e4hle die Maschine an die du den Auftrag bzw. die Applikationskarte senden m\u00f6chtest. W\u00e4hle <b>MyJohnDeere<\/b> falls du die Daten in die MyJohnDeere Plattform senden m\u00f6chtest.": "W\u00e4hle die Maschine an die du den Auftrag bzw. die Applikationskarte senden m\u00f6chtest. W\u00e4hle <b>MyJohnDeere<\/b> falls du die Daten in die MyJohnDeere Plattform senden m\u00f6chtest.",
    "W\u00e4hle ein Dateiformat abh\u00e4ngig vom Terminal, auf dem die Applikationskarte verwendet werden soll.": "W\u00e4hle ein Dateiformat abh\u00e4ngig vom Terminal, auf dem die Applikationskarte verwendet werden soll.",
    "W\u00e4hle ein Produkt": "W\u00e4hle ein Produkt",
    "W\u00e4hle eine Option um fortzufahren": "W\u00e4hle eine Option um fortzufahren",
    "W\u00e4hle einen Betrieb um deine Berichte anzuzeigen.": "W\u00e4hle einen Betrieb um deine Berichte anzuzeigen.",
    "W\u00e4hle mindesten ein Feld mit dem Kontrollk\u00e4stchen in der ersten Spalte. Alle ausgew\u00e4hlten Felder m\u00fcssen eine Kultur haben.": "W\u00e4hle mindesten ein Feld mit dem Kontrollk\u00e4stchen in der ersten Spalte. Alle ausgew\u00e4hlten Felder m\u00fcssen eine Kultur haben.",
    "W\u00e4hle zumindest 1": "W\u00e4hle zumindest 1",
    "W\u00e4hle zumindest 2": "W\u00e4hle zumindest 2",
    "W\u00e4hle \u201cStandard\u201d wenn dein Terminal-Hersteller nicht in der Liste angef\u00fchrt ist.": "W\u00e4hle \u201cStandard\u201d wenn dein Terminal-Hersteller nicht in der Liste angef\u00fchrt ist.",
    "W\u00e4hlen": "W\u00e4hlen",
    "W\u00e4hlen ...": "W\u00e4hlen ...",
    "W\u00e4hlen...": "W\u00e4hlen...",
    "Wählen Sie Polygone in der Karte aus, um Felder zu erstellen. Sie können mehrere Polygone zusammenführen, wenn sie mehrere Polygone zu einem Feld zusammenführen möchten.": "Wählen Sie Polygone in der Karte aus, um Felder zu erstellen. Sie können mehrere Polygone zusammenführen, wenn sie mehrere Polygone zu einem Feld zusammenführen möchten.",
    "Wähle ein Feld in der Karte und gib den Prüfabstand ein. Die Feldkontur wird um den Prüfabstand nach außen verschoben und alle Felder gekennzeichnet, die vom Prüfabstand geschnitten werden.": "Wähle ein Feld in der Karte und gib den Prüfabstand ein. Die Feldkontur wird um den Prüfabstand nach außen verschoben und alle Felder gekennzeichnet, die vom Prüfabstand geschnitten werden.",
    "Wärmesumme": "Wärmesumme",
    "Qualit\u00e4t": "Qualit\u00e4t",
    "Qualit\u00e4tsd\u00fcngung (3. Gabe)": "Qualit\u00e4tsd\u00fcngung (3. Gabe)",
    "Querformat": "Querformat",
    "XLS": "XLS",
    "ZIEHE DIE DATEI HIER HER": "ZIEHE DIE DATEI HIER HER",
    "Zeichnen beenden": "Zeichnen beenden",
    "Zeichnen eines Polygons": "Zeichnen eines Polygons",
    "Zeichnen einer Polyline": "Zeichnen einer Polyline",
    "Zeichnen Sie Polygone oder Linien in die Karte, um Ihr GIS-Objekt darzustellen.": "Zeichnen Sie Polygone oder Linien in die Karte, um Ihr GIS-Objekt darzustellen.",
    "Zeitraum von {periodStart} bis {periodEnd}": "Zeitraum von {periodStart} bis {periodEnd}",
    "Zeitraum:": "Zeitraum:",
    "Zeitstempel": "Zeitstempel",
    "Ziehe die exportierte(n) Datei(en) mit der Maus in das wei\u00dfe Rechteck mit dem strichlierten Rahmen (Drag &amp; Drop).": "Ziehe die exportierte(n) Datei(en) mit der Maus in das wei\u00dfe Rechteck mit dem strichlierten Rahmen (Drag &amp; Drop).",
    "Ziel ausw\u00e4hlen": "Ziel ausw\u00e4hlen",
    "Ziel N-Rate (kg/ha)": "Ziel N-Rate (kg/ha)",
    "Ziel-Betrieb": "Ziel-Betrieb",
    "Ziel-Erntejahr": "Ziel-Erntejahr",
    "Zielbetrieb: In welchen Betrieb sollen die Daten importiert werden?": "Zielbetrieb: In welchen Betrieb sollen die Daten importiert werden?",
    "Zielertrag (kg/ha)": "Zielertrag (kg/ha)",
    "Zone": "Zone",
    "Zonen": "Zonen",
    "Zonen Ergebnis": "Zonen Ergebnis",
    "Zonen erstellen": "Zonen erstellen",
    "Zonenkarte erstellen": "Zonenkarte erstellen",
    "Zonen basiert auf {indexType}, Anzahl der Zonen: {quantisationCode}": "Zonen basiert auf {indexType}, Anzahl der Zonen: {quantisationCode}",
    "Zonierung": "Zonierung",
    "Zu den Berichten": "Zu den Berichten",
    "Zu den offenen Vertr\u00e4gen": "Zu den offenen Vertr\u00e4gen",
    "Zu meinen Vertr\u00e4gen": "Zu meinen Vertr\u00e4gen",
    "Zuckergehalt (t)": "Zuckergehalt (t)",
    "Zugelassen bis:": "Zugelassen bis:",
    "Zugeordnet": "Zugeordnet",
    "Zuletzt aktualisiert": "Zuletzt aktualisiert",
    "Zuletzt verwendet": "Zuletzt verwendet",
    "Zum Bericht": "Zum Bericht",
    "Zum Vertrag": "Zum Vertrag",
    "Zum Import in dein ISOBUS-Terminal": "Zum Import in dein ISOBUS-Terminal",
    "Zur Startseite": "Zur Startseite",
    "Zur\u00fcck": "Zur\u00fcck",
    "Zurück zu": "Zurück zu",
    "Zur\u00fcck zum Login": "Zur\u00fcck zum Login",
    "Zur\u00fcck zum Login.": "Zur\u00fcck zum Login.",
    "Zur\u00fcck zur Dateien-\u00dcbersicht": "Zur\u00fcck zur Dateien-\u00dcbersicht",
    "Zur\u00fcck zu Felder": "Zur\u00fcck zu Felder",
    "Zur\u00fcck zur Feldst\u00fccke-Startseite": "Zur\u00fcck zur Feldst\u00fccke-Startseite",
    "Zur\u00fcck zu Ma\u00dfnahmen": "Zur\u00fcck zu Ma\u00dfnahmen",
    "Zur\u00fcck zur Ma\u00dfnahmen-Startseite": "Zur\u00fcck zur Ma\u00dfnahmen-Startseite",
    "Zur\u00fcck zur Vertragsansicht": "Zur\u00fcck zur Vertragsansicht",
    "Zur\u00fcck zur Vertrags-\u00dcbersicht": "Zur\u00fcck zur Vertrags-\u00dcbersicht",
    "Zur\u00fcck zur Verwaltungs-\u00dcbersicht": "Zur\u00fcck zur Verwaltungs-\u00dcbersicht",
    "Zur\u00fccksetzen": "Zur\u00fccksetzen",
    "Zusammenarbeit verbessern": "Zusammenarbeit verbessern",
    "Zusammenfassung je Schlag": "Zusammenfassung je Schlag",
    "Zusammenführen von Polygonen": "Zusammenführen von Polygonen",
    "Zwischenfrucht": "Zwischenfrucht",
    "aus Import": "aus Import",
    "Assessment erstellen": "Assessment erstellen",
    "Assessment Name": "Assessment Name",
    "bearbeitete Fl\u00e4che (ha)": "bearbeitete Fl\u00e4che (ha)",
    "eAMA Online Fl\u00e4chen Import": "eAMA Online Fl\u00e4chen Import",
    "eAMA PIN": "eAMA PIN",
    "eAMA \u00f6ffnen": "eAMA \u00f6ffnen",
    "eAMA-Betriebsnummer": "eAMA-Betriebsnummer",
    "empfohlen": "empfohlen",
    "f\u00fcr Minerald\u00fcnger": "f\u00fcr Minerald\u00fcnger",
    "gel\u00f6scht am": "gel\u00f6scht am",
    "ha": "ha",
    "in FARMDOK": "in FARMDOK",
    "je mehr desto mehr": "je mehr desto mehr",
    "je weniger desto mehr": "je weniger desto mehr",
    "keine Angabe": "keine Angabe",
    "Keine gültigen Schadfaktoren für Mittel <strong>{pesticide}</strong> und <strong>{culture}</strong> gewählt": "Keine gültigen Schadfaktoren für Mittel <strong>{pesticide}</strong> und <strong>{culture}</strong> gewählt",
    "kg\/ha": "kg\/ha",
    "manuelle Dosierung": "manuelle Dosierung",
    "Möchten Sie die Bewertung wirklich abbrechen?": "Möchten Sie die Bewertung wirklich abbrechen?",
    "oder klicke Datei auswählen oder Ordner auswählen, um eine Datei hinzuzufügen.": "oder klicke {0}Datei auswählen{0} oder {0}Ordner auswählen{0}, um eine Datei hinzuzufügen.",
    "pH": "pH",
    "t": "t",
    "und": "und",
    "{countSelected} von {countAvailable} Betrieben ausgew\u00e4hlt": "{countSelected} von {countAvailable} Betrieben ausgew\u00e4hlt",
    "{count} Felder": "{count} Felder",
    "{count} Felder ohne Erntema\u00dfnahme": "{count} Felder ohne Erntema\u00dfnahme",
    "{count} von {total} Feldern mit Erntema\u00dfnahme": "{count} von {total} Feldern mit Erntema\u00dfnahme",
    "{field} ist ein Pflichtfeld.": "{field} ist ein Pflichtfeld.",
    "{field} muss mindestens {length} Zeichen lang sein.": "{field} muss mindestens {length} Zeichen lang sein.",
    "{name}: {sizeFormatted} ha": "{name}: {sizeFormatted} ha",
    "{number} Fl\u00e4chen ({area} ha) an {partner} gesendet.": "{number} Fl\u00e4chen ({area} ha) an {partner} gesendet.",
    "{seedrateMin} bis {seedrateMax} K\u00f6rner pro Hektar": "{seedrateMin} bis {seedrateMax} K\u00f6rner pro Hektar",
    "{size} ha": "{size} ha",
    "{value} %": "{value} %",
    "\u00b0C W\u00e4rmesumme in {year}": "\u00b0C W\u00e4rmesumme in {year}",
    "\u00dcberspringen": "\u00dcberspringen",
    "\u201ehoch\u201c ergibt mehrere, kleine Teilfl\u00e4chen; \u201eniedrig\u201c weniger, gr\u00f6\u00dfere": "\u201ehoch\u201c ergibt mehrere, kleine Teilfl\u00e4chen; \u201eniedrig\u201c weniger, gr\u00f6\u00dfere",
    "\u00dcbrig in": "\u00dcbrig in",
    "\u00dcbrig am Betrieb (kein spezifisches Lager)": "\u00dcbrig am Betrieb (kein spezifisches Lager)",
    "UploadPending": "Ausstehend",
    "UploadExpired": "Abgelaufen",
    "Calculating": "Wird analysiert",
    "Finished": "Abgeschlossen",
    "Failed": "Fehlgeschlagen",
  };
}
