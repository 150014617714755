<template>
  <h2 class="sidebar-headline">
    {{ text }}
  </h2>
</template>

<script>
export default {
  name: 'SidebarHeadline',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.sidebar-headline {
  margin: 15px 0;
  padding-left: 12px;
  font-weight: 600;
  font-size: 20px;
}
</style>
