
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import numbro from '@/initNumbro';
import Export from '@/precision-farming/application-maps/components/export/Export.vue';
import ExportTable, { type Row } from '@/precision-farming/application-maps/components/export/ExportTable.vue';
import type {
  ApplicationMapsSprayingState,
  Calculation,
} from '@/precision-farming/application-maps/spraying/store/types';
import type { Field } from '@/shared/api/rest/models';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

export default defineComponent({
  name: 'SidebarExportSpraying',
  components: {
    Export,
    ExportTable,
  },
  data(): { numbro: typeof numbro } {
    return {
      numbro,
    };
  },
  computed: {
    ...mapState('fields', {
      fields: 'data',
    }),
    ...mapState('precisionFarming/applicationMaps/spraying', {
      selectedFieldIds(state: ApplicationMapsSprayingState): string[] {
        return state.selectedFields;
      },
      calculation(state: ApplicationMapsSprayingState): Calculation {
        return state.calculation;
      },
    }),
    selectedFields(): Field[] {
      return this.selectedFieldIds.map<Field>((fieldId: string) => this.fields[fieldId]).filter(notNullOrUndefined);
    },
    tableHeaders(): string[] {
      const productNames = this.calculation.products.map((p) => p.product.name);
      return [this.$t('Spritzbrühe') ?? '', ...productNames];
    },
    totalArea(): number {
      return this.selectedFields.map((field) => field.fieldSize).reduce((a, b) => a + b, 0);
    },
    tableRows(): Row[] {
      const productAmounts = this.calculation.products.map((p) => p.amount);
      return this.selectedFields.map<Row>((field: Field) => {
        const sprayMixMid = (this.calculation.sprayMix * field.fieldSize) / this.totalArea;
        const productMids = productAmounts.map((productAmount) => (productAmount * field.fieldSize) / this.totalArea);

        return {
          title: field.name,
          name: field.name,
          values: [numbro(sprayMixMid).format(), ...productMids.map((weightedMid) => numbro(weightedMid).format())],
        };
      });
    },
  },
  methods: {},
});
