<template>
  <BContainer class="settings">
    <p class="small">
      <!-- eslint-disable max-len -->
      {{
        $t(
          'Aktualisiere den in der Arbeitsplanung geplanten Auftrag und füge die eben erstellte Applikationskarte hinzu.',
        )
      }}
      <!-- eslint-enable max-len -->
    </p>
    <CompanySelectFormFieldDropdown
      v-if="companySelectRequired"
      ref="company"
      class="formControl"
      v-model="selectedCompany"
      :companies="currentCompanies"
      variant="vertical"
      disableDelete
      hideLabel
      :required="companySelectRequired"
      data-cy="company-select-form-field-dropdown"
    />
    <div class="export__datepicker-wrapper">
      <div class="export__calendar">
        <FontAwesomeIcon class="export__calendar-icon" :icon="['fa', 'calendar-alt']" />

        <Datepicker
          class="export__datepicker"
          calendar-class="export__datepicker-calendar"
          input-class="export__datepicker-input"
          calendar-button-icon="fa fa-calendar"
          maximum-view="day"
          :language="$store.getters.datepickerTranslation"
          :value="selectedTaskDate"
          @selected="
            (date) => {
              setSelectedTaskDate(date);
            }
          "
        />
        <span class="export__datepicker-timestamp">
          {{ taskDateFormatted }}
        </span>
      </div>
      <div class="export__datepicker-button-wrapper">
        <BButton @click="moveSelectedTaskDateBy(-1)" variant="link" class="text-black">
          <FontAwesomeIcon icon="chevron-left" />
        </BButton>
        <BButton @click="moveSelectedTaskDateBy(1)" variant="link" class="text-black">
          <FontAwesomeIcon icon="chevron-right" />
        </BButton>
      </div>
    </div>
    <div>
      <BButton variant="primary" class="btn" :disabled="createTaskButtonDisabled" @click="createTask">
        <span>{{ buttonCreateTaskLabel }}</span>
      </BButton>
    </div>
  </BContainer>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex';

import CompanySelectFormFieldDropdown from './CompanySelectFormFieldDropdown.vue';
import getDatabaseId from './companyUtils';

export default {
  name: 'PlanTaskTab',
  components: {
    Datepicker,
    CompanySelectFormFieldDropdown,
  },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
    buttonsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      createTaskInProgress: false,
    };
  },
  computed: {
    ...mapState({
      task(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].task;
      },
      taskDataAsync(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/taskDataAsync`];
      },
      selectedTaskDate(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/selectedTaskDate`];
      },
      currentCompanies(state) {
        return state.auth.currentCompanies;
      },
    }),
    selectedCompany: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps[this.workflowKey].selectedCompany;
      },
      set(value) {
        this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setSelectedCompany`, value);
      },
    },
    taskDateFormatted() {
      return moment(this.selectedTaskDate).format('DD.MM.YYYY');
    },
    buttonCreateTaskLabel() {
      if (this.task == null) {
        return this.$t('Auftrag erstellen');
      }
      return this.$t('Auftrag aktualisieren');
    },
    createTaskButtonDisabled() {
      return (
        this.buttonsDisabled || this.createTaskInProgress || (this.companySelectRequired && !this.selectedCompany.id)
      );
    },
    companySelectRequired() {
      return this.currentCompanies.length !== 1;
    },
  },
  watch: {
    task: {
      immediate: true,
      handler(newTask) {
        if (newTask != null && typeof newTask.timeStart === 'number') {
          this.setSelectedTaskDate(new Date(newTask.timeStart * 1000));
          return;
        }

        this.setSelectedTaskDate(new Date());
      },
    },
  },
  methods: {
    setSelectedTaskDate(date) {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setSelectedTaskDate`, date);
    },
    moveSelectedTaskDateBy(val) {
      const result = this.selectedTaskDate.setDate(this.selectedTaskDate.getDate() + val);
      this.setSelectedTaskDate(new Date(result));
    },
    async createTask() {
      this.$emit('update:errorMessage', null);
      this.createTaskInProgress = true;

      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setFetchingTask`, true);
      const taskData = await this.taskDataAsync(this.$store.dispatch);
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setFetchingTask`, false);

      const databaseId = getDatabaseId(this.selectedCompany);

      let redirect = false;
      try {
        if (this.task) {
          await axios.put(`/admin/comp/${databaseId}/taskplan/update/${this.task.id}`, taskData);
          redirect = true;
        } else {
          await axios.post(`/admin/comp/${databaseId}/taskplan/insert`, taskData);
          redirect = true;
        }
      } catch (error) {
        if (error.response) {
          this.$emit('update:errorMessage', error.response.data.errorUserMessage);
        }
      } finally {
        this.createTaskInProgress = false;
      }

      if (redirect) {
        this.$router.push({ name: 'task-plan' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 0 40px;
  margin: 30px 0;
}

.settings > *:not(:last-child) {
  margin-bottom: 18px;
}

.btn {
  width: 100%;
}

.export__datepicker-timestamp {
  color: var(--gray_600);
  margin-left: 6px;
}

.export__datepicker-wrapper {
  display: flex;
  padding: 3px;
  border: 1px solid;
  border-radius: 3px;
  border-color: var(--gray_400);
  justify-content: space-between;

  &:focus-within {
    border-color: var(--primary);
  }
}

.export__calendar {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.export__calendar-icon {
  margin: 5px;
  font-size: 16px;
}

.export__datepicker {
  position: absolute;
  width: 100%;
}

.export__datepicker-button-wrapper {
  display: flex;
}
</style>

<style lang="scss">
.export__datepicker-input {
  border: 0;
  cursor: pointer;
  width: 100%;

  &:focus {
    outline: 0;
  }
}
</style>
