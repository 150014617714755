<template lang="html">
  <aside class="navbar bg-primary" :class="{ 'navbar--expanded': expanded }">
    <div class="navbar__inner-wrapper" :class="{ 'navbar__inner-wrapper--expanded': expanded }">
      <div class="navbar__top">
        <button class="navbar__burger" @click="toggleNavbarVisibility">
          <FontAwesomeIcon v-if="expanded" :icon="['far', 'times']" class="text-white" />
          <FontAwesomeIcon v-else :icon="['far', 'bars']" class="text-white" />
        </button>
        <RouterLink class="navbar__logo" :class="{ 'navbar__logo--expanded': expanded }" :to="baseUrl">
          <IconBase name="Farmdok" width="129" height="25" viewBox="0 0 197 39" iconColor="white">
            <IconLogo />
          </IconBase>
        </RouterLink>
        <span class="flex-grow-1"></span>
        <MetaNav slim class="navbar__meta-nav" />
      </div>
      <div ref="navbarBottom" class="navbar__bottom" :class="{ 'navbar__bottom--expanded': expanded }">
        <nav class="navbar__primary-nav">
          <NavbarItem
            :icon="['fass', 'fa-grid-2']"
            :to="{ name: 'fields' }"
            :expanded="expanded"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
          <NavbarItem
            icon="pencil-alt"
            :to="{ name: 'activities' }"
            :expanded="expanded"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
          <NavbarItem
            icon="map"
            :to="{ name: 'map' }"
            :expanded="expanded"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
          <NavbarItem
            icon="chart-pie"
            :to="{ name: 'reports' }"
            :expanded="expanded"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
          <NavbarItem
            v-if="$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_DATURA_CONTROL)"
            icon="badge-check"
            :to="{ name: 'datura' }"
            :expanded="expanded"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
        </nav>
        <DebugComponentWrapper>
          <nav class="navbar__primary-nav my-3">
            <NavbarItem
              icon="map"
              :to="{ name: 'map' }"
              :expanded="expanded"
              @navigationLinkClicked="onNavigationLinkClicked"
            />
            <NavbarItem
              :icon="['far', 'rectangle-wide']"
              :to="{ name: 'button-test' }"
              :expanded="expanded"
              @navigationLinkClicked="onNavigationLinkClicked"
            />
            <NavbarItem
              :icon="['far', 'file-alt']"
              :to="{ name: 'form-test' }"
              :expanded="expanded"
              @navigationLinkClicked="onNavigationLinkClicked"
            />
          </nav>
        </DebugComponentWrapper>
        <span class="navbar__vertical-spacer flex-grow-1"></span>
        <nav class="navbar__secondary-nav">
          <NavbarItem
            icon="ellipsis-v"
            :expanded="expanded"
            :label="$t('Mehr')"
            :to="{ name: 'more' }"
            :disabled="true"
            :submenu="[
              {
                headline: $t('Mehr'),
                links: [
                  { to: { name: 'notes' } },
                  { to: { name: 'stock-movement' } },
                  { to: { name: 'cost-accounting' } },
                  { to: { name: 'digital-contracting' } },
                  { to: { name: 'more/shared-fields' } },
                ],
              },
              {
                headline: $t('Planung'),
                links: [{ to: { name: 'task-plan' } }, { to: { name: 'fertilization-plan' } }],
              },
              {
                headline: $t('Düngeverordnung'),
                links: [
                  { to: { name: 'fertilizer-regulation' } },
                  { to: { name: 'nutrient-comparison' } },
                  { to: { name: 'substance-flow' } },
                ],
              },
              {
                headline: $t('Precision Farming'),
                links: [{ to: { name: 'monitoring' } }, { to: { name: 'application-maps' } }],
              },
            ]"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
          <NavbarItem
            icon="cog"
            :to="{ name: 'management' }"
            :expanded="expanded"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
          <NavbarItem
            icon="shopping-cart"
            :to="{ name: 'shop' }"
            :expanded="expanded"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
          <NavbarItem
            v-if="$store.state.auth.user.superAdmin || $store.state.auth.user.isPartner"
            icon="qrcode"
            :to="{ name: 'partner/voucher-bundles' }"
            :expanded="expanded"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
          <NavbarItem
            v-if="$store.state.auth.user.superAdmin"
            icon="fire"
            :expanded="expanded"
            :label="$t('Superuser')"
            :submenu="[
              {
                headline: $t('Users and Companies'),
                links: [
                  { to: { name: 'admin/users' } },
                  { to: { name: 'admin/app-instances' } },
                  { to: { name: 'admin/partners' } },
                  { to: { name: 'admin/companies' } },
                  { to: { name: 'admin/switch-company' } },
                  { to: { name: 'admin/multi-companies' } },
                ],
              },
              {
                headline: $t('Sales'),
                links: [
                  { to: { name: 'partner/voucher-bundles' } },
                  { to: { name: 'admin/used-vouchers' } },
                  { to: { name: 'admin/agb' } },
                  { to: { name: 'admin/orders' } },
                  { to: { name: 'admin/licenses' } },
                  { to: { name: 'admin/invoices' } },
                ],
              },
              {
                headline: $t('Reports'),
                links: [{ to: { name: 'admin/reports' } }],
              },
              {
                headline: $t('Permissions'),
                links: [
                  { to: { name: 'admin/permission-groups' } },
                  { to: { name: 'admin/user-roles' } },
                  { to: { name: 'admin/license-modules' } },
                  { to: { name: 'admin/license-packages' } },
                ],
              },
              {
                headline: $t('Other'),
                links: [
                  { to: { name: 'admin/languages' } },
                  { to: { name: 'admin/material-import' } },
                  { to: { name: 'admin/field-import-mappings' } },
                ],
              },
            ]"
            @navigationLinkClicked="onNavigationLinkClicked"
          />
        </nav>
        <button class="navbar__toggle" @click="toggleNavbarVisibility">
          <FontAwesomeIcon icon="arrow-circle-right" v-if="!expanded" />
          <span class="navbar__toggle-text" :class="{ 'navbar__toggle-text--expanded': expanded }">{{
            $t('Menü einklappen')
          }}</span>
          <span class="flex-grow-1"></span>
          <FontAwesomeIcon icon="arrow-circle-left" v-if="expanded" />
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faFileAlt, faRectangleWide, faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faBadgeCheck,
  faBell,
  faChartPie,
  faCog,
  faEllipsisH,
  faEllipsisV,
  faFire,
  faHandHoldingSeedling,
  faHome,
  faMap,
  faPencilAlt,
  faPencilRuler,
  faQrcode,
  faQuestionCircle,
  faShareAlt,
  faShoppingCart,
  faStickyNote,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { faGrid2 } from '@fortawesome/sharp-solid-svg-icons';

import DebugComponentWrapper from '@/shared/components/DebugComponentWrapper.vue';
import { baseUrl } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import IconBase from '../svg/IconBase.vue';
import IconLogo from '../svg/IconLogo.vue';
import MetaNav from './MetaNav.vue';
import NavbarItem from './NavbarItem.vue';

library.add(
  faBell,
  faFire,
  faQrcode,
  faQuestionCircle,
  faUserCircle,
  faHome,
  faPencilAlt,
  faStickyNote,
  faChartPie,
  faBadgeCheck,
  faShoppingCart,
  faHandHoldingSeedling,
  faCog,
  faArrowCircleRight,
  faArrowCircleLeft,
  faMap,
  faEllipsisH,
  faEllipsisV,
  faPencilRuler,
  faBars,
  faTimes,
  faFileAlt,
  faRectangleWide,
  faShareAlt,
  faGrid2,
);

export default {
  name: 'TheNavbar',
  components: {
    DebugComponentWrapper,
    IconBase,
    IconLogo,
    NavbarItem,
    MetaNav,
  },
  data() {
    return {
      baseUrl,
      availableFeatures,
    };
  },
  computed: {
    expanded() {
      return this.$store.state.navbar.expanded;
    },
    sharedFieldsEnabled() {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_FIELDS_SHARED);
    },
  },
  methods: {
    toggleNavbarVisibility(event) {
      this.$store.commit('toggleNavbar');
      if (event != null && event.currentTarget != null) {
        event.currentTarget.blur();
      }
      this.$refs.navbarBottom.scrollTop = 0;
      this.$root.$emit('navbar::closeAllSubmenus');
    },
    onNavigationLinkClicked() {
      if (!window.matchMedia('(max-width: 767px)').matches) {
        return;
      }
      this.toggleNavbarVisibility();
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  position: sticky;
  width: var(--navbar-width, 50px);
  height: calc(100 * var(--vh, 1vh));
  padding: 0;
  flex: none;
  top: 0;
  box-shadow: inset -3px 0px 4px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  transition: width 0.5s ease;
  z-index: 200;
}

@media (max-width: 767px) {
  .navbar {
    --navbar-mobile-height: 45px;
    width: 100%;
    height: var(--navbar-mobile-height);
    box-shadow: none;
    z-index: 1000;
  }
}

.navbar__burger {
  display: none;
}

@media (max-width: 767px) {
  .navbar__burger {
    border: none;
    display: flex;
    justify-content: center;
    width: 26px;
    padding: 0 0 0 10px;
    margin-right: 13px;

    .fa-bars {
      width: 16px;
      height: 18px;
    }

    .fa-times {
      width: 12px;
      height: 18px;
    }
  }
}

.navbar__inner-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 767px) {
  .navbar__inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    background-color: var(--primary);
  }

  .navbar__inner-wrapper--expanded {
    height: calc(100 * var(--vh, 1vh));
  }
}

.navbar__meta-nav {
  display: none;
}

@media (max-width: 767px) {
  .navbar__meta-nav {
    display: flex;
  }
}

.navbar__top {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .navbar__top {
    padding: 0 10px;
    height: var(--navbar-mobile-height);
    align-items: center;
  }
}

@media (max-width: 767px) {
  .navbar__vertical-spacer {
    display: none;
  }
}

.navbar__bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .navbar__bottom {
    display: none;
    padding-top: 10px;
    overflow-y: auto;
    background-color: var(--primary);
  }

  .navbar__bottom--expanded {
    display: flex;
    opacity: 0;
    animation: b-icon-animation-fade 0.1s ease-in;
    animation-fill-mode: forwards;
  }
}

/* FARMDOK LOGO */
.navbar__logo {
  display: block;
  padding: 14px 13px;
  margin-bottom: 15px;
  width: 48px;
  overflow: hidden;
  transition: width 0.1s ease;

  @media (max-width: 400px) {
    svg {
      margin-left: -35px;
    }
  }
}

.navbar__logo--expanded {
  width: var(--navbar-width, 50px);
  transition: width 0.5s ease;
}

@media (max-width: 767px) {
  .navbar__logo,
  .navbar__logo--expanded {
    width: 129px;
    margin: 0;
    padding: 0;
    transition: none;
  }
}

/* PRIMARY NAVIGAITON */
.navbar__primary-nav {
  display: flex;
  flex-direction: column;
  padding: 10px 6px;
}

/* SECONDARY NAVIGATION */
.navbar__secondary-nav {
  padding: 0 6px 15px;
}

/* SIDEBAR TOGGLER */
.navbar__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 17px;
  background-color: rgba(19, 53, 60, 0.2);
  color: white;
  text-decoration: none;
  font-size: 1.14rem;
  z-index: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  outline: none;
  border: none;

  &:hover,
  &:focus {
    background-color: rgba(19, 53, 60, 0.4);
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.navbar__toggle-text {
  opacity: 0;
}

.navbar__toggle-text--expanded {
  animation: b-icon-animation-fade 0.1s ease-in;
  animation-delay: 0.35s;
  animation-fill-mode: forwards;
}

/* PRINT */
@media print {
  .navbar {
    display: none;
  }
}
</style>
