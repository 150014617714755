<template>
  <TheSatellite :empty="empty" :expanded="expanded" :title="$t('Schlagblatt')">
    <template v-if="expanded" #header-title>
      <h2 class="d-inline-block mr-5">
        <span
          v-if="typeof labelCurrentField === 'string' && labelCurrentField.length > 0"
          v-html="$t('Schlagblatt für {fieldName}', { fieldName: `<strong>${labelCurrentField}</strong>` })"
          class="font-weight-normal"
        ></span>
        <span v-else v-html="$t('Schlagblatt')"></span>
      </h2>
    </template>
    <template v-if="expanded" #header-center>
      <div class="position-relative satellite-field-detail__prev-next-navigation">
        <ButtonLink
          class="satellite-field-detail__center-button satellite-field-detail__center-button--left"
          color="black"
          :disabled="previousField == null"
          @click="$emit('update:currentFieldId', previousField.id)"
        >
          {{ labelPreviousField }}
        </ButtonLink>
        <div>
          <ButtonBorderedIcon
            :icon="['far', 'chevron-left']"
            :disabled="previousField == null"
            @click="$emit('update:currentFieldId', previousField.id)"
          />
          <ButtonBorderedIcon
            :icon="['far', 'chevron-right']"
            :disabled="nextField == null"
            @click="$emit('update:currentFieldId', nextField.id)"
          />
        </div>
        <ButtonLink
          class="satellite-field-detail__center-button satellite-field-detail__center-button--right"
          color="black"
          :disabled="nextField == null"
          @click="$emit('update:currentFieldId', nextField.id)"
        >
          {{ labelNextField }}
        </ButtonLink>
      </div>
    </template>
    <template v-if="expanded" #header-buttons>
      <div class="satellite-field-detail__page-actions">
        <ButtonBorderedIcon :title="$t('Seite drucken')" :icon="['far', 'print']" @click="printPage" />
        <!--
          <ButtonBorderedIcon
            :title="$t('Schlagblatt teilen')"
            :icon="['far', 'share-alt']"
          />
          <ButtonBorderedIcon
            :title="$t('Link kopieren')"
            :icon="['far', 'link']"
          />
        -->
      </div>
    </template>
    <template v-if="!expanded" #default>
      <WidgetMap
        :visible="visible"
        :expanded="expanded"
        :visible-fields="visibleFields"
        :selected-fields="selectedFields"
        :crops="crops"
        :varieties="varieties"
      />
      <WidgetCustomer
        v-if="selectedFields.length === 1"
        :expanded="expanded"
        :selected-fields="selectedFields"
        :customers="customers"
      />
      <WidgetActivities
        v-if="selectedFields.length === 1"
        :selectedFields="[selectedFields[0]]"
        :activityTypes="activityTypes"
        :activities="activities"
        :activitiesFetching="activitiesFetching"
        :units="units"
        :findProductStorageDropdownItem="findProductStorageDropdownItem"
      />
      <WidgetCropRotation v-if="selectedFields.length === 1" :expanded="expanded" :selected-field="selectedFields[0]" />
      <WidgetNotes
        v-if="selectedFields.length === 1"
        :expanded="expanded"
        :selectedFields="[selectedFields[0]]"
        :notes="notes"
        :notesFetching="notesFetching"
      />
      <WidgetCropRatio
        v-if="selectedFields.length > 1"
        :fields="selectedFields"
        :crops-by-id="crops"
        :varieties-by-id="varieties"
      />
      <WidgetKPIs v-if="selectedFields.length > 1" :selected-fields="selectedFields" :kindOfUseTypes="kindOfUseTypes" />
      <WidgetReports v-if="selectedFields.length > 1" />
    </template>
    <template #expanded-area>
      <div v-if="loading" class="w-100 mb-5 bg-lightest" style="height: calc(40 * var(--vh, 1vh))" />
      <div v-if="loading" class="d-flex w-100" style="height: calc(40 * var(--vh, 1vh))">
        <div class="mr-5 flex-grow-1 bg-lightest" />
        <div class="flex-grow-1 bg-lightest" />
      </div>
      <WidgetMap
        :visible="visible"
        :expanded="expanded"
        :visible-fields="fieldsForExpandedMode"
        :selected-fields="currentField != null ? [currentField] : []"
        :crops="crops"
        :varieties="varieties"
      />
      <WidgetActivities
        v-if="currentField != null"
        :expanded="expanded"
        :selectedFields="[currentField]"
        :activityTypes="activityTypes"
        :activities="activities"
        :activitiesFetching="activitiesFetching"
        :units="units"
        :findProductStorageDropdownItem="findProductStorageDropdownItem"
      />
      <div class="satellite-field-detail__column-container">
        <div class="satellite-field-detail__column">
          <WidgetCustomer
            v-if="currentField != null"
            :expanded="expanded"
            :selectedFields="[currentField]"
            :customers="customers"
          />
          <WidgetCropRotation v-if="currentField != null" :expanded="expanded" :selected-field="currentField" />
          <WidgetNotes
            v-if="currentField != null"
            :expanded="expanded"
            :selectedFields="[currentField]"
            :notes="notes"
            :notesFetching="notesFetching"
          />
        </div>
        <div class="satellite-field-detail__column">
          <WidgetNutrientBalance v-if="currentField != null" :selectedFields="[currentField]" />
          <Widget v-if="currentField != null" class="widget-weather-info">
            <WeatherInfo
              variant="widget"
              :selectedField="currentField"
              :navigationDate="navigationDate"
              @update:navigationDate="(date) => (navigationDate = date)"
            />
          </Widget>
        </div>
      </div>
      <WidgetSoilSamples v-if="currentField != null" :selected-field="currentField" />
    </template>
    <template #empty>
      <div class="field-detail__message-empty">
        {{ $t('Keine Einträge vorhanden.') }}
      </div>
    </template>
  </TheSatellite>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faLink, faPrint, faShareAlt } from '@fortawesome/pro-regular-svg-icons';
import { mapGetters } from 'vuex';

import TheSatellite from '@/layout/components/TheSatellite.vue';
import Widget from '@/shared/components/Widget.vue';
import ButtonBorderedIcon from '@/shared/components/buttons/ButtonBorderedIcon.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import WeatherInfo from '@/shared/components/weather/WeatherInfo.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import { fullFieldName } from '../handsontable/columns/columns';
import WidgetActivities from './WidgetActivities.vue';
import WidgetCropRatio from './WidgetCropRatio.vue';
import WidgetCropRotation from './WidgetCropRotation.vue';
import WidgetCustomer from './WidgetCustomer.vue';
import WidgetKPIs from './WidgetKPIs.vue';
import WidgetMap from './WidgetMap.vue';
import WidgetNotes from './WidgetNotes.vue';
import WidgetNutrientBalance from './WidgetNutrientBalance.vue';
import WidgetReports from './WidgetReports.vue';
import WidgetSoilSamples from './WidgetSoilSamples.vue';

library.add(faPrint, faShareAlt, faLink, faChevronLeft, faChevronRight);

export default {
  name: 'SatelliteFieldDetail',
  components: {
    ButtonLink,
    WidgetKPIs,
    WidgetReports,
    WidgetCropRatio,
    ButtonBorderedIcon,
    TheSatellite,
    WidgetNutrientBalance,
    WeatherInfo,
    WidgetCropRotation,
    WidgetMap,
    WidgetActivities,
    WidgetNotes,
    Widget,
    WidgetSoilSamples,
    WidgetCustomer,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    visibleFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    currentFieldId: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    crops: {
      type: Object,
      default: () => ({}),
    },
    varieties: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      navigationDate: new Date(),
    };
  },
  created() {
    this.$store.dispatch('fields/subscribe');
    this.$store.dispatch('activityTypes/subscribe');
    this.$store.dispatch('activities/subscribe');
    this.$store.dispatch('units/subscribe');
    if (this.currentCompanyHasFeatureNotes.enabled && this.currentCompanyHasFeatureNotes.visible) {
      this.$store.dispatch('notes/subscribe');
    }
    this.$store.dispatch('storagePlaces/subscribe');
    this.$store.dispatch('products/subscribe');
  },
  computed: {
    ...mapGetters({
      units: 'units/data',
      customers: 'customers/data',
      activityTypes: 'activityTypes/data',
      activities: 'activities/data',
      activitiesFetching: 'activities/fetching',
      notes: 'notes/data',
      notesFetching: 'notes/fetching',
      kindOfUseTypes: 'fields/kindOfUseTypes',
      findProductStorageDropdownItem: 'products/dropdownItem',
    }),
    currentCompanyHasFeatureNotes() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_NOTES);
    },
    empty() {
      if (this.loading) {
        return false;
      }
      if (this.selectedFields.length === 0) {
        return true;
      }
      if (this.expanded && this.currentField == null) {
        return true;
      }
      return false;
    },
    fieldsForExpandedMode() {
      if (this.selectedFields.length === 1 && this.visibleFields.length > 1) {
        return this.visibleFields;
      }
      return this.selectedFields;
    },
    currentField() {
      let currentField = null;
      this.fieldsForExpandedMode.some((field) => {
        if (field.id === this.currentFieldId) {
          currentField = field;
          return true;
        }
        return false;
      });
      return currentField;
    },
    labelCurrentField() {
      return fullFieldName.data(this.currentField);
    },
    previousField() {
      let previousField = null;
      this.fieldsForExpandedMode.some((field, index) => {
        if (field.id !== this.currentFieldId) {
          return false;
        }
        if (this.fieldsForExpandedMode[index - 1] != null) {
          previousField = this.fieldsForExpandedMode[index - 1];
        }
        return true;
      });
      return previousField;
    },
    labelPreviousField() {
      if (this.previousField == null) {
        return this.$t('Kein weiteres Feld');
      }
      return fullFieldName.data(this.previousField);
    },
    nextField() {
      let nextField = null;
      this.fieldsForExpandedMode.some((field, index) => {
        if (field.id !== this.currentFieldId) {
          return false;
        }
        if (this.fieldsForExpandedMode[index + 1] != null) {
          nextField = this.fieldsForExpandedMode[index + 1];
        }
        return true;
      });
      return nextField;
    },
    labelNextField() {
      if (this.nextField == null) {
        return this.$t('Kein weiteres Feld');
      }
      return fullFieldName.data(this.nextField);
    },
  },
  watch: {
    visibleFields() {
      if (this.currentCompanyHasFeatureNotes.enabled && this.currentCompanyHasFeatureNotes.visible) {
        this.$store.dispatch('notes/subscribe');
      }
      this.$store.dispatch('activityTypes/subscribe');
      this.$store.dispatch('activities/subscribe');
      this.$store.dispatch('units/subscribe');
    },
  },
  methods: {
    printPage() {
      window.print();
    },
  },
};
</script>

<style lang="css" scoped>
.field-detail__message-empty {
  margin: auto;
}

.satellite__left .widget {
  margin-bottom: var(--spacer_5);
}

.satellite__right .widget {
  margin-bottom: calc(var(--spacer_6) * 1.3);
}

.satellite-field-detail__center-button {
  position: absolute;
  top: 50%;
  white-space: nowrap;
  transform: translateY(-50%);
  font-size: 12px;
}

.satellite-field-detail__center-button--left {
  right: 100%;
  margin-right: var(--spacer_3);
}

.satellite-field-detail__center-button--right {
  left: 100%;
  margin-left: var(--spacer_3);
}

.satellite-field-detail__column-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.satellite-field-detail__column {
  width: calc(50% - var(--spacer_4));
}

.widget-weather-info {
  max-width: 600px;
}

@media print {
  .satellite-field-detail__prev-next-navigation {
    display: none;
  }
  .satellite-field-detail__page-actions {
    display: none;
  }
}
</style>
