import Vue from 'vue';
import { MutationTree } from 'vuex';

import initialState from './initialState';
import { MonitoringState } from './types';

const mutations: MutationTree<MonitoringState> = {
  reset(state) {
    const newState = initialState();
    Object.entries(newState).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },
  setSelectedFields(state, data) {
    Vue.set(state, 'selectedFields', data);
  },
  addPolygon(state, data) {
    Vue.set(state.polygons, data.field_key, data);
  },
  setPolygonProperty(state, polygon) {
    const polygons = { ...state.polygons };

    if (polygon.subProperty) {
      // @ts-ignore
      polygons[polygon.key][polygon.property][polygon.subProperty] = polygon.value;
    } else {
      // @ts-ignore
      polygons[polygon.key][polygon.property] = polygon.value;
    }
    Vue.set(state, 'polygons', polygons);
  },
  // HEATMAPS
  addHeatmaps(state, heatmaps) {
    Vue.set(state.heatmaps, 'loaded', { ...state.heatmaps.loaded, ...heatmaps });
  },
  setCurrentHeatmaps(state, heatmaps) {
    Vue.set(state.heatmaps, 'current', heatmaps);
  },
  unsetCurrentHeatmap(state, heatmapId) {
    const newCurrentHeatmaps = { ...state.heatmaps.current };

    delete newCurrentHeatmaps[heatmapId];
    Vue.set(state.heatmaps, 'current', newCurrentHeatmaps);
  },
  unsetCurrentHeatmaps(state) {
    Vue.set(state.heatmaps, 'current', {});
  },
  // MULTIPOLY
  addMultipolyTimestamp(state, timestamp) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.loaded };

    newMultiPolyTimestamps[timestamp.key] = timestamp.data;
    Vue.set(state.multiPolyTimestamps, 'loaded', newMultiPolyTimestamps);
  },
  setCurrentMultipolyTimestamp(state, timestamp) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.current };

    newMultiPolyTimestamps[timestamp.key] = timestamp.data;
    Vue.set(state.multiPolyTimestamps, 'current', newMultiPolyTimestamps);
  },
  unsetCurrentMultipolyTimestamp(state, polygonKey) {
    const newMultiPolyTimestamps = { ...state.multiPolyTimestamps.current };

    delete newMultiPolyTimestamps[polygonKey];
    Vue.set(state.multiPolyTimestamps, 'current', newMultiPolyTimestamps);
  },
  unsetCurrentMultipolyTimestamps(state) {
    Vue.set(state.multiPolyTimestamps, 'current', {});
  },
  // VEGETAIONDATA
  addVegetationData(state, timestamp) {
    const newVegetationData = { ...state.vegetationData.loaded };

    newVegetationData[timestamp.key] = timestamp.data;
    Vue.set(state.vegetationData, 'loaded', newVegetationData);
  },
  setCurrentVegetationData(state, timestamp) {
    const newVegetationData = { ...state.vegetationData.current };

    newVegetationData[timestamp.key] = timestamp.data;
    Vue.set(state.vegetationData, 'current', newVegetationData);
  },
  unsetCurrentVegetationData(state, polygonKey) {
    const newVegetationData = { ...state.vegetationData.current };

    delete newVegetationData[polygonKey];
    Vue.set(state.vegetationData, 'current', newVegetationData);
  },
  unsetAllCurrentVegetationData(state) {
    Vue.set(state.vegetationData, 'current', {});
  },
  setSelectedIndexType(state, indexType) {
    Vue.set(state, 'selectedIndexType', indexType);
  },
  setSelectedTimestamp(state: MonitoringState, timestamp) {
    Vue.set(state, 'selectedTimestamp', timestamp);
  },
  setSelectedHeatmapDbId(state, heatMapDbId) {
    Vue.set(state, 'selectedHeatmapDbId', heatMapDbId);
  },
  toggleOverlayFold(state) {
    Vue.set(state.overlay, 'folded', !state.overlay.folded);
  },
  setOverlayLoading(state) {
    Vue.set(state.overlay, 'loading', true);
  },
  unsetOverlayLoading(state) {
    Vue.set(state.overlay, 'loading', false);
  },
};

export default mutations;
