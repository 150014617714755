import Handsontable from 'handsontable';
import Vue from 'vue';

import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';

import ReadOnlyRenderer from './ReadOnlyRenderer';
import RequiredRenderer from './RequiredRenderer';
import getRendererValueDate from './helpers/getRendererValueDate';

const { fastInnerHTML } = Handsontable.dom;

export default function DateRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  Handsontable.renderers.getRenderer('base')(instance, TD, row, col, prop, value, cellProperties, ...args);

  if (cellProperties.required && (value == null || Number.isNaN(value))) {
    RequiredRenderer.apply(this, [instance, TD, row, col, prop, value, cellProperties, ...args]);
    return;
  }

  let internalValue = value;
  if (value == null && cellProperties.placeholder != null) {
    internalValue = cellProperties.placeholder;
  }

  internalValue = getRendererValueDate(internalValue);

  if (cellProperties.required) {
    Vue.set(cellProperties, 'valid', !!internalValue);
  }

  fastInnerHTML(TD, internalValue);

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, row, col, prop, internalValue, cellProperties, ...args]);
  }

  applyMergeRowsBorderStyles(TD, row, instance);
}
