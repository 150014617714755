
import { PropType, defineComponent } from 'vue';

import Dropdown from '../Dropdown.vue';
import BaseFormField from './BaseFormField.vue';
import { DropdownItem, DropdownItemsPerCategory } from './formFieldDropdownTypes';

/**
 * Renders a Dropdown inside a BFormGroup. Ideally placed within a FieldSet.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 */
export default defineComponent({
  name: 'FormFieldDropdown',
  components: { BaseFormField, Dropdown },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    /**
     * Id set for input. If not set a unique id is generated as bootstrap places the label next to the input.
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * Adds the `required` attribute to the form control.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Label rendered above or to the left, depending on the variant.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Supported types:
     *  <table style="width:30%;">
     *    <tr><td>'horizontal'</td><td>Label to the left.</td></tr>
     *    <tr><td>'vertical'</td><td>Label above. Increased margin bottom.</td></tr>
     *    <tr><td>'vertical-lg'</td><td>Label above. Increases margin bottom and input paddings.</td></tr>
     * </table>
     */
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value: string) => ['horizontal', 'vertical', 'vertical-lg'].includes(value),
    },
    variantVariation: {
      type: String,
      validator: (variant: string) => ['small-modal'].includes(variant),
    },
    /**
     * If set to `true` or `false` the input will be highlighted with colors and icons, depending on the state.<br>
     * Default `null`.
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * The selected value:<br>
     * <pre>
     *   { id: 'id', name: 'label' }
     * </pre>
     * If nothing is selected:<br>
     * <pre>
     *   { id: null, name: '' }
     * </pre>
     */
    value: {
      type: Object as PropType<DropdownItem>,
      required: true,
    },
    /**
     * The selectable items. The Category name will be displayed if there is more then one category.
     * <pre>
     *   [{
     *     name: 'Category',
     *     id: 'category',
     *     items: [{ id: 'a', name: 'Item 1' }, { id: 'b', name: 'Item 2' }, { id: 'c', name: 'Item 3' }, ...],
     *     sort: true,
     *   }, ...]
     * </pre>
     */
    items: {
      type: Array as PropType<DropdownItemsPerCategory[]>,
      required: true,
    },
    /**
     * If the items are not fetched yet set this to `true`. A spinner will be shown when the user opens the dropdown.
     */
    itemsLoading: {
      type: Boolean,
      default: false,
    },

    boundary: {
      type: [Window, HTMLElement, String, Function],
    },
    /**
     * Placeholder text when no item is selected.
     */
    placeholder: {
      type: String,
      default() {
        return this.$t('Tippen um zu suchen');
      },
    },
    /**
     * If set to `true` the following props will be overwritten:
     *   - value with `null`
     *   - placeholder with `'(verschiedene)'`
     */
    various: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable the form element.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    slimMargin: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    positionFixed: {
      type: Boolean,
      default: true,
    },
    colorCoded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    itemsComputed(): { selected: DropdownItem; data: DropdownItemsPerCategory[]; loading: boolean } {
      return {
        selected: this.value,
        data: this.items,
        loading: this.itemsLoading,
      };
    },
  },
  methods: {
    /**
     * Emitted when the selected value changes. Emitted to update the v-model.
     *
     * @event FormFieldDropdown#update
     * @type {Object} value
     */
    update(value: DropdownItem) {
      this.$emit('update', value);
    },
    /**
     * Emitted when the selected value is cleared. Emitted to update the v-model.
     *
     * @event FormFieldDropdown#update
     * @type {Object} value
     */
    deleteValue() {
      this.$emit('update', { id: null, name: null, value: undefined });
    },
    /**
     * Emitted when the dropdown is opened.
     *
     * @event FormFieldDropdown#open
     */
    open() {
      this.$emit('open');
    },
  },
});
