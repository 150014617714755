<template>
  <PageApplicationMaps :workflowKey="workflowKey" :stepCount="4" @onNavigationNext="onNavigationNext">
    <template #steps>
      <SidebarFields
        tableId="sidebar-table-fields-fertilization"
        v-show="paginationStep === 1"
        :selectedRows.sync="selectedFields"
        :customColumnDefinition="columnDefinition"
      />
      <SidebarZones v-show="paginationStep === 2" :workflowKey="workflowKey" />
      <SidebarCreateApplicationMapFertilization v-if="paginationStep === 3" :workflowKey="workflowKey" />
      <SidebarExportFertilization v-show="paginationStep === 4" :workflowKey="workflowKey" />
    </template>
  </PageApplicationMaps>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import SidebarFields from '@/fields/components/SidebarFields.vue';
import { WorkflowKey } from '@/precision-farming/application-maps/types';

import columnsSidebarTableFields from '../../../fields/handsontable/columns/applicationMapsSidebarTableFields';
import PageApplicationMaps from '../PageApplicationMaps.vue';
import SidebarCreateApplicationMapFertilization from './SidebarCreateApplicationMapFertilization.vue';
import SidebarExportFertilization from './SidebarExportFertilization.vue';
import SidebarZones from './SidebarZones.vue';

export default {
  name: 'PageApplicationMapsFertilization',
  components: {
    PageApplicationMaps,
    SidebarCreateApplicationMapFertilization,
    SidebarZones,
    SidebarFields,
    SidebarExportFertilization,
  },
  data() {
    return {
      workflowKey: WorkflowKey.FERTILIZATION,
      columnDefinition: columnsSidebarTableFields,
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilization', ['paginationStep']),
    ...mapGetters({
      zones: 'precisionFarming/applicationMaps/fertilization/zones',
    }),
    selectedFields: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps.fertilization.selectedFields;
      },
      set(selected) {
        this.$store.commit('precisionFarming/applicationMaps/fertilization/setSelectedFields', selected);
      },
    },
  },
  watch: {
    zones() {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setManualDosage`, null);
    },
  },

  methods: {
    onNavigationNext(callBack) {
      callBack();
    },
  },
};
</script>
