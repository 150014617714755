import Vue from 'vue';
import vuexI18n from 'vuex-i18n';

import store from '@/store';
import de from '@/translations/de';
import en from '@/translations/en';

/**
 * setup vuex-i18n
 */
Vue.use(vuexI18n.plugin, store, {
  onTranslationNotFound: (locale, key) => {
    if (store.state.fetchingTranslation) {
      return '...';
    }
    // 'de' uses keys as translation, every other language uses fallback 'en'
    if (locale.substring(0, 2) === 'de') {
      return key;
    }
    return null;
  },
});
Vue.i18n.add('de', de());
Vue.i18n.add('en', en());
Vue.i18n.fallback('en');

Vue.i18n.set(store.state.locale);

// export for usage in Composition API
const { i18n } = Vue;
export default i18n;
