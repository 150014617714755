<template>
  <ThePageSkeleton
    :page-feature-title="$router.currentRoute.meta.label"
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <template #right="{ contentbarLarge }">
          <SearchBar class="ml-4" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <CompanySwitch class="ml-4" :slim="!contentbarLarge" />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <div class="open-calls__card-container">
        <p v-if="contractsComputed.length === 0">
          {{ $t('Keine Kontrakte vorhanden.') }}
          <br />
          <BButton variant="link" @click="$router.push({ name: 'digital-contracting/my-contracts' })">{{
            $t('Zu meinen Verträgen')
          }}</BButton>
        </p>
        <CardContract
          v-for="(contract, index) in contractsComputed"
          :key="`card-${index}`"
          :contract="contract"
          :pageFeaturePermissions="currentCompaniesHaveFeature"
          @send="sendContract(contract)"
        />
      </div>
    </template>
  </ThePageSkeleton>
</template>

<script>
import { mapGetters } from 'vuex';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import CardContract from './components/CardContract.vue';
import filterContracts from './mixins/filterContracts';

export default {
  name: 'PageOpenCalls',
  components: {
    TheContentbar,
    ThePageSkeleton,
    CompanySwitch,
    SearchBar,
    CardContract,
  },
  mixins: [filterContracts],
  props: {
    noCompanySwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      storeUnsubscribe: null,
    };
  },
  computed: {
    ...mapGetters({
      contracts: 'digitalContracting/data',
    }),
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_DIGITAL_CONTRACTING);
    },
    contractsComputed() {
      if (this.$store.state.digitalContracting.fetching) {
        return [{ fetching: true }, { fetching: true }, { fetching: true }];
      }
      const now = new Date() / 1000;
      const contracts = Object.values(this.contracts).filter(
        (contract) =>
          contract.contractCompanyId == null &&
          (contract.periodEnd == null || contract.periodEnd > now) &&
          (contract.periodStart == null || contract.periodStart < now),
      );
      return this.filterContracts(contracts);
    },
  },
  created() {
    this.$store.dispatch('digitalContracting/subscribe');
    this.storeUnsubscribe = this.$store.subscribe(({ type }) => {
      if (type === 'afterResetData') {
        this.$store.dispatch('digitalContracting/subscribe');
      }
    });
  },
  beforeDestroy() {
    this.storeUnsubscribe();
  },
  methods: {
    sendContract(contract) {
      this.$router.push(`contracting/${contract.guid}`);
    },
  },
};
</script>

<style scoped>
.open-calls__card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  height: auto;
  max-height: 100%;
  padding: 12px;
  overflow-y: auto;
}
</style>
