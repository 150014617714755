import { Activity, ActivityEquipment, ActivityProduct, RuleViolationType } from 'farmdok-rest-api';

import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { Expandable, Selectable, TableDataBase } from '@/shared/handsontable/rework/types';

export type TableDataActivity = TableDataBase &
  Expandable &
  Selectable &
  Omit<Activity, 'activityTypeId' | 'fieldId' | 'products' | 'equipment' | 'userId'> & {
    activityTypeDropdownItem?: DropdownItem;
    fieldDropdownItem?: DropdownItem;
    product?: TableDataActivityProduct;
    equipment?: TableDataActivityEquipment;
    fieldSize?: number;
    ruleCheckStatus?: RuleCheckStatus;
    companyName: string;
    user: string | undefined;
  } & {
    storeStatus: symbol | undefined;
  };

export type TableDataActivityProduct = Omit<ActivityProduct, 'amount' | 'unitId'> & {
  productStorageDropdownItem?: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER';
  pesticideIndicationDropdownItem?: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER';
  isLoading: boolean;
} & AmountsAndUnits;

export type TableDataActivityEquipment = Omit<ActivityEquipment, 'equipmentId'> & {
  dropdownItem?: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER';
};

export type AmountsAndUnits = {
  amountUnitRelative: AmountUnitPair;
  amountUnitTotal: AmountUnitPair;
};

export type AmountUnitPair = {
  amount: number | null;
  unitId: string | null;
  isFixed: boolean;
};

export function isAmountUnitPair(value: unknown): value is AmountUnitPair {
  return typeof value === 'object' && value != null && 'amount' in value && 'unitId' in value && 'isFixed' in value;
}

export type RuleCheckStatus = {
  type: RuleCheckStatusType;
  tooltipLabel: string | null;
};

export type RuleCheckStatusType = 'OK' | RuleViolationType;

export type RuleCheckStatusTypeLoading = RuleCheckStatusType | 'LOADING';
