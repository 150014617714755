import moment from 'moment';

export default {
  data() {
    return {
      filterString: '',
    };
  },
  methods: {
    filterContracts(contracts) {
      if (typeof this.filterString !== 'string' || this.filterString.length === 0) {
        return contracts;
      }
      return contracts.filter((contract) => {
        if (
          typeof contract.name === 'string' &&
          contract.name.toLowerCase().includes(this.filterString.toLowerCase())
        ) {
          return true;
        }
        if (
          typeof contract.description === 'string' &&
          contract.description.toLowerCase().includes(this.filterString.toLowerCase())
        ) {
          return true;
        }
        const periodStart = moment.unix(contract.periodStart).format('L');
        if (periodStart.includes(this.filterString.toLowerCase())) {
          return true;
        }
        const periodEnd = moment.unix(contract.periodEnd).format('L');
        if (periodEnd.includes(this.filterString.toLowerCase())) {
          return true;
        }
        return false;
      });
    },
  },
};
