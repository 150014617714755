import Handsontable from 'handsontable';

import isFirstRowOfEntry from '../utils/isFirstRowOfEntry';

/**
 * This function is the "undo" of propagateChangesToMergedCells()
 * after changes are propagated to merged cells, we need to filter out the changes to merged cells
 * otherwise we would propagate multiple changes (of the same value) to other hooks or e.g. the vuex store
 * @param hot
 * @returns
 */
export default function filterChangesToMergedCellsFactory(hot: Handsontable | null) {
  function filterChangesToMergedCells(
    changes: Handsontable.CellChange[] | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    source?: Handsontable.ChangeSource,
  ): Handsontable.CellChange[] | null {
    if (!hot) return null;
    if (!changes) return null;

    removeChangesToMergedCells(changes, hot);

    return changes; // although we return changes here, we need to mutate the original changes so that the next hook will get the updated changes
  }

  return filterChangesToMergedCells;
}

function removeChangesToMergedCells(changes: Handsontable.CellChange[], hot: Handsontable): void {
  const idsToRemove = changes.reduce((acc, change, index) => {
    const [row, prop] = change;
    if (isFirstRowOfEntry(row, hot)) return acc;

    const column = hot.propToCol(prop);
    const cellMeta = hot.getCellMeta(row, column);
    if (cellMeta.isSubtableColumn) return acc;

    acc.push(index);
    return acc;
  }, [] as number[]);

  for (let i = changes.length - 1; i >= 0; i -= 1) {
    if (idsToRemove.includes(i)) {
      changes.pop();
    }
  }
}
