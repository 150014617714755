import { AxiosRequestConfig } from 'axios';
import { ActionContext, Module } from 'vuex';

import { fetchProducts } from '@/shared/api/rest/RestApi';
import { ApiResponse, Product, ProductCategoryType } from '@/shared/api/rest/models';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

function fetchFunctionsFactory<Entry extends Product, Resolve = undefined>(
  categoryType: ProductCategoryType,
  resolve?: string[],
) {
  return {
    fetchAll: async (
      context: ActionContext<SubscribableDataState<Entry>, RootState>,
      axiosOptions?: AxiosRequestConfig,
    ): Promise<ApiResponse<Entry[], Resolve>> => {
      const { currentCompanies } = context.rootState.auth;

      const { data } = await fetchProducts<Entry, Resolve>(currentCompanies, categoryType, axiosOptions, {
        resolve,
        itemsPerPage: 5000,
      });

      return data;
    },
    fetchByIds: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ids: string[],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      context: ActionContext<SubscribableDataState<Entry>, RootState>,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      axiosOptions?: AxiosRequestConfig,
    ): Promise<ApiResponse<Entry[]>> => {
      throw new Error('NOT IMPLEMENTED');
    },
  };
}

export default function subscribableStoreFactory<Entry extends Product, Resolve = undefined>(
  categoryType: ProductCategoryType,
  resolve?: string[],
): Module<SubscribableDataState<Entry>, RootState> {
  const { fetchAll, fetchByIds } = fetchFunctionsFactory<Entry, Resolve>(categoryType, resolve);
  return subscribableData<Entry, Resolve>(fetchAll, fetchByIds);
}
