
import { Activity, MoveActivityMode } from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Getters as MoveActivityGetters } from '@/activities/modals/moveActivity/store/getters';
import { Getters as FieldGetters } from '@/fields/store/getters';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { DropdownItems } from '@/shared/handsontable/types';

import ConflictionStep from '../components/ConflictionStep.vue';
import { MoveActivityParameterWithResult } from '../store/types';

export default defineComponent({
  name: 'ConflictionStepContainer',
  components: {
    ConflictionStep,
  },
  created() {
    this.$store.dispatch('activityTypes/subscribe');
    this.$store.dispatch('fields/subscribe');
  },
  computed: {
    ...mapGetters({
      activities: 'activities/data',
      fields: 'fields/data',
      activityTypes: 'activityTypes/data',
      customers: 'customers/data',
      crops: 'products/crops/data',
    }),
    ...mapState('activities/moveActivity', [
      'counterCurrentMoveActivityConflict',
      'moveActivityParametersWithResult',
      'selectedProcessOrderId',
      'keepOption',
    ]),
    fieldDropdownItems(): DropdownItems[] {
      return (this.$store.getters['fields/dropdownItems'] as FieldGetters['dropdownItems'])(
        this.selectedProcessOrderId,
      );
    },
    moveActivityParametersWithConflict(): MoveActivityParameterWithResult[] | null {
      return this.$store.getters[
        'activities/moveActivity/moveActivityParametersWithConflict'
      ] as MoveActivityGetters['moveActivityParametersWithConflict'];
    },
    moveActivityParametersWithoutMode(): MoveActivityParameterWithResult[] | null {
      return this.$store.getters[
        'activities/moveActivity/moveActivityParametersWithoutMode'
      ] as MoveActivityGetters['moveActivityParametersWithoutMode'];
    },
    currentMoveActivityParameter(): MoveActivityParameterWithResult | null {
      if (this.moveActivityParametersWithConflict) {
        return this.moveActivityParametersWithConflict[this.counterCurrentMoveActivityConflict];
      }
      return null;
    },
    selectedField(): DropdownItem | null {
      if (this.currentMoveActivityParameter) {
        return (
          this.fieldDropdownItems[0].items.find(
            (field) => field.id === this.currentMoveActivityParameter?.destinationFieldId,
          ) || null
        );
      }
      return null;
    },
    selectedMode(): MoveActivityMode | null {
      return this.currentMoveActivityParameter?.mode || null;
    },
    activity(): Activity | null {
      if (this.currentMoveActivityParameter) {
        return this.activities[this.currentMoveActivityParameter.sourceActivityId];
      }
      return null;
    },
  },
  methods: {
    updateFieldId(fieldId: string | null): void {
      this.$store.commit('activities/moveActivity/setDestinationFieldIdOnMoveActivityParametersWithResult', {
        id: this.currentMoveActivityParameter?.sourceActivityId,
        destinationFieldId: fieldId,
      });
    },
    updateMode(mode: MoveActivityMode, sourceActivityId?: string): void {
      this.$store.commit('activities/moveActivity/setModeOnMoveActivityParametersWithResult', {
        id: sourceActivityId || this.currentMoveActivityParameter?.sourceActivityId,
        mode,
      });
    },
    updateKeepOption(keepOption: boolean): void {
      this.$store.commit(`activities/moveActivity/setKeepOption`, keepOption);

      if (keepOption) {
        this.moveActivityParametersWithoutMode?.forEach((moveActivity) => {
          this.updateMode(MoveActivityMode.CopyField, moveActivity.sourceActivityId);
        });
      }
    },
  },
});
