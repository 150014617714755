import { Module } from 'vuex';

import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import usedItemsStore, { initialState as initialStateUsedItems } from '@/shared/mixins/store/usedItems';
import { RootState } from '@/store/types';

import subscribableStore from './common';
import getters from './getters';
import { EquipmentsState } from './types';

export function initialState(): EquipmentsState {
  return {
    ...initialStateSubscribable(),
    ...initialStateUsedItems(),
  };
}

const vuexModule: Module<EquipmentsState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: { ...subscribableStore.mutations, ...usedItemsStore.mutations },
  actions: { ...subscribableStore.actions },
  getters: { ...getters },
};

export default vuexModule;
