<template>
  <WelcomeContentContainer class="step-farmer-work" padding-top>
    <template #default>
      <MaxWidthContainer size="lg">
        <WelcomeTitle white>
          {{ $t('Wie bewirtschaftest du?') }}
        </WelcomeTitle>
      </MaxWidthContainer>
      <MaxWidthContainer class="mt-auto">
        <ButtonOnboarding
          :active="farmingSystem === 'organic'"
          @click="$emit('change', { key: 'farmingSystem', value: 'organic' })"
        >
          {{ $t('Biologisch') }}
        </ButtonOnboarding>
        <ButtonOnboarding
          :active="farmingSystem === 'conventional'"
          @click="$emit('change', { key: 'farmingSystem', value: 'conventional' })"
        >
          {{ $t('Konventionell') }}
        </ButtonOnboarding>
      </MaxWidthContainer>
    </template>
    <template #cta-container>
      <MaxWidthContainer>
        <Button variant="primary" size="lg" block class="my-0" :disabled="farmingSystem == null" @click="$emit('next')">
          {{ $t('Weiter') }}
        </Button>
      </MaxWidthContainer>
    </template>
  </WelcomeContentContainer>
</template>

<script>
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';

import image from '../assets/FARMDOK_Onboarding_Field_Portrait.jpg';
import imageLandscape from '../assets/FARMDOK_Onboarding_Tractor_Field_Landscape.jpg';
import ButtonOnboarding from './ButtonOnboarding.vue';
import WelcomeContentContainer from './WelcomeContentContainer.vue';
import WelcomeTitle from './WelcomeTitle.vue';

export default {
  name: 'StepFarmerWork',
  components: {
    ButtonOnboarding,
    MaxWidthContainer,
    WelcomeContentContainer,
    WelcomeTitle,
    Button,
  },
  props: {
    farmingSystem: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.$emit('background', { image, imageLandscape });
  },
};
</script>

<style scoped></style>
