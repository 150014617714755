import {
  ActivityRuleViolation,
  RuleCheckApi,
  RuleCheckApiRulecheckActivityRuleViolationsRequest,
  RuleCheckApiRulecheckRecheckRequest,
} from 'farmdok-rest-api';

import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';

export default class ActivityRuleViolationsApiService {
  private readonly ruleCheckApi: RuleCheckApi;

  constructor(ruleCheckApi: RuleCheckApi) {
    this.ruleCheckApi = ruleCheckApi;
  }

  public async getActivityRuleViolations(processOrderIds: string[]): Promise<ApiResponse<ActivityRuleViolation>> {
    const apiResponses = await Promise.all(
      processOrderIds.map((processOrderId) => this.getActivityRuleViolationsSingleProcessOrder(processOrderId)),
    );

    return mergeApiResponses(apiResponses);
  }

  public async recheck(processOrderIds: string[]): Promise<ApiResponse<ActivityRuleViolation>> {
    const apiResponses = await Promise.all(
      processOrderIds.map((processOrderId) => this.recheckSingleProcessOrder(processOrderId)),
    );

    return mergeApiResponses(apiResponses);
  }

  public async dismiss(activityRuleViolationIds: string[]): Promise<void> {
    const apiResponses = await Promise.all(
      activityRuleViolationIds.map((activityRuleViolationId) =>
        this.dismissSingleActivityRuleViolation(activityRuleViolationId),
      ),
    );

    mergeApiResponses(apiResponses);
  }

  private async getActivityRuleViolationsSingleProcessOrder(
    processOrderId: string,
  ): Promise<ApiResponse<ActivityRuleViolation>> {
    const requestParameters: RuleCheckApiRulecheckActivityRuleViolationsRequest = {
      processOrderId,
    };

    return loadAllPages<RuleCheckApi, ActivityRuleViolation>(
      this.ruleCheckApi,
      'rulecheckActivityRuleViolations',
      requestParameters,
    );
  }

  private async recheckSingleProcessOrder(processOrderId: string): Promise<ApiResponse<ActivityRuleViolation>> {
    const requestParameters: RuleCheckApiRulecheckRecheckRequest = {
      rulecheckRecheckRequest: {
        processOrderId,
      },
    };

    const { data } = await this.ruleCheckApi.rulecheckRecheck(requestParameters);

    return data;
  }

  private async dismissSingleActivityRuleViolation(
    activityRuleViolationId: string,
  ): Promise<ApiResponse<ActivityRuleViolation>> {
    const { data } = await this.ruleCheckApi.rulecheckDismiss({
      activityRuleViolationId,
    });

    return data;
  }
}
