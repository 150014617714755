import { DataEntry, SyncableDataState } from '../types';

export default function initialState<Entry extends DataEntry>(): SyncableDataState<Entry> {
  return {
    data: {},
    errors: [],
    syncing: false,
    syncableObservers: [],
  };
}
