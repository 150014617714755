<template>
  <MaxWidthContainer class="no-content" size="xl">
    <img :src="imageSrc" alt="no-content" class="no-content__icon" />

    <h1 class="mt-6 mb-4">
      <span v-if="type === 'partners'">
        {{/* eslint-disable max-len*/}}
        {{
          $t(
            'Es hat noch kein Partner Felder bereitgestellt. Lade deine Partner ein, Felder in Farmdok zu kontrahieren.',
          )
        }}
        {{/* eslint-enable max-len*/}}
      </span>
      <span v-if="type === 'fields'">
        {{ $t('Es wurden noch keine Felder übermittelt. Lade deine Partner ein, Felder in Farmdok freizugeben.') }}
      </span>

      <span v-if="type === 'files'">
        {{ $t('Es wurden noch keine Dateien hochgeladen!') }}
      </span>

      <span v-if="type === 'search'">
        {{ $t('Keine Ergebnisse gefunden. Ändere den Suchbegriff.') }}
      </span>
    </h1>

    <p v-if="type === 'partners' || type === 'fields'">
      <span>
        {{/* eslint-disable max-len*/}}
        {{
          $t(
            'Sende deinem Partner einen Link, der ihn direkt zum Vertrag führt. Er kann dort mit wenigen Klicks Felder freigeben.',
          )
        }}
        {{/* eslint-enable max-len*/}}
      </span>
    </p>

    <p v-if="type === 'files'">
      <span>
        {{ $t('Importiere Dateien in den Formaten Shape und GeoJSON.') }}
      </span>
      <br />
      <span>
        {{/* eslint-disable max-len*/}}

        {{
          $t(
            'Für jede Datei wird ein Link generiert. GeoJSON-Links können im Webbrowser geöffnet werden. Max. 10 MB pro Datei.',
          )
        }}
        {{/* eslint-enable max-len*/}}
      </span>
    </p>
    <div class="mt-4">
      <ButtonLink v-if="type === 'partners' || type === 'fields'" @click="$emit('copyLinkToClipboard')">
        {{ $t('Link in die Zwischenablage kopieren') }}
      </ButtonLink>
      <ButtonLink v-if="type === 'search'" @click="$emit('clearAllFilters')">
        {{ $t('Suche zurücksetzen') }}
      </ButtonLink>
      <Button v-if="type === 'files'" leading-icon icon="cloud-upload" @click="$emit('uploadFiles')">
        {{ $t('Datei hochladen') }}
      </Button>
    </div>
    <div v-if="errorResponseData != null">
      <ErrorUserMessage :response-data="errorResponseData" />
      <div class="mt-2">
        <ButtonLink @click="$emit('refresh')">
          {{ $t('Neu laden') }}
        </ButtonLink>
      </div>
    </div>
  </MaxWidthContainer>
</template>

<script>
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';

const images = require.context('../assets/');

export default {
  name: 'NoContent',
  components: {
    Button,
    ButtonLink,
    MaxWidthContainer,
    ErrorUserMessage,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => ['partners', 'fields', 'files', 'search', 'loading'].includes(val),
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    errorResponseData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    imageSrc() {
      return images(`./no-content-${this.type}.svg`);
    },
  },
};
</script>

<style scoped>
.no-content {
  padding-top: 46px; /* If you change this, consider changing the --from-top css variable in TableFieldsContainer.vue */
  text-align: center;
}
.no-content__icon {
  width: 295px;
  height: 295px;
  object-fit: cover;
  background-color: var(--gray_100);
  border-radius: 50%;
}

.file-drop-label {
  margin-top: 220px;
  position: fixed;
}
</style>
