import Handsontable from 'handsontable';

import { TableDataBaseExpandable } from '../../../types';
import isTableDataBaseExpandable from './isTableDataBaseExpandable';

export default function getLastRowOfSubtable(
  rowIndex: number,
  instanceOrTableData: Handsontable | TableDataBaseExpandable[],
): number {
  if (isTableDataBaseExpandable(instanceOrTableData)) {
    return getLastRowOfSubtableTableData(rowIndex, instanceOrTableData);
  }

  return getLastRowOfSubtableInstance(rowIndex, instanceOrTableData);
}

function getLastRowOfSubtableTableData(rowIndex: number, tableData: TableDataBaseExpandable[]): number {
  if (isLastRowOfSubtableTableData(rowIndex, tableData)) return rowIndex;

  return getLastRowOfSubtableTableData(rowIndex + 1, tableData);
}

function getLastRowOfSubtableInstance(rowIndex: number, instance: Handsontable): number {
  if (isLastRowOfSubtableInstance(rowIndex, instance)) return rowIndex;

  return getLastRowOfSubtableInstance(rowIndex + 1, instance);
}

function isLastRowOfSubtableTableData(rowIndex: number, tableData: TableDataBaseExpandable[]): boolean {
  if (rowIndex === tableData.length - 1) return true;
  const nextRowId = tableData[rowIndex + 1].id;
  const currentRowId = tableData[rowIndex].id;

  return nextRowId !== currentRowId;
}

export function isLastRowOfSubtableInstance(rowIndex: number, instance: Handsontable) {
  if (rowIndex === instance.countRows() - 1) return true;
  const nextRowId = instance.getDataAtRowProp(rowIndex + 1, 'id');
  const currentRowId = instance.getDataAtRowProp(rowIndex, 'id');
  return nextRowId !== currentRowId;
}
