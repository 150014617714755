import TableColumnsService from '../../../services/TableColumnsService';
import { ColumnSettingsWithUserSettings } from '../../../types';
import { ColumnSettingsFlattenedSubtableWithUserSettings } from '../types';

export default function columns(
  tableColumns: ColumnSettingsWithUserSettings[],
): ColumnSettingsFlattenedSubtableWithUserSettings[] {
  let flattenedColumns: ColumnSettingsFlattenedSubtableWithUserSettings[] = [];
  tableColumns.forEach((col) => {
    if (col.type === 'subtable' && col.subtableColumns) {
      const tableColumnService = new TableColumnsService(col.subtableColumns);
      const subtableColumns = tableColumnService.getAvailableColumns();
      let columnHeaderApplied = false;
      const subtableColumnsFlattened = subtableColumns.map((subtableCol, index) => {
        const isFirstSubtableColumn = index === 0;
        const isLastSubtableColumn = index === subtableColumns.length - 1;

        let { header } = subtableCol;
        if (!columnHeaderApplied && !subtableCol.hidden) {
          // use header of subtable in first non hidden subtable column
          header = {
            ...col.header,
            ...subtableCol.header,
          };
          columnHeaderApplied = true;
        }

        if (typeof col.data !== 'string') throw new Error('Column data not of type string');

        const subtableColumnFlattened: ColumnSettingsFlattenedSubtableWithUserSettings = {
          ...subtableCol,
          header,
          isFirstSubtableColumn,
          isLastSubtableColumn,
          isSubtableColumn: true,
          isPartOfSubtable: col.data, // data of parent column
          // UserColumnSettings from parent column
          hidden: subtableCol.hidden !== undefined ? subtableCol.hidden : col.hidden,
          visualColumn: col.visualColumn + index * 0.1, // visualColumn is e.g. 1.1 to remain the visualColumn order (when sorted by visualColumn), will be fixed via fixVisualColumnIndex
          physicalColumn: -1, // will be fixed via fixPhysicalColumnIndex
        };

        return subtableColumnFlattened;
      });
      flattenedColumns.push(...subtableColumnsFlattened);
    } else {
      flattenedColumns.push(col);
    }
  });

  flattenedColumns = fixPhysicalColumnIndex(flattenedColumns);
  flattenedColumns = fixVisualColumnIndex(flattenedColumns);

  return flattenedColumns;
}

function fixPhysicalColumnIndex(flattenedColumns: ColumnSettingsFlattenedSubtableWithUserSettings[]) {
  return flattenedColumns.map((column: ColumnSettingsFlattenedSubtableWithUserSettings, index: number) => ({
    ...column,
    physicalColumn: index,
  }));
}

function fixVisualColumnIndex(flattenedColumns: ColumnSettingsFlattenedSubtableWithUserSettings[]) {
  const sorted = [...flattenedColumns].sort((a, b) => a.visualColumn - b.visualColumn);
  const fixed = sorted.map((col, visualColumn) => ({
    ...col,
    visualColumn,
  }));
  fixed.sort((a, b) => a.physicalColumn - b.physicalColumn);
  return fixed;
}
