import { REGION_GUIDS } from '@/shared/constants';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import {
  amaCode,
  cadastralCommunity,
  catchCropVariant,
  comment,
  companyId,
  crop,
  customer,
  expiryDate,
  fieldGroupNumber,
  fieldNumber,
  gwArea,
  kindOfUse,
  nameWithStatus,
  owner,
  plots,
  preCrop,
  rightOfUse,
  senderEmail,
  senderFarmName,
  senderFarmNumber,
  senderMessage,
  senderReference,
  sharedDate,
  sharedFieldInfo,
  size,
  status,
  variety,
} from './columns';

export const tableSharedFields = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...fieldGroupNumber,
    lockedVisibility: true,
    lockedPosition: true,
    sortOrder: 'asc',
  },
  {
    ...fieldNumber,
    hiddenPerDefault: true,
    lockedPosition: true,
  },
  {
    ...customer,
    lockedPosition: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length > 0,
  },
  {
    ...customer,
    hiddenPerDefault: true,
    lockedPosition: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length === 0,
  },
  {
    ...nameWithStatus,
    lockedVisibility: true,
    lockedPosition: true,
  },
  size,
  crop,
  variety,
  {
    ...sharedFieldInfo,
    requiredFeatures: (store, context) =>
      context.sharedFieldsByFieldId != null && Object.keys(context.sharedFieldsByFieldId).length > 0,
  },
  preCrop,
  {
    ...kindOfUse,
    hiddenPerDefault: true,
  },
  comment,
  {
    ...cadastralCommunity,
    hiddenPerDefault: true,
  },
  {
    ...plots,
    hiddenPerDefault: true,
  },
  {
    ...owner,
    hiddenPerDefault: true,
  },
  {
    ...rightOfUse,
    hiddenPerDefault: true,
  },
  {
    ...status,
    lockedVisibility: true,
  },
  senderReference,
  senderFarmNumber,
  senderMessage,
  {
    ...senderEmail,
    hiddenPerDefault: true,
  },
  sharedDate,
  {
    ...expiryDate,
    hiddenPerDefault: true,
  },
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export const tableSharedFieldsAt = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...fieldGroupNumber,
    lockedVisibility: true,
    lockedPosition: true,
    sortOrder: 'asc',
  },
  {
    ...fieldNumber,
    lockedPosition: true,
  },
  {
    ...customer,
    lockedPosition: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length > 0,
  },
  {
    ...customer,
    hiddenPerDefault: true,
    lockedPosition: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length === 0,
  },
  {
    ...nameWithStatus,
    lockedVisibility: true,
    lockedPosition: true,
  },
  size,
  crop,
  variety,
  {
    ...sharedFieldInfo,
    requiredFeatures: (store, context) =>
      context.sharedFieldsByFieldId != null && Object.keys(context.sharedFieldsByFieldId).length > 0,
  },
  preCrop,
  {
    ...catchCropVariant,
    hiddenPerDefault: true,
  },
  kindOfUse,
  comment,
  {
    ...amaCode,
    hiddenPerDefault: true,
  },
  {
    ...cadastralCommunity,
    hiddenPerDefault: true,
  },
  {
    ...plots,
    hiddenPerDefault: true,
  },
  {
    ...owner,
    hiddenPerDefault: true,
  },
  {
    ...rightOfUse,
    hiddenPerDefault: true,
  },
  {
    ...status,
    lockedVisibility: true,
  },
  {
    ...gwArea,
    lockedVisibility: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_GROUNDWATER_PROTECTION),
  },
  senderReference,
  senderFarmNumber,
  senderFarmName,
  senderMessage,
  {
    ...senderEmail,
    hiddenPerDefault: true,
  },
  sharedDate,
  {
    ...expiryDate,
    hiddenPerDefault: true,
  },
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export const tableSharedFieldsDe = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...fieldGroupNumber,
    lockedVisibility: true,
    lockedPosition: true,
    sortOrder: 'asc',
  },
  {
    ...fieldNumber,
    lockedPosition: true,
  },
  {
    ...customer,
    lockedPosition: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length > 0,
  },
  {
    ...customer,
    hiddenPerDefault: true,
    lockedPosition: true,
    requiredFeatures: (store) =>
      store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS) &&
      Object.values(store.state.customers.data).length === 0,
  },
  {
    ...nameWithStatus,
    lockedVisibility: true,
    lockedPosition: true,
  },
  size,
  crop,
  variety,
  {
    ...sharedFieldInfo,
    requiredFeatures: (store, context) =>
      context.sharedFieldsByFieldId != null && Object.keys(context.sharedFieldsByFieldId).length > 0,
  },
  preCrop,
  {
    ...kindOfUse,
    hiddenPerDefault: true,
  },
  comment,
  {
    ...cadastralCommunity,
    hiddenPerDefault: true,
  },
  {
    ...plots,
    hiddenPerDefault: true,
  },
  {
    ...owner,
    hiddenPerDefault: true,
  },
  {
    ...rightOfUse,
    hiddenPerDefault: true,
  },
  {
    ...status,
    lockedVisibility: true,
  },
  senderReference,
  senderFarmNumber,
  senderMessage,
  {
    ...senderEmail,
    hiddenPerDefault: true,
  },
  sharedDate,
  {
    ...expiryDate,
    hiddenPerDefault: true,
  },
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export default {
  international: tableSharedFields,
  [REGION_GUIDS.AT]: tableSharedFieldsAt,
  [REGION_GUIDS.DE]: tableSharedFieldsDe,
};
