import Handsontable from 'handsontable';

export default function prepareCellsMetadata(columnsForExport: Handsontable.ColumnSettings[], hot: Handsontable) {
  const columnWidths: number[] = [];
  const columnTitles: string[] = [];
  const columnDescriptions: string[] = [];

  columnsForExport.forEach((column) => {
    if (typeof column.data !== 'string') throw new Error('column.data is not a string');
    const visualCol = hot.propToCol(column.data);

    columnTitles.push(column.header?.title || '');
    columnDescriptions.push(column.header?.description || '');
    columnWidths.push(hot.getColWidth(visualCol) || 45);
  });

  return { columnWidths, columnTitles, columnDescriptions };
}
