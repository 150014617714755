import { AmountsAndUnits } from '@/activities/types';
import { Unit } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';
import roundToDecimals from '@/shared/modules/roundToDecimals';

import { calculateRelative, calculateTotal } from './calculateAmount';
import { findRelativeUnit, findTotalUnit, isRelativeUnit, isTotalUnit } from './findUnit';

export default function calculateAmountsAndUnits(
  amount: number | null | undefined,
  unitId: string | null | undefined,
  processedAreaInHa: number | null | undefined,
  units: Data<Unit>,
): AmountsAndUnits {
  if (!unitId) {
    return EMPTY_AMOUNTS_AND_UNITS;
  }

  const unit = units[unitId];
  if (!unit) return EMPTY_AMOUNTS_AND_UNITS;

  if (isTotalUnit(unit)) {
    return calculateAmountsAndUnitsFromTotalUnit(amount, unit, processedAreaInHa, units);
  }

  if (isRelativeUnit(unit)) {
    return calculateAmountsAndUnitsFromRelativeUnit(amount, unit, processedAreaInHa, units);
  }

  throw new Error(`Unit ${unit.id} is neither relative nor total unit. This shouldn't happen.`);
}

function calculateAmountsAndUnitsFromTotalUnit(
  amountTotal: number | null | undefined,
  unitTotal: Unit,
  processedAreaInHa: number | null | undefined,
  units: Data<Unit>,
): AmountsAndUnits {
  const unitRelative = findRelativeUnit(unitTotal, units);
  const amountRelative =
    typeof amountTotal === 'number' && typeof processedAreaInHa === 'number' && unitRelative
      ? calculateRelative(amountTotal, unitRelative, processedAreaInHa, units)
      : null;
  const amountRelativeRounded = typeof amountRelative === 'number' ? roundToDecimals(amountRelative, 2) : null;

  return {
    amountUnitRelative: {
      amount: amountRelativeRounded,
      unitId: unitRelative?.id ?? null,
      isFixed: false,
    },
    amountUnitTotal: {
      amount: amountTotal ?? null,
      unitId: unitTotal.id,
      isFixed: true,
    },
  };
}

function calculateAmountsAndUnitsFromRelativeUnit(
  amountRelative: number | null | undefined,
  unitRelative: Unit,
  processedAreaInHa: number | null | undefined,
  units: Data<Unit>,
): AmountsAndUnits {
  const unitTotal = findTotalUnit(unitRelative, units);
  const amountTotal =
    typeof amountRelative === 'number' && typeof processedAreaInHa === 'number' && unitTotal
      ? calculateTotal(amountRelative, unitRelative, processedAreaInHa, units)
      : null;
  const amountTotalRounded = typeof amountTotal === 'number' ? roundToDecimals(amountTotal, 2) : null;

  return {
    amountUnitRelative: {
      amount: amountRelative ?? null,
      unitId: unitRelative.id,
      isFixed: true,
    },
    amountUnitTotal: {
      amount: amountTotalRounded,
      unitId: unitTotal?.id ?? null,
      isFixed: false,
    },
  };
}

const EMPTY_AMOUNTS_AND_UNITS: AmountsAndUnits = {
  amountUnitRelative: {
    amount: null,
    unitId: null,
    isFixed: false,
  },
  amountUnitTotal: {
    amount: null,
    unitId: null,
    isFixed: false,
  },
};
