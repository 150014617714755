import debounce from 'lodash.debounce';

export default {
  data() {
    return {
      featureHighlightedFixedColumnsScrolled: false,
    };
  },
  mounted() {
    this.addHotHook(
      'afterScrollHorizontally',
      debounce(this.featureHighlightedFixedColumnsAfterScrollHorizontally, 100),
    );
  },
  methods: {
    featureHighlightedFixedColumnsAfterScrollHorizontally() {
      if (this.tableSettingsComputed.fixedColumnsLeft < 1) {
        return;
      }
      const featureHighlightedFixedColumnsScrolled = this.hot.container.querySelector('.wtHolder').scrollLeft > 10;
      if (featureHighlightedFixedColumnsScrolled === this.featureHighlightedFixedColumnsScrolled) {
        return;
      }
      this.featureHighlightedFixedColumnsScrolled = featureHighlightedFixedColumnsScrolled;
      const visibleCol = this.hot.toVisualColumn(this.tableSettingsComputed.fixedColumnsLeft - 1);
      const countVisibleRows = this.hot.countRows();
      for (let visualRow = 0; visualRow < countVisibleRows; visualRow += 1) {
        let { className } = this.hot.getCellMeta(visualRow, visibleCol);
        if (typeof className === 'string') {
          className = className.split(' ');
        } else if (!Array.isArray(className)) {
          className = [];
        }
        if (this.featureHighlightedFixedColumnsScrolled) {
          className.push('shadow-right');
        } else {
          className = className.filter((current) => current !== 'shadow-right');
        }
        this.hot.setCellMeta(visualRow, visibleCol, 'className', className.join(' '));
      }
      this.hotRender();
    },
  },
};
