import { Equipment, ImportActivityEquipment } from 'farmdok-rest-api';

import {
  DropdownMatch,
  TableDataImportActivityEquipment,
} from '@/activities/importActivities/types/TableDataImportActivity.types';
import { Data } from '@/shared/mixins/store/types';

export default function tableDataActivitiesImportEquipmentService() {
  function convertToActivityImportEquipmentTableData(
    importActivityEquipment: ImportActivityEquipment,
    stateEquipment: Data<Equipment>,
  ): TableDataImportActivityEquipment | undefined {
    if (!importActivityEquipment) return undefined;

    const { equipmentId, equipmentIdMatch, ...restOfImportActivityEquipment } = importActivityEquipment;

    if (!equipmentId && equipmentIdMatch && equipmentIdMatch.matchType === 'NoMatch') {
      return {
        ...restOfImportActivityEquipment,
        dropdownItem: {
          id: DropdownMatch.NO_MATCH,
          name: importActivityEquipment.equipmentIdMatch.originalValue ?? '',
        },
      };
    }
    return {
      ...restOfImportActivityEquipment,
      dropdownItem: {
        id: equipmentId,
        name: equipmentId ? stateEquipment[equipmentId].name : '',
      },
    };
  }

  function dummyEquipmentForAddEquipmentButton(): TableDataImportActivityEquipment {
    return {
      dropdownItem: 'HOT_DISPLAY_BUTTON_RENDERER',
    };
  }

  return {
    convertToActivityImportEquipmentTableData,
    dummyEquipmentForAddEquipmentButton,
  };
}
