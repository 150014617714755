<template>
  <span class="base-search-term">
    {{ preSearch }}<strong v-if="search">{{ search }}</strong
    >{{ postSearch }}
  </span>
</template>

<script>
export default {
  name: 'BaseSearchTerm',
  props: {
    haystack: {
      type: String,
      required: true,
    },
    needle: {
      type: String,
      default: null,
    },
  },
  computed: {
    indexOfMatch() {
      if (this.needle == null) {
        return -1;
      }
      return this.haystack.toLowerCase().indexOf(this.needle);
    },
    preSearch() {
      if (this.indexOfMatch < 0) {
        return this.haystack;
      }
      return this.haystack.substring(0, this.indexOfMatch);
    },
    search() {
      if (this.indexOfMatch < 0) {
        return null;
      }
      return this.haystack.substring(this.indexOfMatch, this.indexOfMatch + this.needle.length);
    },
    postSearch() {
      if (this.indexOfMatch < 0) {
        return null;
      }
      return this.haystack.substring(this.indexOfMatch + this.needle.length);
    },
  },
};
</script>
