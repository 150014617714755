/**
 * This function can be used to filter duplicate items in stream or array
 * https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
 * @deprecated replace by {@link /src/shared/modules/uniqueFilter.ts#isUnique} filter, because this filter is not applicable to non-primitive types. Besides the new filter offers higher versatility for comparing two values by providing a custom comparator if necessary.
 */
export default function isUnique<T, K extends string & keyof T>(value: T, index: number, array: T[], key?: K): boolean {
  if (key) {
    const subValues = array.map((t: T) => t[key]).filter(isUnique);
    return subValues.indexOf(value[key]) === index;
  }
  return array.indexOf(value) === index;
}
