
import { PropType, defineComponent } from 'vue';

import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';

type ValidationError = {
  msg: string;
};

/**
 * this component is a wrapper around FormFieldInput to display number values formatted with a formatter function
 * the v-model value is always a number
 */
export default defineComponent({
  name: 'FormFieldInputNumberFormatted',
  components: { FormFieldInput },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    value: {
      type: Number as PropType<number | null>,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Label rendered above or to the left, depending on the variant.
     *
     * If variant is 'no-label' the label can be omitted.
     */
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value: string) =>
        ['horizontal', 'vertical', 'vertical-lg', 'no-label', 'no-label-sm'].includes(value),
    },
    /**
     * If set to `true` or `false` the input will be highlighted with colors and icons, depending on the state.<br>
     * Default `null`.
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * If set to `true` the following props will be overwritten:
     *   - value with `null`
     *   - placeholder with `'(verschiedene)'`
     */
    various: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    formatter: {
      type: Function as PropType<(value: string | number | null) => string>,
      required: true,
    },
    parser: {
      type: Function as PropType<(value: string | null) => number | null>,
      required: true,
    },
    stateMessage: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      validationStateMessage: null as string | null,
      validationState: null as boolean | null,
    };
  },
  computed: {
    computedValue(): number | string | null {
      return this.formatter(this.value);
    },
    computedState(): boolean | null {
      return this.validationState ?? this.state;
    },
    computedStateMessage(): string | null {
      return this.validationStateMessage ?? this.stateMessage;
    },
  },
  methods: {
    onUpdate(newValue: string | null) {
      const formattedValue = this.formatter(newValue);
      const numberValue = this.parser(formattedValue);

      const error = this.validate(numberValue);

      if (error) {
        this.$emit('error', true);
        this.setError(error.msg);
      } else {
        this.$emit('error', false);
        this.setError(null);
      }

      this.$emit('update', numberValue);
    },
    validate(value: number | null): ValidationError | null {
      if (value === null) return null;

      if (this.min !== null && value < this.min) {
        return { msg: `Value can not be smaller than ${this.min}` };
      }

      if (this.max && value > this.max) {
        return { msg: `Value can not be greater than ${this.max}` };
      }

      return null;
    },
    setError(validationErrorMessage: string | null): void {
      if (validationErrorMessage) {
        this.setState(false);
        this.setStateMessage(validationErrorMessage);
      } else {
        this.setState(null);
        this.setStateMessage(null);
      }
    },
    setStateMessage(stateMessage: string | null) {
      this.validationStateMessage = stateMessage;
    },
    setState(state: boolean | null) {
      this.validationState = state;
    },
  },
});
