<template>
  <div>
    <div
      :id="`badge-${label}`"
      ref="badge"
      class="badge"
      :class="{ 'badge-active': isActive }"
      @click="$emit('onBadgeClick')"
    >
      <span class="label">{{ label }}</span>
    </div>

    <BTooltip :target="`badge-${label}`" placement="bottomright">{{ label }}</BTooltip>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.badge {
  min-width: 60px;
  max-width: 120px;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid var(--gray_300);
}

.badge-active {
  background-color: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding: 3px 5px;
}
</style>
