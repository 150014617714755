import { Customer, Field, Product } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

export function getCustomerNamePart(customerId: string | undefined, customers: Data<Customer>): string {
  if (!customerId) {
    return '';
  }
  const customer = customers[customerId];
  if (!customer) {
    return '';
  }
  if (customer.shortname) {
    return `${customer.shortname} - `;
  }
  return `${customer.name} - `;
}

export function getFieldNamePart(field: Field): string {
  if (field.fieldGroup.mfa) {
    return `${field.fieldGroup.mfa} - ${field.name}`;
  }
  return field.name;
}

export function getCropNamePart(cropId: string | undefined | null, crops: Data<Product>): string {
  if (!cropId) {
    return '';
  }
  const crop = crops[cropId];
  if (!crop) {
    return '';
  }
  return ` (${crop.name})`;
}
