import { Activity } from 'farmdok-rest-api';

import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import ActivityService from '@/activities/services/ActivityService';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';

import { CreateEditActivityState } from './types';

function initialState(): CreateEditActivityState {
  const activity: Activity = {
    ...ActivityService.createActivity(),
    timeStart: toUnixTimestamp(Date.now()),
    completed: true,
    products: [ActivityProductService.createActivityProduct()],
    equipment: [ActivityEquipmentService.createActivityEquipment()],
  };

  return {
    polygons: {},
    currentActivityProductId: null,
    activities: [activity],
    workflowKey: 'createActivity',
  };
}

export default initialState;
