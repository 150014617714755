import Handsontable from 'handsontable';
import numbro from 'numbro';

import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';

const { isNumeric } = Handsontable.helper;
const { getRenderer } = Handsontable.renderers;

/**
 * Numeric cell renderer
 *
 * @private
 * @renderer NumericRenderer
 * @param {Object} instance Handsontable instance
 * @param {Element} TD Table cell where to render
 * @param {Number} row
 * @param {Number} col
 * @param {String|Number} prop Row object property name
 * @param value Value to render (remember to escape unsafe HTML before inserting to DOM!)
 * @param {Object} cellProperties Cell properties (shared by cell renderer and editor)
 */
function numericRenderer(instance, TD, row, col, prop, value, cellProperties, ...args) {
  let newValue = value;

  if (isNumeric(newValue)) {
    const { numericFormat } = cellProperties;
    let cellCulture = null;
    if (numericFormat != null && typeof numericFormat.culture === 'string') {
      cellCulture = numericFormat.culture;
    }
    let cellFormatPattern = '0';
    if (numericFormat != null && typeof numericFormat.pattern === 'string') {
      cellFormatPattern = numericFormat.pattern;
    }
    let className = '';
    if (typeof cellProperties.className === 'string') {
      ({ className } = cellProperties);
    }
    const classArr = className.length ? className.split(' ') : [];

    if (cellCulture != null && !numbro.languages()[cellCulture]) {
      const shortTag = cellCulture.replace('-', '');
      const langData = numbro.allLanguages ? numbro.allLanguages[cellCulture] : numbro[shortTag];

      if (langData) {
        numbro.registerLanguage(langData);
      }
    }

    const originalCulture = numbro.language();
    if (cellCulture != null) {
      numbro.setLanguage(cellCulture);
    }

    newValue = numbro(newValue).format(cellFormatPattern);
    if (originalCulture != null) {
      numbro.setLanguage(originalCulture);
    }

    if (
      !classArr.includes('htLeft') &&
      !classArr.includes('htCenter') &&
      !classArr.includes('htRight') &&
      !classArr.includes('htJustify')
    ) {
      classArr.push('htRight');
    }

    if (!classArr.includes('htNumeric')) {
      classArr.push('htNumeric');
    }

    // eslint-disable-next-line no-param-reassign
    cellProperties.className = classArr.join(' ');
  }

  getRenderer('text')(instance, TD, row, col, prop, newValue, cellProperties, ...args);

  applyMergeRowsBorderStyles(TD, row, instance);
}

export default numericRenderer;
