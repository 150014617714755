import { DataEntry, SubscribableDataState } from '../types';

export default function initialState<Entry extends DataEntry>(): SubscribableDataState<Entry> {
  return {
    data: {},
    errors: [],
    fetching: false,
    loaded: null,
    observers: [],
    axiosSources: [],
  };
}
