import { Module } from 'vuex';

import productsModuleFactory from '@/products/store/productsModuleFactory';
import { ProductsModuleState } from '@/products/store/productsModuleFactory/types';
import { ProductCategoryType } from '@/shared/api/rest/models';
import { RootState } from '@/store/types';

const vuexModule: Module<ProductsModuleState, RootState> = productsModuleFactory(
  ProductCategoryType.SECONDARY_FERTILIZER,
);

export default vuexModule;
