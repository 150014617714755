import Handsontable from 'handsontable';

import { TableDataBaseExpandable } from '../../../types';
import isTableDataBaseExpandable from './isTableDataBaseExpandable';

export default function getFirstRowOfSubtable(
  rowIndex: number,
  instanceOrTableData: Handsontable | TableDataBaseExpandable[],
): number {
  if (isTableDataBaseExpandable(instanceOrTableData)) {
    return getFirstRowOfSubtableTableData(rowIndex, instanceOrTableData);
  }

  return getFirstRowOfSubtableInstance(rowIndex, instanceOrTableData);
}

function getFirstRowOfSubtableTableData(rowIndex: number, tableData: TableDataBaseExpandable[]): number {
  if (isFirstRowOfSubtableTableData(rowIndex, tableData)) return rowIndex;

  return getFirstRowOfSubtableTableData(rowIndex - 1, tableData);
}

function getFirstRowOfSubtableInstance(rowIndex: number, instance: Handsontable): number {
  if (isFirstRowOfSubtableInstance(rowIndex, instance)) return rowIndex;

  return getFirstRowOfSubtableInstance(rowIndex - 1, instance);
}

function isFirstRowOfSubtableTableData(rowIndex: number, tableData: TableDataBaseExpandable[]): boolean {
  if (rowIndex === 0) return true;
  const previousRowId = tableData[rowIndex - 1].id;
  const currentRowId = tableData[rowIndex].id;

  return previousRowId !== currentRowId;
}

function isFirstRowOfSubtableInstance(rowIndex: number, instance: Handsontable) {
  if (rowIndex === 0) return true;
  const previousRowId = instance.getDataAtRowProp(rowIndex - 1, 'id');
  const currentRowId = instance.getDataAtRowProp(rowIndex, 'id');
  return previousRowId !== currentRowId;
}
