<template>
  <BPopover ref="popover" show placement="bottom" custom-class="popover-field-group-archived shadow" :target="target">
    <h3 class="popover-field-group-archived__title">
      {{ $t('Archiviert') }}
    </h3>
    <div class="popover-field-group-archived__body">
      <FormFieldRadioGroup v-model="archived" class="m-0" variant="no-label" stacked @change="(value) => save(value)">
        <FormRadio class="text-primary" variant="bordered" :value="0" :disabled="fetching">
          <template #default>
            <span>{{ $t('Aktiv') }}</span>
          </template>
        </FormRadio>
        <FormRadio variant="bordered" :value="1" :disabled="fetching">
          <template #default>
            <span>{{ $t('Archiviert') }}</span>
          </template>
        </FormRadio>
      </FormFieldRadioGroup>
    </div>
  </BPopover>
</template>

<script>
import vClickOutside from 'v-click-outside';

import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';

const { bind, unbind } = vClickOutside.directive;

export default {
  name: 'PopoverFieldGroupArchived',
  components: { FormFieldRadioGroup, FormRadio },
  props: {
    target: {
      type: HTMLElement,
      required: true,
    },
    fieldGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      archived: this.fieldGroup.archived,
      popover: null,
      fetching: false,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.popover = this.$refs.popover.$el;
    if (this.popover != null) {
      bind(this.popover, { value: this.onClickOutside });
    }
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    if (this.popover != null) {
      unbind(this.popover);
    }
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (e.key === 'Escape') {
        this.$emit('close');
      }
    },
    onClickOutside() {
      this.$emit('close');
    },
    async save(archived) {
      if (this.error) {
        this.$emit('close');
        return;
      }
      this.fetching = true;
      // wait 100ms so that the BE request is not blocking the update of the UI (loading status)
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 100));
      const entry = {
        ...this.fieldGroup,
        archived: Number(archived),
      };
      await this.$store.dispatch('fieldGroups/updateAndSyncEntry', { entry });
      this.fetching = false;
      this.$emit('close');
    },
  },
};
</script>

<style>
.popover.popover-field-group-archived {
  min-width: 390px;
}

.popover-field-group-archived.bs-popover-bottom > .arrow::before,
.popover-field-group-archived.bs-popover-bottom > .arrow::after {
  border-bottom-color: var(--black);
}

.popover.popover-field-group-archived .popover-body {
  padding: 0;
}
</style>

<style scoped>
.popover-field-group-archived__title {
  padding: var(--spacer_2) var(--spacer_3);
  background: var(--black);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--white);
}

.popover-field-group-archived__body {
  padding: var(--spacer_2) var(--spacer_3);
}
</style>
