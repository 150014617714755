
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import assessmentColumns from '@/datura/assessments/handsontable/columns';
import ModalPageSettings from '@/shared/components/ModalPageSettings.vue';
import {
  ColumnForPageSettings,
  toColumnsForPageSettings,
  toUserColumnSettings,
} from '@/shared/components/modalPageSettingsConverter';
import TableColumnsService from '@/shared/handsontable/rework/services/TableColumnsService';

export default defineComponent({
  name: 'ModalPageSettingsContainer',
  components: { ModalPageSettings },
  data() {
    return {
      columnsForPageSettings: null,
    } as {
      columnsForPageSettings: ColumnForPageSettings[] | null;
    };
  },
  computed: {
    ...mapState('datura/assessments', ['hot']),
  },
  methods: {
    async show() {
      if (this.hot) {
        const availableColumns = new TableColumnsService(assessmentColumns).getAvailableColumns();
        this.columnsForPageSettings = toColumnsForPageSettings(availableColumns, this.hot);
      }
      // @ts-ignore
      const pageSettings: { columns: ColumnForPageSettings[] } | null = await this.$refs.modalPageSettings.show();
      if (pageSettings) {
        const userColumnSettings = toUserColumnSettings(pageSettings.columns);
        this.$store.commit('datura/assessments/setUserColumnSettings', userColumnSettings);
      }
    },
  },
});
