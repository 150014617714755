<template>
  <div :class="satelliteState">
    <BButton
      v-if="satellite.visible === true"
      class="expand-satellite text-dark"
      variant="link"
      @click="expandSatellite"
    >
      <FontAwesomeIcon v-if="satellite.expanded !== true" :icon="['fas', 'arrow-to-left']" />
      <span v-if="satellite.expanded === true">{{ $t('Verkleinern') }}</span>
      <span v-else-if="satellite.expandAvailable">{{ expandButtonLabel }}</span>
      <FontAwesomeIcon v-if="satellite.expanded === true" :icon="['fas', 'arrow-to-right']" />
    </BButton>
    <span class="flex-grow-1"></span>
    <BButton class="toggle-satellite text-dark" variant="link" @click="toggleSatellite">
      <FontAwesomeIcon v-if="satellite.visible !== true" :icon="['fas', 'arrow-to-left']" />
      <span v-if="satellite.visible !== true">{{ $t('Details anzeigen') }}</span>
      <span v-else-if="satellite.visible === true">{{ $t('Schließen') }}</span>
      <FontAwesomeIcon v-if="satellite.visible === true" :icon="['far', 'times']" />
    </BButton>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faArrowToLeft, faArrowToRight } from '@fortawesome/pro-solid-svg-icons';
import { mapGetters } from 'vuex';

library.add(faArrowToLeft, faArrowToRight, faBars, faTimes);

export default {
  name: 'TheSatelliteActions',
  methods: {
    toggleSatellite() {
      if (this.satellite.visible && this.satellite.expanded) {
        this.$store.commit('satellite/toggleExpand');
      }
      this.$store.commit('satellite/toggleVisibility');
    },
    expandSatellite() {
      this.$store.commit('satellite/toggleExpand');
    },
  },
  computed: {
    ...mapGetters(['satellite']),
    satelliteState() {
      return [{ 'satellite-actions': true }, { 'satellite-actions--full': this.satellite.expanded }];
    },
    expandButtonLabel() {
      if (this.satellite.expandButtonLabel == null) {
        return this.$t('Details anzeigen');
      }
      return this.satellite.expandButtonLabel;
    },
  },
};
</script>

<style lang="css" scoped>
.satellite-actions {
  display: flex;
  justify-content: space-between;
  width: 480px;
  padding: 0 20px;
}
.satellite-actions--full {
  width: 100%;
}
.toggle-satellite,
.expand-satellite {
  font-size: 12px;
  font-weight: 600;
}
</style>
