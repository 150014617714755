import { getRestResponseData } from '@/shared/modules/restApiHelpers';

export default {
  data() {
    return {
      /**
       * Gets set by setUserErrorMessageFromResponse and should be displayed to the user.
       *
       * @userErrorMessage {null|string|Array<string>}
       */
      userErrorMessage: null,
      /**
       * @userErrorMessage {null|string|Array<string>}
       */
      defaultUserErrorMessage: this.$t('Es ist ein unbekannter Fehler aufgetreten.'),
    };
  },
  methods: {
    /**
     * Extract first error message returned by REST backend: https://git.farmdok.com/farmdok/AgriDat/-/wikis/REST/Response-standard
     *
     * If the error messages contains one of these keys it will be translated:
     * https://git.farmdok.com/farmdok/jwt/-/blob/master/README.md#error-codes
     *
     * Response can also be an error thrown by axios. Then the response (and errorMessage) will be extracted from the error object.
     *
     * Then sets userErrorMessage.
     *
     * @param {object} error
     * @returns void
     */
    setUserErrorMessageFromResponse(error) {
      const responseData = getRestResponseData(error, { errorUserMessage: [this.defaultUserErrorMessage] });
      if (responseData.status === 'success') {
        this.userErrorMessage = null;
        return;
      }
      this.userErrorMessage = this.translateErrorCode(responseData.errorCode);
      if (this.userErrorMessage == null) {
        this.userErrorMessage = responseData.errorUserMessage;
      }
    },
    translateErrorCode(errorCode) {
      if (errorCode === 'invalidLoginRequestInvalidEmail') {
        return this.$t('Die E-Mail-Adresse ist ungültig.');
      }
      if (errorCode === 'invalidLoginRequestInvalidCredentials') {
        return [
          this.$t('Benutzername oder Passwort ungültig.'),
          this.$t(
            'Bitte achte darauf, die gleiche E-Mail-Adresse wie in der FARMDOK App am Smartphone oder Tablet zu verwenden. Du findest die E-Mail-Adresse in der FARMDOK App im Menüpunkt "Mehr".',
          ),
        ];
      }
      return null;
    },
  },
};
