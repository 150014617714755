import { AxiosRequestConfig } from 'axios';
import { Pest, PestsApi, PestsApiPestListRequest } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

async function fetchAll(
  context: ActionContext<SubscribableDataState<Pest>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Pest>> {
  const regionIds: string[] = context.rootGetters['auth/currentCompaniesRegionIds'];

  const { pestsApi } = Vue.prototype.$api as Api;

  const apiResponses = await Promise.all(
    regionIds.map((regionId) => {
      const requestParameters: PestsApiPestListRequest = { regionId };
      return loadAllPages<PestsApi, Pest>(pestsApi, 'pestList', requestParameters, axiosOptions);
    }),
  );

  const apiResponse = mergeApiResponses(apiResponses);

  return apiResponse;
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<Pest>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Pest>> {
  throw new Error('API NOT IMPLEMENTED');
}

const subscribableStore = subscribableData(fetchAll, fetchByIds, [availableFeatures.FEATURE_PESTS]);

export default subscribableStore;
