<template>
  <ThePageSkeleton>
    <template #contentbar>
      <TheContentbar>
        <template #left>
          <ImgPartnerLogo
            v-if="contract.imagePartnerLogo != null"
            :src="contract.imagePartnerLogo"
            :width="100"
            :height="55"
          />
        </template>
        <template #right>
          <SearchBar class="mr-1" :searchFilter.sync="filterString" />
          <ButtonWithArrow
            variant="lightest"
            type="left"
            class="mr-1"
            @click="$router.push({ name: 'digital-contracting/my-contracts' })"
          >
            {{ $t('Zurück') }}
          </ButtonWithArrow>
          <BButton
            v-if="!isExpired"
            variant="primary"
            class="view-contract__btn-resend"
            :disabled="currentCompaniesHaveFeature.readonly"
            @click="resend"
          >
            {{ $t('Erneut senden') }}
          </BButton>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <FetchingError v-if="fetchingError" @reload="loadData" />
      <TableViewContractContainer
        :table-data="tableData"
        v-if="!fetchingError && !noContent"
        :filter-string.sync="filterString"
        :visible-rows.sync="visibleRows"
      />
      <div v-if="noContent" class="view-contract__no_content">
        <p>
          {{ $t('Die Flächen wurden nicht über FARMDOK an {partner} gemeldet.', contract) }}
        </p>
        <p v-if="isExpired">
          {{ $t('Du kannst aber Erntedaten importieren, sobald sie bei {partner} verfügbar sind.', contract) }}
        </p>
        <BButton
          v-if="isExpired"
          variant="outline-primary"
          :to="{ name: 'digital-contracting/import-contract', params: contract }"
        >
          {{ $t('Erntedaten laden') }}
        </BButton>
      </div>
    </template>
  </ThePageSkeleton>
</template>

<script>
import axios from 'axios';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import ButtonWithArrow from '@/shared/components/buttons/ButtonWithArrow.vue';
import FetchingError from '@/shared/handsontable/components/FetchingError.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import ImgPartnerLogo from './components/ImgPartnerLogo.vue';
import TableViewContractContainer from './containers/TableViewContractContainer.vue';

export default {
  name: 'PageViewContract',
  components: {
    TableViewContractContainer,
    ThePageSkeleton,
    FetchingError,
    SearchBar,
    ButtonWithArrow,
    TheContentbar,
    ImgPartnerLogo,
  },
  data() {
    return {
      fetchingError: false,
      noContent: false,
      tableData: {},
      filterString: '',
      visibleRows: [],
    };
  },
  computed: {
    contract() {
      let contract = {};
      Object.values(this.$store.state.digitalContracting.data).some((current) => {
        if (Number(current.contractCompanyId) === Number(this.$route.params.contractCompanyId)) {
          contract = current;
          return true;
        }
        return false;
      });
      return contract;
    },
    contractCompanyId() {
      return this.$route.params.contractCompanyId;
    },
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_DIGITAL_CONTRACTING);
    },
    isExpired() {
      return this.contract.periodEnd * 1000 < new Date();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.fetchingError = false;
      try {
        const { data } = await axios.get(`/admin/digitalContracting/getContractCompanyData/${this.contractCompanyId}`);
        if (data == null || !Array.isArray(data.contractFields) || data.contractFields.length === 0) {
          this.noContent = true;
        } else {
          this.tableData = data.contractFields.reduce(
            (tableData, field) => ({
              ...tableData,
              [field.id]: field,
            }),
            {},
          );
        }
      } catch (e) {
        console.error('unable to load data contract data', e);
        this.fetchingError = true;
      }
    },
    resend() {
      this.$store.commit('digitalContracting/setContracting', this.contract);
      this.$router.push({ name: 'digital-contracting/contracting-resend', params: this.contract });
    },
  },
};
</script>

<style scoped>
.view-contract__btn-resend {
  padding-right: 2em;
  padding-left: 2em;
}

.view-contract__no_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(40 * var(--vh, 1vh));
}
</style>
