import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';

import { DataEntry, RemovableDataState } from '../types';

export default function initialState<Entry extends DataEntry>(): RemovableDataState<Entry> {
  return {
    ...initialStateSubscribable(),
    removing: false,
    removableObservers: [],
  };
}
