<template>
  <div
    class="table-context-menu"
    :style="{
      top,
      left,
    }"
  >
    <BDropdown ref="dropdown" variant="lightest" class="show" menu-class="shadow" :boundary="boundary">
      <BDropdownItem
        v-if="!hideInsert"
        :data-namespace="`ButtonInsertEntry.${componentNamespace}`"
        @click="$emit('insert')"
      >
        <FontAwesomeIcon icon="plus-circle" class="mr-2" />
        {{ $t('Einfügen (oberhalb)') }}
      </BDropdownItem>
      <BDropdownItem v-if="!hideEdit" :data-namespace="`ButtonEditEntry.${componentNamespace}`" @click="$emit('edit')">
        <FontAwesomeIcon icon="pencil-alt" class="mr-2" />
        {{ $t('Bearbeiten') }}
      </BDropdownItem>
      <BDropdownItem :data-namespace="`ButtonRemoveEntry.${componentNamespace}`" @click="$emit('remove')">
        <FontAwesomeIcon icon="trash-alt" class="mr-2" />
        {{ $t('Löschen') }}
      </BDropdownItem>
      <BDropdownDivider v-if="$slots.default != null" />
      <slot />
    </BDropdown>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt, faPlusCircle, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';

import componentNamespace from '../../mixins/componentNamespace';

library.add(faPlusCircle, faPencilAlt, faTrashAlt);

export default {
  name: 'TableContextMenu',
  mixins: [componentNamespace],
  props: {
    boundary: {
      type: [HTMLElement, String],
      default: () => 'window',
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideInsert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      topBase: 0,
      leftBase: 0,
    };
  },
  computed: {
    top() {
      if (this.topBase <= 0 || typeof this.topBase !== 'number') {
        return 0;
      }
      return `${this.topBase - 33}px`;
    },
    left() {
      if (this.leftBase <= 0 || typeof this.leftBase !== 'number') {
        return 0;
      }
      return `${this.leftBase}px`;
    },
  },
  methods: {
    show({ top, left }) {
      this.topBase = top;
      this.leftBase = left;
      this.$refs.dropdown.show();
    },
  },
};
</script>

<style scoped lang="scss">
.table-context-menu {
  position: fixed;
  z-index: 1000;
}

::v-deep .dropdown-toggle {
  width: 1px;
  padding: 0;
  pointer-events: none;
  opacity: 0;
}
</style>
