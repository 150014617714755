import { Module } from 'vuex';

import { initialState } from '@/products/store/productsModuleFactory';
import { MineralFertilizer } from '@/shared/api/rest/models';
import { RootState } from '@/store/types';

import productsModuleStore from './common';
import mutations from './mutations';
import { MineralFertilizersState } from './types';

const vuexModule: Module<MineralFertilizersState, RootState> = {
  namespaced: true,
  state: initialState<MineralFertilizer>(),
  mutations,
  actions: { ...productsModuleStore.actions },
  getters: { ...productsModuleStore.getters },
};

export default vuexModule;
