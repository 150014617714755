import axios, { AxiosRequestConfig } from 'axios';

import { BASE_PATH } from '../common';
import { ActivityType, ApiRequestOptions, ApiResponse, Filter } from '../models';
import { apiOptionsToSearchParams, dataToBase64, urlSearchParamsToUnencodedString } from '../requestUtils';

export async function fetchActivityTypes<Resolve = undefined, ResolveInSeparateList = undefined>(
  companyIds: string[],
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
) {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  const filter: Filter = [
    'AND',
    ['companyId', 'IN', companyIds],
    ['rough.category', '=', 'user_selectable'],
    ['hidden', 'IS', null],
  ];
  searchParams.append('filter', dataToBase64(filter));

  const url = `${BASE_PATH}/activityTypes?${urlSearchParamsToUnencodedString(searchParams)}`;

  return axios.get<ApiResponse<ActivityType[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}

export async function fetchSharedActivityTypes<Resolve = undefined, ResolveInSeparateList = undefined>(
  fieldSharingCompanyId: string,
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
) {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  const filter: Filter = ['AND', ['rough.category', '=', 'user_selectable'], ['hidden', 'IS', null]];
  searchParams.append('filter', dataToBase64(filter));

  searchParams.append('permissionMode', 'fieldSharing');
  searchParams.append('fieldSharingCompanyId', fieldSharingCompanyId);

  const url = `${BASE_PATH}/activityTypes?${urlSearchParamsToUnencodedString(searchParams)}`;

  return axios.get<ApiResponse<ActivityType[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}
