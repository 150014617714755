import Handsontable from 'handsontable';

/**
 * disable sorting for this column
 */
function compareFunctionFactory(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sortOrder: Handsontable.columnSorting.SortOrderType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  columnMeta: Handsontable.GridSettings,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function comparator(value: number, nextValue: number): 0 | 1 | -1 {
    return 0;
  };
}

export default {
  indicator: false,
  headerAction: false,
  compareFunctionFactory,
};
