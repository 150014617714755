<template>
  <div class="weather-info__period">
    <span class="text-dark">{{ $t('Zeitraum:') }}</span>
    <button
      v-for="(period, index) in periods"
      :key="`period-${index}`"
      class="border-0"
      :class="value === period.period ? 'text-secondary' : 'text-black'"
      @click="$emit('input', period.period)"
    >
      {{ period.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'WeatherPeriodSwitch',
  props: {
    value: {
      type: String,
      default: 'Daily',
    },
    periods: {
      type: Array,
      default: () => [
        {
          period: 'Daily',
          label: this.$t('Tage'),
        },
        {
          period: 'Monthly',
          label: this.$t('Monate'),
        },
      ],
    },
  },
  emits: ['input'],
};
</script>

<style scoped>
.weather-info__period {
  position: relative;
  z-index: 1;
  font-size: 12px;
}

.weather-info__period > span,
.weather-info__period > button {
  padding: 0 5px;
}
</style>
