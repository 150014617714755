
import { PropType, defineComponent } from 'vue';

import BaseFormField from '@/shared/components/form/BaseFormField.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import FormFieldTextArea from '@/shared/components/form/FormFieldTextArea.vue';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

export default defineComponent({
  name: 'NoteWorkInstructionSection',
  components: {
    BaseFormField,
    FormFieldSet,
    FormFieldTextArea,
  },
  data(): {
    text: string;
  } {
    return {
      text: this.notes.filter(notNullOrUndefined).join(', '),
    };
  },
  model: {
    prop: 'text',
    event: 'update',
  },
  props: {
    headline: {
      type: String as PropType<string>,
      default: '',
    },
    notes: {
      type: Array as PropType<Array<string>>,
      required: true,
      default: () => [],
    },
    predefinedNotes: {
      type: Array as PropType<Array<string>>,
    },
  },
  computed: {
    headlineAdditionalInfo(): string {
      if (this.notes && this.notes.length > 1 && !this.isEqual(this.notes)) {
        return `${this.headline} (${this.$t('verschiedene')})`;
      }
      return this.headline;
    },
  },
  methods: {
    update(value: string) {
      this.text = value;
      this.$emit('update', value);
    },
    isEqual(array: (string | null)[]): boolean {
      if (array.every((val, i, arr) => val === arr[0])) {
        return true;
      }
      return false;
    },
    prependNote(note: string): void {
      if (this.text.includes(note)) {
        return;
      }

      this.text += `${note}; ${this.text}`;
      this.$emit('update', this.text);
    },
    truncateText(text: string): string {
      return text.length > 70 ? `${text.slice(0, 70)}...` : text;
    },
  },
});
