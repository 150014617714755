export default function polygonToGeoJson(polygon: google.maps.Polyline | google.maps.Polygon): any {
  const path = polygon
    .getPath()
    .getArray()
    .map((point) => [point.lng(), point.lat()]);

  if (polygon instanceof google.maps.Polygon) {
    if (path[path.length - 1][0] !== path[0][0] || path[path.length - 1][1] !== path[0][1]) {
      path.push([...path[0]]);
    }
    return {
      coordinates: [[...path]],
      type: 'Polygon',
    };
  }

  return {
    coordinates: [...path],
    type: 'LineString',
  };
}
