
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { defineComponent } from 'vue';

import componentNamespace from '../mixins/componentNamespace';
import Button from './buttons/Button.vue';

library.add(faTimes);

/**
 * Use this component for all modals in FARMDOK WebClient (never use BModal directly).<br>
 * For examples take a look at [Figma]{@link https://www.figma.com/file/p4TEqNSpxiFc9gquNdOsCp/2.-Components---FDK?node-id=2%3A77}.
 *
 * @category Shared
 * @subcategory Templates
 * @component
 */
export default defineComponent({
  name: 'ModalWrapper',
  mixins: [componentNamespace],
  components: { Button },
  model: {
    prop: 'visible',
    event: 'change',
  },
  emits: ['change', 'hide', 'cancel', 'ok'],
  props: {
    /**
     * Delegated directly to BModal body-class.<br>
     * CSS class (or classes) to apply to the '.modal-body' wrapper element.
     */
    bodyClass: {
      type: [Array, Object, String],
      default: null,
    },
    /**
     * Dynamically sets the visibility of the modal. Is mapped to v-model.<br>
     * Alternatively use modal.show() and modal.hide().
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     *  <table style="width:30%;">
     *  <tr><td>'md'</td><td>500 px</td></tr>
     *  <tr><td>'lg'</td><td>800 px</td></tr>
     *  <tr><td>'xl'</td><td>1140 px</td></tr>
     *  <tr><td>'max'</td><td>90% vw/vh</td></tr>
     * </table>
     */
    size: {
      type: String,
      default: 'md',
      validator: (value: string) => ['md', 'lg', 'xl', 'max'].indexOf(value) !== -1,
    },
    /**
     * If the slot #map-container contains content map-full defines if it takes 50% or 100% of the modal space.
     */
    mapFull: {
      type: Boolean,
      default: false,
    },
    /**
     * Modal header title.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Okay button text.
     */
    okTitle: {
      type: String,
      default(): string {
        return this.$t('OK') ?? 'OK';
      },
    },
    /**
     * Okay button variant.
     */
    okVariant: {
      type: String,
      default: 'primary',
    },
    /**
     * Cancel button text.
     */
    cancelTitle: {
      type: String,
      default(): string {
        return this.$t('Abbrechen') ?? 'Abbrechen';
      },
    },
    /**
     * Content bottom and footer (buttons area) will have lightest bg color.
     */
    bottomBgGray: {
      type: Boolean,
      default: false,
    },
    /**
     * Only render the 'OK' button.
     */
    noCancelButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Only render the 'Cancel' button.
     */
    noOkButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Set a fixed height (in px) for the inner content. This sets the overflow to auto (and therefore make it scrollable)
     */
    innerContentHeight: {
      type: Number,
      default: null,
    },
    /**
     * Per default the button container (footer) takes the full width of the modal. If set to 'content' the buttons will only
     * be directly below the content container. Relevant if the sidebar of map containers are used.
     */
    footerVariant: {
      type: String,
      default: 'full',
      validator: (value: string) => ['full', 'content', 'none'].includes(value),
    },
    static: {
      type: Boolean,
      default: false,
    },
    /**
     * Hide the modal custom header.
     */
    hideCustomHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resolves: [],
      hiddenEventTriggered: false,
    };
  },
  computed: {
    bodyClassComputed(): any {
      if (this.bodyClass == null) {
        return 'modal-wrapper';
      }
      if (typeof this.bodyClass === 'string') {
        // @ts-ignore
        return ['modal-wrapper', this.bodyClass];
      }
      if (Array.isArray(this.bodyClass)) {
        // @ts-ignore
        return ['modal-wrapper', ...this.bodyClass];
      }
      return {
        // @ts-ignore
        ...this.bodyClass,
        'modal-wrapper': true,
      };
    },
    staticComputed(): any {
      // @ts-ignore
      return this.static;
    },
  },
  mounted() {
    if (this.visible) {
      this.onShown();
    }
    this.preventModalsFromStealingFocus();
  },
  beforeDestroy() {
    if (this.visible) {
      this.onHidden();
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.onShown();
      }
      if (!newVal && oldVal) {
        this.onHidden();
      }
    },
  },
  methods: {
    /**
     * Calls BModal.show(), and therefore also emits 'change' event to update visible status.
     * Returns a promise that is resolved when the user clicks OK or closes the overlay
     * (using close button, ESC, cancel button or this.hide()).
     * Resolve returns `true` on OK or `false` otherwise.
     *
     * @returns {Promise}
     */
    async show() {
      return new Promise((resolve) => {
        // @ts-ignore
        this.resolves = [...this.resolves, resolve];
        // @ts-ignore
        this.$refs.modal.show();
        this.onShown();
      });
    },
    /**
     * Hides the modal by calling BModal.hide(), and therefore also emits 'change' event to update visible status.
     * Also resolves the promises returned by this.show().
     */
    hide() {
      this.cancel();
    },
    // region - private methods
    change(isVisible: boolean) {
      /**
       * New modal visibility state. Used to update the v-model.
       *
       * @event ModalWrapper#change
       * @type {Boolean} isVisible
       */
      this.$emit('change', isVisible);
    },
    ok() {
      /**
       * Fired when the user clicks the 'OK' button, before the modal is removed/hidden.
       *
       * @event ModalWrapper#ok
       */
      this.$emit('ok');
      // @ts-ignore
      this.$refs.modal.hide();
      // @ts-ignore
      this.resolves.forEach((resolve) => resolve(true));
      this.resolves = [];
      this.onHidden();
    },
    cancel() {
      /**
       * Fired when the user clicks the 'cancel' button, before the modal is removed/hidden.
       *
       * @event ModalWrapper#cancel
       */
      this.$emit('cancel');
      // @ts-ignore
      this.$refs.modal.hide();
      // @ts-ignore
      this.resolves.forEach((resolve) => resolve(false));
      this.resolves = [];
      this.onHidden();
    },
    onHide(event: any) {
      if (event.trigger !== 'backdrop') {
        return;
      }
      this.$nextTick(() => {
        // @ts-ignore
        this.resolves.forEach((resolve) => resolve(false));
        this.resolves = [];
      });
    },
    onShown() {
      /**
       *
       * @event root#modal-wrapper--shown
       * @type {String}
       */
      this.$root.$emit('modal-wrapper--shown', this.componentNamespace);
      this.hiddenEventTriggered = false;
    },
    onHidden() {
      if (this.hiddenEventTriggered) {
        return;
      }
      /**
       *
       * @event root#modal-wrapper--hidden
       * @type {String}
       */
      this.$root.$emit('modal-wrapper--hidden', this.componentNamespace);
      this.hiddenEventTriggered = true;
    },
    /**
     * Author's note on accessibility: this will compromise screen readers when enabled, because the modal itself becomes focusable, but not readable.
     * Preferably, we should not use custom form controls, especially if they use popper.js. There's a whole literature about this https://bootstrap-vue.org/docs/components/modal#keyboard-navigation.
     * Instead, using Bootstrap's controls from bootstrap-vue is waaay safer.
     * On top of this, this is a very hacky solution, that works by scraping the DOM template, and is therefore prone to break in the future, if bootstrap's
     * implementation changes too.
     */
    preventModalsFromStealingFocus() {
      const modalContents = document.querySelectorAll('.modal-content');
      modalContents.forEach((div) => div.removeAttribute('tabindex'));
    },
    // endregion
  },
});
