/**
 * Tries to convert a string into a number. If the string has '-' or '/' in it it gets split by it and only the first part is converted.
 * If conversion is not possible the original value is returned
 *
 * @param value
 * @returns {string|number|*}
 */
export const stringToComparableNumber = (value) => {
  if (typeof value !== 'string') {
    return value;
  }
  const parts = value.split(/[-/]/g).map((currValue) => currValue.trim());
  if (parts.length < 1 || Number.isNaN(Number(parts[0]))) {
    return value;
  }
  return Number(parts[0]);
};

/**
 * Returns true if the value is undefined, null or an empty string.
 *
 * @param value
 * @return {boolean}
 */
export const empty = (value) => value == null || (typeof value === 'string' && value.length === 0);

/**
 * Try to convert values to numbers and compare them.
 * Return value aligns with Array.prototype.sort() specification.
 * If emptyLast is true empty values will always be moved to the back, no matter the direction.
 *
 * @param value
 * @param nextValue
 * @param direction
 * @param emptyLast
 * @returns {number}
 */
export const comparatorAlphaNumeric = (value, nextValue, direction = 1, emptyLast = false) => {
  if (empty(value) && empty(nextValue)) {
    return 0;
  }
  if (empty(value) && emptyLast) {
    return 1;
  }
  if (empty(nextValue) && emptyLast) {
    return -1;
  }

  if (typeof value === 'string' && typeof nextValue === 'string') {
    return direction * value.localeCompare(nextValue, undefined, { numeric: true });
  }
  if (typeof stringToComparableNumber(value) === 'number' && typeof stringToComparableNumber(nextValue) === 'number') {
    return direction * (stringToComparableNumber(value) - stringToComparableNumber(nextValue));
  }
  return 0;
};

/**
 * Call comparatorAlphaNumeric with emptyLast set to `true`.
 *
 * @param value
 * @param nextValue
 * @param direction
 * @returns {number}
 */
export const comparatorAlphaNumericEmptyLast = (value, nextValue, direction = 1) =>
  comparatorAlphaNumeric(value, nextValue, direction, true);
