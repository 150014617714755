<template>
  <ThePageSkeleton>
    <template #contentbar>
      <TheContentbar>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #right="{ contentbarLarge }">
          <Button color="lightest" @click="onBack">
            {{ $t('Abbrechen') }}
          </Button>
          <Button :icon="['far', 'arrow-right']" :disabled="!uploadEnabled" :fetching="fetching" @click="onUpload">
            {{ $t('Upload') }}
          </Button>
        </template>
      </TheContentbar>
    </template>
    <template #content
      ><FilesUpload
        ref="filesUpload"
        @uploadSuccess="onUploadSuccess"
        @uploadEnabledChanged="uploadEnabledChanged"
        :initialFiles="initialFiles"
    /></template>
  </ThePageSkeleton>
</template>

<script>
import FilesUpload from '@/digital-contracting/managed-contracts/components/filesUpload/FilesUpload.vue';
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import Button from '@/shared/components/buttons/Button.vue';

export default {
  name: 'PageManagedContractFilesUpload',
  components: {
    ThePageSkeleton,
    TheContentbar,
    Button,
    FilesUpload,
  },
  props: {
    initialFiles: FileList,
  },
  data() {
    return {
      uploadEnabled: false,
      fetching: false,
    };
  },
  methods: {
    onBack() {
      this.$router.push({
        name: 'digital-contracting/managed-contracts/files',
        params: { contractId: this.$route.params.contractId },
      });
    },
    onUpload() {
      this.$refs.filesUpload.onSubmit();
    },
    onUploadSuccess() {
      this.$router.push({
        name: 'digital-contracting/managed-contracts/files',
        params: { contractId: this.$route.params.contractId },
      });
    },
    uploadEnabledChanged(enabled) {
      this.uploadEnabled = enabled;
    },
  },
};
</script>
