import { AxiosRequestConfig } from 'axios';
import { StoragePlace, StoragePlacesApi, StoragePlacesApiStoragePlaceListRequest } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<StoragePlace>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<StoragePlace>> {
  const { storagePlacesApi } = Vue.prototype.$api as Api;

  const requestParameters: StoragePlacesApiStoragePlaceListRequest = {
    companyId: context.rootGetters['auth/currentCompanyIds'][0],
  };
  const apiResponse = loadAllPages<StoragePlacesApi, StoragePlace>(
    storagePlacesApi,
    'storagePlaceList',
    requestParameters,
    axiosOptions,
  );

  return apiResponse;
}

async function fetchByIds(
  ids: string[],
  context: ActionContext<SubscribableDataState<StoragePlace>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<StoragePlace>> {
  const { storagePlacesApi } = Vue.prototype.$api as Api;
  const { data } = await storagePlacesApi.storagePlaceObjects({ objectIds: ids.join(',') }, axiosOptions);

  return data as ApiResponse<StoragePlace>;
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds, [availableFeatures.FEATURE_STOCK_MOVEMENT]);
