import { VisualizationMode } from '../types';

export enum MapType {
  TRUE_COLOR_IMAGE = 'TCI',
  NDVI = 'NDVI',
  ZONE_MAP = 'ZONE_MAP',
}

export default function toMapType(visualizationMode: VisualizationMode): MapType {
  switch (visualizationMode) {
    case VisualizationMode.SATELLITE_IMAGE:
      return MapType.TRUE_COLOR_IMAGE;
    case VisualizationMode.VEGETATION:
      return MapType.NDVI;
    case VisualizationMode.ZONES:
      return MapType.ZONE_MAP;
    default:
      throw new Error(`Cannot convert visualization mode to mapType: ${visualizationMode}`);
  }
}
