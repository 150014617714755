<template>
  <ThePageSkeleton
    :page-feature-title="$router.currentRoute.meta.label"
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <template #right="{ contentbarLarge }">
          <SearchBar class="ml-4" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <CompanySwitch class="ml-4" :slim="!contentbarLarge" />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <div class="managed-contracts__card-container">
        <p v-if="contractsComputed.length === 0">
          {{ $t('Keine Kontrakte vorhanden.') }}
        </p>
        <CardContract
          v-for="(contract, index) in contractsComputed"
          :key="`card-${index}`"
          :contract="contract"
          managed
          @view="viewContract(contract)"
        />
      </div>
    </template>
  </ThePageSkeleton>
</template>

<script>
import { mapGetters } from 'vuex';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import CardContract from '../components/CardContract.vue';
import filterContracts from '../mixins/filterContracts';

export default {
  name: 'PageManagedContracts',
  components: {
    ThePageSkeleton,
    TheContentbar,
    CompanySwitch,
    SearchBar,
    CardContract,
  },
  mixins: [filterContracts],

  data() {
    return {
      storeUnsubscribe: null,
    };
  },

  computed: {
    ...mapGetters({
      contracts: 'managedContracts/contracts/data',
    }),
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_MANAGED_CONTRACTS);
    },
    contractsComputed() {
      if (this.fetching) {
        return [{ fetching: true }, { fetching: true }, { fetching: true }];
      }
      const contracts = Object.values(this.contracts);
      return this.filterContracts(contracts);
    },
  },
  created() {
    this.$store.dispatch('managedContracts/contracts/loadAll');
    this.$store.dispatch('managedContracts/contracts/subscribe');
    this.storeUnsubscribe = this.$store.subscribe(({ type }) => {
      if (type === 'afterResetData') {
        this.$store.dispatch('managedContracts/contracts/subscribe');
      }
    });
  },
  beforeDestroy() {
    this.storeUnsubscribe();
  },

  methods: {
    viewContract(contract) {
      this.$router.push(`managed-contracts/${contract.id}`);
    },
  },
};
</script>

<style scoped>
.managed-contracts__card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  height: auto;
  max-height: 100%;
  padding: 12px;
  overflow-y: auto;
}
</style>
