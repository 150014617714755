<template>
  <h3 class="header-dark-gray bg-dark text-white" :style="{ marginLeft }">
    <slot />
  </h3>
</template>

<script>
export default {
  name: 'HeaderDarkGray',
  props: {
    variant: {
      type: String,
      default: 'none',
    },
  },
  computed: {
    marginLeft() {
      if (this.variant === 'margin-left-negative-30') {
        return '-30px';
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.header-dark-gray {
  padding: 7px 17px;
  margin-bottom: 15px;
  font-size: 12px;
  border-radius: 2px;
  font-weight: 600;
}
</style>
