<template lang="html">
  <div class="chart-axis-bottom">
    <div class="chart-axis-bottom__navigation d-flex align-items-center" :style="{ width: `${navigationWidth}px` }">
      <button class="border-0" @click="() => this.$emit('prev')">
        <FontAwesomeIcon icon="chevron-left" />
      </button>
      <button class="border-0" @click="() => this.$emit('restore')">
        <FontAwesomeIcon icon="dot-circle" />
      </button>
      <button class="border-0" @click="() => this.$emit('next')">
        <FontAwesomeIcon icon="chevron-right" />
      </button>
    </div>
    <div class="chart-axis-bottom__captions">
      <span
        v-for="(caption, index) in captions"
        v-show="caption.visible"
        :key="`caption-${index}`"
        class="chart-axis-bottom__caption"
        :style="{
          left: caption.style.left ? `${caption.style.left}px` : null,
          right: caption.style.right ? `${caption.style.right}px` : null,
          transform: caption.style.transform,
        }"
      >
        {{ caption.text }}
      </span>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faDotCircle } from '@fortawesome/pro-solid-svg-icons';

library.add(faChevronLeft, faDotCircle, faChevronRight);

export default {
  name: 'ChartAxisBottom',
  props: {
    chartWidth: {
      type: Number,
      required: true,
    },
    navigationWidth: {
      type: Number,
      default: 55,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    captions() {
      let hoveredCaption = null;
      let previousEnd = 0;
      let overlap = false;
      let captions = this.data
        .toSorted((a, b) => {
          if (a.left < b.left) {
            return 1;
          }
          if (a.left > b.left) {
            return -1;
          }
          return 0;
        })
        .map((entry, index) => {
          const text = entry.caption;
          const style = {
            left: entry.left,
          };
          let start = style.left - text.length * 3.5;
          if (entry.left < text.length * 3.5) {
            style.left = 7;
            style.transform = 'translate(0, -50%)';
            start = style.left;
          } else if (this.navigationWidth + entry.left + text.length * 3.5 > this.chartWidth) {
            delete style.left;
            style.right = 7;
            style.transform = 'translate(0, -50%)';
            start = this.chartWidth - this.navigationWidth - style.right - text.length * 7;
          }
          const end = start + text.length * 7;

          // only check overlap for not-hovered caption
          if (entry.hovered) {
            hoveredCaption = index;
          } else {
            if (start < previousEnd) {
              overlap = true;
            }
            previousEnd = end;
          }
          return {
            text,
            style,
            highlight: entry.highlight,
            start,
            end,
          };
        });
      captions = captions.map((caption, index) => {
        let visible = true;
        if (overlap && !caption.highlight && index !== hoveredCaption) {
          visible = false;
        }
        if (hoveredCaption != null && index !== hoveredCaption) {
          if (
            (caption.start > captions[hoveredCaption].start && caption.start < captions[hoveredCaption].end) ||
            (caption.end > captions[hoveredCaption].start && caption.end < captions[hoveredCaption].end) ||
            (caption.start <= captions[hoveredCaption].start && caption.end >= captions[hoveredCaption].end)
          ) {
            visible = false;
          }
        }
        return {
          ...caption,
          visible,
        };
      });
      return captions;
    },
  },
};
</script>

<style lang="css" scoped>
.chart-axis-bottom {
  display: flex;
  flex-direction: row;
  color: white;
  background: #13353c;
  height: 24px;
  border-radius: 0 0 0.2em 0.2em;
}

.chart-axis-bottom__navigation {
  height: 100%;
  background: #425d63;
  border-radius: 0 0 0 0.2em;
}

.chart-axis-bottom__navigation button {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: white;
  background: transparent;
}

.chart-axis-bottom__navigation button:hover {
  background: #527177;
}

.chart-axis-bottom__captions {
  position: relative;
  flex: 1;
}

.chart-axis-bottom__caption {
  display: block;
  position: absolute;
  top: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}
</style>
