<template>
  <ThePageSkeleton class="page-login" scroll-y content-flex>
    <div />
  </ThePageSkeleton>
</template>

<script>
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import { origin } from '@/shared/constants';

import { fetchFieldsByProcessOrderIds } from './shared/api/rest/RestApi';

export default {
  name: 'PageHome',
  components: { ThePageSkeleton },
  async mounted() {
    if (typeof process.env.VUE_APP_START_PAGE === 'string' && process.env.VUE_APP_START_PAGE.length > 0) {
      this.$router.push({ name: process.env.VUE_APP_START_PAGE });
      return;
    }
    await this.$store.dispatch('auth/subscribe');

    const processOrderIds = this.$store.getters.auth?.currentProcessOrderIds;
    if (!processOrderIds || processOrderIds.length === 0) {
      await this.$router.replace({ name: 'fields' });
      return;
    }

    try {
      const { data } = await fetchFieldsByProcessOrderIds(processOrderIds, undefined, undefined, undefined, {
        itemsPerPage: 1,
      });
      if (data.data.length === 0) {
        await this.$router.replace({ name: 'fields' });
      } else {
        window.location.replace(`${origin}`);
      }
    } catch (error) {
      window.location.replace(`${origin}`);
    }
  },
};
</script>

<style scoped></style>
