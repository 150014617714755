import {
  ColumnSettingsWithUserSettings,
  FarmdokColumnSettings,
  UserColumnSettings,
} from '@/shared/handsontable/rework/types';

export default class TableColumnsService {
  private readonly columns: FarmdokColumnSettings[];

  constructor(columns: FarmdokColumnSettings[]) {
    this.columns = columns;
  }

  public getColumnsForHandsontable(
    userColumnSettings: Record<string, UserColumnSettings>,
  ): ColumnSettingsWithUserSettings[] {
    return this.columns
      .filter(TableColumnsService.filterUnavailableColumns)
      .map(TableColumnsService.addUserColumnSettings(userColumnSettings));
  }

  public getAvailableColumns(): FarmdokColumnSettings[] {
    return this.columns.filter(TableColumnsService.filterUnavailableColumns);
  }

  private static filterUnavailableColumns(column: FarmdokColumnSettings): boolean {
    if (column.requiredFeatures) {
      return column.requiredFeatures();
    }

    return true;
  }

  private static addUserColumnSettings(userColumnSettings: Record<string, UserColumnSettings>) {
    return (column: FarmdokColumnSettings, columnIndex: number) => {
      if (typeof column.data !== 'string') throw new Error('column.data is not a string');
      const userSettings = userColumnSettings[column.data] ?? {
        visualColumn: columnIndex,
        physicalColumn: columnIndex,
      };

      return {
        ...column,
        ...userSettings,
        hidden: userSettings.hidden ?? column.hidden ?? false,
      };
    };
  }
}
