<template>
  <TableBase
    id="hot-contracting-import-total-yields"
    ref="table"
    disable-read-only-bg
    :filter-string="filterString"
    :table-settings="tableSettings"
    :table-data="tableData"
    :loading="loading"
    @tableMounted="tableMounted"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @update:filterString="(value) => $emit('update:filterString', value)"
  >
    <template #footer>
      <TableFooter :total-rows="Object.keys(tableData).length" :visible-rows="Object.keys(tableData).length" />
    </template>
  </TableBase>
</template>

<script>
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import columnsTableImportTotalYields from '../handsontable/columns/tableImportTotalYields';

export default {
  name: 'TableImportTotalYieldsContainer',
  components: { TableFooter, TableBase },
  mixins: [tableContainerBase],
  props: {
    filterString: {
      type: String,
      default: '',
    },
    totalYields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columnDefinition: columnsTableImportTotalYields,
      loading: true,
    };
  },
  computed: {
    tableData() {
      return {
        1: { ...this.totalYields },
      };
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>
