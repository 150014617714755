<template>
  <div v-if="errorUserMessageInternal != null || errorsFromResponseData != null" class="error-user-message text-danger">
    <div
      v-for="(message, index) in errorUserMessageInternal"
      :key="`errorUserMessage-errorUserMessageInternal-${message}-${index}`"
      class="error-user-message__error-line"
      :class="{ centerText: centerText }"
    >
      {{ message }}
    </div>

    <div v-if="outerErrorUserMessage != null" class="mb-2">
      <div
        v-for="(singleOuterErrorUserMessage, index) in outerErrorUserMessage"
        :key="`errorUserMessage-singleOuterErrorUserMessage-${singleOuterErrorUserMessage}-${index}`"
      >
        {{ singleOuterErrorUserMessage }}
      </div>
    </div>
    <div
      v-for="(error, index) in errorsFromResponseData"
      class="mb-1"
      :key="`errorUserMessage-errorsFromResponseData-${error.errorUserMessage[0]}-${index}`"
    >
      <div
        v-for="(singleErrorUserMessage, index) in error.errorUserMessage"
        class="font-weight-bold"
        :key="`errorUserMessage-singleErrorUserMessage-${singleErrorUserMessage}-${index}`"
      >
        {{ singleErrorUserMessage }}
      </div>
      <div
        v-for="(errorAttributeUserMessage, index) in error.errorAttributeUserMessages"
        :key="`errorUserMessage-errorAttributeUserMessage-${errorAttributeUserMessage}-${index}`"
      >
        {{ errorAttributeUserMessage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorUserMessage',
  props: {
    errorUserMessage: {
      type: [String, Array],
      validator(errorUserMessage) {
        if (errorUserMessage == null || typeof errorUserMessage === 'string') {
          return true;
        }
        if (!Array.isArray(errorUserMessage)) {
          return false;
        }
        return errorUserMessage.every((message) => typeof message === 'string');
      },
    },
    /**
     * responseData needs to follow the schema described here, either with status 'error' or with status 'partialSuccess':
     * https://git.farmdok.com/farmdok/AgriDat/-/wikis/REST/Response-standard
     */
    responseData: {
      type: Object,
      default: null,
    },
    veeValidateErrors: {
      type: Object,
      default: null,
    },
    showFirstOnly: {
      type: Boolean,
      default: false,
    },
    centerText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    messagesVeeValidate() {
      if (this.veeValidateErrors == null) {
        return [];
      }
      return Object.values(this.veeValidateErrors).reduce((acc, current) => [...acc, ...current], []);
    },
    errorUserMessageInternal() {
      let messages = this.messagesVeeValidate;
      if (typeof this.errorUserMessage === 'string' && this.errorUserMessage.length > 0) {
        messages = [...messages, this.errorUserMessage];
      } else if (Array.isArray(this.errorUserMessage)) {
        messages = [
          ...messages,
          ...this.errorUserMessage.filter((message) => typeof message === 'string' && message.length > 0),
        ];
      }
      if (messages.length === 0) {
        return null;
      }
      if (this.showFirstOnly) {
        return messages.slice(0, 1);
      }
      return messages;
    },
    errorsFromResponseData() {
      if (this.responseData == null) {
        return null;
      }
      if (this.responseData.status === 'error') {
        return [
          {
            errorUserMessage: this.responseData.errorUserMessage,
            errorAttributeUserMessages: this.convertErrorAttributeUserMessagesToArray(
              this.responseData.errorAttributeUserMessages,
            ),
          },
        ];
      }
      if (this.responseData.status === 'partialSuccess') {
        return Object.values(this.responseData.errors).map((error) => ({
          errorUserMessage: error.errorUserMessage,
          errorAttributeUserMessages: this.convertErrorAttributeUserMessagesToArray(error.errorAttributeUserMessages),
        }));
      }
      return null;
    },
    outerErrorUserMessage() {
      if (this.responseData == null || this.responseData.status !== 'partialSuccess') {
        return null;
      }
      return this.responseData.errorUserMessage;
    },
  },
  methods: {
    convertErrorAttributeUserMessagesToArray(errorAttributeUserMessagesObject) {
      if (errorAttributeUserMessagesObject == null) {
        return [];
      }
      return Object.keys(errorAttributeUserMessagesObject).reduce(
        (result, currentField) => [
          ...result,
          `"${currentField}": ${errorAttributeUserMessagesObject[currentField].join(', ')}`,
        ],
        [],
      );
    },
  },
};
</script>

<style scoped>
.error-user-message {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.error-user-message__error-line {
  display: inline-block;
}

.error-user-message__error-line:not(:last-child) {
  margin-bottom: var(--spacer_2);
}

.centerText {
  display: flex;
  justify-content: center;
}
</style>
