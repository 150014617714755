import Handsontable from 'handsontable';

import convertToComparatorReturnValue from '../../features/columnSorting/convertToComparatorReturnValue';

function compareFunctionFactory(
  sortOrder: Handsontable.columnSorting.SortOrderType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  columnMeta: Handsontable.GridSettings,
) {
  return function comparator(value: number, nextValue: number): 0 | 1 | -1 {
    if (sortOrder === 'asc') {
      const result = value - nextValue;
      return convertToComparatorReturnValue(result);
    }

    const result = nextValue - value;
    return convertToComparatorReturnValue(result);
  };
}

export default {
  compareFunctionFactory,
};
