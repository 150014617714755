import { ENTRY_RECENTLY_RESTORED } from '@/shared/constants';

export default {
  mounted() {
    this.addHotHook('afterRenderer', this.featureDeletableTableDataAfterRenderer);
  },
  methods: {
    /**
     * Adds status classes to cells where necessary.
     *
     * @param {HTMLElement} td
     * @param {number} visualRow
     * @param {number} visualColumn
     * @param {function} prop
     * @param {mixed} value
     * @param {object} cellProperties
     */
    featureDeletableTableDataAfterRenderer(td, visualRow, visualColumn, prop, value, cellProperties) {
      if (cellProperties != null && cellProperties.renderer === 'loading') {
        return;
      }
      const guid = this.visualRowToGuid(visualRow);
      if (this.tableData[guid] == null) {
        return;
      }
      const { storeStatus } = this.tableData[guid];

      if (storeStatus === ENTRY_RECENTLY_RESTORED) {
        td.classList.add('recently-restored');
      }
    },
  },
};
