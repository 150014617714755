import { render, staticRenderFns } from "./WidgetCustomer.vue?vue&type=template&id=60001044&scoped=true&"
import script from "./WidgetCustomer.vue?vue&type=script&lang=js&"
export * from "./WidgetCustomer.vue?vue&type=script&lang=js&"
import style0 from "./WidgetCustomer.vue?vue&type=style&index=0&id=60001044&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60001044",
  null
  
)

export default component.exports