import { MutationTree } from 'vuex';

import { subscribableStore } from './common';
import { Sen4State } from './types';

const mutations: MutationTree<Sen4State> = {
  ...subscribableStore.mutations,
};

export default mutations;
