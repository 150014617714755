import { Sen4ApiSen4VegetationIndexGraphRequest } from 'farmdok-rest-api';

import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import subscribableData from '@/shared/mixins/store/subscribableData/index';

import { Sen4Data } from './types';

export async function fetchAll(): Promise<ApiResponse<Sen4Data>> {
  throw new Error('not implemented yet!');
}

async function fetchByIds(): Promise<ApiResponse<Sen4Data>> {
  throw new Error('not implemented yet!');
}

export function identifier(requestParameters: Sen4ApiSen4VegetationIndexGraphRequest): string {
  return `${requestParameters.fieldId}-${requestParameters.indexType}-${requestParameters.statistics}-${requestParameters.startDate}-${requestParameters.endDate}`;
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
