<template>
  <Widget class="widget-map">
    <div class="widget-map__map" :class="{ 'widget-map__map--expanded': expanded }">
      <FieldsMap
        v-if="visible && initialized"
        disable-map-controls
        re-center-on-field-update
        :controls="['zoom']"
        :companies="companies"
        :disabledFields="disabledFields"
        :activeFields="activeFields"
        :selectionSource="'TABLE'"
      />
    </div>
    <div v-if="expanded" class="widget-map__field-info">
      <h2 v-if="fieldInfo != null" class="h1 mb-4">{{ fieldInfo.name }} ({{ fieldInfo.processOrderName }})</h2>
      <div v-if="fieldInfo != null" class="widget-map__field-info-grid">
        <span class="widget-map__field-info-grid-item--1">{{ $t('Feldstück Nummer:') }}</span>
        <span class="widget-map__field-info-grid-item--2">{{ fieldInfo.mfa }}</span>
        <span class="widget-map__field-info-grid-item--1">{{ $t('Feld Nummer:') }}</span>
        <span class="widget-map__field-info-grid-item--2">{{ fieldInfo.number }}</span>
        <span class="widget-map__field-info-grid-item--1">{{ $t('Größe (in ha):') }}</span>
        <span class="widget-map__field-info-grid-item--2">{{ fieldInfo.size | numbro }}</span>
        <span class="widget-map__field-info-grid-item--3">{{ $t('Kultur:') }}</span>
        <span class="widget-map__field-info-grid-item--4">{{ fieldInfo.crop }}</span>
        <span class="widget-map__field-info-grid-item--3">{{ $t('Sorte:') }}</span>
        <span class="widget-map__field-info-grid-item--4">{{ fieldInfo.variety }}</span>
      </div>
    </div>
  </Widget>
</template>

<script>
import Widget from '@/shared/components/Widget.vue';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';

import FieldsMap from './FieldsMap.vue';

export default {
  name: 'WidgetMap',
  components: { Widget, FieldsMap },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    visibleFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    crops: {
      type: Object,
      default: () => ({}),
    },
    varieties: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      initialized: false,
    };
  },
  computed: {
    companies() {
      return this.$store.state.auth.currentCompanies;
    },
    disabledFields() {
      const selectedFieldIds = this.selectedFields.map((field) => field.id);
      return this.visibleFields.reduce((disabledFields, field) => {
        if (selectedFieldIds.includes(field.id)) {
          return disabledFields;
        }
        return {
          ...disabledFields,
          [field.id]: field,
        };
      }, {});
    },
    activeFields() {
      return this.selectedFields.reduce(
        (activeFields, field) => ({
          ...activeFields,
          [field.id]: field,
        }),
        {},
      );
    },
    fieldInfo() {
      if (this.selectedFields.length !== 1) {
        return null;
      }
      const [field] = this.selectedFields;
      let crop = this.$t('Nicht gepflegt');
      if (field.cropId != null && this.crops[field.cropId] != null) {
        crop = this.crops[field.cropId].name;
      }
      let variety = this.$t('Nicht gepflegt');
      if (field.varietyId != null && this.varieties[field.varietyId] != null) {
        variety = this.varieties[field.varietyId].name;
      }
      return {
        name: field.name,
        mfa: getDescendantProp(field, 'fieldGroup.mfa'),
        number: field.fieldNumber,
        size: field.fieldSize,
        crop,
        variety,
        processOrderName: field.processOrder.name,
      };
    },
  },
  async created() {
    // do not render map before activeFields are set, otherwise the initial map centering isn't working
    this.initialized = true;
  },
};
</script>

<style lang="css" scoped>
.widget-map {
  display: flex;
  min-height: 330px;
  flex-direction: row;
  background: var(--lightest);
}

.widget-map__map {
  position: relative;
  flex-grow: 1;
}

.widget-map__map--expanded {
  min-width: 430px;
}

.widget-map__field-info {
  width: 66%;
  padding: var(--spacer_5) var(--spacer_6);
  border: 1px solid var(--medium);
  background: var(--lightest);
}

@media (min-width: 1500px) {
  .widget-map__field-info {
    width: calc(50% + var(--spacer_4));
  }
}

.widget-map__field-info-grid {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  grid-auto-flow: row dense;
  grid-column-gap: var(--spacer_3);
  grid-row-gap: var(--spacer_2);
}

.widget-map__field-info-grid-item--1 {
  grid-column: 1;
  font-weight: 600;
}

.widget-map__field-info-grid-item--2 {
  grid-column: 2;
}

.widget-map__field-info-grid-item--3 {
  grid-column: 3;
  font-weight: 600;
}

.widget-map__field-info-grid-item--4 {
  grid-column: 4;
}
</style>
