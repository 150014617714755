<template>
  <ModalWrapper
    ref="modalWrapper"
    size="xl"
    body-class="modal-image-gallery"
    footer-variant="none"
    scrollable
    :visible="visible"
    :title="$t('Bilder')"
    @change="(value) => $emit('change', value)"
  >
    <template #default>
      <BCarousel controls indicators no-wrap :interval="0" :value="startIndex">
        <BCarouselSlide v-for="image in imagesComputed" :key="image.src">
          <template #img>
            <img class="modal-image-gallery__img" :src="image.src" :alt="image.alt" />
          </template>
        </BCarouselSlide>
      </BCarousel>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';

library.add(faCircleNotch);

export default {
  name: 'ModalCreateProcessOrder',
  components: { ModalWrapper },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      required: true,
    },
    startIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    imagesComputed() {
      return this.images.map((image) => ({
        src: typeof image === 'string' ? image : image.src,
      }));
    },
  },
};
</script>

<style scoped>
.modal-image-gallery__img {
  width: 100%;
  height: calc(80 * var(--vh, 1vh));
  object-fit: contain;
  background-color: var(--gray_400);
}
</style>
