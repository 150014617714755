<template>
  <ModalImportHarvestDataFromUnsignedContract
    ref="modal"
    :fetching-company-data="fetchingCompanyData"
    :is-creating="isCreating"
    :error-creating="errorCreating"
    :process-order-name="processOrderName"
    :process-order-names="processOrderNames"
    :contract-id="contractId"
    :contract-options="contractOptions"
    :company-bdm-id="company.companyBdmId"
    :foreign-id="company.foreignId"
    @change:processOrderName="(value) => (processOrderName = value)"
    @change:contractId="(value) => (contractId = value)"
    @change:companyBdmId="(value) => (company.companyBdmId = value)"
    @change:foreignId="(value) => (company.foreignId = value)"
    @accept="createCompanyContract"
  />
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import ModalImportHarvestDataFromUnsignedContract from '../components/ModalImportHarvestDataFromUnsignedContract.vue';

export default {
  name: 'ModalImportHarvestDataFromUnsignedContractContainer',
  components: { ModalImportHarvestDataFromUnsignedContract },
  props: {
    partner: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      processOrderName: null,
      contractId: null,

      fetchingCompanyData: false,
      company: {
        companyBdmId: '',
        foreignId: '',
        companyName: '',
        address: '',
        postcode: '',
        city: '',
        country: '',
        user: {
          title: null,
          firstname: '',
          lastname: '',
          email: '',
        },
      },

      isCreating: false,
      errorCreating: null,
    };
  },
  computed: {
    ...mapGetters({
      contractsUnsignedExpired: 'digitalContracting/unsignedExpired',
      contractsById: 'digitalContracting/data',
    }),
    usableContracts() {
      return this.contractsUnsignedExpired.filter((contract) => contract.partner === this.partner);
    },
    processOrderNames() {
      const processOrderNames = new Set();
      this.usableContracts.forEach((contract) => processOrderNames.add(contract.processOrderName));
      return [...processOrderNames].sort((a, b) => b - a);
    },
    contractsBySelectedProcessOrderName() {
      return this.usableContracts.filter((contract) => contract.processOrderName === this.processOrderName);
    },
    contractOptions() {
      return this.contractsBySelectedProcessOrderName.map((contract) => ({ value: contract.id, text: contract.name }));
    },
  },
  watch: {
    processOrderName() {
      if (this.contractsBySelectedProcessOrderName[0] != null) {
        this.contractId = this.contractsBySelectedProcessOrderName[0].id;
      }
    },
    contractId() {
      if (this.company.companyBdmId === '' || this.company.foreignId === '') {
        this.reloadCompanyData();
      }
    },
  },
  methods: {
    show() {
      if (this.processOrderNames.length > 0) {
        [this.processOrderName] = this.processOrderNames;
      } else {
        this.processOrderName = null;
      }
      this.errorCreating = null;
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    async reloadCompanyData() {
      this.fetchingCompanyData = true;
      const { data } = await axios.get(`/admin/digitalContracting/getCompanyDataForContract/${this.contractId}`);
      data.country = data.region.name;
      if (this.company.companyBdmId === '') {
        this.company.companyBdmId = String(data.companyBdmId == null ? '' : data.companyBdmId);
      }
      if (this.company.foreignId === '') {
        this.company.foreignId = String(data.foreignId == null ? '' : data.foreignId);
      }
      this.company.companyName = data.companyName;
      this.company.address = data.address;
      this.company.postcode = String(data.postcode);
      this.company.city = data.city;
      this.company.country = data.country;
      this.company.user.title = data.title;
      this.company.user.firstname = data.firstname;
      this.company.user.lastname = data.lastname;
      this.company.user.email = data.email;
      this.fetchingCompanyData = false;
    },
    async createCompanyContract() {
      this.isCreating = true;
      this.errorCreating = null;
      try {
        const { data } = await axios.post(`/admin/digitalContracting/agrana/contractCompany/${this.contractId}`, {
          companyBdmId: this.companyBdmId,
          ...this.company,
          ...this.company.user,
        });
        this.$store.commit('digitalContracting/accept', {
          contract: this.contractsById[this.contractId],
          contractCompanyId: data.contractCompanyId,
        });
        this.$refs.modal.hide();
        this.$router.push({
          name: 'digital-contracting/import-contract',
          params: {
            contractCompanyId: data.contractCompanyId,
          },
        });
        this.isCreating = false;
      } catch (e) {
        let errorCreating = this.$t('Unerwarteter Fehler.');
        if (e != null && e.response != null && e.response.data != null) {
          if (e.response.data.status === 'attributeValueError') {
            [[errorCreating]] = Object.values(e.response.data.attributeErrorMessages);
          }
          if (e.response.data.status === 'error' && typeof e.response.data.errorUserMessage === 'string') {
            errorCreating = e.response.data.errorUserMessage;
          }
        }
        this.errorCreating = errorCreating;
        this.isCreating = false;
      }
    },
  },
};
</script>

<style scoped></style>
