import axios, { AxiosResponse } from 'axios';
import { Sen4ApiSen4VegetationIndexGraphRequest, Sen4VegetationIndexGraph200Response } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { RootState } from '@/store/types';

import { identifier, subscribableStore } from './common';
import { Sen4Data, Sen4State } from './types';

function containsOnlyNulls(dataEntry: Sen4Data): boolean {
  return dataEntry.data.value.every((v: number) => v === null);
}

const actions: ActionTree<Sen4State, RootState> = {
  ...subscribableStore.actions,
  async vegetationIndexGraph(
    { state, commit }: ActionContext<Sen4State, RootState>,
    requestParameters: Sen4ApiSen4VegetationIndexGraphRequest,
  ): Promise<Sen4Data> {
    const id = identifier(requestParameters);
    const sen4Data = state.data[id];
    if (sen4Data) {
      return Promise.resolve().then(() => sen4Data);
    }

    const source = axios.CancelToken.source();
    commit('startFetching', source);
    const { sen4Api } = Vue.prototype.$api as Api;
    return sen4Api
      .sen4VegetationIndexGraph(requestParameters)
      .then((response: AxiosResponse<Sen4VegetationIndexGraph200Response>) => ({ id, ...response.data }))
      .then((dataEntry: Sen4Data) => {
        if (containsOnlyNulls(dataEntry)) {
          throw new Error('Vegetation data contains only nulls! Avoid setting in the state.');
        }

        commit('addOne', dataEntry);
        return dataEntry;
      })
      .catch((error) => {
        console.error('Failed to load vegetation index data due to the following error', error);
        return sen4Data;
      })
      .finally(() => commit('finishFetching', source));
  },
};

export default actions;
