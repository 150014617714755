
import { ActivityProduct, StoragePlace } from 'farmdok-rest-api';
import { defineComponent } from 'vue';

import { Product } from '@/shared/api/rest/models';
import { RootState } from '@/store/types';

import ProductDetails from '../components/ProductDetails.vue';

export default defineComponent({
  name: 'ProductDetailsContainer',
  components: { ProductDetails },
  computed: {
    currentActivityProductId(): string | null {
      return (this.$store.state as RootState).activities.createEditActivity.currentActivityProductId;
    },
    products(): Product[] {
      return this.$store.getters['products/allProducts'];
    },
    storagePlaces(): StoragePlace[] {
      return Object.values((this.$store.state as RootState).storagePlaces.data);
    },
    activityProductsWithProductId(): ActivityProduct[] {
      const activityProducts =
        (this.$store.getters[`activities/createEditActivity/mergedActivityProducts`] as ActivityProduct[]) ?? [];
      return activityProducts.filter((activityProduct) => activityProduct.productId);
    },
  },
  methods: {
    updateCurrentActivityProductId(currentActivityProductId: string) {
      this.$store.commit(`activities/createEditActivity/setCurrentActivityProductId`, currentActivityProductId);
    },
  },
});
