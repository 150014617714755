import { PesticideIndication } from 'farmdok-rest-api';

import { isGlobalProduct } from '@/products/store/compatibleProductsFilter';
import { Getters as ProductGetters } from '@/products/store/getters';

export function filterByCropIds(pestIndication: PesticideIndication, cropIds: string[]): boolean {
  return cropIds.every((cropId) => pestIndication.cropIds.includes(cropId));
}

export function filterCompanySpecificCropId(
  cropId: string,
  findProductById: ProductGetters['findProductById'],
): boolean {
  const product = findProductById(cropId);
  if (!product) return false;

  return isGlobalProduct(product);
}

export function filterByApprovalTimes(pesticideIndication: PesticideIndication, activityTimeStarts: number[]): boolean {
  return (
    activityTimeStarts.length === 0 ||
    (pesticideIndication.approvalStart < Math.min(...activityTimeStarts) &&
      (!pesticideIndication.approvalEnd || pesticideIndication.approvalEnd > Math.max(...activityTimeStarts)))
  );
}
