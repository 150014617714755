import parseNumberAsHoursOrMinutes from './parseNumberAsHoursOrMinutes';
import parsePattern1d2h3m4s from './parsePattern1d2h3m4s';
import parsePatternhhmmss from './parsePatternhhmmss';

export default function parseDurationStringToSeconds(value: string | undefined | null): number | null {
  if (value == null) return null;

  const trimmedValue = value.trim();
  if (trimmedValue === '') return null;

  let parsedValue: number | null = null;
  parsedValue = parsePattern1d2h3m4s(trimmedValue);
  if (parsedValue !== null) return parsedValue;

  parsedValue = parsePatternhhmmss(trimmedValue);
  if (parsedValue !== null) return parsedValue;

  parsedValue = parseNumberAsHoursOrMinutes(trimmedValue);
  if (parsedValue !== null) return parsedValue;

  return null;
}
