<template>
  <BFormRadio
    class="form-radio"
    :class="classes"
    :value="value"
    :checked="isInGroup ? null : checked"
    :disabled="disabled"
    @change="change"
    @input="input"
    @click.native="onClick"
  >
    <FontAwesomeIcon
      class="form-radio__icon"
      :class="[
        `form-radio__icon--${size}`,
        `form-radio__icon--${variant}`,
        { 'form-radio__icon--checked': checkedComputed },
      ]"
      :icon="checkedComputed ? ['far', 'dot-circle'] : ['far', 'circle']"
    />
    <span
      v-if="$slots.image != null"
      class="form-radio__image"
      :class="[
        `form-radio__image--${size}`,
        `form-radio__image--${variant}`,
        { 'form-radio__image--checked': checkedComputed },
      ]"
    >
      <!--
        @slot
        Optional image to be used in variant "card", will be rendered above the title
      -->
      <slot name="image" />
    </span>
    <span
      class="form-radio__title"
      :class="[
        `form-radio__title--${size}`,
        `form-radio__title--${variant}`,
        { 'form-radio__title--checked': checkedComputed },
      ]"
    >
      <!--
        @slot
        The rendered content inside the FormRadio, the text describing the radio button.
      -->
      <slot name="default" />
    </span>
    <span
      v-if="$slots.text != null"
      class="form-radio__text"
      :class="[
        `form-radio__text--${size}`,
        `form-radio__text--${variant}`,
        { 'form-radio__text--checked': checkedComputed },
      ]"
    >
      <!--
        @slot
        Additional text that is rendered smaller and below the radio label.
      -->
      <slot name="text" />
    </span>
  </BFormRadio>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faDotCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BFormRadio } from 'bootstrap-vue';

library.add(faCircle, faDotCircle);

/**
 * Use this component always inside a FormFieldRadioGroup.
 *
 * @category Shared
 * @subcategory Molecules
 */
export default {
  name: 'FormRadio',
  components: { FontAwesomeIcon, BFormRadio },
  model: {
    prop: 'checked',
    event: 'input',
  },
  inject: {
    bvGroup: {
      from: 'bvRadioGroup',
      default: false,
    },
  },
  props: {
    /**
     * Changes the size of the text and the icon.
     */
    size: {
      type: String,
      default: 'md',
      validator: (size) => ['sm', 'md', 'lg'].includes(size),
    },
    /**
     * Adds a border and background color (for hover and checked) to the text + icon.
     */
    variant: {
      type: String,
      default: 'default',
      validator: (variant) => ['default', 'bordered', 'card'].includes(variant),
    },
    /**
     * The value that can be selected in the group.
     */
    value: {
      required: true,
    },
    /**
     * Specifies if the radio button is checked.
     */
    checked: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables the checkbox.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isInGroup() {
      return !!this.bvGroup;
    },
    checkedComputed() {
      if (this.isInGroup) {
        return this.bvGroup.localChecked === this.value;
      }
      return this.checked;
    },
    classes() {
      const classes = [`form-radio--${this.size}`];
      if (this.variant !== 'default') {
        classes.push(`form-radio--${this.variant}`);
      }
      if (this.checkedComputed) {
        classes.push('form-radio--checked');
      }
      return classes;
    },
  },
  methods: {
    async onClick() {
      this.$el.querySelector('input').focus();
      if (this.disabled) {
        return;
      }
      this.$emit('input', this.value);
      if (this.isInGroup) {
        this.bvGroup.localChecked = this.value;
        this.bvGroup.$emit('input', this.value);
      }
      await this.$nextTick();
      this.$emit('change', this.value);
      if (this.isInGroup) {
        this.bvGroup.$emit('change', this.value);
      }
    },
    /**
     * Emitted when selected value(s) is changed due to user interaction.<br>
     * Also used to update the model.
     *
     * @event FormRadio#input
     * @type {Boolean} value
     */
    input(value) {
      this.$emit('input', value);
    },
    /**
     * Emitted when the selected value(s) is changed.
     *
     * @event FormRadio#change
     * @type {Boolean} value
     */
    change(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style scoped lang="scss">
.form-radio {
  margin: 0;
  padding: 0.375rem 0.75rem 0.375rem 24px;
}

.form-radio--sm {
  padding-left: 22px;
}

.form-radio--lg {
  padding-left: 28px;
}

.form-radio__icon {
  position: absolute;
  border-radius: 8px;
  font-size: 16px;
  transform: translate(-24px, 2px);
}

.form-radio__icon--sm {
  border-radius: 7px;
  font-size: 14px;
  transform: translate(-22px, 2px);
}

.form-radio__icon--lg {
  border-radius: 10px;
  font-size: 20px;
  transform: translate(-28px, -1px);
}

::v-deep .custom-control-input:focus-visible ~ .custom-control-label .form-radio__icon,
::v-deep .custom-control-input:active ~ .custom-control-label .form-radio__icon {
  box-shadow: 0 0 0 2px var(--primary_opacity_60);
}

.form-radio--bordered {
  margin-bottom: var(--spacer_2);
  padding: 0.75rem 0.5rem 0.75rem 2.5rem;
  border: 1px solid var(--medium);
  border-radius: 3px;
  cursor: pointer;
}

.form-radio--bordered::v-deep * {
  pointer-events: none;
}

.form-radio--bordered.form-radio--checked {
  background: var(--lightest);
}

.form-radio--bordered:hover,
.form-radio--bordered.form-radio--checked:hover {
  background: var(--gray_200);
}

.form-radio--card {
  width: 185px;
  min-height: 230px;
  margin: var(--spacer_3);
  padding: 0;
  cursor: pointer;
}

.form-radio--card::v-deep .custom-control-label {
  position: relative;
  width: 100%;
  padding: var(--spacer_6) var(--spacer_4) var(--spacer_4);
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 0 1px var(--gray_400);
}

.form-radio--card:where(:hover)::v-deep .custom-control-label {
  background-color: var(--gray_100);
}

.form-radio--card:where(.form-radio--checked)::v-deep .custom-control-input:focus-visible ~ .custom-control-label,
.form-radio--card:where(.form-radio--checked)::v-deep .custom-control-input:active ~ .custom-control-label {
  box-shadow: 0 0 0 2px var(--primary_dark), 0 0 0 4px var(--primary_opacity_25);
}

.form-radio--card:where(.form-radio--checked)::v-deep .custom-control-label {
  box-shadow: 0 0 0 2px var(--primary_dark);
  background-color: var(--primary_light);
}

.form-radio--card::v-deep * {
  pointer-events: none;
}

.form-radio--card::v-deep .custom-control-label {
}

.form-radio__icon--card {
  top: 9px;
  right: 10px;
  transform: none;
  background-color: var(--white);
}

.form-radio__icon--checked.form-radio__icon--card {
  box-shadow: 0 0 0 2px var(--primary_opacity_60);
}

.form-radio__image--card {
  display: flex;
  height: 45px;
  margin-bottom: var(--spacer_4);
  align-items: center;
  justify-content: center;
}

.form-radio__title {
  display: block;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.form-radio__title--card {
  font-weight: bold;
  margin-bottom: 1em;
}

.form-radio__title--checked.form-radio__title--card {
  color: var(--primary_darker);
}

.form-radio__title--sm {
  font-size: 12px;
}

.form-radio__title--lg {
  font-size: 16px;
}

.form-radio__text {
  display: block;
  margin-top: var(--spacer_2);
  font-size: 12px;
}

.form-radio__text--sm {
  font-size: 10px;
}

.form-radio__text--lg {
  font-size: 14px;
}

::v-deep .custom-control-label::before,
::v-deep .custom-control-label::after {
  display: none;
}
</style>
