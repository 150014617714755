import { MutationTree } from 'vuex';

import { MineralFertilizer } from '@/shared/api/rest/models';
import { ENTRY_SYNCED, GUID_KEY } from '@/shared/constants';
import { Data } from '@/shared/mixins/store/types';

import productsModuleStore from './common';
import { MineralFertilizersState } from './types';

const mutations: MutationTree<MineralFertilizersState> = {
  ...productsModuleStore.mutations,
  loadAll(state, { data }: { data: MineralFertilizer[] }) {
    state.data = data.reduce((dataCurrent, entry: MineralFertilizer) => {
      const material: MineralFertilizer = {
        ...entry,
        storeStatus: ENTRY_SYNCED,
        n: 0,
        p: 0,
        k: 0,
      };
      if (
        Array.isArray(material.variants) &&
        material.variants.length > 0 &&
        material.variants[0].processOrderId == null &&
        material.variants[0].ingredients != null
      ) {
        Object.keys(material.variants[0].ingredients).forEach((key) => {
          // @ts-ignore
          material[key.toLowerCase()] = material.variants[0].ingredients[key].amount;
        });
      }
      const newData: Data<MineralFertilizer> = { ...dataCurrent, [entry[GUID_KEY]]: material };
      return newData;
    }, {});

    state.loaded = new Date();
  },
};

export default mutations;
