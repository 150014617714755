import { UNIT_ID_KG_PER_HA } from '@/products/constants';

import initialStateBaseWorkflow from '../../store/baseWorkflowStore/initialState';
import { ApplicationMapsFertilizationState } from './types';

export default function initialState(): ApplicationMapsFertilizationState {
  return {
    ...initialStateBaseWorkflow(),
    selectedIndexType: 'DNN_NDVI',
    selectedHeatmapDbId: null,
    selectedHeatmapTimestamp: null,
    heatmapTimestampSelectedIndex: null,
    heatmapTimestampManuallySelected: false,
    selectedQuantisationCode: 'medium',

    // uploaded zones
    uploadedZonesByFilename: {},

    multiPolyTimestamps: {
      loaded: {},
      current: {},
    },

    // dosage calculation
    calculation: {
      averageDosage: null,
      material: {
        id: null,
        name: '',
      },
      unit: UNIT_ID_KG_PER_HA,
      n: 0,
      p: 0,
      k: 0,
      zoneDifference: 0.05,
      strategy: 'balancing',
      manualDosage: null,
    },
  };
}
