<template>
  <ModalWrapper
    ref="modalWrapper"
    size="md"
    body-class="modal-edit-status"
    static
    :visible="visible"
    :title="$t('Erntejahr {newYear} hinzufügen', { newYear })"
    @change="(value) => $emit('change', value)"
  >
    <template v-if="processOrdersReadOnly" #default>
      <div>
        <p>
          {{ $t('Du hast keine Berechtigung ein neues Erntejahr hinzuzufügen.') }}
        </p>
      </div>
    </template>
    <template v-else #default>
      <div v-if="success">
        <p>
          {{ $t('Das Erntejahr {newYear} wurde hinzugefügt.', { newYear }) }}
        </p>
        <p v-if="copyFields">
          {{ $t('Die Felder wurden aus {sourceYear} übernommen.', { sourceYear }) }}
        </p>
      </div>
      <p v-else-if="error" class="modal-edit-status__error">
        {{ errorUserMessage }}
      </p>
      <div v-else>
        <p>
          {{ $t('Willst du ein neues Erntejahr {newYear} hinzufügen?', { newYear }) }}
        </p>
        <p v-if="direction === 'next'">
          {{ $t('Die Felder aus dem vorangehenden Erntejahr {sourceYear} werden übernommen.', { sourceYear }) }}
        </p>
        <div v-else>
          <FormCheckbox v-model="copyFields" switch>
            <span v-if="copyFields">
              {{ $t('Die Felder aus dem nachfolgenden Erntejahr {sourceYear} übernehmen.', { sourceYear }) }}
            </span>
            <span v-else>
              {{ $t('Es werden keine Felder in {newYear} erstellt.', { newYear }) }}
            </span>
          </FormCheckbox>
        </div>
      </div>
    </template>
    <template #buttons>
      <Button variant="primary" :fetching="fetching" :disabled="processOrdersReadOnly" @click="create">
        <span v-if="success || error">
          {{ $t('Schließen') }}
        </span>
        <span v-else>
          {{ $t('OK') }}
        </span>
      </Button>
      <Button v-if="!success && !error" variant="white" :disabled="fetching" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </Button>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Button from '@/shared/components/buttons/Button.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

library.add(faCircleNotch);

export default {
  name: 'ModalCreateProcessOrder',
  components: { Button, FormCheckbox, ModalWrapper },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'next',
      validator: (direction) => ['next', 'previous'].includes(direction),
    },
    sourceProcessOrderName: {
      type: String,
      default: null,
    },
    switchToNewProcessOrder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentProcessOrderName: null,
      copyFields: true,
      fetching: false,
      success: false,
      error: false,
      errorUserMessage: null,
      resolves: [],
    };
  },
  computed: {
    sourceYear() {
      return this.sourceProcessOrderName != null ? this.sourceProcessOrderName : this.currentProcessOrderName;
    },
    previous() {
      return Number(this.sourceYear) - 1;
    },
    next() {
      return Number(this.sourceYear) + 1;
    },
    newYear() {
      if (this.direction === 'previous') {
        return this.previous;
      }
      return this.next;
    },
    processOrdersReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_PROCESS_ORDER);
    },
  },
  mounted() {
    this.currentProcessOrderName = this.$store.state.auth.currentProcessOrderName;
  },
  methods: {
    async create() {
      if (this.success || this.error) {
        this.hide();
        return;
      }
      this.fetching = true;
      // wait 100ms so that the BE request is not blocking the update of the UI (loading status)
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 100));
      const response = await this.$store.dispatch('auth/createProcessOrder', {
        direction: this.direction,
        copyFields: this.copyFields,
        sourceProcessOrderName: this.sourceYear,
      });
      if (response.status !== 'success') {
        this.fetching = false;
        this.error = true;
        this.errorUserMessage = response.errorUserMessage.join('\n');
        return;
      }
      await this.$store.dispatch('auth/refreshUserCompanies');
      if (this.switchToNewProcessOrder && response.status === 'success') {
        await this.$store.dispatch('auth/setCurrentProcessOrderName', response.data[0].name);
      }
      this.fetching = false;
      this.success = true;
    },
    async show() {
      return new Promise((resolve) => {
        this.resolves = [...this.resolves, resolve];
        this.$refs.modalWrapper.show();
      });
    },
    hide() {
      this.$refs.modalWrapper.hide();
      this.resolves.forEach((resolve) => resolve(this.success && !this.error));
      this.resolves = [];
    },
  },
};
</script>

<style scoped>
.modal-edit-status__error {
  color: var(--danger_dark);
  white-space: pre-line;
}

.modal-edit-status__icon {
  margin-bottom: 2px;
  margin-left: 5px;
  font-size: 12px;
}

.modal-edit-status__icon--completed {
  color: var(--gray_600);
}

.modal-edit-status__icon--active {
  color: var(--primary);
}

.modal-edit-status__icon--planned {
  color: var(--black);
}
</style>
