
import { defineComponent } from 'vue';

import { availableFeatures } from '@/shared/storeDynamicFeatures';

import NoContent from '../../components/table/NoContent.vue';

export default defineComponent({
  name: 'NoContentContainer',
  components: { NoContent },
  computed: {
    featureActivitiesEnabled(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_ACTIVITIES);
    },
    featureActivitiesReadOnly(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_ACTIVITIES);
    },
    featureCreateActivityEnabled(): boolean {
      return this.featureActivitiesEnabled && !this.featureActivitiesReadOnly;
    },
  },
});
