import Handsontable from 'handsontable';

import { ColspanFunction } from './types';

function applyColspanFactory(hot: Handsontable) {
  function applyColspan(
    TD: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: any,
    cellProperties: Handsontable.CellProperties,
  ) {
    if (!hot) return;

    if (typeof cellProperties.colspan === 'function') {
      const colspan = (cellProperties.colspan as ColspanFunction)(row, col, value, hot);
      // eslint-disable-next-line no-param-reassign
      TD.colSpan = colspan;
    } else {
      TD.removeAttribute('colspan');
    }
  }

  return applyColspan;
}

export default {
  afterRenderer: { applyColspanFactory },
};
