import { ActivityRuleViolation } from 'farmdok-rest-api';

export default class ActivityRuleViolationService {
  private readonly activityRuleViolations: ActivityRuleViolation[];

  constructor(activityRuleViolations: ActivityRuleViolation[]) {
    this.activityRuleViolations = activityRuleViolations;
  }

  public getActivityRuleViolations(activityId?: string): ActivityRuleViolation[] {
    if (!activityId) return this.activityRuleViolations;

    return this.activityRuleViolations.filter(
      (activityRuleViolation) => activityRuleViolation.activityId === activityId,
    );
  }
}
