import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { MonitoringState } from './types';

export type Getters = {};

const moduleGetters: GetterTree<MonitoringState, RootState> = {
  selectedFields: (state: MonitoringState) => state.selectedFields,
  overlay: (state: MonitoringState) => state.overlay,
  overlayFolded: (state: MonitoringState) => state.overlay.folded,
  polygons: (state: MonitoringState) => state.polygons,
  currentHeatmaps: (state: MonitoringState) => state.heatmaps.current,
  multiPolyTimestamps: (state: MonitoringState) => Object.values(state.multiPolyTimestamps.current),
  vegetationData: (state: MonitoringState) => state.vegetationData.current,
  selectedHeatmapDbId: (state: MonitoringState) => state.selectedHeatmapDbId,
};

export default moduleGetters;
