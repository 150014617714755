
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FilterInfoBar',
  props: {
    zindex: { type: Number, default: 9999 },
    top: { type: Number, default: 0 },
  },
  computed: {
    cssProps(): any {
      return {
        '--z-index': `${this.zindex}`,
        '--top': `${this.top}px`,
      };
    },
  },
  methods: {
    onReset() {
      this.$emit('resetFilter');
    },
  },
});
