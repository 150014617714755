import Handsontable from 'handsontable';

type Row = number;
type Key = string;
type OldValue = any;
type NewValue = any;
type IdObject = { id: string };

export type CellChangeWithIds<T extends IdObject> = [Row, Key, OldValue, NewValue, T];

export default function addIdsToChanges<T extends IdObject>(
  changes: Handsontable.CellChange[],
  hot: Handsontable,
): CellChangeWithIds<T>[] {
  return changes.map<CellChangeWithIds<T>>((change) => {
    const [visualRow, prop, oldValue, newValue] = change;
    const id: string = hot.getDataAtRowProp(visualRow, 'id');
    // casting is necessary because we cannot instantiate type T
    return [visualRow, `${prop}`, oldValue, newValue, { id }] as CellChangeWithIds<T>;
  });
}
