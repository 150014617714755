import Handsontable from 'handsontable';
import Vue from 'vue';

import EquipmentService from '@/equipments/services/EquipmentService';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { ColumnSettingsOptional } from '@/shared/handsontable/rework/cellTypes/optionalRenderer/types';
import { columnSorting as columnSortingSubtablePrimaryColumn } from '@/shared/handsontable/rework/cellTypes/subtablePrimaryColumn';
import { ColumnSettingsSubtablePrimaryColumn } from '@/shared/handsontable/rework/cellTypes/subtablePrimaryColumn/types';
import { ColumnSettingsSubtable } from '@/shared/handsontable/rework/features/nestedTable/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

const colspanPrimaryColumn = (
  visualRow: number,
  value: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER' | null,
  instance: Handsontable,
): number => {
  const isExpanded = instance.getDataAtRowProp(visualRow, 'expand');
  if (!isExpanded) return 2;

  if (value === null || value === 'HOT_DISPLAY_BUTTON_RENDERER' || !value.id) return 2;

  return 1;
};

const equipment: ColumnSettingsSubtable<
  Handsontable.ColumnSettings | ColumnSettingsSubtablePrimaryColumn | ColumnSettingsOptional
> = {
  data: 'equipment',
  header: {
    title: Vue.i18n.translate('Maschinen & Geräte'),
  },
  requiredFeatures: () =>
    store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_EQUIPMENT) &&
    store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_EQUIPMENT),
  type: 'subtable',
  subtableColumns: [
    {
      data: 'equipment.dropdownItem',
      type: 'farmdok.subtablePrimaryColumn',
      width: 200,
      colspan: (visualRow, visualColumn, value, instance) => colspanPrimaryColumn(visualRow, value, instance),
      collapsedSubtable: {
        getRendererValue({ values }) {
          const displayNames = values.map((value) => value.name ?? '').filter((name) => name !== '');

          return displayNames.join(', ');
        },
        onClick({ event, visualRow, instance: hot }) {
          event.preventDefault();
          hot.setDataAtRowProp(visualRow, 'expand', true);
        },
      },
      button: {
        label: `+ ${Vue.i18n.translate('Maschine hinzufügen')}` || '+ Maschine hinzufügen',
        color: 'primary',
        onClick({ visualRow, instance: hot }) {
          const activityId = hot.getDataAtRowProp(visualRow, 'id');
          store.dispatch('activities/addActivityEquipment', activityId);
        },
      },
      dropdown: {
        async getItems(visualRow: number, hot: Handsontable) {
          const activityTypeId: string = hot.getDataAtRowProp(visualRow, 'activityTypeDropdownItem')?.id;
          if (!activityTypeId) return [];
          const activityType = store.state.activityTypes.data[activityTypeId];

          const processOrderId: string | undefined = hot.getDataAtRowProp(visualRow, 'processOrderId');
          if (!processOrderId) return [];
          const company = store.getters['auth/findCompanyByProcessOrderId'](processOrderId);
          if (!company) return [];

          const equipmentService = new EquipmentService(store.state.equipments.data);
          const usedEquipment = store.state.equipments.used;
          const compatibleEquipments = equipmentService.getCompatibleEquipmentsToDropdownItems(
            [activityType],
            [company],
            usedEquipment,
          );

          return compatibleEquipments;
        },
      },
      columnSorting: columnSortingSubtablePrimaryColumn,
    },
    // is always hidden but needed in table for change detection of product.id,
    // not allowed to be first column (otherwise column would be always hidden in tableSettings),
    // not allowed to be the last column (to properly add subtable styles)
    { data: 'equipment.id', type: 'text', hidden: true },
    {
      data: 'equipmentTrashIcon', // this is just a dummy value, the column is not bound to any data (required for move columns via user column settings)
      type: 'farmdok.trashIcon',
      renderer: 'farmdok.optional',
      noHeaderContextMenu: true,
      width: 45,
      disableColumnResize: true,
      renderEmpty(visualRow, instance) {
        const activityEquipmentId = instance.getDataAtRowProp(visualRow, 'equipment.id');
        return !activityEquipmentId;
      },
      renderNone: (visualRow, instance) => {
        const dropdownItem = instance.getDataAtRowProp(visualRow, 'equipment.dropdownItem');
        return colspanPrimaryColumn(visualRow, dropdownItem, instance) === 2;
      },
      onClick(visualRow, hot) {
        if (!this.readOnly) {
          const activityId = hot.getDataAtRowProp(visualRow, 'id');
          const activityEquipmentId = hot.getDataAtRowProp(visualRow, 'equipment.id');
          store.dispatch('activities/removeOrDeleteActivityEquipmentAndSync', { activityId, activityEquipmentId });
        }
      },
    },
  ],
};

export default equipment;
