
import { defineComponent } from 'vue';

import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';

const images = require.context('../../assets/');

export default defineComponent({
  name: 'NoContent',
  components: { Button, MaxWidthContainer },
  props: {
    createActivityDisabled: { type: Boolean, default: false },
  },
  data: () => ({
    imageSrc: images('./no-content-activities.svg'),
  }),
});
