import { ENTRY_NEW } from '@/shared/constants';

import AnalyticsHelper from './initAnalytics';
import ClevertapHelper from './initClevertap';

const modalsNotToTrack = ['VueConfirmDialog'];

const initialStateNewFields = () => ({
  field_count: 0,
  total_hectares: 0,
  crops: [],
});

const initialStateCompletedFields = () => ({
  field_count: 0,
  total_hectares: 0,
  crop_year_name: null,
  crops: [],
});

class TrackingHelper {
  constructor(instance, router, store) {
    this.instance = instance;
    this.router = router;
    this.store = store;
    this.analytics = new AnalyticsHelper(instance, router, store);
    this.clevertap = new ClevertapHelper(instance, router, store);
  }

  init() {
    this.initGenericEvents();
    this.initCustomEvents();
  }

  track(event, payload) {
    this.analytics.logEvent(event, payload);
  }

  initGenericEvents() {
    // ModalWrapper
    this.instance.$on('modal-wrapper--shown', (name) => {
      if (modalsNotToTrack.some((event) => name.indexOf(event) > -1)) {
        return;
      }
      this.track('modal_show', { name });
    });
    this.instance.$on('modal-wrapper--hidden', (name) => {
      if (modalsNotToTrack.some((event) => name.indexOf(event) > -1)) {
        return;
      }
      this.track('modal_hide', { name });
    });

    // TheSatellite
    this.instance.$on('the-satellite--shown', (name) => {
      this.track('satellite_show', { name });
    });
    this.instance.$on('the-satellite--expanded', (name) => {
      this.track('satellite_expand', { name });
    });
    this.instance.$on('the-satellite--shrunk', (name) => {
      this.track('satellite_shrink', { name });
    });
    this.instance.$on('the-satellite--hidden', (name) => {
      this.track('satellite_hide', { name });
    });

    // Button Clicks
    this.instance.$on('button--click', (name) => {
      this.track('button_click', { name });
    });

    // Page Views
    this.router.afterEach(({ name, path }) => {
      this.track('page_view', { name, path });
    });
  }

  initCustomEvents() {
    this.newFields = initialStateNewFields();
    this.completedFields = initialStateCompletedFields();

    this.instance.$on('register--success', (onboarding) => {
      const payload = {
        type: onboarding.type,
        farmingSystem: onboarding.farmingSystem,
        activityTypes: Array.isArray(onboarding.activityTypes) ? onboarding.activityTypes.join(', ') : '',
        crops: Array.isArray(onboarding.crops) ? onboarding.crops.join(', ') : '',
        goals: Array.isArray(onboarding.goals) ? onboarding.goals.join(', ') : '',
      };
      if (onboarding.size != null && onboarding.size.hectares != null) {
        payload.hectares = Number(onboarding.size.hectares);
      }
      if (onboarding.size != null && onboarding.size.employees != null) {
        payload.employees = Number(onboarding.size.employees);
      }
      if (onboarding.size != null && onboarding.size.customers != null) {
        payload.customers = Number(onboarding.size.customers);
      }
      this.track('registration_completed', payload);
    });

    this.store.subscribe(({ type, payload }) => {
      if (type === 'fields/insert') {
        this.onFieldInsert(payload);
      } else if (type === 'fields/updateEntry') {
        this.onFieldUpdate(payload);
      }
    });

    this.store.subscribeAction({
      before: ({ type, payload }) => {
        if (type === 'fields/updateAndSyncEntry') {
          this.onBeforeUpdateField(payload);
        }
      },
    });
  }

  onFieldInsert({ entry }) {
    if (entry.name == null) {
      return;
    }
    clearTimeout(this.insertTimeout);

    this.newFields.field_count += 1;
    if (entry.fieldSize > 0) {
      this.newFields.total_hectares += entry.fieldSize;
    }
    if (
      entry.cropId != null &&
      this.store.state.products.crops.data[entry.cropId] != null &&
      !this.newFields.crops.includes(this.store.state.products.crops.data[entry.cropId].name)
    ) {
      this.newFields.crops.push(this.store.state.products.crops.data[entry.cropId].name);
    }

    this.insertTimeout = setTimeout(() => this.logNewFields(), 300);
  }

  onFieldUpdate({ entry }) {
    if (entry.storeStatus === ENTRY_NEW) {
      this.onFieldInsert({ entry });
    }
  }

  logNewFields() {
    if (this.newFields.field_count < 1) {
      return;
    }
    this.track('fields_created', {
      ...this.newFields,
      crops: this.newFields.crops.join(', '),
    });
    this.newFields = initialStateNewFields();
  }

  onBeforeUpdateField({ entry }) {
    if (
      entry.status !== 'completed' ||
      this.store.state.fields.data[entry.id] == null ||
      this.store.state.fields.data[entry.id].status === 'completed'
    ) {
      return;
    }
    clearTimeout(this.completeTimeout);

    const field = { ...this.store.state.fields.data[entry.id], ...entry };
    this.completedFields.field_count += 1;
    if (field.fieldSize > 0) {
      this.completedFields.total_hectares += field.fieldSize;
    }
    this.completedFields.crop_year_name = this.store.state.auth.currentProcessOrderName;
    if (
      field.cropId != null &&
      this.store.state.products.crops.data[field.cropId] != null &&
      !this.completedFields.crops.includes(this.store.state.products.crops.data[field.cropId].name)
    ) {
      this.completedFields.crops.push(this.store.state.products.crops.data[field.cropId].name);
    }

    this.completeTimeout = setTimeout(() => this.logCompletedFields(), 300);
  }

  logCompletedFields() {
    if (this.completedFields.field_count < 1) {
      return;
    }
    this.track('crop_year_completed', {
      ...this.completedFields,
      crops: this.completedFields.crops.join(', '),
    });
    this.completedFields = initialStateCompletedFields();
  }
}

export default TrackingHelper;
