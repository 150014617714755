import { Module } from 'vuex';

import productsModuleFactory from '@/products/store/productsModuleFactory';
import { ProductsModuleState } from '@/products/store/productsModuleFactory/types';
import { Product, ProductCategoryType } from '@/shared/api/rest/models';
import { Pesticide } from '@/shared/api/rest/models/products/pesticide';
import { RootState } from '@/store/types';

type ProductWithPesticide = Product & { pesticide: Pesticide };

const vuexModule: Module<ProductsModuleState<ProductWithPesticide>, RootState> = productsModuleFactory<
  ProductWithPesticide,
  Pesticide
>(ProductCategoryType.HERBIZIDE, ['pesticide']);

export default vuexModule;
