<template>
  <ThePageSkeleton class="page-register" scroll-y>
    <WelcomeContentContainer>
      <TopContentContainer>
        <FontAwesomeIcon v-if="fetching" class="text-medium mb-4" size="3x" spin :icon="['fal', 'spinner-third']" />
        <span v-if="fetching">{{ $t('Dein Profil wird für dich angepasst!') }}</span>
        <span v-else>{{ $t('Fast am Ziel!') }}</span>
      </TopContentContainer>
      <MaxWidthContainer class="mt-auto pb-0" size="lg">
        <WelcomeTitle centered>
          {{ $t('Lass uns zusammen arbeiten!') }}
        </WelcomeTitle>
        <p>{{ $t('Wir fühlen uns dazu verpflichtet dir maßgeschneiderte Unterstützung zu bieten!') }}</p>
      </MaxWidthContainer>
      <MaxWidthContainer class="mb-auto" left>
        <FormFieldInput
          v-model="tel"
          ref="phone"
          class="w-100"
          name="phone"
          type="tel"
          variant="vertical-lg"
          :label="$t('Telefonnummer')"
          :placeholder="$t('Telefonnummer')"
        />
        <FormCheckbox class="text-left" size="lg" :checked="true" :disabled="true">
          {{ $t('Ich verstehe, Services & Support erfolgen per E-Mail.') }}
        </FormCheckbox>
        <FormCheckbox v-model="newsletter" class="text-left" size="lg">
          {{ $t('Ja, ich will den FARMDOK Newsletter bekommen!') }}
        </FormCheckbox>
        <Button type="submit" variant="primary" size="lg" block :disabled="fetching" @click="next">
          <FontAwesomeIcon v-if="fetching" icon="circle-notch" class="text-white" spin />
          <span v-else>{{ $t('Weiter') }}</span>
        </Button>
      </MaxWidthContainer>
    </WelcomeContentContainer>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';

import TopContentContainer from './components/TopContentContainer.vue';
import WelcomeContentContainer from './components/WelcomeContentContainer.vue';
import WelcomeTitle from './components/WelcomeTitle.vue';

library.add(faCircleNotch, faSpinnerThird);

export default {
  name: 'PageRegisterSupport',
  components: {
    FormCheckbox,
    ThePageSkeleton,
    FormFieldInput,
    WelcomeContentContainer,
    MaxWidthContainer,
    TopContentContainer,
    WelcomeTitle,
    Button,
  },
  data() {
    return {
      fetching: true,
      tel: null,
      newsletter: false,
    };
  },
  computed: {
    currentCompany() {
      return this.$store.state.auth.currentCompanies[0];
    },
  },
  async mounted() {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 1500));
    this.fetching = false;
  },
  methods: {
    next() {
      this.$store.dispatch('auth/updateAndSyncUser', {
        newsletter: this.newsletter,
      });
      this.$store.dispatch('auth/updateAndSyncCurrentCompany', {
        id: this.currentCompany.id,
        data: { tel: this.tel },
      });
      this.$router.push({ name: 'register-check-email' });
    },
  },
};
</script>

<style scoped></style>
