import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  ActivitiesApi,
  ActivitiesApiActivityListRequest,
  Activity,
  ActivityBase,
  ActivityDelete200Response,
} from 'farmdok-rest-api';
import chunk from 'lodash.chunk';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import removableData from '@/shared/mixins/store/removableData/index';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import syncableData from '@/shared/mixins/store/syncableData/index';
import { SubscribableDataState, SyncableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

import ActivitySyncService from '../services/ActivitySyncService';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<Activity>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Activity>> {
  const processOrderIds = context.rootGetters['auth/currentProcessOrderIds'] as string[];

  const emptyFakeResponse: ApiResponse<Activity> = {
    version: '2.0',
    status: 'success',
    data: [],
  };

  if (processOrderIds?.length === 0) {
    return emptyFakeResponse;
  }

  const { activitiesApi } = Vue.prototype.$api as Api;

  const apiResponses = await Promise.all(
    processOrderIds.map((processOrderId) => {
      const requestParameters: ActivitiesApiActivityListRequest = { processOrderId, deleted: false };
      return loadAllPages<ActivitiesApi, Activity>(activitiesApi, 'activityList', requestParameters, axiosOptions);
    }),
  );

  const apiResponse = mergeApiResponses(apiResponses);

  return apiResponse;
}

async function fetchByIds(
  ids: string[],
  context: ActionContext<SubscribableDataState<Activity>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Activity>> {
  const { activitiesApi } = Vue.prototype.$api as Api;
  const { data } = await activitiesApi.activityObjects({ objectIds: ids.join(',') }, axiosOptions);

  return data as ApiResponse<Activity>;
}

async function updateEntries(
  entries: Activity[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SyncableDataState<Activity>, RootState>,
) {
  const syncableActivities = ActivitySyncService.toSyncableActivities(entries);

  const { activitiesApi } = Vue.prototype.$api as Api;
  const { data } = await activitiesApi.activityPut({
    activityPutRequest: { data: syncableActivities },
  });

  return data;
}

export async function patchEntry(entry: ActivityBase): Promise<ApiResponse<Activity>> {
  const { activitiesApi } = Vue.prototype.$api as Api;
  const { data } = await activitiesApi.activityPatch({
    activityPatchRequest: { data: [entry] },
  });

  return data;
}

function toApiResponse(response: ActivityDelete200Response): ApiResponse<Activity> {
  return {
    ...response,
    data: response.data || [],
  } as ApiResponse<Activity>;
}

async function removeEntries(entries: Activity[]): Promise<ApiResponse<Activity>> {
  const { activitiesApi } = Vue.prototype.$api as Api;

  const activityIds = entries.map((entry: Activity) => entry.id);
  const activityIdsInBlocks = chunk<string>(activityIds, 100);
  const promises = activityIdsInBlocks.map((block: string[]) =>
    activitiesApi
      .activityDelete({ objectIds: block.join(',') })
      .then((response: AxiosResponse<ActivityDelete200Response>) => response.data)
      .then((response: ActivityDelete200Response) => toApiResponse(response)),
  );

  const responses = await Promise.all(promises);
  return mergeApiResponses(responses);
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
export const syncableStore = syncableData(updateEntries);
export const removableStore = removableData(removeEntries);
// TODO restorableData
