import { ENTRY_NEW } from '@/shared/constants';

/**
 * ATTENTION, USE WITH CARE!
 * Always use this mixin together with shared/handsontable/mixins/containers/tableContainerBase.
 * This mixin maps the current store companies to Dropdown items.
 *
 * columnKeysToStore:
 * The key of the company column
 *
 * @param {string} columnKey
 * @return Object
 */
export default function columnDropdownCompanies(columnKey) {
  return {
    data() {
      return {
        columnSettingsMiddleware: [],
      };
    },
    created() {
      this.columnSettingsMiddleware.push(this.columnDropdownCompaniesColumnSettings);
    },
    methods: {
      columnDropdownCompaniesCells(physicalRow, physicalCol, props) {
        if (
          this.tableSettings == null ||
          this.tableSettings.columns == null ||
          this.tableSettings.columns[physicalCol].key !== columnKey
        ) {
          return props;
        }
        const guid = this.$refs.table.physicalRowToGuid(physicalRow);
        if (this.tableData == null || this.tableData[guid] == null || this.tableData[guid].storeStatus !== ENTRY_NEW) {
          return props;
        }
        return {
          ...props,
          readOnly: false,
        };
      },
      columnDropdownCompaniesColumnSettings(columns) {
        return columns.map((column) => {
          if (column.key !== columnKey) {
            return column;
          }
          return {
            ...column,
            getRendererValue: (context) => this.columnDropdownCompaniesGetRendererValue(context),
            getItems: () => this.columnDropdownCompaniesGetItems(),
          };
        });
      },
      columnDropdownCompaniesGetRendererValue({ value }) {
        if (value == null) {
          return '';
        }
        let text = '';
        Object.values(this.$store.state.auth.companiesById).some((company) => {
          if (company.id === value) {
            text = company.abbreviation;
            return true;
          }
          return false;
        });
        return text;
      },
      async columnDropdownCompaniesGetItems() {
        return [
          {
            name: this.$t('Betriebe'),
            id: 'companies',
            items: this.$store.state.auth.currentCompanies.map((company) => ({
              id: company.id,
              name: company.abbreviation,
            })),
            sort: true,
          },
        ];
      },
    },
  };
}
