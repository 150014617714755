<template>
  <ThePageSkeleton
    :page-feature-title="$router.currentRoute.meta.label"
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar>
        <template #right="{ contentbarLarge }">
          <SearchBar class="ml-4" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <CompanySwitch class="ml-4" :slim="!contentbarLarge" />
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <div class="my-contracts__card-container">
        <CardContract
          v-for="contract in contractsComputed"
          :key="`card-contract-${contract.id}`"
          :contract="contract"
          :pageFeaturePermissions="currentCompaniesHaveFeature"
          @view="viewContract(contract)"
          @send="sendContract(contract)"
          @import="importContract(contract)"
        />
        <CardContract
          v-for="contract in unsignedContracts"
          :key="`card-contract-unsigned-${contract.id}`"
          :contract="contract"
          @import="importUnsignedContract(contract)"
        />
        <p
          v-if="contractsComputed.length === 0 && $store.state.digitalContracting.creating == null"
          class="my-contracts__no-content"
        >
          {{ noContractMessage }}
          <br />
          <BButton variant="link" @click="$router.push({ name: 'digital-contracting/open-calls' })">{{
            $t('Zu den offenen Verträgen')
          }}</BButton>
        </p>
      </div>
      <ModalImportHarvestDataFromUnsignedContractContainer ref="modal" :partner="selectedPartner" />
    </template>
  </ThePageSkeleton>
</template>

<script>
import { mapGetters } from 'vuex';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import CardContract from './components/CardContract.vue';
import ModalImportHarvestDataFromUnsignedContractContainer from './containers/ModalImportHarvestDataFromUnsignedContractContainer.vue';
import filterContracts from './mixins/filterContracts';

export default {
  name: 'PageMyContracts',
  components: {
    ModalImportHarvestDataFromUnsignedContractContainer,
    CardContract,
    TheContentbar,
    ThePageSkeleton,
    CompanySwitch,
    SearchBar,
  },
  mixins: [filterContracts],
  props: {
    noCompanySwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPartner: '',
      storeUnsubscribe: null,
    };
  },
  computed: {
    ...mapGetters({
      contracts: 'digitalContracting/data',
      contractsUnsignedExpired: 'digitalContracting/unsignedExpired',
    }),
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_DIGITAL_CONTRACTING);
    },
    contractsComputed() {
      if (this.$store.state.digitalContracting.fetching) {
        return [
          { fetching: true, id: 'fetching_1' },
          { fetching: true, id: 'fetching_2' },
          { fetching: true, id: 'fetching_3' },
        ];
      }
      const contracts = Object.values(this.contracts).filter((contract) => contract.contractCompanyId != null);
      return this.filterContracts(contracts);
    },
    unsignedContracts() {
      const dummyContractsByPartner = {};
      this.contractsUnsignedExpired.forEach((contract) => {
        if (dummyContractsByPartner[contract.partner] == null) {
          dummyContractsByPartner[contract.partner] = {
            ...contract,
            isGhost: true,
          };
        }
      });
      return Object.values(dummyContractsByPartner);
    },
    noContractMessage() {
      if (this.filterString.length === 0) {
        return this.$t('Du hast noch keine Kontrakte angenommen.');
      }
      return this.$t('Keine Kontrakte vorhanden.');
    },
  },
  created() {
    this.$store.dispatch('digitalContracting/subscribe');
    this.storeUnsubscribe = this.$store.subscribe(({ type }) => {
      if (type === 'afterResetData') {
        this.$store.dispatch('digitalContracting/subscribe');
      }
    });
  },
  beforeDestroy() {
    this.storeUnsubscribe();
  },
  methods: {
    sendContract(contract) {
      this.$store.commit('digitalContracting/setContracting', contract);
      this.$router.push({ name: 'digital-contracting/contracting-resend', params: contract });
    },
    viewContract(contract) {
      this.$router.push({ name: 'digital-contracting/view-contract', params: contract });
    },
    importContract(contract) {
      this.$router.push({ name: 'digital-contracting/import-contract', params: contract });
    },
    importUnsignedContract(contract) {
      this.selectedPartner = contract.partner;
      // give the modal time to update computed props
      this.$nextTick(this.$refs.modal.show);
    },
  },
};
</script>

<style scoped>
.my-contracts__card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  height: auto;
  max-height: 100%;
  padding: 12px;
  overflow-y: auto;
}

.my-contracts__no-content {
  margin: 12px;
}
</style>
