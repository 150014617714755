<template>
  <WelcomeContentContainer class="step-welcome" cta-container-transparent>
    <template #default>
      <TopContentContainer />
      <MaxWidthContainer class="step-welcome__logo-container mt-auto" size="lg">
        <LogoAuthPages />
        <WelcomeTitle centered>
          {{ $t('Verwirkliche deinen Erfolg am Feld!') }}
        </WelcomeTitle>
        <p>{{ $t('Von der Aussaat bis zur Ernte, alles über deine Felder an einem Ort.') }}</p>
      </MaxWidthContainer>
      <MaxWidthContainer class="mb-auto">
        <Button variant="primary" size="lg" block class="m-0" @click="$emit('next')">
          {{ $t('Jetzt starten') }}
        </Button>
        <ButtonLink class="mt-1 py-3" :to="{ name: 'login' }">
          {{ $t('Ich habe bereits einen Account') }}
        </ButtonLink>
      </MaxWidthContainer>
    </template>
  </WelcomeContentContainer>
</template>

<script>
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';

import LogoAuthPages from './LogoAuthPages.vue';
import TopContentContainer from './TopContentContainer.vue';
import WelcomeContentContainer from './WelcomeContentContainer.vue';
import WelcomeTitle from './WelcomeTitle.vue';

export default {
  name: 'StepWelcome',
  components: {
    WelcomeContentContainer,
    WelcomeTitle,
    TopContentContainer,
    MaxWidthContainer,
    LogoAuthPages,
    Button,
    ButtonLink,
  },
  mounted() {
    this.$emit('background', null);
  },
};
</script>

<style scoped>
.step-welcome__logo-container {
  margin-top: auto;
}

@media (min-width: 768px) {
  .step-welcome__logo-container {
    margin-top: 0;
  }
}
</style>
