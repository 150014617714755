
import { Activity } from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import ActivityStatusIndicator from '@/activities/components/ActivityStatusIndicator.vue';
import { Getters as MoveActivityGetters } from '@/activities/modals/moveActivity/store/getters';
import { ActivitiesState } from '@/activities/store/types';
import { ActivityWithDisplayNames } from '@/activities/types';
import { convertToActivitiesWithDisplayNames } from '@/activities/utils/convertToActivityWithDisplayName';
import { Data } from '@/shared/mixins/store/types';

import { MoveActivityParameterWithResult } from '../store/types';

export default defineComponent({
  name: 'ThirdStepContainer',
  components: {
    ActivityStatusIndicator,
  },
  computed: {
    ...mapGetters({
      activityTypes: 'activityTypes/data',
      fields: 'fields/data',
      customers: 'customers/data',
      crops: 'products/crops/data',
      processOrdersById: 'auth/processOrdersById',
    }),
    ...mapState('activities/moveActivity', [
      'selectedProcessOrderId',
      'selectedCompanyId',
      'moveActivityParametersWithResult',
    ]),
    ...mapState('activities', {
      activities: (state) => (state as ActivitiesState).data as Data<Activity>,
    }),
    ...mapState('auth', ['companiesById']),
    moveActivityParametersWithConflict(): MoveActivityParameterWithResult[] | null {
      return this.$store.getters[
        'activities/moveActivity/moveActivityParametersWithConflict'
      ] as MoveActivityGetters['moveActivityParametersWithConflict'];
    },
    moveActivityParametersWithSuccess(): MoveActivityParameterWithResult[] | null {
      return this.$store.getters[
        'activities/moveActivity/moveActivityParametersWithSuccess'
      ] as MoveActivityGetters['moveActivityParametersWithSuccess'];
    },
    successfulMovedActivitiesWithDisplayName(): ActivityWithDisplayNames[] | null {
      if (this.moveActivityParametersWithSuccess && this.moveActivityParametersWithSuccess.length > 0) {
        const successfulMovedActivities: Activity[] = this.moveActivityParametersWithSuccess.map(
          (moveActivityParameter: MoveActivityParameterWithResult) =>
            this.activities[moveActivityParameter.sourceActivityId],
        );

        return this.getActivitiesWithDisplayNames(successfulMovedActivities);
      }
      return null;
    },
    failedMovedActivitiesWithDisplayName(): ActivityWithDisplayNames[] | null {
      if (this.moveActivityParametersWithConflict && this.moveActivityParametersWithConflict.length > 0) {
        const failedMovedActivities: Activity[] = this.moveActivityParametersWithConflict.map(
          (moveActivityParameter: MoveActivityParameterWithResult) =>
            this.activities[moveActivityParameter.sourceActivityId],
        );
        return this.getActivitiesWithDisplayNames(failedMovedActivities);
      }
      return null;
    },

    cropYear(): string {
      return this.processOrdersById[this.selectedProcessOrderId]?.name ?? '';
    },
    companyName(): string {
      return this.companiesById[this.selectedCompanyId]?.name ?? '';
    },
    successTitle(): string {
      if (this.successfulMovedActivitiesWithDisplayName?.length === 1) {
        return (
          this.$t('Maßnahme erfolgreich verschoben nach {cropYear} auf {companyName}', {
            cropYear: this.cropYear,
            companyName: this.companyName,
          }) || 'Maßnahme erfolgreich verschoben'
        );
      }
      return (
        this.$t('Maßnahmen erfolgreich verschoben nach {cropYear} auf {companyName}', {
          cropYear: this.cropYear,
          companyName: this.companyName,
        }) || 'Maßnahmen erfolgreich verschoben'
      );
    },
    errorTitle(): string {
      if (this.failedMovedActivitiesWithDisplayName?.length === 1) {
        return (
          this.$t('Maßnahme konnte nicht erfolgreich verschoben werden nach {cropYear} auf {companyName}', {
            cropYear: this.cropYear,
            companyName: this.companyName,
          }) || 'Maßnahme nichterfolgreich verschoben'
        );
      }
      return (
        this.$t('Maßnahmen konnten nicht erfolgreich verschoben werden nach {cropYear} auf {companyName}', {
          cropYear: this.cropYear,
          companyName: this.companyName,
        }) || 'Maßnahmen nicht erfolgreich verschoben'
      );
    },
  },
  methods: {
    updateCompanyId(companyId: string): void {
      this.$store.commit(`activities/moveActivity/setSelectedCompanyId`, companyId);
    },
    updateProcessOrderId(processOrderId: string): void {
      this.$store.commit(`activities/moveActivity/setSelectedProcessOrderId`, processOrderId);
    },
    getActivitiesWithDisplayNames(activities: Activity[]): ActivityWithDisplayNames[] {
      return Object.values(
        convertToActivitiesWithDisplayNames(
          activities.reduce((acc, activity) => ({ ...acc, [activity.id]: activity }), {}),
          () => undefined,
          false,
          {},
          {},
          this.fields,
          this.customers,
          this.crops,
        ),
      );
    },
  },
});
