import ActivityProductService from '@/activities/services/ActivityProductService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import ProductService from '@/products/services/ProductService';
import { RootState } from '@/store/types';

import FindService from '../../../services/FindService';
import { CreateEditActivityState } from '../../types';

export default function initServices(state: CreateEditActivityState, rootState: RootState) {
  const {
    products: {
      mineralFertilizers,
      companyFertilizers,
      secondaryFertilizers,
      herbizides,
      crops,
      miscellaneous,
      harvests,
      seeds,
      otherProductsAndFertilizers,
    },
  } = rootState;
  const productService = new ProductService(
    mineralFertilizers.data,
    companyFertilizers.data,
    secondaryFertilizers.data,
    herbizides.data,
    crops.data,
    miscellaneous.data,
    harvests.data,
    seeds.data,
    otherProductsAndFertilizers.data,
  );
  const activityProductService = new ActivityProductService(
    rootState.units.data,
    productService,
    calculateAmountsAndUnits,
  );
  const findService = new FindService(state.activities, activityProductService);

  return {
    activityProductService,
    findService,
  };
}
