import { AxiosResponse } from 'axios';
import { ActivitiesGpsLog200Response } from 'farmdok-rest-api';
import Vue from 'vue';

import { TimestampsGps } from '@/activities/gpsLog/store/types';
import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';

function emptyFakeResponse(): ApiResponse<TimestampsGps> {
  return {
    version: '2.0',
    status: 'success',
    data: [],
  };
}

export async function fetchAll(): Promise<ApiResponse<TimestampsGps>> {
  throw new Error('not implemented yet!');
}

function toApiResponse(response: ActivitiesGpsLog200Response, activityId: string): ApiResponse<TimestampsGps> {
  return {
    ...response,
    data: [{ id: activityId, ...response.data }],
  } as ApiResponse<TimestampsGps>;
}

async function fetchByIds(ids: string[]): Promise<ApiResponse<TimestampsGps>> {
  if (ids.length === 0) {
    return emptyFakeResponse();
  }

  const { activitiesApi } = Vue.prototype.$api as Api;
  const promises: Promise<ApiResponse<TimestampsGps>>[] = ids.map((activityId: string) =>
    activitiesApi
      .activitiesGpsLog({ activityId, dataTypes: 'time' })
      .then((response: AxiosResponse<ActivitiesGpsLog200Response>) => response.data)
      .then((response: ActivitiesGpsLog200Response) => toApiResponse(response, activityId)),
  );
  const responses: ApiResponse<TimestampsGps>[] = await Promise.all(promises);
  return mergeApiResponses(responses);
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
