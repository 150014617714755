<template>
  <Card
    v-if="!isAgranaGhostCard"
    :isGhost="contract.isGhost"
    :fetching="contract.fetching"
    :imageTitleUrl="imageTitleUrl"
    :imageCompanyLogoUrl="imagePartnerLogoUrl"
    :headline="headline"
    :subTitle="subTitle"
    :description="description"
    :primaryButtonLabel="primaryButtonOptions.label"
    :primaryButtonDisabled="primaryButtonOptions.disabled"
    @onPrimaryClick="primaryButtonOptions.action"
    :secondaryButtonLabel="secondaryButtonOptions.label"
    :secondaryButtonDisabled="secondaryButtonOptions.disabled"
    @onSecondaryClick="secondaryButtonOptions.action"
  />
</template>

<script>
import moment from 'moment';

import numbro from '@/initNumbro';
import Card from '@/shared/components/cards/Card.vue';
import { fileNameToImageUrl, isValidUrl } from '@/shared/modules/urlHelpers';

export default {
  name: 'CardContract',
  components: {
    Card,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    managed: {
      type: Boolean,
      default: false,
    },
    pageFeaturePermissions: {
      type: Object,
      required: false,
    },
  },
  computed: {
    imageTitleUrl() {
      if (isValidUrl(this.contract.imageTitle)) {
        return this.contract.imageTitle;
      }
      return fileNameToImageUrl(this.contract.imageTitle, 'contracting');
    },
    imagePartnerLogoUrl() {
      if (isValidUrl(this.contract.imagePartnerLogo)) {
        return this.contract.imagePartnerLogo;
      }
      return fileNameToImageUrl(this.contract.imagePartnerLogo, 'contracting');
    },
    headline() {
      if (this.contract.isGhost) {
        return this.$t('Erntedatenimport von {partner}', this.contract);
      }
      return this.contract.name;
    },
    subTitle() {
      if (this.contract.periodStart == null || this.contract.periodEnd == null) {
        return '';
      }
      const periodStart = moment.unix(this.contract.periodStart).format('L');
      const periodEnd = moment.unix(this.contract.periodEnd).format('L');
      return this.$t('Zeitraum von {periodStart} bis {periodEnd}', {
        periodStart,
        periodEnd,
      });
    },
    isExpired() {
      return this.contract.periodEnd * 1000 < new Date();
    },
    description() {
      const params = {
        number: this.contract.contractFieldCount,
        area: numbro(this.contract.contractFieldSizeSum).format(),
        partner: this.contract.partner,
        processOrderName: this.contract.processOrderName,
      };
      if (this.contract.isGhost) {
        return this.$t('Erntedaten für Flächen laden, die nicht über FARMDOK an {partner} gemeldet wurden.', params);
      }
      if (this.contract.contractCompanyId == null || typeof this.contract.contractFieldCount !== 'number') {
        return this.contract.description;
      }
      if (this.contract.contractFieldCount < 1) {
        return this.$t('Erntedaten für {processOrderName} von {partner} importieren.', params);
      }
      if (this.contract.contractFieldCount === 1) {
        return this.$t('1 Fläche ({area} ha) an {partner} gesendet.', params);
      }
      return this.$t('{number} Flächen ({area} ha) an {partner} gesendet.', params);
    },

    primaryButtonOptions() {
      const btnDisabled = this.pageFeaturePermissions?.readonly && this.$route.name.includes('open-calls');
      if (this.contract.contractCompanyId != null && !this.managed) {
        return {
          label: this.$t('Flächen ansehen'),
          disabled: btnDisabled,
          action: () => this.$emit('view'),
        };
      }

      if (this.managed) {
        return {
          label: this.$t('Zum Vertrag'),
          disabled: btnDisabled,
          action: () => this.$emit('view'),
        };
      }

      if (!this.isExpired && !this.managed && this.contract.contractCompanyId == null) {
        return {
          label: this.$t('Flächen senden'),
          disabled: btnDisabled,
          action: () => this.$emit('send'),
        };
      }

      return {
        label: null,
        disabled: btnDisabled,
        action: () => null,
      };
    },

    secondaryButtonOptions() {
      if (this.isExpired && !this.managed) {
        return {
          label: this.$t('Erntedaten laden'),
          disabled: this.pageFeaturePermissions?.readonly ?? false,
          action: () => this.$emit('import'),
        };
      }

      if (!this.isExpired && !this.managed && this.contract.contractCompanyId != null) {
        return {
          label: this.$t('Erneut senden'),
          disabled: this.pageFeaturePermissions?.readonly ?? false,
          action: () => this.$emit('send'),
        };
      }
      return {
        label: null,
        disabled: this.pageFeaturePermissions?.readonly ?? false,
        action: () => null,
      };
    },
    isAgranaGhostCard() {
      return this.contract.partner === 'AGRANA' && this.contract.isGhost;
    },
  },
};
</script>
