import Handsontable from 'handsontable';

import isOutsideDataCellClick from '../../utils/isOutsideDataCellClick';

function handleKeyClickFactory(hot: Handsontable | null) {
  function handleKeyClick(event: KeyboardEvent) {
    if (!hot) return;

    if (event.key === 'Enter') {
      const selected = hot.getSelected();
      if (!selected) return;
      const visualRow = selected[0][0];
      const visualColumn = selected[0][1];
      if (isOutsideDataCellClick(visualRow, visualColumn)) return;
      const cellMeta = hot.getCellMeta(visualRow, visualColumn);

      if (cellMeta.type === 'farmdok.expand') {
        const selectValue = hot.getDataAtCell(visualRow, visualColumn);
        hot.setDataAtRowProp(visualRow, 'expand', !selectValue);
      }
    }
  }

  return handleKeyClick;
}

function showHideRowsFactory(hot: Handsontable | null) {
  function showHideRows(changes: Array<Handsontable.CellChange | null>, source?: Handsontable.ChangeSource) {
    if (!hot) return;
    if (source === 'edit') {
      changes?.forEach((change) => {
        if (!change) return;

        const [row, prop, oldValue, newValue] = change;

        if (prop === 'expand' && oldValue !== newValue) {
          const hiddenRowsPlugin = hot.getPlugin('hiddenRows');
          if (!hiddenRowsPlugin) throw new Error('hiddenRows plugin must be activated for ExpandRenderer');

          const hiddenSiblingRows = calcHiddenRowsNextToCurrentRow(row, hot);
          if (newValue) {
            hiddenRowsPlugin.showRows(hiddenSiblingRows);
          } else {
            hiddenRowsPlugin.hideRows(hiddenSiblingRows);
          }
        }
      });
    }
  }

  return showHideRows;
}

function calcHiddenRowsNextToCurrentRow(currentRow: number, hot: Handsontable): number[] {
  const hiddenRows: number[] = [];

  const rowData = hot.getDataAtRow(currentRow);
  const rowId = rowData[0];

  for (let nextRow = currentRow + 1; nextRow < hot.countRows(); nextRow += 1) {
    const nextRowData = hot.getDataAtRow(nextRow);
    const nextRowId = nextRowData[0];

    if (nextRowId === rowId) {
      hiddenRows.push(nextRow);
    } else {
      break;
    }
  }

  return hiddenRows;
}

// add these hooks to your handsontable instance
const hooks = {
  beforeChange: { showHideRowsFactory },
  beforeKeyDown: { handleKeyClickFactory },
};

export default hooks;
