
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp, faGripLines } from '@fortawesome/pro-solid-svg-icons';
import { PropType, defineComponent } from 'vue';
import Draggable from 'vuedraggable';

import { ColumnForPageSettings } from '@/shared/components/modalPageSettingsConverter';

import ModalWrapper from './ModalWrapper.vue';
import FormCheckbox from './form/FormCheckbox.vue';

library.add(faGripLines, faChevronUp, faChevronDown);

/**
 * Shows all the given columns.
 * The column order can be rearranged by drag-and-drop.
 * Displays a checkbox for every column. There the user can change the visibility.
 * The following options are handles:
 *   - 'hiddenInPageSettings' this column will not be displayed in the settings
 *   - 'hiddenPerDefault' if the user clicks on "reset" this columns visibility will be "not-checked"
 *   - 'lockedVisibility' the checkbox for the visibility will be disabled for this column
 *   - 'fixed' all fixed columns will be on top and can not be moved using drag and drop
 *   - 'visible' the initial state of the visibility checkbox
 *
 * Call save() to get the column data as soon as the user is done.
 *
 * @category Shared
 * @subcategory Page
 * @component
 */
export default defineComponent({
  name: 'ModalPageSettings',
  components: { Draggable, ModalWrapper, FormCheckbox },
  props: {
    columns: {
      type: Array as PropType<ColumnForPageSettings[] | null>,
      default: null,
      validator(columns) {
        if (columns == null) {
          return true;
        }
        if (!Array.isArray(columns)) {
          return false;
        }
        return columns.every((column) => {
          if (column.hiddenInPageSettings) {
            return true;
          }
          return (
            column != null &&
            typeof column.physicalColumn === 'number' &&
            typeof column.visualColumn === 'number' &&
            (column.hiddenInPageSettings || (column.header != null && typeof column.header.title === 'string')) &&
            (typeof column.key === 'string' || typeof column.data === 'string') &&
            typeof column.fixed === 'boolean' &&
            typeof column.visible === 'boolean'
          );
        });
      },
    },
    pageSettings: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      columnDescription: this.$t(
        'Deaktiviere das Kontrollkästchen um Spalten zu verstecken. Zum Ändern der Spaltenreihenfolge in der Tabelle klicke auf das Feld und ziehe es an die gewünschte Stelle.',
      ),
      columnsFixed: null,
      columnsFixedHidden: null,
      columnsMovable: null,
      columnsMovableHidden: null,
    } as {
      columnDescription: string;
      columnsFixed: ColumnForPageSettings[] | null;
      columnsFixedHidden: ColumnForPageSettings[] | null;
      columnsMovable: ColumnForPageSettings[] | null;
      columnsMovableHidden: ColumnForPageSettings[] | null;
    };
  },
  computed: {
    title() {
      if (this.columns != null) {
        return this.$t('Spaltenreihenfolge ändern');
      }
      return 'PageSettings not implemented yet!';
    },
  },
  methods: {
    async show(): Promise<{ columns: ColumnForPageSettings[] } | null> {
      this.$nextTick(this.columnsInit);
      // @ts-ignore
      const ok = await this.$refs.modalWrapper.show();
      if (!ok) {
        return null;
      }
      if (
        !this.columnsFixed ||
        !this.columnsFixedHidden ||
        !this.columnsMovable ||
        !this.columnsMovableHidden ||
        !this.columns
      ) {
        return null;
      }

      const settings = {
        columns: [
          ...this.columnsFixedHidden,
          ...this.columnsFixed,
          ...this.columnsMovable,
          ...this.columnsMovableHidden,
        ] as ColumnForPageSettings[],
      };

      settings.columns = this.updateVisualColumnIndices(settings.columns);
      return settings;
    },
    // region - private methods
    columnsInit() {
      if (this.columns == null) return;

      this.columnsInitFixed();
      this.columnsInitMovable();
    },
    columnsInitFixed() {
      if (this.columns == null) return;

      this.columnsFixed = this.columns
        .filter((column) => !column.hiddenInPageSettings && column.fixed)
        .map((column) => ({ ...column }));

      this.columnsFixedHidden = this.columns
        .filter((column) => column.hiddenInPageSettings && column.fixed)
        .map((column) => ({ ...column }));
    },
    columnsInitMovable() {
      if (this.columns == null) return;
      this.columnsMovable = this.columns
        .filter((column) => !column.hiddenInPageSettings && !column.fixed)
        .map((column) => ({ ...column }))
        .sort((a, b) => a.visualColumn - b.visualColumn);

      this.columnsMovableHidden = this.columns
        .filter((column) => column.hiddenInPageSettings && !column.fixed)
        .map((column) => ({ ...column }))
        .sort((a, b) => a.visualColumn - b.visualColumn);
    },
    columnsReset() {
      if (this.columns == null) return;

      this.columnsInitFixed();
      this.columnsResetMovable();
    },
    columnsResetMovable() {
      if (this.columns == null) return;

      this.columnsMovable = this.columns
        .filter((column) => !column.hiddenInPageSettings && !column.fixed)
        .map((column) => ({ ...column }))
        .sort((a, b) => a.physicalColumn - b.physicalColumn);

      this.columnsMovableHidden = this.columns
        .filter((column) => column.hiddenInPageSettings && !column.fixed)
        .map((column) => ({ ...column }))
        .sort((a, b) => a.physicalColumn - b.physicalColumn);
    },
    columnTitle(column: ColumnForPageSettings) {
      if (column.fixed && column.lockedVisibility) {
        return this.$t('Diese Eigenschaft ist ein Pflichtfeld und kann nicht deaktiviert oder verschoben werden.');
      }
      if (column.fixed) {
        return this.$t('Diese Eigenschaft kann nicht verschoben werden.');
      }
      if (column.lockedVisibility) {
        return this.$t('Diese Eigenschaft ist ein Pflichtfeld und kann nicht deaktiviert werden.');
      }
      return '';
    },
    updateVisualColumnIndices(columns: ColumnForPageSettings[]) {
      return columns.map((column, index) => ({
        ...column,
        visualColumn: index,
      }));
    },
    // endregion
  },
  expose: ['show'],
});
