<template>
  <div class="import-file-upload">
    <MaxWidthContainer size="xl">
      <div class="import__intro">
        <h2 class="h1 my-3">{{ $t('Datei hochladen') }}</h2>
        <p>
          {{ $t('Anleitung: Woher bekomme ich eine Datei mit meinen Feldern?') }}
          <BLink @click="infoModalVisible = true">
            {{ $t('Klicke hier für mehr Infos ...') }}
          </BLink>
        </p>
      </div>
      <form
        class="import-file-upload__form"
        method="post"
        action=""
        enctype="multipart/form-data"
        :class="{ 'import-file-upload__form--is-dragover': isDragover }"
        @drag.prevent
        @dragstart.prevent
        @dragend.prevent="isDragover = false"
        @dragover.prevent="isDragover = true"
        @dragenter.prevent="isDragover = true"
        @dragleave.prevent="isDragover = false"
        @drop.prevent="onFileDrop"
        @click="onFormClick"
      >
        <label class="position-absolute">
          <input
            ref="input"
            class="import-file-upload__input"
            type="file"
            name="file[]"
            multiple
            @change="onFileChange"
          />
        </label>
        <div v-if="fileGroups.length === 0" class="import-file-upload__form-empty-content">
          <FontAwesomeIcon class="mb-3" icon="cloud-upload" size="3x" />
          <span class="import-file-upload__label-text-one">{{ $t('ZIEHE DIE DATEI HIER HER') }}</span>
          <span>
            {{ labelUploadInfoParts[0] }}
            <BLink>
              {{ labelUploadInfoParts[1] }}
            </BLink>
            {{ labelUploadInfoParts[2] }}
          </span>
          <span class="mt-3 text-small">{{
            $t('Es können Dateien in den Formaten Shape, XML, KML, XLS, XLSX und CSV importiert werden.')
          }}</span>
        </div>
        <div v-else class="import-file-upload__form-files-content">
          <ul class="import-file-upload__file-groups-list">
            <li
              v-for="fileGroup in fileGroups"
              class="import-file-upload__file-group"
              :class="{ 'import-file-upload__file-group--selected': selectedFileGroupName === fileGroup.key }"
              :key="fileGroup.key"
              @click="$emit('update:selectedFileGroupName', fileGroup.key)"
            >
              <div v-if="fileGroup.files.length > 1" class="import-file-upload__file-group-title">
                {{ fileGroup.key }}
              </div>
              <ul class="import-file-upload__file-subgroup">
                <li
                  v-for="file in fileGroup.files"
                  :key="`${fileGroup.key}_${file.name}`"
                  class="import-file-upload__item"
                >
                  <div
                    class="import-file-upload__item-label"
                    :class="{ 'import-file-upload__filename--single': fileGroup.files.length === 1 }"
                  >
                    {{ file.name }}
                  </div>
                  <FontAwesomeIcon
                    class="import-file-upload__item-icon import-file-upload__item-icon--success"
                    :icon="['far', 'check-circle']"
                  />
                </li>
                <li
                  v-for="error in fileGroup.errors"
                  class="text-danger import-file-upload__item"
                  :key="`${fileGroup.key}_${error.key}`"
                >
                  <div class="import-file-upload__item-label">
                    {{ error.errorUserMessage[0] }}
                  </div>
                  <FontAwesomeIcon
                    class="text-danger import-file-upload__item-icon"
                    :icon="['far', 'exclamation-circle']"
                  />
                </li>
                <li
                  v-for="warning in fileGroup.warnings"
                  class="text-warning import-file-upload__item"
                  :key="`${fileGroup.key}_${warning.key}`"
                >
                  <div class="import-file-upload__item-label">
                    {{ warning.errorUserMessage[0] }}
                  </div>
                  <FontAwesomeIcon
                    class="text-warning import-file-upload__item-icon"
                    :icon="['far', 'exclamation-triangle']"
                  />
                </li>
              </ul>
            </li>
          </ul>
          <div class="import-file-upload__form-button-container">
            <span class="cursor-pointer" @click="() => $refs.input.click()">
              <FontAwesomeIcon icon="cloud-upload" class="mr-2" />
              {{ $t('Weitere Dateien durch Ablegen hinzufügen') }}
            </span>
            <div>
              <Button outline :disabled="fetching" @click="onReset">
                {{ $t('Alle Dateien entfernen') }}
              </Button>
              <Button :disabled="!importNextEnabled" :fetching="fetching" @click="onNext">
                {{ $t('Weiter') }}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <p class="mt-4">
        {{ labelCsvDownloadLinkParts[0] }}
        <BLink download :href="`${baseUrl}farmdok_field_import_example.csv`">
          {{ labelCsvDownloadLinkParts[1] }}
        </BLink>
        {{ labelCsvDownloadLinkParts[2] }}
      </p>
      <ErrorUserMessage class="mt-3" :error-user-message="userErrorMessage" />
    </MaxWidthContainer>
    <ModalImportFileUploadInfo v-model="infoModalVisible" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import { baseUrl } from '@/shared/constants';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';

import ModalImportFileUploadInfo from './ModalImportFileUploadInfo.vue';

library.add(faCloudUpload, faExclamationCircle, faExclamationTriangle, faCheckCircle);

export default {
  name: 'ImportFileUpload',
  components: {
    Button,
    ErrorUserMessage,
    MaxWidthContainer,
    ModalImportFileUploadInfo,
  },
  mixins: [backendResponseErrorHelpers],
  props: {
    importNextEnabled: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    fileUploadResponseData: {
      type: Object,
      default: null,
    },
    importTargetProcessOrderId: {
      type: String,
      default: null,
    },
    selectedFileGroupName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      infoModalVisible: false,
      labelUploadInfo: this.$t('oder klicke {linkStart}Datei auswählen{linkEnd}, um eine Datei hinzuzufügen.', {
        linkStart: '#####',
        linkEnd: '#####',
      }),
      labelCsvDownloadLink: this.$t('Vorlage im Format .CSV {linkStart}herunterladen{linkEnd}', {
        linkStart: '#####',
        linkEnd: '#####',
      }),
      isDragover: false,
      uploadedFiles: null,
      baseUrl,
    };
  },
  computed: {
    labelUploadInfoParts() {
      return this.labelUploadInfo.split('#####');
    },
    labelCsvDownloadLinkParts() {
      return this.labelCsvDownloadLink.split('#####');
    },
    fileGroups() {
      if (this.uploadedFiles == null || Object.values(this.uploadedFiles).length === 0) {
        return [];
      }
      return Object.keys(this.uploadedFiles)
        .filter((key) => Array.isArray(this.uploadedFiles[key].files) && this.uploadedFiles[key].files.length > 0)
        .map((key) => {
          const errors = [];
          if (this.uploadedFiles[key].errors != null) {
            Object.keys(this.uploadedFiles[key].errors).forEach((errorKey) => {
              errors.push({ key: errorKey, ...this.uploadedFiles[key].errors[errorKey] });
            });
          }
          const warnings = [];
          if (this.uploadedFiles[key].warnings != null) {
            Object.keys(this.uploadedFiles[key].warnings).forEach((warningKey) => {
              warnings.push({ key: warningKey, ...this.uploadedFiles[key].warnings[warningKey] });
            });
          }
          return {
            key,
            files: this.uploadedFiles[key].files,
            errors,
            warnings,
          };
        });
    },
    fileGroupsByKey() {
      return this.fileGroups.reduce(
        (fileGroupsByKey, fileGroup) => ({
          ...fileGroupsByKey,
          [fileGroup.key]: fileGroup,
        }),
        {},
      );
    },
  },
  watch: {
    selectedFileGroupName() {
      this.updateImportNextEnabled();
    },
    fileGroups(newVal) {
      if (!Array.isArray(newVal) || newVal.length === 0) {
        this.$emit('update:selectedFileGroupName', null);
        return;
      }
      if (typeof this.selectedFileGroupName === 'string' && this.selectedFileGroupName.length > 0) {
        return;
      }
      this.$emit('update:selectedFileGroupName', newVal[0].key);
      this.updateImportNextEnabled();
    },
  },
  async mounted() {
    this.updateImportNextEnabled();
    this.$emit('update:fetching', true);

    let responseData = this.fileUploadResponseData;
    if (responseData == null) {
      try {
        const { data } = await axios.get('/admin/rest/field/import_upload_files_status');
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      }
    }

    if (responseData.status !== 'success' && responseData.status !== 'partialSuccess') {
      this.setUserErrorMessageFromResponse(responseData);
      return;
    }
    if (responseData.status === 'partialSuccess') {
      this.userErrorMessage = Object.values(responseData.errors).reduce((messages, error) => {
        if (error.errorUserMessage == null) {
          return messages;
        }
        if (Array.isArray(error.errorUserMessage)) {
          return [...messages, ...error.errorUserMessage];
        }
        return [...messages, error.errorUserMessage];
      }, []);
    }
    this.uploadedFiles = responseData.data;
    this.$emit('update:fetching', false);
  },
  methods: {
    updateImportNextEnabled() {
      const importNextEnabled =
        this.selectedFileGroupName != null &&
        this.fileGroupsByKey[this.selectedFileGroupName] != null &&
        (!Array.isArray(this.fileGroupsByKey[this.selectedFileGroupName].errors) ||
          this.fileGroupsByKey[this.selectedFileGroupName].errors.length === 0);
      this.$emit('update:importNextEnabled', importNextEnabled);
    },
    onFormClick() {
      if (this.fileGroups.length === 0 && this.$refs.input != null) {
        this.$refs.input.click();
      }
    },
    onFileDrop(event) {
      this.isDragover = false;
      this.submit(event.dataTransfer.files);
    },
    onFileChange(event) {
      this.submit(event.target.files);
    },
    async submit(files) {
      if (files.length < 1) {
        return;
      }
      this.userErrorMessage = null;
      this.$emit('update:fetching', true);
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('file[]', file);
      });
      let responseData;
      try {
        const { data } = await axios.post('/admin/rest/field/import_upload_files', formData);
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      }
      if (responseData.status !== 'success' && responseData.status !== 'partialSuccess') {
        this.setUserErrorMessageFromResponse(responseData);
        this.$emit('update:fetching', false);
        return;
      }
      if (responseData.status === 'partialSuccess') {
        this.userErrorMessage = Object.values(responseData.errors).reduce((messages, error) => {
          if (error.errorUserMessage == null) {
            return messages;
          }
          if (Array.isArray(error.errorUserMessage)) {
            return [...messages, ...error.errorUserMessage];
          }
          return [...messages, error.errorUserMessage];
        }, []);
      }
      this.uploadedFiles = responseData.data;
      this.$emit('update:fetching', false);
    },
    async onReset(event) {
      event.stopPropagation();
      this.userErrorMessage = null;
      this.$emit('update:fetching', true);
      let responseData;
      try {
        const { data } = await axios.post('/admin/rest/field/import_upload_files_reset');
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      }
      if (responseData.status !== 'success') {
        this.setUserErrorMessageFromResponse(responseData);
      } else {
        this.uploadedFiles = null;
      }
      this.$emit('update:fetching', false);
    },
    onNext(event) {
      event.stopPropagation();
      this.$emit('next');
    },
    async next() {
      this.userErrorMessage = null;
      this.$emit('update:fetching', true);
      let responseData;
      try {
        const { data } = await axios.post('/admin/rest/field/import_map_data_from_uploaded_files', {
          version: '2.0',
          data: {
            mappingId: null,
            manualMapping: null,
            processOrderId: this.importTargetProcessOrderId,
            fileGroupName: this.selectedFileGroupName,
          },
        });
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      }
      this.$emit('update:fetching', false);
      if (responseData.status !== 'success') {
        this.setUserErrorMessageFromResponse(responseData);
        return false;
      }
      if (
        (!responseData.data.mappings || responseData.data.mappings.length === 0) &&
        (!responseData.data.rawData || responseData.data.rawData.length === 0)
      ) {
        this.userErrorMessage = `${this.selectedFileGroupName} ${this
          .$t(`Upload fehlgeschlagen! Die Datei scheint defekt zu sein.
           Probiere den Export aus der Quellapplikation zu einem späteren Zeitpunkt nochmal.
           Sollte das Problem weiterhin auftreten, sende die Datei bitten an den Support.`)}`;
        return false;
      }
      this.$emit('update:importData', responseData.data);
      return true;
    },
  },
};
</script>

<style scoped>
.import__intro {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.import-file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.import-file-upload__form {
  width: 100%;
  border: 1px dashed var(--gray_600);
  border-radius: 3px;
  text-align: left;
}

.import-file-upload__form--is-dragover {
  border-color: var(--primary_dark);
  background-color: var(--primary_light);
}

.import-file-upload__input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.import-file-upload__form-empty-content {
  display: flex;
  width: 100%;
  padding: var(--spacer_3);
  flex-direction: column;
  align-items: center;
}

.import-file-upload__file-groups-list {
  margin: 0;
  padding: var(--spacer_2);
  list-style: none;
  font-size: 0.857rem;
}

.import-file-upload__file-group {
  margin: var(--spacer_2);
  padding: var(--spacer_3);
  border: 1px solid var(--gray_400);
  background-color: var(--gray_100);
  cursor: pointer;
}

.import-file-upload__file-group--selected {
  border-color: var(--primary_dark);
  background-color: var(--primary_light);
}

.import-file-upload__file-group-title {
  font-weight: 600;
  margin-bottom: var(--spacer_2);
}

.import-file-upload__file-subgroup {
  margin: 0;
  padding: 0;
  list-style: none;
}

.import-file-upload__item {
  display: flex;
  margin: var(--spacer_1) 0;
}

.import-file-upload__item-label {
  flex: 1;
}

.import-file-upload__item-icon {
  font-size: 1rem;
}

.import-file-upload__item-icon--success {
  color: var(--primary_darker);
}

.import-file-upload__label-text-one {
  color: var(--primary_dark);
  font-weight: 600;
  line-height: 1.5;
}

.import-file-upload__form-button-container {
  display: flex;
  padding: var(--spacer_3);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--gray_300);
}
</style>
