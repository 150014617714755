#
<template>
  <BFormGroup
    label-class="base-form-field__label"
    :class="[`base-form-field--${variant}`, { 'base-form-field--slim-margin': slimMargin }]"
    :label-cols="variant === 'horizontal' ? 3 : null"
    :id="idFormGroup"
    :label="label"
    :label-for="idFormInputComputed"
    :description="description"
  >
    <template #label v-if="variant === 'vertical' || variant === 'vertical-lg'">
      <div v-show="!hideLabel" class="base-form-field__label-wrapper">
        <span>{{ label }}</span>
        <span
          v-if="type === 'password'"
          class="base-form-field__label-floating-text base-form-field__label-floating-text--password"
          @click="showPassword = !showPassword"
        >
          <FontAwesomeIcon class="base-form-field__label-floating-icon" :icon="['far', 'eye']" />
          {{ showPassword ? $t('Verstecken') : $t('Anzeigen') }}
        </span>
        <span v-else-if="required" class="base-form-field__label-floating-text">
          <FontAwesomeIcon
            class="base-form-field__label-floating-icon base-form-field__label-floating-icon--asterisk"
            icon="asterisk"
          />
          {{ $t('Pflichtfeld') }}
        </span>
      </div>
    </template>
    <div class="base-form-field__form-input-wrapper">
      <!--
        @slot
        Form field to be rendered.
      -->
      <slot name="default" :id="idFormInputComputed" :icon-count="iconCount" :show-password="showPassword" />
      <div
        v-if="iconCount > 0"
        class="base-form-field__icons"
        :class="variant === 'vertical-lg' ? 'base-form-field__icons--vertical-lg' : null"
      >
        <div
          v-if="showIconDelete"
          v-b-tooltip.hover
          class="base-form-field__icon-container-with-tooltip"
          :title="$t('Löschen')"
          @click="$emit('delete')"
        >
          <FontAwesomeIcon class="base-form-field__icon base-form-field__icon--times" :icon="['far', 'times']" />
        </div>
        <div
          v-if="showIconAsterisk"
          v-b-tooltip.hover
          class="base-form-field__icon-container-with-tooltip"
          :title="$t('Pflichtfeld')"
        >
          <FontAwesomeIcon class="base-form-field__icon base-form-field__icon--asterisk" icon="asterisk" />
        </div>
        <div
          v-if="showIconPassword"
          v-b-tooltip.hover
          class="base-form-field__icon-container-with-tooltip"
          :title="showPassword ? $t('Verstecken') : $t('Anzeigen')"
          @click="showPassword = !showPassword"
        >
          <FontAwesomeIcon class="base-form-field__icon base-form-field__icon--password" :icon="['far', 'eye']" />
        </div>
        <FontAwesomeIcon
          v-if="showIconCheck"
          class="base-form-field__icon base-form-field__icon--check"
          :icon="['far', 'check-circle']"
        />
        <FontAwesomeIcon
          v-if="showIconExclamation"
          class="base-form-field__icon base-form-field__icon--exclamation"
          :icon="['far', 'exclamation-circle']"
        />
        <FontAwesomeIcon
          v-if="showIconCaretDown"
          class="base-form-field__icon"
          icon="caret-down"
          :style="{ opacity: type === 'dropdown' ? 0 : 1 }"
        />
      </div>
    </div>
    <span
      v-if="{ stateMessage }"
      :class="{ 'error-state-message': state === false, 'success-state-message': state === true }"
      >{{ stateMessage }}</span
    >
  </BFormGroup>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faExclamationCircle, faEye, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faAsterisk, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BFormGroup } from 'bootstrap-vue';

library.add(faCheckCircle, faExclamationCircle, faEye, faTimes, faAsterisk, faCaretDown);
let counter = 0;

/**
 * Use this component for different form fields. Ideally placed within a FieldSet.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 */
export default {
  name: 'BaseFormField',
  components: { FontAwesomeIcon, BFormGroup },
  props: {
    /**
     * Id set for input. If not set a unique id is generated as bootstrap places the label next to the input.
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * Some input types have custom icons. As the icons are rendered in the BaseFormField we need the type info.
     */
    type: {
      type: String,
      default: 'text',
      validator: (value) =>
        [
          'text',
          'number',
          'email',
          'password',
          'select',
          'dropdown',
          'radio',
          'checkbox',
          'time',
          'datepicker',
        ].includes(value),
    },
    /**
     * Adds the `required` attribute to the form control.
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Label rendered above or to the left, depending on the variant.
     *
     * If variant is 'no-label' the label can be omitted.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Supported types:
     *  <table style="width:30%;">
     *    <tr><td>'horizontal'</td><td>Label to the left.</td></tr>
     *    <tr><td>'vertical'</td><td>Label above. Increased margin bottom.</td></tr>
     *    <tr><td>'vertical-lg'</td><td>Label above. Increases margin bottom and input paddings.</td></tr>
     *    <tr><td>'no-label'</td><td>No label. This is only allowed for type 'radio' or 'checkbox'.</td></tr>
     * </table>
     */
    variant: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical', 'vertical-lg', 'no-label', 'no-label-sm'].includes(value),
    },
    /**
     * Use only with FormFieldReadOnly
     */
    slimMargin: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to `true` or `false` the input will be highlighted with colors and icons, depending on the state.<br>
     * Default `null`.
     */
    state: {
      type: Boolean,
      default: null,
    },
    /**
     * If the value is not empty than the delete icon will be rendered next to the caret.
     */
    dropdownValue: {
      type: Object,
      default: null,
    },
    /**
     * If set to `true` the type dropdown will render the delete option
     */
    various: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to `true` it will not render delete option
     */
    disableDelete: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to `true` it will not render Label -> variant should be vertical because with horitzontal layout can be off
     */
    hideLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * If set a state message will be displayed below the input field
     */
    stateMessage: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      idFormGroup: `base-form-field__form-group--${counter}`,
      idFormInput: `base-form-field__form-input--${counter}`,
      showPassword: false,
    };
  },
  computed: {
    idFormInputComputed() {
      if (this.id != null) {
        return this.id;
      }
      return this.idFormInput;
    },
    showIconDelete() {
      if (this.disableDelete) {
        return false;
      }
      return (
        this.type === 'dropdown' && ((this.dropdownValue != null && this.dropdownValue.id != null) || this.various)
      );
    },
    showIconAsterisk() {
      return this.variant === 'horizontal' && this.required === true && typeof this.state !== 'boolean';
    },
    showIconPassword() {
      return this.variant === 'horizontal' && this.type === 'password';
    },
    showIconCheck() {
      return this.state === true;
    },
    showIconExclamation() {
      return this.state === false;
    },
    showIconCaretDown() {
      return this.type === 'select' || this.type === 'dropdown';
    },
    iconCount() {
      let iconCount = 0;
      if (this.showIconDelete) {
        iconCount += 1;
      }
      if (this.showIconAsterisk) {
        iconCount += 1;
      }
      if (this.showIconPassword) {
        iconCount += 1;
      }
      if (this.showIconCheck) {
        iconCount += 1;
      }
      if (this.showIconExclamation) {
        iconCount += 1;
      }
      if (this.showIconCaretDown) {
        iconCount += 1;
      }
      return iconCount;
    },
  },
  created() {
    counter += 1;
  },
};
</script>

<style scoped lang="scss">
.base-form-field--horizontal {
  text-align: left;
}

.base-form-field--horizontal,
.base-form-field--no-label {
  margin-top: var(--spacer_3);
  margin-bottom: var(--spacer_3);
}

.base-form-field--vertical,
.base-form-field--vertical-lg {
  margin-top: var(--spacer_3);
  margin-bottom: var(--spacer_4);
}

.base-form-field--horizontal.base-form-field--slim-margin,
.base-form-field--no-label.base-form-field--slim-margin {
  margin-top: var(--spacer_1);
  margin-bottom: var(--spacer_1);
}

.base-form-field--vertical.base-form-field--slim-margin,
.base-form-field--vertical-lg.base-form-field--slim-margin {
  margin-top: var(--spacer_1);
  margin-bottom: var(--spacer_2);
}

.base-form-field--vertical-lg,
.base-form-field--vertical-lg::v-deep .custom-select,
.base-form-field--vertical-lg::v-deep .form-control {
  font-size: 16px;
}

::v-deep .base-form-field__label {
  line-height: 1.5em;
  font-weight: 600;
}

.base-form-field__label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.base-form-field__label-floating-text {
  font-size: 12px;
}

.base-form-field__label-floating-text--password {
  color: var(--secondary);
  cursor: pointer;
}

.base-form-field__label-floating-icon {
  margin-right: 6px;
}

.base-form-field__label-floating-icon--asterisk {
  margin-bottom: 2px;
  font-size: 9px;
}

.base-form-field__form-input-wrapper {
  position: relative;
}

.base-form-field__icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.base-form-field__icons--vertical-lg {
  right: 10px;
}

.base-form-field__icon {
  height: 16px;
  width: 16px;
  padding: 0.6rem;
}

.base-form-field__icon-container-with-tooltip {
  height: calc(16px + 1.2rem);
  width: calc(16px + 1.2rem);
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  align-items: center;
}

.base-form-field__icon--check {
  color: var(--primary_dark);
}

.base-form-field__icon--exclamation {
  color: var(--danger_dark);
}

.base-form-field__icon--password {
  color: var(--secondary);
}

.error-state-message {
  color: var(--danger_dark);
}

.success-state-message {
  color: var(--primary);
}
</style>
