import { RestErrorCode, RestErrorResponse } from 'farmdok-rest-api';

import { ResponseStatus } from '../../shared/types';
import { ApiResponse, isRestErrorResponse } from '../types';

export default function mergeApiResponses<Entry>(apiResponses: ApiResponse<Entry>[]): ApiResponse<Entry> {
  const apiResponse: ApiResponse<Entry> = {
    status: mergeApiResponsesStatus(apiResponses),
    data: mergeApiResponsesData(apiResponses),
  };

  if (apiResponse.status !== 'success') {
    const { errorCode, errorUserMessage } = mergeApiResponsesErrors(apiResponses);
    // @ts-ignore
    (apiResponse as RestErrorResponse).errorCode = errorCode.join(', ');
    (apiResponse as RestErrorResponse).errorUserMessage = errorUserMessage;
  }

  return apiResponse;
}

export function mergeApiResponsesStatus<Entry>(apiResponses: ApiResponse<Entry>[]): ResponseStatus {
  if (apiResponses.find((apiResponse) => apiResponse.status === 'error')) return 'error';
  if (apiResponses.find((apiResponse) => apiResponse.status === 'partialSuccess')) return 'partialSuccess';
  if (apiResponses.every((apiResponse) => apiResponse.status === 'success')) return 'success';

  const unsupported = apiResponses.find(
    (apiResponse) =>
      apiResponse.status !== 'success' && apiResponse.status !== 'partialSuccess' && apiResponse.status !== 'error',
  );

  throw new Error(`Unsupported response status: ${unsupported?.status}`);
}

export function mergeApiResponsesData<Entry>(apiResponses: ApiResponse<Entry>[]): Entry[] {
  const entries = apiResponses.reduce<Entry[]>((accummulatedData, apiResponse) => {
    if (apiResponse.data) {
      return [...accummulatedData, ...apiResponse.data];
    }
    return accummulatedData;
  }, []);

  return entries;
}

export function mergeApiResponsesErrors<Entry>(apiResponses: Array<ApiResponse<Entry> | RestErrorResponse>): {
  errorCode: RestErrorCode[];
  errorUserMessage: string[];
} {
  const errorCodes = apiResponses.reduce<RestErrorCode[]>((accummulatedErrorCodes, apiResponse) => {
    if (isRestErrorResponse(apiResponse) && apiResponse.errorCode) {
      accummulatedErrorCodes.push(apiResponse.errorCode);
    }

    return accummulatedErrorCodes;
  }, []);

  const errorUserMessage = apiResponses.reduce<string[]>((accummulatedErrorUserMessage, apiResponse) => {
    if (isRestErrorResponse(apiResponse) && apiResponse.errorUserMessage) {
      accummulatedErrorUserMessage.push(...apiResponse.errorUserMessage);
    }

    return accummulatedErrorUserMessage;
  }, []);

  return {
    errorCode: errorCodes,
    errorUserMessage,
  };
}
