<template>
  <ModalWrapper ref="modalWrapper" class="modal-ask-rate-key" no-cancel-button :title="$t('Zonen erstellen')">
    <p class="mt-3 mb-3">
      <!-- eslint-disable max-len -->
      {{
        $t(
          'In der hochgeladenen Datei befinden sich mehrere Werte. Welcher davon soll zum Erstellen der Zonen benutzt werden?',
        )
      }}
      <!-- eslint-enable max-len -->
    </p>
    <BFormSelect class="w-50 mb-3" v-model="rateKey" :options="options" />
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '@/shared/components/ModalWrapper.vue';

export default {
  name: 'ModalAskRateKey',
  components: { ModalWrapper },
  data() {
    return {
      rateKey: null,
      rateKeys: null,
    };
  },
  computed: {
    options() {
      if (!Array.isArray(this.rateKeys)) {
        return [];
      }
      return this.rateKeys.map((rateKey) => ({ value: rateKey, text: rateKey }));
    },
  },
  methods: {
    async show(rateKeys) {
      this.rateKeys = rateKeys;
      [this.rateKey] = this.rateKeys;
      const ok = await this.$refs.modalWrapper.show();
      if (!ok) {
        return null;
      }
      return this.rateKey;
    },
  },
};
</script>

<style scoped></style>
