<template lang="html">
  <div v-if="availableProcessOrderNames.length > 0" class="process-order-switch">
    <span v-show="!slim && currentProcessOrderName != null" class="process-order-switch__label">
      {{ $t('Erntejahr:') }}
    </span>
    <FontAwesomeIcon v-if="previousProcessOrderAvailable" icon="arrow-alt-circle-left" @click="previousProcessOrder" />
    <FontAwesomeIcon
      v-else-if="currentProcessOrderName != null"
      icon="plus-circle"
      :class="{ 'process-order-switch__add-icon--read-only': processOrdersReadOnly }"
      @click="createProcessOrder('previous')"
    />
    <BFormSelect
      ref="select"
      class="process-order-switch__current"
      :value="currentProcessOrderName"
      :options="processOrderOptions"
      @change="select"
    />
    <FontAwesomeIcon v-if="nextProcessOrderAvailable" icon="arrow-alt-circle-right" @click="nextProcessOrder" />
    <FontAwesomeIcon
      v-else-if="currentProcessOrderName != null"
      icon="plus-circle"
      :class="{ 'process-order-switch__add-icon--read-only': processOrdersReadOnly }"
      @click="createProcessOrder('next')"
    />
    <ModalCreateProcessOrder v-if="modalVisible" v-model="modalVisible" :direction="modalDirection" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import ModalCreateProcessOrder from '@/shared/components/ModalCreateProcessOrder.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

library.add(faPlusCircle, faArrowAltCircleLeft, faArrowAltCircleRight);

/**
 * Use this dropdown only inside the template #right inside TheContentbar.
 *
 * @category Shared
 * @subcategory Molecules
 */
export default {
  name: 'ProcessOrderSwitch',
  components: { ModalCreateProcessOrder },
  props: {
    slim: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalVisible: false,
      modalDirection: null,
    };
  },
  computed: {
    currentProcessOrderName() {
      return this.$store.state.auth.currentProcessOrderName;
    },
    availableProcessOrderNames() {
      return this.$store.getters['auth/availableProcessOrderNames'];
    },
    processOrdersReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_PROCESS_ORDER);
    },
    processOrderOptions() {
      const processOrderOptions = this.availableProcessOrderNames
        .map((processOrderName) => ({ value: processOrderName, text: processOrderName }))
        .sort((a, b) => b.value - a.value);
      if (this.currentProcessOrderName != null) {
        return processOrderOptions;
      }
      return [{ value: null, text: this.$t('Erntejahr wählen...'), disabled: true }, ...processOrderOptions];
    },
    title() {
      if (this.currentProcessOrderName == null) {
        return this.$t('Erntejahr wählen...');
      }
      return this.currentProcessOrderName;
    },
    previousProcessOrderAvailable() {
      if (this.currentProcessOrderName == null) {
        return false;
      }
      return this.availableProcessOrderNames.indexOf(this.currentProcessOrderName) > 0;
    },
    nextProcessOrderAvailable() {
      if (this.currentProcessOrderName == null) {
        return false;
      }
      return (
        this.availableProcessOrderNames.indexOf(this.currentProcessOrderName) + 1 <
        this.availableProcessOrderNames.length
      );
    },
  },
  methods: {
    previousProcessOrder() {
      this.$store.dispatch('auth/setCurrentProcessOrderName', String(this.currentProcessOrderName - 1));
    },
    nextProcessOrder() {
      this.$store.dispatch('auth/setCurrentProcessOrderName', String(Number(this.currentProcessOrderName) + 1));
    },
    select(processOrderName) {
      this.$store.dispatch('auth/setCurrentProcessOrderName', processOrderName);
      this.$refs.select.blur();
    },
    createProcessOrder(direction) {
      if (this.processOrdersReadOnly) {
        return;
      }
      this.modalDirection = direction;
      this.modalVisible = true;
    },
  },
};
</script>

<style lang="css" scoped>
.process-order-switch {
  display: flex;
  align-items: center;
  color: var(--black);
}

.process-order-switch__label {
  margin-right: 0.75rem;
  white-space: nowrap;
  color: var(--dark);
  font-weight: 600;
}

.process-order-switch__current {
  width: auto;
  margin: 0 0.375rem;
  padding: 0.375rem;
  background: none;
  font-weight: 600;
}

:where(.process-order-switch) svg {
  cursor: pointer;
}

.process-order-switch__add-icon--read-only {
  color: var(--gray_600);
  opacity: 0.9;
  cursor: default;
}
</style>
