import { render, staticRenderFns } from "./ControlNumZones.vue?vue&type=template&id=54dfe1c8&scoped=true&"
import script from "./ControlNumZones.vue?vue&type=script&lang=ts&"
export * from "./ControlNumZones.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54dfe1c8",
  null
  
)

export default component.exports