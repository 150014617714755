type ViolationBase = {
  ruleId: string;
  shortMessage: string;
  activityId: string;
  activityRuleViolationId: string;
};

export type Violation = ViolationBase & {
  longMessageHtml: string;
};

export type ViolationNotChecked = ViolationBase & {
  links: IndicationLink[];
};

export type IndicationLink = { label: string; href: string };

export function isViolationNotChecked(violation: Violation | ViolationNotChecked): violation is ViolationNotChecked {
  return (violation as ViolationNotChecked).links !== undefined;
}
