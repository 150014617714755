export default function getProductAndStorageId(productId: string | null): {
  productId: string | null;
  storagePlaceId: string | null;
} {
  if (productId === null) {
    return { productId: null, storagePlaceId: null };
  }

  const productIdParts = productId.split('_');
  if (productIdParts.length === 1) {
    return { productId, storagePlaceId: null };
  }
  return { productId: productIdParts[0], storagePlaceId: productIdParts[1] };
}
