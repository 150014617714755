
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import FirstStep from '@/activities/modals/moveActivity/components/FirstStep.vue';
import { Company, FeatureName, UserCompany } from '@/auth/store/types';
import { ProcessOrder } from '@/shared/api/rest/models';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

export default defineComponent({
  name: 'FirstStepContainer',
  components: {
    FirstStep,
  },
  watch: {
    selectedCompanyId: {
      handler() {
        if (!this.selectedCompanyId) {
          this.$store.commit(`activities/moveActivity/setSelectedCompanyId`, this.currentCompanyIds[0]);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentProcessOrderIds: 'auth/currentProcessOrderIds',
      currentCompanyIds: 'auth/currentCompanyIds',
    }),
    ...mapState('activities/moveActivity', ['selectedCompanyId', 'selectedProcessOrderId']),
    companiesWithMoveActivitiesEnabled(): Company[] {
      const userCompanies: UserCompany[] = this.$store.getters['auth/userCompaniesWithFeatureEnabled'](
        FeatureName.FEATURE_MOVE_ACTIVITIES,
      );
      return userCompanies.map((userCompany: UserCompany) => userCompany.company);
    },
    selectedCompany(): Company | null {
      return this.companiesWithMoveActivitiesEnabled.find((company) => company.id === this.selectedCompanyId) || null;
    },
    companyDropdownItems(): DropdownItemsPerCategory[] {
      const companyIdAndName: DropdownItem[] = this.companiesWithMoveActivitiesEnabled.map((company) => ({
        id: company.id,
        name: company.name,
      }));
      return [{ items: companyIdAndName, sort: true }];
    },
    processOrdersDropdownItems(): DropdownItemsPerCategory[] {
      if (this.selectedCompany) {
        const processOrderIdAndName: DropdownItem[] = this.selectedCompany.processOrders
          .filter((processOrder) => !this.currentProcessOrderIds.includes(processOrder.id))
          .map((processOrder: ProcessOrder) => ({
            id: processOrder.id,
            name: processOrder.name,
          }))
          .sort((a, b) => {
            const aYear = Number(a.name);
            const bYear = Number(b.name);

            return aYear && bYear ? bYear - aYear : 0;
          });

        return [{ items: processOrderIdAndName }];
      }
      return [];
    },
    selectedProcessOrder(): DropdownItem | null {
      return (
        this.selectedCompany?.processOrders.find((processOrder) => processOrder.id === this.selectedProcessOrderId) ||
        null
      );
    },
  },
  methods: {
    updateCompanyId(companyId: string): void {
      this.$store.commit(`activities/moveActivity/setSelectedCompanyId`, companyId);
    },
    updateProcessOrderId(processOrderId: string): void {
      this.$store.commit(`activities/moveActivity/setSelectedProcessOrderId`, processOrderId);
    },
  },
});
