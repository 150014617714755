<template>
  <div class="date-editor">
    <Datepicker
      ref="datepicker"
      :value="selectedDate"
      @selected="setDate"
      calendar-class="datepicker-calendar"
      :style="{ border: 0 }"
      :inline="true"
      :monday-first="true"
    />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'DateEditor',
  data() {
    return {
      selectedDate: null,
    };
  },
  components: { Datepicker },
  methods: {
    /**
     *
     * @param {Date} date
     */
    setDate(date) {
      this.selectedDate = date;
      this.$emit('input', date);
    },
  },
};
</script>

<style>
.datepicker-calendar {
  border: none !important;
  padding-right: 16px !important;
  box-shadow: 0 25px 50px rgba(0, 0, 50, 0.1), 0 8px 20px rgba(0, 0, 50, 0.15), 0 5px 7px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.datepicker-calendar .day {
  border-radius: 6px !important;
}

.datepicker-calendar .day:hover {
  background: var(--primary) !important;
}

.datepicker-calendar .day.selected:hover {
  background: var(--primary) !important;
}

.datepicker-calendar .day.selected {
  background: var(--primary_light) !important;
}
</style>
