import { GetterTree } from 'vuex';

import { ActivitiesGpsState } from '@/activities/gpsTrack/store/types';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';

const getters: GetterTree<ActivitiesGpsState, RootState> = {
  ...subscribableStore.getters,
};

export default getters;
