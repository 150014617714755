import { Activity, ActivityProduct, StockLevel } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import { ActionContext } from 'vuex';

import ActivityProductService from '@/activities/services/ActivityProductService';
import ActivityService from '@/activities/services/ActivityService';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../types';
import calcAmountBasedOnProcessedArea from './utils/calcAmountBasedOnProcessedArea';
import initServices from './utils/initServices';

type UpdateValues = StockLevel;

export default function applyStockLevelOnActivityProductInAllActivities(
  { state, commit, rootState, rootGetters }: ActionContext<CreateEditActivityState, RootState>,
  data: { activityProductId: string; updateValues: UpdateValues },
) {
  const { activityProductService, companyService, activityService, findService } = initServices(
    state,
    rootState,
    rootGetters,
  );
  const referenceActivityProduct = _cloneDeep(findService.getActivityProduct(data.activityProductId));

  const stockLevel = _cloneDeep(data.updateValues);

  state.activities.forEach((activity) => {
    const clonedActivity = _cloneDeep(activity);
    const family = activityService.findActivityProductsOrSiblings(clonedActivity, referenceActivityProduct);
    const companyId = companyService.getCompanyByProcessOrder(activity.processOrderId).id;
    stockLevel.amount = calcAmountBasedOnProcessedArea(activity, state.activities, data.updateValues.amount);

    if (family.length > 0) {
      updateProducts(family, stockLevel, companyId, clonedActivity.id, activityProductService, commit);
    } else {
      addNewProduct(clonedActivity, stockLevel, companyId, activityService, activityProductService, commit);
    }
  });
}

function updateProducts(
  product: ActivityProduct[],
  updateValues: UpdateValues,
  companyId: string,
  activityId: string,
  activityProductService: ActivityProductService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  product.forEach((p) => {
    updateProduct(p, updateValues, companyId, activityId, activityProductService, commit);
  });
}

function updateProduct(
  product: ActivityProduct,
  updateValues: UpdateValues,
  companyId: string,
  activityId: string,
  activityProductService: ActivityProductService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  const updatedProduct = updateActivityProductValues(product, updateValues, companyId, activityProductService);

  commit('setActivityProduct', { activityId, activityProduct: updatedProduct });
}

function addNewProduct(
  activity: Activity,
  updateValues: UpdateValues,
  companyId: string,
  activityService: ActivityService,
  activityProductService: ActivityProductService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  const product = ActivityProductService.createActivityProduct();
  const activityProduct = updateActivityProductValues(product, updateValues, companyId, activityProductService);
  activityService.addActivityProduct(activity, activityProduct);

  commit('addActivityProduct', { activityId: activity.id, activityProduct: activity.products.slice(-1)[0] });
}

// TODO combine this with updateActivityProductValues of updatePestOnActivityProductInAllActivities.ts
function updateActivityProductValues(
  activityProduct: ActivityProduct,
  updateValues: UpdateValues,
  companyId: string,
  activityProductService: ActivityProductService,
): ActivityProduct {
  let updatedProduct = _cloneDeep(activityProduct);
  updatedProduct = activityProductService.setProductOrSibling(activityProduct, updateValues.productId, companyId);
  updatedProduct = ActivityProductService.setUnitId(updatedProduct, updateValues.unitId);
  updatedProduct = ActivityProductService.setStoragePlaceId(updatedProduct, updateValues.storagePlaceId);
  updatedProduct.amount = updateValues.amount; // TODO if unit is total unit, distribute amount to all siblings (as done in updateAmountAndUnitOnActivityProductInAllActivities.ts)

  return updatedProduct;
}
