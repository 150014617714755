import { render, staticRenderFns } from "./ContentBox.vue?vue&type=template&id=36caf593&scoped=true&"
import script from "./ContentBox.vue?vue&type=script&lang=js&"
export * from "./ContentBox.vue?vue&type=script&lang=js&"
import style0 from "./ContentBox.vue?vue&type=style&index=0&id=36caf593&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36caf593",
  null
  
)

export default component.exports