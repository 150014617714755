import axios from 'axios';
import Vue from 'vue';
import { Module } from 'vuex';

import { ApiResponse } from '@/shared/api/rest/models';
import {
  ENTRY_DIRTY,
  ENTRY_ERROR_INSERTING,
  ENTRY_ERROR_UPDATING,
  ENTRY_INSERTING,
  ENTRY_NEW,
  ENTRY_SYNCED,
  ENTRY_UPDATING,
  ERROR_INSERTING,
  ERROR_UPDATING,
  GUID_KEY,
} from '@/shared/constants';
import {
  getRestResponseData,
  modelRequiredFieldsAreSet,
  requestDataCreateFromEntryAndModel,
} from '@/shared/modules/restApiHelpers';
import { RootState } from '@/store/types';

import initialStateSubscribable from './subscribableData/initialState';
import { DataEntry, Error, ResponseData, SyncableDataState } from './types';

/**
 * @deprecated use syncableData module instead
 * @returns
 */
export function initialState<E extends DataEntry>(): SyncableDataState<E> {
  return {
    ...initialStateSubscribable(),
    syncing: false,
    syncableObservers: [],
    errors: [],
  };
}

/**
 * Returns a store that can update store data + sync those changes to the backend.
 *
 * Add resolve like in subscribableData to prevent sending resolves objects on update.
 * @deprecated use syncableData module instead
 */
export default function syncableData<Entry extends DataEntry, Model>(
  dataUrl: string,
  { model, modelDefinition = {} }: { model: Model; modelDefinition: Record<string, unknown> },
): Module<SyncableDataState<Entry>, RootState> {
  return {
    namespaced: true,
    state: initialState(),
    mutations: {
      /**
       * Set the syncing state to true.
       *
       * @param state
       */
      syncAllStart(state) {
        state.syncing = true;
      },
      /**
       * Call this before sending (bulk) data to the BE.
       * Update the storeStatus for all given entries to either ENTRY_INSERTING or ENTRY_UPDATING.
       * Removes errors for all entries that get synced.
       *
       * @param state
       * @param entries
       */
      syncAllAddEntries(state, entries: Entry[]) {
        state.data = entries.reduce(
          (currentState, entry) => ({
            ...currentState,
            [entry.id]: {
              ...currentState[entry.id],
              storeStatus: state.data[entry.id].storeStatus === ENTRY_NEW ? ENTRY_INSERTING : ENTRY_UPDATING,
            },
          }),
          state.data,
        );
        const entryIds = entries.map(({ id }) => id);
        state.errors = state.errors.filter((error) => !entryIds.includes(error.guid));
      },
      /**
       * Call this after successful (bulk) data request to BE.
       * Updates all given entries in the store iff the storeStatus is still 'inserting' or 'updating'.
       * Additionally set state.syncing to false.
       *
       * @param state
       * @param entries
       */
      syncAllFinish(state, entries: Entry[]) {
        if (!state.syncing) {
          return;
        }
        state.syncing = false;
        if (!Array.isArray(entries)) {
          return;
        }
        state.data = entries.reduce((currentState, entry) => {
          const storeStatus = state.data[entry.id]?.storeStatus;
          if (storeStatus && ![ENTRY_INSERTING, ENTRY_UPDATING].includes(storeStatus)) {
            return currentState;
          }
          return {
            ...currentState,
            [entry.id]: {
              ...currentState[entry.id],
              ...entry,
              storeStatus: ENTRY_SYNCED,
            },
          };
        }, state.data);
      },
      /**
       * Add a callback that is called as soon as all entries are synced or an error occurred.
       *
       * @param state
       * @param callback
       */
      addSyncableObserver(state, callback: Function) {
        state.syncableObservers = [...state.syncableObservers, callback];
      },
      /**
       * Resolve and clear all current callbacks. Call this after all entries are synced or an error occurred.
       *
       * @param state
       */
      clearSyncableObservers(state) {
        state.syncableObservers.forEach((callback) => callback());
        state.syncableObservers = [];
      },
      /**
       * Add error into store.
       *
       * @param state
       * @param guid
       * @param key
       * @param errorUserMessage
       */
      addSyncError(state, error: Error) {
        let storeStatus = ENTRY_ERROR_UPDATING;
        let type = ERROR_UPDATING;
        if (
          state.data[error.guid].storeStatus === ENTRY_NEW ||
          state.data[error.guid].storeStatus === ENTRY_INSERTING ||
          state.data[error.guid].storeStatus === ENTRY_ERROR_INSERTING
        ) {
          storeStatus = ENTRY_ERROR_INSERTING;
          type = ERROR_INSERTING;
        }
        state.data[error.guid] = {
          ...state.data[error.guid],
          storeStatus,
        };
        state.errors = [
          ...state.errors,
          {
            ...error,
            type,
          },
        ];
      },
      /**
       * Update the value of a single field of a single entry.
       * Sets the storeStatus to 'dirty'.
       *
       * @param state
       * @param guid
       * @param key
       * @param value
       */
      updateEntryByKeyAndValue(state, { guid, key, value }) {
        if (state.data[guid] == null) {
          return;
        }
        const data = { ...state.data };
        data[guid] = {
          ...state.data[guid],
          [key]: value,
          storeStatus: ENTRY_DIRTY,
        };
        state.data = data;
        state.errors = state.errors.filter((error) => error[GUID_KEY] !== guid || error.key !== key);
      },
      /**
       * Update an entry in the store and setting it to 'dirty' (unless the silent flag is set, than storeStatus is not changed).
       *
       * @param state
       * @param entry
       * @param silent {boolean}
       */
      updateEntry(state, { entry, silent = false }) {
        const guid = entry[GUID_KEY];
        const data = { ...state.data };
        data[guid] = {
          ...state.data[guid],
          ...entry,
        };

        if (!silent) {
          data[guid].storeStatus = ENTRY_DIRTY;
        }
        state.data = data;
      },
      /**
       * Insert an entry in the store and setting it to 'new'.
       *
       * @param state
       * @param entry
       */
      insert(state, { entry }) {
        const guid = entry[GUID_KEY];
        state.data = {
          ...state.data,
          [guid]: {
            ...entry,
            storeStatus: ENTRY_NEW,
          },
        };
      },
    },
    actions: {
      /**
       * Insert a new entry into the store. Gets populated by model default data if entry is missing fields.
       *
       * @param commit
       * @param entry
       * @return {Promise<void>}
       */
      async insertNewEntry({ commit }, { entry }: { entry: Entry }) {
        const newEntry = {
          ...model,
          ...entry,
        };
        commit('insert', { entry: newEntry });
      },
      /**
       * Insert new entry using 'insertNewEntry' and then syncs data to BE.
       *
       * @param dispatch
       * @param entry
       * @return {Promise<void>}
       */
      async insertAndSyncNewEntry({ dispatch }, { entry }) {
        await dispatch('insertNewEntry', { entry });
        await dispatch('syncAll');
      },
      /**
       * Updates an existing entry and then syncs data to BE.
       *
       * @param commit
       * @param dispatch
       * @param entry
       * @return {Promise<void>}
       */
      async updateAndSyncEntry({ commit, dispatch }, { entry }) {
        commit('updateEntry', { entry });
        await dispatch('syncAll');
      },
      /**
       * Sync all dirty and new entries to BE.
       * If a request already runs do not send another one.
       * After each successful request call automatically calls 'syncAll' recursively again, until there are no more dirty/new entries anymore.
       */
      async syncAll({ state, commit, dispatch }) {
        if (state.syncing) {
          await new Promise((callback) => {
            commit('addSyncableObserver', callback);
          });
          return;
        }
        // Do the debouncing manually (even though a new call will not delay the call anymore)
        // as lodash.debounce doesnt work for async functions out of the box
        commit('syncAllStart');
        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });
        const requestData = Object.values(state.data)
          .filter((entry) => entry.storeStatus === ENTRY_DIRTY || entry.storeStatus === ENTRY_NEW)
          .map((entry) => ({
            // @ts-ignore
            ...requestDataCreateFromEntryAndModel(entry, model, modelDefinition),
            id: entry.id,
          }))
          // @ts-ignore
          .filter((entry) => modelRequiredFieldsAreSet(entry, modelDefinition));
        if (requestData.length < 1) {
          commit('clearSyncableObservers');
          commit('syncAllFinish');
          return;
        }
        commit('syncAllAddEntries', requestData);
        let responseData: ResponseData<Entry>;
        try {
          const { data } = await axios.put<ApiResponse<Entry>>(dataUrl, {
            version: '2.0',
            data: requestData,
          });
          responseData = getRestResponseData(data);
        } catch (error) {
          responseData = getRestResponseData(error);
        }
        if (responseData.status === 'success') {
          commit('syncAllFinish', responseData.data);
          await dispatch('syncAll');
        } else if (responseData.status === 'partialSuccess') {
          dispatch('addSyncErrors', { requestData, responseData });
          commit(
            'syncAllFinish',
            // @ts-ignore
            responseData.data?.filter((entry) => responseData?.errors?.[entry.id] == null),
          );
          await dispatch('syncAll');
        } else {
          dispatch('addSyncErrors', { requestData, responseData });
          commit('clearSyncableObservers');
          commit('syncAllFinish');
        }
      },
      /**
       * Adds dummy error objects for every entry that was changed if the whole request failed.
       * In case of a 'partialSuccess' errors will be added for every entry that failed.
       *
       * @param commit
       * @param requestData
       * @param responseData
       */
      addSyncErrors({ commit }, { requestData, responseData }) {
        if (responseData.status !== 'partialSuccess') {
          requestData.forEach((entry: Entry) => {
            commit('addSyncError', {
              guid: entry.id,
              key: null,
              errorUserMessage: responseData.errorUserMessage[0],
            });
          });
          return;
        }
        Object.keys(responseData.errors).forEach((guid) => {
          const error = getRestResponseData({
            status: 'error',
            ...responseData.errors[guid],
          });
          error.errorUserMessage.forEach((errorUserMessage: any) => {
            commit('addSyncError', {
              guid,
              key: null,
              errorUserMessage,
            });
          });
          if (error.errorCode === 'attributeValidationError') {
            // @ts-ignore
            Object.keys(error.errorAttributeUserMessages).forEach((key) => {
              commit('addSyncError', {
                guid,
                key,
                // @ts-ignore
                errorUserMessage: error.errorAttributeUserMessages[key][0],
              });
            });
            // @ts-ignore
          } else if (typeof error.errorFieldName === 'string' && error.errorFieldName.length > 0) {
            commit('addSyncError', {
              guid,
              // @ts-ignore
              key: error.errorFieldName,
              errorUserMessage: Vue.i18n.translate('Ungültiger Wert.'),
            });
          }
        });
      },
    },
  };
}
