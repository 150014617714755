import columns from './columns';
import hiddenRows from './hiddenRows';
import mergeCells from './mergeCells';

const settings = {
  mergeCells,
  columns,
  hiddenRows,
};

export default settings;
