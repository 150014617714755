import { AxiosRequestConfig } from 'axios';
import {
  ProductCategoriesApi,
  ProductCategoriesApiProductCategoryListRequest,
  ProductCategory,
} from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<ProductCategory>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ProductCategory>> {
  const { productCategoriesApi } = Vue.prototype.$api as Api;

  const requestParameters: ProductCategoriesApiProductCategoryListRequest = {};
  const apiResponse = loadAllPages<ProductCategoriesApi, ProductCategory>(
    productCategoriesApi,
    'productCategoryList',
    requestParameters,
    axiosOptions,
  );

  return apiResponse;
}

async function fetchByIds(
  ids: string[],
  context: ActionContext<SubscribableDataState<ProductCategory>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ProductCategory>> {
  const { productCategoriesApi } = Vue.prototype.$api as Api;
  const { data } = await productCategoriesApi.productCategoryObjects({ objectIds: ids.join(',') }, axiosOptions);

  return data as ApiResponse<ProductCategory>;
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
