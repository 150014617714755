<template lang="html">
  <div>
    <div
      v-show="$store.state.notifications.visible"
      v-click-outside="() => $store.commit('notifications/hide')"
      class="notifications-overlay bg-lightest"
    >
      <div class="notifications-overlay__list">
        <div v-if="$store.state.notifications.fetching" class="notifications-overlay__fetching text-primary">
          <FontAwesomeIcon icon="circle-notch" spin />
        </div>
        <Notification
          v-for="notification in latestNotifications"
          :key="notification.id"
          :priority="notification.priority"
          :read="!!notification.read"
          :title="notification.subject"
          :content="notification.messageText"
          @click="() => handleClick(notification)"
        />
      </div>
      <RouterLink :to="{ name: 'notifications' }" class="all-notifications text-dark"
        >{{ $t('Alle anzeigen') }}
      </RouterLink>
    </div>
    <ModalWrapper ref="modal" size="lg" noCancelButton static :title="notificationTitle" :okTitle="$t('Schließen')">
      <div v-html="notificationMessage" />
    </ModalWrapper>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { mapGetters } from 'vuex';

import VueSanitizeOptions from '@/initVueSanitize';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import { baseUrl } from '@/shared/constants';

import Notification from './Notification.vue';

library.add(faCircleNotch);

export default {
  name: 'NotificationsOverlay',
  components: {
    ModalWrapper,
    Notification,
  },
  data() {
    return {
      baseUrl,
      notificationTitle: null,
      notificationMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/data',
    }),
    latestNotifications() {
      return Object.values(this.notifications)
        .sort((a, b) => b.sendTime - a.sendTime)
        .slice(0, 5)
        .map((notification) => {
          if (notification.template == null) {
            return notification;
          }
          const mergedNotification = { ...notification };
          if (mergedNotification.subject == null) {
            mergedNotification.subject = notification.template.subject;
          }
          if (mergedNotification.messageText == null) {
            mergedNotification.messageText = notification.template.messageText;
            mergedNotification.message = notification.template.message;
          }
          return mergedNotification;
        });
    },
  },
  methods: {
    handleClick(notification) {
      this.$store.dispatch('notifications/markRead', notification);
      this.$store.commit('notifications/toggleVisibility');
      this.notificationTitle = notification.subject;
      this.notificationMessage = this.$sanitize(notification.message, {
        ...VueSanitizeOptions,
        allowedAttributes: {
          ...VueSanitizeOptions.allowedAttributes,
          p: ['style'],
          span: ['style'],
        },
      });
      this.$refs.modal.show();
    },
  },
};
</script>

<style lang="css" scoped>
.notifications-overlay {
  position: fixed;
  top: 50px;
  right: 20px;
  width: 350px;
  box-shadow: 0 25px 50px rgba(0, 0, 50, 0.1), 0 8px 20px rgba(0, 0, 50, 0.15), 0 5px 7px rgba(0, 0, 0, 0.05);
  z-index: 1000;
}

.notifications-overlay__list {
  padding: 10px 10px 0;
}

.notifications-overlay__fetching {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 var(--padding-x);
}

.all-notifications {
  display: block;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  padding: 0 7px 7px;
  font-weight: 600;
}
</style>
