import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';

library.add(faLockAlt);

export default function ReadOnlyRenderer(instance, TD, row, col, prop) {
  TD.classList.add('read-only');

  // placeholder column has no defined prop and doesnt need the lock icon
  if (typeof prop === 'function') {
    const [lockAlt] = icon({ prefix: 'fas', iconName: 'lock-alt' }).node;
    lockAlt.classList.add('lock-icon');
    TD.append(lockAlt);
  }
}
