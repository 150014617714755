
import Handsontable from 'handsontable';
import { PropType, defineComponent } from 'vue';

import TableFooter from '@/shared/handsontable/components/TableFooter.vue';

import TableFooterRuleCheckStatusContainer from './TableFooterRuleCheckStatusContainer.vue';

export default defineComponent({
  name: 'TableFooterContainer',
  components: { TableFooter, TableFooterRuleCheckStatusContainer },
  props: {
    hot: {
      type: Object as PropType<Handsontable | null>,
    },
    visibleRows: {
      type: Number as PropType<number>,
    },
    totalRows: {
      type: Number as PropType<number>,
    },
  },
  computed: {
    selectedRows(): number {
      const uniqueIds = new Set();
      const uniqueRows = this.hot?.getSourceData().filter((row) => {
        const { id } = row;
        if (!uniqueIds.has(id)) {
          uniqueIds.add(id);
          return true;
        }
        return false;
      });
      const selectedRows = uniqueRows?.filter((row) => row.select === true);
      return selectedRows?.length ?? 0;
    },
  },
});
