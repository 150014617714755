import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { subscribableStore, syncableStore } from './common';
import { GeoObjectState } from './types';

const moduleGetters: GetterTree<GeoObjectState, RootState> = {
  ...subscribableStore.getters,
  ...syncableStore.getters,
};

export default moduleGetters;
