import { render, staticRenderFns } from "./ModalPageSettings.vue?vue&type=template&id=45efe0b8&scoped=true&"
import script from "./ModalPageSettings.vue?vue&type=script&lang=ts&"
export * from "./ModalPageSettings.vue?vue&type=script&lang=ts&"
import style0 from "./ModalPageSettings.vue?vue&type=style&index=0&id=45efe0b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45efe0b8",
  null
  
)

export default component.exports