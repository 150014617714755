
import { PropType, defineComponent } from 'vue';

import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import FormFieldSetBordered from '@/shared/components/form/FormFieldSetBordered.vue';
import { SelectItem } from '@/shared/components/form/formFieldSelectTypes';

import { ZoningMode } from '../../store/types';

export default defineComponent({
  name: 'Accordion',
  components: {
    FormFieldSetBordered,
    FormFieldSelect,
    FormCheckbox,
  },
  props: {
    selectedNumberOfZones: {
      type: Number as PropType<number>,
      required: true,
    },
    selectedZoningMode: {
      type: String as PropType<string>,
      required: true,
    },
    borderZoneCorrection: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    collapsed: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    const numberOfZones: SelectItem[] = [
      { value: 2, text: '2' },
      { value: 3, text: '3' },
      { value: 4, text: '4' },
      { value: 5, text: '5' },
    ];

    const zoningModes: SelectItem[] = [
      { value: ZoningMode.EQUAL_ZONE_SIZE, text: this.$t('Gleiche Zonengröße') || 'Gleiche Zonengröße' },
      { value: ZoningMode.EQUAL_INDEX_DISTANCE, text: this.$t('Gleicher Indexabstand') || 'Gleicher Indexabstand' },
      { value: ZoningMode.JENKS_NATURAL_BREAKS, text: this.$t('Jenks natural breaks') || 'Jenks natural breaks' },
    ];

    return {
      numberOfZones,
      zoningModes,
    };
  },
  computed: {
    description(): string | null {
      const selectedZoningModeText: string | undefined = this.zoningModes.find(
        (zoningMode) => zoningMode.value === this.selectedZoningMode,
      )?.text;

      const selectedZoneText: string | undefined = this.numberOfZones.find(
        (zoneNumber) => zoneNumber.value === this.selectedNumberOfZones,
      )?.text;

      const borderAreaCorrectedText = this.borderZoneCorrection
        ? this.$t('Randbereich korrigiert')
        : this.$t('Randbereich nicht korrigiert');

      return this.collapsed
        ? `${selectedZoneText} ${this.$t('Zonen')}, ${selectedZoningModeText}, ${borderAreaCorrectedText}`
        : null;
    },
    borderZoneCorrectionLabel(): string {
      return this.borderZoneCorrection
        ? this.$t('Randbereich korrigiert') || 'Randbereich korrigiert'
        : this.$t('Randbereich nicht korrigiert') || 'Randbereich nicht korrigiert';
    },
  },
});
