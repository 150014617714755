import Vue from 'vue';
import { MutationTree } from 'vuex';

import { BaseWorkflowStoreModuleState } from './types';
import { Feature, Heatmap } from './types/Heatmap';

const mutations: MutationTree<BaseWorkflowStoreModuleState> = {
  // reset(state) must be implemented in derived store module

  setPaginationStep(state, step: number) {
    Vue.set(state, 'paginationStep', step);
  },
  setMultiPolyRequestIdentifier(state, requestIdentifier) {
    Vue.set(state, 'multiPolyRequestIdentifier', requestIdentifier);
  },
  setPaginationCallbackAsync(state, paginationCallbackAsync: Function | null) {
    Vue.set(state, 'paginationCallbackAsync', paginationCallbackAsync);
  },
  setSelectedFields(state, data) {
    Vue.set(state, 'selectedFields', data);
  },
  addPolygon(state, data) {
    Vue.set(state.polygons, data.field_key, data);
  },
  polygonSetState(state, { key, state: polygonState }) {
    const polygons = { ...state.polygons };
    polygons[key].state = polygonState;
    Vue.set(state, 'polygons', polygons);
  },
  selectHeatmapDbId(state, dbId) {
    Vue.set(state, 'selectedHeatmapDbId', dbId);
  },
  setCheckingViolation(state, value: boolean) {
    Vue.set(state, 'checkingViolation', value);
  },
  fetchHeatmaps(state) {
    Vue.set(state.heatmaps, 'fetching', true);
    Vue.set(state.heatmaps, 'current', {});
  },
  setHeatmaps(state, heatmaps: Record<string, Heatmap>) {
    Object.entries(heatmaps).forEach(([heatmapKey, heatmap]) => {
      heatmap.features.forEach((feature: Feature, index: number) => {
        // eslint-disable-next-line no-param-reassign
        feature.heatmapKey = heatmapKey;
        // eslint-disable-next-line no-param-reassign
        feature.featureIndex = index;
      });
    });
    Vue.set(state.heatmaps, 'fetching', false);
    Vue.set(state.heatmaps, 'current', heatmaps);
  },
  setZoneGenerationMode(state, mode) {
    Vue.set(state, 'zoneGenerationMode', mode);
  },
  updateFeatureVegetation(state: BaseWorkflowStoreModuleState, feature: Feature): void {
    if (feature.heatmapKey === undefined || feature.featureIndex === undefined) {
      throw new Error(
        'Cannot update features with missing heatmap key or feature index. Features are subordinates of heatmaps, therefore they need to provide a heatmap key and a feature index for ease of reference.',
      );
    }

    Vue.set(
      state.heatmaps.current[feature.heatmapKey].features[feature.featureIndex].vegetation,
      `custom_value`,
      feature.vegetation.custom_value,
    );
    Vue.set(
      state.heatmaps.current[feature.heatmapKey].features[feature.featureIndex].properties,
      `fill`,
      feature.properties.fill,
    );
    Vue.set(
      state.heatmaps.current[feature.heatmapKey].features[feature.featureIndex].properties,
      `stroke`,
      feature.properties.stroke,
    );
  },
  setStepsCompleted(state, completed) {
    Vue.set(state, 'stepsCompleted', completed);
  },
  // task
  setTask(state, task) {
    Vue.set(state, 'task', task);
  },

  setSelectedTaskDate(state, selectedTaskDate: Date) {
    Vue.set(state, 'selectedTaskDate', selectedTaskDate.toISOString());
  },
  /**
   *
   * @param {*} state
   * @param {{id: string, name: string}} selectedCompany
   */
  setSelectedCompany(state, selectedCompany) {
    Vue.set(state, 'selectedCompany', selectedCompany);
  },

  setFetchingTask(state, fetchingTask: boolean) {
    Vue.set(state, 'fetchingTask', fetchingTask);
  },

  setFetchingMaterial(state, fetchingMaterial: boolean) {
    Vue.set(state, 'fetchingMaterial', fetchingMaterial);
  },
};

export default mutations;
