<template>
  <BDropdown
    variant="lightest"
    menu-class="shadow mt-1"
    right
    no-caret
    :data-namespace="`${$options.name}.${componentNamespace}`"
  >
    <template slot="button-content">
      {{ $t('Optionen') }}
      <FontAwesomeIcon icon="chevron-circle-down" />
    </template>
    <BDropdownItemButton
      v-if="!hideSettings"
      class="dropdown-page-options__item text-black"
      button-class="dropdown-page-options__button"
      :data-namespace="`ButtonSettings.${$options.name}.${componentNamespace}`"
      @click="$emit('settings')"
    >
      <FontAwesomeIcon icon="cog" class="dropdown-page-options__button-icon" />
      {{ settingsLabel }}
    </BDropdownItemButton>
    <BDropdownItemButton
      v-if="!hideRestore"
      class="dropdown-page-options__item text-black"
      button-class="dropdown-page-options__button"
      :disabled="restoreReadOnly"
      :data-namespace="`ButtonRestore.${$options.name}.${componentNamespace}`"
      @click="$emit('restore')"
    >
      <FontAwesomeIcon icon="history" class="dropdown-page-options__button-icon" />
      {{ restoreLabel }}
    </BDropdownItemButton>
    <BDropdownItemButton
      v-if="!hideExport"
      class="dropdown-page-options__item text-black"
      button-class="dropdown-page-options__button"
      :disabled="exportDisabled"
      :data-namespace="`ButtonExport.${$options.name}.${componentNamespace}`"
      @click="$emit('export')"
    >
      <FontAwesomeIcon icon="cloud-download" class="dropdown-page-options__button-icon" />
      {{ exportLabel }}
    </BDropdownItemButton>
    <BDropdownItemButton
      v-for="option in additionalOptions"
      class="dropdown-page-options__item text-black"
      button-class="dropdown-page-options__button"
      :key="option.key"
      :disabled="option.disabled"
      :data-namespace="`${option.key}.${$options.name}.${componentNamespace}`"
      @click="option.onClick"
    >
      <FontAwesomeIcon class="dropdown-page-options__button-icon" :icon="option.icon" />
      {{ option.label }}
    </BDropdownItemButton>
    <slot name="additional-options" />
  </BDropdown>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronCircleDown, faCloudUpload, faCog, faHistory } from '@fortawesome/pro-solid-svg-icons';
import Vue from 'vue';

import componentNamespace from '../mixins/componentNamespace';

library.add(faChevronCircleDown, faHistory, faCog, faCloudUpload);

/**
 * Use this dropdown only inside the template #right inside TheContentbar.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <DropdownPageOptions/>
 */
export default {
  name: 'DropdownPageOptions',
  mixins: [componentNamespace],
  props: {
    restoreLabel: {
      type: String,
      default: () => Vue.i18n.translate('Wiederherstellen'),
    },
    restoreReadOnly: {
      type: Boolean,
      default: false,
    },
    hideRestore: {
      type: Boolean,
      default: false,
    },
    settingsLabel: {
      type: String,
      default: () => Vue.i18n.translate('Einstellungen'),
    },
    hideSettings: {
      type: Boolean,
      default: false,
    },
    exportLabel: {
      type: String,
      default: () => Vue.i18n.translate('Exportieren'),
    },
    exportDisabled: {
      type: Boolean,
      default: false,
    },
    hideExport: {
      type: Boolean,
      default: false,
    },
    additionalOptions: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .dropdown-page-options__button {
  font-weight: 600;
  font-size: 14px;
}

.dropdown-page-options__button-icon {
  width: 17px;
}
</style>
