export default {
  mounted() {
    this.addHotHook('afterGetColHeader', this.tableHeaderLinkAfterGetColHeader);
  },
  methods: {
    tableHeaderLinkAfterGetColHeader(visualCol, th) {
      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      const column = this.tableSettingsComputed.columns[physicalCol];
      if (column == null || column.header == null || !Array.isArray(column.header.links)) {
        return;
      }

      const colHeader = th.querySelector('.colHeader');
      if (colHeader == null || colHeader.parentNode == null) {
        return;
      }

      let div = th.querySelector('.table-header-link');
      if (div == null) {
        div = document.createElement('div');
        div.classList.add('table-header-link');
        colHeader.parentNode.appendChild(div);
      }
      div.innerHTML = '';

      let activeLinkValue = null;
      if (typeof column.getCurrentHeaderLinkValue === 'function') {
        activeLinkValue = column.getCurrentHeaderLinkValue();
      }
      column.header.links.forEach((link) => {
        const button = document.createElement('a');
        button.classList.add('d-inline-block', 'mr-2');
        if (link.value !== activeLinkValue) {
          button.classList.add('text-black');
        }
        button.innerText = link.text;
        button.onclick = () => {
          this.$emit('click:columnHeaderLink', { column, link });
        };
        div.appendChild(button);
      });
    },
  },
};
