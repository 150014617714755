import { ActionTree } from 'vuex';

import { ActivitiesGpsState } from '@/activities/gpsTrack/store/types';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';

const actions: ActionTree<ActivitiesGpsState, RootState> = {
  ...subscribableStore.actions,
};

export default actions;
