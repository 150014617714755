
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { type PropType, defineComponent } from 'vue';

library.add(faCircleNotch);

export default defineComponent({
  name: 'Loading',
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String as PropType<'primary' | 'secondary' | 'success' | 'warning' | 'danger'>,
      required: false,
      default: 'primary',
    },
  },
});
