export type MenuItem = {
  id: string;
  title: String;
};

export type ColorFieldItem = {
  title: string | undefined;
  value: string;
};

export type LayerItem = {
  title: string;
  selected: boolean;
  type: LayerType;
};

export enum FieldColor {
  CROP = 'crop',
  VARIETY = 'variety',
  ACTIVITY = 'activity',
  NDVI = 'ndvi',
  MBI = 'mbi',
  DEFAULT = 'default',
}

export enum LayerType {
  FIELD_UTILIZATION = 'field-utilization',
  LAND_CADASTRES = 'land-cadastres',
  ISOLATION_ZONES = 'isolation-zones',
  MULTIPLIER_GROUPS = 'multiplier-groups',
}

export enum MenuItemId {
  ISOLATION_ZONES = 'isolation-zones',
  MULTIPLIER_GROUPS = 'multiplier-groups',
  CREATE_REPORT = 'create-report',
}

export type SelectionSource = 'MAP' | 'TABLE';
