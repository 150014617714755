import Vue from 'vue';

import AggregationService from '@/activities/modals/createEditActivity/services/AggregationService';
import { CreateEditActivityState } from '@/activities/modals/createEditActivity/store/types';

import calcTimeOnField from './calcTimeOnField';

export default function distributeTimeToActivities(
  state: CreateEditActivityState,
  data: {
    time: number;
    propertyKey: 'workingTime' | 'drivingTime' | 'setupTime' | 'pauseTime';
  },
): void {
  const { activities } = state;
  if (activities.length === 1) {
    Vue.set(activities[0], data.propertyKey, data.time);
    return;
  }

  const { sumProcessedArea } = new AggregationService(activities);

  activities.forEach((activity) => {
    const processedArea = activity.processedArea ?? 0;
    const timeOnProcessedArea = calcTimeOnField(data.time, processedArea, sumProcessedArea);

    Vue.set(activity, data.propertyKey, timeOnProcessedArea);
  });
}
