
import { PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import NutrientAmountSelection from '@/precision-farming/application-maps/components/NutrientAmountSelection.vue';
import {
  FertilizationCalculation,
  FertilizationNutrients,
} from '@/precision-farming/application-maps/fertilization/store/types';
import { FertilizationCerealsNutrients } from '@/precision-farming/application-maps/fertilizationCereals/store/types';
import { ApplicationMapsState } from '@/precision-farming/application-maps/store/types';
import { RootState } from '@/store/types';

export default defineComponent({
  name: 'NutrientAmountSelectionContainer',
  components: { NutrientAmountSelection },
  props: {
    workflowKey: {
      type: String as PropType<string>,
      required: true,
    },
    disableAmountLine: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    ...mapState({
      calculation(state: RootState): FertilizationCalculation {
        return state.precisionFarming.applicationMaps[this.workflowKey as keyof ApplicationMapsState]
          .calculation as FertilizationCalculation;
      },
    }),
    calculationNutrients() {
      const nutrients: FertilizationNutrients = {
        n: this.calculation.n,
        p: this.calculation.p,
        k: this.calculation.k,
      };
      return nutrients;
    },
  },
  methods: {
    updateNutrient(key: keyof FertilizationCerealsNutrients, value: number | null) {
      const nutrientValue = value ? value / 100 : null;
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setCalculationField`, {
        key,
        value: nutrientValue,
      });
    },
    updateNutrientAmount(key: keyof FertilizationCerealsNutrients, value: number | null) {
      let averageDosage = Math.max(0, value ?? 0);
      if (this.calculation[key] > 0) {
        averageDosage /= this.calculation[key];
      }
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setCalculationField`, {
        key: 'averageDosage',
        value: averageDosage,
      });
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setManualDosage`, null);
    },
  },
});
