
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import ResultTable from '@/precision-farming/application-maps/spraying/components/ResultTable.vue';
import type {
  ApplicationMapsSprayingState,
  SummaryData,
} from '@/precision-farming/application-maps/spraying/store/types';
import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';
import ResetButton from '@/shared/components/buttons/ResetButton.vue';

export default defineComponent({
  name: 'ResultTableContainer',
  components: { MultiRootWrapper, ResetButton, ResultTable },
  computed: {
    ...mapState('precisionFarming/applicationMaps/spraying', {
      reduction(state: ApplicationMapsSprayingState) {
        return state.calculation.reduction;
      },
      sprayMix(state: ApplicationMapsSprayingState) {
        return state.calculation.sprayMix;
      },
      products(state: ApplicationMapsSprayingState) {
        return state.calculation.products;
      },
    }),
    ...mapGetters('precisionFarming/applicationMaps/spraying', [
      'zones',
      'areas',
      'reducedSprayMixPerZone',
      'reducedVegetationPerZone',
      'sprayMixMid',
      'isOverwritten',
    ]),
    ...mapActions('precisionFarming/applicationMaps/spraying', ['resetOverwrite']),
    summaryData: {
      get(): SummaryData {
        return {
          reducedSprayMixPerZone: this.reducedSprayMixPerZone as number[],
          reducedVegetationPerZone: this.reducedVegetationPerZone as number[],
        };
      },
      set(summaryData: SummaryData) {
        // Unfortunately. I cannot map this aciton like I did above with resetOverwrite, because compiler complains as this is a different scope than the component's --> downside of using the Options API
        this.$store.dispatch('precisionFarming/applicationMaps/spraying/updateOverwrite', { summaryData });
      },
    },
  },
});
