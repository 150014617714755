import { render, staticRenderFns } from "./ChartAxisBottom.vue?vue&type=template&id=774b6927&scoped=true&lang=html&"
import script from "./ChartAxisBottom.vue?vue&type=script&lang=js&"
export * from "./ChartAxisBottom.vue?vue&type=script&lang=js&"
import style0 from "./ChartAxisBottom.vue?vue&type=style&index=0&id=774b6927&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774b6927",
  null
  
)

export default component.exports