
import { FavouriteProduct, FavouriteProducts } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import numbro from '@/initNumbro';
import { Product, Unit } from '@/shared/api/rest/models';
import Button from '@/shared/components/buttons/Button.vue';

export default defineComponent({
  name: 'FavouriteProducts',
  components: { Button },

  props: {
    favouriteProducts: {
      type: Array as PropType<FavouriteProducts[]>,
      required: true,
    },
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    units: {
      type: Object as PropType<Record<string, Unit>>,
      required: true,
    },
    processedArea: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  computed: {
    filterEmptyFavouriteProducts(): FavouriteProducts[] {
      return this.favouriteProducts.filter((favouriteProduct) => !!favouriteProduct.products);
    },
  },
  methods: {
    productName(favouriteProduct: FavouriteProduct): string {
      const product = this.products.find((p: Product) => p.id === favouriteProduct.productId) || null;
      const unitId = favouriteProduct?.unitId || '';
      const unitName = this.units[unitId]?.name || '';
      const amount = favouriteProduct.amount
        ? numbro(favouriteProduct.amount).format({ thousandSeparated: true, mantissa: 2 })
        : '';
      return `${product?.name} ${amount} ${unitName}`;
    },
    addFavouriteProducts(favouriteProducts: FavouriteProducts) {
      this.$emit('addFavouriteProducts', favouriteProducts);
    },
  },
});
