import { ValidationObserver, ValidationProvider, extend, setInteractionMode } from 'vee-validate';
import { email, min, length as ruleLength, required as ruleRequired } from 'vee-validate/dist/rules';
import Vue from 'vue';

setInteractionMode('passive');

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
extend('length', ruleLength);
extend('min', min);

extend('email', {
  ...email,
  message: Vue.i18n.translate('Die E-Mail-Adresse ist ungültig.'),
});

extend('min', {
  validate: (value, { length }) => value.length >= length,
  params: ['length'],
  message: Vue.i18n.translate('{field} muss mindestens {length} Zeichen lang sein.', {
    field: '{_field_}',
    length: '{length}',
  }),
});

extend('required', {
  ...ruleRequired,
  message: Vue.i18n.translate('{field} ist ein Pflichtfeld.', { field: '{_field_}' }),
});

extend('FormFieldDropdownRequired', {
  validate: (value) => value != null && value.id != null,
  message: Vue.i18n.translate('{field} ist ein Pflichtfeld.', { field: '{_field_}' }),
});

extend('errorBackend', {
  computesRequired: true,
  validate(value, { message }) {
    return message;
  },
  params: ['message'],
});
