import Handsontable from 'handsontable';
import numbro from 'numbro';

import { AmountUnitPair } from '@/activities/types';
import ReadOnlyRenderer from '@/shared/handsontable/renderers/ReadOnlyRenderer';
import RequiredRenderer from '@/shared/handsontable/renderers/RequiredRenderer';
import TextRenderer from '@/shared/handsontable/renderers/TextRenderer';

import applyMergeRowsBorderStyles from '../../features/nestedTable/utils/applyMergeRowsBorderStyles';
import applySubtableBorderStyles from '../../features/nestedTable/utils/applySubtableRendererBorderStyles';
import { ColumnSettingsAmountUnit } from './types';

export default function AmountUnitRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: AmountUnitPair | null,
  cellProperties: ColumnSettingsAmountUnit,
  ...args: unknown[]
) {
  if (cellProperties.required && value?.amount === null) {
    RequiredRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args);
    return;
  }

  const productId = instance.getDataAtRowProp(visualRow, 'product.productId');
  const amountFormatted =
    typeof value?.amount === 'number'
      ? numbro(value.amount).format({
          thousandSeparated: true,
          mantissa: cellProperties.getNDecimals(productId),
        })
      : '';
  const unitName = value?.unitId ? cellProperties.getUnitName(value.unitId) ?? '' : '';
  const dot = value?.isFixed ? '•' : '';
  const displayValue = `${amountFormatted} ${unitName} ${dot}`;

  applySubtableBorderStyles(TD, visualRow, cellProperties, instance);
  applyMergeRowsBorderStyles(TD, visualRow, instance);

  if (cellProperties.readOnly) {
    ReadOnlyRenderer(instance, TD, visualRow, visualCol, prop);
  }
  TextRenderer(instance, TD, visualRow, visualCol, prop, displayValue, cellProperties, ...args);
}
