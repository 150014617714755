
import Vue, { ComponentPublicInstance, PropType, defineComponent } from 'vue';

import FilterInfoBar from './FilterInfoBar.vue';

export default defineComponent({
  name: 'FilterInfoBarInjector',
  props: {
    filterActive: { type: Boolean, default: false },
    tableRef: { type: Object as PropType<ComponentPublicInstance>, required: true },
  },
  watch: {
    filterActive: {
      handler() {
        if (this.filterActive) {
          this.mountFilterInfoBar();
        } else {
          this.unmountFilterInfoBar();
        }
      },
      immediate: true,
    },
  },
  methods: {
    mountFilterInfoBar() {
      const tableElement = this.tableRef.$el;
      let top = 0;
      const thead = tableElement.querySelector('.ht_master .htCore thead');
      if (thead != null) {
        top = thead.getBoundingClientRect().height;
      }

      const wtHolder = tableElement.querySelector('.ht_master .wtHolder');
      if (wtHolder == null) return;

      const FilterInfoBarClass = Vue.extend(FilterInfoBar);

      const mainTableDiv = document.createElement('div');
      wtHolder.prepend(mainTableDiv);
      new FilterInfoBarClass({
        propsData: { zIndex: 999, top },
        methods: { onReset: this.onReset },
      }).$mount(mainTableDiv);

      const wtHolderCloneLeft = tableElement.querySelector('.ht_clone_left .wtHolder');
      if (wtHolderCloneLeft == null) return;

      const cloneLeftDiv = document.createElement('div');
      wtHolderCloneLeft.prepend(cloneLeftDiv);
      new FilterInfoBarClass({
        propsData: { zIndex: 99, top },
        methods: { onReset: this.onReset },
      }).$mount(cloneLeftDiv);
    },
    unmountFilterInfoBar() {
      const tableElement = this.tableRef.$el;
      const filterInfoBars = tableElement.querySelectorAll('.filter-table__filter-bar');

      filterInfoBars.forEach((filterInfoBar) => {
        filterInfoBar.remove();
      });
    },
    onReset(event: Event) {
      event.preventDefault();
      this.$emit('onReset');
    },
  },
});
