<script>
const FARMDOK_SHOW_DEBUG_COMPONENTS = 'FARMDOK_SHOW_DEBUG_COMPONENTS';

document.addEventListener('keydown', (event) => {
  if (event.code !== 'KeyB' || !event.ctrlKey || !event.altKey) {
    return;
  }
  let show;
  try {
    show = JSON.parse(localStorage.getItem(FARMDOK_SHOW_DEBUG_COMPONENTS));
  } catch (error) {
    show = false;
  }
  localStorage.setItem(FARMDOK_SHOW_DEBUG_COMPONENTS, JSON.stringify(!show));
});

export default {
  name: 'DebugComponentWrapper',
  data() {
    return {
      show: false,
      instance: window.location.hostname.split('.')[0],
    };
  },
  created() {
    this.updateShow();
    document.addEventListener('keydown', this.updateShow);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.updateShow);
  },
  methods: {
    updateShow() {
      if (this.instance === 'app') {
        this.show = false;
        return;
      }
      try {
        this.show = JSON.parse(localStorage.getItem(FARMDOK_SHOW_DEBUG_COMPONENTS));
      } catch (error) {
        this.show = false;
      }
    },
  },
  render() {
    if (!this.show) {
      return null;
    }
    if (Array.isArray(this.$slots.default)) {
      if (this.$slots.default.length > 1) {
        console.error(
          'DebugComponentWrapper: multiple root nodes in default template. This component supports only a single root node.',
        );
      }
      return this.$slots.default[0];
    }
    return null;
  },
};
</script>
