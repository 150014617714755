import { ProductCategory } from 'farmdok-rest-api';

import { sortDropdownItems } from '@/products/store/getterUtils/sortProducts';
import { Product } from '@/shared/api/rest/models';
import { DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

export default function groupProductsByCategory(
  products: Product[],
  productCategories: ProductCategory[],
): DropdownItemsPerCategory[] {
  if (productCategories.length === 0) return [{ items: products }];

  const itemsPerCategory: Record<string, DropdownItemsPerCategory> = {};

  const addCategory = (category: ProductCategory) => {
    itemsPerCategory[category.id] = {
      name: category.name,
      id: category.id,
      items: [],
    };
  };

  const addProduct = (product: Product) => {
    itemsPerCategory[product.categoryId].items.push(product);
  };

  products.forEach((product) => {
    if (!itemsPerCategory[product.categoryId]) {
      const productCategory = productCategories.find((category) => category.id === product.categoryId);
      if (productCategory) {
        addCategory(productCategory);
      }
    }
    addProduct(product);
  });

  const dropDownItemsPerCategory = Object.values(itemsPerCategory);
  return sortDropdownItems(dropDownItemsPerCategory);
}
