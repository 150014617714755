
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { PropType, defineComponent } from 'vue';

import { MergedActivityEquipment } from '@/activities/modals/createEditActivity/services/MergedActivityEquipmentService';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import { DropdownItem, DropdownItemsPerCategory } from '@/shared/components/form/formFieldDropdownTypes';

import RemoveSectionButton from './RemoveSectionButton.vue';

library.add(faTrashAlt);

export default defineComponent({
  name: 'EquipmentSelectSection',
  components: { FormFieldSet, FormFieldDropdown, RemoveSectionButton },
  props: {
    dropdownItem: {
      type: Object as PropType<DropdownItem>,
      required: true,
    },
    dropdownItemsPerCategory: {
      type: Array as PropType<DropdownItemsPerCategory[]>,
      required: true,
    },
    activityEquipment: {
      type: Object as PropType<MergedActivityEquipment>,
      required: true,
    },
    fieldNamesPerActivity: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },
  computed: {
    headline(): string {
      if (this.dropdownItem.name !== '') {
        if (this.activityEquipment.activityIds.length !== Object.keys(this.fieldNamesPerActivity).length) {
          if (this.activityEquipment.activityIds.length === 1) {
            return `${this.dropdownItem.name} (${this.fieldNamesPerActivity[this.activityEquipment.activityIds[0]]})`;
          }
          return `${this.dropdownItem.name} (${this.$t('verschiedene')})`;
        }

        return this.dropdownItem.name;
      }

      return this.$t('Maschine') as string;
    },
  },
  methods: {
    update(dropdownValue: DropdownItem): void {
      const activityEquipment: MergedActivityEquipment = {
        ...this.activityEquipment,
        equipmentId: dropdownValue.id ?? '',
      };

      this.$emit('update:activityEquipment', activityEquipment);
    },
    remove(): void {
      this.$emit('remove:activityEquipment', this.activityEquipment);
    },
  },
});
