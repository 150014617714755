
import { StockLevel } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import StorageSelection from './StorageSelection.vue';
import { StorageSelection as StorageSelectionType } from './types';

export default defineComponent({
  name: 'StorageSelectSection',
  components: { StorageSelection },
  props: {
    currentStorageSelection: {
      type: Object as PropType<StorageSelectionType>,
      default: null,
    },
    otherStorageSelections: {
      type: Array as PropType<StorageSelectionType[]>,
      default: [] as StorageSelectionType[],
    },
  },
  methods: {
    applyStockLevel(stockLevel: StockLevel): void {
      this.$emit('applyStockLevel', stockLevel);
    },
    addProductWithStockLevel(stockLevel: StockLevel): void {
      this.$emit('addProductWithStockLevel', stockLevel);
    },
  },
});
