import Handsontable from 'handsontable';

import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';

import convertToComparatorReturnValue from '../../features/columnSorting/convertToComparatorReturnValue';

function compareFunctionFactory(
  sortOrder: Handsontable.columnSorting.SortOrderType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  columnMeta: Handsontable.GridSettings,
) {
  return function comparator(value: DropdownItem | null, nextValue: DropdownItem | null): 0 | 1 | -1 {
    const name = value?.name ?? '';
    const nameNext = nextValue?.name ?? '';

    const result = name.localeCompare(nameNext);
    if (sortOrder === 'asc') {
      return convertToComparatorReturnValue(result);
    }
    return convertToComparatorReturnValue(result * -1);
  };
}

export default {
  compareFunctionFactory,
};
