<template>
  <ModalWrapper
    ref="modalWrapper"
    size="max"
    body-class="modal-split-field"
    :visible="visible"
    :title="$t('Feld aufteilen')"
    :map-full="currentStep === 0"
    @change="hide"
  >
    <template #default>
      <Pagination :pages="3" :current="currentStep" />
      <div v-if="currentStep === 1">
        <FormInfoContainer :headline="$t('Ergebnis anpassen')">
          <p>
            {{ $t('Das Feld wurde erfolgreich geteilt.') }}
            {{ $t('Du kannst die Eigenschaften der neuen Felder noch anpassen.') }}
            <span v-if="measuresExist">
              {{ $t('Auf welches Feld sollen bereits gebuchte Maßnahmen übernommen werden?') }}
            </span>
          </p>
          <FormFieldRadioGroup
            v-if="measuresExist"
            v-model="measuresAction"
            variant="no-label"
            :options="[
              {
                text: $t('Maßnahmen auf {fieldName} ({fieldSize} ha) übernehmen', {
                  fieldName: getNameForField(newFieldA),
                  fieldSize: getFormattedSizeForField(newFieldA),
                }),
                value: newFieldA.id,
                disabled: fetching,
              },
              {
                text: $t('Maßnahmen auf {fieldName} ({fieldSize} ha) übernehmen', {
                  fieldName: getNameForField(newFieldB),
                  fieldSize: getFormattedSizeForField(newFieldB),
                }),
                value: newFieldB.id,
                disabled: fetching,
              },
              { text: $t('Maßnahmen auf beide Felder kopieren'), value: 'both', disabled: fetching },
              { text: $t('Maßnahmen löschen'), value: 'delete', disabled: fetching },
            ]"
          />
        </FormInfoContainer>
        <FormFieldSet
          v-for="field in [newFieldA, newFieldB]"
          collapsible
          :key="field.id"
          :headline="getNameForField(field)"
          :collapsed="currentField !== field.id"
          @update="(collapsed) => updateFormFieldCollapsed(field, collapsed)"
        >
          <FormFieldInput
            v-model="field['fieldGroup.mfa']"
            :label="$t('Feldstück Nr')"
            :placeholder="$t('Feldstück Nr')"
            :disabled="fetching"
          />
          <FormFieldInput
            v-if="useFieldNumber"
            v-model="field.fieldNumber"
            :label="$t('Feld-Nummer')"
            :placeholder="$t('Feld-Nummer')"
            :disabled="fetching"
          />
          <FormFieldInput
            v-model="field.name"
            required
            :label="$t('Bezeichnung')"
            :placeholder="$t('Pflichtfeld')"
            :state="missingName != null && (typeof field.name !== 'string' || field.name.length === 0) ? false : null"
            :disabled="fetching"
            @update="missingName = null"
          />
          <FormFieldInput
            v-model="field.fieldSize"
            type="number"
            placeholder="0"
            step="0.01"
            :label="$t('Größe (in ha)')"
            :disabled="fetching"
            @blur="field.fieldSize = fieldSizeDecimals(field.fieldSize)"
          />
          <FormFieldDropdown
            :label="$t('Kultur')"
            :value="cropValueForDropdown(field)"
            :items="cropsToDropdownItems"
            :items-loading="$store.state.products.crops.fetching"
            :disabled="fetching"
            @update="(value) => (field.cropId = value.id)"
          />
        </FormFieldSet>
      </div>
      <div v-if="currentStep === 2">
        <FormInfoContainer :headline="$t('Erledigt')">
          <p>
            {{ $t('Die Felder wurden erfolgreich erstellt.') }}
          </p>
        </FormInfoContainer>
      </div>
    </template>
    <template #map-container>
      <FieldsMap
        v-if="initialized"
        class="modal-split-field__map"
        ref="map"
        hide-all-map-actions
        :crops="crops"
        :disabledFields="disabledFields"
        :activeFields="activeFields"
        :editableFields="editableFields"
        :splitFieldEnabled="currentStep === 0"
        :splitFieldLineValid.sync="validSplitLine"
        @update:newFieldPolygons="updateNewFieldPolygons"
        @action:split="split"
        @click:polygon="(fieldId) => (currentField = fieldId)"
        @click:marker="(fieldId) => (currentField = fieldId)"
        @change:polygon="changePolygon"
        @change:marker="changeMarker"
        @change:fieldName="changeFieldName"
      />
      <div v-else />
    </template>
    <template #bottom-content>
      <p v-if="missingName" class="text-danger m-0">
        <span>{{ $t('Bezeichnung fehlt.') }}</span>
      </p>
      <ErrorUserMessage v-else :error-user-message="userErrorMessage" class="m-0" show-first-only />
    </template>
    <template #buttons>
      <BButton v-if="currentStep === 0" variant="primary" class="mr-3" :disabled="!validSplitLine" @click="split">
        <span>{{ $t('Aufteilen') }}</span>
      </BButton>
      <BButton v-if="currentStep === 1" variant="primary" class="mr-3" @click="save">
        <span>
          {{ $t('Speichern') }}
          <FontAwesomeIcon v-if="fetching" icon="circle-notch" spin />
        </span>
      </BButton>
      <BButton v-if="currentStep === 1" variant="white" :disabled="fetching" @click="back">
        <span>{{ $t('Zurück') }}</span>
      </BButton>
      <BButton v-if="currentStep < 2" variant="white" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
      <BButton v-if="currentStep === 2" variant="primary" @click="hide">
        <span>{{ $t('Schließen') }}</span>
      </BButton>
    </template>
    <template #footer-content-right>
      <InfoContainer v-if="currentStep === 0 && validSplitLine == null">
        <!-- eslint-disable max-len -->
        {{
          $t(
            'Klicke in der Karte um eine Trennlinie zu zeichnen. Klicke zwei mal schnell hintereinander um das Zeichnen zu beenden.',
          )
        }}
        <!-- eslint-enable max-len -->
      </InfoContainer>
      <InfoContainer v-if="currentStep === 0 && validSplitLine === true">
        {{ $t('Klicke auf "Aufteilen" um zwei neue Felder zu erstellen.') }}
        {{ $t('Du kannst die Trennlinie durch Ziehen der Eckpunkte anpassen.') }}
      </InfoContainer>
      <InfoContainer v-if="currentStep === 0 && validSplitLine === false" error>
        {{
          $t(
            'Die aktuelle Trennlinie ist ungülitg. Zeichne eine Linie die das aktuelle Feld in genau zwei Teile teilt.',
          )
        }}
        {{ $t('Du kannst die Trennlinie durch Ziehen der Eckpunkte anpassen.') }}
      </InfoContainer>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import area from '@turf/area';
import axios from 'axios';
import numbro from 'numbro';
import { mapGetters } from 'vuex';

import cropsToDropdownItems from '@/products/crops/mixins/containers/cropsToDropdownItems';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import InfoContainer from '@/shared/components/InfoContainer.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Pagination from '@/shared/components/Pagination.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormFieldSet from '@/shared/components/form/FormFieldSet.vue';
import FormInfoContainer from '@/shared/components/form/FormInfoContainer.vue';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';
import { requestDataCreateFromEntryAndModel } from '@/shared/modules/restApiHelpers';
import { createUuid } from '@/shared/modules/uuid';

import { customer as columnCustomer } from '../handsontable/columns/columns';
import fieldSizeDecimals from '../mixins/fieldSizeDecimals';
import { model, modelDefinition } from '../store/common';
import FieldsMap from './FieldsMap.vue';

library.add(faCircleNotch);

export default {
  name: 'ModalSplitField',
  components: {
    ErrorUserMessage,
    FormFieldDropdown,
    FormFieldInput,
    FormFieldSet,
    FormFieldRadioGroup,
    InfoContainer,
    FieldsMap,
    FormInfoContainer,
    ModalWrapper,
    Pagination,
  },
  mixins: [backendResponseErrorHelpers, fieldSizeDecimals, cropsToDropdownItems],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fieldId: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      default: null,
      validator(columns) {
        if (columns == null) {
          return true;
        }
        if (!Array.isArray(columns)) {
          return false;
        }
        return columns.every(
          (column) => column != null && typeof column.key === 'string' && typeof column.visible === 'boolean',
        );
      },
    },
  },
  data() {
    return {
      initialized: false,
      currentStep: 0,
      validSplitLine: undefined,

      newFieldA: null,
      newFieldB: null,
      currentField: null,
      measuresAction: 'both',

      missingName: null,

      fetching: false,
      source: null,
    };
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('products/crops/subscribe'),
      this.$store.dispatch('fields/subscribe'),
      this.$store.dispatch('activities/subscribe'),
    ]);

    // do not render map before activeFields are set, otherwise the initial map centering isn't working
    this.initialized = true;
  },
  computed: {
    ...mapGetters({
      crops: 'products/crops/data',
      fields: 'fields/dataWithoutRemoved',
      measures: 'activities/data',
    }),
    disabledFields() {
      return Object.values(this.fields)
        .filter((field) => this.currentStep > 0 || field.id !== this.fieldId)
        .reduce(
          (accumulator, field) => ({
            ...accumulator,
            [field.id]: field,
          }),
          {},
        );
    },
    activeFields() {
      const activeFields = {};
      if (this.currentStep === 0) {
        activeFields[this.fieldId] = this.fields[this.fieldId];
      }
      if (this.currentStep === 1 && this.currentField !== this.newFieldA.id) {
        activeFields[this.newFieldA.id] = this.newFieldA;
      }
      if (this.currentStep === 1 && this.currentField !== this.newFieldB.id) {
        activeFields[this.newFieldB.id] = this.newFieldB;
      }
      if (this.currentStep === 2) {
        activeFields[this.newFieldA.id] = this.newFieldA;
        activeFields[this.newFieldB.id] = this.newFieldB;
      }
      return activeFields;
    },
    editableFields() {
      if (this.currentStep !== 1) {
        return {};
      }
      if (this.currentField === this.newFieldA.id) {
        return {
          [this.newFieldA.id]: this.newFieldA,
        };
      }
      if (this.currentField === this.newFieldB.id) {
        return {
          [this.newFieldB.id]: this.newFieldB,
        };
      }
      return {};
    },
    columnsByKey() {
      if (this.columns == null) {
        return {};
      }
      return this.columns.reduce(
        (columnsByKey, column) => ({
          ...columnsByKey,
          [column.key]: column,
        }),
        {},
      );
    },
    measuresExist() {
      if (Object.values(this.measures).length === 0) {
        return false;
      }
      return Object.values(this.measures).some((measure) => measure.fieldId === this.fieldId);
    },
    companies() {
      return this.$store.state.auth.currentCompanies;
    },
    companyOptions() {
      return [
        { value: null, text: this.$t('Betrieb wählen'), disabled: true },
        ...this.companies.map((company) => ({ value: company.id, text: company.name })),
      ];
    },
    useCustomers() {
      if (this.customers == null) {
        return false;
      }
      return (
        this.columnsByKey[columnCustomer.key] != null &&
        this.columnsByKey[columnCustomer.key].visible &&
        Object.keys(this.customers).length > 0
      );
    },
    useFieldNumber() {
      return this.columnsByKey.fieldNumber != null && this.columnsByKey.fieldNumber.visible;
    },
    customerValueForDropdown() {
      const customerId = this.newField['fieldGroup.customerId'];
      if (customerId === 'own') {
        return { id: 'own', name: this.$t('Eigener Betrieb') };
      }
      if (this.customers[customerId] != null) {
        return {
          id: customerId,
          name: this.customers[customerId].name,
        };
      }
      return { id: null, name: '' };
    },
    customersToDropdownItems() {
      return [
        {
          id: 'own-company',
          items: [{ id: 'own', name: this.$t('Eigener Betrieb') }],
          sort: false,
        },
        {
          name: this.$t('Kunden'),
          id: 'customers',
          items: Object.values(this.customers),
          sort: true,
        },
      ];
    },
  },
  methods: {
    getNewFieldForGeoJson(geoJson) {
      const [lon, lat] = geoJson.coordinates[0].reduce(
        ([totalLon, totalLat], [nextLon, nextLat]) => [totalLon + nextLon, totalLat + nextLat],
        [0, 0],
      );
      const fieldSize = Math.floor(area(geoJson)) / 10000;
      return {
        ...this.fields[this.fieldId],
        id: createUuid(),
        lon: lon / geoJson.coordinates[0].length,
        lat: lat / geoJson.coordinates[0].length,
        fieldContour: {
          geoJson,
          area: fieldSize,
        },
        fieldSize,
      };
    },
    updateNewFieldPolygons({ geoJsonA, geoJsonB }) {
      this.newFieldA = this.getNewFieldForGeoJson(geoJsonA);
      this.newFieldB = this.getNewFieldForGeoJson(geoJsonB);
    },
    changePolygon({ fieldContour, fieldId }) {
      const fieldSize = Math.floor(area(fieldContour.geoJson)) / 10000;
      if (fieldId === this.newFieldA.id) {
        this.newFieldA = {
          ...this.newFieldA,
          fieldSize,
          fieldContour: {
            geoJson: fieldContour.geoJson,
            area: fieldSize,
          },
        };
      } else if (fieldId === this.newFieldB.id) {
        this.newFieldB = {
          ...this.newFieldB,
          fieldSize,
          fieldContour: {
            geoJson: fieldContour.geoJson,
            area: fieldSize,
          },
        };
      }
    },
    changeMarker({ lat, lon, fieldId }) {
      if (fieldId === this.newFieldA.id) {
        this.newFieldA = {
          ...this.newFieldA,
          lat,
          lon,
        };
      } else if (fieldId === this.newFieldB.id) {
        this.newFieldB = {
          ...this.newFieldB,
          lat,
          lon,
        };
      }
    },
    changeFieldName({ fieldId, name }) {
      if (fieldId === this.newFieldA.id) {
        this.newFieldA = {
          ...this.newFieldA,
          name,
        };
      } else if (fieldId === this.newFieldB.id) {
        this.newFieldB = {
          ...this.newFieldB,
          name,
        };
      }
    },
    getNameForField(field) {
      if (typeof field.name === 'string' && field.name.length > 0) {
        return field.name;
      }
      if (field.id === this.newFieldA.id) {
        return this.$t('Feld {number}', { number: 1 });
      }
      if (field.id === this.newFieldB.id) {
        return this.$t('Feld {number}', { number: 2 });
      }
      return this.$t('Feld');
    },
    getFormattedSizeForField(field) {
      let fieldSize = Number(field.fieldSize);
      if (Number.isNaN(fieldSize)) {
        fieldSize = 0;
      }
      return numbro(fieldSize).format();
    },
    getCropName(field) {
      const { cropId } = field;
      if (this.crops[cropId] == null) {
        return '';
      }
      return this.crops[cropId].name;
    },
    split() {
      if (this.newFieldA == null || this.newFieldB == null) {
        return;
      }
      this.currentStep = 1;
      this.currentField = this.newFieldA.id;
    },
    updateFormFieldCollapsed(field, collapsed) {
      if (!collapsed) {
        this.currentField = field.id;
      } else if (collapsed && this.currentField === field.id) {
        this.currentField = null;
      }
    },
    cropValueForDropdown(field) {
      if (field.cropId == null || this.crops[field.cropId] == null) {
        return { id: null, name: '' };
      }
      return { id: field.cropId, name: this.crops[field.cropId].name };
    },
    back() {
      this.currentStep = 0;
    },
    async save() {
      if (
        typeof this.newFieldA.name !== 'string' ||
        this.newFieldA.name.length < 1 ||
        typeof this.newFieldB.name !== 'string' ||
        this.newFieldB.name.length < 1
      ) {
        this.missingName = true;
        return;
      }
      this.missingName = false;
      this.userErrorMessage = null;
      this.fetching = true;

      const newFieldA = requestDataCreateFromEntryAndModel(this.newFieldA, model, modelDefinition);
      newFieldA.id = this.newFieldA.id;
      newFieldA.fieldContour = this.newFieldA.fieldContour;
      delete newFieldA.fieldContourId;
      const newFieldB = requestDataCreateFromEntryAndModel(this.newFieldB, model, modelDefinition);
      newFieldB.id = this.newFieldB.id;
      newFieldB.fieldContour = this.newFieldB.fieldContour;
      delete newFieldB.fieldContourId;

      try {
        this.source = axios.CancelToken.source();
        const { data } = await axios.post(
          '/admin/rest/field/split_field',
          {
            data: {
              fieldId: this.fieldId,
              measuresAction: this.measuresAction,
              newFields: [newFieldA, newFieldB],
            },
          },
          { cancelToken: this.source.token },
        );
        if (data == null || data.status !== 'success') {
          this.setUserErrorMessageFromResponse(data);
          this.fetching = false;
          return;
        }
      } catch (error) {
        this.setUserErrorMessageFromResponse(error);
        this.fetching = false;
        return;
      }
      await this.$store.dispatch('fields/refresh');
      this.$emit('split', [this.newFieldA.id, this.newFieldB.id]);
      this.fetching = false;
      this.currentStep = 2;
    },
    hide() {
      if (this.source != null) {
        this.source.cancel();
      }
      this.$emit('change', false);
    },
  },
};
</script>

<style scoped></style>
