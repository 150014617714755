import Handsontable from 'handsontable';

import formatDuration from './formatDuration';
import parseDurationStringToSeconds from './parseDurationStringToSeconds';

// @ts-ignore
export default class DurationEditor extends Handsontable.editors.TextEditor {
  beginEditing(initialValue?: any, event?: Event | undefined): void {
    if (initialValue == null && Handsontable.helper.isNumeric(this.originalValue)) {
      const value = formatDuration(this.originalValue);

      super.beginEditing(value, event);
    } else {
      super.beginEditing(initialValue, event);
    }
  }

  saveValue(val?: any, ctrlDown?: boolean | undefined): void {
    const parsedValue = parseDurationStringToSeconds(val[0][0]);
    super.saveValue([[parsedValue]], ctrlDown);
  }
}
