<template>
  <SidebarContentWrapper>
    <SidebarSubHeadline class="mb-3" :text="$t('Bedarf')" />
    <div class="result-total d-flex justify-content-between">
      <span>{{ $t('Flächensumme') }}</span>
      <span>{{ zoneSizesTotal | numbro }} ha</span>
    </div>
    <div class="result-total d-flex justify-content-between">
      <span>{{ additionalTotal.heading }}</span>
      <span>{{ additionalTotal.amount | numbro({ mantissa: 0 }) }} {{ additionalTotal.unit }}</span>
    </div>
    <p class="additional-information" v-if="additionalInformation != null">{{ additionalInformation }}</p>
  </SidebarContentWrapper>
</template>

<script>
import SidebarContentWrapper from './SidebarContentWrapper.vue';
import SidebarSubHeadline from './SidebarSubHeadline.vue';

export default {
  name: 'RequiredAmountTable',
  components: { SidebarContentWrapper, SidebarSubHeadline },
  props: {
    zoneSizesTotal: {
      type: Number,
      default: 0,
    },
    additionalTotal: {
      type: Object,
      heading: {
        type: String,
        default: '',
      },
      amount: {
        type: Number,
        default: 0,
      },
      unit: {
        type: String,
        default: '',
      },
    },
    additionalInformation: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.result-total {
  margin-top: 1px;
  font-weight: 400;
  font-size: 14px;
  background-color: var(--gray_100);
  height: 37px;
  align-items: center;
  padding: 0px 13px 0px 7px;
}

.additional-information {
  font-size: 12px;
  padding: 7px;
  color: var(--gray_900);
  margin-bottom: 0;
}
</style>
