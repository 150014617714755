import Handsontable from 'handsontable';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import { subscribableStore, syncableStore } from '@/datura/assessments/store/common';
import { UserColumnSettings } from '@/shared/handsontable/rework/types';

import { DaturaAssessmentState } from './types';

const mutations: MutationTree<DaturaAssessmentState> = {
  ...subscribableStore.mutations,
  ...syncableStore.mutations,
  setHot(state, hot: Handsontable | null) {
    Vue.set(state, 'hot', hot);
  },
  setUserColumnSettings(state, userColumnSettings: Record<string, UserColumnSettings>) {
    Vue.set(state, 'userColumnSettings', userColumnSettings);
  },
};

export default mutations;
