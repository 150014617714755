import { mapGetters } from 'vuex';

export type Config = {
  forceRefresh?: boolean;
  forceReset?: boolean;
};

/**
 * Always use this mixin together with shared/handsontable/mixins/containers/tableContainerBase.
 *
 * This mixin automatically connects a handsontable container to a store that uses the subscribableData mixin.
 *
 * Check subscribableData store mixin for info about forceReset and forceRefresh.
 *
 * @param storeName {string}
 * @param forceReset {boolean}
 * @param forceRefresh {boolean}
 * @return {object}
 */
export default function featureSubscribableTableData(
  storeName: string,
  { forceReset = false, forceRefresh = false } = {},
) {
  return {
    data() {
      return {
        subscribableDataUnsubscribe: () => null,
      };
    },
    computed: {
      ...mapGetters({
        loading: `${storeName}/loading`,
        tableData: `${storeName}/data`,
        tableErrors: `${storeName}/errors`,
      }),
    },
    mounted() {
      // @ts-ignore
      this.subscribableDataUnsubscribe = this.$store.subscribe(({ type }: { type: string }) => {
        if (type === 'afterResetData') {
          // @ts-ignore
          this.loadData();
        }
      });
      // @ts-ignore
      this.loadData();
    },
    beforeDestroy() {
      // @ts-ignore
      this.subscribableDataUnsubscribe();
    },
    methods: {
      async loadData() {
        // #412 give the UI some time to catch up
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 30));
        // @ts-ignore
        await this.$store.dispatch('auth/subscribe');
        // @ts-ignore
        await this.$store.dispatch(`${storeName}/subscribe`, { forceReset, forceRefresh });
        await this.loadAdditionalData();
      },
      /**
       * Use this to load additional data before the table will be rendered (i.e. this.loading = false).
       *
       * @override
       * @returns {Promise<void>}
       */
      async loadAdditionalData() {
        return null;
      },
    },
  };
}
