<template>
  <div class="form-empty-content" @click="onEmptyFormClick">
    <FontAwesomeIcon class="mb-3" icon="cloud-upload" size="3x" />
    <span class="label-text-one">{{ $t('ZIEHE DIE DATEI HIER HER') }}</span>
    <span>
      {{ labelUploadInfoParts[0] }}
      <BLink>
        {{ labelUploadInfoParts[1] }}
      </BLink>
      {{ labelUploadInfoParts[2] }}
    </span>
    <span class="mt-3 text-small">
      {{ fileFormatDescription }}
      {{ $t('Max. Dateigröße: {maxFileSize} MB', { maxFileSize: maxFileSize }) }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    supportedFileFormats: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    maxFileSize: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      labelUploadInfo: this.$t('oder klicke {linkStart}Datei auswählen{linkEnd}, um eine Datei hinzuzufügen.', {
        linkStart: '#####',
        linkEnd: '#####',
      }),
    };
  },
  computed: {
    labelUploadInfoParts() {
      return this.labelUploadInfo.split('#####');
    },
    supportedFileFormatDisplayNames() {
      return this.$props.supportedFileFormats.map((format) => format.displayName);
    },
    fileFormatDescription() {
      if (this.supportedFileFormatDisplayNames.length === 1) {
        return this.$t('Es können Dateien im Format {fileFormat} importiert werden.', {
          fileFormat: this.supportedFileFormatDisplayNames[0],
        });
      }
      return this.$t('Es können Dateien in den Formaten {fileFormats} und {lastFileFormat} importiert werden.', {
        fileFormats: this.supportedFileFormatDisplayNames.slice(0, -1).join(', '),
        lastFileFormat: this.supportedFileFormatDisplayNames[this.supportedFileFormatDisplayNames.length - 1],
      });
    },
  },
  methods: {
    onEmptyFormClick() {
      this.$emit('onEmptyFormClick');
    },
  },
};
</script>

<style scoped>
.form-empty-content {
  display: flex;
  width: 100%;
  padding: var(--spacer_3);
  flex-direction: column;
  align-items: center;
}

.label-text-one {
  color: var(--primary_dark);
  font-weight: 600;
  line-height: 1.5;
}
</style>
