<template>
  <Widget v-if="notesVisible" :title="$t('Notizen')" :subtitle="subtitle">
    <Table v-if="notesEnabled" fixed headerless :fields="tableFields" :items="tableItems" :busy="notesFetching">
      <template #empty>
        {{ $t('Noch keine Notizen vorhanden.') }}
      </template>
      <template v-if="!expanded" #cell()="{ value }">
        <span :title="value">{{ value }}</span>
      </template>
      <template v-if="expanded" #cell(userComment)="{ value, item }">
        <span :title="value">{{ value }}</span>
        <div v-if="item.pictures.length > 0" class="mt-2 widget-notes__thumbs">
          <img
            v-for="(picture, index) in item.pictures"
            class="widget-notes__thumb"
            :key="picture.id"
            :src="picture.uriSmallThumb"
            :alt="picture.alt"
            @click="openGallery(item.pictures, index)"
          />
        </div>
      </template>
    </Table>
    <FeatureNotAvailable v-else :add-link-to-shop="canPurchaseLicenses" />
    <ModalImageCarousel v-model="carouselOpen" :images="carouselImages" :start-index="carouselStartIndex" />
  </Widget>
</template>

<script>
import moment from 'moment';

import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import ModalImageCarousel from '@/shared/components/ModalImageCarousel.vue';
import Table from '@/shared/components/Table.vue';
import Widget from '@/shared/components/Widget.vue';
import { origin } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

export default {
  name: 'WidgetNotes',
  components: {
    FeatureNotAvailable,
    Table,
    Widget,
    ModalImageCarousel,
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    selectedFields: {
      type: Array,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    notes: {
      type: Object,
      default: () => ({}),
    },
    notesFetching: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carouselOpen: false,
      carouselImages: [],
      carouselStartIndex: 0,
    };
  },
  computed: {
    notesVisible() {
      if (this.companyIds.length === 0) {
        return false;
      }
      return this.companyIds.some((companyId) =>
        this.$store.getters.companyFeatureVisible(companyId, availableFeatures.FEATURE_NOTES),
      );
    },
    notesEnabled() {
      if (this.companyIds.length === 0) {
        return false;
      }
      return this.companyIds.every((companyId) =>
        this.$store.getters.companyFeatureEnabled(companyId, availableFeatures.FEATURE_NOTES),
      );
    },
    canPurchaseLicenses() {
      if (this.companyIds.length === 0) {
        return false;
      }
      return this.companyIds.every((companyId) =>
        this.$store.getters.companyFeatureEnabled(companyId, availableFeatures.CAN_PURCHASE_LICENSE),
      );
    },
    companyIds() {
      const companyIds = new Set();
      this.$store.state.auth.currentCompanies.forEach((field) => {
        if (field == null || field.id == null) {
          return;
        }
        companyIds.add(field.id);
      });
      return [...companyIds];
    },
    tableFields() {
      return [
        {
          key: 'date',
          width: '85px',
        },
        {
          key: 'userComment',
          tdClass: this.expanded ? '' : 'text-truncate',
        },
      ];
    },
    tableItems() {
      return Object.values(this.notes)
        .filter((note) => this.selectedFields.some((field) => note.fieldId === field.id))
        .map((note) => {
          const { id, userComment } = note;
          return {
            id,
            date: moment(note.creationDate * 1000).format('L'),
            userComment,
            pictures: note.pictures.map((picture) => ({
              id: picture.id,
              uri: origin + picture.uri,
              uriSmallThumb: origin + picture.uriSmallThumb,
            })),
          };
        });
    },
  },
  methods: {
    openGallery(pictures, startIndex) {
      this.carouselOpen = true;
      this.carouselImages = pictures.map((picture) => picture.uri);
      this.carouselStartIndex = startIndex;
    },
  },
};
</script>

<style lang="css" scoped>
.widget-notes__thumbs {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget-notes__thumb {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: var(--spacer_2);
  object-fit: cover;
  cursor: pointer;
  filter: grayscale(0.5);
}

.widget-notes__thumb:hover {
  filter: none;
}
</style>
