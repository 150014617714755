const baseUrl = process.env.VUE_APP_ORIGIN;
const imageBaseUrl = `${baseUrl}/assets/img/`;

export const isValidUrl = (value) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return pattern.test(value);
};

export const fileNameToImageUrl = (fileName, subPath = '') => `${imageBaseUrl}${subPath}/${fileName}`;

export const urlToFile = (url, filename, type) =>
  fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type }));
