import Vue from 'vue';

import '@/initI18n';

import { baseUrl } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

import PageFieldGroups from './PageFieldGroups.vue';
import PageFieldGroupsRestore from './PageFieldGroupsRestore.vue';

const tabs = () => {
  const currentTabs = ['field-groups', 'fields'];
  if (store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CROP_ROTATION)) {
    currentTabs.push('crop-rotation');
  }
  if (store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_SOIL_SAMPLES)) {
    currentTabs.push('soil-samples');
  }
  return currentTabs;
};

export default [
  {
    path: `${baseUrl}field-groups`,
    name: 'field-groups',
    component: PageFieldGroups,
    meta: {
      requiresAuth: true,
      tabs,
      label: Vue.i18n.translate('Feldstücke'),
      featureControl: availableFeatures.FEATURE_FIELDS,
    },
    async beforeEnter(to, from, next) {
      if (from != null && typeof from.name === 'string' && from.name.indexOf('field-groups') !== 0) {
        await store.dispatch('fieldGroups/reset');
      }
      next();
    },
  },
  {
    path: `${baseUrl}field-groups/restore`,
    name: 'field-groups/restore',
    component: PageFieldGroupsRestore,
    meta: {
      requiresAuth: true,
      label: Vue.i18n.translate('Feldstücke wiederherstellen'),
      subheader: Vue.i18n.translate('Feldstücke wiederherstellen, die in den letzten 90 Tagen gelöscht wurden'),
      back: {
        to: { name: 'field-groups' },
        label: Vue.i18n.translate('Zurück zur Feldstücke-Startseite'),
      },
      featureControl: availableFeatures.FEATURE_FIELDS,
    },
  },
];
