<template>
  <ModalWrapper ref="modal" class="modal-import-harvest-data-from-unsigned-contract" :title="$t('Vertrag wählen')">
    <div class="m-3">
      <ValidationObserver v-slot="{ errors }" ref="validationObserver">
        <BForm @submit.prevent="submitForm" novalidate ref="form">
          <div class="mb-4">
            <BFormGroup :label="$t('Erntejahr')" label-for="process-order-name" label-cols="5">
              <BFormSelect
                v-model="processOrderNameComputed"
                id="process-order-name"
                class="form-control--text-input-form-group-align"
                :options="processOrderNames"
                :disabled="fetchingCompanyData || isCreating"
              />
            </BFormGroup>
            <BFormGroup :label="$t('Vertrag')" label-for="contract" label-cols="5">
              <BFormSelect
                v-model="contractIdComputed"
                id="contract"
                class="form-control--text-input-form-group-align"
                :options="contractOptions"
                :disabled="fetchingCompanyData || isCreating"
              />
            </BFormGroup>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              :name="$t('Betriebsnummer')"
              vid="companyBdmId"
              slim
            >
              <BFormGroup
                :label="$t('Betriebsnummer')"
                label-for="company-nr"
                label-cols="5"
                :state="errors.length > 0 ? false : null"
              >
                <BaseInput
                  v-model="companyBdmIdComputed"
                  id="company-nr"
                  class="form-control--text-input-form-group-align"
                  :disabled="fetchingCompanyData || isCreating"
                  :state="errors.length > 0 ? false : null"
                />
              </BFormGroup>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }" :name="$t('Partner-Nummer')" vid="foreignId" slim>
              <BFormGroup
                :label="$t('Partner-Nummer')"
                label-for="company-foreign-id"
                label-cols="5"
                :state="errors.length > 0 ? false : null"
              >
                <BaseInput
                  v-model="foreignIdComputed"
                  id="company-foreign-id"
                  class="form-control--text-input-form-group-align"
                  :disabled="fetchingCompanyData || isCreating"
                  :state="errors.length > 0 ? false : null"
                />
              </BFormGroup>
            </ValidationProvider>
          </div>
          <p class="text-danger">{{ errorCreating || firstError(errors) }}&nbsp;</p>
        </BForm>
      </ValidationObserver>
    </div>
    <template #buttons>
      <BButton
        class="mr-3"
        variant="primary"
        style="min-width: 120px"
        :disabled="fetchingCompanyData || isCreating"
        @click="submitForm"
      >
        <span v-if="!isCreating">{{ $t('Absenden') }}</span>
        <FontAwesomeIcon v-if="isCreating" icon="circle-notch" spin />
      </BButton>
      <BButton class="text-black" variant="link" :disabled="isCreating" @click="hide">
        {{ $t('Abbrechen') }}
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import BaseInput from '@/shared/components/form/BaseInput.vue';

library.add(faCircleNotch);

export default {
  name: 'ModalImportHarvestDataFromUnsignedContract',
  components: { BaseInput, ModalWrapper },
  props: {
    fetchingCompanyData: {
      type: Boolean,
      default: false,
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
    errorCreating: {
      type: String,
      default: null,
    },
    processOrderName: {
      type: String,
      default: null,
    },
    processOrderNames: {
      type: Array,
      required: true,
    },
    contractId: {
      type: Number,
      default: null,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
    companyBdmId: {
      type: String,
      required: true,
    },
    foreignId: {
      type: String,
      required: true,
    },
  },
  computed: {
    processOrderNameComputed: {
      get() {
        return this.processOrderName;
      },
      set(processOrderName) {
        this.$emit('change:processOrderName', processOrderName);
      },
    },
    contractIdComputed: {
      get() {
        return this.contractId;
      },
      set(contractId) {
        this.$emit('change:contractId', contractId);
      },
    },
    companyBdmIdComputed: {
      get() {
        return this.companyBdmId;
      },
      set(companyBdmId) {
        this.$emit('change:companyBdmId', companyBdmId);
      },
    },
    foreignIdComputed: {
      get() {
        return this.foreignId;
      },
      set(foreignId) {
        this.$emit('change:foreignId', foreignId);
      },
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    submitForm() {
      this.$refs.validationObserver.handleSubmit(this.accept);
    },
    accept() {
      this.$emit('accept');
    },
    firstError(errors) {
      let error = null;
      Object.values(errors).some((currErrors) => {
        if (currErrors.length < 1) {
          return false;
        }
        [error] = currErrors;
        return true;
      });
      return error;
    },
  },
};
</script>

<style scoped></style>
