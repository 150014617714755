import {
  activityTypeColumn,
  company,
  date,
  drivingTime,
  fieldColumn,
  id,
  pauseTime,
  processedArea,
  selectColumn,
  setupTime,
  totalFieldSize,
  user,
  userComment,
  workingTime,
} from '@/activities/handsontable/columns';
import placeholderColumn from '@/shared/handsontable/rework/features/placeholderColumn/column';
import { FarmdokColumnSettings } from '@/shared/handsontable/rework/types';

import equipment from './equipments';
import products from './products';

export const tableColumns: FarmdokColumnSettings[] = [
  { ...id, readOnly: true },
  { ...selectColumn, readOnly: false },
  { ...company, readOnly: true },
  { ...date, readOnly: true },
  { ...activityTypeColumn, readOnly: true },
  { ...fieldColumn, readOnly: true },
  { ...processedArea, readOnly: true },
  products,
  equipment,
  { ...workingTime, readOnly: true },
  { ...drivingTime, readOnly: true },
  { ...setupTime, readOnly: true },
  { ...pauseTime, readOnly: true },
  { ...userComment, readOnly: true },
  { ...user, readOnly: true },
  { ...totalFieldSize, readOnly: true },
  { ...placeholderColumn, readOnly: true },
];

export default tableColumns;
