<template lang="html">
  <div class="notification bg-white border-medium" @click="$emit('click')">
    <span class="notification__icon text-white" :class="iconColor">
      <FontAwesomeIcon :icon="icon"></FontAwesomeIcon>
    </span>
    <div class="notification__text">
      <h4 :class="titleColor">{{ title }}</h4>
      <p>{{ content }}</p>
    </div>
    <FontAwesomeIcon :icon="['fas', 'chevron-right']" class="notification__chevron text-dark"></FontAwesomeIcon>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

library.add(faChevronRight, faExclamationTriangle, faInfoCircle);

const PRIORITY_DANGER = 'WW';
const PRIORITY_WARNING = 'W';

export default {
  name: 'Notification',
  props: {
    priority: {
      type: String,
      default: '',
    },
    read: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconColor() {
      if (this.read) {
        return 'bg-medium';
      }
      if (this.priority === PRIORITY_DANGER) {
        return 'bg-danger';
      }
      if (this.priority === PRIORITY_WARNING) {
        return 'bg-warning';
      }
      return 'bg-secondary';
    },
    icon() {
      if (this.priority === PRIORITY_DANGER) {
        return 'exclamation-triangle';
      }
      if (this.priority === PRIORITY_WARNING) {
        return 'exclamation-triangle';
      }
      return 'info-circle';
    },
    titleColor() {
      if (this.read) {
        return '';
      }
      if (this.priority === PRIORITY_DANGER) {
        return 'text-danger';
      }
      if (this.priority === PRIORITY_WARNING) {
        return 'text-warning';
      }
      return 'text-secondary';
    },
  },
};
</script>

<style lang="css" scoped>
.notification {
  position: relative;
  display: flex;
  padding: 12px 0 12px 20px;
  border: 1px solid;
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 10px;
  align-items: center;
  text-align: left;
  cursor: pointer;
}

.notification__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  border-radius: 2px;
  margin-right: 15px;
}

.notification__text {
  flex-grow: 1;
  overflow: hidden;
}

h4 {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 2px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

p {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification__chevron {
  margin: 0 12px;
}
</style>
