
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'MapOverlayButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    type: {
      type: String,
      default: 'default',
    },
    iconSize: {
      type: String,
      default: '1x',
    },
  },
});
