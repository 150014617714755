import Vue from 'vue';

export function initialState() {
  return {
    available: false,
    visible: false,
    expanded: false,
    expandAvailable: false,
    expandButtonLabel: null,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setAvailability(state, available) {
      Vue.set(state, 'available', available);
    },
    toggleVisibility(state) {
      Vue.set(state, 'visible', !state.visible);
    },
    setExpandability(state, { expandAvailable, expandButtonLabel }) {
      Vue.set(state, 'expandAvailable', expandAvailable);
      Vue.set(state, 'expandButtonLabel', expandButtonLabel);
    },
    toggleExpand(state) {
      Vue.set(state, 'expanded', !state.expanded);
    },
    setState(state, { visible, expanded }) {
      Vue.set(state, 'visible', visible);
      Vue.set(state, 'expanded', expanded);
    },
    reset(state) {
      Object.keys(initialState()).forEach((key) => {
        Vue.set(state, key, initialState()[key]);
      });
    },
  },
};
