import { FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE } from '../constants';

export default {
  data() {
    return {
      authRegistrationProcessActive: false,
      localStorageCheck: null,
    };
  },
  created() {
    this.localStorageCheck = setInterval(() => {
      this.authRegistrationProcessActive =
        localStorage.getItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE) != null &&
        JSON.parse(localStorage.getItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE)) === true;
    }, 100);
  },
  beforeDestroy() {
    clearInterval(this.localStorageCheck);
  },
};
