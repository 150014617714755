<template>
  <div class="top-content-container">
    <MaxWidthContainer v-if="this.$slots.default != null" size="lg">
      <slot name="default" />
    </MaxWidthContainer>
  </div>
</template>

<script>
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';

/* eslint-disable max-len */
export default {
  name: 'TopContentContainer',
  components: { MaxWidthContainer },
};
</script>

<style scoped>
.top-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 130px;
  width: 100%;
  padding: var(--spacer_3);
  padding-bottom: calc(100vw * 0.1 + var(--spacer_3));
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYwIiBoZWlnaHQ9IjMyMiIgdmlld0JveD0iMCAwIDM2MCAzMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMDIuMzA5IDMyMS40MzNDNDcuOTIzNSAzMTYuOTkxIC02OC4wNzY4IDI1Mi43NTIgLTgyIDI0MS41NzFMLTIzIC02SDM4M1YyNTguNjA4QzM4MyAyNjguMTkyIDM3OS42MzQgMjY5LjY4NCAzMjUuNSAyNjkuNjg0QzI1OSAyNjkuNjg0IDE4NyAzMjguMzUxIDEwMi4zMDkgMzIxLjQzM1oiIGZpbGw9IiMzQ0M5NjEiLz4KPC9zdmc+Cg=='),
    linear-gradient(
      var(--primary) 0%,
      var(--primary) calc(100% - 100vw * 0.15),
      transparent calc(100% - 100vw * 0.15),
      transparent 100%
    );
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom center;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: white;
  text-align: center;
}

@media (min-width: 768px) {
  .top-content-container {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 768px) and (min-height: 600px) {
  .top-content-container {
    min-height: 200px;
  }
}

@media (min-width: 768px) and (min-height: 800px) {
  .top-content-container {
    min-height: 300px;
  }
}

@media (min-width: 992px) {
  .top-content-container {
    padding-bottom: calc(100vw * 0.04 + var(--spacer_3));
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSIzNzgiIHZpZXdCb3g9IjAgMCAxNDQwIDM3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3NC41IDM3Ny40MzNDMjU4LjE5MSAzNzcuNDMzIDExLjQyMzIgMzA4Ljc1MiAtMi41IDI5Ny41NzFWLTNIMTQ0MlYzMTQuNjA4QzE0NDIgMzI0LjE5MiAxMzUxIDI3NiAxMTk0IDI3NkMxMDM3IDI3NiA4MzYuMTkxIDM3Ny40MzMgNDc0LjUgMzc3LjQzM1oiIGZpbGw9IiMzQ0M5NjEiLz4KPC9zdmc+Cg=='),
      linear-gradient(
        var(--primary) 0%,
        var(--primary) calc(100% - 100vw * 0.1),
        transparent calc(100% - 100vw * 0.1),
        transparent 100%
      );
  }
}

@media (min-width: 992px) and (min-height: 950px) {
  .top-content-container {
    min-height: 350px;
  }
}

@media (min-width: 2400px) {
  .top-content-container {
    min-height: 450px;
  }
}
</style>
