<template>
  <ThePageSkeleton>
    <template #contentbar>
      <TheContentbar>
        <template #left="{ contentbarLarge }">
          <Button
            v-if="!editMode"
            class="mr-3"
            icon="plus-circle"
            leading-icon
            component-namespace-name="ButtonAddFieldGroup"
            :disabled="fieldsReadOnly"
            @click="create"
          >
            {{ $t('Feldstück hinzufügen') }}
          </Button>
          <Button
            v-if="showArchived && !editMode"
            color="lightest"
            leading-icon
            component-namespace-name="ButtonToggleFieldGroupsArchive"
            :icon="['far', 'eye-slash']"
            :title="showArchived ? $t('Archivierte ausblenden') : $t('Archivierte einblenden')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="toggleArchive"
          />
          <ButtonDropdown
            v-if="editMode"
            outline
            component-namespace-name="ButtonRemoveSelection"
            boundary="viewport"
            :text="$t('Auswahl aufheben')"
            :buttons="buttonUpdateSelection"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="selectedRows = []"
          />
          <Button
            v-if="editMode"
            class="text-danger"
            color="lightest"
            icon="trash-alt"
            leading-icon
            component-namespace-name="ButtonDeleteFieldGroups"
            :disabled="fieldsReadOnly"
            :title="$t('Löschen')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="() => $refs.table.removeSelectedEntries()"
          />
          <Button
            v-if="showArchived && editMode"
            color="lightest"
            leading-icon
            component-namespace-name="ButtonFieldGroupsEditArchived"
            :disabled="fieldsReadOnly"
            :icon="['far', 'archive']"
            :title="$t('Archiv editieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="modalEditFieldGroupArchivedVisible = true"
          />
          <Button
            v-if="editMode"
            color="lightest"
            icon="cloud-download"
            leading-icon
            component-namespace-name="ButtonExportFieldGroups"
            :disabled="exportDisabled"
            :title="$t('Auswahl exportieren')"
            :icon-only="!contentbarLarge"
            :tooltip-options="{ boundary: 'viewport' }"
            @click="$refs.table.$refs.table.export(selectedRows)"
          />
        </template>
        <template #right="{ contentbarLarge }">
          <SearchBar v-show="!editMode" :type="contentbarLarge ? 'large' : ''" :searchFilter.sync="filterString" />
          <CompanySwitch v-show="!editMode" class="ml-4" :slim="!contentbarLarge" />
          <DropdownPageOptions
            v-show="!editMode"
            class="ml-4"
            :restore-label="$t('Feldstücke wiederherstellen')"
            :restore-read-only="fieldsReadOnly"
            :export-disabled="exportDisabled"
            @restore="$router.push({ name: 'field-groups/restore' })"
            @settings="showSettings"
            @export="exportData"
          >
            <template #additional-options>
              <BDropdownItemButton
                class="text-black"
                button-class="dropdown-page-options__button"
                @click="toggleArchive"
              >
                <FontAwesomeIcon
                  :icon="showArchived ? ['far', 'eye-slash'] : ['far', 'eye']"
                  class="dropdown-page-options__button-icon"
                />
                {{ showArchived ? $t('Archivierte ausblenden') : $t('Archivierte einblenden') }}
              </BDropdownItemButton>
            </template>
          </DropdownPageOptions>
        </template>
      </TheContentbar>
    </template>
    <template #content>
      <TableFieldGroupsContainer
        ref="table"
        :filterString.sync="filterString"
        :selectedRows.sync="selectedRows"
        :visibleRows.sync="visibleRows"
        :show-archived="showArchived"
        :remove-row-confirm-message="removeRowConfirmMessage"
        @click:archived="onClickArchived"
      />
      <ModalPageSettings ref="modalPageSettings" :columns="columnsForPageSettings" />
      <ModalTableExport
        ref="modalTableExport"
        :availableTypes="null"
        :filtered="$refs.table != null && !$refs.table.allVisible"
      />
      <ModalEditFieldGroupArchived
        v-if="modalEditFieldGroupArchivedVisible"
        v-model="modalEditFieldGroupArchivedVisible"
        :field-group-ids="selectedRows"
        @success="selectedRows = []"
      />
      <PopoverFieldGroupArchived
        v-if="fieldGroupArchivedTarget != null"
        :field-group="fieldGroupArchivedFieldGroup"
        :target="fieldGroupArchivedTarget"
        @close="fieldGroupArchivedTarget = null"
      />
    </template>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArchive, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { faCloudUpload, faPencilAlt, faPlusCircle, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { mapGetters } from 'vuex';

import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import DropdownPageOptions from '@/shared/components/DropdownPageOptions.vue';
import ModalPageSettings from '@/shared/components/ModalPageSettings.vue';
import ModalTableExport from '@/shared/components/ModalTableExport.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonDropdown from '@/shared/components/buttons/ButtonDropdown.vue';
import buttonUpdateSelection from '@/shared/handsontable/mixins/pages/buttonUpdateSelection';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import ModalEditFieldGroupArchived from './components/ModalEditFieldGroupArchived.vue';
import PopoverFieldGroupArchived from './components/PopoverFieldGroupArchived.vue';
import TableFieldGroupsContainer from './containers/TableFieldGroupsContainer.vue';

library.add(faPencilAlt, faPlusCircle, faCloudUpload, faTrashAlt, faArchive, faEye, faEyeSlash);

export default {
  name: 'PageFieldGroups',
  components: {
    PopoverFieldGroupArchived,
    ModalEditFieldGroupArchived,
    ModalTableExport,
    DropdownPageOptions,
    TableFieldGroupsContainer,
    TheContentbar,
    ThePageSkeleton,
    CompanySwitch,
    SearchBar,
    ModalPageSettings,
    Button,
    ButtonDropdown,
  },
  mixins: [buttonUpdateSelection('table')],
  data() {
    this.$store.commit('fieldGroups/setAddArchived', false);
    return {
      filterString: '',
      selectedRows: [],
      visibleRows: [],
      columnsForPageSettings: null,
      showArchived: false,
      modalEditFieldGroupArchivedVisible: false,
      fieldGroupArchivedTarget: null,
      fieldGroupArchivedFieldGroup: null,
    };
  },
  computed: {
    ...mapGetters({
      fieldGroups: 'fieldGroups/data',
    }),
    editMode() {
      return this.selectedRows.length > 0;
    },
    fieldsReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_FIELDS);
    },
    exportDisabled() {
      return !this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_EXPORT_FIELD);
    },
  },
  methods: {
    removeRowConfirmMessage(entries) {
      const messages = [];
      if (entries.length === 1) {
        const [{ name }] = entries;
        if (typeof name === 'string' && name.length > 0) {
          messages.push(this.$t('Willst du wirklich {name} löschen?', { name }));
        } else {
          messages.push(this.$t('Willst du wirklich das gewählte Feldstück löschen?'));
        }
      } else {
        messages.push(
          this.$t('Willst du wirklich {count} Feldstücke löschen?', {
            count: entries.length,
          }),
        );
      }
      messages.push(this.$t('Du löschst damit auch alle zugehörigen Felder in allen Erntejahren.'));
      return messages.join(' ');
    },
    create() {
      this.$refs.table.$refs.table.insert({ visualRow: 0 });
    },
    async showSettings() {
      this.columnsForPageSettings = this.$refs.table.$refs.table.getColumnsForPageSettings();
      const settings = await this.$refs.modalPageSettings.show();
      if (settings != null && Array.isArray(settings.columns)) {
        this.$refs.table.$refs.table.updateColumnsFromPageSettings(settings.columns);
      }
    },
    async exportData() {
      let exportFiltered = true;
      let okay = true;

      if (!this.$refs.table.allVisible) {
        ({ exportFiltered, okay } = await this.$refs.modalTableExport.show());
      }
      if (!okay) {
        return;
      }

      let fieldIds = [];
      if (exportFiltered) {
        fieldIds = this.$refs.table.visibleRows;
      } else {
        fieldIds = Object.values(this.$refs.table.tableData).map((field) => field.id);
      }
      this.$refs.table.$refs.table.export(fieldIds);
    },
    toggleArchive() {
      this.showArchived = !this.showArchived;
      if (this.showArchived) {
        this.$store.dispatch('fieldGroups/addArchived');
      }
      this.$refs.table.loadData();
    },
    async onClickArchived(payload) {
      if (payload == null) {
        this.fieldGroupArchivedTarget = null;
        this.fieldGroupArchivedFieldGroup = null;
        return;
      }
      const { target, id } = payload;
      if (this.fieldGroupArchivedTarget === target) {
        this.fieldGroupArchivedTarget = null;
        this.fieldGroupArchivedFieldGroup = null;
        return;
      }
      this.fieldGroupArchivedTarget = null;
      this.fieldGroupArchivedFieldGroup = null;
      if (this.fieldGroups[id] == null) {
        return;
      }

      await this.$nextTick();
      this.fieldGroupArchivedTarget = target;
      this.fieldGroupArchivedFieldGroup = this.fieldGroups[id];
    },
    onResize() {
      this.fieldGroupArchivedTarget = null;
      this.fieldGroupArchivedFieldGroup = null;
    },
  },
};
</script>

<style scoped></style>
