
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import AlertLiveCheckContainer from '@/precision-farming/application-maps/containers/AlertLiveCheckContainer.vue';
import FooterLiveCheckContainer from '@/precision-farming/application-maps/containers/FooterLiveCheckContainer.vue';
import ModalRuleCheckViolationsContainer from '@/precision-farming/application-maps/containers/ModalRuleCheckViolationsContainer.vue';
import DemandTableContainer from '@/precision-farming/application-maps/spraying/containers/DemandTableContainer.vue';
import ProductListContainer from '@/precision-farming/application-maps/spraying/containers/ProductListContainer.vue';
import ResultTableContainer from '@/precision-farming/application-maps/spraying/containers/ResultTableContainer.vue';
import SprayControlsContainer from '@/precision-farming/application-maps/spraying/containers/SprayControlsContainer.vue';
import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';
import SidebarHeadline from '@/shared/components/SidebarHeadline.vue';
import SidebarSubHeadline from '@/shared/components/SidebarSubHeadline.vue';

export default defineComponent({
  name: 'SidebarCreateApplicationMapSpraying',
  components: {
    FooterLiveCheckContainer,
    ModalRuleCheckViolationsContainer,
    AlertLiveCheckContainer,
    DemandTableContainer,
    ProductListContainer,
    ResultTableContainer,
    SprayControlsContainer,
    SidebarContentWrapper,
    SidebarHeadline,
    SidebarSubHeadline,
  },
  computed: {
    ...mapState({
      paginationStep(state): number {
        // @ts-ignore
        return state.precisionFarming.applicationMaps.spraying.paginationStep;
      },
    }),
  },
});
