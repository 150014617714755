<template lang="html">
  <div class="barchart">
    <p class="barchart__no-data" v-if="chartData.length === 0">
      {{ missingDataMessageComputed }}
    </p>
    <!--  vertical labels -->
    <svg :width="width" :height="height" class="barchart__data">
      <g :style="{ transform: `translate(${margin.left - 7}px, ${margin.top}px)` }">
        <text
          v-for="(line, index) in lines"
          :key="`line-description-${index}`"
          x="0"
          :y="line.description.y + 3"
          text-anchor="end"
          font-size="12"
        >
          {{ line.description.value | numbro({ mantissa: 1 }) }}
        </text>
      </g>

      <!-- horizontal lines -->
      <g :style="{ transform: `translate(${margin.left}px, ${margin.top}px)` }">
        <path
          v-for="(line, index) in lines"
          :key="`line-${index}`"
          :d="line.data"
          class="barchart__horizontal-helper"
          :class="{ 'barchart__horizontal-helper--zero': line.isZero }"
        ></path>
        <g v-for="(bar, index) in bars" :key="`bar-${index}`" :style="{ transform: `translate(${bar.x}px, 0px)` }">
          <rect :width="barWidth" :height="bar.height" rx="1.5" :y="bar.y" class="barchart__bar"></rect>
        </g>
        <g v-for="(mean, index) in means" :key="`mean-${index}`" :style="{ transform: `translate(${mean.x}px, 0px)` }">
          <rect :width="barWidth" height="3px" rx="1.5" :y="mean.y" class="barchart__bar--mean"></rect>
        </g>
      </g>
    </svg>

    <ChartTooltip
      v-if="tooltip"
      :chart-width="width"
      :top="tooltip.top"
      :left="tooltip.left"
      :entries="tooltip.entries"
    />

    <svg :width="width" :height="height" class="barchart__hover">
      <g :style="{ transform: `translate(${margin.left}px, ${margin.top}px)` }">
        <g
          v-for="tooltipBar in tooltipBars"
          :key="tooltipBar.key"
          :style="{ transform: `translate(${tooltipBar.x}px, 0px)` }"
        >
          <rect
            :width="barWidth + barSpacing"
            :height="chartHeight"
            :data-index="tooltipBar.index"
            @mouseover="mouseover"
            @mouseout="mouseout"
          ></rect>
        </g>
      </g>
    </svg>

    <ChartAxisBottom
      v-if="width != null"
      v-on="this.$listeners"
      :chart-width="width"
      :navigation-width="margin.left"
      :data="axisDataComputed"
    />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import numbro from 'numbro';

import ChartAxisBottom from './ChartAxisBottom.vue';
import ChartTooltip from './ChartTooltip.vue';

library.add(faBars);

const margin = {
  top: 50,
  right: 0,
  bottom: 30,
  left: 57,
};
const barWidthRatio = 0.7;

export default {
  name: 'BarChart',
  components: { ChartAxisBottom, ChartTooltip },
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    showMean: {
      type: Boolean,
      default: false,
    },
    maxY: {
      type: Number,
      default: null,
    },
    axisData: {
      type: Array,
      default: () => [],
    },
    missingDataMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      margin,
      hoveredIndex: null,

      // calculated from this.$el
      width: null,
      height: null,
      chartWidth: null,
      chartHeight: null,
    };
  },
  computed: {
    barWidth() {
      if (this.chartWidth == null || this.chartData.length < 1) {
        return null;
      }
      return (this.chartWidth * barWidthRatio) / this.chartData.length;
    },
    barSpacing() {
      if (this.chartWidth == null || this.chartData.length < 1) {
        return null;
      }
      const spacingRatio = 1 - barWidthRatio;
      return (this.chartWidth * spacingRatio) / this.chartData.length;
    },
    scaleX() {
      if (this.chartWidth == null || this.chartData.length < 1) {
        return null;
      }
      return scaleLinear()
        .domain([0, this.chartData.length - 1])
        .range([0, this.chartWidth]);
    },
    maxYComputed() {
      const maxY = max(this.chartData, (entry) => Math.max(entry.value, entry.mean));
      if (this.maxY != null && this.maxY > maxY) {
        return this.maxY;
      }
      return maxY * 1.05;
    },
    scaleY() {
      if (this.chartHeight == null || this.chartData.length < 1) {
        return null;
      }
      return scaleLinear().domain([0, this.maxYComputed]).range([this.chartHeight, 0]);
    },
    tickValues() {
      if (this.chartHeight == null || this.chartData.length < 1) {
        return null;
      }
      let base = 1;
      let multiplier = 1;
      while (this.maxYComputed > base * multiplier) {
        switch (multiplier) {
          case 1:
            multiplier = 2.5;
            break;
          case 2.5:
            multiplier = 5;
            break;
          case 5:
            multiplier = 7.5;
            break;
          case 7.5:
            multiplier = 1;
            base *= 10;
            break;
          default:
            break;
        }
      }
      const tickValues = [];
      switch (multiplier) {
        case 1:
          base /= 4;
          break;
        case 2.5:
          base /= 2;
          break;
        case 5:
          break;
        case 7.5:
          base *= 1.5;
          break;
        default:
          break;
      }
      for (let x = 0; base * x < this.maxYComputed; x += 1) {
        tickValues.push(base * x);
      }
      return tickValues;
    },
    lines() {
      if (this.width == null || this.chartData.length < 1) {
        return null;
      }
      return this.tickValues.map((tickValue) => {
        const horizontalHelper = line()
          .x((entry, index) => this.scaleX(index))
          .y(() => this.scaleY(tickValue));
        return {
          data: horizontalHelper(this.chartData),
          description: {
            y: this.scaleY(tickValue),
            value: tickValue,
          },
          isZero: tickValue === 0,
        };
      });
    },
    bars() {
      if (this.width == null || this.chartData.length < 1) {
        return null;
      }
      return this.chartData
        .map((entry, index) => ({
          x: this.barSpacing * 0.5 + index * (this.barWidth + this.barSpacing),
          y: this.scaleY(entry.value),
          height: this.chartHeight - this.scaleY(entry.value),
        }))
        .filter((entry) => !entry.placeholder);
    },
    means() {
      if (!this.showMean || this.width == null || this.chartData.length < 1) {
        return null;
      }
      return this.chartData
        .map((entry, index) => ({
          x: this.barSpacing * 0.5 + index * (this.barWidth + this.barSpacing),
          y: this.scaleY(entry.mean),
        }))
        .filter((entry) => !entry.placeholder);
    },
    tooltipBars() {
      if (this.width == null || this.chartData.length <= 1) {
        return null;
      }
      return this.chartData
        .map((entry, index) => ({
          index,
          x: this.barSpacing * 0.5 + index * (this.barWidth + this.barSpacing),
        }))
        .filter((entry) => !entry.placeholder);
    },
    axisDataComputed() {
      const normalize = (index) =>
        ((this.width - this.barWidth) / this.axisData.length) * index + this.barSpacing * 1.5;
      const axisData = this.axisData.map((entry, index) => ({
        caption: entry.caption,
        left: normalize(index),
        highlight: entry.highlight,
      }));
      if (this.hoveredIndex != null) {
        axisData.push({
          caption: this.chartData[this.hoveredIndex].caption,
          left: (0.5 + this.hoveredIndex) * (this.barWidth + this.barSpacing),
          hovered: true,
        });
      }
      return axisData;
    },
    tooltip() {
      if (this.hoveredIndex == null || this.chartData[this.hoveredIndex] == null) {
        return null;
      }
      const entry = this.chartData[this.hoveredIndex];
      const entries = [
        {
          color: '#3797AE',
          description: this.$t('Aktuell:'),
          value: numbro(entry.value).format({ mantissa: 1 }),
        },
      ];
      if (this.showMean) {
        entries.push({
          color: '#FF614C',
          description: this.$t('Durchschnitt:'),
          value: numbro(entry.mean).format({ mantissa: 1 }),
        });
      }
      return {
        top: this.margin.top + this.scaleY(entry.value) - 15,
        left:
          this.margin.left +
          this.barSpacing * 0.5 +
          this.hoveredIndex * (this.barWidth + this.barSpacing) +
          this.barWidth / 2,
        entries,
      };
    },
    missingDataMessageComputed() {
      if (this.missingDataMessage != null) {
        return this.missingDataMessage;
      }
      return this.$t('Keine Daten verfügbar.');
    },
  },
  mounted() {
    window.addEventListener('resize', this.recalculateSizes);
    this.$nextTick(this.recalculateSizes);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.recalculateSizes);
  },
  methods: {
    mouseover(event) {
      this.hoveredIndex = Number(event.target.dataset.index);
    },
    mouseout() {
      this.hoveredIndex = null;
    },
    recalculateSizes() {
      if (!this.$el || !(this.$el.offsetWidth > 0)) {
        this.width = null;
        this.height = null;
        this.chartWidth = null;
        this.chartHeight = null;
        return;
      }
      if (this.width === this.$el.offsetWidth) {
        return;
      }
      this.width = this.$el.offsetWidth;
      this.height = Math.min(350, Math.max(250, Math.floor(this.width * 0.4)));
      this.chartWidth = this.width - margin.left - margin.right;
      this.chartHeight = this.height - margin.top - margin.bottom;
    },
  },
};
</script>

<style lang="css" scoped>
.barchart {
  position: relative;
}

.barchart__no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.barchart__horizontal-helper {
  stroke: #dedede;
  stroke-width: 1;
  stroke-dasharray: 2 3;
}

.barchart__horizontal-helper--zero {
  stroke-dasharray: none;
}

.barchart__bar {
  fill: #3797ae;
  opacity: 0.8;
  border-radius: 0 1em;
}

.barchart__bar--mean {
  fill: #ff614c;
}

.barchart__hover {
  position: absolute;
  top: 0;
  left: 0;
}

.barchart__hover rect {
  fill: transparent;
}
</style>
