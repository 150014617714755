<template>
  <MultiRootWrapper>
    <BContainer class="settings">
      <p class="small">
        {{ $t('Sende die Applikationskarte direkt an die Cloud-Plattform deines Maschinenherstellers.') }}
        {{ $t('Von dort kannst du sie vom Terminal deines Traktors abrufen ohne einen USB-Stick.') }}
      </p>
      <p class="font-weight-bold">
        {{ $t('Applikationskarte senden an ...') }}
      </p>
      <div>
        <BButton variant="white" class="btn btn-send-via-api" :disabled="buttonsDisabled" @click="sendApplicationMap">
          <div class="john-deere-wrapper">
            <div>
              <img alt="John Deere Logo" :src="require('@/assets/images/JD_Logo_884x663_Transparent.png')" />
            </div>
            <span>MyJohnDeere</span>
          </div>
        </BButton>
      </div>
    </BContainer>
    <ModalSendToJohnDeere
      v-if="showModalSendToJohnDeere"
      v-model="showModalSendToJohnDeere"
      :workflowKey="workflowKey"
    />
  </MultiRootWrapper>
</template>
<script>
import { mapState } from 'vuex';

import MultiRootWrapper from '@/shared/components/MultiRootWrapper.vue';

import ModalSendToJohnDeere from '../ModalSendToJohnDeere.vue';

export default {
  name: 'SendToTab',
  components: {
    ModalSendToJohnDeere,
    MultiRootWrapper,
  },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
    buttonsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModalSendToJohnDeere: false,
    };
  },
  computed: {
    ...mapState({
      taskDataAsync(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/taskDataAsync`];
      },
    }),
  },
  methods: {
    async sendApplicationMap() {
      this.$emit('sendApplicationMapTriggered');
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setFetchingTask`, true);
      const taskData = await this.taskDataAsync(this.$store.dispatch);
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setFetchingTask`, false);

      taskData.data.usage = 'myJohnDeere';
      this.createApplicationMapEvent(taskData);

      this.showModalSendToJohnDeere = true;
    },
    createApplicationMapEvent(taskData) {
      this.$emit('createApplicationMapEvent', taskData);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 0 40px;
  margin: 30px 0;
}

.settings > *:not(:last-child) {
  margin-bottom: 18px;
}

.btn {
  width: 100%;
}

.btn-send-via-api {
  border: 1px solid #c4c4c4;
  height: 65px;
  padding: 0;
}

.john-deere-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.john-deere-wrapper div {
  height: 100%;
}

.john-deere-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
