import { AxiosRequestConfig } from 'axios';
import { GeoObject, GeoObjectType, MapApi, MapApiGeoObjectListRequest } from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import removableData from '@/shared/mixins/store/removableData/index';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import syncableData from '@/shared/mixins/store/syncableData/index';
import { SubscribableDataState, SyncableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<GeoObject>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<GeoObject>> {
  const processOrderIds = context.rootGetters['auth/currentProcessOrderIds'] as string[];
  const { mapApi } = Vue.prototype.$api as Api;

  const apiResponsesIsolationZone = await Promise.all(
    processOrderIds.map((processOrderId) => {
      const requestParameters: MapApiGeoObjectListRequest = { objectType: GeoObjectType.IsolationZone, processOrderId };
      return loadAllPages<MapApi, GeoObject>(mapApi, 'geoObjectList', requestParameters, axiosOptions);
    }),
  );

  const apiResponsesMultiplierGroup = await Promise.all(
    processOrderIds.map((processOrderId) => {
      const requestParameters: MapApiGeoObjectListRequest = {
        objectType: GeoObjectType.MultiplierGroup,
        processOrderId,
      };
      return loadAllPages<MapApi, GeoObject>(mapApi, 'geoObjectList', requestParameters, axiosOptions);
    }),
  );

  const apiResponse = mergeApiResponses([...apiResponsesIsolationZone, ...apiResponsesMultiplierGroup]);
  return apiResponse;
}

export async function fetchByIds(
  ids: string[],
  context: ActionContext<SubscribableDataState<GeoObject>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<GeoObject>> {
  const { mapApi } = Vue.prototype.$api as Api;
  const { data } = await mapApi.geoObject({ objectIds: ids.join(',') }, axiosOptions);

  return data as ApiResponse<GeoObject>;
}

async function updateEntries(
  entries: GeoObject[],
  context: ActionContext<SyncableDataState<GeoObject>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<GeoObject>> {
  const { mapApi } = Vue.prototype.$api as Api;
  const { data } = await mapApi.geoObjectPut({ geoObjectPutRequest: { data: entries } }, axiosOptions);

  return data;
}

async function removeEntries(
  entries: GeoObject[],
  context: ActionContext<SubscribableDataState<GeoObject>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<GeoObject>> {
  const { mapApi } = Vue.prototype.$api as Api;
  const apiResponses = await Promise.all(
    entries.map(async (entry) => {
      const { data } = await mapApi.geoObjectDelete(
        {
          objectIds: entry.id,
        },
        axiosOptions,
      );
      return data;
    }),
  );

  return mergeApiResponses(apiResponses);
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
export const syncableStore = syncableData(updateEntries);
export const removableStore = removableData(removeEntries);
