<template>
  <Widget v-if="customersVisible && customer != null" :title="$t('Kunde')" :subtitle="subtitle">
    <Table v-if="customersEnabled" fixed headerless :fields="tableFields" :items="tableItems" :busy="fetching">
      <template v-if="!expanded" #cell(value)="{ value }">
        <div class="widget-customer__values-collapsed">
          <span v-b-tooltip.hover.left="{ title: value }" :title="value">
            {{ value }}
          </span>
        </div>
      </template>
    </Table>
    <FeatureNotAvailable v-else :add-link-to-shop="canPurchaseLicenses" />
  </Widget>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash.debounce';

import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import Table from '@/shared/components/Table.vue';
import Widget from '@/shared/components/Widget.vue';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

export default {
  name: 'WidgetCustomer',
  components: {
    FeatureNotAvailable,
    Table,
    Widget,
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    selectedFields: {
      type: Array,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    customers: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fetching: true,
      source: null,
      address: null,
    };
  },
  computed: {
    customersVisible() {
      if (this.customer == null || this.companyIds.length === 0) {
        return false;
      }
      return this.companyIds.some((companyId) =>
        this.$store.getters.companyFeatureVisible(companyId, availableFeatures.FEATURE_CUSTOMERS),
      );
    },
    customersEnabled() {
      if (this.companyIds.length === 0) {
        return false;
      }
      return this.companyIds.every((companyId) =>
        this.$store.getters.companyFeatureEnabled(companyId, availableFeatures.FEATURE_CUSTOMERS),
      );
    },
    canPurchaseLicenses() {
      if (this.companyIds.length === 0) {
        return false;
      }
      return this.companyIds.every((companyId) =>
        this.$store.getters.companyFeatureEnabled(companyId, availableFeatures.CAN_PURCHASE_LICENSE),
      );
    },
    companyIds() {
      const companyIds = new Set();
      this.selectedFields.forEach((field) => {
        if (field == null || field.processOrder == null) {
          return;
        }
        companyIds.add(field.processOrder.companyId);
      });
      return [...companyIds];
    },
    customer() {
      const customerIds = new Set();
      this.selectedFields.forEach((field) => {
        if (field == null || field.fieldGroup == null) {
          return;
        }
        customerIds.add(field.fieldGroup.customerId);
      });
      if (customerIds.size !== 1) {
        return null;
      }
      const [customerId] = [...customerIds];
      return this.customers[customerId];
    },
    tableFields() {
      return [
        {
          key: 'label',
          width: '140px',
        },
        {
          key: 'value',
        },
      ];
    },
    tableItems() {
      if (this.customer == null) {
        return [];
      }
      const tableItems = [
        {
          label: this.$t('Kundennummer'),
          value: this.customer.customerNumber,
        },
        {
          label: this.$t('Name'),
          value: this.customer.name,
        },
      ];
      if (this.address != null) {
        if (this.address.address != null) {
          tableItems.push({
            label: this.$t('Straße'),
            value: this.address.address,
          });
        }
        if (this.address.postcode != null) {
          tableItems.push({
            label: this.$t('PLZ'),
            value: this.address.postcode,
          });
        }
        if (this.address.city != null) {
          tableItems.push({
            label: this.$t('Ort'),
            value: this.address.city,
          });
        }
      }
      if (this.customer.tel != null) {
        tableItems.push({
          label: this.$t('Telefonnummer'),
          value: this.customer.tel,
        });
      }
      if (this.customer.email != null) {
        tableItems.push({
          label: this.$t('E-Mail-Adresse'),
          value: this.customer.email,
        });
      }
      if (this.customer.description != null) {
        tableItems.push({
          label: this.$t('Beschreibung'),
          value: this.customer.description,
        });
      }
      return tableItems;
    },
  },
  watch: {
    selectedFields: {
      handler() {
        if (this.source != null) {
          this.source.cancel();
        }
        this.fetching = true;
        this.address = null;
        this.updateCustomer();
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.source != null) {
      this.source.cancel();
    }
  },
  methods: {
    updateCustomer: debounce(async function updateCustomer() {
      if (!this.customersVisible || !this.customersEnabled) {
        return;
      }
      this.fetching = true;
      await this.$store.dispatch('customers/subscribe');
      if (this.customer == null || !Array.isArray(this.customer.address) || this.customer.address.length === 0) {
        this.fetching = false;
        return;
      }
      const [customerAddress] = this.customer.address.sort((a, b) => {
        if (a.type === 'billing' && b.type !== 'billing') {
          return -1;
        }
        if (b.type === 'billing' && a.type !== 'billing') {
          return 1;
        }
        return b.tstamp - a.tstamp;
      });
      try {
        this.source = axios.CancelToken.source();
        const { data } = await axios.get(`/admin/rest/address/${customerAddress.addressId}`, {
          cancelToken: this.source.token,
        });
        if (data.status === 'success') {
          this.address = data.data;
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('WidgetCustomer: unable to fetch address for customer');
        }
      }
      this.fetching = false;
    }, 100),
  },
};
</script>

<style lang="css" scoped>
.widget-customer__values-collapsed {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
