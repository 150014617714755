import { Company } from '@/auth/store/types';
import { ActivityType } from '@/shared/api/rest/models';

export function filterMulticompany(
  activityType: ActivityType,
  activityTypeSiblings: ActivityType[],
  companies: Company[],
): boolean {
  const activityTypesFamily = [activityType, ...activityTypeSiblings];

  if (activityTypesFamily.length === 0 || companies.length === 0) {
    return true; // ignore this filter
  }

  const activityTypeCompanyIds = activityTypesFamily.map((a) => a.companyId);
  return companies.every((company) => activityTypeCompanyIds.includes(company.id));
}

export function findSiblingsOfActivityType(
  activityType: ActivityType,
  allActivityTypes: ActivityType[],
): ActivityType[] {
  return allActivityTypes
    .filter((candidate) => isSiblingActivityType(activityType, candidate))
    .filter((candidate) => candidate.id !== activityType.id);
}

function isSiblingActivityType(activityType: ActivityType, candidate: ActivityType): boolean {
  return syncName(activityType) === syncName(candidate);
}

export function syncName(activityType: ActivityType) {
  const { roughId, fineId, displayName } = activityType;

  return `${roughId}_${fineId}_${displayName}`;
}
