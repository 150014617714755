const availableFeatureProperties = {
  /**
   * Defines if the feature should be visible to the user (do not confuse this with read-only).
   * E.g. if its false the user shouldn't even see the navigation links to a specific feature page.
   *
   * Example:
   * User-role employee should not see soil samples. Even the link in the tabs of the fields page should be hidden.
   *
   * @type {boolean}
   */
  visible: {
    type: Boolean,
    validator: (value: any) => typeof value === 'boolean',
    default: true,
  },
  /**
   * Defines if the user can see/use the features UI (it does NOT specify if it's read-only!).
   * If it's false then the user should see fallback/preview content describing how to enable this feature (e.g. buying another license in the shop).
   * The exact implementation how the preview should look like depends on the feature and has to be implemented in the application.
   *
   * Example:
   * Application-maps feature should only be enabled for performance licenses.
   * Other licenses should still see the link and get a small preview page and a link to the shop where they can buy the performance licenses.
   *
   * @type {boolean}
   */
  enabled: {
    type: Boolean,
    validator: (value: any) => typeof value === 'boolean',
    default: true,
  },
  /**
   * Defines if the user has read-only access to the feature's UI.
   * If it's true then the user should only be able to view the current contents and all UI elements should be disabled or locked
   * The exact implementation how the read-only UI should look like depends on the feature and has to be implemented in the application.
   *
   * Example:
   * Fields feature should be read-only for user roles Employee and Read.
   * Only role Farm manager should have full access and enabled UI elements and table.
   *
   * @type {boolean}
   */
  readonly: {
    type: Boolean,
    validator: (value: any) => typeof value === 'boolean',
    default: false,
  },
};

export default availableFeatureProperties;
