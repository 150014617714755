import axios from 'axios';
import Vue from 'vue';

import { origin } from '@/shared/constants';
import removableData, { initialState as initialStateRemovable } from '@/shared/mixins/store/removableData';
import subscribableDataStore from '@/shared/mixins/store/subscribableData';
import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import syncableData from '@/shared/mixins/store/syncableData';
import initialStateSyncable from '@/shared/mixins/store/syncableData/initialState';

const dataToBase64 = (data) => (data != null ? btoa(JSON.stringify(data)) : '');

const contracts = subscribableDataStore('/admin/rest/contract');

export const companyModel = {
  id: null,
  status: null,
};
const companies = subscribableDataStore('/admin/rest/contractCompany');
const syncableCompanies = syncableData('/admin/rest/contractCompany', { model: companyModel });

const fieldsResolve = ['contour'];
const fields = subscribableDataStore('/admin/rest/contractField', { resolve: fieldsResolve });
const removableFields = removableData('/admin/rest/contractField');

export const fieldModel = {
  id: null,
  contractCompanyId: null,
  fieldGroupMfa: null,
  fieldNumber: null,
  fieldName: null,
  fieldSize: 0,
  fieldCropName: null,
  fieldPreCropName: null,
  fieldVarietyName: null,
  tstamp: null,
  deleted: null,
};
const syncableFields = syncableData('/admin/rest/contractField', { model: fieldModel });

export const fileModel = {
  id: null,
  contractId: null,
  fileName: null,
  contractCompanyId: null,
  created: null,
  tstamp: null,
  deleted: null,
};
const files = subscribableDataStore('/admin/rest/contractFile');
const syncableFiles = syncableData('/admin/rest/contractFile', { model: fileModel });
const removableFiles = removableData('/admin/rest/contractFile');

const contractStore = {
  ...contracts,
  mutations: {
    ...contracts.mutations,
  },
  actions: {
    ...contracts.actions,
    reset(context) {
      contracts.actions.reset(context);
      context.commit('reset');
    },
  },
};

const companyStore = {
  ...companies,
  state: {
    ...initialStateSubscribable(),
    ...initialStateSyncable(),
  },
  mutations: {
    ...companies.mutations,
    ...syncableCompanies.mutations,
  },
  actions: {
    ...companies.actions,
    ...syncableCompanies.actions,
    reset(context) {
      companies.actions.reset(context);
      context.commit('reset');
    },
    async updateAndSyncEntry(context, { status, companyId }) {
      const { state, commit, dispatch } = context;
      const updatedEntry = state.data[companyId];
      updatedEntry.status = status;
      commit('updateEntry', { entry: updatedEntry });
      await dispatch('syncAll');
    },
  },
};

const fieldStore = {
  ...fields,
  mutations: {
    ...fields.mutations,
    ...syncableFields.mutations,
    ...removableFields.mutations,
    setFieldNdvis(state, { data }) {
      Vue.set(
        state,
        'data',
        Object.keys(state.data).reduce((dataCurrent, entryId) => {
          if (data[entryId]) {
            return {
              ...dataCurrent,
              [entryId]: {
                ...state.data[entryId],
                ndvi: data[entryId].vegetation.value,
              },
            };
          }
          return {
            ...dataCurrent,
            [entryId]: state.data[entryId],
          };
        }, state.data),
      );
    },
  },
  actions: {
    ...fields.actions,
    ...syncableFields.actions,
    ...removableFields.actions,
    reset(context) {
      fields.actions.reset(context);
      context.commit('reset');
    },
    async loadByContractId({ commit, dispatch, state }, { contractId }) {
      dispatch('reset');
      const source = axios.CancelToken.source();
      commit('startFetching', source);
      const filter = [
        'AND',
        ['contractCompany.contractId', '=', contractId],
        ['contractCompany.status', '!=', 'canceled'],
      ];
      try {
        const { data } = await axios.get(
          `/admin/rest/contractField?filter==${dataToBase64(filter)}&resolve=${dataToBase64(
            fieldsResolve,
          )}&itemsPerPage=5000`,
          {
            cancelToken: source.token,
          },
        );
        if (!Array.isArray(data.data)) {
          commit('fetchingError', source);
        } else {
          commit('loadAll', { data: data.data });
          commit('finishFetching', source);
        }
      } catch (error) {
        console.error(error);
        commit('fetchingError', source);
      } finally {
        state.observers.forEach((callback) => callback());
        commit('clearObservers');
      }
    },
    async loadNdvi({ state, commit }) {
      const timestamp = Math.floor(Date.now() / 1000);
      const products = Object.keys(state.data).map((entryKey) => {
        const entry = state.data[entryKey];
        return {
          clientId: entry.id,
          polygon: entry.contour?.coordinates[0],
          timestamp,
        };
      });

      const productsWithContour = products.filter((product) => product.polygon);
      const source = axios.CancelToken.source();
      try {
        const { data } = await axios.post(
          '/admin/sen4/multiPolyMean',
          {
            indexType: 'DNN_NDVI',
            products: productsWithContour,
          },
          { cancelToken: source.token },
        );
        await commit('setFieldNdvis', { data: data.products });
      } catch (error) {
        console.error(error);
        commit('fetchingError', source);
      }
    },
  },
};

const contractCompaniesByContractId = (state, contractId) =>
  Object.values(state.companies.data).filter((company) => company.contractId === contractId);

const contractFieldsByCompanyId = (state, companyId) => {
  const fieldsByCompanyId = Object.values(state.fields.data).filter((field) => field.contractCompanyId === companyId);
  return fieldsByCompanyId;
};

const contractFieldsByContractId = (state, contractId) => {
  const companiesInContract = contractCompaniesByContractId(state, contractId).map((company) => company.id);
  const fieldsInContract = Object.values(state.fields.data).filter((field) =>
    companiesInContract.includes(field.contractCompanyId),
  );

  return fieldsInContract;
};

const calculateTotalFieldSize = (fieldArray) => fieldArray.reduce((sum, field) => sum + field.fieldSize, 0);

const formatCrops = (fieldArray) => {
  const cropList = fieldArray.map((field) => field.fieldCropName);
  return [...new Set(cropList.filter((crop) => crop))];
};

const formatVarieties = (fieldArray) => {
  const varietyList = fieldArray.map((field) => field.fieldVarietyName);
  return [...new Set(varietyList.filter((variety) => variety))];
};

const fileStore = {
  ...files,
  state: {
    ...initialStateSubscribable(),
    ...initialStateSyncable(),
    ...initialStateRemovable(),
  },
  mutations: {
    ...files.mutations,
    ...syncableFiles.mutations,
    ...removableFiles.mutations,
  },
  actions: {
    ...files.actions,
    ...syncableFiles.actions,
    ...removableFiles.actions,
    reset(context) {
      companies.actions.reset(context);
      context.commit('reset');
    },
    async updateAndSyncEntry(context, { entry }) {
      const { state, commit, dispatch } = context;
      const newContractCompanyId = entry.companyId;
      const updatedEntry = state.data[entry.id];
      updatedEntry.contractCompanyId = newContractCompanyId;
      await commit('updateEntry', { entry: updatedEntry });
      await dispatch('syncAll');
    },
  },
};

const contractFilesByContractId = (state, contractId) =>
  Object.values(state.files.data).filter((file) => file.contractId === contractId);

export default {
  namespaced: true,
  state: {},
  modules: {
    contracts: contractStore,
    companies: companyStore,
    fields: fieldStore,
    files: fileStore,
  },
  actions: {
    reset({ dispatch }) {
      dispatch('contracts/reset');
      dispatch('companies/reset');
      dispatch('fields/reset');
    },
  },
  getters: {
    contracts: (state) => state.contracts.data,
    companies: (state) => state.companies.data,

    companiesByContractId: (state) => (contractId) => contractCompaniesByContractId(state, contractId),

    contractCompanyData: (state) => (contractId) => {
      const companiesInContract = contractCompaniesByContractId(state, contractId);

      const companyData = companiesInContract.map((company) => {
        const fieldsForContractCompany = contractFieldsByCompanyId(state, company.id);
        return {
          guid: company.id,
          id: company.id,
          partnerNumber: company.foreignId,
          farmNumber: company.companyBdmId,
          farmName: company.companyName,
          lastUpdate: company.tstamp,
          status: company.status,
          contactEmail: company.email,
          fieldCount: fieldsForContractCompany.length,
          fieldSize: calculateTotalFieldSize(fieldsForContractCompany),
          crops: formatCrops(fieldsForContractCompany),
          varieties: formatVarieties(fieldsForContractCompany),
        };
      });
      return companyData;
    },

    contractFieldData: (state) => (contractId) => {
      const companiesInContract = contractCompaniesByContractId(state, contractId);

      const fieldsInContract = contractFieldsByContractId(state, contractId);

      const fieldData = fieldsInContract.map((field) => {
        const fieldCompany = companiesInContract.find((company) => company.id === field.contractCompanyId);
        return {
          guid: field.id,
          id: field.id,
          companyId: fieldCompany.id,
          partnerNumber: fieldCompany.foreignId,
          farmNumber: fieldCompany.companyBdmId,
          farmName: fieldCompany.companyName,
          fieldGroupNumber: field.fieldGroupMfa,
          fieldNumber: field.fieldNumber,
          fieldName: field.fieldName,
          fieldSize: field.fieldSize,
          crops: [field.fieldCropName],
          varieties: [field.fieldVarietyName],
          status: fieldCompany.status,
          lastUpdate: field.tstamp,
          ndvi: field.ndvi,
        };
      });
      return fieldData;
    },

    contractFileData: (state) => (contractId) => {
      const companiesInContract = contractCompaniesByContractId(state, contractId);

      const filesInContract = contractFilesByContractId(state, contractId);

      const fileData = filesInContract.map((file) => {
        const fileCompany = companiesInContract.find((company) => company.id === file.contractCompanyId);
        const fileLink = `${origin}/contractFile/${file.id}`;
        return {
          guid: file.id,
          id: file.id,
          companyId: fileCompany?.id,
          partnerNumber: fileCompany?.foreignId,
          farmNumber: fileCompany?.companyBdmId,
          farmName: fileCompany?.companyName,
          fileName: file.fileName,
          fileLink,
          contactEmail: fileCompany?.email,
          uploadDate: file.created,
        };
      });
      return fileData;
    },
  },
};
