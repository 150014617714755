<template>
  <ModalWrapper
    ref="modalWrapper"
    size="md"
    body-class="modal-edit-field-group-archived"
    :visible="visible"
    :title="$t('Archiviert')"
    @change="(value) => $emit('change', value)"
  >
    <template #default>
      <div v-if="success">
        <p v-if="archived">
          {{ $t('Die gewählten Feldstücke wurden erfolgreich archiviert.') }}
        </p>
        <p v-else>
          {{ $t('Die gewählten Feldstücke wurden wieder aktiviert.') }}
        </p>
      </div>
      <ErrorUserMessage v-else-if="error" :error-user-message="userErrorMessage" />
      <div v-else>
        <FormFieldRadioGroup v-model="archived" class="m-0" variant="no-label" stacked>
          <FormRadio class="text-primary" variant="bordered" :value="0" :disabled="fetching">
            <template #default>
              <span>{{ $t('Aktiv') }}</span>
            </template>
          </FormRadio>
          <FormRadio variant="bordered" :value="1" :disabled="fetching">
            <template #default>
              <span>{{ $t('Archiviert') }}</span>
            </template>
          </FormRadio>
        </FormFieldRadioGroup>
      </div>
    </template>
    <template #buttons>
      <Button
        component-namespace-name="ButtonOk"
        :title="okBtnLabel"
        :disabled="okDisabled"
        :fetching="fetching"
        @click="save"
      />
      <Button
        v-if="!success && !error"
        variant="white"
        component-namespace-name="ButtonOk"
        :disabled="fetching"
        :title="$t('Abbrechen')"
        @click="hide"
      />
    </template>
  </ModalWrapper>
</template>

<script>
import { mapGetters } from 'vuex';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import Button from '@/shared/components/buttons/Button.vue';
import FormFieldRadioGroup from '@/shared/components/form/FormFieldRadioGroup.vue';
import FormRadio from '@/shared/components/form/FormRadio.vue';

export default {
  name: 'ModalEditFieldGroupArchived',
  components: {
    ErrorUserMessage,
    FormFieldRadioGroup,
    FormRadio,
    ModalWrapper,
    Button,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fieldGroupIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      archived: null,
      fetching: false,
      success: false,
      error: false,
      userErrorMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      fieldGroups: 'fieldGroups/data',
      fieldGroupErrors: 'fieldGroups/errors',
    }),
    okDisabled() {
      if (this.success || this.error) {
        return false;
      }
      return this.archived == null;
    },
    okBtnLabel() {
      if (this.success || this.error) {
        return this.$t('Schließen');
      }
      if (this.archived == null) {
        return this.$t('Speichern');
      }
      if (this.archived) {
        return this.$t('Archivieren');
      }
      return this.$t('Aktivieren');
    },
  },
  methods: {
    async save() {
      if (this.success || this.error) {
        this.hide();
        return;
      }
      if (this.archived == null) {
        return;
      }
      this.fetching = true;
      // wait 100ms so that the BE request is not blocking the update of the UI (loading status)
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 100));
      await Promise.all(
        this.fieldGroupIds.map(async (id) => {
          const entry = {
            ...this.fieldGroups[id],
            archived: Number(this.archived),
          };
          await this.$store.dispatch('fieldGroups/updateAndSyncEntry', { entry });
        }),
      );
      this.fetching = false;

      if (
        this.fieldGroupErrors.some(({ guid, errorUserMessage }) => {
          if (!this.fieldGroupIds.includes(guid)) {
            return false;
          }
          this.userErrorMessage = errorUserMessage;
          return true;
        })
      ) {
        this.error = true;
        return;
      }

      this.success = true;
    },
    hide() {
      if (this.success) {
        this.$emit('success');
      }
      this.$refs.modalWrapper.hide();
    },
  },
};
</script>

<style scoped></style>
