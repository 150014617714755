<template>
  <div class="import-e-ama">
    <MaxWidthContainer size="xl">
      <div class="import__intro text-small">
        <h2 class="h1 my-3">{{ $t('Voraussetzung: Einverständnis in eAMA abgeben!') }}</h2>
        <p>
          {{
            $t(
              'Du musst in eAMA einmalig die Erlaubnis geben, dass deine Flächen in FARMDOK importieren werden dürfen.',
            )
          }}
        </p>
        <ol class="import-e-ama__list">
          <li>{{ $t('Steig auf www.eama.at mit Betriebsnummer und Pincode (oder Handysignatur) ein.') }}</li>
          <li>{{ $t('Wechsle zum Reiter Kundendaten, Menüpunkt Datenfreigabe > Übersicht.') }}</li>
          <li>
            {{
              $t('Drück bei Datenempfänger FARMDOK GmbH die Taste „Einwilligung starten“ und folge den Anweisungen.')
            }}
          </li>
        </ol>
        <BLink href="https://www.eama.at/" target="_blank">
          {{ $t('eAMA öffnen') }}
        </BLink>
      </div>
      <hr class="w-100 mt-5 mb-5" />
      <h2>{{ $t('eAMA Online Flächen Import') }}</h2>
      <ValidationObserver v-slot="{ errors }" class="w-100" ref="validationObserver">
        <FormFieldSelect
          v-model="type"
          name="type"
          :options="[
            { value: 'MFA', text: $t('Mehrfachantrag (MFA)') },
            { value: 'HA', text: $t('Herbstantrag (HA)') },
          ]"
          :label="$t('Antrag')"
          :disabled="fetching"
        />
        <FormFieldSelect v-model="year" name="year" :options="yearOptions" :label="$t('Jahr')" :disabled="fetching" />
        <ValidationProvider v-slot="{ errors, dirty }" rules="required" slim :name="$t('eAMA-Betriebsnummer')">
          <FormFieldInput
            v-model="bdmId"
            name="bdmId"
            type="text"
            autocomplete="off"
            required
            :label="$t('eAMA-Betriebsnummer')"
            :placeholder="$t('eAMA-Betriebsnummer')"
            :disabled="fetching"
            :state="errors.length > 0 && !dirty ? false : null"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors, dirty }" rules="required" slim :name="$t('eAMA-Betriebsnummer')">
          <FormFieldInput
            v-model="pin"
            name="pin"
            type="password"
            autocomplete="off"
            required
            :label="$t('eAMA PIN')"
            :placeholder="$t('eAMA PIN')"
            :disabled="fetching"
            :state="errors.length > 0 && !dirty ? false : null"
          />
        </ValidationProvider>
        <ErrorUserMessage :vee-validate-errors="errors" :error-user-message="userErrorMessage" />
        <div class="float-right">
          <Button :disabled="!importNextEnabled" :fetching="fetching" @click="$emit('next')">
            {{ $t('Weiter') }}
          </Button>
        </div>
      </ValidationObserver>
    </MaxWidthContainer>
  </div>
</template>

<script>
import axios from 'axios';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import FormFieldSelect from '@/shared/components/form/FormFieldSelect.vue';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';

export default {
  name: 'ImportEAma',
  components: {
    Button,
    ErrorUserMessage,
    FormFieldSelect,
    FormFieldInput,
    MaxWidthContainer,
  },
  mixins: [backendResponseErrorHelpers],
  props: {
    importNextEnabled: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    importTargetProcessOrderId: {
      type: String,
      required: true,
    },
  },
  data() {
    let bdmId = null;
    if (this.$store.state.auth.currentCompanies.length > 0) {
      [{ bdmId }] = this.$store.state.auth.currentCompanies;
    }
    return {
      type: 'MFA',
      year: String(Math.min(this.$store.state.auth.currentProcessOrderName, new Date().getFullYear())),
      bdmId,
      pin: null,
    };
  },
  computed: {
    yearOptions() {
      return this.$store.getters['auth/availableProcessOrderNames']
        .map((year) => ({ value: year, text: year }))
        .sort((a, b) => b.value - a.value);
    },
  },
  mounted() {
    this.$emit('update:importNextEnabled', true);
  },
  methods: {
    async next() {
      this.$refs.validationObserver.reset();
      const valid = await this.$refs.validationObserver.validate();
      if (!valid) {
        return false;
      }
      this.$emit('update:fetching', true);
      let responseData;
      try {
        const { data } = await axios.post('/admin/rest/field/import_eama', {
          version: '2.0',
          data: {
            eamaPrefix: this.type,
            eamaFarmNo: this.bdmId,
            eamaPin: this.pin,
            cropYear: this.year,
            targetProcessOrderId: this.importTargetProcessOrderId,
          },
        });
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      }
      this.$emit('update:fetching', false);
      if (responseData.status !== 'success') {
        this.setUserErrorMessageFromResponse(responseData);
        return false;
      }
      this.$emit('update:importData', responseData.data);
      return true;
    },
  },
};
</script>

<style scoped>
.import-e-ama {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.import-e-ama__list {
  padding-inline-start: 1rem;
  text-align: left;
}

.import__intro {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
