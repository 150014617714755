<template lang="html">
  <div
    v-if="companies.length > 1 || $store.state.auth.user.superAdmin"
    class="company-switch"
    :class="{ 'company-switch--block': block }"
  >
    <span
      v-if="companies.length === 1 && $store.state.auth.user.superAdmin"
      class="company-switch__label"
      :class="{ 'company-switch__label--slim': slim }"
    >
      <span v-show="!slim && currentCompanies.length === 1">{{ $t('Betrieb') }}:</span>
      {{ title }}
    </span>
    <BDropdown
      v-if="companies.length > 1"
      ref="dropdown"
      variant="lightest"
      menu-class="company-switch__dropdown-menu shadow mt-1"
      no-caret
      no-flip
      :right="right"
      :block="block"
    >
      <template slot="button-content">
        <span class="company-switch__label" :class="{ 'company-switch__label--slim': slim }" :title="title">
          <span v-show="!slim && currentCompanies.length === 1">{{ $t('Betrieb') }}:</span>
          {{ title }}
        </span>
        <FontAwesomeIcon v-if="companies.length > 1" icon="chevron-circle-down" />
      </template>
      <BDropdownForm
        v-for="company in companiesWithoutCluster"
        class="company-switch__dropdown-form"
        :key="company.id"
        :class="{ 'company-switch__dropdown-form--active': company.isActive }"
        @submit.prevent
      >
        <div class="company-switch__item-container">
          <div class="company-switch__checkbox"></div>
          <button
            class="company-switch__company-name btn btn-link"
            :data-company-id="company.id"
            :title="company.name"
            @click="() => selectCompany(company)"
          >
            {{ company.name }}
          </button>
          <div class="company-switch__package-name">{{ company.licensePackageName }}</div>
        </div>
      </BDropdownForm>
      <div v-for="clusterId in Object.keys(companiesByCluster)" :key="clusterId">
        <div class="company-switch__select-all-button-container">
          <BButton
            variant="outline-lightest"
            class="company-switch__select-all-button"
            @click="() => selectAllCompanies(clusterId)"
          >
            {{ $t('Alle wählen') }}
          </BButton>
          <BButton
            v-if="currentCompanies.length > 0 && String(currentCompanies[0].clusterId) === clusterId"
            variant="outline-lightest"
            class="company-switch__select-all-button"
            @click="deselectAllCompanies"
          >
            {{ $t('Auswahl aufheben') }}
          </BButton>
        </div>
        <BDropdownForm
          v-for="company in companiesByCluster[clusterId]"
          class="company-switch__dropdown-form"
          :key="company.id"
          :class="{ 'company-switch__dropdown-form--active': company.isActive }"
          @submit.prevent
        >
          <div class="company-switch__item-container">
            <div class="company-switch__checkbox">
              <FormCheckbox
                :data-company-id="company.id"
                :checked="company.isActive"
                @change="() => selectCompany(company)"
              />
            </div>
            <button
              class="company-switch__company-name btn btn-link"
              :data-company-id="company.id"
              @click="() => selectCompany(company)"
            >
              {{ company.name }}
            </button>
            <div class="company-switch__package-name">{{ company.licensePackageName }}</div>
          </div>
        </BDropdownForm>
      </div>
    </BDropdown>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronCircleDown } from '@fortawesome/pro-solid-svg-icons';

import FormCheckbox from './form/FormCheckbox.vue';

library.add(faChevronCircleDown);

/**
 * Use this dropdown only inside the template #right inside TheContentbar.
 *
 * @category Shared
 * @subcategory Molecules
 * @component
 * @example
 * <CompanySwitch/>
 */
export default {
  name: 'CompanySwitch',
  components: { FormCheckbox },
  props: {
    slim: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentCompanies() {
      return this.$store.state.auth.currentCompanies;
    },
    title() {
      if (this.currentCompanies.length === 1) {
        return this.currentCompanies[0].name;
      }
      if (this.currentCompanies.length === 0) {
        return this.$t('Kein Betrieb gewählt');
      }
      let countAvailable = this.currentCompanies.length;
      const { clusterId } = this.currentCompanies[0];
      if (this.companiesByCluster[clusterId] != null) {
        countAvailable = this.companiesByCluster[clusterId].length;
      }
      return this.$t('{countSelected} von {countAvailable} Betrieben ausgewählt', {
        countSelected: this.currentCompanies.length,
        countAvailable,
      });
    },
    companies() {
      return this.$store.state.auth.userCompanies
        .filter((userCompany) => userCompany.active && userCompany.company != null)
        .map((userCompany) => ({
          id: userCompany.company.id,
          name: userCompany.company.name,
          licensePackageName: userCompany.company.licensePackageName,
          clusterId: userCompany.company.clusterId,
          isActive: this.currentCompanies.some((currentCompany) => currentCompany.id === userCompany.company.id),
        }));
    },
    companiesWithoutCluster() {
      return this.companies.filter((company) => company.clusterId == null).sort(this.sortByCompanyName);
    },
    companiesByCluster() {
      return this.companies
        .filter((company) => company.clusterId != null)
        .reduce((accumulator, company) => {
          const companiesByCluster = { ...accumulator };
          if (companiesByCluster[company.clusterId] == null) {
            companiesByCluster[company.clusterId] = [];
          }
          companiesByCluster[company.clusterId] = [...companiesByCluster[company.clusterId], company].sort(
            this.sortByCompanyName,
          );
          return companiesByCluster;
        }, {});
    },
  },
  methods: {
    sortByCompanyName(companyA, companyB) {
      if (companyA.name < companyB.name) {
        return -1;
      }
      if (companyA.name > companyB.name) {
        return 1;
      }
      return 0;
    },
    selectCompany(selected) {
      const company = this.$store.state.auth.companiesById[selected.id];
      if (company.clusterId == null) {
        this.$store.dispatch('auth/setCurrentCompanies', [company]);
        this.$refs.dropdown.hide();
        return;
      }
      let companies = this.currentCompanies.filter((currentCompany) => currentCompany.id !== company.id);
      if (selected.isActive) {
        this.$store.dispatch('auth/setCurrentCompanies', companies);
        this.updateDropdownPosition();
        return;
      }
      if (companies.length > 0 && companies[0].clusterId !== company.clusterId) {
        companies = [];
      }
      companies.push(company);
      this.$store.dispatch('auth/setCurrentCompanies', companies);
      this.updateDropdownPosition();
    },
    selectAllCompanies(clusterId) {
      const companies = this.companiesByCluster[clusterId].map(
        (company) => this.$store.state.auth.companiesById[company.id],
      );
      this.$store.dispatch('auth/setCurrentCompanies', companies);
      this.updateDropdownPosition();
    },
    deselectAllCompanies() {
      this.$store.dispatch('auth/setCurrentCompanies', []);
      this.updateDropdownPosition();
    },
    updateDropdownPosition() {
      // #344 force re-positioning of the dropdown
      this.$refs.dropdown.hide();
      this.$nextTick(() => {
        if (this.$refs.dropdown != null) {
          this.$refs.dropdown.show();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.company-switch {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--black);
}

.company-switch--block {
  display: block;
}

.company-switch__label {
  margin-right: 5px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.company-switch__label--slim {
  max-width: 150px;
}

::v-deep .company-switch__dropdown-menu {
  max-width: 98vw;
  max-height: calc(50 * var(--vh, 1vh));
  overflow-y: auto;
}

.company-switch__item-container {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  min-height: 19px;
  white-space: nowrap;
}

.company-switch__select-all-button-container {
  border-top: 1px solid var(--lightest);
  border-bottom: 1px solid var(--medium);
  background: var(--lightest);
}

.company-switch__select-all-button {
  color: var(--dark);
}

.company-switch__select-all-button:hover {
  text-decoration: underline;
  color: var(--dark);
}

.company-switch__checkbox {
  width: 21px;
  flex: none;
  color: var(--black);
}

.company-switch__company-name,
.btn.company-switch__company-name {
  display: inline-block;
  overflow: hidden;
  margin-right: var(--spacer_4);
  padding: 0;
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  color: var(--black);
}

.company-switch__package-name {
  margin-left: auto;
  font-weight: normal;
  font-size: 14px;
}

.company-switch__dropdown-form {
  color: var(--black);
}

.company-switch__dropdown-form--active {
  background-color: var(--secondary_light);
}

svg {
  margin-left: 7px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
}
</style>
