var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"modal",attrs:{"hide-header":"","hide-footer":"","no-close-on-backdrop":"","centered":"","body-class":_vm.bodyClassComputed,"visible":_vm.visible,"size":_vm.size,"static":_vm.staticComputed},on:{"change":_vm.change,"hide":_vm.onHide}},[_c('div',{staticClass:"modal-wrapper__content-wrapper",class:{ 'modal-wrapper__content-wrapper--max': _vm.size === 'max' }},[(_vm.$slots['side-column'])?_c('div',{staticClass:"modal-wrapper__side-column w-25"},[_vm._t("side-column")],2):_vm._e(),_c('div',{staticClass:"modal-wrapper__content",class:{
        'modal-wrapper__content--max': _vm.size === 'max',
        'modal-wrapper__content--with-map': _vm.$slots['map-container'] && !_vm.$slots['side-column'],
      }},[(!_vm.hideCustomHeader)?_c('header',{staticClass:"modal-wrapper__header p-3"},[_c('h1',{staticClass:"modal-wrapper__title m-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),_c('div',{staticClass:"modal-wrapper__content-inner",class:{ 'modal-wrapper__content-inner--max': _vm.size === 'max' }},[(_vm.$slots['top-content'])?_c('div',{staticClass:"modal-wrapper__top-content p-3"},[_vm._t("top-content")],2):_vm._e(),_c('div',{staticClass:"modal-wrapper__body p-3",class:[
            `modal-wrapper__body--${_vm.size}`,
            { 'modal-wrapper__body--fixed-height': _vm.innerContentHeight != null },
          ],style:({ height: _vm.innerContentHeight != null ? `${_vm.innerContentHeight}px` : 'auto' })},[_vm._t("default")],2),(_vm.$slots['bottom-content'])?_c('div',{staticClass:"modal-wrapper__bottom-content p-3",class:{ 'bg-lightest': _vm.bottomBgGray }},[_vm._t("bottom-content")],2):_vm._e()]),(_vm.footerVariant === 'content')?_c('footer',{staticClass:"modal-wrapper__content-footer p-3",class:{ 'bg-lightest': _vm.bottomBgGray }},[(!_vm.$slots['buttons'])?_c('Button',{staticClass:"mr-3",attrs:{"component-namespace-name":"ButtonOK","variant":_vm.okVariant},on:{"click":_vm.ok}},[_vm._v(" "+_vm._s(_vm.okTitle)+" ")]):_vm._e(),(!_vm.$slots['buttons'] && !_vm.noCancelButton)?_c('Button',{attrs:{"variant":"white","component-namespace-name":"ButtonCancel"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.cancelTitle)+" ")]):_vm._e(),_vm._t("buttons")],2):_vm._e()]),(_vm.$slots['map-container'] && !_vm.$slots['side-column'])?_c('div',{staticClass:"modal-wrapper__map-container",class:{
        'modal-wrapper__map-container--max': _vm.size === 'max',
        'modal-wrapper__map-container--with-content': !_vm.mapFull,
      }},[_vm._t("map-container")],2):_vm._e()]),(_vm.footerVariant === 'full')?_c('footer',{staticClass:"modal-wrapper__full-footer p-3",class:{ 'bg-lightest': _vm.bottomBgGray }},[(!_vm.$slots['buttons'] && !_vm.noOkButton)?_c('Button',{staticClass:"mr-3",attrs:{"component-namespace-name":"ButtonOK","variant":_vm.okVariant},on:{"click":_vm.ok}},[_vm._v(" "+_vm._s(_vm.okTitle)+" ")]):_vm._e(),(!_vm.$slots['buttons'] && !_vm.noCancelButton)?_c('Button',{attrs:{"variant":"white","component-namespace-name":"ButtonCancel"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.cancelTitle)+" ")]):_vm._e(),_vm._t("buttons"),(_vm.$slots['footer-content-right'] != null)?_c('div',{staticClass:"modal-wrapper__footer-content-right ml-auto"},[_vm._t("footer-content-right")],2):_vm._e()],2):_vm._e(),_c('Button',{staticClass:"modal-wrapper__close m-0 border-white bg-black text-white",attrs:{"component-namespace-name":"ButtonX"},on:{"click":_vm.cancel}},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'times']}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }