import Handsontable from 'handsontable';
import numbro from 'numbro';
import Vue from 'vue';

import { isDropdownItem } from '../components/form/formFieldDropdownTypes';
import { GUID_KEY } from '../constants';
import getRendererValueDate from '../handsontable/renderers/helpers/getRendererValueDate';
import formatDuration from '../handsontable/rework/cellTypes/duration/formatDuration';
import { HOT_DISPLAY_BUTTON_RENDERER } from '../handsontable/rework/cellTypes/subtablePrimaryColumn/constants';

/**
 * desc contains a list of keys concatinated using a dot
 * (e.g. 'foo.bar.gotcha')
 * recursively look up the keys in the object and return the value
 *
 * @param {object} obj
 * @param {string} desc
 * @returns {*}
 */
export function getDescendantProp(obj: any, desc: string): any {
  if (obj[desc] != null) {
    return obj[desc];
  }
  const arr = desc.split('.');
  if (arr.length === 1) {
    return obj[arr[0]];
  }
  if (obj[arr[0]] == null) {
    return undefined;
  }
  // @ts-ignore
  return getDescendantProp(obj[arr.shift()], arr.join('.'));
}

/**
 * desc contains a list of keys concatinated using a dot
 * (e.g. 'foo.bar.gotcha')
 * recursively look up the keys in the object and set the value
 *
 * @category Shared
 * @param {object} obj
 * @param {string} desc
 * @param {*} value
 * @returns void
 */
export function setDescendantProp(obj: any, desc: string, value: any) {
  if (obj == null) {
    return;
  }
  const arr = desc.split('.');
  if (arr.length === 1) {
    Vue.set(obj, arr[0], value);
  } else {
    // @ts-ignore
    setDescendantProp(obj[arr.shift()], arr.join('.'), value);
  }
}

/**
 * basically a toString function
 *
 * @param {object} entry
 * @param {object} column
 * @returns {string}
 */
export default function getDisplayValue(entry: any, column: any) {
  if (entry == null || column == null) {
    return '';
  }

  const key = getColumnKey(column) ?? '';

  const isSupportedEntry =
    typeof entry === 'string' || typeof entry === 'number' || typeof entry === 'boolean' || isDropdownItem(entry);
  let value = isSupportedEntry ? entry : getDescendantProp(entry, key);
  if (typeof column.data === 'function' && column.data(entry)) {
    value = column.data(entry);
  }

  if (column.type === 'farmdok.subtablePrimaryColumn') {
    if (Array.isArray(entry)) {
      return column.collapsedSubtable.getRendererValue({ values: entry });
    }
    if (value === HOT_DISPLAY_BUTTON_RENDERER) return value;

    return value?.name ?? '';
  }

  if (value == null) {
    return '';
  }

  if (column.type === 'numeric' || column.type === 'farmdok.numeric.optional') {
    if (typeof value !== 'number') {
      return '';
    }
    return numbro(value).format();
  }

  if (column.type === 'currency') {
    // TODO : implement
    return '';
  }

  if (column.type === 'date' || column.type === 'farmdok.date.optional') {
    return getRendererValueDate(value);
  }

  if (column.type === 'duration' || column.type === 'farmdok.duration' || column.type === 'farmdok.duration.optional') {
    return formatDuration(value);
  }

  if (column.type === 'dropdown') {
    const guid = entry[GUID_KEY];
    return column.getRendererValue({ value, guid });
  }

  if (column.type === 'farmdok.dropdown' || column.type === 'farmdok.dropdown.optional') {
    return value.name; // value is a DropdownItem
  }

  if (column.type === 'farmdok.dropdownOrButton') {
    return column.dropdown.getRendererValue({ value }) ?? '';
  }

  if (column.type === 'farmdok.amountUnit') {
    const { amount, unitId } = value;
    const amountFormatted = amount ? numbro(amount).format() : '';
    const unit = column.getUnitName(unitId);

    return `${amountFormatted} ${unit ?? ''}`;
  }

  if (column.type === 'time') {
    return '';
  }

  if (column.type === 'table') {
    // TODO : implement
    return '';
  }

  if (['text', 'fieldName', 'farmName', 'farmdok.text.optional'].includes(column.type)) {
    if (typeof value !== 'string') {
      return '';
    }
    return value;
  }

  if (column.type === 'fieldStatus') {
    if (typeof value !== 'string') {
      return '';
    }
    const fieldStatusLabels = {
      completed: Vue.i18n.translate('Abgeschlossen'),
      active: Vue.i18n.translate('Aktiv'),
      planned: Vue.i18n.translate('Geplant'),
      calculating: Vue.i18n.translate('Berechnen ...'),
    };
    // @ts-ignore
    return fieldStatusLabels[value];
  }

  if (column.type === 'fieldGroupArchived') {
    if (value) {
      return Vue.i18n.translate('Archiviert');
    }
    return Vue.i18n.translate('Aktiv');
  }

  if (column.type === 'sharedFieldInfo' && Array.isArray(value)) {
    return value.map((v) => v.name);
  }

  if (column.type === 'subtable') {
    return value;
  }

  if (column.type === 'partnerStatus') {
    if (typeof value !== 'string') {
      return '';
    }

    const partnerStatusLabels = {
      received: Vue.i18n.translate('Erhalten'),
      contracted: Vue.i18n.translate('Kontraktiert'),
      canceled: Vue.i18n.translate('Storniert'),
    };
    // @ts-ignore
    return partnerStatusLabels[value];
  }

  return '';
}

export function getColumnKey(column: { key?: string | undefined; data?: Handsontable.ColumnSettings['data'] }) {
  if (column.key) return column.key;
  if (column.data !== undefined && column.data !== null) {
    if (typeof column.data === 'string') return column.data;
    throw new Error('Column data property is not a string');
  }
  throw new Error(`Column has no key or data property: ${JSON.stringify(column)}`);
}
