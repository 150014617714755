<template>
  <ModalWrapper
    ref="modalWrapper"
    size="lg"
    body-class="modal-complete-crop-year"
    :visible="visible"
    :title="$t('Erntejahr abschließen')"
    @change="(value) => $emit('change', value)"
  >
    <template #default>
      <p v-if="success">
        {{
          $t('Die gewählten Kulturen wurden abgeschlossen. Die entsprechenden Felder sind jetzt für {next} aktiv.', {
            next,
          })
        }}
      </p>
      <p v-else-if="error" class="modal-complete-crop-year__error">
        {{ errorUserMessage }}
      </p>
      <div v-else-if="availableCrops.length > 0">
        <p>
          {{
            $t('Erntejahr {current} für die gewählten Kulturen abschließen und {next} als das aktive Jahr setzen.', {
              current,
              next,
            })
          }}
        </p>
        <FormCheckbox
          :checked="all"
          :disabled="fetching"
          @change="
            (value) => {
              all = value;
            }
          "
        >
          {{ $t('Alle') }}
        </FormCheckbox>
        <div class="modal-complete-crop-year__row">
          <div v-if="cropsWithMeasures.length > 0" class="modal-complete-crop-year__content-box">
            <h2 class="modal-complete-crop-year__content-box-headline">{{ $t('Erntemaßnahmen vorhanden') }}</h2>
            <FormCheckbox
              v-for="crop in cropsWithMeasures"
              v-model="selectedCrops[crop.id]"
              class="modal-complete-crop-year__content-box-checkbox"
              slim
              :key="crop.id"
              :disabled="fetching"
            >
              {{ crop.name }}
              <br />
              <small v-if="crop.fieldTotal > 1">
                ({{
                  $t('{count} von {total} Feldern mit Erntemaßnahme', {
                    count: crop.fieldCount,
                    total: crop.fieldTotal,
                  })
                }})
              </small>
              <small v-else> ({{ $t('1 Feld mit Erntemaßnahme') }}) </small>
            </FormCheckbox>
          </div>
          <div v-if="cropsWithoutMeasures.length > 0" class="modal-complete-crop-year__content-box">
            <h2 class="modal-complete-crop-year__content-box-headline">{{ $t('Kulturen ohne Erntemaßnahmen') }}</h2>
            <FormCheckbox
              v-for="crop in cropsWithoutMeasures"
              v-model="selectedCrops[crop.id]"
              class="modal-complete-crop-year__content-box-checkbox"
              slim
              :key="crop.id"
              :disabled="fetching"
            >
              {{ crop.name }}
              <br />
              <small v-if="crop.fieldTotal > 1">
                ({{ $t('{count} Felder ohne Erntemaßnahme', { count: crop.fieldTotal - crop.fieldCount }) }})
              </small>
              <small v-else> ({{ $t('1 Feld ohne Erntemaßnahme') }}) </small>
            </FormCheckbox>
          </div>
        </div>
      </div>
      <div v-else>
        {{ $t('Keine aktiven Kulturen im aktuellen Erntejahr vorhanden.') }}
      </div>
    </template>
    <template #buttons>
      <BButton variant="primary" class="mr-3" :disabled="okDisabled" @click="save">
        <span>
          {{ success || error || crops.length === 0 ? $t('Schließen') : $t('Ernte abschließen') }}
          <FontAwesomeIcon v-if="fetching" icon="circle-notch" spin />
        </span>
      </BButton>
      <BButton v-if="!success && !error" variant="white" :disabled="fetching" @click="hide">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';

library.add(faCircleNotch);

export default {
  name: 'ModalCompleteCropYear',
  components: { FormCheckbox, ModalWrapper },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fieldIdsWithHarvestingMeasure: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      availableCrops: [],
      selectedCrops: {},
      fetching: false,
      success: false,
      error: false,
      errorUserMessage: null,
    };
  },
  mounted() {
    const cropsWithMeasures = {};
    this.activeFields.forEach(({ id, cropId }) => {
      const foundCropName = cropId != null ? this.crops[cropId].name : this.$t('Keine Kultur');
      if (cropsWithMeasures[cropId] == null) {
        cropsWithMeasures[cropId] = {
          id: cropId,
          name: foundCropName,
          fieldCount: 0,
          fieldTotal: 0,
        };
      }
      cropsWithMeasures[cropId].fieldTotal += 1;
      if (this.fieldIdsWithHarvestingMeasure.includes(id)) {
        cropsWithMeasures[cropId].fieldCount += 1;
      }
    });
    this.availableCrops = Object.values(cropsWithMeasures);

    this.initCrops();
  },
  computed: {
    ...mapGetters({
      fields: 'fields/data',
      crops: 'products/crops',
    }),
    current() {
      return Number(this.$store.state.auth.currentProcessOrderName);
    },
    next() {
      return this.current + 1;
    },
    activeFields() {
      return Object.values(this.fields).filter((field) => field.status === 'active');
    },
    cropsWithMeasures() {
      return this.availableCrops
        .filter((crop) => crop.fieldCount > 0)
        .sort((a, b) => {
          if (a.id === -1) {
            return 1;
          }
          if (b.id === -1) {
            return -1;
          }
          return a.name.localeCompare(b.name);
        });
    },
    cropsWithoutMeasures() {
      return this.availableCrops
        .filter((crop) => crop.fieldCount === 0)
        .sort((a, b) => {
          if (a.id === -1) {
            return 1;
          }
          if (b.id === -1) {
            return -1;
          }
          return a.name.localeCompare(b.name);
        });
    },
    all: {
      get() {
        return Object.values(this.selectedCrops).every((value) => value);
      },
      set(value) {
        Object.keys(this.selectedCrops).forEach((cropId) => {
          this.selectedCrops[cropId] = value;
        });
      },
    },
    okDisabled() {
      if (this.fetching) {
        return true;
      }
      if (this.success || this.error || this.availableCrops.length === 0) {
        return false;
      }
      return Object.values(this.selectedCrops).every((value) => !value);
    },
  },
  watch: {
    crops() {
      this.initCrops();
    },
  },
  methods: {
    initCrops() {
      this.availableCrops.forEach((crop) => {
        if (this.selectedCrops[crop.id] == null) {
          Vue.set(this.selectedCrops, crop.id, false);
        }
      });
    },
    async save() {
      if (this.success || this.error || this.availableCrops.length === 0) {
        this.$refs.modalWrapper.hide();
        return;
      }
      this.fetching = true;
      // wait 100ms so that the BE request is not blocking the update of the UI (loading status)
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 100));
      const fieldIds = this.activeFields.reduce((result, field) => {
        if (this.selectedCrops[field.cropId] === true) {
          result.push(field.id);
        }
        return result;
      }, []);
      const data = await this.$store.dispatch('fields/setStatus', {
        status: 'completed',
        fieldIds,
      });
      if (data.status === 'error') {
        this.fetching = false;
        this.error = true;
        this.errorUserMessage = data.errorUserMessage.join('\n');
        return;
      }
      await this.$store.dispatch('auth/refreshUserCompanies');
      this.fetching = false;
      this.success = true;
    },
    hide() {
      this.$refs.modalWrapper.hide();
    },
  },
};
</script>

<style scoped>
.modal-complete-crop-year__error {
  color: var(--danger_dark);
}

.modal-complete-crop-year__row {
  display: flex;
  flex-direction: row;
  margin-top: var(--spacer_3);
}

.modal-complete-crop-year__content-box {
  flex: 1;
  padding: var(--spacer_3);
  border: 1px solid var(--medium);
  border-radius: 2px;
  background: var(--lightest);
}

.modal-complete-crop-year__content-box:first-child {
  margin-right: var(--spacer_5);
}

.modal-complete-crop-year__content-box-headline {
  margin-bottom: var(--spacer_3);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
</style>
