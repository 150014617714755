<template>
  <div class="form-button-container">
    <span class="cursor-pointer" @click="onAddAdditionalFiles">
      <FontAwesomeIcon icon="cloud-upload" class="mr-2" />
      {{ $t('Weitere Dateien durch Ablegen hinzufügen') }}
    </span>
    <div>
      <Button outline :disabled="fetching" @click="onReset">
        {{ $t('Alle Dateien entfernen') }}
      </Button>
      <Button v-if="uploadVisible" :fetching="fetching" :disabled="uploadDisabled" @click="onUpload">
        {{ $t('Upload') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/shared/components/buttons/Button.vue';

export default {
  name: 'FilesListButtons',
  components: {
    Button,
  },
  props: {
    uploadDisabled: {
      type: Boolean,
      default: false,
    },
    uploadVisible: {
      type: Boolean,
      default: true,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onAddAdditionalFiles() {
      this.$emit('onAddAdditionalFiles');
    },
    onReset() {
      this.$emit('onReset');
    },
    onUpload() {
      this.$emit('onUpload');
    },
  },
};
</script>

<style scoped>
.form-button-container {
  display: flex;
  padding: var(--spacer_3);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--gray_300);
}
</style>
