
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import SidebarZones from '../../components/sidebarZones/SidebarZones.vue';
import { VisualizationMode } from '../../store/types';

export default defineComponent({
  name: 'SidebarZonesContainer',
  components: {
    SidebarZones,
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilizationCereals', ['zoneMap']),
    visualizationMode(): VisualizationMode {
      return this.zoneMap.visualizationMode;
    },
  },
});
