import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { CropsState } from './types';

const actions: ActionTree<CropsState, RootState> = {
  ...subscribableStore.actions,
};

export default actions;
