import { Unit } from '@/shared/api/rest/models/unit';

export enum AmountType {
  Total,
  PerHa,
}

export function findUnitByName(unitName: string, units: Record<string, Unit>): Unit | undefined {
  return Object.values(units).find((unit) => unit.name === unitName);
}

export function findTotalUnit(relativeUnit: Unit, units: Record<string, Unit>): Unit | null {
  const totalUnitId = relativeUnit.numeratorUnitId;
  if (!totalUnitId) return null;

  const totalUnit = units[totalUnitId];

  if (!totalUnit) return null;

  return totalUnit;
}

export function findRelativeUnit(totalUnit: Unit, units: Record<string, Unit>): Unit | null {
  if (totalUnit && totalUnit.groupId) {
    const relativeUnits = Object.values(units).filter(
      (unit) => unit.groupId === totalUnit?.groupId && unit.byUnitId !== null && !unit.isPerLoad,
    );

    const perHaUnit = relativeUnits.find((relativeUnit) => relativeUnit.name.endsWith(`${totalUnit.name}/ha`));
    if (perHaUnit) return perHaUnit;
    const firstRelativeUnit = relativeUnits[0];
    if (firstRelativeUnit) return firstRelativeUnit;
  }
  return null;
}

export function isTotalUnit(unit: Unit): boolean {
  if (unit.byUnitId !== null && !unit.isPerLoad) {
    return false;
  }
  return true;
}

export function isRelativeUnit(unit: Unit): boolean {
  if (unit.byUnitId !== null && !unit.isPerLoad) {
    return true;
  }
  return false;
}
