
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import RequiredAmountTable from '@/shared/components/RequiredAmountTable.vue';

import { Getters } from '../../store/getters';
import { Zones } from '../../store/types';

export default defineComponent({
  name: 'RequiredAmountContainer',
  components: { RequiredAmountTable },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilizationCereals', ['zones']),
    ...mapGetters({
      zoneDosage: 'precisionFarming/applicationMaps/fertilizationCereals/zoneDosage',
    }),
    zoneSizesTotal(): number {
      return (this.zones as Zones).data?.reduce((total, zone) => total + zone.size, 0) ?? 0;
    },
    fertilizerAmountTotal(): number {
      return (
        (this.zoneDosage as Getters['zoneDosage'])?.reduce(
          (sum, zone, index) => sum + zone.dosage * ((this.zones as Zones).data?.[index]?.size ?? 0),
          0,
        ) ?? 0
      );
    },
  },
});
