import initialStateSubscribable from '@/shared/mixins/store/subscribableData/initialState';
import { initialState as initialStateUsedItems } from '@/shared/mixins/store/usedItems';

import { ActivityTypesState } from './types';

export default function initialState(): ActivityTypesState {
  return {
    ...initialStateSubscribable(),
    ...initialStateUsedItems(),
  };
}
