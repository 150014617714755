import { ActionContext } from 'vuex';

import setActivityTypeOrSibling from '@/activities/utils/activity/setActivityTypeOrSibling';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../types';

export default function updateActivityType(
  { state, commit, rootGetters }: ActionContext<CreateEditActivityState, RootState>,
  syncName: string,
): void {
  state.activities.forEach((activity) => {
    const updatedActivity = setActivityTypeOrSibling(
      activity,
      syncName,
      rootGetters['auth/getCompanyByProcessOrderId'],
      rootGetters['activityTypes/getActivityTypeOfCompanyBySyncName'],
      rootGetters['activityTypes/getBySyncName'],
    );
    commit('setActivityTypeId', { activityId: activity.id, activityTypeId: updatedActivity.activityTypeId });
  });
}
