<template>
  <HotTable :id="id" :settings="tableSettings" :data="[]" />
</template>

<script>
import { HotTable } from '@handsontable/vue';

// WORKAROUND : until https://forum.handsontable.com/t/typeerror-cannot-convert-a-symbol-value-to-a-string/3768 is resolved

export default {
  name: 'HotWrapper',
  components: { HotTable },
  props: {
    id: {
      type: String,
      required: true,
    },
    tableSettings: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
