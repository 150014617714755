<template>
  <SidebarContentWrapper class="mb-3">
    <div class="header-logo-container">
      <img :src="src" class="header-logo" alt="Logo" />
    </div>
  </SidebarContentWrapper>
</template>

<script>
import SidebarContentWrapper from '@/shared/components/SidebarContentWrapper.vue';

export default {
  name: 'HeaderLogo',
  components: {
    SidebarContentWrapper,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: 'Logo',
    },
  },
};
</script>

<style scoped>
.header-logo-container {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
}

.header-logo {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
</style>
