<template>
  <div
    class="table-base"
    @drag.prevent
    @dragstart.prevent
    @dragend.prevent="$emit('update:isDragover', false)"
    @dragover.prevent="$emit('update:isDragover', true)"
    @dragenter.prevent="$emit('update:isDragover', true)"
    @dragleave.prevent="$emit('update:isDragover', false)"
    @drop.prevent="(event) => $emit('drop', event)"
  >
    <TableLoading v-if="loading" class="flex-grow-1" :columns="tableSettingsComputed.columns" />
    <FetchingError v-else-if="fetchingError" @reload="$emit('reload')" />
    <div v-else-if="noContent" class="table-base__no-content">
      <slot name="no-content" />
    </div>
    <div v-else class="table-base__table-container">
      <HotWrapper
        ref="table"
        :id="id"
        :class="{
          'handsontable--read-only-default-bg': disableReadOnlyBg,
          'handsontable--invert-selection-background': invertSelectionBg,
          [innerClass]: true,
        }"
        :table-settings="tableSettingsForHot"
        @hook:mounted="tableMounted"
        @hook:beforeDestroy="tableDestroyed"
      />
      <TableRowHoverButtons
        v-if="hoverButtons && contextMenu && !loading"
        ref="tableRowHoverButtons"
        :target="featureRowHoverTarget"
        :no-main-button="hoverButtonsNoMainButton"
        :main-button-icon="hoverButtonsMainButtonIcon"
        :main-button-title="hoverButtonsMainButtonTitle"
        :read-only="readOnly"
        @mainButton="$emit('rowHoverMainButton', [featureRowHoverGuid])"
        @contextmenu="(event) => featureTableContextMenuShow(event, featureRowHoverGuid)"
      />
      <TableContextMenu
        v-if="contextMenu && !loading"
        ref="tableContextMenu"
        :boundary="$el"
        :hide-edit="contextMenuHideEdit"
        :hide-insert="contextMenuHideInsert"
        @insert="insert({ guid: featureTableContextMenuGuid })"
        @edit="$emit('edit', [featureTableContextMenuGuid])"
        @remove="$emit('remove:row', tableDataComputed[featureTableContextMenuGuid])"
      >
        <slot name="context-menu" />
      </TableContextMenu>
      <TooltipCellError :cell-error="cellError" />
      <TooltipRowError :row-error="rowError" />
      <TooltipTableHeader :table-header-tooltips="tableHeaderTooltips" />
      <TableHeaderContextMenu
        :target="tableHeaderContextMenuTarget"
        :filter="tableHeaderContextMenuFilter"
        :available-values="tableHeaderContextMenuAvailableValues"
        @close="tableHeaderContextMenuClose"
        @sort="tableHeaderContextMenuSort"
        @update:filter="tableHeaderContextMenuUpdateFilter"
      />
    </div>
    <div v-show="!fetchingError && !noContent">
      <slot name="footer" class="table-base__footer-container" />
    </div>
    <div v-if="noSearchResults" class="table-base__no-search-results">
      <slot name="no-search-results" />
    </div>
  </div>
</template>

<script>
import deletableTableData from '@/shared/handsontable/mixins/featureDeletableTableData';
import editableTableData from '@/shared/handsontable/mixins/featureEditableTableData';
import expandableRows from '@/shared/handsontable/mixins/featureExpandableRows';
import featureExport from '@/shared/handsontable/mixins/featureExport';
import filterTable from '@/shared/handsontable/mixins/featureFilterTable';
import highlightedFixedColumns from '@/shared/handsontable/mixins/featureHighlightedFixedColumns';
import insertRow from '@/shared/handsontable/mixins/featureInsertRow';
import observeTableData from '@/shared/handsontable/mixins/featureObserveTableData';
import preventWrapAround from '@/shared/handsontable/mixins/featurePreventWrapAround';
import removeRow from '@/shared/handsontable/mixins/featureRemoveRow';
import selectableRows from '@/shared/handsontable/mixins/featureSelectableRows';
import tableContextMenu from '@/shared/handsontable/mixins/featureTableContextMenu';
import tableHeaderContextMenu from '@/shared/handsontable/mixins/featureTableHeaderContextMenu';
import tableHeaderLink from '@/shared/handsontable/mixins/featureTableHeaderLink';
import tableHeaderTooltip from '@/shared/handsontable/mixins/featureTableHeaderTooltip';
import tableRowHover from '@/shared/handsontable/mixins/featureTableRowHover';
import tablesettingsColumn from '@/shared/handsontable/mixins/featureTablesettingsColumn';
import table from '@/shared/handsontable/mixins/tableBase';
import resizeObserver from '@/shared/mixins/resizeObserver';

import FetchingError from './FetchingError.vue';
import HotWrapper from './HotWrapper.vue';
import TableContextMenu from './TableContextMenu.vue';
import TableHeaderContextMenu from './TableHeaderContextMenu.vue';
import TableLoading from './TableLoading.vue';
import TableRowHoverButtons from './TableRowHoverButtons.vue';
import TooltipCellError from './TooltipCellError.vue';
import TooltipRowError from './TooltipRowError.vue';
import TooltipTableHeader from './TooltipTableHeader.vue';

export default {
  name: 'TableBase',
  components: {
    TableHeaderContextMenu,
    FetchingError,
    HotWrapper,
    TableRowHoverButtons,
    TooltipCellError,
    TooltipRowError,
    TooltipTableHeader,
    TableContextMenu,
    TableLoading,
  },
  mixins: [
    table,
    featureExport,
    selectableRows,
    editableTableData,
    deletableTableData,
    filterTable,
    highlightedFixedColumns,
    tableHeaderTooltip,
    tableRowHover,
    tableContextMenu,
    tableHeaderContextMenu,
    insertRow,
    removeRow,
    observeTableData,
    preventWrapAround,
    tableHeaderLink,
    expandableRows,
    tablesettingsColumn,
    resizeObserver,
  ],
  props: {
    /**
     * Has to be set as the handsontable uses the id for storing settings into localStorage.
     * Ideally you should prepend the current companyId so the user can store different settings per company.
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Applied directly onto the handsontable wrapper.
     */
    innerClass: {
      type: String,
      default: '',
    },
    /**
     * If set to true the cell background color will be default for all read-only cells (instead of light grey).
     */
    disableReadOnlyBg: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to true the cell background color will be default gray and turns white if the cell is selected.
     */
    invertSelectionBg: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    resizeObserverSize() {
      this.$nextTick(() => {
        this.hotRender();
      });
    },
  },
};
</script>

<style scoped>
.table-base {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.table-base__table-container {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.table-base__no-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  overflow: hidden;
}

.table-base__no-search-results {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 70px;
  overflow-y: scroll;
  background-color: var(--white);
}
</style>
