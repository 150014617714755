<template>
  <div class="step-update-names w-100">
    <h1 class="step-update-names__headline">
      {{ $t('Betrieb') }}
    </h1>
    <ValidationObserver v-slot="{ errors }" ref="validationObserver" slim>
      <BForm class="w-100 mb-4" novalidate @submit.prevent="submit">
        <FormFieldInput
          v-model="firstnameInternal"
          class="w-100 mt-0"
          name="firstname"
          type="text"
          autocomplete="given-name"
          variant="vertical-lg"
          :label="$t('Vorname')"
          :placeholder="$t('Vorname')"
          :disabled="fetching"
          @input="$emit('input')"
        />
        <FormFieldInput
          v-model="lastnameInternal"
          class="w-100"
          name="lastname"
          type="text"
          autocomplete="family-name"
          variant="vertical-lg"
          :label="$t('Nachname')"
          :placeholder="$t('Nachname')"
          :disabled="fetching"
          @input="$emit('input')"
        />
        <ValidationProvider v-slot="{ errors, dirty }" rules="required" slim :name="$t('Betriebsbezeichnung')">
          <FormFieldInput
            v-model="companyNameComputed"
            class="w-100"
            name="companyName"
            type="text"
            variant="vertical-lg"
            required
            :label="$t('Betriebsbezeichnung')"
            :placeholder="$t('Betriebsbezeichnung')"
            :disabled="fetching"
            :state="errors.length > 0 && !dirty ? false : null"
            @input="$emit('input')"
          />
        </ValidationProvider>
        <p v-if="firstError(errors)" class="text-danger">{{ firstError(errors) }}&nbsp;</p>
        <ErrorUserMessage v-else :error-user-message="userErrorMessage" />
        <Button type="submit" variant="primary" size="lg" block class="my-0" :disabled="fetching">
          <FontAwesomeIcon v-if="fetching" icon="circle-notch" class="text-white" spin />
          <span v-else>{{ $t('Speichern') }}</span>
        </Button>
      </BForm>
    </ValidationObserver>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import Button from '@/shared/components/buttons/Button.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import veeValidateHelpers from '@/shared/mixins/form/veeValidateHelpers';

library.add(faCircleNotch);

export default {
  name: 'StepUpdateNames',
  components: { ErrorUserMessage, FormFieldInput, Button },
  mixins: [veeValidateHelpers],
  props: {
    fetching: {
      type: Boolean,
      default: false,
    },
    userErrorMessage: {
      type: String,
      default: null,
    },
    firstname: {
      type: String,
      default: null,
    },
    lastname: {
      type: String,
      default: null,
    },
    companyName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      firstnameInternal: this.firstname,
      lastnameInternal: this.lastname,
      companyNameInternal: this.companyName,
      companyNameChanged: false,
    };
  },
  computed: {
    companyNameComputed: {
      get() {
        if (this.companyNameChanged) {
          return this.companyNameInternal;
        }
        const parts = [];
        if (typeof this.firstnameInternal === 'string') {
          parts.push(this.firstnameInternal.trim());
        }
        if (typeof this.lastnameInternal === 'string') {
          parts.push(this.lastnameInternal.trim());
        }
        return parts.join(' ').trim();
      },
      set(value) {
        this.companyNameInternal = value;
        this.companyNameChanged = true;
      },
    },
  },
  methods: {
    submit() {
      this.$emit('submit');
      this.$refs.validationObserver.reset();
      this.$refs.validationObserver.handleSubmit(() => {
        this.$emit('save', {
          firstname: this.firstnameInternal,
          lastname: this.lastnameInternal,
          companyName: this.companyNameComputed,
        });
      });
    },
  },
};
</script>

<style scoped>
.step-update-names__headline {
  margin-bottom: var(--spacer_3);
  text-align: center;
  font-size: 28px;
  line-height: 38px;
}
</style>
