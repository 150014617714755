<template>
  <TableBase
    ref="table"
    context-menu
    hover-buttons
    class="table-fields-container"
    :id="id"
    :class="[
      { 'table-fields-container--is-dragover': isDragover },
      { 'table-fields-container--is-uploading': isUploading },
    ]"
    :data-dragover-label="isUploading ? $t('Lade hoch ...') : $t('Leg deine Datei hier ab')"
    :cells="columnDropdownCompaniesCells"
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :visible-rows="visibleRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    :hover-buttons-main-button-icon="['fas', 'arrow-to-left']"
    :hover-buttons-main-button-title="$t('Details anzeigen')"
    :read-only="fieldsReadOnly"
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @update:cell="updateEntryByKeyAndValue"
    @create:newRow="insertAndSyncNewEntry"
    @remove:row="(entry) => removeEntries([entry])"
    @click:status="onClickStatus"
    @click:sharedFieldInfo="onClickSharedFieldInfo"
    @afterScrollVertically="$emit('click:status')"
    @afterScrollHorizontally="$emit('click:status')"
    @reload="loadData"
    @edit="(guids) => $emit('edit', guids)"
    @update:isDragover="(value) => (isDragover = value)"
    @drop="onFileDrop"
    @rowHoverMainButton="showDetailPage"
    @update:tableSettings="onTableSettingsChanged"
  >
    <template #footer>
      <TableFooter
        :total-rows="Object.keys(tableData).length"
        :visible-rows="visibleRows.length"
        :selected-rows="selectedRows.length"
      >
        {{ $t('Summe Größe: {sizeTotalFormatted} ha', { sizeTotalFormatted }) }}
      </TableFooter>
    </template>
    <template #no-content>
      <NoContent
        :type="noContentType.type"
        :variant="noContentType.variant"
        :fetching="noContentFetching"
        :error-response-data="noContentError"
        :read-only="fieldsReadOnly"
        @create="$emit('create')"
        @import="$router.push({ name: 'fields/import' })"
        @copyFromPreviousProcessOrder="() => copyAllFieldsFromProcessOrder('previous')"
        @copyFromNextProcessOrder="() => copyAllFieldsFromProcessOrder('next')"
        @refresh="refresh"
      />
    </template>
    <template v-if="!isDragover && !isUploading" #no-search-results>
      <NoContent type="search" :read-only="fieldsReadOnly" @clearAllFilters="clearAllFilters" />
    </template>
  </TableBase>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFlag } from '@fortawesome/pro-regular-svg-icons';
import { faArrowToLeft, faFlagCheckered } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';
import { mapState } from 'vuex';

import columnDropdownCompanies from '@/auth/mixins/containers/columnDropdownCompanies';
import columnDropdownCustomers from '@/customers/mixins/containers/columnDropdownCustomers';
import columnDropdownCrops from '@/products/crops/mixins/containers/columnDropdownCrops';
import { REGION_GUIDS } from '@/shared/constants';
import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import subscribableTableData from '@/shared/handsontable/mixins/containers/featureSubscribableTableData';
import syncableTableData from '@/shared/handsontable/mixins/containers/featureSyncableTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';
import { dataToBase64 } from '@/shared/mixins/store/subscribableData';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import columnDropdownVarieties from '@/varieties/mixins/containers/columnDropdownVarieties';

import NoContent from '../components/NoContent.vue';
import {
  amaCode,
  catchCropVariant,
  companyId,
  crop,
  customer,
  gwArea,
  kindOfUse,
  preCrop,
  variety,
} from '../handsontable/columns/columns';
import columnsTableFields from '../handsontable/columns/tableFields';
import calculateSizeTotal from '../mixins/calculateSizeTotal';
import columnDropdownForFields from '../mixins/containers/columnDropdownForFields';
import {
  STORE_KEY_AMA_CODES,
  STORE_KEY_CATCH_CROP_VARIANTS,
  STORE_KEY_GW_AREAS,
  STORE_KEY_KIND_OF_USE_TYPES,
} from '../mixins/containers/dropdownItemsForFields';

library.add(faFlagCheckered, faFlag, faArrowToLeft);

export default {
  name: 'TableFieldsContainer',
  components: {
    TableFooter,
    TableBase,
    NoContent,
  },
  props: {
    filterString: {
      type: String,
      default: '',
    },
    fieldIdsWithHarvestingMeasure: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mixins: [
    tableContainerBase,
    subscribableTableData('fields', { forceRefresh: true }),
    syncableTableData('fields'),
    columnDropdownCompanies(companyId.key),
    columnDropdownCustomers({
      [customer.key]: 'fieldGroup.customer',
    }),
    columnDropdownCrops({
      [crop.key]: 'crop',
      [preCrop.key]: 'preCrop',
    }),
    columnDropdownVarieties({
      [variety.key]: 'variety',
    }),
    columnDropdownForFields({
      [kindOfUse.key]: STORE_KEY_KIND_OF_USE_TYPES,
      [catchCropVariant.key]: STORE_KEY_CATCH_CROP_VARIANTS,
      [amaCode.key]: STORE_KEY_AMA_CODES,
      [gwArea.key]: STORE_KEY_GW_AREAS,
    }),
    calculateSizeTotal,
  ],
  data() {
    return {
      columnDefinition: columnsTableFields,
      isDragover: false,
      isUploading: false,
      noContentFetching: false,
      noContentError: null,
      nextCropYearHasFields: null,
      previousCropYearHasFields: null,
      sharedFieldsByFieldId: {},
      sharedFieldsCompaniesById: {},
      sharedFieldsByShareId: {},
      sharedFieldsInfoLoading: true,
    };
  },
  computed: {
    ...mapState('fields', {
      loadingNdvi: (state) => state.loadingNdvi,
      loadingMbi: (state) => state.loadingMbi,
      pendingNdvi: (state) => Object.values(state.data).filter((field) => field.ndvi === undefined),
      pendingMbi: (state) => Object.values(state.data).filter((field) => field.mbi === undefined),
    }),
    id() {
      return ['hot-fields', ...this.currentCompanies.map((company) => company.id)].join('-');
    },
    loading() {
      const customersLoading = this.$store.getters.currentCompaniesHaveFeatureVisible(
        availableFeatures.FEATURE_CUSTOMERS,
      )
        ? this.$store.getters['customers/loading']
        : false;

      return (
        this.sharedFieldsInfoLoading ||
        this.$store.getters['fields/loading'] ||
        this.$store.getters['products/crops/loading'] ||
        this.$store.getters['varieties/loading'] ||
        customersLoading
      );
    },
    cropYearNeighbourInfo() {
      const availableProcessOrderNames = this.$store.getters['auth/availableProcessOrderNames'];
      const current = this.$store.state.auth.currentProcessOrderName;
      const previous = String(Number(current) - 1);
      const next = String(Number(current) + 1);
      return {
        current,
        previous: availableProcessOrderNames.includes(previous) ? previous : null,
        next: availableProcessOrderNames.includes(next) ? next : null,
      };
    },
    noContentType() {
      if (this.previousCropYearHasFields === true) {
        return { type: 'cropYear', variant: 'copyFromPrevious' };
      }
      if (this.nextCropYearHasFields === true) {
        return { type: 'cropYear', variant: 'copyFromNext' };
      }
      if (this.previousCropYearHasFields === null || this.nextCropYearHasFields === null) {
        return { type: 'loading' };
      }
      if (this.currentCompanies.some((company) => [REGION_GUIDS.AT, REGION_GUIDS.DE].includes(company.regionId))) {
        return { type: 'company', variant: 'import' };
      }
      return { type: 'company', variant: 'create' };
    },
    processOrdersReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_PROCESS_ORDER);
    },
    fieldsReadOnly() {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_FIELDS);
    },
    currentProcessOrderIds() {
      return this.$store.getters['auth/currentProcessOrderIds'];
    },
    currentCompanyIds() {
      return this.$store.getters['auth/currentCompanyIds'];
    },
    currentCompanies() {
      return this.$store.state.auth.currentCompanies;
    },
    sgdVersion() {
      return (
        this.$store.getters['auth/currentProcessOrders'].map((order) => order.sgdVersion).find(notNullOrUndefined) ?? ''
      );
    },
  },
  watch: {
    currentProcessOrderIds() {
      this.loadData();
    },
    currentCompanyIds() {
      this.loadData();
    },
    fieldIdsWithHarvestingMeasure() {
      if (this.$refs.table != null) {
        this.$refs.table.hotRender();
      }
    },
    tableData() {
      this.noContentError = null;
      this.checkForFieldsInNeighbourCropYears();
    },
    loading() {
      this.checkForFieldsInNeighbourCropYears();
    },
  },
  created() {
    this.unsubscribeFromStore = this.$store.subscribe(({ type }) => {
      if (type === 'afterResetData') {
        this.loadSharedFieldsInfo();
      }
    });
    this.loadData();
    this.loadSharedFieldsInfo();
  },
  beforeDestroy() {
    this.unsubscribeFromStore();
  },
  methods: {
    tableMounted() {
      this.loadNdviData();
      this.loadMbiData();
    },
    async loadSharedFieldsInfo(preventLoadingFlag = false, resetSharedFields = true) {
      if (!preventLoadingFlag) {
        this.sharedFieldsInfoLoading = true;
      }
      if (resetSharedFields) {
        this.sharedFieldsByFieldId = {};
        this.sharedFieldsCompaniesById = {};
        this.sharedFieldsByShareId = {};
      }
      if (this.$store.getters['auth/currentProcessOrderIds'].length === 0) {
        return;
      }
      const filter = ['field.processOrderId', 'IN', this.$store.getters['auth/currentProcessOrderIds']];
      const urlParts = [
        '/admin/rest/sharedField?version=2.0',
        'disablePagination=1',
        `filter=${dataToBase64(filter)}`,
        `resolveInSeparateList=${dataToBase64(['company'])}`,
      ];
      try {
        const { data } = await axios.get(urlParts.join('&'));
        if (Array.isArray(data.data)) {
          this.sharedFieldsByFieldId = data.data.reduce((sharedFieldsByFieldId, sharedField) => {
            const current = { ...sharedFieldsByFieldId };
            if (current[sharedField.fieldId] == null) {
              current[sharedField.fieldId] = [sharedField.id];
            } else {
              current[sharedField.fieldId] = [...current[sharedField.fieldId], sharedField.id];
            }
            return current;
          }, {});

          this.sharedFieldsByShareId = data.data.reduce((sharedFieldsByShareId, sharedField) => {
            const current = { ...sharedFieldsByShareId };
            current[sharedField.id] = sharedField;
            return current;
          }, {});
        }
        if (data.resolved != null && Array.isArray(data.resolved.company)) {
          this.sharedFieldsCompaniesById = data.resolved.company.reduce(
            (sharedFieldsCompaniesById, company) => ({
              ...sharedFieldsCompaniesById,
              [company.id]: company,
            }),
            {},
          );
        }
      } catch (error) {
        console.error('TableFieldsContainer.vue :: unable to load shared fields info.', error);
      }
      this.sharedFieldsInfoLoading = false;
      if (preventLoadingFlag && this.$refs.table != null) {
        this.$refs.table.hotRender();
      }
      this.$emit('updateSharedFieldInfo');
    },
    updateSharedFields(resetSharedFields) {
      // force reload of table if column is not visible yet
      this.loadSharedFieldsInfo(Object.keys(this.sharedFieldsByFieldId).length > 0, resetSharedFields);
    },
    showDetailPage([fieldId]) {
      if (this.selectedRows.length > 0 && !this.selectedRows.includes(fieldId)) {
        this.$emit('update:selectedRows', [...this.selectedRows, fieldId]);
      }
      this.$router.push({ name: 'fields/record', params: { fieldId } });
    },
    async onFileDrop(event) {
      this.isDragover = false;
      const { files } = event.dataTransfer;
      if (files.length < 1) {
        return;
      }
      this.isUploading = true;
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('file[]', file);
      });
      let responseData;
      try {
        const { data } = await axios.post('/admin/rest/field/import_upload_files', formData);
        responseData = getRestResponseData(data);
      } catch (error) {
        responseData = getRestResponseData(error);
      }
      this.isUploading = false;
      await this.$router.push({
        name: 'fields/import/load-data',
        params: {
          method: 'upload',
          fileUploadResponseData: responseData,
        },
      });
    },
    async loadAdditionalData() {
      // fields/subscribe will be called via mixin subscribableTableData
      this.$store.dispatch('products/crops/subscribe');
      this.$store.dispatch('varieties/subscribe');

      if (this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_CUSTOMERS)) {
        this.$store.dispatch('customers/subscribe');
      }
      await this.loadNdviData();
      await this.loadMbiData();
    },
    onClickStatus({ target, visualRow }) {
      this.$emit('click:status', {
        target,
        guid: this.$refs.table.visualRowToGuid(visualRow),
      });
    },
    onClickSharedFieldInfo({ shareId }) {
      const fieldShare = this.sharedFieldsByShareId[shareId];
      const { sharingEmail } = this.sharedFieldsCompaniesById[fieldShare.companyId];
      this.$emit('click:sharedFieldInfo', {
        ...fieldShare,
        sharingEmail,
      });
    },
    async checkForFieldsInNeighbourCropYears() {
      this.previousCropYearHasFields = null;
      this.nextCropYearHasFields = null;
      if (this.loading || Object.keys(this.tableData).length > 0) {
        return;
      }
      const hasFields = await Promise.all(
        [this.cropYearNeighbourInfo.previous, this.cropYearNeighbourInfo.next].map(async (processOrderName) => {
          if (processOrderName == null) {
            return false;
          }
          return this.$store.getters['auth/currentCompaniesHaveFieldsInCropYear'](processOrderName);
        }),
      );
      [this.previousCropYearHasFields, this.nextCropYearHasFields] = hasFields;
    },
    clearAllFilters() {
      this.$refs.table.clearAllFilters();
    },
    async copyAllFieldsFromProcessOrder(direction) {
      this.noContentFetching = true;
      const response = await this.$store.dispatch('fields/copyAllFieldsFromProcessOrder', { direction });
      if (response.status === 'success') {
        await this.refresh();
        this.noContentFetching = false;
        return;
      }
      this.noContentError = response;
      this.noContentFetching = false;
    },
    async refresh() {
      this.noContentError = null;
      await this.$store.dispatch('fields/refresh');
      await this.loadNdviData();
      await this.loadMbiData();
    },
    async loadNdviData() {
      if (!this.$refs.table) {
        return;
      }

      // wait until the table is fully rendered to access the right visibility states of the columns
      await this.$refs.table.$nextTick();
      await this.$refs.table.$nextTick(); // we need to wait two ticks on page refresh

      const ndviColumn = this.$refs.table.getColumnsForPageSettings().find((column) => column.key === 'ndvi');

      if (ndviColumn && ndviColumn.visible && !this.loadingNdvi) {
        await this.$store.dispatch('fields/loadNdvi', this.pendingNdvi);
      }
    },
    async loadMbiData() {
      if (!this.$refs.table) {
        return;
      }

      // wait until the table is fully rendered to access the right visibility states of the columns
      await this.$refs.table.$nextTick();
      await this.$refs.table.$nextTick(); // we need to wait two ticks on page refresh

      const mbiColumn = this.$refs.table.getColumnsForPageSettings().find((column) => column.key === 'mbi');

      if (mbiColumn && mbiColumn.visible && !this.loadingMbi) {
        await this.$store.dispatch('fields/loadMbi', this.pendingMbi);
      }
    },
    async onTableSettingsChanged() {
      this.loadNdviData();
      this.loadMbiData();
    },
  },
};
</script>

<style scoped>
.table-fields-container--is-dragover::after,
.table-fields-container--is-uploading::after {
  --from-top: 46px; /* should match the padding-top from NoContent.vue */
  content: attr(data-dragover-label);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  padding-top: calc(var(--from-top) + 200px);
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
  color: var(--black);
  /* eslint-disable-nextline max-len */
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 295 295" xmlns="http://www.w3.org/2000/svg"><mask id="a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="295" height="295"><circle cx="147.5" cy="147.5" r="147.5" fill="%23C4C4C4"/></mask><g mask="url(%23a)"><path fill="%23F7F7F7" d="M-9-8h312v312H-9z"/></g><path d="M199.605 91.91c-.926-13.422-12.034-24.298-25.687-24.298-1.85 0-3.703.23-5.322.694-7.406-9.256-18.745-15.504-31.704-15.504-19.67 0-36.1 13.884-40.034 32.397-13.885 5.785-22.91 19.207-22.91 34.248 0 20.596 16.431 37.026 37.026 37.026h77.754c18.282 0 33.323-14.81 33.323-33.323 0-14.116-9.256-26.612-22.446-31.24zm-10.877 53.455h-77.754c-14.347 0-25.918-11.57-25.918-25.918 0-12.96 9.488-23.834 22.216-25.454v-.463c0-16.2 13.19-29.621 29.62-29.621 12.265 0 22.91 7.637 27.307 18.513 2.545-2.314 6.016-3.702 9.72-3.702 8.098 0 14.81 6.71 14.81 14.81 0 2.777-.926 5.322-2.083 7.636.694 0 1.388-.231 2.082-.231 12.265 0 22.216 9.95 22.216 22.215s-9.95 22.215-22.216 22.215z" fill="%233CC960"/><path d="M155.76 116.83h-29.736l-4.188 68.476 33.924 1.256 18.01-1.256v-42.3l-4.818-16.334-13.192-9.842z" fill="%23C7EDD1" stroke="%233CC960"/><path d="M174.624 127.227l-12.635-12.484c-1.354-1.354-3.159-2.256-4.964-2.256H126.19a7.324 7.324 0 00-7.069 7.37v62.573a7.062 7.062 0 007.07 7.07h43.32c4.061 0 7.37-3.16 7.37-7.07v-50.088c0-1.805-.903-3.761-2.256-5.115zm-17.148-6.92l11.582 11.583h-11.582v-11.582zm12.184 62.123h-43.47v-62.573h24.066V135.5c0 1.956 1.655 3.61 3.61 3.61h15.794v43.32zm-23.164-35.648l-10.83 10.83c-1.053 1.203-.3 3.158 1.353 3.158h7.371v12.635c0 .903.752 1.805 1.805 1.805h3.61c.902 0 1.805-.902 1.805-1.805V160.77h7.22c1.654 0 2.407-1.955 1.354-3.158l-10.83-10.83c-.752-.752-2.106-.752-2.858 0z" fill="%233CC960"/></svg>');
  background-size: 295px 295px;
  background-repeat: no-repeat;
  background-position: center var(--from-top);
  background-color: var(--primary_opacity_25);
  pointer-events: none;
}

.table-fields-container--is-uploading::after {
  font-size: 1rem;
}
</style>
