<template>
  <div class="pagination">
    <span
      v-for="index in indices"
      :key="index"
      :class="{ 'bg-primary border-primary': index === current, 'border-medium': index !== current }"
      class="pagination__dot"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pages: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
  computed: {
    indices() {
      return [...new Array(this.pages).keys()];
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacer_3);
}

.pagination__dot {
  display: block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  padding: 0;
  border: 1px solid;
}
</style>
