import { mapGetters } from 'vuex';

import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

export const STORE_KEY_KIND_OF_USE_TYPES = 'kindOfUseTypes';
export const STORE_KEY_CATCH_CROP_VARIANTS = 'catchCropVariants';
export const STORE_KEY_AMA_CODES = 'amaCodes';
export const STORE_KEY_GW_AREAS = 'gwAreas';

function getGwAreaDropdownItems(sgdVersion, company) {
  const items = [{ name: 'keines', id: 'gwNone' }];

  if (company.oepulGw) {
    items.push({ name: 'ÖPUL GWA', id: 'gwOepul' });
  }

  if (company.gradGw) {
    items.push({ name: 'WRRL GW GRAD', id: 'gwGrad' });
  }

  if (sgdVersion === 'sgd8' && company.nRisiko) {
    items.push({ name: 'N-RISIKO', id: 'nRisiko' });

    if (company.oepulGw) {
      items.push({ name: 'N-RISIKO + ÖPUL GWA', id: 'nRisikoGw' });
    }
  }

  return items;
}

export default {
  computed: {
    ...mapGetters({
      kindOfUseTypes: 'fields/kindOfUseTypes',
      kindOfUseTypesById: 'fields/kindOfUseTypesById',
      catchCropVariants: 'fields/catchCropVariants',
      catchCropVariantsById: 'fields/catchCropVariantsById',
      amaCodes: 'fields/amaCodes',
      amaCodesById: 'fields/amaCodesById',
      gwAreas: 'fields/gwAreas',
      gwAreasById: 'fields/gwAreasById',
    }),
  },
  methods: {
    dropdownItemsForFieldsGetRendererValue(storeKey, { value }) {
      const byId = `${storeKey}ById`;
      if (this[byId] == null || this[byId][value] == null) {
        return '';
      }
      return this[byId][value].name;
    },
    dropdownItemsForFieldsGetItems(storeKey) {
      let name;
      if (storeKey === STORE_KEY_KIND_OF_USE_TYPES) {
        name = this.$t('Nutzungsarten');
      } else if (storeKey === STORE_KEY_CATCH_CROP_VARIANTS) {
        name = this.$t('Begrünung');
        return [
          {
            name,
            id: storeKey,
            items: this.sgdVersion === 'sgd8' ? this[storeKey].slice(22) : this[storeKey].slice(0, 22),
          },
        ];
      } else if (storeKey === STORE_KEY_AMA_CODES) {
        name = this.$t('AMA Codes');
      } else if (storeKey === STORE_KEY_GW_AREAS) {
        name = this.$t('Aktionsgebiet');
        return [
          {
            name,
            id: storeKey,
            items: getGwAreaDropdownItems(this.sgdVersion, this.currentCompanies.find(notNullOrUndefined)),
          },
        ];
      }
      return [
        {
          name,
          id: storeKey,
          items: this[storeKey],
        },
      ];
    },
  },
};
