import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { BASE_PATH } from '../common';
import {
  ApiRequestOptions,
  ApiResponse,
  ApiResponseExport,
  Field,
  FieldExportType,
  Filter,
  PermissionMode,
} from '../models';
import { apiOptionsToSearchParams, dataToBase64, urlSearchParamsToUnencodedString } from '../requestUtils';

export async function fetchFieldsByProcessOrderIds<Resolve = undefined, ResolveInSeparateList = undefined>(
  processOrderIds: string[],
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
): Promise<AxiosResponse<ApiResponse<Field[], Resolve, ResolveInSeparateList>>> {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  const filter: Filter = ['processOrderId', 'IN', processOrderIds];
  searchParams.append('filter', dataToBase64(filter));

  const url = `${BASE_PATH}/field?${urlSearchParamsToUnencodedString(searchParams)}`;

  return axios.get<ApiResponse<Field[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}

export async function fetchFieldsByIds<Resolve = undefined, ResolveInSeparateList = undefined>(
  ids: string[],
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
): Promise<AxiosResponse<ApiResponse<Field[], Resolve, ResolveInSeparateList>>> {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  const filter: Filter = ['id', 'IN', ids];
  searchParams.append('filter', dataToBase64(filter));

  const url = `${BASE_PATH}/field?${urlSearchParamsToUnencodedString(searchParams)}`;

  return axios.get<ApiResponse<Field[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}

export async function exportFields(
  type: FieldExportType,
  fieldIds: string[],
): Promise<AxiosResponse<ApiResponseExport>>;
export async function exportFields(
  type: FieldExportType,
  fieldIds: string[],
  permissionMode: PermissionMode,
  fieldSharingCompanyId: string,
): Promise<AxiosResponse<ApiResponseExport>>;
export async function exportFields(
  type: FieldExportType,
  fieldIds: string[],
  permissionMode?: PermissionMode,
  fieldSharingCompanyId?: string,
): Promise<AxiosResponse<ApiResponseExport>> {
  const searchParams = new URLSearchParams();
  if (permissionMode) {
    searchParams.append('permissionMode', permissionMode);
  }
  if (fieldSharingCompanyId) {
    searchParams.append('fieldSharingCompanyId', fieldSharingCompanyId);
  }

  const url = `${BASE_PATH}/field/export?${searchParams.toString()}`;

  return axios.post<ApiResponseExport>(url, {
    version: '2.0',
    data: {
      type,
      fieldIds,
    },
  });
}
