<template>
  <!-- eslint-disable vue/no-unused-vars -->
  <ThePageSkeleton class="page-login" scroll-y>
    <WelcomeContentContainer>
      <TopContentContainer class="page-login__top-content-container">
        <WelcomeTitle class="m-0" white centered>
          <span class="position-relative">
            {{ $t('Login') }}
            <DebugInstanceBadge />
          </span>
        </WelcomeTitle>
      </TopContentContainer>
      <MaxWidthContainer class="mt-auto">
        <LogoAuthPages />
      </MaxWidthContainer>
      <MaxWidthContainer class="mb-auto">
        <Button v-if="false" class="page-login__button-google my-0" size="lg" block>
          {{ $t('Mit Google anmelden') }}
        </Button>
        <div v-if="false" class="page-login__divider w-100">
          <div class="flex-grow-1">
            <hr />
          </div>
          <span class="page-login__divider-text">ODER</span>
          <div class="flex-grow-1">
            <hr />
          </div>
        </div>
        <ValidationObserver v-slot="{ errors }" ref="validationObserver" slim>
          <BForm class="w-100" novalidate @submit.prevent="submit">
            <ValidationProvider
              v-slot="{ errors, dirty, failed }"
              rules="required|email"
              slim
              :name="$t('E-Mail-Adresse')"
            >
              <FormFieldInput
                v-model="email"
                ref="email"
                class="w-100 mt-0"
                name="email"
                type="email"
                autocomplete="email"
                variant="vertical-lg"
                :label="$t('E-Mail-Adresse')"
                :placeholder="$t('E-Mail-Adresse')"
                :disabled="fetching"
                :state="errors.length > 0 && !dirty ? false : null"
                @input="userErrorMessage = null"
              />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors, dirty }" rules="required" slim :name="$t('Passwort')">
              <FormFieldInput
                v-model="password"
                class="w-100"
                name="password"
                type="password"
                autocomplete="current-password"
                variant="vertical-lg"
                :label="$t('Passwort')"
                :placeholder="$t('Passwort')"
                :disabled="fetching"
                :state="errors.length > 0 && !dirty ? false : null"
                @input="userErrorMessage = null"
              />
            </ValidationProvider>
            <p v-if="firstError(errors) != null" class="text-danger">
              {{ firstError(errors) || userErrorMessage }}&nbsp;
            </p>
            <ErrorUserMessage v-else :error-user-message="userErrorMessage" />
            <Button type="submit" variant="primary" size="lg" block class="my-0" :disabled="fetching">
              <FontAwesomeIcon v-if="fetching" icon="circle-notch" class="text-white" spin />
              <span v-else>{{ $t('Einloggen') }}</span>
            </Button>
          </BForm>
        </ValidationObserver>
        <p class="mt-4 mb-0">
          {{ $t('Du hast noch kein Konto?') }}
          <ButtonLink :to="{ name: 'welcome' }">{{ $t('Hier gehts zur Registrierung.') }}</ButtonLink>
        </p>
        <ButtonLink class="mt-1 py-3" :to="{ name: 'password-lost', params: { email } }">
          {{ $t('Passwort vergessen?') }}
        </ButtonLink>
      </MaxWidthContainer>
    </WelcomeContentContainer>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import DebugInstanceBadge from '@/shared/components/DebugInstanceBadge.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import veeValidateHelpers from '@/shared/mixins/form/veeValidateHelpers';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';

import LogoAuthPages from './components/LogoAuthPages.vue';
import TopContentContainer from './components/TopContentContainer.vue';
import WelcomeContentContainer from './components/WelcomeContentContainer.vue';
import WelcomeTitle from './components/WelcomeTitle.vue';

library.add(faCircleNotch);

export default {
  name: 'PageLogin',
  components: {
    ErrorUserMessage,
    DebugInstanceBadge,
    MaxWidthContainer,
    TopContentContainer,
    WelcomeContentContainer,
    WelcomeTitle,
    ThePageSkeleton,
    FormFieldInput,
    LogoAuthPages,
    Button,
    ButtonLink,
  },
  mixins: [veeValidateHelpers, backendResponseErrorHelpers],
  data() {
    return {
      fetching: false,
      email: null,
      password: null,
      defaultUserErrorMessage: this.$t('Unbekannter Fehler. Bitte überprüfe deine Internetverbindung.'),
    };
  },
  mounted() {
    if (this.$refs.email == null) {
      return;
    }
    this.$refs.email.$el.querySelector('input').focus();
  },
  async created() {
    const { oneTimeToken } = this.$route.query;
    if (oneTimeToken) {
      await this.$store.dispatch('auth/loginWithOneTimeToken', { oneTimeToken });
      await this.$router.replace({ oneTimeToken: null });
    }
  },
  methods: {
    submit() {
      this.userErrorMessage = null;
      this.$refs.validationObserver.reset();
      this.$refs.validationObserver.handleSubmit(this.login);
    },
    async login() {
      if (this.fetching) {
        return;
      }
      this.fetching = true;

      // wait at least 200ms that the spinner looks better
      let response;
      await Promise.all([
        // eslint-disable-next-line no-promise-executor-return
        new Promise((resolve) => setTimeout(resolve, 200)),
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          response = await this.$store.dispatch('auth/login', {
            email: this.email,
            password: this.password,
          });
          resolve();
        }),
      ]);

      if (response.status !== 'success') {
        this.setUserErrorMessageFromResponse(response);
      }
      this.fetching = false;
    },
  },
};
</script>

<style scoped>
.page-login__top-content-container {
  display: none;
}

@media (min-height: 750px) {
  .page-login__top-content-container {
    display: flex;
  }
}

.page-login__button-google {
  margin-bottom: var(--spacer_4);
}

.page-login__divider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacer_4);
}

.page-login__divider-text {
  padding: 0 1.5rem;
}

hr {
  border-color: var(--gray_600);
}

.page-login__bottom-link {
  text-align: center;
}

.page-login__error-line {
  display: inline-block;
  margin-bottom: var(--spacer_2);
}
</style>
