<template>
  <BLink
    class="button-link"
    :class="[`button-link--${color}`, { 'button-link--disabled': disabled }]"
    :href="href"
    :target="target"
    :to="to"
    :disabled="disabled"
    @click="(event) => onClick(event)"
  >
    <FontAwesomeIcon v-if="icon" class="button-link__icon" :icon="icon" />
    <slot name="default" />
  </BLink>
</template>

<script>
import componentNamespace from '../../mixins/componentNamespace';

/**
 * FARMDOK Link Button<br>
 * For examples take a look at [Figma]{@link https://www.figma.com/file/p4TEqNSpxiFc9gquNdOsCp/2.-Components---FDK}.<br>
 *
 * @category Shared
 * @subcategory Atoms
 * @component
 */
export default {
  name: 'ButtonLink',
  mixins: [componentNamespace],
  props: {
    /**
     * Denotes the target URL of the link for standard a links.
     */
    href: {
      type: String,
      default: null,
    },
    /**
     * Sets the 'target' attribute on the rendered link.
     */
    target: {
      type: String,
      default: '_self',
    },
    /**
     * Denotes the target route of the link. When clicked, the value of the to prop will be passed to `router.push()` internally,
     * so the value can be either a string or a Location descriptor object.
     */
    to: {
      type: [Object, String],
      default: null,
    },
    /**
     * Set the color of the text and icon.
     */
    color: {
      type: String,
      default: 'primary',
      validator: (color) => ['primary', 'black', 'red'].includes(color),
    },
    /**
     * Fontawesome icon
     */
    icon: {
      type: [String, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(event) {
      /**
       *
       * @event root#button--click
       * @type {String}
       */
      this.$root.$emit('button--click', this.componentNamespace);
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped>
.button-link__icon {
  margin-right: 0.4em;
}

.button-link--black {
  color: black;
}
.button-link--red {
  color: red;
}

.button-link--disabled {
  font-style: italic;
}

.button-link--disabled:hover {
  text-decoration: none;
  cursor: default;
}

.button-link--primary.button-link--disabled {
  color: var(--primary_medium);
}

.button-link--black.button-link--disabled {
  color: var(--gray);
}
</style>
