<template>
  <div class="step-update-address w-100">
    <h1 class="step-update-address__headline">
      {{ $t('Adresse') }}
    </h1>
    <BForm class="w-100 mb-4" novalidate @submit.prevent="submit">
      <FormFieldInput
        v-model="addressInternal"
        class="w-100"
        name="addressInternal"
        type="text"
        autocomplete="address-line1"
        variant="vertical-lg"
        :label="$t('Straße, Hausnummer, Tür')"
        :placeholder="$t('Straße, Hausnummer, Tür')"
        :disabled="fetching"
        @input="$emit('input')"
      />
      <FormFieldInput
        v-model="postcodeInternal"
        class="w-100"
        name="postcodeInternal"
        type="text"
        autocomplete="postal-code"
        variant="vertical-lg"
        :label="$t('Postleitzahl')"
        :placeholder="$t('Postleitzahl')"
        :disabled="fetching"
        @input="$emit('input')"
      />
      <FormFieldInput
        v-model="cityInternal"
        class="w-100"
        name="city"
        type="text"
        autocomplete="address-level2"
        variant="vertical-lg"
        :label="$t('Ort')"
        :placeholder="$t('Ort')"
        :disabled="fetching"
        @input="$emit('input')"
      />
      <ErrorUserMessage :error-user-message="userErrorMessage" />
      <Button type="submit" variant="primary" size="lg" block class="my-0" :disabled="fetching">
        <FontAwesomeIcon v-if="fetching" icon="circle-notch" class="text-white" spin />
        <span v-else>{{ $t('Speichern') }}</span>
      </Button>
    </BForm>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';

import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import Button from '@/shared/components/buttons/Button.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import veeValidateHelpers from '@/shared/mixins/form/veeValidateHelpers';

library.add(faCircleNotch);

export default {
  name: 'StepUpdateAddress',
  components: { ErrorUserMessage, FormFieldInput, Button },
  mixins: [veeValidateHelpers],
  props: {
    fetching: {
      type: Boolean,
      default: false,
    },
    userErrorMessage: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    postcode: {
      type: String,
      default: null,
    },
    city: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      addressInternal: this.address,
      postcodeInternal: this.postcode,
      cityInternal: this.city,
    };
  },
  methods: {
    submit() {
      this.$emit('submit');
      this.$emit('save', {
        address: this.addressInternal,
        postcode: this.postcodeInternal,
        city: this.cityInternal,
      });
    },
  },
};
</script>

<style scoped>
.step-update-address__headline {
  margin-bottom: var(--spacer_3);
  text-align: center;
  font-size: 28px;
  line-height: 38px;
}
</style>
