import { MutationTree } from 'vuex';

import usedItems from '@/shared/mixins/store/usedItems';

import { subscribableStore } from './common';
import { PlantVarietyState } from './types';

const mutations: MutationTree<PlantVarietyState> = {
  ...subscribableStore.mutations,
  ...usedItems.mutations,
};

export default mutations;
