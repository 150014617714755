<template>
  <div class="multi-root-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MultiRootWrapper',
};
</script>

<style scoped>
.multi-root-wrapper {
  display: contents;
}
</style>
