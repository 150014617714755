<template>
  <div>
    <Widget
      v-if="tableItemsFarmdok.length > 0"
      :title="$t('Bodenproben')"
      :subtitle="tableItemsAgrana.length ? 'FARMDOK' : null"
    >
      <Table fixed :fields="tableFieldsFarmdok" :items="tableItemsFarmdok">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #head(samples)="data">
          <div style="display: flex; flex-direction: row">
            <div
              v-for="field in tableFieldsSamplesFarmdok"
              :key="field.key"
              :style="{
                'flex-grow': field.width == null ? '1' : '0',
                width: field.width,
                padding: '0.75rem',
              }"
            >
              {{ field.label }}
            </div>
          </div>
        </template>
        <template #cell(samples)="data">
          <Table
            fixed
            borderless
            headerless
            class="m-0"
            :fields="tableFieldsSamplesFarmdok"
            :items="data.item.samples"
          />
        </template>
        <template #cell(userComment)="data">
          <ButtonLink
            v-if="typeof data.item.userComment === 'string' && data.item.userComment.length > 0"
            v-b-tooltip.hover.left="{ title: data.item.userComment }"
            class="widget-measures__user-comment"
            color="black"
          >
            {{ data.item.userComment }}
          </ButtonLink>
        </template>
      </Table>
    </Widget>
    <Widget
      v-if="tableItemsAgrana.length > 0"
      :title="$t('Bodenproben')"
      :subtitle="tableItemsFarmdok.length ? 'EUF' : null"
    >
      <Table fixed :fields="tableFieldsAgrana" :items="tableItemsAgrana">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template #head(samples)="data">
          <div style="display: flex; flex-direction: row">
            <div
              v-for="field in tableFieldsSamplesAgrana"
              :key="field.key"
              :style="{
                'flex-grow': field.width == null ? '1' : '0',
                width: field.width,
                padding: '0.75rem',
              }"
            >
              {{ field.label }}
            </div>
          </div>
        </template>
        <template #cell(samples)="data">
          <Table
            fixed
            borderless
            headerless
            class="m-0"
            :fields="tableFieldsSamplesAgrana"
            :items="data.item.samples"
          />
        </template>
        <template #cell(pdfLink)="data">
          <ButtonLink v-if="data.item.pdfLink != null" target="_blank" :href="data.item.pdfLink">
            {{ $t('Herunterladen') }}
          </ButtonLink>
        </template>
        <template #cell(userComment)="data">
          <ButtonLink
            v-if="typeof data.item.userComment === 'string' && data.item.userComment.length > 0"
            v-b-tooltip.hover.left="{ title: data.item.userComment }"
            class="widget-measures__user-comment"
            color="black"
          >
            {{ data.item.userComment }}
          </ButtonLink>
        </template>
      </Table>
    </Widget>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import numbro from 'numbro';

import Table from '@/shared/components/Table.vue';
import Widget from '@/shared/components/Widget.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import { origin } from '@/shared/constants';

export default {
  name: 'WidgetSoilSamples',
  components: { ButtonLink, Table, Widget },
  props: {
    selectedField: {
      type: Object,
      required: true,
    },
    isSharedField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      soilSampleData: {},
      fetching: false,
      source: axios.CancelToken.source(),
    };
  },
  computed: {
    tableFieldsFarmdok() {
      return [
        {
          key: 'date',
          label: this.$t('Datum'),
          width: '100px',
        },
        {
          key: 'samples',
          thClass: 'p-0',
          tdClass: 'p-0',
          width: '650px',
        },
        {
          key: 'userComment',
          label: this.$t('Bemerkung'),
          tdClass: 'text-truncate',
        },
      ];
    },
    tableFieldsSamplesFarmdok() {
      return [
        {
          key: 'number',
          label: this.$t('Nummer'),
        },
        {
          key: 'phLevel',
          label: this.$t('pH'),
          width: '70px',
        },
        {
          key: 'humusContent',
          label: this.$t('Humus'),
          width: '80px',
          tdClass: 'text-right',
        },
        {
          key: 'p2o5Class',
          label: this.$t('P₂O₅-Klasse'),
          width: '100px',
        },
        {
          key: 'k2oClass',
          label: this.$t('K₂O-Klasse'),
          width: '100px',
        },
        {
          key: 'mgClass',
          label: this.$t('Mg-Klasse'),
          width: '100px',
        },
        {
          key: 'caClass',
          label: this.$t('Ca-Klasse'),
          width: '100px',
        },
      ];
    },
    tableItemsFarmdok() {
      if (
        this.soilSampleData == null ||
        !Array.isArray(this.soilSampleData.farmdok) ||
        this.soilSampleData.farmdok.length === 0
      ) {
        return [];
      }
      return [...this.soilSampleData.farmdok]
        .sort((a, b) => b.creationDate - a.creationDate)
        .map((measure) => ({
          date: moment(measure.creationDate * 1000).format('L'),
          samples: measure.samples.map((sample) => ({
            ...sample.additionalData,
            humusContent:
              typeof sample.additionalData.humusContent === 'number'
                ? numbro(sample.additionalData.humusContent).format()
                : sample.additionalData.humusContent,
          })),
          userComment: measure.userComment,
        }));
    },
    tableFieldsAgrana() {
      return [
        {
          key: 'date',
          label: this.$t('Datum'),
          width: '100px',
        },
        {
          key: 'samples',
          thClass: 'p-0',
          tdClass: 'p-0',
          width: '670px',
        },
        {
          key: 'pdfLink',
          label: this.$t('PDF Herunterladen'),
          width: '140px',
        },
        {
          key: 'userComment',
          label: this.$t('Bemerkung'),
          tdClass: 'text-truncate',
        },
      ];
    },
    tableFieldsSamplesAgrana() {
      return [
        {
          key: 'number',
          label: this.$t('Nummer'),
        },
        {
          key: 'ph',
          label: this.$t('pH'),
          width: '70px',
          tdClass: 'text-right',
        },
        {
          key: 'humus',
          label: this.$t('Humus'),
          width: '80px',
          tdClass: 'text-right',
        },
        {
          key: 'sustenanceN',
          label: this.$t('N'),
          width: '70px',
        },
        {
          key: 'sustenanceP',
          label: this.$t('P'),
          width: '70px',
        },
        {
          key: 'sustenanceK',
          label: this.$t('K'),
          width: '70px',
        },
        {
          key: 'sustenanceCa',
          label: this.$t('Ca'),
          width: '70px',
        },
        {
          key: 'sustenanceMg',
          label: this.$t('Mg'),
          width: '70px',
        },
        {
          key: 'sustenanceB',
          label: this.$t('B'),
          width: '70px',
        },
      ];
    },
    tableItemsAgrana() {
      if (
        this.soilSampleData == null ||
        !Array.isArray(this.soilSampleData.agrana) ||
        this.soilSampleData.agrana.length === 0
      ) {
        return [];
      }
      return [...this.soilSampleData.agrana]
        .sort((a, b) => b.creationDate - a.creationDate)
        .map((measure) => {
          let pdfLink = null;
          if (typeof measure.pdfLink === 'string' && measure.pdfLink.length > 0) {
            pdfLink = `${origin}${measure.pdfLink}`;
          }
          return {
            date: moment(measure.creationDate * 1000).format('L'),
            samples: measure.samples.map((sample) => ({
              ...sample.additionalData,
              humus:
                typeof sample.additionalData.humus === 'number'
                  ? numbro(sample.additionalData.humus).format()
                  : sample.additionalData.humus,
            })),
            pdfLink,
            userComment: measure.userComment,
          };
        });
    },
    fieldId() {
      if (this.selectedField == null) {
        return null;
      }
      return this.selectedField.id;
    },
  },
  watch: {
    fieldId() {
      this.loadSoilSamples();
    },
  },
  created() {
    this.loadSoilSamples();
  },
  methods: {
    async loadSoilSamples() {
      if (this.fieldId == null) {
        return;
      }
      this.soilSampleData = {};
      this.fetching = true;
      const sharedFieldQueryParams = `?permissionMode=fieldSharing&fieldSharingCompanyId=${this.$store.state.auth.currentCompanies[0].id}`;
      try {
        const { data } = await axios.get(
          `/admin/rest/field/soil_samples_for_field/${this.fieldId}${this.isSharedField ? sharedFieldQueryParams : ''}`,
          { cancelToken: this.source.token },
        );
        this.fetching = false;
        this.reloading = false;

        if (data.status !== 'success' || data.data.length < 1) {
          return;
        }

        this.soilSampleData = data.data;
      } catch (error) {
        this.fetching = false;
        this.reloading = false;
      }
    },
  },
};
</script>

<style scoped></style>
