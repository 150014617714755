import Vue from 'vue';
import { MutationTree } from 'vuex';

import { BaseWorkflowStoreModuleState } from '@/precision-farming/application-maps/store/baseWorkflowStore/types';
import { Feature } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import initialState from './initialState';
import { ApplicationMapsSeedingState, Calculation, WorkflowVariety } from './types';

const mutations: MutationTree<ApplicationMapsSeedingState> = {
  ...baseWorkflowStore.mutations,

  reset(state: ApplicationMapsSeedingState): void {
    const newState: ApplicationMapsSeedingState = initialState();
    Object.entries(newState).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },
  fetchVarieties(state: ApplicationMapsSeedingState): void {
    Vue.set(state.varieties, 'fetching', true);
  },
  setVarieties(state: ApplicationMapsSeedingState, varieties: WorkflowVariety[]): void {
    Vue.set(state.varieties, 'fetching', false);
    Vue.set(state.varieties, 'current', varieties);
  },
  setVarietiesFetching(state: ApplicationMapsSeedingState, fetching: boolean): void {
    Vue.set(state.varieties, 'fetching', fetching);
  },
  setCalculation(state: ApplicationMapsSeedingState, calculation: Calculation): void {
    Vue.set(state, 'calculation', calculation);
  },
  setCalculationField(state: ApplicationMapsSeedingState, data): void {
    Vue.set(state.calculation, data.key, data.value);
  },
  setQuantisationCode(state: ApplicationMapsSeedingState, quantisationCode: string): void {
    Vue.set(state, 'selectedQuantisationCode', quantisationCode);
  },
  updateFeature(state: BaseWorkflowStoreModuleState, feature: Feature): void {
    if (feature.heatmapKey === undefined || feature.featureIndex === undefined) {
      throw new Error(
        'Cannot update features with missing heatmap key or feature index in seeding workflow. Features are subordinates of heatmaps and therefore need to provide a heatmap key and a feature index for ease of reference.',
      );
    }

    Vue.set(
      state.heatmaps.current[feature.heatmapKey].features[feature.featureIndex].vegetation,
      `value_q`,
      feature.vegetation.custom_value,
    );
    Vue.set(
      state.heatmaps.current[feature.heatmapKey].features[feature.featureIndex].properties,
      `fill`,
      feature.properties.previewFill,
    );
    Vue.set(
      state.heatmaps.current[feature.heatmapKey].features[feature.featureIndex].properties,
      `stroke`,
      feature.properties.previewFill,
    );
  },
};

export default mutations;
