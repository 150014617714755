import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';

import { company, contactEmail, farmNumber, fileLink, fileName, partnerNumber, uploadDate } from './columns';

export default [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...partnerNumber,
    lockedVisibility: true,
    lockedPosition: true,
    sortOrder: 'asc',
  },
  farmNumber,
  company,
  fileName,
  fileLink,
  contactEmail,
  uploadDate,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];
