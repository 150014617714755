import axios from 'axios';
import Vue from 'vue';

import { GUID_KEY } from '@/shared/constants';
import subscribableDataStore from '@/shared/mixins/store/subscribableData';
import { requestDataCreateFromEntryAndModel } from '@/shared/modules/restApiHelpers';

/* eslint-disable camelcase */
export const F_Subject = 'subject';
export const F_MessageText = 'messageText';
export const F_Priority = 'priority';
export const F_Read = 'read';
/* eslint-enable camelcase */

export const model = {
  [F_Subject]: null,
  [F_MessageText]: null,
  [F_Priority]: null,
  [F_Read]: null,
};

export const dataUrl = '/admin/rest/message';
export const filter = ({ rootState }) => {
  if (rootState.auth.user == null || rootState.auth.user.id == null) {
    return false;
  }
  return ['AND', ['receiverId', '=', rootState.auth.user.id], ['type', '=', 'system']];
};

const store = subscribableDataStore(dataUrl, { filter, resolve: ['template'] });

export function initialState() {
  return {
    ...store.state,
    visible: false,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    ...store.mutations,
    toggleVisibility(state) {
      Vue.set(state, 'visible', !state.visible);
    },
    hide(state) {
      Vue.set(state, 'visible', false);
    },
    markRead(state, notification) {
      const guid = notification[GUID_KEY];
      Vue.set(state, 'data', {
        ...state.data,
        [guid]: {
          ...state.data[guid],
          read: true,
        },
      });
    },
  },
  actions: {
    ...store.actions,
    markRead({ state, commit }, notification) {
      commit('markRead', notification);
      const guid = notification[GUID_KEY];
      axios.put(`${dataUrl}/${guid}`, {
        version: '2.0',
        data: requestDataCreateFromEntryAndModel(state.data[guid], model),
      });
    },
  },
  getters: {
    ...store.getters,
  },
};
