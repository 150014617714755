/**
 * This feature gives the possibility to add a 'expandColumn' to a handsontable.
 * Therefore you have to use a table mixin and this, e.g. `mixins: [tableBase, featureExpandableRows]`.
 * Then you have to add the expandColumn as usual: `tableSettings: { columns: [expandColumn, ...], ... }`
 */
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

library.add(faChevronUp, faChevronDown);

export const expandColumn = {
  // Handsontable settings
  type: 'expand',
  className: 'expand border-right-0',
  width: 45,
  // TableBase settings
  key: '__expand',
  data({ id }, value) {
    if (typeof value === 'undefined') {
      return this.expandedRowsComputed.includes(id);
    }
    return this.updateExpansion(id, value);
  },
  expandableForRenderer(visualRow) {
    return this.isRowExpandable(this.visualRowToGuid(visualRow));
  },
  toggleExpand(visualRow) {
    this.toggleExpand(visualRow);
  },
  hiddenInPageSettings: true,
  lockedVisibility: true,
  lockedPosition: true,
  noHeaderContextMenu: true,
};

export default {
  props: {
    /**
     * If you do not set this prop the expanded rows are stored (and read) internally.
     * When using this prop also listen to 'update:expandedRows' emits (either by using sync option for binds or manually).
     */
    expandedRows: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      expandedRowsInternal: [],
    };
  },
  computed: {
    /**
     * If expanded rows are set via prop those are used. Otherwise return the internal array.
     * Updates always update both.
     *
     * @return {Array}
     */
    expandedRowsComputed: {
      get() {
        if (Array.isArray(this.expandedRows)) {
          return this.expandedRows;
        }
        return this.expandedRowsInternal;
      },
      set(expandedRows) {
        this.expandedRowsInternal = expandedRows;
        this.$emit('update:expandedRows', expandedRows);
      },
    },
  },
  watch: {
    expandedRowsComputed() {
      this.hotRender();
    },
  },
  created() {
    this.tableHooks.colHeaders.push(this.expandableRowsColHeaders);
  },
  mounted() {
    this.$el.addEventListener('click', (e) => {
      // noinspection JSUnresolvedVariable
      if (
        e.target != null &&
        e.target.className != null &&
        typeof e.target.className.indexOf === 'function' &&
        e.target.className.indexOf('header-checkbox--expand-rows') > -1
      ) {
        this.toggleAllRowsExpanded();
      }
      return true;
    });
  },
  methods: {
    /**
     * Set a given row to be either expanded or not expanded.
     * This method will use the set() of expandedRowsComputed.
     *
     * @param {string} id
     * @param {boolean} expanded
     */
    updateExpansion(id, expanded) {
      if (!this.isRowExpandable(id)) {
        return;
      }
      const currentlyExpanded = this.expandedRowsComputed.includes(id);
      if (expanded === currentlyExpanded) {
        return;
      }
      if (expanded) {
        this.expandedRowsComputed = [...this.expandedRowsComputed, id];
      } else {
        this.expandedRowsComputed = this.expandedRowsComputed.filter((currentId) => currentId !== id);
      }
    },
    /**
     * If all VISIBLE rows are currently expanded this function will set the expandedRows to an empty array.
     *
     * Otherwise adds all VISIBLE rows to expandedRows.
     */
    toggleAllRowsExpanded() {
      if (this.hot == null) {
        return;
      }

      const expandableRows = this.getExpandableRows();
      if (this.expandedRowsComputed.length > 0) {
        this.expandedRowsComputed = [];
      } else {
        this.expandedRowsComputed = expandableRows;
      }
    },
    /**
     * This will render a checkbox for the expandColumn header.
     * The checkbox will show 'expanded' if all visible/selectable rows are expanded.
     * Clicking the header will trigger toggleAllRowsExpanded().
     *
     * @param {object} column
     * @param {string|null} colHeader
     * @return {string|null}
     */
    expandableRowsColHeaders(column, colHeader) {
      if (this.hot == null || column.key !== expandColumn.key) {
        return colHeader;
      }
      const expandableRows = this.getExpandableRows();
      if (expandableRows.length === 0) {
        return null;
      }
      const wrapper = document.createElement('div');
      let [expandIcon] = icon({ prefix: 'fas', iconName: 'chevron-down' }).node;
      if (this.expandedRowsComputed.length > 0) {
        [expandIcon] = icon({ prefix: 'fas', iconName: 'chevron-up' }).node;
      }
      wrapper.classList.add('text-black');
      wrapper.classList.add('header-checkbox--expand-rows');
      expandIcon.style.pointerEvents = 'none';
      wrapper.appendChild(expandIcon);
      return wrapper.outerHTML;
    },
    isRowExpandable(id) {
      if (!Array.isArray(this.tableSettingsComputed.columns)) {
        return false;
      }
      return this.tableSettingsComputed.columns.some((column) => {
        if (column.expandable === true) {
          return true;
        }
        if (typeof column.expandable === 'function') {
          return column.expandable(id);
        }
        return false;
      });
    },
    getExpandableRows() {
      if (!Array.isArray(this.tableSettingsComputed.columns)) {
        return [];
      }
      const expandableColumns = this.tableSettingsComputed.columns.filter((column) => column.expandable != null);
      return [...new Array(this.hot.countRows()).keys()]
        .map((visualRow) => this.visualRowToGuid(visualRow))
        .filter((id) =>
          expandableColumns.some((column) => {
            if (column.expandable === true) {
              return true;
            }
            if (typeof column.expandable === 'function') {
              return column.expandable(id);
            }
            return false;
          }),
        );
    },

    toggleExpand(visualRow) {
      const guid = this.visualRowToGuid(visualRow);
      if (!guid) {
        return;
      }
      const currentlyExpanded = this.expandedRowsComputed.includes(guid);
      this.updateExpansion(guid, !currentlyExpanded);
    },
  },
};
