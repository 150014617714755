import { Activity, ActivityEquipment } from 'farmdok-rest-api';

import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';

export type MergedActivityEquipment = Omit<ActivityEquipment, 'id'> & { ids: string[]; activityIds: string[] };

export default class MergedActivityEquipmentService {
  private readonly activityEquipmentService: ActivityEquipmentService;

  constructor(activityEquipmentService: ActivityEquipmentService) {
    this.activityEquipmentService = activityEquipmentService;
  }

  public mergedActivityEquipment(activities: Activity[]): MergedActivityEquipment[] {
    const activityEquipments: MergedActivityEquipment[] = [];

    activities.forEach((activity) => {
      activity.equipment.forEach((equipment) => {
        const siblingIndex = activityEquipments.findIndex((activityEquipment) =>
          this.isMergeableActivityEquipment(activityEquipment, equipment),
        );
        if (siblingIndex > -1) {
          const equipmentWithActivityId = MergedActivityEquipmentService.toMergedActivityEquipment(
            equipment,
            activity.id,
          );

          activityEquipments[siblingIndex] = MergedActivityEquipmentService.mergeActivityEquipment(
            activityEquipments[siblingIndex],
            equipmentWithActivityId,
          );
        } else {
          activityEquipments.push(MergedActivityEquipmentService.toMergedActivityEquipment(equipment, activity.id));
        }
      });
    });

    return activityEquipments;
  }

  private isMergeableActivityEquipment(
    mergedEquipment: MergedActivityEquipment,
    equipment: ActivityEquipment,
  ): boolean {
    const unmergedEquipment = MergedActivityEquipmentService.toActivityEquipment({
      ...mergedEquipment,
      ids: [mergedEquipment.ids[0]],
    });
    return this.activityEquipmentService.isSibling(unmergedEquipment, equipment);
  }

  private static mergeActivityEquipment(
    equipmentA: MergedActivityEquipment,
    equipmentB: MergedActivityEquipment,
  ): MergedActivityEquipment {
    const mergedEquipment: MergedActivityEquipment = {
      ...equipmentA,
      activityIds: [...equipmentA.activityIds, ...equipmentB.activityIds],
      ids: [...equipmentA.ids, ...equipmentB.ids],
    };

    return mergedEquipment;
  }

  private static toMergedActivityEquipment(equipment: ActivityEquipment, activityId: string): MergedActivityEquipment {
    const { id, ...equipmentWithoutId } = equipment;
    return { ...equipmentWithoutId, activityIds: [activityId], ids: [id] };
  }

  private static toActivityEquipment(mergedEquipment: MergedActivityEquipment): ActivityEquipment {
    const { activityIds, ids, ...equipment } = mergedEquipment;
    if (ids.length !== 1) throw new Error('Cannot convert merged equipment to activity equipment. Multiple ids found.');
    return { ...equipment, id: ids[0] };
  }
}
