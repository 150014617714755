import throttle from 'lodash.throttle';
import Vue from 'vue';

import { GUID_KEY } from '@/shared/constants';
import { filterByAllFields } from '@/shared/modules/dataFilters';

/**
 * Use this mixin together with tableBase mixin. Adds filter possibility by using handsontable plugin 'trimRows'.
 */
export default {
  props: {
    /**
     * If you do not set this prop the filter is stored (and read) internally.
     * When using this prop also listen to 'update:filterString' emits (either by using sync option for binds or manually).
     *
     * The filter function searches every row in every column for the given string. Only rows that include this string in ANY column are shown.
     */
    filterString: {
      type: String,
      default: '',
    },
    visibleRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableSettingsInternal: {
        trimRows: true,
      },
      filterStringInternal: '',
      filtersByColumnKey: {},
    };
  },
  computed: {
    filterStringComputed() {
      if (typeof this.filterString === 'string' && this.filterString.length > 0) {
        return this.filterString;
      }
      return this.filterStringInternal;
    },
    filterActive() {
      return (
        (typeof this.filterStringComputed === 'string' && this.filterStringComputed.length > 0) ||
        Object.keys(this.filtersByColumnKey).length > 0
      );
    },
  },
  watch: {
    filterStringComputed() {
      if (this.hot == null) {
        return;
      }
      /**
       * Filter string changed. (either by user or internally)
       *
       * @event featureFilterTable#update:filterString
       */
      this.$emit('update:filterString', this.filterStringComputed);
      this.filterTable();
    },
    filterActive() {
      this.updateFilterBar();
    },
    tablePhysicalRowByGuid() {
      this.filterTable();
    },
  },
  created() {
    this.tableHooks.setData.push(() => {
      this.$emit('update:visibleRows', [...this.tableGuidByPhysicalRow]);
      this.filtersByColumnKey = {};
      this.$nextTick(this.filterTable);
      this.updateFilterBar();
    });
  },
  mounted() {
    this.$nextTick(this.filterTable);
  },
  methods: {
    updateFilterBar() {
      const wtHolder = this.$el.querySelector('.ht_master .wtHolder');
      const wtHolderCloneLeft = this.$el.querySelector('.ht_clone_left .wtHolder');
      if (wtHolder == null || wtHolderCloneLeft == null) {
        return;
      }
      if (this.filterActive) {
        const div = this.filterTableGetNewBar('999');
        wtHolder.prepend(div);
        this.$el.querySelector('.ht_master .filter-table__filter-bar-link').addEventListener('click', (event) => {
          event.preventDefault();
          this.clearAllFilters();
        });

        const divCloneLeft = this.filterTableGetNewBar('99');
        wtHolderCloneLeft.prepend(divCloneLeft);
      } else {
        this.$el.querySelectorAll('.filter-table__filter-bar').forEach((element) => {
          element.parentNode.removeChild(element);
        });
      }
    },
    clearAllFilters() {
      this.$el.querySelectorAll('.table-header-context-menu__icon--filtered').forEach((element) => {
        element.classList.remove('table-header-context-menu__icon--filtered');
      });
      this.$emit('update:filterString', '');
      this.filtersByColumnKey = {};
      this.filterTable();
    },
    /**
     * Set a filter for a specific column.<br>
     * If the filter is null it will be removed.<br>
     * Only rows that have a match in EVERY column that has a filter are shown.<br>
     * Available filter types: contains, not, greater, less, equals
     * <pre>
     *   { value: 'foo', type: 'contains' }
     * </pre>
     *
     * @param {object|null} filter
     * @param {string} columnKey
     */
    setColumnFilter(filter, columnKey) {
      if (filter == null) {
        Vue.delete(this.filtersByColumnKey, columnKey);
      } else {
        Vue.set(this.filtersByColumnKey, columnKey, filter);
      }
      this.filterTable();
    },
    /**
     * Filters the table by using 'trimRows' plugin.
     *
     * @private
     */
    filterTable: throttle(function filterTable() {
      if (this.hot == null) {
        return;
      }
      const trimRowsPlugin = this.hot.getPlugin('trimRows');
      trimRowsPlugin.untrimAll();
      if (!this.filterActive) {
        /**
         * Table was filtered. The ids of the still visible rows gets emitted.
         *
         * @param Array
         * @event featureFilterTable#update:visibleRows
         */
        this.$emit('update:visibleRows', this.tableGuidByPhysicalRow);
        this.hotRender();
        return;
      }
      const rowsToTrim = filterByAllFields(
        this.filterStringComputed,
        Object.values(this.tableDataComputed),
        this.tableSettingsComputed.columns,
        this.filtersByColumnKey,
      ).map(({ entry }) => this.guidToPhysicalRow(entry[GUID_KEY]));
      trimRowsPlugin.trimRows(rowsToTrim);
      /**
       * Table was filtered. The ids of the still visible rows gets emitted.
       *
       * @param Array
       * @event featureFilterTable#update:visibleRows
       */
      this.$emit(
        'update:visibleRows',
        this.tableGuidByPhysicalRow.filter((guid, physicalRow) => !rowsToTrim.includes(physicalRow)),
      );
      this.hotRender();
    }, 300),
    filterTableGetNewBar(zIndex) {
      let top = 0;
      const thead = this.$el.querySelector('.ht_master .htCore thead');
      if (thead != null) {
        top = thead.getBoundingClientRect().height;
      }
      const div = document.createElement('div');
      div.style.display = 'flex';
      div.style.alignItems = 'center';
      div.style.justifyContent = 'center';
      div.style.position = 'sticky';
      div.style.top = `${top}px`;
      div.style.left = '0px';
      div.style.right = '0px';
      div.style.padding = 'var(--spacer_2)';
      div.style.zIndex = zIndex;
      div.style.fontSize = '12px';
      div.className = 'filter-table__filter-bar bg-secondary text-white';

      const linkParts = this.$t(
        'Filter aktiviert - {linkStart}Filter deaktivieren{linkEnd} um alle Einträge anzuzeigen.',
        {
          linkStart: '#####',
          linkEnd: '#####',
        },
      ).split('#####');
      const span = document.createElement('span');
      span.innerText += linkParts[0];
      const link = document.createElement('a');
      // eslint-disable-next-line prefer-destructuring
      link.innerHTML = linkParts[1];
      link.href = '#';
      link.style.textDecoration = 'underline';
      link.className = 'filter-table__filter-bar-link text-white';
      span.appendChild(link);
      span.innerHTML += linkParts[2];
      span.style.whiteSpace = 'nowrap';
      span.style.overflow = 'hidden';
      div.appendChild(span);

      return div;
    },
  },
};
