<template>
  <!-- have to use id as tooltip.container doesnt work with HTMLElement as of v2.0.4 -->
  <div id="form-select-satellite-index">
    <div id="form-select-satellite-index__indexType">
      <FormFieldDropdown
        ref="indexType"
        :items="indexTypesToDropDownItems"
        :label="$t('Satellitenindex')"
        :value="indexTypeValueForDropdown"
        disableDelete
        slimMargin
        variant="vertical"
        @update="(value) => indexTypeSelect(value)"
      />
      <BTooltip
        v-if="$refs.indexType && !$refs.indexType.$refs.dropdown.isOpen"
        target="form-select-satellite-index__indexType"
      >
        {{ $t('Der Index wird aus Satellitendaten berechnet und bildet die Basis für die Teilflächen (Zonen).') }}
      </BTooltip>
    </div>
    <div v-if="showDatePicker" id="form-select-satellite-index__calender">
      <label class="form-select-satellite-index__label" for="datePickerSelect">{{ $t('Datum') }}</label>
      <div
        v-b-tooltip.hover="{ container: '#form-select-satellite-index', delay: { show: 500 }, placement: 'topleft' }"
        :title="heatmapTimestampTooltip"
        class="form-select-satellite-index__calendar"
        @mouseleave="onTooltipMouseleave"
      >
        <FontAwesomeIcon :icon="['fa', 'calendar-alt']" class="date-picker-calendar-icon" />
        <Datepicker
          id="datepickerSelect"
          :disabled="heatmapTimestampAvailableTimestamps.timeArray.length === 0"
          :disabled-dates="heatmapTimestampDisabledDates"
          :language="$store.getters.datepickerTranslation"
          calendar-button-icon="fa fa-calendar"
          calendar-class="form-select-satellite-index__datepicker-calendar"
          class="form-select-satellite-index__datepicker"
          input-class="form-select-satellite-index__datepicker-input"
          maximum-view="day"
          @selected="heatmapTimestampSetDateFromDatepicker"
        />
        <span class="form-select-satellite-index__timestamp">
          {{ heatmapTimestampSelectedFormatted }}
        </span>
        <div class="form-select-satellite-index__datepicker-button-wrapper">
          <BButton
            :class="datepickerButtonPrevDisabled"
            :disabled="datepickerButtonPrevDisabled"
            class="form-select-satellite-index__datepicker-button text-black"
            variant="link"
            @click="heatmapTimestampMovePickedDateBy(-1)"
          >
            <FontAwesomeIcon icon="chevron-left" />
          </BButton>
          <BButton
            :class="datepickerButtonNextDisabled"
            :disabled="datepickerButtonNextDisabled"
            class="form-select-satellite-index__datepicker-button text-black"
            variant="link"
            @click="heatmapTimestampMovePickedDateBy(1)"
          >
            <FontAwesomeIcon icon="chevron-right" />
          </BButton>
        </div>
      </div>
    </div>
    <div id="form-select-satellite-index__quantisationCode">
      <FormFieldDropdown
        ref="quantisationCode"
        :items="quantisationCodesToDropDownItems"
        :label="$t('Anzahl der Zonen')"
        :value="quantisationCodeValueForDropdown"
        disableDelete
        slimMargin
        variant="vertical"
        @update="(value) => quantisationCodeSelect(value)"
      />
      <BTooltip
        v-if="$refs.quantisationCode && !$refs.quantisationCode.$refs.dropdown.isOpen"
        target="form-select-satellite-index__quantisationCode"
      >
        {{ $t('„hoch“ ergibt mehrere, kleine Teilflächen; „niedrig“ weniger, größere') }}
      </BTooltip>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faCalendarAlt, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { mapState } from 'vuex';

import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';

library.add(faCalendar, faCalendarAlt, faChevronLeft, faChevronRight);

export default {
  name: 'FormSelectSatelliteIndex',
  components: { Datepicker, FormFieldDropdown },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      indexTypes: [
        { id: 'DNN_NDVI', name: this.$t('NDVI - Biomasse') },
        { id: 'REIP', name: this.$t('REIP - Stickstoffgehalt') },
        { id: 'DNN_CIRE', name: this.$t('CIre - Chlorophyll') },
        { id: 'DNN_NDWI', name: this.$t('NDWI - Wassergehalt') },
        { id: 'DNN_LAI', name: this.$t('LAI - Blattflächenindex') },
        { id: 'BMMY', name: this.$t('BMMY - Mehrjährige Biomasse (3 Jahre)') },
      ],
      heatmapTimestampTooltip: this.$t(
        'An diesem Datum wurde das Satellitenbild aufgenommen. Alle 5 Tage passiert der Satellit die gleiche Stelle. Bedingt durch Wolken und Wetter ist nicht jedes Bild verwendbar.',
      ),
      heatmapTimestampDisabledDates: {
        customPredictor: (date) => {
          let disable = true;
          const currentDate = moment(date).format('DDMMYYYY');

          for (let i = 0; i < this.heatmapTimestampAvailableTimestamps.timeArray.length; i += 1) {
            const availableDate = moment.unix(this.heatmapTimestampAvailableTimestamps.timeArray[i]).format('DDMMYYYY');
            if (currentDate === availableDate) {
              disable = false;
              break;
            }
          }

          return disable;
        },
      },
      quantisationCodes: [
        { id: 'high', name: this.$t('Hoch') },
        { id: 'medium', name: this.$t('Mittel') },
        { id: 'low', name: this.$t('Gering') },
      ],
    };
  },
  computed: {
    ...mapState({
      selectedField(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].selectedFields;
      },
      indexTypeSelected(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/selectedIndexType`];
      },
      heatmapTimestampAvailableTimestamps(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/availableTimestamps`];
      },
      heatmapTimestampSelected(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/selectedHeatmapTimestamp`];
      },
      heatmapTimestampSelectedIndex(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/heatmapTimestampSelectedIndex`];
      },
      heatmapTimestampManuallySelected(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/heatmapTimestampManuallySelected`];
      },
      quantisationCodeSelected(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/selectedQuantisationCode`];
      },
    }),

    datepickerButtonPrevDisabled() {
      return this.heatmapTimestampSelectedIndex == null || this.heatmapTimestampSelectedIndex === 0;
    },
    datepickerButtonNextDisabled() {
      return (
        this.heatmapTimestampSelectedIndex == null ||
        this.heatmapTimestampSelectedIndex === this.heatmapTimestampAvailableTimestamps.timeArray.length - 1
      );
    },
    heatmapTimestampSelectedFormatted() {
      if (this.heatmapTimestampSelected == null) {
        return this.$t('Lade..');
      }
      return moment.unix(this.heatmapTimestampSelected).format('DD.MM.YYYY');
    },
    indexTypeValueForDropdown() {
      return this.indexTypes.find((type) => type.id === this.indexTypeSelected);
    },
    showDatePicker() {
      return this.indexTypeSelected !== 'BMMY';
    },
    quantisationCodeValueForDropdown() {
      return this.quantisationCodes.find((code) => code.id === this.quantisationCodeSelected);
    },
    indexTypesToDropDownItems() {
      return [
        {
          items: this.indexTypes,
        },
      ];
    },
    quantisationCodesToDropDownItems() {
      return [
        {
          items: this.quantisationCodes,
        },
      ];
    },
  },
  watch: {
    heatmapTimestampAvailableTimestamps(timestamps) {
      if (this.heatmapTimestampManuallySelected) {
        return;
      }
      const { timeArray, mapping } = timestamps;
      const lastIndex = timeArray.length - 1;
      const lastAvailableTimestamp = timeArray[lastIndex];
      this.$store.commit(
        `precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapTimestampSelectedIndex`,
        lastIndex,
      );
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapInformation`, {
        dbId: mapping[lastAvailableTimestamp] || null,
        timestamp: lastAvailableTimestamp || null,
      });
    },
  },
  methods: {
    // workaround as bootstrap has no option to hide tooltip when hovering over the popover
    onTooltipMouseleave() {
      this.$root.$emit('bv::hide::tooltip');
    },
    indexTypeSelect(indexType) {
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setSelectedIndexType`, indexType.id);
      this.selectedField.forEach((fieldKey) =>
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setMultipolyTimestamp`, fieldKey),
      );
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/loadHeatmaps`);
    },
    heatmapTimestampMovePickedDateBy(val) {
      this.$store.commit(
        `precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapTimestampManuallySelected`,
        true,
      );
      this.$store.commit(
        `precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapTimestampSelectedIndex`,
        this.heatmapTimestampSelectedIndex + val,
      );
      const { timeArray, mapping } = this.heatmapTimestampAvailableTimestamps;
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapInformation`, {
        dbId: mapping[timeArray[this.heatmapTimestampSelectedIndex]] || null,
        timestamp: timeArray[this.heatmapTimestampSelectedIndex] || null,
      });
    },
    heatmapTimestampSetDateFromDatepicker(date) {
      const formattedDate = moment(date).startOf('day').unix();
      this.$store.commit(
        `precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapTimestampManuallySelected`,
        true,
      );

      const { timeArray, mapping } = this.heatmapTimestampAvailableTimestamps;
      for (let i = 0; i < timeArray.length; i += 1) {
        const availableDate = moment.unix(timeArray[i]).startOf('day').unix();
        if (formattedDate === availableDate) {
          this.$store.commit(
            `precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapTimestampSelectedIndex`,
            i,
          );
          const foundDate = timeArray[i];
          this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/setHeatmapInformation`, {
            dbId: mapping[foundDate] || null,
            timestamp: foundDate || null,
          });
          break;
        }
      }
    },
    quantisationCodeSelect(quantisationCode) {
      this.$store.commit(
        `precisionFarming/applicationMaps/${this.workflowKey}/setSelectedQuantisationCode`,
        quantisationCode.id,
      );
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/loadHeatmaps`);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select-satellite-index__label {
  line-height: 1.5em;
  font-weight: 600;
}

.form-select-satellite-index__timestamp {
  color: var(--gray_600);
  margin-left: 6px;
}

.form-select-satellite-index__datepicker-button-wrapper {
  display: flex;
  flex-grow: 1;
  place-content: flex-end;
}

#form-select-satellite-index__calender {
  margin-bottom: var(--spacer_2);
}

.date-picker-calendar-icon {
  margin: 5px;
  font-size: 16px;
}

.form-select-satellite-index__calendar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border: 1px solid;
  border-radius: 3px;
  border-color: var(--gray_400);
}

.form-select-satellite-index__datepicker {
  position: absolute;
  width: 80%;
}

::v-deep .form-select-satellite-index__datepicker-calendar {
  width: 260px;
  left: 0;
}

::v-deep .form-select-satellite-index__datepicker-input {
  opacity: 0;
  cursor: pointer;
  width: 100%;
}
</style>
