<template>
  <div class="widget">
    <header v-if="title || subtitle || $slots.title != null" class="widget__header">
      <slot name="title">
        <h3 v-if="title" class="widget__title" :class="{ 'widget__title--with-subtitle': subtitle }">
          {{ title }}
        </h3>
      </slot>
      <p v-if="subtitle" class="widget__subtitle">({{ subtitle }})</p>
      <div v-if="$slots['header-right'] != null" class="widget__header-right">
        <slot name="header-right" />
      </div>
    </header>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  margin-bottom: var(--spacer_5);
}

.widget__header {
  display: flex;
  margin: 0 0 var(--spacer_3) 0;
  align-items: baseline;
}

.widget__title {
  margin-bottom: 0;
  font-size: 1.14rem;
  font-weight: 600;
}

.widget__title--with-subtitle {
  margin-right: var(--spacer_2);
}

.widget__subtitle {
  margin-bottom: 0;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.widget__header-right {
  margin-left: auto;
}

@media print {
  .widget {
    page-break-inside: avoid;
  }
}
</style>
