import { AxiosRequestConfig } from 'axios';
import {
  ActivityImportApi,
  ActivityImportApiActivityImportMapDataRequest,
  ImportActivity,
  ImportActivityEquipment,
  ImportActivityProduct,
} from 'farmdok-rest-api';
import Vue from 'vue';
import { ActionContext } from 'vuex';

import {
  ImportActivityEquipmentCombined,
  ImportActivityProductCombined,
  ImportActivityWithId,
} from '@/activities/importActivities/store/types';
import { Getters as AuthGetters } from '@/auth/store/getters';
import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { createUuid } from '@/shared/modules/uuid';
import { RootState } from '@/store/types';

export const dataUrl = '/admin/rest/measure';
export const resolve = ['processOrder', 'workingMeans'];

function emptyFakeResponse(): ApiResponse<ImportActivityWithId> {
  return {
    version: '2.0',
    status: 'success',
    data: [],
  };
}

function getImportActivitiesWithId(
  apiResponsesMerged: ApiResponse<ImportActivity>,
): ImportActivityWithId[] | undefined {
  // TODO remove ts-ignore after merged with other branch that contains props data setup
  // @ts-ignore
  return apiResponsesMerged.data?.map((importActivity) => ({
    ...importActivity,
    products: getImportActivitiesProducts(importActivity.products),
    equipment: getImportActivitiesEquipment(importActivity.equipment),
    id: createUuid(),
  }));
}

function getImportActivitiesProducts(importActivityProducts: ImportActivityProduct[]): ImportActivityProductCombined[] {
  return importActivityProducts.map((importActivityProduct) => ({
    ...importActivityProduct,
    id: createUuid(),
    tstamp: toUnixTimestamp(Date.now()),
    productId: importActivityProduct.productId || '',
  }));
}

function getImportActivitiesEquipment(
  importActivityEquipments: ImportActivityEquipment[],
): ImportActivityEquipmentCombined[] {
  return importActivityEquipments.map((importActivityEquipment) => ({
    ...importActivityEquipment,
    id: createUuid(),
    tstamp: toUnixTimestamp(Date.now()),
    equipmentId: importActivityEquipment.equipmentId || '',
  }));
}

export async function fetchAll(
  context: ActionContext<SubscribableDataState<ImportActivityWithId>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ImportActivityWithId>> {
  const processOrderIds = context.rootGetters['auth/currentProcessOrderIds'] as AuthGetters['currentProcessOrderIds'];

  if (processOrderIds?.length === 0) {
    return emptyFakeResponse();
  }

  const { activityImportApi } = Vue.prototype.$api as Api;
  const promises: Promise<ApiResponse<ImportActivity>>[] = [];
  processOrderIds.forEach((processOrderId) => {
    const requestParameters: ActivityImportApiActivityImportMapDataRequest = {
      ...context.rootState.activities.importActivities.activityImportMapDataRequestParameters,
      processOrderId,
    };
    promises.push(
      loadAllPages<ActivityImportApi, ImportActivity>(
        activityImportApi,
        'activityImportMapData',
        requestParameters,
        axiosOptions,
      ),
    );
  });

  const apiResponses = await Promise.all(promises);
  const apiResponsesMerged = mergeApiResponses(apiResponses);
  const importActivitiesWithId = getImportActivitiesWithId(apiResponsesMerged);

  return {
    ...apiResponsesMerged,
    data: importActivitiesWithId,
  };
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<ImportActivityWithId>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ImportActivityWithId>> {
  throw new Error('API NOT AVAILABLE');
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
