import {
  Activity,
  ActivityEquipment,
  ActivityProduct,
  Equipment,
  ProductCategory,
  RuleViolationType,
} from 'farmdok-rest-api';

import ViolationService from '@/activities/ruleCheck/services/ViolationService';
import { Violation, ViolationNotChecked, isViolationNotChecked } from '@/activities/ruleCheck/types';
import ActivityProductService from '@/activities/services/ActivityProductService';
import { TableDataMetadata } from '@/activities/store/types';
import {
  AmountsAndUnits,
  RuleCheckStatus,
  RuleCheckStatusType,
  TableDataActivity,
  TableDataActivityEquipment,
  TableDataActivityProduct,
} from '@/activities/types';
import { Getters as ActivityTypeGetters } from '@/activityTypes/store/getters';
import { Getters as AuthGetters } from '@/auth/store/getters';
import { Company, User } from '@/auth/store/types';
import { Getters as FieldGetters } from '@/fields/store/getters';
import { Getters as PesticideIndicationsGetters } from '@/pesticideIndications/store/getters';
import { Getters as ProductGetters } from '@/products/store/getters';
import { Field, Product, Unit } from '@/shared/api/rest/models';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { ENTRY_SYNCED } from '@/shared/constants';
import { Data, DataEntry } from '@/shared/mixins/store/types';

export default class TableDataActivityService {
  private readonly activities: Activity[];

  private readonly metadata: Record<string, TableDataMetadata>;

  private readonly units: Data<Unit>;

  private readonly fields: Data<Field>;

  private readonly productCategories: Data<ProductCategory>;

  private readonly equipments: Data<Equipment>;

  private readonly users: Data<User>;

  private readonly violationsService: ViolationService | null;

  private readonly activityProductService: ActivityProductService;

  private readonly companiesById: Data<Company>;

  private readonly processOrdersById: AuthGetters['processOrdersById'];

  private readonly productsLoading: boolean;

  private readonly findProductById: (id: string) => Product | undefined;

  private readonly productStorageDropdownItem: ProductGetters['dropdownItem'];

  private readonly pesticideIndicationsDropdownItem: PesticideIndicationsGetters['dropdownItem'];

  private readonly activityTypeDropdownItem: ActivityTypeGetters['dropdownItem'];

  private readonly fieldDropdownItem: FieldGetters['dropdownItem'];

  private readonly calculateAmountsAndUnits: (
    amount: number | null | undefined,
    unitId: string | null | undefined,
    processedArea: number | null | undefined,
    units: Data<Unit>,
  ) => AmountsAndUnits;

  private readonly isHerbicide: (product: Product | undefined, productCategories: Data<ProductCategory>) => boolean;

  constructor(
    activities: Activity[],
    metadata: Record<string, TableDataMetadata>,
    units: Data<Unit>,
    fields: Data<Field>,
    productCategories: Data<ProductCategory>,
    equipments: Data<Equipment>,
    users: Data<User>,
    violationService: ViolationService | null,
    activityProductService: ActivityProductService,
    companiesById: Data<Company>,
    processOrdersById: AuthGetters['processOrdersById'],
    productsLoading: boolean,
    findProductById: ProductGetters['findProductById'],
    productStorageDropdownItem: ProductGetters['dropdownItem'],
    pesticideIndicationsDropdownItem: PesticideIndicationsGetters['dropdownItem'],
    activityTypeDropdownItem: ActivityTypeGetters['dropdownItem'],
    fieldDropdownItem: FieldGetters['dropdownItem'],
    calculateAmountsAndUnits: (
      // TODO replace by amountsAndUnitsService
      amount: number | null | undefined,
      unitId: string | null | undefined,
      processedArea: number | null | undefined,
      units: Data<Unit>,
    ) => AmountsAndUnits,
    isHerbicide: (product: Product | undefined, productCategories: Data<ProductCategory>) => boolean, // TODO replace by productsService
  ) {
    this.activities = activities;
    this.metadata = metadata;
    this.units = units;
    this.fields = fields;
    this.productCategories = productCategories;
    this.equipments = equipments;
    this.users = users;
    this.violationsService = violationService;
    this.activityProductService = activityProductService;
    this.companiesById = companiesById;
    this.processOrdersById = processOrdersById;
    this.productsLoading = productsLoading;
    this.findProductById = findProductById;
    this.productStorageDropdownItem = productStorageDropdownItem;
    this.pesticideIndicationsDropdownItem = pesticideIndicationsDropdownItem;
    this.activityTypeDropdownItem = activityTypeDropdownItem;
    this.fieldDropdownItem = fieldDropdownItem;
    this.calculateAmountsAndUnits = calculateAmountsAndUnits;
    this.isHerbicide = isHerbicide;
  }

  public convertToActivitiesTableData(): TableDataActivity[] {
    const tableData: TableDataActivity[] = [];

    this.activities.forEach((activity) => {
      const { products, equipment, id, activityTypeId, fieldId, storeStatus, ...restOfActivity } =
        activity as Activity & DataEntry;
      const productsNotDeleted = TableDataActivityService.getNotDeletedActivityProducts(products);
      const equipmentNotDeleted = TableDataActivityService.getNotDeletedActivityEquipment(equipment);
      const metadataWithFallback = this.getMetadataWithFallback(id);
      const activityTypeDropdownItem = this.activityTypeDropdownItem(activityTypeId) ?? undefined;
      const fieldDropdownItem = fieldId ? this.fieldDropdownItem(fieldId) ?? undefined : undefined;
      const fieldSize = this.getFieldSize(activity.fieldId);
      const ruleCheckStatus = this.getRuleCheckStatus(id);
      const companyName = this.getCompanyName(activity.processOrderId);
      const user = this.getUserName(activity.userId);

      const longestSubtableLength = Math.max(productsNotDeleted.length, equipmentNotDeleted.length);
      if (longestSubtableLength === 0) {
        tableData.push({
          ...restOfActivity,
          id,
          activityTypeDropdownItem,
          fieldDropdownItem,
          ...metadataWithFallback,
          fieldSize,
          ruleCheckStatus,
          companyName,
          user,
          storeStatus,
        });
      } else {
        for (let i = 0; i < longestSubtableLength; i += 1) {
          const singleProduct = productsNotDeleted[i];
          const singleEquipment = equipmentNotDeleted[i];

          tableData.push({
            ...restOfActivity,
            id,
            activityTypeDropdownItem,
            fieldDropdownItem,
            ...metadataWithFallback,
            product: singleProduct ? this.toTableDataActivityProduct(singleProduct, activity.processedArea) : undefined,
            equipment: singleEquipment ? this.toTableDataActivityEquipment(singleEquipment) : undefined,
            fieldSize,
            ruleCheckStatus,
            companyName,
            user,
            storeStatus,
          });
        }
      }

      tableData.push(
        TableDataActivityService.rowForAddButtons(
          id,
          activityTypeDropdownItem,
          fieldDropdownItem,
          fieldSize,
          ruleCheckStatus,
          restOfActivity,
          metadataWithFallback,
          companyName,
          user,
        ),
      );
    });

    return tableData;
  }

  public static getRuleCheckStatusType(violations: Array<Violation | ViolationNotChecked> | null): RuleCheckStatusType {
    if (!violations || violations.length === 0) {
      return 'OK';
    }

    if (violations.some((violation) => !isViolationNotChecked(violation))) {
      return RuleViolationType.Violation;
    }

    return RuleViolationType.NotChecked;
  }

  private getMetadataWithFallback(activityId: string): TableDataMetadata {
    const metadataSelect = this.metadata[activityId]?.select ?? false;
    const metadataExpand = this.metadata[activityId]?.expand ?? false;
    return { select: metadataSelect, expand: metadataExpand };
  }

  private toTableDataActivityProduct(
    activityProduct: ActivityProduct,
    processedArea: number | null | undefined,
  ): TableDataActivityProduct {
    const activityProductWithFallbackAmount = { ...activityProduct, amount: activityProduct.amount ?? 0 };
    const amountsAndUnits = this.activityProductService.getAmountsAndUnits(
      activityProductWithFallbackAmount,
      processedArea,
    );

    const { amount, unitId, ...restOfActivityProduct } = activityProduct;

    const productStorageDropdownItem = this.productStorageDropdownItem(
      activityProduct.productId,
      activityProduct.storagePlaceId,
    );
    const pesticideIndicationDropdownItem = this.getOptionalPesticideIndicationDropdownItem(activityProduct);

    return {
      ...restOfActivityProduct,
      productStorageDropdownItem: productStorageDropdownItem ?? undefined,
      pesticideIndicationDropdownItem,
      ...amountsAndUnits,
      isLoading: this.productsLoading,
    };
  }

  private toTableDataActivityEquipment(activityEquipment: ActivityEquipment): TableDataActivityEquipment {
    const { id, equipmentId, ...restOfActivityEquipment } = activityEquipment;
    return {
      ...restOfActivityEquipment,
      id,
      dropdownItem: {
        id: equipmentId,
        name: this.equipments[equipmentId]?.name ?? '',
      },
    };
  }

  private static rowForAddButtons(
    id: string,
    activityTypeDropdownItem: DropdownItem | undefined,
    fieldDropdownItem: DropdownItem | undefined,
    fieldSize: number | undefined,
    ruleCheckStatus: RuleCheckStatus | undefined,
    restOfActivity: Omit<Activity, 'products' | 'id' | 'equipment' | 'activityTypeId'>,
    metadata: TableDataMetadata,
    companyName: string,
    user: string | undefined,
  ): TableDataActivity {
    return {
      ...restOfActivity,
      id,
      activityTypeDropdownItem,
      fieldDropdownItem,
      fieldSize,
      ruleCheckStatus,
      ...metadata,
      product: TableDataActivityService.dummyProductForAddProductButton(),
      equipment: TableDataActivityService.dummyEquipmentForAddEquipmentButton(),
      companyName,
      user,
      storeStatus: ENTRY_SYNCED,
    };
  }

  private static dummyProductForAddProductButton(): TableDataActivityProduct {
    return {
      id: '',
      productId: '',
      tstamp: 0,
      productStorageDropdownItem: 'HOT_DISPLAY_BUTTON_RENDERER',
      pesticideIndicationDropdownItem: undefined,
      amountUnitRelative: {
        amount: null,
        unitId: null,
        isFixed: false,
      },
      amountUnitTotal: {
        amount: null,
        unitId: null,
        isFixed: false,
      },
      isLoading: false,
    };
  }

  private static dummyEquipmentForAddEquipmentButton(): TableDataActivityEquipment {
    return {
      id: '',
      tstamp: 0,
      dropdownItem: 'HOT_DISPLAY_BUTTON_RENDERER',
    };
  }

  private getOptionalPesticideIndicationDropdownItem(activityProduct: ActivityProduct): DropdownItem | undefined {
    const product = this.findProductById(activityProduct.productId);

    if (
      product &&
      this.isHerbicide(product, this.productCategories) &&
      activityProduct.pestId &&
      activityProduct.pesticideIndicationId
    ) {
      const dropdownItem = this.pesticideIndicationsDropdownItem(
        activityProduct.productId,
        activityProduct.pestId,
        activityProduct.pesticideIndicationId,
      );

      return dropdownItem;
    }

    return undefined;
  }

  private getFieldSize(fieldId: string | null | undefined): number | undefined {
    if (!fieldId) return undefined;
    const field = this.fields[fieldId];

    return field?.fieldSize;
  }

  private getRuleCheckStatus(activityId: string): RuleCheckStatus {
    const violations = this.violationsService?.getViolations(activityId);

    if (violations) {
      const type = TableDataActivityService.getRuleCheckStatusType(violations);
      const tooltipLabel = TableDataActivityService.getRuleCheckTooltipLabel(violations);

      return {
        type,
        tooltipLabel,
      };
    }

    return { type: 'OK', tooltipLabel: null };
  }

  private getCompanyName(processOrderId: string): string {
    const { companyId } = this.processOrdersById[processOrderId];
    return this.companiesById[companyId].abbreviation ?? this.companiesById[companyId].name;
  }

  private getUserName(userId: string | null | undefined): string | undefined {
    const user = userId ? this.users[userId] : undefined;
    if (!user) return undefined;

    return `${user.firstname} ${user.lastname}`;
  }

  private static getRuleCheckTooltipLabel(violations: Array<Violation | ViolationNotChecked> | null): string | null {
    if (!violations || violations.length === 0) {
      return null;
    }

    if (violations.length === 1) {
      return violations[0].shortMessage;
    }

    return 'Es gibt mehrere Warnhinweise';
  }

  private static getNotDeletedActivityProducts(products: ActivityProduct[]): ActivityProduct[] {
    return products.filter((product) => !product.deleted);
  }

  private static getNotDeletedActivityEquipment(equipment: ActivityEquipment[]): ActivityEquipment[] {
    return equipment.filter((equipmentItem) => !equipmentItem.deleted);
  }
}
