import { ImportActivity } from 'farmdok-rest-api';

export default function isImportActivityValid(importActivity: ImportActivity) {
  if (!importActivity) return false;

  if (!importActivity.activityTypeId) return false;
  if (!importActivity.fieldId) return false;

  const validProducts = importActivity.products.every((product) => product.productId !== '');
  const validEquipments = importActivity.equipment.every((equipment) => equipment.equipmentId !== '');

  return validProducts && validEquipments;
}
