import Handsontable from 'handsontable';

import NumericEditor from '@/shared/handsontable/editors/NumericEditor';

import DateEditor from './editors/DateEditor';
import DropdownEditor from './editors/DropdownEditor';
import ButtonRenderer from './renderers/ButtonRenderer';
import DateRenderer from './renderers/DateRenderer';
import DropdownRenderer from './renderers/DropdownRenderer';
import ErrorRenderer from './renderers/ErrorRenderer';
import ExpandRenderer from './renderers/ExpandRenderer';
import FooterRenderer from './renderers/FooterRenderer';
import LoadingRenderer from './renderers/LoadingRenderer';
import NumericRenderer from './renderers/NumericRenderer';
import SubTableRenderer from './renderers/SubTableRenderer';
import TableToggleRenderer from './renderers/TableToggleRenderer';
import TextRenderer from './renderers/TextRenderer';
import CheckboxRenderer from './renderers/checkboxRenderer/CheckboxRenderer';
import OptionalCheckboxRenderer from './renderers/optionalCheckboxRenderer/OptionalCheckboxRenderer';
import AmountUnitEditor from './rework/cellTypes/amountUnit/AmountUnitEditor';
import AmountUnitRenderer from './rework/cellTypes/amountUnit/AmountUnitRenderer';
import { ButtonRenderer as ButtonRendererNew } from './rework/cellTypes/buttonRenderer';
import CollapsedSubtableRenderer from './rework/cellTypes/collapsedSubtableRenderer/CollapsedSubtableRenderer';
import {
  DropdownEditor as DropdownEditorNew,
  DropdownRenderer as DropdownRendererNew,
} from './rework/cellTypes/dropdown';
import DurationEditor from './rework/cellTypes/duration/DurationEditor';
import DurationRenderer from './rework/cellTypes/duration/DurationRenderer';
import EmptyRenderer from './rework/cellTypes/emptyRenderer/EmptyRenderer';
import { ExpandRenderer as ExpandRendererNew } from './rework/cellTypes/expandRenderer';
import OptionalRenderer from './rework/cellTypes/optionalRenderer/OptionalRenderer';
import RuleCheckStatusRenderer from './rework/cellTypes/ruleCheckStatusRenderer/RuleCheckStatusRenderer';
import { SubtablePrimaryColumnRenderer } from './rework/cellTypes/subtablePrimaryColumn';
import TrashIconRenderer from './rework/cellTypes/trashIconRenderer/TrashIconRenderer';
import { WarningIconRenderer } from './rework/cellTypes/warningIconRenderer';

Handsontable.cellTypes.registerCellType('checkbox', {
  renderer: CheckboxRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.checkbox.optional', {
  renderer: OptionalCheckboxRenderer,
});

Handsontable.cellTypes.registerCellType('expand', {
  renderer: ExpandRenderer,
});

Handsontable.cellTypes.registerCellType('date', {
  editor: DateEditor,
  renderer: DateRenderer,
});

Handsontable.cellTypes.registerCellType('datetime', {
  renderer: DateRenderer,
});

/**
 * @deprecated use farmdok.dropdown instead
 */
Handsontable.cellTypes.registerCellType('dropdown', {
  editor: DropdownEditor,
  renderer: DropdownRenderer,
});

Handsontable.cellTypes.registerCellType('error', {
  renderer: ErrorRenderer,
});

Handsontable.cellTypes.registerCellType('footer', {
  renderer: FooterRenderer,
});

Handsontable.cellTypes.registerCellType('loading', {
  renderer: LoadingRenderer,
});

Handsontable.cellTypes.registerCellType('numeric', {
  editor: NumericEditor,
  renderer: NumericRenderer,
});

Handsontable.cellTypes.registerCellType('text', {
  editor: Handsontable.editors.TextEditor,
  renderer: TextRenderer,
});

Handsontable.cellTypes.registerCellType('table', {
  renderer: TableToggleRenderer,
});

Handsontable.cellTypes.registerCellType('subtable', {
  renderer: SubTableRenderer,
});

Handsontable.cellTypes.registerCellType('button', {
  renderer: ButtonRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.button', {
  renderer: ButtonRendererNew,
});

Handsontable.cellTypes.registerCellType('farmdok.expand', {
  renderer: ExpandRendererNew,
});

Handsontable.cellTypes.registerCellType('farmdok.dropdown', {
  editor: DropdownEditorNew,
  renderer: DropdownRendererNew,
});

Handsontable.cellTypes.registerCellType('farmdok.subtablePrimaryColumn', {
  editor: DropdownEditorNew, // only dropdown needs a renderer
  renderer: SubtablePrimaryColumnRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.amountUnit', {
  editor: AmountUnitEditor,
  renderer: AmountUnitRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.collapsedSubtableRenderer', {
  renderer: CollapsedSubtableRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.warningIcon', {
  renderer: WarningIconRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.trashIcon', {
  renderer: TrashIconRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.ruleCheckStatus', {
  renderer: RuleCheckStatusRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.duration', {
  editor: DurationEditor,
  renderer: DurationRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.placeholder', {
  renderer: TextRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.empty', {
  renderer: EmptyRenderer,
});

Handsontable.cellTypes.registerCellType('farmdok.optional', {
  renderer: OptionalRenderer,
});
