<template>
  <ThePageSkeleton class="page-password-reset" scroll-y content-flex>
    <div class="page-password-reset__content">
      <LogoAuthPages />
      <h1 class="page-password-reset__headline">
        {{ $t('Setze dein neues Passwort') }}
      </h1>
      <p v-if="!invalid" class="page-password-reset__subline">
        <span v-if="!passwordReset">{{ $t('Hier kannst du dein neues Passwort eingeben.') }} </span>
        <span v-else>{{ $t('Dein Passwort wurde erfolgreich gespeichert.') }} </span>
        <ButtonLink :to="{ name: 'login' }">{{ $t('Zurück zum Login.') }}</ButtonLink>
      </p>
      <ValidationObserver v-if="!invalid && !passwordReset" v-slot="{ errors }" ref="validationObserver" slim>
        <BForm class="w-100" novalidate @submit.prevent="submit">
          <ValidationProvider v-slot="{ errors, dirty }" rules="required|min:8" slim :name="$t('Passwort')">
            <FormFieldInput
              v-model="password"
              ref="password"
              class="w-100"
              name="password"
              type="password"
              autocomplete="new-password"
              variant="vertical-lg"
              :label="$t('Passwort')"
              :placeholder="$t('Passwort')"
              :disabled="fetching"
              :state="errors.length > 0 && !dirty ? false : null"
              @input="userErrorMessage = null"
            />
          </ValidationProvider>
          <p class="text-danger">{{ firstError(errors) || userErrorMessage }}&nbsp;</p>
          <Button type="submit" variant="primary" size="lg" block class="my-0" :disabled="fetching">
            <FontAwesomeIcon v-if="fetching" icon="circle-notch" class="text-white" spin />
            <span v-else>{{ $t('Passwort speichern') }}</span>
          </Button>
        </BForm>
      </ValidationObserver>
      <div v-if="invalid">
        <ErrorUserMessage :error-user-message="userErrorMessage" />
        <ButtonLink :to="{ name: 'login' }">{{ $t('Zurück zum Login.') }}</ButtonLink>
      </div>
    </div>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';

import LogoAuthPages from '@/auth/components/LogoAuthPages.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import ErrorUserMessage from '@/shared/components/ErrorUserMessage.vue';
import Button from '@/shared/components/buttons/Button.vue';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import veeValidateHelpers from '@/shared/mixins/form/veeValidateHelpers';
import backendResponseErrorHelpers from '@/shared/mixins/rest/backendResponseErrorHelpers';

library.add(faCircleNotch, faPaperPlane, faSpinnerThird);

export default {
  name: 'PagePasswordReset',
  components: {
    ErrorUserMessage,
    ThePageSkeleton,
    LogoAuthPages,
    FormFieldInput,
    Button,
    ButtonLink,
  },
  mixins: [veeValidateHelpers, backendResponseErrorHelpers],
  data() {
    return {
      fetching: false,
      password: null,
      passwordReset: false,
      invalid: false,
      defaultUserErrorMessage: this.$t('Unbekannter Fehler. Bitte überprüfe deine Internetverbindung.'),
    };
  },
  async created() {
    await this.$store.dispatch('auth/logout');
    try {
      const { data } = await axios.post('/admin/rest/user/verify_password_reset_token', {
        version: '2.0',
        data: {
          passwordToken: this.$route.params.token,
        },
      });
      if (data.status !== 'success') {
        this.invalid = true;
        this.setUserErrorMessageFromResponse(data);
      }
    } catch (error) {
      this.invalid = true;
      if (error == null || error.response == null) {
        this.setUserErrorMessageFromResponse(null);
      } else {
        this.setUserErrorMessageFromResponse(error.response.data);
      }
    }
  },
  mounted() {
    if (this.$refs.password == null) {
      return;
    }
    this.$refs.password.$el.querySelector('input').focus();
  },
  methods: {
    submit() {
      this.userErrorMessage = null;
      this.$refs.validationObserver.reset();
      this.$refs.validationObserver.handleSubmit(this.reset);
    },
    async reset() {
      if (this.fetching) {
        return;
      }
      this.fetching = true;

      // wait at least 200ms that the spinner looks better
      let response;
      await Promise.all([
        // eslint-disable-next-line no-promise-executor-return
        new Promise((resolve) => setTimeout(resolve, 200)),
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          try {
            const { data } = await axios.post('/admin/rest/user/password_reset', {
              version: '2.0',
              data: {
                passwordToken: this.$route.params.token,
                password: this.password,
              },
            });
            response = data;
          } catch (error) {
            if (error != null && error.response != null) {
              response = error.response.data;
            }
          }
          resolve();
        }),
      ]);

      if (response != null && response.status === 'success') {
        this.passwordReset = true;
      } else {
        this.setUserErrorMessageFromResponse(response);
      }
      this.fetching = false;
    },
  },
};
</script>

<style scoped>
.page-password-reset__content {
  display: flex;
  max-width: calc(500px + 2rem);
  width: 100%;
  margin: auto;
  padding: 1rem 1rem 200px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.page-password-reset__headline {
  margin-bottom: var(--spacer_3);
  font-size: 28px;
  line-height: 38px;
}

.page-password-reset__subline {
  margin-bottom: var(--spacer_6);
}
</style>
