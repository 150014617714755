import { GetterTree } from 'vuex';

import { ENTRY_REMOVED, ENTRY_REMOVING, ENTRY_TO_REMOVE } from '@/shared/constants';
import { RootState } from '@/store/types';

import { Data, DataEntry, Error, SubscribableDataState } from '../types';

export type SubscribableGetters<E extends DataEntry> = {
  data: Data<E>;
  dataWithoutRemoved: Data<E>;
  errors: Error[];
  fetching: boolean;
  loaded: Date | null;
  loading: boolean;
  findById: (id: string) => E | undefined;
  getById: (id: string) => E;
};

const moduleGetters: GetterTree<SubscribableDataState<DataEntry>, RootState> = {
  data: (state: SubscribableDataState<DataEntry>) => state.data,
  dataWithoutRemoved: (state: SubscribableDataState<DataEntry>) =>
    Object.values(state.data).reduce((dataWithoutRemoved, entry) => {
      if (
        entry.storeStatus === ENTRY_REMOVED ||
        entry.storeStatus === ENTRY_REMOVING ||
        entry.storeStatus === ENTRY_TO_REMOVE
      ) {
        return dataWithoutRemoved;
      }
      return {
        ...dataWithoutRemoved,
        [entry.id]: entry,
      };
    }, {}),
  errors: (state: SubscribableDataState<DataEntry>): Error[] => state.errors,
  fetching: (state: SubscribableDataState<DataEntry>): boolean => state.fetching,
  loaded: (state: SubscribableDataState<DataEntry>): Date | null => state.loaded,
  /**
   * Returns true if data is being loaded initially or the data has been reset.
   * (I.e. a loading animation should be shown)
   *
   * @param state
   * @return {boolean}
   */
  loading: (state: SubscribableDataState<DataEntry>): boolean => {
    if (state.loaded != null) {
      return false;
    }
    if (state.fetching) {
      return true;
    }
    return state.errors.length === 0;
  },
  findById<E extends DataEntry>(state: SubscribableDataState<E>): (id: string) => E | undefined {
    return (id: string) => state.data[id];
  },
  getById<E extends DataEntry>(state: SubscribableDataState<E>, getters: SubscribableGetters<E>): (id: string) => E {
    return (id: string) => {
      const entry = getters.findById(id);
      if (entry) {
        return entry;
      }
      throw new Error(`Entry with id ${id} not found`);
    };
  },
};

export default moduleGetters;
