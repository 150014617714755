export default {
  props: {
    /**
     * If set to true on hover show floating buttons, containing a main button (e.g. "edit") and "show context-menu", will appear on the right.
     */
    hoverButtons: {
      type: Boolean,
      default: false,
    },
    hoverButtonsNoMainButton: {
      type: Boolean,
      default: false,
    },
    hoverButtonsMainButtonIcon: {
      type: [String, Array],
      default: undefined,
    },
    hoverButtonsMainButtonTitle: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      featureRowHoverTd: null,
      featureRowHoverTarget: null,
      featureRowHoverGuid: null,
      featureRowHoverCurrentRow: null,
    };
  },
  mounted() {
    // add target adding via native event listener and handsontable mouseover:
    // 1. handsontable mouseover is not working when returning to the cell after hovering over TableRowHoverButtons
    // 2. native event only works on master cells
    this.addHotHook('afterRender', this.featureRowHoverAfterRender);
    this.addHotHook('beforeOnCellMouseOver', this.featureRowHoverBeforeOnCellMouseOver);
    this.addHotHook('afterScrollVertically', this.featureRowHoverAfterScrollVertically);
  },
  methods: {
    featureRowHoverAfterRender() {
      this.$el.querySelectorAll('.handsontable tbody tr').forEach((tr) => {
        tr.removeEventListener('mouseenter', this.featureRowHoverOnRowMouseEnter);
        tr.addEventListener('mouseenter', this.featureRowHoverOnRowMouseEnter);
      });
      this.$el.querySelectorAll('.table-base__table-container').forEach((tr) => {
        tr.removeEventListener('mouseleave', this.featureRowHoverOnTableMouseLeave);
        tr.addEventListener('mouseleave', this.featureRowHoverOnTableMouseLeave);
      });
    },
    featureRowHoverOnTableMouseLeave() {
      this.$el.querySelectorAll('.handsontable tr.hovering').forEach((tr) => {
        tr.classList.remove('hovering');
      });

      this.featureRowHoverTd = null;
      this.featureRowHoverTarget = null;
      this.featureRowHoverCurrentRow = null;
    },
    featureRowHoverOnRowMouseEnter(event) {
      this.$el.querySelectorAll('.handsontable tr.hovering').forEach((tr) => {
        tr.classList.remove('hovering');
      });
      let n = 0;
      for (let { target } = event; target.previousElementSibling != null; target = target.previousElementSibling) {
        n += 1;
      }
      this.$el.querySelectorAll('.handsontable tbody').forEach((tbody) => {
        const tr = tbody.children.item(n);
        if (tr == null) {
          return;
        }
        tr.classList.add('hovering');
      });
    },
    featureRowHoverBeforeOnCellMouseOver(event, { row: visualRow }, td) {
      if (this.featureRowHoverCurrentRow !== visualRow) {
        this.featureRowHoverRowIndex = visualRow;
        this.featureRowHoverTd = td;
        this.featureRowHoverTarget = td.parentElement;
        this.featureRowHoverGuid = this.visualRowToGuid(visualRow);
      }
    },
    featureRowHoverAfterScrollVertically() {
      if (this.$refs.featureRowHoverButtons == null) {
        return;
      }
      this.$refs.featureRowHoverButtons.updatePosition();
    },
  },
};
