import Handsontable from 'handsontable';

import {
  appendNumberIndicator,
  appendSubTable,
  currentCellMetadata,
  selectNeighborCell,
} from '@/shared/handsontable/renderers/helpers/subTableRendererHelpers';

import ReadOnlyRenderer from './ReadOnlyRenderer';
import RequiredRenderer from './RequiredRenderer';

const minColumnWidth = 210;

export default function SubTableRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args) {
  const beforeKeyDown = (event, subTable) => {
    if (event.key === 'Enter') {
      const currentCell = currentCellMetadata(instance);
      if (!currentCell) {
        return;
      }
      if (
        currentCell.type === 'subtable' &&
        visualRow === currentCell.coords.row &&
        visualCol === currentCell.coords.col &&
        cellProperties.expanded(currentCell.coords.row)
      ) {
        event.stopImmediatePropagation(); // prevent cell from entering edit mode
        subTable.selectFirstCell();
      }
    }
  };

  const beforeOnCellMouseDown = (event, subTable) => {
    const clickableClasses = [
      'dropdown__item', // if a dropdown item is clicked, the dropdown should not be closed
      'dropdown__select',
      'base-search-term',
    ];
    const targetClassList = event.target.classList;
    const keepEditorActive = clickableClasses.some((classname) => targetClassList.contains(classname));
    if (keepEditorActive) {
      return;
    }
    subTable?.closeEditor();
  };

  const expandRow = (event) => {
    if (!cellProperties.expanded(visualRow)) {
      const currentCell = currentCellMetadata(instance);
      if (currentCell.coords.row === visualRow && currentCell.coords.col === visualCol) {
        event?.stopImmediatePropagation();
        cellProperties.toggleExpand(visualRow);
      }
    }
  };

  const setMinColWidth = (newSize, col) => {
    if (col !== visualCol) {
      return newSize;
    }
    if (cellProperties.expanded(visualRow)) {
      return Math.max(cellProperties.width, newSize);
    }
    return Math.max(minColumnWidth, newSize);
  };

  if (cellProperties.required && (typeof value !== 'string' || value.length === 0)) {
    RequiredRenderer.apply(this, [instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args]);
    return;
  }

  const values = cellProperties.getRendererValues({ visualRow, visualCol });
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    TD,
    visualRow,
    visualCol,
    prop,
    values.map((v) => v.displayName).join(', '),
    cellProperties,
    ...args,
  ]);

  TD.classList.add('subtable');
  // eslint-disable-next-line no-param-reassign
  TD.innerHTML = '';
  const subTableInstance = appendSubTable(values, cellProperties, TD, visualRow, cellProperties.expanded(visualRow));

  subTableInstance.$on('updateCell', (subtableRow, change) => {
    cellProperties.updateEntry(visualRow, subtableRow, change);
  });

  subTableInstance.$on('remove:row', (subtableRow) => {
    cellProperties.removeRow(visualRow, subtableRow);
  });

  subTableInstance.$on('deselectParentCell', () => {
    instance.deselectCell();
  });

  subTableInstance.$on('exitSubTable', (direction) => {
    selectNeighborCell(direction, visualRow, visualCol, instance);
  });

  subTableInstance.$on('expand', () => {
    expandRow(null);
  });

  instance.addHook('beforeKeyDown', (event) => beforeKeyDown(event, subTableInstance));
  instance.addHook('beforeOnCellMouseDown', (event) => beforeOnCellMouseDown(event, subTableInstance));
  instance.addHook('modifyColWidth', (newSize, col) => setMinColWidth(newSize, col));

  if (values.length > 1 && !cellProperties.expanded(visualRow)) {
    appendNumberIndicator(values || [], TD, expandRow);
  }

  if (cellProperties.readOnly) {
    ReadOnlyRenderer.apply(this, [instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args]);
  }
}
