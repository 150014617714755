import Handsontable from 'handsontable';

import { TableDataBase } from '../../types';
import createCellsMeta from './createCellsMeta';
import getColumnsForExport from './getColumnsForExport';
import prepareCellsMetadata from './prepareCellsMetadata';
import prepareTableData from './prepareTableData';

export default function exportBuildJson(
  ids: string[],
  columns: Handsontable.ColumnSettings[],
  tableData: TableDataBase[],
  hot: Handsontable,
  subtableColumnData?: string,
): string {
  const lut = [
    { bold: true }, // header
    { fontSize: 10 }, // subheader
    { format: 'default' },
    { format: 'numeric' },
    { format: 'datetime' },
  ];

  const columnsForExport = getColumnsForExport(columns, subtableColumnData);
  const { columnWidths, columnTitles, columnDescriptions } = prepareCellsMetadata(columnsForExport, hot);
  const data = prepareTableData(ids, columns, columnTitles, columnDescriptions, tableData, subtableColumnData);
  const cellsMeta = createCellsMeta(data.length, columnsForExport);

  const pages = [
    {
      sheetSettings: {},
      sheetName: 'Sheet 1',
      metadata: { cells: cellsMeta, lut },
      data,
      columnWidths,
      rowHeights: [],
      images: [],
    },
  ];

  return JSON.stringify(pages);
}
