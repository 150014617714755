import Vue from 'vue';

export const companyId = {
  key: 'processOrder.companyId',
  header: {
    title: Vue.i18n.translate('Betrieb'),
  },
  type: 'dropdown',
  width: 130,
  required: true,
  readOnly: true,
};

export const deletedAt = {
  key: 'deleted',
  header: {
    title: Vue.i18n.translate('gelöscht am'),
  },
  type: 'date',
  width: 130,
};
