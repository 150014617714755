import axios, { AxiosRequestConfig } from 'axios';

import { BASE_PATH } from '../common';
import { ApiRequestOptions, ApiResponse, Customer, Filter } from '../models';
import { apiOptionsToSearchParams, dataToBase64, urlSearchParamsToUnencodedString } from '../requestUtils';

// eslint-disable-next-line import/prefer-default-export
export async function fetchCustomers<Resolve = undefined, ResolveInSeparateList = undefined>(
  companyIds: string[],
  axiosOptions?: AxiosRequestConfig,
  apiOptions: ApiRequestOptions = { itemsPerPage: 5000 },
) {
  const searchParams = apiOptionsToSearchParams(apiOptions);
  searchParams.append('version', '2.0');

  const filter: Filter = ['companyId', 'IN', companyIds];
  searchParams.append('filter', dataToBase64(filter));

  const url = `${BASE_PATH}/companyCustomer?${urlSearchParamsToUnencodedString(searchParams)}`;

  return axios.get<ApiResponse<Customer[], Resolve, ResolveInSeparateList>>(url, axiosOptions);
}
