import { AxiosResponse } from 'axios';
import { ActivitiesGpsTrack200Response } from 'farmdok-rest-api';
import Vue from 'vue';

import { ActivitiesGps } from '@/activities/gpsTrack/store/types';
import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { TracksGps } from '@/tracks/store/types';

function emptyFakeResponse(): ApiResponse<ActivitiesGps> {
  return {
    version: '2.0',
    status: 'success',
    data: [],
  };
}

export async function fetchAll(): Promise<ApiResponse<ActivitiesGps>> {
  throw new Error('not implemented yet!');
}

function toApiResponse(response: ActivitiesGpsTrack200Response, activityId: string): ApiResponse<TracksGps> {
  return {
    ...response,
    data: [{ id: activityId, ...response.data }],
  } as ApiResponse<ActivitiesGps>;
}

async function fetchByIds(ids: string[]): Promise<ApiResponse<ActivitiesGps>> {
  if (ids.length === 0) {
    return emptyFakeResponse();
  }

  const { activitiesApi } = Vue.prototype.$api as Api;
  const promises: Promise<ApiResponse<ActivitiesGps>>[] = ids.map((activityId: string) =>
    activitiesApi
      .activitiesGpsTrack({ activityId })
      .then((response: AxiosResponse<ActivitiesGpsTrack200Response>) => response.data)
      .then((response: ActivitiesGpsTrack200Response) => toApiResponse(response, activityId)),
  );
  const responses: ApiResponse<ActivitiesGps>[] = await Promise.all(promises);
  return mergeApiResponses(responses);
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
