import _isEqual from 'lodash.isequal';

/**
 * compares two objects and returns the differences
 * copied from https://gist.github.com/Yimiprod/7ee176597fef230d1451?permalink_comment_id=3267525#gistcomment-3267525
 * differnce(base, object);
 * {
 *   "aws.secretKey": "u dont say",      // value has changed or was added
 *   "aws.array[1].foo": null,           // key was removed
 * }
 * @param oldObject
 * @param newObject
 * @returns
 */
export default function differenceObject(oldObject: Object, newObject: Object): Record<string, any> {
  const changes: Record<string, any> = walkObject(oldObject, newObject);

  return changes;
}

function walkObject(oldObject: Object, newObject: Object, path = ''): Record<string, any> {
  const deletedChanges = detectDeletedProperties(oldObject, newObject, path);
  const newAndChangedPropertyChanges = detectNewAndChangedProperties(oldObject, newObject, path);

  return { ...deletedChanges, ...newAndChangedPropertyChanges };
}

function detectDeletedProperties(oldObject: Object, newObject: Object, path = ''): Record<string, any> {
  const changes: Record<string, any> = {};
  Object.keys(oldObject).forEach((key) => {
    const currentPath = path === '' ? key : `${path}.${key}`;

    if (newObject[key as keyof Object] === undefined) {
      changes[currentPath] = null;
    }
  });

  return changes;
}

function detectNewAndChangedProperties(oldObject: Object, newObject: Object, path = ''): Record<string, any> {
  let changes: Record<string, any> = {};

  Object.entries(newObject).forEach(([key, newValue]) => {
    const currentPath = getCurrentPath(newObject, key as keyof Object, path);

    if (!_isEqual(newValue, oldObject[key as keyof Object])) {
      if (typeof newValue === 'object' && typeof oldObject[key as keyof Object] === 'object') {
        changes = { ...changes, ...walkObject(oldObject[key as keyof Object], newValue, currentPath) };
      } else {
        changes[currentPath] = newValue;
      }
    }
  });

  return changes;
}

function getCurrentPath(newObject: Object, key: keyof Object, path = ''): string {
  if (Array.isArray(newObject)) {
    return `${path}[${key}]`;
  }
  if (path === '') {
    return key;
  }
  return `${path}.${key}`;
}
