
import type { ActivityRuleViolation, Rule } from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import ModalRuleCheckViolations from '@/precision-farming/application-maps/components/ModalRuleCheckViolations.vue';
import isUnique from '@/shared/modules/uniqueFilter';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

export default defineComponent({
  name: 'ModalRuleCheckViolationsContainer',
  components: { ModalRuleCheckViolations },
  computed: {
    visible: {
      get(): boolean {
        return this.$store.state.precisionFarming.applicationMaps.spraying.showRuleViolationModal;
      },
      set(show: boolean): void {
        this.$store.state.precisionFarming.applicationMaps.spraying.showRuleViolationModal = show;
      },
    },
    ...mapState('precisionFarming/applicationMaps/spraying', ['violations']),
    ...mapState('activities/ruleCheck/rules', {
      rules: 'data',
    }),
    violationMessages(): string[] {
      return this.violations
        .map((violation: ActivityRuleViolation) => {
          const rule: Rule = this.rules[violation.ruleId];
          const messageArgs = Object.values<string>(violation.violationData);
          return this.interpolateMessage(rule.longMessage, messageArgs);
        })
        .filter(isUnique);
    },
    featureDismissViolationEnabled(): boolean {
      return !this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_RULE_CHECK);
    },
  },
  methods: {
    interpolateMessage(message: string, args: string[], anchor = '%s'): string {
      return args.reduce((previous, current) => previous.replace(anchor, current), message);
    },
  },
});
