import { Activity } from 'farmdok-rest-api';

import { ActivityType } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

export default function findLastActivityTypeOfField(
  fieldId: string,
  activities: Activity[],
  activityTypes: Data<ActivityType>,
): ActivityType | null {
  const fieldActivities = activities.filter((activity: Activity) => activity.fieldId === fieldId);

  if (fieldActivities.length > 0) {
    const lastActivity = fieldActivities.reduce((a, b) => (a.timeFinish! > b.timeFinish! ? a : b));
    const type = activityTypes[lastActivity.activityTypeId] ?? null;
    return type;
  }
  return null;
}
