import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import Handsontable from 'handsontable';

import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';

import RequiredRenderer from '../../../renderers/RequiredRenderer';
import setCellTitleAttribute from '../../../renderers/helpers/setCellTitleAttribute';
import applyMergeRowsBorderStyles from '../../features/nestedTable/utils/applyMergeRowsBorderStyles';
import applySubtableBorderStyles from '../../features/nestedTable/utils/applySubtableRendererBorderStyles';
import { ColumnSettingsDropdown } from './types';

library.add(faExclamationCircle);

export default function DropdownRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  visualRow: number,
  visualCol: number,
  prop: string | number,
  value: DropdownItem | null,
  cellProperties: ColumnSettingsDropdown,
  ...args: unknown[]
) {
  if (cellProperties.required && value == null) {
    if (isWarningVisible(cellProperties, value, visualRow, instance)) {
      applyWarningStyles(TD, cellProperties);
    }
    // @ts-ignore
    RequiredRenderer.apply(this, [instance, TD, visualRow, visualCol, prop, value, cellProperties, ...args]);
    return;
  }

  const displayValue = value?.name || '';
  // @ts-ignore
  Handsontable.renderers.AutocompleteRenderer(instance, TD, visualRow, visualCol, prop, displayValue, cellProperties);

  if (cellProperties.dropdown.isReadOnly) {
    // eslint-disable-next-line no-param-reassign
    cellProperties.readOnly = cellProperties.dropdown.isReadOnly(visualRow, visualCol, instance);
  }

  if (cellProperties.readOnly) TD.classList.add('read-only');

  setCellTitleAttribute(TD, displayValue);
  applySubtableBorderStyles(TD, visualRow, cellProperties, instance);
  applyMergeRowsBorderStyles(TD, visualRow, instance);

  // appending icons goes to the end, so they are not overwritten by AutocompleteRenderer

  if (cellProperties.showNotification) addNotificationIcon(TD, cellProperties);

  if (isWarningVisible(cellProperties, value, visualRow, instance)) {
    applyWarningStyles(TD, cellProperties);
  }
}

function isWarningVisible(
  cellProperties: ColumnSettingsDropdown,
  value: DropdownItem | null,
  visualRow: number,
  instance: Handsontable,
) {
  if (typeof cellProperties.showWarning === 'function') {
    if (cellProperties.showWarning(value, visualRow, instance)) return true;
  } else if (cellProperties.showWarning) return true;
  return false;
}

/* -- Setters -- */

function applyWarningStyles(TD: HTMLTableCellElement, cellProperties: ColumnSettingsDropdown) {
  TD.classList.add('warning');
  addNotificationIcon(TD, cellProperties);
}

function addNotificationIcon(TD: HTMLTableCellElement, cellProperties: ColumnSettingsDropdown) {
  // remember the span, as it is used as target for Bootstrap popovers
  const span: HTMLElement = TD.querySelector('span.hot-floating-cell-icon') || document.createElement('span');
  span.className = 'hot-floating-cell-icon hot-floating-cell-icon--plain hot-floating-cell-icon--dropdown';
  span.innerHTML = '';
  const [statusIcon] = icon({ prefix: 'fal', iconName: 'exclamation-circle' }).node;
  span.append(statusIcon);

  if (cellProperties.warningIcon?.tooltipLabel) {
    span.title = cellProperties.warningIcon.tooltipLabel;
  }

  TD.classList.add('hot-cell-with-floating-cell-icon');
  TD.classList.add('hot-cell-with-floating-cell-icon--dropdown');
  TD.append(span);
}
