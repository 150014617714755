<template>
  <div class="info-container" :class="{ 'info-container--error': error }">
    <FontAwesomeIcon v-if="error" class="info-container__icon--error mr-1" :icon="['far', 'exclamation-triangle']" />
    <FontAwesomeIcon v-else class="mr-1" :icon="['far', 'info-circle']" />
    <slot name="default" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

library.add(faInfoCircle, faExclamationTriangle);

export default {
  name: 'InfoContainer',
  props: {
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.info-container {
  padding: 0.375rem 0.75rem;
  border: 1px solid var(--secondary_medium);
  border-radius: 2px;
}

.info-container--error {
  border-color: var(--danger);
}

.info-container__icon--error {
  color: var(--danger);
}
</style>
