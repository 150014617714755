import { render, staticRenderFns } from "./ModalContentViolation.vue?vue&type=template&id=2308efed&"
import script from "./ModalContentViolation.vue?vue&type=script&lang=ts&setup=true&generic=T%20extends%20%7B%20longMessageHtml%3A%20string%3B%20%7D&"
export * from "./ModalContentViolation.vue?vue&type=script&lang=ts&setup=true&generic=T%20extends%20%7B%20longMessageHtml%3A%20string%3B%20%7D&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports