import {
  ActivityImportApiActivityImportResetRequest,
  ActivityImportApiActivityImportStatusRequest,
  ActivityImportStatusCode,
} from 'farmdok-rest-api';
import Vue, { ref } from 'vue';

import { Api } from '@/plugins/farmdokRestApi';

const pollingInProgress = ref(false);
const importProgress = ref(0);
const importStatus = ref<ActivityImportStatusCode | undefined>();
const importErrorMessage = ref<string | null>(null);

export default function useCnhActivityImport() {
  const POLLING_INTERVAL = 3000;

  const { activityImportApi } = Vue.prototype.$api as Api;

  function startPollingImportStatus() {
    pollingInProgress.value = true;
    importProgress.value = 0;
    importErrorMessage.value = null;
    setTimeout(() => pollImportStatus(), 3000);
  }

  function stopPollingImportStatus() {
    pollingInProgress.value = false;
  }

  async function pollImportStatus() {
    if (!pollingInProgress.value) return;
    try {
      const statusResponse = await cnhImportStatus();
      importStatus.value = statusResponse.data.data?.status ?? 'Error';
      importProgress.value = statusResponse.data.data?.progressPercent ?? 1;
      if (statusResponse.data.data?.status === 'Pending') {
        setTimeout(() => pollImportStatus(), POLLING_INTERVAL);
      } else if (statusResponse.data.data?.status === 'Error') {
        importErrorMessage.value = statusResponse.data.data?.errorUserMessage ?? 'An error occured';
        importProgress.value = 0;
        pollingInProgress.value = false;
        stopPollingImportStatus();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function resetImport() {
    const requestParametes: ActivityImportApiActivityImportResetRequest = {
      activityImportResetRequest: { source: 'CnhDownload' },
    };
    return activityImportApi.activityImportReset(requestParametes);
  }

  function cnhImportStatus() {
    const requestParametes: ActivityImportApiActivityImportStatusRequest = { source: 'CnhDownload' };
    return activityImportApi.activityImportStatus(requestParametes);
  }

  return {
    startPollingImportStatus,
    stopPollingImportStatus,
    resetImport,
    cnhImportStatus,
    importProgress,
    pollingInProgress,
    importStatus,
    importErrorMessage,
  };
}
