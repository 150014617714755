import Vue from 'vue';
import { ActionTree } from 'vuex';

import { ENTRY_DIRTY, ENTRY_NEW } from '@/shared/constants';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';
import { RootState } from '@/store/types';

import { DataEntry, ResponseData, SyncableDataState } from '../types';
import { UpdateEntries } from './types';

function actions<Entry extends DataEntry>(
  updateEntries: UpdateEntries<Entry>,
): ActionTree<SyncableDataState<Entry>, RootState> {
  return {
    async insertNewEntry({ commit }, { entry }: { entry: Entry }) {
      commit('insert', { entry });
    },
    /**
     * Insert new entry using 'insertNewEntry' and then syncs data to BE.
     *
     * @param dispatch
     * @param entry
     * @return {Promise<void>}
     */
    async insertAndSyncNewEntry({ dispatch }, { entry }: { entry: Entry }) {
      await dispatch('insertNewEntry', { entry });
      await dispatch('syncAll');
    },
    /**
     * Updates an existing entry and then syncs data to BE.
     *
     * @param commit
     * @param dispatch
     * @param entry
     * @return {Promise<void>}
     */
    async updateAndSyncEntry({ commit, dispatch }, { entry }: { entry: Entry }) {
      commit('updateEntry', { entry });
      await dispatch('syncAll');
    },
    /** */
    async updateEntryByKeyAndValueAndSync(
      { commit, dispatch },
      { key, guid, value }: { key: string; guid: string; value: any },
    ) {
      commit('updateEntryByKeyAndValue', { key, guid, value });
      await dispatch('syncAll');
    },
    /**
     * Sync all dirty and new entries to BE.
     * If a request already runs do not send another one.
     * After each successful request call automatically calls 'syncAll' recursively again, until there are no more dirty/new entries anymore.
     */
    async syncAll(context) {
      const { state, commit, dispatch } = context;
      if (state.syncing) {
        await new Promise((callback) => {
          commit('addSyncableObserver', callback);
        });
        return;
      }
      // Do the debouncing manually (even though a new call will not delay the call anymore)
      // as lodash.debounce doesnt work for async functions out of the box
      commit('syncAllStart');
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
      const requestData = Object.values(state.data).filter(
        (entry) => entry.storeStatus === ENTRY_DIRTY || entry.storeStatus === ENTRY_NEW,
      );

      if (requestData.length < 1) {
        commit('clearSyncableObservers');
        commit('syncAllFinish');
        return;
      }
      commit('syncAllAddEntries', requestData);

      let responseData: ResponseData<Entry>;

      try {
        // TODO remove storeStatus field?
        const response = await updateEntries(requestData, context);
        responseData = getRestResponseData(response);
      } catch (error) {
        console.error(error);
        responseData = getRestResponseData(error);
      }

      if (responseData.status === 'success') {
        commit('syncAllFinish', responseData.data);
        await dispatch('syncAll');
      } else if (responseData.status === 'partialSuccess') {
        dispatch('addSyncErrors', { requestData, responseData });
        commit(
          'syncAllFinish',
          // @ts-ignore
          responseData.data?.filter((entry) => responseData?.errors?.[entry.id] == null),
        );
        await dispatch('syncAll');
      } else {
        dispatch('addSyncErrors', { requestData, responseData });
        commit('clearSyncableObservers');
        commit('syncAllFinish');
      }
    },
    /**
     * Adds dummy error objects for every entry that was changed if the whole request failed.
     * In case of a 'partialSuccess' errors will be added for every entry that failed.
     *
     * @param commit
     * @param requestData
     * @param responseData
     */
    addSyncErrors({ commit }, { requestData, responseData }) {
      if (responseData.status !== 'partialSuccess') {
        requestData.forEach((entry: Entry) => {
          commit('addSyncError', {
            guid: entry.id,
            key: null,
            errorUserMessage: responseData.errorUserMessage[0],
          });
        });
        return;
      }
      Object.keys(responseData.errors).forEach((guid) => {
        const error = getRestResponseData({
          status: 'error',
          ...responseData.errors[guid],
        });
        error.errorUserMessage.forEach((errorUserMessage: any) => {
          commit('addSyncError', {
            guid,
            key: null,
            errorUserMessage,
          });
        });
        if (error.errorCode === 'attributeValidationError') {
          // @ts-ignore
          Object.keys(error.errorAttributeUserMessages).forEach((key) => {
            commit('addSyncError', {
              guid,
              key,
              // @ts-ignore
              errorUserMessage: error.errorAttributeUserMessages[key][0],
            });
          });
          // @ts-ignore
        } else if (typeof error.errorFieldName === 'string' && error.errorFieldName.length > 0) {
          commit('addSyncError', {
            guid,
            // @ts-ignore
            key: error.errorFieldName,
            errorUserMessage: Vue.i18n.translate('Ungültiger Wert.'),
          });
        }
      });
    },
  };
}

export default actions;
