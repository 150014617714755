// this mixin is copied and simplified from https://github.dev/e2o/vue-element-query/blob/master/src/index.js
// because vue-element-query uses babel-polyfill which is incompatible to core-js@3
import ResizeObserver from 'resize-observer-polyfill';

export default {
  data() {
    return {
      resizeObserver: null,
      resizeObserverSize: {
        width: 0,
        height: 0,
      },
    };
  },
  beforeDestroy() {
    this.destroy();
  },
  mounted() {
    this.initResizeObserver();
  },
  methods: {
    /**
     * initialize the ResizeObserver for this component
     */
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(([entry]) => {
        const { height, width } = entry.contentRect;

        this.resizeObserverSize = { height, width };
      }).observe(this.$el);
    },

    /**
     * Stop observing the current element and disconnect the ResizeObserver
     */
    destroy() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
  },
};
