<template>
  <TableBase
    ref="table"
    :id="id"
    disable-read-only-bg
    :filter-string="filterString"
    :selected-rows="selectedRows"
    :visible-rows="visibleRows"
    :loading="loading"
    :table-settings="tableSettings"
    :table-data="tableData"
    :table-errors="tableErrors"
    @tableMounted="tableMounted"
    @update:filterString="(value) => $emit('update:filterString', value)"
    @update:selectedRows="(value) => $emit('update:selectedRows', value)"
    @update:visibleRows="(value) => $emit('update:visibleRows', value)"
    @reload="loadData"
    @update:cell="updateEntryByKeyAndValue"
    @remove:row="(entry) => removeEntries([entry])"
    :class="[{ 'table-files-container--is-dragover': isDragover }]"
    @update:isDragover="(value) => (isDragover = value)"
    :data-dragover-label="$t('Leg deine Datei hier ab')"
    @drop="onFileDrop"
  >
    <template #footer>
      <TableFooter
        :total-rows="Object.keys(tableData).length"
        :visible-rows="visibleRows.length"
        :selected-rows="selectedRows.length"
      />
    </template>
    <template #no-content>
      <NoContent type="files" @uploadFiles="$emit('uploadFiles')" />
    </template>

    <template v-if="!isDragover" #no-search-results>
      <NoContent type="search" @clearAllFilters="clearAllFilters" />
    </template>
  </TableBase>
</template>

<script>
import { mapGetters } from 'vuex';

import TableBase from '@/shared/handsontable/components/TableBase.vue';
import TableFooter from '@/shared/handsontable/components/TableFooter.vue';
import subscribableTableData from '@/shared/handsontable/mixins/containers/featureSubscribableTableData';
import syncableTableData from '@/shared/handsontable/mixins/containers/featureSyncableTableData';
import tableContainerBase from '@/shared/handsontable/mixins/containers/tableContainerBase';

import NoContent from '../components/NoContent.vue';
import columnsTableFiles from '../handsontable/columns/tableFiles';
import columndDropdownContractCompanies from './columnDropdownContractCompanies';

export default {
  name: 'TableFilesContainer',
  components: {
    TableBase,
    TableFooter,
    NoContent,
  },
  mixins: [
    tableContainerBase,
    subscribableTableData('managedContracts/files', { forceRefresh: true }),
    syncableTableData('managedContracts/files'),
    columndDropdownContractCompanies('companyId'),
  ],
  props: {
    contractId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      columnDefinition: columnsTableFiles,
      contractIdState: this.contractId,
      isDragover: false,
    };
  },
  computed: {
    ...mapGetters({
      contractFileData: 'managedContracts/contractFileData',
    }),
    id() {
      return ['hot-files', ...this.$store.state.auth.currentCompanies.map((company) => company.id)].join('-');
    },
    tableData() {
      return this.contractFileData(this.contractId).reduce(
        (tableData, file) => ({
          ...tableData,
          [file.guid]: file,
        }),
        {},
      );
    },
    loading() {
      return (
        this.$store.getters['managedContracts/files/loading'] ||
        this.$store.getters['managedContracts/companies/loading']
      );
    },
    contractIdComputed() {
      return 1;
    },
  },
  methods: {
    loadAdditionalData() {
      this.$store.dispatch('managedContracts/companies/subscribe');
    },

    clearAllFilters() {
      this.$refs.table.clearAllFilters();
    },
    async onFileDrop(event) {
      this.isDragover = false;
      const { files } = event.dataTransfer;
      if (files.length < 1) {
        return;
      }
      await this.$router.push({
        name: 'digital-contracting/managed-contracts/files/upload',
        params: {
          initialFiles: files,
        },
      });
    },
  },
};
</script>

<style scoped>
.table-files-container--is-dragover::after {
  --from-top: 46px; /* should match the padding-top from NoContent.vue */
  content: attr(data-dragover-label);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  padding-top: calc(var(--from-top) + 200px);
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
  color: var(--black);
  /* eslint-disable-nextline max-len */
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 295 295" xmlns="http://www.w3.org/2000/svg"><mask id="a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="295" height="295"><circle cx="147.5" cy="147.5" r="147.5" fill="%23C4C4C4"/></mask><g mask="url(%23a)"><path fill="%23F7F7F7" d="M-9-8h312v312H-9z"/></g><path d="M199.605 91.91c-.926-13.422-12.034-24.298-25.687-24.298-1.85 0-3.703.23-5.322.694-7.406-9.256-18.745-15.504-31.704-15.504-19.67 0-36.1 13.884-40.034 32.397-13.885 5.785-22.91 19.207-22.91 34.248 0 20.596 16.431 37.026 37.026 37.026h77.754c18.282 0 33.323-14.81 33.323-33.323 0-14.116-9.256-26.612-22.446-31.24zm-10.877 53.455h-77.754c-14.347 0-25.918-11.57-25.918-25.918 0-12.96 9.488-23.834 22.216-25.454v-.463c0-16.2 13.19-29.621 29.62-29.621 12.265 0 22.91 7.637 27.307 18.513 2.545-2.314 6.016-3.702 9.72-3.702 8.098 0 14.81 6.71 14.81 14.81 0 2.777-.926 5.322-2.083 7.636.694 0 1.388-.231 2.082-.231 12.265 0 22.216 9.95 22.216 22.215s-9.95 22.215-22.216 22.215z" fill="%233CC960"/><path d="M155.76 116.83h-29.736l-4.188 68.476 33.924 1.256 18.01-1.256v-42.3l-4.818-16.334-13.192-9.842z" fill="%23C7EDD1" stroke="%233CC960"/><path d="M174.624 127.227l-12.635-12.484c-1.354-1.354-3.159-2.256-4.964-2.256H126.19a7.324 7.324 0 00-7.069 7.37v62.573a7.062 7.062 0 007.07 7.07h43.32c4.061 0 7.37-3.16 7.37-7.07v-50.088c0-1.805-.903-3.761-2.256-5.115zm-17.148-6.92l11.582 11.583h-11.582v-11.582zm12.184 62.123h-43.47v-62.573h24.066V135.5c0 1.956 1.655 3.61 3.61 3.61h15.794v43.32zm-23.164-35.648l-10.83 10.83c-1.053 1.203-.3 3.158 1.353 3.158h7.371v12.635c0 .903.752 1.805 1.805 1.805h3.61c.902 0 1.805-.902 1.805-1.805V160.77h7.22c1.654 0 2.407-1.955 1.354-3.158l-10.83-10.83c-.752-.752-2.106-.752-2.858 0z" fill="%233CC960"/></svg>');
  background-size: 295px 295px;
  background-repeat: no-repeat;
  background-position: center var(--from-top);
  background-color: var(--primary_opacity_25);
  pointer-events: none;
}
</style>
