/* eslint-disable no-param-reassign */
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import Handsontable from 'handsontable';

import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';

import { ColumnSettingsWarningIcon } from './types';

library.add(faCircleExclamation);

export default function WarningIconRenderer(
  instance: Handsontable,
  TD: HTMLTableCellElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visualRow: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visualCol: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  prop: string | number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value: string | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cellProperties: ColumnSettingsWarningIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...args: unknown[]
) {
  Handsontable.dom.empty(TD);

  const [warnIcon] = icon({ prefix: 'far', iconName: 'circle-exclamation' }).node;
  TD.appendChild(warnIcon);

  if (cellProperties.warningIcon.onClick != null) {
    TD.style.cursor = 'pointer';
  }

  if (cellProperties.warningIcon.tooltipLabel) {
    TD.title = cellProperties.warningIcon.tooltipLabel;
  }

  const colorClass = getColorClass(cellProperties.warningIcon.color);
  warnIcon.classList.add(colorClass);

  TD.classList.add('warning-icon');

  applyMergeRowsBorderStyles(TD, visualRow, instance);
}

function getColorClass(color: string) {
  switch (color) {
    case 'red':
      return 'text-danger-dark';
    case 'gray':
      return 'text-gray-600';
    default:
      throw new Error(`Unsupported color: ${color}`);
  }
}
