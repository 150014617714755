import { AxiosRequestConfig } from 'axios';
import { ActionContext } from 'vuex';

import { ApiResponse, ProductVisibility } from '@/shared/api/rest/models';
import { fetchProductVisibilities } from '@/shared/api/rest/productsVisibility/productsVisibilityApi';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { Resolve, SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

export async function fetchAll(
  context: ActionContext<SubscribableDataState<ProductVisibility>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ProductVisibility[], Resolve>> {
  const { currentCompanies } = context.rootState.auth;
  const { data } = await fetchProductVisibilities(currentCompanies, axiosOptions);

  return data;
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<ProductVisibility>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<ProductVisibility[], Resolve>> {
  throw new Error('NOT IMPLEMENTED');
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
