<template>
  <div id="app" class="app" :class="{ 'app--content-fullscreen': isFullscreen }">
    <div v-if="initialFetching || $store.state.redirectingToAgriDat" class="loading-global-wrapper">
      <LoadingGlobal />
    </div>
    <TheNavbar v-if="$store.state.auth.loggedIn && !isFullscreen" />
    <div v-if="!initialFetching || $store.state.auth.loggedIn" class="page">
      <TheTopbar v-if="!isFullscreen" />
      <ThePagebar v-if="hasTabs && !isFullscreen" />
      <PageNoMultiCompanySupport v-if="!initialFetching && noMultiCompanySupport" />
      <RouterView v-else-if="!initialFetching && !$store.state.redirectingToAgriDat" />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';

import PageNoMultiCompanySupport from '@/auth/PageNoMultiCompanySupport.vue';
import TheNavbar from '@/layout/components/TheNavbar.vue';
import ThePagebar from '@/layout/components/ThePagebar.vue';
import TheTopbar from '@/layout/components/TheTopbar.vue';
import LoadingGlobal from '@/shared/components/LoadingGlobal.vue';
import { GOOGLE_MAPS_SETTINGS } from '@/shared/constants';

window.FARMDOK = { tables: {} };

export default {
  name: 'app',
  components: {
    LoadingGlobal,
    PageNoMultiCompanySupport,
    TheNavbar,
    TheTopbar,
    ThePagebar,
  },
  computed: {
    ...mapGetters({
      initialFetching: 'auth/initialFetching',
    }),
    hasTabs() {
      let { tabs } = this.$route.meta;
      if (typeof tabs === 'function') {
        tabs = tabs(this.$route);
      }
      return Array.isArray(tabs) && tabs.length >= 1;
    },
    navbarExpanded() {
      return this.$store.state.navbar.expanded;
    },
    isFullscreen() {
      return this.$route.meta.isFullscreen;
    },
    noMultiCompanySupport() {
      return this.$store.state.auth.currentCompanies.length > 1 && this.$route.meta.noMultiCompany;
    },
  },
  provide: {
    googleMapsSettings: GOOGLE_MAPS_SETTINGS,
  },
  watch: {
    navbarExpanded: {
      handler(expanded) {
        if (!expanded) {
          document.body.classList.remove('navbar-expanded');
          return;
        }
        document.body.classList.add('navbar-expanded');
      },
      immediate: true,
    },
  },
  mounted() {
    if (process.env.VUE_APP_EXPOSE === '1') {
      window.FARMDOK.app = this;
      window.FARMDOK.store = this.$store;
    }
    window.addEventListener('resize', debounce(this.calculateVh));
    this.calculateVh();
  },
  methods: {
    calculateVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
};
</script>

<style lang="scss">
@import './sass/app.scss';

.app {
  display: flex;
  height: 100%;
}

@media (max-width: 767px) {
  .app {
    flex-direction: column;
  }
}

.page {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

@media print {
  .app {
    height: auto;
  }
}
</style>

<style scoped>
.loading-global-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background: rgba(255, 255, 255, 0.7);
}
</style>
