import axios from 'axios';

import {
  ColorCode,
  ColorConfig,
  Feature,
  Heatmap,
} from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { Field } from '@/shared/api/rest/models';
import { dataToBase64 } from '@/shared/api/rest/requestUtils';
import { rgbtoHex } from '@/shared/modules/colorHelpers';
import { Variety } from '@/varieties/types';

import { roundToPrecision } from '../common';
import { Calculation, SeedingZone, WorkflowVariety } from '../types';

export { default as calcAreaWeightedVarietyMeanRoundedAndClamped } from './calcAreaWeightedVarietyMeanRoundedAndClamped';

export function calcKDiffFromManualSeedrate(zones: SeedingZone[], calculation: Calculation) {
  const { manualMinSeedrate, manualMaxSeedrate } = calculation;
  const zoneVarietyValues = zones.map((zone) => zone.varietyValue || 0);
  const minVarietyValue = Math.min(...zoneVarietyValues);
  const maxVarietyValue = Math.max(...zoneVarietyValues);
  const kDiff = ((manualMaxSeedrate || 0) - (manualMinSeedrate || 0)) / (maxVarietyValue - minVarietyValue);
  return Number(kDiff.toFixed(2));
}

export function calcBasicSeedStrengthFromManualSeedrate(zones: SeedingZone[], calculation: Calculation) {
  const { manualMinSeedrate, kDiff, areaWeightedVarietyMean } = calculation;
  const minVarietyValue = Math.min(...zones.map((zone) => zone.varietyValue || 0));

  const basicSeedStrength = (manualMinSeedrate || 0) - kDiff * (minVarietyValue - areaWeightedVarietyMean);
  const basicSeedStrengthRounded = roundToPrecision(basicSeedStrength);
  return basicSeedStrengthRounded;
}

export function calcHeatmapColors(heatmaps: Record<string, Heatmap>, zones: SeedingZone[]) {
  const newHeatmaps = heatmaps;
  const colorConfig = Object.values(heatmaps)[0].color_config;
  const newColorCodes = recalculateColorCodesForZones(colorConfig, zones);
  Object.entries(heatmaps).forEach(([heatmapKey, heatmap]) => {
    newHeatmaps[heatmapKey].color_codes = mapColorCodes(heatmap.color_codes, newColorCodes);
    newHeatmaps[heatmapKey].features = mapFeatureFillColor(heatmap.features, newColorCodes);
  });
  return newHeatmaps;
}

function recalculateColorCodesForZones(colorConfig: ColorConfig, zones: SeedingZone[]) {
  const minValue = Math.min(...zones.map((zone) => zone.seedRate || 0));
  const maxValue = Math.max(...zones.map((zone) => zone.seedRate || 0));
  const colorCodes: Record<string, string> = {};
  zones.forEach((zone) => {
    if (!colorCodes[zone.color]) {
      colorCodes[zone.color] = calcZoneColor(colorConfig, minValue, maxValue, zone.seedRate || 0);
    }
  });
  return colorCodes;
}

function calcZoneColor(colorConfig: ColorConfig, minValue: number, maxValue: number, seedRate: number) {
  let value = seedRate;
  if (value >= maxValue) {
    value = 1 - Number.EPSILON;
  } else {
    value = (seedRate - minValue) / (maxValue - minValue);
  }
  const colDelta = 1 / (colorConfig.color_codes.length - 1);
  const cidx0 = Math.trunc(value / colDelta);
  const cidx1 = cidx0 + 1;

  const scale = (value - cidx0 * colDelta) / colDelta;
  const c0 = colorConfig.color_codes[cidx0];
  const c1 = colorConfig.color_codes[cidx1];
  const colRgb = [
    Math.trunc(c0[0] + scale * (c1[0] - c0[0])),
    Math.trunc(c0[1] + scale * (c1[1] - c0[1])),
    Math.trunc(c0[2] + scale * (c1[2] - c0[2])),
  ];
  const colHex = rgbtoHex(colRgb);
  return colHex;
}

function mapColorCodes(oldColorCodes: ColorCode[], newColorCodes: Record<string, string>) {
  return oldColorCodes.map((colorCode) => {
    if (newColorCodes[colorCode.col]) {
      return { ...colorCode, col: newColorCodes[colorCode.col] };
    }
    return colorCode;
  });
}

function mapFeatureFillColor(features: Feature[], newColorCodes: Record<string, string>): Feature[] {
  return features.map((feature) => {
    const oldColor = feature.properties.fill;
    const newColor = newColorCodes[oldColor];
    if (newColor) {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          fill: newColor,
          stroke: newColor,
        },
      };
    }
    return feature;
  });
}

export function getFirstSelectedField(selectedFields: string[], fields: Record<string, Field>) {
  if (selectedFields.length === 0) {
    return null;
  }

  const selectedField = fields[selectedFields[0]];

  return selectedField;
}

export async function findMaterialBasedOnVariety(variety: WorkflowVariety, selectedField: Field) {
  if (!variety) {
    return null;
  }

  const {
    processOrder: { companyId },
  } = selectedField; // we assume that all fields have the same companyId

  const filter = [
    'AND',
    ['category.type', '=', 'seed'],
    ['name', '=', variety.name],
    ['OR', ['companyId', 'IS', null], ['companyId', '=', companyId]],
  ];

  try {
    const encodedFilter = dataToBase64(filter);

    const { data } = await axios.get(`/admin/rest/material/?version=2.0&filter=${encodedFilter}`);

    if (data.status === 'success' && data.data.length > 0) {
      return data.data[0];
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function findMaterialBasedOnSelectedFields(selectedField: Field) {
  const {
    cropId,
    processOrder: { companyId },
  } = selectedField; // we assume that all fields have the same cropId and companyId
  if (!cropId) {
    throw new Error('No cropId found on selected field');
  }
  const material = await findSeedsMaterialBasedOnCropId(cropId, companyId);

  return material;
}

async function findSeedsMaterialBasedOnCropId(fieldCropId: string, fieldCompanyId: string) {
  const filter = [
    'AND',
    ['category.type', '=', 'seed'],
    ['mainCropId', '=', fieldCropId],
    ['OR', ['companyId', 'IS', null], ['companyId', '=', fieldCompanyId]],
  ];

  try {
    const encodedFilter = dataToBase64(filter);

    const { data } = await axios.get(`/admin/rest/material/?version=2.0&filter=${encodedFilter}`);

    if (data.status === 'success' && data.data.length > 0) {
      return data.data[0];
    }

    throw new Error(`No material found: ${data}`);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getMostFrequentWorkflowVarietyInFields(
  fields: Field[],
  globalVarieties: Record<string, Variety>,
  workflowVarieties: WorkflowVariety[],
) {
  const fieldVarieties = getWorkflowVarietyCountsInFields(fields, globalVarieties, workflowVarieties);
  if (Object.values(fieldVarieties).length === 0) {
    return null;
  }
  const fieldVarietiesSorted = Object.values(fieldVarieties).sort((a, b) => b.count - a.count);
  const mostFrequentVariety = workflowVarieties.find((variety) => variety.name === fieldVarietiesSorted[0].name);
  return mostFrequentVariety;
}

function getWorkflowVarietyCountsInFields(
  fields: Field[],
  globalVarieties: Record<string, Variety>,
  workflowVarieties: WorkflowVariety[],
) {
  const fieldVarieties: Record<string, WorkflowVariety> = {};
  const workflowVarietyNames = workflowVarieties.map((variety) => variety.name);

  Object.values(fields).forEach((field) => {
    if (!field.varietyId) {
      return;
    }

    let varietyName = globalVarieties[field.varietyId].name;

    if (!workflowVarietyNames.includes(varietyName)) {
      const variety = workflowVarieties.find((workflowVariety) => workflowVariety.varietyId === field.varietyId);
      if (!variety) {
        return;
      }
      varietyName = variety.name;
    }

    if (!fieldVarieties[varietyName]) {
      fieldVarieties[varietyName] = {
        id: null,
        name: varietyName,
        count: 1,
      };
      return;
    }
    fieldVarieties[varietyName].count += 1;
  });
  return fieldVarieties;
}
