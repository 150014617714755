/* eslint-disable consistent-return */
import Handsontable from 'handsontable';

function preventMoveOfFixedColumnsFactory(hot: Handsontable, fixedColumnsLeft: number) {
  function preventMoveOfFixedColumns(
    movedColumns: number[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    finalIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropIndex: number | void,
    movePossible: boolean,
  ): boolean {
    const { columns } = hot.getSettings();
    if (!Array.isArray(columns)) throw new Error('columns must be of type ColumnSettings[]');

    const containsMoveOfFixedColumn = movedColumns.some((movedColumn) => {
      const physicalColumn = hot.toPhysicalColumn(movedColumn);
      return physicalColumn < fixedColumnsLeft;
    });

    if (containsMoveOfFixedColumn) {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
      movePossible = false;
      return false;
    }

    return true;
  }

  return preventMoveOfFixedColumns;
}

function preventMoveIntoFixedColumnsFactory(fixedColumnsLeft: number) {
  function preventMoveIntoFixedColumns(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    movedColumns: number[],
    finalIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropIndex: number | void,
    movePossible: boolean,
  ): boolean {
    if (finalIndex < fixedColumnsLeft) {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
      movePossible = false;
      return false;
    }

    return true;
  }

  return preventMoveIntoFixedColumns;
}

/**
 * prevents moving of a single column to the same position
 * Note: this is a workaround for a bug in handsontable
 * this hook prevents the following scenario:
 * if you move the column to the same position and try to move another column, the move highlights are broken
 * therefore we prevent the move of a single column to the same position
 */
function preventMoveToSamePosition(
  movedColumns: number[],
  finalIndex: number,
  dropIndex: number | void,
  movePossible: boolean,
): boolean {
  if (movedColumns.length === 1 && movedColumns[0] === finalIndex && movedColumns[0] === dropIndex) {
    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
    movePossible = false;
    return false;
  }

  return true;
}

export default {
  beforeColumnMove: { preventMoveToSamePosition, preventMoveOfFixedColumnsFactory, preventMoveIntoFixedColumnsFactory },
};
