<template>
  <BContainer class="settings">
    <p class="small">
      {{ $t('Wähle ein Dateiformat abhängig vom Terminal, auf dem die Applikationskarte verwendet werden soll.') }}
      {{ $t('Wähle “Standard” wenn dein Terminal-Hersteller nicht in der Liste angeführt ist.') }}
    </p>
    <FormFieldDropdown
      ref="shapeType"
      class="formControl"
      :label="$t('Dateiformat')"
      variant="vertical"
      :value="shapeTypeValueForDropdown"
      :items="shapeTypesToDropDownItems"
      @update="(value) => (selectedShapeType = value.id)"
      disableDelete
      slimMargin
      hideLabel
    />
    <CompanySelectFormFieldDropdown
      v-if="companySelectRequired"
      ref="company"
      class="formControl"
      v-model="selectedCompany"
      :companies="currentCompanies"
      variant="vertical"
      disableDelete
      hideLabel
      :required="companySelectRequired"
      data-cy="company-select-form-field-dropdown"
    />
    <div>
      <BButton
        variant="primary"
        class="btn btn-export"
        :disabled="downloadButtonDisabled"
        @click="exportApplicationMap"
      >
        <span>{{ $t('Download') }}</span>
      </BButton>
    </div>
  </BContainer>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';

import applicationMapAsShape from '../../mixins/applicationMapAsShape';
import CompanySelectFormFieldDropdown from './CompanySelectFormFieldDropdown.vue';
import getDatabaseId from './companyUtils';

export default {
  name: 'DownloadTab',
  components: { FormFieldDropdown, CompanySelectFormFieldDropdown },
  mixins: [applicationMapAsShape],
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
    buttonsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shapeTypes: [
        { id: 'default', name: this.$t('Standard - Shapefile') },
        { id: 'agleader', name: 'Ag Leader - Shapefile' },
        { id: 'amazone', name: 'AMAZONE - Shapefile' },
        { id: 'johndeere', name: 'JOHN DEERE - Shapefile' },
        { id: 'raven', name: 'RAVEN - Shapefile' },
        { id: 'topcon', name: 'TOPCON - Shapefile' },
        { id: 'trimble', name: 'Trimble - Shapefile' },
        { id: 'isoxml', name: 'ISOXML' },
      ],
      selectedShapeType: 'default',
      downloadInProgress: false,
    };
  },
  computed: {
    ...mapState({
      taskDataAsync(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/taskDataAsync`];
      },
      currentCompanies(state) {
        return state.auth.currentCompanies;
      },
    }),
    selectedCompany: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps[this.workflowKey].selectedCompany;
      },
      set(value) {
        this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setSelectedCompany`, value);
      },
    },
    shapeTypeValueForDropdown() {
      return this.shapeTypes.find((type) => type.id === this.selectedShapeType);
    },
    shapeTypesToDropDownItems() {
      return [{ items: this.shapeTypes }];
    },
    companySelectRequired() {
      return this.selectedShapeType === 'isoxml' && this.currentCompanies.length !== 1;
    },
    downloadButtonDisabled() {
      return (
        this.buttonsDisabled || this.downloadInProgress || (this.companySelectRequired && !this.selectedCompany.id)
      );
    },
  },
  methods: {
    async exportApplicationMap() {
      this.$emit('exportTriggered');

      this.downloadInProgress = true;

      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setFetchingTask`, true);
      const taskData = await this.taskDataAsync(this.$store.dispatch);
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setFetchingTask`, false);

      if (this.selectedShapeType === 'isoxml') {
        this.exportIsoXml(taskData);
        this.downloadInProgress = false;
        return;
      }

      const { default: saveAs } = await import('jszip/vendor/FileSaver');
      const { file, filename } = await this.applicationMapAsShape(this.selectedShapeType);
      saveAs(file, filename);
      taskData.data.usage = 'shape';
      this.createApplicationMapEvent(taskData);
      this.downloadInProgress = false;
    },
    async exportIsoXml(taskData) {
      try {
        const databaseId = getDatabaseId(this.selectedCompany);

        const { data } = await axios.post(
          `/admin/comp/${databaseId}/precision-farming/application-maps/isoxml/export`,
          taskData,
        );

        const b64DecodeUnicode = (str) =>
          decodeURIComponent(
            atob(str)
              .split('')
              .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
              .join(''),
          );

        const file = new File([b64DecodeUnicode(data.data)], 'TASKDATA.xml', { type: 'application/xml' });
        const a = document.createElement('a');
        a.download = 'TASKDATA.xml';
        a.href = URL.createObjectURL(file);
        a.click();
      } catch (e) {
        console.error(e);
      }
    },
    createApplicationMapEvent(taskData) {
      this.$emit('createApplicationMapEvent', taskData);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 0 40px;
  margin: 30px 0;
}

.settings > *:not(:last-child) {
  margin-bottom: 18px;
}

.btn {
  width: 100%;
}
</style>
