<template>
  <ModalTerms ref="modal" :gtc="gtc" :checkbox-text="checkboxText" :is-creating="isCreating" @accept="accept" />
</template>

<script>
import ModalTerms from '../components/ModalTerms.vue';

export default {
  name: 'ModalTermsContainer',
  components: { ModalTerms },
  props: {
    gtc: {
      type: String,
      default: null,
    },
    checkboxText: {
      type: String,
      default: null,
    },
    fieldIds: {
      type: Array,
      required: true,
    },
    companyData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCreating() {
      return this.$store.state.digitalContracting.creating != null;
    },
  },
  methods: {
    show() {
      if (this.$refs.modal == null) {
        return;
      }
      this.$refs.modal.show();
    },
    hide() {
      if (this.$refs.modal == null) {
        return;
      }
      this.$refs.modal.hide();
    },
    async accept() {
      try {
        await this.$store.dispatch('digitalContracting/accept', {
          fieldIds: this.fieldIds,
          companyData: this.companyData,
          newsletter: 0,
        });
      } catch (e) {
        console.error('Unable to accept contract.', e);
      } finally {
        this.$refs.modal.hide();
      }
    },
  },
};
</script>

<style scoped></style>
