import axios, { AxiosRequestConfig } from 'axios';
import { ActionContext } from 'vuex';

import { Getters as AuthGetters } from '@/auth/store/getters';
import { User } from '@/auth/store/types';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import { dataToBase64 } from '@/shared/api/rest/requestUtils';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

import { UserCompanyWithUser } from './types';

async function fetchAll(
  context: ActionContext<SubscribableDataState<User>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<User>> {
  const currentCompanyIds = context.rootGetters['auth/currentCompanyIds'] as AuthGetters['currentCompanyIds'];
  const filter = ['companyId', 'IN', currentCompanyIds];

  const resolve = ['user'];

  const { data: apiResponse } = await axios.get<ApiResponse<UserCompanyWithUser>>(
    `/admin/rest/companyUser?version=2.0&disablePagination=1&filter=${dataToBase64(filter)}&resolve=${dataToBase64(
      resolve,
    )}`,
    axiosOptions,
  );

  const users = extractUsersFromResponse(apiResponse.data);

  const apiResponseUsers: ApiResponse<User> = {
    ...apiResponse,
    data: users,
  };

  return apiResponseUsers;
}

async function fetchByIds(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context: ActionContext<SubscribableDataState<User>, RootState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<User>> {
  throw new Error('API NOT AVAILABLE');
}

const subscribableStore = subscribableData(fetchAll, fetchByIds);

export default subscribableStore;

function extractUsersFromResponse(data?: UserCompanyWithUser[] | undefined): User[] {
  if (!data) return [];

  const users = data.map((userCompanyWithUser) => {
    const { user } = userCompanyWithUser;
    return user;
  });

  return users;
}
