import { Activity, ImportActivity } from 'farmdok-rest-api';
import { GetterTree } from 'vuex';

import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import CompanyService from '@/auth/services/CompanyService';
import EquipmentService from '@/equipments/services/EquipmentService';
import FieldService from '@/fields/services/FieldService';
import isHerbicide from '@/products/herbizides/isHerbicide';
import ProductService from '@/products/services/ProductService';
import { Getters as ProductsGetters } from '@/products/store/getters';
import { Product } from '@/shared/api/rest/models';
import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { Data } from '@/shared/mixins/store/types';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { RootState } from '@/store/types';

import ActivityRuleViolationService from '../ruleCheck/activityRuleViolations/services/ActivityRuleViolationService';
import ViolationService from '../ruleCheck/services/ViolationService';
import ActivityEquipmentService from '../services/ActivityEquipmentService';
import ActivityProductService from '../services/ActivityProductService';
import ActivityService from '../services/ActivityService';
import TableDataActivityService from '../services/TableDataActivityService';
import { ActivityWithDisplayNames, TableDataActivity } from '../types';
import {
  convertToActivitiesWithDisplayNames,
  convertToActivityWithDisplayNames,
} from '../utils/convertToActivityWithDisplayName';
import findSimilarActivitiesForImportActivity from '../utils/findSimilarActivitiesForImportActivity';
import { subscribableStore, syncableStore } from './common';
import { ActivitiesState } from './types';

export type Getters = SubscribableGetters<Activity> & {
  activityWithDisplayNames: (activityId: string) => ActivityWithDisplayNames | undefined;
  activitiesWithDisplayNames: Data<ActivityWithDisplayNames>;
  activityProductDisplayName: (productId: string, storagePlaceId?: string | null) => string;
  selectedActivityIds: string[];
  tableDataActivities: TableDataActivity[];
  pesticideIdsInUse: string[];
  findSimilarActivitiesForImportActivity: (importActivity: ImportActivity) => Activity[];
  findAllByFieldId: (fieldId: string) => Activity[];
  findAllByFieldIds: (fieldIds: string[]) => Activity[];
};

const moduleGetters: GetterTree<ActivitiesState, RootState> = {
  ...subscribableStore.getters,
  ...syncableStore.getters,

  activityWithDisplayNames:
    (state: ActivitiesState, getters: Getters, rootState: RootState, rootGetters) =>
    (id: string): ActivityWithDisplayNames | undefined => {
      const activity = state.data[id];
      const findProductById = rootGetters['products/findProductById'] as ProductsGetters['findProductById'];
      const productsLoading = rootGetters['products/loading'];
      const storagePlaces = rootState.storagePlaces.data;
      const equipments = rootState.equipments.data;
      const fields = rootState.fields.data;
      const customers = rootGetters['customers/data'];
      const crops = rootState.products.crops.data;
      return convertToActivityWithDisplayNames(
        activity,
        findProductById,
        productsLoading,
        storagePlaces,
        equipments,
        fields,
        customers,
        crops,
      );
    },
  activitiesWithDisplayNames: (state, getters, rootState, rootGetters): Data<ActivityWithDisplayNames> => {
    const findProductById = rootGetters['products/findProductById'] as ProductsGetters['findProductById'];
    const productsLoading: boolean = rootGetters['products/loading'];
    const storagePlaces = rootState.storagePlaces.data;
    const equipments = rootState.equipments.data;
    const fields = rootState.fields.data;
    const customers = rootGetters['customers/data'];
    const crops = rootState.products.crops.data;
    return convertToActivitiesWithDisplayNames(
      state.data,
      findProductById,
      productsLoading,
      storagePlaces,
      equipments,
      fields,
      customers,
      crops,
    );
  },
  activityProductDisplayName:
    (state, getters, rootState, rootGetters) =>
    (productId: string, storagePlaceId?: string | null): string | null => {
      const product = (rootGetters['products/findProductById'] as ProductsGetters['findProductById'])(productId);
      if (!product) return null;

      if (storagePlaceId) {
        const storagePlace = rootState.storagePlaces.data[storagePlaceId];
        if (!storagePlace) return product.name;

        return `${product.name} (${storagePlace.name})`;
      }

      return product.name;
    },

  selectedActivityIds: (state): string[] =>
    Object.entries(state.tableDataMetadata)
      .filter(([, metadata]) => metadata.select)
      .map(([id]) => id),

  selectedActivities: (state, getters): Activity[] => {
    const selectedIds = getters.selectedActivityIds;
    return Object.values(state.data).filter((activity: Activity) => selectedIds.includes(activity.id));
  },

  tableDataActivities: (state, getters, rootState, rootGetters): TableDataActivity[] => {
    const currentTimestamp = toUnixTimestamp(Date.now());
    const activities = Object.values(state.data).filter(
      (activity) => activity.deleted === undefined || activity.deleted === null || activity.deleted > currentTimestamp,
    );
    const regionCodes: string[] = rootGetters['auth/currentCompaniesRegionCodes'];
    if (regionCodes.length > 1) throw new Error('Multiple region codes not supported');

    const fieldService = new FieldService(rootState.fields.data, rootGetters['products/findProductById']);
    const equipmentService = new EquipmentService(rootState.equipments.data);
    const activityEquipmentService = new ActivityEquipmentService(equipmentService);
    const companyService = new CompanyService(rootState.auth.companiesById);
    const productService = new ProductService(
      rootState.products.mineralFertilizers.data,
      rootState.products.companyFertilizers.data,
      rootState.products.secondaryFertilizers.data,
      rootState.products.herbizides.data,
      rootState.products.crops.data,
      rootState.products.miscellaneous.data,
      rootState.products.harvests.data,
      rootState.products.seeds.data,
      rootState.products.otherProductsAndFertilizers.data,
    );
    const activityProductService = new ActivityProductService(
      rootState.units.data,
      productService,
      calculateAmountsAndUnits,
    );
    const activityService = new ActivityService(
      state.data,
      fieldService,
      activityEquipmentService,
      activityProductService,
      companyService,
    );
    const activityRuleViolationService = new ActivityRuleViolationService(state.ruleCheck.activityRuleViolations.data);
    const violationService = new ViolationService(
      state.ruleCheck.rules.data,
      activityService,
      activityRuleViolationService,
      regionCodes[0],
    );
    const { companiesById } = rootState.auth;

    return new TableDataActivityService(
      activities,
      state.tableDataMetadata,
      rootState.units.data,
      rootState.fields.data,
      rootState.productCategories.data,
      rootState.equipments.data,
      rootState.users.data,
      violationService,
      activityProductService,
      companiesById,
      rootGetters['auth/processOrdersById'],
      rootGetters['products/loading'],
      rootGetters['products/findProductById'],
      rootGetters['products/dropdownItem'],
      rootGetters['pesticideIndications/dropdownItem'],
      rootGetters['activityTypes/dropdownItem'],
      rootGetters['fields/dropdownItem'],
      calculateAmountsAndUnits,
      isHerbicide,
    ).convertToActivitiesTableData();
  },
  pesticideIdsInUse: (state: ActivitiesState, getters: Getters, rootState, rootGetters): string[] => {
    const activities = Object.values(state.data);
    const pesticideIdsInUse = new Set<string>();

    activities.forEach((activity) => {
      activity.products.forEach((activityProduct) => {
        const product: Product = rootGetters['products/findProductById'](activityProduct.productId);
        if (product && isHerbicide(product, rootState.productCategories.data)) {
          pesticideIdsInUse.add(product.id);
        }
      });
    });
    return Array.from(pesticideIdsInUse).sort();
  },
  findSimilarActivitiesForImportActivity:
    (state) =>
    (importActivity: ImportActivity): Activity[] => {
      const activities = Object.values(state.data);
      return findSimilarActivitiesForImportActivity(activities, importActivity);
    },
  findAllByFieldId:
    (state: ActivitiesState) =>
    (fieldId: string): Activity[] =>
      Object.values(state.data).filter((activity: Activity) => activity.fieldId === fieldId),
  findAllByFieldIds:
    (state: ActivitiesState) =>
    (fieldIds: string[]): Activity[] =>
      Object.values(state.data)
        .filter((activity: Activity) => notNullOrUndefined(activity.fieldId))
        .filter((activity: Activity) => fieldIds.includes(activity.fieldId as string)),
};

export default moduleGetters;
