
import { PropType, defineComponent } from 'vue';

import { GOOGLE_MAPS_SETTINGS } from '@/shared/constants';
import googleMapsApi from '@/shared/modules/googleMapsApi';

import CenterMapControl from './CenterMapControl.vue';
import DownloadFileMapControl from './DownloadFileMapControl.vue';
import LocationMarker from './LocationMarker';

export interface GeolocationPositionError {
  code: number;
  message: string;
}

const initialPosition = {
  lat: 47.561838,
  lng: 14.633302,
};

export default defineComponent({
  name: 'GeoJsonMap',
  components: { DownloadFileMapControl, CenterMapControl },
  props: {
    controls: {
      type: Array as PropType<string[]>,
      default: () => ['searchField', 'mapType', 'zoom'],
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  inject: {
    googleMapsSettings: {
      default: () => GOOGLE_MAPS_SETTINGS,
    },
  },

  data() {
    return {
      map: null as google.maps.Map | null,
      google: null as any,
      currentLocation: null as { lat: any; lng: any } | null,
      mapDataBounds: null,
      centerControlActionType: 'currentLocation',
    };
  },

  async mounted() {
    await this.mapInit();
    await this.loadGeojson(this.data);
  },
  methods: {
    async mapInit() {
      this.google = await googleMapsApi();
      const options = {
        center: initialPosition,
        zoom: 16,
        tilt: 0,
        mapTypeId: this.google.maps.MapTypeId.SATELLITE,
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: this.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: this.google.maps.ControlPosition.LEFT_TOP,
          mapTypeIds: [
            this.google.maps.MapTypeId.SATELLITE,
            this.google.maps.MapTypeId.ROADMAP,
            this.google.maps.MapTypeId.HYBRID,
          ],
        },
        zoomControl: true,
        zoomControlOptions: {
          position: this.google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        fullscreenControl: false,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ],
      };
      this.map = new this.google.maps.Map(this.$refs['map-container'], options);

      this.displayCurrentLocation();
      this.map?.addListener('center_changed', () => {
        this.centerControlActionType = 'currentLocation';
      });
    },

    async loadGeojson(data: any) {
      if (data) {
        await this.map?.data.addGeoJson(data);
        this.map?.data.setStyle((feature) => ({
          fillColor: feature.getProperty('fill'),
          fillOpacity: feature.getProperty('fill-opacity'),
          strokeColor: feature.getProperty('stroke'),
          strokeWeight: feature.getProperty('stroke-width'),
          strokeOpacity: feature.getProperty('stroke-opacity'),
        }));
        this.computeDataMapBounds();
        this.mapFitBoundsToData();
        await this.$nextTick();
      }
    },

    mapFitBoundsToData() {
      if (this.mapDataBounds) {
        this.map?.fitBounds(this.mapDataBounds);
      }
    },

    computeDataMapBounds() {
      const bounds = new this.google.maps.LatLngBounds();
      this.map?.data.forEach((feature) => {
        feature.getGeometry()?.forEachLatLng((latLng) => {
          bounds.extend(latLng);
        });
      });
      this.mapDataBounds = bounds;
    },

    async displayCurrentLocation() {
      try {
        if (!navigator.geolocation) {
          throw new Error('Geolocation not available');
        }

        const marker = LocationMarker({ google: this.google, map: this.map });

        await this.watchCurrentLocation({
          onSuccess: ({ coords: { latitude: lat, longitude: lng, accuracy } }) => {
            marker.innerCircle.setPosition({ lat, lng });
            marker.outerCircle.setRadius(accuracy);
            this.currentLocation = { lat, lng };
          },
          onError: (err: any) => {
            throw new Error(`Error: ${err.code}: ${this.getPositionErrorMessage(err.code)}`);
          },
        });
      } catch (error: any) {
        console.error(`Could not retrieve current location: ${error.message}`);
      }
    },

    async watchCurrentLocation({
      onSuccess,
      onError,
    }: {
      onSuccess: (position: any) => void;
      onError: (error: GeolocationPositionError) => void;
    }) {
      await navigator.geolocation.watchPosition(onSuccess, onError, {
        enableHighAccuracy: true,
      });
    },

    async focusCurrentLocation() {
      try {
        if (!navigator.geolocation) {
          throw new Error('Geolocation not available');
        }

        if (!this.currentLocation) {
          throw new Error('Could not retrieve current location.');
        }

        this.map?.panTo(this.currentLocation);
      } catch (error: any) {
        console.error(`Could not focus current location: ${error.message}`);
      }
    },

    getPositionErrorMessage(code: number) {
      switch (code) {
        case 1:
          return 'Permission denied.';
        case 2:
          return 'Position unavailable.';
        case 3:
          return 'Timeout reached.';
        default:
          return 'unknown error';
      }
    },

    centerMap() {
      switch (this.centerControlActionType) {
        case 'mapData':
          this.mapFitBoundsToData();
          this.centerControlActionType = 'currentLocation';
          return;
        case 'currentLocation':
          this.focusCurrentLocation();
          this.centerControlActionType = 'mapData';
          return;
        default:
          this.mapFitBoundsToData();
          this.centerControlActionType = 'currentLocation';
      }
    },
  },
});
