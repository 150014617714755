import { REGION_GUIDS } from '@/shared/constants';
import { deletedAt } from '@/shared/handsontable/columns';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';

import { companyId, crop, fieldGroupNumber, fieldNumber, name, size } from './columns';

export const tableFieldsRestore = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...deletedAt,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
    sortOrder: 'desc',
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
    requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...fieldGroupNumber,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...fieldNumber,
    hiddenPerDefault: true,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...name,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...size,
    readOnly: true,
  },
  {
    ...crop,
    readOnly: true,
  },
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export const tableFieldsRestoreAt = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...deletedAt,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
    sortOrder: 'desc',
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
    requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...fieldGroupNumber,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...fieldNumber,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...name,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...size,
    readOnly: true,
  },
  {
    ...crop,
    readOnly: true,
  },
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export const tableFieldsRestoreDe = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  {
    ...deletedAt,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
    sortOrder: 'desc',
  },
  {
    ...companyId,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
    requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1,
  },
  {
    ...fieldGroupNumber,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...fieldNumber,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...name,
    lockedVisibility: true,
    lockedPosition: true,
    readOnly: true,
  },
  {
    ...size,
    readOnly: true,
  },
  {
    ...crop,
    readOnly: true,
  },
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export default {
  international: tableFieldsRestore,
  [REGION_GUIDS.AT]: tableFieldsRestoreAt,
  [REGION_GUIDS.DE]: tableFieldsRestoreDe,
};
