<template>
  <BButton
    v-b-tooltip.hover.bottom.d500.viewport
    size="sm"
    class="button-bordered-icon"
    :class="{ 'button-bordered-icon--has-next-sibling': hasNextSibling, active: active }"
    :title="title"
    @click="onClick"
  >
    <FontAwesomeIcon :icon="icon" />
  </BButton>
</template>

<script>
import componentNamespace from '../../mixins/componentNamespace';

export default {
  name: 'ButtonBorderedIcon',
  mixins: [componentNamespace],
  props: {
    /**
     * Fontawesome icon
     */
    icon: {
      type: [String, Array],
      default: null,
    },
    /**
     * The title will be shown using bootstrap tooltip directive.
     */
    title: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasNextSibling: false,
    };
  },
  mounted() {
    if (this.$el.nextElementSibling == null) {
      return;
    }
    this.hasNextSibling = true;
  },
  methods: {
    onClick() {
      /**
       *
       * @event root#button--click
       * @type {String}
       */
      this.$root.$emit('button--click', this.componentNamespace);
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.button-bordered-icon {
  border: 1px solid var(--gray_400);
  border-radius: 4px;
  color: var(--black);
  background: var(--white);
}

.button-bordered-icon:hover {
  border: 1px solid var(--gray_400);
  border-radius: 4px;
  color: var(--black);
  background: var(--lightest);
}

.button-bordered-icon:active,
.button-bordered-icon:not(:disabled):not(.disabled):active,
.button-bordered-icon:not(:disabled):not(.disabled):active:focus,
.button-bordered-icon.active,
.button-bordered-icon:not(:disabled):not(.disabled).active:focus,
.button-bordered-icon:not(:disabled):not(.disabled).active {
  border: 1px solid var(--primary_light);
  border-radius: 4px;
  color: var(--black);
  background: var(--primary_light);
  box-shadow: none;
}

.button-bordered-icon:focus {
  border: 1px solid var(--gray_400);
  border-radius: 4px;
  color: var(--black);
  background: var(--white);
  box-shadow: 0 0 0 2px var(--primary_light);
}

.button-bordered-icon:disabled {
  border: 1px solid var(--gray_400);
  border-radius: 4px;
  color: var(--gray_600);
  background: var(--gray_400);
}

.button-bordered-icon--has-next-sibling {
  margin-right: var(--spacer_2);
}
</style>
