import { StoragePlace } from 'farmdok-rest-api';

import { Product } from '@/shared/api/rest/models';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';

import Dropdown from '../entities/Dropdown';

export default class DropdownService {
  static getDropdownItem(product: Product, storagePlace?: StoragePlace): DropdownItem {
    return new Dropdown(product, storagePlace).toData();
  }
}
