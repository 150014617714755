
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import SidebarFields from '@/fields/components/SidebarFields.vue';
import columnsSidebarTableFields from '@/fields/handsontable/columns/applicationMapsSidebarTableFields';
import ModalConfirmation from '@/precision-farming/application-maps/components/ModalConfirmation.vue';
import SidebarCreateApplicationMapSpraying from '@/precision-farming/application-maps/spraying/SidebarCreateApplicationMapSpraying.vue';
import SidebarZones from '@/precision-farming/application-maps/spraying/SidebarZones.vue';
import SidebarExportSpraying from '@/precision-farming/application-maps/spraying/components/SidebarExportSpraying.vue';
import type {
  ApplicationMapsSprayingState,
  Calculation,
  PlantProtection,
} from '@/precision-farming/application-maps/spraying/store/types';
import type { Field, Product } from '@/shared/api/rest/models';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import isUnique from '@/shared/modules/uniqueFilter';

import PageApplicationMaps from '../PageApplicationMaps.vue';

export default defineComponent({
  name: 'PageApplicationMapsSpraying',
  components: {
    ModalConfirmation,
    SidebarExportSpraying,
    SidebarCreateApplicationMapSpraying,
    PageApplicationMaps,
    SidebarZones,
    SidebarFields,
  },
  data(): {
    columnDefinition: typeof columnsSidebarTableFields;
    stepForward: () => void;
    confirmationRequired: boolean;
  } {
    return {
      columnDefinition: columnsSidebarTableFields,
      stepForward: () => {},
      confirmationRequired: false,
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/spraying', {
      paginationStep(state: ApplicationMapsSprayingState): number {
        return state.paginationStep;
      },
      calculation(state: ApplicationMapsSprayingState): Calculation {
        return state.calculation;
      },
    }),
    ...mapGetters({
      zones: 'precisionFarming/applicationMaps/spraying/zones',
    }),
    selectedFieldIds: {
      get(): string[] {
        return this.$store.state.precisionFarming.applicationMaps.spraying.selectedFields;
      },
      set(selected: string[]) {
        this.$store.commit('precisionFarming/applicationMaps/spraying/setSelectedFields', selected);
      },
    },
    ...mapState('fields', {
      fields: 'data',
    }),
    ...mapGetters('productCategories', ['isHerbicide', 'isInsecticide']),
    ...mapGetters('products', ['findProductById']),
    selectedFields(): Field[] {
      return this.selectedFieldIds.map((id) => this.fields[id]).filter(notNullOrUndefined);
    },
    messages(): string[] {
      const messages = [];

      const multipleSortsMessages = this.$t(
        'Es wurden Felder mit verschiedenen Kulturen oder Sorten ausgewählt. Bitte beachte den Sorteneinfluss bei der Wahl der Mittel und Mengen. Mittel und Aufwandmengen müssen für alle ausgewählten Kulturen und Sorten geeignet sein!',
      );
      if (this.paginationStep === 1) {
        messages.push(multipleSortsMessages);
      }

      const herbicidesMessage = this.$t(
        'Die Variation von Ausbringmengen bei der Applikation von Herbiziden birgt das Risiko, dass der Schaderreger nicht effektiv bekämpft wird. Die variable Ausbringung von Herbiziden wir nicht empfohlen.',
      );
      if (this.paginationStep === 3 && this.hasHerbicides()) {
        messages.push(herbicidesMessage);
      }

      const insecticidesMessage = this.$t(
        'Die Variation von Ausbringmengen bei der Applikation von Insektiziden birgt hohes Risiko der Bildung von Resistenzen. Die variable Ausbringung von Insektiziden wird nicht empfohlen.',
      );
      if (this.paginationStep === 3 && this.hasInsecticides()) {
        messages.push(insecticidesMessage);
      }

      const incompatibleSprayMixMessage = this.$t(
        'Bitte beachte die technischen Möglichkeiten deiner Feldspritze. Prüfe ob deine Feldspritze in der Lage ist die festgelegte Menge Spritzbrühe auszubringen.',
      );
      if (this.paginationStep === 3 && this.incompatibleSprayTool()) {
        messages.push(incompatibleSprayMixMessage);
      }

      return messages.filter(notNullOrUndefined);
    },
  },
  methods: {
    onNavigationNext(stepForward: () => void) {
      if (!this.isConfirmationRequired()) {
        stepForward();
        return;
      }

      this.stepForward = stepForward;
      this.confirmationRequired = true;
    },
    selectedDifferentCrops(): boolean {
      const crops = this.selectedFields
        .map((field) => field.cropId)
        .filter(isUnique)
        .filter(notNullOrUndefined);
      const varieties = this.selectedFields
        .map((field) => field.varietyId)
        .filter(isUnique)
        .filter(notNullOrUndefined);
      return crops.length > 1 || varieties.length > 1;
    },
    hasHerbicides(): boolean {
      return this.calculation.products
        .map((protection: PlantProtection) => this.findProductById(protection.product.id))
        .filter(notNullOrUndefined)
        .some((product: Product) => this.isHerbicide(product.categoryId));
    },
    hasInsecticides(): boolean {
      return this.calculation.products
        .map((protection: PlantProtection) => this.findProductById(protection.product.id))
        .some((productId: string) => this.isInsecticide(productId));
    },
    incompatibleSprayTool(): boolean {
      const { sprayMix } = this.calculation;
      return sprayMix < 100 || sprayMix > 300;
    },
    isConfirmationRequired(): boolean {
      switch (this.paginationStep) {
        case 1:
          return this.selectedDifferentCrops();
        case 3:
          return this.hasHerbicides() || this.hasInsecticides() || this.incompatibleSprayTool();
        default:
          return false;
      }
    },
  },
});
