import Vue from 'vue';
import { Module } from 'vuex';

import { RootState } from '@/store/types';

import { UsedItemsState } from './types';

export function initialState(): UsedItemsState {
  return {
    used: [],
  };
}

const moduleUsedItems: Module<UsedItemsState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: {
    /**
     * Adds the given item to the list if it's not already there. Usually guids should be used here.
     * A maximum of 5 items is collected.
     *
     * @param state
     * @param item
     */
    useItem(state: UsedItemsState, item: string) {
      const items = [item, ...state.used.filter((usedItem) => usedItem !== item)].slice(0, 4);
      Vue.set(state, 'used', items);
    },
  },
  getters: {
    used: (state: UsedItemsState) => state.used,
  },
};

export default moduleUsedItems;
