<template>
  <div
    class="form-field-set-bordered"
    :class="{
      'form-field-set-bordered--collapsed': !contentVisible,
      'form-field-set-bordered--no-toggle-link': hideLinkOpen,
    }"
  >
    <span v-if="collapsible" class="form-field-set-bordered__collapsible-icon" @click="toggleCollapsed">
      <FontAwesomeIcon :icon="contentVisible ? ['far', 'angle-up'] : ['far', 'angle-down']" />
    </span>
    <div
      class="form-field-set-bordered__headline"
      :class="{ 'form-field-set-bordered__headline--collapsible': collapsible }"
      @click="toggleCollapsed"
    >
      <div v-if="!$slots['header']" class="form-field-set-bordered__headline-title">
        {{ headline }}
      </div>
      <div v-else class="form-field-set-bordered__headline-title">
        <!--
          @slot
          If set it replaces the headline prop.
        -->
        <slot name="header" />
      </div>
      <div v-if="$slots['description']" class="form-field-set-bordered__headline-description">
        <!--
          @slot
          Rendered below the headline.
        -->
        <slot name="description" />
      </div>
      <div
        v-else-if="description != null"
        v-show="!(hideDescriptionOnOpen && contentVisible)"
        class="form-field-set-bordered__headline-description"
      >
        {{ description }}
      </div>
    </div>
    <BCollapse
      v-model="contentVisible"
      class="form-field-set-bordered__content"
      :class="{ 'form-field-set-bordered__content--no-toggle-link': hideLinkClose }"
    >
      <!--
        @slot
        The rendered content inside the BCollapse. Usually a list of form fields.
      -->
      <slot name="default" />
    </BCollapse>
    <ButtonLink
      v-if="(contentVisible && !hideLinkClose) || (!contentVisible && !hideLinkOpen)"
      class="text-small"
      @click="toggleCollapsed"
    >
      {{ contentVisible ? labelLinkClose : labelLinkOpen }}
    </ButtonLink>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BCollapse } from 'bootstrap-vue';

import ButtonLink from '../buttons/ButtonLink.vue';

library.add(faAngleUp, faAngleDown);

export default {
  name: 'FormFieldSetBordered',
  components: { ButtonLink, BCollapse, FontAwesomeIcon },
  model: {
    prop: 'collapsed',
    event: 'update',
  },
  props: {
    /**
     * Rendered on top and clickable to toggle content.
     */
    headline: {
      type: String,
      default: null,
    },
    /**
     * Rendered below the headline and clickable to toggle content.
     */
    description: {
      type: String,
      default: null,
    },
    hideDescriptionOnOpen: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to false the field set will always be visible/expanded.
     */
    collapsible: {
      type: Boolean,
      default: true,
    },
    /**
     * Only works if collapsible is set to true.<br>
     * Dynamically defines if the content is collapsed/visible. It is mapped to v-model.
     */
    collapsed: {
      type: Boolean,
      default: true,
    },
    hideLinkOpen: {
      type: Boolean,
      default: false,
    },
    labelLinkOpen: {
      type: String,
      default() {
        return this.$t('Einstellungen anzeigen');
      },
    },
    hideLinkClose: {
      type: Boolean,
      default: false,
    },
    labelLinkClose: {
      type: String,
      default() {
        return this.$t('Einstellungen verstecken');
      },
    },
  },
  data() {
    return {
      collapsedInternal: this.collapsed,
    };
  },
  watch: {
    collapsed() {
      this.collapsedInternal = this.collapsed;
    },
  },
  computed: {
    contentVisible: {
      get() {
        if (!this.collapsible) {
          return true;
        }
        return !this.collapsedInternal;
      },
      set(value) {
        this.collapsedInternal = !value;
        /**
         * Content collapsed status. Used to update the v-model.
         *
         * @event FormFieldSet#update
         * @type {Boolean} collapsed
         */
        this.$emit('update', this.collapsedInternal);
      },
    },
  },
  methods: {
    toggleCollapsed() {
      this.contentVisible = this.collapsedInternal;
    },
  },
};
</script>

<style scoped>
.form-field-set-bordered {
  position: relative;
  display: flow-root;
  margin-top: var(--spacer_2);
  margin-bottom: var(--spacer_2);
  padding: var(--spacer_3);
  padding-left: var(--spacer_6);
  border: 1px solid var(--medium);
  border-radius: 3px;
}

.form-field-set-bordered--collapsed.form-field-set-bordered--no-toggle-link {
  /* padding-bottom: calc(var(--spacer_3) - var(--spacer_2)); */
}

.form-field-set-bordered__headline {
  position: relative;
  margin: 0;
}

.form-field-set-bordered__headline--collapsible {
  cursor: pointer;
}

.form-field-set-bordered__headline-title {
  margin-bottom: var(--spacer_2);
}

.form-field-set-bordered__headline-description {
  margin-bottom: var(--spacer_2);
  font-size: 12px;
}

.form-field-set-bordered__collapsible-icon {
  position: absolute;
  left: var(--spacer_3);
  top: calc(var(--spacer_3) - 1px);
  font-size: 1rem;
  cursor: pointer;
}

.form-field-set-bordered__content {
  padding: 0 0 var(--spacer_2);
}

.form-field-set-bordered__content--no-toggle-link {
  padding-bottom: 0;
}
</style>
