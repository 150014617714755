import { render, staticRenderFns } from "./TableHeaderContextMenuInjector.vue?vue&type=template&id=5b2acdf1&"
import script from "./TableHeaderContextMenuInjector.vue?vue&type=script&lang=ts&"
export * from "./TableHeaderContextMenuInjector.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports