import Vue from 'vue';
import { MutationTree } from 'vuex';

import { ENTRY_DIRTY, ENTRY_SYNCED, ENTRY_UPDATING } from '@/shared/constants';
import restorableData from '@/shared/mixins/store/restorableData';
import { getDescendantProp } from '@/shared/modules/getDisplayValue';

// eslint-disable-next-line camelcase
import { F_FieldGroupCustomer, F_FieldGroupNumber, removableStore, subscribableStore, syncableStore } from './common';
import { FieldsState } from './types';

const mutations: MutationTree<FieldsState> = {
  ...subscribableStore.mutations,
  ...syncableStore.mutations,
  ...removableStore.mutations,
  ...restorableData.mutations,
  loadAll(state, { data }) {
    Vue.set(
      state,
      'data',
      // @ts-ignore
      data.reduce((dataCurrent, entry) => {
        // @ts-ignore
        if (state.data[entry.id] != null && [ENTRY_DIRTY, ENTRY_UPDATING].includes(state.data[entry.id].storeStatus)) {
          return {
            ...dataCurrent,
            // @ts-ignore
            [entry.id]: state.data[entry.id],
          };
        }
        return {
          ...dataCurrent,
          [entry.id]: {
            ...entry,
            [F_FieldGroupNumber]: getDescendantProp(entry, F_FieldGroupNumber),
            [F_FieldGroupCustomer]: getDescendantProp(entry, F_FieldGroupCustomer),
            storeStatus: ENTRY_SYNCED,
          },
        };
      }, {}),
    );
    Vue.set(state, 'loaded', new Date());
  },
  updateEntries(state, { data }) {
    Vue.set(
      state,
      'data',
      // @ts-ignore
      data.reduce((dataCurrent, entry) => {
        // @ts-ignore
        if (state.data[entry.id] != null && [ENTRY_DIRTY, ENTRY_UPDATING].includes(state.data[entry.id].storeStatus)) {
          return {
            ...dataCurrent,
            // @ts-ignore
            [entry.id]: state.data[entry.id],
          };
        }
        return {
          ...dataCurrent,
          [entry.id]: {
            ...entry,
            [F_FieldGroupNumber]: getDescendantProp(entry, F_FieldGroupNumber),
            [F_FieldGroupCustomer]: getDescendantProp(entry, F_FieldGroupCustomer),
            storeStatus: ENTRY_SYNCED,
          },
        };
        // @ts-ignore
      }, state.data),
    );
  },
  setFieldNdvis(state: FieldsState, { data }) {
    Vue.set(
      state,
      'data',
      // @ts-ignore
      Object.keys(state.data).reduce((dataCurrent, entryId) => {
        if (data[entryId]) {
          return {
            ...dataCurrent,
            [entryId]: {
              // @ts-ignore
              ...state.data[entryId],
              ndvi: data[entryId].vegetation.value,
              ndviProps: {
                ...data[entryId].properties,
              },
            },
          };
        }
        return {
          ...dataCurrent,
          // @ts-ignore
          [entryId]: state.data[entryId],
        };
        // @ts-ignore
      }, state.data),
    );
  },
  setFieldMbis(state: FieldsState, { data }) {
    Vue.set(
      state,
      'data',
      // @ts-ignore
      Object.keys(state.data).reduce((dataCurrent, entryId) => {
        if (data[entryId]) {
          return {
            ...dataCurrent,
            [entryId]: {
              // @ts-ignore
              ...state.data[entryId],
              mbi: data[entryId].vegetation.value,
              mbiProps: {
                ...data[entryId].properties,
              },
            },
          };
        }
        return {
          ...dataCurrent,
          // @ts-ignore
          [entryId]: state.data[entryId],
        };
        // @ts-ignore
      }, state.data),
    );
  },
};

export default mutations;
