import { Activity, ActivityEquipment, ActivityProduct } from 'farmdok-rest-api';

import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';

export default class ActivitySyncService {
  public static toSyncableActivities(activities: Activity[]): Activity[] {
    return activities.map((activity) => ActivitySyncService.toSyncableActivity(activity));
  }

  public static toSyncableActivity(activity: Activity): Activity {
    const syncableActivityProducts = ActivitySyncService.toSyncableActivityProducts(activity.products);
    const syncableActivityEquipment = ActivitySyncService.toSyncableActivityEquipments(activity.equipment);

    return { ...activity, products: syncableActivityProducts, equipment: syncableActivityEquipment };
  }

  public static toSyncableActivityProducts(activityProducts: ActivityProduct[]): ActivityProduct[] {
    return activityProducts
      .map((activityProduct) => ActivitySyncService.toSyncableActivityProduct(activityProduct))
      .filter(notNullOrUndefined);
  }

  public static toSyncableActivityProduct(activityProduct: ActivityProduct): ActivityProduct | null {
    if (!ActivitySyncService.isSyncableActivityProduct(activityProduct)) return null;

    // amount and unitId must be both set or both not set
    if (
      (activityProduct.amount == null && activityProduct.unitId != null) ||
      (activityProduct.amount != null && activityProduct.unitId == null)
    ) {
      return {
        ...activityProduct,
        amount: null,
        unitId: null,
      };
    }

    return activityProduct;
  }

  public static isSyncableActivityProduct(activityProduct: ActivityProduct): boolean {
    return activityProduct.productId !== '';
  }

  public static toSyncableActivityEquipments(activityEquipments: ActivityEquipment[]): ActivityEquipment[] {
    return activityEquipments
      .map((activityEquipment) => ActivitySyncService.toSyncableActivityEquipment(activityEquipment))
      .filter(notNullOrUndefined);
  }

  public static toSyncableActivityEquipment(activityEquipment: ActivityEquipment): ActivityEquipment | null {
    if (!ActivitySyncService.isSyncableActivityEquipment(activityEquipment)) return null;

    return activityEquipment;
  }

  public static isSyncableActivityEquipment(activityEquipment: ActivityEquipment): boolean {
    return activityEquipment.equipmentId !== '';
  }
}
