import { ProductCategory, ProductCategoryType } from 'farmdok-rest-api';

import { Product } from '@/shared/api/rest/models';

/**
 * @deprecated use isHerbicide from /src/productCategories/store/getters.ts instead
 */
function isHerbicide(product: Product | undefined, productCategories: Record<string, ProductCategory>): boolean {
  if (!product?.categoryId) return false;

  const productCategory: ProductCategory = productCategories[product.categoryId];
  if (!productCategory) return false;
  if (productCategory.type !== ProductCategoryType.Herb) return false;

  return true;
}

export default isHerbicide;
