<template>
  <Export :workflowKey="workflowKey">
    <ExportTable :tableHeaders="tableHeaders" :tableRows="tableRows" :tableHint="$t('Alle Werte in kg/ha')" />
  </Export>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { combinedNameExclCrop } from '@/fields/handsontable/columns/columns';
import numbro from '@/initNumbro';

import Export from '../components/export/Export.vue';
import ExportTable from '../components/export/ExportTable.vue';
import { ZONE_GENERATION_MODE_SATELLITE, ZONE_GENERATION_MODE_UPLOAD } from '../store/baseWorkflowStore/common';

export default {
  name: 'SidebarExportFertilization',
  components: {
    Export,
    ExportTable,
  },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [this.$t('Dosierung'), 'N', 'P₂O₅', 'K₂O'],
    };
  },
  computed: {
    ...mapGetters({
      fields: 'fields',
    }),
    ...mapState({
      zoneDosage(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/zoneDosage`];
      },
      selectedFields(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].selectedFields;
      },
      currentHeatmaps(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/currentHeatmaps`];
      },
      calculation(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/calculation`];
      },
      uploadedZonesByFilename(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/uploadedZonesByFilename`];
      },
      zoneGenerationMode(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].zoneGenerationMode;
      },
    }),
    fieldValuesByHeatmaps() {
      return Object.keys(this.currentHeatmaps).map((productId) => {
        const product = this.currentHeatmaps[productId];
        const guid = productId.split('_')[0];
        const field = {
          name: combinedNameExclCrop.data.call(this, this.fields[guid]),
          dosage: 0,
          title: null,
        };
        if (field.name.length > 19) {
          field.title = field.name;
        }
        product.color_codes.forEach((zone) => {
          field.dosage += (this.getDosageForZone(zone.col) * zone.area) / 10000;
        });
        const heatmapSize = product.color_codes.reduce((currentSize, zone) => currentSize + zone.area, 0) / 10000;
        if (heatmapSize > 0) {
          field.dosage /= heatmapSize;
        }
        field.n = field.dosage * this.calculation.n;
        field.p = field.dosage * this.calculation.p;
        field.k = field.dosage * this.calculation.k;
        return field;
      });
    },
    fieldValuesByUploadedFiles() {
      const fieldValuesByFieldId = {};
      this.selectedFields.forEach((guid) => {
        const field = {
          name: combinedNameExclCrop.data.call(this, this.fields[guid]),
          title: null,
          dosage: 0,
        };
        if (field.name.length > 19) {
          field.title = field.name;
        }
        fieldValuesByFieldId[guid] = field;
      });
      Object.values(this.uploadedZonesByFilename).forEach((featureCollection) => {
        if (featureCollection.properties == null || !Array.isArray(featureCollection.properties.fieldIds)) {
          return;
        }
        featureCollection.properties.fieldIds.forEach((fieldId) => {
          if (fieldValuesByFieldId[fieldId] == null) {
            return;
          }
          let dosage = 0;
          let zonesTotalSize = 0;
          featureCollection.features.forEach((feature) => {
            const color = feature.properties.fill;
            const { size } = feature.properties;
            dosage += (this.getDosageForZone(color) * size) / 10000;
            zonesTotalSize += size / 10000;
          });
          if (zonesTotalSize > 0) {
            dosage /= zonesTotalSize;
          }
          fieldValuesByFieldId[fieldId].dosage += dosage;
        });
      });
      return Object.values(fieldValuesByFieldId).map((field) => ({
        ...field,
        n: field.dosage * this.calculation.n,
        p: field.dosage * this.calculation.p,
        k: field.dosage * this.calculation.k,
      }));
    },
    fieldValues() {
      if (this.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
        return this.fieldValuesByHeatmaps;
      }
      if (this.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
        return this.fieldValuesByUploadedFiles;
      }
      return [];
    },
    tableRows() {
      return this.fieldValues.map((field) => ({
        title: field.title,
        name: field.name,
        values: [
          numbro(field.dosage).format({ mantissa: 0 }),
          numbro(field.n).format({ mantissa: 0 }),
          numbro(field.p).format({ mantissa: 0 }),
          numbro(field.k).format({ mantissa: 0 }),
        ],
      }));
    },
  },
  methods: {
    getDosageForZone(color) {
      const zone = this.zoneDosage?.find((zoneCandidate) => zoneCandidate.color === color);
      return zone?.dosage || 0;
    },
  },
};
</script>
