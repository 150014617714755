import router from '@/router';
import { baseUrl, origin } from '@/shared/constants';
import store from '@/store';

import PageAdditionalData from './PageAdditionalData.vue';
import PageCheckMail from './PageCheckMail.vue';
import PageLogin from './PageLogin.vue';
import PageNoCompany from './PageNoCompany.vue';
import PagePasswordLost from './PagePasswordLost.vue';
import PagePasswordReset from './PagePasswordReset.vue';
import PageRegister from './PageRegister.vue';
import PageRegisterComplete from './PageRegisterComplete.vue';
import PageRegisterSupport from './PageRegisterSupport.vue';
import PageWelcome from './PageWelcome.vue';
import { FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE } from './constants';

export const ADDITIONAL_DATA_STEPS = ['company', 'address'];

export default [
  {
    path: `${baseUrl}oauth/cnh`,
    redirect: `${baseUrl}activities/import/load-data/cnh`,
  },
  {
    path: `${baseUrl}welcome/:type?/:step?`,
    name: 'welcome',
    component: PageWelcome,
    meta: {
      isFullscreen: true,
    },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('auth/subscribe');
      if (store.state.auth.loggedIn) {
        return next({ name: 'home' });
      }
      return next();
    },
  },
  {
    path: `${baseUrl}login`,
    name: 'login',
    component: PageLogin,
    meta: {
      isFullscreen: true,
    },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('auth/subscribe');
      if (store.state.auth.loggedIn) {
        let targetRoute = { name: 'home' };
        if (typeof to.query.redirect === 'string' && to.query.redirect.length > 0) {
          const redirectRoute = router.resolve(to.query.redirect);
          if (redirectRoute != null && redirectRoute.resolved != null && redirectRoute.resolved.name !== '404') {
            targetRoute = redirectRoute.resolved;
          } else {
            store.commit('redirectToAgriDat');
            window.location = `${origin}${to.query.redirect}`;
            return;
          }
        }
        next(targetRoute);
        return;
      }
      next();
    },
  },
  {
    path: `${baseUrl}register`,
    name: 'register',
    component: PageRegister,
    meta: {
      isFullscreen: true,
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.auth.loggedIn) {
        return next({ name: 'home' });
      }
      return next();
    },
  },
  {
    path: `${baseUrl}register/support`,
    name: 'register-support',
    component: PageRegisterSupport,
    meta: {
      isFullscreen: true,
    },
  },
  {
    path: `${baseUrl}register/check-mail`,
    name: 'register-check-email',
    component: PageCheckMail,
    meta: {
      isFullscreen: true,
      requiresAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.auth.user.emailIsConfirmed) {
        return next({ name: 'home' });
      }
      return next();
    },
  },
  {
    path: `${baseUrl}register/complete/:token`,
    name: 'register-complete',
    component: PageRegisterComplete,
    meta: {
      isFullscreen: true,
    },
  },
  {
    path: `${baseUrl}register/additional-data/:step?`,
    name: 'register-additional-data',
    component: PageAdditionalData,
    meta: {
      isFullscreen: true,
      requiresAuth: true,
    },
    beforeEnter: async (to, from, next) => {
      if (!ADDITIONAL_DATA_STEPS.includes(to.params.step)) {
        return next({ name: 'register-additional-data', params: { step: 'company' } });
      }
      if (
        localStorage.getItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE) != null &&
        JSON.parse(localStorage.getItem(FARMDOK_AUTH_REGISTRATION_PROCESS_ACTIVE)) !== true
      ) {
        return next({ name: 'home' });
      }
      return next();
    },
  },
  {
    path: `${baseUrl}password-lost`,
    name: 'password-lost',
    component: PagePasswordLost,
    props: true,
    meta: {
      isFullscreen: true,
    },
  },
  {
    path: `${baseUrl}password-reset/:token`,
    name: 'password-reset',
    component: PagePasswordReset,
    meta: {
      isFullscreen: true,
    },
  },
  {
    path: `${baseUrl}no-company`,
    name: 'no-company',
    component: PageNoCompany,
    meta: {
      isFullscreen: true,
      requiresAuth: true,
    },
  },
];
