import axios from 'axios';
import chunk from 'lodash.chunk';
import Vue from 'vue';
import { ActionTree } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { Feature } from '@/precision-farming/application-maps/store/baseWorkflowStore/types/Heatmap';
import { Field } from '@/shared/api/rest/models';
import { GUID_KEY, UUID5_FIELD_CONTOUR_NODE } from '@/shared/constants';
import restorableData from '@/shared/mixins/store/restorableData';
import {
  getRestResponseData,
  mergeRestResponseData,
  requestDataCreateFromEntryAndModel,
} from '@/shared/modules/restApiHelpers';
import { createUuidv5 } from '@/shared/modules/uuid';
import { RootState } from '@/store/types';

import {
  compareFieldContours,
  fetchMBI,
  fetchNdvi,
  modelFieldContour,
  resolveAdditionalData,
  resolveCropId,
  resolveProcessOrderId,
} from './actionUtils';
import { model, removableStore, subscribableStore, syncableStore } from './common';
import { FieldsState, ProductRequestBody } from './types';

const actions: ActionTree<FieldsState, RootState> = {
  ...subscribableStore.actions,
  ...syncableStore.actions,
  ...removableStore.actions,
  ...restorableData.actions,
  async insertNewEntry(context, { entry }) {
    const { commit } = context;
    let newEntry = await resolveProcessOrderId({ ...model, ...entry }, context);
    newEntry = resolveCropId(newEntry, context);

    commit('insert', { entry: newEntry });
  },
  async insertAndSyncNewEntry(context, { entry }) {
    const { dispatch } = context;
    let newEntry = await resolveProcessOrderId(entry, context);
    newEntry = resolveCropId(newEntry, context);

    await dispatch('insertNewEntry', { entry: newEntry });
    await dispatch('syncAll');
    if (entry.fieldContour != null) {
      newEntry.fieldContour.id = createUuidv5(UUID5_FIELD_CONTOUR_NODE, entry.id);
      await dispatch('syncFieldContourForEntry', { entry: newEntry });
    }
  },
  async updateAndSyncEntry(context, { entry }) {
    const { commit, dispatch, state } = context;
    let newEntry = await resolveProcessOrderId(entry, context);
    newEntry = resolveCropId(newEntry, context);
    const syncFieldContour = !compareFieldContours(state.data[entry.id].fieldContour, entry.fieldContour);
    newEntry.additionalData = resolveAdditionalData(entry);
    commit('updateEntry', { entry: newEntry });
    await dispatch('syncAll');
    if (syncFieldContour && newEntry.fieldContour != null) {
      await dispatch('syncFieldContourForEntry', { entry: newEntry });
    }
  },
  /**
   * Update the field contour of a single field.
   *
   * @param commit
   * @param dispatch
   * @param state
   * @param entry
   * @return {Promise<void>}
   */
  async syncFieldContourForEntry({ commit }, { entry }) {
    const guid = entry[GUID_KEY];
    const requestData = {
      ...entry.fieldContour,
      fieldId: guid,
    };
    // duplicate geoJson to geometry because the new API route needs a geometry property
    requestData.geometry = requestData.geoJson;
    let responseData;
    try {
      const { fieldsApi } = this.$api as Api;
      const { data } = await fieldsApi.fieldContourPut({
        fieldContourPutRequest: { data: [requestDataCreateFromEntryAndModel(requestData, modelFieldContour)] },
      });
      responseData = getRestResponseData(data);
    } catch (error) {
      responseData = getRestResponseData(error);
    }
    if (responseData.status !== 'success') {
      commit('addSyncError', {
        guid,
        key: null,
        errorUserMessage: Vue.i18n.translate('Die Feldkontur konnte nicht aktualisiert werden.'),
      });
    } else {
      // @ts-ignore
      commit('updateEntry', { entry: { id: entry.id }, silent: true });
    }
  },
  loadResolved({ commit }, { resolved }) {
    if (resolved == null) {
      return;
    }
    if (Array.isArray(resolved.crop)) {
      resolved.crop.forEach((crop: unknown) => {
        commit('products/crops/addOne', crop, { root: true });
      });
    }
    if (Array.isArray(resolved.preCrop)) {
      resolved.preCrop.forEach((crop: unknown) => {
        commit('products/crops/addOne', crop, { root: true });
      });
    }
    if (Array.isArray(resolved.variety)) {
      resolved.variety.forEach((variety: unknown) => {
        commit('varieties/addOne', variety, { root: true });
      });
    }
  },
  async setStatus({ dispatch, rootGetters }, { status, fieldIds }) {
    if (!['completed', 'active', 'planned'].includes(status)) {
      throw new Error('fields/setStatus: desired status not possible');
    }
    try {
      const { data } = await axios.post('admin/rest/field/set_status', {
        version: '2.0',
        data: {
          status,
          fieldIds,
        },
      });
      await Promise.all([dispatch('auth/refreshUserCompanies', null, { root: true }), dispatch('refresh')]);
      data.data = Object.keys(data.data).reduce((result, processOrderId) => {
        const processOrderName = rootGetters['auth/processOrdersById'][processOrderId].name;
        return {
          ...result,
          [processOrderName]: {
            // @ts-ignore
            ...result[processOrderName],
            ...data.data[processOrderId],
          },
        };
      }, {});
      return data;
    } catch (error: any) {
      let errorUserMessage = [Vue.i18n.translate('Der Erntejahr-Status konnte nicht geändert werden.')];
      if (error.response != null && error.response.data != null && error.response.data.errorUserMessage != null) {
        ({ errorUserMessage } = error.response.data);
      }
      return {
        version: '2.0',
        status: 'error',
        errorCode: 'generalError',
        errorUserMessage,
      };
    }
  },
  async copyAllFieldsFromProcessOrder({ rootState, rootGetters }, { direction }) {
    if (!['previous', 'next'].includes(direction)) {
      console.error("Method copyAllFieldsFromProcessOrder can only copy from 'next' or 'previous' processOrder");
      return {
        status: 'error',
      };
    }
    const targetProcessOrderName = rootState.auth.currentProcessOrderName;
    const sourceProcessOrderName =
      direction === 'next' ? String(Number(targetProcessOrderName) + 1) : String(Number(targetProcessOrderName) - 1);
    const responsesData: unknown[] = [];
    await Promise.all(
      rootState.auth.currentCompanies.map(async (company) => {
        const sourceProcessOrder = rootGetters['auth/processOrderByCompanyIdAndNameAndType'](
          company.id,
          sourceProcessOrderName,
        );
        const targetProcessOrder = rootGetters['auth/processOrderByCompanyIdAndNameAndType'](
          company.id,
          targetProcessOrderName,
        );
        try {
          const { data } = await axios.post('admin/rest/field/copy_fields_from_process_order', {
            version: '2.0',
            data: {
              fromProcessOrderId: sourceProcessOrder.id,
              targetProcessOrderId: targetProcessOrder.id,
            },
          });
          responsesData.push(getRestResponseData(data));
        } catch (error) {
          responsesData.push(getRestResponseData(error));
        }
      }),
    );
    return mergeRestResponseData(...responsesData);
  },
  async loadNdvi({ state, commit }, fields: Field[]): Promise<void> {
    state.loadingNdvi = true;
    const timestamp = Math.floor(Date.now() / 1000);
    const products: ProductRequestBody[] = fields.map(
      (field: Field): ProductRequestBody => ({
        clientId: field.id,
        polygon: field.fieldContour.geoJson?.coordinates[0],
        timestamp,
      }),
    );

    const productsWithContour = products.filter((product: ProductRequestBody) => product.polygon);
    const batchSize = 10;
    const productsWithContourRequestBatches = chunk<ProductRequestBody>(productsWithContour, batchSize);

    function setNdviInStore(data: Record<string, Feature>): void {
      try {
        commit('setFieldNdvis', { data });
      } catch (error) {
        console.error(error);
        commit('fetchingError');
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const productBatch of productsWithContourRequestBatches) {
      // eslint-disable-next-line no-await-in-loop
      const body = await fetchNdvi(productBatch);
      setNdviInStore(body);
    }
    state.loadingNdvi = false;
  },
  async loadMbi({ state, commit }, fields: Field[]): Promise<void> {
    state.loadingMbi = true;
    const timestamp = Math.floor(Date.now() / 1000);
    const products: ProductRequestBody[] = fields.map(
      (field: Field): ProductRequestBody => ({
        clientId: field.id,
        polygon: field.fieldContour.geoJson?.coordinates[0],
        timestamp,
      }),
    );

    const productsWithContour = products.filter((product: ProductRequestBody) => product.polygon);
    const batchSize = 25;
    const productsWithContourRequestBatches = chunk<ProductRequestBody>(productsWithContour, batchSize);

    function setMbiInStore(data: Record<string, Feature>): void {
      try {
        commit('setFieldMbis', { data });
      } catch (error) {
        console.error(error);
        commit('fetchingError');
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const productBatch of productsWithContourRequestBatches) {
      // eslint-disable-next-line no-await-in-loop
      const body = await fetchMBI(productBatch);
      setMbiInStore(body);
    }
    state.loadingMbi = false;
  },
};
export default actions;
