
import { ActivityProduct, PesticideIndication } from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import PestDetails from '@/activities/modals/createEditActivity/components/PestDetails.vue';
import { Company } from '@/auth/store/types';
import { ActionPayloads } from '@/pesticideIndications/store/actions';
import { Getters } from '@/pesticideIndications/store/getters';
import isHerbicide from '@/products/herbizides/isHerbicide';
import { Product } from '@/shared/api/rest/models';
import FeatureNotAvailable from '@/shared/components/FeatureNotAvailable.vue';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

import { Getters as CreateEditActivityGetters } from '../store/getters';

export default defineComponent({
  name: 'PestDetailsContainer',
  components: { PestDetails, FeatureNotAvailable },
  data() {
    const pestIndications: PesticideIndication[] = [];
    return {
      pestIndications,
    };
  },
  created() {
    this.$store.dispatch('units/subscribe');
    this.$store.dispatch('pests/subscribe');
  },
  computed: {
    ...mapState('auth', ['currentCompanies']),
    ...mapState('pests', {
      pests: 'data',
    }),
    ...mapState('units', {
      units: 'data',
    }),
    ...mapState('fields', {
      fields: 'data',
    }),
    ...mapState('productCategories', {
      productCategories: 'data',
    }),
    activityTimeStarts(): number[] {
      return this.$store.getters[
        `activities/createEditActivity/activityTimeStarts`
      ] as CreateEditActivityGetters['activityTimeStarts'];
    },
    currentActivityProduct(): ActivityProduct | null {
      return this.$store.getters[
        `activities/createEditActivity/currentActivityProduct`
      ] as CreateEditActivityGetters['currentActivityProduct'];
    },
    selectedProduct(): Product | null {
      if (this.currentActivityProduct?.productId) {
        return this.$store.getters['products/findProductById'](this.currentActivityProduct.productId);
      }
      return null;
    },
    selectedFieldsCropIds(): string[] {
      const selectedFieldIds = this.$store.getters[
        `activities/createEditActivity/selectedFields`
      ] as CreateEditActivityGetters['selectedFields'];
      const fields = (this.$store.state as RootState).fields.data;

      const cropIds = selectedFieldIds
        ?.map((selectedFieldId) => fields[selectedFieldId]?.cropId)
        .filter(notNullOrUndefined);

      if (!cropIds) return [];

      const uniqueCropIds = [...new Set(cropIds)];
      return uniqueCropIds;
    },
    compatiblePestIndications(): PesticideIndication[] {
      if (!this.selectedProduct || !isHerbicide(this.selectedProduct, this.productCategories)) {
        return [];
      }

      const pestIndications = (
        this.$store.getters[
          'pesticideIndications/compatiblePesticideIndications'
        ] as Getters['compatiblePesticideIndications']
      )(this.selectedProduct.id, this.selectedFieldsCropIds, this.activityTimeStarts);

      return pestIndications;
    },
    pestName(): string {
      if (this.currentActivityProduct?.pestId) {
        return this.pests[this.currentActivityProduct?.pestId].name;
      }
      return '';
    },
    showPestDetails(): boolean {
      if (!this.featurePesticideDetailsEnabled) return false;
      if (!this.featurePesticideDetailsVisible) return false;

      return this.herbicideSelected;
    },
    showFeatureNotAvailable(): boolean {
      if (this.featurePesticideDetailsEnabled) return false;
      if (!this.featurePesticideDetailsVisible) return false;

      return this.herbicideSelected;
    },
    herbicideSelected(): boolean {
      if (!this.selectedProduct) return false;
      if (!this.currentActivityProduct?.pestId || !this.currentActivityProduct?.pesticideIndicationId) return false;

      return isHerbicide(this.selectedProduct, this.productCategories);
    },
    featurePesticideDetailsEnabled(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureEnabled(
        availableFeatures.FEATURE_ACTIVITIES_PESTICIDE_DETAILS,
      );
    },
    featurePesticideDetailsVisible(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureVisible(
        availableFeatures.FEATURE_ACTIVITIES_PESTICIDE_DETAILS,
      );
    },
    currentRegionCodes(): string[] {
      const uniqueRegionCode = new Set<string>();
      this.currentCompanies.forEach((company: Company) => uniqueRegionCode.add(company.region.code));

      return Array.from(uniqueRegionCode);
    },
  },
  watch: {
    selectedProduct: {
      handler(newProduct: Product) {
        if (isHerbicide(newProduct, this.productCategories)) {
          const payload: ActionPayloads['loadByIds'] = [newProduct.id];
          this.$store.dispatch('pesticideIndications/loadByIds', payload);
        }
      },
      immediate: true,
    },
  },
});
