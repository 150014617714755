/* eslint-disable consistent-return */
import Handsontable from 'handsontable';

export default function preventMoveBetweenSubtableColumnsFactory(hot: Handsontable) {
  function preventMoveBetweenSubtableColumns(
    movedColumns: number[],
    finalIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dropIndex: number | void,
    movePossible: boolean,
  ): boolean {
    const { columns } = hot.getSettings();
    if (!Array.isArray(columns)) throw new Error('columns must be of type ColumnSettings[]');

    const moveToRight = movedColumns.every((movedColumn) => movedColumn < finalIndex);
    const moveToLeft = movedColumns.every((movedColumn) => movedColumn > finalIndex);
    const mixedMove = moveToRight && moveToLeft;

    if (mixedMove) {
      console.warn('mixed move not supported');
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
      movePossible = false;
      return false;
    }

    let columnBefore: Handsontable.ColumnSettings | undefined;
    let columnAfter: Handsontable.ColumnSettings | undefined;
    if (moveToRight) {
      columnBefore = columns[hot.toPhysicalColumn(finalIndex)];
      columnAfter = columns[hot.toPhysicalColumn(finalIndex + 1)];
    }
    if (moveToLeft) {
      columnBefore = columns[hot.toPhysicalColumn(finalIndex - 1)];
      columnAfter = columns[hot.toPhysicalColumn(finalIndex)];
    }

    if (columnBefore?.isSubtableColumn && columnAfter?.isSubtableColumn) {
      if (columnBefore?.isLastSubtableColumn && columnAfter?.isFirstSubtableColumn) return true; // two different subtables

      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unused-vars
      movePossible = false;
      return false;
    }

    return true;
  }

  return preventMoveBetweenSubtableColumns;
}
