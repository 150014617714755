
import { PesticideIndication } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import { Product, Unit } from '@/shared/api/rest/models';
import ButtonLink from '@/shared/components/buttons/ButtonLink.vue';
import { fromUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';

type PesticideCatalogue = {
  name: string;
  link: string;
};

export default defineComponent({
  name: 'PestDetails',
  components: { ButtonLink },
  props: {
    selectedPestId: {
      type: String as PropType<string | null>,
      required: true,
    },
    selectedPestName: {
      type: String as PropType<string>,
      required: true,
    },
    selectedPestIndicationId: {
      type: String as PropType<string | null>,
      required: true,
    },
    selectedProduct: {
      type: Object as PropType<Product | null>,
      required: true,
    },
    compatiblePestIndications: {
      type: Array as PropType<PesticideIndication[]>,
      required: true,
    },
    units: {
      type: Object as PropType<Record<string, Unit>>,
      required: true,
    },
    selectedFieldsCropIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
    regionCodes: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  computed: {
    pesticideIndication(): PesticideIndication | undefined {
      return this.compatiblePestIndications.find((candidate) => candidate.id === this.selectedPestIndicationId);
    },
    maxAmount(): string {
      if (this.pesticideIndication?.maxAmount || this.pesticideIndication?.maxAmountUnitId) {
        const unit: Unit | undefined = this.pesticideIndication.maxAmountUnitId
          ? this.units[this.pesticideIndication.maxAmountUnitId]
          : undefined;

        return `${this.pesticideIndication.maxAmount || ''} ${unit?.name || ''}${
          this.pesticideIndication.isSplitting ? ', Splitting' : ''
        }`;
      }
      return '-';
    },
    maxTreatmentsPerCropYear(): string {
      return `${this.pesticideIndication?.maxApplicationsPerVegetation || '-'}`;
    },
    maxTreatments(): string {
      return `${this.pesticideIndication?.maxApplicationsPerInfestation || '-'}`;
    },
    waitPeriodUntilHarvest(): string {
      return `${this.pesticideIndication?.waitPeriod || '-'}`;
    },
    waitPeriodBetweenTreatments(): string {
      if (
        this.pesticideIndication?.minTimeBetweenApplicationsOriginal &&
        this.pesticideIndication?.maxTimeBetweenApplicationsOriginal &&
        this.pesticideIndication?.timeBetweenApplicationsUnit
      ) {
        return `${this.pesticideIndication.minTimeBetweenApplicationsOriginal} - ${this.pesticideIndication.maxTimeBetweenApplicationsOriginal} ${this.pesticideIndication.timeBetweenApplicationsUnit} `;
      }
      return '-';
    },
    cropLimitations(): string {
      if (this.pesticideIndication?.cropLimitations) {
        const cropLimitations = Object.fromEntries(
          Object.entries(this.pesticideIndication?.cropLimitations).filter(([key], index) =>
            key.includes(this.selectedFieldsCropIds[index]),
          ),
        );

        return Object.values(cropLimitations).flat().join(', ');
      }
      return '-';
    },
    pestLimitation(): string {
      if (this.pesticideIndication?.pestLimitations && this.selectedPestId) {
        return `${this.pesticideIndication.pestLimitations[this.selectedPestId] || '-'}`;
      }
      return '-';
    },
    approvedUntil(): string {
      if (this.pesticideIndication?.approvalEnd) {
        const date = new Date(fromUnixTimestamp(this.pesticideIndication.approvalEnd)).toLocaleDateString('de-AT');
        return `${date}`;
      }
      return '-';
    },
    useUntil(): string {
      if (this.selectedProduct?.pesticide?.gracePeriodUsageDate) {
        const { gracePeriodUsageDate } = this.selectedProduct.pesticide;
        const date = new Date(fromUnixTimestamp(gracePeriodUsageDate)).toLocaleDateString('de-AT');
        return `${date}`;
      }
      return '-';
    },
    pesticideCatalogues(): Record<string, PesticideCatalogue> {
      const pesticideCatalogues: Record<string, PesticideCatalogue> = {};
      const pesticideCatalogue: PesticideCatalogue = {
        name: '',
        link: '',
      };
      this.regionCodes.forEach((regionCode) => {
        if (this.pesticideIndication?.externalId) {
          if (regionCode === 'de') {
            const parts = this.pesticideIndication.externalId.split('/');
            const kennNr = parts[0];
            pesticideCatalogue.name =
              this.$t('Pflanzenschutzmittelkatalog öffnen') || 'Pflanzenschutzmittelkatalog öffnen';
            pesticideCatalogue.link = `https://apps2.bvl.bund.de/psm/jsp/BlattAnwendg.jsp?awg_id=${this.pesticideIndication.externalId}&kennr=${kennNr}`;
          } else {
            const parts = this.pesticideIndication?.externalId.split('/');
            const refNr = parts[0];
            const indNr = parts[1];
            pesticideCatalogue.name = this.$t('Link zu AGES Register') || 'Link zu AGES Register';
            pesticideCatalogue.link = `https://psmregister.baes.gv.at/psmregister/faces/indication.jspx?refNr=${refNr}&indNr=${indNr}&locale=de`;
          }
        }
        pesticideCatalogues[regionCode.toUpperCase()] = pesticideCatalogue;
      });

      return pesticideCatalogues;
    },
  },
});
