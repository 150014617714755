<template>
  <BDropdown
    split
    class="button-dropdown"
    menu-class="shadow"
    :class="[`button-dropdown--${variant}`, { 'button-dropdown--has-next-sibling': hasNextSibling }]"
    :text="text"
    :variant="variant"
    :disabled="disabled"
    :boundary="boundary"
    @click="onClick"
  >
    <BDropdownItemButton
      v-for="button in buttons"
      :key="button.text"
      :disabled="button.disabled"
      @click="(event) => onClickButton(button, event)"
    >
      {{ button.text }}
    </BDropdownItemButton>
  </BDropdown>
</template>

<script>
import componentNamespace from '../../mixins/componentNamespace';

export default {
  name: 'ButtonDropdown',
  mixins: [componentNamespace],
  props: {
    /**
     * Button text. Unlike Button this component doesnt support a slot.
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * List of buttons to be rendered inside the dropdown:<br>
     * [{ text: String, handler: Function, disabled: Boolean, componentNamespaceName: String }, ...]
     */
    buttons: {
      type: Array,
      required: true,
    },
    /**
     * Change the button look to an outline variant (border with color, rest of the button transparent).
     */
    outline: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable the button.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Propagated to BDropdown, see https://bootstrap-vue.org/docs/components/dropdown#boundary-constraint.
     */
    boundary: {
      type: String,
      default: 'scrollParent',
      validator: (val) => ['scrollParent', 'window', 'viewport'].includes(val),
    },
  },
  data() {
    return {
      hasNextSibling: false,
    };
  },
  computed: {
    variant() {
      if (this.outline) {
        return 'outline-primary';
      }
      return 'primary';
    },
  },
  mounted() {
    if (this.$el.nextElementSibling == null) {
      return;
    }
    this.hasNextSibling = true;
  },
  methods: {
    onClick(event) {
      /**
       * @event root#button--click
       * @type {String}
       */
      this.$root.$emit('button--click', this.componentNamespace);
      this.$emit('click', event);
    },
    onClickButton(button, event) {
      let namespace = this.componentNamespace;
      if (typeof button.componentNamespaceName === 'string' && button.componentNamespaceName.length > 0) {
        namespace = `${button.componentNamespaceName}.${namespace}`;
      }
      /**
       * @event root#button--click
       * @type {String}
       */
      this.$root.$emit('button--click', namespace);
      if (typeof button.handler === 'function') {
        button.handler(event);
      }
    },
  },
};
</script>

<style scoped>
.button-dropdown {
  margin: var(--spacer_2) 0;
}

.button-dropdown--has-next-sibling {
  margin-right: var(--spacer_3);
}
</style>
