import { GetterTree } from 'vuex';

import { TimestampsGpsState } from '@/activities/gpsLog/store/types';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';

const getters: GetterTree<TimestampsGpsState, RootState> = {
  ...subscribableStore.getters,
};

export default getters;
